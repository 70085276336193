import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';

import KeyNotesSearchListing from './KeyNotesSearchListing';
import Delete from './components/Delete';
import Edit from './components/Edit';
import TimeTracker from '../../../component/TimeTracker';

const KeyNotesSearch = observer((props) => {

  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const {
    KeyNotesSearchStore,
  } = useStore();


  //-----------------OPEN-CLOSE EDIT DRAWER--------------//
  const openEditDrawer = (data) => {
    KeyNotesSearchStore.setEditValue(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => setEditDrawer(false);
  //-----------------OPEN-CLOSE VIEW LIBRARY--------------//

  //-----------------OPEN-CLOSE DELETE CONFIRMATION--------------//
  const openDeleteDrawer = (data) => {
    KeyNotesSearchStore.setDeleteValue(data)
    setDeleteDrawer(true);
  };

  const closeDeleteDrawer = () => {
    setDeleteDrawer(false);
  };

  return (
    <>
      <KeyNotesSearchListing
        openEditDrawer={openEditDrawer}
        openDeleteDrawer={openDeleteDrawer}
      />
      <Edit
        visible={editDrawer}
        close={closeEditDrawer}
      />
      <Delete
        visible={deleteDrawer}
        close={closeDeleteDrawer}
        SearchPage={props.SearchPage}
        searchPayload={props.searchPayload}
      />
      <TimeTracker
        page={"key-notes"}
      />
    </>
  );
});

export default KeyNotesSearch;
