import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class LibrarySearchStore {
  agGrid = null;
  loader = null;
  listData = null;
  search_payload = {};
  per_page = LocalGridConfig.options.paginationPageSize;

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('library_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };
  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_library_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  onGridChangedSearch = (params) => {
    localStorage.setItem('search_library_grid', JSON.stringify(params.columnApi.getColumnState()));
  };

  getList = (payload) => {
    if (this.loader) {
      return false;
    } else {
      this.loader = true;
    }

    let merged = { ...payload, ...this.search_payload };

    var temp = JSON.parse(localStorage.getItem('params'));
    
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.LibrarySearchStore?.sort;
    }
    this.list_data = null;

    return Axios.post('/deal-desk/leads-sss-search/list', merged)
      .then(({ data }) => {
        this.loader = false;
        this.listData = data?.data ? data?.data : [];
        if (this.listData?.length === 0) {
          this.agGrid.api.showNoRowsOverlay();
        }

        this.agGrid.api.setSortModel(sort ? sort : temp?.LibrarySearchStore?.sort);
        
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        this.loader = false;
        return Promise.reject(data);
      });
  };

  setSearch_payload = (data) => {
    this.search_payload = data;
  };
}

decorate(LibrarySearchStore, {
  agGrid: observable,
  loader: observable,
  search_payload: observable,
  listData: observable,
  per_page: observable,

  setupGrid: action,
  getList: action,
  setSearch_payload: action,
  setPageSize: action,
  setupGridSearch: action,
  onGridChangedSearch: action,
});
