import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Form, Modal, Row, Col, Checkbox, Button, Spin } from 'antd';
import useStore from '../../../store';
import { vsmNotify, vsmUsergroup } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const EditPermissionComponent = observer((props) => {
  const { t } = useTranslation()
  const [permissions, setPermissions] = useState([]);
  const [saving, setSaving] = useState();
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const {
    USERGROUP,
    USERGROUP: { permissionValues, getPermissions },
  } = useStore();
  // check for valid form values then accordingly make save button disable / enable & set value of user
  const handleChange = async (value) => { };

  // reset form and close edit form
  const close = () => {
    form.resetFields();
    props.close();
  };

  // make a fuction to call to edit record
  const handleSubmit = (data) => {
    var actions = {};
    if (data.permissions) {
      data.permissions.map((item) => {
        if (item.submenu) {
          item.submenu.map((action) => {
            action.permissions.map((action1) => {
              if (action1.is_check) {
                if (!actions[action.id]) {
                  actions[action.id] = [];
                }
                actions[action.id].push(action1.id);
              }
              return null;
            });
            return null;
          });
        } else {
          item.permissions.map((action) => {
            if (action.is_check) {
              if (!actions[item.id]) {
                actions[item.id] = [];
              }
              actions[item.id].push(action.id);
            }
            return null;
          });
        }

        return null;
      });
      data.permissions = actions;
    } else {
      data.permissions = null;
    }
    data.user_group_id = permissionValues.id;

    USERGROUP.modifyPermissions(data)
      .then(() => {
        props.close();
        vsmNotify.success({
          message: vsmUsergroup.permissionSuccess,
        });
        form.resetFields();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  //set the form values to edit
  useEffect(() => {
    if (USERGROUP && props.visible) {
      let payload = {
        user_group_id: permissionValues.id,
      };
      USERGROUP.getPermissions(payload).then((data) => {
        form.setFieldsValue({
          permissions: data.data,
        });
        setPermissions(data.data);
        setLoading(false);
      });
    }
  }, [USERGROUP, getPermissions, permissionValues, form, props]);

  return (
    <>
      <Modal
        //centered
        style={{ top: '2vh' }}
        className='permissionPopup'
        title={`${t('Common_Data.Edit')} ${t('Common_Data.User')} ${t('Common_Data.Group')} ${t('UserGroups.Permissions')} - ${permissionValues?.group_name}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='editpermissionform'
            shape='round'
            htmlType='submit'
            type='primary'
            loading={saving}
          >
            {t('Common_Data.Update')}
          </Button>,
          <Button key='2' form='editpermissionform' shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
        width={1200}
      >
        <Spin size='medium' spinning={loading} tip={`${t('Common_Data.Load')}`}>
          {!loading && (
            <Form
              form={form}
              id='editpermissionform'
              onFinish={handleSubmit}
              labelCol={{ span: 8 }}
              className='innerForm rowHeader'
              onChange={handleChange}
            >
              <Form.List name='permissions'>
                {(fields) => {
                  return (
                    fields &&
                    fields.map((field) => {
                      var fieldvalue = form.getFieldValue('permissions'); //[field.key];
                      var value = fieldvalue[field.key];

                      var isDisabled = false;
                      value.permissions.map((action, index) => {
                        if (action.action_name === 'list' && action.is_check !== true) {
                          isDisabled = true;
                        }
                        return null;
                      });

                      var all_checked = permissions.length;
                      value.permissions.map((action, index) => {
                        if (action.is_check) {
                          all_checked++;
                        }
                        return null;
                      });

                      return (
                        <div key={field.key} className='evenRow'>
                          {value.permissions && value.permissions.length > 0 && (
                            <Row gutter={15}>
                              <Col className='border-right' md={{ span: 4 }}>
                                <Checkbox
                                  checked={
                                    all_checked === true ? value.is_check : value.is_check
                                  }
                                  onChange={(e) => {
                                    value.is_check = !value.is_check;

                                    value.permissions.forEach((element) => {
                                      element.is_check = value.is_check;
                                    });
                                    setPermissions(form.getFieldValue('permissions'));
                                    form.setFieldsValue({
                                      permissions: form.getFieldValue('permissions'),
                                    });
                                  }}
                                >
                                  {value.title}
                                </Checkbox>
                              </Col>
                              <Col className='' md={{ span: 20 }}>
                                <Row gutter={15}>
                                  {value.permissions.map((action, index) => {
                                    return (
                                      <Col md={{ span: 6 }}>
                                        <div key={index}>
                                          <Form.Item
                                            noStyle
                                            name={[
                                              field.name,
                                              'permissions',
                                              index,
                                              'is_check',
                                            ]}
                                          >
                                            <Checkbox
                                              disabled={
                                                isDisabled &&
                                                action.action_name !== 'list'
                                              }
                                              checked={action.is_check}
                                              onChange={(e) => {
                                                action.is_check = !action.is_check;

                                                var totalcheck = 0;
                                                value.permissions.forEach((element) => {
                                                  /*START: IF List Unchecked - Un Check All Permission*/
                                                  if (
                                                    action.action_name === 'list' ||
                                                    action.action_name === 'List'
                                                  ) {
                                                    if (action.is_check) {
                                                      element.disabled = false;
                                                    } else {
                                                      element.is_check = false;
                                                      element.disabled = true;
                                                    }
                                                  }
                                                  /*END:IF List Unchecked - Un Check All Permission*/

                                                  if (!element.is_check) {
                                                    value.is_check = false;
                                                  }
                                                  if (element.is_check) {
                                                    totalcheck++;
                                                    if (
                                                      value.permissions.length ===
                                                      totalcheck
                                                    ) {
                                                      value.is_check = true;
                                                    }
                                                  }
                                                });

                                                setPermissions(fieldvalue);

                                                form.setFieldsValue({
                                                  permissions: fieldvalue,
                                                });
                                              }}
                                            >
                                              {/* {action.action_name} */}
                                              {action.action_name
                                                .charAt(0)
                                                .toUpperCase() +
                                                action.action_name.slice(1)}
                                            </Checkbox>
                                          </Form.Item>
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                            </Row>
                          )}

                          {value.submenu && value.submenu.length > 0 && (
                            <>
                              <Row gutter={15}>
                                <Col className='border-right' md={{ span: 4 }}>
                                  <Checkbox
                                    checked={value.is_check}
                                    onChange={(e) => {
                                      value.is_check = !value.is_check;

                                      /*START: Update Submenu and actions true/false*/
                                      value.submenu.forEach((element) => {
                                        element.is_check = value.is_check;
                                        element.permissions.forEach((element1) => {
                                          element1.is_check = value.is_check;
                                        });
                                      });
                                      /*END: Update Submenu and actions true/false*/

                                      setPermissions(fieldvalue);

                                      form.setFieldsValue({
                                        permissions: fieldvalue,
                                      });
                                    }}
                                  >
                                    {value.title}
                                  </Checkbox>
                                </Col>
                              </Row>

                              {value.submenu.map((action, index) => {
                                return (
                                  <Row gutter={15}>
                                    <Col
                                      className='border-right subOptions'
                                      md={{ span: 4 }}
                                    >
                                      <Checkbox
                                        checked={action.is_check}
                                        onChange={(e) => {
                                          action.is_check = !action.is_check;

                                          action.permissions.forEach((element) => {
                                            element.is_check = action.is_check;
                                          });

                                          var totalsubcheck = 0;
                                          value.submenu.forEach((element) => {
                                            if (!element.is_check) {
                                              value.is_check = false;
                                            }
                                            if (element.is_check) {
                                              totalsubcheck++;
                                              if (
                                                value.submenu.length === totalsubcheck
                                              ) {
                                                value.is_check = true;
                                              }
                                            }
                                          });

                                          setPermissions(fieldvalue);

                                          form.setFieldsValue({
                                            permissions: fieldvalue,
                                          });
                                        }}
                                      >
                                        {/* {action.title} */}
                                        {action.title.charAt(0).toUpperCase() +
                                          action.title.slice(1)}
                                      </Checkbox>
                                    </Col>

                                    <Col className='' md={{ span: 20 }}>
                                      <Row gutter={15}>
                                        {action.permissions.map((action1, index1) => {
                                          return (
                                            <Col md={{ span: 6 }}>
                                              <div key={index}>
                                                <Form.Item
                                                  noStyle
                                                  name={[
                                                    field.name,
                                                    'permissions',
                                                    index,
                                                    'is_check',
                                                  ]}
                                                >
                                                  <Checkbox
                                                    disabled={
                                                      isDisabled &&
                                                      action1.action_name !== 'list'
                                                    }
                                                    checked={action1.is_check}
                                                    value={action1.id}
                                                    onChange={(e) => {
                                                      action1.is_check =
                                                        !action1.is_check;

                                                      if (!action1.is_check) {
                                                        action.is_check = false;
                                                        value.is_check = false;
                                                      }

                                                      /*START:If All submenu checked than - True Sub Menu*/
                                                      if (action1.is_check) {
                                                        var selected_sub_menu_count = 0;
                                                        action.permissions.forEach(
                                                          (element) => {
                                                            if (element.is_check) {
                                                              selected_sub_menu_count++;
                                                            }
                                                            if (
                                                              action.permissions
                                                                .length ===
                                                              selected_sub_menu_count
                                                            ) {
                                                              action.is_check = true;

                                                              var selected_total_submenu_count = 0;

                                                              /*START: Update Main Menu If All Submenu Selected*/
                                                              value.submenu.forEach(
                                                                (submenu) => {
                                                                  if (submenu.is_check) {
                                                                    selected_total_submenu_count++;
                                                                    if (
                                                                      value.submenu
                                                                        .length ===
                                                                      selected_total_submenu_count
                                                                    ) {
                                                                      value.is_check = true;
                                                                    }
                                                                  }
                                                                }
                                                              );
                                                              /*END: Update Main Menu If All Submenu Selected*/
                                                            }
                                                          }
                                                        );
                                                      }
                                                      /*END:If All submenu checked than - True Sub Menu*/

                                                      /*START: IF List Unchecked - Un Check All Permission*/
                                                      if (
                                                        action1.action_name === 'list' ||
                                                        action1.action_name === 'List'
                                                      ) {
                                                        action.permissions.forEach(
                                                          (element2) => {
                                                            if (action1.is_check) {
                                                              element2.disabled = false;
                                                            } else {
                                                              element2.is_check = false;
                                                              element2.disabled = true;
                                                            }
                                                          }
                                                        );
                                                      }
                                                      /*END:IF List Unchecked - Un Check All Permission*/

                                                      setPermissions(fieldvalue);

                                                      form.setFieldsValue({
                                                        permissions: fieldvalue,
                                                      });
                                                    }}
                                                  >
                                                    {/* {action1.action_name} */}
                                                    {action1.action_name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      action1.action_name.slice(1)}
                                                  </Checkbox>
                                                </Form.Item>
                                              </div>
                                            </Col>
                                          );
                                        })}
                                      </Row>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </>
                          )}
                        </div>
                      );
                    })
                  );
                }}
              </Form.List>
            </Form>
          )}
        </Spin>
      </Modal>
    </>
  );
});

export default EditPermissionComponent;
