import React from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const PublishComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const {
    AlgorithmsStore: { statusValues, setupGrid, agGrid, publish_list }
  } = useStore();

  // reset form and close publish form
  const close = () => {
    form.resetFields();
    props.close();
    setupGrid(agGrid);
  };

  return statusValues ? (
    <Modal
      centered
      title={`${t('DealBuilder.Error_Publish_Builder')} - #${statusValues.id} - ${statusValues.algorithm_name}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button key='1' htmlType='button' onClick={close}>
          {t('Common_Data.Ok')}
        </Button>
      ]}
      afterClose={() => {
        setupGrid(agGrid);
      }}
    >
      <Form form={form} id='publishform' labelCol={{ span: 6 }}>
        <>
          <Row>
            <Col span={4}>
              <WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} />
            </Col>
            <Col span={20}>
              <h3>
                {t('DealBuilder.Publish_Active')}
              </h3>
              <span>
                <b>{t('DealBuilder.Associate_Transcation_Fields')}:</b>
                <ul>
                  {publish_list && publish_list.tte_field.length > 0 ? (
                    publish_list.tte_field.map((item, index) => (
                      <li key={index}>{item.field_name}</li>
                    ))
                  ) : (
                    <li>{''}</li>
                  )}
                </ul>
              </span>
              <span>
                <b>{t('DealBuilder.Associate_Algorithms')}:</b>
                <ul>
                  {publish_list && publish_list.algorithm.length > 0 ? (
                    publish_list.algorithm.map((item, index) => (
                      <li key={index}>{item.algorithm_name}</li>
                    ))
                  ) : (
                    <li>{''}</li>
                  )}
                </ul>
              </span>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  ) : null;
});

export default PublishComponent;
