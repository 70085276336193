import React from 'react';
import { Button, Tooltip } from 'antd';
import useStore from '../../../../store';
//import { DateComparator } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

export const ActionRenderer = (props) => {
  const {t} = useTranslation()
  const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents;
  const { AUTH } = useStore();
  return (
    <div className='action-column'>
      {AUTH.checkPermission(16, 'role-mapping-edit') && (
        <Button
          title={`${t('Common_Data.Edit')} ${t('OrganisationManagement.Role')} ${t('Common_Data.Mapping')}`}
          type='text'
          onClick={() => {
            openEditModal(props.data);
          }}
        >
          <img alt='' className='menuicon' src={AUTH.GetThemedImage('Edit')} />
        </Button>
      )}
      {!AUTH.checkPermission(16, 'role-mapping-delete') ? null : props.data
        .association_count > 0 ? (
        <Tooltip
          placement='topRight'
          color={'red'}
          title={`${t('OrganisationManagement.Role_Mapping_Tooltip')}.`}
          
        >
          <Button type='text' disabled>
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
          </Button>
        </Tooltip>
      ) : (
        <Button
          title={`${t('Common_Data.Delete')} ${t('OrganisationManagement.Role')} ${t('Common_Data.Mapping')}`}
          type='text'
          onClick={() => {
            openDeleteModal(props.data);
          }}
        >
          <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
        </Button>
      )}
    </div>
  );
};

// export const gridOptions = {
//   columnDefs: [
//     {
//       headerName: '#',
//       valueGetter: function (params) { return params.node.rowIndex + 1; },
//       tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
//       cellClass: 'cellClass',
//       pinned: 'left',
//       filter: false,
//       sortable: false,
//       width: 60,
//     },
//     {
//       headerName: `${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Name')}`,
//       headerTooltip: `${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Name')}`,
//       field: 'entity_name',
//       tooltipValueGetter: (params) => params.data && params.data.entity_type === 'People'
//         ? params.data.people &&
//         params.data.people.first_name +
//         (params.data.people.last_name ? ' ' + params.data.people.last_name : '')
//         : params.data.organisation && params.data.organisation.organisation_name,
//       valueGetter: (params) =>
//         params.data && params.data.entity_type === 'People'
//           ? params.data.people &&
//           params.data.people.first_name +
//           (params.data.people.last_name ? ' ' + params.data.people.last_name : '')
//           : params.data.organisation && params.data.organisation.organisation_name,
//     },
//     {
//       headerName: `${t('OrganisationManagement.Role')}`,
//       headerTooltip: `${t('OrganisationManagement.Role')}`,
//       field: 'role_name',
//       tooltipValueGetter: (params) => params.data && params.data.entity_type === 'People'
//         ? params.data.people_role && params.data.people_role.role_name
//         : params.data.role && params.data.role.role_name,
//       valueGetter: (params) =>
//         params.data && params.data.entity_type === 'People'
//           ? params.data.people_role && params.data.people_role.role_name
//           : params.data.role && params.data.role.role_name,
//       filter: 'agSetColumnFilter',
//       filterParams: { defaultToNothingSelected: true },
//     },
//     {
//       headerName: `${t('OrganisationManagement.Related')} ${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Name')}`,
//       headerTooltip: `${t('OrganisationManagement.Related')} ${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Name')}`,
//       field: 'related_entity_name',
//       tooltipValueGetter: (params) => params.data && params.data.related_entity_type === 'People'
//         ? params.data.related_people &&
//         params.data.related_people.first_name +
//         (params.data.related_people.last_name
//           ? ' ' + params.data.related_people.last_name
//           : '')
//         : params.data.related_organisation &&
//         params.data.related_organisation.organisation_name,
//       valueGetter: (params) =>
//         params.data && params.data.related_entity_type === 'People'
//           ? params.data.related_people &&
//           params.data.related_people.first_name +
//           (params.data.related_people.last_name
//             ? ' ' + params.data.related_people.last_name
//             : '')
//           : params.data.related_organisation &&
//           params.data.related_organisation.organisation_name,
//     },
//     {
//       headerName: `${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`,
//       headerTooltip: `${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`,
//       field: 'related_role_name',
//       tooltipValueGetter: (params) => params.data && params.data.related_entity_type === 'People'
//         ? params.data.peoplemerge_role && params.data.peoplemerge_role.role_name
//         : params.data.merge_role && params.data.merge_role.role_name,
//       valueGetter: (params) =>
//         params.data && params.data.related_entity_type === 'People'
//           ? params.data.peoplemerge_role && params.data.peoplemerge_role.role_name
//           : params.data.merge_role && params.data.merge_role.role_name,
//       filter: 'agSetColumnFilter',
//       filterParams: { defaultToNothingSelected: true },
//     },
//     {
//       headerName: `${t('Common_Data.Tags')}`,
//       headerTooltip: `${t('Common_Data.Tags')}`,
//       field: 'tag_names',
//       tooltipValueGetter: (params) => params.data && params.data.tags_names
//         ? params.data.tags_names.map((item) => {
//           return item;
//         })
//         : '',
//       valueGetter: (params) =>
//         params.data && params.data.tags_names
//           ? params.data.tags_names.map((item) => {
//             return item;
//           })
//           : '',
//     },
//     {
//       headerName: `${t('Common_Data.Last_Updated_By')}`,
//       headerTooltip: `${t('Common_Data.Last_Updated_By')}`,
//       field: 'updated_by',
//       tooltipField: 'updated_by',
//       filter: 'agSetColumnFilter',

//       filterParams: { defaultToNothingSelected: true },
//     },
//     {
//       headerName: `${t('Common_Data.Last_Updated_On')}`,
//       headerTooltip: `${t('Common_Data.Last_Updated_On')}`,
//       field: 'updated_at',
//       tooltipField: 'updated_at',
//       filter: 'agDateColumnFilter',

//       filterParams: {
//         buttons: ['reset'],
//         inRangeInclusive: true,
//         comparator: DateComparator,
//       },
//     },
//     {
//       headerName: `#${t('Common_Data.ID')}`,
//       headerTooltip: `#${t('Common_Data.ID')}`,
//       field: 'id',
//       tooltipField: 'id',
//       filter: 'agNumberColumnFilter',

//       width: 120,
//     },
//     {
//       headerName: `${t('Common_Data.Actions')}`,
//       headerTooltip: `${t('Common_Data.Actions')}`,
//       field: 'actions',
//       tooltipField: 'actions',
//       cellClass: 'cellClass actionColumn',
//       type: 'actionColumn',
//       filter: false,
//       sortable: false,
//       pinned: 'right',

//       width: 120,
//     },
//   ],
// };
