import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
//import LocalGridConfig from '../../config/LocalGridConfig';

export default class SpanNoteStore {
  user_list_data = [];
  tags_list_data = [];
  ticket_list_data = [];
  lead_list_data = [];
  contacts_list_data = [];
  fetching = false;
  list_data = [];
  read_data = [];
  totalNotes = 0;
  usersSelected = [];
  unreadBlink = true;

  noteSyncTimestamp = null;
  unReadNote_count = 0;
  urgentNote_count = 0;
  criticalNote_count = 0;
  tagNote_count = 0;

  /**START:  NOTE - LIST LOAD MORE (TOP NOTIFICATION- TAB -RIGHT SECTION)*/
  is_unread_loadMore = false;
  unreadNoteList = [];
  unreadLoading = false;

  is_urgent_loadMore = false;
  urgentNoteList = [];
  urgentLoading = false;

  is_critical_loadMore = false;
  criticalNoteList = [];
  criticalLoading = false;

  is_tag_loadMore = false;
  tagNoteList = [];
  tagLoading = false;

  is_snapnote_loadMore = false;
  snapnoteLoading = false;
  /**END:  NOTE - LIST LOAD MORE (TOP NOTIFICATION- TAB -RIGHT SECTION)*/

  searchUsersAndGroup = (payload) => {
    this.user_list_data = null;
    this.tags_list_data = null;
    this.ticket_list_data = null;
    this.lead_list_data = null;
    this.contacts_list_data = null;
    //if (payload?.search_text?.length >= 1) {
    this.fetching = true;
    return Axios.post(`snapnote/user-group-search`, payload)
      .then(({ data }) => {
        this.user_list_data = data.data;
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.resolve({ data: null });
      })
      .finally(() => {
        this.fetching = false;
      });
    // } else {
    //   return Promise.resolve({ data: null });
    // }
  };
  searchTags = (payload) => {
    this.user_list_data = null;
    this.tags_list_data = null;
    this.ticket_list_data = null;
    this.lead_list_data = null;
    this.contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetching = true;
      return Axios.post(`snapnote/tag-search`, payload)
        .then(({ data }) => {
          this.tags_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };
  searchTicket = (payload) => {
    this.user_list_data = null;
    this.tags_list_data = null;
    this.ticket_list_data = null;
    this.lead_list_data = null;
    this.contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetching = true;
      return Axios.post(`snapnote/ticket-search`, payload)
        .then(({ data }) => {
          this.ticket_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };
  searchLead = (payload) => {
    this.user_list_data = null;
    this.tags_list_data = null;
    this.ticket_list_data = null;
    this.lead_list_data = null;
    this.contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetching = true;
      return Axios.post(`snapnote/lead-deal-oportunity-search`, payload)
        .then(({ data }) => {
          this.lead_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };
  searchLeadSOLDName = (payload) => {
    this.user_list_data = null;
    this.tags_list_data = null;
    this.ticket_list_data = null;
    this.lead_list_data = null;
    this.contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetching = true;
      return Axios.post(`snapnote/lead-search`, payload)
        .then(({ data }) => {
          this.lead_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  searchContacts = (payload) => {
    this.user_list_data = null;
    this.tags_list_data = null;
    this.ticket_list_data = null;
    this.lead_list_data = null;
    this.contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetching = true;
      return Axios.post(`snapnote/contacts-organisations-search`, payload)
        .then(({ data }) => {
          this.contacts_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  searchPersonContacts = (payload) => {
    this.user_list_data = null;
    this.tags_list_data = null;
    this.ticket_list_data = null;
    this.lead_list_data = null;
    this.contacts_list_data = null;
    if (payload?.search_text?.length >= 1) {
      this.fetching = true;
      return Axios.post(`snapnote/contacts-people-search`, payload)
        .then(({ data }) => {
          this.contacts_list_data = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  getList_15122021 = () => {
    let token = localStorage.getItem('token');
    if (token && token !== 'undefined') {
      return Axios.post('snapnote/list').then(({ data }) => {
        let oldNotesCount = this.totalNotes;
        this.list_data = data;
        this.totalNotes = data.total;

        //Code to blink new latest unread note
        if (this.totalNotes > oldNotesCount) {
          this.unreadBlink = true;
        }
        return data;
      });
    }
    return null;
  };

  readReminder = (payload = {}) => {
    return Axios.post('snapnote/read', payload).then(({ data }) => {
      this.read_data = data;
      //this.getList();

      if (this.totalNotes > 0) {
        this.totalNotes = this.totalNotes - 1;
      }
      if (this.list_data && this.list_data.data && this.list_data.data.length > 0) {
        let restulFilterSnapnoteList =
          this.list_data.data &&
          this.list_data.data.filter((x) => x.id !== payload.id[0]);
        this.list_data = {
          data: restulFilterSnapnoteList,
        };
      }
      return data;
    });
  };

  addSnapNote = (formdata) => {
    return Axios.post(`snapnote/add`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        this.getList();
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  setTotalNotes = (data) => {
    this.totalNotes = data;
  };

  setUsersSelected = (names) => {
    this.usersSelected = names;
  };
  setUnreadBlink = (data) => {
    this.unreadBlink = data;
  };

  getList = (isNoteTypeOnPaging = 0) => {
    let token = localStorage.getItem('token');
    if (token && token !== 'undefined') {
      let payload = {
        waste: false,
        filter_data: {},
        type: 0,
        sort_data: [],
        per_page: 25,
        page_skip: 0,
        sync: this.noteSyncTimestamp,
      };

      if (isNoteTypeOnPaging) {
        let page_skip = 0;
        if (isNoteTypeOnPaging === 1) {
          //Snapnote List
          page_skip = this.list_data.data ? this.list_data.data.length : 0;
          this.snapnoteLoading = true;
        }
        if (isNoteTypeOnPaging === 2) {
          //Unread Note List
          page_skip = this.unreadNoteList ? this.unreadNoteList.length : 0;
          this.unreadLoading = true;
        }
        if (isNoteTypeOnPaging === 3) {
          // Urgent List
          page_skip = this.urgentNoteList ? this.urgentNoteList.length : 0;
          this.urgentLoading = true;
        }
        if (isNoteTypeOnPaging === 4) {
          //Critical Note List
          page_skip = this.criticalNoteList ? this.criticalNoteList.length : 0;

          this.criticalLoading = true;
        }
        if (isNoteTypeOnPaging === 5) {
          //Tag Note List
          page_skip = this.tagNoteList ? this.tagNoteList.length : 0;
          this.tagLoading = true;
        }
        //UnRead Note List
        payload = {
          waste: false,
          filter_data: {},
          type: isNoteTypeOnPaging,
          sort_data: [],
          per_page: 25,
          page_skip: page_skip,
          sync: null,
        };
      }
      return Axios.post('snapnote/list-new', payload).then(({ data }) => {
        let oldNotesCount = this.totalNotes;
        //this.list_data = data.snapnote_list;
        //this.totalNotes = data.snapnote_list.total;

        /*START: Snapnote Note List  */
        if (this.noteSyncTimestamp) {
          if (data.snapnote_list.data && data.snapnote_list.data.length > 0) {
            let newArr = data.snapnote_list.data;
            let newListArr = this.list_data?.data;
            if (newListArr && newListArr.length > 0) {
              newListArr.forEach((x) => newArr.push(x));
              this.list_data.data = newArr;
            } else {
              if (newArr && newArr.length > 0) {
                this.list_data = data.snapnote_list;
              }
            }
          }
        } else {
          if (isNoteTypeOnPaging) {
            if (data.snapnote_list.data && data.snapnote_list.data.length > 0) {
              let newArr = data.snapnote_list?.data;
              let newListArr = this.list_data.data;
              newArr.forEach((x) => newListArr.push(x));
              this.list_data.data = newListArr;
            }
          } else {
            this.list_data = data.snapnote_list;
          }
        }

        if (data.snapnote_count > (this.list_data ? this.list_data.data.length : 0)) {
          this.is_snapnote_loadMore = true;
        } else {
          this.is_snapnote_loadMore = false;
        }
        this.totalNotes = data.snapnote_count;
        /*END: Snapnote Note List  */

        //Code to blink new latest unread note
        if (this.totalNotes > oldNotesCount) {
          this.unreadBlink = true;
        }

        /*START: Update Top Notification Count*/
        this.unReadNote_count = data.unread_count;
        this.urgentNote_count = data.urgent_count;
        this.criticalNote_count = data.critical_count;
        this.tagNote_count = data.tag_count;
        /*START: Update Top Notification Count*/

        /*START: UnRead New Note List  */
        if (this.noteSyncTimestamp) {
          if (data.unread_list.data && data.unread_list.data.length > 0) {
            let newArr = data.unread_list.data;
            let newListArr = this.unreadNoteList;

            if (newListArr && newListArr.length > 0) {
              newListArr.forEach((x) => newArr.push(x));
              this.unreadNoteList = newArr;
            } else {
              if (newArr && newArr.length > 0) {
                this.unreadNoteList = newArr;
              }
            }
          }
        } else {
          if (isNoteTypeOnPaging) {
            if (data.unread_list.data && data.unread_list.data.length > 0) {
              let newArr = data.unread_list.data;
              let newListArr = this.unreadNoteList;
              newArr.forEach((x) => newListArr.push(x));
              this.unreadNoteList = newListArr;
            }
          } else {
            this.unreadNoteList = data.unread_list.data;
          }
        }

        if (data.unread_count > (this.unreadNoteList ? this.unreadNoteList.length : 0)) {
          this.is_unread_loadMore = true;
        } else {
          this.is_unread_loadMore = false;
        }
        /*END: UnRead New Note List  */

        /*START: Urgent Note List  */
        if (this.noteSyncTimestamp) {
          if (data.urgent_list.data && data.urgent_list.data.length > 0) {
            let newArr = data.urgent_list.data;
            let newListArr = this.urgentNoteList;

            if (newListArr && newListArr.length > 0) {
              newListArr.forEach((x) => newArr.push(x));
              this.urgentNoteList = newArr;
            } else {
              if (newArr && newArr.length > 0) {
                this.urgentNoteList = newArr;
              }
            }
          }
        } else {
          if (isNoteTypeOnPaging) {
            if (data.urgent_list.data && data.urgent_list.data.length > 0) {
              let newArr = data.urgent_list.data;
              let newListArr = this.urgentNoteList;
              newArr.forEach((x) => newListArr.push(x));
              this.urgentNoteList = newListArr;
            }
          } else {
            this.urgentNoteList = data.urgent_list.data;
          }
        }

        if (data.urgent_count > (this.urgentNoteList ? this.urgentNoteList.length : 0)) {
          this.is_urgent_loadMore = true;
        } else {
          this.is_urgent_loadMore = false;
        }
        /*END: Urgent New Note List  */

        /*START: Critical Note List  */
        if (this.noteSyncTimestamp) {
          if (data.critical_list.data && data.critical_list.data.length > 0) {
            let newArr = data.critical_list.data;
            let newListArr = this.criticalNoteList;

            if (newListArr && newListArr.length > 0) {
              newListArr.forEach((x) => newArr.push(x));
              this.criticalNoteList = newArr;
            } else {
              if (newArr && newArr.length > 0) {
                this.criticalNoteList = newArr;
              }
            }
          }
        } else {
          if (isNoteTypeOnPaging) {
            if (data.critical_list.data && data.critical_list.data.length > 0) {
              let newArr = data.critical_list.data;
              let newListArr = this.criticalNoteList;
              newArr.forEach((x) => newListArr.push(x));
              this.criticalNoteList = newListArr;
            }
          } else {
            this.criticalNoteList = data.critical_list.data;
          }
        }

        if (data.critical_count > this.criticalNoteList.length) {
          this.is_critical_loadMore = true;
        } else {
          this.is_critical_loadMore = false;
        }
        /*END: Critical New Note List  */

        /*START: Tag Note List  */
        if (this.noteSyncTimestamp) {
          if (data.tag_list.data && data.tag_list.data.length > 0) {
            let newArr = data.tag_list.data;
            let newListArr = this.tagNoteList;

            if (newListArr && newListArr.length > 0) {
              newListArr.forEach((x) => newArr.push(x));
              this.tagNoteList = newArr;
            } else {
              if (newArr && newArr.length > 0) {
                this.tagNoteList = newArr;
              }
            }
          }
        } else {
          if (isNoteTypeOnPaging) {
            if (data.tag_list.data && data.tag_list.data.length > 0) {
              let newArr = data.tag_list.data;
              let newListArr = this.tagNoteList;

              newArr.forEach((x) => newListArr.push(x));
              this.tagNoteList = newListArr;
            }
          } else {
            this.tagNoteList = data.tag_list.data;
          }
        }

        if (data.tag_count > (this.tagNoteList ? this.tagNoteList.length : 0)) {
          this.is_tag_loadMore = true;
        } else {
          this.is_tag_loadMore = false;
        }
        /*END: Critical New Note List  */

        this.noteSyncTimestamp = data.sync;

        if (isNoteTypeOnPaging) {
          this.unreadLoading = false;
          this.urgentLoading = false;
          this.criticalLoading = false;
          this.tagLoading = false;
          this.snapnoteLoading = false;
        }

        return data;
      });
    }
    return null;
  };

  /**Used To Close Reminder - and Remove Item From Arr */
  readCommonReminder = (payload, type = 0) => {
    return Axios.post('read-reminder', payload).then(({ data }) => {
      if (type === 2) {
        //Unread Reminder
        if (this.unReadNote_count > 0) {
          this.unReadNote_count = this.unReadNote_count - 1;
        }
        if (this.unreadNoteList && this.unreadNoteList.length > 0) {
          let resultNewReminderList = this.unreadNoteList.filter(
            (x) => x.id !== payload.reminder_id[0]
          );
          this.unreadNoteList = resultNewReminderList;
        }
      }
      if (type === 3) {
        //Unread Reminder
        if (this.urgentNote_count > 0) {
          this.urgentNote_count = this.urgentNote_count - 1;
        }
        if (this.urgentNoteList && this.urgentNoteList.length > 0) {
          let resultNewReminderList = this.urgentNoteList.filter(
            (x) => x.id !== payload.reminder_id[0]
          );
          this.urgentNoteList = resultNewReminderList;
        }
      }
      if (type === 4) {
        //Unread Reminder
        if (this.criticalNote_count > 0) {
          this.criticalNote_count = this.criticalNote_count - 1;
        }
        if (this.criticalNoteList && this.criticalNoteList.length > 0) {
          let resultNewReminderList = this.criticalNoteList.filter(
            (x) => x.id !== payload.reminder_id[0]
          );
          this.criticalNoteList = resultNewReminderList;
        }
      }
      if (type === 5) {
        //Tag
        if (this.tagNote_count > 0) {
          this.tagNote_count = this.tagNote_count - 1;
        }
        if (this.tagNoteList && this.tagNoteList.length > 0) {
          let resultNewReminderList = this.tagNoteList.filter(
            (x) => x.id !== payload.id[0]
          );
          this.tagNoteList = resultNewReminderList;
        }
      }
      return data;
    });
  };
}

decorate(SpanNoteStore, {
  user_list_data: observable,
  tags_list_data: observable,
  ticket_list_data: observable,
  lead_list_data: observable,
  contacts_list_data: observable,
  fetching: observable,
  list_data: observable,
  read_data: observable,
  totalNotes: observable,
  usersSelected: observable,
  unreadBlink: observable,

  unReadNote_count: observable,
  urgentNote_count: observable,
  criticalNote_count: observable,
  tagNote_count: observable,
  noteSyncTimestamp: observable,
  is_unread_loadMore: observable,
  unreadNoteList: observable,
  is_urgent_loadMore: observable,
  urgentNoteList: observable,
  is_critical_loadMore: observable,
  criticalNoteList: observable,
  is_tag_loadMore: observable,
  tagNoteList: observable,
  unreadLoading: observable,
  urgentLoading: observable,
  criticalLoading: observable,
  tagLoading: observable,
  snapnoteLoading: observable,
  is_snapnote_loadMore: observable,

  searchUsersAndGroup: action,
  searchTags: action,
  searchTicket: action,
  getList: action,
  readReminder: action,
  addSnapNote: action,
  setTotalNotes: action,
  setUsersSelected: action,
  setUnreadBlink: action,
  readCommonReminder: action,
  searchLeadSOLDName: action,
});
