import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form } from 'antd';
import debounce from 'lodash/debounce';
import moment from 'moment';

import FormComponent from './FormComponent';
import useStore from '../../../store';
import { vsmNotify, vsmTags } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const CloneComponent = observer((props) => {
  const { t } = useTranslation()
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [content, setContent] = useState(null);

  const [form] = Form.useForm();

  const {
    AUTH,
    TagsStore: { cloneValues, addTag },
  } = useStore();

  const close = () => {
    props.close();
    setDisabled(true);
    setSaving(false);
    setContent(null);
  };

  const handleSubmit = (data) => {
    data.deadline_date = moment(data.deadline_date).format('YYYY-MM-DD');
    setSaving(true);
    addTag(data)
      .then(() => {
        close();
        form.resetFields();
        AUTH.fetchLeftmenuCount(); //to update left menu count
        vsmNotify.success({
          message: vsmTags.add,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (cloneValues) {
      form.setFieldsValue({
        tag_subject: cloneValues?.tag_subject,
        tagsnumber: cloneValues?.id,
        organisation_id: cloneValues?.global_organisation_id
          ? cloneValues?.global_organisation_id
          : null,
        holder: cloneValues?.holder,
        tag_status: cloneValues?.tag_status_id,
        tag_content: cloneValues?.tag_content,
        parent_ticket_id: cloneValues.parent_ticket_id
          ? cloneValues.parent_ticket_id
          : null,
        deadline_date:
          cloneValues?.deadline_date && moment(cloneValues.deadline_date, 'YYYY-MM-DD'),
      });
      setContent(cloneValues?.tag_content);
    }
  }, [cloneValues, form]);

  return (
    <>
      <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        title={`${t('Common_Data.Clone')} ${t('Tags.Tag')} ${cloneValues?.id ? ' - #' + cloneValues?.id : ''}`}
        destroyOnClose={true}
        footer={[
          <div className='text-right' key='1'>
            <Button
              key='1'
              form='tagcloneform'
              className='mr-10'
              loading={saving}
              htmlType='submit'
              type='primary'
              shape='round'
              disabled={isDisabled}
            >
              {t('Common_Data.Clone')}
            </Button>
            <Button shape='round' onClick={close} key='2'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>,
        ]}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='tagcloneform'
          content={content}
        />
      </Drawer>
    </>
  );
});

export default CloneComponent;
