import React from 'react';
import { observer } from 'mobx-react';
import Algorithms from './Algorithms';
//import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';
const DealBuilder = observer((props) => {
  return (
    <>
      <Algorithms />
      {/* <TrackTimeComponent pageName={'deal_builder'} /> */}
      <TimeTracker />
    </>
  );
});
export default DealBuilder;
