import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';
import moment from 'moment';

export default class TransactionFieldStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;

  editValues = null;
  deleteValues = null;
  unpublishValues = null;
  agGrid = null;

  dropdown_list = null;
  dropdown_type_list = null;
  dropdown_algorithm_list = null;
  relation_list = null;

  exportLoading = false;
  listLoading = false;

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    if (data.association_count > 0) {
      this.getAllRecord();
    }
    this.deleteValues = data;
  };

  // set form values to edit
  setUnpublishValues = (data) => {
    this.unpublishValues = data;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(page);
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('transaction_field_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'transaction_field_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.TRANSACTIONFIELD) {
        params = { request: temp.TRANSACTIONFIELD };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          TRANSACTIONFIELD: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          TRANSACTIONFIELD: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    this.setFilter(params.api);
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // export data as a csv
  exportData = async () => {
    this.exportLoading = true;
    var params = {
      columnKeys: [
        'id',
        'field_name',
        'field_type',
        'algorithm_name',
        'default_value',
        'description',
        'updated_by',
        'updated_at',
      ],
      fileName: '1 - Transaction field-export-' + moment().format('YYYY-MM-DD hh:mm:s'),
    };
    await this.agGrid.api.paginationSetPageSize(this.total);
    await this.agGrid.api.exportDataAsCsv(params);
    await this.agGrid.api.paginationSetPageSize(this.per_page);
    this.exportLoading = false;
  };

  // call api to get all field types list
  getAllFieldType = () => {
    Axios.get(`getfieldtype`).then(({ data }) => {
      this.dropdown_type_list = [
        ...data.data,
        'Currency',
        'Toggle (Months/Days)',
        'Toggle (Convertibill®/Trade Credebt®)',
      ];
    });
  };

  // call api to get all types list
  getAllRecord = () => {
    return Axios.get(`gettransactionfieldlist`).then(({ data }) => {
      this.dropdown_list = data.data;
    });
  };

  // call Transaction field Relation api
  getAllRelations = (formdata) => {
    var payload = { field_id: formdata.field_id };
    Axios.post(`transactionfield/getallrelations/`, payload).then(({ data }) => {
      this.relation_list = data.data;
    });
  };

  // call api to get algorithm list
  getAllFieldAlgorithm = () => {
    Axios.get(`algorithm/getAlgorithmlist`).then(({ data }) => {
      this.dropdown_algorithm_list = data.data;
    });
  };

  autoSizeColumns = () => {
    var allColumnIds = [];
    if (this.agGrid && this.agGrid.columnApi) {
      this.agGrid.columnApi.getAllColumns().forEach(function (column) {
        if (!['actions'].includes(column.colId)) {
          allColumnIds.push(column.colId);
        }
      });
      this.agGrid.columnApi.autoSizeColumns(allColumnIds);
    }
  };

  // call api to get records
  getList = () => {
    this.listLoading = true;
    this.list_data = null;
    return Axios.get(`transactionfield/list`).then(({ data }) => {
      this.listLoading = false;
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.association_count = item.get_transactions_field_values_count || 0;
          item.updated_by = item.users
            ? item.users.first_name + ' ' + item.users.last_name
            : null;
          item.srno = index + 1;
          item.algorithm_name = item.get_algorithm_name
            ? item.get_algorithm_name.algorithm_name
            : null;
          item.published = item.status ? 'Yes' : 'No';
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;

      let columnConfig = localStorage.getItem('transaction_field_grid');
      if (this.agGrid && this.agGrid.columnApi && columnConfig) {
        this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
      } else {
        if (this.agGrid && this.agGrid.columnApi && data.total) {
          this.autoSizeColumns();
        }
      }
      if (this.agGrid && this.agGrid.columnApi) {
        let param = this.getFilter(this.agGrid);
        if (param && param.request) {
          this.agGrid.api.setFilterModel(param.request.filter);
          this.agGrid.api.setSortModel(this.agGrid.columnApi.getColumnState());
        }
      }
    });
  };

  // Call add api to insert new record
  AddData = (formdata) => {
    return Axios.post(`transactionfield/add`, formdata)
      .then(({ data }) => {
        this.getList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call edit api
  EditData = (formdata) => {
    return Axios.post(`transactionfield/edit/${formdata.id}`, formdata)
      .then(({ data }) => {
        this.getList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call delete api
  DeleteData = (formdata) => {
    return Axios.post(`transactionfield/delete/${formdata.id}`, formdata)
      .then(({ data }) => {
        this.getList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call unpublish/publish api
  TogglepublishData = (formdata) => {
    return Axios.post(`transactionfield/changestatus/${formdata.id}`, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data.errors);
      });
  };
  // reset function
  handleReset = () => {
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.setSortModel(null);
    this.agGrid.api.onFilterChanged(null);
  };
}

decorate(TransactionFieldStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  editValues: observable,
  deleteValues: observable,
  unpublishValues: observable,
  dropdown_list: observable,
  relation_list: observable,
  agGrid: observable,
  dropdown_type_list: observable,
  dropdown_algorithm_list: observable,
  listLoading: observable,
  getList: action,
  EditData: action,
  setEditValues: action,
  setDeleteValues: action,
  setUnpublishValues: action,
  DeleteData: action,
  setupGrid: action,
  onFilterChanged: action,
  setPageSize: action,
  TogglepublishData: action,
  getAllFieldType: action,
  getAllFieldAlgorithm: action,
  getAllRecord: action,
  getAllRelations: action,
  onGridChanged: action,
  autoSizeColumns: action,
  handleReset: action
});
