import { observer } from 'mobx-react';
import React from 'react';
import { List } from 'antd';
//import { Link } from 'react-router-dom';
import useStore from '../../../store';
/*function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}*/
/*function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}*/

const ListComponent = observer((props) => {
  const {
    AUTH,
    DEALDESKSTORE: { deal_data, downloadFileS3 },
  } = useStore();

  //const { height } = useWindowDimensions();

  const downloadFile = (filedata) => {
    let payload = {
      org_id: deal_data?.lead_information?.sold_organisation?.id,
      leadid: deal_data.lead_information?.id,
      file: filedata.entity_name,
      fileID: filedata.id,
    };

    downloadFileS3(payload)
      .then((data) => {
        let anchor = document.createElement('a');
        anchor.href = data;
        anchor.download = filedata.entity_name;
        anchor.target = '_blank';
        anchor.click();
      })
      .catch((e) => { })
      .finally(() => { });
  };

  return (
    AUTH.app_color_theme && (
      <>
        {props.leadFileFolderList && (
          <>
            <List size='small' className='right-list'>
              {props.leadFileFolderList.map((item, index) => {
                return index <= 2 ? (
                  <List.Item onClick={() => downloadFile(item)}>
                    <img src={AUTH.GetThemedImage('Files_icn')} alt='' />
                    {item.entity_name}
                    {/* <a href={item.full_path} target='_blank' rel='noopener noreferrer'>
                      <img src={AUTH.GetThemedImage('Files_icn')} alt='' />
                      {item.entity_name}
                    </a> */}
                  </List.Item>
                ) : null;
              })}
              {/* <List.Item>
                <Link to='#nogo'>
                  <img src={AUTH.GetThemedImage('Folders_icn')} alt='' />
                  Advantage Tankers
                </Link>
              </List.Item>
              <List.Item>
                <Link to='#nogo'>
                  <img src={AUTH.GetThemedImage('Folders_icn')} alt='' />
                  18.2 POD-Cetuer adipis...
                </Link>
              </List.Item>
              <List.Item>
                <Link to='#nogo'>
                  <img src={AUTH.GetThemedImage('Folders_icn')} alt='' />
                  0.4.1 RPV-Consectetuer...
                </Link>
              </List.Item> */}
            </List>
          </>
        )}
      </>
    )
  );
});

export default ListComponent;
