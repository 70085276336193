import React, { useEffect,useState } from 'react';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';
import useStore from '../../store';
import People from './component/People';
import Organisations from './component/Organisations';
import Leads from './component/Leads';
import Tags from './component/Tags';
import Tickets from './component/Tickets';
// import Notes from './component/Notes';
//import TrackTimeComponent from '../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../component/TimeTracker';
import History from '../History/component/ListComponentWasteManagement';

const Waste = observer(() => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const [stopTimer, setStopTimer] = useState(false);
  const { AUTH, LEADSSTORE, WASTESTORE, TicketsStore, TagsStore, PEOPLE, ORGANISATION, TimeTrackerStore } =
    useStore(); 

  const PeopleTitle = `${t('Common_Data.People')} (${
    WASTESTORE.wasteCount
      ? WASTESTORE.wasteCount?.people_waste_count.toLocaleString('en-GB')
      : 0
  })`;
  const OrganisationsTitle = `${t('Common_Data.Organisations')} (${
    WASTESTORE.wasteCount
      ? WASTESTORE.wasteCount?.organisation_waste_count.toLocaleString('en-GB')
      : 0
  })`;
  const LeadsTitle = `${t('DashBoard.Leads')} (${
    WASTESTORE.wasteCount
      ? WASTESTORE.wasteCount?.lead_waste_count.toLocaleString('en-GB')
      : 0
  })`;
  const OpportunitiesTitle = `${t('DashBoard.Opportunities')} (${
    WASTESTORE.wasteCount
      ? WASTESTORE.wasteCount?.opportunities_waste_count.toLocaleString('en-GB')
      : 0
  })`;
  const DealsTitle = `${t('DashBoard.Deals')} (${
    WASTESTORE.wasteCount
      ? WASTESTORE.wasteCount?.deals_waste_count.toLocaleString('en-GB')
      : 0
  })`;
  const HistoryTitle = `${t('History.History')} (${
    WASTESTORE.wasteCount
      ? WASTESTORE.wasteCount?.note_waste_count.toLocaleString('en-GB')
      : 0
  })`;
  const TagsTitle = `${t('Common_Data.Tags')} (${
    WASTESTORE.wasteCount
      ? WASTESTORE.wasteCount?.tag_waste_count.toLocaleString('en-GB')
      : 0
  })`;
  const TicketsTitle = `${t('Common_Data.Tickets')} (${
    WASTESTORE.wasteCount
      ? WASTESTORE.wasteCount?.ticket_waste_count.toLocaleString('en-GB')
      : 0
  })`;
  const WasteManagement = true;

  const handleChange = (key) => {
    /**Used To Reset Filter  */
    if (key === '1') {
      PEOPLE.resetGridFilterForWaste();
    }
    if (key === '2') {
      ORGANISATION.resetGridFilterForWaste();
    }
    if (key === '7') {
      TagsStore.resetGridFilterForWaste();
    }
    if (key === '8') {
      TicketsStore.resetGridFilterForWaste();
    }
    if (key === '3' || key === '4' || key === '5') {
      LEADSSTORE.resetGridFilterForWaste();
    }

    WASTESTORE.activeTab = key;

    /**Used To Reset Filter  */

    if (key === '3') {
      LEADSSTORE.current_list_path = null;
    }
    if (key === '4') {
      LEADSSTORE.current_list_path = '/leads/opportunities';
    }
    if (key === '5') {
      LEADSSTORE.current_list_path = '/leads/deals';
    }
    if (key === '6') {
      LEADSSTORE.current_list_path = '/leads/deals';
    }
  };

  useEffect(() => {
    WASTESTORE.getWasteCount();
    return () => {
      LEADSSTORE.current_list_path = null;
    };
  }, [LEADSSTORE, WASTESTORE]);

  useEffect(() => {
    if (TimeTrackerStore.stopForWaste) {
      setStopTimer(true);
    } else {
      setStopTimer(false);
    }
  }, [TimeTrackerStore.stopForWaste]);

  return (
    <>
      <Tabs
        defaultActiveKey={localStorage.getItem('waste_filter_note_id') ? '6' : '1'}
        className='tabs'
        onChange={(key) => handleChange(key)}
      >
        {AUTH.checkPermission(33, 'details') && (
          <TabPane key='1' tab={PeopleTitle}>
            <People WasteManagement={WasteManagement} />
          </TabPane>
        )}
        {AUTH.checkPermission(34, 'details') && (
          <TabPane key='2' tab={OrganisationsTitle}>
            <Organisations WasteManagement={WasteManagement} />
          </TabPane>
        )}
        {AUTH.checkPermission(35, 'details') && (
          <TabPane key='3' tab={LeadsTitle}>
            <Leads WasteManagement={WasteManagement} />
          </TabPane>
        )}
        {AUTH.checkPermission(36, 'details') && (
          <TabPane key='4' tab={OpportunitiesTitle} disabled={true}>
            <Leads WasteManagement={WasteManagement} />
          </TabPane>
        )}
        {AUTH.checkPermission(37, 'details') && (
          <TabPane key='5' tab={DealsTitle}>
            <Leads WasteManagement={WasteManagement} />
          </TabPane>
        )}
        {AUTH.checkPermission(38, 'details') && (
          <TabPane key='6' tab={HistoryTitle}>
            <History WasteManagement={WasteManagement} />
          </TabPane>
        )}
        {AUTH.checkPermission(52, 'details') && (
          <TabPane key='7' tab={TagsTitle}>
            <Tags WasteManagement={WasteManagement} />
          </TabPane>
        )}
        {AUTH.checkPermission(39, 'details') && (
          <TabPane key='8' tab={TicketsTitle}>
            <Tickets WasteManagement={WasteManagement} />
          </TabPane>
        )}
      </Tabs>
      {/* <TrackTimeComponent pageName={'waste'} /> */}
      <TimeTracker stop={stopTimer}/>
    </>
  );
});

export default Waste;
