import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class OrgBankAccountStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;

  editValues = null;
  currentValues = null;
  agGrid = null;

  dropdown_bank_type_list = null;
  dropdown_currency_list = null;
  dropdown_all_bank_list = null;
  bankNameList = null;

  // set form values to active / deactive bank account
  setCurrentValues = (data) => {
    this.currentValues = data;
  };

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set currency dropdown values to edit & view
  setCurrency = (data) => {
    this.dropdown_currency_list = data;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // call api to get all banks list
  getAllBanksList = (organisation_id) => {
    return Axios.post(
      `organisations/bankaccount/get/${organisation_id}`
    ).then(({ data }) => {
      this.dropdown_all_bank_list = data.data;
      return data;
    });
  };

  // call for bank name list for auto populate
  getBanksNameList = () => {
    return Axios.get(`global/banks/get`)
      .then(({ data }) => {
        var i;
        var arrayObj = data.data;
        for (i = 0; i < arrayObj.length; i++) {
          arrayObj[i].value = arrayObj[i]['name'];
          delete arrayObj[i].key1;
        }
        this.bankNameList = arrayObj;
        return data;
      })
      .catch(() => {
        this.bankNameList = null;
      });
  };

  // call for currency list
  getCurrencyList = () => {
    return Axios.get(`globalcurreny/list`)
      .then(({ data }) => {
        this.dropdown_currency_list = data.data;
        return data;
      })
      .catch(() => {
        this.dropdown_currency_list = null;
      });
  };

  // call for bank account types list
  getBankAccountTypeList = () => {
    return Axios.get(`bankaccount/bankAccountType`)
      .then(({ data }) => {
        this.dropdown_bank_type_list = data.data;
        return data;
      })
      .catch(() => {
        this.dropdown_bank_type_list = null;
      });
  };

  // call api to get records
  getList = (payload = {}) => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel(filter);
      var sort = this.agGrid.columnApi.getColumnState();
    }
    return Axios.post(`organisations/bankaccount/list`, payload).then(
      ({ data }) => {
        if (data.data.length) {
          data.data.forEach((item) => {
            item.published = item.status ? 'Active' : 'Deactive';
            item.bank_type =
              item.bank_account_type && item.bank_account_type.name
                ? item.bank_account_type.name
                : null;
          });
        }
        this.list_data = data.data;
        this.total = data.total;
        this.current_page = data.current_page;
        var allColumnIds = [];
        if (this.agGrid && this.agGrid.columnApi && data.total) {
          this.agGrid.columnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
          });
          //	this.agGrid.columnApi.autoSizeColumns(allColumnIds)
        }
        if (this.agGrid) {
          this.agGrid.api.setFilterModel(filter);
          this.agGrid.columnApi.applyColumnState({ state: sort });
        }
        return data;
      }
    );
  };

  // Call add api
  AddData = (formdata) => {
    return Axios.post(`organisations/bankaccount/add`, formdata)
      .then(({ data }) => {
        this.getList({ organisation_id: formdata.organisation_id });
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call edit api
  EditData = (formdata) => {
    return Axios.post(
      `organisations/bankaccount/edit/${formdata.id}`,
      formdata
    )
      .then(({ data }) => {
        this.getList({ organisation_id: formdata.organisation_id });
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Call activate / deactivate api
  ToggleData = (formdata, payload, params) => {
    return Axios.post(
      `organisations/bankaccount/archive/${formdata.id}`,
      payload
    )
      .then(({ data }) => {
        this.getList(params);
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
}

decorate(OrgBankAccountStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  editValues: observable,
  deleteValues: observable,
  dropdown_bank_type_list: observable,
  dropdown_currency_list: observable,
  dropdown_all_bank_list: observable,
  bankNameList: observable,
  agGrid: observable,
  getBankAccountTypeList: action,
  getAllBanksList: action,
  getCurrencyList: action,
  getBanksNameList: action,
  getList: action,
  EditData: action,
  setEditValues: action,
  setCurrentValues: action,
  ToggleData: action,
  setupGrid: action,
  onFilterChanged: action,
  setPageSize: action,
  setCurrency: action,
});
