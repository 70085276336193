import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../../store';
import { vsmNotify } from '../../../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { saveType } = props;
  const { SEARCHSTORE,
    SSSLEADLIBRARYSTORE,
    SSSLEADLIBRARYSTORE: {
      deleteSssData,
    },
  } = useStore();
  const [saving, setSaving] = useState(false);

  const close = (reload = false) => {
    props.close(reload);
    form.resetFields();
    setSaving(false);
  };

  const getTitleBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return `${t('DealDesk.Screen')}`;
    } else if (saveType === 'structure') {
      return `${t('Common_Data.Structure')}`;
    } else if (saveType === 'scrutinise') {
      return `${t('Common_Data.Scrutinise')}`;
    } else if (saveType === 'shortfall') {
      return `${t('Common_Data.Shortfall')}`;
    }
  }

  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);

    deleteSssData(props?.id)
      .then(() => {
        vsmNotify.success({
          message: `${getTitleBasedOnSaveType()} ${t('DealDesk.Remove_Successfully')}!`,
        });
        close(true);
        if (props.libData) {
          SSSLEADLIBRARYSTORE.agGridRef.current.api.applyTransaction({ remove: [props.libData] });
        }
        SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm }, 'library');
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return props ? (
    <Modal
      centered
      title={`${t('Common_Data.Delete')} ${getTitleBasedOnSaveType()} - #${props?.id}`}
      visible={props.visible}
      onCancel={() => close()}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form={`deleteformLibrary${saveType}`}
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
          shape='round'
          size='medium'
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' onClick={() => {
          close()
        }
        } shape='round' size='medium'>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id={`deleteformLibrary${saveType}`} onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col className='DeleteText'>
              {t('DealDesk.Are_You_Sure_To_Delete')}{' '}
              {getTitleBasedOnSaveType()}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
