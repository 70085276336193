import React from 'react';
import { Card } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const OrgDetailComponent = observer((props) => {
  const {t} = useTranslation()
  return (
    <Card size='small' className='mb-20'>
      <h3>
        <b>{props.data.organisation_name}</b>
      </h3>
      <div className='ant-table-content ant-table-small ant-table viewPeople'>
        <table>
          <tbody className='ant-table-tbody'>
            <tr>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
            </tr>
            <tr>
              <th>{t('OrganisationManagement.Trade')} {t('Common_Data.Name')}:</th>
              <td>{props.data.trade_name && props.data.trade_name}</td>
              <th>{t('OrganisationManagement.Registration')} {t('Common_Data.Country')}:</th>
              <td>
                {props.data.global_countries && props.data.global_countries.country_name}
              </td>
            </tr>
            <tr>
              <th>{t('Common_Data.Phone')}:</th>
              <td>
                {props.data.multiple_phone &&
                  props.data.multiple_phone.map((x) => x.contact_value).join(', ')}
              </td>
              <th>{t('Common_Data.Website')}:</th>
              <td>{props.data.website && props.data.website}</td>
            </tr>
            <tr>
              <th>{t('Common_Data.Address')}</th>
              <td colSpan='3'>
                {props.data.addresses && (
                  <div>
                    {props.data.addresses.address_1}
                    {props.data.addresses.address_2 &&
                      ', ' + props.data.addresses.address_2}
                    {props.data.addresses.address_3 &&
                      ', ' + props.data.addresses.address_3}
                    {props.data.addresses.city && ', ' + props.data.addresses.city}
                    {props.data.addresses.state_county &&
                      ', ' + props.data.addresses.state_county}
                    {props.data.addresses.countries &&
                      ', ' + props.data.addresses.countries.country_name}
                    {props.data.addresses.postal_code &&
                      ', ' + props.data.addresses.postal_code}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
  );
});

export default OrgDetailComponent;
