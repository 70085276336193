import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Space, Form, Button } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import useStore from '../../store';
import ListComponent from './component/ListComponent';
import EnterFullscreen from '../../component/EnterFullscreen';
import RecordPerPage from '../../config/RecordPerPage';
import ViewComponent from './component/ViewComponent';
import DeleteComponent from './component/DeleteComponent';
import EditComponent from './component/EditComponent';
import GlobalStatusComponent from './component/GlobalStatusComponent';
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';
import { useTranslation } from 'react-i18next';

const SavedFilter = observer((props) => {
  const { t } = useTranslation()
  const [viewDrawer, setViewDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [toogleFilterVisible, setToogleFilterVisible] = useState(false);
  const [toggleswichvalue, setToggleswichvalue] = useState(false);

  const {
    SETTINGS,
    SAVEDFILTERSTORE,
    SAVEDFILTERSTORE: {
      per_page,
      setPageSize,
      handleReset,
      setViewValues,
      setDeleteValues,
      setEditValues,
      setGlobalStatusTempData,
    },
  } = useStore();
  const [form] = Form.useForm();

  const openViewDrawer = (data) => {
    setViewValues(data);
    setViewDrawer(true);
  };
  const closeViewDrawer = () => {
    setViewValues(null);
    setViewDrawer(false);
  };

  //----------------- DELETE FILTER FUNCTIONS----------------//
  const openDeleteDrawer = (data) => {
    setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);
  //----------------- DELETE FILTER FUNCTIONS----------------//

  //----------------- EDIT FILTER FUNCTIONS----------------//
  const openEditDrawer = (data) => {
    setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditValues(null);
    setEditDrawer(false);
  };
  //----------------- EDIT FILTER FUNCTIONS----------------//

  // Handle on switch data
  const onSwitchChange = (checked, data) => {
    SAVEDFILTERSTORE.agGrid.api.refreshCells({ force: true });

    setGlobalStatusTempData(data);
    setToogleFilterVisible(true);
    setToggleswichvalue(checked);
  };

  // To Close Status Model Popup
  const closeToogleFilter = () => {
    setGlobalStatusTempData(null);
    setToogleFilterVisible(false);
    setToggleswichvalue(null);
  };

  return (
    <>
      <PageHeader
        title={`${t('Common_Data.Page')} ${t('Reports.Filters')}`}
        className={'page-title ' + SETTINGS.fullscreen_class}
        extra={[
          <Form form={form} className='innerForm headerButton'>
            <Space size={5}>
              <Button
                key='1'
                shape='round'
                size='medium'
                title={`${t('Common_Data.Reset')}`}
                onClick={handleReset}
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>
              <EnterFullscreen key='2' />
              <RecordPerPage
                key='3'
                initialValue={per_page + ' per page'}
                onChange={setPageSize}
              />
            </Space>
          </Form>,
        ]}
      >
        <ListComponent
          openViewDrawer={openViewDrawer}
          openDeleteDrawer={openDeleteDrawer}
          openEditDrawer={openEditDrawer}
          onSwitchChange={onSwitchChange}
        />
        <ViewComponent visible={viewDrawer} close={closeViewDrawer} />
        <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
        <EditComponent visible={editDrawer} close={closeEditDrawer} />
        <GlobalStatusComponent
          visible={toogleFilterVisible}
          close={closeToogleFilter}
          toggleswichvalue={toggleswichvalue}
        />
      </PageHeader>
      {/* <TrackTimeComponent pageName={'page_filter'} /> */}
      <TimeTracker />
    </>
  );
});

export default SavedFilter;
