import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import ActionRenderer from '../elements/ActionRenderer';
// import StatusRenderer from '../elements/StatusRenderer';
import EmailRenderer from '../elements/EmailRenderer';
import PhoneRenderer from '../elements/PhoneRenderer';
import NoteRenderer from '../elements/NoteRenderer';
import { vsmCommon, vsmNotify, vsmQuickNoteUpdate } from '../../../config/messages';
import GridConfig from '../../../config/GridConfig';
import { Spin } from 'antd';
import Unauthorized from '../../Unauthorized';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { t } = useTranslation()
  const [quickNoteList, setQuickNoteList] = useState();
  const {
    AUTH,
    LEADSSTORE: { leadSourceArray },
    PROMOTIONSTORE,
    PROMOTIONSTORE: { getLeadStatusList, getQuickNoteList, updateQuickNote },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  const { openViewDrawer, openEditDrawer, openDeleteDrawer, openCloneDrawer } = props;

  const onCellClicked = (event) => {
    if (event?.colDef?.field === 'lead_status_id.status_name') {
      getLeadStatusList(event.data.lead_record_type);
    }
  };

  useEffect(() => {
    if (!quickNoteList) {
      getQuickNoteList().then((data) => {
        setQuickNoteList(data.data.quick_notes);
      });
    }
  }, [getQuickNoteList, quickNoteList]);

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        field: 'lead_holder_name.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PROMOTIONSTORE.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Name')}`,
        field: 'people.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PROMOTIONSTORE.getPeopleList().then((data) => {
              params.success([...data.data.map((x) => x.people)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation_name.organisation_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PROMOTIONSTORE.getMappedOrg().then((data) => {
              params.success([...data.data.map((x) => x.organisation_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Value')}`,
        field: 'finance_value',
        cellClass: 'cellClass',
        cellStyle: {
          'text-align': 'right'
        },
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          return (
            params.data.lead_currency_id?.currency_code + ' ' + params.data?.finance_value
          );
        },
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        headerTooltip: `${t('Common_Data.SOLD_Name')}`,
        field: 'opportunity_name',
        cellClass: 'cellClass',
        tooltipField: 'SOLD Name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`,
        field: 'lead_status_id.status_name',
        cellClass: 'cellClass StatusCellPromotion text-left',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'StatusRenderer',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PROMOTIONSTORE.getAllChildStatusList().then((data) => {
              params.success([...data.map((x) => x && x.status_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? moment(params.data.deadline_date).format(AUTH.global_dateformat)
            : ' ';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Phone')}`,
        headerTooltip: `${t('Common_Data.Phone')}`,
        field: 'phone',
        cellClass: 'cellClass cellClassDropDown',
        filter: 'agTextColumnFilter',
        editable: false,
        cellRenderer: 'PhoneRenderer',
      },
      {
        headerName: `${t('Common_Data.Email_ID')}`,
        headerTooltip: `${t('Common_Data.Email_ID')}`,
        field: 'email',
        cellClass: 'cellClass cellClassDropDown',
        filter: 'agTextColumnFilter',
        editable: false,
        cellRenderer: 'EmailRenderer',
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        field: 'lead_owner_name.full_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PROMOTIONSTORE.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Credebt_Classification')}`,
        field: 'classification.name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PROMOTIONSTORE.getClassificationList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Lead')} ${t('Common_Data.Source')}`,
        field: 'lead_source',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            if (leadSourceArray && leadSourceArray.length > 0) {
              params.success([...leadSourceArray.map((x) => x.value)]);
            }
          },
        },
      },
      {
        headerName: `${t('Common_Data.Lead')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`,
        field: 'lead_source_details',
        filter: 'agTextColumnFilter',
        cellClass: 'cellClass',
      },

      {
        headerName: `${t('Promotion.Reason')}`,
        headerTooltip: `${t('Promotion.Reason')}`,
        field: 'failed_reason',
        tooltipField: 'failed_reason',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
        field: 'created_at',
        tooltipValueGetter: (params) => {
          return params.data?.created_at
            ? moment(params.data?.created_at).format(AUTH.global_dateformat)
            : ' ';
        },
        cellRenderer: (params) => {
          return params.data?.created_at
            ? moment(params.data?.created_at).format(AUTH.global_dateformat)
            : ' ';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,
        headerTooltip: `${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,
        field: 'updated_at',
        tooltipValueGetter: (params) => {
          return params.data?.updated_at
            ? moment(params.data?.updated_at).format(AUTH.global_dateformat)
            : ' ';
        },
        cellRenderer: (params) => {
          return params.data?.updated_at
            ? moment(params.data?.updated_at).format(AUTH.global_dateformat)
            : ' ';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        cellClass: 'cellClass',
        cellStyle: { textAlign: 'center', padding: '0' },
        width: 90,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',
        width: 310,
      },
    ],
  };

  const sendKeynoteByKeyUp = (id, data) => {
    let formdata = {
      notes_flag: 1,
      notes_description: data,
    };
    updateQuickNote(id, formdata)
      .then(() => {
        vsmNotify.success({
          message: vsmQuickNoteUpdate.successQuickNoteUpdate,
        });
      })
      .catch((e) => {
        if (e.errors) {
          //form.setFields(e.errors);
        }
      });
  };

  const onCellKeyDown = (e) => {
    if (quickNoteList.length > 0) {
      if (e.event.ctrlKey || e.event.altKey || e.event.shiftKey) {
        return false;
      }
      let keyPressed = e.event.key;
      let result = quickNoteList
        .filter((x) => x.quick_note_text_value.charAt(0) === keyPressed.toUpperCase())
        .shift();
      if (result) {
        sendKeynoteByKeyUp(e.data.id, result.quick_note_text);
      }
    }
  };

  const onCellKeyUp = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(13, 'edit')) {
            if (result.key === 'Enter') {
              openEditDrawer(e.data);
            }
          }

          if (AUTH.checkPermission(13, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteDrawer(e.data);
            }
          }
        }
      }
    }
  };

  let columns = [];
  let columnConfig = localStorage.getItem('promotion_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['phone', 'email', 'updated_at'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }

    return item;
  });

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/dealdesk/${params.node.data.id}`, '_blank');
          },
        },
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/dealdesk/${params.node.data.id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  function getClipBoardProcess(params) {
    if (params.column.colId === 'phone') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0]?.contact_value) {
          params.value = params.value[0].contact_value;
        }
      }
    }
    if (params.column.colId === 'email') {
      if (Array.isArray(params.value) && params.value.length > 0) {
        if (params.value[0]?.contact_value) {
          params.value = params.node.data.email;
        }
      }
    }

    return params.value;
  }
  return (
    <>
      {!AUTH.checkPermission(2, 'list') ? (
        <Unauthorized />
      ) : (
        <div
          className='ag-theme-alpine grid_wrapper'
          style={{
            height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 11rem)',
          }}
        >
          {PROMOTIONSTORE.list_data ? (
            <AgGridReact
              rowData={PROMOTIONSTORE.list_data}
              modules={AllModules}
              columnDefs={withTooltipCols}
              defaultColDef={{
                ...GridConfig.defaultColDef,
                sortable: true,
                // width: 120,
              }}
              columnTypes={GridConfig.columnTypes}
              overlayNoRowsTemplate={vsmCommon.noRecord}
              frameworkComponents={{
                ActionRenderer,
                NoteRenderer,
                PhoneRenderer,
                EmailRenderer,
                // StatusRenderer,
                quickNoteList,
                openViewDrawer,
                openEditDrawer,
                openDeleteDrawer,
                openCloneDrawer,
              }}
              onGridReady={PROMOTIONSTORE.setupGrid}
              gridOptions={{
                ...GridConfig.options,
                rowHeight: 30,
                pagination: true,
                paginationPageSize: 100,
                onRowDoubleClicked: (event) => openViewDrawer(event?.data),
                processCellForClipboard: getClipBoardProcess,
                getContextMenuItems: getContextMenuItems,
              }}
              onColumnResized={PROMOTIONSTORE.onGridChanged}
              onColumnMoved={PROMOTIONSTORE.onGridChanged}
              onColumnPinned={PROMOTIONSTORE.onGridChanged}
              rowSelection='multiple'
              suppressRowClickSelection={true}
              onCellKeyDown={(e) => {
                onCellKeyDown(e);
                onCellKeyUp(e);
              }}
              onCellClicked={onCellClicked}
              onFilterChanged={(e) => {
                if (
                  e.hasOwnProperty('afterFloatingFilter') &&
                  PROMOTIONSTORE.selectedGridFilter
                ) {
                  PROMOTIONSTORE.setSelectedGridFilter(null);
                }
              }}
            />
          ) : (
            <Spin tip={`${t('Common_Data.Load')}...`} />
          )}
        </div>
      )}
    </>
  );
});
export default ListComponent;
