import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { observer } from 'mobx-react';
import {
  Button, Popover, Input, Select, Tooltip,
  //  Spin
} from 'antd';
import { //LeftOutlined, RightOutlined, 
  DownCircleFilled
} from '@ant-design/icons';
//import { toJS } from 'mobx';
import moment from 'moment';
import { vsmNotify, vsmDealDesk, vsmProductivity } from '../../../../../config/messages';
import useStore from '../../../../../store';
import { default as LeadCloneComponent } from '../../../../../page/LeadListing/component/CloneComponentNew';
import { default as EditOrgComponent } from '../../../../../page/OrganisationManagement/AllOrganisations/component/EditComponentNew';
import { default as EditPeopleComponent } from '../../../../../page/PeopleManagement/People/component/EditComponentNew';
import { default as EditLeadComponent } from '../../../../../page/LeadListing/component/EditComponentNew';
import { default as EditTicketComponent } from '../../../../../page/Tickets/component/EditComponentNew';
import { default as CloneTicketComponent } from '../../../../../page/Tickets/component/CloneComponentNew';

// import { default as OrgHourglassConfirmElements } from '../../../../../page/OrganisationManagement/AllOrganisations/elements/HourglassConfirmElements';
// import { default as PeopleHourglassConfirmElements } from '../../../../../page/PeopleManagement/People/elements/HourglassConfirmElements';
import { default as TicketHourglassConfirmElements } from '../../../../../page/Tickets/component/HourglassConfirmComponent';
// import { default as LeadHourglassConfirmElements } from '../../../../../page/LeadListing/elements/HourglassConfirmElements';

import { default as LeadDateTimeConfirmElements } from '../../../../../page/LeadListing/elements/DateTimeConfirmElements';
import { default as TicketDateTimeConfirmElements } from '../../../../../page/Tickets/component/DateTimeConfirmComponent';
import { default as PeopleDateTimeConfirmElements } from '../../../../../page/PeopleManagement/People/elements/DateTimeConfirmElements';
import { default as OrgDateTimeConfirmElements } from '../../../../../page/OrganisationManagement/AllOrganisations/elements/DateTimeConfirmElements';

import { default as DeleteTicketComponent } from '../../../../Tickets//component/DeleteComponent';
import { default as DeleteLeadComponent } from '../../../../LeadListing/component/DeleteComponent';
import StatusConfirmElements from '../../../../../page/LeadListing/elements/StatusConfirmElements';
import ConvertticketComponent from '../../../../../page/Tickets/component/ConvertticketComponent';

import { useWindowDimensions } from '../../../../../config/WindowHeightWidth';
import ReminderAddComponent from '../../../../Reminders/component/AddComponent';
import ReminderEditComponent from '../../../../Reminders/component/EditComponent';
import { default as ViewAllReminderListComponent } from '../../../../Reminders/component/ViewAllListComponent';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../../../component/DatePickerCostume';

const { Search } = Input;
const { Option } = Select;

const ButtonsComponent = observer((props) => {
  const { setisMasterTicket, setViewTicketDrawer } = props;
  const { t } = useTranslation()
  const {
    AUTH,
    LEADSSTORE,
    REMINDERSSTORE,
    DEALDESKSTORE,
    DEALDESKSTORE: {
      // leadstatus_list,
      deal_data,
      // getLeadStatusList,
      // updateLeadStatus,
      updateLeadDeadLine,
      dealDeskTypeArr,
      getDealDetail,
      setDealdeskActiveTab,
      setStopInvestmentHourCounter,
    },
    ORGANISATION,
    PEOPLE,
    TicketsStore,
    PRODUCTIVITYSTORE: { tickettagStatusUpdate },
    DEALDESKNOTESEQUENCE,
  } = useStore();
  let history = useHistory();
  const { width } = useWindowDimensions();

  const openTicketViewDrawer = (isMaster = false) => {
    // alert("hi")
    setisMasterTicket(isMaster);
    // TicketsStore.setViewValues(deal_data?.lead_information?.ticket_data);
    setViewTicketDrawer(true);
  };

  /*let AdminIsLoggin = AUTH?.user?.id === 1*/
  // let OwnerIsLoggin = true //deal_data?.lead_information?.lead_owner_name?.id === AUTH?.user?.id
  // let HolderIsLoggin = true //deal_data?.lead_information?.lead_holder_name?.id === AUTH?.user?.id

  // const [, updateState] = useState();
  // const forceUpdate = useCallback(() => updateState({}), []);



  // const [prevStatusID, setPrevStatusID] = useState(null);
  // const [nextStatusID, setNextStatusID] = useState(null);
  // const [prevStatusName, setPrevStatusName] = useState(null);
  // const [nextStatusName, setNextStatusName] = useState(null);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  const [cloneNotes, setCloneNotes] = useState(0);
  const [cloneNotesTicket, setCloneNotesTicket] = useState(0);

  const [addReminder, setAddReminder] = useState(false);
  const [editReminder, setEditReminder] = useState(false);

  const [cloneTicketDrawer, setCloneTicketDrawer] = useState(false);

  const [searchText, setSearchText] = useState(null);
  const [ticketstatusVisible, setTicketstatusVisible] = useState(false);

  const [openleadStatusOptins, setOpenleadStatusOptins] = useState(false);
  const [statusUpdateData, setStatusUpdateData] = useState(null);

  /*start: Used For Organisation Edit*/
  const [editOrgModal, setEditOrgModal] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const setId = (id) => setTransactionId(id);
  /*end: Used For Organisation Edit*/

  /*start: Used For People Edit*/
  const [editPeopleModal, setEditPeopleModal] = useState(null);
  /*end: Used For People Edit*/

  /*start: Used For Lead Edit*/
  const [editLeadDrawer, setEditLeadDrawer] = useState(false);
  /*start: Used For Lead Edit*/

  /*start: Used For Lead Edit*/
  const [editTicketDrawer, setEditTicketDrawer] = useState(false);
  /*start: Used For Lead Edit*/

  //------------------USE TO CONVERT TICKET TO SOLD ----------------------//
  const [convertTicketModal, setConvertTicketModal] = useState(false);
  const [convertTicketTempData, setConvertTicketTempData] = useState(null);

  // const [openHourglassModalOrg, setOpenHourglassModalOrg] = useState(false);
  // const [orgHourglassConfirm, setOrgHourglassConfirm] = useState();
  // const [openHourglassModalPeople, setOpenHourglassModalPeople] = useState(false);
  // const [peopleHourglassConfirm, setPeopleHourglassConfirm] = useState();
  const [openHourglassModalTicket, setOpenHourglassModalTicket] = useState(false);
  const [ticketHourglassConfirm, setTicketHourglassConfirm] = useState();
  // const [openHourglassModalLead, setOpenHourglassModalLead] = useState(false);
  // const [leadHourglassConfirm, setLeadHourglassConfirm] = useState();

  // const [loadingLeadStatus, setLoadingLeadStatus] = useState(true);
  const [deleteTicketDrawer, setTicketDeleteDrawer] = useState(false);
  const [deleteLeadDeleteDrawer, setDeleteLeadDeleteDrawer] = useState(false);
  /** START : To Update Status Update */

  //--------------START:REMINDER RESET STATES-------------------------//
  const [date, setDate] = useState();
  const [reminderConfirm, setReminderConfirm] = useState();
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [ticketDateTimeModal, setTicketDateTimeModal] = useState(false);
  const [peopleDateTimeModal, setPeopleDateTimeModal] = useState(false);
  const [orgDateTimeModal, setOrgDateTimeModal] = useState(false);
  const [visibleAllReminder, setVisibleAllReminder] = useState(false);
  //--------------END:REMINDER RESET STATES-------------------------//

  // const openleadStatusModal = (new_id, oldstatus, newstatus, id) => {
  //   let tempDataStatusUpdate = {
  //     new_id: new_id,
  //     oldstatus: oldstatus,
  //     newstatus: newstatus,
  //     id: id,
  //   };
  //   setStatusUpdateData(tempDataStatusUpdate);

  //   setOpenleadStatusOptins(true);
  // };

  const closeleadStatusOptins = () => {
    setStatusUpdateData(null);
    setOpenleadStatusOptins(false);
  };
  /** END : To Update Status Update */

  // --------------------- Open Screen used for Shortfall ---------------- //

  const openShortfall = () => {
    setDealdeskActiveTab('6');
  }


  // --------------------------------------------------------------------- //

  /**Start: Organisation Hour Glass Update  */
  // const openOrgConfirmHourglassModal = (data) => {
  //   setOpenHourglassModalOrg(true);
  //   setOrgHourglassConfirm(data);
  // };

  // const closeOrgHourglassModal = () => setOpenHourglassModalOrg(false);
  /**End: Organisation Hour Glass Update  */

  /**Start: People Hour Glass Update  */
  // const openPeopleConfirmHourglassModal = (data) => {
  //   setOpenHourglassModalPeople(true);
  //   setPeopleHourglassConfirm(data);
  // };

  // const closePeopleConfirmHourglassModal = () => setOpenHourglassModalPeople(false);
  /**Start: People Hour Glass Update  */

  /**Start: TICKET Hour Glass Update  */
  const openTicketConfirmHourglassModal = (data) => {
    setOpenHourglassModalTicket(true);
    setTicketHourglassConfirm(data);
  };

  const closeTicketConfirmHourglassModal = () => setOpenHourglassModalTicket(false);
  /**Start: TICKET Hour Glass Update  */

  /**Start: LEAD Hour Glass Update  */
  // const openLeadConfirmHourglassModal = (data) => {
  //   setOpenHourglassModalLead(true);
  //   setLeadHourglassConfirm(data);
  // };

  // const closeLeadConfirmHourglassModal = () => setOpenHourglassModalLead(false);
  /**Start: LEAD Hour Glass Update  */

  //open delete drawer
  const openTicketDeleteDrawer = (data) => {
    TicketsStore.setDeleteValues(data);
    setTicketDeleteDrawer(true);
  };

  const closeDeleteDrawer = (reload) => {
    setTicketDeleteDrawer(false);
    if (reload) {
      history.push('/tickets');
    }
  };

  const openLeadDeleteDrawer = (data) => {
    LEADSSTORE.setDeleteValues(data);
    setDeleteLeadDeleteDrawer(true);
  };

  const closeLeadDeleteDrawer = (reload) => {
    setDeleteLeadDeleteDrawer(false);
    if (reload) {
      history.push(leadParentStatusName === 'Lead' ? '/leads/active' : '/leads/deals');
    }
  };

  const handleDelete = () => {
    if (deal_data?.lead_information?.is_ticket === 0) {
      openTicketDeleteDrawer(deal_data?.lead_information?.ticket_data);
    } else {
      openLeadDeleteDrawer(deal_data?.lead_information);
    }
  };

  const onDatePickerChange = (value) => {
    let payload = {
      deadline_date: moment(value).format(AUTH.global_dateformat),
    };

    updateLeadDeadLine(deal_data.lead_information.id, payload).then((data) => {
      vsmNotify.success({
        message: vsmDealDesk.deadLineSucess,
      });
    });
  };

  const onSearch = (value) => {
    history.push(`/search/all?q=${value}`);
  };

  const handleChange = (value) => {
    if (deal_data && deal_data.lead_information) {
      if (
        deal_data.lead_information.lead_status_name &&
        deal_data.lead_information.lead_status_name.id &&
        deal_data.lead_information.lead_status_name.id === 4 &&
        deal_data.lead_information.lead_status_name.parent_leads.id === 1
      ) {
        //UnUsed-Leads
        if (value === 'Kanban_View') {
          history.replace('/leads/unused/kanban');
        } else {
          history.replace('/leads/unused');
        }
      } else if (
        deal_data.lead_information.lead_status_name.parent_leads.id &&
        deal_data.lead_information.lead_status_name.parent_leads.id === 2
      ) {
        //OpportunityLeads
        if (value === 'Kanban_View') {
          history.replace('/leads/opportunities/kanban');
        } else {
          history.replace('/leads/opportunities');
        }
      } else if (
        deal_data.lead_information.lead_status_name &&
        deal_data.lead_information.lead_status_name.parent_leads.id &&
        deal_data.lead_information.lead_status_name.parent_leads.id === 1 &&
        deal_data.lead_information.lead_status_name.id !== 4
      ) {
        //Active Leads
        if (value === 'Kanban_View') {
          history.replace('/leads/active/kanban');
        } else {
          history.replace('/leads/active');
        }
      } else if (
        deal_data.lead_information.lead_status_name &&
        deal_data.lead_information.lead_status_name.parent_leads.id &&
        deal_data.lead_information.lead_status_name.parent_leads.id === 3
      ) {
        //DealsLeads
        if (value === 'Kanban_View') {
          history.replace('/leads/deals/kanban');
        } else {
          history.replace('/leads/deals');
        }
      }
    }
  };

  //open add drawer
  const openAddReminder = (data) => {
    if (deal_data?.reminder?.receiver_id === AUTH?.user?.id) {
      REMINDERSSTORE.setEditValues(deal_data?.reminder);
      setEditReminder(true);
    } else {
      setAddReminder(true);
    }
  };

  const closeAddReminder = () => {
    setAddReminder(false);
  };

  const closeEditReminder = () => {
    setEditReminder(false);
  };

  // let liPercentage =
  //   leadstatus_list && leadstatus_list.length > 0
  //     ? ((100 / leadstatus_list.length) - 1) + '%'
  //     : '24%';

  // let ulWidth =
  //   leadstatus_list && (leadstatus_list.length === 1 || leadstatus_list.length === 2)
  //     ? 200 * leadstatus_list.length + 'px'
  //     : '655px';

  // const StatusChangeData = (
  //   <div className='leadStatusOptins'>
  //     {loadingLeadStatus ? (
  //       <ul style={{ width: '655px', height: '100px' }}>
  //         <Spin size='large' spinning={loadingLeadStatus} tip={`${t('Common_Data.Load')}`} />
  //       </ul>
  //     ) : (
  //       <ul style={{ width: ulWidth }}>
  //         {leadstatus_list &&
  //           leadstatus_list.map(function (item) {
  //             return (
  //               <li key={item.id} style={{ width: liPercentage }}>
  //                 <span className='title'>{item.status_name}</span>
  //                 <ul>
  //                   {item.child_leads.map(
  //                     (itemsubleads) =>
  //                       itemsubleads.id && (
  //                         <li
  //                           key={itemsubleads.id}
  //                           className={
  //                             itemsubleads.id ===
  //                               deal_data?.lead_information?.lead_status_id
  //                               ? 'active'
  //                               : ''
  //                           }
  //                           onClick={() => {
  //                             if (itemsubleads.id !==
  //                               deal_data?.lead_information?.lead_status_id) {
  //                               openleadStatusModal(
  //                                 itemsubleads.id,
  //                                 status,
  //                                 itemsubleads.status_name,
  //                                 deal_data.lead_information &&
  //                                 deal_data.lead_information.id
  //                               );
  //                             }
  //                           }}
  //                         >
  //                           <Tooltip title={itemsubleads.status_name}>
  //                             <span>{itemsubleads.status_name}</span>
  //                           </Tooltip>
  //                         </li>
  //                       )
  //                   )}
  //                 </ul>
  //               </li>
  //             );
  //           })}
  //       </ul>
  //     )}
  //   </div>
  // );


  const TicketStatusChangeData = (
    <div className='ticketsPopoverIcons username'>
      <ul>
        {TicketsStore.dropdown_ticketstatus &&
          TicketsStore.dropdown_ticketstatus.map((item) => {
            return (
              <li key={item.id}
                className={item.id === deal_data?.lead_information?.ticket_data?.ticket_status_id ? 'active' : ''}
                onClick={() => {
                  tickettagStatusUpdate({
                    type: 'ticket',
                    id: deal_data?.lead_information?.ticket_data?.id,
                    status_id: item.id,
                  }).then((data) => {
                    setTicketstatusVisible(false);
                    DEALDESKNOTESEQUENCE.fetchSequenceNotes(deal_data?.lead_information?.id);
                    vsmNotify.success({ message: vsmProductivity.successChangeStatus });
                    getDealDetail(deal_data?.lead_information?.id);
                  });
                }}
              >
                {item.status_name}
              </li>
            )
            // return HolderIsLoggin
            //   ? (
            //     (HolderIsLoggin ? item.id !== 6 : item.id >= 0) &&
            //     <li
            //       key={item.id}
            //       className={
            //         item.id === deal_data?.lead_information?.ticket_data?.ticket_status_id
            //           ? 'active'
            //           : ''
            //       }
            //       onClick={() => {
            //         tickettagStatusUpdate({
            //           type: 'ticket',
            //           id: deal_data?.lead_information?.ticket_data?.id,
            //           status_id: item.id,
            //         }).then((data) => {
            //           setTicketstatusVisible(false);
            //           DEALDESKNOTESEQUENCE.fetchSequenceNotes(deal_data?.lead_information?.id);
            //           vsmNotify.success({ message: vsmProductivity.successChangeStatus });
            //           getDealDetail(deal_data?.lead_information?.id);
            //         });
            //       }}
            //     >
            //       {item.status_name}
            //     </li>)

            //   : (
            //     (OwnerIsLoggin ? item.id !== 7 : item.id >= 0) && <li
            //       key={item.id}
            //       className={
            //         item.id === deal_data?.lead_information?.ticket_data?.ticket_status_id
            //           ? 'active'
            //           : ''
            //       }
            //       onClick={() => {
            //         tickettagStatusUpdate({
            //           type: 'ticket',
            //           id: deal_data?.lead_information?.ticket_data?.id,
            //           status_id: item.id,
            //         }).then((data) => {
            //           setTicketstatusVisible(false);
            //           DEALDESKNOTESEQUENCE.fetchSequenceNotes(deal_data?.lead_information?.id);
            //           vsmNotify.success({ message: vsmProductivity.successChangeStatus });
            //           getDealDetail(deal_data?.lead_information?.id);
            //         });
            //       }}
            //     >
            //       {item.status_name}
            //     </li>
            //   )

          })}
      </ul>
    </div>
  );

  // const updateNextPrevStatus = (data = leadstatus_list) => {
  //   data &&
  //     data.forEach((element, index) => {
  //       element.child_leads &&
  //         element.child_leads.forEach((element1, index1) => {
  //           if (element1.id === deal_data.lead_information.lead_status_id) {
  //             if (
  //               element.child_leads[index1 - 1] &&
  //               element.child_leads[index1 - 1].id !== 'undefined'
  //             ) {
  //               setPrevStatusID(element.child_leads[index1 - 1].id);
  //               setPrevStatusName(element.child_leads[index1 - 1].status_name);
  //             } else {
  //               if (data[index - 1]) {
  //                 let lastelement =
  //                   data[index - 1].child_leads[data[index - 1].child_leads.length - 1];
  //                 setPrevStatusID(lastelement.id);
  //                 setPrevStatusName(lastelement.status_name);
  //               } else {
  //                 setPrevStatusID(element.child_leads[index1].id);
  //                 setPrevStatusName(element.child_leads[index1].status_name);
  //               }
  //             }

  //             if (
  //               element.child_leads[index1 + 1] &&
  //               element.child_leads[index1 + 1].id !== 'undefined'
  //             ) {
  //               setNextStatusID(element.child_leads[index1 + 1].id);
  //               setNextStatusName(element.child_leads[index1 + 1].status_name);
  //             } else {
  //               if (data[index + 1]) {
  //                 setNextStatusID(data[index + 1].child_leads[0]?.id);
  //                 setNextStatusName(data[index + 1].child_leads[0]?.status_name);
  //               } else {
  //                 setNextStatusID(element.child_leads[index1]?.id);
  //                 setNextStatusName(element.child_leads[index1]?.status_name);
  //               }
  //             }
  //           }
  //         });
  //     });
  // };

  // const fetchStatusList = () => {
  //   if (!leadstatus_list) {
  //     getLeadStatusList(deal_data?.lead_information?.lead_record_type).then((data) => {
  //       updateNextPrevStatus(data.data);
  //     });
  //   } else {
  //     updateNextPrevStatus(leadstatus_list);
  //   }
  // };

  // const updatePreviousStatusCall = (type) => {
  //   let new_lead_status_id = type === 2 ? nextStatusID : prevStatusID;
  //   if (deal_data.lead_information.lead_status_id !== new_lead_status_id) {
  //     if (
  //       [
  //         AUTH.SOLD_STATUS_FAILED,
  //         AUTH.SOLD_STATUS_FUTURE,
  //         AUTH.SOLD_STATUS_REJECTED,
  //       ].includes(new_lead_status_id)
  //     ) {
  //       openleadStatusModal(
  //         new_lead_status_id,
  //         status,
  //         type === 2 ? nextStatusName : prevStatusName,
  //         deal_data.lead_information && deal_data.lead_information.id
  //       );
  //     } else {
  //       updateLeadStatus(deal_data.lead_information && deal_data.lead_information.id, {
  //         lead_status_id: new_lead_status_id,
  //       }).then((data) => {
  //         Setstatus(type === 2 ? nextStatusName : prevStatusName);
  //       });
  //     }
  //   }
  // };

  //Clone drawer
  // const openCloneDrawer = (isnotes) => {
  //   setCloneNotes(isnotes);
  //   LEADSSTORE.setCloneValues(deal_data.lead_information);
  //   setStopInvestmentHourCounter(true);
  //   setCloneDrawer(true);
  // };

  const closeCloneDrawer = () => {
    setCloneNotes(0);
    setStopInvestmentHourCounter(false);
    setCloneDrawer(false);
  };

  /**START: TICKET CLONE  & CLONE WITH NOTE DRAWER FUNCITONS  */
  const openTicketCloneDrawer = (isnotes) => {
    TicketsStore.setClonevalues({ id: deal_data?.lead_information?.ticket_data?.id });
    setCloneNotesTicket(isnotes);
    setCloneTicketDrawer(true);
  };

  const closeTicketCloneDrawer = () => {
    TicketsStore.cloneValues = null;
    setCloneNotesTicket(0);
    setCloneTicketDrawer(false);
  };
  /**END: TICKET CLONE  & CLONE WITH NOTE DRAWER FUNCITONS  */

  // const lastUpdatedDate = (
  //   <span>
  //     {t('DealDesk.Last_Updated_Date')} <br />
  //     {AUTH.getLocalTimeDealDesk(DEALDESKSTORE?.deal_data.lead_information?.updated_at)}
  //   </span>
  // );

  const lastUpdatedDateTicketDesk = (
    <span>
      {t('DealDesk.Last_Updated_Date')}<br />
      {AUTH.getLocalTime(DEALDESKSTORE?.deal_data?.lead_information?.ticket_data?.updated_at,AUTH.global_fulldate_format)}
    </span>
  );

  // START : Open form for edit existing Organisation and set values to form
  // const openEditOrgModal = () => {
  //   let orgid = deal_data?.lead_information?.sold_organisation?.id;
  //   ORGANISATION.getRecordDetail({ organisation_id: orgid }).then((x) => {
  //     ORGANISATION.setEditValues(x);
  //     ORGANISATION.setOrganisationValues(null);
  //     setId(orgid);
  //     setStopInvestmentHourCounter(true);
  //     setEditOrgModal(true);
  //   });
  // };

  // Close form of edit Organisation
  const closeEditOrgModal = () => {
    ORGANISATION.organisationValues = null;
    ORGANISATION.setEditValues(null);
    setStopInvestmentHourCounter(false);
    setEditOrgModal(false);
    getDealDetail(deal_data?.lead_information?.id);
  };
  // END : Open form for edit existing Organisation and set values to form

  /*START: PEOPLE EDIT FUNCTIONS*/
  //open edit drawer
  // const openPeopleEditDrawer = () => {
  //   var payload = { people_id: deal_data?.lead_information?.people?.id };
  //   PEOPLE.editPeopleLoading = true;
  //   PEOPLE.getRecordDetail(payload)
  //     .then((data) => {
  //       setStopInvestmentHourCounter(true);
  //       setEditPeopleModal(true);
  //       PEOPLE.editPeopleLoading = false;
  //     })
  //     .catch((data) => {
  //       PEOPLE.editPeopleLoading = false;
  //     });
  // };

  //close edit drawer
  const closePeopleEditDrawer = () => {
    setStopInvestmentHourCounter(false);
    setEditPeopleModal(false);
    PEOPLE.peopleValues = null;
    getDealDetail(deal_data?.lead_information?.id);
  };
  /*EDIT: PEOPLE EDIT FUNCTIONS*/

  /*START: LEAD EDIT FUNCTIONS*/
  //Edit drawer
  // const openEditLeadDrawer = () => {
  //   LEADSSTORE.setEditValues({ id: deal_data?.lead_information?.id });
  //   setStopInvestmentHourCounter(true);
  //   setEditLeadDrawer(true);
  // };

  const closeEditLeadDrawer = () => {
    setEditLeadDrawer(false);
    setStopInvestmentHourCounter(false);
    getDealDetail(deal_data?.lead_information?.id);
  };
  /*END: LEAD EDIT FUNCTIONS*/

  /*START: TICKET EDIT FUNCTIONS*/
  //Edit drawer
  const openTicketEditDrawer = () => {
    TicketsStore.setEditValues({ id: deal_data?.lead_information?.ticket_data?.id });
    setEditTicketDrawer(true);
    setStopInvestmentHourCounter(true);
  };

  const closeTicketEditDrawer = () => {
    setEditTicketDrawer(false);
    setStopInvestmentHourCounter(false);
    getDealDetail(deal_data?.lead_information?.id);
  };
  /*EDIT: TICKET EDIT FUNCTIONS*/

  let leadParentStatusName =
    deal_data?.lead_information?.lead_status_name?.parent_leads?.status_name;
  if (dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)) {
    if (deal_data?.lead_information?.is_ticket === 0) {
      leadParentStatusName = `${t('DealDesk.Ticket')}`;
    }
    if (deal_data?.lead_information?.is_ticket === 2) {
      leadParentStatusName = `${t('DealDesk.People')}`;
    }
    if (deal_data?.lead_information?.is_ticket === 3) {
      leadParentStatusName = `${t('DealDesk.Organisation')}`;
    }
  }

  if (leadParentStatusName === undefined) {
    leadParentStatusName = `${t('DealDesk.Unused')}`;
  }

  // let leadHourglassTime = 0;
  // if (deal_data.lead_information?.lead_status_name) {
  //   /*if (deal_data.lead_information?.lead_status_name?.parent_status_id === 2) {
  //     //Opportunity
  //     leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_opportunity_hourglass;
  //   } else*/
  //   if (deal_data.lead_information?.lead_status_name?.parent_status_id === 2) {
  //     //Deals
  //     leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_deals_hourglass;
  //   }
  //   // else if (deal_data.lead_information?.lead_status_name?.id === 4) {
  //   //   //Un-Used Leads
  //   //   leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_unused_leads_hourglass;
  //   // } 
  //   // else {
  //   //   //Active Leads
  //   //   leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_used_leads_hourglass;
  //   // }
  // }

  //------------START: CONVERT TICKET TO SOLD -----------------//
  const openConvertTicketToDeal = (data) => {
    setConvertTicketTempData(data);
    setConvertTicketModal(true);
  };

  const closeConvertModal = () => setConvertTicketModal(false);
  //------------END: CONVERT TICKET TO SOLD -----------------//

  //------------START: CHILD TICKET LIST IN NEW-----------------//
  const openChildTickets = (data) => {
    // localStorage.setItem(
    //   'fetch_child_ticket',
    //   JSON.stringify({
    //     current: 'tickets',
    //     master_ticket_number: data?.ticket_number,
    //   })
    // );
    localStorage.setItem('get_child_tickets', data?.id)
    window.open(`/tickets`, '_blank');
  };
  //------------END : CHILD TICKET LIST IN NEW-----------------//

  // useEffect(() => {
  //   Setstatus(deal_data.lead_information?.lead_status_name?.status_name);
  //   forceUpdate();
  // }, [deal_data, forceUpdate]);

  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // };

  // const disabledHours = (e) => {
  //   let hours = [19, 20, 21, 22, 23];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  const openConfirmDateTimeModal = (dateformat, data) => {
    if (dateformat) {
      setDate(dateformat);

      if (deal_data?.lead_information?.is_ticket === 3) {
        // Org Hour Glass
        setReminderConfirm({
          id: deal_data?.lead_information?.sold_organisation?.id,
          organisation_name:
            deal_data?.lead_information?.sold_organisation?.organisation_name,
        });
        setOrgDateTimeModal(true);
      }
      if (deal_data?.lead_information?.is_ticket === 2) {
        // People Hour Glass
        setReminderConfirm({
          id: deal_data?.lead_information?.people?.id,
          people_name: deal_data?.lead_information?.people?.full_name,
        });
        setPeopleDateTimeModal(true);
      }
      if (deal_data?.lead_information?.is_ticket === 0) {
        // Ticket Hour Glass
        setReminderConfirm({
          id: deal_data?.lead_information?.ticket_data?.id,
          ticket_number: deal_data?.lead_information?.ticket_data?.ticket_number,
        });
        setTicketDateTimeModal(true);
      }
      if (deal_data?.lead_information?.is_ticket === 1) {
        // Lead Hour Glass

        setReminderConfirm({
          id: deal_data?.lead_information?.id,
        });
        setOpenDateTimeModal(true);
      }
    }
  };

  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    if (deal_data?.lead_information?.is_ticket === 3) {
      // Org Hour Glass
      setOrgDateTimeModal(false);
    }
    if (deal_data?.lead_information?.is_ticket === 2) {
      // People Hour Glass
      setPeopleDateTimeModal(false);
    }
    if (deal_data?.lead_information?.is_ticket === 0) {
      // Ticket Hour Glass
      setTicketDateTimeModal(false);
    }
    if (deal_data?.lead_information?.is_ticket === 1) {
      // Lead Hour Glass
      setOpenDateTimeModal(false);
    }
  };

  //---------------START:View All Reminders Drawer Functions---------------------//
  const openViewAllReminder = () => {
    if (deal_data?.lead_information?.is_ticket === 3) {
      REMINDERSSTORE.setSold_reminderfor_data({
        type: 'organisations',
        id: deal_data?.lead_information?.sold_organisation?.id,
      });
    }
    if (deal_data?.lead_information?.is_ticket === 2) {
      REMINDERSSTORE.setSold_reminderfor_data({
        type: 'contacts',
        id: deal_data?.lead_information?.people?.id,
      });
    }
    if (deal_data?.lead_information?.is_ticket === 0) {
      REMINDERSSTORE.setSold_reminderfor_data({
        type: 'tickets',
        id: deal_data?.lead_information?.ticket_data?.id,
      });
    }
    if (deal_data?.lead_information?.is_ticket === 1) {
      REMINDERSSTORE.setSold_reminderfor_data({
        type: 'leads',
        id: deal_data?.lead_information?.id,
      });
    }

    setVisibleAllReminder(true);
  };

  const closeViewAllReminder = () => {
    REMINDERSSTORE.setSold_reminderfor_data(null);
    setVisibleAllReminder(false);
  };
  //---------------END:View All Reminders Drawer Functions---------------------//

  //---------------------PRIORITY QUICK REMINDER------//
  let reminder_priority_name = null;
  let ownReminder = false;
  if (deal_data?.reminder) {
    if (deal_data?.reminder?.receiver_id === AUTH?.user?.id) {
      ownReminder = true;
    }
    if (ownReminder && deal_data?.reminder?.priority) {
      if (REMINDERSSTORE.priorityForFilter.length > 0) {
        let priority = deal_data?.reminder?.priority;
        priority &&
          REMINDERSSTORE.priorityForFilter.forEach((x) => {
            if (parseInt(x.value) === parseInt(priority) && !reminder_priority_name) {
              reminder_priority_name = x.text;
            }
          });
      }
    }
  }
  //---------------------PRIORITY QUICK REMINDER------//



  return (
    DEALDESKSTORE?.deal_data?.lead_information?.is_ticket === 0 && (
      <>
        <ul size={3} className='ButtonsComponent'>
          <li>
            <Tooltip title={lastUpdatedDateTicketDesk}>
              <Button type='text' className='cursorArrow'>
                <img
                  src={AUTH.GetThemedImage('Updated')}
                  alt=''
                  className='icon mr-5'
                />
                {DEALDESKSTORE?.deal_data?.lead_information?.updated_at
                  ? AUTH.getLocalTimeDealDesk(
                    DEALDESKSTORE?.deal_data?.lead_information?.updated_at,
                    AUTH.global_dateformat
                  )
                  : ''}
              </Button>
            </Tooltip>
          </li>

          {DEALDESKSTORE?.deal_data?.lead_information?.ticket_data?.deadline_date && (
            <li className='Deadline cursorPointer'>
              <Tooltip title={`${t('DealDesk.Deadline_Date')}`}>
                <DatePickerCostume
                  format={AUTH.global_dateformat}
                  defaultValue={
                    DEALDESKSTORE?.deal_data?.lead_information?.ticket_data?.deadline_date
                      ? moment(
                        AUTH.getLocalTimeDealDesk(
                          DEALDESKSTORE?.deal_data?.lead_information?.ticket_data?.deadline_date,
                          AUTH.global_dateformat
                        ),
                        AUTH.global_dateformat
                      )
                      : ''
                  }
                  onChange={(value) => onDatePickerChange(value)}
                  disabled={dealDeskTypeArr.includes(
                    DEALDESKSTORE?.deal_data?.lead_information?.is_ticket
                  )}
                />
                <Button title={`${t('DealDesk.Deadline_Date')}`}>
                  <img
                    src={AUTH.GetThemedImage('Deadline')}
                    alt=''
                    title={`${t('DealDesk.Deadline_Date')}`}
                    className='icon mr-5'
                  />
                  {deal_data?.lead_information?.ticket_data?.deadline_date
                    ? AUTH.getLocalTimeDealDesk(
                      deal_data?.lead_information?.ticket_data?.deadline_date,
                      AUTH.global_dateformat
                    )
                    : ''}
                </Button>
              </Tooltip>
            </li>
          )}
          {deal_data.lead_information?.lead_status_name?.status_name && (
            <li>

              <Button type='text' className={`lightBG StatusChange cursorArrow`}>
                <Tooltip
                  title={deal_data?.lead_information.ticket_data?.ticket_status?.status_name}
                >
                  {width && width > 1750 ? (
                    <span className='textEclipses' style={{ width: '90%', minWidth: '100px', display: 'inline-block' }} >
                      {deal_data?.lead_information.ticket_data?.ticket_status?.status_name}
                    </span>
                  ) : (
                    <span
                      style={{ width: '45px', display: 'inline-block' }}
                      className='textEclipses'
                    >
                      {deal_data?.lead_information.ticket_data?.ticket_status?.status_name}
                    </span>
                  )}
                </Tooltip>

                {/* {(OwnerIsLoggin || HolderIsLoggin) &&  */}
                <Popover
                  content={TicketStatusChangeData}
                  placement='bottom'
                  overlayClassName='profileMenu'
                  arrowPointAtCenter
                  trigger='click'
                  visible={ticketstatusVisible}
                  onVisibleChange={(x) => {
                    setTicketstatusVisible(x);
                  }}
                >
                  <Tooltip title={`${t('DealDesk.Change_Ticket_Status')}`}>
                    <DownCircleFilled
                      className='cursorPointer'
                      style={{ display: 'block' }}
                      onMouseEnter={() => {
                        if (!TicketsStore.dropdown_ticketstatus) {
                          TicketsStore.getTicketstatusList();
                        }
                      }}
                    />
                    <span style={{ display: 'block', marginRight: "30px" }}></span>
                  </Tooltip>
                </Popover>
                {/* } */}
              </Button>
            </li>
          )}
          <li>
            <Tooltip title={leadParentStatusName}>
              <Button type='text' className='cursorArrow'>
                {leadParentStatusName}
              </Button>
            </Tooltip>
          </li>
          {deal_data.lead_information?.investment_hours && (
            <li>
              <Tooltip title={`${t('DealDesk.Time_Spent')}`}>
                <Button type='text' className='lightBG cursorArrow'>
                  <img
                    src={AUTH.GetThemedImage('Investment')}
                    alt=''
                    className='icon mr-5'
                  />
                  {deal_data.lead_information &&
                    deal_data.lead_information.investment_hours}
                </Button>
              </Tooltip>
            </li>
          )}
          <li className='search_input'>
            <Tooltip>
              <Search
                placeholder={`${t('DealDesk.Search_By')}`}
                onSearch={onSearch}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                className='searchBtn'
                suffix={
                  <img
                    src={AUTH.GetThemedImage('Search_icon')}
                    alt=''
                    className='icon'
                    onClick={() => {
                      onSearch(searchText);
                    }}
                  />
                }
              />
            </Tooltip>
          </li>

          {AUTH.checkPermission(1, 'clone') && (
            <li className='btn_box'>
              <Tooltip title={`${t('DealDesk.Clone')}`}>
                <Button
                  className='lightBG'
                  onClick={() => { openTicketCloneDrawer(0); }}
                // disabled={!OwnerIsLoggin}
                >
                  <img
                    src={AUTH.GetThemedImage('Clone_Record')}
                    alt={`${t('DealDesk.Clone')}`}
                    className='icon'
                  />
                </Button>
              </Tooltip>
            </li>
          )}
          {/* {AUTH.checkPermission(1, 'clone-with-notes') && (
            <li className='btn_box'>
              <Tooltip title={`${t('DealDesk.Clone_Notes')}`}>
                <Button
                  className='lightBG'
                  onClick={() => { OwnerIsLoggin && openTicketCloneDrawer(1); }}
                  disabled={!OwnerIsLoggin}
                >
                  <img
                    src={AUTH.GetThemedImage('Clone_Record_History')}
                    alt={`${t('DealDesk.Clone_Notes')}`}
                    className='icon'
                  />
                </Button>
              </Tooltip>
            </li>
          )} */}

          <li className='btn_box'>
            <Tooltip title={t('DealDesk.Edit_Ticket')}>
              {<Button className='lightBG' onClick={() => { openTicketEditDrawer(deal_data?.lead_information?.ticket_data, true) }}
              // disabled={!OwnerIsLoggin}
              >
                <img src={AUTH.GetThemedImage('Edit')} alt={`${t('DealDesk.Edit_Lead')}`} className='icon' />
              </Button>
              }

            </Tooltip>
          </li>
          <li className='btn_box'>
            <Tooltip title={t('DealDesk.View_Ticket')}>
              <Button className='lightBG' onClick={() => { openTicketViewDrawer() }}>
                <img src={AUTH.GetThemedImage('View')} alt={`${t('DealDesk.View_Lead')}`} className='icon' />
              </Button>

            </Tooltip>
          </li>

          {deal_data?.lead_information?.ticket_data?.child_ticket_count > 0 ? (
            <li className='btn_box'>
              <Tooltip title={`${t('DealDesk.Linked_Tickets')}`}>
                <Button className='lightBG'
                  onClick={() => { openChildTickets(deal_data?.lead_information?.ticket_data); }}
                // disabled={!OwnerIsLoggin}
                >
                  <img
                    style={{ margin: 5 }}
                    className='icon'
                    alt={`${t('DealDesk.Linked_Tickets')}`}
                    src={AUTH.GetThemedImage('childticket')}
                  />
                </Button>
              </Tooltip>
            </li>
          ) : (
            <li className='btn_box'>
              <Tooltip title={`${t('DealDesk.Convert_SOLD')}`}>
                <Button className='lightBG'
                  onClick={() => { openConvertTicketToDeal(deal_data?.lead_information?.ticket_data); }}
                >
                  <img
                    src={AUTH.GetThemedImage('TicketToDeal')}
                    alt={`${t('DealDesk.Convert_SOLD')}`}
                    className='icon'
                  />
                </Button>
              </Tooltip>
            </li>
          )}

          <li className='btn_box'>
            <Tooltip title={t('DealDesk.Delete_Ticket')}>
              <Button className='lightBG'
                onClick={() => { handleDelete(); }}
              >
                <img
                  src={AUTH.GetThemedImage('Waste')}
                  alt={`${t('DealDesk.Delete_Lead')}`}
                  className='icon'
                />
              </Button>
            </Tooltip>
          </li>

          <li className='btn_box'>
            <Tooltip title={`${t('DealDesk.Reset_Reminder')}`}>
              <Button
                className={ownReminder && deal_data?.reminder ? 'lightBG reminderNotifyIcon' : 'lightBG'}
                onClick={() => {
                  openTicketConfirmHourglassModal(deal_data?.lead_information?.ticket_data);
                }}
              >
                <img
                  src={AUTH.GetThemedImage('Reminders_icn')}
                  alt={`${t('DealDesk.Reset_Reminder')}`}
                  className='icon'
                />
                <span className='ml-5'>
                  {ownReminder && deal_data?.reminder?.reset_count
                    ? deal_data?.reminder?.reset_count
                    : 0}
                </span>
              </Button>
            </Tooltip>
          </li>

          <li className='QuickReminder cursorPointer btn_box'>
            <Tooltip title={`${t('DealDesk.Set_Quick_Reminder')}`}>
              <Button
                className={ownReminder && deal_data?.reminder ? 'lightBG reminderNotifyIcon' : 'lightBG '}
              // disabled={!OwnerIsLoggin}
              >
                <DatePickerCostume
                  name='Calendar'
                  className='cursor'
                  title={`${t('DealDesk.Calendar')}`}
                  placement={'bottomRight'}
                  showTime
                  disabledDate={true}
                  showNow={false}
                  onOk={(e) => openConfirmDateTimeModal(e, props.data)}
                  renderExtraFooter={() => (
                    <div>
                      <div>{t('Common_Data.Priority')} : {reminder_priority_name}</div>
                      <div>
                        {t('DealDesk.Reminder_Date_Time')} :
                        {deal_data?.reminder && deal_data?.reminder?.due_date_time
                          ? moment(deal_data?.reminder?.due_date_time).format(
                            AUTH.global_fulldate_format
                          )
                          : ``}
                      </div>
                    </div>
                  )}
                />
                <img
                  src={AUTH.GetThemedImage('Calendar')}
                  alt={`${t('DealDesk.Date_Time_Picker')}`}
                  className='icon'
                />
              </Button>
            </Tooltip>
          </li>

          <li className='Deadline cursorPointer btn_box'>
            <Tooltip title={deal_data?.reminder?.id ? `${t('DealDesk.Edit_Reminder')}` : `${t('DealDesk.Set_Reminder')}`}>
              <Button className={deal_data?.reminder ? 'lightBG reminderNotifyIcon' : 'lightBG '}
                onClick={openAddReminder}
              // disabled={!OwnerIsLoggin}
              >
                <img
                  src={AUTH.GetThemedImage('Quick_Reminder')}
                  alt={`${t('DealDesk.Date_Time_Picker')}`}
                  className='icon'
                />
              </Button>
            </Tooltip>
          </li>

          <li className='btn_box'>
            <Tooltip title={`${t('DealDesk.View_All_Reminders')}`}>
              {!AUTH.checkPermission(46, 'list') ? (
                <Button disabled={true}>
                  <img
                    src={AUTH.GetThemedImage('List_view')}
                    alt={`${t('DealDesk.View_All_Reminders')}`}
                    className='icon'
                  />
                  <span className='ml-5'>
                    {deal_data?.total_reminder_count
                      ? deal_data?.total_reminder_count
                      : 0}
                  </span>
                </Button>
              ) : (
                <Button onClick={() => openViewAllReminder()}
                // disabled={!OwnerIsLoggin}
                >
                  <img
                    src={AUTH.GetThemedImage('List_view')}
                    alt={`${t('DealDesk.View_All_Reminders')}`}
                    className='icon'
                  />
                  <span className='ml-5'>
                    {deal_data?.total_reminder_count
                      ? deal_data?.total_reminder_count
                      : 0}
                  </span>
                </Button>
              )}
            </Tooltip>
          </li>
          <li className='btn_box'>
            <Tooltip title={`${t('DealDesk.Change_View')}`}>
              <Select
                defaultValue={
                  <img src={AUTH.GetThemedImage('List_view')} alt='' className='icon' />
                }
                onSelect={handleChange}
                disabled={dealDeskTypeArr.includes(
                  deal_data?.lead_information?.is_ticket
                )}
              >
                <Option value='Kanban_View'>
                  <img src={AUTH.GetThemedImage('Kanban_View')} alt='' className='icon' />
                </Option>
                <Option value='List_view'>
                  <img src={AUTH.GetThemedImage('List_view')} alt='' className='icon' />
                </Option>
              </Select>
            </Tooltip>
          </li>
        </ul>

        <LeadCloneComponent
          visible={cloneDrawer}
          close={closeCloneDrawer}
          cloneNotes={cloneNotes}
          cloneLeadId={deal_data.lead_information.id}
          isFromDealDesk={true}
        />

        <EditOrgComponent
          transactionId={transactionId}
          setId={setId}
          visible={editOrgModal}
          close={closeEditOrgModal}
        />
        <EditPeopleComponent visible={editPeopleModal} close={closePeopleEditDrawer} />
        <EditLeadComponent
          visible={editLeadDrawer}
          close={closeEditLeadDrawer}
          isFromDealDesk={true}
        />
        <EditTicketComponent
          visible={editTicketDrawer}
          close={closeTicketEditDrawer}
          isFromTicketDesk={true}
        />

        <DeleteTicketComponent visible={deleteTicketDrawer} close={closeDeleteDrawer} />

        <DeleteLeadComponent
          visible={deleteLeadDeleteDrawer}
          lineText={`${t('DealDesk.Remove_Lead')}?`}
          close={closeLeadDeleteDrawer}
        />

        <ReminderAddComponent
          visible={addReminder}
          close={closeAddReminder}
          isDeal={true}
        />
        {/* <OrgHourglassConfirmElements
          visible={openHourglassModalOrg}
          close={closeOrgHourglassModal}
          // hourglassConfirm={orgHourglassConfirm}
          isFromDealDesk={true}
        />
        <PeopleHourglassConfirmElements
          visible={openHourglassModalPeople}
          close={closePeopleConfirmHourglassModal}
          // hourglassConfirm={peopleHourglassConfirm}
          isFromDealDesk={true}
        /> */}
        <TicketHourglassConfirmElements
          visible={openHourglassModalTicket}
          close={closeTicketConfirmHourglassModal}
          hourglassConfirm={ticketHourglassConfirm}
          isFromDealDesk={true}
        />
        {/* <LeadHourglassConfirmElements
          visible={openHourglassModalLead}
          close={closeLeadConfirmHourglassModal}
          hourglassConfirm={leadHourglassConfirm}
          resetTime={leadHourglassTime}
          isFromDealDesk={true}
        /> */}
        <CloneTicketComponent
          visible={cloneTicketDrawer}
          cloneNotesTicket={cloneNotesTicket}
          leadID={deal_data?.lead_information?.id}
          close={closeTicketCloneDrawer}
        />
        <StatusConfirmElements
          visible={openleadStatusOptins}
          close={closeleadStatusOptins}
          openShortfall={openShortfall}
          statusUpdateData={statusUpdateData}
          isFromDealDesk={true}
        />
        <ConvertticketComponent
          visible={convertTicketModal}
          close={closeConvertModal}
          convertTicketTempData={convertTicketTempData}
        />
        <LeadDateTimeConfirmElements
          visible={openDateTimeModal}
          close={closeDateTimeModal}
          date={date}
          reminderConfirm={reminderConfirm}
          isFromDealDesk={true}
        />
        <TicketDateTimeConfirmElements
          visible={ticketDateTimeModal}
          close={closeDateTimeModal}
          date={date}
          reminderConfirm={reminderConfirm}
          isFromDealDesk={true}
        />
        <PeopleDateTimeConfirmElements
          visible={peopleDateTimeModal}
          close={closeDateTimeModal}
          date={date}
          reminderConfirm={reminderConfirm}
          isFromDealDesk={true}
        />
        <OrgDateTimeConfirmElements
          visible={orgDateTimeModal}
          close={closeDateTimeModal}
          date={date}
          reminderConfirm={reminderConfirm}
          isFromDealDesk={true}
        />

        <ReminderEditComponent
          visible={editReminder}
          close={closeEditReminder}
          isFromDealDesk={true}
        />
        <ViewAllReminderListComponent
          visible={visibleAllReminder}
          close={closeViewAllReminder}
          isFromDealDesk={true}
        />
      </>
    )
  );
});
export default ButtonsComponent;
