import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../../component/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';

const TimesTrackElement = ({ form, formDataList, handleChange }) => {
  const { t } = useTranslation();

  const handleIdleChange = (e, type) => {
    if (type === 'min') {
      if (e < 0) {
        form.setFieldsValue({
          idle_min: 0
        });
      }
      else if (e > form.getFieldValue('idle_max')) {
        form.setFieldsValue({
          idle_min: form.getFieldValue('idle_max')
        });
      }
    }
    else if (type === 'max') {
      if (e < form.getFieldValue('idle_min')) {
        form.setFieldsValue({
          idle_max: form.getFieldValue('idle_min')
        });
      }
      else if (e > 999) {
        form.setFieldsValue({
          idle_max: 999
        });
      }
    }
  }

  const handleTimeSpentChange = (e, type) => {
    if (type === 'min') {
      if (e < 0) {
        form.setFieldsValue({
          timespent_min: 0
        });
      }
      else if (e > form.getFieldValue('timespent_max')) {
        form.setFieldsValue({
          timespent_min: form.getFieldValue('timespent_max')
        });
      }
    }
    else if (type === 'max') {
      if (e < form.getFieldValue('timespent_min')) {
        form.setFieldsValue({
          timespent_max: form.getFieldValue('timespent_min')
        });
      }
      else if (e > 999) {
        form.setFieldsValue({
          timespent_max: 999
        });
      }
    }
  }

  return (
    <>
      {formDataList.includes('idle_min') && formDataList.includes('idle_max') && (
        <Col span={8}>
          <Row gutter={12}>
            <Col span={12}>
              <InputComponent
                label={t('Reports.Idle_Title_Min')}
                name='idle_min'
                min={0}
                max={999}
                type={'number'}
                placeholder={t('Reports.Min')}
                onChange={(e) => {
                  handleChange();
                  handleIdleChange(e, 'min')
                }}
                tooltip={t('Reports.Idle_Title_Min')}
                rules={vsmTransactionReport.validation.idle_min}
                autoComplete='off'
              />
            </Col>
            <Col span={12}>
              <InputComponent
                label={t('Reports.Max')}
                name='idle_max'
                type={'number'}
                min={0}
                max={999}
                placeholder={t('Reports.Max')}
                onChange={(e) => {
                  handleChange(e);;
                  handleIdleChange(e, 'max')
                }}
                tooltip={t('Reports.Idle_Title_Max')}
                rules={vsmTransactionReport.validation.idle_max}
                autoComplete='off'
              />
            </Col>
          </Row>
        </Col>
      )}
      {formDataList.includes('timespent_min') && formDataList.includes('timespent_max') && (
        <Col span={8}>
          <Row gutter={12}>
            <Col span={12}>
              <InputComponent
                label={t('Reports.Timespent_Title_Min')}
                name='timespent_min'
                placeholder={t('Reports.Min')}
                onChange={(e) => {
                  handleChange();
                  handleTimeSpentChange(e, 'min')
                }}
                tooltip={t('Reports.Timespent_Title_Min')}
                rules={vsmTransactionReport.validation.timespent_min}
                maxLength={100}
                autoComplete='off'
                type={'number'}
                min={0}
                max={999}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                label={t('Reports.Max')}
                name='timespent_max'
                placeholder={t('Reports.Max')}
                onChange={(e) => {
                  handleChange();
                  handleTimeSpentChange(e, 'max')
                }}
                tooltip={t('Reports.Timespent_Title_Max')}
                rules={vsmTransactionReport.validation.timespent_min}
                autoComplete='off'
                type={'number'}
                min={0}
                max={999}
              />
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
};

export default TimesTrackElement;
