import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TopSelection from './component/TopSelection';
import ListComponent from './component/ListComponent';
import ViewComponent from './../LeadListing/component/ViewComponent';
import EditComponent from './../LeadListing/component/EditComponentNew';
import DeleteComponent from './../LeadListing/component/DeleteComponent';
import CloneComponent from './../LeadListing/component/CloneComponentNew';

import useStore from '../../store';
import Unauthorized from '../Unauthorized';
//import TrackTimeComponent from '../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../component/TimeTracker';

const Promotion = observer(() => {
  const { t } = useTranslation()
  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { SETTINGS, AUTH, LEADSSTORE } = useStore();

  //Edit drawer
  const openEditDrawer = (data, isEditOpen) => {
    setEditOpen(isEditOpen);
    LEADSSTORE.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
    setEditOpen(false);
  };

  //View drawer
  const openViewDrawer = (data) => {
    //if (AUTH.checkPermission(10, 'view-details')) {
    LEADSSTORE.setViewValues(data);
    setViewDrawer(true);
    //}
  };
  const closeViewDrawer = () => setViewDrawer(false);

  //open delet drawer
  const openDeleteDrawer = (data) => {
    LEADSSTORE.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  //Clone drawer
  const openCloneDrawer = (data) => {
    LEADSSTORE.setCloneValues(data);
    setCloneDrawer(true);
  };
  const closeCloneDrawer = () => setCloneDrawer(false);

  return (
    <>
      {!AUTH.checkPermission(2, 'list') ? (
        <Unauthorized />
      ) : (
        <div className={SETTINGS.fullscreen_class}>
          <TopSelection />
          <ListComponent
            openViewDrawer={openViewDrawer}
            openEditDrawer={openEditDrawer}
            openDeleteDrawer={openDeleteDrawer}
            openCloneDrawer={openCloneDrawer}
          />

          <ViewComponent
            visible={viewDrawer}
            openEditDrawer={openEditDrawer}
            close={closeViewDrawer}
          />
          <EditComponent
            visible={editDrawer}
            editOpen={editOpen}
            close={closeEditDrawer}
          />
          <DeleteComponent
            visible={deleteDrawer}
            close={closeDeleteDrawer}
            lineText={`${t('Common_Data.Remove_Lead')}?`}
          />
          <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />
          {/* <TrackTimeComponent pageName={'promotion'} /> */}
          <TimeTracker stop={viewDrawer || editDrawer || cloneDrawer}/>
        </div>
      )}
    </>
  );
});

export default Promotion;
