import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { ActionRenderer } from './GridComponent';
import { vsmCommon, vsmNotify } from '../../../config/messages';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { useTranslation } from 'react-i18next';

const ListClientSideComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    TagsStore,
    AUTH,
    DEALDESKSTORE: { deal_data },
    RIGHTSECTTION_TAGSTICKETS: { updateTagsSequence, fetchTagsTicketData },
  } = useStore();
  const {
    openDeleteDrawer,
    openEditDrawer,
    openCloneDrawer,
    openViewDrawer,
    TagTicketModule,
    WasteManagement,
  } = props;

  useEffect(() => {
    TagsStore.getList({
      leads_transactions_id: deal_data?.lead_information?.id,
    });
  }, [TagsStore, deal_data?.lead_information?.id]);

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: '',
        field: 'drag',
        cellClass: 'cellClass',
        width: 70,
        filter: false,
        sortable: false,
        rowDrag: TagTicketModule,
      },
      {
        headerName: `${t('Common_Data.Creator')}`,
        field: 'created_by_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Created')}`,
        field: 'created_at',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return AUTH.getLocalTime(params?.data?.Created, AUTH.global_dateformat);
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Master')} ${t('Tags.Ticket')}`,
        field: 'master_ticket.ticket_subject',
        cellClass: 'cellClass',

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Subject')}`,
        field: 'tag_subject',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Tags.Tag')} ${t('Common_Data.Number')}`,
        field: 'tag_number',
        cellClass: 'cellClass',

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation.organisation_name',
        cellClass: 'cellClass',

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        field: 'tag_content',
        cellRenderer: (params) => {
          var a = document.createElement('div');
          a.innerHTML = params.data.tag_content;
          return a;
        },
        cellClass: 'cellClass',

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Tags.Held')}`,
        field: 'tagholder',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? AUTH.getLocalTime(params.data.deadline_date, AUTH.global_dateformat)
            : null;
        },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        field: 'tag_status_name',
        cellClass: 'cellClass',

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,
        field: 'updated_at',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return AUTH.getLocalTime(params?.data?.updated_at, AUTH.global_dateformat);
        },

        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        field: 'created_by_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `# ${t('Common_Data.ID')}`,
        field: 'id',
        cellClass: 'cellClass',

        filter: false,
        sortable: false,
        //rowDrag: TagTicketModule,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: !TagTicketModule ? (WasteManagement === true ? 110 : 200) : 110,
        filter: false,
        sortable: false,
        pinned: 'right',
      },
    ],
  };

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper TicketsGrid'
        style={{
          height: TagTicketModule ? '70vh' : 'calc(100vh - 11rem)',
        }}
      >
        <AgGridReact
          rowData={TagsStore.list_data}
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: true,
            width: 180,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            openDeleteDrawer,
            openEditDrawer,
            openCloneDrawer,
            openViewDrawer,
            TagTicketModule,
            WasteManagement,
          }}
          onGridReady={TagsStore.setupGrid}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: !TagTicketModule,
            paginationPageSize: 10,
            animateRows: true,
            onRowDragEnd: (event) => {
              let payload = {
                ticket_sequences: [
                  {
                    ticket_id: event.node.data.id,
                    sequence_no: event.overIndex === 0 ? 1 : event.overIndex,
                  },
                ],
              };
              updateTagsSequence(payload)
                .then(() => {
                  vsmNotify.success({
                    message: `${t('Common_Data.Tags')} ${t('Tags.Sequence_Updated')}.`,
                  });
                  fetchTagsTicketData(deal_data?.lead_information?.id);
                })
                .catch((e) => { })
                .finally(() => { });
            },
            onRowDoubleClicked: (event) => openViewDrawer(event?.data),
          }}
          rowDragManaged={true}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
        />
      </div>
    </>
  );
});
export default ListClientSideComponent;
