import React, {
  useState,
  //, useEffect
} from 'react';
import { observer } from 'mobx-react';
import { DownOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
//import InputComponent from '../../component/InputComponent';
import useStore from '../../store';
import SearchModal from './component/SearchModal';
//import { vsmNotify, vsmReminders } from '../../config/messages';
import { default as ViewReminderComponent } from '../../page/Reminders/component/ViewComponent';
import TabComponent from './elements';
import { useTranslation } from 'react-i18next';

const TopNotificationModule = observer((props) => {
  const {
    AUTH,
    HEADERREMINDERSSTORE,
    SPANNOTESTORE: { unReadNote_count, urgentNote_count, criticalNote_count },
    REMINDERSSTORE,
    TimeTrackerStore
  } = useStore();
  const [viewModal, setViewModal] = useState(false);
  // const [reminderList, setReminderList] = useState(false);
  const [viewReminder, setViewReminder] = useState(false);
  const [topnotificationViewIDType, setTopnotificationViewIDType] = useState(null);

  const openReminderViewDrawer = (data, type) => {
    if (AUTH.checkPermission(46, 'view')) {
      REMINDERSSTORE.setViewValues(data);
      setTopnotificationViewIDType(type);
      setViewReminder(true);
      TimeTrackerStore.setStop(true);
    }
  };
  const { t } = useTranslation()
  const closeReminderViewDrawer = () => {
    setTopnotificationViewIDType(null);
    setViewReminder(false);
    TimeTrackerStore.setStop(false);
  };

  /*useEffect(() => {
    HEADERREMINDERSSTORE.getList();
  }, [HEADERREMINDERSSTORE]);*/

  function onChange(checkedValues) {
    let payload = {
      reminder_id: [checkedValues],
    };

    HEADERREMINDERSSTORE.readReminder(payload).then(() => {
      // vsmNotify.success({
      //   message: vsmReminders.read,
      // });
    });
  }

  const openViewModal = (data) => {
    setViewModal(true);
  };
  const closeViewModal = () => setViewModal(false);

  return (
    AUTH.app_color_theme && (
      <div className='TopNotificationBar'>
        {(AUTH.checkPermission(47, 'people') ||
          AUTH.checkPermission(47, 'organisation') ||
          AUTH.checkPermission(47, 'used-leads') ||
          AUTH.checkPermission(47, 'opportunity') ||
          AUTH.checkPermission(47, 'deals') ||
          AUTH.checkPermission(47, 'tags') ||
          AUTH.checkPermission(47, 'tickets') ||
          AUTH.checkPermission(47, 'reminders')) && (
            <strong className='nos border-right'>
              <Tooltip title={`${t('Common_Data.Advance_Search')}`}>
                <img
                  className='bar_icon'
                  alt=''
                  src={AUTH.GetThemedImage('Search_icon')}
                  onClick={openViewModal}
                />
              </Tooltip>
            </strong>
          )}

        {AUTH.checkPermission(46, 'list') && (
          <>
            <strong className='nos'>
              <img className='bar_icon' alt='' src={AUTH.GetThemedImage('Reminders')} />
            </strong>
            <Tooltip title={`${t('TopNotificationModule.Open_Tomorrow')}`}>
              <strong className='nos gray border-right'>{unReadNote_count}</strong>
            </Tooltip>
            <Tooltip title={`${t('TopNotificationModule.Open_Today')}`}>
              <strong className='nos blue border-right'>{urgentNote_count}</strong>
            </Tooltip>
            <Tooltip title={`${t('TopNotificationModule.OverDue_Still')}`}>
              <strong className='nos red'>{criticalNote_count}</strong>
            </Tooltip>
            <Popover
              placement='bottomRight'
              content={
                <TabComponent
                  onChange={onChange}
                  openReminderViewDrawer={openReminderViewDrawer}
                />
              }
              title=''
              overlayClassName='topRightNotification'
              trigger='click'
            >
              <DownOutlined className='bar_icon' />
            </Popover>
          </>
        )}
        <SearchModal visible={viewModal} close={closeViewModal} />
        <ViewReminderComponent
          visible={viewReminder}
          close={closeReminderViewDrawer}
          topnotificationViewIDType={topnotificationViewIDType}
          fromSideBar
        />
      </div>
    )
  );
});

export default TopNotificationModule;
