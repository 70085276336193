import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class CredebtClassificationStrore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  agGridRelOrg = null;
  deleteValues = null;
  editValues = null;
  viewValues = null;
  industryValues = null;
  fetching = false;

  initialValues = {
    name: null,
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };
  setEditValues = (data) => {
    this.editValues = data;
  };
  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('cte_classification_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'cte_classification_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // reset function
  handleReset = () => {
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.setSortModel(null);
    this.agGrid.api.onFilterChanged(null);
  };

  getList = (payload = {}) => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.columnApi.getColumnState();
    }
    payload.api_type = 'list';

    return Axios.post(`industry/list`, payload).then(({ data }) => {
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;

      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.columnApi.applyColumnState({ state: sort });
      }
      let columnConfig = localStorage.getItem('cte_classification_grid');
      if (this.agGrid && this.agGrid.columnApi && columnConfig) {
        this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
      }
      return data;
    });
  };

  getRecordDetail = (formdata, setVariable = true) => {
    return Axios.get(`industry/read/${formdata.industryid}`)
      .then(({ data }) => {
        if (setVariable) {
          this.industryValues = data.data;
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        this.industryValues = null;
        return Promise.reject(data);
      });
  };

  setPeopleValues = (data) => {
    this.industryValues = data;
  };

  AddData = (data) => {
    return Axios.post(`industry/add`, data)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  EditData = (data) => {
    return Axios.post(`industry/edit/${data.id}`, data)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  DeleteData = (industry_id) => {
    return Axios.get(`industry/delete/${industry_id}`)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!['logo_url', 'date_of_birth'].includes(key)) {
        data[key] && formData.append(key, data[key]);
      }
    });
    return formData;
  };
}

decorate(CredebtClassificationStrore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  agGridRelOrg: observable,
  deleteValues: observable,
  industryValues: observable,
  initialValues: observable,
  viewValues: observable,
  editValues: observable,
  setPageSize: action,
  getList: action,
  setupGrid: action,
  setDeleteValues: action,
  getRecordDetail: action,
  setPeopleValues: action,
  setRPeopleValues: action,
  AddData: action,
  EditData: action,
  DeleteData: action,
  JsonToFormData: action,
  setEditValues: action,
  onFilterChanged: action,
  onGridChanged: action,
  handleReset: action,
});
