import React from 'react';
import {
  AutoComplete,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Tooltip,
  Slider
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CurrencyFormat } from '../utils/GlobalFunction';
import DatePickerCostume from './DatePickerCostume';

const InputComponent = ({
  hasFeedback,
  hidden,
  label,
  required,
  rules,
  name,
  tooltip,
  hint,
  type,
  options,
  initialValue,
  extra,
  note,
  style,
  className,
  min_value,
  max_value,
  max_label,
  min_label,
  post_fix,
  slider_width,
  valueFormatter,
  onSliderChange,
  rowclassName,
  classNameInput,
  showTime,
  validateStatus,
  help,
  ...rest
}) => {

  switch (type) {
    case 'date':
      return (
        <Form.Item
          label={label}
          tooltip={tooltip}
          extra={note}
          required={required ? true : false}
          hidden={hidden}
        >
          <Form.Item hasFeedback={hasFeedback} name={name} noStyle rules={rules}>
            {showTime ? <DatePickerCostume showTime {...rest} />
              : <DatePickerCostume {...rest} />}
          </Form.Item>
          {hint && (
            <Tooltip title={hint}>
              <InfoCircleOutlined className='ml-10' />
            </Tooltip>
          )}
        </Form.Item>
      );

    case 'date_range':
      return (
        <Form.Item
          label={label}
          tooltip={tooltip}
          extra={note}
          required={required ? true : false}
          hidden={hidden}
        >
          <Form.Item hasFeedback={hasFeedback} name={name} noStyle rules={rules}>
            <DatePicker.RangePicker {...rest} />
          </Form.Item>
          {hint && (
            <Tooltip title={hint}>
              <InfoCircleOutlined className='ml-10' />
            </Tooltip>
          )}
        </Form.Item>
      );

    case 'time':
      return (
        <Form.Item
          label={label}
          tooltip={tooltip}
          extra={note}
          required={required ? true : false}
          hidden={hidden}
        >
          <Form.Item hasFeedback={hasFeedback} name={name} noStyle rules={rules}>
            <DatePickerCostume
            type='time'
            {...rest}
            />
          </Form.Item>
          {hint && (
            <Tooltip title={hint}>
              <InfoCircleOutlined className='ml-10' />
            </Tooltip>
          )}
        </Form.Item>
      );

    case 'number':
      return (
        <Form.Item
          label={label}
          tooltip={tooltip}
          extra={note}
          required={required ? true : false}
          hidden={hidden}
        >
          <Form.Item
            hasFeedback={hasFeedback}
            name={name}
            noStyle
            rules={rules}
            onChange={rest.onChange}
          >
            <InputNumber {...rest} />
          </Form.Item>
          {hint && (
            <Tooltip title={hint}>
              <InfoCircleOutlined className='ml-10' />
            </Tooltip>
          )}
        </Form.Item>
      );

    case 'textarea':
      return (
        <Form.Item
          label={label}
          tooltip={tooltip}
          extra={note}
          required={required ? true : false}
          hidden={hidden}
        >
          <Form.Item hasFeedback={hasFeedback} name={name} noStyle rules={rules}>
            <Input.TextArea rows={3} {...rest} />
          </Form.Item>
          {hint && (
            <Tooltip title={hint}>
              <InfoCircleOutlined className='ml-10' />
            </Tooltip>
          )}
        </Form.Item>
      );

    case 'select':
      if (options) {
        return (
          <Form.Item
            label={label}
            tooltip={tooltip}
            extra={note}
            className={rowclassName}
            required={required ? true : false}
            hidden={hidden}
            validateStatus={validateStatus}
            help={help}
          >
            <Form.Item
              hasFeedback={hasFeedback}
              name={name}
              noStyle
              rules={rules}
              initialValue={initialValue}
            >
              <Select
                className={options.className}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                }
                {...rest}
              >
                {options.values &&
                  options.values.map((item, index) => {
                    if (item) {
                      if (
                        (options.accepted_keys &&
                          options.accepted_keys.includes(
                            options.value_key ? item[options.value_key] : item.value
                          )) ||
                        (options.rejected_keys &&
                          !options.rejected_keys.includes(
                            options.value_key ? item[options.value_key] : item.value
                          )) ||
                        !options.rejected_keys
                      ) {
                        var text = item.text;
                        if (options.text_key) {
                          if (typeof options.text_key === 'object') {
                            text = '';
                            options.text_key.key.map(
                              (key, index) => (text += item[key] ? item[key] : key)
                            );
                          } else {
                            text = item[options.text_key];
                          }
                        }
                        return (
                          <Select.Option
                            key={index}
                            disabled={options.disabled_key ? item[options.disabled_key] : false}
                            value={
                              options.value_key ? item[options.value_key] : item.value
                            }
                            title={text}
                          >
                            {text}
                          </Select.Option>
                        );
                      }
                    }
                    return null;
                  })}
              </Select>
            </Form.Item>
            {extra}
            {hint && (
              <Tooltip title={hint}>
                <InfoCircleOutlined className='ml-10' />
              </Tooltip>
            )}
          </Form.Item>
        );
      }
      return null;

    case 'autocomplete':
      return (
        <Form.Item
          label={label}
          tooltip={tooltip}
          extra={note}
          required={required ? true : false}
          hidden={hidden}
        >
          <Form.Item
            hasFeedback={hasFeedback}
            name={name}
            noStyle
            rules={rules}
            initialValue={initialValue}
          >
            <AutoComplete
              options={options}
              filterOption={(inputValue, option) =>
                option.value &&
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              {...rest}
            />
          </Form.Item>
          {hint && (
            <Tooltip title={hint}>
              <InfoCircleOutlined className='ml-10' />
            </Tooltip>
          )}
        </Form.Item>
      );

    case 'radio_button':
      if (options) {
        return (
          <Form.Item
            label={label}
            tooltip={tooltip}
            extra={note}
            className={classNameInput}
            required={required ? true : false}
            hidden={hidden}
          >
            <Form.Item
              hasFeedback={hasFeedback}
              name={name}
              noStyle
              rules={rules}
              initialValue={initialValue}
            >
              <Radio.Group buttonStyle='solid' {...rest} className={options.className}>
                {options.values &&
                  options.values.map((item, index) => {
                    if (item) {
                      if (
                        (options.accepted_keys &&
                          options.accepted_keys.includes(
                            options.value_key ? item[options.value_key] : item.value
                          )) ||
                        (options.rejected_keys &&
                          !options.rejected_keys.includes(
                            options.value_key ? item[options.value_key] : item.value
                          )) ||
                        !options.rejected_keys
                      ) {
                        return (
                          <Radio.Button
                            style={{ flex: 1, textAlign: 'center' }}
                            key={index}
                            value={
                              options.value_key ? item[options.value_key] : item.value
                            }
                          >
                            {options.text_key ? item[options.text_key] : item.text}
                          </Radio.Button>
                        );
                      }
                    }
                    return null;
                  })}
              </Radio.Group>
            </Form.Item>
            {hint && (
              <Tooltip title={hint}>
                <InfoCircleOutlined className='ml-10' />
              </Tooltip>
            )}
          </Form.Item>
        );
      }
      return null;

    case 'password':
      return (
        <Form.Item
          label={label}
          tooltip={tooltip}
          extra={note}
          required={required ? true : false}
          hidden={hidden}
        >
          <Form.Item hasFeedback={hasFeedback} name={name} noStyle rules={rules}>
            <Input.Password {...rest} />
          </Form.Item>
          {hint && (
            <Tooltip title={hint}>
              <InfoCircleOutlined className='ml-10' />
            </Tooltip>
          )}
        </Form.Item>
      );

    case 'labelOnly':
      return (
        <Form.Item
          label={label}
          tooltip={tooltip}
          extra={note}
          style={style}
          required={required ? true : false}
          hidden={hidden}
          className={`labelOnly ${className}`}
        >
          {hint && (
            <Tooltip title={hint}>
              <InfoCircleOutlined className='ml-10' />
            </Tooltip>
          )}
        </Form.Item>
      );

    case 'range-bar':
      return (
        <Form.Item
          noStyle
          name={name}
        >
          <div className={'slider-box'}>
            <label style={{ width: `calc((100% - ${slider_width}) / 2)` }}>
              <span id={`${name}_min`} className='labelInput' contentEditable={false}>
                {valueFormatter ?
                  CurrencyFormat({ value: min_label })
                  :
                  min_label
                }
              </span>
              {post_fix}
            </label>
            <Slider
              max={max_value}
              onChange={onSliderChange}
              onSliderChange={onSliderChange}
              range={true}
              min={min_value}
              style={{ width: slider_width }}
              {...rest}
            />
            <label className='text-right' style={{ width: `calc((100% - ${slider_width}) / 2)` }}>
              <span className='labelInput' contentEditable={false}>
                {valueFormatter ? CurrencyFormat({ value: max_label }) : max_label}
              </span>
              {post_fix}
            </label>
          </div>
        </Form.Item>
      );

    default:
      return (
        <Form.Item
          label={label}
          tooltip={tooltip}
          extra={note}
          required={required ? true : false}
          hidden={hidden}
          validateStatus={validateStatus}
        >
          <Form.Item hasFeedback={hasFeedback} name={name} noStyle rules={rules}>
            <Input {...rest} />
          </Form.Item>
          {hint && (
            <Tooltip title={hint}>
              <InfoCircleOutlined className='ml-10' />
            </Tooltip>
          )}
        </Form.Item>
      );
  }
};

export default InputComponent;
