import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// -------------- For Resource,JSON will be resides in public/locales/{{language_name}}/translations.json ----- //(language_name will be dynamic)

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: () => {
        // check the domain
        return `${window.location.origin}/locales/{{lng}}/{{ns}}.json`;
      },
      queryStringParams: { v: `${process.env.React_APP_version}` },
    },
    // requestOptions: {
    //   cache: 'no-store',
    // },

    fallbackLng: 'en', // Works as default language
    debug: false,
    ns: ['translations'], // Name Space translations
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

  });

export default i18n;
