import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
export default class ScoreLibraryStore {
  score_library_data = null;
  CopiedScoreData = null;
  Score = [];
  saveScoreForm = null;
  editValues = null;
  tabChanged = '8';
  apiCalled = false;
  libraryGridLoader = true;

  notifyAutosave = false;

  setEditValues = (data) => {
    this.editValues = Object.assign({}, data);
  };

  setTabChanged = (data) => {
    this.tabChanged = data;
    this.apiCalled = false;
  };

  saveScoreData = (id, formdata) => {
    this.libraryGridLoader = true;
    let APIURL = `/score-library/create`;
    if (id) {
      APIURL = `/score-library/edit/${id}`;
    }
    return Axios.post(APIURL, formdata)
      .then(({ data }) => {
        this.libraryGridLoader = false;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  deleteScoreData = (id, formdata) => {
    let APIURL = `/score-library/delete/${id}`;
    return Axios.get(APIURL, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  fetchScoreLibrary = (payload = {}) => {
    this.apiCalled = true;
    return Axios.post(`/score-library/list`, payload)
      .then(({ data }) => {
        if (data && data.data) {
          data.data.map((libObj, index) => {
            libObj.score_json = JSON.parse(libObj.score_json);
            return null;
          });
        }
        this.score_library_data = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  SetScore = (data) => {
    this.Score = data;
  };

  setCopiedScoreData = (data) => {
    this.CopiedScoreData = data;
  };

  setSaveScoreform = (data) => {
    this.saveScoreForm = data;
  };

  calculateHeightOnEdit = () => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  setNotifyAutosave = (data) => {
    this.notifyAutosave = data;
  };
}
decorate(ScoreLibraryStore, {
  score_library_data: observable.shallow,
  Score: observable.shallow,
  editValues: observable,
  CopiedScoreData: observable,
  tabChanged: observable,
  apiCalled: observable,
  notifyAutosave: observable,
  libraryGridLoader:observable,
  
  saveScoreData: action,
  fetchScoreLibrary: action,
  SetScore: action,
  setCopiedScoreData: action,
  setSaveScoreform: action,
  setEditValues: action,
  setTabChanged: action,
  calculateHeightOnEdit: action,
  setNotifyAutosave: action,
  deleteScoreData: action,
});
