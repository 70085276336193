import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import MergeTicketListing from './MergeTicketListing';
import { vsmNotify } from '../../../config/messages';
import ConfirmMergeComponent from './ConfirmMergeComponent';
import { useTranslation } from 'react-i18next';

const MergeTicket = observer((props) => {
  const {t} = useTranslation()
  const [saving, setSaving] = useState(false);
  const [confirmMerge, setconfirmMerge] = useState(false);

  const { selectedMergeData, visible } = props;

  const {
    TicketsStore,
    TicketsStore: { masterTicketToMerge, setMasterTicketToMerge, handleMergeTicket },
  } = useStore();

  const openConfirmMergeModal = () => {
    setconfirmMerge(true);
  };

  const closeConfirmMerge = () => {
    setconfirmMerge(false);
    setSaving(false);
  };

  useEffect(() => {}, [masterTicketToMerge]);

  const close = () => {
    setMasterTicketToMerge(null);
    setSaving(false);
    setconfirmMerge(false);

    props.close();
  };
  const handleSubmit = () => {
    setSaving(true);
    let selectedArray = [];

    selectedMergeData &&
      selectedMergeData.forEach((element) => {
        selectedArray.push(element.id);
      });
    let payload = {
      selectedTickets: selectedArray,
      mergeTicket: masterTicketToMerge,
    };

    handleMergeTicket(payload)
      .then((data) => {
        if (TicketsStore.agGrid && !TicketsStore.isSearchPage) {
          TicketsStore.agGrid.api.deselectAll();
          TicketsStore.setupGrid(TicketsStore.agGrid);
        }
        if (TicketsStore.isSearchPage) {
          TicketsStore.getListSearch(TicketsStore.search_payload);
          TicketsStore.agGrid.api.deselectAll();
        }
        close();
        vsmNotify.success({
          message: `${t('Common_Data.Ticket')} ${t('Common_Data.Merged')} ${t('Tickets.Successfull')}!`,
        });
      })
      .catch((e) => {
        if (e.errors && e.errors.length > 0) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name[1] = parseInt(x.name[1]);
            }
          });
        }
      })
      .finally(() => setSaving(false));
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Merge')} ${t('Common_Data.Ticket')}`}
      visible={visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      width={1200}
      destroyOnClose
      footer={[
        <Button
          key='1'
          shape='round'
          size='medium'
          disabled={masterTicketToMerge ? false : true}
          // loading={saving}
          onClick={() => openConfirmMergeModal()}
        >
          {t('Common_Data.Merge')} {t('Common_Data.Ticket')}
        </Button>,
      ]}
    >
      <h5>
        {t('Tickets.Primary_Ticket')}.
      </h5>
      <MergeTicketListing selectedMergeData={selectedMergeData} />
      <ConfirmMergeComponent
        visible={confirmMerge}
        close={closeConfirmMerge}
        handleSubmit={handleSubmit}
        saving={saving}
        setSaving={setSaving}
      />
    </Modal>
  );
});
export default MergeTicket;