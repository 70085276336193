import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Button, Dropdown, Tooltip, Spin } from "antd";
import useStore from "../../../../store";
import { vsmNotify, vsmDealDesk } from "../../../../config/messages";
import NoteDeleteConfirm from "./NoteDeleteConfirm";
import copy from "copy-to-clipboard";
import NoteTagSelection from "./NoteTagSelection";
import AddKnowledgeFromNote from "./AddKnowledgeFromNote";
import NoteDateTimeConfirmElements from "./NoteDateTimeConfirmElements";
import ConfirmDraftNoteEdit from "./ConfirmDraftNoteEdit";
import { useTranslation } from "react-i18next";
import DatePickerCostume from "../../../../component/DatePickerCostume";

const Footer = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    DEALDESKSTORE,
    DEALDESKSTORE: {
      deal_data,
      colorlist,
      msgBoxIndex,
      fetchColorList,
      setDealdeskActiveTab,
      setActiveNoteEditDated,
      setDealdeskActiveNoteTab,
      msgBoxAPINoteID,
      activeNoteEditDated,
      //setDealData,
      removeDraftNoteContent,
      changeColor,
    },
    DEALDESKNOTESEQUENCE: {
      fetchSequenceNotes,
      setAutoSavedTabID,
      addUpdateNoteList,
      setSeqNoteDisplayData,
      sequenceFilterNotes,
    },
    DEALDESKNOTESOUTBOUND: { fetchLockListGroupUsers },
    RIGHTSECTTION_TAGSTICKETS: { fetchTagsTicketData },
  } = useStore();
  const {
    openExpandModalCom,
    openModalCom,
    commentList,
    openExpandModalSum,
    openModalSum,
    summaryList,
    openExpandModalSeq,
    openModalSeq,
    sequenceList,
    close,
  } = props;

  const [tagsUsers, setTagsUsers] = useState(null);
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [tagPopoverVisible, setTagPopoverVisible] = useState(false);
  const [convertTicketPopoverVisible, setConvertTicketPopoverVisible] =
    useState(false);

  const [formTagUser, setFormTagUser] = useState([]);
  const [formTagGroup, setFormTagGroup] = useState([]);
  const [formTicketUser, setFormTicketUser] = useState(null);
  const [formTicketGroup, setFormTicketGroup] = useState(null);

  const [knowledgeVisible, setKnowledgeVisible] = useState(false);
  const [tempKnowledgeNoteData, setTempKnowledgeNoteData] = useState(null);

  //------------------START : NOTE REMINDER STATE -----------------------------//
  const [date, setDate] = useState();
  const [reminderConfirm, setReminderConfirm] = useState();
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  //------------------END : NOTE REMINDER STATE -----------------------------//

  //------------------START : DRAFT NOTE EDIT CONFIRMATION -----------------------------//
  const [draftNoteEditConfirmModal, setDraftNoteEditConfirmModal] =
    useState(false);
  //------------------END : DRAFT NOTE EDIT CONFIRMATION -----------------------------//

  const onCellKeyUp = async (e) => {
    if (tagsUsers && tagsUsers.userList && tagsUsers.userList.length > 0) {
      if (
        e.nativeEvent.ctrlKey ||
        e.nativeEvent.altKey ||
        e.nativeEvent.shiftKey
      ) {
        return false;
      }
      let keyPressed = e.nativeEvent.key;
      let result = tagsUsers.userList
        .filter(
          (x) => x.name.charAt(0).toUpperCase() === keyPressed.toUpperCase()
        )
        .shift();

      if (result) {
        let newUpdatedBox = await DEALDESKSTORE.addTag(
          props.msgBoxArray.get,
          result.id,
          result.name
        );
        await props.msgBoxArray.set(newUpdatedBox);

        if (deal_data?.lead_information?.id) {
          await setTimeout(() => {
            fetchTagsTicketData(deal_data?.lead_information?.id);
          }, 1000);
        }
      }
    }
  };

  const closeTagPopover = () => {
    setFormTagUser([]);
    setFormTagGroup([]);
    setTagPopoverVisible(false);
  };

  const closeTicketPopover = () => {
    setFormTicketUser(null);
    setFormTicketGroup(null);
    setConvertTicketPopoverVisible(false);
  };

  const convertNoteToTicket = () => {
    closeTicketPopover();
    DEALDESKSTORE.convertNoteToTicketDesk({
      lead_note_id: props.msgBoxArray?.get[msgBoxIndex].id,
      user_id: formTicketUser, //.join(),
      user_group_id: formTicketGroup, //.join(),
      parent_ticket_id: deal_data?.lead_information?.ticket_data?.id,
    }).then((res) => {
      vsmNotify.success({
        message: vsmDealDesk.successNoteConvertedToTicket,
      });
      fetchSequenceNotes(deal_data?.lead_information?.id);
      fetchTagsTicketData(deal_data?.lead_information?.id);
      /*if (res?.ticket_data) {
        let tempDealdata = deal_data;
        tempDealdata.lead_information.ticket_data = res?.ticket_data;
        setDealData(tempDealdata);
      }*/

      //closeTicketPopover();
      window.open(`/ticket/${res.ticket_data.ticket_number}`, "_blank");
    });
  };

  /* UPDATED : tagsContent POPOVER Content - Added Group  */
  const ticketContent = tagsUsers ? (
    <div className="ant-popover-inner-content multipleTag dealdeskCreateTicket">
      <div className="leadStatusOptins lockUserList createNotetoTicket">
        <ul className="mainList">
          {tagsUsers && tagsUsers.userList && (
            <li key="2">
              <span style={{ padding: "0 0 0 8px" }}>
                {t("DealDesk.Users_List")}
              </span>
              <ul>
                {tagsUsers.userList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        formTicketUser && formTicketUser === item.id
                          ? "active"
                          : ""
                      }
                      onClick={async () => {
                        if (formTicketUser && formTicketUser === item.id) {
                          setFormTicketUser(null);
                        } else {
                          setFormTicketUser(item.id);
                        }
                      }}
                    >
                      <Tooltip title={item.name}>{item.name}</Tooltip>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}
        </ul>
        <div className="multipleTagBtn">
          <Button
            size="medium"
            shape="round"
            disabled={formTicketUser ? false : true}
            onClick={() => convertNoteToTicket()}
          >
            {t("Common_Data.Save")}
          </Button>
          <Button
            size="medium"
            shape="round"
            onClick={() => {
              closeTicketPopover();
            }}
          >
            {t("Common_Data.Cancel")}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="ant-popover-inner-content">
      <ul className="popover-menu cursorPointer spinInside">
        <Spin tip={`${t("Common_Data.Load")}...`} />
      </ul>
    </div>
  );

  const closeRemoveConfirm = () => {
    setRemoveConfirm(false);
  };

  const openKnowledgeModal = (data) => {
    setTempKnowledgeNoteData(data);
    setKnowledgeVisible(true);
  };

  const closeKnowledgeModal = () => {
    setTempKnowledgeNoteData(null);
    setKnowledgeVisible(false);
  };

  //------------------START : Reminder Confirmation Functions----------------------------//
  const openConfirmDateTimeModal = (dateformat) => {
    if (dateformat) {
      setDate(dateformat);
      setReminderConfirm(props.msgBoxArray?.get[msgBoxIndex]);
      setOpenDateTimeModal(true);
    }
  };
  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    setOpenDateTimeModal(false);
  };

  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // };
  // const disabledHours = (e) => {
  //   let hours = [19, 20, 21, 22, 23];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };
  //------------------END: Reminder Confirmation Functions----------------------------//

  useEffect(() => {
    fetchColorList();
  }, [fetchColorList]);

  //-----------------START: NOTE EDIT FUNCTION ---------------------//
  const setEditNoteData = (useDraftNote = false) => {
    if ((msgBoxIndex || msgBoxIndex === 0) && props.msgBoxArray) {
      let newObj = Object.assign({}, props.msgBoxArray.get[msgBoxIndex]);
      let allMessageList = props.msgBoxArray.get;

      setDealdeskActiveTab("5");
      let noteActiveTab = "2";
      if (newObj?.notes_flag === 4) {
        // Inbound selection
        noteActiveTab = "1";
      }
      if (newObj?.notes_flag === 3) {
        // Outbound selection
        noteActiveTab = "2";
      }
      if (newObj?.notes_flag === 2) {
        // Comment selection
        noteActiveTab = "3";
      }
      if (newObj?.notes_flag === 1) {
        // Outbound selection
        noteActiveTab = "4";
      }
      setAutoSavedTabID(noteActiveTab);
      setDealdeskActiveNoteTab(noteActiveTab);
      if (useDraftNote) {
        let msgBoxArray = [...allMessageList];
        newObj.notes_description = newObj.draft_note;
        newObj.isEditAutoSaveData = true; // Flag added to hide Clear button during Edit Auto Save
        msgBoxArray[msgBoxIndex] = newObj;
        setActiveNoteEditDated(msgBoxArray);
        setDraftNoteEditConfirmModal(false);
      } else {
        let tempNoteData = newObj;
        if (tempNoteData.draft_note) {
          removeDraftNoteContent(tempNoteData).then((data) => {
            addUpdateNoteList(data.data, true);
            setActiveNoteEditDated(allMessageList);
          });
        } else {
          setActiveNoteEditDated(allMessageList);
        }
        setDraftNoteEditConfirmModal(false);
      }
    }
  };

  const checkNoteEdit = () => {
    if (msgBoxIndex || msgBoxIndex === 0) {
      if (props.msgBoxArray.get[msgBoxIndex]?.draft_note) {
        setDraftNoteEditConfirmModal(true);
        // To update last updated date in  headers with header api
        console.log(deal_data?.lead_information?.id);
        DEALDESKSTORE?.getDealDetail(deal_data?.lead_information?.id);
      } else {
        setEditNoteData();
      }
    } else {
      vsmNotify.error({
        message: vsmDealDesk.Notes.errorNoteSelection,
      });
    }
  };
  //------------------END:NOTE EDIT FUNCTION ---------------------//

  const updateNoteColor = (msgList, color) => {
    if (msgBoxIndex || msgBoxIndex === 0) {
      let newMsgList = changeColor(msgList, color);
      setSeqNoteDisplayData(newMsgList);
      sequenceFilterNotes("", "apply_current_filter");
      // To update last updated date in  headers with header api
      DEALDESKSTORE?.getDealDetail(deal_data?.lead_information?.id);
    } else {
      vsmNotify.error({
        message: vsmDealDesk.Notes.errorNoteSelection,
      });
    }
  };

  useEffect(() => {
    const adjustFooterWidth = () => {
      const footer = document.querySelector(".tabFooter");
      // const modalfooterRight = document.querySelector(".modalFooter");
      const displayTabsLeft = document.querySelector(".displayTabs");

      const parent = document.querySelector(".Dealdesk_MainTab");
      console.log("footer width left side inner", footer, displayTabsLeft);

      if (footer && parent) {
        console.log("footer width inserted inner", parent.offsetWidth);

        footer.style.width = `${parent.offsetWidth}px`;
        // modalfooterRight.style.width = `${parent.offsetWidth}px`;
        displayTabsLeft.style.width = `${parent.offsetWidth}px`;
      }
    };
    if (props.msgBoxArray?.get) {
      // Adjust width on load and resize
      adjustFooterWidth(); // Initial adjustment
      // Cleanup on unmount
    }
    window.addEventListener("resize", adjustFooterWidth);
    return () => window.removeEventListener("resize", adjustFooterWidth);
  }, [props.msgBoxArray?.get]);

  return (
    props.msgBoxArray?.get &&
    Array.isArray(props.msgBoxArray?.get) && (
      <div
        className="tabFooter displayTabs"
        id="summaryTab"
        style={{ position: "sticky" }}
      >
        {/*----------START: APPLY COLOR -------------------*/}
        {commentList === true ? null : (
          <div className="btnSectionBox">
            {!DEALDESKSTORE.disableFooterIcons &&
              colorlist &&
              colorlist.map((c, index) => (
                <Tooltip
                  title={
                    c.color_name !== "Reset Note"
                      ? c.color_name
                      : `${t("DealDesk.Reset_Note")}`
                  }
                  arrowPointAtCenter
                >
                  <Button
                    key={index}
                    size="small"
                    className={`colorBtn ${
                      c.color_name !== "Reset Note"
                        ? c?.color_code?.hex?.replace("#", "x")
                        : ""
                    }`}
                    onClick={() =>
                      !DEALDESKSTORE.disableFooterIcons &&
                      updateNoteColor(props.msgBoxArray.get, c.color_code.hex)
                    }
                    // disabled={DEALDESKSTORE.disableFooterIcons}
                  >
                    {c.color_name === "Reset Note" ? (
                      <img
                        src={AUTH.GetThemedImage("nocolor")}
                        alt=""
                        className="icon"
                      />
                    ) : (
                      c.color_name
                    )}
                  </Button>
                </Tooltip>
              ))}
          </div>
        )}
        {/*----------END: APPLY COLOR -------------------*/}

        {!DEALDESKSTORE.disableFooterIcons && (
          <div
            className={`${
              commentList === true ? "btnSectionBoxComment" : "btnSectionBox"
            }`}
          >
            {/*----------START: KNOWLEDGE -------------------*/}

            {(msgBoxIndex || msgBoxIndex === 0) && (
              <>
                <Button
                  size="small"
                  title={`${t("DealDesk.Set_Reminder")}`}
                  className="setReminderNoteFooter"
                >
                  <DatePickerCostume
                    name="Calendar"
                    className="cursor"
                    title={`${t("DealDesk.Calendar")}`}
                    showTime
                    disabledDate={true}
                    // hideDisabledOptions
                    // showTime={{ format: 'HH:mm' }}
                    // disabledHours={() => disabledHours()}
                    // minuteStep={15}
                    showNow={false}
                    onOk={(e) => openConfirmDateTimeModal(e)}
                  />
                  <img
                    className="icon"
                    title={`${t("DealDesk.Calendar")}`}
                    alt={`${t("DealDesk.Set_Reminder")}`}
                    src={AUTH.GetThemedImage("Calendar")}
                  />
                </Button>

                <Button
                  size="small"
                  title={`${t("Common_Data.Add_Knowledge")}`}
                  onClick={() => {
                    if (props.msgBoxArray && props.msgBoxArray?.get) {
                      openKnowledgeModal(props.msgBoxArray?.get[msgBoxIndex]);
                    }
                  }}
                >
                  <img
                    src={AUTH.GetThemedImage("Knowledge")}
                    alt=""
                    className="icon"
                  />
                </Button>
              </>
            )}

            {/*----------END: KNOWLEDGE -------------------*/}

            {/*----------START: CONVERT NOTE TO TICKET -------------------*/}
            {commentList === true ? (
              ""
            ) : (msgBoxIndex || msgBoxIndex === 0) &&
              props.msgBoxArray &&
              AUTH.user?.id &&
              !props.msgBoxArray?.get[msgBoxIndex]?.ticket_id &&
              (AUTH.user?.id ===
                props.msgBoxArray?.get[msgBoxIndex]?.created_by ||
                AUTH.user?.user_role?.user_role_id === 1) ? (
              <Dropdown
                overlay={ticketContent}
                destroyPopupOnHide
                overlayClassName="lockContentMenu"
                placement="topCenter"
                trigger="click"
                visible={convertTicketPopoverVisible}
                onVisibleChange={(x) => {
                  setConvertTicketPopoverVisible(x);
                }}
              >
                <Button size="small" onKeyPressCapture={onCellKeyUp}>
                  <img
                    src={AUTH.GetThemedImage("TicketToDeal")}
                    alt=""
                    className="icon"
                    title={`${t("DealDesk.Create_Ticket")}`}
                    onMouseEnter={() => {
                      if (!tagsUsers) {
                        fetchLockListGroupUsers().then((data) => {
                          setTagsUsers(data);
                        });
                      }
                    }}
                  />
                </Button>
              </Dropdown>
            ) : null}
            {/*----------END: CONVERT NOTE TO TICKET -------------------*/}

            {/*----------START: TAG SELECTION -------------------*/}
            {commentList === true ? null : (msgBoxIndex || msgBoxIndex === 0) &&
              props.msgBoxArray &&
              AUTH.user?.id &&
              (AUTH.user?.id ===
                props.msgBoxArray?.get[msgBoxIndex]?.created_by ||
                AUTH.user?.user_role?.user_role_id === 1) ? (
              <Dropdown
                overlay={
                  <NoteTagSelection
                    tagsUsers={tagsUsers}
                    formTagGroup={formTagGroup}
                    formTagUser={formTagUser}
                    setFormTagGroup={setFormTagGroup}
                    setFormTagUser={setFormTagUser}
                    closeTagPopover={closeTagPopover}
                    msgBoxArray={props.msgBoxArray}
                  />
                }
                overlayClassName="lockContentMenu"
                placement="topCenter"
                destroyPopupOnHide
                trigger="click"
                visible={tagPopoverVisible}
                onVisibleChange={(x) => {
                  if (!x) {
                    setFormTagUser([]);
                    setFormTagGroup([]);
                  } else {
                    if (
                      props.msgBoxArray &&
                      props.msgBoxArray.get &&
                      props.msgBoxArray.get.length > 0 &&
                      (msgBoxIndex || msgBoxIndex === 0) &&
                      props.msgBoxArray.get[msgBoxIndex]
                    ) {
                      if (
                        props.msgBoxArray.get[msgBoxIndex]["tag_user_id"] !==
                          undefined &&
                        props.msgBoxArray.get[msgBoxIndex].tag_user_id
                      ) {
                        setFormTagUser(
                          props.msgBoxArray.get[msgBoxIndex].tag_user_id
                        );
                      }
                      if (
                        props.msgBoxArray.get[msgBoxIndex]["tag_group_id"] !==
                          undefined &&
                        props.msgBoxArray.get[msgBoxIndex].tag_group_id
                      ) {
                        setFormTagGroup(
                          props.msgBoxArray.get[msgBoxIndex].tag_group_id
                        );
                      }
                    }
                  }
                  setTagPopoverVisible(x);
                }}
              >
                <Button
                  size="small"
                  className={
                    props.msgBoxArray &&
                    props.msgBoxArray.get &&
                    props.msgBoxArray.get.length > 0 &&
                    (msgBoxIndex || msgBoxIndex === 0) &&
                    props.msgBoxArray.get[msgBoxIndex] &&
                    props.msgBoxArray.get[msgBoxIndex]["tag_user_id"] !==
                      undefined &&
                    props.msgBoxArray.get[msgBoxIndex].tag_user_id.length > 0
                      ? "darkBG"
                      : "lightBG"
                  }
                  onKeyPressCapture={onCellKeyUp}
                  //onKeyPress={onCellKeyUp}
                >
                  <img
                    src={AUTH.GetThemedImage("Tags")}
                    alt=""
                    className="icon"
                    onMouseEnter={() => {
                      if (!tagsUsers) {
                        fetchLockListGroupUsers().then((data) => {
                          setTagsUsers(data);
                        });
                      }
                    }}
                  />
                </Button>
              </Dropdown>
            ) : null}
            {/*----------END: TAG SELECTION -------------------*/}

            {/*----------START: NOTE COPY -------------------*/}
            <Button
              size="small"
              className="lightBG"
              title={`${t("DealDesk.Copy")}`}
              onClick={() => {
                if (
                  (msgBoxIndex || msgBoxIndex === 0) &&
                  props.msgBoxArray?.get[msgBoxIndex]?.notes_description
                ) {
                  copy(props.msgBoxArray?.get[msgBoxIndex]?.notes_description, {
                    format: "text/html",
                  });
                  // navigator.clipboard.writeText(props.msgBoxArray?.get[msgBoxIndex]?.notes_description?.replace(/<\/?[^>]+(>|$)/g, ""))
                  vsmNotify.success({
                    message: `${t("DealDesk.Copied")}!`,
                  });
                } else {
                  vsmNotify.error({
                    message: vsmDealDesk.Notes.errorNoteSelection,
                  });
                }
              }}
            >
              <img
                src={AUTH.GetThemedImage("Copy_icn")}
                alt=""
                className="icon"
              />
            </Button>
            {/*----------END: NOTE COPY -------------------*/}

            {/*----------START: DELETE NOTE -------------------*/}
            <Button
              size="small"
              className="lightBG"
              title={`${t("Common_Data.Delete")}`}
              disabled={
                (msgBoxIndex || msgBoxIndex === 0) &&
                props.msgBoxArray &&
                AUTH.user?.id &&
                (AUTH.user?.id ===
                  props.msgBoxArray?.get[msgBoxIndex]?.created_by ||
                  AUTH.user?.user_role?.user_role_id === 1) &&
                !props.msgBoxArray?.get[
                  msgBoxIndex
                ]?.notes_description?.includes("<rejectionSummary>")
                  ? false
                  : true
              }
              onClick={async () => {
                if (msgBoxIndex || msgBoxIndex === 0) {
                  if (
                    activeNoteEditDated &&
                    activeNoteEditDated.id === msgBoxAPINoteID
                  ) {
                    vsmNotify.error({
                      message: `${t("DealDesk.Note_Already_In_Use")}!`,
                    });
                  } else {
                    setRemoveConfirm(true);
                  }
                } else {
                  setRemoveConfirm(false);
                  vsmNotify.error({
                    message: vsmDealDesk.Notes.errorNoteSelection,
                  });
                }
              }}
            >
              <img src={AUTH.GetThemedImage("Waste")} alt="" className="icon" />
            </Button>
            {/*----------END: DELETE NOTE -------------------*/}

            {/*----------START: EXPAND BUTTON -------------------*/}
            {(openModalSeq || openModalCom || openModalSum) &&
            (openModalSeq || openModalCom || openModalSum) === true ? (
              <Button
                size="small"
                className="lightBG"
                onClick={() => {
                  close(props.data);
                }}
                title={`${t("DealDesk.Expand")}`}
              >
                <img
                  src={AUTH.GetThemedImage("CloseExpand")}
                  alt={`${t("DealDesk.Close")} ${t("DealDesk.Expand")}`}
                  className="icon"
                />
              </Button>
            ) : (
              <Button
                size="small"
                className="lightBG"
                onClick={() => {
                  if (commentList === true) {
                    openExpandModalCom(props.data);
                  } else if (summaryList === true) {
                    openExpandModalSum(props.data);
                  } else if (sequenceList === true) {
                    openExpandModalSeq(props.data);
                  }
                }}
                title={`${t("DealDesk.Expand")}`}
              >
                <img
                  src={AUTH.GetThemedImage("Expand")}
                  alt=""
                  className="icon"
                />
              </Button>
            )}
            {/*----------END: EXPAND BUTTON -------------------*/}

            {/*----------START: EDIT NOTE BUTTON -------------------*/}
            <Button
              shape="round"
              disabled={
                (msgBoxIndex || msgBoxIndex === 0) &&
                props.msgBoxArray &&
                AUTH.user?.id &&
                (AUTH.user?.id ===
                  props.msgBoxArray?.get[msgBoxIndex]?.created_by ||
                  AUTH.user?.user_role?.user_role_id === 1) &&
                !props.msgBoxArray?.get[
                  msgBoxIndex
                ]?.notes_description?.includes("<rejectionSummary>")
                  ? false
                  : true
              }
              onClick={() => checkNoteEdit()}
            >
              {t("Common_Data.Edit")}
            </Button>
            {/*----------END: EDIT NOTE BUTTON -------------------*/}
          </div>
        )}
        <NoteDeleteConfirm visible={removeConfirm} close={closeRemoveConfirm} />
        <AddKnowledgeFromNote
          visible={knowledgeVisible}
          close={closeKnowledgeModal}
          tempKnowledgeNoteData={tempKnowledgeNoteData}
        />
        <NoteDateTimeConfirmElements
          visible={openDateTimeModal}
          close={closeDateTimeModal}
          date={date}
          reminderConfirm={reminderConfirm}
        />
        <ConfirmDraftNoteEdit
          visible={draftNoteEditConfirmModal}
          setDraftNoteEditConfirmModal={setDraftNoteEditConfirmModal}
          setEditNoteData={setEditNoteData}
        />
      </div>
    )
  );
});

export default Footer;
