import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { ActionRenderer } from './GridComponent';
import { vsmCommon } from '../../../config/messages';
import GridConfig from '../../../config/GridConfig';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ListComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    TagsStore,
    AUTH,
    SEARCHSTORE,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();
  const {
    openDeleteDrawer,
    openRestoreModal,
    openEditDrawer,
    openCloneDrawer,
    //openViewDrawer,
    openEditViewDrawer,
    TagTicketModule,
    WasteManagement,
    SearchPage,
    AllView,
    onCellKeyDown,
    // openTicketViewDrawer,
  } = props;

  useEffect(() => {
    if (WasteManagement) {
      TagsStore.waste = true;
      TagsStore.search_payload = {};
      TagsStore.setIsSavedFiltersLoadedTag(true);
    } else if (SearchPage) {
      TagsStore.waste = false;
      SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.tag_count;
      TagsStore.setIsSavedFiltersLoadedTag(true);
    } else {
      TagsStore.waste = false;
      TagsStore.search_payload = {};
    }
  }, [TagsStore, WasteManagement, SearchPage, SEARCHSTORE, TagsStore.total]);

  const formatterSetColumnFilter = (dropdownList, params, Id, returnfieldName) => {
    let fieldValue = null;
    if (dropdownList && dropdownList.length > 0) {
      const searchItem = (x) => parseInt(x[Id]) === parseInt(params.value);
      let ownerIndex = dropdownList.findIndex(searchItem);
      if (ownerIndex >= 0) {
        fieldValue = dropdownList[ownerIndex][returnfieldName];
      }
    }
    return fieldValue;
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
        resizable: true,
        lockPosition: true,
        cellClass: 'locked-col',
        suppressNavigable: true,
      },
      {
        headerName: `${t('Common_Data.ID')}`,
        field: 'tag_number',
        filter: 'agTextColumnFilter',
        lockPosition: true,
        cellClass: 'locked-col',
        suppressNavigable: true,
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        field: 'owner',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',

        valueFormatter: (params) =>
          formatterSetColumnFilter(
            TagsStore.dropdown_leads_users_list,
            params,
            'id',
            'name'
          ),

        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            TagsStore.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.id)]);
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              TagsStore.dropdown_leads_users_list,
              params,
              'id',
              'name'
            ),
        },
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        field: 'tagholder',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            TagsStore.dropdown_leads_users_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            TagsStore.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.id)]);
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              TagsStore.dropdown_leads_users_list,
              params,
              'id',
              'name'
            ),
        },
      },
      {
        headerName: `${t('Tags.Ticket')} ${t('Common_Data.Number')}`,
        headerTooltip: `${t('Tags.Ticket')} ${t('Common_Data.Number')}`,
        field: 'master_ticket_number',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
        // onCellClicked: (params) => {
        //   params?.data?.master_ticket?.id &&
        //     openTicketViewDrawer(params?.data?.master_ticket);
        // },
        tooltipValueGetter: (params) => {
          return params?.data?.master_ticket_number;
        },
        cellRendererFramework: (params) => {
          return params?.data?.master_ticket_number ? (
            <>
              <span className='cursor'>{params?.data?.master_ticket_number}</span>{' '}
              <Link to={`/dealdesk/${params?.data?.master_ticket_number}`} target={'_blank'}>
                <img alt='' src={AUTH.GetThemedImage('Tickets')} style={{ width: '14px', marginLeft: "5px", marginTop: "-3px" }} />
              </Link>
            </>
          ) : (
            ''
          );
        },
      },
      {
        headerName: `${t('Tags.Ticket')} ${t('Common_Data.Subject')}`,
        field: 'master_ticket.ticket_subject',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => {
          return params.value && params.data
            ? params.value
            : TagsStore.filter_master_ticket &&
            TagsStore.filter_master_ticket[params.value];
        },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            TagsStore.filterMasterTicketList().then((data) => {
              params.success([null, ...data.map((x, indexID) => indexID)]);
            });
          },
          valueFormatter: (params) => {
            return (
              params.value &&
              TagsStore.filter_master_ticket &&
              TagsStore.filter_master_ticket[params.value]
            );
          },
        },
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation.organisation_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) => {
          return (
            params.value &&
            TagsStore.filter_org_list &&
            TagsStore.filter_org_list[params.value]
          );
        },
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            TagsStore.filterGetMappedOrg().then((data) => {
              params.success([null, ...data.map((x, indexID) => indexID)]);
            });
          },
          valueFormatter: (params) => {
            return (
              params.value &&
              TagsStore.filter_org_list &&
              TagsStore.filter_org_list[params.value]
            );
          },
        },
      },
      {
        headerName: `${t('Common_Data.Subject')}`,
        field: 'tag_subject',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        headerTooltip: `${t('Common_Data.Description')}`,
        field: 'tag_content',
        tooltipValueGetter: (params) => {
          if (params?.data?.tag_content?.includes('<approved')) {
            return params?.data?.tag_subject
          }
          else {
            return params?.data?.tag_content
              ? params?.data?.tag_content.replace(/<(.|\n)*?>/g, '')
              : params?.data?.tag_subject;
          }
        },
        cellRenderer: (params) => {
          var a = document.createElement('div');
          if (params?.data?.tag_content?.includes('<approved')) {
            a.innerHTML = params?.data?.tag_subject
          }
          else {
            a.innerHTML = params?.data?.tag_content
              ? params?.data?.tag_content.replace(/<(.|\n)*?>/g, '')
              : params?.data?.tag_subject;
          }
          return a;
        },
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        field: 'tag_status_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(TagsStore.statusArr_list, params, 'value', 'text'),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            params.success([...TagsStore.statusArr_list.map((x) => x.value)]);
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(TagsStore.statusArr_list, params, 'value', 'text'),
        },
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'master_ticket_deadline_date',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params.data.master_ticket_deadline_date
            ? AUTH.getLocalTime(params?.data?.master_ticket_deadline_date,AUTH.global_fulldate_format)
            : '';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        field: 'created_by_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        valueFormatter: (params) =>
          formatterSetColumnFilter(
            TagsStore.dropdown_leads_users_list,
            params,
            'id',
            'name'
          ),
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            TagsStore.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.id)]);
            });
          },
          valueFormatter: (params) =>
            formatterSetColumnFilter(
              TagsStore.dropdown_leads_users_list,
              params,
              'id',
              'name'
            ),
        },
      },

      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
        field: 'created_at',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params.data.created_at
            ? AUTH.getLocalTime(params?.data?.created_at,AUTH.global_fulldate_format)
            : '';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,
        field: 'updated_at',
        cellClass: 'cellClass',
        cellRenderer: (params) => {
          return params?.data?.updated_at
            ? AUTH.getLocalTime(params?.data?.updated_at,AUTH.global_fulldate_format)
            : '';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      // {
      //   headerName: '# ID',
      //   field: 'id',
      //   cellClass: 'cellClass',

      //   width: 100,
      //   filter: 'agTextColumnFilter',
      //   sortable: true,
      //   rowDrag: TagTicketModule
      // },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: !TagTicketModule ? (WasteManagement === true ? 110 : 250) : 70,
        filter: false,
        sortable: false,
        pinned: 'right',
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('tags_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['tag_content'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }
    return item;
  });

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions' && params.node.data.leads_transactions_id) {
      result = [
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/dealdesk/${params.node.data.leads_transactions_id}`, '_blank');
          },
        },
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/dealdesk/${params.node.data.leads_transactions_id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }

  return (
    <>
      <div
        className={`ag-theme-alpine grid_wrapper TicketsGrid ${SearchPage ? "paginationAtTop" : ""}`}
        style={{
          height: list_fullscreen
            ? fullscreen_heigthwidth
            : TagTicketModule
              ? '70vh'
              : SearchPage && !AllView
                ? 'calc(100vh - 13rem)'
                : SearchPage && AllView
                  ? '150px'
                  : 'calc(100vh - 11rem)',
          minHeight: props.AllView ? '100px' : '250px',
        }}
      >
        <AgGridReact
          rowData={TagsStore.list_data}
          modules={AllModules}
          columnDefs={withTooltipCols}
          // rowClassRules={
          //   !WasteManagement && {
          //     "row-other": function (params) { return params?.node?.data?.master_ticket_priority_name !== null },
          //     "row-critical": function (params) { return params?.node?.data?.master_ticket_priority_name === "Critical" },
          //     "row-urgent": function (params) { return params?.node?.data?.master_ticket_priority_name === "Urgent" },
          //     "row-important": function (params) { return params?.node?.data?.master_ticket_priority_name === "Important" },
          //     "row-timed": function (params) { return params?.node?.data?.master_ticket_priority_name === "Timed" },
          //   }
          // }
          defaultColDef={{
            ...GridConfig.defaultColDef,
            width: 120,
            // minWidth: 70,
            flex: 1,
            // floatingFilter: !SearchPage,
          }}
          columnTypes={GridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            openDeleteDrawer,
            openRestoreModal,
            openEditDrawer,
            openCloneDrawer,
            //openViewDrawer,
            openEditViewDrawer,
            TagTicketModule,
            WasteManagement,
          }}
          onGridReady={TagsStore.setupGrid}
          onColumnResized={TagsStore.onGridChanged}
          onColumnMoved={TagsStore.onGridChanged}
          onColumnPinned={TagsStore.onGridChanged}
          gridOptions={{
            ...GridConfig.options,
            rowHeight: 30,
            pagination: SearchPage ? true : !TagTicketModule,
            animateRows: true,
            onRowDragEnd: (event) => { },
            //onRowDoubleClicked: (event) => openViewDrawer(event?.data),
            getContextMenuItems: getContextMenuItems,
          }}
          rowDragManaged={true}
          onCellKeyDown={onCellKeyDown}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          onFilterChanged={(e) => {
            if (e.hasOwnProperty('afterFloatingFilter') && TagsStore.selectedGridFilter) {
              TagsStore.setSelectedGridFilter(null);
            }
          }}
        />
      </div>
    </>
  );
});
export default ListComponent;
