import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Modal, Col, Row } from 'antd';
import useStore from '../../../../store';

const ConfirmFavReportElement = observer((props) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState();

    const {
        SavedReportStore: { globalStatusTempData },
        SavedReportStore,
        AUTH,
    } = useStore();

    const handleSubmit = (data) => {
        setSaving(true);
        SavedReportStore.saveToMenu(globalStatusTempData.id, globalStatusTempData.is_favorite ? '0' : '1').then((data) => {
            AUTH.setUserOptions(localStorage.getItem('token'));
            props.close();
        }).finally(() => setSaving(false));
    };

    return globalStatusTempData ? (
        <Modal
            centered
            title={'Favorite Report'}
            visible={props.visible}
            onCancel={props.close}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={[
                <Button
                    key='1'
                    form='confirmFavReport'
                    loading={saving}
                    htmlType='submit'
                    type='primary'
                    danger
                >
                    Yes
                </Button>,
                <Button
                    key='2'
                    htmlType='button'
                    onClick={() => {
                        form.resetFields();
                        props.close();
                    }}
                >
                    Cancel
                </Button>,
            ]}
        >
            <Form form={form} id='confirmFavReport' onFinish={handleSubmit}>
                {
                    <Row align='middle'>
                        <Col span={24}>
                            <p>
                                {!globalStatusTempData.is_favorite
                                    ? 'Are you sure you want to set as Favorite on Overview?'
                                    : 'Are you sure you want to remove from Favorite on Overview?'}
                            </p>
                        </Col>
                    </Row>
                }
            </Form>
        </Modal>
    ) : null;
});

export default ConfirmFavReportElement;
