
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import useStore from '../../../store';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import PeopleSearchListing from './PeopleSearchListing';
import EditComponent from '../../PeopleManagement/People/component/EditComponentNew';
import DeleteComponent from '../../PeopleManagement/People/component/DeleteComponent';
import TimeTracker from '../../../component/TimeTracker';

const PeopleSearch = observer((props) => {
  const { t } = useTranslation();
  const {
    // AUTH,
    // AUTH: { allPageFilters, allPageFiltersLoaded },
    PEOPLE,
    // SETTINGS,
    // DEALDESKSTORE: { stopInvestmentHourCounter, setStopInvestmentHourCounter },
    // PEOPLE: { isSavedFiltersLoadedPeople },
  } = useStore();

  const { SearchPage, searchPayload, selectionChanged, showCheckbox } = props
  const [editDrawer, setEditDrawer] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleEditCloneApiCall = debounce((data) => {
    var payload = { people_id: data?.id };
    PEOPLE.editPeopleLoading = true;
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        PEOPLE.editPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.editPeopleLoading = false;
      });
  }, 300);

  //open edit drawer
  const openEditDrawer = (data, isEditOpen = false) => {
    setEditOpen(isEditOpen);
    PEOPLE.editPeopleLoading = true;
    // setStopInvestmentHourCounter(true);
    setEditDrawer(true);
    handleEditCloneApiCall(data);
  };

  //close edit drawer
  const closeEditDrawer = () => {
    setEditDrawer(false);
    if (!editOpen) {
      // PEOPLE.peopleValues = null;
    }
    // setStopInvestmentHourCounter(false);
    setEditOpen(false);
  };

  // open delete modal
  const openDeleteModal = (data) => {
    setDeleteModal(true);
    PEOPLE.setDeleteValues(data);
  };
  //close delete modal
  const closeDeleteModal = () => {
    setDeleteModal(false);

  };

  return (
    <>
      <PeopleSearchListing SearchPage={SearchPage} searchPayload={searchPayload} openEditDrawer={openEditDrawer} openDeleteModal={openDeleteModal} selectionChanged={selectionChanged} showCheckbox={showCheckbox} />
      <EditComponent visible={editDrawer} editOpen={editOpen} close={closeEditDrawer} SearchPageEdit={true} />
      <DeleteComponent visible={deleteModal} close={closeDeleteModal} lineText={`${t('PeopleManagement.Delete_Person')} ? `} SearchPage={SearchPage} />

      {/* {!stopInvestmentHourCounter && <TrackTimeComponent pageName={'people'} />} */}
      <TimeTracker
        page={"people"}
        stop={editDrawer}
      />
    </>
  )
})
export default PeopleSearch;