import React from 'react';
import { observer } from 'mobx-react';

const PriorityRender = observer((props) => {
  const { value } = props;
  return (<>
    <div className={`priority ${value ? value : "Other"}`}>
      {value}
    </div>
  </>)
});

export default PriorityRender;
