import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const ReassignConfirmComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  const {
    MANAGEMENTALLDEPARTMENTSSTORE: { getList },
    TicketsStore: { assignTicket },
  } = useStore();

  const close = () => {
    props.close();
    setSaving(false);
    form.resetFields();
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    let payload = {
      assign_array: [
        {
          type: props.reAssingTempData.tickettagData.type,
          user_id: props.reAssingTempData.userid,
          id: parseInt(props.reAssingTempData.tickettagData.id),
        },
      ],
    };
    assignTicket(payload)
      .then((data) => {
        close();
        getList();
        vsmNotify.success({
          message: props.reAssingTempData.tickettagData.type + `${t('ManageAllDepartments.Updated_Successfully')}`,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
        setSaving(false);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return props.reAssingTempData ? (
    <Modal
      centered
      title={`${t('Common_Data.Reassign')} ${t('Common_Data.To')} ${props.reAssingTempData.username}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deleteformReassignConfirmComponent'
          htmlType='submit'
          type='primary'
          shape='round'
          loading={saving}
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteformReassignConfirmComponent' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('ManageAllDepartments.Reassign_This')} {props.reAssingTempData.ticketno} {t('Common_Data.To')}{' '}
              {props.reAssingTempData.username}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default ReassignConfirmComponent;
