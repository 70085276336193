import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Button, Form, Spin } from 'antd';
import useStore from '../../../../../store';
import { vsmNotify } from '../../../../../config/messages';
import { useTranslation } from 'react-i18next';

const View = observer((props) => {
  const {t} = useTranslation()
  const {
    DEALDESKSTORE: { deal_data },
    DEAL: { fetchDealDetails, sendEmail }
  } = useStore();
  const [PDFPath, SetPDFPath] = useState(null)

  let dealid = deal_data.lead_information && deal_data.lead_information.id;

  useEffect(() => {

    fetchDealDetails(dealid, { is_issued: false }).then((data) => {
      setTimeout(() => {
        if (data.pdf) {
          SetPDFPath(data.pdf)
        } else {
          SetPDFPath(false)
        }
      }, 2000)
    })
  }, [dealid, fetchDealDetails]);

  const handleSubmit = () => {
    let payload = {
      lead_transaction_id: dealid
    };
    sendEmail(dealid, payload)
      .then(() => {
        vsmNotify.success({
          message: `${t('DealDesk.Notes_Sent_Successfully')}`
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  };

  return (
    <Form id='DealView' className='innerForm' layout='vertical'>
      <div className='DealView mt-10'>
        {
          (PDFPath === null) ? (
            <Spin spinning={true} size="large" />
          ) : (
            <embed src={PDFPath} type="application/pdf" style={{ width: '100%', height: '99%' }} />
          )
        }
      </div>
      <Row gutter={0} className='mt-10'>
        <Col span={24} className='text-right border-top pt-15'>
          {/* <Button type='primary' shape='round' size='small' onClick={handleSubmit}>
            Send
          </Button> */}

          <Button
            shape='round'
            size='small'
            // disabled={disabled}
            onClick={handleSubmit}
          >
           {t('DealDesk.Issue')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default View;
