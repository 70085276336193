import Axios from 'axios';
import { action, decorate, observable } from 'mobx';

export default class SettingStore {
  settingsValues = null;
  dropdown_duration_list = null;
  dropdown_timezone_list = null;

  dropdown_reminder_hour_list = null;
  dropdown_reminder_minute_list = null;

  list_fullscreen = false;
  fullscreen_class = '';
  fullscreen_heigthwidth = '';

  toggleListFullscreen = () => {
    this.list_fullscreen = this.list_fullscreen ? false : true;
    this.fullscreen_class = this.list_fullscreen ? 'full_screen_wrapper' : '';
    this.fullscreen_heigthwidth = 'calc(100vh - 8rem)';
  };
  FetchSetting = () => {
    return Axios.post(`my-settings`)
      .then(({ data }) => {
        this.settingsValues = data.data;
        return data.data && data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  SaveSetting = (data) => {
    return Axios.post(`update-settings`, data)
      .then(({ data }) => {
        this.settingsValues = data;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      data[key] && formData.append(key, data[key]);
    });
    return formData;
  };

  getDurationvalues = () => {
    const arr = [];

    for (var i = 5; i <= 180; i += 5) {
      let obj = {};
      obj['value'] = i;
      obj['text'] = i;
      //obj['text'] = i + ' minutes';
      arr.push(obj);
    }
    this.dropdown_duration_list = arr;
    return this.dropdown_duration_list;
  };

  getFetchTimezone = () => {
    // return Axios.get(`globaltimezone/list`).then(({ data }) => {
    //   this.dropdown_timezone_list = data.data;
    //   return data;
    // });
    return Axios.get(`globalcountries/list`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.timezone_new = '(UTC ' + item.time_zone + ') ' + item.country_name;
        });
      }
      this.dropdown_timezone_list = data.data;
      return data;
    });
  };

  getReminderHourvalues = () => {
    const arr = [];

    for (var i = 1; i <= 23; i += 1) {
      let obj = {};
      obj['value'] = i;
      //obj['text'] = i;
      obj['text'] = i + ' Hours';
      arr.push(obj);
    }
    this.dropdown_reminder_hour_list = arr;
    return this.dropdown_reminder_hour_list;
  };

  getReminderMinutevalues = () => {
    const arr = [];

    for (var i = 15; i <= 480; i += 15) {
      let obj = {};
      obj['value'] = i;
      //obj['text'] = i;
      obj['text'] = i + ' Minutes';
      arr.push(obj);
    }
    this.dropdown_reminder_minute_list = arr;
    return this.dropdown_reminder_minute_list;
  };
}

decorate(SettingStore, {
  settingsValues: observable,
  dropdown_duration_list: observable,
  dropdown_timezone_list: observable,
  list_fullscreen: observable,
  fullscreen_class: observable,
  fullscreen_heigthwidth: observable,
  dropdown_reminder_hour_list: observable,
  dropdown_reminder_minute_list: observable,

  FetchSetting: action,
  SaveSetting: action,
  JsonToFormData: action,
  getDurationvalues: action,
  getFetchTimezone: action,
  toggleListFullscreen: action,
  getReminderHourvalues: action,
  getReminderMinutevalues: action,
});
