import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Typography, Button, Divider, Modal, Row, Col, Spin } from 'antd';
import useStore from '../../store';
// import PreviewDataComponent from '../Reports/SavedReports/components/PreviewDataComponent';
import PreviewDataComponent from '../Reports/SavedReportsV1/components/PreviewDataComponent';

import { numberWithCommas } from '../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';

const Dashboard = observer((props) => {
  const [formconfirm, setFormconfirm] = useState(false);
  const [removeId, setRemoveId] = useState(null);
  const [previewDataModal, setPreviewDataModal] = useState(false);
  const [report_id, setReportID] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();

  const {
    AUTH,
    AUTH: { report_data, fetchDashboardReport },
    SavedReportStore,
    DASHBOARD: {
      active_days_leads,
      active_days_opportunity,
      active_days_deals,
      active_days_ticket,

      opportunity_total_count,
      leads_total_count,
      deals_total_count,
      ticket_total_count,
      fetchLeadsTotalCount,
      fetchOpportunityTotalCount,
      fetchDealsTotalCount,
      fetchTicketTotalCount,
    },
  } = useStore();

  useEffect(() => {
    fetchLeadsTotalCount(7);
    fetchOpportunityTotalCount(7);
    fetchDealsTotalCount(7);
    fetchTicketTotalCount(7);
  }, [
    fetchLeadsTotalCount,
    fetchOpportunityTotalCount,
    fetchDealsTotalCount,
    fetchTicketTotalCount,
  ]);

  // call proxylogin function
  const cancelFormsubmit = () => {
    setFormconfirm(false);
    setRemoveId(null);
  };

  // cancel proxylogin function
  const continueFormsubmit = () => {
    setSaving(true);
    SavedReportStore.saveToMenu(removeId, '0')
      .then((data) => {
        setloading(true);
        fetchDashboardReport()
          .then(() => { })
          .finally(() => {
            setloading(false);
          });
        setFormconfirm(false);
        setRemoveId(null);
      })
      .catch((e) => { })
      .finally(() => {
        setSaving(false);
      });
  };

  // call proxylogin function
  const confirmRemoveReport = (id) => {
    setRemoveId(id);
    setFormconfirm(true);
  };

  // Open form for preview Reports Drawer
  const openPreviewReportModal = (id) => {
    setPreviewDataModal(true);
    setReportID(id);
  };

  // Open form for preview Reports Drawer
  const closePreviewDataModal = () => {
    setPreviewDataModal(false);
    setReportID(null);
  };
  return (
    AUTH.app_color_theme && (
      <>
        <Spin size='large' className='fullHeight' spinning={loading === true}>
          <div className='dashboard'>
            <Row>
              <Col span={22}>
                <Typography.Title level={3}>{t('DashBoard.Overview')}</Typography.Title>
              </Col>
              <Col span={2}>V {process.env.React_APP_version}</Col>
            </Row>
            <section className='main-content'>
              {(AUTH.checkPermission(11, 'list') || AUTH.checkPermission(10, 'list')) && (
                <div className='box'>
                  <table cellPadding='0' cellSpacing='0' className='topWidget'>
                    <tbody>
                      <tr>
                        <td rowSpan='2' className='iconBox'>
                          <img
                            src={AUTH.GetThemedImage('Used')}
                            alt=''
                            className='icon'
                          />
                        </td>
                        <td>{t('DashBoard.Leads')}</td>
                        <td className='text-right'>{/* <strong>2</strong> */}</td>
                      </tr>
                      <tr>
                        <td>{t('DashBoard.Total')}</td>
                        <td className='text-right'>
                          <strong>{numberWithCommas(leads_total_count)}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='text-right '>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_leads === 7 && 'active'}
                      onClick={() => fetchLeadsTotalCount(7)}
                    >
                      7 {t('DashBoard.Days')}
                    </Button>
                    <Button
                      size='small'
                      type='text'
                      onClick={() => fetchLeadsTotalCount(30)}
                      className={active_days_leads === 30 && 'active'}
                    >
                      30 {t('DashBoard.Days')}
                    </Button>
                    <Button
                      size='small'
                      type='text'
                      onClick={() => fetchLeadsTotalCount(365)}
                      className={active_days_leads === 365 && 'active'}
                    >
                      12 {t('DashBoard.Months')}
                    </Button>
                  </div>
                </div>
              )}

              {AUTH.checkPermission(12, 'list') && (
                <div className='box'>
                  <table cellPadding='0' cellSpacing='0' className='topWidget'>
                    <tbody>
                      <tr>
                        <td rowSpan='2' className='iconBox'>
                          <img
                            src={AUTH.GetThemedImage('Opportunities')}
                            alt=''
                            className='icon'
                          />
                        </td>
                        <td>{t('DashBoard.Opportunities')}</td>
                        <td className='text-right'>{/* <strong>2</strong> */}</td>
                      </tr>
                      <tr>
                        <td>{t('DashBoard.Total')}</td>
                        <td className='text-right'>
                          <strong>{numberWithCommas(opportunity_total_count)}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='text-right '>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_opportunity === 7 && 'active'}
                      onClick={() => fetchOpportunityTotalCount(7)}
                    >
                      7 {t('DashBoard.Days')}
                    </Button>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_opportunity === 30 && 'active'}
                      onClick={() => fetchOpportunityTotalCount(30)}
                    >
                      30 {t('DashBoard.Days')}
                    </Button>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_opportunity === 365 && 'active'}
                      onClick={() => fetchOpportunityTotalCount(365)}
                    >
                      12 {t('DashBoard.Months')}
                    </Button>
                  </div>
                </div>
              )}

              {AUTH.checkPermission(13, 'list') && (
                <div className='box'>
                  <table cellPadding='0' cellSpacing='0' className='topWidget'>
                    <tbody>
                      <tr>
                        <td rowSpan='2' className='iconBox'>
                          <img
                            src={AUTH.GetThemedImage('Deal_icn')}
                            alt=''
                            className='icon'
                          />
                        </td>
                        <td>{t('DashBoard.Deals')}</td>
                        <td className='text-right'>{/* <strong>2</strong> */}</td>
                      </tr>
                      <tr>
                        <td>{t('DashBoard.Total')}</td>
                        <td className='text-right'>
                          <strong>{numberWithCommas(deals_total_count)}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='text-right '>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_deals === 7 && 'active'}
                      onClick={() => fetchDealsTotalCount(7)}
                    >
                      7 {t('DashBoard.Days')}
                    </Button>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_deals === 30 && 'active'}
                      onClick={() => fetchDealsTotalCount(30)}
                    >
                      30 {t('DashBoard.Days')}
                    </Button>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_deals === 365 && 'active'}
                      onClick={() => fetchDealsTotalCount(365)}
                    >
                      12 {t('DashBoard.Months')}
                    </Button>
                  </div>
                </div>
              )}

              {AUTH.checkPermission(15, 'list') && (
                <div className='box'>
                  <table cellPadding='0' cellSpacing='0' className='topWidget'>
                    <tbody>
                      <tr>
                        <td rowSpan='2' className='iconBox'>
                          <img
                            src={AUTH.GetThemedImage('Tickets')}
                            alt=''
                            className='icon'
                          />
                        </td>
                        <td>{t('DashBoard.Tickets')}</td>
                        <td className='text-right'>{/* <strong>2</strong> */}</td>
                      </tr>
                      <tr>
                        <td>{t('DashBoard.Total')}</td>
                        <td className='text-right'>
                          <strong>{numberWithCommas(ticket_total_count)}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='text-right '>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_ticket === 7 && 'active'}
                      onClick={() => fetchTicketTotalCount(7)}
                    >
                      7 {t('DashBoard.Days')}
                    </Button>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_ticket === 30 && 'active'}
                      onClick={() => fetchTicketTotalCount(30)}
                    >
                      30 {t('DashBoard.Days')}
                    </Button>
                    <Button
                      size='small'
                      type='text'
                      className={active_days_ticket === 365 && 'active'}
                      onClick={() => fetchTicketTotalCount(365)}
                    >
                      12 {t('DashBoard.Months')}
                    </Button>
                  </div>
                </div>
              )}

              <div className='clear'></div>
              {report_data && report_data.length > 0 && <Divider />}

              {report_data &&
                report_data.length > 0 &&
                report_data.map((item, index) => {
                  return (
                    <>
                      <div key={`item-${index}`} className={`box item `}>
                        {AUTH.checkPermission(9, 'delete-reports') && (
                          <span
                            className='close'
                            onClick={() => {
                              confirmRemoveReport(item.id);
                            }}
                          >
                            X
                          </span>
                        )}
                        <table cellPadding='0' cellSpacing='0' className='topWidget'>
                          <tbody>
                            <tr>
                              <td>
                                {item.code}
                                <br />
                                <b>{item.name}</b>
                                <b>
                                  <b>{item.count.toLocaleString('en-GB')}</b>
                                </b>
                              </td>
                              {/* <td className='text-right'>
                            <strong>{item.count}</strong>
                          </td> */}
                            </tr>
                          </tbody>
                        </table>
                        <div className='text-right'>
                          <Button
                            size='small'
                            type='text'
                            onClick={() => openPreviewReportModal(item.id)}
                          >
                            {t('DashBoard.View_Report')}
                          </Button>
                        </div>
                      </div>
                    </>
                  );
                })}
              <Modal
                centered
                title={`${t('DashBoard.Remove_Widget')}`}
                visible={formconfirm}
                onCancel={cancelFormsubmit}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                footer={[
                  <Button
                    key='1'
                    htmlType='submit'
                    type='primary'
                    onClick={continueFormsubmit}
                    loading={saving}
                  >
                    {t('Common_Data.Yes')}
                  </Button>,
                  <Button key='2' htmlType='button' onClick={cancelFormsubmit}>
                    {t('Common_Data.Cancel')}
                  </Button>,
                ]}
              >
                <p>{t('DashBoard.Warn')}?</p>
              </Modal>
            </section>

            {previewDataModal && (
              <PreviewDataComponent
                addModal={false}
                editModal={false}
                report_id={report_id}
                openPreviewReportModal={openPreviewReportModal}
                setReportID={setReportID}
                visible={previewDataModal}
                close={closePreviewDataModal}
              />
            )}
          </div>
        </Spin>
        {/* <TrackTimeComponent pageName={'overview'} /> */}
        <TimeTracker />
      </>
    )
  );
});
export default Dashboard;
