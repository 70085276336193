import { Layout, Menu, Switch, Popover } from 'antd';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { AppNavConfig } from '../config/AppNavConfig';
import {
  RightOutlined,
  PlusCircleOutlined,
  LeftOutlined,
  DownOutlined,
} from '@ant-design/icons';
import FinalLOGO from '../assets/images/gray/logo_final_new.svg';
import Logo_symbol from '../assets/images/gray/logo-symbol.svg';
import { InfoCircleFilled } from '@ant-design/icons';
import TopleftComponent from './TopleftComponent';
import { useHistory } from 'react-router-dom';

import useStore from '../store';
import { default as AddLeadComponent } from '../page/LeadListing/component/AddLeadComponentNew';
import { default as AddKnowledgeComponent } from '../page/Knowledge/component/AddComponent';
import { default as AddTicketsComponent } from '../page/Tickets/component/AddTicketsComponentNew';
import { default as AddPeopleComponent } from '../page/PeopleManagement/People/component/AddComponentNew';
import { default as AddOrganisationComponent } from '../page/OrganisationManagement/AllOrganisations/component/AddComponentNew';
import { default as AddReminderComponent } from '../page/Reminders/component/AddComponent';
import { default as ListKnowledgeDrawer } from '../page/Knowledge/index';

import WasteDeleteComponent from './WasteDeleteComponent';
// import PreviewDataComponent from '../page/Reports/SavedReports/components/PreviewDataComponent';
import PreviewDataComponent from '../page/Reports/SavedReportsV1/components/PreviewDataComponent';
import { useTranslation } from 'react-i18next';

const SidebarComponent = observer((props) => {
  const {
    AUTH,
    AUTH: {
      api_menu_count_reminders,
      api_menu_count_tickets,
      api_menu_count_knowledge,
      api_menu_count_tags,
      api_menu_count_waste,
      api_menu_count_opportunities,
      api_menu_count_deals,
      api_menu_count_organisation,
      menuused_title,
      menuUsedText,
      menuused_link,
      menuused_count,
      menu_people_org_title,
      menuPeopleText,
      menu_people_org_link,
      menu_people_org_count,
      updateSwitchmenu,
      updateSwitchOrgmenu,
      report_data,
    },
    PEOPLE,
    TagsStore,
    TicketsStore,
    REMINDERSSTORE,
    SEQUENCERSTORE,
    TimeTrackerStore,
  } = useStore();
  let menukey = 1;
  const [openMenu, setOpenMenu] = useState([]);
  const location = useLocation();
  const [addLeadDrawer, setAddLeadDrawer] = useState(false);
  const [addTicketsDrawer, setAddTicketsDrawer] = useState(false);
  const [addknowledgedrawer, setAddknowledgedrawer] = useState(false);
  const [peopleDrawer, setPeopleDrawer] = useState(false);
  const [orgDrawer, setOrgDrawer] = useState(false);
  const [wasteDelete, setWasteDelete] = useState(false);
  const [addReminder, setAddReminder] = useState(false);
  const [listknowledgedrawer, setListknowledgedrawer] = useState(false);

  const [previewDataModal, setPreviewDataModal] = useState(false);
  const [report_id, setReportID] = useState(false);

  const { t } = useTranslation();

  let history = useHistory();

  /** To Stop or Start Timer in other Components according to sideBar's Drawers */
  useEffect(() => {
    if (addLeadDrawer || addTicketsDrawer || addknowledgedrawer || peopleDrawer || orgDrawer || addReminder || listknowledgedrawer || previewDataModal) {
      TimeTrackerStore.setStop(true);
    } else {
      TimeTrackerStore.setStop(false);
    }
  }, [TimeTrackerStore, addLeadDrawer, addTicketsDrawer, addknowledgedrawer, peopleDrawer, orgDrawer, addReminder, listknowledgedrawer, previewDataModal]);

  const icons = {
    1: AUTH.GetThemedImage('Dashboard_icn'),
    13: AUTH.GetThemedImage('Deal_icn'),
    12: AUTH.GetThemedImage('Opportunities'),
    11: AUTH.GetThemedImage('Used'),
    5: AUTH.GetThemedImage('Itinerary'),
    46: AUTH.GetThemedImage('Reminders_icn'),
    14: AUTH.GetThemedImage('Tags'),
    15: AUTH.GetThemedImage('Tickets'),
    16: AUTH.GetThemedImage('Connections_icn'),
    20: AUTH.GetThemedImage('Knowledge'),
    21: AUTH.GetThemedImage('Reports'),
    22: AUTH.GetThemedImage('Contacts_icn'),
    6: AUTH.GetThemedImage('Deal_Library_icn'),
    23: AUTH.GetThemedImage('Deals_Builder_icn'),
    24: AUTH.GetThemedImage('Deals_Builder_icn'),
    25: AUTH.GetThemedImage('App_Settings_icn'),
    32: AUTH.GetThemedImage('Waste'),
    33: AUTH.GetThemedImage('Note'),
  };

  const onChange = (checked) => {
    if (checked) {
      history.push(`/leads/active`);
    } else {
      history.push(`/leads/unused`);
    }
    updateSwitchmenu(checked);
  };

  useEffect(() => {
    if (window.location.pathname === '/contacts/organisations') {
      updateSwitchOrgmenu(false);
      AUTH.menu_people_org_count = api_menu_count_organisation;
    }

    if (window.location.pathname === '/leads/unused') {
      updateSwitchmenu(false);
    }
  }, [
    updateSwitchmenu,
    updateSwitchOrgmenu,
    AUTH,
    AUTH.menu_people_org_count,
    api_menu_count_organisation,
  ]);

  const onChangePeopleOrg = (checked) => {
    if (
      window.location.pathname === '/contacts/people' ||
      window.location.pathname === '/contacts/organisations'
    ) {
      if (checked) {
        history.push(`/contacts/people`);
      } else {
        history.push(`/contacts/organisations`);
      }
    }
    updateSwitchOrgmenu(checked);
  };
   console.log("icons",AUTH.GetThemedImage('user_group'))
  /** This the content for Setting > Sub Menu (plus icon) */
  const content = (
    <ul className='popover-menu' key='1'>
      {AUTH.checkPermission(26, 'list') && (
        <li key='1'>
          <Link to='/user-groups' title={`${t('Common_Data.User')} ${t('Common_Data.Groups')}`}>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('user_group')} />{' '}
            {`${t('Common_Data.User')} ${t('Common_Data.Groups')}`}
          </Link>
        </li>
      )}
      {AUTH.checkPermission(27, 'list') && (
        <li key='2'>
          <Link to='/users' title={t('ManageAllDepartments.Users')}>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Users')} /> {t('ManageAllDepartments.Users')}
          </Link>
        </li>
      )}
      {AUTH.checkPermission(28, 'list') && (
        <li key='3'>
          <Link to='/credebt-classification' title={`${t('Common_Data.Credebt_Classification')}`}>
            <img
              className='menuicon'
              alt=''
              src={AUTH.GetThemedImage('Credebt_Classification')}
            />
            {t('Credebt_Classification.Credebt')}<sup>®</sup> {t('Credebt_Classification.Classification')}
          </Link>
        </li>
      )}
      {AUTH.checkPermission(29, 'list') && (
        <li key='4'>
          <Link to='/ticker-tape-broadcast' title={`${t('TickerTapBroadcast.Ticker')} ${t('TickerTapBroadcast.Tape')} ${t('TickerTapBroadcast.Broadcast')}`}>
            <img
              className='menuicon'
              alt=''
              src={AUTH.GetThemedImage('Ticker_Tape_Broadcast')}
            />
            {t('TickerTapBroadcast.Ticker')} {t('TickerTapBroadcast.Tape')} {t('TickerTapBroadcast.Broadcast')}
          </Link>
        </li>
      )}
      {AUTH.checkPermission(30, 'list') && (
        <li key='5'>
          <Link to='/lead-status' title={`${t('Common_Data.Sold')} ${t('Common_Data.Stages')}`}>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Lead_Status')} />{' '}
            {t('Common_Data.Sold')} {t('Common_Data.Stages')}
          </Link>
        </li>
      )}
      {AUTH.checkPermission(31, 'list') && (
        <li key='6'>
          <Link to='/audit-logs' title={`${t('Audit_Logs.Audit_Logs')}`}>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Audit_Logs')} />{' '}
            {`${t('Audit_Logs.Audit_Logs')}`}
          </Link>
        </li>
      )}
      {AUTH.checkPermission(31, 'list') && (
        <li key='7'>
          <Link to='/page-filters' title={`${t('Common_Data.Page')} ${t('Reports.Filters')}`}>
            <img
              className='menuicon'
              alt=''
              src={AUTH.GetThemedImage('PageFilter_Icon')}
            />{' '}
            {`${t('Common_Data.Page')} ${t('Reports.Filters')}`}
          </Link>
        </li>
      )}
      {/* {AUTH.checkPermission(21, 'list') && (
        <li key='7'>
          <Link to='/reports_v1' title={`${t('Reports.Reports')} - v1`}>
            <img
              className='menuicon'
              alt=''
              src={AUTH.GetThemedImage('Reports')}
            />{' '}
            {t('Reports.Reports')} - v1
          </Link>
        </li>
      )} */}
      {/* <li key='8'>
        <Link to='/sequencer' title={'Sequence Data'}>
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('PageFilter_Icon')} />{' '}
          Sequence Data
        </Link>
      </li> */}
    </ul>
  );

  const setSequencerListFilter = (filterArr = []) => {
    let filter = {
      sequence_type: {
        filterType: 'set',
        values: filterArr,
      },
    };
    if (window.location.pathname === '/applications') {
      SEQUENCERSTORE.agGrid.api.setFilterModel(filter);
    } else {
      SEQUENCERSTORE.applyLeftmenuFilter = filter;
      history.push(`/applications`);
    }
  };

  const favReportList = (
    <>
      <ul className='popover-menu reportList' key='1'>
        <li
          title={`${t('Common_Data.Applications')}`}
          onClick={() => {
            history.push('/applications');
          }}
        >
          <span>
            <b>{`${t('Common_Data.Applications')}`}</b>
          </span>
        </li>
        <li
          title={`${t('LeadKanbanView.Originator')}`}
          onClick={() => setSequencerListFilter(['Originator Application'])}
        >
          <span style={{ textIndent: '20px' }}>{`${t('LeadKanbanView.Originator')}`}</span>
        </li>
        <li
          title={`${t('LeadKanbanView.Intermediary')}`}
          onClick={() => setSequencerListFilter(['Intermediary'])}
        >
          <span style={{ textIndent: '20px' }}>{`${t('LeadKanbanView.Intermediary')}`}</span>
        </li>
        <li
          title={`${t('LeadKanbanView.Investor')}`}
          onClick={() => setSequencerListFilter(['Investor Application'])}
        >
          <span style={{ textIndent: '20px' }}>{`${t('LeadKanbanView.Investor')}`}</span>
        </li>
        <li title={`${t('Common_Data.Legal')}`} onClick={() => setSequencerListFilter(['Legal'])}>
          <span style={{ textIndent: '20px' }}>{`${t('Common_Data.Legal')}`}</span>
        </li>
        <li title={`${t('Common_Data.Application_Login')}`} onClick={() => setSequencerListFilter(['application login'])}>
          <span style={{ textIndent: '20px' }}>{`${t('Common_Data.Application_Login')}`}</span>
        </li>
      </ul>
      {AUTH.checkPermission(21, 'save-to-dashboard') && report_data?.length > 0 ? (
        <>
          <ul className='popover-menu reportList favStaticLI' key='2'>
            <li title={`${t('Reports.Favorite_Report')}`}>
              <span>
                <b>{`${t('Reports.Favorite_Report')}`}</b>
              </span>
            </li>
          </ul>
          <ul className='popover-menu reportList favListing' key='3'>
            {report_data?.map((item, index) => {
              return (
                <li
                  key={index}
                  title={item.code + ' (' + item.name + ')'}
                  onClick={() => openPreviewReportModal(item.id)}
                >
                  <span style={{ textIndent: '20px' }}>
                    {item.code} (
                    {item.name && item.name.length > 10
                      ? item.name.substring(0, 9) + '...'
                      : item.name}
                    )
                  </span>
                  <span>{item.count.toLocaleString('en-GB')}</span>
                </li>
              );
            })}
          </ul>
        </>
      ) : null}
    </>
  );

  // Open form for preview Reports Drawer
  const openPreviewReportModal = (id) => {
    setPreviewDataModal(true);
    setReportID(id);
  };

  // Open form for preview Reports Drawer
  const closePreviewDataModal = () => {
    setPreviewDataModal(false);
    setReportID(null);
  };

  //add Lead Drawer
  const openAddLeadDrawer = () => {
    setAddLeadDrawer(true);
  };
  const closeAddLeadDrawer = () => setAddLeadDrawer(false);

  //open Knowledge drawer
  const openAddKnowledgeDrawer = () => {
    setAddknowledgedrawer(true);
  };
  const closeAddKnowledgeDrawer = () => setAddknowledgedrawer(false);

  //open List Knowledge
  const openListKnowledgeDrawer = () => {
    setListknowledgedrawer(true);
  };
  const closeListKnowledgeDrawer = () => setListknowledgedrawer(false);

  //add Tickets Drawer
  const openAddTicketsDrawer = () => {
    setAddTicketsDrawer(true);
  };
  const closeAddTicketsDrawer = () => setAddTicketsDrawer(false);

  //add People Drawer
  const openPeopleDrawer = () => {
    setPeopleDrawer(true);
  };
  //close People Drawer
  const closePeopleDrawer = () => setPeopleDrawer(false);

  //add Organisation Drawer
  const openOrgDrawer = () => {
    setOrgDrawer(true);
  };
  //close Organisation Drawer
  const closeOrgDrawer = () => setOrgDrawer(false);

  // open delete Drawer
  const openWasteDrawer = () => {
    setWasteDelete(true);
  };
  const closeWasteDrawer = () => setWasteDelete(false);

  //open add drawer
  const openAddReminder = (data) => {
    setAddReminder(true);
  };
  const closeAddReminder = () => setAddReminder(false);

  const bottomMenu = (
    <ul className='popover-menu' key='1'>
      {AUTH.checkPermission(42, 'view') && (
        <li key='1'>
          <Link to='#nogo'>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Instructions')} />
          </Link>
        </li>
      )}
      {AUTH.checkPermission(43, 'view') && (
        <li key='2'>
          <Link to='#nogo'>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Cheat_Sheets')} />
          </Link>
        </li>
      )}
      {AUTH.checkPermission(43, 'view') && (
        <li key='3'>
          <Link to='#nogo'>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Group')} />
          </Link>
        </li>
      )}
    </ul>
  );

  // iterate the menu items
  const AppMenu = (menu_item, open = []) => {
    return menu_item
      ? menu_item.map((item) => {
        if (item.submenu) {
          return (
            <Menu.SubMenu key={item.title + menukey} title={item.title}>
              {AppMenu(item.submenu, [...open, item.title + menukey++])}
            </Menu.SubMenu>
          );
        } else {
          if (location.pathname.includes(item.path) && openMenu.length <= 0) {
            if (open.length === 0) {
              open.push(item.path);
            }
            setOpenMenu(open);
          }

          if (['/leads'].includes(item.path)) {
            return (
              <Menu.Item
                key={item.path}
                disabled={
                  !(
                    AUTH.checkPermission(11, 'list') || AUTH.checkPermission(10, 'list')
                  )
                }
                title={menuused_title}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={icons[item.id] ? icons[item.id] : icons[1]}
                />

                <span>{menuused_title}</span>
                <Link to={menuused_link} />

                {props.collapsed
                  ? null
                  : AUTH.checkPermission(11, 'list') &&
                  AUTH.checkPermission(10, 'list') && (
                    <Switch
                      checked={menuUsedText === 'Leads'}
                      onChange={onChange}
                      className='float-right'
                    />
                  )}

                <span className='count'>
                  {menuused_count ? menuused_count : 0}{' '}
                  {AUTH.checkPermission(10, 'add') && (
                    <PlusCircleOutlined onClick={() => openAddLeadDrawer()} />
                  )}
                </span>
              </Menu.Item>
            );
          } else if (['/organisations'].includes(item.path)) {
            return (
              <Menu.Item
                key={item.path}
                disabled={
                  !(
                    AUTH.checkPermission(16, 'list') || AUTH.checkPermission(18, 'list')
                  )
                }
                title={menu_people_org_title}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={icons[item.id] ? icons[item.id] : icons[1]}
                />

                <span>{menu_people_org_title}</span>
                <Link to={menu_people_org_link} />

                {props.collapsed
                  ? null
                  : AUTH.checkPermission(16, 'list') &&
                  AUTH.checkPermission(18, 'list') && (
                    <Switch
                      checked={menuPeopleText === 'People'}
                      onChange={onChangePeopleOrg}
                      className='float-right'
                    />
                  )}

                <span className='count'>
                  {menu_people_org_count ? menu_people_org_count : 0}
                  {menu_people_org_link === '/contacts/organisations'
                    ? AUTH.checkPermission(16, 'add') && (
                      <PlusCircleOutlined onClick={() => openOrgDrawer()} />
                    )
                    : AUTH.checkPermission(18, 'add') && (
                      <PlusCircleOutlined onClick={() => openPeopleDrawer()} />
                    )}
                </span>
              </Menu.Item>
            );
          } else if (['/reminders'].includes(item.path)) {
            return (
              <Menu.Item
                key={item.path}
                disabled={!AUTH.checkPermission(item.id, 'list')}
                title={item.title}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={icons[item.id] ? icons[item.id] : icons[1]}
                />
                <span>{item.title}</span>
                <Link
                  to={item.path}
                  onClick={() => REMINDERSSTORE.resetSelectedSavedGridFilter()}
                />
                <span className='count'>
                  {api_menu_count_reminders ? api_menu_count_reminders : 0}
                  {AUTH.checkPermission(item.id, 'add') && (
                    <PlusCircleOutlined onClick={() => openAddReminder()} />
                  )}
                </span>
              </Menu.Item>
            );
          } else if (['/tickets'].includes(item.path)) {
            return (
              <Menu.Item
                key={item.path}
                disabled={!AUTH.checkPermission(item.id, 'list')}
                title={item.title}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={icons[item.id] ? icons[item.id] : icons[1]}
                />
                <span>{item.title}</span>
                <Link
                  to={item.path}
                  onClick={() => TicketsStore.resetSelectedSavedGridFilter()}
                />

                <span className='count'>
                  {api_menu_count_tickets ? api_menu_count_tickets : 0}
                  {AUTH.checkPermission(item.id, 'add') && (
                    <PlusCircleOutlined onClick={() => openAddTicketsDrawer()} />
                  )}
                </span>
              </Menu.Item>
            );
          } else if (['/tags'].includes(item.path)) {
            return (
              <Menu.Item
                key={item.path}
                disabled={!AUTH.checkPermission(item.id, 'list')}
                title={item.title}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={icons[item.id] ? icons[item.id] : icons[1]}
                />
                <span>{item.title}</span>
                <Link
                  to={item.path}
                  onClick={() => TagsStore.resetSelectedSavedGridFilter()}
                />
                <span className='count'>
                  {api_menu_count_tags ? api_menu_count_tags : 0}
                </span>
              </Menu.Item>
            );
          } else if (['/settings'].includes(item.path)) {
            return (
              <>
                <Menu.Item key={item.path} title={item.title}>
                  <img
                    className='menuicon'
                    alt=''
                    src={icons[item.id] ? icons[item.id] : icons[1]}
                  />
                  <span>{item.title}</span>
                  <Link to={item.path} />
                  <Popover
                    content={content}
                    trigger='click'
                    placement='topRight'
                    overlayClassName='main-menu-popover'
                  >
                    {props.collapsed ? null : (
                      <span className='count'>
                        <PlusCircleOutlined />
                      </span>
                    )}
                  </Popover>
                </Menu.Item>
              </>
            );
          } else if (['/reports'].includes(item.path)) {
            return (
              <>
                <Menu.Item key={item.path} title={item.title}>
                  <img
                    className='menuicon'
                    alt=''
                    src={icons[item.id] ? icons[item.id] : icons[1]}
                  />
                  <span>{item.title}</span>
                  <Link to={item.path} />
                  <Popover
                    content={favReportList}
                    trigger='hover'
                    placement='topRight'
                    overlayClassName='main-menu-popover'
                  >
                    {props.collapsed ? null : (
                      <span className='count'>
                        <DownOutlined />
                      </span>
                    )}
                  </Popover>
                </Menu.Item>
              </>
            );
          } else if (['/knowledge'].includes(item.path)) {
            return (
              <Menu.Item
                key={item.path}
                disabled={!AUTH.checkPermission(item.id, 'list')}
                title={item.title}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={icons[item.id] ? icons[item.id] : icons[1]}
                />
                <span>{item.title}</span>

                <Link to={item.path} />

                <span className='count'>
                  <img
                    alt='Knowledge List'
                    className='menuicon'
                    src={AUTH.GetThemedImage('KnowledgeSubMenu')}
                    onClick={() => openListKnowledgeDrawer()}
                    title={`${t('DealDesk.Knowledge_Library')}`}
                  />
                  {api_menu_count_knowledge ? api_menu_count_knowledge : 0}
                  {AUTH.checkPermission(item.id, 'add') && (
                    <PlusCircleOutlined onClick={() => openAddKnowledgeDrawer()} />
                  )}
                </span>
              </Menu.Item>
            );
          } else if (['/waste'].includes(item.path)) {
            return (
              <>
                <Menu.Item
                  key={item.path}
                  title={item.title}
                  disabled={
                    !AUTH.checkPermission(33, 'details') &&
                    !AUTH.checkPermission(34, 'details') &&
                    !AUTH.checkPermission(35, 'details') &&
                    !AUTH.checkPermission(36, 'details') &&
                    !AUTH.checkPermission(37, 'details') &&
                    !AUTH.checkPermission(38, 'details') &&
                    !AUTH.checkPermission(52, 'details') &&
                    !AUTH.checkPermission(39, 'details')
                  }
                >
                  <img
                    className='menuicon'
                    alt=''
                    src={icons[item.id] ? icons[item.id] : icons[1]}
                  />

                  <span>{item.title}</span>
                  <Link
                    to={item.path}
                    onClick={() => PEOPLE.resetGridFilterForWaste()}
                  />

                  {props.collapsed ? null : (
                    <span className='count'>
                      {api_menu_count_waste}
                      <img
                        className='menuicon'
                        alt=''
                        src={AUTH.GetThemedImage('Delete_Forever')}
                        onClick={() => {
                          if (
                            AUTH.checkPermission(33, 'details') ||
                            AUTH.checkPermission(34, 'details') ||
                            AUTH.checkPermission(35, 'details') ||
                            AUTH.checkPermission(36, 'details') ||
                            AUTH.checkPermission(37, 'details') ||
                            AUTH.checkPermission(38, 'details') ||
                            AUTH.checkPermission(52, 'details') ||
                            AUTH.checkPermission(39, 'details')
                          ) {
                            openWasteDrawer(true);
                          }
                        }}
                      />
                    </span>
                  )}
                </Menu.Item>
              </>
            );
          } else if (['/leads/deals'].includes(item.path)) {
            return (
              <Menu.Item
                key={item.path}
                disabled={!AUTH.checkPermission(item.id, 'list')}
                title={item.title}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={icons[item.id] ? icons[item.id] : icons[1]}
                />
                <span>{item.title}</span>
                <Link to={item.path} />
                <span className='count'>
                  {api_menu_count_deals ? api_menu_count_deals : 0}
                </span>
              </Menu.Item>
            );
          } else if (['/leads/opportunities'].includes(item.path)) {
            return (
              <Menu.Item
                key={item.path}
                disabled
                // disabled={!AUTH.checkPermission(item.id, 'list')}
                title={item.title}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={icons[item.id] ? icons[item.id] : icons[1]}
                />
                <span>{item.title}</span>
                <Link to={item.path} />
                <span className='count'>
                  {api_menu_count_opportunities ? api_menu_count_opportunities : 0}
                </span>
              </Menu.Item>
            );
          } else if (['/deals-library'].includes(item.path)) {
            return (
              <>
                <Menu.Item
                  key={item.path}
                  title={item.title}
                  disabled={
                    !AUTH.checkPermission(53, 'key-library-notes') &&
                    !AUTH.checkPermission(53, 'key-library-documents') &&
                    !AUTH.checkPermission(53, 'structure-library') &&
                    !AUTH.checkPermission(53, 'schedule-library')
                  }
                >
                  <img
                    className='menuicon'
                    alt=''
                    src={icons[item.id] ? icons[item.id] : icons[1]}
                  />
                  <span>{item.title}</span>
                  <Link to={item.path} />
                </Menu.Item>
              </>
            );
          } else {
            return (
              <Menu.Item
                key={item.path}
                disabled={!AUTH.checkPermission(item.id, 'list')}
                title={item.title}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={icons[item.id] ? icons[item.id] : icons[1]}
                />
                <span>{item.title}</span>
                <Link to={item.path} />
              </Menu.Item>
            );
          }
        }
      })
      : null;
  };

  return (
    <>
      {/(\/dealdesk\/(.*))/.test(location.pathname) ||
        /(\/ticket\/(.*))/.test(location.pathname) ? (
        <>
          <Layout.Sider
            trigger={null}
            breakpoint='lg'
            className={'site__sidebar '}
            width='262'
            theme='light'
            key='1'
          >
            <>
              <Link to='/dashboard' className='logo_wrapper'>
                <img
                  src={FinalLOGO}
                  className='LoginLogo'
                  alt='Deal Maker'
                  title='Deal Maker'
                />
              </Link>
              <TopleftComponent forceUpdate={props.forceUpdate} />
            </>
          </Layout.Sider>
          <Layout.Sider
            trigger={null}
            breakpoint='lg'
            collapsible
            collapsed={props.collapsed}
            className={
              'site__sidebar second__site__sidebar ' + (props.collapsed ? 'collapse' : '')
            }
            width='262'
            theme='light'
            key='2'
          >
            {props.collapsed ? (
              <>
                <RightOutlined
                  className='trigger'
                  onClick={() => {
                    localStorage.setItem('collapsed', false);
                    props.setCollapsed(false);
                  }}
                />
                <Menu
                  theme='dark'
                  mode='inline'
                  defaultOpenKeys={openMenu}
                  selectedKeys={location.pathname}
                  className='bottom_icon'
                >
                  {AppMenu(AppNavConfig)}
                  <Menu.Item
                    tooltip={false}
                    title={false}
                    key='1'
                    className='ant-menu-item bottom_menu'
                  >
                    <Popover
                      content={bottomMenu}
                      trigger='hover'
                      placement='right'
                      overlayClassName='bottom-menu-popover'
                    >
                      <InfoCircleFilled />
                    </Popover>
                  </Menu.Item>
                </Menu>
              </>
            ) : (
              <>
                <LeftOutlined
                  className='trigger'
                  onClick={() => {
                    localStorage.setItem('collapsed', true);
                    props.setCollapsed(true);
                  }}
                />
                <Menu
                  theme='dark'
                  mode='inline'
                  defaultOpenKeys={openMenu}
                  selectedKeys={location.pathname}
                  className='bottom_icon'
                >
                  {AppMenu(AppNavConfig)}
                  <Menu.Item className='ant-menu-item bottom_menu'>
                    {AUTH.checkPermission(42, 'view') && (
                      <Link to='#nogo'>
                        <img
                          className='menuicon'
                          alt=''
                          src={AUTH.GetThemedImage('Instructions')}
                        />
                      </Link>
                    )}
                    {AUTH.checkPermission(43, 'view') && (
                      <Link to='#nogo'>
                        <img
                          className='menu-icon'
                          alt=''
                          src={AUTH.GetThemedImage('Cheat_Sheets')}
                        />
                      </Link>
                    )}
                    {AUTH.checkPermission(44, 'view') && (
                      <Link to='#nogo'>
                        <img
                          className='menu-icon'
                          alt=''
                          src={AUTH.GetThemedImage('Group')}
                        />
                      </Link>
                    )}
                  </Menu.Item>
                </Menu>
              </>
            )}
          </Layout.Sider>
        </>
      ) : (
        <Layout.Sider
          trigger={null}
          breakpoint='lg'
          collapsible
          // onCollapse={(collapsed, type) => {
          //   collapsed ? setCollapsed(true) : setCollapsed(false);
          // }}
          collapsed={props.collapsed}
          className={
            'site__sidebar site__main__sidebar ' + (props.collapsed ? 'collapse' : '')
          }
          width='262'
          theme='light'
            key='1'
        >
          {props.collapsed ? (
            <>
              <Link to='/dashboard' className='logo_wrapper'>
                <img
                  src={Logo_symbol}
                  className='LoginLogo'
                  alt='Deal Maker'
                  title='Deal Maker'
                />
              </Link>
              <TopleftComponent
                collapsed={props.collapsed}
                forceUpdate={props.forceUpdate}
              />
              <RightOutlined
                className='trigger'
                onClick={() => {
                  localStorage.setItem('collapsed', false);
                  props.setCollapsed(false);
                }}
              />
              <Menu
                theme='dark'
                mode='inline'
                defaultOpenKeys={openMenu}
                selectedKeys={location.pathname}
                className='bottom_icon'
              >
                {AppMenu(AppNavConfig)}
                {/* <li className=''> */}
                <Menu.Item
                  key='1'
                  className='ant-menu-item bottom_menu text-center'
                  tooltip='flase'
                >
                  <Popover
                    content={bottomMenu}
                    trigger='hover'
                    placement='right'
                    overlayClassName='bottom-menu-popover'
                  >
                    <InfoCircleFilled />
                  </Popover>
                </Menu.Item>
                {/* </li> */}
              </Menu>
            </>
          ) : (
            <>
              <Link to='/dashboard' className='logo_wrapper'>
                <img
                  src={FinalLOGO}
                  className='LoginLogo'
                  alt='Deal Maker'
                  title='Deal Maker'
                />
              </Link>
              <TopleftComponent
                collapsed={props.collapsed}
                forceUpdate={props.forceUpdate}
              />
              <LeftOutlined
                className='trigger'
                onClick={() => {
                  localStorage.setItem('collapsed', true);
                  props.setCollapsed(true);
                }}
              />

              <Menu
                theme='dark'
                mode='inline'
                defaultOpenKeys={openMenu}
                selectedKeys={location.pathname}
                className='bottom_icon'
              >
                {AppMenu(AppNavConfig)}
                <Menu.Item className='ant-menu-item bottom_menu'>
                  {AUTH.checkPermission(42, 'view') && (
                    <Link to='#nogo' title={`${t('Common_Data.Instructions')}`}>
                      <img
                        className='menuicon'
                        alt=''
                        src={AUTH.GetThemedImage('Instructions')}
                      />
                    </Link>
                  )}
                  {AUTH.checkPermission(43, 'view') && (
                    <Link to='#nogo' title={`${t('Common_Data.Cheat_sheets')}`}>
                      <img
                        className='menu-icon'
                        alt=''
                        src={AUTH.GetThemedImage('Cheat_Sheets')}
                      />
                    </Link>
                  )}
                  {AUTH.checkPermission(44, 'view') && (
                    <Link to='#nogo' title={`${t('Common_Data.Datasheets')}`}>
                      <img
                        className='menu-icon'
                        alt=''
                        src={AUTH.GetThemedImage('Group')}
                      />
                    </Link>
                  )}
                </Menu.Item>
              </Menu>
            </>
          )}
        </Layout.Sider>
      )}

      <AddLeadComponent visible={addLeadDrawer} close={closeAddLeadDrawer} fromSideBar />
      <AddTicketsComponent visible={addTicketsDrawer} close={closeAddTicketsDrawer} fromSideBar />
      <AddKnowledgeComponent
        visible={addknowledgedrawer}
        close={closeAddKnowledgeDrawer}
        fromSideBar />
      <AddPeopleComponent visible={peopleDrawer} close={closePeopleDrawer} fromSideBar />
      <AddOrganisationComponent visible={orgDrawer} close={closeOrgDrawer} fromSideBar />
      <WasteDeleteComponent visible={wasteDelete} close={closeWasteDrawer} fromSideBar />
      <AddReminderComponent visible={addReminder} close={closeAddReminder} fromSideBar />
      {listknowledgedrawer && (
        <ListKnowledgeDrawer
          visible={listknowledgedrawer}
          close={closeListKnowledgeDrawer}
          isSidebarDrawer={true}
          fromSideBar />
      )}

      {previewDataModal && (
        <PreviewDataComponent
          addModal={false}
          editModal={false}
          report_id={report_id}
          openPreviewReportModal={openPreviewReportModal}
          setReportID={setReportID}
          visible={previewDataModal}
          close={closePreviewDataModal}
          fromSideBar />
      )}
    </>
  );
});

export default SidebarComponent;
