import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class WasteStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  activeTab = localStorage.getItem('waste_filter_note_id') ? "6" : "1"; // To store the current active tab

  wasteCount = {
    people_waste_count: 0,
    organisation_waste_count: 0,
    lead_waste_count: 0,
    opportunities_waste_count: 0,
    deals_waste_count: 0,
    note_waste_count: 0,
    tag_waste_count: 0,
    ticket_waste_count: 0,
  };
  agGrid = null;
  notesGridLoader = true;
  deleteValues = null;

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('notes_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem('notes_grid', JSON.stringify(params.columnApi.getColumnState()));
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  autoSizeColumns = () => {
    var allColumnIds = [];
    if (this.agGrid && this.agGrid.columnApi) {
      this.agGrid.columnApi.getAllColumns().forEach(function (column) {
        if (!['actions'].includes(column.colId)) {
          allColumnIds.push(column.colId);
        }
      });
      this.agGrid.columnApi.autoSizeColumns(allColumnIds);
    }
  };

  // call api to get records
  getList = () => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.columnApi.getColumnState();
    }
    this.notesGridLoader = true;
    this.list_data = null;
    return Axios.post(`/deal-desk/get-leads-notes`, { waste: true }).then(({ data }) => {
      this.notesGridLoader = false;
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;

      if (this.agGrid && this.agGrid.columnApi && data.total) {
        this.autoSizeColumns();
      }
      if (this.agGrid && this.agGrid.columnApi) {
        /**----------------START:DM-198 - filter waste note--------------------- */
        if (localStorage.getItem('waste_filter_note_id')) {
          filter = {
            id: {
              filter: localStorage.getItem('waste_filter_note_id'),
              filterType: 'number',
              type: 'equals',
            },
          };
          this.agGrid.api.setFilterModel(filter);
          localStorage.removeItem('waste_filter_note_id');
        } else {
          this.agGrid.api.setFilterModel(filter);
        }
        /**----------------END:DM-198 - filter waste note--------------------- */

        this.agGrid.columnApi.applyColumnState({ state: sort });
      }
      return data;
    });
  };

  DeletedData = (formdata) => {
    return Axios.post(
      `deal-desk/delete-note/${formdata.lead_transaction_id}/${formdata.id}`,
      { waste: true }
    )
      .then(({ data }) => {
        this.getWasteCount();
        if (this.agGrid) {
          this.getList();
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  RestoreData = (formdata) => {
    return Axios.get(`deal-desk/note/restore/${formdata}`)
      .then(({ data }) => {
        if (this.agGrid) {
          this.getList();
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //call api to get waste count
  getWasteCount = () => {
    return Axios.get('waste/trashed-count')
      .then(({ data }) => {
        this.wasteCount = data;
        return data;
      })
      .catch(() => {
        this.wasteCount = null;
      });
  };
}

decorate(WasteStore, {
  wasteCount: observable,
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  deleteValues: observable,
  notesGridLoader: observable,
  activeTab: observable,
  getList: action,
  setupGrid: action,
  onFilterChanged: action,
  onGridChanged: action,
  autoSizeColumns: action,
  getWasteCount: action,
  DeletedData: action,
  RestoreData: action,
  setDeleteValues: action,
});
