import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import LeadSearchListing from './LeadSearchListing';
import EditComponent from '../../LeadListing/component/EditComponentNew';
import DeleteComponent from '../../LeadListing/component/DeleteComponent';
import useStore from '../../../store';
// import Unauthorized from '../../Unauthorized';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const Leads = observer((props) => {
    const { t } = useTranslation();
    const location = useLocation();

    const { SearchPage, AllView, selectionChanged, showCheckbox } = props;

    const { LEADSSTORE, AUTH } = useStore();
    const [editDrawer, setEditDrawer] = useState(false);
    const [deleteDrawer, setDeleteDrawer] = useState(false);
    const [editOpen, setEditOpen] = useState(false);

    /** Edit Drawer*/
    const openEditDrawer = (data, isEditOpen = false) => {
        LEADSSTORE.setEditValues(data);
        setEditOpen(isEditOpen);
        setEditDrawer(true);
    };
    const closeEditDrawer = () => {
        setEditDrawer(false);
        setEditOpen(false);
    };

    /** Delete Drawer */
    const openDeleteDrawer = (data) => {
        LEADSSTORE.setDeleteValues(data);
        setDeleteDrawer(true);
    };
    const closeDeleteDrawer = () => setDeleteDrawer(false);

    useEffect(() => {
        if (location.pathname !== LEADSSTORE.current_list_path) {
            LEADSSTORE.current_list_path = location.pathname;
        }
    }, [LEADSSTORE, LEADSSTORE.current_list_path, location]);

    const onCellKeyDown = (e) => {
        if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
            if (
                AUTH.user.bonus_figures[0].shortcut_keys &&
                AUTH.user.bonus_figures[0].shortcut_keys.length > 0
            ) {
                const result = AUTH.user.bonus_figures[0].shortcut_keys
                    .filter((x) => x.key === e.event.key)
                    .shift();
                if (result) {
                    if (AUTH.checkPermission(11, 'edit')) {
                        if (result.key === 'Enter') {
                            openEditDrawer(e.data);
                        }
                    }
                    if (AUTH.checkPermission(11, 'delete')) {
                        if (result.key === 'Delete') {
                            openDeleteDrawer(e.data);
                        }
                    }
                }
            }
        }
    };

    return (
        <>
            <LeadSearchListing
                openEditDrawer={openEditDrawer}
                openDeleteDrawer={openDeleteDrawer}
                SearchPage={SearchPage}
                AllView={AllView}
                onCellKeyDown={onCellKeyDown}
                selectionChanged={selectionChanged}
                showCheckbox={showCheckbox}
            />
            <EditComponent
                visible={editDrawer}
                editOpen={editOpen}
                close={closeEditDrawer}
                SearchPage={SearchPage}
                SearchPageEdit
            />
            {/* to be done it */}
            <DeleteComponent
                visible={deleteDrawer}
                close={closeDeleteDrawer}
                lineText={`${t('Common_Data.Remove_Lead')}`}
                SearchPage={SearchPage}
            />
            <TimeTracker
                page={"leadlisting"}
                stop={editDrawer}
            />
        </>
    );
});

export default Leads;
