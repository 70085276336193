import React from 'react';
import { /* Switch, DatePicker, */ Row, Col /* Space */ } from 'antd';
// import moment from 'moment';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';
const ScoreHeader = ({
  AddNode,
  openViewDrawer,
  scrollToNode = () => {},
  handleFormChange = () => {},
  autoSaveData = () => {},
  Score,
}) => {
  const { AUTH } = useStore();
  // const disabledDate = (current) => {
  //   // Can not select sundays and predfined days

  //   return moment(current).day() === 0 || moment(current).day() === 6;
  // };

  const { t } = useTranslation();

  return (
    <div className='header'>
      <Row gutter={10}>
        <Col span={5}>
          <img
            src={AUTH.GetThemedImage('add')}
            alt={`${t('DealDesk.Add')}`}
            className='icon circle cursorPointer'
            onClick={() => {
              let allowAddNode = true;
              if (
                Score &&
                Score.length > 0 &&
                parseInt(Score[Score.length - 1].position + 1) > 365
              ) {
                allowAddNode = false;
              }
              if (allowAddNode) {
                AddNode();
                scrollToNode(0);
              }
            }}
          />
          {openViewDrawer && (
            <img
              src={AUTH.GetThemedImage('Deal_Library_icn')}
              alt={`${t('DealDesk.Add')}`}
              className='icon cursorPointer'
              onClick={openViewDrawer}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ScoreHeader;
