import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import { globalStatus } from '../../utils/GlobalFunction';
import { convertTextToID } from '../../utils/GlobalFunction';
import { reset, clear } from '../../utils/ResetClearButton';

export default class PeopleStore {
  list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  agGridRelOrg = null;
  deleteValues = null;
  viewValues = null;
  cloneValues = null;
  peopleValues = null;
  rPeopleValues = null;
  dropdown_pplRole_list = null;
  dropdown_RpplRole_list = null;
  peopleStatusList = null;
  dropdown_RPpl_list = null;
  drowpdown_people_list = null;
  drowpdown_people_name_list = null;
  gridLoading = false;
  isMergePeople = false;

  fetching = false;
  fetchingNameDropdown = false;
  list_related_ORG = [];
  list_related_Ppl = [];
  list_trade_transaction = [];
  list_cash_transaction = [];
  list_banks = [];
  peopleCardValue = null;
  rPeopleCardValue = null;
  waste = false;
  search_payload = {};
  industries_list = null;
  dropdown_user_list = null;

  exportLoadingIcn = false;
  viewPeopleLoading = false;
  editPeopleLoading = false;

  initialValues = {
    phone: [{ id: null, contact_for: 'Primary' }],
    email: [{ id: null, contact_for: 'Primary' }],
  };

  selectedGridFilter = null; //Used For Saved Grid Filters To Apply - default my open people

  masterPeopleToMerge = null;

  //-----------------Lead Connection List-----------------------------//
  fetchingConnection = false;
  dropdown_people_connection_list = null;

  //-----------------isSavedFiltersLoadedPeople USED TO CHECK FILTER LOADED-----------------------------//
  isSavedFiltersLoadedPeople = false;

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  //set view people values
  setViewValues = (data) => {
    this.viewValues = data;
  };
  //set view people values
  setCloneValues = (data) => {
    this.cloneValues = data;
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.PEOPLE) {
        params = { request: temp.PEOPLE };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PEOPLE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          PEOPLE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };
  // Setup grid and set column size to autosize
  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_people_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;

    let columnConfig = localStorage.getItem('people_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }

    var datasource = this.createDatasource(GridConfig.options);
    api.setServerSideDatasource(datasource);
    let param = this.getFilter(params);
    if (param && param.request) {
      this.agGrid.api.setFilterModel(param.request.filter);
      this.agGrid.api.setSortModel(param.request.sort);
    }
  };

  // Setup grid and set column size to autosize
  // setupGridRelatedOrganisation = (params) => {
  //   this.agGridRelOrg = params;
  // };
  setupGridRelatedOrganisation = (params) => {
    this.agGridRelOrg = params;
    let columnConfig = localStorage.getItem('RelatedOrganisationList_grid');
    if (this.agGridRelOrg && this.agGridRelOrg.columnApi && columnConfig) {
      this.agGridRelOrg.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  // Setup grid and set column size to autosize
  setupGridRelatedPeople = (params) => {
    this.agGridRelPeo = params;
    let columnConfig = localStorage.getItem('RelatedOrganisationList_grid');
    if (this.agGridRelPeo && this.agGridRelPeo.columnApi && columnConfig) {
      this.agGridRelPeo.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  // Setup grid and set column size to autosize
  setupGridTradeTransactions = (params) => {
    this.agGrid = params;
  };

  // Setup grid and set column size to autosize
  setupGridCashTransactions = (params) => {
    this.agGrid = params;
  };

  // Setup grid and set column size to autosize
  setupGridBanks = (params) => {
    this.agGrid = params;
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('people_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }

        var filter_data = params.request.filterModel;
        if (filter_data['industry_id.name']) {
          filter_data['industry_id.name'].values = convertTextToID(
            filter_data['industry_id.name'],
            this.industries_list,
            'name',
            'id'
          );
        }

        if (filter_data['created_by']) {
          filter_data['created_by'].values = convertTextToID(
            filter_data['created_by'],
            this.dropdown_user_list,
            'name',
            'id'
          );
        }

        var payload = {
          waste: this.waste,
          filter_data: params.request.filterModel,
          // filter_data: this.search_payload?.hasOwnProperty('search')
          //   ? {}
          //   : params.request.filterModel,
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };

        // let merged = { ...payload, ...this.search_payload };
        let merged = { ...payload};

        this.setFilter(params.api);
        this.getList(merged).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          let columnConfig = localStorage.getItem('people_grid');
          if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
              this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
            }
          } else {
            if (this.agGrid && this.agGrid.columnApi && data.total) {
              this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                if (!['actions', 'phone', 'email', 'address'].includes(column.colId)) {
                  allColumnIds.push(column.colId);
                }
              });
              this.agGrid.columnApi.autoSizeColumns(allColumnIds);
            }
          }
        });
      },
    };
  };

  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_user_list = data.data;

      return data;
    });
  };

  getList = (payload = {}) => {
    this.list_data = null;
    return Axios.post(`global/people/list`, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.people_name =
            (item.first_name ? item.first_name : '') +
            ' ' +
            (item.last_name ? item.last_name : '');

          item.created_by =
            item.user &&
            (item.user.first_name ? item.user.first_name : '') +
            ' ' +
            (item.user.last_name ? item.user.last_name : '');

          item.address =
            item.address &&
            item.addresses_global_countries &&
            item.address.length > 0 &&
            item.address[0].address_1 &&
            item.address[0].address_1 +
            ', ' +
            (item.address[0].address_2 ? item.address[0].address_2 + ', ' : '') +
            (item.address[0].address_3 ? item.address[0].address_3 + ', ' : '') +
            (item.address[0].city ? item.address[0].city + ', ' : '') +
            (item.address[0].state_county ? item.address[0].state_county : '') +
            ', ' +
            item.addresses_global_countries.country_name;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };

  getListSearch = (payload = {}) => {
    this.gridLoading = true;
    var temp = JSON.parse(localStorage.getItem('params'));
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.PeopleSearchStore?.sort;
    }
    this.list_data = null;


    return Axios.post(`global/people/list`, payload).then(({ data }) => {
      this.gridLoading = false;
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.people_name =
            (item.first_name ? item.first_name : '') +
            ' ' +
            (item.last_name ? item.last_name : '');

          item.created_by =
            item.user &&
            (item.user.first_name ? item.user.first_name : '') +
            ' ' +
            (item.user.last_name ? item.user.last_name : '');

          item.address =
            item.address &&
            item.addresses_global_countries &&
            item.address.length > 0 &&
            item.address[0].address_1 &&
            item.address[0].address_1 +
            ', ' +
            (item.address[0].address_2 ? item.address[0].address_2 + ', ' : '') +
            (item.address[0].address_3 ? item.address[0].address_3 + ', ' : '') +
            (item.address[0].city ? item.address[0].city + ', ' : '') +
            (item.address[0].state_county ? item.address[0].state_county : '') +
            ', ' +
            item.addresses_global_countries.country_name;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      this.agGrid.api.setSortModel(sort ? sort : temp?.PeopleSearchStore?.sort);

      if(this.list_data?.length === 0){
        this.agGrid.api.showNoRowsOverlay();
      }
      return data;
    });
  };

  getRecordDetail = (formdata, setVariable = true) => {
    if (this.agGridRelOrg) {
      var filter = this.agGridRelOrg.api.getFilterModel();
      var sort = this.agGridRelOrg.columnApi.getColumnState();
    }
    return Axios.get(`global/people/read/${formdata.people_id}`)
      .then(({ data }) => {
        if (setVariable) {
          if (data.data.cash_transactions && data.data.cash_transactions.length) {
            data.data.cash_transactions.forEach((item, index) => {
              item.srno = index + 1;
              item.status_name = globalStatus(
                'cash_transaction_status',
                'key',
                item.status
              );
            });
          }
          this.peopleValues = data.data;
        }
        if (this.agGridRelOrg && this.agGridRelOrg.columnApi) {
          this.agGridRelOrg.api.setFilterModel(filter);
          this.agGridRelOrg.columnApi.applyColumnState({ state: sort });
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        this.peopleValues = null;
        return Promise.reject(data);
      });
  };

  // get related organisation record details
  getRPplRecordDetail = (data, setVariable = true) => {
    return Axios.get(`global/people/read/${data.people_id}`)
      .then(({ data }) => {
        if (setVariable) {
          this.rPeopleValues = data.data;
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        this.rPeopleValues = null;
        return Promise.reject(data);
      });
  };

  // call api to get people status list
  getPeopleStatus = () => {
    return Axios.get(`people/status/list`).then(({ data }) => {
      this.peopleStatusList = data.data;
      return data;
    });
  };

  setPeopleValues = (data) => {
    this.peopleValues = data;
  };

  // set related organisation values
  setRPeopleValues = (data) => {
    this.rPeopleValues = data;
  };

  AddData = (data) => {
    return Axios.post(`global/people/add`, data)
      .then(({ data }) => {
        // if (this.agGrid) {
        //   this.setupGrid(this.agGrid);
        // }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  EditData = (data, id, SearchPageEdit) => {
    return Axios.post(`global/people/edit/${id}`, data)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
          if (SearchPageEdit) {
            this.getListSearch(this.search_payload);
          }
        }

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  CloneData = (data, id) => {
    return Axios.post(`global/people/add`, data)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  DeleteData = (people_id) => {
    return Axios.post(`global/people/delete/${people_id}`, { waste: this.waste })
      .then(({ data }) => {
        // if (this.agGrid) {
        //   this.setupGrid(this.agGrid);
        // }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  RestoreData = (people_id) => {
    return Axios.get(`people/restore/${people_id}`)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  ImportPeopleFile = (formdata) => {
    const options = {
      headers: { Accept: '' },
    };
    return Axios.post(`people/import`, formdata, options)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  ImportPeopleRoleFile = (formdata) => {
    const options = {
      headers: { Accept: '' },
    };
    return Axios.post(`people/role/bank/import`, formdata, options)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  getPeopleRoles = () => {
    return Axios.post(`global/people/role/dropdown/list`).then(({ data }) => {
      this.dropdown_pplRole_list = data.data;
      return data;
    });
  };

  getRelatedPeopleRoles = (id) => {
    return Axios.post(`global/people/role/dropdown/list`, id).then(({ data }) => {
      this.dropdown_RpplRole_list = data.data;
      return data;
    });
  };

  // call api to get all organisations list
  getAllRPeople = (payload) => {
    this.dropdown_RPpl_list = null;
    if ((payload.people_name && payload.people_name.length >= 1) || payload.selected_id) {
      this.fetching = true;
      return Axios.get(`global/people/dropdown/list`, payload)
        .then(({ data }) => {
          this.dropdown_RPpl_list = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  // call api to get all organisations list
  getPeopleSearchList = (payload) => {
    this.dropdown_RPpl_list = null;
    if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
      this.fetching = true;
      return Axios.post(`organisations/get`, payload)
        .then(({ data }) => {
          this.dropdown_RPpl_list = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  ExportPeople = () => {
    this.exportLoadingIcn = true;
    return Axios.get(`people/export`)
      .then(({ data }) => {
        if (data.data && data.data.csv_link) {
          window.location.href = data.data.csv_link;
        }
        this.exportLoadingIcn = false;
        return data;
      })
      .catch(() => {
        this.exportLoadingIcn = false;
        return Promise.reject([]);
      });
  };

  ExportPeopleRoleMappint = () => {
    this.exportLoadingIcn = true;
    return Axios.get(`people/role/bank/export`)
      .then(({ data }) => {
        if (data.data && data.data.csv_link) {
          window.location.href = data.data.csv_link;
        }
        this.exportLoadingIcn = false;
        return data;
      })
      .catch(() => {
        this.exportLoadingIcn = false;
        return Promise.reject([]);
      });
  };

  getViewPplDetail = (data, lead_transaction_id) => {
    let payload = { waste: this.waste };
    if (lead_transaction_id) {
      payload.lead_transaction_id = lead_transaction_id;
    }
    return Axios.post(`people/view/details/${data}`, payload)
      .then(({ data }) => {
        if (data.data) {
          data.data.ppsn_document_type = globalStatus(
            'ppsn_document_type',
            'key',
            data.data.ppsn_document_type
          );
        }
        this.peopleValues = data.data;
        this.ViewPeopleList();
        return data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  ViewPeopleList = () => {
    this.list_related_ORG = this.peopleValues && this.peopleValues.related_organisations;

    this.list_related_Ppl = this.peopleValues && this.peopleValues.related_peoples;

    this.list_trade_transaction =
      this.peopleValues && this.peopleValues.trade_transactions;

    this.list_cash_transaction = this.peopleValues && this.peopleValues.cash_transactions;

    this.list_banks = this.peopleValues && this.peopleValues.banks;
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!['photo_url', 'date_of_birth', 'add_address'].includes(key)) {
        formData.append(
          key,
          data[key] !== undefined && data[key] !== null ? data[key] : ''
        );
      }
    });
    return formData;
  };

  getAllPeople = (payload) => {
    this.drowpdown_people_list = null;
    if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
      this.fetching = true;
      return Axios.post(`organisations/current-ledger/get`, payload)
        .then(({ data }) => {
          this.drowpdown_people_list = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetching = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  /**----USED IN NEW REPORT FORMAT------- */
  getAllPeopleNameDropdown = (payload) => {
    this.drowpdown_people_name_list = null;
    if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
      this.fetchingNameDropdown = true;
      return Axios.post(`organisations/current-ledger/get`, payload)
        .then(({ data }) => {
          this.drowpdown_people_name_list = data.data;

          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingNameDropdown = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  getAllPeopleSOLDConnections = (payload) => {
    this.dropdown_people_connection_list = null;
    if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
      this.fetchingConnection = true;
      return Axios.post(`organisations/current-ledger/get`, payload)
        .then(({ data }) => {
          this.dropdown_people_connection_list = data.data;
          return data;
        })
        .catch(({ response: { data } }) => {
          return data;
        })
        .finally(() => {
          this.fetchingConnection = false;
        });
    } else {
      return Promise.resolve({ data: null });
    }
  };

  getPeopleCardValue = (id, type) => {
    return Axios.post(`global/card/read/${id}`, { entity_type: 2 })
      .then(({ data }) => {
        if (type === 'relatedPeople') {
          this.rPeopleCardValue = data.data;
        } else if (type === 'people') {
          this.peopleCardValue = data.data;
        }
        return data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGridRelOrg = params;
    if (this.agGridRelOrg && this.agGridRelOrg.api.rowModel.rowsToDisplay.length === 0) {
      this.agGridRelOrg.api.showNoRowsOverlay();
    }
    if (this.agGridRelOrg && this.agGridRelOrg.api.rowModel.rowsToDisplay.length > 0) {
      this.agGridRelOrg.api.hideOverlay();
    }
  };
  onFilterChangedRelatedPeople = (params) => {
    this.agGridRelPeo = params;
    if (this.agGridRelPeo && this.agGridRelPeo.api.rowModel.rowsToDisplay.length === 0) {
      this.agGridRelPeo.api.showNoRowsOverlay();
    }
    if (this.agGridRelPeo && this.agGridRelPeo.api.rowModel.rowsToDisplay.length > 0) {
      this.agGridRelPeo.api.hideOverlay();
    }
  };

  // reset all the server side filters
  // Reset : Will reset all filters, sorting, saved columns settings.
  handleReset = () => {
    reset(this.agGrid, "people_grid", this.setSelectedGridFilter)
  }

  /** This is called on clear button */
  // Clear : Will clear all applied Filter and sorting, And will reset to default.
  // saveParams = JSON.parse(localStorage.getItem('params'))
  // onLoadFliter = this.saveParams?.PEOPLE?.filter
  applyFilterDefault = () => {
    clear(this.agGrid)
  };

  handleResetRelatedOrganisation = () => {
    this.agGridRelOrg.api.setFilterModel(null);
    this.agGridRelOrg.api.setSortModel(null);
    this.agGridRelOrg.api.onFilterChanged(null);
  };
  handleResetRelatedPeople = () => {
    this.agGridRelPeo.api.setFilterModel(null);
    this.agGridRelPeo.api.setSortModel(null);
    this.onFilterChangedRelatedPeople(null);
  };
  // Set column width after resizing colums

  onGridChangedSearch = (params) => {
    localStorage.setItem(
      'search_people_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };
  onGridChanged = (params) => {
    localStorage.setItem(
      'people_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };
  onGridChangedRelatedOrganisation = (params) => {
    localStorage.setItem(
      'RelatedOrganisationList_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };
  onGridChangedRelatedPeople = (params) => {
    localStorage.setItem(
      'RelatedPeopleList_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // get Industries list
  getIndustries = () => {
    return Axios.get(`organisations/global/industries`).then(({ data }) => {
      this.industries_list = data.data;
      return data;
    });
  };

  getLeadTransactionID = (id = 0) => {
    return Axios.post(`create-lead-from-people/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PEOPLE: { filter: filterdata },
        })
      );

      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  // Call Audit
  callAudit = (payload) => {
    return Axios.post(`leads-transactions/call-audit/GlobalPeople`, payload).then(
      ({ data }) => {
        return data;
      }
    );
  };

  //Used On Waste Tab Change
  resetGridFilterForWaste = () => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));

      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PEOPLE: {
            filter: {},
          },
        })
      );

      this.agGrid.api.setFilterModel({});
    }
  };

  setMasterPeopleToMerge = (data) => {
    this.masterPeopleToMerge = data;
  };
  handleMergePeople = (data) => {
    return Axios.post(`global/people/merge-people`, data)
      .then(({ data }) => {
        this.isMergePeople = true;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setIsSavedFiltersLoadedPeople = (data) => {
    this.masterPeopleToMerge = data;
  };
}

decorate(PeopleStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  agGridRelOrg: observable,
  deleteValues: observable,
  peopleValues: observable,
  rPeopleValues: observable,
  initialValues: observable,
  fetching: observable,
  dropdown_pplRole_list: observable,
  viewValues: observable,
  cloneValues: observable,
  list_related_ORG: observable,
  list_related_Ppl: observable,
  list_trade_transaction: observable,
  list_cash_transaction: observable,
  list_banks: observable,
  drowpdown_people_list: observable,
  dropdown_RpplRole_list: observable,
  peopleCardValue: observable,
  rPeopleCardValue: observable,
  search_payload: observable,
  waste: observable,
  industries_list: observable,
  exportLoadingIcn: observable,
  viewPeopleLoading: observable,
  editPeopleLoading: observable,
  dropdown_user_list: observable,
  selectedGridFilter: observable,
  masterPeopleToMerge: observable,
  peopleStatusList: observable,
  fetchingConnection: observable,
  dropdown_people_connection_list: observable,
  isSavedFiltersLoadedPeople: observable,
  fetchingNameDropdown: observable,
  drowpdown_people_name_list: observable,
  gridLoading: observable,
  isMergePeople:observable,
  setPageSize: action,
  getList: action,
  setupGrid: action,
  setupGridSearch: action,
  getPeopleStatus: action,
  setupGridRelatedOrganisation: action,
  setupGridRelatedPeople: action,
  setupGridTradeTransactions: action,
  setupGridCashTransactions: action,
  setupGridBanks: action,
  setDeleteValues: action,
  setViewValues: action,
  getRecordDetail: action,
  getRPplRecordDetail: action,
  setPeopleValues: action,
  setRPeopleValues: action,
  AddData: action,
  EditData: action,
  CloneData: action,
  DeleteData: action,
  ImportPeopleFile: action,
  ImportPeopleRoleFile: action,
  getPeopleRoles: action,
  getRelatedPeopleRoles: action,
  getAllRPeople: action,
  ViewPeopleList: action,
  getViewPplDetail: action,
  JsonToFormData: action,
  ExportPeople: action,
  ExportPeopleRoleMappint: action,
  getAllPeople: action,
  getPeopleSearchList: action,
  getPeopleCardValue: action,
  setCloneValues: action,
  RestoreData: action,
  handleReset: action,
  onGridChanged: action,
  handleResetRelatedOrganisation: action,
  onGridChangedRelatedOrganisation: action,
  getIndustries: action,
  getLeadTransactionID: action,
  getUsersList: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  callAudit: action,
  resetGridFilterForWaste: action,
  setMasterPeopleToMerge: action,
  getAllPeopleSOLDConnections: action,
  handleMergePeople: action,
  setIsSavedFiltersLoadedPeople: action,
  applyFilterDefault: action,
  getAllPeopleNameDropdown: action,
  onGridChangedSearch: action,
  getListSearch: action,
});
