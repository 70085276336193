import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "antd/dist/antd.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./scss/index.scss";
import "./i18n";

ReactDOM.render(<App />, document.getElementById("root"));
