import React from 'react'
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { Drawer, Spin } from 'antd'
import PreviewDataGridComponent from '../components/PreviewDataGridComponent';

const TicketPriorityDrawer = observer((props) => {
    const { visible, close, form, title } = props;
    const { REPORTSTORE } = useStore();

    return (
        <Drawer
            visible={visible}
            onClose={close}
            placement="right"
            width={"1200px"}
            title={title}
            destroyOnClose={true}
        >
            <Spin
                size='large'
                spinning={REPORTSTORE.drilldownLoader}
            >
                <PreviewDataGridComponent
                    rowData={REPORTSTORE.ticketPriorityDataByUser}
                    form={form}
                />
            </Spin>
        </Drawer>
    )
})

export default TicketPriorityDrawer