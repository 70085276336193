import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';

import TinyComponent from '../../../component/TinyComponent';
//import { vsmTags } from '../../../config/messages';

const EditorFieldElement = observer(
  ({ form, handleChange, setContent, content, visible }) => {
    const [resetContent, setResetContent] = useState(0);

    const EditorConfig = {
      selector: 'textarea#full-featured-non-premium',
      plugins:
      'print preview paste importcss searchreplace autolink  save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
      menubar: false,
      toolbar:
      'removeformat | bullist numlist | undo redo | forecolor backcolor | fontsizeselect  bold italic underline strikethrough  | charmap | formatselect | upperCaseButton lowerCaseButton | crossButton pipeButton  emoticons insertdatetime |  newdocument superscript subscript  selectall cut copy paste remove | lineheight | alignleft aligncenter alignright alignjustify indent outdent  ',
    insertdatetime_formats: ['%Y-%m-%d', '%D', '%H:%M:%S', '%I:%M:%S %p', '%A', '%B'],
      contextmenu: false,
      height: 200,
    };
    useEffect(() => {
      setResetContent(!visible);
    }, [visible, setResetContent]);

    return (
      <Form.Item className='editorInput'>
        <Form.Item
          name='tag_content'
          noStyle
          onChange={handleChange}
          //rules={vsmTags.validation.tag_response}
        >
          <TinyComponent
            EditorConfig={EditorConfig}
            handleFormChange={handleChange}
            isKnowledge={true}
            //initialValue={`<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`}
            editorvalue={content}
            resetContent={resetContent}
            form={form}
            htmlName='tag_content'
          />
        </Form.Item>
      </Form.Item>
    );
  }
);

export default EditorFieldElement;
