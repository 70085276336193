import React, { useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import DisplayMsgList from "../component/DisplayMsgList";
import Footer from "../component/footer";
import useStore from "../../../../store";
import { Spin } from "antd";

const Sequence = observer((props) => {
  const [summeryWidth, setSummeryWidth] = useState(0);

  const updateWidth = () => {
    // setTimeout(() => {
    const parent = document.querySelector(".Dealdesk_MainTab");
    if (parent) {
      setSummeryWidth(parent.offsetWidth);
    }
    // }, 0); // Ensures DOM is ready
  };

  useLayoutEffect(() => {
    updateWidth(); // Set width on mount

    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);
  const {
    DEALDESKSTORE: { deal_data },
    DEALDESKNOTESEQUENCE: {
      sequence_notes_display_data,
      sequencenotes_list,
      dataValue,
      noteLoader,
    },
  } = useStore();

  const sequenceList = true;

  let dealid = deal_data.lead_information && deal_data.lead_information.id;

  const {
    openExpandModalSeq,
    openModalSeq,
    close,
    isSpecific = false,
    open,
  } = props;
  return (
    <div
      className="displayBoxWithFooter"
      id="displayBoxWithFooterId"
      style={{ position: "relative" }}
    >
      <Spin
        size="large"
        spinning={noteLoader}
        style={{
          textAlign: "center",
          padding: "50px 30px",
        }}
      >
        <DisplayMsgList
          msgBoxArray={
            isSpecific ? sequence_notes_display_data : sequencenotes_list
          }
          type="sequence"
          dealid={dealid}
        />
      </Spin>
      {!open && (
        <div
          style={{
            position: "fixed",
            bottom: "16px",
            width: `${summeryWidth}px`,
          }}
        >
          <Footer
            msgBoxArray={{
              get: isSpecific
                ? sequence_notes_display_data
                : sequencenotes_list,
              set: dataValue,
            }}
            openExpandModalSeq={openExpandModalSeq}
            openModalSeq={openModalSeq}
            close={close}
            sequenceList={sequenceList}
          />
        </div>
      )}
    </div>
  );
});

export default Sequence;
