import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import useStore from "../store";
import { Button, Col, Row, Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import moment from "moment";
import { useTranslation } from "react-i18next";

const IdleTracker = ({ idleTime = 450000 }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [areYouWorkingPopup, setAreYouWorkingPopup] = useState(true);
  const [timeout_id, set_timeout_id] = useState(null);
  const [started, setStarted] = useState(true);

  const { t } = useTranslation();

  const {
    AUTH,
    AUTH: { user, setIsUserIdle, setCallNotesAPIAfterUserBack },
    TimeTrackerStore,
  } = useStore();

  if (
    user &&
    user.bonus_figures &&
    user.bonus_figures.length > 0 &&
    user.bonus_figures[0]?.idle_popup_duration
  ) {
    idleTime =
      (parseInt(moment(user.bonus_figures[0].idle_popup_duration).format("m")) *
        60 +
        parseInt(
          moment(user.bonus_figures[0].idle_popup_duration).format("s")
        )) *
      1000;
  }

  const onIdle = () => {
    setPopupVisible(true);
    setAreYouWorkingPopup(true);

    /** For Timer */
    TimeTrackerStore.setIsPopupInitialized(true);
    const setTimeoutId = setTimeout(() => {
      setAreYouWorkingPopup(false);
      TimeTrackerStore.setIsBackPopup(true);
    }, 10000);
    set_timeout_id(setTimeoutId);

    /**for api call stop */
    setIsUserIdle(true); //to be discussed
  };

  useEffect(() => {
    if (!popupVisible && timeout_id) {
      clearTimeout(timeout_id);
    }
  }, [popupVisible, timeout_id]);

  /**Cross Tab Featue to be enabled*/
  const { start, pause } = useIdleTimer({
    timeout: idleTime,
    onIdle: onIdle,
    debounce: 500,
    startOnMount: true,
    startManually: true,
    stopOnIdle: true,
  });

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("doProxyLogIn"))) {
      pause();
    }
  }, [pause]);

  useEffect(() => {
    if (started) {
      start();
      setStarted(false);
    }
  }, [started, start]);

  const onOkay = () => {
    if (areYouWorkingPopup) {
      start();
      setAreYouWorkingPopup(false);
      TimeTrackerStore.setIsWorkingPopupClicked(true);
    } else {
      TimeTrackerStore.setIsBackPopup(false);
      start();
    }
    setPopupVisible(false);

    /**for api call restart */
    setIsUserIdle(false);
    setCallNotesAPIAfterUserBack(true);
  };

  return (
    AUTH && (
      <Modal
        centered
        title={`Deal Maker`}
        closable={false}
        visible={popupVisible}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        zIndex={99999}
        footer={[
          <Button shape="round" key="2" onClick={() => onOkay()} type="primary">
            {areYouWorkingPopup
              ? `${t("Common_Data.Working_Text")}`
              : `${t("Common_Data.Working_Back_Text")}`}
          </Button>,
        ]}
      >
        <Row align="middle">
          <Col span={4} className="DeleteIcon">
            <WarningFilled />
          </Col>
          <Col span={20} className="DeleteText">
            {areYouWorkingPopup
              ? `${t("Common_Data.Still_Working_Text")}`
              : `${t("Common_Data.Are_you_back_Text")}`}
          </Col>
        </Row>
      </Modal>
    )
  );
};

export default IdleTracker;
