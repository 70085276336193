import React, { useCallback, useEffect } from 'react';
import { Spin, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const TagSelection = observer((props) => {
  const {t} = useTranslation();
  const {
    selectedTagGroup,
    selectedTagUser,
    handleTagUserChange,
    handleTagGroupChange,
    autoSaveData,
    saveObj,
  } = props;

  const handleKeyDown = useCallback(
    (event) => {
      if (!props.tagselectionPopupOpen) {
        return false;
      }
      let finalArrUser = [];
      let finalArrGroup = [];

      let keyPressed = event.key;
      if (event.shiftKey) {
        let resultGroup =
          props.lockUserList &&
          props.lockUserList.userGroupList
            .filter(
              (x) =>
                !props.selectedTagGroup.includes(x.id) &&
                x.group_name.charAt(0) === keyPressed.toUpperCase()
            )
            .shift();

        if (resultGroup !== undefined) {
          if (props.selectedTagGroup && props.selectedTagGroup.length > 0) {
            let allMatchedGroupIDs = [];
            let selectedGroupIndex = 0;
            props.lockUserList &&
              props.lockUserList.userGroupList.forEach((x, groupIndex) => {
                if (x.group_name.charAt(0) === keyPressed.toUpperCase()) {
                  allMatchedGroupIDs.push(x.id);
                  if (props.selectedTagGroup.includes(x.id)) {
                    selectedGroupIndex = groupIndex;
                  }
                }
              });

            if (allMatchedGroupIDs && allMatchedGroupIDs.length > 1) {
              if (selectedGroupIndex) {
                resultGroup = props.lockUserList.userGroupList[selectedGroupIndex + 1];
                if (resultGroup) {
                  if (resultGroup.group_name.charAt(0) !== keyPressed.toUpperCase()) {
                    resultGroup =
                      props.lockUserList &&
                      props.lockUserList.userGroupList
                        .filter((x) => x.id === allMatchedGroupIDs[0])
                        .shift();
                  }
                }
              }

              let latestArry = [];
              props.selectedTagGroup.forEach((selectedGroup) => {
                if (allMatchedGroupIDs.includes(selectedGroup)) {
                } else {
                  latestArry.push(selectedGroup);
                }
              });
              finalArrGroup = [...latestArry, resultGroup.id];
              props.setFormTagGroup([...latestArry, resultGroup.id]);
            } else {
              if (props.selectedTagUser.length > 0) {
                finalArrGroup = [...props.selectedTagGroup, resultGroup.id];
                props.setFormTagGroup([...props.selectedTagGroup, resultGroup.id]);
              } else {
                finalArrGroup = [resultGroup.id];
                props.setFormTagGroup([resultGroup.id]);
              }
            }
          } else {
            finalArrGroup = [resultGroup.id];
            props.setFormTagGroup([resultGroup.id]);
          }
        } else {
          /**TO CLEAR SINGLE CHARCTER SELECTED VALUE */
          if (props.selectedTagGroup && props.selectedTagGroup.length > 0) {
            let resultClear =
              props.lockUserList &&
              props.lockUserList.userGroupList
                .filter((x) => x.group_name.charAt(0) === keyPressed.toUpperCase())
                .shift();

            if (resultClear) {
              let latestArry = [];
              props.selectedTagGroup.forEach((gid) => {
                if (resultClear.id !== gid) {
                  latestArry.push(gid);
                }
              });
              finalArrGroup = latestArry;
              props.setFormTagGroup(latestArry);
            }
          }
        }
      } else {
        let result =
          props.lockUserList &&
          props.lockUserList.userList
            .filter(
              (x) =>
                !props.selectedTagUser.includes(x.id) &&
                x.name.charAt(0) === keyPressed.toUpperCase()
            )
            .shift();

        if (result !== undefined) {
          if (props.selectedTagUser && props.selectedTagUser.length > 0) {
            let allMatchedUserIDs = [];
            let selectedUserIndex = 0;
            props.lockUserList &&
              props.lockUserList.userList.forEach((x, groupIndex) => {
                if (x.name.charAt(0) === keyPressed.toUpperCase()) {
                  allMatchedUserIDs.push(x.id);
                  if (props.selectedTagUser.includes(x.id)) {
                    selectedUserIndex = groupIndex;
                  }
                }
              });

            if (allMatchedUserIDs && allMatchedUserIDs.length > 1) {
              if (selectedUserIndex) {
                result = props.lockUserList.userList[selectedUserIndex + 1];
                if (result) {
                  if (result.name.charAt(0) !== keyPressed.toUpperCase()) {
                    result =
                      props.lockUserList &&
                      props.lockUserList.userList
                        .filter((x) => x.id === allMatchedUserIDs[0])
                        .shift();
                  }
                }
              }

              let latestArry = [];
              props.selectedTagUser.forEach((selectedGroup) => {
                if (allMatchedUserIDs.includes(selectedGroup)) {
                } else {
                  latestArry.push(selectedGroup);
                }
              });
              finalArrUser = [...latestArry, result.id];
              props.setFormTagUser([...latestArry, result.id]);
            } else {
              if (props.selectedTagUser.length > 0) {
                finalArrUser = [...props.selectedTagUser, result.id];
                props.setFormTagUser([...props.selectedTagUser, result.id]);
              } else {
                finalArrUser = [result.id];
                props.setFormTagUser([result.id]);
              }
            }
          } else {
            finalArrUser = [result.id];
            props.setFormTagUser([result.id]);
          }
        } else {
          /**TO CLEAR SINGLE CHARCTER SELECTED VALUE */
          if (props.selectedTagUser && props.selectedTagUser.length > 0) {
            let resultClear =
              props.lockUserList &&
              props.lockUserList.userList
                .filter((x) => x.name.charAt(0) === keyPressed.toUpperCase())
                .shift();

            if (resultClear) {
              let latestArry = [];
              props.selectedTagUser.forEach((uid) => {
                if (resultClear.id !== uid) {
                  latestArry.push(uid);
                }
              });
              finalArrUser = latestArry;
              props.setFormTagUser(latestArry);
            }
          }
        }
      }
      if (saveObj) {
        let newObj = saveObj();
        if (event.shiftKey) {
          newObj.formTagGroup = finalArrGroup;
        } else {
          newObj.formTagUser = finalArrUser;
        }
        autoSaveData(newObj);
      } else {
        autoSaveData();
      }
    },
    [props, autoSaveData, saveObj]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return props.lockUserList ? (
    <div className='ant-popover-inner-content'>
      <div className='leadStatusOptins lockUserList'>
        <ul>
          {props.lockUserList && props.lockUserList.userGroupList && (
            <li key='1'>
              <span style={{ padding: '0 0 0 8px' }}>{t('DealDesk.Users_Group')}</span>
              <ul>
                {props.lockUserList.userGroupList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        selectedTagGroup && selectedTagGroup.includes(item.id)
                          ? 'active'
                          : ''
                      }
                      onClick={() => {
                        handleTagGroupChange(item);
                      }}
                    >
                      <Tooltip title={item.group_name} placement='left'>
                        {item.group_name}
                      </Tooltip>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}
          {props.lockUserList && props.lockUserList.userList && (
            <li key='2'>
              <span style={{ padding: '0 0 0 8px' }}>{t('DealDesk.Users_List')}</span>
              <ul>
                {props.lockUserList.userList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        selectedTagUser && selectedTagUser.includes(item.id)
                          ? 'active'
                          : ''
                      }
                      onClick={() => {
                        handleTagUserChange(item);
                      }}
                    >
                      <Tooltip title={item.name} placement='right'>
                        {item.name}
                      </Tooltip>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}
        </ul>
      </div>
    </div>
  ) : (
    <div className='ant-popover-inner-content'>
      <div className='leadStatusOptins lockUserList'>
        <ul className='popover-menu cursorPointer spinInside'>
          <Spin tip={`${t('Common_Data.Load')}...`} />
        </ul>
      </div>
    </div>
  );
});
export default TagSelection;
