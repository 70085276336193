import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Spin, Form, Button } from 'antd';

import useStore from '../../../../store';
import { vsmTransactionReport, vsmNotify } from '../../../../config/messages';
import FormComponent from './FormComponent';
import { useTranslation } from 'react-i18next';
import { convertError } from '../../../../utils/GlobalFunction';

const AddSummarycomponent = observer((props) => {
  //----------STATE VARIABLE DECLARATION---------------//
  const [saving, setSaving] = useState(false);
  const [tip, setTip] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [submitSaving, setSubmitSaving] = useState(false);
  const [savePreviewSaving, setsavePreviewSaving] = useState(false);
  const [formDataList, setFormDataList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [countsListReport, setCountsListReport] = useState(false);

  const { t } = useTranslation();

  //----------STATE VARIABLE DECLARATION---------------//

  //----------CONST VARIABLE DECLARATION---------------//
  const { visible } = props;
  const [form] = Form.useForm();
  const defaultFormDataList = ['report_type'];
  //----------CONST VARIABLE DECLARATION---------------//

  //----------GLOBAL STATE DECLARATION---------------//
  const {
    AUTH,
    REPORTSTORE,
    REPORTSTORE: {
      setPreviewReportValues,
      initialSummaryValues,
      PreviewData,
      AddData,
      selectedReportFieldsKeys,
      setSelectedReportFieldsKeys,
    },
    AUTH: { checkPermission },
  } = useStore();
  //----------GLOBAL STATE DECLARATION---------------//

  //----------USED FUNCTIONS---------------//
  const handleSubmit = (data, isPreview = false) => {
    data.form_list = formDataList;
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.check_unique_code = true;
    data.grid_columns = selectedReportFieldsKeys;

    // return false;
    setSaving(true);
    if (isPreview) {
      setsavePreviewSaving(true);
    } else {
      setSubmitSaving(true);
    }
    setTip('Saving');
    
    let users = data.users
    let owners = data.owners
    let holders = data.holders
    let user_groups = data.user_groups
    if (data.report_by === "all") {
      data.users = null;
      data.owners = null;
      data.holders = null;
      data.user_groups = null;
    } else if (data.report_by === "users") {
      data.users = users;
      data.owners = null;
      data.holders = null;
      data.user_groups = null;
    } else if (data.report_by === "owners") {
      data.users = null;
      data.owners = owners;
      data.holders = null;
      data.user_groups = null;
    } else if (data.report_by === "holders") {
      data.users = null;
      data.owners = null;
      data.holders = holders;
      data.user_groups = null;
    } else if (data.report_by === "user_groups") {
      data.users = null;
      data.owners = null;
      data.holders = null;
      data.user_groups = user_groups;
    }

    AddData(data)
      .then((res) => {
        vsmNotify.success({
          message: vsmTransactionReport.save,
        });

        if (res.report) {
          REPORTSTORE.created_by_id = res.report.created_by;
          REPORTSTORE.previewReportValues = {
            ...JSON.parse(res.report.generated_on_options),
            id: res.report.id,
            name: res.report.name,
            code: res.report.code,
            description: res.report.description,
          };
          if (REPORTSTORE.previewReportValues?.global_toggle !== undefined) {
            REPORTSTORE.previewReportValues.global_toggle =
              !REPORTSTORE.previewReportValues.global_toggle;
          } else {
            REPORTSTORE.previewReportValues.global_toggle = false;
          }
        }

        if (isPreview) {
          openPreviewReport();
        } else {
          props.close();
        }
        if (REPORTSTORE.my_records === 'my') {
          REPORTSTORE.getList({}, AUTH.user.id);
        } else {
          REPORTSTORE.getList();
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(convertError(e.errors));
          setDisabled(true);
        }
      })
      .finally(() => {
        setSaving(false);
        setSubmitSaving(false);
        setsavePreviewSaving(false);
        setTip(null);
      });
  };

  const openPreviewReport = () => {
    let data;

    REPORTSTORE.setPreviewReportValues({
      ...REPORTSTORE.previewReportValues,
      ...form.getFieldsValue(),
      form_list: formDataList,
    });

    data = REPORTSTORE.formatFormData(REPORTSTORE.previewReportValues, true, true);
    data.action = 'preview-data';
    data.check_unique_code = true;
    data.grid_columns = selectedReportFieldsKeys;

    setSaving(true);
    setTip(`${t('Common_Data.Load')}`);
    PreviewData(data)
      .then((data) => {
        REPORTSTORE.created_by_id = AUTH.user?.id;
        props.openPreviewDataModal(props.report_id);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(convertError(e.errors));
        }
      })
      .finally(() => {
        setSaving(false);
        setTip(null);
      });
  };

  const savePreviewReport = (data = form.getFieldsValue()) => {
    data.form_list = formDataList;
    data = REPORTSTORE.formatFormData({
      ...REPORTSTORE.previewReportValues,
      ...data,
    });
    data.action = 'save_report';
    data.selectedReportFieldsKeys = selectedReportFieldsKeys;
    handleSubmit(data, true);
  };

  const close = () => {
    props.close();
    setPreviewReportValues(null);
    setSaving(true);
    setIndeterminate(false);
    setCheckAll(false);
    setTip(`${t('Common_Data.Load')}`);
    setSelectedReportFieldsKeys([]);
  };
  //----------USED FUNCTIONS---------------//

  return (
    <Drawer
      visible={visible}
      width={'1100px'}
      title={t('Reports.Add_Report')}
      destroyOnClose={true}
      onClose={close}
      footer={[
        <div className='text-right' key='1'>
          {
            form.getFieldValue('code') &&
            (form.getFieldValue('report_type') === 'kpi' ? true : form.getFieldValue('report_on')) &&
            (
              <>
                {
                  <Button
                    key='1'
                    className='mr-10'
                    htmlType='button'
                    shape='round'
                    disabled={isDisabled}
                    onClick={() => {
                      openPreviewReport();
                    }}
                  >
                    {t('Reports.Preview')} {t('Reports.Data')}
                  </Button>
                }
                {checkPermission(21, 'add') && (
                  <Button
                    key='2'
                    form='addformReport'
                    className='mr-10'
                    htmlType='submit'
                    shape='round'
                    type='primary'
                    disabled={isDisabled}
                    loading={submitSaving}
                  >
                    {t('Common_Data.Save')}
                  </Button>
                )}
                {checkPermission(21, 'add') && (
                  <Button
                    key='3'
                    className='mr-10'
                    shape='round'
                    disabled={isDisabled}
                    onClick={() => {
                      savePreviewReport();
                    }}
                    loading={savePreviewSaving}
                  >
                    {t('Common_Data.Save')} & {t('Reports.Preview')}
                  </Button>
                )}
              </>
            )}

          <Button shape='round' onClick={close} key='4'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <Spin size='large' spinning={saving} tip={tip}>
        <FormComponent
          defaultFormDataList={defaultFormDataList}
          formDataList={formDataList}
          setFormDataList={setFormDataList}
          form={form}
          initialValues={{
            ...initialSummaryValues,
          }}
          id='addformReport'
          setDisabled={setDisabled}
          handleSubmit={handleSubmit}
          checkAll={checkAll}
          setCheckAll={setCheckAll}
          indeterminate={indeterminate}
          setIndeterminate={setIndeterminate}
          countsListReport={countsListReport}
          setCountsListReport={setCountsListReport}
        />
      </Spin>
    </Drawer>
  );
});

export default AddSummarycomponent;
