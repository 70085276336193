import React, { useCallback, useState } from 'react';
// import moment from 'moment';
import debounce from 'lodash/debounce';
import { arrayMoveImmutable } from 'array-move';
import { calculateHeight, isElementOutViewport } from '../../../../utils/GlobalFunction';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import ScoreHeader from './ScoreHeader';
import ScoreContent from './ScoreContent';

const ScoreComponent = ({
  Score,
  form,
  handleFormChange,
  isFromLibrary = false,
  SetScore,
  forceUpdate = () => { },

  openViewDrawer,
  INITIAL_HEIGHT,
}) => {
  let themecolor = localStorage.getItem('app_color_theme');

  let isPositive = true;
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }

  //let tempTotalWeught = 0;
  const [totalWeight, setTotalWeight] = useState(0);

  const ADD = require(`../../../../assets/images/${themecolor}/add.svg`).default;
  //------------------------------------------------ Scroll To New Node ---------------------------------------------------------//
  let parentNode = false;
  const scrollToNode = debounce((id) => {
    localStorage.removeItem('last_focusedElementScore');
    let newElementID = 0;

    if (id) {
      let schedule_content = document.getElementById(id);
      let test = schedule_content.querySelector('.fields_draggable_container');
      if (test && test.lastElementChild.id) {
        newElementID = test.lastElementChild.id;
      }
    } else {
      let test = document.getElementById('field_draggable_container_schedule');

      if (test) {
        newElementID = test.lastElementChild?.id;
      }
    }
    if (newElementID && document.getElementById(newElementID)) {
      if (document.getElementById(newElementID).classList) {
        document.getElementById(newElementID).classList.add('blink-BGanimation');
        if (newElementID) {
          document.querySelector('#' + newElementID + ' .data textarea').focus();
          let splitArr = newElementID.split('score_innerContent_');
          if (splitArr) {
            let focusedID = splitArr ? newElementID.split('score_innerContent_')[1] : '';
            if (focusedID) {
              localStorage.setItem('last_focusedElementScore', focusedID);
            }
          }
        }
      }

      if (!isElementOutViewport(document.getElementById(newElementID))) {
        document.getElementById(newElementID).scrollIntoView();
      }
      setTimeout(() => {
        if (
          document.getElementById(newElementID) &&
          document.getElementById(newElementID).classList
        ) {
          document.getElementById(newElementID).classList.remove('blink-BGanimation');
        }
      }, 1000);
    }
  }, 500);

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------ Add Sibling Item  ----------------------------------------------------------//

  const addNodeByIndex = () => {
    let lasFocused = localStorage.getItem('last_focusedElementScore');
    let item_key = lasFocused;
    if (item_key) {
      let finalItemKey;
      let splitArr = item_key.split('-');

      if (splitArr.length === 1) {
        finalItemKey = 0;
      } else {
        if (splitArr.length > 0) {
          splitArr.splice(splitArr.length - 1, 1);
        }
        if (splitArr.length === 1) {
          finalItemKey = Number(splitArr[0]);
        } else {
          finalItemKey = splitArr.join('-');
        }
      }
      let scrollID = finalItemKey === 0 ? 0 : `score_innerContent_${finalItemKey}`;

      AddNode(finalItemKey);
      scrollToNode(scrollID);
    }
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------- Add New Node  -------------------------------------------------------------//

  const AddNode = (ItemKey, isParent = true) => {
    let finalData = Score;
    let position = 1;
    let detail = '';
    let item_key = null;
    let weight = '';
    let height = '18px';
    let isWeightDisabled = true;
    parentNode = isParent;
    let isPositive = true;

    const add = (NodeArray, ItemKey) => {
      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          if (!x.children) {
            x.children = [];
          } else {
            if (x.children.length > 0) {
              position = parseInt(x.children[x.children.length - 1].position) + 1;
              parentNode = false;
              isPositive = !isPositive ? false : isPositive;
              x.isPositive = false;
            } else {
              position = 1;
              x.isPositive = !x.isPositive ? false : true;
            }
          }


          item_key = [x.item_key, x.children.length].join('-');
          let start_dateFromParent = x.start_date;
          x.weight = 0;
          weight = 0;

          detail = '';
          x.children.push({
            item_key,
            position,
            detail,
            height,
            weight,
            start_date: start_dateFromParent,
            parentNode,
            isPositive
          });
        } else {
          if (x.children && x.children.length > 0) {
            add(x.children, ItemKey);
          }
        }
      });
    };
    if (ItemKey) {
      add(finalData, ItemKey);
    } else {
      if (finalData.length > 0) {
        position = parseInt(finalData[finalData.length - 1].position) + 1;
        item_key = finalData.length + 1;
        // setItemKey(prevval => prevval + 1);
        weight = 0;
      } else {
        position = 1;
        item_key = 1;
        weight = 0;
      }
      detail = '';
      finalData.push({ item_key, position, detail, height, weight, isWeightDisabled, parentNode, isPositive });
    }

    //-----------------------START: TO REAGRANGE POSITION-----------------//
    let startingPosition = 0;
    finalData &&
      finalData.forEach((obj, index) => {
        obj.item_key = index + 1;
        if (index === 0) {
          startingPosition = obj.position;
        } else {
          obj.position = startingPosition + index;
        }
      });
    //-----------------------END: TO REAGRANGE POSITION-----------------//

    SetScore(finalData);
    GeneratePercentage(100, finalData);
    handleFormChange();
    forceUpdate();
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------- Remove Node(Also update item key based on Index)  -------------------------------------------//

  const RemoveNode = (ItemKey) => {
    let finalData = Score;
    let startPosition = 0;

    const remove = (NodeArray, ItemKey, IsChild) => {
      NodeArray.forEach((x, index) => {
        if (index === 0 && !IsChild) {
          startPosition = x.position;
        }
        if (x.item_key === ItemKey) {
          if (IsChild) {
            NodeArray.splice(index, 1);
          } else {
            NodeArray.splice(index, 1);
          }
        } else {
          if (x.children && x.children.length > 0) {
            remove(x.children, ItemKey, true);
          }
        }
      });
    };
    remove(finalData, ItemKey);

    //-----------------------START: TO REAGRANGE POSITION-----------------//

    finalData &&
      finalData.forEach((obj, index) => {
        obj.item_key = index + 1;
        if (index === 0) {
          obj.position = startPosition;
        } else {
          obj.position = startPosition + index;
        }
        obj.height = calculateHeight(
          `score_innerContent_textarea_${obj.item_key}`,
          INITIAL_HEIGHT,
          obj.detail,
          true
        );

        if (obj.children && obj.children.length > 0) {
          obj.children.forEach((obj1, index1) => {
            obj1.item_key = [obj.item_key, index1].join('-');
            obj1.position = index1 + 1;

            obj1.height = calculateHeight(
              `score_innerContent_textarea_${obj1.item_key}`,
              INITIAL_HEIGHT,
              obj1.detail,
              true
            );
            if (obj1.children && obj1.children.length > 0) {
              obj1.children.forEach((obj2, index2) => {
                obj2.item_key = [obj1.item_key, index2].join('-');
                obj2.position = index2 + 1;

                obj2.height = calculateHeight(
                  `score_innerContent_textarea_${obj2.item_key}`,
                  INITIAL_HEIGHT,
                  obj2.detail,
                  true
                );
              });
            } else if (obj1.day === 0 && obj1.children?.length === 0) {
              obj1.day = 0;
            }
          });
        } else if (obj.day === 0 && obj.children?.length === 0) {
          obj.day = 0;
        }
      });
    //-----------------------END: TO REAGRANGE POSITION-----------------//

    SetScore(finalData);
    // GeneratePercentage(SelectedDate, is_checked, finalData);
    GeneratePercentage(100, finalData);
    handleFormChange();
    forceUpdate();
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //----------------------------------------- Change Node(Update value based on key)  -------------------------------------------//

  const ChangeNode = (ItemKey, Value, key) => {
    let finalData = Score;
    const change = (NodeArray, ItemKey, childLoop = false) => {
      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          x[key] = Value;
        } else {
          if (x.children && x.children.length > 0) {
            change(x.children, ItemKey, true);
          }
        }
      });
    };
    change(finalData, ItemKey);
    SetScore(finalData);

    if (key === 'isPositive') {
      forceUpdate();
      GeneratePercentage();
    }
  };

  const resetPosition = (value) => {
    value = Number(value);
    let finalData = Score;

    finalData.forEach((obj, index) => {
      if (index === 0) {
        obj.position = value;
      } else {
        obj.position = value + index;
      }
    });

    SetScore(finalData);
    forceUpdate();
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------ Exchange Node(Drag and Drop based on NewIndex,OldIndex)  -------------------------------------//

  const ExchangeNode = (ItemKey, { oldIndex, newIndex }) => {
    let finalData = Score;

    const loop = (NodeArray, ItemKey, Parent) => {
      let initialArr = NodeArray;
      let starting_position = 1;
      if (initialArr) {
        starting_position = parseInt(initialArr[0].position);
      }
      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          if (Parent) {
            Parent.children = arrayMoveImmutable(NodeArray, oldIndex, newIndex);
            Parent.children.forEach((child, child_index) => {
              let sequenceItemKey = child.item_key.split('-');
              if (sequenceItemKey.length === 2) {
                child.item_key = sequenceItemKey[0] + '-' + child_index;
              }
              if (sequenceItemKey.length === 3) {
                child.item_key =
                  sequenceItemKey[0] + '-' + sequenceItemKey[1] + '-' + child_index;
              }
              if (child_index === 0) {
                if (starting_position) {
                  child.position = starting_position;
                }
              } else {
                child.position = parseInt(Parent.children[child_index - 1].position) + 1;
              }
            });
          } else {
            finalData = arrayMoveImmutable(NodeArray, oldIndex, newIndex);
            finalData.forEach((element, index) => {
              element.item_key = index + 1;
              if (index === 0) {
                if (starting_position) {
                  element.position = starting_position;
                }
              } else {
                element.position = parseInt(finalData[index - 1].position) + 1;
              }

              element.children &&
                element.children.length > 0 &&
                element.children.forEach((child, child_index) => {
                  let sequenceItemKey = child.item_key.split('-');
                  if (sequenceItemKey.length === 2) {
                    child.item_key = element.item_key + '-' + child_index;
                  }
                  if (sequenceItemKey.length === 3) {
                    child.item_key =
                      element.item_key + '-' + sequenceItemKey[1] + '-' + child_index;
                  }
                  // child.position = child_index + 1;

                  child.children &&
                    child.children.length > 0 &&
                    child.children.forEach((x, i) => {
                      x.item_key = element.item_key + '-' + child_index + '-' + i;
                      // x.position = i + 1;
                    });
                });
            });
          }
        } else {
          if (x.children && x.children.length > 0) {
            loop(x.children, ItemKey, x);
          }
        }
      });
    };
    loop(finalData, ItemKey);
    SetScore(finalData);
    // GeneratePercentage(SelectedDate, is_checked, finalData);
    forceUpdate();
  };

  //-----------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------- Generate Date  ------------------------------------------------------------//


  const deadlineDate = useCallback(
    (percentage, task_list) => {
      /*Total Node count */
      let totalCount = 0;
      let tttttttt = 0;
      let negetiveCount = 0;
      let defltPrecentage = 100;
      task_list.forEach(function (InnerItem, index) {

        //totalCount = totalCount + (InnerItem.children && InnerItem.children.length > 0 ? InnerItem.children.length : 1);

        //totalCount = totalCount;
        if (InnerItem.children && InnerItem.children.length > 0) {
          totalCount = InnerItem.children.isPositive === true ? totalCount + InnerItem.children.length : totalCount;
          for (let tt = InnerItem.children.length - 1; tt >= 0; tt--) {
            tttttttt = InnerItem.children[tt].isPositive === true ? tttttttt + 1 : tttttttt;
            negetiveCount = InnerItem.children[tt].isPositive === true ? negetiveCount : negetiveCount + 1;

            if (InnerItem.children[tt].isPositive === false) {
              defltPrecentage = parseFloat(defltPrecentage) + -parseFloat(InnerItem.children[tt].weight) * 2;
            }
            //defltPrecentage = defltPrecentage;

          }
        }
        else {
          tttttttt = InnerItem.isPositive === true ? tttttttt + 1 : tttttttt;
          totalCount = InnerItem.isPositive === true ? totalCount + 1 : totalCount;
          negetiveCount = InnerItem.isPositive === true ? negetiveCount : negetiveCount + 1;
          if (InnerItem.isPositive === false) {
            defltPrecentage = parseFloat(defltPrecentage) + -parseFloat(InnerItem.weight) * 1;
          }
          //defltPrecentage = defltPrecentage;
        }

        totalCount = tttttttt;


      });


      //tempTotalWeught = 0;
      const decreaseDate = (NodeArray) => {
        if (NodeArray && NodeArray.length > 0) {
          for (let i = NodeArray.length - 1; i >= 0; i--) {
            let percentageCalculate = defltPrecentage === 100 ? 100 / totalCount : defltPrecentage / totalCount;
            let weightValue = 0;
            //TotalWeight = 0;
            if (NodeArray[i].children && NodeArray[i].children.length > 0) {
              for (let jj = NodeArray[i].children.length - 1; jj >= 0; jj--) {
                if (NodeArray[i].children[jj].isPositive === true) {
                  weightValue = percentageCalculate.toFixed(2);
                  NodeArray[i].children[jj].weight = weightValue;

                  //tempTotalWeught = parseFloat(tempTotalWeught) + parseFloat(weightValue);
                }
                else {
                  //weightValue = -percentageCalculate.toFixed(2);
                  weightValue = Math.sign(NodeArray[i].children[jj].weight) === -1 ? NodeArray[i].children[jj].weight : -NodeArray[i].children[jj].weight;
                  NodeArray[i].children[jj].weight = weightValue;
                  //tempTotalWeught = parseFloat(tempTotalWeught) + parseFloat(weightValue);
                }
                //NodeArray[i].children[jj].weight = NodeArray[i].children[jj].isPositive === true ? percentageCalculate.toFixed(2) : NodeArray[i].children[jj].weight;

              }
            }
            else {

              if (NodeArray[i].isPositive === true) {
                weightValue = percentageCalculate.toFixed(2);
                NodeArray[i].weight = weightValue;

                //tempTotalWeught = parseFloat(tempTotalWeught) + parseFloat(weightValue);
              }
              else {
                //weightValue = -percentageCalculate.toFixed(2);
                weightValue = Math.sign(NodeArray[i].weight) === -1 ? NodeArray[i].weight : -NodeArray[i].weight;
                NodeArray[i].weight = weightValue;
                //tempTotalWeught = parseFloat(tempTotalWeught) + parseFloat(weightValue);
              }

              //weightValue = NodeArray[i].isPositive === true ? percentageCalculate.toFixed(2) : -percentageCalculate.toFixed(2);

              // NodeArray[i].weight = weightValue;
              //// tempTotalWeught = parseFloat(tempTotalWeught) + parseFloat(weightValue);
            }
            ////tempTotalWeught = 100 - tempTotalWeught;
            //setTotalWeight(tempTotalWeught.toFixed(2));
            setTotalWeight(0);
          }
        }
      };
      decreaseDate(task_list);
      SetScore(task_list);
    },
    [SetScore]
  );

  const GeneratePercentage = useCallback(
    (percentage, type) => {
      let task_list;
      task_list = Score;
      // let increase_rate = 0;
      //deadlineDate(percentage, task_list);
      SetScore(task_list);
      deadlineDate(percentage, task_list);
      forceUpdate();
    },

    [Score, SetScore, deadlineDate, forceUpdate]
  );

  //-----------------------------------------------------------------------------------------------------------------------------//

  //-------------------------------------------- UseEffect (Generate Date)  -----------------------------------------------------//

  // useEffect(() => {
  //   GeneratePercentage(SelectedDate, is_checked);
  // }, [GeneratePercentage, SelectedDate, is_checked]);

  const { t } = useTranslation();
  //-----------------------------------------------------------------------------------------------------------------------------//

  return (
    <div>
      {/* Schedule Header(Deadline/View Drawer) */}

      <ScoreHeader
        AddNode={AddNode}
        Score={Score}
        GeneratePercentage={GeneratePercentage}
        scrollToNode={scrollToNode}
        handleFormChange={handleFormChange}
        openViewDrawer={openViewDrawer}
      />

      {/* ------------------------------------ */}

      <div id='score_list' className='content score_list' style={{ padding: 0 }}>
        <table width={'1000px'} className='box-RightScheduleList scoreLibrary'>
          <tr>
            <th width={'20px'} className='header'>
              &nbsp;
            </th>
            <th width={'130px'} className='header' align='center'>
              Focus
            </th>
            <th width={'90px'} className='header' align='center'>
              Feature
            </th>
            <th width={'460x'} className='header'>
              Explanation
            </th>
            <th width={'60px'} className='header' align='center'>
              Min
            </th>
            <th width={'60px'} className='header' align='center'>
              Max
            </th>
            <th width={'60px'} className='header' align='center'>
              +/-
            </th>
            <th width={'60px'} className='header' align='center'>
              Weight
            </th>
            <th width={'60px'} className='actionTD header'>
              &nbsp;
            </th>
          </tr>
        </table>
        <ScoreContent
          AddNode={AddNode}
          scrollToNode={scrollToNode}
          INITIAL_HEIGHT={INITIAL_HEIGHT}
          form={form}
          resetPosition={resetPosition}
          RemoveNode={RemoveNode}
          ChangeNode={ChangeNode}
          editable={true}
          saveType={'score'}
          isFromLibrary={isFromLibrary}
          SetScore={SetScore}
          Score={Score}
          GeneratePercentage={GeneratePercentage}
          handleFormChange={handleFormChange}
          ExchangeNode={ExchangeNode}
          checkParent={parentNode}
          isPositive={isPositive}
        />

      </div>

      {Score.length > 0 ? (
        <table width={'1000px'} className='box-FootScoreBox'>
          <tr>
            <th width={'570px'} >&nbsp;</th>
            <td width={'120px'} class="label" align='right'>Total Weight:</td>
            <td width={'60px'} class="data">{totalWeight}</td>
            <th width={'60px'} >&nbsp;</th>
          </tr>
          <tr>
            <th >&nbsp;</th>
            <td class="label" align='right'>Diff Weight:</td>
            <td class="data">{(parseFloat(100) - parseFloat(totalWeight)).toFixed(2)}</td>
            <th >&nbsp;</th>
          </tr>
        </table>
      ) : null}

      {/* Add Sibling Item(Only Visible when scroll appears) */}

      {document.getElementById('score_list')?.scrollHeight >
        document.getElementById('score_list')?.offsetHeight ? (
        <Tooltip title={`${t('DealDesk.Sibling_Item')}`}>
          <Button
            className={`float-button-addNode ${isFromLibrary ? 'libraryBtn' : ''}`}
            key='2'
            shape='round'
            size='medium'
            onClick={() => addNodeByIndex()}
            icon={<img className='menuicon' alt='' src={ADD} />}
          />
        </Tooltip>
      ) : null}

      {/* -------------------------------------------------- */}
    </div>
  );
};

export default ScoreComponent;
