import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import {
  ageDifferenceInDays,
  //convertTextToID
} from '../../utils/GlobalFunction';

import { reset, clear } from '../../utils/ResetClearButton';


export default class LeadsDealsStore {
  list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;

  dropdown_users_list = null;
  dropdown_people_list = null;
  dropdown_global_org = null;
  dropdown_leadstatus_list = null;
  search_payload = {};
  child_lead_status_list = null;
  dropdown_leads_users_list = null;
  product_list = null;

  filter_people_list = null;
  filter_org_list = null;

  gridLoading = false; // To show loading in grid
  initialValues = {
    phone: [{ id: null, contact_for: 'Primary' }],
    email: [{ id: null, contact_for: 'Primary' }],
  };

  selectedGridFilter = null;

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  // Setup grid and set column size to autosize
  setupGrid = (params, searchPage) => {
    this.agGrid = params;
    const { api } = params;
    let columnConfig = localStorage.getItem('leads_deals_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
    var datasource = this.createDatasource(GridConfig.options);
    api.setServerSideDatasource(datasource);
    if (!searchPage) {
      let param = this.getFilter(params);
      if (param && param.request) {
        this.agGrid.api.setFilterModel(param.request.filter);
        this.agGrid.api.setSortModel(param.request.sort);
      }
    }
  };
  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_deal_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.LEADDEALS) {
        params = { request: temp.LEADDEALS };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          LEADDEALS: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          LEADDEALS: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  getList = (payload = {}) => {
    return Axios.post(`/leadstransactions/list/deals`, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          if (item.email && item.email.length > 0) {
            item.email.forEach((itememail, indexemail) => {
              item.emailname = itememail.contact_value;
            });
          } else {
            item.emailname = '';
          }
          item.age = item.created_at ? ageDifferenceInDays(new Date(item.created_at)) : 1;
          item.idle = item.updated_at
            ? ageDifferenceInDays(new Date(item.updated_at))
            : 1;
          // item.email_name = 'a'; // item.email && item.email[0] && item.email[0].contact_value;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };

  getListSearch = (payload = {}) => {
    this.gridLoading = true;
    return Axios.post(`/leadstransactions/list/deals`, payload).then(({ data }) => {
      this.gridLoading = false;
      if (data.data.length) {
        data.data.forEach((item, index) => {
          if (item.email && item.email.length > 0) {
            item.email.forEach((itememail, indexemail) => {
              item.emailname = itememail.contact_value;
            });
          } else {
            item.emailname = '';
          }
          item.age = item.created_at ? ageDifferenceInDays(new Date(item.created_at)) : 1;
          item.idle = item.updated_at ? ageDifferenceInDays(new Date(item.updated_at)) : 1;
          // item.email_name = 'a'; // item.email && item.email[0] && item.email[0].contact_value;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      if(this.list_data?.length === 0){
        this.agGrid.api.showNoRowsOverlay();
      }
      return data;
    });
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'leads_deals_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };
  onGridChangedSearch = (params) => {
    localStorage.setItem(
      'search_deal_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('leads_deals_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }

        //var filter_data = params.request.filterModel;
        // if (filter_data['lead_owner_name.full_name']) {
        //   filter_data['lead_owner_name.full_name'].values = convertTextToID(
        //     filter_data['lead_owner_name.full_name'],
        //     this.dropdown_leads_users_list,
        //     'name',
        //     'id'
        //   );
        // }
        // if (filter_data['lead_holder_name.full_name']) {
        //   filter_data['lead_holder_name.full_name'].values = convertTextToID(
        //     filter_data['lead_holder_name.full_name'],
        //     this.dropdown_leads_users_list,
        //     'name',
        //     'id'
        //   );
        // }
        // if (filter_data['people.full_name']) {
        //   filter_data['people.full_name'].values = convertTextToID(
        //     filter_data['people.full_name'],
        //     this.dropdown_people_list,
        //     'people',
        //     'id'
        //   );
        // }
        // if (filter_data['organisation_name.organisation_name']) {
        //   filter_data['organisation_name.organisation_name'].values = convertTextToID(
        //     filter_data['organisation_name.organisation_name'],
        //     this.dropdown_global_org,
        //     'organisation_name',
        //     'id'
        //   );
        // }
        // if (filter_data['lead_status_id.status_name']) {
        //   filter_data['lead_status_id.status_name'].values = convertTextToID(
        //     filter_data['lead_status_id.status_name'],
        //     this.child_lead_status_list,
        //     'status_name',
        //     'id'
        //   );
        // }

        var payload = {
          waste: this.waste,
          filter_data: params.request.filterModel,
          // filter_data: this.search_payload?.hasOwnProperty('search')
          //   ? {}
          //   : params.request.filterModel,
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };
        // let merged = { ...payload, ...this.search_payload };
        let merged = { ...payload};
        this.setFilter(params.api);
        this.getList(merged).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          let columnConfig = localStorage.getItem('leads_deals_grid');
          if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
              this.agGrid.columnApi.applyColumnState({
                state: JSON.parse(columnConfig),
              });
            }
          } else {
            if (this.agGrid && this.agGrid.columnApi && data.total) {
              this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                if (!['actions'].includes(column.colId)) {
                  allColumnIds.push(column.colId);
                }
              });
              this.agGrid.columnApi.autoSizeColumns(allColumnIds);
            }
          }
        });
      },
    };
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      //if (!['photo_url'].includes(key)) {
      formData.append(
        key,
        data[key] !== undefined && data[key] !== null ? data[key] : ''
      );
      //}
    });
    return formData;
  };

  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_users_list = data.data;

      return data;
    });
  };

  getLeadsUsersList = () => {
    return Axios.get(`leads/user/dropdown/list`).then(({ data }) => {
      this.dropdown_leads_users_list = data.data;

      return data;
    });
  };
  getPeopleList = () => {
    return Axios.get(`global/people/dropdown/list`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => { });
      }
      this.dropdown_people_list = data.data;

      return data;
    });
  };

  filterGetPeopleList = () => {
    return Axios.get(`global/people/dropdown/list`).then(({ data }) => {
      let newData = [];
      if (data.data.length) {
        data.data.forEach((item, index) => {
          newData[item.id] = item.people;
        });
      }
      this.filter_people_list = newData;

      return newData;
    });
  };

  getProductList = () => {
    return Axios.post(`products-names`).then(({ data }) => {
      this.product_list = data.data;

      return data;
    });
  };

  // call api to get all parent organisations list
  getMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.organisation_name = item.organisation_name + ' (' + item.id + ')';
        });
      }
      this.dropdown_global_org = data.data;
      return data;
    });
  };

  // call api to get all parent organisations list
  filterGetMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      let newData = [];
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.organisation_name = item.organisation_name + ' (' + item.id + ')';
          newData[item.id] = item.organisation_name;
        });
      }
      this.filter_org_list = newData;
      return newData;
    });
  };

  getLeadStatusList = () => {
    return Axios.get(`leads-status/status/list`).then(({ data }) => {
      this.dropdown_leadstatus_list = data.data;

      return data;
    });
  };
  getChildStatusList = (parentstatus_type) => {
    return Axios.get(`leads-status/status/list`).then(({ data }) => {
      let child_status = [];
      if (data.data && data.data.length > 0) {
        // 2 = opportunity
        // 3 = Deal
        // 1 = Unused
        // 1 = Active
        data.data.forEach((e) => {
          if (![1].includes(e.id)) {
            e.child_leads &&
              e.child_leads.length &&
              e.child_leads.forEach((x) => {
                child_status.push(x);
              });
          }
        });
      }
      data.data = child_status;
      this.child_lead_status_list = child_status;

      return data;
    });
  };

  // reset all the server side filters :: DEFAULT-R
  // handleReset = () => {
  //   localStorage.removeItem('leads_deals_grid');

  //   this.selectedGridFilter = null;
  //   this.agGrid.api.setFilterModel(null);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.api.deselectAll();
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.api.onFilterChanged(null);
  // };

  // reset all the server side filters
  // Reset : Will reset all filters, sorting, saved columns settings.
  handleReset = () => {
    reset(this.agGrid, "leads_deals_grid", this.setSelectedGridFilter);
  }

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          LEADDEALS: { filter: filterdata },
        })
      );

      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  // applyFilterDefault = () => {
  //   this.selectedGridFilter = null;
  //   if (this.agGrid) {
  //     // localStorage.removeItem('leads_deals_grid');
  //     this.agGrid.api.setFilterModel(null);
  //     this.agGrid.api.setSortModel(null);
  //     // this.agGrid.columnApi.resetColumnState();
  //     this.agGrid.api.onFilterChanged(null);
  //   }
  // };

  /** This is called on clear button */
  // Clear : Will clear all applied Filter and sorting, And will reset to default.
  applyFilterDefault = () => {
    clear(this.agGrid)
  };

}

decorate(LeadsDealsStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  dropdown_users_list: observable,
  dropdown_people_list: observable,
  dropdown_global_org: observable,
  dropdown_leadstatus_list: observable,
  search_payload: observable,
  child_lead_status_list: observable,
  dropdown_leads_users_list: observable,
  product_list: observable,
  selectedGridFilter: observable,

  filter_people_list: observable,
  filter_org_list: observable,
  gridLoading: observable,

  setPageSize: action,
  onGridChanged: action,
  getList: action,
  setupGrid: action,
  getUsersList: action,
  getPeopleList: action,
  getMappedOrg: action,
  getLeadStatusList: action,
  getChildStatusList: action,
  handleReset: action,
  getLeadsUsersList: action,
  getProductList: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  filterGetPeopleList: action,
  filterGetMappedOrg: action,
  applyFilterDefault: action,
  getListSearch: action,
  setupGridSearch: action,
  onGridChangedSearch: action,
});