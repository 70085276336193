import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Spin } from 'antd';
import InputComponent from '../../../component/InputComponent';
import { vsmTickertap } from '../../../config/messages';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(({ handleSubmit, onChange, form, id }) => {
  const { t } = useTranslation()
  const { TICKERTAPBROADCAST } = useStore();
  const [fetchUsergroup, setFetchUsergroup] = useState(true);

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      className='innerForm'
      onFinish={handleSubmit}
      onChange={onChange}
      id={id}
    >
      <Row gutter={15}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <InputComponent
            name='ticker_text'
            label={`${t('TickerTapBroadcast.Ticker')} ${t('TickerTapBroadcast.Text')}:`}
            required
            placeholder=''
            type='text'
            tooltip={`${t('TickerTapBroadcast.Ticker_Tooltip')}.`}
            rules={vsmTickertap.validation.ticker_text}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          <InputComponent
            name='ticker_url'
            label={`${t('TickerTapBroadcast.Ticker')} ${t('Common_Data.URL')}`}
            required
            placeholder='https://'
            type='text'
            tooltip={`${t('TickerTapBroadcast.Ticker_Url_Tooltip')}.`}
            rules={vsmTickertap.validation.ticker_url}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
          <InputComponent
            name='user_groups'
            label={`${t('Common_Data.User')} ${t('Common_Data.Groups')}`}
            required
            type='select'
            tooltip={`${t('TickerTapBroadcast.User_Group_Tooltip')}.`}
            mode='multiple'
            showArrow
            showSearch={false}
            onChange={onChange}
            options={{
              values:
                TICKERTAPBROADCAST.usergroup_list ||
                (TICKERTAPBROADCAST.editValues &&
                  TICKERTAPBROADCAST.editValues.user_groups),
              value_key: 'id',
              text_key: 'group_name'
              // rejected_keys: TICKERTAPBROADCAST.tickersettingValue && [
              //   TICKERTAPBROADCAST.tickersettingValue.userGroups,
              // ],
            }}
            onFocus={() =>
              fetchUsergroup &&
              TICKERTAPBROADCAST.getUsergroupList().then(() => setFetchUsergroup(false))
            }
            notFoundContent={fetchUsergroup ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            rules={vsmTickertap.validation.userGroups}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
          <InputComponent
            name='description'
            label={`${t('Common_Data.Description')} :`}
            required
            placeholder=''
            type='textarea'
            tooltip={`${t('TickerTapBroadcast.Description_Tooltip')}.`}
            maxLength={100}
            autoSize={{ minRows: 2, maxRows: 3 }}
            rules={vsmTickertap.validation.description}
          />
        </Col>
      </Row>
    </Form>
  );
});

export default FormComponent;
