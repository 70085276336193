import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon } from '../../../../config/messages';
import useStore from '../../../../store';

const PreviewCashReportTableElement = observer((props) => {
  const { SavedReportStore } = useStore();
  if (props.rowData && Array.isArray(props.rowData)) {
    return (
      <div className='' style={{ height: '70vh', marginBottom: 24 }}>
        <div className='ag-theme-alpine grid_wrapper'>
          <AgGridReact
            rowData={props.rowData}
            modules={AllModules}
            columnDefs={props.gridOptions.columnDefs}
            defaultColDef={{ ...LocalGridConfig.defaultColDef }}
            columnTypes={LocalGridConfig.columnTypes}
            overlayNoRowsTemplate={vsmCommon.noRecord}
            onGridReady={props.onGridReady}
            gridOptions={{ ...LocalGridConfig.options, pagination: false }}
            onColumnResized={SavedReportStore.onChildnGridChanged}
            onColumnMoved={SavedReportStore.onChildnGridChanged}
            onColumnPinned={SavedReportStore.onChildnGridChanged}
            onSortChanged={SavedReportStore.onChildnGridChanged}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
});

export default PreviewCashReportTableElement;
