import { Button, Card } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalOperator from './component/ConditionalOperator';
import ConditionalOption from './component/ConditionalOption';
import DeleteComponent from './component/DeleteComponent';
import FormulaComponent from './component/FormulaComponent';
import LogicalBlock from './component/LogicalBlock';
import MainOption from './component/MainOption';
import MinMaxBlock from './component/MinMaxBlock';

const AlgorithmBuilder = (props) => {
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(null);
  let title = null;
  let selectedComponent = null;

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => setDeleteModal(null);

  // Open confirmation alert before deleting existing transaction field
  const openDeleteModal = (props) => {
    setDeleteModal(<DeleteComponent {...props} close={closeDeleteModal} />);
  };

  if (
    props.formula.block &&
    (props.formula.block === 'If-Condition' ||
      props.formula.block === 'If-True' ||
      props.formula.block === 'If-False')
  ) {
    title = <h3>{props.formula.block}</h3>;
  }
  if (Object.keys(props.formula).length > 0) {
    if (props.formula.component) {
      switch (props.formula.component) {
        case 'ELSE IF':
        case 'IF':
          selectedComponent = (
            <Card>
              {title}
              <MainOption
                main={props.main}
                formula={props.formula}
                setFormula={props.setFormula}
              />
              {props.formula.child &&
                props.formula.child.map((item, index) => {
                  return (
                    <AlgorithmBuilder
                      key={index}
                      main={props.main}
                      formula={item}
                      setFormula={props.setFormula}
                    />
                  );
                })}
            </Card>
          );
          break;
        case 'AND':
        case 'OR':
          selectedComponent = (
            <Card>
              {title}
              <div className='d-flex justify-space-between'>
                <ConditionalOption
                  main={props.main}
                  formula={props.formula}
                  setFormula={props.setFormula}
                />
                {props.removeBlock && (
                  <Button
                    onClick={() => {
                      openDeleteModal(props);
                    }}
                  >
                    {t('DealBuilder.Delete_Block')}
                  </Button>
                )}
              </div>
              <LogicalBlock
                main={props.main}
                formula={props.formula}
                setFormula={props.setFormula}
              />
            </Card>
          );
          break;
        case 'Value':
          selectedComponent = (
            <Card>
              {title}
              <MainOption
                main={props.main}
                formula={props.formula}
                setFormula={props.setFormula}
              />
              <Card>
                <FormulaComponent
                  main={props.main}
                  formula={props.formula.value}
                  setFormula={props.setFormula}
                />
              </Card>
            </Card>
          );
          break;
        case 'Formula':
          selectedComponent = (
            <Card>
              {title}
              <div className='d-flex justify-space-between'>
                <ConditionalOption
                  main={props.main}
                  formula={props.formula}
                  setFormula={props.setFormula}
                />
                {props.removeBlock && (
                  <Button
                    onClick={() => {
                      openDeleteModal(props);
                    }}
                  >
                    {t('DealBuilder.Delete_Block')}
                  </Button>
                )}
              </div>
              <Card>
                <FormulaComponent
                  main={props.main}
                  formula={props.formula.operand1}
                  setFormula={props.setFormula}
                />
                <ConditionalOperator
                  main={props.main}
                  formula={props.formula.operator}
                  setFormula={props.setFormula}
                />
                <FormulaComponent
                  main={props.main}
                  formula={props.formula.operand2}
                  setFormula={props.setFormula}
                />
              </Card>
            </Card>
          );
          break;
        case 'Is Blank':
        case 'Not':
          selectedComponent = (
            <Card>
              {title}
              <div className='d-flex justify-space-between'>
                <ConditionalOption
                  main={props.main}
                  formula={props.formula}
                  setFormula={props.setFormula}
                />
                {props.removeBlock && (
                  <Button
                    onClick={() => {
                      openDeleteModal(props);
                    }}
                  >
                    {t('DealBuilder.Delete_Block')}
                  </Button>
                )}
              </div>
              <Card>
                <FormulaComponent
                  main={props.main}
                  formula={props.formula.operand1}
                  setFormula={props.setFormula}
                />
              </Card>
            </Card>
          );
          break;
        case 'Maximum':
        case 'Minimum':
          selectedComponent = (
            <Card>
              {title}
              {props.formula &&
                (props.formula.block === 'If-Condition' ||
                  props.formula.block === 'Logical-Block') ? (
                <div className='d-flex justify-space-between'>
                  <ConditionalOption
                    main={props.main}
                    formula={props.formula}
                    setFormula={props.setFormula}
                  />
                  {props.removeBlock && (
                    <Button
                      onClick={() => {
                        openDeleteModal(props);
                      }}
                    >
                      {t('DealBuilder.Delete_Block')}
                    </Button>
                  )}
                </div>
              ) : (
                <MainOption
                  main={props.main}
                  formula={props.formula}
                  setFormula={props.setFormula}
                />
              )}
              <Card>
                <MinMaxBlock
                  main={props.main}
                  formula={props.formula}
                  setFormula={props.setFormula}
                />
                {props.formula &&
                  (props.formula.block === 'If-Condition' ||
                    props.formula.block === 'Logical-Block') ? (
                  <>
                    <ConditionalOperator
                      main={props.main}
                      formula={props.formula.operator}
                      setFormula={props.setFormula}
                    />
                    <FormulaComponent
                      main={props.main}
                      formula={props.formula.operand2}
                      setFormula={props.setFormula}
                    />
                  </>
                ) : null}
              </Card>
            </Card>
          );
          break;
        default:
          selectedComponent = (
            <Card>
              {title}
              <MainOption
                main={props.main}
                formula={props.formula}
                setFormula={props.setFormula}
              />
            </Card>
          );
      }
    } else {
      if (props.formula.block) {
        selectedComponent = (
          <Card>
            {title}
            {props.formula.block === 'If-Condition' ||
              props.formula.block === 'Logical-Block' ? (
              <div className='d-flex justify-space-between'>
                <ConditionalOption
                  main={props.main}
                  formula={props.formula}
                  setFormula={props.setFormula}
                />
                {props.removeBlock && (
                  <Button
                    onClick={() => {
                      openDeleteModal(props);
                    }}
                  >
                    {t('DealBuilder.Delete_Block')}
                  </Button>
                )}
              </div>
            ) : (
              <MainOption
                main={props.main}
                formula={props.formula}
                setFormula={props.setFormula}
              />
            )}
          </Card>
        );
      } else {
        selectedComponent = (
          <Card>
            <MainOption
              main={props.main}
              formula={props.formula}
              setFormula={props.setFormula}
            />
          </Card>
        );
      }
    }
  } else {
    selectedComponent = (
      <Card>
        <MainOption
          main={props.main}
          formula={props.formula}
          setFormula={props.setFormula}
        />
      </Card>
    );
  }

  return (
    <>
      {selectedComponent}
      {deleteModal}
    </>
  );
};

export default AlgorithmBuilder;
