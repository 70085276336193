import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { vsmOrganisations } from '../../../../config/messages';
import useStore from '../../../../store';
import {
  Form,
  Row,
  Col,
  Spin,
  Upload,
  message,
  Divider,
  Select,
  Input,
  Button,
  Tooltip,
} from 'antd';
import InputComponent from '../../../../component/InputComponent';
import PhoneElement from '../elements/PhoneElement';
import AddressElementNew from '../elements/AddressElementNew';
import { PlusOutlined, PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import EmailElement from '../elements/EmailElement';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(
  ({
    handleSubmit,
    onChange,
    form,
    id,
    fileList,
    updateFileList,
    setImgchanged,
    openAddModal,
    openEditPeopleDrawer,
    isFromPeople = false,
  }) => {
    const { ORGANISATION, ORGSTATUSSTORE, ORGBANKACCOUNTSTORE, AUTH, PEOPLE } =
      useStore();
    const [imgDisabled, setImgDisabled] = useState(false);
    const [fetchCountry, setFetchCountry] = useState(true);
    const [fetchStatus, setFetchStatus] = useState(true);
    const [fetctNace, setFetchNace] = useState(true);
    const [fetchIndustries, setFetchIndustries] = useState(true);
    const [fetchCurrency, setFetchCurrency] = useState(true);
    const [fetchMappedOrg, setFetchMappedOrg] = useState(true);
    const [fetchOrgRole, setFetchOrgRole] = useState(true);
    const [categoryId, setCategoryId] = useState(null);

    const [searchPplKey, setSearchPplKey] = useState();
    const [showPeopleEditBtn, setShowPeopleEditBtn] = useState(false);
    const { t } = useTranslation()
    const documentList = [
      // {
      //   id: 1,
      //   name: 'document_name_1.pdf',
      // },
    ];

    const onPreview = async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    };

    const eventProps = {
      onChange: ({ fileList: newFileList }) => {
        updateFileList(newFileList);
      },
      fileList,
      beforeUpload: (file) => {
        let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        let isLt1M = file.size / 1024 / 1024 < 1;

        if (!isJpgOrPng) {
          message.error(`${t('OrganisationManagement.Upload_Valid_Image')}.`);
          setImgDisabled(true);
          return true;
        } else if (!isLt1M) {
          message.error(`${t('OrganisationManagement.File_Size')} 500${t('OrganisationManagement.Px')} x 500${t('OrganisationManagement.Px')} ${t('OrganisationManagement.And')} 1${t('OrganisationManagement.Mb')}`);
          setImgDisabled(true);
          return true;
        } else {
          setImgchanged(true);
          updateFileList([file]);
          setImgDisabled(false);
          return false;
        }
      },
    };

    // Handle on remove image
    const onRemoveImage = () => {
      setImgchanged(true);
      form.setFields([{ name: 'logo_url', errors: [] }]);
      updateFileList([]);
      setImgDisabled(false);
    };

    const uploadButton = (
      <div>
        <PlusOutlined />
      </div>
    );

    const onFinish = (data) => {
      handleSubmit(data);
    };

    const handleNaceChange = (e = null) => {
      setCategoryId(e);
      form.resetFields(['nace_section_id']);
      onChange();
    };

    const handleSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchPplKey(val);
        PEOPLE.getAllPeople({ search_for: val, mapping_type: 2 });
      };

      return debounce(loadOptions, 500);
    }, [PEOPLE]);

    useEffect(() => {
      if (ORGANISATION.organisationValues && fetctNace) {
        setCategoryId(ORGANISATION.organisationValues.global_nace_sections?.parent_id);
      }
      return null;
    }, [ORGANISATION, ORGANISATION.organisationValues, fetctNace]);

    return (
      <Form
        form={form}
        id={id}
        onFinish={onFinish}
        onChange={onChange}
        layout='vertical'
        initialValues={ORGANISATION.initialValues}
        className='innerForm'
      >
        <Row gutter={15}>
          <Col span={7}>
            <InputComponent
              required
              label={`${t('Common_Data.Organisation')} ${t('Common_Data.Name')}`}
              name='organisation_name'
              placeholder={`${t('Common_Data.Organisation')} ${t('Common_Data.Name')}`}
              onChange={onChange}
              tooltip={`${t('OrganisationManagement.Registered_Name_Tooltip')}`}
              rules={vsmOrganisations.validation.organisation_name}
              maxLength={100}
            />
          </Col>
          <Col span={7}>
            <InputComponent
              label={`${t('OrganisationManagement.Trade')} ${t('Common_Data.Name')}`}
              name='trade_name'
              placeholder={`${t('OrganisationManagement.Trade')} ${t('Common_Data.Name')}`}
              onChange={onChange}
              tooltip={`${t('OrganisationManagement.Organisation_Trade_Name')} (${t('OrganisationManagement.Organisation_Trade_Name_Tooltip')})`}
              rules={vsmOrganisations.validation.trade_name}
              maxLength={100}
            />
          </Col>
          <Col span={6}>
            <InputComponent
              type='select'
              allowClear
              showSearch
              showArrow
              label={`${t('OrganisationManagement.Parent')} ${t('Common_Data.Organisations')}`}
              name='parent_organisation_id'
              placeholder={`${t('OrganisationManagement.Parent')} ${t('Common_Data.Organisation')}`}
              onChange={onChange}
              options={{
                values: ORGANISATION.dropdown_global_org,
                value_key: 'id',
                text_key: 'organisation_name',
              }}
              tooltip={`${t('OrganisationManagement.Enter_Parent_Organisation')}(${t('OrganisationManagement.Multi_Select_DDD')})`}
              onFocus={() =>
                fetchMappedOrg &&
                ORGANISATION.getMappedOrg().then(() => setFetchMappedOrg(false))
              }
              notFoundContent={
                fetchMappedOrg ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
              }
            />
          </Col>
          <Col span={4}>
            <InputComponent
              type='select'
              allowClear
              showSearch
              showArrow
              label={`${t('Common_Data.Types')}`}
              name='types'
              placeholder={`${t('Common_Data.Types')}`}
              mode='multiple'
              onChange={onChange}
              onFocus={() =>
                fetchOrgRole &&
                ORGANISATION.getOrgRoles().then(() => setFetchOrgRole(false))
              }
              notFoundContent={fetchOrgRole ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              options={{
                values:
                  ORGANISATION.dropdown_orgRole_list ||
                  (ORGANISATION.organisationValues &&
                    ORGANISATION.organisationValues?.role_types_list),
                value_key: 'id',
                text_key: 'role_name',
              }}
              tooltip={`${t('OrganisationManagement.Roles_For_Organisation_Tooltip')} (${t('OrganisationManagement.Multi_Select_DDD')})`}
            />
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={3}>
            <InputComponent
              required
              allowClear
              type='select'
              label={`${t('Common_Data.Currency')}`}
              name='currency_id'
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Currency')}`}
              onChange={onChange}
              rules={vsmOrganisations.validation.org_currency}
              tooltip={`${t('OrganisationManagement.Reporting_Currency_Organisation')}`}
              onFocus={() =>
                fetchCurrency &&
                ORGBANKACCOUNTSTORE.getCurrencyList().then(() => setFetchCurrency(false))
              }
              notFoundContent={fetchCurrency ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              options={{
                values: ORGBANKACCOUNTSTORE.dropdown_currency_list,
                value_key: 'id',
                text_key: 'currency_code',
              }}
            />
          </Col>
          <Col span={3}>
            <InputComponent
              label={`${t('OrganisationManagement.Turn_Over')}`}
              name='turnover'
              placeholder={`${t('Common_Data.Turnover')}`}
              onChange={onChange}
              tooltip={`${t('OrganisationManagement.Annual_Estimated_Income')}`}
              disabled={true}
            />
          </Col>
          <Col span={6}>
            <InputComponent
              required
              allowClear
              showSearch
              showArrow
              type='select'
              //mode='multiple'
              label={
                <span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/credebtclassifications-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('Common_Data.Credebt_Classification')}
                </span>
              }
              name='industry_id'
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Classification')}`}
              onChange={onChange}
              rules={vsmOrganisations.validation.industry}
              tooltip={`${t('OrganisationManagement.Credebt_Classification_Tooltip')}.`}
              onFocus={() =>
                fetchIndustries &&
                ORGANISATION.getIndustries().then(() => setFetchIndustries(false))
              }
              notFoundContent={
                fetchIndustries ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
              }
              options={{
                values:
                  ORGANISATION.industries_list ||
                  (ORGANISATION.organisationValues &&
                    ORGANISATION.organisationValues.global_industries && [
                      {
                        id: ORGANISATION.organisationValues.global_industries.id,
                        name: ORGANISATION.organisationValues.global_industries.name,
                      },
                    ]),
                value_key: 'id',
                text_key: 'name',
              }}
            />
          </Col>
          <Col span={6}>
            <InputComponent
              allowClear
              showSearch
              showArrow
              type='select'
              label={
                <span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/nacecategories-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('OrganisationManagement.NACE')} {t('OrganisationManagement.Category')}
                </span>
              }
              name='nace_category_id'
              placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.NACE')} ${t('OrganisationManagement.Category')} `}
              onChange={handleNaceChange}
              tooltip={`${t('OrganisationManagement.NACE_Category_Tooltip')}`}
              onFocus={() =>
                fetctNace && ORGANISATION.getNaceCode().then(() => setFetchNace(false))
              }
              notFoundContent={fetctNace ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              options={{
                values:
                  ORGANISATION?.naceCode_list ||
                  (ORGANISATION.organisationValues &&
                    ORGANISATION.organisationValues.global_nace_sections && [
                      ORGANISATION.organisationValues.global_nace_sections.nace_category,
                    ]),
                value_key: 'id',
                text_key: 'category_name',
              }}
            />
          </Col>
          <Col span={6}>
            <InputComponent
              allowClear
              showSearch
              showArrow
              type='select'
              label={`${t('OrganisationManagement.NACE')} ${t('OrganisationManagement.Code')}`}
              name='nace_section_id'
              placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.NACE')} ${t('OrganisationManagement.Code')}`}
              onChange={onChange}
              disabled={!categoryId}
              tooltip={`${t('Common_Data.Select')} ${t('OrganisationManagement.The')} ${t('OrganisationManagement.NACE')} ${t('OrganisationManagement.Code')} ${t('Common_Data.For')} ${t('OrganisationManagement.The')} ${t('OrganisationManagement.Selected')} ${t('OrganisationManagement.NACE')} ${t('OrganisationManagement.Category')}`}
              onFocus={() =>
                fetctNace && ORGANISATION.getNaceCode().then(() => setFetchNace(false))
              }
              notFoundContent={fetctNace ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              options={{
                values:
                  (categoryId &&
                    ORGANISATION?.naceCode_list?.find((item) => item.id === categoryId)
                      ?.nacesection) ||
                  (ORGANISATION.organisationValues &&
                    ORGANISATION.organisationValues.global_nace_sections && [
                      ORGANISATION.organisationValues.global_nace_sections,
                    ]),
                value_key: 'id',
                text_key: { key: ['code', ' - ', 'section'] },
              }}
            />
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={15}>
            <Row gutter={15}>
              <Col span={8}>
                <InputComponent
                  allowClear
                  showSearch
                  showArrow
                  type='select'
                  required
                  rules={vsmOrganisations.validation.country}
                  label={`${t('OrganisationManagement.Registration')} ${t('Common_Data.Country')}`}
                  name='registered_country_id'
                  placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Registration')} ${t('Common_Data.Country')}`}
                  onChange={onChange}
                  options={{
                    values:
                      ORGANISATION.dropdown_countries_list ||
                      (ORGANISATION.organisationValues &&
                        ORGANISATION.organisationValues.global_countries && [
                          {
                            id: ORGANISATION.organisationValues.global_countries.id,
                            country_name:
                              ORGANISATION.organisationValues.global_countries
                                .country_name,
                          },
                        ]),
                    value_key: 'id',
                    text_key: 'country_name',
                  }}
                  tooltip={`${t('OrganisationManagement.Country_Tooltip')}`}
                  onFocus={() =>
                    fetchCountry &&
                    ORGANISATION.getAllCountries().then(() => setFetchCountry(false))
                  }
                  notFoundContent={
                    ORGANISATION.fetchCountry ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                  }
                  autoComplete='off'
                />
              </Col>
              <Col span={8}>
                <InputComponent
                  label={`${t('OrganisationManagement.Registration')} ${t('Common_Data.Number')}`}
                  name='registration_number'
                  placeholder={`${t('OrganisationManagement.Registration')} ${t('Common_Data.Number')}`}
                  onChange={onChange}
                  tooltip={`${t('OrganisationManagement.Registration_Number_Tooltip')}`}
                  rules={vsmOrganisations.validation.registration_number}
                  maxLength={100}
                />
              </Col>
              <Col span={8}>
                <InputComponent
                  label={`${t('OrganisationManagement.Sales')} ${t('OrganisationManagement.Tax')}/${t('OrganisationManagement.VAT')} ${t('Common_Data.Number')}`}
                  name='vat_number'
                  placeholder={`${t('OrganisationManagement.Sales')} ${t('OrganisationManagement.Tax')}/${t('OrganisationManagement.VAT')} ${t('Common_Data.Number')}`}
                  onChange={onChange}
                  tooltip={`${t('OrganisationManagement.Organisation_Sales_Tooltip')}`}
                  rules={vsmOrganisations.validation.vat_number}
                  maxLength={100}
                />
              </Col>

              <Col span={8}>
                <InputComponent
                  label={`${t('OrganisationManagement.Tax')} ${t('Common_Data.ID')} ${t('OrganisationManagement.Document')}`}
                  tooltip={`${t('OrganisationManagement.Tax_Documents')}.`}
                  type='labelOnly'
                />
                <div className='suffixIcon'>
                  <Form.Item>
                    <Select
                      label={`${t('OrganisationManagement.Test')}`}
                      placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Document')} ${t('Common_Data.Type')}`}
                      className='selfUpdatingOptions'
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <div>
                            <Form className='innerForm addOption'>
                              <div>
                                <Form.Item>
                                  <Input maxLength={50} />
                                </Form.Item>
                                <Button
                                  type='primary'
                                  size='small'
                                // onClick={addItem}
                                >
                                  <PlusOutlined /> {t('Common_Data.Add')} {t('OrganisationManagement.Item')}
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </>
                      )}
                      disabled={true}
                    >
                      <Select.Option value='Document Type 1'>
                        {t('OrganisationManagement.Document')} {t('Common_Data.Type')} 1
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <PlusCircleOutlined className='extraFromIcon mr-5 cursorPointer' />
                  {/* <EditOutlined className='extraFromIcon' /> */}
                </div>
              </Col>
              <Col span={8}>
                <InputComponent
                  label={`${t('OrganisationManagement.Tax')} ${t('Common_Data.ID')} ${t('Common_Data.Number')}`}
                  placeholder={`${t('OrganisationManagement.Tax')} ${t('Common_Data.ID')} ${t('Common_Data.Number')}`}
                  name='tax_id_number'
                  onChange={onChange}
                  tooltip={`${t('OrganisationManagement.Tax_Identification_Number')}.`}
                  autoComplete='off'
                  // rules={vsmPeople.validation.last_name}
                  maxLength={100}
                  disabled={true}
                />
              </Col>
              <Col span={8}>
                <InputComponent
                  label={`${t('OrganisationManagement.Tax')} ${t('OrganisationManagement.Clearance')} ${t('OrganisationManagement.Access')} ${t('Common_Data.No')}.`}
                  name='revenue_access_number'
                  placeholder={`${t('OrganisationManagement.Tax')} ${t('OrganisationManagement.Clearance')} ${t('OrganisationManagement.Access')} ${t('Common_Data.Number')}`}
                  onChange={onChange}
                  rules={vsmOrganisations.validation.tax_clearence}
                  maxLength={100}
                  tooltip={`${t('OrganisationManagement.Tax_Clearance_Access_Number')} (${t('OrganisationManagement.TCAN')}).`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Form.Item
              label={
                <>
                  {t('OrganisationManagement.Logo')}
                  <small className='ml-5'>
                    {t('OrganisationManagement.Maximum_Size')} : 500{t('OrganisationManagement.Px')} x 500{t('OrganisationManagement.Px')}
                  </small>
                </>
              }
              tooltip={`${t('OrganisationManagement.Organisation_Primary_Logo')} 500 x 500 ${t('OrganisationManagement.Format')}`}
            >
              <Upload
                accept='.png, .jpeg'
                fileList={fileList}
                onRemove={onRemoveImage}
                onPreview={onPreview}
                //className='hideeyeicon'
                listType='picture-card'
                multiple={false}
                showUploadList={true}
                {...eventProps}
                maxCount={1}
                disabled={imgDisabled}
                onChange={onChange}
                className='uploadContract twoRow'
              >
                {fileList.length >= 1 ? null : uploadButton} +
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={5}>
            <InputComponent
              label={`${t('OrganisationManagement.Proof_Of')} ${t('OrganisationManagement.Identity')}`}
              tooltip={`${t('OrganisationManagement.Proof_Identity_Tooltip')}.`}
              type='labelOnly'
            />
            <div className='suffixIcon'>
              <Form.Item>
                <Select
                  label={`${t('OrganisationManagement.Test')}`}
                  placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Proof_Of')} ${t('OrganisationManagement.Identity')}`}
                  className='selfUpdatingOptions'
                  disabled={true}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <div>
                        <Form className='innerForm addOption'>
                          <div>
                            <Form.Item>
                              <Input maxLength={50} />
                            </Form.Item>
                            <Button type='primary' size='small'>
                              <PlusOutlined />{t('Common_Data.Add')} {t('OrganisationManagement.Item')}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </>
                  )}
                >
                  <Select.Option value='Proof of Identity 1'>
                    {t('OrganisationManagement.Proof_Of')} {t('OrganisationManagement.Identity')} 1
                  </Select.Option>
                </Select>
              </Form.Item>
              <PlusCircleOutlined className='extraFromIcon mr-5 cursorPointer' />
              {/* <EditOutlined className='extraFromIcon' /> */}
            </div>
          </Col>
          <Col span={5}>
            <InputComponent
              label={`${t('OrganisationManagement.Proof_Of')} ${t('Common_Data.Address')}`}
              tooltip={`${t('OrganisationManagement.Organisation_Registered_Office_Tooltip')}.`}
              type='labelOnly'
            />
            <div className='suffixIcon'>
              <Form.Item>
                <Select
                  label={`${t('OrganisationManagement.Test')}`}
                  placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Proof_Of')} ${t('Common_Data.Address')}`}
                  className='selfUpdatingOptions'
                  disabled={true}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <div>
                        <Form className='innerForm addOption'>
                          <div>
                            <Form.Item>
                              <Input maxLength={50} />
                            </Form.Item>
                            <Button
                              type='primary'
                              size='small'
                            // onClick={addItem}
                            >
                              <PlusOutlined /> {t('Common_Data.Add')} {t('OrganisationManagement.Item')}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </>
                  )}
                >
                  <Select.Option value='Proof of Address 1'>
                    {t('OrganisationManagement.Proof_Of')} {t('Common_Data.Address')} 1
                  </Select.Option>
                </Select>
              </Form.Item>
              <PlusCircleOutlined className='extraFromIcon mr-5 cursorPointer' />
              {/* <EditOutlined className='extraFromIcon' /> */}
            </div>
          </Col>
          <Col span={4}>
            <InputComponent
              label={`${t('Common_Data.Documents')}`}
              type='labelOnly'
              tooltip={`${t('OrganisationManagement.Documents_Tooltip')}.`}
              className='ml-5'
            />
            <div className='suffixIcon' style={{ paddingTop: '8px' }}>
              {documentList &&
                documentList.slice(0, 6).map((doc, index) => {
                  return (
                    <Tooltip placement='top' title={doc.name}>
                      <img
                        className='extraFromIcon cursorPointer mr-5'
                        alt=''
                        src={AUTH.GetThemedImage('Preview_Report')}
                        style={{ height: '20px' }}
                        key={index}
                      />
                    </Tooltip>
                  );
                })}

              {/* <PlusCircleOutlined className='extraFromIcon ml-10' /> */}
            </div>
          </Col>
          {!isFromPeople &&
            <Col span={5}>
              <InputComponent
                label={`${t('OrganisationManagement.Person_Name')}`}
                type='labelOnly'
                tooltip={`${t('OrganisationManagement.Person_Name_Tooltip')}`}
              />
              <div className='suffixIcon'>
                <InputComponent
                  type='select'
                  allowClear
                  className={
                    form.getFieldValue('full_name') ||
                      ORGANISATION.editValues?.people ||
                      showPeopleEditBtn
                      ? 'addEditInputEle editInputEle'
                      : 'addEditInputEle'
                  }
                  showArrow
                  name='full_name'
                  placeholder={`${t('OrganisationManagement.Person_Name')}`}
                  showSearch={true}
                  onSearch={handleSearch}
                  notFoundContent={
                    PEOPLE.fetching ? (
                      <Spin size='small' />
                    ) : searchPplKey ? (
                      `${t('Common_Data.No_Record_Found')}.`
                    ) : null
                  }
                  onChange={() => {
                    if (form.getFieldValue('full_name')) {
                      setShowPeopleEditBtn(true);
                    } else {
                      setShowPeopleEditBtn(false);
                    }
                    onChange();
                  }}
                  options={{
                    values: PEOPLE.drowpdown_people_list,
                    value_key: 'id',
                    text_key: 'entity_name',
                    // rejected_keys: LEADSSTORE.editValues && [
                    //   LEADSSTORE.editValues.id,
                    // ],
                  }}
                  tooltip={`${t('OrganisationManagement.Person_Name_Tooltip')}`}
                />
                {AUTH.checkPermission(18, 'add') ? (
                  <PlusCircleOutlined className='extraFromIcon' onClick={openAddModal} />
                ) : null}
                {AUTH.checkPermission(18, 'edit') ? (
                  form.getFieldValue('full_name') ||
                    ORGANISATION.editValues?.people ||
                    showPeopleEditBtn ? (
                    <EditOutlined
                      className='extraFromIcon'
                      onClick={openEditPeopleDrawer}
                    />
                  ) : null
                ) : null}
              </div>
            </Col>
          }
          <Col span={isFromPeople ? 10 : 5}>
            <InputComponent
              label={`${t('Common_Data.Website')}`}
              name='website'
              placeholder={`${t('Common_Data.Website')} ${t('Common_Data.URL')}`}
              onChange={onChange}
              tooltip={`${t('OrganisationManagement.Website_Url_Tooltip')}.`}
              // rules={vsmOrganisations.validation.website}
            />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={
                <>
                  {t('Common_Data.Phone')}
                  <small className='ml-5'>
                    ({t('OrganisationManagement.To_Add_Extension')} : +353 xxxx-xxxx, ext. xxxx)
                  </small>
                </>
              }
              className='mb-10'
              tooltip={`${t('Common_Data.Phone')} ${t('Common_Data.Type')} ${t('OrganisationManagement.Associate_Type_Tooltip')} ${t('Common_Data.Email')} (${t('Common_Data.Eg')}.: ${t('OrganisationManagement.Primary_Home_Work')}). ${t('Common_Data.Phone')} ${t('OrganisationManagement.Country_Code_And_Extension')}.`}
            >
              <PhoneElement onChange={onChange} form={form} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={`${t('Common_Data.Email')}`}
              tooltip={`${t('Common_Data.Email')} ${t('Common_Data.Type')} ${t('OrganisationManagement.Associate_Type_Tooltip')} ${t('Common_Data.Email')} (${t('Common_Data.Eg')}.: ${t('OrganisationManagement.Primary_Home_Work')}).`}
              className='mb-10'
            >
              <div className='w-100'>
                <Row>
                  <Col span={24}>
                    <EmailElement onChange={onChange} form={form} />
                  </Col>
                </Row>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={6}>
            <InputComponent
              allowClear
              showSearch
              showArrow
              type='select'
              label={`${t('Common_Data.Status')}`}
              name='status_id'
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Status')}`}
              onChange={onChange}
              tooltip={`${t('OrganisationManagement.Status_Active_Inactive')}.`}
              onFocus={() =>
                fetchStatus &&
                ORGSTATUSSTORE.getOrgStatus().then(() => setFetchStatus(false))
              }
              notFoundContent={fetchStatus ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              options={{
                values: ORGSTATUSSTORE.orgStatusList,
                value_key: 'id',
                text_key: 'status_name',
              }}
            />
          </Col>

          <Col span={12}>
            <InputComponent
              type='textarea'
              label={`${t('Common_Data.Description')}`}
              name='description'
              placeholder={`${t('Common_Data.Organisation')} ${t('Common_Data.Description')}`}
              onChange={onChange}
              rules={vsmOrganisations.validation.description}
              maxLength={32000}
              tooltip={`${t('OrganisationManagement.Description_Tooltip')}.`}
            />
          </Col>
        </Row>
        <Divider className='mt-10 mb-20' />
        <Row gutter={24}>
          <Col span={12}>
            <AddressElementNew onChange={onChange} form={form} />
          </Col>
          <Col span={12}>
            <Row gutter={15}>
              <Col span={12} className='address_list'>
                <div className='addMore'>
                  <PlusCircleOutlined className='mr-5 cursorPointer' />
                </div>
                <InputComponent
                  label={`${t('OrganisationManagement.Relationships')}`}
                  tooltip={`${t('OrganisationManagement.Select_Type_Relationship')} (${t('OrganisationManagement.Created_Settings')})`}
                  type='labelOnly'
                />
                <Form.Item name={'Relationships'}>
                  <Select
                    label={`${t('OrganisationManagement.Test')}`}
                    placeholder={`${t('OrganisationManagement.Relationships')} ${t('Common_Data.Type')}`}
                    className='selfUpdatingOptions'
                    disabled={true}
                  >
                    <Select.Option value='Relationships Type 1'>
                      {t('OrganisationManagement.Relationships')} {t('Common_Data.Type')} 1
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={`${t('OrganisationManagement.Related')} ${t('Common_Data.Items')}`}
                  tooltip={`${t('OrganisationManagement.View_Select')} (${t('OrganisationManagement.View_Select_Tooltip')})`}
                  name={'related_items'}
                >
                  <Select
                    placeholder={`${t('OrganisationManagement.Related')} ${t('Common_Data.Items')}`}
                    className='selfUpdatingOptions'
                    disabled={true}
                  >
                    <Select.Option value='Related Items 1'>{t('OrganisationManagement.Related')} {t('Common_Data.Items')} 1</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
);

export default FormComponent;
