import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store';

import DeleteComponent from '../../DealDesk/RightSideComponent/Structure/component/DeleteComponent';
import { default as ScheduleDeleteComponent } from '../../DealDesk/RightSideComponent/NewSchedule/component/DeleteComponent';
import EditComponent from '../../DealDesk/RightSideComponent/Structure/component/EditComponent';
import StructureLibraryView from '../../DealDesk/RightSideComponent/Structure/component/StructureLibraryView';

import LibrarySearchListing from './LibrarySearchListing';
import TimeTracker from '../../../component/TimeTracker';

const LibrarySearch = observer((props) => {
  const { t } = useTranslation();

  const [saveType, setSaveType] = useState('structure');
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [id, setID] = useState(null);
  const [structureData, setStructureData] = useState(null);
  const [openScheduleLibraryDrawer, setOpenScheduleLibraryDrawer] = useState(false);
  const { SearchPage} = props;


  const {
    DEALDESKSTRUCTURELIBRARY,
    LIBRARYSEARCHSTORE,
  } = useStore();


  const checkSaveType = (data) => {
    if (data?.type === 1) {
      setSaveType('structure');
    } else if (data?.type === 3) {
      setSaveType('screen');
    } else if (data?.type === 2) {
      setSaveType('schedule');
    } else if (data?.type === 4) {
      setSaveType('scrutinise');
    } else if (data?.type === 5) {
      setSaveType('shortfall');
    }
  };
  //-----------------OPEN-CLOSE EDIT DRAWER--------------//
  const openEditDrawer = (data) => {
    checkSaveType(data);
    DEALDESKSTRUCTURELIBRARY.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => setEditDrawer(false);
  //-----------------OPEN-CLOSE VIEW LIBRARY--------------//

  //-----------------OPEN-CLOSE DELETE CONFIRMATION--------------//
  const openDeleteDrawer = (data) => {
    checkSaveType(data);
    setDeleteDrawer(true);
    setID(data.id);
  };

  const closeDeleteDrawer = (reload = false) => {
    setDeleteDrawer(false);
    if (reload) {
      LIBRARYSEARCHSTORE.getList();
    }
  };
  //-----------------OPEN-CLOSE DELETE CONFIRMATION--------------//

  const viewScheduleLibraryDrawer = async (data) => {
    if (data?.type === 1) {
      setSaveType('structure');
    } else if (data?.type === 3) {
      setSaveType('screen');
    } else if (data?.type === 2) {
      setSaveType('schedule');
    } else if (data?.type === 4) {
      setSaveType('scrutinise');
    } else if (data?.type === 5) {
      setSaveType('shortfall');
    }
    setStructureData(data);
    await setOpenScheduleLibraryDrawer(true);
  };

  const closeScheduleLibraryDrawer = () => {
    setStructureData(null);
    setOpenScheduleLibraryDrawer(false);
  };

  const getTitleBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return `${t('DealDesk.Screen')}`;
    } else if (saveType === 'structure') {
      return `${t('Common_Data.Structure')}`;
    } else if (saveType === 'scrutinise') {
      return `${t('Common_Data.Scrutinise')}`;
    } else if (saveType === 'shortfall') {
      return `${t('Common_Data.Shortfall')}`;
    } else if (saveType === 'schedule') {
      return `${t('Common_Data.Schedule')}`;
    }
  };

  return (
    <>

      <LibrarySearchListing
          openEditDrawer={openEditDrawer}
          openDeleteDrawer={openDeleteDrawer}
          CommonFieldTitle={getTitleBasedOnSaveType()}
          viewScheduleLibraryDrawer={viewScheduleLibraryDrawer}
          SearchPage={SearchPage}
        />
        <StructureLibraryView
          visibleScheduleLibraryDrawer={openScheduleLibraryDrawer}
          closeScheduleLibraryDrawer={closeScheduleLibraryDrawer}
          structure={structureData}
          saveType={saveType}
        />
        <EditComponent visible={editDrawer} saveType={saveType} close={closeEditDrawer} />
        {saveType === 'schedule' ? (
          <ScheduleDeleteComponent
            visible={deleteDrawer}
            saveType={saveType}
            id={id}
            key={1}
            close={closeDeleteDrawer}
          />
        ) : (
          <DeleteComponent
            visible={deleteDrawer}
            id={id}
            key={2}
            saveType={saveType}
            close={closeDeleteDrawer}
          />
        )}
      <TimeTracker
        page={"library"}
      />
    </>
  );
});

export default LibrarySearch;
