import {
  DeleteOutlined,
  FileExcelOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import AttachmentModalList from '../page/DealDesk/RightSideComponent/Notes/global/AttachmentModal';
import { removeAttachment } from '../page/FileManager';
import useStore from '../store';

const Attachments = ({
  selectedFiles,
  setSelectedFiles,
  downloadable,
  autoSaveData = () => { },
  saveObj = null,
  isFromEditorFooter = false,
  openExpanModal = false,
}) => {
  const {
    DEALDESKSTORE: { deal_data, downloadFileS3 },
  } = useStore();

  const [visibleModal, setVisibleModal] = useState(false);

  const downloadFile = (filedata) => {
    let payload = {
      org_id: deal_data?.lead_information?.sold_organisation?.id,
      leadid: deal_data.lead_information?.id,
      file: filedata.entity_name,
      fileID: filedata.id,
    };
    downloadFileS3(payload)
      .then((data) => {
        let anchor = document.createElement('a');
        anchor.href = data;
        anchor.download = filedata.entity_name;
        anchor.target = '_blank';
        anchor.click();
      })
      .catch((e) => { })
      .finally(() => { });
  };

  const icons = {
    jpg: (params) => <FileJpgOutlined {...params} />,
    png: (params) => <FileImageOutlined {...params} />,
    csv: (params) => <FileExcelOutlined {...params} />,
    xls: (params) => <FileExcelOutlined {...params} />,
    xlsx: (params) => <FileExcelOutlined {...params} />,
    gif: (params) => <FileGifOutlined {...params} />,
    pdf: (params) => <FilePdfOutlined {...params} />,
    ppt: (params) => <FilePptOutlined {...params} />,
    pptx: (params) => <FilePptOutlined {...params} />,
    txt: (params) => <FileTextOutlined {...params} />,
    doc: (params) => <FileWordOutlined {...params} />,
    docx: (params) => <FileWordOutlined {...params} />,
    zip: (params) => <FileZipOutlined {...params} />,
    rar: (params) => <FileZipOutlined {...params} />,
  };

  const openAttachmentDetailModal = () => {
    setVisibleModal(true);
  };
  const closeAttachmentDetailModal = () => {
    setVisibleModal(false);
  };

  return (
    selectedFiles &&
    selectedFiles.length > 0 && (
      <div
        className={openExpanModal ? 'selectedFilesList expand' : 'selectedFilesList'}
      // style={!downloadable ? { paddingBottom: '50px' } : {}}
      >
        {selectedFiles &&
          selectedFiles.map((files, index) => {
            if (
              files &&
              typeof files === 'string' &&
              files !== '[]' &&
              files.search('#')
            ) {
              let fileExplodedDetails = files.split('|');
              let ext = fileExplodedDetails[2];
              ext = ext.replace('.', '');
              files = {
                id: parseInt(fileExplodedDetails[0]),
                entity_name: fileExplodedDetails[1],
                name: fileExplodedDetails[1],
                attachment_type: ext,
              };
            }

            const iconsProps = {
              className: 'mr-5',
              style: { fontSize: 30 },
              onClick: () => downloadFile(files),
            };
            return downloadable ? (
              <Tooltip title={files.name || files.entity_name}>
                {icons[files.attachment_type] ? (
                  icons[files.attachment_type](iconsProps)
                ) : (
                  <FileOutlined {...iconsProps} />
                )}
              </Tooltip>
            ) : (
              index <= 1 && (
                <>
                  <div
                    className='itemBox'
                    key={index}
                  // style={{
                  //   display: 'flex',
                  //   justifyContent: 'space-between',
                  //   padding: '5px 10px',
                  //   borderRadius: '5px',
                  //   border: '1px solid rgba(0,0,0,0.2)',
                  //   margin: '5px 0px',
                  //   alignItems: 'center',
                  // }}
                  >
                    <span
                      // style={{
                      //   width: '100%',
                      //   textOverflow: 'ellipsis',
                      //   overflow: 'hidden',
                      //   whiteSpace: 'nowrap',
                      // }}
                      className='fileName'
                      title={files.name || files.entity_name}
                    >
                      {files.name || files.entity_name}
                    </span>
                    {setSelectedFiles && (
                      <DeleteOutlined
                        title={files.name || files.entity_name}
                        style={{ fontSize: '20px', color: 'red', marginLeft: '15px' }}
                        className='deleteIcon'
                        onClick={() =>
                          removeAttachment(selectedFiles, setSelectedFiles, index).then(
                            (data) => {
                              if (saveObj) {
                                let newObj = saveObj;
                                newObj.selectedFiles = data;
                                autoSaveData(newObj);
                              }
                            }
                          )
                        }
                      />
                    )}
                  </div>
                </>
              )
            );
          })}
        {isFromEditorFooter && selectedFiles.length > 2 && (
          <Button type='primary' onClick={() => openAttachmentDetailModal()}>
            {selectedFiles.length - 2} more
          </Button>
        )}
        <AttachmentModalList
          visible={visibleModal}
          selectedFiles={selectedFiles}
          autoSaveData={autoSaveData}
          saveObj={saveObj}
          setSelectedFiles={setSelectedFiles}
          removeAttachment={removeAttachment}
          close={closeAttachmentDetailModal}
        />
      </div>
    )
  );
};

export default Attachments;
