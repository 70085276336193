import React, { useState, useEffect } from "react";
import { Row, Col, Popover, Tooltip, Modal, Radio } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import { globalStatus } from "../../../../utils/GlobalFunction";
import copy from "copy-to-clipboard";
import { default as ViewTicketComponent } from "../../../../page/Tickets/component/ViewComponent";
import { default as ViewOrganisationComponent } from "../../../../page/OrganisationManagement/AllOrganisations/component/ViewComponent";
import { default as EditOrganisationComponent } from "../../../../page/OrganisationManagement/AllOrganisations/component/EditComponentNew";
import { default as ViewPeopleComponent } from "../../../../page/PeopleManagement/People/component/ViewComponent";
import { default as ViewSoldComponent } from "../../../../page/LeadListing/component/ViewComponent";
import { default as EditSoldComponent } from "../../../../page/LeadListing/component/EditComponentNew";
import { default as EditPeopleComponent } from "../../../../page/PeopleManagement/People/component/EditComponentNew";
import { vsmCallAudit, vsmNotify } from "../../../../config/messages";
import { useTranslation } from "react-i18next";
import CallAuditModal from "./CallAuditModal";

const OrganisationDetailsComponent = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    TicketsStore,
    ORGANISATION,
    PEOPLE,
    LEADSSTORE,
    DEALDESKSTORE: {
      deal_data,
      changeNextPrevDeal,
      fetchGlobalCurrencyList,
      dealDeskTypeArr,
      getDealDetail,
      setLeftSectionActiveTab,
    },
    TimeTrackerStore,
  } = useStore();

  let history = useHistory();
  /*const [currencyName, setcurrencyName] = useState(
    deal_data?.lead_information?.lead_currency_id?.currency_name
  );*/
  const [getCurrency, setGetCurrency] = useState(null);
  const [viewTicketDrawer, setViewTicketDrawer] = useState(false);
  const [viewOrganisationDrawer, setViewOrganisationDrawer] = useState(false);
  const [viewPeopleDrawer, setViewPeopleDrawer] = useState(false);

  const [viewSoldDrawer, setViewSoldDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const [transactionId, setTransactionId] = useState(null);

  /*start: Used For People Edit*/
  const [editPeopleModal, setEditPeopleModal] = useState(null);
  /*end: Used For People Edit*/

  // State for handle modal opening to initiate the call
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("work");
  const [selectedNumber, setSelectedNumber] = useState("");

  /*start: Used For Edit Drawer zIndex*/
  const [editOpen, setEditOpen] = useState(false);
  const [editOpenPEOPLE, seteditOpenPEOPLE] = useState(false);
  const [editOpenSOLD, seteditOpenSOLD] = useState(false);
  /*end: Used For Edit Drawer zIndex*/

  const setId = (id) => setTransactionId(id);

  // For Deal desk & Org.desk
  // First show lead & Org. email and then people email
  const emailData = (
    <ul className="popover-menu autoWidth">
      <></>
      {deal_data?.lead_information?.email.length > 0 &&
        deal_data?.lead_information?.email.map((item, index) => {
          return (
            <li key={index}>
              <img
                src={AUTH.GetThemedImage("Mail")}
                alt=""
                className="mr-5"
                style={{ width: "16px" }}
              />
              <a
                href={`mailto:${item.contact_value}`}
                onClick={() => handleCallAuditEmail(item.contact_value)}
              >
                {item.contact_value}
              </a>
            </li>
          );
        })}
      {deal_data?.lead_information?.people_email.length > 0 &&
        deal_data?.lead_information?.people_email.map((item, index) => {
          return (
            <li key={index}>
              <img
                src={AUTH.GetThemedImage("Mail")}
                alt=""
                className="mr-5"
                style={{ width: "16px" }}
              />
              <a
                href={`mailto:${item.contact_value}`}
                onClick={() => handleCallAuditEmail(item.contact_value)}
              >
                {item.contact_value}
              </a>
            </li>
          );
        })}
    </ul>
  );

  // For People desk.
  // Show only people email
  const peopleEmailData = (
    <ul className="popover-menu autoWidth">
      {deal_data?.lead_information?.people_email.length > 0 &&
        deal_data?.lead_information?.people_email.map((item, index) => {
          return (
            <li key={index}>
              <img
                src={AUTH.GetThemedImage("Mail")}
                alt=""
                className="mr-5"
                style={{ width: "16px" }}
              />
              <a
                href={`mailto:${item.contact_value}`}
                onClick={() => handleCallAuditEmail(item.contact_value)}
              >
                {item.contact_value}
              </a>
            </li>
          );
        })}
    </ul>
  );

  // For Deal desk & Org.desk
  // First show lead & Org. phone and then people phone
  const phoneData = (
    <ul className="popover-menu autoWidth">
      <>
        {deal_data?.lead_information?.phone?.length > 0 &&
          deal_data?.lead_information?.phone.map((item, index) => {
            return (
              <li key={index}>
                {(item.contact_for === "Mobile" && (
                  <img
                    src={AUTH.GetThemedImage("Mobile")}
                    alt=""
                    className="mr-5"
                    style={{ width: "14px" }}
                  />
                )) ||
                  (item.contact_for === "SMS" && (
                    <img
                      src={AUTH.GetThemedImage("SMS")}
                      alt=""
                      className="mr-5"
                      style={{ width: "14px" }}
                    />
                  )) ||
                  ((item.contact_for !== "SMS" ||
                    item.contact_for !== "Mobile") && (
                    <img
                      src={AUTH.GetThemedImage("Phone")}
                      alt=""
                      className="mr-5"
                      style={{ width: "14px" }}
                    />
                  ))}
                <a
                  href={`tel:${item.contact_value}`}
                  onClick={(e) => {
                    handleCallAudit(
                      primaryPhoneDetail?.contact_value
                        ? primaryPhoneDetail?.contact_value
                        : item.contact_value
                    );
                  }}
                >
                  {item.contact_value}
                </a>
              </li>
            );
          })}

        {deal_data?.lead_information?.people_phone?.length > 0 &&
          deal_data?.lead_information?.people_phone.map((item, index) => {
            return (
              <li key={index}>
                {(item.contact_for === "Mobile" && (
                  <img
                    src={AUTH.GetThemedImage("Mobile")}
                    alt=""
                    className="mr-5"
                    style={{ width: "14px" }}
                  />
                )) ||
                  (item.contact_for === "SMS" && (
                    <img
                      src={AUTH.GetThemedImage("SMS")}
                      alt=""
                      className="mr-5"
                      style={{ width: "14px" }}
                    />
                  )) ||
                  ((item.contact_for !== "SMS" ||
                    item.contact_for !== "Mobile") && (
                    <img
                      src={AUTH.GetThemedImage("Phone")}
                      alt=""
                      className="mr-5"
                      style={{ width: "14px" }}
                    />
                  ))}
                <a
                  // href={`tel:${item.contact_value}`}
                  // onClick={(e) => {
                  //   handleCallAudit(
                  //     primaryPhoneDetail?.contact_value
                  //       ? primaryPhoneDetail?.contact_value
                  //       : item.contact_value
                  //   );
                  // }}
                  onClick={(e) => {
                    handleCallAudit(item.contact_value);
                  }}
                >
                  {item.contact_value}
                </a>
              </li>
            );
          })}
      </>
    </ul>
  );

  // For People desk.
  // Show only people phone
  const peopelPhoneData = (
    <ul className="popover-menu autoWidth">
      {deal_data?.lead_information?.people_phone?.lengtsetIsModalOpenh > 0 &&
        deal_data?.lead_information?.people_phone.map((item, index) => {
          return (
            <li key={index}>
              {(item.contact_for === "Mobile" && (
                <img
                  src={AUTH.GetThemedImage("Mobile")}
                  alt=""
                  className="mr-5"
                  style={{ width: "14px" }}
                />
              )) ||
                (item.contact_for === "SMS" && (
                  <img
                    src={AUTH.GetThemedImage("SMS")}
                    alt=""
                    className="mr-5"
                    style={{ width: "14px" }}
                  />
                )) ||
                ((item.contact_for !== "SMS" ||
                  item.contact_for !== "Mobile") && (
                  <img
                    src={AUTH.GetThemedImage("Phone")}
                    alt=""
                    className="mr-5"
                    style={{ width: "14px" }}
                  />
                ))}

              <a
                // href={`tel:${item.contact_value}`}
                // onClick={(e) => {
                //   handleCallAudit(
                //     primaryPhoneDetail?.contact_value
                //       ? primaryPhoneDetail?.contact_value
                //       : item.contact_value
                //   );
                // }}
                onClick={(e) => {
                  handleCallAudit(item.contact_value);
                }}
              >
                {item.contact_value}
              </a>
            </li>
          );
        })}
    </ul>
  );

  let pageName = null;
  if (history.location.pathname.includes("dealdesk")) {
    pageName = "dealdesk";
  } else if (history.location.pathname.includes("organisation")) {
    pageName = "organisation";
  } else if (history.location.pathname.includes("people")) {
    pageName = "people";
  }
  // let formData;
  const handleCallAudit = (data) => {
    setIsModalOpen(true);
    setSelectedNumber(data);
    // console.log("Number:-", data);
    // formData = {
    //   id: deal_data?.lead_information?.id,
    //   phone: primaryPhoneDetail?.contact_value,
    //   callType: selectedValue,
    // };
    // console.log("Form Data is :=>", formData);
    // LEADSSTORE.callAudit(formdata).then(() => {
    //   vsmNotify.success({
    //     message: vsmCallAudit.added,
    //   });
    // });
  };
  const handleCallAuditEmail = (data) => {
    let formdata = {
      id: deal_data?.lead_information?.id,
      email: data,
    };
    LEADSSTORE.callEmailAudit(formdata).then(() => {
      vsmNotify.success({
        message: vsmCallAudit.emailadded,
      });
    });
  };

  const getNextPrevDeal = (type) => {
    let append_lead_url = "/";
    let sorting_arr = [];

    if (deal_data && deal_data.lead_information) {
      if (
        deal_data.lead_information.lead_status_name &&
        deal_data.lead_information.lead_status_name.id &&
        deal_data.lead_information.lead_status_name.id === 4
        //&& deal_data.lead_information.lead_status_name.parent_leads.id === 1
      ) {
        //UnUsed-Leads
        let temp = JSON.parse(localStorage.getItem("unused_params"));
        append_lead_url = "/unused";
        if (temp.LEADSSTORE.sort) {
          sorting_arr = temp.LEADSSTORE.sort;
        }
      } else if (
        deal_data.lead_information.lead_status_name &&
        deal_data.lead_information.lead_status_name.parent_leads.id &&
        deal_data.lead_information.lead_status_name.parent_leads.id === 2
      ) {
        //DealsLeads
        append_lead_url = "/deals";
        let temp = JSON.parse(localStorage.getItem("params"));
        if (temp.LEADDEALS.sort) {
          sorting_arr = temp.LEADDEALS.sort;
        }
      } else if (
        deal_data.lead_information.lead_status_name &&
        deal_data.lead_information.lead_status_name.parent_leads.id &&
        deal_data.lead_information.lead_status_name.parent_leads.id === 1 &&
        deal_data.lead_information.lead_status_name.id !== 4
      ) {
        //Active Leads
        append_lead_url = "/active";
        let temp = JSON.parse(localStorage.getItem("params"));
        if (temp?.LEADACTIVE?.sort) {
          sorting_arr = temp.LEADACTIVE?.sort;
        }
      }
    }

    let payload = {
      filter_data: {},
      sort_data: sorting_arr,
      per_page: 10000,
      page: 1,
      lead_transaction_id: deal_data?.lead_information?.id,
      direction: type,
    };
    changeNextPrevDeal(append_lead_url, payload).then((data) => {
      history.push(`/dealdesk/${data}`);
      setLeftSectionActiveTab("3");
    });
  };

  let mobileDetail = null;
  let smsDetail = null;
  let primaryPhoneDetail = null;
  let primaryEmailDetail = null;

  if (pageName === "dealdesk" || pageName === "organisation") {
    deal_data?.lead_information?.email &&
    deal_data?.lead_information?.email.length > 0
      ? (primaryEmailDetail =
          deal_data?.lead_information?.email[0].contact_value)
      : deal_data?.lead_information?.people_email &&
        deal_data?.lead_information?.people_email.length > 0
      ? (primaryEmailDetail =
          deal_data?.lead_information?.people_email[0].contact_value)
      : (primaryEmailDetail = null);
  } else if (pageName === "people") {
    deal_data?.lead_information?.people_email &&
    deal_data?.lead_information?.people_email.length > 0
      ? (primaryEmailDetail =
          deal_data?.lead_information?.people_email[0].contact_value)
      : (primaryEmailDetail = null);
  }

  if (pageName === "dealdesk" || pageName === "organisation") {
    deal_data?.lead_information?.phone &&
    deal_data?.lead_information?.phone.length > 0
      ? deal_data?.lead_information?.phone.forEach((element) => {
          if (element.contact_for === "Mobile") {
            mobileDetail = element;
          }
          if (element.contact_for === "SMS") {
            smsDetail = element;
          }
          if (element.contact_for === "Primary") {
            primaryPhoneDetail = element;
          }
        })
      : deal_data?.lead_information?.people_phone &&
        deal_data?.lead_information?.people_phone.length > 0 &&
        deal_data?.lead_information?.people_phone.forEach((element) => {
          if (element.contact_for === "Mobile") {
            mobileDetail = element;
          }
          if (element.contact_for === "SMS") {
            smsDetail = element;
          }
          if (element.contact_for === "Primary") {
            primaryPhoneDetail = element;
          }
        });
  } else if (pageName === "people") {
    deal_data?.lead_information?.people_phone &&
      deal_data?.lead_information?.people_phone.forEach((element) => {
        if (element.contact_for === "Mobile") {
          mobileDetail = element;
        }
        if (element.contact_for === "SMS") {
          smsDetail = element;
        }
        if (element.contact_for === "Primary") {
          primaryPhoneDetail = element;
        }
      });
  }

  // View Ticket Drawer
  const openTicketViewDrawer = () => {
    TicketsStore.setViewValues(deal_data?.lead_information?.ticket_data);
    setViewTicketDrawer(true);
  };
  const closeTicketViewDrawer = () => {
    setViewTicketDrawer(false);
  };

  // View Sold Drawer
  const openSoldViewDrawer = () => {
    LEADSSTORE.setViewValues(deal_data?.lead_information);
    setViewSoldDrawer(true);
  };
  const closeSoldViewDrawer = () => {
    setViewSoldDrawer(false);
  };

  //Edit drawer
  const openEditDrawer = (data, isEditOpen = false) => {
    seteditOpenSOLD(isEditOpen);
    LEADSSTORE.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
    seteditOpenSOLD(false);
    getDealDetail(deal_data?.lead_information?.id);
  };

  // View Organisation Drawer
  const handleViewORGANISATIONDetailCall = debounce((data) => {
    ORGANISATION.viewPeopleLoading = true;
    ORGANISATION.getViewOrgDetail(data?.id)
      .then((data) => {
        if (data?.cash_transactions && data?.cash_transactions.length) {
          data?.cash_transactions.forEach((item) => {
            item.status_name = globalStatus(
              "cash_transaction_status",
              "key",
              item.status
            );
          });
        }
        ORGANISATION.viewOrgValues = data;
        PEOPLE.list_related_ORG = data?.related_organisations;
        PEOPLE.list_related_Ppl = data?.related_peoples;
        PEOPLE.list_trade_transaction = data?.trade_transactions;
        PEOPLE.list_cash_transaction = data?.cash_transactions;
        PEOPLE.list_banks = data?.banks;
        ORGANISATION.viewPeopleLoading = false;
      })
      .catch((ORGANISATION.viewPeopleLoading = false));
  }, 500);

  const openOrganisationViewDrawer = () => {
    if (deal_data?.lead_information?.sold_organisation?.id) {
      ORGANISATION.viewPeopleLoading = true;
      ORGANISATION.viewOrgValues =
        deal_data?.lead_information?.sold_organisation;
      setViewOrganisationDrawer(true);
      handleViewORGANISATIONDetailCall({
        id: deal_data?.lead_information?.sold_organisation?.id,
      });
    }
  };

  const closeOrganisationViewDrawer = () => {
    setViewOrganisationDrawer(false);
  };
  // Open form for edit existing Organisation and set values to form
  const openEditModal = (data, isEditOpen = false) => {
    setEditOpen(isEditOpen);
    ORGANISATION.setOrganisationValues(null);
    setId(data?.id);
    ORGANISATION.setEditValues(data);
    setEditModal(true);
  };

  // Close form of edit Organisation
  const closeEditModal = () => {
    // ORGANISATION.organisationValues = null;
    // ORGANISATION.setEditValues(null);
    setEditModal(false);
    setEditOpen(false);
    getDealDetail(deal_data?.lead_information?.id);
  };

  /*START: PEOPLE EDIT FUNCTIONS*/
  //open edit drawer
  const openPeopleEditDrawer = () => {
    var payload = { people_id: deal_data?.lead_information?.people?.id };
    PEOPLE.editPeopleLoading = true;
    seteditOpenPEOPLE(true);
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        setEditPeopleModal(true);
        PEOPLE.editPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.editPeopleLoading = false;
      });
  };

  //close edit drawer
  const closePeopleEditDrawer = () => {
    setEditPeopleModal(false);
    // if (!editOpenPEOPLE) {
    //   PEOPLE.peopleValues = null;
    // }
    getDealDetail(deal_data?.lead_information?.id);
    seteditOpenPEOPLE(false);
  };
  /*EDIT: PEOPLE EDIT FUNCTIONS*/

  // View People Drawer
  const handleViewPeopleDetailCall = debounce((data) => {
    PEOPLE.viewPeopleLoading = true;
    PEOPLE.setViewValues(data);
    PEOPLE.getViewPplDetail(data?.id)
      .then((data) => {
        PEOPLE.viewPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.viewPeopleLoading = false;
      });
  }, 300);

  const openPeopleViewDrawer = () => {
    if (deal_data?.lead_information?.people?.id) {
      if (AUTH.checkPermission(18, "view-details")) {
        PEOPLE.viewPeopleLoading = true;
        setViewPeopleDrawer(true);
        handleViewPeopleDetailCall({
          id: deal_data?.lead_information?.people?.id,
        });
      }
    }
  };
  const closePeopleViewDrawer = () => {
    // PEOPLE.setViewValues([]);
    // PEOPLE.peopleValues = null;
    setViewPeopleDrawer(false);
  };

  const copyIcon = (ticket_num) => {
    return (
      <img
        className="ml-5"
        style={{ width: "10px" }}
        src={AUTH.GetThemedImage("Copy_icn")}
        alt={`${t("DealDesk.Copy")}`}
        onClick={(event) => {
          copy(ticket_num);
          vsmNotify.success({
            message: `${t("DealDesk.Copied")}!`,
          });
        }}
      />
    );
  };
  const viewTicket_desc = () => {
    let ticket_desc = "";
    if (deal_data?.lead_information?.ticket_data?.ticket_description) {
      ticket_desc =
        deal_data?.lead_information?.ticket_data?.ticket_description;
      if (ticket_desc.length > 150) {
        ticket_desc = ticket_desc.substr(0, 150);
        ticket_desc += "...";
      }
    }
    return ticket_desc;
  };

  useEffect(() => {
    setSelectedValue(primaryPhoneDetail?.last_called_on || "work");

    if (
      viewTicketDrawer ||
      viewOrganisationDrawer ||
      editModal ||
      viewPeopleDrawer ||
      editPeopleModal ||
      editDrawer ||
      viewSoldDrawer
    ) {
      TimeTrackerStore.setStopForDesk(true);
    } else {
      TimeTrackerStore.setStopForDesk(false);
    }
  }, [
    TimeTrackerStore,
    viewTicketDrawer,
    viewOrganisationDrawer,
    editModal,
    viewPeopleDrawer,
    editPeopleModal,
    editDrawer,
    viewSoldDrawer,
  ]);

  return (
    AUTH &&
    deal_data && (
      <>
        <div className="orgDetails">
          <Row gutter={5}>
            {dealDeskTypeArr.includes(
              deal_data?.lead_information?.is_ticket
            ) ? (
              <span className="ml-10"></span>
            ) : (
              <Col span={2} className="text-center d-flex align-items-center">
                <div
                  id="triangle-left"
                  onClick={() => getNextPrevDeal("previous")}
                ></div>
              </Col>
            )}
            <Col
              span={
                dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
                  ? 10
                  : 7
              }
              className="orgBrife d-flex align-items-center"
            >
              <ul>
                {deal_data.lead_information?.is_ticket === 0 ? (
                  <li>
                    <span className="title">{t("DealDesk.Ticket_Number")}</span>
                    <Tooltip
                      placement="topLeft"
                      title={`${t("DealDesk.View_Ticket")} - ${
                        deal_data?.lead_information?.ticket_data?.ticket_number
                      }`}
                    >
                      <span
                        onClick={() => {
                          openTicketViewDrawer();
                        }}
                      >
                        {
                          deal_data?.lead_information?.ticket_data
                            ?.ticket_number
                        }
                      </span>
                    </Tooltip>
                    <span>
                      {copyIcon(
                        deal_data?.lead_information?.ticket_data?.ticket_number
                      )}
                    </span>
                  </li>
                ) : deal_data.lead_information?.is_ticket === 1 ? (
                  <li>
                    <span className="title">{t("DealDesk.SOLD_ID")}</span>
                    <Tooltip
                      placement="topLeft"
                      title={deal_data?.lead_information?.id}
                    >
                      <span
                        onClick={() => {
                          openSoldViewDrawer();
                        }}
                      >
                        {deal_data?.lead_information?.id}
                      </span>
                    </Tooltip>
                    <span>{copyIcon(deal_data?.lead_information?.id)}</span>
                  </li>
                ) : (
                  <li>
                    <span className="title">
                      {deal_data?.lead_information?.is_ticket === 2
                        ? t("Common_Data.People")
                        : deal_data?.lead_information?.is_ticket === 3
                        ? t("DealDesk.Organisation")
                        : ""}{" "}
                      {t("Common_Data.ID")}
                    </span>
                    <Tooltip
                      placement="topLeft"
                      title={
                        deal_data?.lead_information?.is_ticket === 2
                          ? deal_data?.lead_information?.people?.id
                          : deal_data?.lead_information?.is_ticket === 3
                          ? deal_data?.lead_information?.sold_organisation?.id
                          : ""
                      }
                    >
                      <span
                        onClick={() => {
                          openOrganisationViewDrawer();
                        }}
                      >
                        {deal_data?.lead_information?.is_ticket === 2
                          ? deal_data?.lead_information?.people?.id
                          : deal_data?.lead_information?.is_ticket === 3
                          ? deal_data?.lead_information?.sold_organisation?.id
                          : ""}
                      </span>
                    </Tooltip>
                  </li>
                )}

                {/* {deal_data.lead_information?.product_name && ( */}

                {deal_data.lead_information?.is_ticket === 0 ? (
                  <li>
                    <span className="title">
                      {t("DealDesk.Organisation_ID")}
                    </span>
                    <Tooltip
                      placement="topLeft"
                      title={deal_data?.lead_information?.sold_organisation?.id}
                    >
                      <span
                        onClick={() => {
                          openOrganisationViewDrawer();
                        }}
                      >
                        {deal_data?.lead_information?.sold_organisation?.id}
                      </span>
                    </Tooltip>
                  </li>
                ) : deal_data.lead_information?.is_ticket === 3 ? (
                  <li>
                    <span className="title">{t("DealDesk.Trade_Name")}</span>
                    <Tooltip
                      placement="topLeft"
                      title={
                        deal_data?.lead_information?.sold_organisation
                          ?.trade_name
                      }
                    >
                      <span
                        onClick={() => {
                          openOrganisationViewDrawer();
                        }}
                      >
                        {
                          deal_data?.lead_information?.sold_organisation
                            ?.trade_name
                        }
                      </span>
                    </Tooltip>
                  </li>
                ) : deal_data.lead_information?.is_ticket === 2 ? (
                  <li>
                    <span className="title">{t("DealDesk.Trade_Name")}</span>
                    <Tooltip
                      placement="topLeft"
                      title={deal_data?.lead_information?.product_name}
                    >
                      <span
                        onClick={() => {
                          openSoldViewDrawer();
                        }}
                      >
                        {deal_data?.lead_information?.product_name}
                      </span>
                    </Tooltip>
                  </li>
                ) : (
                  <li>
                    <span className="title">{t("Common_Data.SOLD_Name")}</span>
                    <Tooltip
                      placement="topLeft"
                      title={deal_data?.lead_information?.opportunity_name}
                    >
                      <span
                        onClick={() => {
                          openSoldViewDrawer();
                        }}
                      >
                        {deal_data?.lead_information?.opportunity_name}
                      </span>
                    </Tooltip>
                  </li>
                )}

                {/* )} */}

                {/* {deal_data.lead_information?.organisation_name?.organisation_name && ( */}
                <li>
                  <span className="title">{t("DealDesk.Organisation")}</span>
                  <Tooltip
                    placement="topLeft"
                    title={
                      deal_data?.lead_information?.sold_organisation
                        ?.organisation_name
                    }
                  >
                    <strong
                      onClick={() => {
                        openOrganisationViewDrawer();
                      }}
                    >
                      {
                        deal_data?.lead_information?.sold_organisation
                          ?.organisation_name
                      }
                    </strong>
                  </Tooltip>
                </li>
                {/* )} */}
                {/* {deal_data?.lead_information?.people?.full_name && ( */}
                <li>
                  <span className="title">{t("DealDesk.Person")}</span>
                  <Tooltip
                    placement="topLeft"
                    title={deal_data?.lead_information?.people_full_name}
                  >
                    <span
                      onClick={() => {
                        openPeopleViewDrawer();
                      }}
                    >
                      {deal_data?.lead_information?.people_full_name}
                    </span>
                  </Tooltip>
                </li>
                {deal_data.lead_information?.is_ticket === 1 ? (
                  <li>
                    <span className="title">
                      {t("Block_Edit_Assign.SOLD_Type")}
                    </span>
                    <Tooltip
                      placement="topLeft"
                      title={deal_data?.lead_information?.lead_record_type}
                    >
                      <span
                        onClick={() => {
                          openSoldViewDrawer();
                        }}
                      >
                        {deal_data?.lead_information?.lead_record_type}
                      </span>
                    </Tooltip>
                  </li>
                ) : null}
                {/* )} */}
              </ul>
            </Col>
            <Col span={1} className="text-center">
              <div className="sep"></div>
            </Col>
            {deal_data.lead_information?.is_ticket === 0 ? (
              <Col span={12} className="orgBrife d-flex">
                {/* align-items-center */}
                <ul>
                  <li>
                    <b>
                      <span
                        onClick={() => {
                          openTicketViewDrawer();
                        }}
                      >
                        {/* data.lead_information.ticket_data.ticket_description */}
                        {
                          deal_data?.lead_information?.ticket_data
                            ?.ticket_subject
                        }
                      </span>
                    </b>
                  </li>
                  <li className="ticket-description">
                    <span
                      onClick={() => {
                        openTicketViewDrawer();
                      }}
                    >
                      {viewTicket_desc()}
                    </span>
                  </li>
                </ul>
              </Col>
            ) : (
              <>
                <Col
                  span={12}
                  className="contactDetails d-flex align-items-center"
                >
                  {[0].includes(deal_data?.lead_information?.is_ticket) ? (
                    <ul></ul>
                  ) : (
                    <ul>
                      <li></li>
                      <li></li>
                      {deal_data?.lead_information?.lead_currency_id
                        ?.currency_name && (
                        <li>
                          <div className="content">
                            <img
                              src={AUTH.GetThemedImage("Value")}
                              alt=""
                              className="icon"
                              onMouseEnter={() => {
                                if (!getCurrency) {
                                  fetchGlobalCurrencyList().then((data) => {
                                    setGetCurrency(data);
                                  });
                                }
                              }}
                            />
                            <Tooltip
                              placement="topLeft"
                              title={`${deal_data?.deal_sum?.deal_sum}`}
                            >
                              {deal_data?.deal_sum?.deal_sum}
                            </Tooltip>
                          </div>
                        </li>
                      )}
                      {[1].includes(deal_data?.lead_information?.is_ticket) && (
                        <li>
                          {t("Common_Data.SOLD_Product_Service")} :{" "}
                          {deal_data?.lead_information?.product_name}
                        </li>
                      )}
                      {/* {primaryPhoneDetail ? ( */}

                      <li className="bgShade">
                        <div className="content ">
                          {deal_data?.lead_information?.people_phone?.length >
                            0 ||
                          deal_data?.lead_information?.phone?.length > 0 ? (
                            <Popover
                              content={
                                pageName === "dealdesk" ||
                                pageName === "organisation"
                                  ? phoneData
                                  : peopelPhoneData
                              }
                              trigger="hover"
                              placement="bottomLeft"
                              overlayClassName="timeMenu autoHeight"
                            >
                              <Tooltip title={`${t("DealDesk.Phone_Numbers")}`}>
                                <img
                                  src={AUTH.GetThemedImage("Phone")}
                                  alt=""
                                  className="icon"
                                />
                              </Tooltip>

                              <Tooltip
                                title={primaryPhoneDetail?.contact_value}
                              >
                                <a
                                  // href={`tel:${primaryPhoneDetail?.contact_value}`}
                                  onClick={(e) => {
                                    handleCallAudit(
                                      primaryPhoneDetail?.contact_value
                                    );
                                  }}
                                >
                                  {primaryPhoneDetail?.contact_value}
                                </a>
                              </Tooltip>
                            </Popover>
                          ) : (
                            <Tooltip title={`${t("DealDesk.Phone_Numbers")}`}>
                              <img
                                src={AUTH.GetThemedImage("Phone")}
                                alt=""
                                className="icon"
                              />
                            </Tooltip>
                          )}
                        </div>
                      </li>
                      {/* ) : (
                        <li className='bgShade'>
                          <div className='content '>
                            <Tooltip title={`${t('DealDesk.Phone_Numbers')}`}>
                              <img
                                src={AUTH.GetThemedImage('Phone')}
                                alt=''
                                className='icon'
                              />
                            </Tooltip>
                          </div>
                        </li>
                      )} */}

                      <li className="bgShade">
                        <div className="content ">
                          {deal_data?.lead_information?.people_email?.length >
                            0 ||
                          deal_data?.lead_information?.email?.length > 0 ? (
                            <Popover
                              content={
                                pageName === "dealdesk" ||
                                pageName === "organisation"
                                  ? emailData
                                  : peopleEmailData
                              }
                              trigger="hover"
                              placement="bottomLeft"
                              overlayClassName="timeMenu autoHeight"
                            >
                              <Tooltip title={`${t("DealDesk.Email_Id")}`}>
                                <img
                                  src={AUTH.GetThemedImage("Mail")}
                                  alt=""
                                  className="icon"
                                />
                              </Tooltip>
                              <Tooltip
                                placement="topLeft"
                                title={primaryEmailDetail}
                              >
                                <a
                                  href={`mailto:${primaryEmailDetail}`}
                                  onClick={(e) => {
                                    handleCallAuditEmail(primaryEmailDetail);
                                  }}
                                >
                                  {primaryEmailDetail}
                                </a>
                              </Tooltip>
                            </Popover>
                          ) : (
                            <Tooltip title={`${t("DealDesk.Email_Id")}`}>
                              <img
                                src={AUTH.GetThemedImage("Mail")}
                                alt=""
                                className="icon"
                              />
                            </Tooltip>
                          )}
                        </div>
                      </li>

                      {[0].includes(
                        deal_data?.lead_information?.is_ticket
                      ) ? null : mobileDetail ? (
                        <li className="bgShade" key={mobileDetail.id}>
                          <div className="content ">
                            <Tooltip title={`${t("DealDesk.Mobile_Number")}`}>
                              <img
                                src={AUTH.GetThemedImage("Mobile")}
                                alt=""
                                className="icon"
                              />
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={mobileDetail.contact_value}
                            >
                              <a href={`tel:${mobileDetail.contact_value}`}>
                                {mobileDetail.contact_value}
                              </a>
                            </Tooltip>
                          </div>
                        </li>
                      ) : (
                        <li className="bgShade">
                          <div className="content ">
                            <Tooltip title={`${t("DealDesk.Mobile_Number")}`}>
                              <img
                                src={AUTH.GetThemedImage("Mobile")}
                                alt=""
                                className="icon"
                              />
                            </Tooltip>
                          </div>
                        </li>
                      )}

                      {[0].includes(
                        deal_data?.lead_information?.is_ticket
                      ) ? null : smsDetail ? (
                        <li className="bgShade" key={smsDetail.id}>
                          <div className="content ">
                            <Tooltip title={`${t("DealDesk.SMS_Number")}`}>
                              <img
                                src={AUTH.GetThemedImage("SMS")}
                                alt=""
                                className="icon"
                              />
                            </Tooltip>
                            <Tooltip
                              placement="topLeft"
                              title={smsDetail.contact_value}
                            >
                              <a href={`tel:${smsDetail.contact_value}`}>
                                {smsDetail.contact_value}
                              </a>
                            </Tooltip>
                          </div>
                        </li>
                      ) : (
                        <li className="bgShade">
                          <div className="content ">
                            <Tooltip title={`${t("DealDesk.SMS_Number")}`}>
                              <img
                                src={AUTH.GetThemedImage("SMS")}
                                alt=""
                                className="icon"
                              />
                            </Tooltip>
                          </div>
                        </li>
                      )}
                    </ul>
                  )}
                </Col>
                {dealDeskTypeArr.includes(
                  deal_data?.lead_information?.is_ticket
                ) ? (
                  <span className="ml-10"></span>
                ) : (
                  <Col
                    span={2}
                    className="text-center d-flex align-items-center"
                  >
                    <div
                      id="triangle-right"
                      onClick={() => getNextPrevDeal("next")}
                    ></div>
                  </Col>
                )}
              </>
            )}
          </Row>
        </div>

        <ViewTicketComponent
          ticketDesk={true}
          id={deal_data?.lead_information?.ticket_data?.id}
          visible={viewTicketDrawer}
          close={closeTicketViewDrawer}
        />

        <ViewOrganisationComponent
          ticketDesk={true}
          id={deal_data?.lead_information?.sold_organisation?.id}
          visible={viewOrganisationDrawer}
          openEditModal={openEditModal}
          close={closeOrganisationViewDrawer}
          handleViewDetailCall={handleViewORGANISATIONDetailCall}
        />

        <EditOrganisationComponent
          transactionId={transactionId}
          setId={setId}
          editOpen={editOpen}
          visible={editModal}
          close={closeEditModal}
        />

        <ViewPeopleComponent
          ticketDesk={true}
          openEditDrawer={openPeopleEditDrawer}
          id={deal_data?.lead_information?.people?.id}
          visible={viewPeopleDrawer}
          close={closePeopleViewDrawer}
          handleViewDetailCall={handleViewPeopleDetailCall}
        />

        <EditPeopleComponent
          editOpen={editOpenPEOPLE}
          visible={editPeopleModal}
          close={closePeopleEditDrawer}
        />

        <EditSoldComponent
          visible={editDrawer}
          editOpen={editOpenSOLD}
          close={closeEditDrawer}
        />

        <ViewSoldComponent
          ticketDesk={true}
          id={deal_data?.lead_information?.id}
          openEditDrawer={openEditDrawer}
          visible={viewSoldDrawer}
          close={closeSoldViewDrawer}
        />
        <CallAuditModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          setSelectedValue={setSelectedValue}
          selectedValue={selectedValue}
          LEADSSTORE={LEADSSTORE}
          lastCalledOn={setSelectedValue}
          formData={{
            id: deal_data?.lead_information?.id,
            phone: selectedNumber,
            callType: selectedValue,
          }}
          // formData={formData}
        />
      </>
    )
  );
});

export default OrganisationDetailsComponent;
