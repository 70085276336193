import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Input, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { vsmNotify, vsmTransactionFields } from '../../../../config/messages';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const {
    TransactionFieldStore: { DeleteData, deleteValues },
  } = useStore();
  const [saving, setSaving] = useState();
  const [deleteButton, ToggleDeleteButton] = useState(true);
  const [deleteText, setDeleteText] = useState(`${t('DealBuilder.Transfer_Delete')}`);

  // Made function call to delete existing record
  const handleSubmit = (data) => {
    setDeleteText(`${t('DealBuilder.Exisiting_Journals_Please_Wait')}`);
    setSaving(true);
    data.id = deleteValues.id;
    DeleteData(data)
      .then(() => {
        props.close();
        vsmNotify.success({
          message: vsmTransactionFields.delete,
        });
        form.resetFields();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
        setDeleteText(`${t('DealBuilder.Transfer_Delete')}`);
        ToggleDeleteButton(true);
      });
  };

  // Set the form values to delete
  useEffect(() => {
    ToggleDeleteButton(true);
    if (deleteValues) {
      form.setFieldsValue({
        field_name: deleteValues.field_name,
        count: deleteValues.association_count,
      });
    }
  }, [deleteValues, form]);

  return deleteValues ? (
    <Modal
      title={`${t('DealBuilder.Delete_Deal_Maker')}- #${deleteValues?.id}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        deleteValues.association_count > 0 ? (
          <Button
            key='1'
            disabled={deleteButton}
            form='deleteformDEALMAKERField'
            loading={saving}
            htmlType='submit'
            type='primary'
            danger
          >
            {deleteText}
          </Button>
        ) : (
          <Button
            key='1'
            form='deleteformDEALMAKERField'
            loading={saving}
            htmlType='submit'
            type='primary'
            danger
          >
            {t('Common_Data.Delete')}
          </Button>
        ),
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            ToggleDeleteButton(true);
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteformDEALMAKERField' onFinish={handleSubmit}>
        <Form.Item name='transfer_id' style={{ display: 'none' }}>
          <Input type='hidden' />
        </Form.Item>
        <Row align='middle'>
          <Col span={4} className='DeleteIcon'>
            <WarningFilled />
          </Col>
          <Col span={20} className='DeleteText'>
            {t('DealBuilder.Remove_Deal_Maker')}?
          </Col>
        </Row>
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
