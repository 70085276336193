import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { PageHeader, Button, Form, Space } from 'antd';
import ListComponent from './component/ListComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import AddComponent from './component/AddComponent';
import useStore from '../../../../store';
//import Unauthorized from '../../../Unauthorized';
import RecordPerPage from '../../../../config/RecordPerPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../../../component/EnterFullscreen';
import Unauthorized from '../../../Unauthorized';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../../component/TimeTracker';
import { useEffect } from 'react';

const PeopleRoles = observer(() => {
  const { t } = useTranslation()
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [goBack, setGoBack] = useState(false)
  const { PEOPLEROLESTORE, SETTINGS, AUTH } = useStore();
  const [form] = Form.useForm();
  let history = useHistory();
  // Open form for add new Organisation
  const openAddModal = () => {
    setAddModal(true);
  };

  // Close form for close new Organisation
  const closeAddModal = () => setAddModal(false);

  // Open form for edit existing Organisation and set values to form
  const openEditModal = (data) => {
    PEOPLEROLESTORE.setEditValues(data);
    setEditModal(true);
  };

  // Close form of edit Organisation
  const closeEditModal = () => setEditModal(false);

  // Open confirmation alert before deleting existing Organisation
  const openDeleteModal = (data) => {
    PEOPLEROLESTORE.setDeleteValues(data);
    setDeleteModal(true);
  };

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => setDeleteModal(false);
  function pageTitle() {
    return (
      <>
        {t('Common_Data.People')} {t('OrganisationManagement.Roles')}
        <Button
          key='1'
          shape='round'
          size='small'
          type='primary'
          onClick={() => { setGoBack(true) }}
          className='goBackBtn'
        >
          {t('Common_Data.GO')} {t('Common_Data.Back')}
        </Button>
      </>
    );
  }

  useEffect(() => {
    if (goBack) {
      history.goBack()
    }
  }, [goBack, history]);


  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(19, 'edit')) {
            if (result.key === 'Enter') {
              openEditModal(e.data);
            }
          }
          if (AUTH.checkPermission(19, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteModal(e.data);
            }
          }
        }
      }
    }
  };
  return (
    <>
      {!AUTH.checkPermission(19, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={pageTitle()}
          className={SETTINGS.fullscreen_class}
          extra={[
            <Form form={form} className='innerForm headerButton'>
              <Space align='center' size={5}>
                {AUTH.checkPermission(19, 'add') && (
                  <Button key='1' shape='round' size='medium' onClick={openAddModal}>
                    {t('PeopleManagement.New')} {t('PeopleManagement.Role')}
                  </Button>
                )}

                <Button
                  key='2'
                  title={`${t('Common_Data.Reset')}`}
                  shape='round'
                  size='medium'
                  onClick={PEOPLEROLESTORE.handleReset}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <EnterFullscreen key='3' />
                <RecordPerPage
                  key='4'
                  initialValue={PEOPLEROLESTORE.per_page + ' per page'}
                  onChange={PEOPLEROLESTORE.setPageSize}
                />
              </Space>
            </Form>,
          ]}
        >
          <ListComponent
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
            onCellKeyDown={onCellKeyDown}
          />
          <AddComponent visible={addModal} close={closeAddModal} />
          <EditComponent visible={editModal} close={closeEditModal} />
          <DeleteComponent visible={deleteModal} close={closeDeleteModal} />
            <TimeTracker
              page={"people-role"}
              stop={goBack}
            />
        </PageHeader>
      )}
    </>
  );
});

export default PeopleRoles;
