import { Button, Switch, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useStore from '../../../store';

export const ActionRenderer = (props) => {
  const { AUTH, SAVEDFILTERSTORE, TicketsStore, TagsStore, PRODUCTIVITYSTORE, MANAGEMENTALLDEPARTMENTSSTORE, BLOCKEDITASSIGNSTORE, LEADSSTORE, LEADACTIVE, LEADDEALS, PEOPLE, ORGANISATION, PROMOTIONSTORE, REMINDERSSTORE } = useStore();
  let history = useHistory();
  const {t}  = useTranslation()
  const { openViewDrawer, openDeleteDrawer, openEditDrawer, onSwitchChange } =
    props.agGridReact.props.frameworkComponents;

  const redirectList = (data) => {
    let pathname = SAVEDFILTERSTORE.filterPageRedirectList[data.page];
    if (data.page === 'tags') {
      TagsStore.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'productivity') {
      PRODUCTIVITYSTORE.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'alldepartments') {
      MANAGEMENTALLDEPARTMENTSSTORE.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'tickets') {
      TicketsStore.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'blockeditassign') {
      BLOCKEDITASSIGNSTORE.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'activeleads') {
      LEADACTIVE.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'deals') {
      LEADDEALS.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'people') {
      PEOPLE.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'organisations') {
      ORGANISATION.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'promotion') {
      PROMOTIONSTORE.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'reminder') {
      REMINDERSSTORE.setSelectedGridFilter(data.id)
    }
    else if (data.page === 'unusedleads') {
      LEADSSTORE.setSelectedGridFilter(data.id)
    }
    history.push(pathname);
  }

  return (
    <>
      <div className='action-column'>
        <Tooltip title={`${props?.data?.global_toggle ? `${t('Save_Filter.Global')} ${t('Common_Data.Filter')}` : `${t('Save_Filter.Private')} ${t('Common_Data.Filter')}`}`}>
          <Switch
            style={{ margin: '5px' }}
            onChange={(val) => onSwitchChange(val, props?.data)}
            defaultChecked={props?.data?.global_toggle ? 1 : 0}
          />
        </Tooltip>
        {/* {AUTH.checkPermission(39, 'view-details') && ( */}
        <Button
          type='text'
          title={`${t('Common_Data.View')}`}
          onClick={() => {
            openViewDrawer(props.data);
          }}
        >
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
        </Button>
        {/* )} */}

        {/* {AUTH.checkPermission(15, 'edit') && ( */}
        <Button
          type='text'
          title={`${t('Common_Data.Edit')}`}
          onClick={() => {
            openEditDrawer(props.data);
          }}
        >
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
        </Button>
        {/* )} */}

        {/* {AUTH.checkPermission(39, 'delete') && ( */}
        <Button
          type='text'
          title={`${t('Common_Data.Delete')}`}
          onClick={() => {
            openDeleteDrawer(props.data);
          }}
        >
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
        </Button>
        <Button
          type='text'
          title={`${SAVEDFILTERSTORE.filterPageList[props?.data?.page]}`}
          onClick={() => {
            redirectList(props.data);
          }}
        >
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('List_view')} />
        </Button>
        {/* )} */}
      </div>
    </>
  );
};
