import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { useLocation } from 'react-router-dom';
import { vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const HourglassConfirmComponent = observer((props) => {
  const {t} = useTranslation()
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const { AUTH, TagsStore, PRODUCTIVITYSTORE, MANAGEMENTALLDEPARTMENTSSTORE } =
    useStore();
  const location = useLocation();

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };

  // Make function call to delete existing record
  const handleSubmit = () => {
    var data = {
      reminder_type: 'tags',
      reminder_for: props?.hourglassConfirm?.id,
      minutes: AUTH.user?.dm_settings?.[0]?.screen_tags_hourglass,
      hourglass: true,
      seperate_hourglass: 0,
    };
    // -----------------If Hourglss RESET From Push Notification------//
    if (props?.resetNotificationData) {
      data.seperate_hourglass = 1;
    }
    // -----------------If Hourglss RESET From Push Notification------//

    setSaving(true);
    AUTH.ResetData(data)
      .then(() => {
        close();
        if (props?.resetNotificationData) {
          props.resetNotificationData();
        }
        if (location.pathname === '/productivity') {
          PRODUCTIVITYSTORE.setupGrid(PRODUCTIVITYSTORE.agGrid);
        } else if (location.pathname === '/management/all-departments') {
          MANAGEMENTALLDEPARTMENTSSTORE.getList();
        } else {
          TagsStore.setupGrid(TagsStore.agGrid);
        }
        vsmNotify.success({
          message: `${t('Common_Data.Reminder_Updated')}!`,
        });
      })
      .catch((e) => {})
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Reset')} ${t('Tags.Tag')} ${t('Common_Data.Reminder')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='hourglassForm'
          htmlType='submit'
          type='primary'
          shape='round'
          loading={saving}
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='hourglassForm' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('Tags.Reset_Reminder_Tag')}{' '}
              <strong>#{props?.hourglassConfirm?.tag_number}</strong> {t('Common_Data.To')} {t('Common_Data.With')}{' '}
              <strong>{AUTH.user?.dm_settings?.[0]?.screen_tags_hourglass}</strong>{' '}
              {t('Common_Data.Minutes')}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  );
});

export default HourglassConfirmComponent;
