import React, { useState } from 'react';
import { observer } from 'mobx-react';
import FormComponent from './FormComponent';
import { Button, Form, Modal } from 'antd';
import useStore from '../../../../../store';
import { vsmNotify, vsmPeopleRoles } from '../../../../../config/messages';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const { PEOPLEROLESTORE } = useStore();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);

  // reset form and close add form
  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
    PEOPLEROLESTORE.setEditValues(null);
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        setDisabled(true);
      });
  };

  const handleSubmit = (data) => {
    data.id = PEOPLEROLESTORE.editValues.id;
    PEOPLEROLESTORE.EditData(data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmPeopleRoles.edit
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  return (
    PEOPLEROLESTORE.editValues && (
      <Modal
        destroyOnClose={true}
        centered
        title={`${t('Common_Data.Edit')} ${t('PeopleManagement.Role')} #${PEOPLEROLESTORE.editValues.id} - ${PEOPLEROLESTORE.editValues.role_name}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            disabled={disabled}
            form='addeditform'
            loading={saving}
            htmlType='submit'
            type='primary'
          >
            {t('Common_Data.Save')}
          </Button>,
          <Button
            key='2'
            htmlType='button'
            onClick={() => {
              close();
            }}
          >
            {t('Common_Data.Cancel')}
          </Button>
        ]}
      >
        <FormComponent
          form={form}
          visible={props.visible}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          tab={"edit"}
        />
      </Modal>
    )
  );
});

export default EditComponent;
