import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';
import { reset } from '../../utils/ResetClearButton';

export default class KnowledgeStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  agGridRelOrg = null;
  deleteValues = null;
  viewValues = null;
  knowledgeValues = null;
  rKnowledgeValues = null;
  fetching = false;
  classification_list = null;
  knowledge_group_list = null;

  search_payload = { filter_data: {}, sort_data: {} };

  initialValues = {
    subject: [null],
    description: [null],
    classification: [null],
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.PEOPLE) {
        params = { request: temp.PEOPLE };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PEOPLE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          PEOPLE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('knowledge_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };
  // Setup grid and set column size to autosize for search
  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_knowledge_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  getList = (payload = {}) => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.columnApi.getColumnState();
      this.search_payload = {
        filter_data: this.agGrid.api.getFilterModel(),
        sort_data: this.agGrid.columnApi.getColumnState()
      }
    }

    let merged = { ...payload, ...this.search_payload };

    return Axios.post(`knowledge/list`, merged).then(({ data }) => {
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.columnApi.applyColumnState({ state: sort });
      }
      let columnConfig = localStorage.getItem('knowledge_grid');
      if (this.agGrid && this.agGrid.columnApi && columnConfig) {
        this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
      }
      return data;
    });
  };


  getListSearch = (payload = {}) => {
    var temp = JSON.parse(localStorage.getItem('params'));
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.KnowledgeSearchStore?.sort;
    }
    this.list_data = null;

    return Axios.post(`knowledge/list`,payload).then(({ data }) => {
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;

      this.agGrid.api.setSortModel(sort ? sort : temp?.KnowledgeSearchStore?.sort);

      if(this.list_data?.length === 0){
        this.agGrid.api.showNoRowsOverlay();
      }
      return data;
    });
  };

  getRecordDetail = (formdata, setVariable = true) => {
    return Axios.get(`knowledge/read/${formdata.knowledgeid}`)
      .then(({ data }) => {
        if (setVariable) {
          this.knowledgeValues = data.data;
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        this.knowledgeValues = null;
        return Promise.reject(data);
      });
  };

  setKnowledgeValues = (data) => {
    this.knowledgeValues = data;
  };

  // set related knowledge values
  setRKnowledgeValues = (data) => {
    this.rKnowledgeValues = data;
  };

  AddData = (data) => {
    return Axios.post(`knowledge/add`, data)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  EditData = (data) => {
    return Axios.post(`knowledge/edit/${data.id}`, data)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  DeleteData = (group_id) => {
    return Axios.get(`knowledge/delete/${group_id}`)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!['logo_url', 'date_of_birth'].includes(key)) {
        data[key] && formData.append(key, data[key]);
      }
    });
    return formData;
  };

  getClassificationList = () => {
    return Axios.post(`industry/list`).then(({ data }) => {
      this.classification_list = data.data;
      /*this.classification_list = data.data.sort((a, b) =>
        a.name < b.name ? -1 : b.name < a.name ? 1 : 0
      );*/
      return data;
    });
  };

  getKnowledgeGroupList = () => {
    return Axios.get(`knowledge/knowledge-group`).then(({ data }) => {
      if (data.data.length) {
        let knowledge_group = data.data;
        knowledge_group.map((group) => {
          group.value = group.knowledge_group;
          return group;
        });
      }
      this.knowledge_group_list = data.data;

      return data;
    });
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'knowledge_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };
  onGridChangedSearch = (params) => {
    localStorage.setItem(
      'search_knowledge_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // reset function
  handleReset = () => {
    reset(this.agGrid, "knowledge_grid");
  };

  deleteKnowledgeData = (data) => {
    return Axios.get(`knowledge/delete/${data.id}`)
      .then(({ data }) => {
        // this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };
}

decorate(KnowledgeStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  agGridRelOrg: observable,
  deleteValues: observable,
  knowledgeValues: observable,
  rknowledgeValues: observable,
  initialValues: observable,
  viewValues: observable,
  classification_list: observable,
  knowledge_group_list: observable,
  search_payload: observable,

  setPageSize: action,
  getList: action,
  setupGrid: action,
  setDeleteValues: action,
  getRecordDetail: action,
  setKnowledgeValues: action,
  setRKnowledgeValues: action,
  AddData: action,
  EditData: action,
  DeleteData: action,
  JsonToFormData: action,
  getClassificationList: action,
  onFilterChanged: action,
  onGridChanged: action,
  onGridChangedSearch: action,
  handleReset: action,
  getKnowledgeGroupList: action,
  deleteKnowledgeData: action,
  setupGridSearch: action,
});
