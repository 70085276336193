import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';

import useStore from '../../../../store';
import { dateCompare } from '../../../../utils/GlobalFunction';
import ScheduleTableContent from '../../RightSideComponent/NewSchedule/component/ScheduleTableContent';
import { useTranslation } from 'react-i18next';

const DisplayMsgList = observer((props) => {
  const {t} = useTranslation()
  const {
    AUTH,
    DEALDESKSTORE,
    //DEALDESKSTORE: { deal_data },
  } = useStore();
  const location = useLocation();

  useEffect(() => {
    if (location.hash && location.hash.substr(0, 2) === '#e' && props.listData) {
      let checkNoteExists = document.getElementById(location.hash);
      if (checkNoteExists) {
        document.getElementById(location.hash).scrollIntoView();
      }
    }
  }, [location.hash, props.listData]);

  let filterDataArr =
    props?.listData && props?.listData.filter((x) => x.type === 2 && ((!x.autosave) || (x.autosave === true && x.draft_description !== null)));
  let hashUrlAlphabet = 'e';

  return (
    <>
      <div className='DisplayMsgList mb-30'>
        {filterDataArr &&
          filterDataArr.map((item, index) => {
            let item_date_different = true;
            if (item.updated_at && item.created_at) {
              item_date_different = dateCompare(
                new Date(item.updated_at),
                new Date(item.created_at)
              );
            }

            return (
              <>
                <div
                  key={index}
                  id={`${`#${hashUrlAlphabet}${item.id}`}`}
                  className={`msgBox 
                ${DEALDESKSTORE?.activeScheduleData?.id === item?.id ? 'active' : ''}
                ${item.type &&
                    ((item.type === 'incoming' ? 'incoming' : '') ||
                      (item.type === 'outgoing' ? 'outgoing' : '') ||
                      (item.type === 'comments' ? 'comments' : ''))
                    }`}
                  onClick={() => {
                    DEALDESKSTORE.setActiveScheduleData(item);
                  }}
                >
                  <div className='header'>
                    <div className='details'>
                      <strong>
                        <Tooltip title={item.user}>{item.updated_by_name}</Tooltip>
                      </strong>{' '}
                      {` `}
                      <Tooltip title={AUTH.getLocalTime(item.updated_at)}>
                        {AUTH.getLocalTime(item.updated_at)}
                      </Tooltip>
                    </div>

                    {item_date_different ? (
                      <div className='details detailsNoteCreated'>
                        <img
                          style={{ width: 12, height: 12 }}
                          alt=''
                          src={AUTH.GetThemedImage('Date_Time_icn')}
                        />
                        {` `}
                        <Tooltip title={item.created_by_name}>
                          {item.created_by_name}
                        </Tooltip>
                        {` : `}
                        {`${AUTH.getLocalTimeDealDesk(
                          item.created_at,
                          AUTH.global_dateformat
                        )}`}
                      </div>
                    ) : null}
                    <div className='dealdeskNoteNo'>
                      <a href={`#${hashUrlAlphabet}${item.id}`}>
                        {hashUrlAlphabet}#{filterDataArr.length - index}
                      </a>
                    </div>
                  </div>
                  <table
                    width='100%'
                    cellSpacing='0'
                    cellPadding='5'
                    className='structure-table mt-10'
                    id={`scheduleid_for_notepost_${item.id}`} //Don't Remove ID - Used in to post details note editor
                  >
                    <tbody>
                      <tr>
                        <th
                          valign='top'
                          style={{ textAlign: 'left', verticalAlign: 'top' }}
                          align='left'
                          width='50px'
                          className='task'
                        >
                          #
                        </th>
                        <th
                          valign='top'
                          style={{ textAlign: 'left', verticalAlign: 'top' }}
                          align='left'
                          width='30px'
                          className='day'
                        >
                          {t('DealDesk.Days')}
                        </th>
                        <th
                          valign='top'
                          style={{ textAlign: 'left', verticalAlign: 'top' }}
                          align='left'
                          className='text'
                        >
                         {t('Common_Data.Description')}
                        </th>
                        <th
                          valign='top'
                          style={{ textAlign: 'left', verticalAlign: 'top' }}
                          width='100px'
                          className='date'
                        >
                         {t('DealDesk.Start_Date')}
                        </th>
                      </tr>
                      <ScheduleTableContent Schedule={item.description} />
                    </tbody>
                  </table>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
});

export default DisplayMsgList;
