import React, { Component, useEffect, useState } from 'react';
import { Form, Input, Button, Card, Tooltip, Divider, Drawer, Affix } from 'antd';
import { observer } from 'mobx-react';
import { InfoCircleOutlined } from '@ant-design/icons';
import useStore from '../../../../store';
import { vsmNotify, vsmAlgorithm } from '../../../../config/messages';
import AlgorithmBuilder from '../AlgorithmBuilder';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { UI } from 'formulize';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { AlgorithmsStore } = useStore();
  const [editValues, setEditValues] = useState(null);

  // set data on refresh page
  useEffect(() => {
    if (props.visible) {
      AlgorithmsStore.getRecordById({ id: props.id })
        .then((data) => {
          setEditValues(data.data);
        })
        .catch(() => {
          vsmNotify.error({
            message: vsmAlgorithm.InvalidRecord,
          });
          props.close();
        });
    }
  }, [AlgorithmsStore, props]);

  class EditComponent extends Component {
    field_list = null;

    constructor(props) {
      super(props);
      this.state = {
        formula: {},
        formula_status: 'error',
        saving: false,
        disabled: false,
        fields: [],
        field_list: this.props.AlgoStore.transaction_field_list,
      };
    }

    // make a fuction to call to edit record
    handleSubmit = (data) => {
      data.algorithm_function = '=trade_transaction.face_value*trade*100';
      data.algorithm_json = this.props.AlgoStore.setFormula(this.state.formula);
      data.used_tte_fields = this.state.fields;
      data.used_algorithms = 1;
      data.last_parse_result = 'Success';
      data.status = false;
      data.id = this.props.editValues.id;
      this.props.AlgoStore.EditData(data)
        .then(() => {
          vsmNotify.success({
            message: vsmAlgorithm.edit,
          });
          this.close();
        })
        .catch((e) => {
          if (e.errors) {
            form.setFields(e.errors);
          }
        });
    };

    componentDidMount() {
      if (this.props.editValues) {
        this.props.AlgoStore.getAllTransactionFields().then((data) => {
          this.setState({ field_list: data });
          this.field_list = data;
        });
        form.setFieldsValue({
          id: this.props.editValues.id,
          algorithm_name: this.props.editValues.algorithm_name,
          algorithm_desc: this.props.editValues.algorithm_desc,
          readable_format: this.props.editValues.readable_format,
        });
        this.setFormula(JSON.parse(this.props.editValues.algorithm_json || '{}'));
      }
      window.onmousemove = logMouseMove;

      function logMouseMove(event) {
        let e = event || window.event;
        window.x = e.clientX;
        window.y = e.clientY;
      }
      var temp_interval = setInterval(function () {
        if ($('.sticky_fields').length > 0) {
          clearInterval(temp_interval);
          var stickyTop = $('.sticky_fields').offset().top;

          $(window).scroll(function () {
            var windowTop = $(window).scrollTop();

            if (stickyTop < windowTop) {
              $('.sticky_fields').css('top', windowTop - stickyTop + 'px');
            } else {
              $('.sticky_fields').css('top', '0');
            }
          });
        }
      }, 200);
    }

    // check for valid form values then accordingly make save button disable/enable
    handleChange = () => {
      form
        .validateFields()
        .then((data) => {
          this.setState({ disabled: false });
        })
        .catch((e) => {
          this.setState({ disabled: true });
        });
    };

    // reset form and close edit form
    close = () => {
      this.props.setEditValues(null);
      this.props.close();
    };

    setFormula = (formula) => {
      var readable_format = this.props.AlgoStore.createReadableFormula(formula);
      form.setFieldsValue({
        readable_format: readable_format.formula,
      });
      this.setState({
        formula,
        formula_status: readable_format.status,
        fields: readable_format.fields,
      });
    };

    onDragEnd = (result) => {
      if (result.destination) {
        var target = $("[data-formulize='" + result.destination.droppableId + "']");
        if (target.length > 0) {
          const formulize = new UI(target, {});
          var position = null;
          if ($(':hover').last().offset()) {
            position = {
              x: window.x - $(':hover').last().offset().left,
              y: window.y - ($(':hover').last().offset().top - $(document).scrollTop()),
            };
          }
          var $element = $(
            "[data-rbd-draggable-id='" + result.draggableId + "']"
          ).clone();
          $element.attr('style', '');
          formulize.insert($element[0], position);
          setTimeout(() => {
            var readable_format = this.props.AlgoStore.getParsedArithmeticFormula(
              formulize.getData()
            );
            form.setFieldsValue({
              readable_format: readable_format,
            });
          }, 100);
        }
      }
    };

    getItemStyle = (isDragging, draggableStyle) => ({
      // styles we need to apply on draggables
      ...draggableStyle,
    });

    // available field search function
    handleSearch = (event) => {
      if (this.field_list) {
        let data = this.field_list;
        if (event.target.value) {
          const searchedData = data.filter((character) => {
            return character.field_name
              .toLowerCase()
              .includes(event.target.value.toLowerCase());
          });
          this.setState({ field_list: searchedData });
        } else {
          this.setState({ field_list: data });
        }
      }
    };

    render() {
      return this.props.editValues ? (
        <>
          <Drawer
            title={`${t('DealBuilder.Edit_Deal')} - #${this.props?.editValues?.id}`}
            visible={this.props.visible}
            onClose={this.close}
            placement='right'
            width={'75%'}
            destroyOnClose={true}
            footer={[
              <div className='text-right'>
                <Button
                  key='1'
                  form='editform'
                  disabled={this.state.disabled || this.state.formula_status === 'error'}
                  className='mr-20'
                  loading={this.state.saving}
                  type='primary'
                  htmlType='submit'
                  shape='round'
                  size='medium'
                >
                  {t('Common_Data.Save')}
                </Button>
                <Button onClick={this.close} shape='round' size='medium' key='2'>
                  {t('Common_Data.Cancel')}
                </Button>
              </div>,
            ]}
          >
            <Form
              form={this.props.form}
              id='editform'
              layout='vertical'
              onChange={this.handleChange}
              onFinish={this.handleSubmit}
              className='builder_wrapper'
            >
              <DragDropContext onDragEnd={this.onDragEnd}>
                <div className='d-flex'>
                  <div className='w-100'>
                    <Form.Item
                      name='algorithm_name'
                      rules={vsmAlgorithm.validation.algorithm_name}
                      label={`${t('DealBuilder.Algorithm_Name')}`}
                      required
                      tooltip={`${t('DealBuilder.Algorithm_Tooltip')}`}
                    >
                      <Input placeholder={`${t('DealBuilder.Algorithm_Name')}`} maxLength={100} />
                    </Form.Item>

                    <Form.Item
                      name='algorithm_desc'
                      rules={vsmAlgorithm.validation.algorithm_desc}
                      label={`${t('DealBuilder.Algorithm_Description')}`}
                      required
                      tooltip={`${t('DealBuilder.Algorithm_Description_Tooltip')}`}
                    >
                      <Input.TextArea
                        placeholder={`${t('DealBuilder.Algorithm_Description')}`}
                        maxLength={1000}
                      />
                    </Form.Item>
                    <h3>
                      <span>{t('DealBuilder.Algorithm_Builder')}</span>
                      <small>
                        <Tooltip
                          placement='topLeft'
                          title={`${t('DealBuilder.Algorithm_Builder_Tooltip')}`}
                        >
                          <InfoCircleOutlined size={10} className='ml-10' />
                        </Tooltip>
                      </small>
                    </h3>
                    <AlgorithmBuilder
                      main={this.state.formula}
                      formula={this.state.formula}
                      setFormula={this.setFormula}
                    />

                    <Form.Item
                      name='readable_format'
                      label={`${t('DealBuilder.Readable_Format')}`}
                      required
                      className='mt-20 custom_higlight'
                      validateStatus={this.state.formula_status}
                      tooltip={`${t('DealBuilder.Readable_Format_Tooltip')}`}
                    >
                      <Input.TextArea
                        disabled
                        autoSize={{ minRows: 2 }}
                        placeholder={`${t('DealBuilder.ReadableFormatPlaceholder')}`}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ minWidth: '280px', width: '280px', paddingLeft: '24px' }}>
                    <Affix offsetTop={80} offsetBottom={80}>
                      <Card>
                        <h3 className='text-center pt-5 pb-5'>
                          <span>{t('DealBuilder.Available_Fields')}</span>
                          <small>
                            <Tooltip
                              placement='topLeft'
                              title={`${t('DealBuilder.Available_Fields_Tooltip')}`}
                            >
                              <InfoCircleOutlined size={10} className='ml-10' />
                            </Tooltip>
                          </small>
                        </h3>
                        <div className='text-center pt-10 pb-10'>
                          <Input
                            placeholder={`${t('DealBuilder.Search')}`}
                            onChange={(value) => {
                              this.handleSearch(value);
                            }}
                          />
                        </div>
                        <Divider className='mt-0 mb-10' />
                        <div className='formulize-field-wrapper'>
                          <Droppable droppableId='droppable'>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef}>
                                {this.state.field_list &&
                                  this.state.field_list.length > 0 ? (
                                  this.state.field_list.map((item, index) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={'draggable_' + item.id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <a
                                            className={'w50  draggable_' + item.id}
                                            data-value={JSON.stringify({ item: item.id })}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              ...this.getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              ),
                                              display: 'block',
                                            }}
                                          >
                                            <div
                                              className={`formulize-custom ${snapshot.isDragging ? 'dragging' : ''
                                                }`}
                                            >
                                              {item.field_name}
                                              {provided.placeholder}
                                            </div>
                                          </a>
                                        );
                                      }}
                                    </Draggable>
                                  ))
                                ) : (
                                  <div className='text-center'>{t('Common_Data.No_Record_Found')}.</div>
                                )}
                              </div>
                            )}
                          </Droppable>
                        </div>
                        <div className='text-center mt-10'>
                          {t('DealBuilder.Drag_Field_Builder')}
                        </div>
                      </Card>
                      <div className='mt-10'>
                        <h4>
                          <span>{t('DealBuilder.Algorithm_Parsed')}</span>
                          <Tooltip
                            placement='topLeft'
                            title={`${t('DealBuilder.Algorithm_Parsed_Tooltip')}`}
                          >
                            <InfoCircleOutlined size={10} className='ml-10' />
                          </Tooltip>
                        </h4>
                        <div
                          className={
                            Object.keys(this.state.formula).length > 0 &&
                              this.state.formula_status === 'success'
                              ? 'text-success parsed_wrapper'
                              : 'text-danger parsed_wrapper'
                          }
                        >
                          {Object.keys(this.state.formula).length <= 0
                            ? `${t('DealBuilder.Parse_Error')}`
                            : this.props.AlgoStore.custom_error_msg}
                        </div>
                      </div>
                    </Affix>
                  </div>
                </div>
              </DragDropContext>

              <Divider />
            </Form>
          </Drawer>
        </>
      ) : null;
    }
  }

  return (
    <EditComponent
      form={form}
      editValues={editValues}
      AlgoStore={AlgorithmsStore}
      {...props}
      setEditValues={setEditValues}
    />
  );
});

export default EditComponent;
