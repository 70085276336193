import React from 'react';
import { observer } from 'mobx-react';
import { Form, Drawer, Spin } from 'antd';
import useStore from '../../../../store';
import ViewElement from '../elements/ViewElement';
// import TrackTimeComponent from '../../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../../component/TimeTracker';
import { Link } from 'react-router-dom';

const ViewOrganisation = observer((props) => {
  const [form] = Form.useForm();
  const { ORGANISATION, AUTH } = useStore();
  const { openEditModal, isChildDrawer = false } = props;
  const { t } = useTranslation();
  const close = () => {
    props.close();
  };

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1100px'}
      zIndex={`${isChildDrawer ? 1005 : 1000}`}
      title={`${t('OrganisationManagement.Details')} ${t('Common_Data.For')} ${ORGANISATION.viewOrgValues
        ? `${ORGANISATION.viewOrgValues?.organisation_name}`
        : ''
        }`}
      destroyOnClose={true}
      footer={[
        <>
          <Link to={`/organisation/${ORGANISATION.viewOrgValues?.leads_transactions_id}`} target='_blank'>
            <img
              className='menuicon'
              style={{ cursor: 'pointer', position: 'absolute', top: '17px', right: '20px', width: '20px', }}
              alt=''
              title={`${t('OrganisationManagement.History')} ${t('Common_Data.Organisation')}`}
              src={AUTH.GetThemedImage('Deal_icn')}
            />
          </Link>

          {AUTH.checkPermission(16, 'edit') && openEditModal && (
            <img
              className='menuicon'
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '17px',
                right: '60px',
                width: '20px',
              }}
              alt=''
              title={`${t('Common_Data.Edit')} ${t('OrganisationManagement.Organization')}`}
              src={AUTH.GetThemedImage('Edit')}
              onClick={() => openEditModal(ORGANISATION.viewOrgValues, true)}
            />
          )}
        </>,
      ]}
    >
      <Spin size='large' spinning={ORGANISATION.viewPeopleLoading} tip={`${t('Common_Data.Load')}`}>
        <ViewElement
          form={form}
          id={ORGANISATION.viewOrgValues?.id}
          viewOrgValues={ORGANISATION.viewOrgValues}
          handleViewDetailCall={props.handleViewDetailCall}
          waste={props.waste}
        />
      </Spin>
      {/* {ORGANISATION.viewOrgValues && !openEditModal && (
        <TrackTimeComponent
          pageName={'organisation'}
          leadId={
            ORGANISATION?.viewOrgValues?.leads_transactions_id
              ? ORGANISATION?.viewOrgValues?.leads_transactions_id
              : 0
          }
        />
      )} */}
      <TimeTracker
        page={"organisation"}
        id={ORGANISATION.viewOrgValues?.leads_transactions_id}
        stop={!props.visible || props.editModal}
      />
    </Drawer>
  );
});

export default ViewOrganisation;
