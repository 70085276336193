import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { ActionRenderer } from './GridComponent';
import LocalGridConfig from '../../../../../config/LocalGridConfig';
import useStore from '../../../../../store';
import { vsmCommon } from '../../../../../config/messages';
import { useTranslation } from 'react-i18next';
import { DateComparator } from '../../../../../utils/GlobalFunction';


const ListComponent = observer((props) => {
  const { t } = useTranslation();
  const { openEditModal, openDeleteModal, onCellKeyDown } = props;
  const {
    ORGROLESTORE,
    ORGROLESTORE: { list_data, getList, setupGrid, onFilterChanged },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('OrganisationManagement.Role')} ${t('Common_Data.Name')}`,
        field: 'role_name',

        width: 130,
      },
      {
        headerName: `${t('OrganisationManagement.No_Of_Organisations')}`,
        field: 'count_in_organisations_count',
        filter: 'agNumberColumnFilter',

      },
      {
        headerName: `${t('OrganisationManagement.Related_Roles')}`,
        headerTooltip: `${t('OrganisationManagement.Related_Roles')}`,
        field: 'associated_role_names',
        tooltipValueGetter: (params) => params.data &&
          params.data?.associated_role_names &&
          params.data?.associated_role_names.length > 0
          ? params.data?.associated_role_names.map((item, i) => {
            return item.role_name;
          })
          : null,
        valueGetter: (params) =>
          params.data &&
            params.data.associated_role_names &&
            params.data.associated_role_names.length > 0
            ? params.data.associated_role_names.map((item, i) => {
              return item.role_name;
            })
            : null,
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
      {
        headerName: `${t('Common_Data.Last_Updated_By')}`,
        field: 'updated_by',
        filter: 'agSetColumnFilter',

        filterParams: {
          defaultToNothingSelected: true,
        },
      },
      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        field: 'updated_at',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },

      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',

        width: 90,
        // lockPosition: true,
        // suppressNavigable: true
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',

        width: 100,
        lockPosition: true,
        suppressNavigable: true,
      },
    ],
  };

  useEffect(() => {
    getList();
  }, [getList]);

  let columns = [];
  let columnConfig = localStorage.getItem('OrgRole_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['associated_role_names'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }

    return item;
  })

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth : '',
      }}
    >
      <AgGridReact
        rowData={list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={LocalGridConfig.defaultColDef}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          openEditModal,
          openDeleteModal,
        }}
        onGridReady={setupGrid}
        gridOptions={{ ...LocalGridConfig.options, pagination: true, rowHeight: 30 }}
        onFilterChanged={onFilterChanged}
        //onSortChanged={onFilterChanged}
        onColumnResized={ORGROLESTORE.onGridChanged}
        onColumnMoved={ORGROLESTORE.onGridChanged}
        onColumnPinned={ORGROLESTORE.onGridChanged}
        //onFilterChanged={ORGROLESTORE.onFilterChanged}
        onSortChanged={ORGROLESTORE.onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        onCellKeyDown={onCellKeyDown}
      />
    </div>
  );
});

export default ListComponent;
