import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Spin } from 'antd';
import { vsmKnowledge } from '../../../config/messages';
import InputComponent from '../../../component/InputComponent';
import useStore from '../../../store';
import TinyComponent from '../../../component/TinyComponent';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(
  ({ handleSubmit, onChange, form, id, setContent, content }) => {
    const [fetchClassification, setFetchClassification] = useState(true);
    const [fetchGroup, setFetchGroup] = useState(true);

    const { KNOWLEDGE, AUTH } = useStore();
    const { t } = useTranslation();
    const onFinish = (data) => {
      handleSubmit(data);
    };
    const EditorConfig = {
      selector: 'textarea#full-featured-non-premium',
      plugins:
        'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars',
      menubar: false,
      quickbars_selection_toolbar: 'addKeyNote addEmailLink addSmsLink link unlink',
      contextmenu: false,
      height: 'calc(100vh - 358px)',
    };

    return (
      <Form
        form={form}
        id={id}
        labelCol={{ span: 24 }}
        className='innerForm'
        onFinish={onFinish}
      >
        <Row gutter={15}>
          <Col span={12}>
            <InputComponent
              name='subject'
              label={`${t('DealDesk.Knowledge')} ${t('Common_Data.Title')}`}
              required
              placeholder={`${t('DealDesk.Knowledge')} ${t('Common_Data.Title')}`}
              type='input'
              tooltip={`${t('Knowledge.Person_Discussed_Described')}.`}
              onChange={onChange}
              autoComplete='off'
              rules={vsmKnowledge.validation.subject}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              name='classification'
              label={<span
                className='converbillLink'
                onClick={() => {
                  window.open(
                    'https://www.convertibill.com/credebtclassifications-popup.html',
                    '_blank',
                    'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                  );
                }}
              >
                {t('Common_Data.Credebt_Classification')}
              </span>}
              placeholder={`${t('Common_Data.Select_Credebt_Classification')}`}
              type='select'
              tooltip={`${t('DealDesk.Industry_Tooltip')}`}
              required
              onChange={onChange}
              options={{
                values:
                  KNOWLEDGE.classification_list ||
                  (KNOWLEDGE.knowledgeValues &&
                    KNOWLEDGE.knowledgeValues.classification && [
                      {
                        id: KNOWLEDGE.knowledgeValues.classification,
                        name: KNOWLEDGE.knowledgeValues.classification_name,
                      },
                    ]),
                value_key: 'id',
                text_key: 'name',
              }}
              onFocus={() =>
                fetchClassification &&
                KNOWLEDGE.getClassificationList().then(() =>
                  setFetchClassification(false)
                )
              }
              notFoundContent={
                fetchClassification ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
              }
              rules={vsmKnowledge.validation.classification}
            />
          </Col>

          <Col span={12}>
            <InputComponent
              allowClear
              type='autocomplete'
              label={`${t('Knowledge.Group_Name')}`}
              name='knowledge_group'
              placeholder={`${t('DealDesk.Knowledge_Group')}`}
              onChange={onChange}
              options={KNOWLEDGE.knowledge_group_list}
              tooltip={`${t('Knowledge.Group_Name_Tooltip')}`}
              onFocus={() =>
                fetchGroup &&
                KNOWLEDGE.getKnowledgeGroupList().then(() => setFetchGroup(false))
              }
              notFoundContent={fetchGroup ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>

          <Col span={12}>
            <InputComponent
              name='knowledge_classification'
              label={`${t('DealDesk.Knowledge_Classification')}`}
              placeholder={`${t('DealDesk.Select_Knowledge_Classification')}`}
              type='select'
              allowSearch
              tooltip={`${t('DealDesk.Knowledge_Classification')}`}
              required
              onChange={onChange}
              options={{
                values: (AUTH.user && AUTH.user.knowledge_classification) || [],
                value_key: 'knowledge_classification',
                text_key: 'knowledge_classification',
              }}
              rules={vsmKnowledge.validation.knowledge_classification}
            />
          </Col>

          <Col span={24}>
            <InputComponent
              label={`${t('Common_Data.Description')}`}
              type='labelOnly'
              required
              tooltip={`${t('Knowledge.Knowledge_Description')}`}
            />

            <Form.Item className='editorInput'>
              <Form.Item
                name='description'
                noStyle
                onChange={onChange}
                rules={vsmKnowledge.validation.description}
              >
                <TinyComponent
                  EditorConfig={EditorConfig}
                  handleFormChange={onChange}
                  isKnowledge={true}
                  //initialValue={`<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`}
                  editorvalue={content}
                  form={form}
                  htmlName='description'
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
);

export default FormComponent;
