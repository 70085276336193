import React from "react";
import { observer } from "mobx-react";
import { Button, Col, Form, Row } from "antd";
import FormComponent from "../components/FormComponent";
import { useTranslation } from "react-i18next";

const Model = observer(({ changeTab }) => {
  const {t} = useTranslation();

  const [form] = Form.useForm()

  const handleSubmit = () => {
    changeTab('3');
  }

  return (
    <Form form={form} layout="vertical" id='DealDetails'
      className='innerForm DealDeskDeal' onFinish={handleSubmit} scrollToFirstError={true}>
      <FormComponent disabled={true} changeTab={changeTab} form={form} is_issued={false} />

      <Row gutter={0} className='mt-10'>
        <Col span={24} className='text-right border-top pt-15'>
          <Button
            shape='round'
            size='small'
            htmlType='submit'
          >
            {t('Common_Data.Next')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default Model;
