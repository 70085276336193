import React from 'react';
import { Tabs } from 'antd';
import Notes from './Notes';
import Key from './Key';
// import Schedule from './Schedule';
import Structure from './Structure';
import Deal from './Deal';
import useStore from '../../../store';
import { observer } from 'mobx-react';
import ScheduleNew from './NewSchedule';
import { useTranslation } from 'react-i18next';

const RightSideComponent = observer(() => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const {
    // AUTH,
    DEALDESKSTORE: {
      deal_data,
      dealdeskActiveTab,
      setDealdeskActiveTab,
      dealDeskTypeArr,
      statusPayloadForShortfall,
    },
    DEALDESKSTRUCTURELIBRARY: { setTabChanged },
    DEALDESKKEYNOTES: { fetchKeyNotes },
    SSSLEADLIBRARYSTORE: {
      fetchListData,
      scheduleAutoSaveData,
      screenAutosaveData,
      strucureAutosaveData,
      scrutiniseAutosaveData,
      setDealDeskRightSectionActiveTab,
    },
  } = useStore();

  const handleTabChange = (value) => {
    setDealdeskActiveTab(value);
    if (value === '3') {
      fetchKeyNotes(deal_data?.lead_information?.id);
    }
    if (value === '6' || value === '1' || value === '7') {
      if (value === '6' && !screenAutosaveData) {
        fetchListData(deal_data?.lead_information?.id);
      }
      if (value === '1' && !strucureAutosaveData) {
        fetchListData(deal_data?.lead_information?.id);
      }
      if (value === '7' && !scrutiniseAutosaveData) {
        fetchListData(deal_data?.lead_information?.id);
      }

      setTabChanged(value === '1' ? '3' : '4');
      setDealDeskRightSectionActiveTab(value === '6' ? 'screen' : value === '1' ? 'structure' : 'scrutinise');
    }
    if (value === '2' && !scheduleAutoSaveData) {
      fetchListData(deal_data?.lead_information?.id);
    }
  };

  return (
    <>
      <Tabs
        activeKey={dealdeskActiveTab}
        centered
        className='Dealdesk_MainTab newTabUI fiveColGap'
        onChange={handleTabChange}
      >

        <TabPane
          tab={`${t('Common_Data.Scrutinise')}`}
          key='7'
          disabled={
            dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
              ? true
              : false
          }
        >
          <Structure type='scrutinise' />
        </TabPane>
        <TabPane
          tab={statusPayloadForShortfall ? `${t('Common_Data.Shortfall')}` : `${t('DealDesk.Screen')}`}
          key='6'
          disabled={
            dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
              ? true
              : false
          }
        >
          <Structure type='screen' />
        </TabPane>

        <TabPane
          tab={`${t('Common_Data.Structure')}`}
          key='1'
          disabled={
            dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
              ? true
              : false
          }
        >
          <Structure type='structure' />
        </TabPane>
        <TabPane
          tab={`${t('DealDesk.Schedule')}`}
          key='2'
          disabled={
            dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
              ? true
              : false
          }
        >
          <ScheduleNew />
        </TabPane>

        <TabPane disabled={true}></TabPane>
        <TabPane tab={`${t('DealDesk.Key')}`} key='3'>
          <Key />
        </TabPane>

        <TabPane
          tab={`${t('DealDesk.Deal')}`}
          key='4'
          disabled={
            dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
              ? true
              : false
          }
        >
          <Deal />
        </TabPane>
        <TabPane
          tab={'Score'}
          key='8'
          disabled={true}
        >
        </TabPane>
        <TabPane tab={`${t('LeadListing.Notes')}`} key='5'>
          <Notes />
        </TabPane>
      </Tabs>
    </>
  );
});

export default RightSideComponent;
