import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';

import useStore from '../../../store';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import InputComponent from '../../../component/InputComponent';
import EditorFieldElement from './EditorFieldForm';
import { vsmTags, vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const EditViewComponent = observer((props) => {
  const { t } = useTranslation()
  const [content, setContent] = useState(null);
  const [holderChanged, setHolderChanged] = useState(false);
  const [fetchUsersList, setFetchUsersList] = useState(true);
  const [markAsCompleted, setmarkAsCompleted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [isRequestResetDeadline, setRequestResetDeadline] = useState(false);
  const [isRequestResetHour, setRequestResetHour] = useState(false);
  const [stopTimer, setStopTimer] = useState(false);


  const {
    visible,
    loadingEditView,
    editFromDealDeskNoteList,
    isFromTopNotification,
    TotalRecords,
    viewRowIndex,
    previousNextReminder,
    SearchPage,
  } = props;
  const [form] = Form.useForm();
  const location = useLocation();
  let history = useHistory();

  const {
    AUTH,
    AUTH: { fetchLeftmenuCount },
    TagsStore,
    TagsStore: {
      statusArr_list,
      viewValues,
      getUsersList,
      dropdown_user_list,
      savingMarkBtn,
      setSavingMarkBtn,
      setSavingApproveBtn,
      savingApproveBtn,
      editTag,
    },
    PRODUCTIVITYSTORE,
    SPANNOTESTORE,
    DEALDESKSTORE: { deal_data },
    DEALDESKNOTESEQUENCE: { fetchSequenceNotes },
    DEALDESKNOTESEQUENCE,
    DEALDESKSTORE,
    TimeTrackerStore
  } = useStore();

  const getStatusResult = (statusID) => {
    let resultStatus = statusArr_list.filter((e) => e.value === statusID).shift();
    return resultStatus?.text;
  };

  let Reopen = viewValues?.leads_notes_description?.notes_description?.includes('<ticketCompletedByHolder');
  let Decline = (
    viewValues?.leads_notes_description?.notes_description?.includes('<requestResetDeadline')
    || viewValues?.leads_notes_description?.notes_description?.includes('<requestForResetHoursRequired')
  );

  const setupTagGrid = () => {
    if (TagsStore.agGrid && !SearchPage) {
      TagsStore.setupGrid(TagsStore.agGrid);
    }
    if (TagsStore.isSearchPage) {
      TagsStore.getListSearch(TagsStore.search_payload)
    }
    fetchLeftmenuCount();
  };

  const close = () => {
    form.resetFields();
    setupTagGrid();
    setHolderChanged(false);
    setDisabled(true);
    props.close();
  };

  const prevNextCloseDrawerLogic = (res = {}, isMarkCompleted = false) => {
    form.resetFields();
    if (location.pathname === '/productivity') {
      PRODUCTIVITYSTORE.setupGrid(PRODUCTIVITYSTORE.agGrid);
    }
    if (editFromDealDeskNoteList) {
      close();
      let redirectURL = viewValues?.leads_transactions_id
        ? res?.approved_note_id
          ? `/dealdesk/${viewValues.leads_transactions_id}#n${res?.approved_note_id}`
          : `/dealdesk/${viewValues?.leads_transactions_id}`
        : '#nogo';

      if (deal_data?.lead_information?.id) {
        fetchSequenceNotes(deal_data?.lead_information?.id, null, false).then(() => {
          history.push(redirectURL);
        });
      }
    } else {
      if (isFromTopNotification) {
        if (isMarkCompleted) {
          setmarkAsCompleted(true);
        }
        if (SPANNOTESTORE.tagNoteList && SPANNOTESTORE.tagNoteList.length > 0) {
          let resultNewReminderList = SPANNOTESTORE.tagNoteList.filter(
            (x) => x.id !== viewValues.id
          );
          SPANNOTESTORE.tagNoteList = resultNewReminderList;
          SPANNOTESTORE.tagNote_count = SPANNOTESTORE.tagNote_count - 1;
        }

        if (viewRowIndex + 1 < TotalRecords) {
          previousNextReminder(2, viewRowIndex, true);
        } else {
          close();
        }
      } else {
        if (TagsStore.list_data && TagsStore.list_data.length > 0) {
          let newLatestArr = TagsStore.list_data.filter((x) => x.id !== viewValues.id);
          TagsStore.list_data = newLatestArr;
        }
        if (viewRowIndex + 1 < TotalRecords) {
          previousNextReminder(2, viewRowIndex - 1);
        } else {
          close();
        }
      }

      //-------------------START: Tag Edit From Dealdesk Note Tag Edit----------------------------//
      if (deal_data?.lead_information?.id) {
        fetchSequenceNotes(deal_data?.lead_information?.id, null, false);
      }
      //-------------------START: Tag Edit From Dealdesk Note Tag Edit----------------------------//
    }
  };

  const handleSubmit = (data) => {
    //setAnyTagUpdated(true);
    if (holderChanged) {
      data.tag_status = 4; //On Reassign Default Tag Status Returned;
      data.tag_status_id = 4;
    } else {
      data.holder = viewValues?.holder;
      data.tag_status_id = viewValues?.tag_status_id;
      data.tag_status = viewValues?.tag_status_id; //On Reassign Default Tag Status Returned;
    }
    data.organisation_id = viewValues?.global_organisation_id
      ? viewValues?.global_organisation_id
      : null;
    data.parent_ticket_id = viewValues?.parent_ticket_id
      ? viewValues?.parent_ticket_id
      : null;
    data.tag_content = data?.tag_content ? data?.tag_content : viewValues?.tag_content;
    data.tag_subject = viewValues.tag_subject;
    data.deadline_date = data.master_ticket?.deadline_date
      ? moment(data.master_ticket?.deadline_date).format('YYYY-MM-DD')
      : null;



    setSaving(true);
    editTag(viewValues.id, data)
      .then((res) => {
        prevNextCloseDrawerLogic(res);
        if (editFromDealDeskNoteList) {
          fetchSequenceNotes(viewValues?.leads_transactions_id && viewValues?.leads_transactions_id);
        }
        viewValues?.master_ticket !== null && DEALDESKSTORE.getDealDetail(viewValues?.master_ticket?.ticket_number);
        DEALDESKNOTESEQUENCE.fetchSequenceNotes(viewValues?.leads_transactions_id);
        vsmNotify.success({
          message: vsmTags.edit,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleMarkComleted = () => {
    let data = form.getFieldsValue();

    setSavingMarkBtn(true);
    //setAnyTagUpdated(true);

    let payload = {};
    payload.holder = viewValues?.holder;
    payload.tag_status = 3; //Mark completed
    payload.tag_status_id = 3; //Mark completed
    payload.organisation_id = viewValues?.global_organisation_id
      ? viewValues?.global_organisation_id
      : null;
    payload.parent_ticket_id = viewValues.parent_ticket_id
      ? viewValues.parent_ticket_id
      : null;
    payload.tag_content = data.tag_content;
    payload.tag_subject = viewValues.tag_subject;
    payload.deadline_date = viewValues.master_ticket?.deadline_date
      ? moment(viewValues.master_ticket?.deadline_date).format('YYYY-MM-DD')
      : null;
    payload.ticket_number = viewValues?.master_ticket?.ticket_number
      ? viewValues?.master_ticket?.ticket_number
      : null
    //payload.is_deadline_reset = false;
    if (viewValues?.leads_notes_description?.notes_description.includes('<requestResetDeadline')) {
      payload.is_deadline_reset = false;
      payload.change_ticket_status_to_close = true;
    }
    if (viewValues?.leads_notes_description?.notes_description.includes('<requestForResetHoursRequired')) {
      let getHours = viewValues?.tag_content.split(":")
      let hours_required_hour = getHours && getHours[0]
      let hours_required_min = getHours && getHours[1]
      if (hours_required_hour || hours_required_min) {
        let setTotalTime = (hours_required_hour && parseInt(hours_required_hour * 60)) + (hours_required_min && parseInt(hours_required_min))
        payload.hours_required = parseInt(setTotalTime) * 60
      }

      payload.reset_hours_required_to_false = false;
      payload.reset_hours = true;
    }

    /* If Clicked on Reopen and holder == Authenticated User*/
    if (Reopen && (viewValues.tag_holder.id === AUTH.user.id)) {
      payload.open_ticket = true;
    }

    editTag(viewValues.id, payload)
      .then((res) => {
        setStopTimer(true);
        viewValues?.master_ticket !== null && DEALDESKSTORE.getDealDetail(viewValues?.master_ticket?.ticket_number);
        DEALDESKNOTESEQUENCE.fetchSequenceNotes(viewValues?.leads_transactions_id);
        prevNextCloseDrawerLogic(res, true);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSavingMarkBtn(false);
      });
  };

  const handleApproved = () => {
    let data = form.getFieldsValue();


    setSavingApproveBtn(true);

    let requestResetDeadline = viewValues?.leads_notes_description?.notes_description?.includes('<requestResetDeadline');
    let requestForResetHoursRequired = viewValues?.leads_notes_description?.notes_description?.includes('<requestForResetHoursRequired');

    let payload = {};
    payload.holder = data.holder ? data.holder : viewValues?.holder;
    payload.tag_status = 5; //Approved
    payload.tag_status_id = 5; //Approved
    payload.organisation_id = viewValues?.global_organisation_id
      ? viewValues?.global_organisation_id
      : null;
    payload.parent_ticket_id = viewValues.parent_ticket_id
      ? viewValues.parent_ticket_id
      : null;
    payload.tag_content = data.tag_content;
    payload.tag_subject = viewValues.tag_subject;
    payload.change_ticket_status_to_close = false;

    payload.deadline_date =
      requestResetDeadline ? viewValues?.tag_content
        : data.master_ticket?.deadline_date
          ? moment(data.master_ticket?.deadline_date).format('YYYY-MM-DD')
          : viewValues.master_ticket?.deadline_date
            ? viewValues.master_ticket?.deadline_date
            : null;

    payload.ticket_number = viewValues?.master_ticket?.ticket_number
      ? viewValues?.master_ticket?.ticket_number
      : null

    if (viewValues?.leads_notes_description?.notes_description.includes('<ticketCompletedByHolder')) {
      payload.change_ticket_status_to_close = true;
    }
    if (requestForResetHoursRequired) {
      let getHours = viewValues?.tag_content.split(":")
      let hours_required_hour = getHours && getHours[0]
      let hours_required_min = getHours && getHours[1]
      if (hours_required_hour || hours_required_min) {
        let setTotalTime = (hours_required_hour && parseInt(hours_required_hour * 60)) + (hours_required_min && parseInt(hours_required_min))
        payload.hours_required = parseInt(setTotalTime) * 60
      }
      payload.reset_hours_required_to_false = true;
    }

    editTag(viewValues.id, payload)
      .then((res) => {
        setStopTimer(true);
        prevNextCloseDrawerLogic(res, true);
        if (editFromDealDeskNoteList) {
          fetchSequenceNotes(viewValues?.leads_transactions_id && viewValues?.leads_transactions_id);
        }
        viewValues?.master_ticket !== null && DEALDESKSTORE.getDealDetail(viewValues?.master_ticket?.ticket_number);
        DEALDESKNOTESEQUENCE.fetchSequenceNotes(viewValues?.leads_transactions_id);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSavingApproveBtn(false);
      });
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        if (
          form.getFieldValue('holder') !== undefined &&
          viewValues.holder !== form.getFieldValue('holder')
        ) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // let deadline_fulldate = '';
  // let deadline_format = AUTH.global_dateformat;

  // if (viewValues?.master_ticket?.deadline_date) {
  //   deadline_format += AUTH.global_timeformat
  //     ? ' ' + AUTH.global_timeformat
  //     : ' hh:mm:ss A';
  //   // deadline_fulldate += ' ';
  // }
  let deadline_fulldate = viewValues?.deadline_date;
  if (viewValues?.master_ticket?.deadline_date) {
    deadline_fulldate = viewValues?.master_ticket?.deadline_date;
  }
  useEffect(() => {
    if (stopTimer) {
      TimeTrackerStore.setLeadTransactionId(viewValues?.leads_transactions_id);
      setStopTimer(false);
    }
  }, [stopTimer, TimeTrackerStore, viewValues])
  useEffect(() => {
    if (viewValues) {
      form.setFieldsValue({
        holder: viewValues?.holder,
        // deadline_date: viewValues.deadline_date && moment(viewValues.deadline_date),
        tag_content: '',
      });
      setContent('');
    }

    if (viewValues?.leads_notes_description?.notes_description?.includes('<requestResetDeadline')) {
      setRequestResetDeadline(true)
    } else if (viewValues?.leads_notes_description?.notes_description?.includes('<requestForResetHoursRequired')) {
      setRequestResetHour(true)
    }
    else {
      setRequestResetDeadline(false)
      setRequestResetHour(false)
    }
    // if (viewValues?.leads_notes_description?.notes_description?.includes('<requestforresethoursrequired')) {
    //   setRequestResetHour(true)
    // } else {
    //   setRequestResetHour(false)
    // }

  }, [viewValues, form, TimeTrackerStore]);

  let updatedNoteData = viewValues?.leads_notes_description?.notes_description;
  let approvedTag = false;
  if (viewValues?.leads_notes_description?.notes_description?.includes('<approved')) {
    approvedTag = true;
    updatedNoteData = `Approved by ${viewValues?.owner} ${AUTH.getLocalTime(
      viewValues?.updated_at,
      'YYYY-MM-DD HH.mma'
    )}`;
  }
  let tagSubject = null;
  if (viewValues?.tag_subject) {
    let tagSubjectArr = viewValues?.tag_subject?.split(" | ");
    let trimTagSubject = [];
    tagSubjectArr.forEach((x) => {
      trimTagSubject.push(x.length > 20 ? `${x.slice(0, 20)}...` : x)
    })
    tagSubject = trimTagSubject.join(" | ");

    // if(tagSubjectArr.length >= 4 && (tagSubjectArr[0].length > 20 || tagSubjectArr[1].length > 20)){
    //   let splicedTagSubject = tagSubjectArr.splice(2,tagSubjectArr.length)
    //   let subject1 = tagSubjectArr[0].length > 20 && `${tagSubjectArr[0].slice(0,20)}...`;
    //   let subject2 = tagSubjectArr[1].length > 20 && `${tagSubjectArr[1].slice(0,20)}...`;
    //   tagSubjectArr = [subject1, subject2, ...splicedTagSubject]
    //   tagSubject = tagSubjectArr.join(" | ")
    // }
    // tagSubject = `${(tagSubjectArr[0].length > 20) ? `${tagSubjectArr[0].splice(0,20)}...` : tagSubjectArr[0]} | ${(tagSubjectArr[1].length > 20) ? `${tagSubjectArr[1].splice(0,20)}...` : tagSubjectArr[1]}`
    // let modifiedSubject = null;
    // tagSubjectArr.slice(0,1).forEach((x)=>{
    //   modifiedSubject = x.length > 20 ? x.slice(0,20)+"...: x;
    // })
  }


  // function DealTicketDeskIcon() {
  //   return (

  //   )
  // }

  return (
    <>
      {viewValues &&
        <Drawer
          visible={visible}
          onClose={close}
          placement='right'
          width={'1100px'}
          title={`${t('Common_Data.Edit')} ${t('Tags.Tag')} ${viewValues.tag_number ? ' - #' + viewValues.tag_number : ''}`}
          zIndex={1001}
          destroyOnClose={true}
          footer={
            <>
              <div className='d-flex' style={{ width: '100%' }} key="888">
                {props?.previousNextReminder && TotalRecords > 1 && (
                  <div className='text-left' style={{ flexGrow: '1' }} key='2'>
                    <Button
                      key='previous1'
                      className='mr-10'
                      type='primary'
                      shape='round'
                      disabled={
                        savingMarkBtn ||
                        (props.prevLoader
                          ? props.prevLoader
                          : viewRowIndex === 0
                            ? true
                            : false)
                      }
                      onClick={() => {
                        form.resetFields();
                        setStopTimer(true);
                        if (markAsCompleted) {
                          setmarkAsCompleted(false);
                          previousNextReminder(1, viewRowIndex - 1);
                        } else {
                          previousNextReminder(1, viewRowIndex);
                        }
                      }}
                    >
                      {t('Tags.Previous')}
                    </Button>
                    <Button
                      shape='round'
                      type='primary'
                      key='next2'
                      disabled={props.nextLoader || savingMarkBtn}
                      onClick={() => {
                        setStopTimer(true);
                        form.resetFields();
                        previousNextReminder(2, viewRowIndex);
                      }}
                    >
                      {t('Common_Data.Next')}
                    </Button>
                  </div>
                )}

                <div className='text-right' style={{ flexGrow: '1' }} key='1'>
                  {!loadingEditView && (
                    <>
                      {approvedTag ? (
                        <>
                          {viewValues.tag_status_id &&
                            ![5, 3].includes(viewValues.tag_status_id) &&
                            viewValues?.holder &&
                            viewValues.holder === AUTH.user.id && (
                              <Button
                                key='markcompleted3'
                                className='mr-10 markAsCompletedTag'
                                type='primary'
                                shape='round'
                                loading={savingMarkBtn}
                                onClick={() => {
                                  handleMarkComleted();
                                }}
                              >
                                {t('Reports.Completed')}
                              </Button>
                            )}
                        </>
                      ) : (
                        <>
                          {viewValues.tag_status_id &&
                            ![5, 3].includes(viewValues.tag_status_id) &&
                            viewValues?.holder &&
                            viewValues.holder === AUTH.user.id && (
                              <Button
                                key='approve5'
                                className='mr-10 markAsReadTag'
                                type='primary'
                                shape='round'
                                loading={savingApproveBtn}
                                onClick={() => {
                                  handleApproved();
                                }}
                              >
                                {t('Tags.Approved')}
                              </Button>
                            )}
                          {viewValues.tag_status_id &&
                            ![5, 3].includes(viewValues.tag_status_id) &&
                            viewValues?.holder &&
                            viewValues.holder === AUTH.user.id && (
                              <Button
                                key='markcompleted3'
                                className='mr-10 markAsCompletedTag'
                                type='primary'
                                shape='round'
                                loading={savingMarkBtn}
                                onClick={() => {
                                  handleMarkComleted();
                                }}
                              >
                                {Reopen
                                  ? t('Reports.Reopen')
                                  : Decline ? t('Reports.Decline')
                                    : t('Reports.Completed')
                                }
                              </Button>
                            )}

                            {viewValues.tag_status_id &&
                              ![5, 3].includes(viewValues.tag_status_id) && (
                                <>
                                  <Button
                                    key='reassign4'
                                    form='tageditform'
                                    className='mr-10'
                                    htmlType='submit'
                                    type='primary'
                                    shape='round'
                                    loading={saving}
                                    disabled={isDisabled}
                                  onClick={() => setStopTimer(true)}
                                >
                                  {`${t('Common_Data.Reassign')}`}
                                </Button>
                              </>
                            )}
                        </>
                      )}
                    </>
                  )}

                  <Button shape='round' onClick={close} key='2'>
                    {`${t('Common_Data.Cancel')}`}
                  </Button>
                </div>
              </div>

              {viewValues?.master_ticket
                ? <Link key={998}
                  to={`/ticket/${viewValues?.master_ticket?.ticket_number}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    className='menuicon'
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '15px',
                      right: '20px',
                      width: '20px',
                    }}
                    alt=''
                    title={`${t('Common_Data.View')} ${t('Tags.Chat')} ${t('Tags.History')} ${t('Common_Data.Tags')}`}
                    src={AUTH.GetThemedImage('Tickets')}
                  />
                </Link>
                : <Link key={999}
                  to={
                    viewValues?.leads_transactions_id
                      ? viewValues?.leads_notes_id
                        ? `/dealdesk/${viewValues.leads_transactions_id}#n${viewValues?.leads_notes_id}`
                        : `/dealdesk/${viewValues?.leads_transactions_id}`
                      : '#nogo'
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    className='menuicon'
                    style={
                      viewValues?.leads_transactions_id
                        ? {
                          cursor: 'pointer',
                          position: 'absolute',
                          top: '15px',
                          right: '20px',
                          width: '20px',
                        }
                        : {
                          opacity: '0.5',
                          cursor: 'not-allowed',
                          position: 'absolute',
                          top: '15px',
                          right: '20px',
                          width: '20px',
                        }
                    }
                    alt=''
                    title={`${t('Common_Data.View')} ${t('Tags.Chat')} ${t('Tags.History')} ${t('Common_Data.Tags')}`}
                    src={AUTH.GetThemedImage('Deal_icn')}
                  />
                </Link>
              }
            </>}
        >
          <Spin size='large' spinning={loadingEditView} tip={'Loading'}>
            <div className='viewPeople' key='1'>
              <Form
                form={form}
                labelCol={{ span: 24 }}
                id='tageditform'
                onFinish={handleSubmit}
                className='innerForm'
              >
                <table cellPadding='0' cellSpacing='0' border='0'>
                  <tbody>
                    <tr>
                      <td width='20%' style={{ border: '0' }}></td>
                      <td width='30%' style={{ border: '0' }}></td>
                      <td width='20%' style={{ border: '0' }}></td>
                      <td width='30%' style={{ border: '0' }}></td>
                    </tr>
                    <tr>
                      <th>{t('Common_Data.Subject')}:</th>
                      <td colSpan='3'>
                        <Tooltip title={viewValues?.tag_subject}>
                          {tagSubject}
                        </Tooltip>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Common_Data.Organisation')}:</th>
                      <td colSpan='3'>{viewValues?.organisation?.organisation_name}</td>
                    </tr>
                    <tr>
                      <th>{t('Tags.Tag')} {t('Common_Data.Number')}:</th>
                      <td>{viewValues?.tag_number}</td>
                      <th>{t('Tags.Ticket')} {t('Common_Data.Number')}:</th>
                      <td>
                        {viewValues?.master_ticket ? (
                          <span className='cursorPointer' onClick={() => {
                            window.open((viewValues?.master_ticket
                              ? `/ticket/${viewValues?.master_ticket?.ticket_number}`
                              : '#nogo'), '_blank')
                          }}>
                            {viewValues?.master_ticket?.ticket_number}{" "}
                            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Tickets')} style={{ width: "14px", marginTop: "-4px" }} />
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th>{`${t('Common_Data.Owner')}`}:</th>
                      <td>{viewValues?.created_by_name}</td>
                      <th>{`${t('DealDesk.Holder')}`}:</th>
                      <td>
                        <InputComponent
                          name='holder'
                          label=''
                          type='select'
                          allowClear
                          showSearch={true}
                          placeholder={`${t('Common_Data.Select')} ${t('Common_Data.User')}`}
                          tooltip={`${t('Tags.Holder_Tooltip')}.`}
                          onChange={() => {
                            handleChange();
                            setHolderChanged(true);
                          }}
                          onFocus={() =>
                            fetchUsersList &&
                            getUsersList().then(() => setFetchUsersList(false))
                          }
                          options={{
                            values:
                              dropdown_user_list ||
                              (viewValues?.tag_holder && [
                                {
                                  id: viewValues.holder,
                                  name:
                                    viewValues.tag_holder.first_name +
                                    ' ' +
                                    viewValues.tag_holder.last_name,
                                },
                              ]),
                            value_key: 'id',
                            text_key: 'name',
                          }}
                          notFoundContent={fetchUsersList ? <Spin size='small' /> : null}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Common_Data.Status')}:</th>
                      <td>{getStatusResult(viewValues?.tag_status_id)}</td>
                      <th>{`${t('DealDesk.Deadline_Date')}`}:</th>
                      <td>
                        {/* <InputComponent
                        name='deadline_date'
                        label=''
                        placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Date')}`}
                        required
                        type='date'
                        tooltip={`${t('Tags.Reminder_Due_Date_Tooltip')}.`}
                        onChange={handleChange}
                        format={'YYYY-MM-DD'}
                        rules={
                          holderChanged
                            ? [{ required: false }] //vsmTags.validation.deadline_date
                            : [{ required: false }]
                        }
                      /> */}
                        {deadline_fulldate
                          // ? moment(deadline_fulldate).format(deadline_format)
                          ? AUTH.getLocalTime(deadline_fulldate, AUTH.global_fulldate_format)
                          : ''}
                      </td>
                    </tr>
                    {/* <tr>
                        <th colSpan='4'>Description:</th>
                      </tr>
                      <tr>
                        <td
                          colSpan='4'
                          dangerouslySetInnerHTML={{
                            __html: viewValues.tag_content?.replace(
                              /href/g,
                              "target='_blank' href"
                            ),
                          }}
                        ></td>
                      </tr> */}

                    <tr>
                      <th colSpan='4'>{t('Tags.Tag')} {t('Tags.Response')}:</th>
                    </tr>
                    <tr>
                      <td colSpan='4'>
                        {/* <InputComponent
                          name='tag_content'
                          label=''
                          type='textarea'
                          tooltip='Tag Response'
                          onChange={handleChange}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          rules={vsmTags.validation.tag_response}
                        /> */}
                        <EditorFieldElement
                          form={form}
                          handleChange={handleChange}
                          id='tageditform'
                          content={content}
                          setContent={setContent}
                          visible={props.visible}
                        />
                      </td >
                    </tr >

                    <tr>
                      <th colSpan='4'>{t('Tags.Record')} {t('Tags.Tag')} ({t('Tags.Or')} {t('Common_Data.Previous')} {t('Common_Data.Chat')} {t('Common_Data.History')})</th>
                    </tr>
                    <tr>
                      <td colSpan='4' className='messageDisplay'>
                        {isRequestResetDeadline
                          ? <>
                            {`${viewValues?.created_by_name} requested new deadline : `}
                            <span style={{ margin: 0, padding: 0 }} dangerouslySetInnerHTML={{ __html: viewValues?.leads_notes_description?.notes_description }}></span> {" "}
                          </>
                          : isRequestResetHour
                            ? <>
                              {`${viewValues?.created_by_name} requested change in Time Required : `}
                              <span style={{ margin: 0, padding: 0 }} dangerouslySetInnerHTML={{ __html: viewValues?.leads_notes_description?.notes_description }}></span> {" "}
                            </>
                            : <div dangerouslySetInnerHTML={{ __html: updatedNoteData?.replace(/href/g, "target='_blank' href"), }}></div>
                        }

                      </td>
                    </tr>
                  </tbody >
                </table >
              </Form >
            </div >
            {/* <TrackTimeComponent
            pageName={'tags'}
            leadId={
              viewValues?.leads_transactions_id ? viewValues?.leads_transactions_id : 0
            }
          /> */}
          </Spin >
        </Drawer >
      }
      <TimeTracker
        page={"tags"}
        id={viewValues?.leads_transactions_id}
        stop={!visible || stopTimer}
        fromSideBar={props?.fromSideBar}
      />
    </>
  );
});

export default EditViewComponent;
