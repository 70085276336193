import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { Checkbox, Col, Divider, Form, Row } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { useTranslation } from 'react-i18next';

const SummaryTicketsElement = observer(({ onChange, formDataList, form }) => {
  const {
    TicketsStore: { dropdown_ticketstatus, getTicketstatusList },
  } = useStore();
  const { t } = useTranslation();
  const [fetchTicketstatus, setFetchTicketstatus] = useState(true);

  // --------  UseEffect added for Ticket Status API Call ------ //
  useEffect(() => {
    if (form.getFieldValue('ticket_status') && fetchTicketstatus) {
      getTicketstatusList()
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          setFetchTicketstatus(false);
        });
    }
  }, [form, fetchTicketstatus, getTicketstatusList]);

  // --------  UseEffect added for Ticket Status API Call ------ //

  return (
    <>
      <Row gutter={12} align='middle' className='mb-5'>
        <Col span={24}>
          <Divider orientation='left' orientationMargin='0' plain>
            {t('Common_Data.Tickets')}
          </Divider>
        </Col>

        <Col span={24}>
          <Row gutter={12} align='middle'>
            {formDataList.includes('ticket_sum_total') && (
              <Col span={4}>
                <Form.Item
                  className='m-0'
                  name='ticket_sum_total'
                  valuePropName='checked'
                >
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Total')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('ticket_created') && (
              <Col span={4}>
                <Form.Item className='m-0' name='ticket_created' valuePropName='checked'>
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Created')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('ticket_assigned') && (
              <Col span={4}>
                <Form.Item className='m-0' name='ticket_assigned' valuePropName='checked'>
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Assigned')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('sum_by_ticket_status') && (
              <Col span={4}>
                <Form.Item
                  className='m-0'
                  name='sum_by_ticket_status'
                  valuePropName='checked'
                >
                  <Checkbox onChange={onChange}>{t('Reports.Sum_by_Status')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('ticket_status') && (
              <Col span={8}>
                <InputComponent
                  rowclassName='m-0'
                  name='ticket_status'
                  mode='multiple'
                  type='select'
                  allowClear
                  showArrow
                  placeholder={t('Productivity.Ticket_Status')}
                  onFocus={() =>
                    fetchTicketstatus &&
                    getTicketstatusList().then(() => setFetchTicketstatus(false))
                  }
                  showSearch={false}
                  tooltip={`${t('Reports.People_Status_Tooltip')}`}
                  onChange={onChange}
                  options={{
                    values: dropdown_ticketstatus,
                    value_key: 'id',
                    text_key: 'status_name',
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
});

export default SummaryTicketsElement;
