import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Form, Space, Select } from 'antd';
import useStore from '../../store';
import ViewComponent from './component/ViewComponent';
import ListComponent from './component/ListComponent';
import Unauthorized from '../../page/commonpage/Unauthorized';
import RecordPerPage from '../../config/RecordPerPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../component/EnterFullscreen';
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../component/DatePickerCostume';


const AuditLogs = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    AUDITLOG,
    SETTINGS,
    AUDITLOG: { userList, exportData, getUserList, per_page, setPageSize },
  } = useStore();

  const [form] = Form.useForm();
  const [id, setId] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);

  const [selectFilter, setSelectFilter] = useState(false);

  const [viewDrawer, setViewDrawer] = useState(false);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  const setAuditId = (data) => setId(data);

  // Open form for View audit log
  const openViewDrawer = (data) => {
    setAuditId(data);
    setViewDrawer(true);
  };

  // Open form for View audit log
  const closeViewDrawer = () => setViewDrawer(false);

  return (
    <>
      {!AUTH.checkPermission(31, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={`${t('Audit_Logs.Audit_Logs')}`}
          className={'page-title ' + SETTINGS.fullscreen_class}
          extra={[
            <>
              <Form
                form={form}
                id='SettingForm'
                className='innerForm headerButton'
                labelCol={{ span: 24 }}
              >
                <Space align='center' size={5}>
                  <Select
                    placeholder={`${t('Audit_Logs.Choose_User')}`}
                    className='mr-10 mt-5'
                    onChange={setSelectFilter}
                    showSearch
                    allowClear
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      option.children &&
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 140 }}
                  >
                    {userList &&
                      userList.map((item, index) => {
                        return (
                          <Select.Option value={item.id} key={index}>
                            {item.first_name + ' ' + item.last_name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                  <DatePickerCostume
                    disabledDate={function disabledDate(current) {
                      return current && current.valueOf() > Date.now();
                    }}
                    key='3'
                    onChange={setDateFilter}
                    placeholder={`${t('Audit_Logs.Select_From_Date')}`}
                  />
                  {AUTH.checkPermission(31, 'export-csv') && (
                    <Button key='4' shape='round' title={`${t('Audit_Logs.Profile')}`} onClick={exportData}>
                      {t('Audit_Logs.Export_CSV')}
                    </Button>
                  )}
                  <Button
                    key='5'
                    shape='round'
                    size='medium'
                    title={`${t('Audit_Logs.Reset')}`}
                    onClick={AUDITLOG.handleReset}
                  >
                    <FontAwesomeIcon icon={faUndo} />
                  </Button>
                  <EnterFullscreen key='7' />
                  <RecordPerPage
                    key='6'
                    initialValue={per_page + 'per page'}
                    onChange={setPageSize}
                    shape='round'
                    size='medium'
                  />
                </Space>
              </Form>
            </>,
          ]}
        >
          <ListComponent
            dateFilter={dateFilter}
            selectFilter={selectFilter}
            openViewDrawer={openViewDrawer}
          />
          <ViewComponent
            id={id}
            setId={setAuditId}
            visible={viewDrawer}
            close={closeViewDrawer}
          />
        </PageHeader>
      )}
      {/* <TrackTimeComponent pageName={'audit_logs'} /> */}
      <TimeTracker />
    </>
  );
});

export default AuditLogs;
