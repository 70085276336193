import { Button, Result } from "antd"
import { useHistory } from "react-router-dom"
import React from 'react'
import { useTranslation } from "react-i18next"

const Unauthorized = (props) => {
	const { t } = useTranslation();
	const history = useHistory()
	return (
		<Result
			status="403"
			title={props.title ? props.title : `${t('Common_Page.Not_Allow')}`}
			subTitle={props.subtitle ? props.subtitle : `${t('Common_Page.Not_Allow_Use')}`}
			extra={(props.goback === undefined || props.goback) && (
				<Button type="primary" onClick={() => history.goBack()}>{t('Common_Page.Go_Back')}</Button>
			)}
		/>
	)
}

export default Unauthorized
