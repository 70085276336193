import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import ListComponent from '../../PeopleManagement/People/component/ListComponent';
import ViewComponent from '../../PeopleManagement/People/component/ViewComponent';
import DeleteComponent from '../../PeopleManagement/People/component/DeleteComponent';
import RestoreComponent from './RestoreComponent';
import { useTranslation } from 'react-i18next';

const People = observer((props) => {
  const { WasteManagement } = props;
  const { PEOPLE, WASTESTORE, TimeTrackerStore } = useStore();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [restoreValues, setRestoreValues] = useState(false);
  const { t } = useTranslation()
  // Open & Close Restore Model function
  const openRestoreModal = (data) => {
    setRestoreValues(data)
    setRestoreModal(true);
  };

  const closeRestoreModal = () => setRestoreModal(false);

  // Open & Close Delete Model function
  const openDeleteModal = (data) => {
    setDeleteModal(true);
    PEOPLE.setDeleteValues(data);
  };

  const closeDeleteModal = () => setDeleteModal(false);

  // Open & Close View Drawer function
  const openViewDrawer = (data) => {
    PEOPLE.setViewValues(data);
    PEOPLE.getViewPplDetail(data.id)
      .then((data) => { })
      .catch((data) => {
        if (data.status === 404) {
        }
        if (data.status === 403) {
        }
      });
    setViewDrawer(true);
  };

  const closeViewDrawer = () => {
    //PEOPLE.setViewValues([]);
    setViewDrawer(false);
  };

  useEffect(() => {
    if (viewDrawer) {
      TimeTrackerStore.setStopForWaste(true);
    } else {
      TimeTrackerStore.setStopForWaste(false);
    }
  }, [TimeTrackerStore, viewDrawer]);

  return (
    <>
      <ListComponent
        WasteManagement={WasteManagement}
        openViewDrawer={openViewDrawer}
        openDeleteModal={openDeleteModal}
        openRestoreModal={openRestoreModal}
      />
      <ViewComponent visible={viewDrawer} close={closeViewDrawer} waste={true} />
      <DeleteComponent visible={deleteModal} close={closeDeleteModal} getWasteCount={WASTESTORE.getWasteCount} lineText={`${t('Waste.Permanently_Remove_Record')}?`} />
      <RestoreComponent visible={restoreModal} close={closeRestoreModal} store={PEOPLE} getWasteCount={WASTESTORE.getWasteCount} id={"restorepplform"}
        currentValues={restoreValues} lineText={`${t('Common_Data.People')}`} title={`${t('Common_Data.Restore')} ${t('Common_Data.People')} - #${restoreValues && restoreValues.id} - ${restoreValues && restoreValues.people_name}`} />
    </>
  );
});

export default People;
