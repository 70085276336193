import React, { useCallback, useEffect } from 'react';
import { Spin, Tooltip, Button } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const NoteTagSelection = observer((props) => {
  const { t } = useTranslation()
  const {
    DEALDESKSTORE,
    DEALDESKSTORE: { deal_data },
    DEALDESKNOTESEQUENCE: { fetchSequenceNotes },

    RIGHTSECTTION_TAGSTICKETS: { fetchTagsTicketData },
  } = useStore();

  const handleKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey || event.altKey) {
        return false;
      }
      if (event.keyCode === 13) {
        DEALDESKSTORE.addTag(
          props.msgBoxArray.get,
          props.formTagUser.join(),
          '',
          props.formTagGroup.join() //isGroup To Tag By Group
        );
        fetchSequenceNotes(deal_data?.lead_information?.id);
        fetchTagsTicketData(deal_data?.lead_information?.id);
        return false;
      }

      let keyPressed = event.key;
      if (event.shiftKey) {
        let resultGroup =
          props.tagsUsers &&
          props.tagsUsers.userGroupList
            .filter(
              (x) =>
                !props.formTagGroup.includes(x.id) &&
                x.group_name.charAt(0) === keyPressed.toUpperCase()
            )
            .shift();

        if (resultGroup !== undefined) {
          if (props.formTagGroup && props.formTagGroup.length > 0) {
            let allMatchedGroupIDs = [];
            let selectedGroupIndex = 0;
            props.tagsUsers &&
              props.tagsUsers.userGroupList.forEach((x, groupIndex) => {
                if (x.group_name.charAt(0) === keyPressed.toUpperCase()) {
                  allMatchedGroupIDs.push(x.id);
                  if (props.formTagGroup.includes(x.id)) {
                    selectedGroupIndex = groupIndex;
                  }
                }
              });

            if (allMatchedGroupIDs && allMatchedGroupIDs.length > 1) {
              if (selectedGroupIndex) {
                resultGroup = props.tagsUsers.userGroupList[selectedGroupIndex + 1];
                if (resultGroup) {
                  if (resultGroup.group_name.charAt(0) !== keyPressed.toUpperCase()) {
                    resultGroup =
                      props.tagsUsers &&
                      props.tagsUsers.userGroupList
                        .filter((x) => x.id === allMatchedGroupIDs[0])
                        .shift();
                  }
                }
              }

              let latestArry = [];
              props.formTagGroup.forEach((selectedGroup) => {
                if (allMatchedGroupIDs.includes(selectedGroup)) {
                } else {
                  latestArry.push(selectedGroup);
                }
              });

              props.setFormTagGroup([...latestArry, resultGroup.id]);
            } else {
              if (props.formTagGroup.length > 0) {
                props.setFormTagGroup([...props.formTagGroup, resultGroup.id]);
              } else {
                props.setFormTagGroup([resultGroup.id]);
              }
            }
          } else {
            props.setFormTagGroup([resultGroup.id]);
          }
        } else {
          /**TO CLEAR SINGLE CHARCTER SELECTED VALUE */
          if (props.formTagGroup && props.formTagGroup.length > 0) {
            let resultClear =
              props.tagsUsers &&
              props.tagsUsers.userGroupList
                .filter((x) => x.group_name.charAt(0) === keyPressed.toUpperCase())
                .shift();

            if (resultClear) {
              let latestArry = [];
              props.formTagGroup.forEach((gid) => {
                if (resultClear.id !== gid) {
                  latestArry.push(gid);
                }
              });
              props.setFormTagGroup(latestArry);
            }
          }
        }
      } else {
        let result =
          props.tagsUsers &&
          props.tagsUsers.userList
            .filter(
              (x) =>
                !props.formTagUser.includes(x.id) &&
                x.name.charAt(0) === keyPressed.toUpperCase()
            )
            .shift();

        if (result !== undefined) {
          if (props.formTagUser && props.formTagUser.length > 0) {
            let allMatchedUserIDs = [];
            let selectedUserIndex = 0;
            props.tagsUsers &&
              props.tagsUsers.userList.forEach((x, groupIndex) => {
                if (x.name.charAt(0) === keyPressed.toUpperCase()) {
                  allMatchedUserIDs.push(x.id);
                  if (props.formTagUser.includes(x.id)) {
                    selectedUserIndex = groupIndex;
                  }
                }
              });

            if (allMatchedUserIDs && allMatchedUserIDs.length > 1) {
              if (selectedUserIndex) {
                result = props.tagsUsers.userList[selectedUserIndex + 1];
                if (result) {
                  if (result.name.charAt(0) !== keyPressed.toUpperCase()) {
                    result =
                      props.tagsUsers &&
                      props.tagsUsers.userList
                        .filter((x) => x.id === allMatchedUserIDs[0])
                        .shift();
                  }
                }
              }

              let latestArry = [];
              props.formTagUser.forEach((selectedGroup) => {
                if (allMatchedUserIDs.includes(selectedGroup)) {
                } else {
                  latestArry.push(selectedGroup);
                }
              });

              props.setFormTagUser([...latestArry, result.id]);
            } else {
              if (props.formTagUser.length > 0) {
                props.setFormTagUser([...props.formTagUser, result.id]);
              } else {
                props.setFormTagUser([result.id]);
              }
            }
          } else {
            props.setFormTagUser([result.id]);
          }
        } else {
          /**TO CLEAR SINGLE CHARCTER SELECTED VALUE */
          if (props.formTagUser && props.formTagUser.length > 0) {
            let resultClear =
              props.tagsUsers &&
              props.tagsUsers.userList
                .filter((x) => x.name.charAt(0) === keyPressed.toUpperCase())
                .shift();

            if (resultClear) {
              let latestArry = [];
              props.formTagUser.forEach((uid) => {
                if (resultClear.id !== uid) {
                  latestArry.push(uid);
                }
              });
              props.setFormTagUser(latestArry);
            }
          }
        }
      }
    },
    [props, deal_data, DEALDESKSTORE, fetchSequenceNotes, fetchTagsTicketData]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return props.tagsUsers ? (
    <div className='ant-popover-inner-content multipleTag'>
      <div className='leadStatusOptins lockUserList'>
        <ul>
          {props.tagsUsers && props.tagsUsers.userGroupList && (
            <li key='1'>
              <span style={{ padding: '0 0 0 8px' }}>{t('DealDesk.Users_Group')}</span>
              <ul className={'dealDeskTagSelection'}>
                {props.tagsUsers.userGroupList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        props.formTagGroup && props.formTagGroup.includes(item.id)
                          ? 'active'
                          : ''
                      }
                      onClick={async () => {
                        if (props.formTagGroup && props.formTagGroup.length > 0) {
                          if (props.formTagGroup.includes(item.id)) {
                            const result = props.formTagGroup.filter(
                              (x) => x !== item.id
                            );
                            props.setFormTagGroup(result);
                          } else {
                            props.setFormTagGroup([...props.formTagGroup, item.id]);
                          }
                        } else {
                          props.setFormTagGroup([item.id]);
                        }
                      }}
                    >
                      <Tooltip title={item.group_name}>{item.group_name}</Tooltip>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}
          {props.tagsUsers && props.tagsUsers.userList && (
            <li key='2'>
              <span style={{ padding: '0 0 0 8px' }}>{t('DealDesk.Users_List')}</span>
              <ul className={'dealDeskTagSelection'}>
                {props.tagsUsers.userList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        // (props.msgBoxArray &&
                        //   props.msgBoxArray.get &&
                        //   props.msgBoxArray.get.length > 0 &&
                        //   (msgBoxIndex || msgBoxIndex === 0) &&
                        //   props.msgBoxArray.get[msgBoxIndex] &&
                        //   props.msgBoxArray.get[msgBoxIndex]['tag_user_id'] !==
                        //     undefined &&
                        //   props.msgBoxArray.get[msgBoxIndex].tag_user_id &&
                        //   props.msgBoxArray.get[msgBoxIndex].tag_user_id.includes(
                        //     item.id
                        //   )) ||
                        props.formTagUser && props.formTagUser.includes(item.id)
                          ? 'active'
                          : ''
                      }
                      onClick={async () => {
                        if (props.formTagUser && props.formTagUser.length > 0) {
                          if (props.formTagUser.includes(item.id)) {
                            const result = props.formTagUser.filter((x) => x !== item.id);
                            props.setFormTagUser(result);
                          } else {
                            props.setFormTagUser([...props.formTagUser, item.id]);
                          }
                        } else {
                          props.setFormTagUser([item.id]);
                        }
                      }}
                    >
                      <Tooltip title={item.name}>{item.name}</Tooltip>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}
        </ul>
        <div className='multipleTagBtn'>
          <Button
            size='medium'
            shape='round'
            onClick={async () => {
              await DEALDESKSTORE.addTag(
                props.msgBoxArray.get,
                props.formTagUser.join(),
                '',
                props.formTagGroup.join() //isGroup To Tag By Group
              );

              if (deal_data?.lead_information?.id) {
                await setTimeout(() => {
                  fetchSequenceNotes(deal_data?.lead_information?.id);
                  fetchTagsTicketData(deal_data?.lead_information?.id);
                  // To update last updated date in  headers with header api
                  DEALDESKSTORE?.getDealDetail(deal_data?.lead_information?.id);
                }, 1000);
              }
              props.closeTagPopover();
            }}
          >
            {t('Common_Data.Save')}
          </Button>
          <Button
            size='medium'
            shape='round'
            onClick={() => {
              props.closeTagPopover();
            }}
          >
            {t('Common_Data.Cancel')}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className='ant-popover-inner-content'>
      <div className='leadStatusOptins lockUserList'>
        <ul className='popover-menu cursorPointer spinInside'>
          <Spin tip={`${t('Common_Data.Load')}...`} />
        </ul>
      </div>
    </div>
  );
});
export default NoteTagSelection;
