import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Button, Drawer, PageHeader } from 'antd';

import useStore from '../../../../../store';
import AddComponent from './AddComponent';
import EditComponent from './EditComponent';
import LibraryListGrid from './LibraryListGrid';
import StructureLibraryView from './StructureLibraryView';
import DeleteComponent from './DeleteComponent';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ResetGridOptions from '../../../../../component/ResetGridOptions';
import TimeTracker from '../../../../../component/TimeTracker';

const StructureLibrary = observer((props) => {
  const { t } = useTranslation();
  const [addDrawer, setAddDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [id, setID] = useState(null);
  const [openScheduleLibraryDrawer, setOpenScheduleLibraryDrawer] = useState(false);
  const [structureData, setStructureData] = useState(null);
  const [libData, setlibData] = useState(null);
  const [selectedLibraryItemNode, setSelectedLibraryItemNode] = useState([]);
  // const [agGrid, setAgGrid] = useState({}); // To store & update the agGird ref

  const {
    DEALDESKSTORE: { deal_data },
    // SSSLEADLIBRARYSTORE,
    SSSLEADLIBRARYSTORE: {
      screenLibrary,
      scrutiniseLibrary,
      structureLibrary,
      shortfallLibrary,
      fetchListData,
      fetchLibraryByType,
      // handleResetScreen,
      // handleResetStructure,
      // handleResetScrutinise,
      // handleResetShortfall,
    },
    DEALDESKSTRUCTURELIBRARY: {
      //savestructureform,
      setEditValues,
      SetStructure,
      SetScrunitise,
      setCopiedStructureData,
      setCopiedScreenData,
      setCopiedScrunitiseData,
      setCopiedShortfallData,
      SetScreen,
      Screen,
      Structure,
      Scrunitise,
      SetShortfall,
      Shortfall,
    },
  } = useStore();

  const { DealLibrary = false, saveType, visible, close } = props;

  //-----------------OPEN-CLOSE ADD DRAWER--------------//
  const openAddDrawer = () => setAddDrawer(true);
  const closeAddDrawer = () => setAddDrawer(false);

  //-----------------OPEN-CLOSE EDIT DRAWER--------------//
  const openEditDrawer = (data) => {
    setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => setEditDrawer(false);

  //-----------------OPEN-CLOSE VIEW LIBRARY--------------//
  const viewScheduleLibraryDrawer = async (data) => {
    setStructureData(data);
    await setOpenScheduleLibraryDrawer(true);
  };

  const closeScheduleLibraryDrawer = () => {
    setStructureData(null);
    setOpenScheduleLibraryDrawer(false);
  };

  //-----------------OPEN-CLOSE DELETE CONFIRMATION--------------//
  const openDeleteDrawer = (data) => {
    setlibData(data);
    setDeleteDrawer(true);
    setID(data.id);
  };

  const closeDeleteDrawer = () => setDeleteDrawer(false);


  //-----------------COPY LIBRARY FUNCTION --------------//
  const tempcopyStructure = (data, isCheckboxSelection = false) => {
    if (isCheckboxSelection) {
      let newDescriptionTemp = [];

      let parentKey = 1;
      let Position = 1;
      if (saveType === 'screen') {
        parentKey =
          Screen && Screen.length > 0
            ? parseInt(Screen[Screen.length - 1]['item_key']) + 1
            : 1;
        Position =
          Screen && Screen.length > 0
            ? parseInt(Screen[Screen.length - 1]['position']) + 1
            : 1;
      } else if (saveType === 'structure') {
        parentKey =
          Structure && Structure.length > 0
            ? parseInt(Structure[Structure.length - 1]['item_key']) + 1
            : 1;
        Position =
          Structure && Structure.length > 0
            ? parseInt(Structure[Structure.length - 1]['position']) + 1
            : 1;
      } else if (saveType === 'scrutinise') {
        parentKey =
          Scrunitise && Scrunitise.length > 0
            ? parseInt(Scrunitise[Scrunitise.length - 1]['item_key']) + 1
            : 1;
        Position =
          Scrunitise && Scrunitise.length > 0
            ? parseInt(Scrunitise[Scrunitise.length - 1]['position']) + 1
            : 1;
      } else if (saveType === 'shortfall') {
        parentKey =
          Shortfall && Shortfall.length > 0
            ? parseInt(Shortfall[Shortfall.length - 1]['item_key']) + 1
            : 1;
        Position =
          Shortfall && Shortfall.length > 0
            ? parseInt(Shortfall[Shortfall.length - 1]['position']) + 1
            : 1;
      }


      //-------------------------START: REARRANGE ITEM KEYS FOR SELECTED NODES------------------//
      if (data.description) {
        let parentKeyIterated = 0;

        data.description.forEach((element, index) => {
          let parentSelection = false;

          if (selectedLibraryItemNode && selectedLibraryItemNode.length > 0) {
            if (selectedLibraryItemNode.includes(element.item_key)) {
              element.position = Position;
              if (parentKeyIterated) {
                element.newItemKey = parentKey + 1;
                parentKey = element.newItemKey;
              } else {
                // element.newItemKey = 1;
                // parentKey = 1;
                element.newItemKey = parentKey;
                parentKeyIterated = 1;
              }

              Position = Position + 1;
              parentSelection = true;
              newDescriptionTemp.push(element);
            }

            let childKey = 0;
            let childPosition = 1;
            element.children &&
              element.children.length > 0 &&
              element.children.forEach((element1, indexChild) => {
                let childSelection = false;

                if (selectedLibraryItemNode.includes(element1.item_key)) {
                  if (parentSelection) {
                    element1.newItemKey = parentKey + '-' + childKey;
                    element1.position = childPosition;
                    childPosition = childPosition + 1;
                  } else {
                    if (parentKeyIterated) {
                      element1.newItemKey = parentKey + 1;
                    } else {
                      element1.newItemKey = parentKey;
                    }
                    element1.position = Position;

                    Position = Position + 1;
                    parentKey = parentKey + 1;
                  }

                  childKey = childKey + 1;
                  childSelection = true;
                  newDescriptionTemp.push(element1);
                }

                let subchildKey = 0;

                element1.children &&
                  element1.children.length > 0 &&
                  element1.children.forEach((element2, indexSubChild) => {
                    if (selectedLibraryItemNode.includes(element2.item_key)) {
                      if (parentSelection) {
                        if (childSelection) {
                          element2.newItemKey = element1.newItemKey + '-' + subchildKey;
                          element2.position = subchildKey + 1;
                        } else {
                          element2.newItemKey = parentKey + '-' + childKey;
                          element2.position = subchildKey + 1;
                          childKey = childKey + 1;
                        }
                      } else {
                        if (childSelection) {
                          element2.newItemKey =
                            element1.newItemKey + '-' + subchildKey + 1;
                          element2.position = subchildKey + 1;
                        } else {
                          if (parentKeyIterated) {
                            element2.newItemKey = parentKey + 1;
                          } else {
                            element2.newItemKey = parentKey;
                          }
                          element2.position = Position;

                          parentKey = parentKey + 1;
                          Position = Position + 1;
                        }
                      }
                      subchildKey = subchildKey + 1;

                      newDescriptionTemp.push(element2);
                    }
                  });
              });
          }
        });
      }

      let newFormattedArr = [];
      if (newDescriptionTemp && newDescriptionTemp.length > 0) {
        newDescriptionTemp.forEach((x, index) => {
          if (typeof x.newItemKey === 'string') {
            let itemKeyArr = x.newItemKey.split('-');
            if (itemKeyArr.length === 2) {
              if (newFormattedArr && newFormattedArr.length > 0) {
                newFormattedArr.forEach((e) => {
                  if (parseInt(e.item_key) === parseInt(itemKeyArr[0])) {
                    x.item_key = x.newItemKey;
                    x.children = [];
                    delete x.newItemKey;
                    e.children.push(x);
                  }
                });
              }
            }
            if (itemKeyArr.length === 3) {
              if (newFormattedArr && newFormattedArr.length > 0) {
                newFormattedArr.forEach((e) => {
                  if (parseInt(e.item_key) === parseInt(itemKeyArr[0])) {
                    if (e.children && e.children.length) {
                      e.children.forEach((e1) => {
                        if (e1.item_key === itemKeyArr[0] + '-' + itemKeyArr[1]) {
                          x.item_key = x.newItemKey;
                          x.children = [];
                          delete x.newItemKey;
                          e1.children.push(x);
                        }
                      });
                    }
                  }
                });
              }
            }
          } else {
            x.item_key = x.newItemKey;
            x.children = [];
            delete x.newItemKey;
            newFormattedArr.push(x);
          }
        });
      }


      if (saveType === 'screen') {
        SetScreen(Screen.concat(newFormattedArr));
        setCopiedScreenData(Screen.concat(newFormattedArr));
      } else if (saveType === 'structure') {
        SetStructure(Structure.concat(newFormattedArr));
        setCopiedStructureData(Structure.concat(newFormattedArr));
      } else if (saveType === 'scrutinise') {
        SetScrunitise(Scrunitise.concat(newFormattedArr));
        setCopiedScrunitiseData(Scrunitise.concat(newFormattedArr));
      } else if (saveType === 'shortfall') {
        SetShortfall(Scrunitise.concat(newFormattedArr));
        setCopiedShortfallData(Shortfall.concat(newFormattedArr));
      }

      setSelectedLibraryItemNode([]);
    } else {
      if (saveType === 'screen') {
        SetScreen(data.description);
        setCopiedScreenData(data.description);
      } else if (saveType === 'structure') {
        SetStructure(data.description);
        setCopiedStructureData(data.description);
      } else if (saveType === 'scrutinise') {
        SetScrunitise(data.description);
        setCopiedScrunitiseData(data.description);
      } else if (saveType === 'shortfall') {
        SetShortfall(data.description);
        setCopiedShortfallData(data.description);
      }
    }

    if (isCheckboxSelection) {
      setStructureData(null);
      setOpenScheduleLibraryDrawer(false);
    }
    close();
  };
  /**fetch library data for deal desk right component where data is shown in drawer */
  useEffect(() => {
    if (visible) {
      if (saveType === 'screen') {
        fetchListData(0, false, { type: 3 });
      } else if (saveType === 'structure') {
        fetchListData(0, false, { type: 1 });
      } else if (saveType === 'scrutinise') {
        fetchListData(0, false, { type: 4 });
      } else if (saveType === 'shortfall') {
        fetchListData(0, false, { type: 5 });
      }
    }
  }, [visible, deal_data, fetchListData, saveType]);


  const getTitleBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return `${t('DealDesk.Screen')}`;
    } else if (saveType === 'structure') {
      return `${t('Common_Data.Structure')}`;
    } else if (saveType === 'scrutinise') {
      return `${t('Common_Data.Scrutinise')}`;
    } else if (saveType === 'shortfall') {
      return `${t('Common_Data.Shortfall')}`;
    }
  }

  const getLibraryBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return screenLibrary;
    } else if (saveType === 'structure') {
      return structureLibrary;
    } else if (saveType === 'scrutinise') {
      return scrutiniseLibrary;
    } else if (saveType === 'shortfall') {
      return shortfallLibrary;
    }
  }

  // const getRemoveFunctionBasedOnSaveType = () => {
  //   if (saveType === 'screen') {
  //     return handleResetScreen;
  //   } else if (saveType === 'structure') {
  //     return handleResetStructure;
  //   } else if (saveType === 'scrutinise') {
  //     return handleResetScrutinise;
  //   } else if (saveType === 'shortfall') {
  //     return handleResetShortfall;
  //   }
  // }
  /**To update the agGrid ref data and used in reset button */
  // setAgGrid(SSSLEADLIBRARYSTORE.agGridRef?.current)
  // useEffect(() => {
  // }, [SSSLEADLIBRARYSTORE.agGridRef])

  /**To fetch library data on refresh  */
  const refresh = () => {
    fetchLibraryByType(saveType)
  }
  // if(!LEADSSTORE.classification_role_list) {
  //   LEADSSTORE.getClassificationList();
  // }

  return (
    <>
      <Drawer
        title={`${getTitleBasedOnSaveType()} ${t('DealDesk.Library')}`}
        visible={visible}
        onClose={close}
        placement='right'
        width={'1050px'}
        destroyOnClose={true}
      >
        <LibraryListGrid
          openDeleteDrawer={openDeleteDrawer}
          openEditDrawer={openEditDrawer}
          viewScheduleLibraryDrawer={viewScheduleLibraryDrawer}
          tempcopyStructure={tempcopyStructure}
          DealLibrary={false}
          saveType={saveType}
          libraryData={getLibraryBasedOnSaveType()}
          CommonFieldTitle={getTitleBasedOnSaveType()}
        />

        <StructureLibraryView
          visibleScheduleLibraryDrawer={openScheduleLibraryDrawer}
          closeScheduleLibraryDrawer={closeScheduleLibraryDrawer}
          structure={structureData}
          saveType={saveType}
          isCheckBoxSelection={true}
          tempcopyStructure={tempcopyStructure}
          selectedLibraryItemNode={selectedLibraryItemNode}
          setSelectedLibraryItemNode={setSelectedLibraryItemNode}
        />
      </Drawer>
      {DealLibrary && (
        <PageHeader
          title=''
          className={'peopleOrg'}
          extra={[
            (
              saveType !== 'shortfall' &&
              <Button shape='round' size='medium' key='1' onClick={openAddDrawer}>
                {t('Common_Data.Add')} {getTitleBasedOnSaveType()}
              </Button>

            )
            ,
            // <Button
            //   key='2'
            //   title={`${t('Common_Data.Reset')}`}
            //   shape='round'
            //   size='medium'
            //   onClick={getRemoveFunctionBasedOnSaveType()}
            // >
            //   <FontAwesomeIcon icon={faUndo} />
            // </Button>,
            <ResetGridOptions key={'2'} pageType={saveType} list_grid={`${saveType}_library_grid`} refresh={refresh} />
          ]}
        >
          <LibraryListGrid
            openDeleteDrawer={openDeleteDrawer}
            openEditDrawer={openEditDrawer}
            viewScheduleLibraryDrawer={viewScheduleLibraryDrawer}
            tempcopyStructure={tempcopyStructure}
            DealLibrary={true}
            saveType={saveType}
            libraryData={getLibraryBasedOnSaveType()}
            CommonFieldTitle={getTitleBasedOnSaveType()}
          />
          <StructureLibraryView
            saveType={saveType}
            visibleScheduleLibraryDrawer={openScheduleLibraryDrawer}
            closeScheduleLibraryDrawer={closeScheduleLibraryDrawer}
            structure={structureData}
          />
        </PageHeader>
      )}
      <AddComponent visible={addDrawer} saveType={saveType} close={closeAddDrawer} />
      <EditComponent visible={editDrawer} saveType={saveType} close={closeEditDrawer} />
      <DeleteComponent
        visible={deleteDrawer}
        saveType={saveType}
        id={id}
        libData={libData}
        close={closeDeleteDrawer}
      />
      <TimeTracker
        stop={!visible}
        page={"library"}
      />
    </>
  );
});

export default StructureLibrary;
