// reset all the server side filters
// Reset : Will reset all filters, sorting, saved columns settings.
export const reset = (agGrid, list_grid = '', setGridFilter, gridFilterId = null, filter = {}) => {
  localStorage.removeItem(list_grid);
  agGrid.api.setFilterModel(null);
  agGrid.api.setSortModel(null);
  agGrid.api.onFilterChanged(null);
  agGrid.columnApi.resetColumnState();
  agGrid.columnApi.applyColumnState({
    state: [
      {
        colId: '0',
        aggFunc: null,
        flex: null,
        hide: false,
        pinned: "left",
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 37,
      },
      // {
      //   colId: "actions",
      //   pinned: "right",
      // }
    ],
    // defaultState: {
    //   flex: 1,
    //   hide: false,
    //   pinned: null,
    //   pivot: false,
    //   pivotIndex: null,
    //   rowGroup: false,
    //   rowGroupIndex: null,
    //   sort: null,
    //   sortIndex: null,
    //   minWidth: 70,
    //   width: 70,
    // }
  });
  if (setGridFilter) {
    setGridFilter(2);
  }
};

/** This is called on clear button */
// Clear : Will clear all applied Filter and sorting, And will reset to default.
export const clear = (agGrid, filter = null) => {
  agGrid.api.setSortModel(null);
  agGrid.api.setFilterModel(filter);
  agGrid.columnApi.applyColumnState({
    state: [
      {
        colId: '0',
        aggFunc: null,
        flex: null,
        hide: false,
        pinned: "left",
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 37,
      },
    ],
  });
};
