import React from 'react'
import MergeLead from '../../LeadListing/component/MergeLead'
import MergeOrganisation from '../../OrganisationManagement/AllOrganisations/component/MergeOrganisation'
import MergePeople from '../../PeopleManagement/People/component/MergePeople'
import MergeTicket from '../../Tickets/component/MergeTicket'

const MergeModal = ({ sectorSelector, mergeVisibleModal, closeMergeModal, selectedMergeData }) => {
    if (sectorSelector === "People") {
        return <MergePeople
            visible={mergeVisibleModal}
            close={closeMergeModal}
            selectedMergeData={selectedMergeData}
        />
    } else if (sectorSelector === "Organisations") {
        return <MergeOrganisation
            visible={mergeVisibleModal}
            close={closeMergeModal}
            selectedMergeData={selectedMergeData}
        />
    } else if (sectorSelector === "Leads" || sectorSelector === "Deals") {
        return <MergeLead
            visible={mergeVisibleModal}
            close={closeMergeModal}
            selectedLeadsMerge={selectedMergeData}
        />
    } else if (sectorSelector === "Tickets" || sectorSelector === "Master") {
        return <MergeTicket
            visible={mergeVisibleModal}
            close={closeMergeModal}
            selectedMergeData={selectedMergeData}
        />
    }
    else {
        return ""
    }
}

export default MergeModal