import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ListComponent from './component/ListComponent';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import ViewComponent from './component/ViewComponent';
import { PageHeader, Button, Space, Form, Tooltip, Typography } from 'antd';
import useStore from '../../store';
import RecordPerPage from '../../config/RecordPerPage';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../component/EnterFullscreen';
import Unauthorized from '../Unauthorized';
//import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';
import ApplyGridFilterNew from '../../component/ApplyGridFilterNew';
import { filterFiltersByPageType } from '../../utils/GlobalFunction';
import ResetGridOptions from '../../component/ResetGridOptions';
import { useTranslation } from 'react-i18next';

const Reminders = observer((props) => {
  const { t } = useTranslation()
  const [addReminder, setAddReminder] = useState(false);
  const [editReminder, setEditReminder] = useState(false);
  const [deleteReminder, setDeleteReminder] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);

  const [isRefreshGrid, setIsRefreshGrid] = useState(false);

  const {
    REMINDERSSTORE,
    SETTINGS,
    AUTH,
    AUTH: { allPageFilters, allPageFiltersLoaded },
    DEALDESKSTORE: { stopInvestmentHourCounter, setStopInvestmentHourCounter },
  } = useStore();

  const { SearchPage, AllView, AllSOLDReminder, sectorSelector, search_term, resultCount, handleRoute } = props;
  const { Title } = Typography;

  //open add drawer
  const openAddReminder = (data) => {
    // DEALDESKSTORE.deal_data = null;
    setAddReminder(true);
  };
  const closeAddReminder = () => setAddReminder(false);

  //open edit drawer
  const openEditReminder = (data) => {
    //DEALDESKSTORE.deal_data = null;
    if (!data.is_read) {
      setIsRefreshGrid(true);
    }
    REMINDERSSTORE.setEditValues(data);
    setStopInvestmentHourCounter(true);
    setEditReminder(true);
  };
  const closeEditReminder = () => {
    setStopInvestmentHourCounter(false);
    setEditReminder(false);
    setIsRefreshGrid(false);
  };

  //open delete drawer
  const openDeleteReminder = (data) => {
    REMINDERSSTORE.setDeleteValues(data);
    setDeleteReminder(true);
  };
  const closeDeleteReminder = () => setDeleteReminder(false);

  //open view drawer
  const openViewDrawer = (data) => {
    if (AUTH.checkPermission(46, 'view')) {
      if (!data.is_read) {
        setIsRefreshGrid(true);
      }
      REMINDERSSTORE.setViewValues(data);
      setStopInvestmentHourCounter(true);
      setViewDrawer(true);
    }
  };
  const closeViewDrawer = () => {
    REMINDERSSTORE.viewValues = null;
    setViewDrawer(false);
    setStopInvestmentHourCounter(false);
    setIsRefreshGrid(false);
  };

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(46, 'edit')) {
            if (result.key === 'Enter') {
              openEditReminder(e.data);
            }
          }
          if (AUTH.checkPermission(46, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteReminder(e.data);
            }
          }
        }
      }
    }
  };

  useEffect(() => { }, [allPageFilters, allPageFiltersLoaded]);

  const pageFilterType = 'reminder'; //FOR PROPS: TOP FILTER DROPDOWN LIST AND RESET OPTIONS

  return (
    <>
      {!AUTH.checkPermission(46, 'list') ? (
        <Unauthorized />
      ) :
        //  SearchPage ? (
        //   <>
        //     <ListComponent
        //       openAddReminder={openAddReminder}
        //       openEditReminder={openEditReminder}
        //       openDeleteReminder={openDeleteReminder}
        //       openViewDrawer={openViewDrawer}
        //       SearchPage={SearchPage}
        //       AllView={AllView}
        //     />
        //     <AddComponent visible={addReminder} close={closeAddReminder} />
        //     <EditComponent visible={editReminder} close={closeEditReminder} />
        //     <DeleteComponent
        //       visible={deleteReminder}
        //       close={closeDeleteReminder}
        //       SearchPage={SearchPage}
        //     />
        //     <ViewComponent visible={viewDrawer} close={closeViewDrawer} />
        //   </>
        // ) : 
        AllSOLDReminder ? (
          <>
            <ListComponent
              openAddReminder={openAddReminder}
              openEditReminder={openEditReminder}
              openDeleteReminder={openDeleteReminder}
              openViewDrawer={openViewDrawer}
              SearchPage={SearchPage}
              onCellKeyDown={onCellKeyDown}
              AllSOLDReminder={AllSOLDReminder}
            />
            <AddComponent visible={addReminder} close={closeAddReminder} />
            <EditComponent
              visible={editReminder}
              close={closeEditReminder}
              isRefreshGrid={isRefreshGrid}
            />
            <DeleteComponent
              visible={deleteReminder}
              close={closeDeleteReminder}
              AllSOLDReminder={AllSOLDReminder}
            />
            <ViewComponent
              visible={viewDrawer}
              close={closeViewDrawer}
              isRefreshGrid={isRefreshGrid}
            />
            {/* {!stopInvestmentHourCounter && <TrackTimeComponent pageName={'reminders'} />} */}
            {!stopInvestmentHourCounter && <TimeTracker />}
          </>
        ) : (
          <PageHeader
            title={
              !SearchPage ? `${t('Common_Data.Reminders')}`
                : (<>
                  <div className='d-flex justify-content-start align-items-center notes'>
                    <Title level={3}>
                      {sectorSelector} : <Tooltip title={search_term} placement="topLeft">"{search_term?.length > 25 ? search_term.substring(0, 25) + "..." : search_term}"</Tooltip> {`${t('DealDesk.Results')}`}
                    </Title>
                    <div className='searchView ml-10 '>
                      <span className='sep'>|</span>
                      {resultCount ? resultCount.toLocaleString('en-GB') : resultCount}{' '}
                      {t('Common_Data.Records_found')}
                      <span className='sep'>|</span>
                      <span className='cursorPointer text-decoration-underline mr-10' onClick={handleRoute} >
                        {t('Common_Data.View_all')} {sectorSelector}
                      </span>
                    </div>
                  </div>
                </>)
            }
            className={SearchPage ? 'SearchResult-PageHeader ' + SETTINGS.fullscreen_class : 'page-title ' + SETTINGS.fullscreen_class}
            extra={[
              <Form className='innerForm headerButton'>
                <Space size={5}>
                  {!SearchPage ? <ApplyGridFilterNew
                    type={pageFilterType}
                    selectionValue={REMINDERSSTORE.selectedGridFilter}
                    filterList={filterFiltersByPageType(allPageFilters, pageFilterType)}
                  /> : null}
                  {!SearchPage ? AUTH.checkPermission(46, 'add') && (
                    <Button
                      className='gridbtn'
                      key='1'
                      shape='round'
                      onClick={openAddReminder}
                    >
                      {t('Common_Data.Add')} {t('Common_Data.Reminder')}
                    </Button>
                  ) : null}

                  {/* <Button
                  key='2'
                  shape='round'
                  size='medium'
                  title={`${t('Common_Data.Reset')}`}
                  onClick={REMINDERSSTORE.handleReset}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button> */}
                  <ResetGridOptions key={'2'} pageType={pageFilterType} />

                  <EnterFullscreen key='3' />
                  <RecordPerPage
                    key='4'
                    initialValue={REMINDERSSTORE.per_page + ' per page'}
                    onChange={REMINDERSSTORE.setPageSize}
                  />
                </Space>
              </Form>,
            ]}
          >
            <ListComponent
              openAddReminder={openAddReminder}
              openEditReminder={openEditReminder}
              openDeleteReminder={openDeleteReminder}
              openViewDrawer={openViewDrawer}
              SearchPage={SearchPage}
              AllView={AllView}
              onCellKeyDown={onCellKeyDown}
            />
            <AddComponent visible={addReminder} close={closeAddReminder} />
            <EditComponent
              visible={editReminder}
              close={closeEditReminder}
              isRefreshGrid={isRefreshGrid}
            />
            <DeleteComponent visible={deleteReminder} close={closeDeleteReminder} SearchPage={SearchPage} />
            <ViewComponent
              visible={viewDrawer}
              close={closeViewDrawer}
              isRefreshGrid={isRefreshGrid}
            />
            {/* {!stopInvestmentHourCounter && <TrackTimeComponent pageName={'reminders'} />} */}
            {!stopInvestmentHourCounter && <TimeTracker />}
          </PageHeader>
        )}
    </>
  );
});

export default Reminders;
