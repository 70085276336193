import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Form, Checkbox, Button, Typography, Modal, Divider, Spin } from 'antd';
import useStore from '../../../store';
import { vsmNotify, vsmTickertap } from '../../../config/messages';
import debounce from 'lodash/debounce';
import InputComponent from '../../../component/InputComponent';
import { useTranslation } from 'react-i18next';
// import Unauthorized from '../../Unauthorized';

const { Title } = Typography;

const InformationComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const { TICKERTAPBROADCAST, AUTH } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formconfirm, setFormconfirm] = useState(false);
  const [fetchUsergroup, setFetchUsergroup] = useState(true);
  const [leadsStatus, setLeadsStatus] = useState(false);
  const [tagsStatus, setTagsStatus] = useState(false);
  const [oppStatus, setOppStatus] = useState(false);
  const [ticketStatus, setTicketStatus] = useState(false);
  const [dealStatus, setDealStatus] = useState(false);
  const [tickerinfoStatus, setTickerinfoStatus] = useState(false);

  const close = () => {
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    setFormconfirm(false);
    data.leads_status = leadsStatus;
    data.tags_status = tagsStatus;
    data.opportunities_status = oppStatus;
    data.tickets_status = ticketStatus;
    data.deals_status = dealStatus;
    data.ticker_information_status = tickerinfoStatus;

    TICKERTAPBROADCAST.updateTickerSetting(data)
      .then(() => {
        AUTH.getTickerHeaderInfo();
        vsmNotify.success({
          message: vsmTickertap.tickerSetting
        });
        close();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // call form cancel function
  const cancelFormsubmit = () => {
    setFormconfirm(false);
  };

  const fetchTickerSettingData = useCallback(() => {
    TICKERTAPBROADCAST.fetchTickerSetting().then((data) => {
      if (!TICKERTAPBROADCAST.usergroup_list) {
        TICKERTAPBROADCAST.getUsergroupList();
      }

      setLeadsStatus(data.leads_status ? true : false);
      setTagsStatus(data.tags_status ? true : false);
      setOppStatus(data.opportunities_status ? true : false);
      setTicketStatus(data.tickets_status ? true : false);
      setDealStatus(data.deals_status ? true : false);
      setTickerinfoStatus(data.ticker_information_status ? true : false);
      form.setFieldsValue({
        opportunities_user_groups: data.opportunities_user_groups
          ? data.opportunities_user_groups
          : [],
        tickets_user_groups: data.tickets_user_groups ? data.tickets_user_groups : [],
        deals_user_groups: data.deals_user_groups ? data.deals_user_groups : [],
        leads_user_groups: data.leads_user_groups ? data.leads_user_groups : [],
        tags_user_groups: data.tags_user_groups ? data.tags_user_groups : []
      });
    });
  }, [TICKERTAPBROADCAST, form]);

  // call form cancel function
  const resetForm = () => {
    fetchTickerSettingData();
    form.resetFields();
  };

  useEffect(() => {
    fetchTickerSettingData();
  }, [fetchTickerSettingData]);

  return (
    <>
      <Title level={1} className='page-title'>
        {t('TickerTapBroadcast.Ticker')} {t('TickerTapBroadcast.Tape')} {t('TickerTapBroadcast.Broadcast')}
      </Title>

      <Form
        onChange={handleChange}
        onFinish={handleSubmit}
        form={form}
        labelCol={{ span: 24 }}
        id='addeditform'
        className='innerForm tickerTab'
      >
        <div className=''>
          <Checkbox.Group
            options=''
            defaultValue={['Leads']}
            className='TickerTape-Checkbox'
          />
          <table width='100%' cellPadding='0' cellSpacing='0'>
            <tbody>
              <tr>
                <td width='15%'></td>
                <td width='35%'>
                  {AUTH.checkPermission(29, 'leads' || 'opportunities' || 'deals') && (
                    <InputComponent
                      type='labelOnly'
                      label={`${t('Common_Data.User')} ${t('Common_Data.Groups')}`}
                      required
                      tooltip={`${t('TickerTapBroadcast.User_Group_Tooltip')}.`}
                    />
                  )}
                </td>
                <td width='15%'></td>
                <td width='35%'>
                  {AUTH.checkPermission(
                    29,
                    'tags' || 'tickets' || 'ticker-information'
                  ) && (
                      <InputComponent
                        type='labelOnly'
                        label={`${t('Common_Data.User')} ${t('Common_Data.Groups')}`}
                        required
                        tooltip={`${t('TickerTapBroadcast.User_Group_Tooltip')}.`}
                      />
                    )}
                </td>
              </tr>
              <tr>
                <td>
                  <Checkbox
                    checked={leadsStatus}
                    name='leads_status'
                    value={leadsStatus}
                    onChange={(e) => setLeadsStatus(e.target.checked)}
                    disabled={!AUTH.checkPermission(29, 'leads') ? true : false}
                    defaultChecked={!AUTH.checkPermission(29, 'leads') ? false : true}
                  >
                    {t('LeadListing.Leads')}
                  </Checkbox>
                </td>
                <td>
                  <InputComponent
                    name='leads_user_groups'
                    required
                    type='select'
                    tooltip={`${t('TickerTapBroadcast.User_Group_Tooltip')}.`}
                    mode='tags'
                    showArrow
                    showSearch={false}
                    onChange={handleChange}
                    disabled={!AUTH.checkPermission(29, 'leads') ? true : false}
                    options={{
                      values: TICKERTAPBROADCAST.usergroup_list,
                      value_key: 'id',
                      text_key: 'group_name',
                      rejected_keys: TICKERTAPBROADCAST.tickersettingValue && [
                        TICKERTAPBROADCAST.tickersettingValue.leads_user_groups
                      ]
                    }}
                    onFocus={() =>
                      fetchUsergroup &&
                      TICKERTAPBROADCAST.getUsergroupList().then(() =>
                        setFetchUsergroup(false)
                      )
                    }
                    notFoundContent={
                      fetchUsergroup ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                  />
                </td>
                <td>
                  <Checkbox
                    checked={tagsStatus}
                    value={tagsStatus}
                    name='tags_status'
                    onChange={(e) => setTagsStatus(e.target.checked)}
                    disabled={!AUTH.checkPermission(29, 'tags') ? true : false}
                  >
                    {t('Common_Data.Tags')}
                  </Checkbox>
                </td>
                <td>
                  <InputComponent
                    name='tags_user_groups'
                    required
                    type='select'
                    tooltip={`${t('TickerTapBroadcast.User_Group_Tooltip')}.`}
                    mode='tags'
                    showArrow
                    showSearch={false}
                    onChange={handleChange}
                    disabled={!AUTH.checkPermission(29, 'tags') ? true : false}
                    options={{
                      values: TICKERTAPBROADCAST.usergroup_list,
                      value_key: 'id',
                      text_key: 'group_name',
                      rejected_keys: TICKERTAPBROADCAST.tickersettingValue && [
                        TICKERTAPBROADCAST.tickersettingValue.opportunities_user_groups
                      ]
                    }}
                    onFocus={() =>
                      fetchUsergroup &&
                      TICKERTAPBROADCAST.getUsergroupList().then(() =>
                        setFetchUsergroup(false)
                      )
                    }
                    notFoundContent={
                      fetchUsergroup ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Checkbox
                    checked={oppStatus}
                    value={oppStatus}
                    name='opportunities_status'
                    onChange={(e) => setOppStatus(e.target.checked)}
                    disabled={!AUTH.checkPermission(29, 'opportunities') ? true : false}
                  >
                    {t('Common_Data.Opportunities')}
                  </Checkbox>
                </td>
                <td>
                  <InputComponent
                    name='opportunities_user_groups'
                    required
                    type='select'
                    tooltip={`${t('TickerTapBroadcast.User_Group_Tooltip')}.`}
                    mode='tags'
                    showArrow
                    showSearch={false}
                    onChange={handleChange}
                    disabled={!AUTH.checkPermission(29, 'opportunities') ? true : false}
                    options={{
                      values: TICKERTAPBROADCAST.usergroup_list,
                      value_key: 'id',
                      text_key: 'group_name',
                      rejected_keys: TICKERTAPBROADCAST.tickersettingValue && [
                        TICKERTAPBROADCAST.tickersettingValue.opportunities_user_groups
                      ]
                    }}
                    onFocus={() =>
                      fetchUsergroup &&
                      TICKERTAPBROADCAST.getUsergroupList().then(() =>
                        setFetchUsergroup(false)
                      )
                    }
                    notFoundContent={
                      fetchUsergroup ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                  />
                </td>
                <td>
                  <Checkbox
                    checked={ticketStatus}
                    name='tickets_status'
                    value={ticketStatus}
                    onChange={(e) => setTicketStatus(e.target.checked)}
                    disabled={!AUTH.checkPermission(29, 'tickets') ? true : false}
                  >
                    {t('Common_Data.Tickets')}
                  </Checkbox>
                </td>
                <td>
                  <InputComponent
                    name='tickets_user_groups'
                    required
                    type='select'
                    tooltip={`${t('TickerTapBroadcast.User_Group_Tooltip')}.`}
                    mode='tags'
                    showArrow
                    showSearch={false}
                    onChange={handleChange}
                    disabled={!AUTH.checkPermission(29, 'tickets') ? true : false}
                    options={{
                      values: TICKERTAPBROADCAST.usergroup_list,
                      value_key: 'id',
                      text_key: 'group_name',
                      rejected_keys: TICKERTAPBROADCAST.tickersettingValue && [
                        TICKERTAPBROADCAST.tickersettingValue.tickets_user_groups
                      ]
                    }}
                    onFocus={() =>
                      fetchUsergroup &&
                      TICKERTAPBROADCAST.getUsergroupList().then(() =>
                        setFetchUsergroup(false)
                      )
                    }
                    notFoundContent={
                      fetchUsergroup ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Checkbox
                    checked={dealStatus}
                    value={dealStatus}
                    name='deals_status'
                    onChange={(e) => setDealStatus(e.target.checked)}
                    disabled={!AUTH.checkPermission(29, 'deals') ? true : false}
                  >
                    {t('LeadListing.Deals')}
                  </Checkbox>
                </td>
                <td>
                  <InputComponent
                    name='deals_user_groups'
                    required
                    type='select'
                    tooltip={`${t('TickerTapBroadcast.User_Group_Tooltip')}.`}
                    mode='tags'
                    showArrow
                    showSearch={false}
                    onChange={handleChange}
                    disabled={!AUTH.checkPermission(29, 'deals') ? true : false}
                    options={{
                      values: TICKERTAPBROADCAST.usergroup_list,
                      value_key: 'id',
                      text_key: 'group_name',
                      rejected_keys: TICKERTAPBROADCAST.tickersettingValue && [
                        TICKERTAPBROADCAST.tickersettingValue.deals_user_groups
                      ]
                    }}
                    onFocus={() =>
                      fetchUsergroup &&
                      TICKERTAPBROADCAST.getUsergroupList().then(() =>
                        setFetchUsergroup(false)
                      )
                    }
                    notFoundContent={
                      fetchUsergroup ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                  />
                </td>
                <td colSpan='2'>
                  <Checkbox
                    checked={tickerinfoStatus}
                    value={tickerinfoStatus}
                    name='ticker_information_status'
                    onChange={(e) => setTickerinfoStatus(e.target.checked)}
                    disabled={
                      !AUTH.checkPermission(29, 'ticker-information') ? true : false
                    }
                  >
                    {t('TickerTapBroadcast.Ticker')} {t('TickerTapBroadcast.Information')}
                  </Checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Divider />
        <div className='text-right'>
          <Button
            shape='round'
            saving={saving}
            size='small'
            //htmlType='submit'
            disabled={
              !AUTH.checkPermission(
                29,
                'leads' ||
                'opportunities' ||
                'deals' ||
                'tags' ||
                'tickets' ||
                'ticker-information'
              )
                ? true
                : isDisabled
            }
            onClick={() => setFormconfirm(true)}
          >
            {t('Common_Data.Update')}
          </Button>
          <Button
            shape='round'
            size='small'
            onClick={resetForm}
            disabled={
              AUTH.checkPermission(
                29,
                'leads' ||
                'opportunities' ||
                'deals' ||
                'tags' ||
                'tickets' ||
                'ticker-information'
              )
                ? true
                : false
            }
          >
            {t('Common_Data.Cancel')}
          </Button>
        </div>
      </Form>

      <Modal
        centered
        title={`${t('Common_Data.Save')} ${t('Common_Data.Details')}`}
        visible={formconfirm}
        onCancel={cancelFormsubmit}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            //disabled={disabled}
            form='addeditform'
            //loading={saving}
            htmlType='submit'
            type='primary'
          >
            {t('Common_Data.Save')}
          </Button>,
          <Button key='2' htmlType='button' onClick={cancelFormsubmit}>
            {t('Common_Data.Cancel')}
          </Button>
        ]}
      >
        <p>{t('TickerTapBroadcast.Save_All_Details')} {t('Common_Data.For')} {t('TickerTapBroadcast.Ticker')} {t('TickerTapBroadcast.Tape')} {t('TickerTapBroadcast.Broadcast')}?</p>
      </Modal>
    </>
  );
});

export default InformationComponent;
