import { Form, Button, Modal, Row, Col, Upload, message, Radio } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import useStore from '../../../../store';
import { vsmOrganisations, vsmNotify } from '../../../../config/messages';
import simpleImportOrganisations from '../../../../assets/import-simple-organisations.csv';
import advanceImportOrganisations from '../../../../assets/import-advance-organisations.csv';
import sampleImportOrganisationsRoles from '../../../../assets/import-sample-organisations-roles.csv';
import sampleImportOrgPeopleMapping from '../../../../assets/import-sample-Org-People_Mapping.csv';
import Unauthorized from '../../../Unauthorized';
import { useTranslation } from 'react-i18next';
const { Dragger } = Upload;

const ImportComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [roleSuccessModal, setRoleSuccessModal] = useState(false);
  const [successCount, setSuccessCount] = useState();
  const [errorFileURL, setErrorFileURL] = useState();
  const {
    AUTH,
    ORGANISATION: { ImportOrgFile, ImportOrgRolesFile }
  } = useStore();
  const [importType, setImportType] = useState(1);

  // call function to import orgnaisations file
  const handleOrgSubmit = (data) => {
    setSaving(true);
    const formData = new FormData();
    formData.append('file1', fileList[0]);
    ImportOrgFile(formData)
      .then((res) => {
        close(true);
        if (res) {
          setSuccessCount(res.success_count);
          setErrorFileURL(res.csv_link);
          setSuccessModal(true);
        }
        if (res.success_count !== 0) {
          vsmNotify.success({
            message: vsmOrganisations.import
          });
        }
      })
      .catch((e) => {
        if (e.errors && e.errors.file && e.errors.file[0]) {
          message.error(e.errors.file[0]);
        }

        if (e.errors && e.errors[0] && e.errors[0].errors && e.errors[0].errors[0]) {
          message.error(e.errors[0].errors[0]);
        }
      })
      .finally(() => setSaving(false));
  };

  // call function to import orgnaisation roles file
  const handleRoleSubmit = (data) => {
    setSaving(true);
    const formData = new FormData();
    formData.append('file', fileList[0]);
    ImportOrgRolesFile(formData)
      .then((res) => {
        close(true);
        if (res) {
          setSuccessCount(res.success_count);
          setErrorFileURL(res.csv_link);
          setRoleSuccessModal(true);
        }
        if (res.success_count !== 0) {
          vsmNotify.success({
            message: vsmOrganisations.importRole
          });
        }
      })
      .catch((e) => {
        if (e.errors && e.errors.file && e.errors.file[0]) {
          message.error(e.errors.file[0]);
        }

        if (e.errors && e.errors[0] && e.errors[0].errors && e.errors[0].errors[0]) {
          message.error(e.errors[0].errors[0]);
        }
      })
      .finally(() => setSaving(false));
  };

  // reset form and close import form
  const close = (close = false) => {
    if (!saving || close) {
      setImportType(1);
      props.close();
      form.resetFields();
      setFileList([]);
      setDisabled(true);
      setSuccessCount();
      setErrorFileURL();
      setSaving(false);
    }
  };

  // call for file type and size
  const beforeUpload = (file) => {
    let isCSV =
      file.type === 'text/csv' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === '';
    let isLt20M = file.size / 1024 / 1024 < 20;
    if (!isCSV) {
      message.error(`${t('OrganisationManagement.Upload_CSV_File')}!`);
      setDisabled(true);
      return true;
    } else if (!isLt20M) {
      message.error(`${t('OrganisationManagement.File_Smaller')} 20${t('OrganisationManagement.Mb')}`);
      setDisabled(true);
      return true;
    } else {
      setFileList([file]);
      return false;
    }
  };

  // handle file change to uploaded file
  const handleFileChange = (info) => {
    if (info.fileList) {
      if (
        (info.file.status && info.file.status === 'error') ||
        info.fileList.length <= 0
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  };

  return (
    <>
      <Modal
        title={
          importType === 1
            ? `${t('Common_Data.Import')} ${t('Common_Data.Organisations')}`
            : importType === 2
              ? `${t('Common_Data.Import')} ${t('Common_Data.Organisations')}${t('OrganisationManagement.Role')} ${t('OrganisationManagement.Mappings')}`
              : null
        }
        destroyOnClose={true}
        centered
        visible={props.visible}
        onCancel={() => !saving && close()}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form={
              importType === 1
                ? 'importorgform'
                : importType === 2
                  ? 'importroleform'
                  : null
            }
            disabled={disabled}
            loading={saving}
            htmlType='submit'
            type='primary'
          >
            {t('Common_Data.Import')}
          </Button>,
          <Button
            key='2'
            htmlType='button'
            disabled={saving}
            onClick={() => {
              close();
            }}
          >
            {t('Common_Data.Cancel')}
          </Button>
        ]}
        width={700}
      >
        {AUTH.checkPermission(16, 'import') && (
          <Radio.Group
            buttonStyle='solid'
            optionType='button'
            className='mb-20'
            value={importType}
            onChange={(e) => {
              setImportType(e.target.value);
            }}
          >
            <Radio.Button key='1' value={1}>
              {t('Common_Data.Organisations')}
            </Radio.Button>
            <Radio.Button key='2' value={2}>
              {t('OrganisationManagement.Role')} {t('OrganisationManagement.Mappings')}
            </Radio.Button>
          </Radio.Group>
        )}
        {!AUTH.checkPermission(16, 'import') ? (
          <Unauthorized />
        ) : importType === 1 ? (
          <Form
            form={form}
            id='importorgform'
            onFinish={handleOrgSubmit}
            encType='multipart/form-data'
            acceptCharset='UTF-8'
          >
            <Row gutter={24}>
              <Col span={12}>
                <Dragger
                  accept='.csv'
                  height={300}
                  fileList={fileList}
                  onRemove={() => setFileList([])}
                  beforeUpload={beforeUpload}
                  onChange={handleFileChange}
                  name='file'
                  showUploadList={true}
                  multiple={false}
                  action={process.env.React_APP_API_URL + 'organisation/checkvalidfile/'}
                  headers={{
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                  }}
                >
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    {t('OrganisationManagement.Drag_Your_File_To_Upload')}
                  </p>
                </Dragger>
              </Col>
              <Col span={12}>
                <h3>{t('OrganisationManagement.Following_Criteria')}:</h3>
                <ul className='bullet'>
                  <li>{t('OrganisationManagement.File_Format_Csv')}.</li>
                  <li>{t('OrganisationManagement.Maximum')} 20 {t('OrganisationManagement.Mb')} {t('OrganisationManagement.File_Size_Allowed')}.</li>
                  <li>{t('OrganisationManagement.Minimum')} 1 {t('OrganisationManagement.Record_Maximum')} 20000 {t('OrganisationManagement.Records_There')}.</li>
                  <li>{t('OrganisationManagement.File_Should_Exact_Name')}.</li>
                  <li>{t('OrganisationManagement.Sample_File')}.</li>
                  <li>
                    {t('OrganisationManagement.Data_Required_All_Column')}.
                  </li>
                </ul>
                <span className='mr-5 mt-10 d-flex'>{t('OrganisationManagement.Download_Sample_File')}.</span>
                <div className='d-flex'>
                  <a
                    href={simpleImportOrganisations}
                    className='mr-5'
                    download
                    rel='noopener noreferrer'
                  >
                    {t('OrganisationManagement.Simple')} {t('Common_Data.Import')}
                  </a>
                  -
                  <a
                    href={advanceImportOrganisations}
                    className='ml-5'
                    download
                    rel='noopener noreferrer'
                  >
                    {t('OrganisationManagement.Advance')} {t('Common_Data.Import')}
                  </a>
                </div>
              </Col>
            </Row>
          </Form>
        ) : importType === 2 ? (
          <Form
            form={form}
            id='importroleform'
            onFinish={handleRoleSubmit}
            encType='multipart/form-data'
            acceptCharset='UTF-8'
          >
            <Row gutter={24}>
              <Col span={12}>
                <Dragger
                  accept='.csv'
                  height={300}
                  fileList={fileList}
                  onRemove={() => setFileList([])}
                  beforeUpload={beforeUpload}
                  onChange={handleFileChange}
                  name='file'
                  showUploadList={true}
                  multiple={false}
                  action={process.env.React_APP_API_URL + 'organisation/checkvalidfile/'}
                  headers={{
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                  }}
                >
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    {t('OrganisationManagement.Drag_Your_File_To_Upload')}
                  </p>
                </Dragger>
              </Col>
              <Col span={12}>
                <h3>{t('OrganisationManagement.Following_Criteria')}:</h3>
                <ul className='bullet'>
                  <li>{t('OrganisationManagement.File_Format_Csv')}.</li>
                  <li>{t('OrganisationManagement.Maximum')} 20 {t('OrganisationManagement.Mb')} {t('OrganisationManagement.File_Size_Allowed')}.</li>
                  <li>{t('OrganisationManagement.Minimum')} 1 {t('OrganisationManagement.Record_Maximum')} 20000 {t('OrganisationManagement.Records_There')}.</li>
                  <li>{t('OrganisationManagement.File_Should_Exact_Name')}.</li>
                  <li>{t('OrganisationManagement.Sample_File')}.</li>
                  <li>
                    {t('OrganisationManagement.Related_Organisation_People')}
                  </li>
                  <li>{t('OrganisationManagement.Bank_Account_Details')}</li>
                </ul>
                <a
                  href={sampleImportOrganisationsRoles}
                  className='mr-5 mt-10 d-flex'
                  download
                  rel='noopener noreferrer'
                >
                  {t('OrganisationManagement.Download_Sample_File')}.
                </a>
                <div>
                  <a
                    href={sampleImportOrgPeopleMapping}
                    download
                    rel='noopener noreferrer'
                  >
                    {t('OrganisationManagement.Download_Sample_File')} {t('Common_Data.For')} {t('OrganisationManagement.Org')}/{t('Common_Data.People')} {t('Common_Data.Mapping')}.
                  </a>
                </div>
              </Col>
            </Row>
          </Form>
        ) : null}
      </Modal>

      {/* Modal for successfully created organisations records, after importing file */}
      <Modal
        title={`${t('Common_Data.Import')} ${t('Common_Data.Organisations')}`}
        centered
        visible={successModal}
        onCancel={() => {
          close();
          setSuccessModal(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            htmlType='button'
            onClick={() => {
              close();
              setSuccessModal(false);
            }}
          >
            {t('Common_Data.Close')}
          </Button>
        ]}
      >
        <h3 className='text-center'>
          {successCount} {t('Common_Data.Organisations')} ${t('Common_Data.Records')} {t('Common_Data.Successfully')} {t('Common_Data.Created')} !<br />
        </h3>
        {errorFileURL && (
          <h3 className='text-center'>
            {t('OrganisationManagement.Download_Status_Note')}.
            <br />
            <a href={errorFileURL} rel='noopener noreferrer'>
              {t('OrganisationManagement.Download_File')}
            </a>
          </h3>
        )}
      </Modal>

      {/* Modal for successfully created organisation roles records, after importing file */}
      <Modal
        title={`${t('Common_Data.Import')} ${t('Common_Data.Organisations')}${t('OrganisationManagement.Roles')}`}
        centered
        visible={roleSuccessModal}
        onCancel={() => {
          close();
          setRoleSuccessModal(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            htmlType='button'
            onClick={() => {
              close();
              setRoleSuccessModal(false);
            }}
          >
            {t('Common_Data.Close')}
          </Button>
        ]}
      >
        <h3 className='text-center'>
          {successCount} {t('OrganisationManagement.Organisations_Roles')} {t('Common_Data.Successfully')} {t('OrganisationManagement.Created')} !<br />
        </h3>
        {errorFileURL && (
          <h3 className='text-center'>
            {t('OrganisationManagement.Download_Status_Note')}.
            <br />
            <a href={errorFileURL} rel='noopener noreferrer'>
              {t('OrganisationManagement.Download_File')}
            </a>
          </h3>
        )}
      </Modal>
    </>
  );
});

export default ImportComponent;
