import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Menu, Dropdown, Space, Form, Radio, Tooltip, Typography } from 'antd';
import useStore from '../../../store';
import Unauthorized from '../../../page/Unauthorized';
import ListComponent from './component/ListComponent';
import AddComponent from './component/AddComponentNew';
import EditComponent from './component/EditComponentNew';
import DeleteComponent from './component/DeleteComponent';
import CloneComponent from './component/CloneComponent';
import ViewComponent from './component/ViewComponent';
//import { default as MapAddComponent } from '../RoleMapping/component/AddComponent';
import { useHistory } from 'react-router-dom';
import RecordPerPage from '../../../config/RecordPerPage';
import ImportComponent from './component/ImportComponent';

import EnterFullscreen from '../../../component/EnterFullscreen';
import ApplyGridFilterNew from '../../../component/ApplyGridFilterNew';
import debounce from 'lodash/debounce';
import MergePeople from './component/MergePeople';
import { vsmNotify } from '../../../config/messages';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { filterFiltersByPageType } from '../../../utils/GlobalFunction';
import ResetGridOptions from '../../../component/ResetGridOptions';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const People = observer((props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const {
    AUTH,
    AUTH: { allPageFilters, allPageFiltersLoaded },
    PEOPLE,
    SETTINGS,
    DEALDESKSTORE: { setStopInvestmentHourCounter },
    PEOPLE: { isSavedFiltersLoadedPeople },
  } = useStore();
  const [tabselection, setTabselection] = useState(1);
  const [addDrawer, setAddDrawer] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  //const [mapDrawer, setMapDrawer] = useState(false);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  const [importDrawer, setImportDrawer] = useState(false);

  //----------------MERGE PEOPLE STATES---------------------------//
  const [selectedRowsCount, setSelectedRowsCount] = useState(false);
  const [mergeVisibleModal, setMergeVisibleModal] = useState(false);
  const [selectedMergeData, setSelectedMergeData] = useState(null);
  const [showCheckbox, setshowCheckbox] = useState(false);
  //----------------MERGE PEOPLE STATES---------------------------//
  const [goToPeopleRole, setGoToPeopleRole] = useState(false)

  const [editOpen, setEditOpen] = useState(false);

  const [form] = Form.useForm();
  const { SearchPage, AllView, sectorSelector, search_term, resultCount, handleRoute } = props;
  const { Title } = Typography;

  //open add drawer
  const openAddDrawer = () => {
    setAddDrawer(true);
  };
  //close add drawer
  const closeAddDrawer = () => setAddDrawer(false);

  const handleEditCloneApiCall = debounce((data) => {
    var payload = { people_id: data?.id };
    PEOPLE.editPeopleLoading = true;
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        PEOPLE.editPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.editPeopleLoading = false;
      });
  }, 300);

  //open edit drawer
  const openEditDrawer = (data, isEditOpen = false) => {
    setEditOpen(isEditOpen);
    PEOPLE.editPeopleLoading = true;
    setStopInvestmentHourCounter(true);
    setEditDrawer(true);
    handleEditCloneApiCall(data);
  };

  //close edit drawer
  const closeEditDrawer = () => {
    setEditDrawer(false);
    // if (!editOpen) {
    //   PEOPLE.peopleValues = null;
    // }
    setStopInvestmentHourCounter(false);
    setEditOpen(false);
  };

  // open delete modal
  const openDeleteModal = (data) => {
    setDeleteModal(true);
    PEOPLE.setDeleteValues(data);
  };
  //close delete modal
  const closeDeleteModal = () => setDeleteModal(false);

  //open clone drawer
  const openCloneDrawer = (data) => {
    PEOPLE.editPeopleLoading = true;
    setCloneDrawer(true);
    handleEditCloneApiCall(data);
    PEOPLE.setCloneValues(data);
  };

  //close clone drawer
  const closeCloneDrawer = () => {
    setCloneDrawer(false);
    PEOPLE.peopleValues = null;
    PEOPLE.setCloneValues(null);
  };

  //open map drawer
  // const openMapDrawer = () => {
  //   PEOPLE.peopleValues = null;
  //   setMapDrawer(true);
  // };

  //close map drawer
  // const closeMapDrawer = () => setMapDrawer(false);

  const handleViewDetailCall = debounce((data) => {
    PEOPLE.viewPeopleLoading = true;
    PEOPLE.setViewValues(data);
    PEOPLE.getViewPplDetail(data?.id)
      .then((data) => {
        PEOPLE.viewPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.viewPeopleLoading = false;
      });
  }, 300);

  const openViewDrawer = (data) => {
    if (AUTH.checkPermission(18, 'view-details')) {
      PEOPLE.viewPeopleLoading = true;
      setStopInvestmentHourCounter(true);
      setViewDrawer(true);
      handleViewDetailCall(data);
    }
  };
  const closeViewDrawer = () => {
    // PEOPLE.setViewValues([]);
    // PEOPLE.peopleValues = null;
    setStopInvestmentHourCounter(false);
    setViewDrawer(false);
  };

  //open bank role listing page
  const openRoleListing = (data) => {
    history.push(`/people/all-people/role-mapping/${data?.id}`);
  };
  const openRoleURL = () => {
    setGoToPeopleRole(true);
  };
  useEffect(() => {
    if (goToPeopleRole) {
      history.push(`/people/roles`);
    }
  }, [goToPeopleRole, history])

  //Menu to show export Organisations and Organisations Roles
  const menu = (
    <Menu>
      <Menu.Item key='people' onClick={PEOPLE.ExportPeople}>
        {t('Common_Data.People')}
      </Menu.Item>
      <Menu.Item key='people_roles' onClick={PEOPLE.ExportPeopleRoleMappint}>
        {t('Common_Data.People')} {t('OrganisationManagement.Roles')}
      </Menu.Item>
    </Menu>
  );

  const optionsRadiolist = [
    { label: `${t('Common_Data.People')}`, value: '1', disabled: true },
    {
      label: `${t('Common_Data.Organisations')}`, value: '2', disabled: !AUTH.checkPermission(16, 'list')
    },
  ];

  const optionsRadioPpl = [{ label: `${t('Common_Data.People')}`, value: '1', disabled: true }];

  const onRadiolistchange = (e) => {
    if (e.target.value > 1) {
      AUTH.updateSwitchOrgmenu(false);
      history.push(`/contacts/organisations`);
      setTabselection(2);
    }
  };

  const importData = () => setImportDrawer(true);

  //Close import Drawer
  const closeImportDrawer = () => {
    setImportDrawer(false);
  };

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(18, 'edit')) {
            if (result.key === 'Enter') {
              openEditDrawer(e.data);
            }
          }
          if (AUTH.checkPermission(18, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteModal(e.data);
            }
          }
        }
      }
    }
  };

  //START:-----------PEOPLE Merge Functions---------------------------//
  const toggleMergeTicket = () => {
    if (showCheckbox) {
      openMergeModal();
    } else {
      setshowCheckbox(true);
    }
  };

  const openMergeModal = () => {
    let selectedRow = PEOPLE.agGrid.api.getSelectedRows();
    if (selectedRow.length >= 2) {
      setMergeVisibleModal(true);
      setSelectedMergeData(selectedRow);
    } else {
      vsmNotify.error({
        message: `${t('Common_Data.Please_Select_Records')} 2 ${t('Common_Data.Record')}.`,
      });
    }
  };
  const closeMergeModal = () => {
    setshowCheckbox(false);
    setMergeVisibleModal(false);
    setSelectedMergeData(null);
    if (PEOPLE.agGrid) {
      PEOPLE.agGrid.api.deselectAll();
    }
    setSelectedRowsCount(0);
  };
  const selectionChanged = () => {
    let selectedRow = PEOPLE.agGrid.api.getSelectedRows();
    setSelectedRowsCount(selectedRow.length > 0 ? selectedRow.length : 0);
  };
  const onClearSelectionClick = () => {
    if (PEOPLE.agGrid) {
      PEOPLE.agGrid.api.deselectAll();
    }
    setshowCheckbox(false);
  };
  //END:-----------PEOPLE Merge Functions---------------------------//

  useEffect(() => { }, [isSavedFiltersLoadedPeople, allPageFiltersLoaded, allPageFilters]);

  const pageFilterType = 'people'; //FOR PROPS: TOP FILTER DROPDOWN LIST AND RESET OPTIONS

  return (
    <>
      {!AUTH.checkPermission(18, 'list') ? (
        <Unauthorized />
      ) :
        // SearchPage ? (
        //   <>
        //     <ListComponent
        //       openViewDrawer={openViewDrawer}
        //       openEditDrawer={openEditDrawer}
        //       openDeleteModal={openDeleteModal}
        //       openCloneDrawer={openCloneDrawer}
        //       openRoleListing={openRoleListing}
        //       SearchPage={SearchPage}
        //       AllView={AllView}
        //     />
        //     <AddComponent visible={addDrawer} close={closeAddDrawer} />
        //     <EditComponent
        //       visible={editDrawer}
        //       editOpen={editOpen}
        //       close={closeEditDrawer}
        //     />
        //     <DeleteComponent
        //       visible={deleteModal}
        //       close={closeDeleteModal}
        //       lineText={`${t('PeopleManagement.Delete_People')} ? `}
        //       SearchPage={SearchPage}
        //     />
        //     <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />
        //     <ViewComponent
        //       visible={viewDrawer}
        //       openEditDrawer={openEditDrawer}
        //       close={closeViewDrawer}
        //       handleViewDetailCall={handleViewDetailCall}
        //     />
        //     <ImportComponent
        //       visible={importDrawer}
        //       close={closeImportDrawer}
        //       importData={importData}
        //     />
        //   </>
        // ) : 
        (
          <>
            {!SearchPage ? <Radio.Group
              options={
                AUTH.checkPermission(16, 'list') ? optionsRadiolist : optionsRadioPpl
              }
              onChange={onRadiolistchange}
              value={tabselection}
              optionType='button'
              buttonStyle='solid'
              className='peopleOrgTabs'
            /> : null}
            <PageHeader
              title={!SearchPage ? ''
                : (<>
                  <div className='d-flex justify-content-start align-items-center notes'>
                    <Title level={3}>
                      {sectorSelector} : <Tooltip title={search_term} placement="topLeft">"{search_term?.length > 25 ? search_term.substring(0, 25) + "..." : search_term}"</Tooltip> {`${t('DealDesk.Results')}`}
                    </Title>
                    <div className='searchView ml-10 '>
                      <span className='sep'>|</span>
                      {resultCount ? resultCount.toLocaleString('en-GB') : resultCount}{' '}
                      {t('Common_Data.Records_found')}
                      <span className='sep'>|</span>
                      <span className='cursorPointer text-decoration-underline mr-10' onClick={handleRoute} >
                        {t('Common_Data.View_all')} {sectorSelector}
                      </span>
                    </div>
                  </div>
                </>)
              }
              className={SearchPage ? 'SearchResult-PageHeader ' + SETTINGS.fullscreen_class : 'peopleOrg ' + SETTINGS.fullscreen_class}
              extra={[
                <Form form={form} className='innerForm headerButton narrowButtons'>
                  <Space align='center' size={5}>
                    {selectedRowsCount || showCheckbox ? (
                      <Tooltip title={`${t('Common_Data.Click')} ${t('Common_Data.To')} ${t('Common_Data.Clear')}`}>
                        <Button
                          key='1'
                          shape='round'
                          size='medium'
                          onClick={onClearSelectionClick}
                        >
                          <img
                            src={AUTH.GetThemedImage('Refresh_NoSpace')}
                            alt=''
                            className='icon'
                          />
                        </Button>
                      </Tooltip>
                    ) : null}

                    <Tooltip title={showCheckbox ? `${t('Common_Data.Click')} ${t('Common_Data.To')} ${t('Common_Data.Merge')}` : `${t('Common_Data.Start')} ${t('PeopleManagement.Merging')}`}>
                      <Button
                        key='2'
                        shape='round'
                        size='medium'
                        onClick={toggleMergeTicket}
                      >
                        <img
                          src={AUTH.GetThemedImage('Hyperlink_icn')}
                          alt=''
                          className='icon'
                        />
                      </Button>
                    </Tooltip>

                    {!SearchPage ? <ApplyGridFilterNew
                      type={pageFilterType}
                      selectionValue={PEOPLE.selectedGridFilter}
                      filterList={filterFiltersByPageType(allPageFilters, pageFilterType)}
                    /> : null}
                    {!SearchPage ? AUTH.checkPermission(19, 'add') && (
                      <Button key='3' shape='round' size='medium' onClick={openRoleURL}>
                        {t('Common_Data.People')} {t('PeopleManagement.Role')}
                      </Button>
                    ) : null}
                    {!SearchPage ? AUTH.checkPermission(18, 'import') && (
                      <Button shape='round' size='medium' key='4' onClick={importData}>
                        {t('Common_Data.Import')}
                      </Button>
                    ) : null}
                    {!SearchPage ? AUTH.checkPermission(18, 'export-people') && (
                      <Dropdown key='5' overlay={menu}>
                        <Button
                          shape='round'
                          size='medium'
                          loading={PEOPLE.exportLoadingIcn}
                          key='5btn'
                          onClick={(e) => e.preventDefault()}
                        >
                          {t('Common_Data.Export')}
                        </Button>
                      </Dropdown>
                    ) : null}
                    {/* {!SearchPage ? AUTH.checkPermission(18, 'role-mapping-list') && (
                    <Button shape='round' size='medium' key='5' onClick={openMapDrawer}>
                      Add People Mapping
                    </Button>
                  ):null} */}
                    {!SearchPage ? AUTH.checkPermission(18, 'add') && (
                      <Button shape='round' size='medium' key='6' onClick={openAddDrawer}>
                        {t('Common_Data.Add')} {t('Common_Data.People')}
                      </Button>
                    ) : null}

                    <ResetGridOptions key={'7'} pageType={pageFilterType} />

                    <EnterFullscreen key='8' />
                    <RecordPerPage
                      key='9'
                      initialValue={PEOPLE.per_page + ' per page'}
                      onChange={PEOPLE.setPageSize}
                      className='narrowRecordPerPage'
                    />
                  </Space >
                </Form >,
              ]}
            >
              <ListComponent
                openViewDrawer={openViewDrawer}
                openEditDrawer={openEditDrawer}
                openDeleteModal={openDeleteModal}
                openCloneDrawer={openCloneDrawer}
                openRoleListing={openRoleListing}
                SearchPage={SearchPage}
                onCellKeyDown={onCellKeyDown}
                selectionChanged={selectionChanged}
                showCheckbox={showCheckbox}
                AllView={AllView}
              />
              <AddComponent visible={addDrawer} close={closeAddDrawer} />
              <EditComponent
                visible={editDrawer}
                editOpen={editOpen}
                close={closeEditDrawer}
              />
              <DeleteComponent
                visible={deleteModal}
                close={closeDeleteModal}
                lineText={`${t('PeopleManagement.Delete_Person')} ? `}
                SearchPage={SearchPage}
              />
              <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />
              <ViewComponent
                visible={viewDrawer}
                openEditDrawer={openEditDrawer}
                editDrawer={editDrawer}
                close={closeViewDrawer}
                editOpen={editOpen}
                handleViewDetailCall={handleViewDetailCall}
              />
              {/* <MapAddComponent
              visible={mapDrawer}
              close={closeMapDrawer}
              callList={() => PEOPLE.setupGrid(PEOPLE.agGrid)}
            /> */}
              <ImportComponent
                visible={importDrawer}
                close={closeImportDrawer}
                importData={importData}
              />
              <MergePeople
                visible={mergeVisibleModal}
                close={closeMergeModal}
                selectedMergeData={selectedMergeData}
              />
              {/* {!stopInvestmentHourCounter && <TrackTimeComponent pageName={'people'} />} */}
              <TimeTracker
                page={"people"}
                stop={addDrawer || editDrawer || cloneDrawer || viewDrawer || goToPeopleRole}
              />
            </PageHeader >
          </>
        )}
    </>
  );
});

export default People;
