import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Drawer, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import FormComponent from './FormComponent';
import useStore from '../../../../store';
import { vsmDealDesk } from '../../../../config/messages';
import { vsmNotify } from '../../../../config/messages';
// import moment from 'moment'

const AddComponent = observer((props) => {
  const { visible } = props;

  const [form] = Form.useForm();

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const {
    SCORELIBRARYSTORE: { Score, SetScore, saveScoreData, fetchScoreLibrary },
  } = useStore();

  const [saving, setSaving] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const { t } = useTranslation();

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        if (Score.length > 0) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  const handleSubmit = (data) => {
    data = form.getFieldsValue();
    data.is_library = true;
    data.score_json = JSON.stringify(Score);
    //-------------------- Get Last Active Element ID (Auto Save Only) -------------//
    setSaving(true);

    saveScoreData(0, data)
      .then((data) => {
        fetchScoreLibrary();
        close();
        vsmNotify.success({
          message: vsmDealDesk.score.saveSuccess,
        });
      })
      .catch((e) => {
        if (e.errors) {
          let schedule_description_error = 0;
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (e === 'description') {
                  schedule_description_error = 1;
                }
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });

          if (schedule_description_error) {
            vsmNotify.error({
              message: `${t('DealDesk.Schedule_Description')}!`,
            });
          } else {
            form.setFields(e.errors);
          }
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const clearForm = () => {
    form.resetFields();
    setDisabled(true);
    setSaving(false);
    SetScore([]);
  };

  const close = () => {
    form.resetFields();
    setDisabled(true);
    setSaving(false);
    SetScore([]);
    props.close();
  };

  return (
    <Drawer
      title={`${t('DealLibrary.Score')} ${t('DealDesk.Library')}`}
      visible={visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      destroyOnClose={true}
      footer={[
        <>
          <div className='DealLibrary-ScheduleFormFooter'>
            <div className='mr-10'>
              <Checkbox checked={true} disabled>
                {t('DealDesk.Save_Template')}
              </Checkbox>
              {/* <label className='mr-10'>Save as Template</label> */}
            </div>
            <div>
              {/* <AutosaveNotifiy saveType={saveType} /> */}
              <Button
                key='1'
                form={`addfromScore`}
                className='mr-10'
                loading={saving}
                htmlType='submit'
                type='primary'
                disabled={disabled}
                shape='round'
                size='medium'
              >
                {t('Common_Data.Save')}
              </Button>
              <Button
                shape='round'
                className='mr-10'
                size='medium'
                onClick={close}
                key='2'
              >
                {t('Common_Data.Cancel')}
              </Button>
              <Button
                shape='round'
                size='medium'
                onClick={() => {
                  clearForm();
                }}
                key='3'
              >
                {t('Common_Data.Clear')}
              </Button>
            </div>
          </div>
        </>,
      ]}
    >
      <FormComponent
        form={form}
        onChange={handleChange}
        Score={Score}
        forceUpdate={forceUpdate}
        SetScore={SetScore}
        handleSubmit={handleSubmit}
        id={`addfromScore`}
      />
    </Drawer>
  );
});

export default AddComponent;
