import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import MergeOrganisationListing from './MergeOrganisationListing';
import { vsmNotify } from '../../../../config/messages';
import ConfirmMergeComponent from './ConfirmMergeComponent';
import { useTranslation } from 'react-i18next';

const MergeOrganisation = observer((props) => {
  const {t} = useTranslation()
  const [saving, setSaving] = useState(false);
  const [confirmMerge, setconfirmMerge] = useState(false);

  const { selectedMergeData, visible } = props;

  const {
    ORGANISATION,
    ORGANISATION: { masterOrgToMerge, setMasterOrgToMerge, handleMergeOrganisation },
  } = useStore();

  useEffect(() => {}, [masterOrgToMerge]);

  const close = () => {
    setMasterOrgToMerge(null);
    setSaving(false);
    setconfirmMerge(false);
    props.close();
  };

  //---------------------MERGE CONFIRM FUNCTIONS -------------------------//
  const openConfirmMergeModal = () => {
    setconfirmMerge(true);
  };

  const closeConfirmMerge = () => {
    setconfirmMerge(false);
    setSaving(false);
  };
  //---------------------MERGE CONFIRM FUNCTIONS -------------------------//

  const handleSubmit = () => {
    let selectedArray = [];

    selectedMergeData &&
      selectedMergeData.forEach((element) => {
        selectedArray.push(element.id);
      });

    if (masterOrgToMerge && selectedArray && selectedArray.length) {
      setSaving(true);
      let payload = {};
      payload = {
        selectedOrganisations: selectedArray,
        mergeOrganisation: masterOrgToMerge,
      };

      handleMergeOrganisation(payload)
        .then((data) => {
          ORGANISATION.setupGrid(ORGANISATION.agGrid);
          close();
          vsmNotify.success({
            message: `${t('Common_Data.Organisation')} ${t('OrganisationManagement.Merged')} ${t('Common_Data.Successfully')}!`,
          });
        })
        .catch((e) => {
          if (e.errors && e.errors.length > 0) {
            e.errors.forEach((x) => {
              if (x.name.indexOf('.') !== -1) {
                x.name = x.name.split('.');
                x.name[1] = parseInt(x.name[1]);
              }
            });
          }
        })
        .finally(() => setSaving(false));
    }
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Merge')} ${t('Common_Data.Organisation')}`}
      visible={visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      width={1200}
      destroyOnClose
      footer={[
        <Button
          key='1'
          shape='round'
          size='medium'
          disabled={masterOrgToMerge ? false : true}
          // loading={saving}
          onClick={() => openConfirmMergeModal()}
        >
          {t('Common_Data.Merge')} {t('Common_Data.Organisation')}
        </Button>,
      ]}
    >
      <h5>
        {t('OrganisationManagement.Select_Primary_Organisation')}.
      </h5>
      <MergeOrganisationListing selectedMergeData={selectedMergeData} />
      <ConfirmMergeComponent
        visible={confirmMerge}
        close={closeConfirmMerge}
        handleSubmit={handleSubmit}
        saving={saving}
        setSaving={setSaving}
      />
    </Modal>
  );
});
export default MergeOrganisation;
