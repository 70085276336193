import React from 'react';
import Blank from '../page/Blank';
import PageNotFound from '../page/commonpage/PageNotFound';
import Unauthorized from '../page/commonpage/Unauthorized';
import Dashboard from '../page/Dashboard';
import Login from '../page/Login';
import ForgetPassword from '../page/ForgetPassword';
import ResetPassword from '../page/ResetPassword';
import TableExample from '../page/TableExample';
import Settings from '../page/Settings';
import Usergroup from '../page/usergroups';
import CredebtClassification from '../page/CredebtClassification';
// import LeadStatus from '../page/LeadStatus';
import Users from '../page/users';
import Knowledge from '../page/Knowledge';
import TickerTapBroadcast from '../page/TickerTapBroadcast';
import Contracts from '../page/Contracts';
import AuditLogs from '../page/AuditLogs';
import LeadListing from '../page/LeadListing';
import LeadKanbanView from '../page/LeadKanbanView';
import Tickets from '../page/Tickets';
import Tags from '../page/Tags';
import People from '../page/PeopleManagement/People';
import PeopleRoleMapping from '../page/PeopleManagement/RoleMapping';
import PeopleRoles from '../page/PeopleManagement/Administration/Roles';
import Organisations from '../page/OrganisationManagement/AllOrganisations';
import RoleMapping from '../page/OrganisationManagement/RoleMapping';
import Roles from '../page/OrganisationManagement/Administration/Roles';
import ViewOrganisation from '../page/OrganisationManagement/AllOrganisations/component/ViewComponent';
import DealDesk from '../page/DealDesk';
import Promotion from '../page/Promotion';
import BlockEditAssign from '../page/BlockEditAssign';
import Productivity from '../page/Productivity';
import ManagementAllDepartments from '../page/ManagementAllDepartments';
import Waste from '../page/Waste';
import DealBuilder from '../page/DealBuilder';
import TransactionFields from '../page/DealBuilder/TransactionFields';
import Search from '../page/Search';
import Reports from '../page/Reports/SavedReports';
import SavedReportsNew from '../page/Reports/SavedReportsV1';
import DealLibrary from '../page/DealLibrary';
import Reminders from '../page/Reminders';
import LeadListingOpportunity from '../page/LeadListing/Opportunities';
import LeadListingDeals from '../page/LeadListing/Deals';
import LeadListingActive from '../page/LeadListing/ActiveLeads';
import Comingsoon from '../page/commonpage/Comingsoon';
import AllLeads from '../page/LeadListing/AllLeads';
import LeadStatusKanbanView from '../page/LeadStatus/LeadStatusKanbanView';
import SavedFilter from '../page/SavedFilter';
// import Notes from '../page/Notes';
import Sequencer from '../page/Sequencer';

export const RouterConfig = [
  {
    title: 'Login',
    path: '/',
    component: Login,
    default: 'beforeAuth',
  },
  {
    title: 'Login',
    path: '/login',
    component: Login,
  },
  {
    title: 'Forget Password',
    path: '/forget-password',
    component: ForgetPassword,
    default: true,
  },
  {
    title: 'Reset Password',
    path: '/reset/:id',
    component: (props) => <ResetPassword {...props} />,
    default: true,
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    default: 'AfterAuth',
    auth: true,
  },
  {
    title: 'User Group',
    path: '/user-groups',
    component: Usergroup,
    auth: true,
  },
  {
    title: 'Credebt Classification',
    path: '/credebt-classification',
    component: CredebtClassification,
    auth: true,
  },
  {
    title: 'Contracts',
    path: '/contracts',
    component: Contracts,
    auth: true,
  },
  {
    title: 'Deal Status',
    path: '/lead-status',
    component: LeadStatusKanbanView,
    default: 'AfterAuth',
    auth: true,
  },
  // {
  //   title: 'Audit Logs',
  //   path: '/audit-logs',
  //   component: AuditLogs,
  //   default: 'AfterAuth',
  //   auth: true,
  // },
  // {
  //   title: 'Ticker Tap Broadcast',
  //   path: '/ticker-tape-broadcast',
  //   component: TickerTapBroadcast,
  //   default: 'AfterAuth',
  //   auth: true,
  // },
  {
    title: 'Audit Logs',
    path: '/audit-logs',
    component: AuditLogs,
    default: 'AfterAuth',
    auth: true,
  },
  {
    title: 'Ticker Tap Broadcast',
    path: '/ticker-tape-broadcast',
    component: TickerTapBroadcast,
    default: 'AfterAuth',
    auth: true,
  },
  {
    title: 'Users',
    path: '/users',
    component: Users,
    auth: true,
  },
  {
    title: 'Blank',
    path: '/blank',
    component: Blank,
    auth: true,
  },
  {
    title: 'Contracts',
    path: '/contracts',
    component: Contracts,
    auth: true,
  },
  {
    title: 'Table Example',
    path: '/table-example',
    component: TableExample,
    auth: true,
  },
  {
    title: 'Settings',
    path: '/settings',
    component: Settings,
    auth: true,
  },
  {
    title: 'Knowledge',
    path: '/knowledge',
    component: Knowledge,
    auth: true,
  },
  {
    title: 'Role Mapping',
    path: '/people/all-people/role-mapping/:id',
    component: PeopleRoleMapping,
    auth: true,
    setting: { header: true, nav: true },
  },
  {
    title: 'People Roles',
    path: '/people/roles',
    component: PeopleRoles,
    auth: true,
    setting: { header: true, nav: true },
  },
  {
    title: 'People',
    path: '/contacts/people',
    component: People,
    auth: true,
  },
  {
    title: 'Opportunities',
    path: '/leads/opportunities',
    component: LeadListingOpportunity,
    auth: true,
  },
  {
    title: 'Deals',
    path: '/leads/deals',
    component: LeadListingDeals,
    auth: true,
  },
  {
    title: 'Unused',
    path: '/leads/unused',
    component: LeadListing, // LeadListingUnused,
    auth: true,
  },
  {
    title: 'Leads',
    path: '/leads/active',
    component: LeadListingActive,
    auth: true,
  },
  {
    title: 'Deals',
    path: '/leads/deals/kanban',
    component: LeadKanbanView,
    auth: true,
  },
  {
    title: 'Opportunities',
    path: '/leads/opportunities/kanban',
    component: LeadKanbanView,
    auth: true,
  },
  {
    title: 'Leads',
    path: '/leads/active/kanban',
    component: LeadKanbanView,
    auth: true,
  },
  {
    title: 'All Leads',
    path: '/leads/all',
    component: AllLeads,
    auth: true,
  },
  {
    title: 'Unused Kanban',
    path: '/leads/unused/kanban',
    component: LeadKanbanView,
    auth: true,
  },
  {
    title: 'Tickets',
    path: '/tickets',
    component: Tickets,
    auth: true,
  },
  {
    title: 'Tags',
    path: '/tags',
    component: Tags,
    auth: true,
  },
  {
    title: 'View Organisation',
    path: '/organisations/all-organisations/view-organisation/:id',
    component: ViewOrganisation,
    auth: true,
    setting: { header: true, nav: true },
  },
  {
    title: 'Organisations',
    path: '/contacts/organisations',
    component: Organisations,
    auth: true,
    setting: { header: true, nav: true },
  },
  {
    title: 'Role Mapping',
    path: '/organisations/all-organisations/role-mapping/:id',
    component: RoleMapping,
    auth: true,
    setting: { header: true, nav: true },
  },
  {
    title: 'roles',
    path: '/organisations/administration/roles',
    component: Roles,
    auth: true,
    setting: { header: true, nav: true },
  },
  {
    title: 'Deal Desk',
    path: '/dealdesk/:id',
    component: (props) => <DealDesk {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Ticket Desk',
    path: '/ticket/:id',
    component: (props) => <DealDesk {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'People Desk',
    path: '/people/:id',
    component: (props) => <DealDesk {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Organisation Desk',
    path: '/organisation/:id',
    component: (props) => <DealDesk {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Promotion',
    path: '/promotion',
    component: (props) => <Promotion {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Block Edit/Assign',
    path: '/management/block-edit-assign',
    component: (props) => <BlockEditAssign {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Productivity',
    path: '/productivity',
    component: (props) => <Productivity {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Management - All Departments',
    path: '/management/all-departments',
    component: (props) => <ManagementAllDepartments {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Waste',
    path: '/waste',
    component: (props) => <Waste {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Deal Builder',
    path: '/deal-builders',
    component: (props) => <DealBuilder {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Deal Maker Fields',
    path: '/deal-builders/deal-maker-fields',
    component: (props) => <TransactionFields {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Search',
    path: '/search',
    component: (props) => <Search {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Applications',
    path: '/applications',
    component: (props) => <Sequencer {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Search',
    path: '/search/:slug',
    component: (props) => <Search {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Reports',
    path: '/reports',
    component: (props) => <SavedReportsNew {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Reports',
    path: '/reports_v1',
    component: (props) => <Reports {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Library',
    path: '/deals-library',
    component: (props) => <DealLibrary {...props} />,
    auth: true,
    default: true,
  },
  {
    title: 'Reminders',
    path: '/reminders',
    component: Reminders,
    auth: true,
    default: true,
  },
  {
    title: 'Page Filters',
    path: '/page-filters',
    component: SavedFilter,
    auth: true,
    default: true,
  },
  {
    title: 'calendar',
    path: '/calendar',
    component: Comingsoon,
    exact: 'false',
    errorpage: true,
  },
  {
    title: 'notes',
    path: '/notes',
    component: Comingsoon,
    auth: true,
    default: true,
  },
  {
    title: 'Unauthorized',
    path: '/unauthorized',
    component: Unauthorized,
    errorpage: true,
  },
  {
    title: 'PageNotFound',
    path: '/',
    component: PageNotFound,
    exact: 'false',
    errorpage: true,
  },
];
