import { Button, notification } from "antd";

const Notify = {
  success: (data) => {
    notification.success({ placement: "bottomRight", duration: 3, ...data });
  },
  error: (data) => {
    notification.error({ placement: "bottomRight", duration: 4, ...data });
  },
  custome: (data) => {
    const btn = (
      <>
        <Button
          type="primary"
          size="small"
          onClick={() => window.open(data.data.click_action, "_blank")}
        >
          Go to Details
        </Button>
      </>
    );
    notification.open({
      message: data.notification.title,
      description: data.notification.body,
      btn,
      placement: "bottomRight",
      className: "notification_wrapper",
    });
  },
};

export { Notify };
