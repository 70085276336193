import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Popover, Tooltip } from 'antd';
import useStore from '../../../../store';
import Attachments from '../../../../component/Attachments';
import { useLocation } from 'react-router-dom';
import TagsBorder from '../../../../include/images/TagsBorder.png';
import quoteSVG from '../../../../assets/images/Quote.svg';
import TagsGray from '../../../../include/images/TagsGray.png';
import EditViewComponent from '../../../Tags/component/EditViewComponent';
import { dateCompare } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

// import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import moment from 'moment';

const DisplayMsgList = observer((props) => {
  const { t } = useTranslation();
  const [viewTagDrawer, setViewTagDrawer] = useState(false);
  const [loadingEditView, setLoadingEditView] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const location = useLocation();

  const {
    AUTH,
    DEALDESKSTORE,
    TagsStore,
    DEALDESKNOTESEQUENCE: { noteLoader },
  } = useStore();

  // Scroll to note from tag list
  useEffect(() => {
    if (location.hash) {
      if (
        location.hash &&
        location.hash.substr(0, 2) === '#n' &&
        location.hash !== '#nogo'
      ) {
        let checkNoteExists = document.getElementById(location.hash);
        if (checkNoteExists) {
          document.getElementById(location.hash).scrollIntoView();
          forceUpdate();
        }
      }
    }
  }, [location.hash, forceUpdate, noteLoader]);

  const openTagViewDrawer = (data) => {
    setLoadingEditView(true);
    TagsStore.setViewValues(data).then((res) => {
      TagsStore.viewValues = res;
      setLoadingEditView(false);
      setViewTagDrawer(true);
    });
  };
  const closeViewTagDrawer = () => setViewTagDrawer(false);

  const lockedUsersList = (lockedUsers) => (
    <>{lockedUsers && lockedUsers.map((t, index) => <li key={index}>{t}</li>)}</>
  );

  const openWasteNote = (item) => {
    localStorage.setItem('waste_filter_note_id', item.id);
    window.open(`/waste`, '_blank');
  };

  return (
    <>
      <div className='DisplayMsgList mb-30' id='Scroll_top'>
        {props.msgBoxArray &&
          props.msgBoxArray.map((item, index) => {

            let OwnerIsLoggin = item?.tagged_user[0]?.tag_holder === AUTH?.user?.id

            let item_date_different = true;
            if (item.notes_date && item.created_at) {
              item_date_different = dateCompare(
                new Date(item.notes_date),
                new Date(item.created_at)
              );
            }

            let approvedTagNoteDesc = item?.notes_description?.includes('<approved');
            let ticketHolderAssigned = item?.notes_description?.includes('<ticketHolderAssigned');
            let requestResetDeadline = item?.notes_description?.includes('<requestResetDeadline');
            let resetDeadlineapproved = item?.notes_description?.includes('<resetDeadlineApproved');
            let requestResetDeadlineDecline = item?.notes_description?.includes('<deadlineResetRequestDeclined');

            let ticketCompletedByHolder = item?.notes_description?.includes('<ticketCompletedByHolder');
            let ticketClosedByOwner = item?.notes_description?.includes('<ticketClosedByOwner');
            let ticketReopenByOwner = item?.notes_description?.includes('<ticketReopenByOwner');

            let requestForResetHoursRequired = item?.notes_description?.includes('<requestForResetHoursRequired');
            let resetHoursRequestDeclined = item?.notes_description?.includes('<resetHoursRequestDeclined');


            let replaceStr = item?.notes_description?.split('c#');
            let cID = '';
            if (replaceStr?.length >= 2) {
              cID = replaceStr[1];
              cID = cID.replace('>', '');
            }

            return (
              !item.is_autosaved && (
                <div
                  key={index}
                  className={
                    (item.deleted_at || approvedTagNoteDesc || resetDeadlineapproved || requestResetDeadline || ticketHolderAssigned || ticketClosedByOwner || ticketCompletedByHolder || requestResetDeadlineDecline || ticketReopenByOwner || requestForResetHoursRequired || resetHoursRequestDeclined)
                      ? `deletedNote`
                      : `msgBox 
                        ${DEALDESKSTORE.msgBoxIndex === index ? 'active' : ''}
                        ${item.notes_flag &&
                      ((item.notes_flag === 2 ? 'comments' : '') ||
                        (item.notes_flag === 4 ? 'incoming' : '') ||
                        (item.notes_flag === 3 ? 'conversation' : '') ||
                        (item.notes_flag === 1 ? 'outgoing' : ''))
                      } ${item.current_bg_color && props.commentList === true
                        ? ''
                        : item.current_bg_color === ''
                          ? ''
                          : item.current_bg_color
                            ? item.current_bg_color.replace('#', 'x')
                            : ''
                      } `
                  }
                  onClick={() => {

                    if (!item.deleted_at) {
                      if (!approvedTagNoteDesc) {
                        DEALDESKSTORE.setMsgBoxIndex(index, props.type, props.dealid, item.id);
                      }
                    } else {
                      openWasteNote(item);
                    }

                  }}
                  id={`#n${item.id}`}
                >

                  {item.deleted_at
                    ? (
                      <>
                        <div className='text' onClick={() => { DEALDESKSTORE.setDisableFooterIcons(true); }}>
                          <span>{` c#${item.sequence} - ${t('DealDesk.Deleted_By')} ${item.updated_by_name
                            } ${AUTH.getLocalTime(
                              item.deleted_at,
                              'YYYY-MM-DD HH mm a'
                            )}`}</span>
                        </div>
                      </>
                    )
                    : item.notes_description && requestForResetHoursRequired
                      ? (
                        <>
                          {item.tagged_user && item.tagged_user.map((tagged, index) => {
                            return <>
                              <div key={index} className={`text ${OwnerIsLoggin && 'cursorPointer'}`}
                                onClick={() => {
                                  DEALDESKSTORE.setDisableFooterIcons(true);
                                  OwnerIsLoggin && openTagViewDrawer(tagged);
                                }}
                              >
                                <span>
                                  {` c#${item.sequence} - ${item.updated_by_name} requested change in Time Required : `}
                                  <span style={{ margin: 0, padding: 0 }} dangerouslySetInnerHTML={{
                                    __html: item.notes_description
                                  }}></span>{" "}
                                  {OwnerIsLoggin && <Tooltip title={`${item?.tagged_user[0]?.user?.full_name} - ${item?.tagged_user[0]?.tag_number} | ${AUTH.getLocalTime(item.updated_at)} `}>
                                    <img src={AUTH.GetThemedImage('Tags')} alt='' className='icon' style={{ width: "1.4rem" }} />
                                  </Tooltip>}
                                </span>
                              </div>
                            </>
                          })
                          }
                        </>
                      )
                      : item.notes_description && ticketReopenByOwner
                        ? (
                          <>
                            <div className='text' onClick={() => { DEALDESKSTORE.setDisableFooterIcons(true); }}>
                              <span>{` c#${item.sequence} - `}
                                <span style={{ margin: 0, padding: 0 }} dangerouslySetInnerHTML={{
                                  __html: item.notes_description
                                }}></span>{" "}<Tooltip title={"Note Date: " + moment(item.notes_date).format(AUTH.global_fulldate_format)}><InfoCircleOutlined className='cursorPointer' /></Tooltip>
                              </span>
                            </div>
                          </>
                        )
                        : item.notes_description && resetHoursRequestDeclined
                          ? (
                            <>
                              <div className='text' onClick={() => { DEALDESKSTORE.setDisableFooterIcons(true); }}>
                                <span>{` c#${item.sequence} - `}
                                  <span style={{ margin: 0, padding: 0 }} dangerouslySetInnerHTML={{
                                    __html: item.notes_description
                                  }}></span>{" "}<Tooltip title={"Note Date: " + moment(item.notes_date).format(AUTH.global_fulldate_format)}><InfoCircleOutlined className='cursorPointer' /></Tooltip>
                                </span>
                              </div>
                            </>
                          )
                          : item.notes_description && requestResetDeadlineDecline
                            ? (
                              <>
                                <div className='text' onClick={() => { DEALDESKSTORE.setDisableFooterIcons(true); }}>
                                  <span>{` c#${item.sequence} - `}
                                    <span style={{ margin: 0, padding: 0 }} dangerouslySetInnerHTML={{
                                      __html: item.notes_description
                                    }}></span>{" "}<Tooltip title={"Note Date: " + moment(item.notes_date).format(AUTH.global_fulldate_format)}><InfoCircleOutlined className='cursorPointer' /></Tooltip>
                                  </span>
                                </div>
                              </>
                            )
                            : item.notes_description && resetDeadlineapproved
                              ? (
                                <>
                                  <div className='text' onClick={() => { DEALDESKSTORE.setDisableFooterIcons(true); }}>
                                    <span>
                                      {` c#${item.sequence} - ${item.updated_by_name} changed deadline on `}
                                      {/* <span style={{ margin: 0, padding: 0 }} dangerouslySetInnerHTML={{
                        __html: item.notes_description
                      }}></span>  */}
                                      {AUTH.getLocalTime(item.updated_at, AUTH.global_fulldate_format)} </span>
                                  </div>
                                </>
                              )
                              : item.notes_description && ticketHolderAssigned
                                ? (
                                  <>
                                    <div className='text' onClick={() => { DEALDESKSTORE.setDisableFooterIcons(true); }}>
                                      <span>{` c#${item.sequence} - `}
                                        <span dangerouslySetInnerHTML={{
                                          __html: item?.notes_description
                                        }}></span>{" "}<Tooltip title={"Note Date: " + moment(item.notes_date).format(AUTH.global_fulldate_format)}><InfoCircleOutlined className='cursorPointer' /></Tooltip>
                                      </span>
                                    </div>
                                  </>
                                )
                                : item.notes_description && ticketClosedByOwner
                                  ? (
                                    <>
                                      <div className='text' onClick={() => { DEALDESKSTORE.setDisableFooterIcons(true); }}>
                                        {/* <span> */}{` c#${item.sequence} - `}
                                        <span dangerouslySetInnerHTML={{
                                          __html: item?.notes_description
                                        }}></span>{" "}<Tooltip title={"Note Date: " + moment(item.notes_date).format(AUTH.global_fulldate_format)}><InfoCircleOutlined className='cursorPointer' /></Tooltip>
                                        {/* </span> */}
                                      </div>
                                    </>
                                  )
                                  : item.notes_description && ticketCompletedByHolder
                                    ? (<>
                                      {
                                        item.tagged_user && item.tagged_user.map((tagged, index) => {
                                          return <>

                                            <div key={index} className={`text ${OwnerIsLoggin && 'cursorPointer'}`}
                                              onClick={() => {
                                                DEALDESKSTORE.setDisableFooterIcons(true);
                                                OwnerIsLoggin && openTagViewDrawer(tagged);
                                              }}
                                            >
                                              <span>{` c#${item.sequence} - `}
                                                <span dangerouslySetInnerHTML={{
                                                  __html: item?.notes_description
                                                }}></span>{" "}
                                                {OwnerIsLoggin && <Tooltip title={`${item?.tagged_user[0]?.user?.full_name} - ${item?.tagged_user[0]?.tag_number} | ${AUTH.getLocalTime(item.updated_at)} `}>
                                                  <img src={AUTH.GetThemedImage('Tags')} alt='' className='icon' style={{ width: "1.4rem" }} />
                                                </Tooltip>}
                                              </span>
                                            </div>
                                          </>
                                        })
                                      }
                                    </>)
                                    : item.notes_description && approvedTagNoteDesc
                                      ? (
                                        <>
                                          <div className='text' onClick={() => { DEALDESKSTORE.setDisableFooterIcons(true); }}>
                                            <span>{` c#${item.sequence} - ${t(
                                              'Common_Data.Note'
                                            )}: c#${cID} ${t('DealDesk.Approved_By')} ${item.updated_by_name
                                              } on ${AUTH.getLocalTime(item.updated_at, AUTH.global_fulldate_format)}`}</span>
                                          </div>
                                        </>
                                      )

                                      : item.notes_description && requestResetDeadline
                                        ? (
                                          <>
                                            {item.tagged_user && item.tagged_user.map((tagged, index) => {
                                              let date = item.notes_description.split('>',)
                                              let newDate = date && date[1]?.split('<',)
                                              return <>
                                                <div key={index} className={`text ${OwnerIsLoggin && 'cursorPointer'}`}
                                                  onClick={() => {
                                                    DEALDESKSTORE.setDisableFooterIcons(true);
                                                    OwnerIsLoggin && openTagViewDrawer(tagged);
                                                  }}
                                                >
                                                  <span>
                                                    {` c#${item.sequence} - ${item.updated_by_name} requested new deadline : `}
                                                    {moment(newDate && newDate[0]).format(AUTH.global_fulldate_format) + ' '}
                                                    {OwnerIsLoggin && <Tooltip title={`${item?.tagged_user[0]?.user?.full_name} - ${item?.tagged_user[0]?.tag_number} | ${AUTH.getLocalTime(item.updated_at)} `}>
                                                      <img src={AUTH.GetThemedImage('Tags')} alt='' className='icon' style={{ width: "1.4rem" }} />
                                                    </Tooltip>}
                                                  </span>
                                                </div>
                                              </>
                                            })
                                            }
                                          </>
                                        )
                                        : (
                                          <>
                                            <div className='header'
                                              onClick={() => { DEALDESKSTORE.setDisableFooterIcons(false); }}
                                            >
                                              <div className='details'>
                                                <strong>{item.sent_by_name}</strong>
                                              </div>
                                              <div className='details'>
                                                {/* {AUTH.getLocalTime(item.notes_date)} */}
                                                {AUTH.getLocalTimeDealDesk(
                                                  item.notes_date,
                                                  `${AUTH.global_dateformat} ${AUTH.global_timeformat}`
                                                )}
                                              </div>
                                              {item_date_different ? (
                                                <div className='details detailsNoteCreated'>
                                                  <img
                                                    style={{ width: 12, height: 12 }}
                                                    alt=''
                                                    src={AUTH.GetThemedImage('Date_Time_icn')}
                                                  />
                                                  {` `}
                                                  {/* {` ${AUTH.getLocalTime(
                              item.created_at,
                              AUTH.global_dateformat
                            )}`} */}
                                                  {AUTH.getLocalTimeDealDesk(
                                                    item.created_at,
                                                    `${AUTH.global_dateformat} ${AUTH.global_timeformat}`
                                                  )}
                                                </div>
                                              ) : null}
                                              <div className='dealdeskNoteNo'>
                                                {item.ticket_id ? (
                                                  <Tooltip
                                                    placement='topLeft'
                                                    title={item.ticket_number}
                                                  >
                                                    <Link to={`/ticket/${item.ticket_number}`} target='_blank'>
                                                      <img
                                                        className='ticketIcon'
                                                        alt=''
                                                        src={AUTH.GetThemedImage('Tickets')}
                                                      />
                                                    </Link>
                                                  </Tooltip>
                                                ) : null}
                                                <a href={`#n${item.id}`}>
                                                  {'c'}#{item.sequence}
                                                </a>
                                              </div>
                                            </div>

                                            {item.notes_flag === 3 ? (
                                              // Dated Message CSS QUOTE Changes
                                              <div
                                                onClick={() => { DEALDESKSTORE.setDisableFooterIcons(false); }}
                                                className='msgContent'
                                                dangerouslySetInnerHTML={{
                                                  __html: `
                                        <div class="content conversation-quote">
                                          <div class="qStart">
                                            <img
                                              src=${quoteSVG}
                                              alt='“'
                                            />
                                          </div>
                                          ${item.notes_description}
                                          <div class="qEnd">
                                            <img
                                              src=${quoteSVG}
                                              alt='”'
                                            />
                                          </div>
                                        </div>
                                    `?.replace(/href/g, "target='_blank' href"),
                                                }}
                                              ></div>
                                            ) : (
                                              <div
                                                onClick={() => { DEALDESKSTORE.setDisableFooterIcons(false); }}
                                                className='msgContent'
                                                dangerouslySetInnerHTML={{
                                                  __html: item.notes_description?.replace(
                                                    /href/g,
                                                    "target='_blank' href"
                                                  ),
                                                }}
                                              ></div>
                                            )}

                                            <div className='footer'
                                              onClick={() => { DEALDESKSTORE.setDisableFooterIcons(false); }}
                                            >
                                              {props.commentList === true
                                                ? ''
                                                : item.is_tagged
                                                  ? item.tagged_user &&
                                                  item.tagged_user.map((tagged) => {

                                                    return (
                                                      <Tooltip placement='top' title={tagged?.tag_number + ' ('+AUTH.getLocalTimeDealDesk(tagged?.created_at,AUTH.global_fulldate_format)+')'}>
                                                        <div className='tags'
                                                          onClick={() => {
                                                            if (
                                                              (tagged.user &&
                                                                AUTH.user?.id === tagged?.user?.id) ||
                                                              AUTH.user?.user_role?.user_role_id === 1
                                                            ) {
                                                              openTagViewDrawer(tagged);
                                                            }
                                                          }}
                                                        >
                                                          {tagged?.tag_status_id === 1 ? (
                                                            <img src={TagsBorder} alt='' className='icon' />
                                                          ) : tagged?.tag_status_id === 2 ? (
                                                            <img src={TagsGray} alt='' className='icon' />
                                                          ) : tagged?.tag_status_id === 3 ||
                                                            tagged?.tag_status_id === 5 ? (
                                                            <img
                                                              src={AUTH.GetThemedImage('Tags')}
                                                              alt=''
                                                              className='icon'
                                                            />
                                                          ) : (
                                                            <img src={TagsBorder} alt='' className='icon' />
                                                          )}
                                                          {tagged.user?.full_name}
                                                        </div>
                                                      </Tooltip>
                                                    );
                                                  })
                                                  : ''}

                                              {props.commentList === true ? (
                                                ''
                                              ) : item.is_locked === true ? (
                                                <Popover
                                                  content={lockedUsersList(item.lock_user_names)}
                                                  title={`${t('DealDesk.Locked')} ${t('DealDesk.Users_List')}`}
                                                >
                                                  <div className='lock'>
                                                    <img
                                                      src={AUTH.GetThemedImage('Lock_Note')}
                                                      alt=''
                                                      className='icon'
                                                    />
                                                  </div>
                                                </Popover>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                            <div className='attachment_section' onClick={() => { DEALDESKSTORE.setDisableFooterIcons(false); }}>
                                              <Attachments
                                                selectedFiles={item.attachment || []}
                                                downloadable={true}
                                              />
                                            </div>
                                          </>
                                        )}
                </div>
              )
            );
          })}
      </div>
      <EditViewComponent
        visible={viewTagDrawer}
        close={closeViewTagDrawer}
        loadingEditView={loadingEditView}
        editFromDealDeskNoteList={true}
      />
    </>
  );
});

export default DisplayMsgList;