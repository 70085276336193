import React from 'react';
import { Switch } from 'antd';
import useStore from '../../../store';

const SwitchComponent = (props) => {
  const { AUTH } = useStore();

  return (
    <div>
      {props.values.id === 1 ||
      (AUTH.superUser && AUTH.superUser.includes(props.values.id)) ||
      (AUTH.user.user_role && ![1, 2].includes(AUTH.user.user_role.user_role_id)) ||
      (AUTH.user.user_role.user_role_id &&
        [1, 2].includes(AUTH.user.user_role.user_role_id) &&
        AUTH.user.id !== 1 &&
        props.values.user_role &&
        [1].includes(props.values.user_role.user_role_id)) ||
      props.values.deleted_at !== null ? (
        <Switch disabled defaultChecked={props.values.status === 'Active' ? 1 : 0} />
      ) : (
        <Switch
          defaultChecked={props.values.status === 'Active' ? 1 : 0}
          onChange={(val) => props.onSwitchChange(val, props.values)}
        />
      )}
    </div>
  );
};

export default SwitchComponent;
