import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Modal, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Structure from "./Structure";
import Schedule from "./Schedule";
import Sequence from "./Sequence";
// import Comment from './Comment';
import Summary from "./Summary";
import useStore from "../../../store";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Footer from "./component/footer";

const LeftSideComponent = observer((props) => {
  const { t } = useTranslation();

  const [filteredResultSeq, setfilteredResultSeq] = useState(null);
  const [filteredResultSum, setfilteredResultSum] = useState(null);
  const [filteredResultCom, setfilteredResultCom] = useState(null);

  const [openModalSeq, setOpenModalSeq] = useState(false);
  const [openModalSum, setOpenModalSum] = useState(false);
  const [openModalCom, setOpenModalCom] = useState(false);

  const {
    AUTH,
    AUTH: { setCallNotesAPIAfterUserBack },
    DEALDESKSTORE: {
      deal_data,
      resetNoteTabIndex,
      colorlist,
      getUserGroupFilter,
      dealDeskTypeArr,
      leftSectionActiveTab,
      setLeftSectionActiveTab,
    },
    DEALDESKNOTESEQUENCE: {
      sequence_notes_display_data,
      sequencenotes_list,
      // comment_notes_list,
      summary_notes_list,
      sequence_filter_option_list,
      isOutsideClicked,
      // commentsOutsideClicked,
      // currentCommentFilterApplied,
      currentSummaryFilterApplied,
      currentSequenceFilterApplied,
      summaryOutsideClicked,
      sequenceFilterNotes,
      // commentFilterNotes,
      summaryFilterNotes,
      // setCommentsOutsideClicked,
      setSummaryOutsideClicked,
      fetchSequenceNotes,
      setIsOutsideClicked,
    },
    SSSLEADLIBRARYSTORE: { setSyncData, fetchListData },
  } = useStore();

  const { TabPane } = Tabs;

  let dealid = deal_data.lead_information && deal_data.lead_information.id;

  const fetchNotesData = useCallback(() => {
    fetchSequenceNotes(dealid, null, false, true).then((data) => {
      setSyncData(data?.sss);
    });
  }, [fetchSequenceNotes, dealid, setSyncData]);

  /*Start:Used in Sequence Filter -Will Rerender after filter apply*/
  useEffect(() => {
    setfilteredResultSeq(sequencenotes_list);
  }, [sequencenotes_list]);
  /*End:Used in Sequence Filter -Will Rerender after filter apply*/

  /*Start:Used in Summary Filter -Will Rerender after filter apply*/
  useEffect(() => {
    setfilteredResultSum(summary_notes_list);
  }, [summary_notes_list]);
  /*End:Used in Summary Filter -Will Rerender after filter apply*/

  /*Start:Used in Summary Filter -Will Rerender after filter apply*/
  useEffect(() => {
    setfilteredResultCom(sequence_notes_display_data);
  }, [sequence_notes_display_data]);
  /*End:Used in Summary Filter -Will Rerender after filter apply*/

  /*Start:Used Form Main Notes List - All in one - for comment,sequence and summary */
  useEffect(() => {
    fetchSequenceNotes(dealid, null, true);
  }, [fetchSequenceNotes, dealid]);

  /*End:Used Form Main Notes List - All in one - for comment,sequence and summary */

  useEffect(() => {
    if (dealid) {
      getUserGroupFilter(AUTH.user);
    }
  }, [getUserGroupFilter, AUTH.user, dealid]);

  /*Start: On every 2 min update notes & structure and schedule-API call */
  useEffect(() => {
    if (!AUTH.isUserIdle) {
      const id = setInterval(() => {
        fetchNotesData();
      }, 120000);
      return () => {
        clearInterval(id);
      };
    }
    if (AUTH.callNotesAPIAfterUserBack) {
      fetchNotesData();
      setCallNotesAPIAfterUserBack(false);
    }
  }, [
    AUTH.isUserIdle,
    dealid,
    fetchNotesData,
    AUTH.callNotesAPIAfterUserBack,
    setCallNotesAPIAfterUserBack,
  ]);
  /*END: On every 2 min update notes & structure and schedule-API call */

  const sequenceContent = sequence_filter_option_list ? (
    <div className="leadStatusOptins" style={{ width: "600px" }}>
      <div
        className="headerTitle"
        onClick={() => {
          sequenceFilterNotes(null, "all");
          setIsOutsideClicked(!isOutsideClicked);
        }}
      >
        {t("Reports.All")}
      </div>
      <ul>
        <li>
          {colorlist && (
            <>
              <span className="title">
                {`${t("OrganisationManagement.Category")}`}
              </span>
              <ul>
                {colorlist.map((childObj) => {
                  if (childObj.color_name !== "Reset Note") {
                    return (
                      <li
                        onClick={() => {
                          sequenceFilterNotes(
                            childObj.color_code.hex,
                            "current_bg_color"
                          );
                          setIsOutsideClicked(!isOutsideClicked);
                        }}
                        className={`${
                          currentSequenceFilterApplied?.findIndex(
                            (x) =>
                              x?.field === "current_bg_color" &&
                              x?.value === childObj.color_code.hex
                          ) !== -1
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="colorStrip">
                          <span
                            className={`colorBox ${childObj?.color_code?.hex?.replace(
                              "#",
                              "x"
                            )}`}
                          ></span>
                          {childObj.color_name === "Reset Note"
                            ? "No Category"
                            : childObj.color_name}
                        </div>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            </>
          )}
        </li>
        {sequence_filter_option_list &&
          Object.keys(sequence_filter_option_list).map((obj, index) => {
            return (
              <li key={index}>
                <span className="title">
                  {obj === "Users"
                    ? `${t("Common_Data.User")}`
                    : obj === "User Groups"
                    ? `${t("Common_Data.Group")}`
                    : ""}
                </span>
                <ul>
                  {sequence_filter_option_list[obj].map((childObj) => {
                    let fieldName =
                      obj === "Users"
                        ? "filter_user_list"
                        : "filter_user_group_list";
                    let selectedOBJ = currentSequenceFilterApplied?.findIndex(
                      (x) => x?.field === fieldName && x?.value === childObj
                    );
                    return childObj ? (
                      <>
                        <li
                          onClick={() => {
                            sequenceFilterNotes(
                              childObj,
                              obj === "Users"
                                ? "filter_user_list"
                                : "filter_user_group_list"
                            );
                            setIsOutsideClicked(!isOutsideClicked);
                          }}
                          className={`${selectedOBJ !== -1 ? "active" : ""} `}
                        >
                          {childObj}
                        </li>
                      </>
                    ) : null;
                  })}
                </ul>
              </li>
            );
          })}
      </ul>
    </div>
  ) : null;

  const sequenceTitle = (
    <>
      {t("DealDesk.Sequence")}
      {/* <Dropdown
        overlayClassName='multiColDropdown'
        overlay={sequenceContent}
        trigger='click'
        placement='bottomCenter'
        arrow
      >
        <span>
          <DownOutlined className='ml-5' />
        </span>
      </Dropdown> */}
    </>
  );
  const specificTitle = (
    <>
      {t("Common_Data.Specific")}
      <Dropdown
        overlayClassName="multiColDropdown"
        overlay={sequenceContent}
        trigger="click"
        placement="bottomCenter"
        arrow
      >
        <span>
          <DownOutlined className="ml-5" />
        </span>
      </Dropdown>
    </>
  );

  // const commentContent = sequence_filter_option_list ? (
  //   <div className='leadStatusOptins'>
  //     <div
  //       className='headerTitle'
  //       onClick={() => {
  //         commentFilterNotes(null, 'all');
  //         setCommentsOutsideClicked(!commentsOutsideClicked);
  //       }}
  //     >
  //       {t('Reports.All')}
  //     </div>
  //     <ul style={{ width: '400px' }}>
  //       {sequence_filter_option_list &&
  //         Object.keys(sequence_filter_option_list).map((obj, index) => {
  //           return (
  //             <li key={index}>
  //               <span className='title'>{obj === 'Users' ? `${t('ManageAllDepartments.Users')}` : obj === 'User Groups' ? `${t('Common_Data.User')} ${t('Common_Data.Groups')}` : ''}</span>
  //               <ul>
  //                 {sequence_filter_option_list[obj].map((childObj) => {
  //                   let fieldName = (obj === 'Users'
  //                     ? 'filter_user_list'
  //                     : 'filter_user_group_list')
  //                   let selectedOBJ = currentCommentFilterApplied?.findIndex(x => x?.field === fieldName && x?.value === childObj);

  //                   return childObj ? (
  //                     <>
  //                       <li
  //                         onClick={() => {
  //                           commentFilterNotes(
  //                             childObj,
  //                             obj === 'Users'
  //                               ? 'filter_user_list'
  //                               : 'filter_user_group_list'
  //                           );
  //                           setCommentsOutsideClicked(!commentsOutsideClicked);
  //                         }}
  //                         className={`${selectedOBJ !== -1 ? 'active' : ''} `}
  //                       >
  //                         {childObj}
  //                       </li>
  //                     </>
  //                   ) : null;
  //                 })}
  //               </ul>
  //             </li>
  //           );
  //         })}
  //     </ul>
  //   </div>
  // ) : null;

  // const commentTitle = (
  //   <>
  //     {t('Common_Data.Specific')}
  //     <Dropdown
  //       overlayClassName='multiColDropdown'
  //       overlay={commentContent}
  //       trigger='click'
  //       placement='bottomCenter'
  //       arrow
  //     >
  //       <span>
  //         <DownOutlined className='ml-5' />
  //       </span>
  //     </Dropdown>
  //   </>
  // );

  const summaryContent = (
    <div className="leadStatusOptins">
      <div
        className="headerTitle"
        onClick={() => {
          summaryFilterNotes(null, "all");
          setSummaryOutsideClicked(!summaryOutsideClicked);
        }}
      >
        {t("Reports.All")}
      </div>
      <ul style={{ width: "200px" }}>
        <li>
          <ul>
            <li
              onClick={() => {
                summaryFilterNotes(4, "notes_flag");
                setSummaryOutsideClicked(!summaryOutsideClicked);
              }}
              className={`${
                currentSummaryFilterApplied.findIndex(
                  (x) => x.value === 4 && x.field === "notes_flag"
                ) !== -1
                  ? "active"
                  : ""
              } `}
            >
              <strong>{`${t("DealDesk.Inbound")}`}</strong>
            </li>
            <li
              onClick={() => {
                summaryFilterNotes(3, "notes_flag");
                setSummaryOutsideClicked(!summaryOutsideClicked);
              }}
              className={`${
                currentSummaryFilterApplied.findIndex(
                  (x) => x.value === 3 && x.field === "notes_flag"
                ) !== -1
                  ? "active"
                  : ""
              } `}
            >
              <strong>{`${t("DealDesk.Conversation")}`}</strong>
            </li>
            <li
              onClick={() => {
                summaryFilterNotes(2, "notes_flag");
                setSummaryOutsideClicked(!summaryOutsideClicked);
              }}
              className={`${
                currentSummaryFilterApplied.findIndex(
                  (x) => x.value === 2 && x.field === "notes_flag"
                ) !== -1
                  ? "active"
                  : ""
              } `}
            >
              <strong>{`${t("DealDesk.Comment")}`}</strong>
            </li>
            <li
              onClick={() => {
                summaryFilterNotes(1, "notes_flag");
                setSummaryOutsideClicked(!summaryOutsideClicked);
              }}
              className={`${
                currentSummaryFilterApplied.findIndex(
                  (x) => x.value === 1 && x.field === "notes_flag"
                ) !== -1
                  ? "active"
                  : ""
              } `}
            >
              <strong>{`${t("DealDesk.Outbound")}`}</strong>
            </li>
            <li className="disabled">
              <strong>{`${t("DealDesk.Key_Documents")}`}</strong>
            </li>
            <li
              onClick={() => {
                summaryFilterNotes("toolicon keyNote", "notes_description");
                setSummaryOutsideClicked(!isOutsideClicked);
              }}
              className={`${
                currentSummaryFilterApplied?.findIndex(
                  (x) =>
                    x?.field === "notes_description" &&
                    x?.value === "toolicon keyNote"
                ) !== -1
                  ? "active"
                  : ""
              }`}
            >
              <strong>{`${t("Common_Data.Key_Comments")}`}</strong>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );

  const summaryTitle = (
    <>
      {t("DealDesk.Summary")}
      <Dropdown
        overlayClassName="multiColDropdown"
        overlay={summaryContent}
        trigger="click"
        placement="bottomCenter"
        arrow
      >
        <span>
          <DownOutlined className="ml-5" />
        </span>
      </Dropdown>
    </>
  );

  const close = () => {
    setOpenModalSeq(false);
    setOpenModalCom(false);
    setOpenModalSum(false);
  };
  const openExpandModalSeq = () => {
    setOpenModalSeq(true);
  };
  const openExpandModalSum = () => {
    setOpenModalSum(true);
  };
  const openExpandModalCom = () => {
    setOpenModalCom(true);
  };
  const [tabChange, setTabChange] = useState(false);
  const onTabSelection = (e) => {
    let type =
      e === "7" ? 4 : e === "6" ? 3 : e === "1" ? 1 : e === "2" ? 2 : null;
    type && fetchListData(dealid, false, { type: type });
    setLeftSectionActiveTab(e);
    resetNoteTabIndex();
    setTabChange(true);
  };
  // useEffect(() => {
  //   fetchListData(deal_data?.lead_information?.id, false, { type: 4 });
  // }, [fetchListData, deal_data]);
  // });

  useEffect(() => {
    const adjustFooterWidth = () => {
      const footer = document.querySelector(".tabFooter");
      const modalfooterRight = document.querySelector(".modalFooter");
      const displayTabsLeft = document.querySelector(".displayTabs");
      const parentWrapper = document.querySelector("#rc-tabs-0-panel-5");
      const parentWrapperSpecific = document.querySelector(
        "#rc-tabs-0-panel-4 #displayBoxWithFooterId"
      );

      const parent = document.querySelector(".Dealdesk_MainTab");
      console.log(
        "footer width summary",
        footer,
        displayTabsLeft,
        parentWrapper
      );

      if (footer && parent) {
        console.log("footer width inserted summary", parent.offsetWidth);

        footer.style.width = `${parent.offsetWidth}px !important`;
        modalfooterRight.style.width = `${parent.offsetWidth}px`;
        displayTabsLeft.style.width = `${parent.offsetWidth}px`;
        if (parentWrapperSpecific) {
          console.log(
            "footer width inserted summary for 6",
            parent.offsetWidth
          );
          parentWrapperSpecific.style.maxWidth = `${parent.offsetWidth}px`;
        }
        if (parentWrapper) {
          console.log(
            "footer width inserted summary for 5",
            parent.offsetWidth
          );

          parentWrapper.style.maxWidth = `${parent.offsetWidth}px`;
        }
      }
      setTabChange(false);
    };

    if (filteredResultSum || tabChange) {
      // Adjust width on load and resize
      window.addEventListener("resize", adjustFooterWidth);
      adjustFooterWidth(); // Initial adjustment

      // Cleanup on unmount
      return () => window.removeEventListener("resize", adjustFooterWidth);
    }
  }, [filteredResultSum, tabChange]);

  return (
    <>
      <Tabs
        onChange={onTabSelection}
        activeKey={leftSectionActiveTab}
        defaultActiveKey={7}
        // defaultActiveKey={leftSectionActiveTab}
        centered
        className="Dealdesk_MainTab newTabUI fiveColGap"
      >
        <TabPane
          tab={`${t("Common_Data.Scrutinise")}`}
          key="7"
          disabled={
            dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
              ? true
              : false
          }
        >
          <Structure listingType="scrutinise" apiTypeValue={4} />
        </TabPane>
        <TabPane
          tab={`${t("DealDesk.Screen")}`}
          key="6"
          disabled={
            dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
              ? true
              : false
          }
        >
          <Structure listingType="screen" apiTypeValue={3} />
        </TabPane>
        <TabPane
          tab={`${t("Common_Data.Structure")}`}
          key="1"
          disabled={
            dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
              ? true
              : false
          }
        >
          <Structure listingType="structure" apiTypeValue={1} />
        </TabPane>
        <TabPane
          tab={`${t("DealDesk.Schedule")}`}
          key="2"
          disabled={
            dealDeskTypeArr.includes(deal_data?.lead_information?.is_ticket)
              ? true
              : false
          }
        >
          <Schedule />
        </TabPane>

        <TabPane disabled={true}></TabPane>

        <TabPane tab={sequenceTitle} key="3">
          <Sequence
            filteredResultSeq={filteredResultSeq}
            openExpandModalSeq={openExpandModalSeq}
          />
        </TabPane>
        <TabPane tab={summaryTitle} key="5">
          <Summary
            filteredResultSum={filteredResultSum}
            openExpandModalSum={openExpandModalSum}
          />
        </TabPane>
        <TabPane tab={specificTitle} key="4">
          <Sequence
            filteredResultSeq={filteredResultCom}
            isSpecific={true}
            openExpandModalSeq={openExpandModalCom}
          />
        </TabPane>
      </Tabs>

      <Modal
        centered
        width={1200}
        //title=''
        visible={openModalSeq}
        onCancel={close}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        style={{ position: "relative" }}
        footer={
          <Footer
            msgBoxArray={{
              get: sequence_notes_display_data,
              // set: dataValue,
            }}
            openExpandModalSeq={openExpandModalSeq}
            openModalSeq={openModalSeq}
            close={close}
          />
        }
        className="ExpandModal"
      >
        <Tabs defaultActiveKey="1" centered className="Dealdesk_MainTab">
          <TabPane tab={sequenceTitle} key="1">
            <Sequence
              filteredResultSeq={filteredResultSeq}
              openExpandModalSeq={openExpandModalSeq}
              openModalSeq={openModalSeq}
              close={close}
              open={true}
            />
          </TabPane>
        </Tabs>
      </Modal>

      <Modal
        centered
        width={1200}
        //title=''
        visible={openModalCom}
        onCancel={close}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        footer={
          <Footer
            msgBoxArray={{
              get: sequence_notes_display_data,
              // set: dataValue,
            }}
            openExpandModalSeq={openExpandModalSeq}
            openModalSeq={openModalSeq}
            close={close}
          />
        }
        className="ExpandModal"
      >
        <Tabs defaultActiveKey="1" centered className="Dealdesk_MainTab">
          <TabPane tab={specificTitle} key="1">
            <Sequence
              filteredResultSeq={filteredResultCom}
              isSpecific={true}
              openExpandModalSeq={openExpandModalSeq}
              openModalSeq={openModalSeq}
              close={close}
              open={true}
            />
          </TabPane>
        </Tabs>
      </Modal>

      <Modal
        centered
        width={1200}
        //title=''
        visible={openModalSum}
        onCancel={close}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        style={{ position: "relative" }}
        footer={
          <Footer
            msgBoxArray={{
              get: sequence_notes_display_data,
              // set: dataValue,
            }}
            openExpandModalSeq={openExpandModalSeq}
            openModalSeq={openModalSeq}
            close={close}
          />
        }
        className="ExpandModal"
      >
        <Tabs defaultActiveKey="1" centered className="Dealdesk_MainTab">
          <TabPane tab={summaryTitle} key="1">
            <Summary
              filteredResultSum={filteredResultSum}
              openExpandModalSum={openExpandModalSum}
              openModalSum={openModalSum}
              close={close}
              open={true}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
});

export default LeftSideComponent;
