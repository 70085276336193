import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Form,
  Row,
  Col,
  Select,
  //  Button, Input
} from 'antd';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  // PlusOutlined
} from '@ant-design/icons';

import AddressSubElementNew from './SubElement/AddressSubElementNew';
import { address_type_list } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const AddressElementNew = observer(({ onChange, form }) => {
  const { t } = useTranslation()
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [SelectTypes, SetSelectedTypes] = useState();

  const viewAddressOptions = () => {

    return address_type_list.map((obj) => {
      return (
        <Select.Option value={obj}>{obj}</Select.Option>
      )
    })

  }
  return (
    <div className='w-100'>
      <Row gutter={15}>
        <Form.List name='address' initialValue={[{}]} className='d-flex address_list'>
          {(fields, { add, remove }) => {
            let rejected_types = [];
            SelectTypes &&
              SelectTypes.map((x) => {
                if (x && x.address_type === 'Primary') {
                  rejected_types.push('Primary');
                }
                return null;
              });
            return fields.map((field, index) => {
              return (
                <>
                  <Col className='address_list' span={12}>
                    <div className='addMore'>
                      {index === fields.length - 1 &&
                        form.getFieldValue('address') &&
                        form.getFieldValue('address')[field.name] &&
                        form.getFieldValue('address')[field.name].address_type && (
                          <PlusCircleOutlined
                            onClick={async () => {
                              await add(null);
                              //onChange();
                            }}
                          />
                        )}
                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          className='ml-5'
                          onClick={() => {
                            remove(field.name);
                            //onChange();
                          }}
                        />
                      )}
                    </div>
                    <Form.Item
                      label={`${t('Credebt_Classification.Address_Type')}`}
                      tooltip={`${t('PeopleManagement.Address_Type_Tooltip')} (${t('PeopleManagement.DDD_Show_Options')})`}
                      name={[field.name, 'address_type']}
                    >
                      <Select
                        label={`${t('PeopleManagement.Test')}`}
                        placeholder={`${t('Common_Data.Select')} ${t('PeopleManagement.Proof')} ${t('Common_Data.Of')} ${t('Common_Data.Address')}`}
                        className='selfUpdatingOptions'
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <div>
                              {/* <Form className='innerForm addOption'>
                                <div>
                                  <Form.Item>
                                    <Input maxLength={50} />
                                  </Form.Item>
                                  <Button
                                    type='primary'
                                    size='small'
                                    // onClick={addItem}
                                  >
                                    <PlusOutlined /> Add item
                                  </Button>
                                </div>
                              </Form> */}
                            </div>
                          </>
                        )}
                        onChange={() => {
                          onChange();
                          forceUpdate();
                        }}
                      >
                        {viewAddressOptions()}
                        {/* <Select.Option value='Primary'>Primary</Select.Option>
                        <Select.Option value='Office'>Office</Select.Option>
                        <Select.Option value='Home'>Home</Select.Option>
                        <Select.Option value='Other'>Other</Select.Option> */}
                      </Select>
                    </Form.Item>
                  </Col>
                  {form.getFieldValue('address') &&
                    form.getFieldValue('address')[field.name] &&
                    form.getFieldValue('address')[field.name].address_type && (
                      <AddressSubElementNew
                        onChange={onChange}
                        field={field}
                        SetSelectedTypes={SetSelectedTypes}
                        rejected_types={rejected_types}
                        form={form}
                      />
                    )}
                </>
              );
            });
          }}
        </Form.List>
      </Row>
    </div>
  );
});

export default AddressElementNew;
