import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import {
  ageDifferenceInDays,
  defaultMyOpenTags,
  //convertTextToID,
  tag_status_name,
} from '../../utils/GlobalFunction';
import { clear, reset } from '../../utils/ResetClearButton';

export default class TagsStore {
  list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  dropdown_master_ticket = null;
  dropdown_user_list = null;
  dropdown_leads_users_list = null;

  editValues = null;
  viewValues = null;
  cloneValues = null;
  deleteValues = null;
  waste = false;
  search_payload = {};
  isSearchPage = false;
  gridLoading = false;

  filter_org_list = null;
  filter_master_ticket = null;

  statusArr_list = [
    {
      value: 1,
      text: 'Open',
    },
    {
      value: 2,
      text: 'Read',
    },
    {
      value: 3,
      text: 'Closed',
    },
    {
      value: 4,
      text: 'Returned',
    },
    {
      value: 5,
      text: 'Approved',
    },
  ];

  // selectedGridFilter = localStorage.getItem('savedFilters')
  //   ? JSON.parse(localStorage.getItem('savedFilters'))?.TagsStore
  //     ? JSON.parse(localStorage.getItem('savedFilters'))?.TagsStore
  //     : null
  //   : 99997;

  DEFAULT_GRID_FILTER_ID = 99997;
  selectedGridFilter = this.DEFAULT_GRID_FILTER_ID;
  isSavedFiltersLoadedTag = false;

  savingMarkBtn = false;
  savingApproveBtn = false;

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;

    const { api } = params;
    let columnConfig = localStorage.getItem('tags_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    let param = this.getFilter(params);
    if (param && param.request) {
      this.agGrid.api.setFilterModel(param.request.filter);
      this.agGrid.api.setSortModel(param.request.sort);
    }
    var datasource = this.createDatasource(GridConfig.options);
    if (this.isSavedFiltersLoadedTag) {
      api.setServerSideDatasource(datasource);
    } else {
      if (localStorage.getItem('isSavedFiltersFetching')) {
        setTimeout(() => {
          api.setServerSideDatasource(datasource);
        }, 2000);
      }
    }
  };

  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_tags_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem('tags_grid', JSON.stringify(params.columnApi.getColumnState()));
  };

  onGridChangedSearch = (params) => {
    localStorage.setItem('search_tags_grid', JSON.stringify(params.columnApi.getColumnState()));
  };


  // reset all the server side filters
  // handleReset = () => {
  //   localStorage.removeItem('tags_grid');

  //   this.setSelectedGridFilter(this.DEFAULT_GRID_FILTER_ID);
  //   // var tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
  //   // localStorage.setItem(
  //   //   'savedFilters',
  //   //   JSON.stringify({
  //   //     ...tempsavedFilters,
  //   //     TagsStore: null,
  //   //   })
  //   // );

  //   this.agGrid.api.setFilterModel(defaultMyOpenTags);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.api.onFilterChanged(null);
  // };
  handleReset = () => {
    reset(this.agGrid, "tags_grid", this.setSelectedGridFilter, this.DEFAULT_GRID_FILTER_ID, defaultMyOpenTags)
  }

  /** This is called on clear button */
  /** Clear : Remove only applied sorting and filter and set to default */
  applyFilterDefault = () => {
    clear(this.agGrid, defaultMyOpenTags)
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.TagsStore) {
        params = { request: temp.TagsStore };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          TagsStore: { filter: param.getFilterModel(), sort: param.getSortModel() },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          TagsStore: { filter: param.getFilterModel(), sort: param.getSortModel() },
        })
      );
    }
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('tags_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }

        //var filter_data = params.request.filterModel;
        var filter_data = {
          final_filter: params.request.filterModel,
          final_sort: params.request.sortModel,
        };
        // if (filter_data.final_filter['created_by_name']) {
        //   filter_data.final_filter['created_by_name'].values = convertTextToID(
        //     filter_data.final_filter['created_by_name'],
        //     this.dropdown_leads_users_list,
        //     'name',
        //     'id'
        //   );
        // }
        // if (filter_data.final_filter['owner']) {
        //   filter_data.final_filter['owner'].values = convertTextToID(
        //     filter_data.final_filter['owner'],
        //     this.dropdown_leads_users_list,
        //     'name',
        //     'id'
        //   );
        // }
        // if (filter_data.final_filter['tag_status_name']) {
        //   filter_data.final_filter['tag_status_name'].values = convertTextToID(
        //     filter_data.final_filter['tag_status_name'],
        //     this.statusArr_list,
        //     'text',
        //     'value'
        //   );
        // }

        // if (filter_data.final_filter['tagholder']) {
        //   filter_data.final_filter['tagholder'].values = convertTextToID(
        //     filter_data.final_filter['tagholder'],
        //     this.dropdown_leads_users_list,
        //     'name',
        //     'id'
        //   );
        // }
        // if (filter_data.final_filter['organisation.organisation_name']) {
        //   filter_data.final_filter['organisation.organisation_name'].values =
        //     convertTextToID(
        //       filter_data.final_filter['organisation.organisation_name'],
        //       this.filter_org_list,
        //       'organisation_name',
        //       'id'
        //     );
        // }
        // if (filter_data.final_filter['master_ticket.ticket_subject']) {
        //   filter_data.final_filter['master_ticket.ticket_subject'].values =
        //     convertTextToID(
        //       filter_data.final_filter['master_ticket.ticket_subject'],
        //       this.dropdown_master_ticket,
        //       'ticket_subject',
        //       'id'
        //     );
        // }

        const resultUpdatedAt =
          filter_data?.final_sort &&
          filter_data.final_sort.length > 0 &&
          filter_data.final_sort.filter((s) => s.colId === 'updated_at');

          if (!resultUpdatedAt) {
            filter_data.final_sort.push({ colId: 'updated_at', sort: 'desc' });
          }

        var payload = {
          waste: this.waste,
          filter_data: params.request.filterModel,
          // filter_data: this.search_payload?.hasOwnProperty('search')
          //   ? {}
          //   : params.request.filterModel,
          sort_data: filter_data.final_sort,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };
        if (this.selectedGridFilter === this.DEFAULT_GRID_FILTER_ID) {
          payload.filter = 'my_open_tag';
        }
        // let merged = { ...payload, ...this.search_payload };
        let merged = { ...payload};
        this.setFilter(params.api);
        if (this.isSavedFiltersLoadedTag) {
          this.getList(merged).then((data) => {
            if (data.total === 0) {
              this.agGrid.api.showNoRowsOverlay();
            } else {
              this.agGrid.api.hideOverlay();
            }
            params.successCallback(data.data, data.total);
            var allColumnIds = [];
            let columnConfig = localStorage.getItem('tags_grid');
            if (this.agGrid && this.agGrid.columnApi && columnConfig) {
              if (
                JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig
              ) {
                this.agGrid.columnApi.applyColumnState({
                  state: JSON.parse(columnConfig),
                });
              }
            } else {
              if (this.agGrid && this.agGrid.columnApi && data.total) {
                this.agGrid.columnApi.getAllColumns()?.forEach(function (column) {
                  if (!['actions'].includes(column.colId)) {
                    allColumnIds.push(column.colId);
                  }
                });
                this.agGrid.columnApi.autoSizeColumns(allColumnIds);
              }
            }
          });
        }
      },
    };
  };

  getList = (payload = {}) => {
    return Axios.post(`leadstags/list`, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.tag_status_name = tag_status_name[item.tag_status_id];
          item.owner = item.created_by_name;
          item.tagholder = null;
          if (item.tag_holder && item.tag_holder) {
            item.tagholder =
              (item.tag_holder.first_name && item.tag_holder.first_name) +
              ' ' +
              (item.tag_holder.last_name && item.tag_holder.last_name);
          }
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;

      return data;
    });
  };

  getListSearch = (payload = {}) => {
    this.gridLoading = true;

    var temp = JSON.parse(localStorage.getItem('params'));
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.TagsSearchStore?.sort;
    }
    this.list_data = null;


    return Axios.post(`leadstags/list`, payload).then(({ data }) => {
      this.gridLoading = false;
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.tag_status_name = tag_status_name[item.tag_status_id];
          item.owner = item.created_by_name;
          item.tagholder = null;
          item.age = item.created_at ? ageDifferenceInDays(new Date(item.created_at)) : 1;
          item.idle = item.updated_at ? ageDifferenceInDays(new Date(item.updated_at)) : 1;
          if (item.tag_holder && item.tag_holder) {
            item.tagholder =
              (item.tag_holder.first_name && item.tag_holder.first_name) +
              ' ' +
              (item.tag_holder.last_name && item.tag_holder.last_name);
          }
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;

      this.agGrid.api.setSortModel(sort ? sort : temp?.TagsSearchStore?.sort);

      if (this.list_data?.length === 0) {
        this.agGrid.api.showNoRowsOverlay();
      }
      return data;
    });
  };

  //set Edit Value Data
  setEditValues = (data) => {
    return Axios.post(`leadstags/read/${data.id}`, {
      waste: this.waste,
      search: this.is_fromsearch,
    })
      .then(({ data }) => {
        this.editValues = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  //set Edit Value Data
  resetEditValues = () => {
    this.editValues = null;
  };

  getMasterTicketList = (payload = {}) => {
    return Axios.get(`leadstickets/drop-down-list`).then(({ data }) => {
      this.dropdown_master_ticket = data.data;

      return data & data.data;
    });
  };

  filterMasterTicketList = (payload = {}) => {
    return Axios.get(`leadstickets/drop-down-list`).then(({ data }) => {
      let newData = [];
      if (data.data.length) {
        data.data.forEach((item, index) => {
          newData[item.id] = item.ticket_subject;
        });
      }

      this.filter_master_ticket = newData;

      return newData;
    });
  };

  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_user_list = data.data;

      return data;
    });
  };

  getLeadsUsersList = () => {
    return Axios.get(`leads/user/dropdown/list`).then(({ data }) => {
      this.dropdown_leads_users_list = data.data;

      return data;
    });
  };

  editTag = (id, formdata) => {
    return Axios.post(`leadstags/edit/${id}`, formdata)
      .then(({ data }) => {
        // if (this.agGrid) {
        //   this.setupGrid(this.agGrid);
        // }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //set view Value Data
  setViewValues = (data) => {
    return Axios.post(`leadstags/read/${data.id}`, {
      waste: this.waste,
      search: this.is_fromsearch,
    })
      .then(({ data }) => {
        //this.viewValues = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  removeTag = (formdata) => {
    return Axios.post(`leadstags/delete/${formdata.id}`, {
      waste: this.waste,
      search: this.is_fromsearch,
    })
      .then(({ data }) => {
        // if (this.agGrid) {
        //   this.setupGrid(this.agGrid);
        // }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setCloneValues = (data) => {
    return Axios.post(`leadstags/read/${data.id}`, {
      waste: this.waste,
      search: this.is_fromsearch,
    })
      .then(({ data }) => {
        this.cloneValues = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  addTag = (formdata) => {
    return Axios.post(`leadstags/add`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //reset Clone Values
  resetCloneValues = () => {
    this.cloneValues = null;
  };

  assignTag = (formdata) => {
    return Axios.post(`leadstickets/update-tickets-tags-users`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  RestoreData = (tag_id) => {
    return Axios.get(`leads-tags/restore/${tag_id}`)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // call api to get all parent organisations list
  getMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      this.filter_org_list = data.data;
      return data;
    });
  };

  filterGetMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      let newData = [];
      if (data.data.length) {
        data.data.forEach((item, index) => {
          item.organisation_name = item.organisation_name + ' (' + item.id + ')';
          newData[item.id] = item.organisation_name;
        });
      }
      this.filter_org_list = newData;

      return newData;
    });
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;

    // var tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
    // localStorage.setItem(
    //   'savedFilters',
    //   JSON.stringify({
    //     ...tempsavedFilters,
    //     TagsStore: data,
    //   })
    // );
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          TagsStore: { filter: filterdata },
        })
      );
      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  setSavingMarkBtn = (data) => {
    this.savingMarkBtn = data;
  };

  setSavingApproveBtn = (data) => {
    this.savingApproveBtn = data;
  };

  //Used On Waste Tab Change
  resetGridFilterForWaste = () => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));

      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          TagsStore: {
            filter: {},
          },
        })
      );

      this.agGrid.api.setFilterModel({});
    }
  };

  resetSelectedSavedGridFilter = () => {
    this.selectedGridFilter = this.DEFAULT_GRID_FILTER_ID;
  };

  setIsSavedFiltersLoadedTag = (data) => {
    this.isSavedFiltersLoadedTag = data;
  };
}

decorate(TagsStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  editValues: observable,
  viewValues: observable,
  cloneValues: observable,
  deleteValues: observable,
  dropdown_master_ticket: observable,
  dropdown_user_list: observable,
  statusArr_list: observable,
  waste: observable,
  filter_org_list: observable,
  search_payload: observable,
  dropdown_leads_users_list: observable,
  selectedGridFilter: observable,
  savingMarkBtn: observable,
  DEFAULT_GRID_FILTER_ID: observable,
  savingApproveBtn: observable,
  filter_master_ticket: observable,
  isSavedFiltersLoadedTag: observable,
  isSearchPage: observable,
  gridLoading: observable,

  setPageSize: action,
  getList: action,
  setupGrid: action,
  setEditValues: action,
  setViewValues: action,
  getMasterTicketList: action,
  getUsersList: action,
  resetEditValues: action,
  addTag: action,
  setCloneValues: action,
  assignTag: action,
  RestoreData: action,
  getMappedOrg: action,
  getLeadsUsersList: action,
  handleReset: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  setSavingMarkBtn: action,
  resetGridFilterForWaste: action,
  resetSelectedSavedGridFilter: action,
  setSavingApproveBtn: action,
  filterGetMappedOrg: action,
  filterMasterTicketList: action,
  setIsSavedFiltersLoadedTag: action,
  applyFilterDefault: action,
  getListSearch: action,
  setupGridSearch: action,
  onGridChangedSearch: action,
});
