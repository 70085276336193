import React, { useState } from 'react';
import { Col, Radio, Row, Drawer, Spin } from 'antd';

import UserInvestmentSOLD from './UserInvestmentSOLD';
import UserInvestmentTicket from './UserInvestmentTicket';
import UserInvestmentDatewise from './UserInvestmentDateWise';
import UserInvestmentOthers from './UserInvestmentOthers';

import useStore from '../../../../store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const InvestmentHourSubReportElement = observer((props) => {
    const { t } = useTranslation()
    const { REPORTSTORE } = useStore();

    const { visible, userWiseDrillDetails, close } = props;
    const [tabselection, setTabselection] = useState(1);

    const optionsRadiolist = [
        { label: 'All', value: 1 },
        { label: 'SOLD', value: 2 },
        { label: 'Ticket', value: 3 },
        { label: 'Other', value: 4 },
    ];

    const onRadiolistchange = (e) => {
        setTabselection(e.target.value);
    };

    return (
        <>
            <Drawer
                visible={visible}
                onClose={close}
                placement='right'
                width={'1200px'}
                title={`${REPORTSTORE.previewReportValues.name} - ${userWiseDrillDetails?.column} (${userWiseDrillDetails?.group_on})`}
                destroyOnClose={true}
                extra={[
                    <Row>
                        <Col span={24}>
                            <Radio.Group
                                size='small'
                                options={optionsRadiolist}
                                onChange={onRadiolistchange}
                                value={tabselection}
                                optionType='button'
                                buttonStyle='solid'
                            />
                        </Col>
                    </Row>,
                ]}
            >
                <Spin
                    size='large'
                    spinning={REPORTSTORE.drilldownLoader}
                    tip={`${t('Reports.Fetching')} ${t('Common_Data.Details')} ${t('Reports.Of')} ${userWiseDrillDetails?.column}.`}
                >
                    {tabselection === 1 && (
                        <>
                            <UserInvestmentDatewise />
                        </>
                    )}
                    {tabselection === 2 && (
                        <>
                            <UserInvestmentSOLD />
                        </>
                    )}
                    {tabselection === 3 && (
                        <>
                            <UserInvestmentTicket />
                        </>
                    )}
                    {tabselection === 4 && (
                        <>
                            <UserInvestmentOthers />
                        </>
                    )}
                </Spin>
            </Drawer>
        </>
    );
});

export default InvestmentHourSubReportElement;
