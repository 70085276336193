import React, { useState } from 'react';
import { Col, Spin } from 'antd';

import InputComponent from '../../../../component/InputComponent';
import OrganisationElements from './OrganisationElements';
import PersonElements from './PersonElements';
import { vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const TagsReportCriteria = ({ formDataList, form, handleChange }) => {
  const [fetchTicket, setFetchTicket] = useState(true);
  const { t } = useTranslation();
  const {
    TagsStore,
    TicketsStore: { dropdown_master_ticket, getMasterTicketList },
  } = useStore();

  return (
    <>
      <OrganisationElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <PersonElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />

      <Col span={12}>
        {formDataList.includes('master_ticket_number') && (
          <InputComponent
            name='master_ticket_number'
            label={`${t('Common_Data.Master')} ${t('DealDesk.Ticket_Number')}`}
            type='select'
            mode='multiple'
            showSearch={true}
            placeholder={`${t('Common_Data.Master')} ${t('DealDesk.Ticket_Number')}`}
            tooltip={`${t('Common_Data.Master')} ${t('DealDesk.Ticket_Number')}`}
            onChange={handleChange}
            allowClear
            showArrow
            onFocus={() =>
              fetchTicket && getMasterTicketList().then(() => setFetchTicket(false))
            }
            notFoundContent={fetchTicket ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            options={{
              values: dropdown_master_ticket,
              value_key: 'id',
              text_key: 'ticket_subject',
            }}
            rules={vsmTransactionReport.validation.master_ticket_number}
          />
        )}
      </Col>
      {formDataList.includes('tag_status') && (
        <Col span={12}>
          <InputComponent
            type='select'
            allowClear
            showSearch
            showArrow
            label={t('Reports.Tag_Status')}
            name='tag_status'
            placeholder={t('Reports.Tag_Status')}
            mode='multiple'
            onChange={handleChange}
            options={{
              values: TagsStore.statusArr_list,
              value_key: 'value',
              text_key: 'text',
            }}
            tooltip={t('Reports.Organiation_Type_Tooltip')}
          />
        </Col>
      )}
      {formDataList.includes(`ticket_number`) && (
        <Col span={12}>
          <InputComponent
            name={`ticket_number`}
            label={`${t('DealDesk.Ticket_Number')}`}
            placeholder={`${t('Reports.Ticket_Placeholder')}`}
            type='textarea'
            tooltip={`${t('DealDesk.Ticket_Number')}`}
            onChange={handleChange}
          />
        </Col>
      )}
      {formDataList.includes(`sold_id`) && (
        <Col span={12}>
          <InputComponent
            name={`sold_id`}
            label={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
            placeholder={`${t('Reports.Sold_Id_Placeholder')}`}
            type='textarea'
            tooltip={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
            onChange={handleChange}
          />
        </Col>
      )}
      {formDataList.includes(`tag_subject`) && (
        <Col span={24}>
          <InputComponent
            name={`tag_subject`}
            label={t('Common_Data.Subject')}
            placeholder={t('Reports.Subject_Placeholder')}
            type='textarea'
            tooltip={t('Common_Data.Subject')}
            onChange={handleChange}
          />
        </Col>
      )}
    </>
  );
};

export default TagsReportCriteria;
