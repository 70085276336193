import React from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button } from 'antd';
import useStore from '../../../store';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { vsmNotify } from '../../../config/messages';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const ViewComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    REMINDERSSTORE,
    REMINDERSSTORE: { viewValues },
    SPANNOTESTORE,
  } = useStore();

  const close = () => {
    if (props?.isRefreshGrid) {
      if (REMINDERSSTORE.agGrid) {
        REMINDERSSTORE.setupGrid(REMINDERSSTORE.agGrid);
      }
    }
    REMINDERSSTORE.viewValues = null;
    props.close();
  };

  let priorityFilter = REMINDERSSTORE.priorityForFilter.filter(
    (x) => x.value === viewValues?.priority
  );

  const reminderMarkasCompleted = () => {
    SPANNOTESTORE.readCommonReminder(
      { reminder_id: [viewValues.id], type: viewValues.reminder_type },
      props?.topnotificationViewIDType ? props.topnotificationViewIDType : 4
    ).then(() => {
      REMINDERSSTORE.viewValues = null;
      props.close();
      if (REMINDERSSTORE.agGrid) {
        REMINDERSSTORE.setupGrid(REMINDERSSTORE.agGrid);
      }
      vsmNotify.success({
        message: `${t('Common_Data.Reminder_Updated')}!`,
      });
    });
  };

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      title={`${t('Common_Data.View')} ${t('Common_Data.Reminder')}`}
      destroyOnClose={true}
      footer={[
        <>
          <div className='d-flex' style={{ width: '100%' }}>
            {viewValues?.status &&
              viewValues?.receiver_id &&
              viewValues.receiver_id === AUTH.user.id &&
              !['Closed', 'Completed'].includes(viewValues?.status) ? (
              <div className='text-left' style={{ flexGrow: '1' }} key='2'>
                <Button
                  type='primary'
                  key='1markascompleted'
                  shape='round'
                  size='medium'
                  onClick={() => reminderMarkasCompleted()}
                >
                  {t('Reminders.Mark_Completed')}
                </Button>
              </div>
            ) : null}

            <div className='text-right' style={{ flexGrow: '1' }} key='1'></div>
          </div>
          {!viewValues?.leads_transactions_id ||
            viewValues?.reminder_type === 'private' ? (
            <img
              className='menuicon'
              style={{
                opacity: '0.5',
                cursor: 'not-allowed',
                position: 'absolute',
                top: '15px',
                right: '20px',
                width: '20px',
              }}
              alt=''
              src={AUTH.GetThemedImage('Deal_icn')}
            />
          ) : (
            <Link
              to={
                viewValues?.reminder_type === 'notes'
                  ? `/dealdesk/${viewValues?.leads_transactions_id}#n${viewValues?.reminder_for}`
                  : viewValues?.reminder_type === 'tags' && viewValues?.leads_notes_id
                    ? `/dealdesk/${viewValues?.leads_transactions_id}#n${viewValues?.leads_notes_id}`
                    : `/dealdesk/${viewValues?.leads_transactions_id}`
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                className='menuicon'
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '15px',
                  right: '20px',
                  width: '20px',
                }}
                alt=''
                src={AUTH.GetThemedImage('Deal_icn')}
              />
            </Link>
          )}
        </>,
      ]}
    >
      <div className='viewPeople' key='1'>
        <table cellPadding='0' cellSpacing='0' border='0'>
          <tbody>
            <tr>
              <td width='20%' style={{ border: '0' }}></td>
              <td width='30%' style={{ border: '0' }}></td>
              <td width='20%' style={{ border: '0' }}></td>
              <td width='30%' style={{ border: '0' }}></td>
            </tr>
            <tr>
              <th>{t('Common_Data.Reminder')} {t('Common_Data.Type')}</th>
              <td>
                {viewValues?.reminder_type &&
                  REMINDERSSTORE.remindersTypeValue[viewValues?.reminder_type]}
              </td>
              <th>{t('Common_Data.Reminder')} {t('Reminders.For')}</th>
              <td>
                {viewValues?.reminder_type === 'contacts'
                  ? viewValues?.reminder_for_info
                  : viewValues?.reminder_for_name}
              </td>
            </tr>
            <tr>
              <th>{t('Common_Data.Priority')}</th>
              <td>
                {priorityFilter && priorityFilter.length > 0
                  ? priorityFilter[0].text
                  : ''}
              </td>
              <th>{t('Common_Data.Status')}</th>
              <td>{viewValues?.status}</td>
            </tr>
            <tr>
              <th>{t('Common_Data.Reminder')} {t('Reminders.Due')} {t('Common_Data.Date')}</th>
              <td>
                {viewValues?.due_date_time &&
                  moment(viewValues?.due_date_time).format(AUTH.global_fulldate_format_upper)}
              </td>{' '}
              {/* <th>Reminder Date-Time</th>
              <td>
                {viewValues?.reminder_date_time &&
                  moment(viewValues?.reminder_date_time).format('YYYY-MM-DD hh:mm A')}
              </td> */}
              <th>{t('Common_Data.Created')} {t('Common_Data.By')}</th>
              <td>{viewValues?.created_by_name}</td>
            </tr>
            <tr>
              <th>{t('Common_Data.Subject')}</th>
              <td colSpan={3}>{viewValues?.subject}</td>
            </tr>
            <tr>
              <th>{t('Common_Data.Description')}</th>
              <td colSpan={3}>{viewValues?.description}</td>
            </tr>
            <tr>
              <th>{t('Common_Data.Full')} {t('Reminders.Description')}</th>
              <td colSpan={3}>{viewValues?.full_description}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <TrackTimeComponent
        pageName={'reminders'}
        leadId={viewValues?.leads_transactions_id ? viewValues?.leads_transactions_id : 0}
      /> */}
      {(props?.fromSideBar && props.visible) &&
        <TimeTracker
          page={"reminders"}
          // stop={!props.visible}
          fromSideBar={props?.fromSideBar}
      />
      }
    </Drawer>
  );
});

export default ViewComponent;
