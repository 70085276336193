import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';
import Details from './Details';
import Model from './Model';
import Sum from './Sum';
import View from './View';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';
const Deal = observer(() => {
  const {t} = useTranslation()
  const {
    DEAL: { isDisable },
  } = useStore();

  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState('1');
  const changeTab = (activeKey) => {
    setActiveTab(activeKey);
  };

  return (
    <Tabs activeKey={activeTab} centered className='Dealdesk_SubTab' onChange={changeTab} destroyInactiveTabPane={true}>
      <TabPane tab={`${t('DealDesk.Details')}`} key='1' destroyInactiveTabPane={true}>
        <Details changeTab={changeTab} />
      </TabPane>
      <TabPane tab={`${t('DealDesk.Model')}`} key='2' disabled={isDisable} destroyInactiveTabPane={true}>
        <Model changeTab={changeTab} />
      </TabPane>
      <TabPane tab={`${t('DealDesk.Sum')}`} key='3' disabled={isDisable} destroyInactiveTabPane={true}>
        <Sum changeTab={changeTab} />
      </TabPane>
      <TabPane tab={`${t('DealDesk.View')}`} key='4' disabled={isDisable} destroyInactiveTabPane={true}>
        <View changeTab={changeTab} />
      </TabPane>
    </Tabs>
  );
});

export default Deal;
