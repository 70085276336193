import React from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col } from 'antd';
import InputComponent from '../../../component/InputComponent';
import { vsmClassification } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(({ handleSubmit, onChange, form, id }) => {
  const {t}= useTranslation();
  const onFinish = (data) => {
    handleSubmit(data);
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      onFinish={onFinish}
      className='innerForm'
      id={id}
    >
      <Row gutter={15}>
        <Col xs={{ span: 24 }}>
          <InputComponent
            name='name'
            label={`${t('Credebt_Classification.Classification_Name')}:`}
            required
            placeholder={`${t('Credebt_Classification.Classification_Name')}`}
            type='text'
            tooltip={`${t('Credebt_Classification.Industry_Sectors')}`}
            onChange={onChange}
            rules={vsmClassification.validation.ClassificationName}
          />
        </Col>
      </Row>
    </Form>
  );
});

export default FormComponent;
