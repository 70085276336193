import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Spin, Form, Row, Col } from 'antd';
import InputComponent from '../../../../../component/InputComponent';
import { vsmDealDesk } from '../../../../../config/messages';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(
  ({ disabled, form, handleChange, editable, is_issued, is_sum = false }) => {
    const { t } = useTranslation();

    const formList = useMemo(
      () => [
        { value: 'a-ETR', text: `a-${t('DealDesk.ETR')}` },
        { value: 'b-ETR', text: `b-${t('DealDesk.ETR')}` },
        { value: 'c-ETR', text: `c-${t('DealDesk.ETR')}` },
        { value: 'd-ETR', text: `d-${t('DealDesk.ETR')}` },
        { value: 'f-ETR', text: `f-${t('DealDesk.ETR')}` },
      ],
      [t]
    );

    const [isloading, setLoading] = useState(false);
    const {
      DEALDESKSTORE: { deal_data, getFormFields },
      DEAL: { fetchDealDetails, getCurrencyList },
    } = useStore();

    const [currencyList, setCurrencyList] = useState();

    const dealid = deal_data.lead_information && deal_data.lead_information.id;

    const fetchFormFields = useCallback(
      (data) => {
        setLoading(true);
        getFormFields({ search_text: data })
          .then(async (res) => {
            await form.setFieldsValue({
              fields: res.data?.data,
            });
            if (editable) {
              handleChange();
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      },
      [editable, form, getFormFields, handleChange, setLoading]
    );

    useEffect(() => {
      setLoading(true);
      fetchDealDetails(dealid, { is_issued }).then((data) => {
        setLoading(false);
        if (data) {
          if (is_sum) {
            data.fields = data.fields.filter((x) => x.is_algorithm === 'true');
          } else {
            data.fields = data.fields.filter((x) => x.is_algorithm !== 'true');
          }
          form.setFieldsValue(data);
        } else {
          if (formList && formList[0] && editable) {
            fetchFormFields(formList[0].value);
            form.setFieldsValue({
              rpa_group: formList[0].value,
            });
          }
        }
      });
    }, [
      formList,
      form,
      fetchFormFields,
      fetchDealDetails,
      dealid,
      editable,
      is_issued,
      is_sum,
    ]);

    useEffect(() => {
      getCurrencyList().then((data) => {
        setCurrencyList(data);
      });
    }, [getCurrencyList]);

    const renderInput = (item, field) => {
      if (!field) return null;
      const field_label = field.field_name && field.field_name;

      if (field.algorithm_id) {
        return (
          <InputComponent
            disabled={true}
            name={[item.name, 'default_value']}
            label={field_label}
            placeholder={field_label}
            tooltip={field.description}
          />
        );
      }

      if (['Currency'].includes(field.field_type)) {
        return (
          <InputComponent
            name={[item.name, 'default_value']}
            disabled={disabled}
            label={field_label}
            onChange={
              editable
                ? handleChange
                : () => {
                    return null;
                  }
            }
            type='select'
            tooltip={field.description}
            options={{
              values: currencyList,
              value_key: 'currency_code',
              text_key: {
                key: ['currency_name', ' (', 'currency_code', ')'],
              },
            }}
            rules={[
              {
                required: true,
                message: field_label + `${t('DealDesk.Cannot_Empty')}`,
              },
            ]}
          />
        );
      }

      if (
        [
          'Flag',
          'Toggle (Months/Days)',
          'Toggle (Convertibill®/Trade Credebt®)',
          'Toggle (Months/b-ETR/c-ETR)',
        ].includes(field.field_type)
      ) {
        let optionList = [];
        if (field.field_type === 'Toggle (Convertibill®/Trade Credebt®)') {
          optionList = [
            { value: 'Convertibill®', text: `${t('DealDesk.Convertibill')}®` },
            { value: 'Trade Credebt®', text: `${t('DealDesk.Trade_Credebt')}®` },
          ];
        }
        if (field.field_type === 'Toggle (Months/Days)') {
          optionList = [
            { value: 'Months', text: `${t('Common_Data.Months')}` },
            { value: 'Days', text: `${t('Common_Data.Days')}` },
          ];
        }
        if (field.field_type === 'Flag') {
          optionList = [
            { value: '0', text: `${t('Common_Data.Yes')}` },
            { value: '1', text: `${t('Common_Data.No')}` },
          ];
        }
        if (field.field_type === 'Toggle (Months/b-ETR/c-ETR)') {
          optionList = [
            { value: 'Months', text: `${t('Common_Data.Months')}` },
            { value: 'b-ETR', text: `b-${t('DealDesk.ETR')}` },
            { value: 'c-ETR', text: `c-${t('DealDesk.ETR')}` },
          ];
        }

        return (
          <InputComponent
            name={[item.name, 'default_value']}
            disabled={disabled}
            label={field_label}
            onChange={
              editable
                ? handleChange
                : () => {
                    return null;
                  }
            }
            type='radio_button'
            tooltip={field.description}
            options={{
              values: optionList,
            }}
            rules={[
              {
                required: true,
                message: field_label + `${t('DealDesk.Cannot_Empty')}`,
              },
            ]}
          />
        );
      }

      if (field.field_type === 'Flag') {
        return (
          <InputComponent
            name={[item.name, 'default_value']}
            disabled={disabled}
            label={field_label}
            onChange={
              editable
                ? handleChange
                : () => {
                    return null;
                  }
            }
            rules={vsmDealDesk.deal.firststep.validation['val_' + field.field_type]}
            initialValue={0}
            type='radio_button'
            tooltip={field.description}
            options={{
              values: [
                { value: 1, text: `${t('Common_Data.Yes')}` },
                { value: 0, text: `${t('Common_Data.No')}` },
              ],
            }}
          />
        );
      }

      return (
        <InputComponent
          disabled={disabled}
          name={[item.name, 'default_value']}
          label={field_label}
          placeholder={field_label}
          onChange={
            editable
              ? handleChange
              : () => {
                  return null;
                }
          }
          rules={vsmDealDesk.deal.firststep.validation['val_' + field.field_type]}
          tooltip={field.description}
        />
      );
    };

    return (
      <div>
        <InputComponent
          disabled={disabled}
          type='select'
          name='rpa_group'
          label={`${t('DealDesk.RPA_Group')}`}
          placeholder={`${t('DealDesk.Select_RPA_Group')}`}
          options={{
            values: formList,
          }}
          onChange={fetchFormFields}
          rules={vsmDealDesk.deal.firststep.validation.rpa_group}
        />
        <Spin spinning={isloading}>
          <Form.List name='fields'>
            {(fields) => {
              return (
                <Row style={{ marginBottom: 24 }} gutter={24}>
                  {fields &&
                    fields.map((field, index) => {
                      return (
                        <Col key={index} span={12}>
                          {renderInput(field, form.getFieldValue('fields')[index])}
                        </Col>
                      );
                    })}
                </Row>
              );
            }}
          </Form.List>
        </Spin>
      </div>
    );
  }
);

export default FormComponent;
