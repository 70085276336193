import React from 'react';
import { Switch, Row, Col, Space } from 'antd';
import moment from 'moment';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../../../component/DatePickerCostume';
const ScheduleHeader = ({
  openViewDrawer,
  SelectedDate,
  SetDate,
  SetDateType,
  AddNode,
  scrollToNode = () => { },
  handleFormChange = () => { },
  autoSaveData = () => { },
  saveObj,
  is_checked,
  setis_checked,
  scheduleList,
}) => {
  const { AUTH } = useStore();
  const disabledDate = (current) => {
    // Can not select sundays and predfined days

    return moment(current).day() === 0 || moment(current).day() === 6;
  };

  const { t } = useTranslation();

  return (
    <div className='header'>
      <Row gutter={10}>
        <Col span={5}>
          <img
            src={AUTH.GetThemedImage('add')}
            alt={`${t('DealDesk.Add')}`}
            className='icon circle cursorPointer'
            onClick={() => {
              let allowAddNode = true;
              if (
                scheduleList &&
                scheduleList.length > 0 &&
                parseInt(scheduleList[scheduleList.length - 1].position + 1) > 365
              ) {
                allowAddNode = false;
              }
              if (allowAddNode) {
                AddNode();
                scrollToNode(0);
                autoSaveData();
              }
            }}
          />
          {openViewDrawer && (
            <img
              src={AUTH.GetThemedImage('Deal_Library_icn')}
              alt={`${t('DealDesk.Add')}`}
              className='icon cursorPointer'
              onClick={openViewDrawer}
            />
          )}
        </Col>
        <Col span={19} className='text-right'>
          <Space size={5}>
            <span className='mr-24 DateType'>
              {is_checked ? `${t('DealDesk.Deadline')}` : `${t('DealDesk.Start_Date')}`}
            </span>
            <Switch
              className='mr-24'
              checked={is_checked}
              onChange={(value) => {
                SetDateType(value);
                setis_checked(value);
                handleFormChange();
                if (saveObj) {
                  let currentObj = saveObj();
                  currentObj.is_checked = value;
                  autoSaveData(currentObj);
                } else {
                  autoSaveData();
                }
              }}
            />
            <div className='Calendar'>
              <DatePickerCostume
                format='YYYY-MM-DD'
                defaultValue={moment(SelectedDate)}
                value={moment(SelectedDate)}
                disabledDate={disabledDate}
                allowClear={false}
                onChange={(value) => {
                  SetDate(value);
                  handleFormChange();
                  if (saveObj) {
                    let currentObj = saveObj();
                    currentObj.date = value;
                    autoSaveData(currentObj);
                  } else {
                    autoSaveData();
                  }
                }}
              />
              {moment(SelectedDate).format(AUTH.global_dateformat)}
              <img
                src={AUTH.GetThemedImage('Calendar')}
                alt={`${t('DealDesk.Add')}`}
                className='icon ml-5'
              />
            </div>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default ScheduleHeader;
