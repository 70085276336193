import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';
const AutosaveNotifiy = observer(() => {
    const {t} = useTranslation();
    const {
        SSSLEADLIBRARYSTORE: { notifySchedule, setNotifySchedule },
    } = useStore();

    useEffect(() => {
        if (notifySchedule) {
            setTimeout(() => {
                setNotifySchedule(false);
            }, 4000);
        }
    }, [notifySchedule, setNotifySchedule]);

    return (
        <>
            {notifySchedule ? <span className={'autoSaveNoteNotify'}>{t('DealDesk.Draft_Saved')}</span> : null}
        </>
    );
});

export default AutosaveNotifiy;
