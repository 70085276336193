import { notification } from 'antd';
import IBAN from 'iban';
import TranslateMessage from '../component/TranslateMessages';

const vsmAuth = {
  success: <TranslateMessage msgKey={'Messages.vsmAuth_success'} />,
  forgot_success: <TranslateMessage msgKey={'Messages.vsmAuth_forgot_success'} />,
  reset_success: <TranslateMessage msgKey={'Messages.vsmAuth_reset_success'} />,
  validation: {
    email: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmAuth_validation_email_required'} />
        ),
      },
      {
        pattern:
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        message: (
          <TranslateMessage msgKey={'Messages.vsmAuth_validation_email_pattern'} />
        ),
      },
    ],
    password: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmAuth_validation_password_required'} />
        ),
      },
      {
        pattern: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmAuth_validation_password_pattern'} />
        ),
      },
    ],
    confirmpassword: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmAuth_validation_confirmpassword_required'}
          />
        ),
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            <TranslateMessage
              msgKey={'Messages.vsmAuth_validation_confirmpassword_validator'}
            />
          );
        },
      }),
    ],
  },
};

const vsmCommon = {
  // noRecord: <TranslateMessage msgKey={'Messages.vsmCommon_noRecord'} />,
  noRecord: 'No Records Found.',
};
const vsmNotify = {
  success: (data) => {
    notification.success({
      placement: 'bottomRight',
      duration: 3,
      ...data,
    });
  },
  error: (data) => {
    notification.error({
      placement: 'bottomRight',
      duration: 3,
      ...data,
    });
  },
};

const vsmUsergroup = {
  add: <TranslateMessage msgKey={'Messages.vsmUsergroup_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmUsergroup_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmUsergroup_delete'} />,
  import: <TranslateMessage msgKey={'Messages.vsmUsergroup_import'} />,
  importRole: <TranslateMessage msgKey={'Messages.vsmUsergroup_importRole'} />,
  permissionSuccess: (
    <TranslateMessage msgKey={'Messages.vsmUsergroup_permissionSuccess'} />
  ),
  validation: {
    group_name: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsergroup_validation_group_name_required'}
          />
        ),
      },
      {
        min: 1,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsergroup_validation_group_name_min'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsergroup_validation_group_name_pattern'}
          />
        ),
      },
    ],
  },
};

const vsmContracts = {
  add: <TranslateMessage msgKey={'Messages.vsmContracts_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmContracts_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmContracts_delete'} />,
  import: <TranslateMessage msgKey={'Messages.vsmContracts_import'} />,
  importRole: <TranslateMessage msgKey={'Messages.vsmContracts_importRole'} />,
  validation: {
    contract_owner: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_owner_required'}
          />
        ),
      },
      {
        min: 3,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_owner_min'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_owner_pattern'}
          />
        ),
      },
    ],
    contract_number: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_number_required'}
          />
        ),
      },
      {
        min: 3,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_number_min'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_number_pattern'}
          />
        ),
      },
    ],
    contract_start_date: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_start_date_required'}
          />
        ),
      },
    ],
    contract_end_date: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_end_date_required'}
          />
        ),
      },
    ],
    contract_months: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_months_required'}
          />
        ),
      },
      {
        pattern: /^[0-9]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_months_pattern'}
          />
        ),
      },
    ],
    global_organisation_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_global_organisation_id_required'}
          />
        ),
      },
    ],
    contract_status: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_contract_status_required'}
          />
        ),
      },
    ],
    price_book_desc: [
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_price_book_desc_pattern'}
          />
        ),
      },
    ],
    currency_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_currency_id_required'}
          />
        ),
      },
    ],
    special_terms: [
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_special_terms_pattern'}
          />
        ),
      },
    ],
    description: [
      {
        max: 100,
        message: (
          <TranslateMessage msgKey={'Messages.vsmContracts_validation_description_max'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTickertap_validation_description_pattern'}
          />
        ),
      },
    ],
    customer_signed_by: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_customer_signed_by_required'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_customer_signed_by_pattern'}
          />
        ),
      },
    ],
    company_signed_by: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_company_signed_by_required'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmContracts_validation_company_signed_by_pattern'}
          />
        ),
      },
    ],
  },
};

const vsmKnowledge = {
  add: <TranslateMessage msgKey={'Messages.vsmKnowledge_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmKnowledge_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmKnowledge_delete'} />,
  validation: {
    subject: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmKnowledge_validation_subject_required'}
          />
        ),
      },
    ],
    classification: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmKnowledge_validation_classification_required'}
          />
        ),
      },
    ],
    knowledge_classification: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmKnowledge_validation_knowledge_classification_required'}
          />
        ),
      },
    ],
    description: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmKnowledge_validation_description_required'}
          />
        ),
      },
    ],
  },
};

const vsmTickertap = {
  add: <TranslateMessage msgKey={'Messages.vsmTickertap_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmTickertap_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmTickertap_delete'} />,
  tickerSetting: <TranslateMessage msgKey={'Messages.vsmTickertap_tickerSetting'} />,
  validation: {
    ticker_text: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTickertap_validation_ticker_text_required'}
          />
        ),
      },
      {
        min: 10,
        message: (
          <TranslateMessage msgKey={'Messages.vsmTickertap_validation_ticker_text_min'} />
        ),
      },
      {
        pattern: /^[^\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTickertap_validation_ticker_text_pattern'}
          />
        ),
      },
    ],
    ticker_url: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTickertap_validation_ticker_url_required'}
          />
        ),
      },
      {
        min: 1,
        message: (
          <TranslateMessage msgKey={'Messages.vsmTickertap_validation_ticker_url_min'} />
        ),
        type: 'url',
      },
    ],

    description: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTickertap_validation_description_required'}
          />
        ),
      },
      {
        min: 3,
        message: (
          <TranslateMessage msgKey={'Messages.vsmTickertap_validation_description_min'} />
        ),
      },
      {
        pattern: /^[^\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTickertap_validation_description_pattern'}
          />
        ),
      },
    ],
    userGroups: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTickertap_validation_userGroups_required'}
          />
        ),
      },
    ],
  },
};

const vsmUsers = {
  addUser: <TranslateMessage msgKey={'Messages.vsmUsers_addUser'} />,
  editUser: <TranslateMessage msgKey={'Messages.vsmUsers_editUser'} />,
  deleteUser: <TranslateMessage msgKey={'Messages.vsmUsers_deleteUser'} />,
  modifyUser: <TranslateMessage msgKey={'Messages.vsmUsers_modifyUser'} />,
  resetUser: <TranslateMessage msgKey={'Messages.vsmUsers_resetUser'} />,
  resetEmailUser: <TranslateMessage msgKey={'Messages.vsmUsers_resetEmailUser'} />,
  restoreUser: <TranslateMessage msgKey={'Messages.vsmUsers_restoreUser'} />,
  activeUser: <TranslateMessage msgKey={'Messages.vsmUsers_activeUser'} />,
  deactiveUser: <TranslateMessage msgKey={'Messages.vsmUsers_deactiveUser'} />,
  userSetting: <TranslateMessage msgKey={'Messages.vsmUsers_userSetting'} />,
  userSalesTarget: <TranslateMessage msgKey={'Messages.vsmUsers_userSalesTarget'} />,
  userProfileUpdate: <TranslateMessage msgKey={'Messages.vsmUsers_userProfileUpdate'} />,
  cancelSettingFormNotify: (
    <TranslateMessage msgKey={'Messages.vsmUsers_cancelSettingFormNotify'} />
  ),
  validation: {
    sales_target: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_sales_target_required'}
          />
        ),
      },
      {
        pattern: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_sales_target_pattern'}
          />
        ),
      },
    ],
    days_target: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_days_target_required'}
          />
        ),
      },
    ],
    timezone: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_timezone_required'} />
        ),
      },
    ],
    date_format: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_date_format_required'}
          />
        ),
      },
    ],
    time_format: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_time_format_required'}
          />
        ),
      },
    ],
    organization_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_organization_hourglass_required'}
          />
        ),
      },
    ],
    people_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_people_hourglass_required'}
          />
        ),
      },
    ],
    screen_ticket_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_screen_ticket_hourglass_required'}
          />
        ),
      },
    ],
    screen_note_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_screen_note_hourglass_required'}
          />
        ),
      },
    ],
    screen_reminder_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_screen_reminder_hourglass_required'}
          />
        ),
      },
    ],

    reminder_due_days: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_reminder_due_days_required'}
          />
        ),
      },
    ],
    reminder_due_hours: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_reminder_due_hours_required'}
          />
        ),
      },
    ],
    reminder_due_mins: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_reminder_due_mins_required'}
          />
        ),
      },
    ],
    note_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_note_hourglass_required'}
          />
        ),
      },
    ],
    screen_organization_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_screen_organization_hourglass_required'}
          />
        ),
      },
    ],
    screen_people_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_screen_people_hourglass_required'}
          />
        ),
      },
    ],
    screen_unused_leads_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_screen_unused_leads_hourglass_required'}
          />
        ),
      },
    ],
    screen_used_leads_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_screen_used_leads_hourglass_required'}
          />
        ),
      },
    ],
    screen_opportunity_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_screen_opportunity_hourglass_required'}
          />
        ),
      },
    ],
    deal_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_deal_hourglass_required'}
          />
        ),
      },
    ],
    screen_tags_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_screen_tags_hourglass_required'}
          />
        ),
      },
    ],
    ticket_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_ticket_hourglass_required'}
          />
        ),
      },
    ],
    snapnotesendoptions: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmUsers_validation_snap_note_send_option_required'}
          />
        ),
      },
    ],
    first_name: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_first_name_required'} />
        ),
      },
      {
        min: 1,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_first_name_min'} />
        ),
      },
    ],
    last_name: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_last_name_required'} />
        ),
      },
      {
        min: 1,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_last_name_min'} />
        ),
      },
    ],
    email: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_email_required'} />
        ),
      },
      {
        pattern:
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_email_pattern'} />
        ),
      },
    ],
    phone: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_phone_required'} />
        ),
      },
      {
        pattern:
          /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,11},(\se|e)xt.(\s\d{2,4}|\d{2,4})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,11}$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_phone_pattern'} />
        ),
      },
    ],
    mobile: [
      {
        pattern:
          /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,11},(\se|e)xt.(\s\d{2,4}|\d{2,4})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,11}$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_mobile_pattern'} />
        ),
      },
    ],
    msgno: [
      {
        pattern:
          /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,11},(\se|e)xt.(\s\d{2,4}|\d{2,4})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,11}$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_msgno_pattern'} />
        ),
      },
    ],
    photo: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmUsers_validation_photo_required'} />
        ),
      },
      {
        min: 1,
        message: <TranslateMessage msgKey={'Messages.vsmUsers_validation_photo_min'} />,
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_photo_pattern'} />
        ),
      },
    ],
    language: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_language_required'}
          />
        ),
      },
    ],
  },
};

const vsmClassification = {
  add: <TranslateMessage msgKey={'Messages.vsmClassification_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmClassification_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmClassification_delete'} />,
  alreadyexist: <TranslateMessage msgKey={'Messages.vsmClassification_alreadyexist'} />,
  validation: {
    ClassificationName: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmClassification_validation_ClassificationName_required'}
          />
        ),
      },
      {
        min: 1,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmClassification_validation_ClassificationName_min'}
          />
        ),
      },
    ],
    screenhour_usedleads: [
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmClassification_validation_screenhour_usedleads_pattern'}
          />
        ),
      },
    ],
  },
};

const vsmLeadstatus = {
  add: <TranslateMessage msgKey={'Messages.vsmLeadstatus_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmLeadstatus_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmLeadstatus_delete'} />,
  validation: {
    status_name: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadstatus_validation_status_name_required'}
          />
        ),
      },
      {
        min: 1,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadstatus_validation_status_name_min'}
          />
        ),
      },
    ],
    parent_status_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadstatus_validation_parent_status_id_required'}
          />
        ),
      },
    ],
    record_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadstatus_validation_record_type_required'}
          />
        ),
      },
    ],
  },
};

const vsmSettings = {
  add: <TranslateMessage msgKey={'Messages.vsmSettings_add'} />,
  cancelResetFormNotify: (
    <TranslateMessage msgKey={'Messages.vsmSettings_cancelResetFormNotify'} />
  ),
  validation: {
    idle_popup_duration: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_idle_popup_duration_required'}
          />
        ),
      },
    ],
    maxcharacterLength: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_maxcharacterLength_required'}
          />
        ),
      },
      {
        pattern: /^([1-9][0-9]{0,2}|999)$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_maxcharacterLength_pattern'}
          />
        ),
      },
    ],
    reminder_due_days: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_reminder_due_days_required'}
          />
        ),
      },
    ],
    reminder_due_hours: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_reminder_due_hours_required'}
          />
        ),
      },
    ],
    reminder_due_mins: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_reminder_due_mins_required'}
          />
        ),
      },
    ],
    timezone: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_timezone_required'}
          />
        ),
      },
    ],
    language: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_language_required'}
          />
        ),
      },
    ],
    date_format: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_date_format_required'}
          />
        ),
      },
    ],
    time_format: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_time_format_required'}
          />
        ),
      },
    ],
    product: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmSettings_validation_product_required'} />
        ),
      },
    ],
    organization_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_organization_hourglass_required'}
          />
        ),
      },
    ],
    people_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_people_hourglass_required'}
          />
        ),
      },
    ],

    deal_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_deal_hourglass_required'}
          />
        ),
      },
    ],
    note_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_note_hourglass_required'}
          />
        ),
      },
    ],
    dealSnapNote: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_dealSnapNote_required'}
          />
        ),
      },
    ],
    ticket_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_ticket_hourglass_required'}
          />
        ),
      },
    ],
    NoteSnapNote: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_NoteSnapNote_required'}
          />
        ),
      },
    ],
    PeopleSnapNote: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_PeopleSnapNote_required'}
          />
        ),
      },
    ],
    tagsScreen: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_tagsScreen_required'}
          />
        ),
      },
    ],
    TicketsScreen: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_TicketsScreen_required'}
          />
        ),
      },
    ],
    screen_unused_leads_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_screen_unused_leads_hourglass_required'
            }
          />
        ),
      },
    ],
    screen_used_leads_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_screen_used_leads_hourglass_required'
            }
          />
        ),
      },
    ],
    screen_opportunity_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_screen_opportunity_hourglass_required'
            }
          />
        ),
      },
    ],
    screen_deals_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_screen_deals_hourglass_required'}
          />
        ),
      },
    ],
    screen_organization_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_screen_organization_hourglass_required'
            }
          />
        ),
      },
    ],
    screen_people_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_screen_people_hourglass_required'}
          />
        ),
      },
    ],
    screen_tags_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_screen_tags_hourglass_required'}
          />
        ),
      },
    ],
    screen_ticket_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_screen_ticket_hourglass_required'}
          />
        ),
      },
    ],
    screen_reminder_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_screen_reminder_hourglass_required'}
          />
        ),
      },
    ],
    screen_note_hourglass: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_screen_note_hourglass_required'}
          />
        ),
      },
    ],
    PeopleScreen: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_PeopleScreen_required'}
          />
        ),
      },
    ],
    snap_note_send_option: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_snap_note_send_option_required'}
          />
        ),
      },
    ],
    week_number: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_week_number_required'}
          />
        ),
      },
      {
        pattern: /^[0-9]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_week_number_pattern'}
          />
        ),
      },
    ],
    meetings: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_meetings_required'}
          />
        ),
      },
      {
        pattern: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_meetings_required'}
          />
        ),
      },
    ],
    converted: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_converted_required'}
          />
        ),
      },
      {
        pattern: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_converted_pattern'}
          />
        ),
      },
    ],
    rpa: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmSettings_validation_rpa_required'} />
        ),
      },
      {
        pattern: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmSettings_validation_rpa_pattern'} />
        ),
      },
    ],
    confirmed: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_confirmed_required'}
          />
        ),
      },
      {
        pattern: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_confirmed_pattern'}
          />
        ),
      },
    ],
    traded: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmSettings_validation_traded_required'} />
        ),
      },
      {
        pattern: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmSettings_validation_traded_pattern'} />
        ),
      },
    ],
    teamIndividual: [
      {
        pattern: /^(([0-9]+\.[0-9]{1,2})|[0-9]+)$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_teamIndividual_pattern'}
          />
        ),
      },
    ],

    with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          var count = 0;
          if (value) {
            if (getFieldValue('bonus_settings')['notes_category']) {
              getFieldValue('bonus_settings')['notes_category'].forEach((x, index) => {
                if (value && value === x.color_name) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={'Messages.vsmSettings_validation_with_required_validator'}
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_with_required_required'}
          />
        ),
      },
    ],
    colorpicker_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('bonus_settings')['notes_category']) {
              getFieldValue('bonus_settings')['notes_category'].forEach((x, index) => {
                if (value && value.hex && value.hex === x.color_code.hex) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_colorpicker_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_colorpicker_with_required_required'}
          />
        ),
      },
    ],
    quick_note_text_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('quick_notes')) {
              getFieldValue('quick_notes').forEach((x, index) => {
                if (value && value === x.quick_note_text) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_quick_note_text_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_quick_note_text_with_required_required'
            }
          />
        ),
      },
    ],
    quick_note_text_value_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('quick_notes')) {
              getFieldValue('quick_notes').forEach((x, index) => {
                if (value && value === x.quick_note_text_value) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_quick_note_text_value_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_quick_note_text_value_with_required_required'
            }
          />
        ),
      },
    ],
    reason_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('failed_reasons')) {
              getFieldValue('failed_reasons').forEach((x, index) => {
                if (value && value === x.reason) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_reason_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_reason_with_required_required'}
          />
        ),
      },
    ],

    rejectedreason_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('rejected_reasons')) {
              getFieldValue('rejected_reasons').forEach((x, index) => {
                if (value && value === x.reason) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_rejectedreason_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_rejectedreason_with_required_required'
            }
          />
        ),
      },
    ],
    futurereason_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('future_reasons')) {
              getFieldValue('future_reasons').forEach((x, index) => {
                if (value && value === x.reason) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_futurereason_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_futurereason_with_required_required'}
          />
        ),
      },
    ],
    connection_roles_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('connection_role')) {
              getFieldValue('connection_role').forEach((x, index) => {
                if (value && value === x.role) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_connection_roles_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_connection_roles_with_required_required'
            }
          />
        ),
      },
    ],
    knowledge_classification_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('knowledge_classification')) {
              getFieldValue('knowledge_classification').forEach((x, index) => {
                if (value && value === x.knowledge_classification) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_knowledge_classification_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_knowledge_classification_with_required_required'
            }
          />
        ),
      },
    ],

    person_telephone_types_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('type')) {
              getFieldValue('type').forEach((x, index) => {
                if (value && value === x.type) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_person_telephone_types_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_person_telephone_types_with_required_required'
            }
          />
        ),
      },
    ],
    person_email_types_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('type')) {
              getFieldValue('type').forEach((x, index) => {
                if (value && value === x.type) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_person_email_types_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_person_email_types_with_required_required'
            }
          />
        ),
      },
    ],
    person_address_types_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('type')) {
              getFieldValue('type').forEach((x, index) => {
                if (value && value === x.type) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_person_address_types_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_person_address_types_with_required_required'
            }
          />
        ),
      },
    ],

    org_telephone_types_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('type')) {
              getFieldValue('type').forEach((x, index) => {
                if (value && value === x.type) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_org_telephone_types_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_org_telephone_types_with_required_required'
            }
          />
        ),
      },
    ],
    org_email_types_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('type')) {
              getFieldValue('type').forEach((x, index) => {
                if (value && value === x.type) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_org_email_types_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_org_email_types_with_required_required'
            }
          />
        ),
      },
    ],
    org_address_types_with_required: [
      ({ getFieldValue }) => ({
        validator(rule, value, callback) {
          var count = 0;
          if (value) {
            if (getFieldValue('type')) {
              getFieldValue('type').forEach((x, index) => {
                if (value && value === x.type) {
                  count++;
                }
              });
            }

            if (value && count > 1) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmSettings_validation_org_address_types_with_required_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmSettings_validation_org_address_types_with_required_required'
            }
          />
        ),
      },
    ],
    genericemail_with_required: [
      {
        pattern: /^[a-zA-Z0-9!#$%&'/?^&*`{|+=_-]*$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSettings_validation_genericemail_with_required_pattern'}
          />
        ),
      },
    ],
  },
};

const vsmPeople = {
  add: <TranslateMessage msgKey={'Messages.vsmPeople_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmPeople_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmPeople_delete'} />,
  restore: <TranslateMessage msgKey={'Messages.vsmPeople_restore'} />,
  import: <TranslateMessage msgKey={'Messages.vsmPeople_import'} />,
  importRole: <TranslateMessage msgKey={'Messages.vsmPeople_importRole'} />,
  clone: <TranslateMessage msgKey={'Messages.vsmPeople_clone'} />,
  validation: {
    first_name: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_first_name_required'}
          />
        ),
      },
      {
        min: 1,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_first_name_min'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_first_name_pattern'} />
        ),
      },
    ],
    last_name: [
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_last_name_pattern'} />
        ),
      },
    ],
    title: [
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_title_pattern'} />
        ),
      },
    ],
    date_of_birth: [],
    phone_with_required: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_phone_with_required_required'}
          />
        ),
      },
      {
        pattern:
          /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_phone_with_required_pattern'}
          />
        ),
      },
    ],
    phone_without_required: [
      {
        pattern:
          /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_phone_without_required_pattern'}
          />
        ),
      },
    ],
    email_with_required: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_email_with_required_required'}
          />
        ),
      },
      {
        pattern:
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_email_with_required_pattern'}
          />
        ),
      },
    ],
    email_without_required: [
      {
        pattern:
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_email_without_required_pattern'}
          />
        ),
      },
    ],
    secondary_email: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          if (getFieldValue('secondary_email')) {
            let pattern =
              /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
            if (!pattern.test(getFieldValue('secondary_email'))) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={'Messages.vsmPeople_validation_secondary_email_validator'}
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
    ],
    classification: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_classification_required'}
          />
        ),
      },
    ],
    pps_number: [
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_pps_number_pattern'} />
        ),
      },
    ],
    former_last_name: [
      {
        min: 1,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_former_last_name_min'}
          />
        ),
      },
      {
        max: 100,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_former_last_name_max'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_former_last_name_pattern'}
          />
        ),
      },
    ],
    notes: [
      {
        min: 1,
        message: <TranslateMessage msgKey={'Messages.vsmPeople_validation_notes_min'} />,
      },
      {
        max: 131072,
        message: <TranslateMessage msgKey={'Messages.vsmPeople_validation_notes_max'} />,
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_notes_pattern'} />
        ),
      },
    ],
    ppsn_document_type: [],
    photo_URL: [
      {
        min: 1,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_photo_URL_min'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_photo_URL_pattern'} />
        ),
      },
    ],
    pronounced: [
      {
        min: 1,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_pronounced_min'} />
        ),
      },
      {
        max: 100,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_pronounced_max'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_pronounced_pattern'} />
        ),
      },
    ],
    address_type: [
      {
        min: 1,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_address_type_min'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_address_type_pattern'}
          />
        ),
      },
    ],
    address_1: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_address_1_required'}
          />
        ),
      },
      {
        min: 2,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_address_1_min'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_address_1_pattern'} />
        ),
      },
    ],
    address_2and3: [
      {
        min: 2,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_address_2and3_min'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_address_2and3_pattern'}
          />
        ),
      },
    ],
    city: [
      {
        min: 3,
        message: <TranslateMessage msgKey={'Messages.vsmPeople_validation_city_min'} />,
      },
      {
        max: 100,
        message: (
          <TranslateMessage msgKey={'Messages.vsmOrganisations_validation_city_max'} />
        ),
      },
      {
        pattern: /^([ A-Za-z0-9-\s]*)$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_city_pattern'} />
        ),
      },
    ],
    state: [
      {
        min: 3,
        message: <TranslateMessage msgKey={'Messages.vsmPeople_validation_state_min'} />,
      },
      {
        max: 100,
        message: (
          <TranslateMessage msgKey={'Messages.vsmOrganisations_validation_state_max'} />
        ),
      },
      {
        pattern: /^[ A-Za-z0-9-]*$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_state_pattern'} />
        ),
      },
    ],
    postal_code: [
      {
        min: 2,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_postal_code_min'} />
        ),
      },
      {
        pattern: /^[ A-Za-z0-9-/]*$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_postal_code_pattern'}
          />
        ),
      },
    ],
    country: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmPeople_validation_country_required'} />
        ),
      },
    ],
    organisation: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_organisation_required'}
          />
        ),
      },
    ],
    organisation_role: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_organisation_role_required'}
          />
        ),
      },
    ],
    related_entity_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_related_entity_type_required'}
          />
        ),
      },
    ],
    related_organisation: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          if (getFieldValue('related_entity_type') === 'People') {
            if (
              (getFieldValue('related_entity_id') === null ||
                getFieldValue('related_entity_id') === undefined ||
                getFieldValue('related_entity_id') === '') &&
              (getFieldValue('related_people_id') === null ||
                getFieldValue('related_people_id') === undefined ||
                getFieldValue('related_people_id') === '')
            ) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={'Messages.vsmPeople_validation_related_organisation_validator'}
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
    ],
    related_people: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          if (getFieldValue('related_entity_type') === 'People') {
            if (
              (getFieldValue('related_entity_id') === null ||
                getFieldValue('related_entity_id') === undefined ||
                getFieldValue('related_entity_id') === '') &&
              (getFieldValue('related_people_id') === null ||
                getFieldValue('related_people_id') === undefined ||
                getFieldValue('related_people_id') === '')
            ) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={'Messages.vsmPeople_validation_related_people_validator'}
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
    ],
    related_role: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          if (getFieldValue('related_entity_type') !== 'Ledger') {
            if (
              (getFieldValue('related_role_id') === null ||
                getFieldValue('related_role_id') === undefined ||
                getFieldValue('related_role_id') === '') &&
              (getFieldValue('related_role_id') === null ||
                getFieldValue('related_role_id') === undefined ||
                getFieldValue('related_role_id') === '')
            ) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={'Messages.vsmPeople_validation_related_role_validator'}
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
    ],
    tag_name: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          let pattern = /^([ A-Za-z0-9\s\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/;
          let data = getFieldValue('tag_ids');
          let min =
            data && data.find((item) => typeof item === 'string' && item.length < 3);
          let max =
            data && data.find((item) => typeof item === 'string' && item.length > 100);
          let check =
            data && data.find((item) => typeof item === 'string' && !pattern.test(item));
          if (min) {
            return Promise.reject(
              <TranslateMessage
                msgKey={'Messages.vsmPeople_validation_tag_name_validator_1'}
              />
            );
          }
          if (check) {
            return Promise.reject(
              <TranslateMessage
                msgKey={'Messages.vsmPeople_validation_tag_name_validator_2'}
              />
            );
          }
          if (max) {
            return Promise.reject(
              <TranslateMessage
                msgKey={'Messages.vsmOrganisations_validation_tag_name_validator_3'}
              />
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    exchange_ref_id: [
      {
        min: 3,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_exchange_ref_id_min'}
          />
        ),
      },
      {
        pattern: /^[0-9]*$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_exchange_ref_id_pattern'}
          />
        ),
      },
    ],
    email_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_email_type_required'}
          />
        ),
      },
    ],
    phone_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeople_validation_phone_type_required'}
          />
        ),
      },
    ],
  },
};

const vsmOrganisations = {
  add: <TranslateMessage msgKey={'Messages.vsmOrganisations_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmOrganisations_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmOrganisations_delete'} />,
  restore: <TranslateMessage msgKey={'Messages.vsmOrganisations_restore'} />,
  map: <TranslateMessage msgKey={'Messages.vsmOrganisations_map'} />,
  import: <TranslateMessage msgKey={'Messages.vsmOrganisations_import'} />,
  importRole: <TranslateMessage msgKey={'Messages.vsmOrganisations_importRole'} />,
  validation: {
    organisation: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_organisation_required'}
          />
        ),
      },
    ],
    organisation_role: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_organisation_role_required'}
          />
        ),
      },
    ],
    related_entity_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_related_entity_type_required'}
          />
        ),
      },
    ],
    related_organisation: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          if (getFieldValue('related_entity_type') === 'Organisation') {
            if (
              (getFieldValue('related_entity_id') === null ||
                getFieldValue('related_entity_id') === undefined ||
                getFieldValue('related_entity_id') === '') &&
              (getFieldValue('related_people_id') === null ||
                getFieldValue('related_people_id') === undefined ||
                getFieldValue('related_people_id') === '')
            ) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={
                    'Messages.vsmOrganisations_validation_related_organisation_validator'
                  }
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
    ],
    related_people: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          if (getFieldValue('related_entity_type') === 'People') {
            if (
              (getFieldValue('related_entity_id') === null ||
                getFieldValue('related_entity_id') === undefined ||
                getFieldValue('related_entity_id') === '') &&
              (getFieldValue('related_people_id') === null ||
                getFieldValue('related_people_id') === undefined ||
                getFieldValue('related_people_id') === '')
            ) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={'Messages.vsmOrganisations_validation_related_people_validator'}
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
    ],
    related_role: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          if (getFieldValue('related_entity_type') !== 'Ledger') {
            if (
              (getFieldValue('related_role_id') === null ||
                getFieldValue('related_role_id') === undefined ||
                getFieldValue('related_role_id') === '') &&
              (getFieldValue('related_role_id') === null ||
                getFieldValue('related_role_id') === undefined ||
                getFieldValue('related_role_id') === '')
            ) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={'Messages.vsmOrganisations_validation_related_role_validator'}
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
    ],
    tag_name: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          let pattern = /^[^\t\n]+$/;
          let data = getFieldValue('tag_ids');
          let min =
            data && data.find((item) => typeof item === 'string' && item.length < 2);
          let max =
            data && data.find((item) => typeof item === 'string' && item.length > 100);
          let check =
            data && data.find((item) => typeof item === 'string' && !pattern.test(item));
          if (min) {
            return Promise.reject(
              <TranslateMessage
                msgKey={'Messages.vsmOrganisations_validation_tag_name_validator_1'}
              />
            );
          }
          if (check) {
            return Promise.reject(
              <TranslateMessage
                msgKey={'Messages.vsmOrganisations_validation_tag_name_validator_2'}
              />
            );
          }
          if (max) {
            return Promise.reject(
              <TranslateMessage
                msgKey={'Messages.vsmOrganisations_validation_tag_name_validator_3'}
              />
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    exchange_ref_id: [
      {
        min: 3,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_exchange_ref_id_min'}
          />
        ),
      },
      {
        pattern: /^[0-9]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_exchange_ref_id_pattern'}
          />
        ),
      },
    ],
    organisation_name: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_organisation_name_required'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_organisation_name_pattern'}
          />
        ),
      },
    ],
    trade_name: [
      {
        min: 1,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_trade_name_min'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_trade_name_pattern'}
          />
        ),
      },
    ],
    registration_country: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_registration_country_required'}
          />
        ),
      },
    ],
    registration_number: [
      {
        min: 1,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_registration_number_min'}
          />
        ),
      },
      {
        pattern: /^[\000-\377]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_registration_number_pattern'}
          />
        ),
      },
    ],
    vat_number: [
      {
        min: 3,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_vat_number_min'}
          />
        ),
      },
      {
        pattern: /^[\000-\377]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_vat_number_pattern'}
          />
        ),
      },
    ],
    website: [
      {
        min: 1,
        message: (
          <TranslateMessage msgKey={'Messages.vsmOrganisations_validation_website_min'} />
        ),
        type: 'url',
      },
    ],
    phone: [
      {
        pattern:
          /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_phone_pattern'}
          />
        ),
      },
    ],
    status: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_status_required'}
          />
        ),
      },
    ],
    address_type: [
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_address_type_pattern'}
          />
        ),
      },
    ],
    address_1: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_address_1_required'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_address_1_pattern'}
          />
        ),
      },
    ],
    address_2and3: [
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_address_2and3_pattern'}
          />
        ),
      },
    ],
    city: [
      {
        max: 100,
        message: (
          <TranslateMessage msgKey={'Messages.vsmOrganisations_validation_city_max'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_city_pattern'}
          />
        ),
      },
    ],
    state: [
      {
        max: 100,
        message: (
          <TranslateMessage msgKey={'Messages.vsmOrganisations_validation_state_max'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_state_pattern'}
          />
        ),
      },
    ],
    postal_code: [
      {
        min: 1,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_postal_code_min'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_postal_code_pattern'}
          />
        ),
      },
    ],
    industry: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_industry_required'}
          />
        ),
      },
    ],
    nace_code: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_nace_code_required'}
          />
        ),
      },
    ],
    nace_category: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_nace_category_required'}
          />
        ),
      },
    ],
    org_currency: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_org_currency_required'}
          />
        ),
      },
    ],
    tax_clearence: [
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_tax_clearence_pattern'}
          />
        ),
      },
    ],
    description: [
      {
        min: 5,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_description_min'}
          />
        ),
      },
    ],
    country: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_country_required'}
          />
        ),
      },
    ],
    rating: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_rating_required'}
          />
        ),
      },
    ],
    short_rating: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_short_rating_required'}
          />
        ),
      },
      {
        whitespace: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_short_rating_whitespace'}
          />
        ),
      },
    ],
    long_rating: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_long_rating_required'}
          />
        ),
      },
      {
        whitespace: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_long_rating_whitespace'}
          />
        ),
      },
    ],
    email_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_email_type_required'}
          />
        ),
      },
    ],
    email_with_required: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_email_with_required_required'}
          />
        ),
      },
      {
        pattern:
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_email_with_required_pattern'}
          />
        ),
      },
    ],
    email_without_required: [
      {
        pattern:
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmOrganisations_validation_email_without_required_pattern'}
          />
        ),
      },
    ],
  },
};

const vsmRoleMapping = {
  add: <TranslateMessage msgKey={'Messages.vsmRoleMapping_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmRoleMapping_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmRoleMapping_delete'} />,
  mapBank: <TranslateMessage msgKey={'Messages.vsmRoleMapping_mapBank'} />,
  InvalidRecord: <TranslateMessage msgKey={'Messages.vsmRoleMapping_InvalidRecord'} />,
  validation: {
    organisation_role: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmRoleMapping_validation_organisation_role_required'}
          />
        ),
      },
    ],
    related_role: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmRoleMapping_validation_related_role_required'}
          />
        ),
      },
    ],
    Insurance: [
      {
        min: 3,
        message: (
          <TranslateMessage msgKey={'Messages.vsmRoleMapping_validation_Insurance_min'} />
        ),
      },
      {
        pattern: /^[\000-\377]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmRoleMapping_validation_Insurance_pattern'}
          />
        ),
      },
    ],
    Discount: [
      {
        min: 3,
        message: (
          <TranslateMessage msgKey={'Messages.vsmRoleMapping_validation_Discount_min'} />
        ),
      },
      {
        pattern: /^[\000-\377]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmRoleMapping_validation_Discount_pattern'}
          />
        ),
      },
    ],
    crm: [
      {
        min: 3,
        message: (
          <TranslateMessage msgKey={'Messages.vsmRoleMapping_validation_crm_min'} />
        ),
      },
      {
        pattern: /^[\000-\377]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmRoleMapping_validation_Insurance_min'} />
        ),
      },
    ],
  },
};

const vsmPeopleRoleMapping = {
  add: <TranslateMessage msgKey={'Messages.vsmPeopleRoleMapping_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmPeopleRoleMapping_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmPeopleRoleMapping_delete'} />,
  mapBank: <TranslateMessage msgKey={'Messages.vsmPeopleRoleMapping_mapBank'} />,
  validation: {
    organisation_role: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeopleRoleMapping_validation_organisation_role_required'}
          />
        ),
      },
    ],
    related_role: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeopleRoleMapping_validation_related_role_required'}
          />
        ),
      },
    ],
  },
};

const vsmPeopleRoles = {
  add: <TranslateMessage msgKey={'Messages.vsmPeopleRoles_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmPeopleRoles_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmPeopleRoles_delete'} />,
  import: <TranslateMessage msgKey={'Messages.vsmPeopleRoles_import'} />,
  validation: {
    role_name: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmRoles_validation_role_name_required'} />
        ),
      },
      {
        pattern: /^[ A-Za-z0-9-]*$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeopleRoles_validation_role_name_pattern'}
          />
        ),
      },
    ],
    related_roles: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmPeopleRoles_validation_related_roles_required'}
          />
        ),
      },
    ],
  },
};

const vsmRoles = {
  add: <TranslateMessage msgKey={'Messages.vsmRoles_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmRoles_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmRoles_delete'} />,
  validation: {
    role_name: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmRoles_validation_role_name_required'} />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage msgKey={'Messages.vsmRoles_validation_role_name_pattern'} />
        ),
      },
    ],
    related_role: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmRoles_validation_related_role_required'}
          />
        ),
      },
    ],
  },
};

const vsmLeadsList = {
  add: <TranslateMessage msgKey={'Messages.vsmLeadsList_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmLeadsList_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmLeadsList_delete'} />,
  successStatusUpdate: (
    <TranslateMessage msgKey={'Messages.vsmLeadsList_successStatusUpdate'} />
  ),
  successAssignLead: (
    <TranslateMessage msgKey={'Messages.vsmLeadsList_successAssignLead'} />
  ),
  validation: {
    lead_record_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_lead_record_type_required'}
          />
        ),
      },
    ],
    classification_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_classification_id_required'}
          />
        ),
      },
    ],
    full_name: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_full_name_required'}
          />
        ),
      },
    ],
    organisation_name: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_organisation_name_required'}
          />
        ),
      },
    ],
    product_name: [
      {
        min: 1,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_product_name_min'}
          />
        ),
      },
    ],
    email: [
      ({ getFieldValue }) => ({
        validator(value, rule) {
          let email = getFieldValue('email').filter((x) => x && x.contact_value);
          if (email && email.length === 0) {
            let phone = getFieldValue('phone').filter((x) => x && x.contact_value);
            if (phone && phone.length === 0) {
              return Promise.reject(
                <TranslateMessage
                  msgKey={'Messages.vsmLeadsList_validation_email_validator'}
                />
              );
            }
          }
          return Promise.resolve();
        },
      }),
    ],

    phone_with_required: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_phone_with_required_required'}
          />
        ),
      },
      {
        pattern:
          /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_phone_with_required_pattern'}
          />
        ),
      },
    ],
    phone_without_required: [
      {
        pattern:
          /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_phone_without_required_pattern'}
          />
        ),
      },
    ],
    email_with_required: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_email_with_required_required'}
          />
        ),
      },
      {
        pattern:
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_email_with_required_pattern'}
          />
        ),
      },
    ],
    email_without_required: [
      {
        pattern:
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_email_without_required_pattern'}
          />
        ),
      },
    ],

    lead_status_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_lead_status_id_required'}
          />
        ),
      },
    ],
    lead_currency_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_lead_currency_id_required'}
          />
        ),
      },
    ],
    phone_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_phone_type_required'}
          />
        ),
      },
    ],
    email_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_email_type_required'}
          />
        ),
      },
    ],
    finace_value: [
      {
        pattern:
          /^(([0-9]+\.[0-9]{1,6})|[0-9]+|[0-9]+[k,m,b,t,K,M,B,T]|[0-9]+\.[0-9]{1,6}[k,m,b,t,K,M,B,T])$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_finace_value_pattern'}
          />
        ),
      },
    ],
    turn_over: [
      {
        pattern:
          /^(([0-9]+\.[0-9]{1,6})|[0-9]+|[0-9]+[k,m,b,t,K,M,B,T]|[0-9]+\.[0-9]{1,6}[k,m,b,t,K,M,B,T])$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmLeadsList_validation_turn_over_pattern'}
          />
        ),
      },
    ],
  },
};

const vsmDealDesk = {
  deadLineSucess: <TranslateMessage msgKey={'Messages.vsmDealDesk_deadLineSucess'} />,
  deadStatusSucess: <TranslateMessage msgKey={'Messages.vsmDealDesk_deadStatusSucess'} />,
  successLeadOwnerUpdate: (
    <TranslateMessage msgKey={'Messages.vsmDealDesk_successLeadOwnerUpdate'} />
  ),
  successLeadHolderUpdate: (
    <TranslateMessage msgKey={'Messages.vsmDealDesk_successLeadHolderUpdate'} />
  ),
  successNoteConvertedToTicket: (
    <TranslateMessage msgKey={'Messages.vsmDealDesk_successNoteConvertedToTicket'} />
  ),
  Notes: {
    saveSuccess: 'Notes saved successfully.',
    descError: 'Notes can not be empty.',
    sentSuccess: 'Notes Sent successfully.',
    errorNoteSelection: 'Please select Note.',
    successDelete: 'Note removed successfully.',
    errorTagUpdate: 'Invalid request for this Lead',
    successTagUpdate: 'Note tag updated successfully.',
    successColorChange: 'Note color changed successfully.',
  },
  structure: {
    saveSuccess: 'Structure saved successfully.',
    updateSuccess: 'Structure updated successfully.',
    sentSuccess: 'Structure sent successfully.',
    validation: {
      structure_title: [
        {
          required: true,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_structure_title_required'}
            />
          ),
        },
        {
          max: 100,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_structure_title_max'}
            />
          ),
        },
      ],
      screen_title: [
        {
          required: true,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_screen_title_required'}
            />
          ),
        },
        {
          max: 100,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_screen_title_max'}
            />
          ),
        },
      ],
      scrutinise_title: [
        {
          required: true,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_scrutinise_title_required'}
            />
          ),
        },
        {
          max: 100,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_scrutinise_title_max'}
            />
          ),
        },
      ],
      shortfall_title: [
        {
          required: true,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_shortfall_title_required'}
            />
          ),
        },
        {
          max: 100,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_shortfall_title_max'}
            />
          ),
        },
      ],
    },
  },
  screen: {
    saveSuccess: 'Screen saved successfully.',
    updateSuccess: 'Screen updated successfully.',
    sentSuccess: 'Screen sent successfully.',
    validation: {
      screen_title: [
        {
          required: true,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_screen_title_required'}
            />
          ),
        },
      ],
    },
  },
  shortfall: {
    saveSuccess: 'Shortfall saved successfully.',
    updateSuccess: 'Shortfall updated successfully.',
    sentSuccess: 'Shortfall sent successfully.',
    validation: {
      shortfall_title: [
        {
          required: true,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_shortfall_title_required'}
            />
          ),
        },
      ],
    },
  },
  scrutinise: {
    saveSuccess: 'Scrutinise saved successfully.',
    updateSuccess: 'Scrutinise updated successfully.',
    sentSuccess: 'Scrutinise sent successfully.',
    validation: {
      scrutinise_title: [
        {
          required: true,
          message: (
            <TranslateMessage
              msgKey={'Messages.vsmDealDesk_validation_scrutinise_title_required'}
            />
          ),
        },
      ],
    },
  },
  schedule: {
    saveSuccess: 'Schedule saved successfully.',
    updateSuccess: 'Schedule updated successfully.',
    sentSuccess: 'Schedule sent successfully.',
    validation: {
      title: [
        {
          required: true,
          message: (
            <TranslateMessage msgKey={'Messages.vsmDealDesk_validation_title_required'} />
          ),
        },
      ],
    },
  },
  score: {
    saveSuccess: 'Score saved successfully.',
    updateSuccess: 'Score updated successfully.',
    sentSuccess: 'Score sent successfully.',
    validation: {
      title: [
        {
          required: true,
          message: (
            <TranslateMessage msgKey={'Messages.vsmDealDesk_validation_title_required'} />
          ),
        },
      ],
    },
  },
  deal: {
    firststep: {
      successDealdeatail: 'Details saved successfully.',
      issuedDealdeatail: 'Details issued successfully.',
      validation: {
        rpa_group: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_rpa_group_required'}
              />
            ),
          },
        ],
        'val_CSV Column': [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_CSV_required'}
              />
            ),
          },
          {
            pattern: /^[A-Za-z0-9-. ]*$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_CSV_pattern'}
              />
            ),
          },
        ],
        val_Date: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Date_required'}
              />
            ),
          },
          {
            pattern: /^[+-]?[0-9]+$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Date_pattern'}
              />
            ),
          },
        ],
        val_Number: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Number_required'}
              />
            ),
          },
          {
            pattern: /^[0-9]+([.][0-9]+)?$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Number_pattern'}
              />
            ),
          },
        ],
        val_Charges: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Charges_required'}
              />
            ),
          },
          {
            pattern: /^[0-9]+([.][0-9]+)?$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Charges_pattern'}
              />
            ),
          },
        ],
        val_Flag: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Flag_required'}
              />
            ),
          },
          {
            pattern: /^[01]$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Flag_pattern'}
              />
            ),
          },
        ],
        val_Text: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Text_required'}
              />
            ),
          },
          {
            pattern: /^[A-Za-z0-9-. ]*$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_val_Text_pattern'}
              />
            ),
          },
        ],
        debtor_currency_id_withrequired: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_debtor_currency_id_withrequired_required'
                }
              />
            ),
          },
        ],
        debtor_currency_id_withoutrequired: [
          {
            required: false,
          },
        ],
        creditor_currency_id_withrequired: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_creditor_currency_id_withrequired_required'
                }
              />
            ),
          },
        ],
        creditor_currency_id_withoutrequired: [
          {
            required: false,
          },
        ],

        asset_currency_id_withrequired: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_asset_currency_id_withrequired_required'
                }
              />
            ),
          },
        ],
        asset_currency_id_withoutrequired: [
          {
            required: false,
          },
        ],

        debtor_amount_withrequired: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_debtor_amount_withrequired_required'
                }
              />
            ),
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_debtor_amount_withrequired_pattern'
                }
              />
            ),
          },
        ],
        debtor_amount_withoutrequired: [
          {
            required: false,
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_debtor_amount_withoutrequired_pattern'
                }
              />
            ),
          },
        ],
        creditor_amount_withrequired: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_creditor_amount_withrequired_required'
                }
              />
            ),
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_creditor_amount_withrequired_pattern'
                }
              />
            ),
          },
        ],
        creditor_amount_withoutrequired: [
          {
            required: false,
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_creditor_amount_withoutrequired_pattern'
                }
              />
            ),
          },
        ],
        repayment_amount: [
          {
            required: false,
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_repayment_amount_pattern'}
              />
            ),
          },
        ],
        asset_amount_withrequired: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_asset_amount_withrequired_required'
                }
              />
            ),
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_asset_amount_withrequired_pattern'
                }
              />
            ),
          },
        ],
        asset_amount_withoutrequired: [
          {
            required: false,
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_asset_amount_withoutrequired_pattern'
                }
              />
            ),
          },
        ],
        non_traded_service_amount: [
          {
            required: false,
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_asset_amount_withoutrequired_pattern'
                }
              />
            ),
          },
        ],
        variable_id: [
          {
            required: false,
            message: '',
          },
        ],
        record_type_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_record_type_id_required'}
              />
            ),
          },
        ],
        probability: [
          {
            required: false,
            message: '',
          },
        ],
        monthly_amount: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_monthly_amount_required'}
              />
            ),
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_monthly_amount_pattern'}
              />
            ),
          },
        ],
        purchase_percent: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_purchase_percent_required'}
              />
            ),
          },
          {
            pattern: /^(([0-9]+\.[0-9]{1,2})|[0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_purchase_percent_pattern'}
              />
            ),
          },
        ],
        sell_rate_percent: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_sell_rate_percent_required'}
              />
            ),
          },
          {
            pattern: /^(([0-9]+\.[0-9]{1,2})|[0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_sell_rate_percent_pattern'}
              />
            ),
          },
        ],
        arrangement_amount: [
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_arrangement_amount_pattern'}
              />
            ),
          },
        ],
        repayment_currency_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_repayment_currency_id_required'}
              />
            ),
          },
        ],
        usage_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_usage_id_required'}
              />
            ),
          },
        ],
        usage_rate_percent: [
          {
            pattern: /^(([0-9]+\.[0-9]{1,2})|[0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_usage_rate_percent_pattern'}
              />
            ),
          },
        ],
        repaid_from_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_repaid_from_id_required'}
              />
            ),
          },
        ],
        over: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_over_required'}
              />
            ),
          },
        ],
        non_traded_service_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_non_traded_service_id_required'}
              />
            ),
          },
        ],
        specific_condition_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_specific_condition_id_required'}
              />
            ),
          },
        ],
        note: [{}],
      },
    },
    secondstep: {
      successDealdeatail: 'Deal Details saved successfully.',
      validation: {
        turnover_amount: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_turnover_amount_required'}
              />
            ),
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_turnover_amount_pattern'}
              />
            ),
          },
        ],

        grade: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_grade_required'}
              />
            ),
          },
        ],
        gross_margin_percent: [
          {
            pattern: /^(([0-9]+\.[0-9]{1,2})|[0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_gross_margin_percent_pattern'}
              />
            ),
          },
        ],
        net_margin_percent: [
          {
            pattern: /^(([0-9]+\.[0-9]{1,2})|[0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_net_margin_percent_pattern'}
              />
            ),
          },
        ],
        class: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_class_required'}
              />
            ),
          },
        ],
        bank_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_bank_id_required'}
              />
            ),
          },
        ],
        lone_type_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_lone_type_id_required'}
              />
            ),
          },
        ],
        loan_amount_currency_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={
                  'Messages.vsmDealDesk_validation_loan_amount_currency_id_required'
                }
              />
            ),
          },
        ],
        loan_amount: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_loan_amount_required'}
              />
            ),
          },
          {
            pattern: /^[+-]?([,0-9]+([.][0-9]*)?|[.][0-9]+)$/,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_loan_amount_pattern'}
              />
            ),
          },
        ],
        account_system_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_account_system_id_required'}
              />
            ),
          },
        ],
        pricing_method_id: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_pricing_method_id_required'}
              />
            ),
          },
        ],
        last_increase_date: [
          {
            required: true,
            message: (
              <TranslateMessage
                msgKey={'Messages.vsmDealDesk_validation_last_increase_date_required'}
              />
            ),
          },
        ],
      },
    },
  },
  KeyNoteSave: {
    saveSuccess: 'Key Notes Saved successfully.',
    saveError: 'Note Subject can not be blank.',
  },
  noteKnowledgeSave: {
    saveSuccess: <TranslateMessage msgKey={'Messages.vsmKnowledge_add'} />,
    saveError: 'Knowledge can not be blank.',
  },
};

const vsmAlgorithm = {
  add: <TranslateMessage msgKey={'Messages.vsmAlgorithm_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmAlgorithm_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmAlgorithm_delete'} />,
  publish: <TranslateMessage msgKey={'Messages.vsmAlgorithm_publish'} />,
  unpublish: <TranslateMessage msgKey={'Messages.vsmAlgorithm_unpublish'} />,
  InvalidRecord: <TranslateMessage msgKey={'Messages.vsmAlgorithm_InvalidRecord'} />,
  validation: {
    algorithm_name: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmAlgorithm_validation_algorithm_name_required'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]*$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmAlgorithm_validation_algorithm_name_pattern'}
          />
        ),
      },
    ],
    algorithm_desc: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmAlgorithm_validation_algorithm_desc_required'}
          />
        ),
      },
      {
        min: 10,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmAlgorithm_validation_algorithm_desc_min'}
          />
        ),
      },
    ],
  },
};

const vsmTransactionFields = {
  add: <TranslateMessage msgKey={'Messages.vsmTransactionFields_add'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmTransactionFields_delete'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmTransactionFields_edit'} />,
  unpublish: <TranslateMessage msgKey={'Messages.vsmTransactionFields_unpublish'} />,
  publish: <TranslateMessage msgKey={'Messages.vsmTransactionFields_publish'} />,
  validation: {
    rpa_group: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionFields_validation_rpa_group_required'}
          />
        ),
      },
    ],
    field_name: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionFields_validation_field_name_required'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionFields_validation_field_name_pattern'}
          />
        ),
      },
    ],
    field_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionFields_validation_field_type_required'}
          />
        ),
      },
    ],
    algorithm_id: [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (getFieldValue('field_type')) {
            if (['CSV Column', 'Date'].includes(getFieldValue('field_type'))) {
              return Promise.resolve();
            } else {
              let rpg_values = getFieldValue('rpg_values');
              let has_error = false;
              if (rpg_values) {
                rpg_values.forEach((x) => {
                  if (!x.default_value) {
                    has_error = true;
                  }
                });
              }

              if (has_error) {
                if (!getFieldValue('algorithm_id')) {
                  return Promise.reject(
                    <TranslateMessage
                      msgKey={
                        'Messages.vsmTransactionFields_validation_algorithm_id_validator'
                      }
                    />
                  );
                }
              }
            }
          }
          return Promise.resolve();
        },
      }),
    ],
    default_value: [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (getFieldValue('field_type') && !getFieldValue('algorithm_id')) {
            if (['Date'].includes(getFieldValue('field_type'))) {
              let invInput = /^[+-]?[0-9]+$/;
              if (!value || (value && !invInput.test(value))) {
                return Promise.reject(
                  <TranslateMessage
                    msgKey={
                      'Messages.vsmTransactionFields_validation_default_value_validator_1'
                    }
                  />
                );
              }
            }
            if (['CSV Column'].includes(getFieldValue('field_type'))) {
              let invInput = /^[A-Za-z0-9-. ]*$/;
              if (!value || (value && !invInput.test(value))) {
                return Promise.reject(
                  <TranslateMessage
                    msgKey={
                      'Messages.vsmTransactionFields_validation_default_value_validator_2'
                    }
                  />
                );
              }
            }
            if (['Number', 'Charges'].includes(getFieldValue('field_type'))) {
              let invInput = /^[0-9]+([.][0-9]+)?$/;
              if (!value || (value && !invInput.test(value))) {
                return Promise.reject(
                  <TranslateMessage
                    msgKey={
                      'Messages.vsmTransactionFields_validation_default_value_validator_3'
                    }
                  />
                );
              }
            }
            if (['Flag'].includes(getFieldValue('field_type'))) {
              let invInput = /^[01]$/;
              if (!value || (value && !invInput.test(value))) {
                return Promise.reject(
                  <TranslateMessage
                    msgKey={
                      'Messages.vsmTransactionFields_validation_default_value_validator_4'
                    }
                  />
                );
              }
            }
            if (['Text'].includes(getFieldValue('field_type'))) {
              let invInput = /^[A-Za-z0-9-. ]*$/;
              if (!value || (value && !invInput.test(value))) {
                return Promise.reject(
                  <TranslateMessage
                    msgKey={
                      'Messages.vsmTransactionFields_validation_default_value_validator_5'
                    }
                  />
                );
              }
            }
          }

          return Promise.resolve();
        },
      }),
    ],
    description: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionFields_validation_description_required'}
          />
        ),
      },
    ],
  },
};

const vsmTransactionReport = {
  save: <TranslateMessage msgKey={'Messages.vsmTransactionReport_save'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmTransactionReport_delete'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmTransactionReport_edit'} />,
  validation: {
    report_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_report_type_required'}
          />
        ),
      },
    ],
    include_related_item_types: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmTransactionReport_validation_include_related_item_types_required'
            }
          />
        ),
      },
    ],
    name: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_name_required'}
          />
        ),
      },
      {
        min: 3,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_name_min'}
          />
        ),
      },
      {
        pattern: /^[^\t\n]*$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_name_pattern'}
          />
        ),
      },
    ],
    code: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_code_required'}
          />
        ),
      },
      {
        pattern: /^([A-Za-z0-9-.\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_code_pattern'}
          />
        ),
      },
    ],
    description: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_description_required'}
          />
        ),
      },
      {
        min: 1,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_description_min'}
          />
        ),
      },
    ],
    type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_type_required'}
          />
        ),
      },
    ],
    contact_value: [
      {
        required: false,
        message: '',
      },
      {
        min: 5,
        message: '',
      },
      {
        pattern:
          /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,11},(\se|e)xt.(\s\d{2,4}|\d{2,4})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,11}$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_contact_value_pattern'}
          />
        ),
      },
    ],
    contact_for: [
      {
        required: false,
        message: '',
      },
      {
        min: 5,
        message: '',
      },
      {
        pattern:
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_contact_for_pattern'}
          />
        ),
      },
    ],
    lead_type: [
      {
        required: false,
        message: '',
      },
    ],
    record_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_record_type_required'}
          />
        ),
      },
    ],
    classification_id: [
      {
        required: false,
        message: '',
      },
    ],
    product_name: [
      {
        required: false,
        message: '',
      },
    ],
    group_filter_by: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_group_filter_by_required'}
          />
        ),
      },
    ],
    lead_status_list: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_lead_status_list_required'}
          />
        ),
      },
    ],
    group_by: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_group_by_required'}
          />
        ),
      },
    ],
    lead_status: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_lead_status_required'}
          />
        ),
      },
    ],
    journal_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_journal_type_required'}
          />
        ),
      },
    ],
    group_on: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_group_on_required'}
          />
        ),
      },
    ],
    currency: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_currency_required'}
          />
        ),
      },
    ],
    generate_on: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_generate_on_required'}
          />
        ),
      },
    ],
    date_range: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_date_range_required'}
          />
        ),
      },
    ],
    deadline_date: [
      {
        required: false,
        message: '',
      },
    ],
    date: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_date_required'}
          />
        ),
      },
    ],
    sold_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_date_x_value_required'}
          />
        ),
      },
    ],
    tag_status_id: [
      {
        required: false,
        message: '',
      },
    ],
    ticket_type: [
      {
        required: false,
        message: '',
      },
    ],
    reminder_type: [
      {
        required: false,
        message: '',
      },
    ],
    priority: [
      {
        required: false,
        message: '',
      },
    ],
    date_x_value: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_date_x_value_required'}
          />
        ),
      },
      {
        pattern: /^([1-9]|[1]+[0-2])*$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_date_x_value_pattern'}
          />
        ),
      },
    ],
    financial_year: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_financial_year_required'}
          />
        ),
      },
    ],
    period_date_range: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_period_date_range_required'}
          />
        ),
      },
    ],
    period_x_value: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_period_x_value_required'}
          />
        ),
      },
      {
        pattern: /^([1-9]|[1]+[0-2])*$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_period_x_value_pattern'}
          />
        ),
      },
    ],
    single_transaction: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmTransactionReport_validation_single_transaction_required'
            }
          />
        ),
      },
      {
        pattern: /^[0-9]*$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_single_transaction_pattern'}
          />
        ),
      },
    ],
    multiple_transaction: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmTransactionReport_validation_multiple_transaction_required'
            }
          />
        ),
      },
      ({ getFieldValue }) => ({
        validator(value, rule) {
          let pattern = /^[0-9]*$/;
          let data = getFieldValue('multiple_transaction');
          let check = data && data.find((item) => !pattern.test(item));
          if (check) {
            return Promise.reject(
              <TranslateMessage
                msgKey={
                  'Messages.vsmTransactionReport_validation_multiple_transaction_validator'
                }
              />
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    single_model: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_single_model_required'}
          />
        ),
      },
    ],
    multiple_model: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_multiple_model_required'}
          />
        ),
      },
    ],
    single_mapping: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_single_mapping_required'}
          />
        ),
      },
    ],
    single_transaction_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmTransactionReport_validation_single_transaction_type_required'
            }
          />
        ),
      },
    ],
    single_journal: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_single_journal_required'}
          />
        ),
      },
    ],
    multiple_journal: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_multiple_journal_required'}
          />
        ),
      },
    ],
    combined_reports: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_combined_reports_required'}
          />
        ),
      },
    ],
    transaction_field: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_transaction_field_required'}
          />
        ),
      },
    ],
    operator: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_operator_required'}
          />
        ),
      },
    ],
    value: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_value_required'}
          />
        ),
      },
    ],
    org_people_bank_iban: [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (value && !IBAN.isValid(value)) {
            return Promise.reject(
              <TranslateMessage
                msgKey={
                  'Messages.vsmTransactionReport_validation_org_people_bank_iban_validator'
                }
              />
            );
          } else {
            return Promise.resolve();
          }
        },
      }),
    ],
    cte_exchange_id: [
      {
        min: 3,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_cte_exchange_id_required'}
          />
        ),
      },
      {
        pattern: /^[\000-\377]+$/,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_cte_exchange_id_pattern'}
          />
        ),
      },
    ],
    cte_bank_ref: [
      {
        min: 3,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_cte_bank_ref_min'}
          />
        ),
      },
    ],
    cte_customer_ref: [
      {
        min: 3,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTransactionReport_validation_cte_customer_ref_min'}
          />
        ),
      },
    ],
    related_item_ticket_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmTransactionReport_validation_related_item_ticket_id_required'
            }
          />
        ),
      },
    ],
    related_item_leads_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmTransactionReport_validation_related_item_leads_id_required'
            }
          />
        ),
      },
    ],
    related_item_contacts_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmTransactionReport_validation_related_item_contacts_id_required'
            }
          />
        ),
      },
    ],
    related_item_organisations_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={
              'Messages.vsmTransactionReport_validation_related_item_organisations_id_required'
            }
          />
        ),
      },
    ],
  },
};

const vsmTicket = {
  add: <TranslateMessage msgKey={'Messages.vsmTicket_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmTicket_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmTicket_delete'} />,
  reassign: <TranslateMessage msgKey={'Messages.vsmTicket_reassign'} />,
  restore: <TranslateMessage msgKey={'Messages.vsmTicket_restore'} />,
  validation: {
    ticket_subject: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTicket_validation_ticket_subject_required'}
          />
        ),
      },
    ],
    organisation_id: [
      {
        required: true,
        message: '',
      },
    ],
    holder: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmTicket_validation_holder_required'} />
        ),
      },
    ],
    status: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmTicket_validation_status_required'} />
        ),
      },
    ],
    hours_required: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Please enter estimated hours'}
          />
        ),
      },
    ],
    deadline_date: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTicket_validation_deadline_date_required'}
          />
        ),
      },
    ],
    deadline_time: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTicket_validation_deadline_time_required'}
          />
        ),
      },
    ],
    ticket_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTicket_validation_ticket_type_required'}
          />
        ),
      },
    ],
    priority: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmTicket_validation_priority_required'} />
        ),
      },
    ],
    ticket_description: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTicket_validation_ticket_description_required'}
          />
        ),
      },
    ],
    parent_ticket_id: [
      {
        required: true,
        message: '',
      },
    ],
  },
};

const vsmTags = {
  add: <TranslateMessage msgKey={'Messages.vsmTags_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmTags_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmTags_delete'} />,
  reassign: <TranslateMessage msgKey={'Messages.vsmTags_reassign'} />,
  validation: {
    tag_subject: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmTags_validation_tag_subject_required'} />
        ),
      },
    ],
    organisation_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTags_validation_organisation_id_required'}
          />
        ),
      },
    ],
    tag_status_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTags_validation_tag_status_id_required'}
          />
        ),
      },
    ],
    deadline_date: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTags_validation_deadline_date_required'}
          />
        ),
      },
    ],
    tag_content: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmTags_validation_tag_content_required'} />
        ),
      },
    ],
    parent_ticket_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTags_validation_parent_ticket_id_required'}
          />
        ),
      },
    ],
    tag_response: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmTags_validation_tag_response_required'}
          />
        ),
      },
    ],
  },
};

const vsmRightSectTagsTicket = {
  add: <TranslateMessage msgKey={'Messages.vsmRightSectTagsTicket_add'} />,
  remove: <TranslateMessage msgKey={'Messages.vsmRightSectTagsTicket_remove'} />,
};

const vsmQuickNoteUpdate = {
  successQuickNoteUpdate: (
    <TranslateMessage msgKey={'Messages.vsmQuickNoteUpdate_successQuickNoteUpdate'} />
  ),
  successNoteUpdate: (
    <TranslateMessage msgKey={'Messages.vsmQuickNoteUpdate_successNoteUpdate'} />
  ),
};

const vsmWaste = {
  restore: <TranslateMessage msgKey={'Messages.vsmWaste_restore'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmWaste_delete'} />,
};

const vsmReminders = {
  add: <TranslateMessage msgKey={'Messages.vsmReminders_add'} />,
  edit: <TranslateMessage msgKey={'Messages.vsmReminders_edit'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmReminders_delete'} />,
  read: <TranslateMessage msgKey={'Messages.vsmReminders_read'} />,
  validation: {
    event_record_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_event_record_type_required'}
          />
        ),
      },
    ],
    reminder_type: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_reminder_type_required'}
          />
        ),
      },
    ],
    reminder_for: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_reminder_for_required'}
          />
        ),
      },
    ],
    priority: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_priority_required'}
          />
        ),
      },
    ],
    reminder_date: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_reminder_date_required'}
          />
        ),
      },
    ],
    subject: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_subject_required'}
          />
        ),
      },
    ],
    status: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmReminders_validation_status_required'} />
        ),
      },
    ],
    description: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_description_required'}
          />
        ),
      },
    ],
    full_description: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_full_description_required'}
          />
        ),
      },
    ],
    reminder_text: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_reminder_text_required'}
          />
        ),
      },
    ],
    receiver_id: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_receiver_id_required'}
          />
        ),
      },
    ],
    reminder_date_time: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmReminders_validation_reminder_date_time_required'}
          />
        ),
      },
    ],
    holder: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmReminders_validation_holder_required'} />
        ),
      },
    ],
  },
};

const vsmProductivity = {
  sucecssBulkAssign: (
    <TranslateMessage msgKey={'Messages.vsmProductivity_sucecssBulkAssign'} />
  ),
  sucecssBulkDelete: (
    <TranslateMessage msgKey={'Messages.vsmProductivity_sucecssBulkDelete'} />
  ),
  errorNoRowSelection: (
    <TranslateMessage msgKey={'Messages.vsmProductivity_errorNoRowSelection'} />
  ),
  successChangeStatus: (
    <TranslateMessage msgKey={'Messages.vsmProductivity_successChangeStatus'} />
  ),
  delete: <TranslateMessage msgKey={'Messages.vsmProductivity_delete'} />,
  restore: <TranslateMessage msgKey={'Messages.vsmProductivity_restore'} />,
};

const vsmSnapNote = {
  add: <TranslateMessage msgKey={'Messages.vsmSnapNote_add'} />,
  read: <TranslateMessage msgKey={'Messages.vsmSnapNote_read'} />,
  reminderadd: <TranslateMessage msgKey={'Messages.vsmSnapNote_reminderadd'} />,
  validation: {
    type: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmSnapNote_validation_type_required'} />
        ),
      },
    ],
    content: [
      {
        required: true,
        message: (
          <TranslateMessage msgKey={'Messages.vsmSnapNote_validation_content_required'} />
        ),
      },
    ],
    userSelect: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmSnapNote_validation_userSelect_required'}
          />
        ),
      },
    ],
  },
};

const vsmGridFilter = {
  add: <TranslateMessage msgKey={'Messages.vsmGridFilter_add'} />,
  apply: <TranslateMessage msgKey={'Messages.vsmGridFilter_apply'} />,
  validation: {
    filtername: [
      {
        required: true,
        message: (
          <TranslateMessage
            msgKey={'Messages.vsmGridFilter_validation_filtername_required'}
          />
        ),
      },
    ],
  },
};

const vsmCallAudit = {
  added: <TranslateMessage msgKey={'Messages.vsmCallAudit_added'} />,
  emailadded: <TranslateMessage msgKey={'Messages.vsmCallAudit_Email_added'} />,
};

const vsmSavedFilter = {
  add: <TranslateMessage msgKey={'Messages.vsmSavedFilter_add'} />,
  update: <TranslateMessage msgKey={'Messages.vsmSavedFilter_update'} />,
  delete: <TranslateMessage msgKey={'Messages.vsmSavedFilter_delete'} />,
};

export {
  vsmAuth,
  vsmNotify,
  vsmCommon,
  vsmUsergroup,
  vsmContracts,
  vsmKnowledge,
  vsmTickertap,
  vsmUsers,
  vsmClassification,
  vsmLeadstatus,
  vsmSettings,
  vsmPeople,
  vsmOrganisations,
  vsmRoleMapping,
  vsmPeopleRoleMapping,
  vsmPeopleRoles,
  vsmRoles,
  vsmLeadsList,
  vsmDealDesk,
  vsmAlgorithm,
  vsmTransactionFields,
  vsmTicket,
  vsmTransactionReport,
  vsmTags,
  vsmRightSectTagsTicket,
  vsmWaste,
  vsmQuickNoteUpdate,
  vsmProductivity,
  vsmReminders,
  vsmSnapNote,
  vsmGridFilter,
  vsmCallAudit,
  vsmSavedFilter,
};
