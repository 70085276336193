import React from 'react';
import StructureHeader from './StructureHeader';
import StructureContent from './StructureContent';
import { arrayMoveImmutable } from 'array-move';
import debounce from 'lodash/debounce';
import { Button, Tooltip } from 'antd';
import { isElementOutViewport } from '../../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const StructureComponent = ({
  Structure = [],
  MainStructure = [],
  SetStructure,
  forceUpdate,
  editable,
  openViewDrawer,
  handleFormChange,
  saveType,
  autoSaveData = () => { },
  INITIAL_HEIGHT,
  isFromLibrary = false,
}) => {
  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }
  const ADD = require(`../../../../../assets/images/${themecolor}/add.svg`).default;

  // Variables -------------------

  // Functions -------------------
  const { t } = useTranslation();
  const addNodeByIndex = () => {
    let lasFocused = localStorage.getItem(`last_focusedElement${saveType}`);
    let item_key = lasFocused;
    if (item_key) {
      let finalItemKey;
      let splitArr = item_key.split('-');

      if (splitArr.length === 1) {
        finalItemKey = 0;
      } else {
        if (splitArr.length > 0) {
          splitArr.splice(splitArr.length - 1, 1);
        }
        if (splitArr.length === 1) {
          finalItemKey = Number(splitArr[0]);
        } else {
          finalItemKey = splitArr.join('-');
        }
      }
      let scrollID = finalItemKey === 0 ? 0 : `${saveType}_innerContent_${finalItemKey}`;
      AddNode(finalItemKey);

      scrollToNode(scrollID);
      autoSaveData();
    }
  };

  const scrollToNode = debounce((id) => {
    localStorage.removeItem(`last_focusedElement${saveType}`);
    let newElementID = 0;
    let selectedElement = null;
    if (id) {
      let schedule_content = document.getElementById(id);
      selectedElement = schedule_content.querySelector('.fields_draggable_container');
      if (selectedElement && selectedElement.lastElementChild.id) {
        newElementID = selectedElement.lastElementChild.id;
      }
    } else {
      if (isFromLibrary) {
        selectedElement = document.querySelector(
          `#addfrom${saveType} .fields_draggable_container`
        );
        if (selectedElement === null) {
          selectedElement = document.querySelector(
            `#editfrom${saveType} .fields_draggable_container`
          );
        }
      } else {
        if (saveType === 'structure') {
          selectedElement = document.querySelector(
            '#StructureForm .fields_draggable_container'
          );
        }
        if (saveType === 'screen') {
          selectedElement = document.querySelector(
            '#ScreenForm .fields_draggable_container'
          );
        }
        if (saveType === 'shortfall') {
          selectedElement = document.querySelector(
            '#ShortfallForm .fields_draggable_container'
          );
        }
        if (saveType === 'scrutinise') {
          selectedElement = document.querySelector(
            '#ScrutiniseForm .fields_draggable_container'
          );
        }
      }

      if (selectedElement) {
        newElementID = selectedElement.lastElementChild?.id;
      }
    }

    if (newElementID && document.getElementById(newElementID)) {
      if (document.getElementById(newElementID).classList) {
        document.getElementById(newElementID).classList.add('blink-BGanimation');
        document.querySelector('#' + newElementID + ' .textAreaSection textarea').focus();
        let splitArr = newElementID.split(`${saveType}_innerContent_`);
        if (splitArr) {
          let focusedID = splitArr
            ? newElementID.split(`${saveType}_innerContent_`)[1]
            : '';
          if (focusedID) {
            localStorage.setItem(`last_focusedElement${saveType}`, focusedID);
          }
        }
      }
      if (!isElementOutViewport(document.getElementById(newElementID))) {
        document.getElementById(newElementID).scrollIntoView();
      }
      setTimeout(() => {
        if (
          document.getElementById(newElementID) &&
          document.getElementById(newElementID).classList
        ) {
          document.getElementById(newElementID).classList.remove('blink-BGanimation');
        }
      }, 1000);
    }
  }, 500);

  const AddNode = (ItemKey) => {
    let finalData = MainStructure;
    let position = 1;
    let detail = '';
    let item_key = null;
    let height = INITIAL_HEIGHT;

    const add = (NodeArray, ItemKey) => {
      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          if (!x.children) {
            x.children = [];
          } else {
            if (x.children.length > 0) {
              position = parseInt(x.children[x.children.length - 1].position) + 1;
            } else {
              position = 1;
            }
          }
          item_key = [x.item_key, x.children.length].join('-');
          detail = '';
          x.children.push({ item_key, position, detail, height });
        } else {
          if (x.children && x.children.length > 0) {
            add(x.children, ItemKey);
          }
        }
      });
    };
    if (ItemKey) {
      add(finalData, ItemKey, [ItemKey]);
    } else {
      if (finalData.length > 0) {
        position = parseInt(finalData[finalData.length - 1].position) + 1;
        item_key = finalData.length + 1;
      } else {
        position = 1;
        item_key = 1;
      }
      detail = '';
      finalData.push({ item_key, position, detail, height });
    }

    SetStructure(finalData);
    handleFormChange();
    forceUpdate();
  };

  const RemoveNode = (ItemKey, startPosition = 1) => {
    let finalData = MainStructure;
    const remove = (NodeArray, ItemKey, IsChild) => {
      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          if (IsChild) {
            NodeArray.splice(index, 1);
          } else {
            NodeArray.splice(index, 1);
          }
        } else {
          if (x.children && x.children.length > 0) {
            remove(x.children, ItemKey, true);
          }
        }
      });
    };
    remove(finalData, ItemKey);

    finalData &&
      finalData.forEach((obj, index) => {
        obj.item_key = index + 1;
        obj.position = obj.item_key;

        if (obj.children && obj.children.length > 0) {
          obj.children.forEach((obj1, index1) => {
            obj1.item_key = [obj.item_key, index1].join('-');
            obj1.position = index1 + 1;

            if (obj1.children && obj1.children.length > 0) {
              obj1.children.forEach((obj2, index2) => {
                obj2.item_key = [obj1.item_key, index2].join('-');
                obj2.position = index2 + 1;
              });
            }
          });
        }
      });

    SetStructure(finalData);
    handleFormChange();
    forceUpdate();
  };

  const ChangeNode = (ItemKey, Value, key) => {
    let finalData = MainStructure;
    const change = (NodeArray, ItemKey) => {
      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          x[key] = Value;
        } else {
          if (x.children && x.children.length > 0) {
            change(x.children, ItemKey);
          }
        }
      });
    };
    change(finalData, ItemKey);
    SetStructure(finalData);
    handleFormChange();
    // forceUpdate();
  };

  const ExchangeNode = (ItemKey, { oldIndex, newIndex }) => {
    let finalData = MainStructure;

    const loop = (NodeArray, ItemKey, Parent) => {
      let initialArr = NodeArray;
      let starting_position = 1;
      if (initialArr) {
        starting_position = parseInt(initialArr[0].position);
      }

      NodeArray.forEach((x, index) => {
        if (x.item_key === ItemKey) {
          if (Parent) {
            Parent.children = arrayMoveImmutable(NodeArray, oldIndex, newIndex);
            Parent.children.forEach((child, child_index) => {
              let sequenceItemKey = child.item_key.split('-');
              if (sequenceItemKey.length === 2) {
                child.item_key = sequenceItemKey[0] + '-' + child_index;
              }
              if (sequenceItemKey.length === 3) {
                child.item_key =
                  sequenceItemKey[0] + '-' + sequenceItemKey[1] + '-' + child_index;
              }
              if (child_index === 0) {
                if (starting_position) {
                  child.position = starting_position;
                }
              } else {
                child.position = parseInt(Parent.children[child_index - 1].position) + 1;
              }
            });
          } else {
            finalData = arrayMoveImmutable(NodeArray, oldIndex, newIndex);
            finalData.forEach((element, index) => {
              element.item_key = index + 1;
              if (index === 0) {
                if (starting_position) {
                  element.position = starting_position;
                }
              } else {
                element.position = parseInt(finalData[index - 1].position) + 1;
              }

              element.children &&
                element.children.length > 0 &&
                element.children.forEach((child, child_index) => {
                  let sequenceItemKey = child.item_key.split('-');
                  if (sequenceItemKey.length === 2) {
                    child.item_key = element.item_key + '-' + child_index;
                  }
                  if (sequenceItemKey.length === 3) {
                    child.item_key =
                      element.item_key + '-' + sequenceItemKey[1] + '-' + child_index;
                  }
                  // child.position = child_index + 1;

                  child.children &&
                    child.children.length > 0 &&
                    child.children.forEach((x, i) => {
                      x.item_key = element.item_key + '-' + child_index + '-' + i;
                      // x.position = i + 1;
                    });
                });
            });
          }
        } else {
          if (x.children && x.children.length > 0) {
            loop(x.children, ItemKey, x);
          }
        }
      });
    };
    loop(finalData, ItemKey);

    // -------------------UPDATE POSTION----------------//
    let starting_position_new = 1;
    finalData &&
      finalData.forEach((obj, index) => {
        if (index === 0) {
          obj.position = starting_position_new;
        } else {
          obj.position = starting_position_new + index;
        }

        if (obj.children && obj.children.length > 0) {
          obj.children.forEach((obj1, index1) => {
            obj1.position = index1 + 1;
            if (obj1.children && obj1.children.length > 0) {
              obj1.children.forEach((obj2, index2) => {
                obj2.position = index2 + 1;
              });
            }
          });
        }
      });
    // -------------------UPDATE POSTION----------------//

    SetStructure(finalData);
    forceUpdate();
    autoSaveData(finalData);
  };

  // Markup ----------------------
  return (
    <div>
      <StructureHeader
        AddNode={AddNode}
        MainStructure={MainStructure}
        editable={editable}
        openViewDrawer={openViewDrawer}
        scrollToNode={scrollToNode}
        autoSaveData={autoSaveData}
        saveType={saveType}
      />
      <div className='list' id={`${saveType}_list`}>
        <StructureContent
          AddNode={AddNode}
          Structure={MainStructure}
          RemoveNode={RemoveNode}
          ChangeNode={ChangeNode}
          isFromLibrary={isFromLibrary}
          SetStructure={SetStructure}
          ExchangeNode={ExchangeNode}
          editable={editable}
          scrollToNode={scrollToNode}
          saveType={saveType}
          autoSaveData={autoSaveData}
          INITIAL_HEIGHT={INITIAL_HEIGHT}
        />
      </div>

      {document.getElementById(`${saveType}_list`)?.scrollHeight >
        document.getElementById(`${saveType}_list`)?.offsetHeight ? (
        <Tooltip title={`${t('DealDesk.Sibling_Item')}`}>
          <Button
            className={`float-button-addNode ${isFromLibrary ? 'libraryBtn' : ''}`}
            key='2'
            shape='round'
            size='medium'
            onClick={() => addNodeByIndex()}
            icon={<img className='menuicon' alt='' src={ADD} />}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};

export default StructureComponent;
