import React from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import { Col, Row } from 'antd';
import { vsmTransactionReport } from '../../../../config/messages';

const DateSelectionElement = observer(
  ({ formDataList, onChange, disabledDate, SetDateRange }) => (
    <Row gutter={24}>
      <Col>
        <InputComponent
          label='Date Selection'
          onChange={onChange}
          type='radio_button'
          name='date_selection'
          options={{
            values: [
              { value: 'range', text: 'Range' },
              { value: 'single', text: 'Single' },
              { value: 'auto', text: 'Auto' },
            ],
          }}
          tooltip='A date selection provide various options to choose date and accordingly generate report.'
        />
      </Col>
      <Col>
        {formDataList.includes('date_range') && (
          <InputComponent
            label='Date Range'
            type='date_range'
            name='date_range'
            onChange={onChange}
            rules={vsmTransactionReport.validation.date_range}
            tooltip='Used to fetch the reports between specific date range.'
            disabledDate={disabledDate}
            onCalendarChange={(val) => SetDateRange(val)}
          />
        )}
        {formDataList.includes('date') && (
          <InputComponent
            label='Date'
            type='date'
            name='date'
            onChange={onChange}
            rules={vsmTransactionReport.validation.date}
            tooltip='Select specific date to fetch reports.'
          />
        )}
        {(formDataList.includes('date_x_value') ||
          formDataList.includes('date_x_value_type')) && (
          <Row gutter={24}>
            {formDataList.includes('date_x_value') && (
              <Col span={4}>
                <InputComponent
                  label='Last'
                  required
                  type='number'
                  name='date_x_value'
                  min={1}
                  max={12}
                  className='w-100'
                  onChange={onChange}
                  rules={vsmTransactionReport.validation.date_x_value}
                  tooltip='A date selection provide various options to choose date and accordingly generate report.'
                />
              </Col>
            )}
            {formDataList.includes('date_x_value_type') && (
              <Col span={20}>
                <InputComponent
                  label='&nbsp;'
                  onChange={onChange}
                  type='radio_button'
                  name='date_x_value_type'
                  options={{
                    className: 'd-flex w-100',
                    values: [
                      { value: 'day', text: 'Day/s' },
                      { value: 'week', text: 'Week/s' },
                      { value: 'month', text: 'Month/s' },
                      { value: 'quarter', text: 'Quater/s' },
                      { value: 'year', text: 'Year/s' },
                    ],
                  }}
                />
              </Col>
            )}
          </Row>
        )}
      </Col>
    </Row>
  )
);

export default DateSelectionElement;
