import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import useStore from '../../../../store';
import { Form, Spin, Button } from 'antd';
import EnterFullscreen from '../../../../component/EnterFullscreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const TopSelection = observer((props) => {
  //--------------------- Constants --------------------------//
  const { t } = useTranslation();
  const { openAddDrawer } = props;
  const [fetchLeadstatusList, setFetchLeadstatusList] = useState(true);

  const { AUTH, LEADSTATUS } = useStore();

  const [form] = Form.useForm();

  useEffect(() => {
    if (LEADSTATUS.extraFilter) {
      form.setFieldsValue({
        lead_record_type: LEADSTATUS.extraFilter,
      });
    }
  }, [LEADSTATUS.extraFilter, form]);

  //------------------------------------------------------------//

  //--------------------- Subscriptions --------------------------//

  const onChange = (e) => {
    LEADSTATUS.extraFilter = e;

    LEADSTATUS.getList();
  };

  //------------------------------------------------------------//

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        className='innerForm'
        // onFinish={handleSubmit}
        id='topSelectionLeadStatus'
      >
        <div className='topSelection soldStatus'>
          <InputComponent
            // className="topSelection-maxwidth"
            name='lead_record_type'
            placeholder={`${t('Common_Data.Sold')} ${t('Common_Data.Type')}`}
            type='select'
            onChange={(e) => onChange(e)}
            allowClear
            options={{
              values: LEADSTATUS.recordTypeList,
              value_key: 'record_type_name',
              text_key: 'record_type_name',
            }}
            onFocus={() =>
              fetchLeadstatusList &&
              LEADSTATUS.getRecordType().then(() => setFetchLeadstatusList(false))
            }
            notFoundContent={
              fetchLeadstatusList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
            }
          />

          {AUTH.checkPermission(30, 'add') && (
            <Button key='1' shape='round' onClick={openAddDrawer}>
              {t('Common_Data.Add')} {t('Common_Data.Sold')} {t('Common_Data.Status')}
            </Button>
          )}
          <Button
            key='2'
            shape='round'
            size='medium'
            title={`${t('Common_Data.Reset')}`}
            onClick={() => {
              form.resetFields();
              LEADSTATUS.extraFilter = null;
              LEADSTATUS.getList();
            }}
          >
            <FontAwesomeIcon icon={faUndo} />
          </Button>
          <EnterFullscreen key='3' />
        </div>
      </Form>
    </>
  );
});
export default TopSelection;
