import { observer } from 'mobx-react';
import React, { useEffect, useCallback } from 'react';
import RelatedOrganisationList from '../../../page/PeopleManagement/People/elements/ListElement/RelatedOrganization';
import RelatedPeopleList from '../../../page/PeopleManagement/People/elements/ListElement/RelatedPeople';
import useStore from '../../../store';
import { vsmNotify } from '../../../config/messages';

const DisplayRelatedConnection = observer(({ ...rest }) => {
  const {
    PEOPLE,
    ORGANISATION,
    CONNECTIONS,
    DEALDESKSTORE: { deal_data },
  } = useStore();
  const fetchMappingData = useCallback(() => {
    if (rest.currentTab === 'people') {
      PEOPLE.getViewPplDetail(rest.id, deal_data?.lead_information?.id);
    } else {
      ORGANISATION.getViewOrgDetail(rest.id, deal_data?.lead_information?.id).then(
        (data) => {
          ORGANISATION.viewOrgValues = data;
          PEOPLE.list_related_ORG = data.related_organisations;
          PEOPLE.list_related_Ppl = data.related_peoples;
          PEOPLE.list_trade_transaction = data.trade_transactions;
          PEOPLE.list_cash_transaction = data.cash_transactions;
          PEOPLE.list_banks = data.banks;
        }
      );
    }
  }, [rest.id, rest.currentTab, PEOPLE, ORGANISATION, deal_data]);
  useEffect(() => {
    fetchMappingData();
  }, [fetchMappingData]);

  const updateConnection = (data) => {
    let payload = {
      ledgers_role_mapping_id: data.id,
    };
    CONNECTIONS.updateConnection(
      deal_data.lead_information && deal_data.lead_information.id,
      payload
    ).then((data) => {
      fetchMappingData();
      if (data?.data?.message) {
        vsmNotify.success({
          message: data?.data?.message,
        });
      }
      if (deal_data?.lead_information?.id) {
        CONNECTIONS.fetchConnections(deal_data?.lead_information?.id);
      }
    });
  };

  return (
    <>
      <RelatedOrganisationList
        {...rest}
        updateConnection={updateConnection}
        fetchMappingData={fetchMappingData}
      />
      <RelatedPeopleList
        {...rest}
        updateConnection={updateConnection}
        fetchMappingData={fetchMappingData}
      />
    </>
  );
});

export default DisplayRelatedConnection;
