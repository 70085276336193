import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Form, Button, Modal, Row, Col, Upload, message, Radio } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import useStore from '../../../store';
//import Unauthorized from '../../../Unauthorized';
import ImportLeadSample from '../../../assets/importLeadsSample.csv';
import ImportLeadsNoteSample from '../../../assets/importLeadsNoteSample.csv';
import { vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';
const { Dragger } = Upload;

const LeadImportComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const { LEADSSTORE } = useStore();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [roleSuccessModal, setRoleSuccessModal] = useState(false);
  const [successCount, setSuccessCount] = useState();
  const [errorFileURL, setErrorFileURL] = useState();
  const [importType, setImportType] = useState(1);

  // reset form and close import form
  const close = (close = false) => {
    if (!saving || close) {
      setImportType(1);
      props.close();
      form.resetFields();
      setFileList([]);
      setDisabled(true);
      setSuccessCount();
      setErrorFileURL();
      setSaving(false);
    }
  };

  const handleLeadSubmit = () => {
    setSaving(true);
    const formData = new FormData();
    formData.append('file1', fileList[0]);

    LEADSSTORE.ImportLeadsFile(formData)
      .then((res) => {
        close(true);
        if (res.success_count !== 0) {
          vsmNotify.success({
            message: `${t('LeadListing.Lead_Records_Imported')}!`
          });
        }
      })
      .catch((e) => {
        if (e.errors && e.errors.file && e.errors.file[0]) {
          message.error(e.errors.file[0]);
        }
        if (e.errors && e.errors[0] && e.errors[0].errors && e.errors[0].errors[0]) {
          message.error(e.errors[0].errors[0]);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleLeadNoteSubmit = () => {
    setSaving(true);
    const formData = new FormData();
    formData.append('file1', fileList[0]);

    LEADSSTORE.ImportLeadsNoteFile(formData)
      .then((res) => {
        close(true);
        if (res.success_count !== 0) {
          vsmNotify.success({
            message: `${t('LeadListing.Lead_Records_Imported')}!`
          });
        }
      })
      .catch((e) => {
        if (e.errors && e.errors.file && e.errors.file[0]) {
          message.error(e.errors.file[0]);
        }
        if (e.errors && e.errors[0] && e.errors[0].errors && e.errors[0].errors[0]) {
          message.error(e.errors[0].errors[0]);
        }
      })
      .finally(() => setSaving(false));
  };

  // call for file type and size
  const beforeUpload = (file) => {
    let isCSV =
      file.type === 'text/csv' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === '';
    let isLt2M = file.size / 1024 / 1024 < 2;
    if (!isCSV) {
      message.error(`${t('LeadListing.Upload_CSV_File')}!`);
      setDisabled(true);
      return true;
    } else if (!isLt2M) {
      message.error(`${t('LeadListing.File_Smaller')} 2${t('LeadListing.Mb')}`);
      setDisabled(true);
      return true;
    } else {
      setFileList([file]);
      return false;
    }
  };

  // handle file change to uploaded file
  const handleFileChange = (info) => {
    if (info.fileList) {
      if (
        (info.file.status && info.file.status === 'error') ||
        info.fileList.length <= 0
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  };

  return (
    <>
      <Modal
        title={
          importType === 1
            ? `${t('LeadListing.Import')} ${t('LeadListing.Leads')}`
            : importType === 2
              ? `${t('LeadListing.Import')} ${t('LeadListing.Leads')} ${t('LeadListing.Notes')}`
              : null
        }
        destroyOnClose={true}
        centered
        visible={props.visible}
        onCancel={() => !saving && close()}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form={
              importType === 1
                ? 'importleadform'
                : importType === 2
                  ? 'importroleform'
                  : null
            }
            disabled={disabled}
            loading={saving}
            htmlType='submit'
            type='primary'
          >
            {t('LeadListing.Import')}
          </Button>,
          <Button
            key='2'
            htmlType='button'
            disabled={saving}
            onClick={() => {
              close();
            }}
          >
            {t('Common_Data.Cancel')}
          </Button>
        ]}
        width={700}
      >
        <Radio.Group
          buttonStyle='solid'
          optionType='button'
          className='mb-20'
          value={importType}
          onChange={(e) => {
            setImportType(e.target.value);
          }}
        >
          <Radio.Button key='1' value={1}>
            {t('LeadListing.Leads')}
          </Radio.Button>
          <Radio.Button key='2' value={2}>
            {t('LeadListing.Notes')}
          </Radio.Button>
        </Radio.Group>

        {importType === 1 ? (
          <Form
            form={form}
            id='importleadform'
            onFinish={handleLeadSubmit}
            encType='multipart/form-data'
            acceptCharset='UTF-8'
          >
            <Row gutter={24}>
              <Col span={12}>
                <Dragger
                  accept='.csv'
                  height={300}
                  fileList={fileList}
                  onRemove={() => setFileList([])}
                  beforeUpload={beforeUpload}
                  onChange={handleFileChange}
                  name='file'
                  showUploadList={true}
                  multiple={false}
                  action={process.env.React_APP_API_URL + 'people/checkvalidfile/'}
                  headers={{
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                  }}
                >
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    {t('LeadListing.Drag_Your_File_To_Upload')}
                  </p>
                </Dragger>
              </Col>
              <Col span={12}>
                <h3>{t('LeadListing.Following_Criteria')}:</h3>
                <ul className='bullet'>
                  <li>{t('LeadListing.File_Format_Csv')}.</li>
                  <li>{t('LeadListing.Maximum')} 2 {t('LeadListing.Mb')} {t('LeadListing.File_Size_Allowed')}.</li>
                  <li>{t('LeadListing.Minimum')} 1 {t('LeadListing.Record_Maximum')} 10000 {t('LeadListing.Records_There')}.</li>
                  <li>{t('LeadListing.File_Should_Exact_Name')}.</li>
                  <li>{t('LeadListing.Sample_File')}.</li>
                  <li>
                   {t('LeadListing.Data_Required_All_Column')}.
                  </li>
                </ul>
                <div className='d-flex mt-10'>
                  <a
                    href={ImportLeadSample}
                    className='mr-5'
                    download
                    rel='noopener noreferrer'
                  >
                   {t('LeadListing.Download_Sample_File')}.
                  </a>
                </div>
              </Col>
            </Row>
          </Form>
        ) : importType === 2 ? (
          <Form
            form={form}
            id='importroleform'
            onFinish={handleLeadNoteSubmit}
            encType='multipart/form-data'
            acceptCharset='UTF-8'
          >
            <Row gutter={24}>
              <Col span={12}>
                <Dragger
                  accept='.csv'
                  height={300}
                  fileList={fileList}
                  onRemove={() => setFileList([])}
                  beforeUpload={beforeUpload}
                  onChange={handleFileChange}
                  name='file'
                  showUploadList={true}
                  multiple={false}
                  action={process.env.React_APP_API_URL + 'people/checkvalidfile/'}
                  headers={{
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                  }}
                >
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                  {t('LeadListing.Drag_Your_File_To_Upload')} 
                  </p>
                </Dragger>
              </Col>
              <Col span={12}>
                <h3>{t('LeadListing.Following_Criteria')}:</h3>
                <ul className='bullet'>
                  <li>{t('LeadListing.File_Format_Csv')}.</li>
                  <li>{t('LeadListing.Maximum')} 2 {t('LeadListing.Mb')} {t('LeadListing.File_Size_Allowed')}.</li>
                  <li>{t('LeadListing.Minimum')} 1 {t('LeadListing.Record_Maximum')} 10000 {t('LeadListing.Records_There')}.</li>
                  <li>{t('LeadListing.File_Should_Exact_Name')}.</li>
                  <li>{t('LeadListing.Sample_File')}.</li>
                  <li>
                  {t('LeadListing.Data_Required_All_Column')}.
                  </li>
                </ul>
                <div className='d-flex mt-10'>
                  <a
                    href={ImportLeadsNoteSample}
                    className='mr-5'
                    download
                    rel='noopener noreferrer'
                  >
                    {t('LeadListing.Download_Sample_File')}.
                  </a>
                </div>
              </Col>
            </Row>
          </Form>
        ) : null}
      </Modal>

      {/* Modal for successfully created people records, after importing file */}
      <Modal
        title={`${t('LeadListing.Import')} ${t('LeadListing.Leads')}`}
        centered
        visible={successModal}
        onCancel={() => {
          close();
          setSuccessModal(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            htmlType='button'
            onClick={() => {
              close();
              setSuccessModal(false);
            }}
          >
            {t('Common_Data.Close')}
          </Button>
        ]}
      >
        <h3 className='text-center'>
          {successCount} {t('LeadListing.Leads_Records_Created')}!
          <br />
        </h3>
        {errorFileURL && (
          <h3 className='text-center'>
            {t('LeadListing.Download_Status_Note')}.
            <br />
            <a href='#nogo' rel='noopener noreferrer'>
              {t('LeadListing.Download_File')}
            </a>
          </h3>
        )}
      </Modal>

      {/* Modal for successfully created people roles records, after importing file */}
      <Modal
        title={`${t('LeadListing.Import')} ${t('LeadListing.Leads')} ${t('LeadListing.Notes')}`}
        centered
        visible={roleSuccessModal}
        onCancel={() => {
          close();
          setRoleSuccessModal(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            htmlType='button'
            onClick={() => {
              close();
              setRoleSuccessModal(false);
            }}
          >
            {t('Common_Data.Close')}
          </Button>
        ]}
      >
        <h3 className='text-center'>
          {successCount} {t('LeadListing.Leads_Notes_Created')} !<br />
        </h3>
        {errorFileURL && (
          <h3 className='text-center'>
            {t('LeadListing.Download_Status_Note')}.
            <br />
            <a href='#nogo' rel='noopener noreferrer'>
             {t('LeadListing.Download_File')}
            </a>
          </h3>
        )}
      </Modal>
    </>
  );
});

export default LeadImportComponent;
