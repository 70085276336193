import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';
import { reset, clear } from '../../utils/ResetClearButton';
export default class ManagementAllDepartmentsStore {
  list_data = null;
  chart_data = [];
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;

  graphData = null;
  extraFilter = null;
  totalActiveUsers = 0;

  selectedGridFilter = localStorage.getItem('savedFilters')
    ? JSON.parse(localStorage.getItem('savedFilters'))?.MANAGEMENTALLDEPARTMENTSSTORE
      ? JSON.parse(localStorage.getItem('savedFilters'))?.MANAGEMENTALLDEPARTMENTSSTORE
      : null
    : null;

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('alldepartment_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  getList = (payload = {}) => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.columnApi.getColumnState();
    }
    // payload
    let payloadMerged = { ...payload, ...this.extraFilter };
    return Axios.post(`deal-desk-all-departments/tag-ticket/list`, payloadMerged).then(
      ({ data }) => {
        if (data.data && data.data.length) {
          data.data.forEach((item, index) => {
            if (item.type === 'tag') {
              item.tag_ticket_no = item.tag_no;
            } else {
              item.tag_ticket_no = item.ticket_no;
            }

            item.holder = null;
            if (item?.assigned_to?.assigned_to_user) {
              item.holder =
                item?.assigned_to?.assigned_to_user?.first_name +
                ' ' +
                item?.assigned_to?.assigned_to_user?.last_name;
            }
          });
        }
        this.list_data = data.data;
        this.total = data.total;
        this.current_page = data.current_page;

        if (this.agGrid && this.agGrid.columnApi) {
          this.agGrid.api.setFilterModel(filter);
          this.agGrid.columnApi.applyColumnState({ state: sort });
        }
        let columnConfig = localStorage.getItem('alldepartment_grid');
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
          this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
        }
        return data;
      }
    );
  };

  fetchGraphData = () => {
    return Axios.get(`leadstickets/open-ticket-status-userslist`)
      .then(({ data }) => {
        this.graphData = data && data.data;
        this.totalActiveUsers = data && data.users;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'alldepartment_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // reset function :: DEFAULT - R
  // handleReset = () => {
  //   localStorage.removeItem('alldepartment_grid');

  //   this.extraFilter = null;
  //   this.selectedGridFilter = null;

  //   // let tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
  //   // localStorage.setItem(
  //   //   'savedFilters',
  //   //   JSON.stringify({
  //   //     ...tempsavedFilters,
  //   //     MANAGEMENTALLDEPARTMENTSSTORE: null,
  //   //   })
  //   // );

  //   this.agGrid.api.setFilterModel(null);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.api.onFilterChanged(null);
  // };

  handleReset = () => {
    this.extraFilter = null;
    reset(this.agGrid, "alldepartment_grid", this.setSelectedGridFilter);
  }

  /** This is called on clear button */
  // Clear : Will clear all applied Filter and sorting, And will reset to default.
  applyFilterDefault = () => {
    this.extraFilter = null;
    clear(this.agGrid)
  };

  tickettagStatusUpdate = (payload = {}) => {
    return Axios.post(`deal-desk-productivity/tag-ticket/statuschange`, payload)
      .then(({ data }) => {
        if (this.agGrid) {
          this.getList();
        }
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;

    let tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
    localStorage.setItem(
      'savedFilters',
      JSON.stringify({
        ...tempsavedFilters,
        MANAGEMENTALLDEPARTMENTSSTORE: data,
      })
    );
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          MANAGEMENTALLDEPARTMENTSSTORE: { filter: filterdata },
        })
      );

      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  onExpandAllGroups = () => {
    if (this.agGrid) {
      this.agGrid.api.expandAll();
    }
  };
  onCollapseAllGroups = () => {
    if (this.agGrid) {
      this.agGrid.api.collapseAll();
    }
  };

  /* This is called on clear button :: DEFAULT-R */
  // applyFilterDefault = () => {
  //   this.selectedGridFilter = null;
  //   this.extraFilter = null;
  //   if (this.agGrid) {
  //     // localStorage.removeItem('alldepartment_grid');
  //     // let tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
  //     // localStorage.setItem(
  //     //   'savedFilters',
  //     //   JSON.stringify({
  //     //     ...tempsavedFilters,
  //     //     MANAGEMENTALLDEPARTMENTSSTORE: null,
  //     //   })
  //     // );

  //     this.agGrid.api.setFilterModel(null);
  //     this.agGrid.api.setSortModel(null);
  //     // this.agGrid.columnApi.resetColumnState();
  //     this.agGrid.api.onFilterChanged(null);
  //   }
  // };
}

decorate(ManagementAllDepartmentsStore, {
  list_data: observable,
  chart_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  extraFilter: observable,
  graphData: observable,
  selectedGridFilter: observable,
  totalActiveUsers: observable,

  setPageSize: action,
  getList: action,
  getChart: action,
  setupGrid: action,
  setDeleteValues: action,
  fetchGraphData: action,
  onFilterChanged: action,
  onGridChanged: action,
  handleReset: action,
  tickettagStatusUpdate: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  onExpandAllGroups: action,
  onCollapseAllGroups: action,
  applyFilterDefault: action,
});
