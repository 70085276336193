import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button, Form, Checkbox } from 'antd';
import FormComponent from './FormComponent';
import { vsmDealDesk, vsmNotify } from '../../../../../config/messages';
import useStore from '../../../../../store';
import moment from 'moment';
import {
  trimExtraCharacters,
  calculateHeight,
} from '../../../../../utils/GlobalFunction';
import debounce from 'lodash/debounce';
import AutosaveNotifiy from './AutosaveNotifiy';
import StructureLibrary from './StructureLibrary';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const { t } = useTranslation();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);

  const INITIAL_HEIGHT = '18px';

  const [form] = Form.useForm();
  const { saveType = 'structure' } = props;

  const autosaveConcatHash = '__##@#$%##__';
  const {
    AUTH,
    SSSLEADLIBRARYSTORE: {
      addSssData,
      screenAutoSaveDataLIB,
      structureAutoSaveDataLIB,
      scrutiniseAutoSaveDataLIB,
      structureAutosaveUpdatedLIB,
      screenAutosaveUpdatedLIB,
      scrutiniseAutosaveUpdatedLIB,
      clearAutosaveData,
      deleteSssData,
      fetchLibraryByType,
    },
    DEALDESKSTRUCTURELIBRARY: {
      Structure,
      SetStructure,
      Screen,
      SetScreen,
      Scrunitise,
      SetScrunitise,
      setCopiedStructureData,
      setCopiedScrunitiseData,
      setCopiedScreenData,
      calculateHeightOnEdit,
    },
  } = useStore();

  //---------------------------------AUTOSAVE - TIME OUT RELATED FUNCTION-------------------------------------------------------------//
  var autosaveTimeoutData = null;

  const clearAutoSaveTimer = () => {
    if (saveType === 'screen') {
      let screenTimeoutCall = localStorage.getItem('screenAutosaveTimeOutLIB');

      if (screenTimeoutCall) {
        clearTimeout(Number(screenTimeoutCall));
        localStorage.removeItem('screenAutosaveTimeOutLIB');
      }
    }
    if (saveType === 'structure') {
      let structureTimeoutCall = localStorage.getItem('structureAutosaveTimeOutLIB');
      if (structureTimeoutCall) {
        clearTimeout(Number(structureTimeoutCall));
        localStorage.removeItem('structureAutosaveTimeOutLIB');
      }
    }
    if (saveType === 'scrutinise') {
      let scrutiniseTimeoutCall = localStorage.getItem('scrutiniseAutosaveTimeOutLIB');
      if (scrutiniseTimeoutCall) {
        clearTimeout(Number(scrutiniseTimeoutCall));
        localStorage.removeItem('scrutiniseAutosaveTimeOutLIB');
      }
    }
  };

  const onChangeCallSubmit = (data = null) => {
    let allowAutosave = true;

    let timer = null;
    if (saveType === 'structure') {
      timer = localStorage.getItem('structureAutosaveTimeOutLIB');
    } else if (saveType === 'screen') {
      timer = localStorage.getItem('screenAutosaveTimeOutLIB');
    } else if (saveType === 'scrutinise') {
      timer = localStorage.getItem('scrutiniseAutosaveTimeOutLIB');
    }
    if (allowAutosave) {
      if (autosaveTimeoutData || timer) {
        clearTimeout(autosaveTimeoutData || Number(timer));
      }

      autosaveTimeoutData = setTimeout(() => {
        if (data) {
          handleSubmit(form.getFieldsValue(), true, {
            descriptionData: data,
          });
        } else {
          handleSubmit(form.getFieldsValue(), true);
        }
      }, 15000);
      if (saveType === 'screen') {
        localStorage.setItem('screenAutosaveTimeOutLIB', autosaveTimeoutData);
      }
      if (saveType === 'structure') {
        localStorage.setItem('structureAutosaveTimeOutLIB', autosaveTimeoutData);
      }
      if (saveType === 'scrutinise') {
        localStorage.setItem('scrutiniseAutosaveTimeOutLIB', autosaveTimeoutData);
      }
    }
  };
  //---------------------------------AUTOSAVE - TIME OUT RELATED FUNCTION-------------------------------------------------------------//

  useEffect(() => {
    let DataToProcess = null;
    if (saveType === 'structure' && props.visible && structureAutoSaveDataLIB) {
      let autosaveTitleArr = [];
      if (structureAutoSaveDataLIB?.title) {
        autosaveTitleArr = structureAutoSaveDataLIB?.title.split(autosaveConcatHash);
      }

      form.setFieldsValue({
        title:
          autosaveTitleArr.length > 0
            ? autosaveTitleArr[0]
            : structureAutoSaveDataLIB?.title,
        description_text: structureAutoSaveDataLIB?.description_text,
      });

      if (structureAutoSaveDataLIB.description) {
        DataToProcess = structureAutoSaveDataLIB.description;
      }
    }
    if (saveType === 'screen' && props.visible && screenAutoSaveDataLIB) {
      let autosaveTitleArr = [];
      if (screenAutoSaveDataLIB?.title) {
        autosaveTitleArr = screenAutoSaveDataLIB?.title.split(autosaveConcatHash);
      }

      form.setFieldsValue({
        title:
          autosaveTitleArr.length > 0
            ? autosaveTitleArr[0]
            : screenAutoSaveDataLIB?.title,
        description_text: screenAutoSaveDataLIB?.description_text,
      });

      if (screenAutoSaveDataLIB.description) {
        DataToProcess = screenAutoSaveDataLIB.description;
      }
    }
    if (saveType === 'scrutinise' && props.visible && scrutiniseAutoSaveDataLIB) {
      let autosaveTitleArr = [];
      if (scrutiniseAutoSaveDataLIB?.title) {
        autosaveTitleArr = scrutiniseAutoSaveDataLIB?.title.split(autosaveConcatHash);
      }

      form.setFieldsValue({
        title:
          autosaveTitleArr.length > 0
            ? autosaveTitleArr[0]
            : scrutiniseAutoSaveDataLIB?.title,
        description_text: scrutiniseAutoSaveDataLIB?.description_text,
      });

      if (scrutiniseAutoSaveDataLIB.description) {
        DataToProcess = scrutiniseAutoSaveDataLIB.description;
      }
    }

    if (DataToProcess) {
      let lengthLimit = 170;
      if (AUTH?.user?.bonus_figures) {
        if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
          lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
        }
      }
      calculateHeightOnEdit(DataToProcess).then(
        debounce(() => {
          DataToProcess &&
            DataToProcess.forEach((obj, index) => {
              obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

              obj.height = calculateHeight(
                `${saveType}_innerContent_textarea_${obj.item_key}`,
                INITIAL_HEIGHT,
                obj.detail,
                true
              );

              if (obj.children && obj.children.length > 0) {
                obj.children.forEach((obj1, index) => {
                  obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                  obj1.height = calculateHeight(
                    `${saveType}_innerContent_textarea_${obj1.item_key}`,
                    INITIAL_HEIGHT,
                    obj1.detail,
                    true
                  );
                  if (obj1.children && obj1.children.length > 0) {
                    obj1.children.forEach((obj2, index) => {
                      obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                      obj2.height = calculateHeight(
                        `${saveType}_innerContent_textarea_${obj2.item_key}`,
                        INITIAL_HEIGHT,
                        obj2.detail,
                        true
                      );
                    });
                  }
                });
              }
            });
        }, 200)
      );

      if (saveType && saveType === 'screen') {
        SetScreen(DataToProcess);
      }
      if (saveType && saveType === 'structure') {
        SetStructure(DataToProcess);
      }
      if (saveType && saveType === 'scrutinise') {
        SetScrunitise(DataToProcess);
      }
    }
  }, [
    form,
    props.visible,
    saveType,
    AUTH?.user,
    structureAutoSaveDataLIB,
    scrutiniseAutoSaveDataLIB,
    calculateHeightOnEdit,
    screenAutoSaveDataLIB,
    SetScreen,
    SetStructure,
    SetScrunitise,
  ]);

  const close = () => {
    if (saveType === 'structure') {
      let structuretimer = localStorage.getItem('structureAutosaveTimeOutLIB');
      if (Structure && Structure.length > 0 && structuretimer) {
        handleSubmit(form.getFieldsValue(), true);
      }
      SetStructure([]);
      setCopiedStructureData(null);
    }
    if (saveType === 'screen') {
      let screentimer = localStorage.getItem('screenAutosaveTimeOutLIB');
      if (Screen && Screen.length > 0 && screentimer) {
        handleSubmit(form.getFieldsValue(), true);
      }
      SetScreen([]);
      setCopiedScreenData(null);
    }
    if (saveType === 'scrutinise') {
      let scrutiniseTimer = localStorage.getItem('scrutiniseAutosaveTimeOutLIB');
      if (Scrunitise && Scrunitise.length > 0 && scrutiniseTimer) {
        handleSubmit(form.getFieldsValue(), true);
      }
      SetScrunitise([]);
      setCopiedScrunitiseData(null);
    }

    props.close();
    form.resetFields();
    setDisabled(true);
    clearAutoSaveTimer();
  };

  const checkSaveType = () => {
    if (saveType === 'screen') {
      return Screen;
    }
    if (saveType === 'structure') {
      return Structure;
    }
    if (saveType === 'scrutinise') {
      return Scrunitise;
    }
  }


  const getSaveIDBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return 3;
    }
    else if (saveType === 'structure') {
      return 1;
    }
    else if (saveType === 'scrutinise') {
      return 4;
    }
  }

  const handleSubmit = (data, isAutoSave = 0, descData = null) => {
    let lastFocusID;
    let lastFocusElement;
    if (isAutoSave) {
      lastFocusID = document.activeElement?.id;
      lastFocusElement = document.activeElement;
    } else {
      //--------------------TO CLEAR PREVIOUS TIMEOUT -------------//
      if (saveType === 'screen') {
        let screenTimeoutCall = localStorage.getItem('screenAutosaveTimeOutLIB');

        if (screenTimeoutCall) {
          clearTimeout(Number(screenTimeoutCall));
          localStorage.removeItem('screenAutosaveTimeOutLIB');
        }
      }
      if (saveType === 'structure') {
        let structureTimeoutCall = localStorage.getItem('structureAutosaveTimeOutLIB');
        if (structureTimeoutCall) {
          clearTimeout(Number(structureTimeoutCall));
          localStorage.removeItem('structureAutosaveTimeOutLIB');
        }
      }
      if (saveType === 'scrutinise') {
        let scrutiniseTimeoutCall = localStorage.getItem('scrutiniseAutosaveTimeOutLIB');
        if (scrutiniseTimeoutCall) {
          clearTimeout(Number(scrutiniseTimeoutCall));
          localStorage.removeItem('scrutiniseAutosaveTimeOutLIB');
        }
      }
      //--------------------TO CLEAR PREVIOUS TIMEOUT -------------//

      setSaving(true);
    }

    if (!checkSaveType()) {
      vsmNotify.error({
        message: `${getTitleBasedOnSaveType()} Required!`,
      });
      return false;
    }

    let datanew = {
      ...data,
      description: checkSaveType(),
      date: moment(new Date()).format('YYYY-MM-DD'),
    };

    if (isAutoSave && descData?.descriptionData) {
      datanew.description = descData?.descriptionData;
    }
    if (isAutoSave) {
      datanew.title = `${datanew.title ? datanew.title : ''
        }${autosaveConcatHash} ${new Date().getTime().toString()}`;
      if (!datanew.description_text) {
        delete datanew.description_text;
      }
    }

    datanew.is_library = true;
    datanew.type = getSaveIDBasedOnSaveType();
    datanew.autosave = isAutoSave;
    datanew.is_template = isTemplate ? 1 : 0;
    datanew.isSaveFromLibrary = 1; //TO LOAD EXISTING AUTOSAVE DATA

    addSssData(0, datanew)
      .then((data) => {
        if (!isAutoSave) {
          // fetchListData(0);
          fetchLibraryByType(saveType)
          clearAutosaveData(getSaveIDBasedOnSaveType(), true);
          //fetchStructureLibrary({ type: saveType });
          close();
          vsmNotify.success({
            message:
              saveType === 'screen'
              ? vsmDealDesk.screen.saveSuccess
              : saveType === 'structure' ? vsmDealDesk.structure.saveSuccess : vsmDealDesk.scrutinise.saveSuccess
          });
          setIsSaveModal(false);
        }
      })
      .catch((e) => {
        if (e.errors) {
          let structure_description_error = 0;
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (e === 'description') {
                  structure_description_error = 1;
                }
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });

          if (structure_description_error) {
            vsmNotify.error({
              message: `${getTitleBasedOnSaveType()} ${t('Common_Data.Description')} ${t('DealDesk.Required')}!`,
            });
          } else {
            form.setFields(e.errors);
          }
        }
      })
      .finally(() => {
        setSaving(false);

        //-------------------------START: TO FOCUS INPUT IN CASE OF AUTOSAVE---------------------------------//
        if (isAutoSave && lastFocusID?.includes(`${saveType}_innerContent_`)) {
          let elementID = document.getElementById(lastFocusID);
          elementID.focus();
          let len = lastFocusElement?.selectionStart;
          if (len) {
            elementID.setSelectionRange(len, len);
          }
        }
        //-------------------------END: TO FOCUS INPUT IN CASE OF AUTOSAVE---------------------------------//
      });
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        if (
          (checkSaveType().length > 0) &&
          form.getFieldValue('title') !== ''
        ) {
          onChangeCallSubmit();

          setDisabled(false);
        } else {
          setDisabled(true);
        }
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  const clearAutoSave = () => {
    if (saveType === 'structure') {
      if (structureAutosaveUpdatedLIB) {
        deleteSssData(structureAutosaveUpdatedLIB.id).then(() => {
          clearAutosaveData(1, true);
          SetStructure([]);
          form.resetFields();
          setDisabled(true);
        });
      } else {
        SetStructure([]);
        form.resetFields();
        setDisabled(true);
      }
    }
    if (saveType === 'screen') {
      if (screenAutosaveUpdatedLIB) {
        deleteSssData(screenAutosaveUpdatedLIB.id).then(() => {
          clearAutosaveData(3, true);
          SetScreen([]);
          form.resetFields();
          setDisabled(true);
        });
      } else {
        SetScreen([]);
        form.resetFields();
        setDisabled(true);
      }
    }
    if (saveType === 'scrutinise') {
      if (scrutiniseAutosaveUpdatedLIB) {
        deleteSssData(scrutiniseAutosaveUpdatedLIB.id).then(() => {
          clearAutosaveData(4, true);
          SetScrunitise([]);
          form.resetFields();
          setDisabled(true);
        });
      } else {
        SetScrunitise([]);
        form.resetFields();
        setDisabled(true);
      }
    }
    clearAutoSaveTimer();
  };

  //-----------START: LIBRARY COPY---------------//
  const openViewDrawer = (data) => {
    setViewDrawer(true);
  };
  const closeViewDrawer = () => setViewDrawer(false);
  //-----------END: LIBRARY COPY---------------//

  const getTitleBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return `${t('DealDesk.Screen')}`;
    } else if (saveType === 'structure') {
      return `${t('Common_Data.Structure')}`;
    } else if (saveType === 'scrutinise') {
      return `${t('Common_Data.Scrutinise')}`;
    }
  }

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={viewDrawer ? '1200px' : '1050px'}
      title={`${t('Common_Data.Add')} ${getTitleBasedOnSaveType()}`}
      destroyOnClose={true}
      footer={[
        <>
          <div className='DealLibrary-ScheduleFormFooter'>
            <div className='mr-10'>
              <Checkbox checked={isTemplate} onChange={(e) => { setIsTemplate(e.target.checked) }}>
                {t('DealDesk.Save_Template')}
              </Checkbox>
            </div>
            <div>
              <AutosaveNotifiy saveType={saveType} />
              <Button
                key='1'
                // form={`addfrom${saveType}`}
                className='mr-10'
                // loading={saving}
                // htmlType='submit'
                type='primary'
                disabled={isDisabled}
                shape='round'
                size='medium'
                onClick={()=>setIsSaveModal(true)}
              >
                {t('Common_Data.Save')}
              </Button>
              <Button
                shape='round'
                className='mr-10'
                size='medium'
                onClick={close}
                key='2'
              >
                {t('Common_Data.Cancel')}
              </Button>

              <Button shape='round' size='medium' onClick={() => clearAutoSave()} key='3'>
                {t('Common_Data.Clear')}
              </Button>
            </div>
          </div>
        </>,
      ]}
    >
      <FormComponent 
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        id={`addfrom${saveType}`}
        saveType={saveType}
        autoSaveData={onChangeCallSubmit}
        openViewDrawer={openViewDrawer}
        /**Save Modal props */
        isSaveModal={isSaveModal}
        setIsSaveModal={setIsSaveModal}
        loading={saving}
        isTemplate={isTemplate}
        setIsTemplate={setIsTemplate}
      />

      {viewDrawer && (
        <StructureLibrary
          visible={viewDrawer}
          close={closeViewDrawer}
          saveType={saveType}
        />
      )}
    </Drawer>
  );
});

export default AddComponent;
