import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../store';
import { vsmNotify } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation()
  const {
    DEALDESKSTORE: { activeStructureData, setActiveStructureData },
    // DEALDESKSTRUCTURE: { removeStructure },
    SSSLEADLIBRARYSTORE: { deleteSssData }
  } = useStore();
  const [saving, setSaving] = useState(false);

  const { listingType } = props;

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };

  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    deleteSssData(activeStructureData?.id, { is_deal_desk: true })
      .then(() => {
        close();
        setActiveStructureData(null);
        vsmNotify.success({
          message: `${getTitleBasedOnSaveType()} ${t('DealDesk.Remove_Successfully')}!`,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const getTitleBasedOnSaveType = () => {
    if (listingType === 'screen') {
      return `${t('DealDesk.Screen')}`;
    } else if (listingType === 'structure') {
      return `${t('Common_Data.Structure')}`;
    } else if (listingType === 'scrutinise') {
      return `${t('Common_Data.Scrutinise')}`;
    }
  }

  return activeStructureData ? (
    <Modal
      centered
      title={`${t('Common_Data.Delete')} ${getTitleBasedOnSaveType()} - #${activeStructureData?.id}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form={`deleteStructureFromDealdesk${props.listingType}`}
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
          shape='round'
          size='medium'
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' onClick={close} shape='round' size='medium'>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id={`deleteStructureFromDealdesk${props.listingType}`} onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col className='DeleteText'>
              {t('DeleteComp.Are_You_Sure_To_Delete')}{" "}{getTitleBasedOnSaveType()}? {/*<strong>"#"</strong>? */}
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
