import React from 'react';
import { observer } from 'mobx-react';
import { Popover } from 'antd';
import useStore from '../../../../store';
import { vsmCallAudit, vsmNotify } from '../../../../config/messages';

const EmailNumberRender = observer((props) => {
  const { AUTH, LEADSSTORE } = useStore();
  const emailList = props.value;

  const handleEmailCallAudit = (data) => {
    let formdata = {
      id: props.data.id,
      email: data,
    };
    LEADSSTORE.callEmailAudit(formdata).then(() => {
      vsmNotify.success({
        message: vsmCallAudit.emailadded,
      });
    });
  };

  const emails = (
    <div className='contactList'>
      <ul>
        {emailList &&
          emailList.length > 0 &&
          emailList.map((detail, index) => {
            return (
              <li key={index} value={`${detail.contact_for}: ${detail.contact_value}`}>
                <a
                  href={`mailto:${detail.contact_value}`}
                  onClick={(e) => {
                    handleEmailCallAudit(detail.contact_value);
                  }}
                >
                  <img
                    src={
                      (detail.contact_for === 'Primary' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Home' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Office' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Other' && AUTH.GetThemedImage('Mail'))
                    }
                    alt=''
                    className='menuicon'
                  />{' '}
                  {detail.contact_value}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );

  return (
    <div className='action-column TelephoneRenderer'>
      <Popover
        content={emails}
        placement='topLeft'
        trigger='hover'
        overlayClassName='profileMenu'
      >
        {emailList &&
          emailList.length > 0 &&
          emailList.map((detail, index) => {
            if (index === 0) {
              return (
                <a
                  href={`mailto:${detail.contact_value}`}
                  onClick={(e) => {
                    handleEmailCallAudit(detail.contact_value);
                  }}
                >
                  <img
                    src={
                      (detail.contact_for === 'Primary' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Home' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Office' && AUTH.GetThemedImage('Mail')) ||
                      (detail.contact_for === 'Other' && AUTH.GetThemedImage('Mail'))
                    }
                    alt=''
                    className='menuicon'
                  />{' '}
                  {detail.contact_value}
                </a>
              );
            } else {
              return null;
            }
          })}
      </Popover>
    </div>
  );
});
export default EmailNumberRender;
