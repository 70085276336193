import React from 'react';
import Moment from 'moment';
import { useLocation } from 'react-router';
import NumberFormat from 'react-number-format';
import moment from 'moment';
//import NumberFormat from 'react-number-format';

export const DateComparator = (filterLocalDateAtMidnight, cellValue) => {
  //using moment js
  var dateAsString = Moment(cellValue).format('DD/MM/YYYY');
  var dateParts = dateAsString.split('/');
  var cellDate = new Date(
    Number(dateParts[2]),
    Number(dateParts[1]) - 1,
    Number(dateParts[0])
  );

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    return 0;
  }

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  }

  if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
};

export const tag_status_name = {
  1: 'Open',
  2: 'Read',
  3: 'Closed',
  4: 'Returned',
  5: 'Approved',
};

export const globalStatus = (module_name, param_type, param) => {
  let cash_transaction_status = {
    0: 'Pending',
    1: 'Imported / added',
    2: 'Auto Identified',
    3: 'Manually Verified',
    4: 'Problematic / Unidentified',
    5: 'Problematic / Identified',
  };
  let reconcile_status = {
    0: 'Pending(Identified)',
    1: 'Partially Reconciled',
    2: 'Fully Reconciled',
  };
  let cash_import = {
    1: 'Import Success',
    2: 'Partial Import',
    3: 'Pending',
    4: 'In Progress',
    5: 'Failed',
    6: 'Cancel',
    7: 'Delete',
  };
  let cash_import_log = {
    1: 'Imported / added',
    2: 'Auto Identified',
    3: 'Manually Verified',
    4: 'Problematic / Unidentified',
    5: 'Problematic / Identified',
  };
  let reconcile_transaction_status = {
    0: 'Pending(Identified)',
    1: 'Partially Reconciled',
    2: 'Fully Reconciled',
  };
  let ppsn_document_type = {
    1: 'Legal Correspondence',
    2: 'P21 (Tax Balancing Statement)',
    3: 'Tax Assessment',
    4: 'Notice of Tax Credits',
    5: 'Payslip',
    6: 'P60',
    7: 'P45',
  };
  let contract_status_list = {
    1: 'In Approval Process',
    2: 'Activated',
    3: 'Draft',
  };
  switch (module_name) {
    case 'cash_transaction_status':
      return getStatusValue(cash_transaction_status, param_type, param);
    case 'reconcile_status':
      return getStatusValue(reconcile_status, param_type, param);
    case 'cash_import':
      return getStatusValue(cash_import, param_type, param);
    case 'cash_import_log':
      return getStatusValue(cash_import_log, param_type, param);
    case 'reconcile_transaction_status':
      return getStatusValue(reconcile_transaction_status, param_type, param);

    case 'ppsn_document_type':
      return getStatusValue(ppsn_document_type, param_type, param);
    case 'contracts_status':
      return getStatusValue(contract_status_list, param_type, param);

    default:
      return null;
  }
};

const getStatusValue = (array, param_type, param) => {
  if (param_type === 'key') {
    return array[param];
  } else {
    return parseInt(Object.keys(array).find((x) => (array[x] === param ? x : false)));
  }
};

export const CurrencyFormat = (prop) => {
  return (
    <NumberFormat
      value={prop.value}
      displayType={prop.displayType || 'text'}
      thousandSeparator={true}
      {...prop}
    />
  );
};

export const numberWithCommas = (x) => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : x;
};

export const convertTextToID = (text_array, main_array, text_key, id_key) => {
  let new_array = [];
  if (text_array && text_array.values && text_array.values.length > 0) {
    text_array.values.forEach((x) => {
      var temp =
        main_array && main_array.length > 0 && main_array.find((y) => y[text_key] === x);
      if (x && temp) {
        new_array.push(temp[id_key]);
      } else {
        insertAt(new_array, 0, x);
      }
    });
  }
  return new_array;
};

function insertAt(array, index, ...elementsArray) {
  array.splice(index, 0, ...elementsArray);
}

// module path
export const relatedOrgPath = '/organisations/all-organisations/role-mapping';
export const relatedPeoplePath = '/people/all-people/role-mapping';
export const orgBanksPath = '/organisations/all-organisations/bank-accounts';
export const peopleBanksPath = '/people/all-people/bank-accounts';
export const allTransactionPath = '/trade-transactions/all-transactions';
export const cashTransactionPath = '/cash-transactions/all-transaction';
export const peoplePhotoURL = process.env.React_APP_API_URL + 'storage/peoplephoto/';

// Convert Number To Numberformatted
export const convertNumberToComma = (value, fixed_to = 6) => {
  let tempvalue = convertFormatedNoToFloat(value);
  if (!isNaN(tempvalue)) {
    let newvalue = parseFloat(tempvalue)
      .toFixed(fixed_to)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return newvalue;
  }
  return 0;
};
// Convert Numberformatted To Float
export const convertFormatedNoToFloat = (value) => {
  if (!isNaN(parseInt(value))) {
    let newvalue = value.toString().replace(/,/g, '');
    return parseFloat(newvalue);
  }
  return 0;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const convertError = (formatedErrors) => {
  formatedErrors.forEach((x) => {
    if (x.name.indexOf('.') !== -1) {
      x.name = x.name.split('.');
      x.name.forEach((e, i) => {
        if (!isNaN(parseInt(e))) {
          x.name[i] = parseInt(e);
        }
      });
    }
  });
  return formatedErrors;
};

export const reportTypeArray = [
  { value: 'leads', text: 'SOLD' },
  { value: 'tags', text: 'Tags' },
  { value: 'tickets', text: 'Tickets' },
  { value: 'reminders', text: 'Reminders' },
  { value: 'deal_maker_bonus', text: 'Deal Maker Bonus' },
  { value: 'sample_weekly', text: 'Sample Weekly' },
  { value: 'performance', text: 'Performance' },
  { value: 'investment_hours', text: 'Time Spent' },
  { value: 'related_items', text: 'Related Items' },
];

export const text_patterns_tinyMCE = [
  // ------------------------ Please do not remove ----------------------------//
  // { start: '*', end: '*', format: 'italic' },
  // { start: '**', end: '**', format: 'bold' },
  // { start: '#', format: 'h1' },
  // { start: '##', format: 'h2' },
  // { start: '###', format: 'h3' },
  // { start: '####', format: 'h4' },
  // { start: '#####', format: 'h5' },
  // { start: '######', format: 'h6' },
  // The following text patterns require the `lists` plugin
  // { start: '3.', cmd: 'InsertOrderedList' },
  // { start: '* ', cmd: 'InsertUnorderedList' },
  // { start: '- ', cmd: 'InsertUnorderedList' }
  // ----------------------------------------------------------------------------//
];

export const valid_elements_tinyMCE = `a[*],strong/b,br,ul,ol[*],li[*],&nbsp;,span,h1,h2,h3,h4,h5,h6,pre,em,img[*],span[*],div,br[*],table,tr[*],thead,tbody,th[*],table[*], thead[*], tbody[*], tr[*], th[*] ,sub, sup,font,p[*],span[style],td[abbr|align<center?char?justify?left?right|axis|bgcolor|char|charoff|class
  |colspan|dir<ltr?rtl|headers|height|id|lang|nowrap<nowrap|onclick
  |ondblclick|onkeydown|onkeypress|onkeyup|onmousedown|onmousemove
  |onmouseout|onmouseover|onmouseup|rowspan|scope<col?colgroup?row?rowgroup
  |style|title|valign<baseline?bottom?middle?top|width],`;

export const dateCompare = (date1, date2) => {
  date1.setSeconds(0);
  date2.setSeconds(0);
  let newDate1 = date1.getTime();
  let newDate2 = date2.getTime();
  if (newDate1 === newDate2) {
    return false;
  } else {
    return true;
  }
};

export const address_type_list = [
  'Registered Office',
  'Business Location',
  'Commercial Office',
  'Technical Office',
  'Operations Office',
  'Invoice Address',
  'Other',
];

export const reminder_priority = [
  { value: '2', text: 'Routine' },
  { value: '1', text: 'Timed' },
  { value: '5', text: 'Urgent' },
  { value: '6', text: 'Critical' },
  { value: '3', text: 'Normal' },
  { value: '4', text: 'High' },
];

export const ageDifferenceInDays = (date1 = new Date(), date2 = new Date()) => {
  let date1_time = date1.getTime();
  let date2_time = date2.getTime();
  let diff_time = Math.abs(date2_time - date1_time);
  let diff_days = Math.ceil(diff_time / (1000 * 3600 * 24));
  return diff_days;
};

export const ageDifferenceInHours = (date1 = new Date(), date2 = new Date()) => {
  let diffInMilliSeconds = Math.abs(date2 - date1) / 1000;
  const days = Math.floor(diffInMilliSeconds / 86400);
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  return { days, hours };
};

export const isDatePassed = (date1 = new Date(), date2 = new Date()) => {
  let diff = date2.getTime() - date1.getTime();
  if (diff > 0) {
    return true;
  } else {
    return false;
  }
};

export const calculateHeight = (
  id,
  initialValue = '18px',
  value = null,
  isStructureScreen = false
) => {
  let textAreaID = id;
  let newHeight = initialValue;
  let textAreaField = document.getElementById(textAreaID);

  // Reset field height
  if (textAreaField) {
    if (value !== null) {
      textAreaField.value = value;
    }
    if (isStructureScreen) {
      textAreaField.style.height = initialValue;
    } else {
      textAreaField.style.height = 'inherit';
    }
    // Get the computed styles for the element
    let computed = window.getComputedStyle(textAreaField);
    // Calculate the height
    let height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      parseInt(computed.getPropertyValue('padding-top'), 10) +
      textAreaField.scrollHeight +
      parseInt(computed.getPropertyValue('padding-bottom'), 10) +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);
    height += 1;
    textAreaField.style.height = height + 'px';
    newHeight = height + 'px';
  }
  return newHeight;
};

export const trimExtraCharacters = (len, description) => {
  let newString = '';
  if (description) {
    newString = description.substring(0, len);
  }
  return newString;
};

//------------------------ Function for removing background color based on theme ------------------------------//

export const removeBGColorTinyMCE = (newContent) => {
  newContent = newContent.replace(
    /background-color:[^;]+(?=;)|^background-color:[^;]+;/g,
    'background-color: transparent'
  );
  newContent = newContent.replace(
    /^background: #[0-9a-fA-F]{6,6};/g,
    'background: rgba(0, 0, 0, 0.85);'
  );
  newContent = newContent.replace(/style="background: #[0-9a-fA-F]{6,6}"/g, '');
  newContent = newContent.replace(/style="color: #[0-9a-fA-F]{6,6}"/g, '');
  newContent = newContent.replace(/^#([a-z0-9]{3}){1,2}$/gim, '"rgba(0, 0, 0, 0.85);"');

  return newContent;
  /*let themeColor = localStorage.getItem('app_color_theme');
  if (themeColor) {
    newContent = newContent.replace(
      /background-color: var\(--lite\)/g,
      'background-color: transparent;'
    );
    newContent = newContent.replace(
      /background-color: var\(--seleteBG\)/g,
      'background-color: transparent;'
    );
    newContent = newContent.replace(
      /background-color: var\(--primary_rgb\)/g,
      'background-color: transparent;'
    );
    newContent = newContent.replace(
      /background-color: var\(--primaryComment\)/g,
      'background-color: transparent;'
    );
    newContent = newContent.replace(
      /background-color: var\(--primaryConversation\)/g,
      'background-color: transparent;'
    );
    newContent = newContent.replace(
      /background-color: var\(--textDark\)/g,
      'background-color: transparent;'
    );
    newContent = newContent.replace(
      /background-color: var\(--textLite\)/g,
      'background-color: transparent;'
    );
    newContent = newContent.replace(
      /background-color: var\(--dark\)/g,
      'background-color: transparent;'
    );
    newContent = newContent.replace(
      /background-color: var\(--primary_rgb\)/g,
      'background-color: transparent;'
    );
    if (themeColor === 'blue') {
      newContent = newContent.replace(
        /background-color: rgb\(233, 239, 251\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(198, 213, 235\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(210, 226, 251\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(222, 229, 239\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #e9effb;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #96a6bf;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #d2e2fb;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #777777;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #28324b;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #dee7fa;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #dee5ef;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #c6d5eb;/g,
        'background-color: transparent;'
      );
    }
    if (themeColor === 'pink') {
      newContent = newContent.replace(
        /background-color: rgb\(250, 235, 244\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(224, 195, 213\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(239, 204, 226\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(255, 223, 243\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #faebf4;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #bb9baf;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #E0C3D5;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #777777;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #5f2d48;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #efd8e6;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #ffdff3;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #efcce2;/g,
        'background-color: transparent;'
      );
    }
    if (themeColor === 'green') {
      newContent = newContent.replace(
        /background-color: rgb\(244, 255, 240\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(207, 231, 199\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(219, 247, 210\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(185, 225, 173\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #A0C793;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #4D803B;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #CFE7C7;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #777777;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #475B40;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #D1E5CA;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #F4FFF0;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #dbf7d2;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #b9e1ad;/g,
        'background-color: transparent;'
      );
    }
    if (themeColor === 'yellow') {
      newContent = newContent.replace(
        /background-color: rgb\(255, 253, 239\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(245, 236, 184\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(238, 222, 138\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(245, 238, 199\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #DDB95C;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #DDB95C;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #EEDE8A;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #777777;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #8B7506;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #EDEEA7;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #FFFDEF;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #f5ecb8;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #f5eec7;/g,
        'background-color: transparent;'
      );
    }
    if (themeColor === 'gray') {
      newContent = newContent.replace(
        /background-color: rgb\(248, 248, 248\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(206, 206, 206\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(229, 229, 229\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: rgb\(221, 221, 221\);/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #a5a5a5;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #b4b4b4;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #e5e5e5;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #777777;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #484848;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #dddddd;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #f8f8f8;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #cecece;/g,
        'background-color: transparent;'
      );
      newContent = newContent.replace(
        /background-color: #dddddd;/g,
        'background-color: transparent;'
      );
    }
  }
  return newContent;*/
};
//-------------------------------------------------------------------------------------------------------------//

//-------------------------------------- Check Element is in Current ViewPort Or Not --------------------------//

export const isElementOutViewport = (el) => {
  let rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= window.innerHeight &&
    rect.right <= window.innerWidth
  );

  // return rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight;
};
//-------------------------------------------------------------------------------------------------------------//

export const convertTinyMCEDropdown = (list = [], key) => {
  list.map((obj) => {
    obj.text = obj[key];
    obj.value = obj[key];
    return obj;
  });
  return list;
};

export const max_value_turn_over = 16150000;

export const max_value_investment_hours = 999;

export const max_value_idle_ticket = 90;

export const sortByArr = [
  { value: 'lead_status', text: 'Sold Stage' },
  { value: 'product_name', text: 'Sold Product/Service' },
  { value: 'sold_source_details', text: 'Sold Source & Details' },
  { value: 'investment_hours', text: 'Time Spent' },
  { value: 'created_at', text: 'Created On' },
  { value: 'modified_at', text: 'Modified On' },
  { value: 'last_note_on', text: 'Last Note On' },
  { value: 'last_conversation_on', text: 'Last Conversation On' },
];

export const firstPositionItemKey = [1];

export const generate_onReports = [
  'deal_maker_bonus',
  'sample_weekly',
  'performance',
  'investment_hours',
];

export const saveFilterArr = ['leads', 'tags', 'tickets', 'reminders'];

export const remindersStatus = [
  // { value: 'Active', text: 'Active' },
  //{ value: 'Approved', text: 'Approved' },
  { value: 'Closed', text: 'Closed' },
  //{ value: 'Document Uploaded', text: 'Document Uploaded' },
  { value: 'Open', text: 'Open' },
  //{ value: 'Pending', text: 'Pending' },
  //{ value: 'Rejected', text: 'Rejected' },
  //{ value: 'New', text: 'New' },
  { value: 'Read', text: 'Read' },
  //{ value: 'Completed', text: 'Completed' },
  //{ value: 'Reset', text: 'Reset' },
];

export const reminderTypeList = [
  { value: 'contacts', text: 'Person' },
  { value: 'organisations', text: 'Organisation' },
  { value: 'leads', text: 'SOLD' },
  { value: 'tickets', text: 'Ticket' },
  { value: 'notes', text: 'Note' },
  { value: 'tags', text: 'Tag' },
  { value: 'private', text: 'Private' },
];

//--------------START - USED IN REPORT - RELATED ITEMS-----------//
export const relatedItemTypeList = [
  { value: 'contacts', text: 'People' },
  { value: 'organisations', text: 'Organisation' },
  { value: 'leads', text: 'SOLD' },
  { value: 'tickets', text: 'Ticket' },
];
export const relatedItemCheckboxOptions = ['SOLD', 'Tickets', 'Organisations', 'People'];
//--------------END - USED IN REPORT - RELATED ITEMS-----------//

export const audit_group_onArr = [
  { value: 'day', text: 'Daily' },
  { value: 'week', text: 'Weekly' },
  { value: 'month', text: 'Monthly' },
  { value: 'year', text: 'Yearly' },
];

export const group_onArr = [
  { value: 'day', text: 'Day' },
  { value: 'week', text: 'Week' },
  { value: 'month', text: 'Month' },
  { value: 'year', text: 'Year' },
];

export const minTwoDigits = (n) => {
  return (n < 10 ? '0' : '') + n;
};

export const secondsToHms = (d) => {
  d = Number(d);
  if (d > 59) {
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    // var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? (h > 999 ? numberWithCommas(h) : minTwoDigits(h)) + 'h ' : ' ';
    var mDisplay = h > 0 || m > 0 ? minTwoDigits(m) + 'm ' : ' ';
    // var sDisplay = s > 0 ? minTwoDigits(s) + 's' : ' ';
    return hDisplay + mDisplay;
  } else {
    return '0';
  }
};

export const secondsToHmsWithZeros = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  // var s = Math.floor((d % 3600) % 60);
  var hDisplay = (h > 999 ? numberWithCommas(h) : minTwoDigits(h)) + 'h ';
  var mDisplay = minTwoDigits(m) + 'm ';
  // var sDisplay = minTwoDigits(s) + 's';
  return hDisplay + mDisplay;
};

export const secondsToHmsWithAppend = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  // var s = Math.floor((d % 3600) % 60);

  var hDisplay = minTwoDigits(h ? h : 0) + 'h ';
  var mDisplay = minTwoDigits(m ? m : 0) + 'm ';
  // var sDisplay = minTwoDigits(s ? s : 0) + 's ';

  return hDisplay + mDisplay;
};

export const reminderTypeBasedOn = (isAdd) => {
  if (isAdd) {
    return [
      { value: 'contacts', text: 'Person' },
      { value: 'organisations', text: 'Organisation' },
      { value: 'leads', text: 'SOLD' },
      { value: 'tickets', text: 'Ticket' },
      { value: 'private', text: 'Private' },
    ];
  } else {
    return [
      { value: 'contacts', text: 'Person' },
      { value: 'organisations', text: 'Organisation' },
      { value: 'leads', text: 'SOLD' },
      { value: 'tickets', text: 'Ticket' },
      { value: 'notes', text: 'Note' },
      { value: 'tags', text: 'Tag' },
      { value: 'private', text: 'Private' },
    ];
  }
};

export const capitalFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const replaceUnderScoreWithSpace = (string = '') => {
  return typeof string === 'string' ? string.replace(/_/gm, ' ') : string;
};

export const hideTotalRecordsCountForReport = ['investment_hours', 'performance'];

export const getSOLDTotalColumns = [
  'turn_over',
  'financial_value',
  'rate',
  'loan_amount',
  'investment_hours',
  'total_conversation_words',
  'total_conversation',
  'total_communication',
  'total_inbound_notes',
  'total_outbound_notes',
  'total_comments',
  'total_words',
  'deals',
  'meetings',
  'meetings_held',
  'qualify_held',
  'traded'
];

export const geTicketTotalColumns = ['total_notes'];
export const getDealMakerBonusTotalColumns = [
  'leads_screen_count',
  'leads_structure_count',
  'leads_schedule_count',
  'leads_issued_rpas_count',
];
export const getTotalColumnSampleWeeklyReport = [
  'investment_hours',
  'financial_value',
  'rate',
  'loan_amount',
];

export const getTotalColumnName = {
  turn_over: 'turn_over_original',
  financial_value: 'financial_value_original',
  rate: 'rate',
  loan_amount: 'loan_amount_original',
  investment_hours: 'investment_hours_original',
  total_conversation_words: 'total_conversation_words',
  total_conversation: 'total_conversation',
  total_communication: 'total_communication',
  total_inbound_notes: 'total_inbound_notes',
  total_outbound_notes: 'total_outbound_notes',
  total_comments: 'total_comments',
  total_notes: 'total_notes',
  total_words: 'total_words',
  leads_screen_count: 'leads_screen_count',
  leads_structure_count: 'leads_structure_count',
  leads_schedule_count: 'leads_schedule_count',
  leads_issued_rpas_count: 'leads_issued_rpas_count',
  deals: 'deals',
  meetings: 'meetings',
  meetings_held: 'meetings_held',
  qualify_held: 'qualify_held',
  traded: 'traded'
};

export const formatDataBasedOnColumn = (colName, total) => {
  if (currencyType.includes(colName)) {
    if (total / 1000000000000 > 1) {
      return `${numberWithCommas(parseFloat(total / 1000000000000).toFixed(2))}t`;
    } else if (total / 1000000000 > 1) {
      return `${numberWithCommas(parseFloat(total / 1000000000).toFixed(2))}b`;
    } else if (total / 1000000 >= 1) {
      return `${numberWithCommas(parseFloat(total / 1000000).toFixed(2))}m`;
    } else if (total / 999 >= 1) {
      return `${numberWithCommas(parseFloat(total / 1000).toFixed(2))}k`;
    } else {
      total = parseFloat(total).toFixed(2);
      return numberWithCommas(total);
    }
  }
  if (timeFormat.includes(colName)) {
    return secondsToHms(total);
  }
  return numberWithCommas(total);
};

export const currencyType = [
  'turn_over',
  'sold_turn_over',
  'leads_turn_over',
  'finance',
  'financial_value',
  'loan_amount',
  'active_deals_finance_value',
  'active_deals_turn_over',
  'active_leads_finance_value',
  'active_leads_turn_over',
  'active_trade_finance_value',
  'active_trade_turn_over',
  'active_treasury_finance_value',
  'active_treasury_turn_over',
  'deals_finance_value',
  'deals_turn_over',
  'leads_finance_value',
  'leads_turn_over',
  'sold_finance_value',
  'sold_turn_over',
  'trade_finance_value',
  'trade_turn_over',
  'treasury_finance_value',
  'treasury_turn_over',
];

// ------------- Summery Sold country report column array ---------//

export const preDefine_column = [
  'active_deals_finance_value',
  'active_deals_turn_over',
  'active_leads_finance_value',
  'active_leads_turn_over',
  'active_trade_finance_value',
  'active_trade_turn_over',
  'active_treasury_finance_value',
  'active_treasury_turn_over',
  'deals_finance_value',
  'deals_turn_over',
  'leads_finance_value',
  'leads_turn_over',
  'sold_finance_value',
  'sold_turn_over',
  'trade_finance_value',
  'trade_turn_over',
  'treasury_finance_value',
  'treasury_turn_over',
];
// ------------- Summery Sold country report column array end ---------//

export const timeFormat = ['investment_hours'];

export const getGroupOnText = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',
};

export const trackTimeSpentPageNameList = {
  tags: 'Tags',
  blockeditassign: 'Block Edit Assign',
  dealdesk: 'Dealdesk',
  leadlisting: 'Lead Listing',
  promotion: 'Promotion',
  waste: 'Waste',
  settings: 'Settings',
  page_filter: 'Page Filter',
  audit_logs: 'Audit Logs',
  sold_status: 'SOLD Status',
  ticker_tap_info: 'Ticker Information',
  credebt_classification: 'Credebt Classification',
  all_departments: 'All Departments',
  productivity: 'Productivity',
  overview: 'Overview',
  knowledge: 'Knowledge',
  reports: 'Reports',
  contracts: 'Contracts',
  deal_builder: 'Deal Builder',
  user_groups: 'User Group',
  users: 'Users',
  people: 'People',
  organisation: 'Organisation',
  library: 'Library',
  reminders: 'Reminder',
  tickets: 'Tickets',
  search: 'Search',
  sequencer: 'Application',
};

/** To use these values for reset button and clear button on setFilterModel */
export let defaultMyOpenTickets = null;
export let defaultMyOpenTags = null;
export let defaultMyOpenReminders = null;
export let defaultPromotionFilter = null;

//-------START:fetchStaticFilters,getSavedFilterPageTypes,filterPageSavedFilter USED IN PAGE SAVED FILTER ----------//
export const fetchStaticFilters = (pageType = null) => {
  let capitalizePageType = () => {
    let capitalizeFilterName = '';
    if (pageType.includes("leads")) {
      capitalizeFilterName = pageType.replace("leads", "").charAt(0).toUpperCase() + pageType.replace("leads", "").slice(1) + " Leads"
    }
    if (pageType.includes("blockeditassign")) {
      capitalizeFilterName = 'Block Edit Assign'
    }
    if (pageType.includes("reminder")) {
      capitalizeFilterName = "Reminders"
    }
    else {
      capitalizeFilterName = pageType.charAt(0).toUpperCase() + pageType.slice(1)
    }
    return capitalizeFilterName
  }
  let staticFilters = [{ id: 0, filter: 'Save New Filter' }, { id: 2, filter: `All ${capitalizePageType()} All Time` }];
  defaultMyOpenReminders = {
    status: {
      values: [
        //'Approved',
        //'Document Uploaded',
        'Open',
        //'Pending',
        //'Rejected',
        'Read',
      ],
      filterType: 'set',
    },
  };
  defaultPromotionFilter = {
    'lead_owner_name.full_name': {
      values: [localStorage.getItem('user_name_fullname')],
      filterType: 'set',
    },
  };
  if (pageType === 'tickets') {
    // let fetch_child_ticket = JSON.parse(localStorage.getItem('fetch_child_ticket'));
    // let get_child_tickets = JSON.parse(localStorage.getItem('get_child_tickets'));
    defaultMyOpenTickets = {
      'ticket_status.status_name': {
        values: [
          null,
          '5', //'Escalated',
          '2', //'In Progress',
          '9', //'IT Active',
          '8', //'IT Assigned',
          '12', //'IT Feedback',
          '14', //'IT In QA',
          '11', //'IT Paused',
          '10', //'IT Planning',
          '13', //'IT QA Ready',
          '4', //'On Hold',
          '1', //'Open',
          '7', //'Project System',
          '3', //,'Waiting for Response',
        ],
        filterType: 'set',
      },
      ticketholder: {
        values: [localStorage.getItem('user_name')],
        filterType: 'set',
      },
    };
    // if (defaultMyOpenTickets && fetch_child_ticket) {
    //   defaultMyOpenTickets = {
    //     ...defaultMyOpenTickets,
    //     master_ticket_number: {
    //       filterType: 'text',
    //       type: 'contains',
    //       filter: fetch_child_ticket?.master_ticket_number,
    //     },
    //   };
    // }
    // let defaultTicketsFilter;
    // if (get_child_tickets) {
    //   defaultTicketsFilter = {
    //     'ticket_status.status_name': {
    //       values: [
    //         null,
    //         '5', //'Escalated',
    //         '2', //'In Progress',
    //         '9', //'IT Active',
    //         '8', //'IT Assigned',
    //         '12', //'IT Feedback',
    //         '14', //'IT In QA',
    //         '11', //'IT Paused',
    //         '10', //'IT Planning',
    //         '13', //'IT QA Ready',
    //         '4', //'On Hold',
    //         '1', //'Open',
    //         '7', //'Project System',
    //         '3', //,'Waiting for Response',
    //         '6', // 'Closed'
    //       ],
    //       filterType: 'set',
    //     },
    //   };
    // }
    staticFilters = [
      ...staticFilters,
      // {
      //   id: 99993,
      //   filter: 'Master Ticket',
      //   value: null,
      // },
      // {
      //   id: 99994,
      //   filter: 'Linked Tickets',
      //   value: defaultTicketsFilter,
      // },
      {
        id: 99998,
        filter: 'My Open Tickets',
        value: defaultMyOpenTickets,
      },
      {
        id: 99999,
        filter: 'Open Tickets',
        value: {
          'ticket_status.status_name': {
            values: [
              null,
              '5', //'Escalated',
              '2', //'In Progress',
              '9', //'IT Active',
              '8', //'IT Assigned',
              '12', //'IT Feedback',
              '14', //'IT In QA',
              '11', //'IT Paused',
              '10', //'IT Planning',
              '13', //'IT QA Ready',
              '4', //'On Hold',
              '1', //'Open',
              '7', //'Project System',
              '3', //,'Waiting for Response',
            ],
            filterType: 'set',
          },
        },
      },
      {
        id: 99997, ///Recently Updated
        filter: 'Recently Viewed Tickets',
        value: {},
      },
    ];
  } else if (pageType === 'tags') {
    defaultMyOpenTags = {
      tag_status_name: {
        values: [
          null,
          '1', //,'Open'
          '2', //, 'Read',
          '4', //'Returned'
        ],
        filterType: 'set',
      },
      tagholder: {
        values: [localStorage.getItem('user_name')],
        filterType: 'set',
      },
    };
    staticFilters = [
      ...staticFilters,
      {
        id: 99997,
        filter: 'My Open Tags',
        // value: {
        //   tag_status_name: {
        //     values: [
        //       null,
        //       '1', //,'Open'
        //       '2', //, 'Read',
        //       '4', //'Returned'
        //     ],
        //     filterType: 'set',
        //   },
        //   tagholder: {
        //     values: [localStorage.getItem('user_name')],
        //     filterType: 'set',
        //   },
        // },
        value: defaultMyOpenTags,
      },
    ];
  } else if (pageType === 'reminder') {
    staticFilters = [
      ...staticFilters,
      {
        id: 99996,
        filter: 'My Open Reminders',
        value: defaultMyOpenReminders,
      },
    ];
  } else if (pageType === 'promotion') {
    staticFilters = [
      ...staticFilters,
      {
        id: 99995,
        filter: 'My Leads',
        value: defaultPromotionFilter,
      },
    ];
  }
  return staticFilters;
};

export const getSavedFilterPageTypes = () => {
  return [
    'tags',
    'productivity',
    'alldepartments',
    'blockeditassign',
    'activeleads',
    'unusedleads',
    'deals',
    'people',
    'organisations',
    'tickets',
    'promotion',
    'reminder',
  ];
};

export const filterFiltersByPageType = (allSavedFilterList, pageType) => {
  let filterIndex =
    allSavedFilterList &&
    allSavedFilterList.length > 0 &&
    allSavedFilterList.findIndex((x) => x.page === pageType);

  return allSavedFilterList?.[filterIndex]?.filters
    ? allSavedFilterList?.[filterIndex]?.filters
    : [];
};
//-------START:fetchStaticFilters,getSavedFilterPageTypes,filterPageSavedFilter USED IN PAGE SAVED FILTER ----------//

// Language Translations Array

export const lang_trans_arr = [
  { id: 'en', language: 'English' },
  { id: 'ga-IE', language: 'Irish' },
  { id: 'test', language: 'Test' },
];

/**---- NEW REPORT LAYOUT USED IN API PAYLOAD AND REPORT PREVIEW------ */
export const getNewReportFormField = (data, forRequest = false) => {
  if (typeof data.record_type === 'number') {
    data.record_type = [data.record_type];
  }

  /**------------------START-REPORT Dynamic Field Criteria--------------------- */
  if (
    data.form_list.includes('organisation') &&
    JSON.stringify(data.organisation) === JSON.stringify([0])
  ) {
    data.organisation = undefined;
  }

  if (
    data.form_list.includes('people') &&
    JSON.stringify(data.people) === JSON.stringify([0])
  ) {
    data.people = undefined;
  }

  // if (data.turn_over && data?.turn_over?.length > 0) {
  //   // let newTurnOver = [data.turn_over[0] / 1000000, data.turn_over[1] / 1000000];
  //   data.turn_over = newTurnOver;
  // }
  if (data.investment_hours && data?.investment_hours?.length > 0) {
    let newInvestmentHour = [
      Math.round(data.investment_hours[0] / (60 * 60)).toFixed(0),
      Math.round(data.investment_hours[1] / (60 * 60)).toFixed(0),
    ];
    data.investment_hours = newInvestmentHour;
  }
  /**------------------END-REPORT Dynamic Field Criteria--------------------- */

  /**------------------START-DATE RELATED FIELDS--------------------- */

  if (data.date) {
    data.date = moment(data.date).format('YYYY-MM-DD');
  }
  if (data.single_date) {
    if (forRequest) {
      data.single_date = moment(data.single_date).format('YYYY-MM-DD');
    } else {
      data.single_date = moment(data.single_date);
    }
  }

  if (data.date_range) {
    if (Array.isArray(data.date_range)) {
      if (forRequest) {
        data.date_range = {
          from_date: moment(data.date_range[0]).format('YYYY-MM-DD'),
          to_date: moment(data.date_range[1]).format('YYYY-MM-DD'),
        };
      } else {
        if (data.date_range && data.date_range.length === 2) {
          data.date_range = [moment(data.date_range[0]), moment(data.date_range[1])];
        }
      }
    } else {
      if (forRequest) {
        data.date_range = {
          from_date: moment(data.date_range[0]).format('YYYY-MM-DD'),
          to_date: moment(data.date_range[1]).format('YYYY-MM-DD'),
        };
      } else {
        data.date_range = [
          moment(data.date_range.from_date),
          moment(data.date_range.to_date),
        ];
      }
    }
  }
  if (data.due_date && data.due_date.length === 2) {
    data.due_date = {
      from_date: moment(data.due_date[0]).format('YYYY-MM-DD'),
      to_date: moment(data.due_date[1]).format('YYYY-MM-DD'),
    };
  }
  if (data.created_at && Array.isArray(data.created_at) && data.created_at.length === 2) {
    data.created_at = {
      from_date: moment(data.created_at[0]).format('YYYY-MM-DD'),
      to_date: moment(data.created_at[1]).format('YYYY-MM-DD'),
    };
  } else {
    if (data.created_at && data.created_at.from_date) {
      data.created_at = [
        moment(data.created_at.from_date),
        moment(data.created_at.to_date),
      ];
    }
  }
  if (
    data.due_date_time &&
    Array.isArray(data.due_date_time) &&
    data.due_date_time.length === 2
  ) {
    data.due_date_time = {
      from_date: moment(data.due_date_time[0]).format('YYYY-MM-DD'),
      to_date: moment(data.due_date_time[1]).format('YYYY-MM-DD'),
    };
  } else {
    if (data.due_date_time && data.due_date_time.from_date) {
      data.due_date_time = [
        moment(data.due_date_time.from_date),
        moment(data.due_date_time.to_date),
      ];
    }
  }
  if (data.completed_on && data.completed_on.length === 2) {
    data.completed_on = {
      from_date: moment(data.completed_on[0]).format('YYYY-MM-DD'),
      to_date: moment(data.completed_on[1]).format('YYYY-MM-DD'),
    };
  }
  if (data.modified_at && data.modified_at.length === 2) {
    data.modified_at = {
      from_date: moment(data.modified_at[0]).format('YYYY-MM-DD'),
      to_date: moment(data.modified_at[1]).format('YYYY-MM-DD'),
    };
  }
  if (data.last_note_on && data.last_note_on.length === 2) {
    data.last_note_on = {
      from_date: moment(data.last_note_on[0]).format('YYYY-MM-DD'),
      to_date: moment(data.last_note_on[1]).format('YYYY-MM-DD'),
    };
  }
  if (data.last_conversation_on && data.last_conversation_on.length === 2) {
    data.last_conversation_on = {
      from_date: moment(data.last_conversation_on[0]).format('YYYY-MM-DD'),
      to_date: moment(data.last_conversation_on[1]).format('YYYY-MM-DD'),
    };
  }
  if (data.period_date_range) {
    let tempDateRange = [];
    data.period_date_range.map((item, index) => {
      if (item && item.length === 2) {
        tempDateRange.push({
          from_date: moment(item[0]).format('YYYY-MM-DD'),
          to_date: moment(item[1]).format('YYYY-MM-DD'),
        });
      }
      return null;
    });
    if (tempDateRange.length > 0) {
      data.period_date_range = tempDateRange;
    }
  }
  let updateComparisonData = data.report_for_comparison_mode;

  if (updateComparisonData) {
    updateComparisonData.map((obj) => {
      if (obj.date_range) {
        if (Array.isArray(obj.date_range)) {
          if (forRequest) {
            obj.date_range = {
              from_date: moment(obj.date_range[0]).format('YYYY-MM-DD'),
              to_date: moment(obj.date_range[1]).format('YYYY-MM-DD'),
            };
          } else {
            if (obj.date_range && obj.date_range.length === 2) {
              obj.date_range = [moment(obj.date_range[0]), moment(obj.date_range[1])];
            }
          }
        } else {
          if (forRequest) {
            if (Array.isArray(obj.date_range)) {
              obj.date_range = {
                from_date: moment(obj.date_range[0]).format('YYYY-MM-DD'),
                to_date: moment(obj.date_range[1]).format('YYYY-MM-DD'),
              };
            }
          } else {
            obj.date_range = [
              moment(obj.date_range.from_date),
              moment(obj.date_range.to_date),
            ];
          }
        }
      }
      if (obj.single_date) {
        if (forRequest) {
          obj.single_date = moment(obj.single_date).format('YYYY-MM-DD');
        } else {
          obj.single_date = moment(obj.single_date);
        }
      }
      return null;
    });
    data.report_for_comparison_mode = updateComparisonData;
  }
  /**------------------END-DATE RELATED FIELDS--------------------- */

  return data;
};

export const formatReportForComparisonData = (data, forRequest = false) => {
  let updateComparisonData = data;

  if (updateComparisonData) {
    updateComparisonData.map((obj) => {
      if (obj.date_range && !Array.isArray(obj.date_range)) {
        obj.date_range = [
          moment(obj.date_range.from_date),
          moment(obj.date_range.to_date),
        ];
      }
      if (obj.single_date && !obj.single_date instanceof moment) {
        obj.single_date = moment(obj.single_date);
      }
      return null;
    });
    return updateComparisonData;
  }
};

export const reportForTypeNew = [
  { value: 'all', text: 'All' },
  { value: 'today', text: 'Today' },
  { value: 'yesterday', text: 'Yesterday' },
  { value: 'single', text: 'Date' },
  { value: 'this', text: 'This' },
  { value: 'last', text: 'Last' },
  { value: 'financial_year', text: 'Financial Year' },
  { value: 'date_range', text: 'Range' },
];

export const reportSubTypeList = {
  records: [
    { value: 'sold', text: 'SOLD' },
    { value: 'tags', text: 'Tags' },
    { value: 'tickets', text: 'Tickets' },
    { value: 'reminders', text: 'Reminders' },
    { value: 'people', text: 'People' },
    { value: 'organisation', text: 'Organisation' },
    { value: 'saved_filter', text: 'Saved Filter' },
  ],
  summary: [
    { value: 'all', text: 'All' },
    { value: 'sold', text: 'SOLD' },
    { value: 'tags', text: 'Tags' },
    { value: 'tickets', text: 'Tickets' },
    { value: 'reminders', text: 'Reminders' },
    { value: 'people', text: 'People' },
    { value: 'organisation', text: 'Organisation' },
  ],
  count_list: [
    { value: 'sold', text: 'SOLD' },
  ],
  time_spent: [
    { value: 'all', text: 'All' },
    { value: 'sold', text: 'SOLD' },
    { value: 'tickets', text: 'Tickets' },
    { value: 'people', text: 'People' },
    { value: 'organisation', text: 'Organisation' },
    { value: 'others', text: 'Others' },
  ],
  relationship: [
    { value: 'sold', text: 'SOLD' },
    { value: 'tickets', text: 'Tickets' },
    { value: 'people', text: 'People' },
    { value: 'organisation', text: 'Organisation' },
  ],
};

export const reportByUserDropdown = {
  records: [
    { value: 'all', text: 'All' },
    { value: 'users', text: 'Users' },
    { value: 'owners', text: 'Owners' },
    { value: 'holders', text: 'Holders' },
    { value: 'user_group', text: 'User Groups' },
  ],
  summary: [
    { value: 'all', text: 'All' },
    { value: 'users', text: 'Users' },
    { value: 'owners', text: 'Owners' },
    { value: 'holders', text: 'Holders' },
    { value: 'user_group', text: 'User Groups' },
  ],
  count_list: [
    // { value: 'all', text: 'All' },
    { value: 'users', text: 'Users' },
    // { value: 'owners', text: 'Owners' },
    // { value: 'holders', text: 'Holders' },
    // { value: 'user_group', text: 'User Groups' },
  ],
  time_spent: [
    { value: 'all', text: 'All' },
    { value: 'users', text: 'Users' },
    { value: 'owners', text: 'Owners' },
    { value: 'holders', text: 'Holders' },
    { value: 'user_group', text: 'User Groups' },
  ],
  kpi: [
    { value: 'all', text: 'All' },
    { value: 'users', text: 'Users' },
    { value: 'owners', text: 'Owners' },
    { value: 'holders', text: 'Holders' },
    { value: 'user_group', text: 'User Groups' },
  ],
};

export const reportTypeListNewLayout = [
  { value: 'records', text: 'List' },
  { value: 'summary', text: 'Summary' },
  { value: 'count_list', text: 'Counts List' },
  { value: 'kpi', text: 'KPI' },
  { value: 'time_spent', text: 'Time Spent' },
  { value: 'relationship', text: 'Relationships' },
];

export const reportTypeValueObject = {
  1: 'List',
  2: 'Summary',
  3: 'Time Spent',
  4: 'Relationships',
  5: 'KPI',
  6: 'Count List',
};

export const reportTypeObject = {
  1: 'records',
  2: 'summary',
  3: 'time_spent',
  4: 'relationship',
  5: 'kpi',
  6: 'count_list'
};

export const summaryType = ['summary'];
export const countsListType = ['count_list'];
export const kpiReportType = ['kpi'];
export const listReportType = ['records'];
export const timeSpentReportType = ['time_spent'];
export const relationShipsReportType = ['relationship'];

export const dynamicFieldsListReport = {
  sold: [
    'sr',
    'id',
    'classification_name',
    'created_on',
    'currency_code',
    'country',
    'deadline_date',
    'financial_value',
    'turn_over',
    'idle',
    'idle_by_holder',
    'investment_hours',
    'last_communications',
    'last_conversation_on',
    'last_note',
    // 'last_sold_chat_on',
    'chat',
    'lead_emails',
    'lead_holder',
    'lead_owner',
    'lead_phones',
    'lead_record_type',
    'lead_source',
    'lead_source_details',
    'lead_status',
    'modified_date',
    'modified_by',
    'organisation_name',
    'organisation_role',
    'parent_status_name',
    'person',
    'product_name',
    'rate',
    'rejected_reason',
    'rejected_summary',
    'rejected_shortfall',
    'failed_reason',
    'failed_summary',
    'failed_shortfall',
    'future_reason',
    'sold_name',
    'total_comments',
    'total_communication',
    'total_conversation',
    'total_conversation_words',
    'total_inbound_notes',
    'total_outbound_notes',
    'total_words',
    'last_summary',
    'last_subject',
    'deals',
    // 'meetings',
    'meetings_held',
    'qualify_held',
    'traded'
  ],
  tags: [
    'sr',
    'id',
    'leads_transactions_id',
    'opportunity_name',
    'lead_owner',
    'lead_holder',
    'tag_number',
    'tag_subject',
    'tag_description',
    'ticket_subject',
    'ticket_number',
    'organisation_name',
    'deadline_date',
    'tag_status',
    'modified_date',
    'modified_by',
    'creator_name',
    'created_date',
  ],
  tickets: [
    'sr',
    'id',
    'ticket_number',
    'ticket_subject',
    'holder',
    'deadline_date',
    'ticket_status',
    'master_ticket',
    'owner',
    'modified_date',
    'modified_by',
    'creator',
    'created_date',
    'priority',
    'ticket_type',
    'ticket_description',
    'organisation_name',
    'last_note',
    'last_note_on',
    'idle_day',
    'total_notes',
  ],
  reminders: [
    'sr',
    'id',
    'reminder_type',
    'reminder_for_name',
    'receiver_name',
    'priority',
    'due_date_time',
    'status',
    'subject',
    'description',
    'created_on',
    'created_by_name',
    'event_record_type',
    'modified_by',
    // 'reminder_date',
  ],
  people: [
    'sr',
    'people_id',
    'name',
    'title',
    'people_role',
    'lead_phones',
    'lead_emails',
    'pps_number',
    'address',
    'status',
    'modified_date',
    'modified_by',
    'crm_id',
    'created_on',
    'idle',
    'investment_hours',
    'last_communications',
    'last_conversation_on',
    'last_note',
    'last_sold_chat_on',
    'person',
    'total_comments',
    'total_communication',
    'total_conversation',
    'total_conversation_words',
    'total_inbound_notes',
    'total_outbound_notes',
    'total_words',
  ],
  organisation: [
    'sr',
    'organisation_id',
    'organisation_name',
    'classification_name',
    'trade_name',
    'organisation_role_mapping',
    'registration_number',
    'registered_country',
    'status',
    'modified_date',
    'modified_by',
    'crm_id',
    'created_on',
    'org_currency',
    'lead_emails',
    'lead_phones',
    'address',
    'idle',
    'investment_hours',
    'last_communications',
    'last_conversation_on',
    'last_note',
    'last_sold_chat_on',
    'person',
    'total_comments',
    'total_communication',
    'total_conversation',
    'total_conversation_words',
    'total_inbound_notes',
    'total_outbound_notes',
    'total_words',
  ],
  saved_filter: [],
};

export const dynamicFieldsRelationshipReport = {
  sold: [
    'sr',
    'relation_type',
    'sold_name',
    'id',
    'ticket_subject',
    'ticket_number',
    'organisation',
    'person',
    'lead_owner',
    'lead_holder',
    'lead_phones',
    'lead_emails',
    'address',
    'registered_country',
    'classification_name',
    'lead_record_type',
    'lead_status',
    'parent_status_name',
    'product_name',
    'ticket_type',
    'ticket_status',
    'organisation_role',
    'currency',
    'lead_source',
    'lead_source_details',
    'turn_over',
    'financial_value',
    'rate',
    'investment_hours',
    'deadline_date',
    'ticket_description',
    'idle',
    'idle_by_holder',
    'last_communications',
    'created_on',
    'last_sold_chat_on',
    'last_conversation_on',
  ],
  tickets: [
    'sr',
    'relation_type',
    'sold_name',
    'id',
    'ticket_subject',
    'ticket_number',
    'organisation',
    'person',
    'lead_owner',
    'lead_holder',
    'lead_phones',
    'lead_emails',
    'address',
    'registered_country',
    'classification_name',
    'lead_record_type',
    'lead_status',
    'parent_status_name',
    'product_name',
    'ticket_type',
    'ticket_status',
    'organisation_role',
    'currency',
    'lead_source',
    'lead_source_details',
    'turn_over',
    'financial_value',
    'rate',
    'investment_hours',
    'deadline_date',
    'ticket_description',
    'idle',
    'last_communications',
    'created_on',
    'last_sold_chat_on',
    'last_conversation_on',
  ],
  people: [
    'sr',
    'relation_type',
    'sold_name',
    'id',
    'ticket_subject',
    'ticket_number',
    'organisation',
    'person',
    'lead_owner',
    'lead_holder',
    'lead_phones',
    'lead_emails',
    'address',
    'registered_country',
    'classification_name',
    'lead_record_type',
    'lead_status',
    'parent_status_name',
    'product_name',
    'ticket_type',
    'ticket_status',
    'organisation_role',
    'currency',
    'lead_source',
    'lead_source_details',
    'turn_over',
    'financial_value',
    'rate',
    'investment_hours',
    'deadline_date',
    'ticket_description',
    'idle',
    'last_communications',
    'created_on',
    'last_sold_chat_on',
    'last_conversation_on',
  ],
  organisation: [
    'sr',
    'relation_type',
    'sold_name',
    'id',
    'ticket_subject',
    'ticket_number',
    'organisation',
    'person',
    'lead_owner',
    'lead_holder',
    'lead_phones',
    'lead_emails',
    'address',
    'registered_country',
    'classification_name',
    'lead_record_type',
    'lead_status',
    'parent_status_name',
    'product_name',
    'ticket_type',
    'ticket_status',
    'organisation_role',
    'currency',
    'lead_source',
    'lead_source_details',
    'turn_over',
    'financial_value',
    'rate',
    'investment_hours',
    'deadline_date',
    'ticket_description',
    'idle',
    'last_communications',
    'created_on',
    'last_sold_chat_on',
    'last_conversation_on',
  ],
};
export const reportDynamicFieldsListColumnConfig = {
  sr: {
    title: '#',
    filtertype: 'sr',
  },
  id: {
    title: 'ID',
    filtertype: 'text',
  },
  people_id: {
    title: 'ID',
    filtertype: 'text',
  },
  organisation_id: {
    title: 'ID',
    filtertype: 'text',
  },
  classification_name: {
    title: 'Classification',
    filtertype: 'columnfilter',
  },
  created_on: {
    title: 'Created On',
    filtertype: 'date',
  },
  currency_code: {
    title: 'Currency',
    filtertype: 'columnfilter',
  },
  org_currency: {
    title: 'Org Currency',
    filtertype: 'columnfilter',
  },
  deleted_at: {
    title: 'Deleted On',
    filtertype: 'date',
  },
  financial_value: {
    title: 'Finance',
    filtertype: 'number',
  },
  idle: {
    title: 'Idle',
    filtertype: 'text',
  },
  idle_by_holder: {
    title: 'Idle By Holder',
    filtertype: 'text',
  },
  investment_hours: {
    title: 'Spent',
    filtertype: 'text',
  },
  last_communications: {
    title: 'Communication',
    filtertype: 'text',
  },
  last_conversation_on: {
    title: 'Conversation On',
    filtertype: 'date',
  },
  last_sold_chat_on: {
    title: 'Last Chat On',
    filtertype: 'date',
  },
  chat: {
    title: 'Chat',
    filtertype: 'number',
  },
  lead_emails: {
    title: 'Email',
    filtertype: 'text',
  },
  lead_holder: {
    title: 'Holder',
    filtertype: 'columnfilter',
  },
  lead_owner: {
    title: 'Owner',
    filtertype: 'columnfilter',
  },
  lead_phones: {
    title: 'Phone',
    filtertype: 'text',
  },
  lead_record_type: {
    title: 'Record Type',
    filtertype: 'columnfilter',
  },
  lead_source: {
    title: 'Source',
    filtertype: 'columnfilter',
  },
  lead_source_details: {
    title: 'Source Details',
    filtertype: 'columnfilter',
  },
  lead_status: {
    title: 'Status',
    filtertype: 'columnfilter',
  },
  modified_date: {
    title: 'Modified On',
    filtertype: 'date',
  },
  modified_by: {
    title: 'Modified By',
    filtertype: 'columnfilter',
  },
  organisation_name: {
    title: 'Organisation',
    filtertype: 'columnfilter',
  },
  organisation_role: {
    title: 'Org Type',
    filtertype: 'columnfilter',
  },
  parent_status_name: {
    title: 'Stage',
    filtertype: 'columnfilter',
  },
  person: {
    title: 'People',
    filtertype: 'columnfilter',
  },
  product_name: {
    title: 'Product',
    filtertype: 'columnfilter',
  },
  rate: {
    title: 'Rate',
    filtertype: 'number',
  },
  rejected_reason: {
    title: 'Rejected Reason',
    filtertype: 'text',
  },
  rejected_summary: {
    title: 'Rejected Summary',
    filtertype: 'text',
  },
  rejected_shortfall: {
    title: 'Rejected Shortfall',
    filtertype: 'text',
  },
  failed_reason: {
    title: 'Failed Reason',
    filtertype: 'text',
  },
  failed_summary: {
    title: 'Failed Summary',
    filtertype: 'text',
  },
  failed_shortfall: {
    title: 'Failed Shortfall',
    filtertype: 'text',
  },
  future_reason: {
    title: 'Future Reason',
    filtertype: 'text',
  },
  sold_name: {
    title: 'SOLD Name',
    filtertype: 'text',
  },
  total_comments: {
    title: 'Comments',
    filtertype: 'number',
  },
  total_communication: {
    title: 'Communications',
    filtertype: 'number',
  },
  total_conversation: {
    title: 'Conversations',
    filtertype: 'number',
  },
  total_conversation_words: {
    title: 'Conv. Words',
    filtertype: 'number',
  },
  total_inbound_notes: {
    title: 'Inbound Notes',
    filtertype: 'number',
  },
  total_outbound_notes: {
    title: 'Outbound Notes',
    filtertype: 'number',
  },
  total_words: {
    title: 'Words',
    filtertype: 'number',
  },
  turn_over: {
    title: 'Turn Over',
    filtertype: 'number',
  },
  sold_id: {
    title: 'SOLD ID',
    filtertype: 'number',
  },
  owner: {
    title: 'Owner',
    filtertype: 'columnfilter',
  },
  holder: {
    title: 'Holder',
    filtertype: 'columnfilter',
  },
  tag_number: {
    title: 'Tag #',
    filtertype: 'text',
  },
  tag_subject: {
    title: 'Subject',
    filtertype: 'text',
  },
  tag_description: {
    title: 'Description',
    filtertype: 'text',
  },
  ticket_subject: {
    title: 'Ticket Subject',
    filtertype: 'text',
  },
  ticket_number: {
    title: 'Ticket Number',
    filtertype: 'text',
  },
  organisation: {
    title: 'Organisation',
    filtertype: 'text',
  },
  deadline_date: {
    title: 'Deadline',
    filtertype: 'columnfilter',
  },
  tag_status: {
    title: 'Status',
    filtertype: 'columnfilter',
  },
  creator: {
    title: 'Created By',
    filtertype: 'columnfilter',
  },
  creator_name: {
    title: 'Created By',
    filtertype: 'columnfilter',
  },
  created_date: {
    title: 'Created On',
    filtertype: 'date',
  },
  leads_transactions_id: {
    title: 'SOLD ID',
    filtertype: 'number',
  },

  ticket_status: {
    title: 'Status',
    filtertype: 'columnfilter',
  },
  master_ticket: {
    title: 'Master Ticket #',
    filtertype: 'columnfilter',
  },

  priority: {
    title: 'Priority',
    filtertype: 'columnfilter',
  },
  ticket_type: {
    title: 'Ticket Type',
    filtertype: 'columnfilter',
  },
  ticket_description: {
    title: 'Description',
    filtertype: 'text',
  },
  last_note: {
    title: 'Note',
    filtertype: 'text',
  },
  last_note_on: {
    title: 'Note On',
    filtertype: 'date',
  },
  idle_day: {
    title: 'Idle Since',
    filtertype: 'text',
  },
  total_notes: {
    title: 'Notes',
    filtertype: 'text',
  },
  reminder_type: {
    title: 'Type',
    filtertype: 'columnfilter',
  },

  due_date_time: {
    title: 'Due Date Time',
    filtertype: 'date',
  },

  status: {
    title: 'Status',
    filtertype: 'columnfilter',
  },
  subject: {
    title: 'Subject',
    filtertype: 'text',
  },
  description: {
    title: 'Description',
    filtertype: 'text',
  },
  created_at: {
    title: 'Created On',
    filtertype: 'date',
  },
  created_by_name: {
    title: 'Created By Name',
    filtertype: 'columnfilter',
  },
  event_record_type: {
    title: 'Record Type',
    filtertype: 'columnfilter',
  },
  reminder_date: {
    title: 'Reminder Date',
    filtertype: 'date',
  },
  reminder_for_name: {
    title: 'Reminder For',
    filtertype: 'text',
  },
  receiver_name: {
    title: 'Assigned To',
    filtertype: 'columnfilter',
  },
  name: {
    title: 'Name',
    filtertype: 'text',
  },
  title: {
    title: 'Title',
    filtertype: 'columnfilter',
  },

  people_role: {
    title: 'People Type',
    filtertype: 'columnfilter',
  },

  pps_number: {
    title: 'PPS No.',
    filtertype: 'text',
  },
  address: {
    title: 'Address',
    filtertype: 'text',
  },
  crm_id: {
    title: 'Exchange Ref ID',
    filtertype: 'text',
  },
  trade_name: {
    title: 'Trade Name',
    filtertype: 'text',
  },
  registration_number: {
    title: 'Reg. No.',
    filtertype: 'text',
  },
  registered_country: {
    title: 'Country',
    filtertype: 'columnfilter',
  },
  country: {
    title: 'Country',
    filtertype: 'columnfilter',
  },
  people_currency: {
    title: 'Currency',
    filtertype: 'columnfilter',
  },
  relation_type: {
    title: 'Relation Type',
    filtertype: 'text',
  },
  last_summary: {
    title: 'Last Summary',
    filtertype: 'text',
  },
  last_subject: {
    title: 'Last Subject',
    filtertype: 'text',
  },
  deals: {
    title: 'Deals',
    filtertype: 'number',
  },
  meetings: {
    title: 'Meetings',
    filtertype: 'number',
  },
  meetings_held: {
    title: 'Meeting Held',
    filtertype: 'number',
  },
  qualify_held: {
    title: 'Qualify Held',
    filtertype: 'number',
  },
  traded: {
    title: 'Traded',
    filtertype: 'number',
  },
  organisation_role_mapping: {
    title: 'Organisation Role Mapping',
    filtertype: 'columnfilter',
  }
};
export const defaultFieldsListReport = {
  sold: [
    'id',
    'idle',
    'idle_by_holder',
    'lead_holder',
    'lead_owner',
    'lead_record_type',
    'lead_status',
    'modified_date',
    'organisation_name',
    'organisation_role',
    'parent_status_name',
    'person',
    'sold_name',
    'financial_value',
    'turn_over',
  ],
  tags: [
    'sr',
    'id',
    'lead_owner',
    'lead_holder',
    'tag_number',
    'tag_subject',
    'tag_status',
    'modified_date',
  ],
  tickets: [
    'sr',
    'id',
    'ticket_number',
    'ticket_subject',
    'holder',
    'ticket_status',
    'owner',
    'modified_date',
    'organisation_name',
  ],
  reminders: [
    'id',
    'reminder_type',
    'reminder_for_name',
    'receiver_name',
    'due_date_time',
    'status',
    'subject',
    'created_on',
  ],
  people: ['sr', 'id', 'people_role', 'modified_date', 'idle', 'name'],
  organisation: [
    'sr',
    'id',
    'organisation_name',
    'organisation_role_mapping',
    'registered_country',
    'modified_date',
    'idle',
  ],
  saved_filter: [],
};

export const getReportOnFromSavedFilterPageType = {
  tags: 'tags',
  reminder: 'reminders',
  tickets: 'tickets',
  organisations: 'organisation',
  people: 'people',
};
export const getDateFilterOnReportList = {
  sold: [
    { value: 'created', text: 'Created' },
    { value: 'modified', text: 'Modified' },
    { value: 'deadline', text: 'Deadline' },
    { value: 'last_note', text: 'Last Note' },
    { value: 'last_conversation', text: 'Last Conversation' },
  ],
  tags: [
    { value: 'created', text: 'Created' },
    { value: 'modified', text: 'Modified' },
    { value: 'deadline', text: 'Deadline' },
  ],
  tickets: [
    { value: 'created', text: 'Created' },
    { value: 'modified', text: 'Modified' },
    { value: 'deadline', text: 'Deadline' },
    { value: 'last_note', text: 'Last Note' },
    { value: 'last_conversation', text: 'Last Conversation' },
  ],
  reminders: [
    { value: 'created', text: 'Created' },
    { value: 'modified', text: 'Modified' },
    { value: 'due_date', text: 'Due Date' },
  ],
  people: [
    { value: 'created', text: 'Created' },
    { value: 'modified', text: 'Modified' },
    { value: 'last_note', text: 'Last Note' },
    { value: 'last_conversation', text: 'Last Conversation' },
  ],
  organisation: [
    { value: 'created', text: 'Created' },
    { value: 'modified', text: 'Modified' },
    { value: 'last_note', text: 'Last Note' },
    { value: 'last_conversation', text: 'Last Conversation' },
  ],
};
/**---- NEW REPORT LAYOUT USED IN API PAYLOAD AND REPORT PREVIEW------ */

/**-----------------USED TO CAPITALIZE STRING'S FIRST CHARACTER------------- */
export const capitalize = (text) => {

  text = text.replaceAll('_', ' ');
  var words = text.split(' ');
  var CapitalizedWords = [];

  words.forEach((element) => {
    if (element[0]) {
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    }
  });



  return CapitalizedWords.join(' ');
};
/**-----------------USED TO CAPITALIZE STRING'S FIRST CHARACTER------------- */

export const getSssNameByTypeID = (type) => {
  if (type === 1) {
    return 'Structure';
  } else if (type === 3) {
    return 'Screen';
  } else if (type === 2) {
    return 'Schedule';
  } else if (type === 4) {
    return 'Scrutinise';
  } else if (type === 5) {
    return 'Shortfall';
  }
};

export const historyCurrentValues = [
  { text: 'History', value: true },
  { text: 'Current', value: false },
];
export const holderModifiedValues = [
  { text: 'By Holder', value: true },
  { text: 'By Modified', value: false },
]

export const focusArray = ['Trade', 'People', 'Organisation', 'Transaction'];
