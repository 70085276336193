import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, PageHeader, Space } from 'antd';

import Unauthorized from '../../Unauthorized';
import useStore from '../../../store';
import EnterFullscreen from '../../../component/EnterFullscreen';
import RecordPerPage from '../../../config/RecordPerPage';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import ListComponent from './components/ListComponent';
import DeleteComponent from './components/DeleteComponent';
import GlobalStatusChangeComponent from './elements/GlobalStatusChangeComponent';
import ConfirmFavReportElement from './elements/ConfirmFavReportElement';
import PreviewDataComponent from './components/PreviewDataComponent';
import EditComponent from './components/EditComponent';
import CloneComponent from './components/CloneComponent';
import AddComponent from './components/AddComponent';
import { useTranslation } from 'react-i18next';
import ResetGridOptions from '../../../component/ResetGridOptions';
import TimeTracker from '../../../component/TimeTracker';

const SavedReportsNew = observer(() => {
  /*--STATE USED IN PREVIEW REPORT--*/
  const [report_id, setReportID] = useState(false);
  const [previewDataModal, setPreviewDataModal] = useState(false);
  const [previewFromList, setpreviewFromList] = useState(false);
  const [previewFromClone, sepreviewFromClone] = useState(false);
  const [previewFromEditDrawer, setpreviewFromEditDrawer] = useState(false);
  const [editData, setEditData] = useState(null);

  /*--EDIT REPORT STATE--*/
  const [editModal, setEditModal] = useState(false);
  /*--ADD REPORT STATE--*/
  const [addModal, setAddModal] = useState(false);
  /*--DELETE REPORT STATE--*/
  const [deleteModal, setDeleteModal] = useState(false);
  /*--CLONE REPORT STATE--*/
  const [cloneModal, setCloneModal] = useState(false);

  /*--TOGGLE FAV REPORT SWITCH STATE--*/
  const [toogleFilterVisible, setToogleFilterVisible] = useState(false);
  const [toggleswichvalue, setToggleswichvalue] = useState(false);
  /*--FAVE REPORT STATE--*/
  const [confirmFavReport, setConfirmFavReport] = useState(false);

  const { t } = useTranslation();
  const pageFilterType = 'reports';

  const {
    AUTH,
    REPORTSTORE: {
      per_page,
      setPageSize,
      setDeleteValues,
      agGrid,
      setGlobalStatusTempData,
      setCreated_by_id,
      setTargetKeys,
    },
    SETTINGS,
  } = useStore();

  /*------------ADD REPORT FUNCTION --------------- */
  const openAddModal = () => {
    setAddModal(true);
    setCreated_by_id(null);
  };

  const closeAddModal = () => setAddModal(false);
  /*------------ADD REPORT FUNCTION --------------- */

  /*------------DELETE REPORT FUNCTION --------------- */
  const openDeleteModal = (data) => {
    setDeleteValues(data);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => setDeleteModal(false);
  /*------------DELETE REPORT FUNCTION --------------- */

  /*------------FAV REPORT - TOGGLE RELATED FUNCTIONS--------------- */
  const onSwitchChange = (checked, data) => {
    agGrid.api.refreshCells({ force: true });

    setGlobalStatusTempData(data);
    setToogleFilterVisible(true);
    setToggleswichvalue(checked);
  };
  const closeToogleFilter = () => {
    setGlobalStatusTempData(null);
    setToogleFilterVisible(false);
    setToggleswichvalue(null);
  };
  /*------------FAV REPORT - TOGGLE RELATED FUNCTIONS--------------- */

  /*------------FAV REPORT - MODAL CONFIRM HANDLE--------------- */
  const onFavReportChange = (data) => {
    setGlobalStatusTempData(data);
    setConfirmFavReport(true);
  };

  const closeFavReport = () => {
    setGlobalStatusTempData(null);
    setConfirmFavReport(false);
  };
  /*------------FAV REPORT - MODAL CONFIRM HANDLE--------------- */

  /*------------CLONE REPORT - FUNCTIONS--------------- */
  const openCloneModal = (id) => {
    setCloneModal(true);
    setReportID(id);
    setCreated_by_id(null);
  };

  const closeCloneModal = () => setCloneModal(false);
  /*------------CLONE REPORT - FUNCTIONS--------------- */

  /*------------EDIT REPORT - FUNCTIONS--------------- */
  const openEditModal = (id, data) => {
    setEditModal(true);
    setReportID(id);
    setEditData(data);
  };

  const closeEditModal = () => {
    setEditModal(false);
    setReportID(false);
    setEditData(null);
  };
  /*------------EDIT REPORT - FUNCTIONS--------------- */

  /*------------PREVIEW REPORT - FUNCTIONS--------------- */
  const openPreviewDataModal = useCallback(
    (id, isEdit = false, isList = false, isClone = false) => {
      setCloneModal(false);
      setAddModal(false);
      setEditModal(false);
      setpreviewFromList(isList);
      sepreviewFromClone(isClone);
      setpreviewFromEditDrawer(isEdit);
      setPreviewDataModal(true);
      setReportID(id);
    },
    [setPreviewDataModal, setReportID, setAddModal, setEditModal]
  );

  const closePreviewDataModal = (close_all = true) => {
    setPreviewDataModal(false);
    sepreviewFromClone(false);
    setpreviewFromList(false);

    setTargetKeys([]);
    setpreviewFromEditDrawer(false);
    setReportID(false);
    if (close_all) {
      setAddModal(false);
      setEditModal(false);
      setCloneModal(false);
    }
  };
  /*------------PREVIEW REPORT - FUNCTIONS--------------- */

  return (
    <>
      {!AUTH.checkPermission(21, 'list') ? (
        <Unauthorized />
      ) : (
        <>
          <PageHeader
            title={t('Reports.Reports')}
            className={'page-title ' + SETTINGS.fullscreen_class}
            extra={[
              <Form className='innerForm headerButton'>
                <Space align='center' size={5}>
                  {AUTH.checkPermission(21, 'add') && (
                    <Button shape='round' key='2' onClick={openAddModal}>
                      {t('Reports.Add_Report')}
                    </Button>
                  )}
                  <ResetGridOptions key={'2'} pageType={pageFilterType} />
                  {/* <Button
                    key='2'
                    title={`${t('Common_Data.Reset')}`}
                    shape='round'
                    size='medium'
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faUndo} />
                  </Button> */}
                  <EnterFullscreen key='3' />
                  <RecordPerPage
                    key='4'
                    initialValue={per_page + ' per page'}
                    onChange={setPageSize}
                  />
                </Space>
              </Form>,
            ]}
          >
            <ListComponent
              openDeleteModal={openDeleteModal}
              onSwitchChange={onSwitchChange}
              onFavReportChange={onFavReportChange}
              openCloneModal={openCloneModal}
              openPreviewDataModal={openPreviewDataModal}
              openEditModal={openEditModal}
            />

            {addModal && (
              <AddComponent
                visible={addModal}
                close={closeAddModal}
                openPreviewDataModal={openPreviewDataModal}
              />
            )}

            {cloneModal && (
              <CloneComponent
                report_id={report_id}
                setReportID={setReportID}
                openPreviewDataModal={openPreviewDataModal}
                visible={cloneModal}
                close={closeCloneModal}
              />
            )}

            {editModal && (
              <EditComponent
                report_id={report_id}
                setReportID={setReportID}
                openPreviewDataModal={openPreviewDataModal}
                visible={editModal}
                editData={editData}
                close={closeEditModal}
              />
            )}

            {previewDataModal && (
              <PreviewDataComponent
                addModal={addModal}
                editModal={editModal}
                cloneModal={cloneModal}
                report_id={report_id}
                previewFromEditDrawer={previewFromEditDrawer}
                previewFromList={previewFromList}
                previewFromClone={previewFromClone}
                setReportID={setReportID}
                visible={previewDataModal}
                close={closePreviewDataModal}
                openCloneModal={openCloneModal}
              />
            )}
            {deleteModal && (
              <DeleteComponent visible={deleteModal} close={closeDeleteModal} />
            )}
            <GlobalStatusChangeComponent
              visible={toogleFilterVisible}
              close={closeToogleFilter}
              toggleswichvalue={toggleswichvalue}
            />
            <ConfirmFavReportElement visible={confirmFavReport} close={closeFavReport} />

              {/* <TrackTimeComponent pageName={'reports'} /> */}
              <TimeTracker />
          </PageHeader>
        </>
      )}
    </>
  );
});

export default SavedReportsNew;
