import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { vsmCommon } from '../../../config/messages';
import useStore from '../../../store';
import { Button } from 'antd';
import SwitchComponent from './SwitchComponent';
import LocalGridConfig from '../../../config/LocalGridConfig';
import EmailCellRender from '../elements/EmailCellRender';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { t } = useTranslation()
  const { onSwitchChange } = props;
  const {
    USERS,
    USERS: { list_data, getList, setupGrid, onFilterChanged, onGridChanged },
    AUTH,
    AUTH: { user },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  const ActionRenderer = (params) => {
    return (
      <div className='action-column'>
        {AUTH.checkPermission(27, 'profile-update') && (
          <Button
            type='text'
            title={`${t('Common_Data.Update')} ${t('Users.Profile')}`}
            onClick={() => props.openProfileEditModal(params.data)}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Updated')} />
          </Button>
        )}
        {AUTH.checkPermission(27, 'reset-password') && (
          <Button
            type='text'
            title={`${t('Common_Data.Reset')} ${t('Users.Password')}`}
            onClick={() => props.resetPassModal(params.data)}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Lock_Note')} />
          </Button>
        )}
        {AUTH.checkPermission(27, 'user-settings') && (
          <Button
            type='text'
            title={`${t('Common_Data.Settings')}`}
            onClick={() => props.openSettingDrawer(params.data)}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Path')} />
          </Button>
        )}
        {AUTH.checkPermission(27, 'set-target') && (
          <Button
            type='text'
            title={`${t('Users.Set')} ${t('OrganisationManagement.Sales')} ${t('Common_Data.Target')}`}
            onClick={() => props.openSalesDrawer(params.data)}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('target')} />
          </Button>
        )}

        {AUTH.checkPermission(27, 'proxy-login') &&
          user.user_role &&
          params.data &&
          user.user_role.user_role_id === 1 &&
          params.data.status === 'Active' &&
          params.data.deleted_at === null &&
          user.id !== params.data.id ? (
          <Button
            type='text'
            title={`${t('Users.Proxy')}  ${t('Users.Login')}`}
            onClick={() => props.openProxyDrawer(params.data)}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Proxy_login')} />
          </Button>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    if (user.user_role.user_role_id === 1) {
      getList({}, true);
    } else {
      getList({});
    }
  }, [getList, user.user_role.user_role_id]);

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.First')} ${t('Common_Data.Name')}`,
        field: 'first_name',
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Name')}`,
        field: 'last_name',
      },

      {
        headerName: `${t('Common_Data.Role')}`,
        field: 'user_role.role_name',
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Users.Login')}`,
        field: 'last_login_at',
        cellRenderer: (params) => {
          return (
            params.data.last_login_at && AUTH.getLocalTime(params.data.last_login_at,AUTH.global_fulldate_format)
          );
        },
      },

      {
        headerName: `${t('Common_Data.Email_ID')}`,
        headerTooltip: `${t('Common_Data.Email_ID')}`,
        field: 'email',
        cellClass: 'cellClassDropDown',
        filter: 'agTextColumnFilter',
        cellRendererFramework: function (data) {
          return <EmailCellRender data={data} />;
        },
      },

      {
        headerName: `${t('Common_Data.Status')}`,
        cellRendererFramework: function (data) {
          const { onSwitchChange } = data.agGridReact.props;
          return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />;
        },

        field: 'status',
        cellClass: 'cellClass statusColumn',
        sortable: false,
        filter: user.user_role.user_role_id === 1 ? 'agSetColumnFilter' : false,
        filterParams: {
          values: ['Active', 'Inactive'],
          defaultToNothingSelected: true,
        },
      },
      {
        headerName: `# ${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',
        // lockPosition: true,
        // suppressNavigable: true,

        width: 90,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',
        cellStyle: { textAlign: 'left', padding: '0 15px' },
        lockPosition: true,
        suppressNavigable: true,
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('users_list_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    item.headerTooltip = item.headerName;
    item.tooltipField = item.field;

    return item;
  });

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 13rem)',
      }}
    >
      <AgGridReact
        rowData={list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={LocalGridConfig.defaultColDef}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        //colResizeDefault={'shift'}
        frameworkComponents={{
          ActionRenderer,
          emailCellRender: EmailCellRender,
        }}
        onGridReady={setupGrid}
        onSwitchChange={onSwitchChange}
        gridOptions={{
          ...LocalGridConfig.options,
          pagination: true,
          rowHeight: 30,
        }}
        onColumnResized={onGridChanged}
        onColumnMoved={onGridChanged}
        onColumnPinned={onGridChanged}
        onFilterChanged={onFilterChanged}
        onSortChanged={onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        isRowSelectable={function (rowNode) {
          return (
            USERS.superUser &&
            !USERS.superUser.includes(rowNode.data.id) &&
            ((USERS.user.id === 1 && rowNode.data && rowNode.data.id !== 1) ||
              (USERS.user.id !== 1 &&
                rowNode.data &&
                rowNode.data.user_role &&
                rowNode.data.user_role.user_role_id !== 1)
              ? true
              : false)
          );
        }}
      />
    </div>
  );
});

export default ListComponent;
