import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../../../store';
import { ActionRenderer } from './GridComponent';
import LocalGridConfig from '../../../../../../config/LocalGridConfig';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { vsmCommon } from '../../../../../../config/messages';
import { useTranslation } from 'react-i18next';
//import { ConsoleSqlOutlined } from '@ant-design/icons';

const ListComponent = observer((props) => {
  const {t} = useTranslation()
  const {
    currentTab,
    openEditModal,
    openDeleteModal,
    openMapBankAccount,
    ConnectionModule,
    updateConnection
  } = props;
  const { PEOPLE } = useStore();

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',
        maxWidth: 100,
  
      },
      {
        headerName: `${t('Common_Data.People')} ${t('Common_Data.Name')}`,
        field: 'entity_name',
  
      },
      {
        headerName: `${t('PeopleManagement.Role')}`,
        field: 'role_name',
  
      },
      {
        headerName: `${t('Common_Data.Title')}`,
        field: 'title',
  
      },
      {
        headerName: `${t('Common_Data.Mobile')}`,
        field: 'mobile',
        valueGetter: (params) =>
          params.data && params.data.view_phone
            ? params.data.view_phone.map((x) => x.contact_value).join(', ')
            : null,
  
      },
      {
        headerName: `${t('Common_Data.Email')}`,
        field: 'email',
        valueGetter: (params) =>
          params.data && params.data.view_email
            ? params.data.view_email.map((x) => x.contact_value).join(', ')
            : null,
  
      },
      {
        headerName: `${t('PeopleManagement.PPS')} ${t('Common_Data.Number')}`,
        field: 'pps_number',
  
      },
      {
        headerName: `${t('Common_Data.Address')}`,
        field: 'address',
        valueGetter: (params) =>
          params.data && params.data.view_addresses
            ? params.data.view_addresses.address_1 +
            (params.data.view_addresses.address_2
              ? ', ' + params.data.view_addresses.address_2
              : '') +
            (params.data.view_addresses.address_3
              ? ', ' + params.data.view_addresses.address_3
              : '') +
            (params.data.view_addresses.city
              ? ', ' + params.data.view_addresses.city
              : '') +
            (params.data.view_addresses.postal_code
              ? ', ' + params.data.view_addresses.postal_code
              : '')
            : null,
  
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        maxWidth: 120,
        filter: false,
        sortable: false,
        pinned: 'right',
      },
    ],
  };
  
  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper'
        style={{ height: '250px', minHeight: '250px' }}
      >
        <AgGridReact
          //domLayout='autoHeight'
          pagination={false}
          rowData={PEOPLE.list_related_Ppl}
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={LocalGridConfig.defaultColDef}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            currentTab,
            openEditModal,
            openDeleteModal,
            openMapBankAccount,
            ConnectionModule,
            updateConnection
          }}
          onGridReady={PEOPLE.setupGridRelatedOrganisation}
          gridOptions={{ ...LocalGridConfig.options, pagination: true, rowHeight: 30 }}
          onColumnResized={PEOPLE.onGridChangedRelatedPeople}
          onColumnMoved={PEOPLE.onGridChangedRelatedPeople}
          onColumnPinned={PEOPLE.onGridChangedRelatedPeople}
          onFilterChanged={PEOPLE.onFilterChangedRelatedPeople}
          onSortChanged={PEOPLE.onGridChangedRelatedPeople}
          rowSelection='multiple'
          suppressRowClickSelection={true}
        />
      </div>
    </>
  );
});

export default ListComponent;
