// import React, { useEffect } from "react";
// import { observer } from "mobx-react";
// import DisplayMsgList from "../component/DisplayMsgList";
// import Footer from "../component/footer";
// import useStore from "../../../../store";
// import { Spin } from "antd";

// const Summary = observer((props) => {
//   let themecolor = localStorage.getItem("app_color_theme");
//   if (themecolor && themecolor !== "undefined") {
//   } else {
//     themecolor = "gray";
//   }

//   const {
//     DEALDESKSTORE: { deal_data },
//     DEALDESKNOTESEQUENCE: { dataValue, noteLoader },
//   } = useStore();

//   const summaryList = true;

//   const { openExpandModalSum, openModalSum, close, open } = props;

//   let dealid = deal_data.lead_information && deal_data.lead_information.id;

//   return (
//     <div
//       className="displayBoxWithFooter"
//       style={{ position: "relative" }}
//     >
//       <Spin
//         size="large"
//         spinning={noteLoader}
//         style={{
//           textAlign: "center",
//           padding: "50px 30px",
//         }}
//       >
//         <DisplayMsgList
//           msgBoxArray={props.filteredResultSum}
//           type="summary"
//           dealid={dealid}
//         />
//       </Spin>
//       {!open && (
//         <div style={{ position: "fixed", bottom: "16px"}}>
//           <Footer
//             msgBoxArray={{ get: props.filteredResultSum, set: dataValue }}
//             openExpandModalSum={openExpandModalSum}
//             openModalSum={openModalSum}
//             close={close}
//             summaryList={summaryList}
//           />
//         </div>
//       )}
//     </div>
//   );
// });

// export default Summary;

import React, { useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import DisplayMsgList from "../component/DisplayMsgList";
import Footer from "../component/footer";
import useStore from "../../../../store";
import { Spin } from "antd";

const Summary = observer((props) => {
  const [summeryWidth, setSummeryWidth] = useState(0);

  const updateWidth = () => {
    // setTimeout(() => {
      const parent = document.querySelector(".Dealdesk_MainTab");
      if (parent) {
        setSummeryWidth(parent.offsetWidth);
      }
    // }, 0); // Ensures DOM is ready
  };

  useLayoutEffect(() => {
    updateWidth(); // Set width on mount

    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const {
    DEALDESKSTORE: { deal_data },
    DEALDESKNOTESEQUENCE: { dataValue, noteLoader },
  } = useStore();

  const { openExpandModalSum, openModalSum, close, open } = props;
  let dealid = deal_data.lead_information?.id;

  return (
    <div className="displayBoxWithFooter" id="displayBoxWithFooterId" style={{ position: "relative" }}>
      <Spin
        size="large"
        spinning={noteLoader}
        style={{ textAlign: "center", padding: "50px 30px" }}
      >
        <DisplayMsgList msgBoxArray={props.filteredResultSum} type="summary" dealid={dealid} />
      </Spin>
      {!open && (
        <div
          style={{
            position: "fixed",
            bottom: "16px",
            width: `${summeryWidth}px`,
          }}
        >
          <Footer
            msgBoxArray={{ get: props.filteredResultSum, set: dataValue }}
            openExpandModalSum={openExpandModalSum}
            openModalSum={openModalSum}
            close={close}
          />
        </div>
      )}
    </div>
  );
});

export default Summary;
