import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Input, Select, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { vsmNotify, vsmTransactionFields } from '../../../../config/messages';
import InputComponent from '../../../../component/InputComponent';
import { convertError } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const {
    AUTH,
    TransactionFieldStore: {
      EditData,
      editValues,
      dropdown_type_list,
      dropdown_algorithm_list,
    },
    DEAL: { getCurrencyList },
  } = useStore();
  const [fieldType, setFieldType] = useState();
  const [currencyList, setCurrencyList] = useState();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [fieldIsCSV, setFieldIsCSV] = useState(false);
  const [fieldIsDate, setFieldIsDate] = useState(false);

  // Made a fuction to call to edit record
  const handleSubmit = (data) => {
    // setSaving(true);
    data.default_value = data?.rpg_values?.map((x) => x.default_value);
    data.rpa_group = data?.rpg_values?.map((x) => x.rpa_group);
    delete data?.rpg_values;
    data.id = editValues.id;
    EditData(data)
      .then(() => {
        props.close();
        vsmNotify.success({
          message: vsmTransactionFields.edit,
        });
        form.resetFields();
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.includes('default_value.')) {
              x.name = 'rpg_values.' + x.name.split('.')[1] + '.default_value';
            }
          });
          form.setFields(convertError(e.errors));
        }
      })
      .finally(() => setSaving(false));
  };

  // Set the form values to edit
  useEffect(() => {
    if (editValues) {
      if (editValues.field_type === 'CSV Column' || editValues.field_type === 'Date') {
        setFieldIsCSV(true);
        setFieldIsDate(true);
      } else {
        setFieldIsCSV(false);
        setFieldIsDate(false);
      }
      let check =
        dropdown_algorithm_list &&
        editValues.get_algorithm_name &&
        dropdown_algorithm_list.find(
          (item) => item.id === editValues.get_algorithm_name.id
        );
      const rpg_values = [];
      if (editValues.rpa_group) {
        const default_values = editValues.default_value
          .replace(/^#+|#+$/gm, '')
          .split('#');
        editValues.rpa_group
          .replace(/^#+|#+$/gm, '')
          .split('#')
          .forEach((x, index) => {
            rpg_values.push({ rpa_group: x, default_value: default_values[index] });
          });
      } else {
        rpg_values.push(null);
      }
      setFieldType(editValues.field_type);
      if (editValues.field_type === 'Currency') {
        getCurrencyList().then((data) => {
          setCurrencyList(data);
        });
      }
      form.setFieldsValue({
        rpg_values,
        rpa_group: editValues.rpa_group
          ? editValues.rpa_group.replace(/^#+|#+$/gm, '').split('#')
          : null,
        field_name: editValues.field_name,
        field_type: editValues.field_type,
        algorithm_id: check ? editValues.get_algorithm_name.id : null,
        default_value: editValues.default_value,
        description: editValues.description,
      });
    }
  }, [editValues, form, props, dropdown_algorithm_list, getCurrencyList]);

  // Check for valid form values then accordingly made save button disable / enable
  const handleChange = async () => {
    if (
      form.getFieldValue().field_type === 'CSV Column' ||
      form.getFieldValue().field_type === 'Date'
    ) {
      setFieldIsCSV(true);
      setFieldIsDate(true);
    } else {
      await setFieldIsCSV(false);
      await setFieldIsDate(false);
    }
    form
      .validateFields()
      .then((d) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  // Reset form and close edit form
  const close = () => {
    form.resetFields();
    setDisabled(true);
    props.close();
  };

  return editValues ? (
    <Modal
      title={`${t('DealBuilder.Edit_Deal_Maker')} - #${editValues?.id}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      style={{ maxWidth: '600px' }}
      width='100%'
      footer={[
        <Button
          key='1'
          disabled={disabled}
          form='editform'
          loading={saving}
          htmlType='submit'
          type='primary'
          shape='round'
          size='medium'
        >
          {t('Common_Data.Save')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            close();
          }}
          shape='round'
          size='medium'
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id='editform'
        onFinish={handleSubmit}
        layout='vertical'
        onChange={handleChange}
        className='innerForm'
      >
        <Form.Item
          name='field_name'
          rules={vsmTransactionFields.validation.field_name}
          label={`${t('DealBuilder.Field_Name')}`}
          required
          tooltip={`${t('DealBuilder.Assigned_Transcation')}`}
        >
          <Input id='field_name' placeholder={`${t('DealBuilder.Field_Name')}`} maxLength={100} />
        </Form.Item>

        <Form.Item
          name='field_type'
          label={`${t('DealBuilder.Field_Type')}`}
          required
          tooltip={`${t('DealBuilder.Field_Type_Tooltip')}`}
        >
          <Select placeholder={`${t('DealBuilder.Field_Type')}`} onChange={handleChange} disabled>
            {dropdown_type_list &&
              dropdown_type_list.map((item, index) => (
                <Select.Option key={index} value={item}>
                  {item}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {!fieldIsCSV || !fieldIsDate ? (
          <Form.Item
            name='algorithm_id'
            rules={vsmTransactionFields.validation.algorithm_id}
            label={`${t('DealBuilder.Algorithm')}`}
            tooltip={`${t('DealBuilder.Algorithm_Transcation_Tooltip')}`}
          >
            <Select
              showSearch
              placeholder={`${t('DealBuilder.Algorithm')}`}
              allowClear
              optionFilterProp='children'
              onChange={handleChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {dropdown_algorithm_list &&
                dropdown_algorithm_list.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.algorithm_name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        ) : null}

        <Form.List name='rpg_values' initialValue={[null]}>
          {(fields, { add, remove }) => {
            return (
              fields &&
              fields.map((field, index) => {
                return (
                  <Row gutter={24} style={{ flexWrap: 'nowrap' }} key={index}>
                    <Col span={10}>
                      <InputComponent
                        name={[field.name, 'rpa_group']}
                        label={`${t('DealBuilder.RPA_Group')}`}
                        placeholder={`${t('DealBuilder.RPA_Group')}`}
                        type='select'
                        onChange={handleChange}
                        options={{
                          values: [
                            { value: 'a-ETR', text: `a-${t('DealBuilder.ETR')}` },
                            { value: 'b-ETR', text: `b-${t('DealBuilder.ETR')}` },
                            { value: 'c-ETR', text: `c-${t('DealBuilder.ETR')}` },
                            { value: 'd-ETR', text: `d-${t('DealBuilder.ETR')}` },
                            { value: 'f-ETR', text: `f-${t('DealBuilder.ETR')}` },
                          ],
                          rejected_keys:
                            form.getFieldValue('rpg_values') &&
                            form.getFieldValue('rpg_values').map((x) => x && x.rpa_group),
                          accepted_keys: form.getFieldValue('rpg_values') &&
                            form.getFieldValue('rpg_values')[field.name] && [
                              form.getFieldValue('rpg_values')[field.name].rpa_group,
                            ],
                        }}
                        required
                        rules={vsmTransactionFields.validation.rpa_group}
                        tooltip={`${t('DealBuilder.RPA_Tooltip')}`}
                      />
                    </Col>
                    <Col flex={1}>
                      {fieldType === 'Currency' ? (
                        <InputComponent
                          placeholder={`${t('DealBuilder.Default_Value')}`}
                          name={[field.name, 'default_value']}
                          label={`${t('DealBuilder.Default_Value')}`}
                          tooltip={`${t('DealBuilder.Default_Value_Tooltip')}`}
                          type='select'
                          options={{
                            values: currencyList,
                            value_key: 'currency_code',
                            text_key: {
                              key: ['currency_name', ' (', 'currency_code', ')'],
                            },
                          }}
                          rules={[
                            {
                              required: true,
                              message: `${t('DealBuilder.Default_Value_Not_Empty')}.`,
                            },
                          ]}
                        />
                      ) : [
                        'Flag',
                        'Toggle (Months/Days)',
                        'Toggle (Convertibill®/Trade Credebt®)',
                      ].includes(fieldType) ? (
                        <InputComponent
                          id='default_value'
                          name={[field.name, 'default_value']}
                          rules={[
                            {
                              required: true,
                              message: `${t('DealBuilder.Default_Value_Not_Empty')}.`,
                            },
                          ]}
                          type='radio_button'
                          options={{
                            values:
                              fieldType === 'Toggle (Convertibill®/Trade Credebt®)'
                                ? [
                                  { value: 'Convertibill®', text: `${t('DealBuilder.Convertibill')}®` },
                                  { value: 'Trade Credebt®', text: `${t('DealBuilder.Trade_Credebt')}®` },
                                ]
                                : fieldType === 'Toggle (Months/Days)'
                                  ? [
                                    { value: 'Months', text: `${t('Common_Data.Months')}` },
                                    { value: 'Days', text: `${t('Common_Data.Days')}` },
                                  ]
                                  : fieldType === 'Flag'
                                    ? [
                                      { value: '0', text: `${t('Common_Data.No')}` },
                                      { value: '1', text: `${t('Common_Data.Yes')}` },
                                    ]
                                    : null,
                          }}
                          label={`${t('DealBuilder.Default_Value')}`}
                          tooltip={`${t('DealBuilder.Default_Value_Tooltip')}`}
                        />
                      ) : (
                        <InputComponent
                          id='default_value'
                          placeholder={`${t('DealBuilder.Default_Value')}`}
                          maxLength={100}
                          name={[field.name, 'default_value']}
                          rules={vsmTransactionFields.validation.default_value}
                          label={`${t('DealBuilder.Default_Value')}`}
                          extra={[
                            <div key={1}>
                              <span>
                                <b>{t('Common_Data.Note')}:</b> {t('DealBuilder.Changing_Algorithm')}.
                              </span>
                            </div>,
                          ]}
                          tooltip={`${t('DealBuilder.Default_Value_Tooltip')}`}
                        />
                      )}
                    </Col>
                    <Col className='d-flex align-items-center'>
                      {fields.length - 1 === index && index < 4 && (
                        <img
                          src={AUTH.GetThemedImage('add')}
                          alt={`${t('Common_Data.Add')}`}
                          className='icon circle cursorPointer mr-5'
                          onClick={async () => {
                            await add();
                            handleChange();
                          }}
                        />
                      )}

                      {fields.length > 1 && (
                        <img
                          src={AUTH.GetThemedImage('remove')}
                          alt={`${t('Common_Data.Remove')}`}
                          className='icon circle cursorPointer'
                          onClick={async () => {
                            await remove(field.name);
                            handleChange();
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })
            );
          }}
        </Form.List>

        <InputComponent
          required
          type='textarea'
          label={`${t('DealBuilder.Description')}`}
          name='description'
          placeholder={`${t('DealBuilder.Description')}`}
          onChange={handleChange}
          rowSize={3}
          rules={vsmTransactionFields.validation.description}
          maxLength={1000}
          tooltip={`${t('DealBuilder.Description_Tooltip')}`}
        />
      </Form>
    </Modal>
  ) : null;
});

export default EditComponent;
