import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { Button, Col, Form, Row } from "antd";
import useStore from "../../../../../store";
import FormComponent from "../components/FormComponent";
import { useTranslation } from "react-i18next";

const Details = observer(({ changeTab, activeTab }) => {
  const {t} = useTranslation();

  const [form] = Form.useForm()

  const [disabled, setDisabled] = useState(false)
  const [loader, setLoader] = useState(false)

  const { DEALDESKSTORE: { deal_data }, DEAL: { saveDealDetails } } = useStore()

  const handleChange = useCallback(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(false);
        }
      });
  }, [form])

  const dealid = deal_data.lead_information && deal_data.lead_information.id;
  const handleSubmit = async (data) => {
    setLoader(true)
    data.is_issue = false
    saveDealDetails(dealid, data)
      .then(() => {
        setLoader(false)
        changeTab('2');
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
  }

  return (
    <Form form={form} layout="vertical" id='DealDetails'
      className='innerForm DealDeskDeal' onFinish={handleSubmit} scrollToFirstError={true}>
      <FormComponent form={form} handleChange={handleChange} editable={true} is_issued={false} />

      <Row gutter={0} className='mt-10'>
        <Col span={24} className='text-right border-top pt-15'>
          <Button
            type='primary'
            shape='round'
            size='small'
            disabled={disabled}
            htmlType='submit'
            loading={loader}
          >
            {t('Common_Data.Save')}
          </Button>
        </Col>
      </Row>
    </Form >
  );
});

export default Details;
