import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../../store';
import { vsmRoles, vsmNotify } from '../../../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const {
    ORGROLESTORE: { DeleteData, deleteValues },
  } = useStore();
  const [saving, setSaving] = useState();

  // Make function call to delete existing record
  const handleSubmit = (data) => {
    setSaving(true);
    data.id = deleteValues.id;
    DeleteData(data)
      .then(() => {
        props.close();
        vsmNotify.success({
          message: vsmRoles.delete,
        });
        form.resetFields();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  // set the form values to delete
  useEffect(() => {
    if (deleteValues) {
      form.setFieldsValue({
        role_name: deleteValues.role_name,
      });
    }
  }, [deleteValues, form]);

  return deleteValues ? (
    <Modal
      centered
      title={`${t('Common_Data.Delete')} ${t('OrganisationManagement.Role')} - #${deleteValues.id} - ${deleteValues.role_name}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deleteformOrgManagement'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteformOrgManagement' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('OrganisationManagement.Remove_Organisation_Role')}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
