import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store';
import { vsmNotify } from '../../../../config/messages';

const DeleteComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    SSSLEADLIBRARYSTORE,
    SCORELIBRARYSTORE: { deleteScoreData },
  } = useStore();
  const [saving, setSaving] = useState(false);

  const close = (reload = false) => {
    props.close(reload);
    form.resetFields();
    setSaving(false);
  };

  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);

    deleteScoreData(props?.id)
      .then(() => {
        if (props.libData) {
          SSSLEADLIBRARYSTORE.agGridRef.current.api.applyTransaction({ remove: [props.libData] });
        }
        close(true);
        vsmNotify.success({
          message: `${t('DealDesk.Score_Removed')}!`,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return props ? (
    <Modal
      centered
      title={`${t('DealDesk.Delete_Score')}- #${props?.id}`}
      visible={props.visible}
      onCancel={() => close()}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deleteScoreFromLibrary'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
          shape='round'
          size='medium'
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => close()}
          shape='round'
          size='medium'
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteScoreFromLibrary' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col className='DeleteText'>
              {t('DealDesk.Score_Delete')}? {/*<strong>"#"</strong>? */}
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
