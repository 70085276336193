import React, { useState, useEffect } from 'react';
import { Form, Button, Spin, Drawer } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { vsmNotify, vsmOrganisations } from '../../../../config/messages';
import FormComponent from './FormComponentNew';

import { default as AddPeopleComponent } from '../../../PeopleManagement/People/component/AddComponentNew';
import { default as EditPeopleComponent } from '../../../PeopleManagement/People/component/EditComponentNew';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../../component/TimeTracker';

const CloneComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const {
    AUTH,
    ORGSTATUSSTORE,
    ORGANISATION,
    ORGBANKACCOUNTSTORE,
    ORGANISATION: { JsonToFormData, CloneData, organisationValues },
    PEOPLE,
    TimeTrackerStore
  } = useStore();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [isChecked, setChecked] = useState(false);
  const [imgchanged, setImgchanged] = useState(false);
  const [fileList, updateFileList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addPeopleModal, setAddPeopleModal] = useState(false);
  const [editPeopleDrawer, setEditPeopleDrawer] = useState(false);

  // make a fuction to call to edit record
  const handleSubmit = (data) => {
    if (data.phone && data.phone.length > 0) {
      data.phone = data.phone.filter(
        (x) => x && x.contact_for && x.contact_value && x.id && delete x.id
      );
      data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : null;
    }
    if (data.email && data.email.length > 0) {
      data.email = data.email.filter(
        (x) =>
          x &&
          x.contact_for &&
          x.contact_value &&
          x.id &&
          delete x.id &&
          delete x.entity_type &&
          delete x.entity_id
      );
      data.email = data.email.length > 0 ? JSON.stringify(data.email) : null;
    }

    if (data.addresses && data.addresses.length > 0) {
      data.add_address = true;
      data.addresses = data.addresses.filter(
        (x) => x && x && x.address_type && delete x.id
      );
      data.addresses =
        data.add_address && data.addresses.length > 0
          ? JSON.stringify(data.addresses)
          : null;

      if (data.addresses && data.addresses.length > 0) {
        data.add_address = true;
      } else {
        data.add_address = false;
      }
    }
    setSaving(true);
    data.id = organisationValues.id;
    data.country = data.country ? data.country : null;
    delete data['nace_category_id'];
    var formData = JsonToFormData(data);
    if (fileList.length > 0 && fileList[0] && imgchanged) {
      formData.append(
        'logo_url',
        fileList[0].hasOwnProperty('originFileObj')
          ? fileList[0].originFileObj
          : fileList[0]
      );
    } else if (fileList.length === 0) {
      formData.append('logo_status', 'deleted');
    }
    CloneData(formData, organisationValues.id)
      .then((data) => {
        TimeTrackerStore.setLeadTransactionId(data.leads_transactions_id)
        close();
        AUTH.fetchLeftmenuCount();
        vsmNotify.success({
          message: vsmOrganisations.edit,
        });
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // set data on refresh page
  useEffect(() => {
    if (props.visible) {
      setLoading(true);
      ORGANISATION.getRecordDetail({
        organisation_id: props.transactionId,
      })
        .then((data) => {
          ORGANISATION.dropdown_global_org = data.parent && [data.parent];
          ORGSTATUSSTORE.orgStatusList = data.status && [data.status];
          ORGANISATION.industries_list = data.global_industries && [
            data.global_industries,
          ];
          ORGBANKACCOUNTSTORE.dropdown_currency_list = data.global_currency && [
            data.global_currency,
          ];
          setChecked(
            data.mutiple_addresses && data.mutiple_addresses.length > 0 ? true : false
          );
          setLoading(false);
        })
        .catch((data) => {
          setLoading(false);
        });
    }
  }, [props, ORGSTATUSSTORE, ORGANISATION, ORGBANKACCOUNTSTORE]);

  // set the form values to edit
  useEffect(() => {
    if (organisationValues && props.visible) {
      updateFileList(
        organisationValues.logo_url
          ? [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: process.env.React_APP_API_URL + organisationValues.logo_url,
              },
            ]
          : []
      );
      form.setFieldsValue({
        parent_organisation_id: organisationValues.parent_organisation_id,
        organisation_name: organisationValues.organisation_name,
        trade_name: organisationValues.trade_name,
        registered_country_id: organisationValues.registered_country_id,
        registration_number: organisationValues.registration_number,
        vat_number: organisationValues.vat_number,
        apply_year_on: organisationValues.apply_year_on,
        transfer_in: organisationValues.transfer_in,
        website: organisationValues.website,
        phone:
          organisationValues.multiple_phone &&
          organisationValues.multiple_phone.length > 0
            ? organisationValues.multiple_phone
            : [null],
        email:
          organisationValues.multiple_email &&
          organisationValues.multiple_email.length > 0
            ? organisationValues.multiple_email
            : [null],
        add_address:
          organisationValues.mutiple_addresses &&
          organisationValues.mutiple_addresses.length > 0
            ? true
            : false,
        addresses:
          organisationValues.mutiple_addresses &&
          organisationValues.mutiple_addresses.length > 0
            ? organisationValues.mutiple_addresses
            : [{}],
        status_id: organisationValues.status_id,
        currency_id: organisationValues.currency_id,
        description: organisationValues.description,
        industry_id: organisationValues.industry_id,
        nace_section_id: organisationValues.nace_section_id,
        nace_category_id: organisationValues.global_nace_sections?.parent_id,
        revenue_access_number: organisationValues.revenue_access_number,
        types:
          organisationValues.types && organisationValues.types.length > 0
            ? organisationValues.types
            : [],
      });
    }
  }, [organisationValues, form, props.visible]);

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((d) => {
        let checkbox = form.getFieldValue('add_address');
        let address = form.getFieldValue('addresses');
        address = address && address.filter((x) => x && x);
        if (checkbox && (!address || (address && address.length === 0))) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      })
      .catch((d) => {
        if (d.errorFields && d.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  // reset form and close edit form
  const close = () => {
    props.close();
    props.setId(null);
    form.resetFields();
    setDisabled(true);
    setImgchanged(false);
  };

  // check whether address checkbox is checked or not
  const addAddress = async (e) => {
    form.setFieldsValue({ addresses: e.target.checked ? [null] : null });
    await setChecked(e.target.checked);
    handleChange();
  };

  // Open form for add new People
  const openAddModal = () => {
    setAddPeopleModal(true);
  };

  const setPeopleDropDown = (id) => {
    PEOPLE.getAllPeople({ selected_id: [id], mapping_type: 2 });
    form.setFieldsValue({ full_name: id });
    handleChange();
  };

  // Close form for add new People
  const closeAddModal = () => setAddPeopleModal(false);

  // Open form for edit People
  const openEditPeopleDrawer = () => {
    var payload = { people_id: form.getFieldValue('full_name') };
    PEOPLE.editPeopleLoading = true;
    PEOPLE.getRecordDetail(payload)
      .then((data) => {
        setEditPeopleDrawer(true);
        PEOPLE.editPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.editPeopleLoading = false;
      });
  };
  // Close form for edit People
  const closeEditPeopleDrawer = () => {
    PEOPLE.getAllPeople({
      selected_id: [form.getFieldValue('full_name')],
      mapping_type: 2,
    });

    setEditPeopleDrawer(false);
    handleChange();
  };

  return (
    <Drawer
      title={`${t('Common_Data.Clone')} ${t('Common_Data.Organisation')} - #${
        organisationValues
          ? `${organisationValues?.id} - ${organisationValues?.organisation_name}`
          : ''
      }`}
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1100px'}
      destroyOnClose={true}
      footer={[
        <div className='text-right'>
          <Button
            key='1'
            disabled={disabled}
            form='cloneform'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            size='medium'
          >
            {t('Common_Data.Clone')}
          </Button>
          <Button
            key='2'
            className='ml-10'
            htmlType='button'
            onClick={close}
            shape='round'
            size='medium'
          >
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <Spin size='large' spinning={loading} tip={`${t('Common_Data.Load')}`}>
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='cloneform'
          updateFileList={updateFileList}
          fileList={fileList}
          addAddress={addAddress}
          setImgchanged={setImgchanged}
          isChecked={isChecked}
          openAddModal={openAddModal}
          openEditPeopleDrawer={openEditPeopleDrawer}
        />
        <AddPeopleComponent
          visible={addPeopleModal}
          close={closeAddModal}
          setPeopleDropDown={setPeopleDropDown}
        />
        <EditPeopleComponent
          visible={editPeopleDrawer}
          close={closeEditPeopleDrawer}
          setPeopleDropDown={setPeopleDropDown}
        />
        <TimeTracker
          page={"organisation"}
          id={organisationValues?.leads_transactions_id}
          stop={!props.visible || addPeopleModal || editPeopleDrawer}
        />
      </Spin>
    </Drawer>
  );
});

export default CloneComponent;
