import React from 'react';
import { observer } from 'mobx-react';
import { Drawer } from 'antd';
import Reminders from '../index';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const ViewAllListComponent = observer((props) => {
  const { t } = useTranslation()
  const { visible } = props;

  const close = () => {
    props.close();
  };

  return (
    <>
    <Drawer
      key='1'
      visible={visible}
      onClose={close}
      placement='right'
      width={'1200px'}
      title={`${t('Common_Data.View')} ${t('Reminders.All')} ${t('Common_Data.Reminders')}`}
      destroyOnClose={true}
      footer={[]}
    >
      <Reminders AllSOLDReminder={true} />
    </Drawer>
      <TimeTracker
        page={"reminders"}
        stop={!visible}
      />
    </>
  );
});

export default ViewAllListComponent;
