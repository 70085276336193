import React, { useEffect, useState, useCallback } from 'react';
import { Form, Row, Col } from 'antd';

import useStore from '../../../../store';
//import DateSelectionElement from '../formElements/DateSelectionElement';
import moment from 'moment';
import InputComponent from '../../../../component/InputComponent';
import PreviewDateSubFilterElement from '../formElements/PreviewDateSubFilterElement';
import { kpiReportType } from '../../../../utils/GlobalFunction';

const PreviewDateSubFilterForm = ({
  defaultFormDataList,
  formDataList,
  setFormDataList,
  setTip,
  setSaving,
  setPreviewReport,
  setNoDataAlert,
  form
}) => {
  const [filterApplied, setFilterApplied] = useState(false);
  const [previewfilterBtnDisable, setPreviewfilterBtnDisable] = useState(true);
  const [DateRange, SetDateRange] = useState([]);
  const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false);

  //const [form] = Form.useForm();

  const {
    REPORTSTORE,
    REPORTSTORE: { setPreviewFilterForExport, getFinancialYearList },
  } = useStore();

  const handlePreviewReport = () => {
    let newObject = {
      ...REPORTSTORE.previewReportValues,
      ...form.getFieldsValue(),
    };

    newObject.form_list = formDataList;
    let data = REPORTSTORE.formatFormData(newObject);

    data.name = REPORTSTORE.previewReportValues.name;
    data.code = REPORTSTORE.previewReportValues.code;
    data.description = REPORTSTORE.previewReportValues.description;
    data.action = 'preview-data';

    let callAPI = true;

    if (callAPI) {
      setSaving(true);
      setTip('Loading Report');

      setPreviewFilterForExport(data);
      REPORTSTORE.PreviewData(data)
        .then((data) => {
          setPreviewReport(data.data);
          if (data.data && Object.keys(data.data).length > 0) {
          } else {
            setNoDataAlert(true);
          }
        })
        .catch((e) => {
          if (e.errors) {
            form.setFields(e.errors);
          }
        })
        .finally(() => {
          setSaving(false);
          setTip(null);
        });
    }
  };

  const disabledDate = (current) => {
    if (!DateRange || DateRange.length === 0) {
      return false;
    }
    return moment(current).format('Y-M-D') === moment(DateRange[0]).format('Y-M-D');
  };

  const handleChange = async (event) => {
    setFilterApplied(true);
    await setFormDataList(getFormDataList(REPORTSTORE.previewReportValues.report_type));

    form
      .validateFields()
      .then((data) => {
        setPreviewfilterBtnDisable(false);

      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setPreviewfilterBtnDisable(true);
        }
      });
  };

  const getFormDataList = useCallback(
    (type) => {
      let FormDataList = defaultFormDataList;

      if (form.getFieldValue('report_type')) {
        if (!kpiReportType.includes(form.getFieldValue('report_type'))) {
          FormDataList = [...FormDataList, 'report_on'];
        }
        if (kpiReportType.includes(form.getFieldValue('report_type'))) {
          FormDataList = [...FormDataList, 'report_by',
            'seperate_sum_users',];
          if (form.getFieldValue('report_by')) {
            FormDataList = [...FormDataList, 'report_for'];
            if (form.getFieldValue('report_for')) {
              FormDataList = [
                ...FormDataList,
                'name',
                'code',
                'description',
                'global_toggle',
                'waste',
                'users',
                'owners',
                'holders',
                'user_groups',
                'include_inactive_user_groups',
                'include_inactive_users',
                'record_type',
                'selected_lead_status'
              ];
            }
          }
        }
        // if (form.getFieldValue('report_type') === 'summary' && (!form.getFieldValue('report_on') || form.getFieldValue('report_on') === 'saved_filter')) {
        //   form.setFieldsValue({
        //     report_on: 'all',
        //     report_by: 'all',
        //   })
        //   setReport_on_report('all');
        // }
        // if (form.getFieldValue('report_type') === 'records' && (!form.getFieldValue('report_on') || form.getFieldValue('report_on') === 'all')) {
        //   form.setFieldsValue({
        //     report_on: 'sold',
        //     date_filter_on: 'created'
        //   })
        //   setReport_on_report('sold');
        // }
        // if (form.getFieldValue('report_type') === 'records' && !form.getFieldValue('date_filter_on')) {
        //   form.setFieldsValue({
        //     date_filter_on: 'created'
        //   })
        // }
      }

      if (
        form.getFieldValue('report_on') &&
        form.getFieldValue('report_type') &&
        form.getFieldValue('report_type') === 'relationship'
      ) {
        FormDataList = [
          ...FormDataList,
          'report',
          'name',
          'code',
          'global_toggle',
          'waste',
          'description',
          'lead_id',
          'ticket_number',
          'organisation_name',
          'people_name',
          'related_to_rows',
        ];
        return FormDataList;
      } else {
        if (form.getFieldValue('report_on')) {
          if (form.getFieldValue('report_on') !== 'saved_filter') {
            FormDataList = [...FormDataList, 'report_by'];
          }
        }
      }

      if (
        form.getFieldValue('report_on') &&
        form.getFieldValue('report_on') === 'saved_filter'
      ) {
        FormDataList = [
          ...FormDataList,
          'name',
          'code',
          'global_toggle',
          'waste',
          'description',
          'filter_type',
          'filter_id',
          'sort_by_columns',
        ];
        if (
          !form.getFieldValue('sort_by_columns') &&
          FormDataList.includes('sort_by_columns')
        ) {
          form.setFieldsValue({
            sort_by_columns: [
              { sort_by: null, sort: 'desc' },
              { sort_by: null, sort: 'desc' },
              { sort_by: null, sort: 'desc' },
            ],
          });
        }
      } else {
        if (form.getFieldValue('report_by')) {
          FormDataList = [...FormDataList, 'report_for'];
          // if (!form.getFieldValue('report_for')) {
          //   form.setFieldsValue({
          //     report_for: 'all',
          //   });
          // }
          if (
            form.getFieldValue('report_for') &&
            form.getFieldValue('report_type') === 'records'
          ) {
            FormDataList = [...FormDataList, 'date_filter_on'];
          }

          if ((form.getFieldValue('report_for') || (form.getFieldValue('enable_comparison_mode') && form.getFieldValue('report_for_comparison_mode')))) {
            if (form.getFieldValue('report_type') === 'summary' || form.getFieldValue('report_type') === 'count_list' ) {
              FormDataList = [
                ...FormDataList,
                'name',
                'code',
                'global_toggle',
                'description',
                'report_for_comparison_mode',
                'enable_comparison_mode',
                'seperate_sum_users',
                'select_all_columns',
                'sum_call_clicks',
                'sum_email_clicks',
                'sum_total_time_spent',
                'sum_modified',
                'users',
                'owners',
                'holders',
                'user_groups',
                'include_inactive_user_groups',
                'include_inactive_users',
                'financial_value',
                'finance',
                
                // 'date_range',
                // 'single_date',
                // 'period_x_value_type',
                // 'period_x_value',
                // 'financial_year',
                // 'period_selection',
                // 'period_date_range',
              ];

              if (
                form.getFieldValue('report_on') &&
                form.getFieldValue('report_on') !== 'reminders'
              ) {
                FormDataList = [...FormDataList, 'waste'];
              }

              switch (form.getFieldValue('report_on')) {
                case 'all':
                  FormDataList = [
                    ...FormDataList,
                    'sold_created',
                    'sold_assigned',
                    'sold_sum_total',
                    'sum_sss_created',
                    'sum_results',
                    'sum_inbound_leads',
                    'sum_outbound_leads',
                    'sum_finance_value',
                    'sum_by_history_category',
                    'sum_by_stages',
                    'tag_sum_total',
                    'tag_created',
                    'tag_assigned',
                    'sum_by_tag_status',
                    'ticket_sum_total',
                    'ticket_created',
                    'ticket_assigned',
                    'sum_by_ticket_status',
                    'reminder_created',
                    'reminder_sum_total',
                    'reminder_assigned',
                    'sum_by_reminder_status',
                    'organisation_created',
                    'people_created',
                  ];

                  if (form.getFieldValue('sum_by_history_category')) {
                    FormDataList = [...FormDataList, 'history_categories'];
                  }

                  if (form.getFieldValue('sum_by_stages')) {
                    FormDataList = [
                      ...FormDataList,
                      'include_bonus_figures',
                      'include_budget',
                      'record_type',
                      'parent_sold_stage',
                      'sold_stage',
                      'count_for_holders_only',
                    ];
                  }

                  if (form.getFieldValue('sum_by_tag_status')) {
                    FormDataList = [...FormDataList, 'tag_status'];
                  }
                  if (form.getFieldValue('sum_by_ticket_status')) {
                    FormDataList = [...FormDataList, 'ticket_status'];
                  }

                  if (form.getFieldValue('sum_by_reminder_status')) {
                    FormDataList = [...FormDataList, 'reminder_status'];
                  }
                  break;
                case 'sold':
                  FormDataList = [
                    ...FormDataList,
                    'sold_created',
                    'sold_assigned',
                    'sold_sum_total',
                    'display_stages_as_row',
                    'count_historical_moments',
                    'sum_sss_created',
                    'sum_results',
                    'sum_inbound_leads',
                    'sum_outbound_leads',
                    'sum_modified',
                    'sum_finance_value',
                    'sum_by_history_category',
                    'sum_by_stages',
                  ];
                  if (form.getFieldValue('sum_by_history_category')) {
                    FormDataList = [...FormDataList, 'history_categories'];
                  }
                  if (form.getFieldValue('sum_by_stages')) {
                    FormDataList = [
                      ...FormDataList,
                      'include_bonus_figures',
                      'include_budget',
                      'record_type',
                      'parent_sold_stage',
                      'sold_stage',
                      'count_for_holders_only',
                    ];
                  }

                  break;
                case 'tags':
                  FormDataList = [
                    ...FormDataList,
                    'tag_sum_total',
                    'tag_created',
                    'tag_assigned',
                    'sum_by_tag_status',
                  ];
                  if (form.getFieldValue('sum_by_tag_status')) {
                    FormDataList = [...FormDataList, 'tag_status'];
                  }

                  break;
                case 'tickets':
                  FormDataList = [
                    ...FormDataList,
                    'ticket_sum_total',
                    'ticket_created',
                    'ticket_assigned',
                    'sum_by_ticket_status',
                  ];

                  if (form.getFieldValue('sum_by_ticket_status')) {
                    FormDataList = [...FormDataList, 'ticket_status'];
                  }

                  break;
                case 'reminders':
                  FormDataList = [
                    ...FormDataList,
                    'reminder_created',
                    'reminder_sum_total',
                    'reminder_assigned',
                    'sum_by_reminder_status',
                  ];

                  if (form.getFieldValue('sum_by_reminder_status')) {
                    FormDataList = [...FormDataList, 'reminder_status'];
                  }
                  break;
                case 'organisation':
                  FormDataList = [...FormDataList, 'organisation_created'];

                  break;
                case 'people':
                  FormDataList = [...FormDataList, 'people_created'];
                  break;
                default:
              }
            } else if (
              form.getFieldValue('date_filter_on') &&
              form.getFieldValue('report_type') === 'records'
            ) {
              if (form.getFieldValue('report_on') === 'reminders') {
                FormDataList = [
                  ...FormDataList,
                  'name',
                  'code',
                  'global_toggle',
                  'description',
                  'sort_by_columns',
                ];
              } else {
                FormDataList = [
                  ...FormDataList,
                  'name',
                  'code',
                  'global_toggle',
                  'waste',
                  'description',
                  'sort_by_columns',
                ];
              }



              switch (form.getFieldValue('report_on')) {
                case 'sold':
                  FormDataList = [
                    ...FormDataList,
                    'opportunity_name',
                    'classification_id',
                    'record_type',
                    'parent_sold_stage',
                    'sold_stage',
                    'product_name',
                    'lead_currency_id',
                    'lead_source',
                    'sold_source_details',
                    'note',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'idle_min',
                    'idle_max',
                    'timespent_min',
                    'timespent_max',
                    'turnover_min',
                    'turnover_max',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'sort_by_columns'
                  ];

                  break;
                case 'tags':
                  FormDataList = [
                    ...FormDataList,
                    'organisation_type',
                    'organisation',
                    'master_ticket_number',
                    'tag_status',
                    'ticket_number',
                    'sold_id',
                    'tag_subject',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'people_type',
                    'people',
                    'sort_by_columns'
                  ];

                  break;
                case 'tickets':
                  FormDataList = [
                    ...FormDataList,
                    'master_ticket_number',
                    'leads_tickets_status',
                    'ticket_type',
                    'ticket_priority',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'ticket_subject',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'idle_min',
                    'idle_max',
                    'timespent_min',
                    'timespent_max',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'sort_by_columns'
                  ];


                  break;
                case 'reminders':
                  FormDataList = [
                    ...FormDataList,
                    'reminder_type',
                    'reminder_status',
                    'reminder_priority',
                    'reminder_subject',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'reminder_ticket_id',
                    'reminder_leads_id',
                    'sort_by_columns'
                  ];
                  break;
                case 'organisation':
                  FormDataList = [
                    ...FormDataList,
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'idle_min',
                    'idle_max',
                    'timespent_min',
                    'timespent_max',
                    'classification_id',
                    'organisation_name',
                    'include_inactive_users',
                    'users',
                    'owners',
                    'holders',
                    'user_groups',
                    'include_inactive_user_groups',
                    'sort_by_columns'
                  ];

                  break;
                case 'people':
                  FormDataList = [
                    ...FormDataList,
                    'parent_organisation',
                    'organisation_registration_country',
                    'organisation_status',
                    'nace_category',
                    'nace_code',
                    'people_status',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                    'idle_min',
                    'idle_max',
                    'timespent_min',
                    'timespent_max',
                    'classification_id',
                    'people_name',
                    'users',
                    'owners',
                    'holders',
                    'sort_by_columns'
                  ];

                  break;
                case 'saved_filter':
                  FormDataList = [...FormDataList, 'filter_type', 'filter_id', 'sort_by_columns'];
                  break;
                default:
              }
              if (
                !form.getFieldValue('sort_by_columns') &&
                FormDataList.includes('sort_by_columns')
              ) {
                form.setFieldsValue({
                  sort_by_columns: [
                    { sort_by: null, sort: 'desc' },
                    { sort_by: null, sort: 'desc' },
                    { sort_by: null, sort: 'desc' },
                  ],
                });
              }
            } else if (form.getFieldValue('report_type') === 'time_spent') {
              FormDataList = [
                ...FormDataList,
                'name',
                'code',
                'global_toggle',
                'waste',
                'description',
                'report_for_comparison_mode',
                'enable_comparison_mode',
                'users',
                'owners',
                'holders',
                'user_groups',
                'include_inactive_user_groups',
                'include_inactive_users',
                'group_on',
              ];

              switch (form.getFieldValue('report_on')) {
                case 'all':
                  FormDataList = [
                    ...FormDataList,
                    'record_type',
                    'lead_id',
                    'master_ticket_number',
                    'ticket_id',
                    'organisation_type',
                    'organisation',
                    'people_type',
                    'people',
                  ];
                  break;
                case 'sold':
                  FormDataList = [...FormDataList, 'record_type', 'lead_id'];
                  break;
                case 'tickets':
                  FormDataList = [...FormDataList, 'master_ticket_number', 'ticket_id'];
                  break;
                case 'organisation':
                  FormDataList = [...FormDataList, 'organisation_type', 'organisation'];
                  break;
                case 'people':
                  FormDataList = [...FormDataList, 'people_type', 'people'];
                  break;
                case 'others':
                  break;
                default:
              }
            }
          }
        }

        switch (form.getFieldValue('report_for')) {
          case 'yesterday':
            FormDataList = [...FormDataList];

            break;
          case 'last_week':
            FormDataList = [...FormDataList];

            break;
          case 'this_month':
            FormDataList = [...FormDataList];

            break;
          case 'this_quarter':
            FormDataList = [...FormDataList];

            break;
          case 'this_year':
            FormDataList = [...FormDataList];

            break;
          case 'date_range':
            FormDataList = [...FormDataList, 'date_range'];

            break;
          case 'single':
            FormDataList = [...FormDataList, 'single_date'];
            if (!form.getFieldValue('date')) {
              form.setFieldsValue({ date: moment(new Date(), 'YYYY-MM-DD') });
            }

            break;
          case 'this':
            FormDataList = [...FormDataList, 'period_x_value_type'];

            break;
          case 'last':
            FormDataList = [...FormDataList, 'period_x_value', 'period_x_value_type'];

            break;
          case 'financial_year':
            FormDataList = [...FormDataList, 'financial_year'];
            if (!GENERATEONFINANCIAL) {
              SETGENERATEONFINANCIAL(true);
              getFinancialYearList();
            }
            break;
          case 'periods':
            FormDataList = [...FormDataList, 'period_selection'];
            switch (form.getFieldValue('period_selection')) {
              case 'custom':
                FormDataList = [...FormDataList, 'period_date_range'];
                break;
              case 'auto':
                FormDataList = [...FormDataList, 'period_x_value', 'period_x_value_type'];
                break;
              default:
            }
            break;
          default:
        }
        return FormDataList;
      }
      return FormDataList;
    },
    [defaultFormDataList, form, getFinancialYearList, GENERATEONFINANCIAL]
  );

  useEffect(() => {
    if (!filterApplied) {
      let data = getFormDataList(REPORTSTORE.previewReportValues?.report_type);

      if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
        setFormDataList(data);
        var previewReportValues = REPORTSTORE.formatFormData(
          REPORTSTORE.previewReportValues,
          false
        );
        form.setFieldsValue(previewReportValues);
      }
    }
  }, [
    getFormDataList,
    setFormDataList,
    formDataList,
    form,
    filterApplied,
    setFilterApplied,
    REPORTSTORE,
  ]);

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        onFinish={handlePreviewReport}
        id='update_daterangeForm '
        className='innerForm'
        initialValues={REPORTSTORE.initialSummaryValues}
      >
        <Row gutter={0}>
          <Col span={24} className="noMargin-InnerRow">
            <div className='d-flex' style={{ gap: "20px" }}>
              <InputComponent label={''} required type='radio_button' name='report_for'
                options={{
                  values: [
                    { value: 'all', text: 'All' },
                    { value: 'today', text: 'Today' },
                    { value: 'yesterday', text: 'Yesterday' },
                    { value: 'single', text: 'Date' },
                    { value: 'this', text: 'This' },
                    { value: 'last', text: 'Last' },
                    { value: 'financial_year', text: 'Fiscal' },
                    { value: 'date_range', text: 'Range' },
                  ],
                }}
                onChange={() => { handleChange(); form.resetFields(['date_range', 'date', 'date_x_value', 'date_x_value_type', 'financial_year',]); }}
              />

              <PreviewDateSubFilterElement
                SetDateRange={SetDateRange}
                disabledDate={disabledDate}
                formDataList={formDataList}
                onChange={handleChange}
                form={form}
                handlePreviewReport={handlePreviewReport}
                isPreviewFilter={true}
                previewfilterBtnDisable={previewfilterBtnDisable}
              />
            </div>
          </Col>

          {/* <Col span={14}>
            <Row gutter={0} className="noMargin-InnerRow">

            </Row>
          </Col> */}
          {/* {REPORTSTORE.previewReportValues
            ? hideTotalRecordsCountForReport.includes(REPORTSTORE.previewReportValues.report_type)
              ? `${REPORTSTORE.previewReportValues.name.length > 21 ? REPORTSTORE.previewReportValues.name.substring(0, 21) : REPORTSTORE.previewReportValues.name} `
              : `${REPORTSTORE.previewReportValues.name.length > 21 ? REPORTSTORE.previewReportValues.name.substring(0, 21) + '...' : REPORTSTORE.previewReportValues.name}  ${!['time_spent'].includes(REPORTSTORE.previewReportValues.report_type)
                ? ''
                : ''
              }`
            : 'Preview Data'}{' '} */}
        </Row>
      </Form>
    </>
  );
};
export default PreviewDateSubFilterForm;
