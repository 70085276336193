import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Spin, PageHeader, Tooltip, Form, Space, Typography, Button } from 'antd';

import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

import useStore from '../../store';

import Opportunities from '../LeadListing/Opportunities';
import History from '../History';
import Contracts from '../Contracts';
import PageNotFound from '../PageNotFound';
// import Deals from '../LeadListing/Deals';
// import Tags from '../Tags';
// import Tickets from '../Tickets';
// import People from '../PeopleManagement/People';
// import Organisations from '../OrganisationManagement/AllOrganisations';
// import Leads from '../LeadListing/Leads';
// import Knowledge from '../Knowledge';
// import Reminders from '../Reminders';
// import LibrarySearch from '../DealLibrary/LibrarySearch';

/** For PageHeader */
import EnterFullscreen from '../../component/EnterFullscreen';
import ResetGridOptions from '../../component/ResetGridOptions';
import { useForm } from 'rc-field-form';
import { vsmNotify } from '../../config/messages';
import MergeModal from './components/MergeModal';
import RecordsSelect from './components/RecordsSelect';

/** For Listings*/
import PeopleSearch from './PeopleSearch';
import Organisations from './OrganisationSearch';
import LeadSearch from './LeadSearch';
import DealSearch from './DealSearch';
import TicketsSearch from './TicketsSearch';
import LibrarySearch from './LibrarySearch';
import KnowledgeSearch from './KnowledgeSearch';
import TagsSearch from './TagsSearch';
import RemindersSearch from './ReminderSearch';
import MasterTicketSearch from './MasterTicketSearch';
import KeyNotesSearch from './KeyNotesSearch';
import TimeTracker from '../../component/TimeTracker';

// import TrackTimeComponent from '../../component/TrackTimeComponent';

const Search = observer((props) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { TagsStore, TicketsStore, PEOPLE, ORGANISATION, LEADDEALS,
    LEADOPPORTUNITY, LEADSSTORE, HISTORY, REMINDERSSTORE, SEARCHSTORE,
    KNOWLEDGE, SEARCHSTORE: { resultCount, plainOptions, searchCount,
      searchTerm, setResultCount, searchLoader, }, LIBRARYSEARCHSTORE,
    SETTINGS, AUTH, KeyNotesSearchStore } = useStore();
  const history = useHistory();
  let query = useQuery();
  const { form } = useForm()
  const SearchPage = true;

  /** States for merge */
  const [selectedRowsCount, setSelectedRowsCount] = useState(false);
  const [mergeVisibleModal, setMergeVisibleModal] = useState(false);
  const [selectedMergeData, setSelectedMergeData] = useState(null);
  const [showCheckbox, setshowCheckbox] = useState(false);
  const [searchResCount, setSearchResCount] = useState(null);
  const [getSearchCount, updateSearchCount] = useState(SEARCHSTORE.searchCount);
  const [searchSection, setSearchSection] = useState(props.match.params.slug);


  useEffect(() => {
    setSearchSection(props.match.params.slug)
  }, [props.match.params.slug]);

  let mergeOption = null;
  // const [form] = Form.useForm();

  let sectorSelector = props.match.params.slug;
  // let search_term = props.match.params.search;

  let search_term = query.get('q');

  let modifiedSearchIn = null;
  if (sectorSelector === 'Master') {
    modifiedSearchIn = "Master Tickets";
  }
  if (sectorSelector === 'Key Notes') {
    modifiedSearchIn = "Keynotes";
  }

  var payload = { search: true, search_for: search_term, search_in: modifiedSearchIn || sectorSelector, "waste": false };

  // let searchPage = true;

  let Store = null;

  const loadList = (Store) => {
    if (payload.search_for !== searchTerm && Store.agGrid) {
      Store.getListSearch(Store.search_payload);
    }
  }

  const recordsLoader = (sector) => {
    let section = null;
    if (sector === 'People') {
      section = 'people_loader';
    }else if (sector === 'Organisations') {
      section = 'organisation_loader';
    }else if (sector === 'Leads') {
      section = 'lead_loader';
    }else if (sector === 'Deals') {
      section = 'deal_loader';
    }else if(sector === 'Master'){
      section = 'master_loader';
    }else if (sector === 'Tickets') {
      section = 'ticket_loader';
    }else if(sector === 'Tags'){
      section = 'tag_loader';
    }else if(sector === 'Reminders'){
      section = 'reminder_loader';
    } else if (sector === 'History') {
      section = 'notes_loader';
    } else if(sector === 'Knowledge') {
      section = 'knowledge_loader';
    }else if(sector === 'Library'){
      section = 'library_loader';
    }else if(sector === 'Key Notes'){
      section = 'key_notes_loader';
    }else{
      section = `${sector.toLowerCase()}_loader`;
    }
    return searchLoader[section];
  }

  if (sectorSelector === 'Tags' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = TagsStore;
    TagsStore.search_payload = payload;
    loadList(Store);
  }
  if (sectorSelector === 'Master' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = TicketsStore;
    mergeOption = true;
    TicketsStore.search_payload = payload;
    if (payload.search_for !== searchTerm && TicketsStore.agGrid) {
      TicketsStore.getMasterListSearch(TicketsStore.search_payload);
    }
  }
  if (sectorSelector === 'Tickets' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = TicketsStore;
    mergeOption = true;
    TicketsStore.search_payload = payload;
    loadList(Store);
  }
  if (sectorSelector === 'People') {
    payload.filter_data = {};
    Store = PEOPLE;
    mergeOption = true;
    PEOPLE.search_payload = payload;
    loadList(Store);
  }
  if (sectorSelector === 'Organisations' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = ORGANISATION;
    mergeOption = true;
    if (sectorSelector === 'Organisations') {
      ORGANISATION.search_payload = payload;
      loadList(Store);
    }
  }
  if (sectorSelector === 'Deals' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = LEADDEALS;
    mergeOption = true;
    LEADDEALS.search_payload = payload;
    loadList(Store);
  }
  if (sectorSelector === 'Opportunities' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = LEADOPPORTUNITY;
    LEADOPPORTUNITY.search_payload = payload;
    loadList(Store);
  }
  if (sectorSelector === 'Leads' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = LEADSSTORE;
    mergeOption = true;
    LEADSSTORE.search_payload = payload;
    loadList(Store);
  }
  if (sectorSelector === 'Reminders' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = REMINDERSSTORE;
    REMINDERSSTORE.search_payload = payload;
    loadList(Store);
  }
  if (sectorSelector === 'History' || sectorSelector === 'all') {
    Store = HISTORY;
    HISTORY.search_payload = payload;
    if (payload.search_for !== searchTerm && HISTORY.agGrid) {
      HISTORY.setupGrid(HISTORY.agGrid);
    }
  }
  if (sectorSelector === 'Knowledge' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = KNOWLEDGE;
    KNOWLEDGE.search_payload = payload;
    loadList(Store);
    // if (payload.search_for !== searchTerm && KNOWLEDGE.agGrid) {
    //   KNOWLEDGE.getList();
    // }
  }
  if (sectorSelector === 'Library' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = LIBRARYSEARCHSTORE;
    LIBRARYSEARCHSTORE.search_payload = payload;
    KeyNotesSearchStore.search_payload = payload;
    /* if (payload.search_for !== searchTerm && LIBRARYSEARCHSTORE.agGrid) {
      LIBRARYSEARCHSTORE.getList();
    } */
  }
  if (sectorSelector === 'Key Notes' || sectorSelector === 'all') {
    payload.filter_data = {};
    Store = KeyNotesSearchStore;
    KeyNotesSearchStore.search_payload = payload;
    if (payload.search_for !== searchTerm && KeyNotesSearchStore.agGrid) {
      KeyNotesSearchStore.getList();
    }
  }

  useEffect(() => {
    if (payload?.search_for) {
      SEARCHSTORE.setIntialCount();
      // SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'all');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'organisation');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'active');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'ticket');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'master');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'tag');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'reminder');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'people');
      //SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'opporunity');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'deal');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'history');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'knowledge');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'library');
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'key-notes');
    }
    return () => {
      setResultCount(0);
    };
  }, [setResultCount, SEARCHSTORE, payload.search_for]);

  const handleRoute = () => {
    let sectorSelectorLowerCase = sectorSelector.toLowerCase();
    if (['Deals'].includes(sectorSelector)) { // Removed due to no longer in use 'Opportunities', 'UnusedLeads'
      history.push(`/leads/${sectorSelectorLowerCase}`);
    } else if (sectorSelector === 'Leads') {
      history.push(`/leads/active`);
    } else if (['People', 'Organisations'].includes(sectorSelector)) {
      history.push(`/contacts/${sectorSelectorLowerCase}`);
    } else if (['Library'].includes(sectorSelector)) {
      history.push(`/deals-${sectorSelectorLowerCase}`);
      SEARCHSTORE.setViewAllSreachPage(sectorSelector);
    } else if (['Key Notes'].includes(sectorSelector)) {
      history.push("/deals-library");
      SEARCHSTORE.setViewAllSreachPage(sectorSelector);
    }
    else {
      history.push(`/${sectorSelectorLowerCase}`);
    }
  };

  // const handleViewAll = (path) => {
  //   history.push(`/search/${path}?q=${search_term}`);
  // };


  /** Merge Function */
  const toggleMergeTicket = () => {
    if (showCheckbox) {
      openMergeModal();
    } else {
      setshowCheckbox(true);
    }
  };
  const openMergeModal = () => {
    let selectedRow = Store?.agGrid.api.getSelectedRows();

    if (sectorSelector === 'People') {
      PEOPLE.isMergePeople = false;
    } else if (sectorSelector === 'Organisations') {
      ORGANISATION.isMergePeople = false;
    } else if (sectorSelector === 'Tickets') {
      TicketsStore.isMergePeople = false;
    } else if (sectorSelector === 'Leads') {
      LEADSSTORE.isMergePeople = false;
    } else if (sectorSelector === 'Deals') {
      LEADSSTORE.isMergePeople = false;
    } else if (sectorSelector === 'Master') {
      TicketsStore.isMergePeople = false;
    }

    if (selectedRow.length >= 2) {
      setMergeVisibleModal(true);
      setSelectedMergeData(selectedRow);
      setSearchResCount(selectedRow.length);
    } else {
      setSearchResCount(0);
      vsmNotify.error({
        message: `${t('Common_Data.Please_Select_Records')} 2 ${t('Common_Data.Record')}.`,
      });
    }
  };
  const closeMergeModal = () => {
    setshowCheckbox(false);
    setMergeVisibleModal(false);
    setSelectedMergeData(null);
    if (Store?.agGrid) {
      Store?.agGrid.api.deselectAll();
    }
    setSelectedRowsCount(0);
    if (sectorSelector === 'People' && PEOPLE.isMergePeople === true) {
      PEOPLE.getListSearch(PEOPLE.search_payload);
      SEARCHSTORE.updateSearchCount({ people_count: SEARCHSTORE.searchCount.people_count - (searchResCount - 1) });
    } else if (sectorSelector === 'Organisations' && ORGANISATION.isMergePeople === true) {
      // ORGANISATION.getListSearch(ORGANISATION.search_payload);
      SEARCHSTORE.updateSearchCount({ organisation_count: SEARCHSTORE.searchCount.organisation_count - (searchResCount - 1) });
    } else if (sectorSelector === 'Leads' && LEADSSTORE.isMergePeople === true) {
      LEADSSTORE.getListSearch(LEADSSTORE.search_payload);
      SEARCHSTORE.updateSearchCount({ lead_count: SEARCHSTORE.searchCount.lead_count - (searchResCount - 1) });
    } else if (sectorSelector === 'Deals' && LEADSSTORE.isMergePeople === true) {
      LEADDEALS.getListSearch(LEADSSTORE.search_payload);
      SEARCHSTORE.updateSearchCount({ deal_count: SEARCHSTORE.searchCount.deal_count - (searchResCount - 1) });
    } else if (sectorSelector === 'Tickets' && TicketsStore.isMergePeople === true) {
      TicketsStore.getListSearch(TicketsStore.search_payload);
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'ticket');
      //SEARCHSTORE.updateSearchCount({ ticket_count: SEARCHSTORE.searchCount.ticket_count - ( searchResCount - 1 )});
    } else if (sectorSelector === 'Master' && TicketsStore.isMergePeople === true) {
      TicketsStore.getMasterListSearch(TicketsStore.search_payload);
      SEARCHSTORE.setSearchCount({ search_for: payload.search_for }, 'master');
      //SEARCHSTORE.updateSearchCount({ master_ticket_count: SEARCHSTORE.searchCount.master_ticket_count - ( searchResCount - 1 )});
    }
  };
  const selectionChanged = () => {
    let selectedRow = Store?.agGrid?.api?.getSelectedRows();
    setSelectedRowsCount(selectedRow?.length > 0 ? selectedRow?.length : 0);
  };
  const onClearSelectionClick = () => {
    if (Store?.agGrid) {
      Store?.agGrid.api.deselectAll();
    }
    setshowCheckbox(false);
  };
  useEffect(() => setshowCheckbox(false), [sectorSelector])/**to make false to the checkbox/merge after changing sector */

  let currentPage = history?.location?.pathname?.split('/')[2];
  useEffect(() => {
    updateSearchCount(SEARCHSTORE.searchCount)
  }, [SEARCHSTORE.searchCount])

  return search_term ? (
    <>
      <Row>
        {/* Search Results */}
        <Col flex='160px' className='SearchSector'>
          <div className='title'>
            <strong>
              {t('Search.Search_Data')} {t('Reports.Results')}
            </strong>
          </div>

          <ul className='list'>
            {plainOptions &&
              plainOptions.map((item, index) => (
                <li
                  key={index}
                  className={item.label === "All" ? "noLink" : `${item.label !== currentPage && "cursorPointer"} ${sectorSelector === item.value && 'active'}`}
                >
                  {item.disabled
                    ? (
                      <div className='disablesearch'>
                        {item.label === "All" ? "Total" : item.label}{' '}
                        {searchLoader[item.loader]
                          ? (
                            <Spin size='small' spinning={searchLoader[item.loader]} />
                          )
                          : (
                            <span>
                              {item.value === 'all'
                                ? Object.values(getSearchCount).reduce((a, b) => a + b)
                                  .toLocaleString('en-GB')
                                : getSearchCount[item.count]
                                  ? getSearchCount[item.count].toLocaleString('en-GB')
                                  : 0}
                            </span>
                          )}
                      </div>
                    )
                    : (item.label === currentPage)
                      ? <span className='currentPage'>
                        {item.label === "All" ? "Total" : item.label}{' '}
                        {searchLoader[item.loader] ? (
                          <Spin size='small' spinning={searchLoader[item.loader]} />
                        ) : (
                          <span>
                            {item.value === 'all'
                              ? Object.values(getSearchCount)
                                .reduce((a, b) => a + b)
                                .toLocaleString('en-GB')
                              : getSearchCount[item.count]
                                ? getSearchCount[item.count].toLocaleString('en-GB')
                                : 0}
                          </span>
                        )}
                      </span>
                      : (
                        <Link
                          to={item.label === "All" ? null : `/search/${item.value}?q=${encodeURIComponent(search_term)}`}
                          className={item.label === "All" ? "noLink" : null}
                        >
                          {item.label === "All" ? "Total" : item.label}{' '}
                          {searchLoader[item.loader] ? (
                            <Spin size='small' spinning={searchLoader[item.loader]} />
                          ) : (
                            <span>
                              {item.value === 'all'
                                ? Object.values(searchCount)
                                  .reduce((a, b) => a + b)
                                  .toLocaleString('en-GB')
                                : searchCount[item.count]
                                  ? searchCount[item.count].toLocaleString('en-GB')
                                  : 0}
                            </span>
                          )}
                        </Link>
                      )}
                </li>
              ))}
          </ul>

          {/* <BrowserRouter basename='/search'>
          <ul className='list'>
            {plainOptions &&
              plainOptions.map((item, index) => (
                <li
                  key={index}
                  className={`cursorPointer ${sectorSelector === item.value && 'active'}`}
                  onClick={() => {
                    !item.disabled &&
                      history.push(
                        `/search/${item.value}?q=${encodeURIComponent(search_term)}`
                      );
                  }}
                >
                  {item.label}{' '}
                  {searchLoader[item.loader] ? (
                    <Spin size='small' spinning={searchLoader[item.loader]} />
                  ) : (
                    <span>
                      {item.value === 'all'
                        ? Object.values(searchCount)
                            .reduce((a, b) => a + b)
                            .toLocaleString('en-GB')
                        : searchCount[item.count]
                        ? searchCount[item.count].toLocaleString('en-GB')
                        : 0}
                    </span>
                  )}
                </li>
              ))}
          </ul>
        </BrowserRouter> */}
        </Col>

        {/* Search Result Listings */}
        <Col flex='1' className='SearchResult'>
          {sectorSelector === 'all' ? null : (
            <PageHeader
              className={SETTINGS.fullscreen_class + ' SearchResult-PageHeader '}
              title={
                <div className='commonHeader notes'>
                  <Title level={3}>
                    {sectorSelector} : <Tooltip title={search_term} placement="topLeft">"{search_term?.length > 25 ? search_term.substring(0, 25) + "..." : search_term}"</Tooltip> {`${t('DealDesk.Results')}`}
                  </Title>
                  <div className='searchView'>
                    <span className='sep'>|</span>
                    {recordsLoader(sectorSelector)
                      ? <span><Spin size='small' style={{ display: "inline-block" }} /></span>
                      : resultCount && resultCount.toLocaleString('en-GB')
                    }

                    {" " + t('Common_Data.Records_found')}
                    {!["History", "Master"].includes(sectorSelector) &&
                      <>
                        <span className='sep'>|</span>
                        <span className='cursorPointer text-decoration-underline mr-10' onClick={handleRoute} >
                          {t('Common_Data.View_all')} {sectorSelector}
                        </span>
                      </>
                    }
                  </div>
                </div>
              }
              extra={[
                <Form form={form} className='innerForm headerButton'>
                  <Space size={5}>
                    {/* Clear button for Merge */}
                    {selectedRowsCount || showCheckbox ? (
                      <Tooltip title={`${t('Common_Data.Click')} ${t('Common_Data.To')} ${t('Common_Data.Clear')}`}>
                        <Button
                          key='1'
                          shape='round'
                          size='medium'
                          onClick={onClearSelectionClick}
                        >
                          <img
                            src={AUTH.GetThemedImage('Refresh_NoSpace')}
                            alt=''
                            className='icon'
                          />
                        </Button>
                      </Tooltip>
                    ) : null}
                    {/** Merge Button */}
                    {mergeOption && (<Tooltip title={showCheckbox ? `${t('Common_Data.Click')} ${t('Common_Data.To')} ${t('Common_Data.Merge')}` : `${t('Common_Data.Start')} ${t('PeopleManagement.Merging')}`}>
                      <Button
                        key='2'
                        shape='round'
                        size='medium'
                        onClick={toggleMergeTicket}
                      >
                        <img
                          src={AUTH.GetThemedImage('Hyperlink_icn')}
                          alt=''
                          className='icon'
                        />
                      </Button>
                    </Tooltip>)}
                    <ResetGridOptions key='3' pageType={sectorSelector.toLowerCase()} Store={Store} />
                    <EnterFullscreen key='4' />
                    <RecordsSelect
                      key='5'
                      value={Store?.per_page}
                      onChange={Store?.setPageSize}
                    />
                  </Space>
                </Form>,
              ]}
            >
              {(sectorSelector === 'Deals' &&
                <DealSearch SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} showCheckbox={showCheckbox} />)
                // <Deals SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} showCheckbox={showCheckbox} />)

                || (sectorSelector === 'Opportunities' &&
                  (<Opportunities SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />))

                || (sectorSelector === 'Leads' &&
                  <LeadSearch SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} showCheckbox={showCheckbox} />)

                || (sectorSelector === 'Tags' &&
                  <TagsSearch SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />)

                // <Tags SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />)

                || (sectorSelector === 'Tickets' &&
                  <TicketsSearch SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} showCheckbox={showCheckbox} />)
                // <Tickets SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} showCheckbox={showCheckbox} />)

                || (sectorSelector === 'Master' &&
                  <MasterTicketSearch SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} showCheckbox={showCheckbox} />)

                || (sectorSelector === 'Organisations' &&
                  (<Organisations searchPayload={payload} SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} showCheckbox={showCheckbox} />)
                  // (<Organisations SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />)
                )

                || (sectorSelector === 'Knowledge' &&
                  <KnowledgeSearch searchPayload={payload} SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />
                  // <Knowledge SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />
                )

                || (sectorSelector === 'Contracts' &&
                  <Contracts SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />)

                || (sectorSelector === 'People' &&
                  <PeopleSearch searchPayload={payload} SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} showCheckbox={showCheckbox} />
                  // <People SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />
                )

                || (sectorSelector === 'History' &&
                  <History SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />)

                || (sectorSelector === 'Reminders' &&
                  <RemindersSearch SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />)
                // <Reminders SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />)

                || (sectorSelector === 'Library' &&
                  <LibrarySearch searchPayload={payload} SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} />
                  // (<LibrarySearch SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />)
                )
                || (sectorSelector === 'Key Notes' &&
                  <KeyNotesSearch searchPayload={payload} SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} selectionChanged={selectionChanged} />
                )

                || <PageNotFound SearchPage={SearchPage} sectorSelector={sectorSelector} search_term={search_term} resultCount={resultCount} handleRoute={handleRoute} />
              }

            </PageHeader>)}
          <MergeModal
            sectorSelector={sectorSelector}
            mergeVisibleModal={mergeVisibleModal}
            closeMergeModal={closeMergeModal}
            selectedMergeData={selectedMergeData}
          />
        </Col>
      </Row>
      {/* <TrackTimeComponent pageName={'search'} /> */}
      {searchSection === "all" ? <TimeTracker
        page={"search"}
      /> : null}
    </>
  ) : (
    <PageNotFound SearchPage={SearchPage} />
  );
});
export default Search;
