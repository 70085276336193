const LocalGridConfig = {
  options: {
    animateRows: true,
    pagination: false,
    paginationPageSize: 100,
    enableRangeSelection: true,
    rowHeight: 30,
    suppressDragLeaveHidesColumns: true,
  },
  defaultColDef: {
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      suppressAndOrCondition: true
    },
    floatingFilter: true,
    flex: 1,
    minWidth: 70
  },
  columnTypes: {
    actionColumn: {
      cellRenderer: 'ActionRenderer'
    }
  },
  domLayout: 'autoHeight'
};

export default LocalGridConfig;
