import { decorate, observable, action } from 'mobx';
import Axios from 'axios';

export default class TimeTrackerStore {
    isPopupInitialized = null;
    isWorkingPopupClicked = null;
    isBackPopup = null;
    isBackPopupClicked = false;
    leadTransactionId = null;
    stop = false;
    stopForDesk = false;
    stopForWaste = false;

    setIsPopupInitialized = (data) => {
        this.isPopupInitialized = data;
    }
    setIsBackPopup = (data) => {
        this.isBackPopup = data;
    }
    setIsWorkingPopupClicked = (data) => {
        this.isWorkingPopupClicked = data;
    }
    setIsBackPopupClicked = (data) => {
        this.isBackPopupClicked = data;
    }
    setLeadTransactionId = (data) => {
        this.leadTransactionId = data;
    }
    setStop = (data) => {
        this.stop = data;
    }
    setStopForDesk = (data) => {
        this.stopForDesk = data;
    }
    setStopForWaste = (data) => {
        this.stopForWaste = data;
    }

    saveTimeTracked = (data) => {
        return Axios.post(`leads-transactions/investmenthours`, data)
            .then(({ data }) => {
                this.setLeadTransactionId(null);
                return data;
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    };
}
decorate(TimeTrackerStore, {
    isPopupInitialized: observable,
    isWorkingPopupClicked: observable,
    isBackPopup: observable,
    isBackPopupClicked: observable,
    leadTransactionId: observable,
    stop: observable,
    stopForDesk: observable,
    stopForWaste: observable,

    setIsPopupInitialized: action,
    setIsWorkingPopupClicked: action,
    setIsBackPopup: action,
    setIsBackPopupClicked: action,
    setLeadTransactionId: action,
    saveTimeTracked: action,
    setStop: action,
    setStopForDesk: action,
    setStopForWaste: action,
});