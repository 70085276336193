import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form, Input } from 'antd';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { vsmNotify, vsmTicket } from '../../../config/messages';
import InputComponent from '../../../component/InputComponent';
import TimeTracker from '../../../component/TimeTracker';

const ViewComponent = observer((props) => {
  const { openEditDrawer = () => { }, editOpen = false, isFromWaste = false, } = props;
  const {
    AUTH,
    TicketsStore,
    TicketsStore: { setViewValues, dropdown_ticket_type },
    DEALDESKNOTESEQUENCE,
    DEALDESKSTORE,
  } = useStore();
  const { t } = useTranslation()

  const [form] = Form.useForm();

  const [viewDrawer, setViewDrawer] = useState(false);
  const [id, setId] = useState();
  const [viewValues, setStateViewValues] = useState();
  const [isDisabled, setDisabled] = useState(true);
  const [isDisabledResetDeadline, setDisabledResetDeadline] = useState(true);
  const [isDeadlineReset, SetIsDeadlineReset] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingHours, setSavingHours] = useState(false);
  const [callAPIOnce, setCallAPIOnce] = useState(false);
  const [isHoursReset, setIsHoursReset] = useState(false);


  const [smallDeadlineDate, setSmallDeadlineDate] = useState(false);

  //View drawer
  // const openViewDrawer = (data) => {
  //   TicketsStore.viewValues = null;
  //   setId(data);
  //   setViewDrawer(true);
  // };

  const closeViewDrawer = () => {
    TicketsStore.viewValues = null;
    setId(null);
    setViewDrawer(false);

  };

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
    setSaving(false);
    // TicketsStore.viewValues = null;
    setDisabledResetDeadline(true);
    SetIsDeadlineReset(true);
    setCallAPIOnce(false)
  };

  let priority_name = null;
  let tickettype_name = null;

  if (TicketsStore.dropdown_priority?.length > 0 && viewValues?.priority) {
    let priority = viewValues.priority;
    priority && TicketsStore.dropdown_priority.forEach((x) => {
      if (x.value === parseInt(priority) && !priority_name) {
        priority_name = x.text;
      }
    });
  }
  if (dropdown_ticket_type.length > 0 && viewValues?.ticket_type) {
    let ticket_type = viewValues.ticket_type;
    ticket_type &&
      dropdown_ticket_type.forEach((x) => {
        if (x.value === parseInt(ticket_type) && !tickettype_name) {
          tickettype_name = x.text;
        }
      });
  }

  var decimalTime = viewValues?.hours_required ? viewValues?.hours_required : 0;
  var hours = Math.floor((decimalTime / (60 * 60)));
  decimalTime = decimalTime - (hours * 60 * 60);
  var minutes = Math.floor((decimalTime / 60));

  useEffect(() => {
    if (callAPIOnce === true) {
      if (props.visible && props?.id) {
        TicketsStore.setViewValues(props?.id); setCallAPIOnce(true)
      }
    }
    if (props.visible && !TicketsStore.viewValues && !TicketsStore.apicalled) {
      setViewValues(id ? id : props.id).then((data) => {
        if (data) {
          setStateViewValues(data);
        } else {
          TicketsStore.apicalled = true; // Temp solution- infinte api call for waste ticket
        }
      });
    }
    if (viewValues?.is_deadline_reset === true) {
      SetIsDeadlineReset(true)
    } else {
      SetIsDeadlineReset(false)
    }

    if (TicketsStore.viewValues) {
      if (TicketsStore.is_data_modified) {
        setStateViewValues(TicketsStore.viewValues);
      }
    }
    if (hours || minutes) {
      form.setFieldsValue({
        hours_required_hour: hours ? hours : 0,
        hours_required_min: minutes ? minutes : 0,
      })
    }
    if (viewValues) {

      if (viewValues.is_hours_required_reset === true) {
        setIsHoursReset(true)
      }
      else {
        setIsHoursReset(false)
      }

      form.setFieldsValue({
        new_deadline_date: viewValues.deadline_date && moment(viewValues.deadline_date),
        hours_required_hour: hours ? hours : 0,
        hours_required_min: minutes ? minutes : 0,
      })
    }


  }, [
    callAPIOnce,
    props.visible,
    props.ticketDesk,
    props.id,
    TicketsStore.apicalled,
    id,
    setViewValues,
    TicketsStore,
    TicketsStore.is_data_modified,
    TicketsStore.viewValues,
    form,
    hours, minutes,
    viewValues
  ]);


  const handleSubmit = (data, setCompleted = false) => {
    data.hours_required = 0
    if (data.hours_required_hour || data.hours_required_min) {
      let setTotalTime = (data.hours_required_hour && parseInt(data.hours_required_hour * 60)) + (data.hours_required_min && parseInt(data.hours_required_min))
      data.hours_required = parseInt(setTotalTime) * 60
    }


    let payload = {
      id: viewValues?.id,
      leads_transactions_id: viewValues?.leads_transactions_id && viewValues?.leads_transactions_id,
      is_masterTicket: viewValues?.is_masterTicket ? viewValues?.is_masterTicket : false,
      parent_ticket_id: viewValues?.parent_ticket_id ? viewValues?.parent_ticket_id : null,
      ticket_subject: viewValues?.ticket_subject ? viewValues?.ticket_subject : null,
      ticket_description: viewValues?.ticket_description ? viewValues?.ticket_description : null,

      priority: viewValues?.priority ? viewValues?.priority : null,
      deadline_date: viewValues?.deadline_date ? viewValues?.deadline_date : null,
      is_org: viewValues?.is_org && viewValues?.is_org,
      organisation_id: viewValues?.organisation?.id ? viewValues?.organisation_id : null,
      organisation_name: viewValues?.organisation_name ? viewValues?.organisation_name : null,
      holder: viewValues?.ticket_holder?.id ? viewValues?.ticket_holder?.id : null,
      hours_required: data.hours_required && data.hours_required,
    }


    if (setCompleted === true) {
      payload.ticket_status = 7
    } else {
      payload.ticket_status = viewValues?.ticket_status?.id ? viewValues?.ticket_status?.id : null
    }

    setSavingHours(true);
    TicketsStore.editTicket(payload.id, payload)
      .then((response) => {
        close();
        DEALDESKSTORE.getDealDetail(viewValues?.ticket_number);
        DEALDESKNOTESEQUENCE.fetchSequenceNotes(viewValues?.leads_transactions_id ? viewValues?.leads_transactions_id : viewValues?.leads_transaction?.id);
        form.resetFields();
        vsmNotify.success({
          message: vsmTicket.edit,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleChange = debounce(() => {

    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);


  const handleDeadlineChange = debounce((event) => {
    if (event <= moment().subtract(1, 'seconds')) {
      setSmallDeadlineDate(true)
    }
    else {
      setSmallDeadlineDate(false)
    }
    // setSaving(true);
    form
      .validateFields()
      .then((data) => {
        if (event === null) {
          setDisabledResetDeadline(true);
        } else {
          setDisabledResetDeadline(false);
          SetIsDeadlineReset(false);
        }

      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabledResetDeadline(true);
          SetIsDeadlineReset(true);
        }
      });
  }, 200);


  const handleResetDeadline = () => {
    let getNewDeadlineDate = form.getFieldValue('new_deadline_date');
    let payload = getNewDeadlineDate && {
      new_deadline_date: getNewDeadlineDate && moment(getNewDeadlineDate).format(AUTH.form_submit_date_with_seconds)
    }

    setSaving(true);
    TicketsStore.applyResetDeadline(TicketsStore?.viewValues?.id, payload)
      .then((res) => {
        DEALDESKSTORE.getDealDetail(viewValues?.ticket_number);
        DEALDESKNOTESEQUENCE.fetchSequenceNotes(viewValues?.leads_transaction?.id);
        close();
        setDisabledResetDeadline(true);
        SetIsDeadlineReset(true);
        setCallAPIOnce(false)
        vsmNotify.success({
          message: res.message,
        });

      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleResetHoursRequired = (data) => {
    data.hours_required = 0
    if (data.hours_required_hour || data.hours_required_min) {
      let setTotalTime = (data.hours_required_hour && parseInt(data.hours_required_hour * 60)) + (data.hours_required_min && parseInt(data.hours_required_min))
      data.hours_required = parseInt(setTotalTime) * 60
    }
    let payload = {
      hours_required: data.hours_required,
    }
    setSaving(true);
    TicketsStore.applyResetHoursRequired(TicketsStore?.viewValues?.id, payload)
      .then((res) => {
        DEALDESKSTORE.getDealDetail(viewValues?.ticket_number);
        DEALDESKNOTESEQUENCE.fetchSequenceNotes(viewValues?.leads_transaction?.id);
        close();
        setCallAPIOnce(false)
        vsmNotify.success({
          message: res.message,
        });

      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };


  let AdminIsLogging = AUTH?.user?.id === 1;
  // let OwnerIsLogging = AUTH?.user?.id === viewValues?.ticket_owner?.id;
  let HolderIsLogging = AUTH?.user?.id === viewValues?.ticket_holder?.id;
  // let SameOwnerHolder = OwnerIsLogging === HolderIsLogging;

  return (
    <>
      {viewValues && <Drawer
        visible={props.visible}
        onClose={() => close()}
        placement='right'
        width={`${editOpen ? '1050px' : '1100px'}`}
        title={`${t('Common_Data.View')} ${t('Common_Data.Ticket')} - #${viewValues?.ticket_number ? viewValues?.ticket_number : ''
          }`}
        destroyOnClose={true}
        zIndex={editOpen ? 1002 : 1001}
        footer={[
          <div className='text-right'>

            {!isFromWaste && viewValues?.ticket_number &&
              <Link key='4'
                to={`/ticket/${viewValues?.ticket_number}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  className='menuicon'
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '15px',
                    right: '20px',
                    width: '20px',
                  }}
                  alt=''
                  title={`${t('Common_Data.View')} ${t('Tickets.Chat')} ${t('Tickets.History')}`}
                  src={AUTH.GetThemedImage('Tickets')}
                />
              </Link>
            }
            {/* {AUTH.checkPermission(15, 'edit') && !editOpen && !isFromWaste && (
              <img key='3'
                className='menuicon'
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '17px',
                  right: '60px',
                  width: '20px',
                }}
                alt=''
                title={`${t('Common_Data.Edit')} ${t('Common_Data.Ticket')}`}
                src={AUTH.GetThemedImage('Edit')}
                onClick={() => openEditDrawer(viewValues, true)}
              />
            )} */}

            {HolderIsLogging && <Button key='1'
              className='mr-10 markAsCompletedTag'
              type='primary'
              shape='round'
              onClick={() => {
                handleSubmit(form.getFieldsValue(), true);
              }}
            >Mark as Completed</Button>}

            <Button shape='round' onClick={close} key='3'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>
        ]}
      >
        <div className='viewPeople'>

          <table cellPadding='0' cellSpacing='0' border='0'>
            <tbody>
              <tr>
                <td width='18%' style={{ border: '0' }}></td>
                <td width='31%' style={{ border: '0' }}></td>
                <td width='18%' style={{ border: '0' }}></td>
                <td width='33%' style={{ border: '0' }}></td>
              </tr>
              <tr>
                <th>{t('Common_Data.Subject')}:</th>
                <td colSpan='3'>{viewValues?.ticket_subject}</td>
              </tr>
              <tr>
                <th>{t('Common_Data.Organisation')}:</th>
                <td colSpan='3'>{viewValues?.organisation?.organisation_name}</td>
              </tr>
              <tr>
                <th>{t('Common_Data.Ticket')} {t('Common_Data.Number')}:</th>
                <td>{viewValues?.ticket_number}</td>
                <th>{t('DealDesk.Master_Ticket')} {t('Common_Data.Number')}:</th>
                <td
                  className='cursor'
                // onClick={() => {
                //   if (viewValues?.master_ticket) {
                //     openViewDrawer(viewValues?.master_ticket);
                //   }
                // }}
                >
                  {viewValues?.master_ticket?.master_ticket_number}{" "}
                  {viewValues?.master_ticket?.master_ticket_number && <Link to={`/ticket/${viewValues?.master_ticket?.master_ticket_number}`} target={'_blank'}>
                    <img alt='' src={AUTH.GetThemedImage('Tickets')} style={{ width: '14px', marginLeft: "5px", marginTop: "-3px" }} />
                  </Link>}
                </td>
              </tr>
              <tr>
                <th>{t('Common_Data.Owner')}:</th>
                <td>
                  {viewValues.ticket_owner &&
                    viewValues?.ticket_owner?.first_name +
                    ' ' +
                    viewValues?.ticket_owner?.last_name}
                </td>
                <th>{t('DealDesk.Master_Ticket')} {t('Common_Data.Subject')}:</th>
                <td>{viewValues?.master_ticket?.ticket_subject}</td>
              </tr>
              <tr>
                <th>{t('Common_Data.Holder')}:</th>
                <td>
                  {viewValues.ticket_holder &&
                    viewValues?.ticket_holder?.first_name +
                    ' ' +
                    viewValues?.ticket_holder?.last_name}
                </td>
                <th>{t('DealDesk.Master_Ticket')} {t('Common_Data.Description')}:</th>
                <td>{viewValues?.master_ticket?.ticket_description}</td>
              </tr>
              <tr>
                <th>{t('Tickets.Age')}{`'(${t('Tickets.Day')}(s))'`}:</th>
                <td>{viewValues?.age}</td>
                <th>{t('Common_Data.Idle')}{`'(${t('Tickets.Day')}(s))'`}:</th>
                <td>{viewValues?.idle}</td>
              </tr>
              <tr>
                <th>{t('Common_Data.Status')}:</th>
                <td>{viewValues?.ticket_status?.status_name}</td>
                <th>{t('Common_Data.DeadLine')} {t('Common_Data.Date')}:</th>
                <td>
                  {HolderIsLogging || AdminIsLogging
                    ? <Form form={form} layout='vertical' id={"updateDeadline"} onFinish={handleResetDeadline} className='innerForm' onChange={handleDeadlineChange}>
                      <div style={{ display: 'flex', alignItems: "center" }}>
                        <InputComponent
                          name='new_deadline_date'
                          // label={`${t('LeadKanbanView.DeadLine_Date')}`}
                          type='date'
                          // format='YYYY-MM-DD hh:mm a'
                          disabledDate={true}
                          showTime={true}
                          initialValue={moment()}
                          placeholder={`${t('LeadKanbanView.DeadLine_Date')}`}
                          tooltip={`${t('Tickets.Deadline_Tooltip')}.`}
                          onChange={handleDeadlineChange}
                          // onClear={handleDeadlineChange}
                          disabled={isDeadlineReset}
                        />


                        <Button type='secondary' size='small' className='noLable ticketDeadline m-0'
                          disabled={smallDeadlineDate ? true : isDisabledResetDeadline}
                          onClick={() => { handleResetDeadline() }}
                          loading={saving}
                        >
                          <img alt='' className='icon' src={AUTH.GetThemedImage('Deadline')} /> Reset Deadline
                        </Button>
                        {/* <Button key='2'
                        form='updateDeadline'
                        className='noLable ticketDeadline m-0'
                        loading={saving}
                        htmlType='submit'
                        type='secondary'
                        size='small'
                        shape='round'
                        disabled={isDisabledResetDeadline}
                        >
                        <img alt='' className='icon' src={AUTH.GetThemedImage('Deadline')} /> Reset Deadline
                      </Button> */}
                      </div>
                      {!isDeadlineReset && smallDeadlineDate && <div class="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert" style={{ fontSize: "1.2rem", color: '#ff4d4f' }}>Deadline is invalid.</div>
                      </div>}
                    </Form>
                    : AUTH.getLocalTime(viewValues?.deadline_date)
                  }
                </td>
              </tr>
              <tr>
                <th colSpan='4'>{t('Common_Data.Description')}:</th>
              </tr>
              <tr>
                <td
                  colSpan='4'
                  dangerouslySetInnerHTML={{
                    __html: viewValues?.ticket_description_html,
                  }}
                ></td>
              </tr>
              <tr>
                <th>{t('Common_Data.Priority')}:</th>
                <td>
                  {/* {TicketsStore.priority_name ? TicketsStore.priority_name : priority_name} */}
                  {viewValues && viewValues?.ticket_priority?.priority}
                </td>
                <th>{t('Common_Data.Ticket')} {t('Common_Data.Type')}:</th>
                <td>
                  {TicketsStore.tickettype_name
                    ? TicketsStore.tickettype_name
                    : tickettype_name}
                </td>

              </tr>

              <tr>
                <th>{t('Common_Data.Created')} {t('Common_Data.By')}</th>
                <td>{viewValues?.created_by_name}</td>
                <th>{t('Common_Data.Created')} {t('Common_Data.At')}</th>
                <td>
                  {viewValues && viewValues?.created_date
                    ? viewValues?.created_time
                      ? `${AUTH.getLocalTime(
                        viewValues?.created_date + ' ' + viewValues?.created_time,AUTH.global_fulldate_format
                      )}`
                      : `${AUTH.getLocalTime(moment(viewValues?.created_date))}`
                    : ''}
                </td>
              </tr>
              <tr>
                <th>{t('Common_Data.Last_Updated_By')}</th>
                <td>{viewValues?.updated_by_name}</td>
                <th>{t('Common_Data.Last_Updated_At')}</th>
                <td>
                  {viewValues && viewValues?.updated_date
                    ? viewValues?.updated_time
                      ? `${AUTH.getLocalTime(
                        viewValues?.updated_date + ' ' + viewValues?.updated_time,AUTH.global_fulldate_format
                      )}`
                      : `${AUTH.getLocalTime(moment(viewValues?.updated_date))}`
                    : ''}

                  {/* {viewValues && viewValues?.updated_date
                  ? AUTH.getLocalTimeDealDesk(
                    moment(viewValues?.updated_date),
                    // AUTH.global_dateformat
                  )
                  : ''} */}
                </td>
              </tr>
              <tr>
                <th>Time Required</th>
                <td>
                  {HolderIsLogging || AdminIsLogging
                    ? !AdminIsLogging && isHoursReset
                      ? (hours || minutes) && `${hours === 0 ? "00" : hours}:${minutes === 0 ? '00' : minutes}`
                      : (<Form form={form} layout='vertical' id={"ResetHoursRequired"}
                        className='innerForm' onChange={handleChange}>
                        <Input.Group>
                          <Form.Item name={'hours_required_hour'} noStyle>
                            <Input
                              name='hours_required_hour'
                              type='number'
                              suffix="hh"
                              onChange={(e) => { handleChange() }}
                              className="w-50 noAppearance"
                              defaultValue={'0'}
                              min="0"
                              max='9999'
                              placeholder='0'
                              required
                            />
                          </Form.Item>
                          <Form.Item name={'hours_required_min'} noStyle>
                            <Input
                              name='hours_required_min'
                              type='number'
                              suffix="mm"
                              onChange={(e) => { handleChange() }}
                              className="w-50 noAppearance"
                              defaultValue={'0'}
                              min="0"
                              max='59'
                              placeholder='0'
                              required
                            />
                          </Form.Item>
                          <Button key='2'
                            form='ResetHoursRequired'
                            className='m-0'
                            loading={savingHours}
                            // htmlType='submit'
                            type='primary'
                            shape='round'
                            disabled={isDisabled}
                            onClick={() => {
                              handleResetHoursRequired(form.getFieldsValue());
                            }}
                          >Update</Button>
                        </Input.Group>
                      </Form>)

                    : (hours || minutes) && `${hours === 0 ? "00" : hours}:${minutes === 0 ? '00' : minutes}`
                  }
                </td>
                <td colSpan={2}></td>
              </tr>
            </tbody>
          </table>
        </div>
        <ViewComponent
          visible={viewDrawer}
          id={id}
          setId={setId}
          openEditDrawer={openEditDrawer}
          close={closeViewDrawer}
        />
        {/* {viewValues && !editOpen && (
          <TrackTimeComponent
            pageName={'tickets'}
            leadId={
              viewValues?.leads_transactions_id
                ? viewValues?.leads_transactions_id
                : 0
            }
          />
        )} */}
      </Drawer>}
      <TimeTracker
        page={"tickets"}
        id={viewValues?.leads_transactions_id}
        stop={!props.visible || props.editDrawer}
        fromSideBar={props?.fromSideBar}
      />
    </>
  )
});

export default ViewComponent;
