import { Button, Drawer, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages';
import PageNotFound from '../../../PageNotFound';
import Unauthorized from '../../../Unauthorized';
import useStore from '../../../../store';
import FormComponent from './NewFormComponent';
import { saveFilterArr } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation()
  const history = useHistory();
  const [form] = useForm();
  const [saving, setSaving] = useState(true);
  const [submitSaving, setSubmitSaving] = useState(false);
  const [savePreviewSaving, setsavePreviewSaving] = useState(false);

  const [tip, setTip] = useState('Loading');
  const [isDisabled, setDisabled] = useState(false);
  const { SavedReportStore, AUTH, PEOPLE, ORGANISATION, LEADSSTORE } = useStore();
  const [formDataList, setFormDataList] = useState([]);
  const [saveFilterList, setsaveFilterList] = useState([]);
  const defaultFormDataList = [
    'report_type',
    'name',
    'code',
    'description',
    'global_toggle',
  ];
  const [isInitiated, setInitiation] = useState(false);

  const InitaitePreviewReport = useCallback(async () => {
    await setFormDataList(SavedReportStore.previewReportValues.form_list);
    var previewReportValues = SavedReportStore.formatFormData(
      SavedReportStore.previewReportValues,
      false
    );

    if (previewReportValues['organisation']) {
      await ORGANISATION.getAllROrg({
        selected_id: previewReportValues['organisation'],
        mapping_type: 1,
      });
    }
    if (previewReportValues['people']) {
      await PEOPLE.getAllPeople({
        selected_id: previewReportValues['people'],
        mapping_type: 2,
      });
    }
    if (previewReportValues['lead_currency_id'] && !LEADSSTORE.dropdown_currency_list) {
      await LEADSSTORE.getCurrencyList();
    }

    await form.setFieldsValue(previewReportValues);
    await setSaving(false);
    if (
      previewReportValues.report_type !== 'leads' &&
      saveFilterArr.includes(previewReportValues.report_type)
    ) {
      if (previewReportValues.report_type === 'reminders') {
        AUTH.getSavedGridFilter({ page: 'reminder' }).then((data) => {
          setsaveFilterList(data);
        });
      } else {
        AUTH.getSavedGridFilter({
          page: previewReportValues.report_type,
        }).then((data) => {
          setsaveFilterList(data);
        });
      }
    }
    if (previewReportValues.report_type === 'investment_hours') {
      let formdata = {
        inactive: true,
      };
      LEADSSTORE.getReportUsersList(formdata);
    }
  }, [SavedReportStore, LEADSSTORE, ORGANISATION, PEOPLE, AUTH, form]);

  useEffect(() => {
    if (!isInitiated && props.report_id && props.visible) {
      setInitiation(true);
      SavedReportStore.ReadReport(props.report_id)
        .then((data) => {
          InitaitePreviewReport();
          // setSaving(false);
          setTip(null);
        })
        .catch(({ response }) => {
          if (response && response.status === 404) {
            setSaving('404');
          }
          if (response && response.status === 403) {
            setSaving('403');
          }
        });
    }
  }, [
    InitaitePreviewReport,
    SavedReportStore,
    isInitiated,
    props.report_id,
    props.visible,
    history,
  ]);

  const savePreviewReport = (data = form.getFieldsValue()) => {
    data.form_list = formDataList;
    data = SavedReportStore.formatFormData({
      ...SavedReportStore.previewReportValues,
      ...data,
    });
    data.action = 'save_report';
    handleSubmit(data, true);
  };

  const openPreviewReport = () => {
    let data;
    SavedReportStore.setPreviewReportValues({
      ...SavedReportStore.previewReportValues,
      ...form.getFieldsValue(),
      form_list: formDataList,
    });
    data = SavedReportStore.formatFormData(
      SavedReportStore.previewReportValues,
      true,
      true
    );
    data.check_unique_code = true;
    data.action = 'preview-data';
    setSaving(true);
    setTip('Loading');
    SavedReportStore.PreviewData(data)
      .then((data) => {
        props.openPreviewDataModal(props.report_id, true);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
        setTip(null);
        setSubmitSaving(false);
        setsavePreviewSaving(false);
      });
  };

  const handleSubmit = (data, isPreview = false) => {
    setSaving(true);
    if (isPreview) {
      setsavePreviewSaving(true);
    } else {
      setSubmitSaving(true);
    }

    setTip('Saving');
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.action = 'save_report';
    data.form_list = formDataList;
    data.id = SavedReportStore.previewReportValues.id;
    data.check_unique_code = true;

    SavedReportStore.EditData(data)
      .then((data) => {
        vsmNotify.success({
          message: vsmTransactionReport.edit,
        });
        if (isPreview) {
          openPreviewReport();
        } else {
          props.close();
        }
        if (SavedReportStore.my_records === 'my') {
          SavedReportStore.getList({}, AUTH.user.id);
        } else {
          SavedReportStore.getList();
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
        setSubmitSaving(false);
        setsavePreviewSaving(false);
        setTip(null);
      });
  };

  const close = () => {
    props.close();
    props.setReportID(null);
    SavedReportStore.previewReportValues = null;
    setInitiation(false);
    setSaving(true);
    setsavePreviewSaving(false);
    setTip('Loading');
  };

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      title={
        `${t('Common_Data.Edit')} ${t('Reports.Report')} -  ` +
        (SavedReportStore.previewReportValues
          ? SavedReportStore.previewReportValues?.name
          : '')
      }
      destroyOnClose={true}
      footer={[
        <div className='text-right' key='1'>
          {AUTH.checkPermission(21, 'preview-data') && (
            <Button
              key='1'
              className='mr-10'
              htmlType='button'
              shape='round'
              disabled={isDisabled}
              onClick={() => {
                openPreviewReport();
              }}
            >
              {t('Reports.Preview')} {t('Reports.Data')}
            </Button>
          )}
          {AUTH.checkPermission(21, 'edit') && (
            <Button
              key='2'
              form='editformReport'
              className='mr-10'
              htmlType='submit'
              shape='round'
              type='primary'
              disabled={isDisabled}
              loading={submitSaving}
            >
              {t('Common_Data.Save')}
            </Button>
          )}
          {AUTH.checkPermission(21, 'add') && (
            <Button
              key='3'
              className='mr-10'
              shape='round'
              disabled={isDisabled}
              onClick={() => {
                savePreviewReport();
              }}
              loading={savePreviewSaving}
            >
              {t('Common_Data.Save')} & {t('Reports.Preview')}
            </Button>
          )}
          <Button shape='round' onClick={close} key='3'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <Spin size='large' spinning={saving === true} tip={tip}>
        {saving === '404' ? (
          <PageNotFound />
        ) : saving === '403' ? (
          <Unauthorized />
        ) : (
          <div className='w-100' style={{ minHeight: '200px' }}>
            {SavedReportStore.previewReportValues ? (
              <>
                {/* <Divider className='pb-5' /> */}
                <FormComponent
                  initialValues={SavedReportStore.initialValues}
                  defaultFormDataList={defaultFormDataList}
                  form={form}
                  formDataList={formDataList}
                  setFormDataList={setFormDataList}
                  handleSubmit={handleSubmit}
                  saveFilterList={saveFilterList}
                  setsaveFilterList={setsaveFilterList}
                  setDisabled={setDisabled}
                  id={'editformReport'}
                />
              </>
            ) : null}
          </div>
        )}
      </Spin>
    </Drawer>
  );
});

export default EditComponent;
