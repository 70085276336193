import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Spin, Popover, Button, Tooltip } from 'antd';
import useStore from '../../../store';
import { vsmNotify, vsmQuickNoteUpdate } from '../../../config/messages';
import NoteRenderer from './NoteRenderer';
import DateTimeConfirmComponent from './DateTimeConfirmComponent';
import { default as HourglassConfirmComponent } from './HourglassConfirmComponent';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../component/DatePickerCostume';

const ActionRenderer = observer((props) => {
  const { t } = useTranslation()
  //const [count, setCount] = useState(0);
  const [extrafooter, setExtrafooter] = useState(null);

  const [date, setDate] = useState();
  const [reminderConfirm, setReminderConfirm] = useState();
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);

  const [openHourglassModalLead, setOpenHourglassModalLead] = useState(false);
  const [leadHourglassConfirm, setLeadHourglassConfirm] = useState();
  const [leadHourglassTime, setleadHourglassTime] = useState(null);

  let history = useHistory();

  const {
    AUTH,
    PROMOTIONSTORE: { updateQuickNote },
    REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();
  const {
    quickNoteList,
    openViewDrawer,
    openEditDrawer,
    openDeleteDrawer,
    openCloneDrawer,
  } = props.agGridReact.props.frameworkComponents;

  /*const handleCount = () => {
    setCount(count + 1);
  };*/

  const handleSubmitQN = (id, data) => {
    let formdata = {
      notes_flag: 1,
      notes_description: data,
    };
    updateQuickNote(id, formdata)
      .then(() => {
        vsmNotify.success({
          message: vsmQuickNoteUpdate.successQuickNoteUpdate,
        });
      })
      .catch((e) => {
        if (e.errors) {
          //form.setFields(e.errors);
        }
      });
  };

  //Confirm Modal
  const openConfirmDateTimeModal = (dateformat, data) => {
    setDate(dateformat);
    setReminderConfirm(data);
    setOpenDateTimeModal(true);
  };
  const closeDateTimeModal = () => setOpenDateTimeModal(false);

  const content = (
    <div className='QuicknoteList'>
      <ul>
        {quickNoteList ? (
          quickNoteList.map(function (item, index) {
            return (
              <li
                key={index}
                title={item.quick_note_text}
                onClick={() => {
                  handleSubmitQN(props.data.id, item.quick_note_text);
                }}
              >
                {item.quick_note_text_value}
              </li>
            );
          })
        ) : (
          <Spin />
        )}
      </ul>
    </div>
  );

  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // };

  /**Start: LEAD Hour Glass Update  */
  const openLeadConfirmHourglassModal = (data) => {
    setOpenHourglassModalLead(true);
    setLeadHourglassConfirm(data);
    let leadHourglassTime = 0;
    if (data.lead_status_id) {
      /*if (data.lead_status_id.parent_status_id === 2) {
        //Opportunity
        leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_opportunity_hourglass;
      } else*/
      if (data.lead_status_id.parent_status_id === 2) {
        //Deals
        leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_deals_hourglass;
      } else if (data.lead_status_id === 4) {
        //Un-Used Leads
        leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_unused_leads_hourglass;
      } else {
        //Active Leads
        leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_used_leads_hourglass;
      }
    }
    setleadHourglassTime(leadHourglassTime);
  };
  const closeLeadConfirmHourglassModal = () => {
    setleadHourglassTime(null);
    setOpenHourglassModalLead(false);
  };
  /**Start: LEAD Hour Glass Update  */

  return (
    <div className='action-column promotionAction'>
      {AUTH.checkPermission(2, 'quick-message') && (
        <Popover
          content={content}
          placement='bottom'
          arrowPointAtCenter
          trigger='hover'
          overlayClassName='profileMenu'
        >
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('Quicknote')} />
        </Popover>
      )}
      <NoteRenderer data={props.data} />

      <Button type='text' title={`${t('Block_Edit_Assign.Deal_Desk')}`}>
        <img
          className='menuicon'
          alt=''
          src={AUTH.GetThemedImage('Deal_icn')}
          onClick={() => {
            history.push(`/dealdesk/${props.data.id}`);
          }}
        />
      </Button>

      {/* {AUTH.checkPermission(35, 'view-details') && ( */}
      <Button type='text' title={`${t('Common_Data.View')}`} onClick={() => openViewDrawer(props.data)}>
        <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
      </Button>
      {/* )} */}
      {/* {AUTH.checkPermission(10, 'edit') && ( */}
      <Button type='text' title={`${t('Common_Data.Edit')}`} onClick={() => openEditDrawer(props.data)}>
        <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
      </Button>
      {/* )} */}
      {/* {AUTH.checkPermission(10, 'delete') && ( */}
      <Button type='text' title={`${t('Common_Data.Delete')}`} onClick={() => openDeleteDrawer(props.data)}>
        <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
      </Button>
      {/* )} */}
      {/* {AUTH.checkPermission(10, 'clone') && ( */}
      <Button type='text' title={`${t('Common_Data.Clone')}`} onClick={() => openCloneDrawer(props.data)}>
        <img className='menuicon' alt='' src={AUTH.GetThemedImage('Clone_Record')} />
      </Button>
      {/* )} */}

      {AUTH.checkPermission(2, 'reset-reminder-date-time') &&
        props.data?.unused_date === null && (
          <span
            className='Calendar'
            onMouseEnter={() => {
              if (props.data?.reset_count !== null) {
                getReminderDatepickerExtraFooter(
                  props.data,
                  AUTH.global_fulldate_format,
                  {
                    type: 'leads',
                  }
                ).then((data) => {
                  setExtrafooter(data);
                });
              }
            }}
          >
            <Tooltip
              title={props.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`}
            >
              <DatePickerCostume
                name='Calendar'
                className='cursor'
                title={`${t('Common_Data.Calendar')}`}
                onChange={(e) => openConfirmDateTimeModal(e, props.data)}
                showTime
                disabledDate={true}
                //value={moment(AUTH.getLocalTimeDealDesk(new Date()))}
                // minuteStep={15}
                showNow={false}
                onOk={(e) => openConfirmDateTimeModal(e, props.data)}
                renderExtraFooter={() => extrafooter}
              />
            </Tooltip>
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Calendar')} />
          </span>
        )}
      {AUTH.checkPermission(2, 'reset-reminder-time') &&
        props.data?.unused_date === null && (
          <>
            <img
              className='menuicon'
              alt=''
              src={AUTH.GetThemedImage('Reminders_icn')}
              //onClick={handleCount}
              onClick={() => openLeadConfirmHourglassModal(props.data)}
            />
            <span className='count'>
              {props?.data?.reset_count
                ? props?.data?.reset_count === null
                  ? 0
                  : props?.data?.reset_count
                : 0}
            </span>
          </>
        )}

      <DateTimeConfirmComponent
        visible={openDateTimeModal}
        close={closeDateTimeModal}
        date={date}
        reminderConfirm={reminderConfirm}
      />
      <HourglassConfirmComponent
        visible={openHourglassModalLead}
        close={closeLeadConfirmHourglassModal}
        hourglassConfirm={leadHourglassConfirm}
        resetTime={leadHourglassTime}
      />
    </div>
  );
});

export default ActionRenderer;
