import { observer } from "mobx-react";
import React from 'react'

const Blank = observer(() => {
	return (
		<>

		</>
	)
})

export default Blank
