import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import LocalGridConfig from '../../../../../config/LocalGridConfig';
import { vsmCommon } from '../../../../../config/messages';
import useStore from '../../../../../store';
import { Button, Tooltip } from 'antd';
import { DateComparator } from '../../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const {t} = useTranslation();
  const { openEditModal, openDeleteModal, onCellKeyDown } = props;
  const {
    AUTH,
    PEOPLEROLESTORE,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  useEffect(() => {
    PEOPLEROLESTORE.getList();
  }, [PEOPLEROLESTORE]);

  const ActionRenderer = (props) => {
    const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents;
    return (
      <>
        <div className='action-column'>
          {AUTH.checkPermission(19, 'edit') && (
            <Button
              type='text'
              onClick={() => {
                openEditModal(props.data);
              }}
            >
              <img alt='' className='menuicon' src={AUTH.GetThemedImage('Edit')} />
            </Button>
          )}
          {AUTH.checkPermission(19, 'delete') && props.data.count_in_peoples_count > 0 ? (
            <Tooltip
              placement='topRight'
              color={'red'}
              title={`${t('PeopleManagement.Associate_Tooltip')}`}
            >
              <Button type='text' disabled>
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            </Tooltip>
          ) : (
            AUTH.checkPermission(19, 'delete') && (
              <Tooltip title={'Delete'}>
                <Button
                  type='text'
                  onClick={() => {
                    openDeleteModal(props.data);
                  }}
                >
                  <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              </Tooltip>
            )
          )}
        </div>
      </>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('PeopleManagement.Role')} ${t('Common_Data.Name')}`,
        field: 'role_name',
        valueGetter: (params) =>
          params.data && params.data.role_name ? params.data.role_name : 'N/A',
      },
      {
        headerName: `${t('Common_Data.No')}. ${t('Common_Data.Of')} ${t('Common_Data.People')}`,
        field: 'count_in_peoples_count',
      },
      {
        headerName: `${t('Common_Data.Last_Updated_By')}`,
        field: 'updated_by',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
        },
        valueGetter: (params) =>
          params.data && params.data.updated_by ? params.data.updated_by : 'N/A',
      },
      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        field: 'updated_on',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',
        pinned: 'left',
        maxWidth: 130,
        width: 130,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',
        maxWidth: 100,
        width: 100,
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('people_role_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    item.headerTooltip = item.headerName;
    item.tooltipField = item.field;

    return item;
  })

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth : '',
      }}
    >
      <AgGridReact
        rowData={PEOPLEROLESTORE.list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={LocalGridConfig.defaultColDef}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          openEditModal,
          openDeleteModal,
        }}
        onGridReady={PEOPLEROLESTORE.setupGrid}
        gridOptions={{ ...LocalGridConfig.options, pagination: true, rowHeight: 30 }}
        onFilterChanged={PEOPLEROLESTORE.onFilterChanged}
        //onSortChanged={PEOPLEROLESTORE.onFilterChanged}

        onColumnResized={PEOPLEROLESTORE.onGridChanged}
        onColumnMoved={PEOPLEROLESTORE.onGridChanged}
        onColumnPinned={PEOPLEROLESTORE.onGridChanged}
        onSortChanged={PEOPLEROLESTORE.onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        onCellKeyDown={onCellKeyDown}
      />
    </div>
  );
});

export default ListComponent;
