import React, { useEffect, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { DateComparator, getSssNameByTypeID } from '../../../utils/GlobalFunction';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { vsmCommon } from '../../../config/messages';
import useStore from '../../../store';
import HistoryRender from './elements/HistoryRender';
import moment from 'moment';
const ListComponent = observer((props) => {
  const { t } = useTranslation();
  const {
    openEditDrawer,
    openDeleteDrawer,
    /* CommonFieldTitle, */
    /* saveType, */
    // viewScheduleLibraryDrawer,
  } = props;

  const {
    AUTH,
    LIBRARYSEARCHSTORE,
    LIBRARYSEARCHSTORE: { loader, listData, },
    SEARCHSTORE: { searchCount, setResultCount },
  } = useStore();

  useEffect(() => {
    setResultCount(searchCount?.Library_count);
    LIBRARYSEARCHSTORE?.getList(LIBRARYSEARCHSTORE.search_payload);
  }, [LIBRARYSEARCHSTORE,setResultCount, searchCount?.Library_count]);

  const ActionRenderer = (params) => {
    return (
      <div className='action-column'>
        {/* <Button
          type='text'
          title={`${t('DealDesk.View')}`}
          onClick={() => viewScheduleLibraryDrawer(params.data)}
        >
          <img
            alt={`${t('DealDesk.View')}`}
            className='menuicon'
            src={AUTH.GetThemedImage('View')}
          />
        </Button> */}

        <Button
          type='text'
          title={`${t('Common_Data.Edit')}`}
          onClick={() => openEditDrawer(params.data)}
        >
          <img
            alt={`${t('Common_Data.Edit')}`}
            className='menuicon'
            src={AUTH.GetThemedImage('Edit')}
          />
        </Button>

        {AUTH.checkPermission(54, 'lead-sss-delete') ? (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            onClick={() => openDeleteDrawer(params.data)}
          >
            <img
              alt={`${t('Common_Data.Delete')}`}
              className='menuicon'
              src={AUTH.GetThemedImage('Waste')}
            />
          </Button>
        ) : null}
      </div>
    );
  };

  const gridRef = useRef();

  const onBtShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, []);

  const hideOverlayFunction = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  useEffect(() => {
    if (loader) {
      onBtShowLoading();
    } else {
      hideOverlayFunction();
    }
    return () => { };
  }, [loader, onBtShowLoading, hideOverlayFunction]);

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Created')}`,
        field: 'created_at',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        filterParams: {
          buttons: ['apply', 'reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.created_at
            ? moment(params.data.created_at).format(AUTH.global_fulldate_format)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        field: 'created_by_name',
        filter: 'agSetColumnFilter',
        filterParams: { suppressAndOrCondition: true, buttons: ['apply', 'reset'], },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Type')}`,
        field: 'type',
        cellRendererFramework: (params) => {
          return getSssNameByTypeID(params?.data?.type);
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          suppressAndOrCondition: true, buttons: ['apply', 'reset'],
          valueGetter: params => {
            return params?.data?.type && getSssNameByTypeID(params?.data?.type)
          }
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Title')}`,
        field: 'title',
        filter: 'agTextColumnFilter',
        cellRendererFramework: (params) => {
          return (
            <>
              {params.data.is_template ? (
                <>
                  <img
                    src={AUTH.GetThemedImage('Template_Icon')}
                    className='menuicon'
                    width={'18px'}
                    height={'18px'}
                    alt=''
                  />{' '}
                </>
              ) : null}
              {params.data.title}
            </>
          );
        },
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        field: 'description_text',
        filter: 'agTextColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      // {
      //   headerName: `${t('Common_Data.History')}`,
      //   field: 'description',
      //   // tooltipField:'description',
      //   filter: 'agTextColumnFilter',
      //   // cellRenderer: 'historyRenderer',
      //   valueGetter: function (params) {
      //     let data = params?.data?.description;
      //     let content = data && data.map((item) => {
      //       return item.children
      //       ? item.item_key + ":" + (item.detail ? item.detail : " ") + ", " + item.children.map((child1) => (
      //         child1.children
      //           ? child1.item_key + ":" + (child1.detail ? child1.detail : " ") + ", " + child1.children.map((child2) => (
      //             child2.item_key + ":" + (child2.detail ? child2.detail : " ")
      //           ))
      //           : child1.item_key + ":" + (child1.detail ? child1.detail : " ") + ", "
      //       )
      //       )
      //       : item.item_key + ":" + (item.detail ? item.detail : " ") + ", "
      //     })   
      //     return  content && content
      //   },
      //   filterParams: {
      //     suppressAndOrCondition: true,
      //   },
      //   floatingFilter: true,
      // },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',

        maxWidth: 100,
        // lockPosition: true,
        // suppressNavigable: true
        filter: 'agNumberColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      // {
      //   headerName: 'Created By',
      //   field: 'created_by_name',
      //   filter: 'agTextColumnFilter',
      //   filterParams: {
      //     suppressAndOrCondition: true,
      //   },
      //   floatingFilter: true,
      // },

      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        // maxWidth: 120,
        width: 90,
        filter: false,
        sortable: false,
        pinned: 'right',
        // lockPosition: true,
        // suppressNavigable: true
      },
    ],
  };

  // const getGridTypeBasedOnSaveType = () => {
  //   return 'library_search_grid';
  // };

  // const getGridStoreTypeBasedOnSaveType = () => {
  //   return setupGrid;
  // };

  let columns = [];
  let columnConfig = localStorage.getItem('search_library_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }


  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['description'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }

    return item;
  });

  return (
    <div
      className={`ag-theme-alpine grid_wrapper ${props.SearchPage ? "paginationAtTop" : ""}`}
      style={{
        height: 'calc(100vh - 13rem)',
      }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={listData}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={{
          ...LocalGridConfig.defaultColDef,
          width: 120,
          minWidth: 70,
          flex: 1,
          // floatingFilter: !SearchPage,
        }}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          historyRenderer: HistoryRender,
        }}
        // onGridReady={getGridStoreTypeBasedOnSaveType()}
        onGridReady={LIBRARYSEARCHSTORE.setupGridSearch}
        onColumnResized={LIBRARYSEARCHSTORE.onGridChangedSearch}
        onColumnMoved={LIBRARYSEARCHSTORE.onGridChangedSearch}
        onColumnPinned={LIBRARYSEARCHSTORE.onGridChangedSearch}
        onSortChanged={(params) => {
          if (localStorage.getItem('params')) {
            var temp = JSON.parse(localStorage.getItem('params'));
            localStorage.setItem('params', JSON.stringify({ ...temp, LibrarySearchStore: { sort: params.api.getSortModel() }, }));
          } else {
            localStorage.setItem('params',
              JSON.stringify({ LibrarySearchStore: { sort: params.api.getSortModel() }, })
            );
          }
        }}
        gridOptions={{
          ...LocalGridConfig.options,
          pagination: true,
          rowHeight: 30,
        }}
      />
    </div>
  );
});

export default ListComponent;
