import { Route, Switch } from "react-router-dom";
import { RouterConfig } from "../config/RouterConfig";
import React from "react";

const AppRouter = () => {
  return (
    <Switch>
      {RouterConfig &&
        RouterConfig.map((item, index) => {
          return (
            <Route
              key={index}
              exact={!item.exact || (item.exact && item.exact !== "false")}
              path={item.path}
              component={item.component}
            />
          );
        })}
    </Switch>
  );
};

export default AppRouter;
