import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button, Popover } from 'antd';

import useStore from '../../../store';

import LocalGridConfig from '../../../config/LocalGridConfig';
import { vsmCommon } from '../../../config/messages';
import moment from 'moment';
import { DateComparator, secondsToHmsWithAppend } from '../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const {
    SEQUENCERSTORE,
    AUTH,
    SEQUENCERSTORE: { onFilterChanged, onGridChanged },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  const { t } = useTranslation();

  useEffect(() => {
    SEQUENCERSTORE.getList();
    if (!SEQUENCERSTORE.dropdown_seq_type_list) {
      SEQUENCERSTORE.getSequenceTypeList();
    }
  }, [SEQUENCERSTORE]);

  const ActionRenderer = (params) => {
    return params.data.sequence_email !== "noDataNeeded" ? (
      <div className='action-column'>
        <Button
          type='text'
          title={`${t('DealDesk.View')}`}
          onClick={() => props.openViewDrawer(params.data)}
        >
          <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
        </Button>
      </div>
    ) : null;
  };

  const EmailCellRender = (data) => {

    return data.value !== "noDataNeeded" ? (
      <div className='action-column TelephoneRenderer'>
        <Popover
          content={
            <div className='contactList'>
              <ul>
                <li>
                  <a href={`mailto:${data.value}`}>
                    <img
                      src={
                        AUTH.GetThemedImage('Mail')
                      }
                      alt=''
                      className='menuicon'
                    />{' '}
                    {data.value}
                  </a>
                </li>
              </ul>
            </div>
          }
          placement='topLeft'
          trigger='hover'
          overlayClassName='profileMenu'
        >
          <a href={`mailto:${data.value}`}>
            <img
              src={
                AUTH.GetThemedImage('Mail')
              }
              alt=''
              className='menuicon'
            />{' '}
            {data.value}
          </a>
        </Popover>
      </div>
    ) : null
  }

  const gridOptions = {
    columnDefs: [
      {
        headerName: `#${t('Common_Data.ID')}`,
        valueGetter: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        field: 'sr',
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 60,
        lockPosition: true,
        cellClass: 'locked-col',
        suppressNavigable: true,
      },
      {
        headerName: `${t('Common_Data.Application')}`,
        field: 'sequence',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Application_Type')}`,
        field: 'sequence_type',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            params.success([...SEQUENCERSTORE.dropdown_seq_type_list.map((x) => x.name)]);

          },
        },
      },
      {
        headerName: `${t('Common_Data.Email')}`,
        field: 'sequence_email',
        cellRenderer: 'emailCellRender',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('DealDesk.Time_Spent')}`,
        field: 'time_spent_seconds',
        valueGetter: (params) => {
          return secondsToHmsWithAppend(
            params?.data?.time_spent_seconds ? params?.data?.time_spent_seconds : 0
          );
        },
        tooltipValueGetter: (params) => {
          return secondsToHmsWithAppend(
            params?.data?.time_spent_seconds ? params?.data?.time_spent_seconds : 0
          );
        },
        cellStyle: { 'text-align': 'right' },
      },
      {
        headerName: `${t('Common_Data.Application_Step')}`,
        field: 'sequence_step',
      },
      {
        headerName: `${t('Common_Data.Assigned_User')}`,
        field: 'approve_user_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            SEQUENCERSTORE.getUsersList().then((data) => {
              params.success([...data.map((x) => x.display_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Email_Status')}`,
        field: 'email_status',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        field: 'status',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            SEQUENCERSTORE.getStatusList().then((data) => {
              params.success([...data.map((x) => x.value)]);
            });
          },
        },
      },

      {
        headerName: `${t('Common_Data.Date_Created')}`,
        field: 'date_created',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.date_created
            ? moment(params.data.date_created).format(AUTH.global_dateformat)
            : '';
        },
      },

      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: 120,
        filter: false,
        sortable: false,
        pinned: 'right',
        cellStyle: () => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }),
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('sequencer_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    item.headerTooltip = item.headerName;
    item.tooltipField = item.field;

    return item;
  });

  let PinnedData = [];
  let soldObj = {};
  gridOptions.columnDefs &&
    gridOptions.columnDefs.map((obj) => {
      if (['time_spent_seconds'].includes(obj.field)) {
        let total = 0;
        SEQUENCERSTORE.list_data &&
          SEQUENCERSTORE.list_data.map((x) => {
            total += x.time_spent_seconds;
            return null;
          });
        soldObj[obj.field] = total;
      }
      return null;
    });
  soldObj["sequence_email"] = "noDataNeeded";
  soldObj["actions"] = "noDataNeeded";

  PinnedData.push(soldObj);

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 11rem)',
      }}
    >
      <AgGridReact
        rowData={SEQUENCERSTORE.list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={LocalGridConfig.defaultColDef}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        pinnedBottomRowData={PinnedData}
        frameworkComponents={{
          ActionRenderer,
          emailCellRender: EmailCellRender,
        }}
        onGridReady={SEQUENCERSTORE.setupGrid}
        gridOptions={{ ...LocalGridConfig.options, rowHeight: 30 }}
        onColumnResized={onGridChanged}
        onColumnMoved={onGridChanged}
        onColumnPinned={onGridChanged}
        onFilterChanged={onFilterChanged}
        onSortChanged={onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
      />
    </div>
  );
});

export default ListComponent;
