import { Button, Modal } from 'antd';
import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const AttachmentModalList = ({ ...props }) => {
  const { t } = useTranslation()
  const {
    visible,
    close,
    selectedFiles,
    autoSaveData,
    saveObj,
    setSelectedFiles,
    removeAttachment,
  } = props;
  return (
    <>
      <Modal
        centered
        title={`${t('DealDesk.Attachments')}`}
        visible={visible}
        onCancel={() => close()}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button key='2' form='addform' shape='round' onClick={() => close()}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
        width={500}
      >
        <div className='attachmentFileList'>
          {selectedFiles &&
            selectedFiles.map((files, index) => {
              if (
                files &&
                typeof files === 'string' &&
                files !== '[]' &&
                files.search('#')
              ) {
                let fileExplodedDetails = files.split('|');
                let ext = fileExplodedDetails[2];
                ext = ext.replace('.', '');
                files = {
                  id: parseInt(fileExplodedDetails[0]),
                  entity_name: fileExplodedDetails[1],
                  name: fileExplodedDetails[1],
                  attachment_type: ext,
                };
              }

              return (
                <>
                  <div className='itemBox' key={index}>
                    <span className='fileName' title={files.name || files.entity_name}>
                      {files.name || files.entity_name}
                    </span>
                    {setSelectedFiles && (
                      <DeleteOutlined
                        title={files.name || files.entity_name}
                        style={{ fontSize: '20px', color: 'red', marginLeft: '15px' }}
                        className='deleteIcon'
                        onClick={() =>
                          removeAttachment(selectedFiles, setSelectedFiles, index).then(
                            (data) => {
                              if (saveObj) {
                                let newObj = saveObj;
                                newObj.selectedFiles = data;
                                autoSaveData(newObj);
                              }
                              if (selectedFiles.length <= 1) {
                                close();
                              }
                            }
                          )
                        }
                      />
                    )}
                  </div>
                </>
              );
            })}
        </div>
      </Modal>
    </>
  );
};

export default AttachmentModalList;
