import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import ViewListing from './component/ViewListing';
import { Form, Button, Checkbox } from 'antd';
import moment from 'moment';
import ScheduleComponent from './component/ScheduleComponent';
import useStore from '../../../../store';
import { vsmNotify, vsmDealDesk } from '../../../../config/messages';
import { calculateHeight, trimExtraCharacters } from '../../../../utils/GlobalFunction';
import debounce from 'lodash/debounce';
import AutosaveNotifiy from './component/AutosaveNotify';
import AddLibaryTitle from '../Structure/component/AddLibaryTitle';
import { default as EditLeadComponent } from '../../../../page/LeadListing/component/EditComponentNew';
import { useTranslation } from 'react-i18next';

const Schedule = observer((props) => {
  const { t } = useTranslation();
  //--------------------------------------------------- Use States ------------------------------------------------------------------//
  const INITIAL_HEIGHT = '18px';
  const [viewDrawer, setViewDrawer] = useState(false);
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [sendClick, setSendClick] = useState(false);
  const [isSaveToLibrary, setIsSaveToLibrary] = useState(false); //For Save to Library Flag
  const [visibleTitleModal, setVisibleTitleModal] = useState(false);
  const [libraryTitle, setLibraryTitle] = useState(null);
  const [classificationId, setClassificationId] = useState(null);
  const [libraryDescriptionInfo, setLibraryDescriptionInfo] = useState(null);
  const [editLeadDrawer, setEditLeadDrawer] = useState(false);
  const [disabledSave, setdisabledSave] = useState(false);
  //-------------- Auto Save Timer State--------------------//

  const [isAutoSaveCalledBefore, setisAutoSaveCalledBefore] = useState(false); //For Stopping Auto Save when Title Drawer is open

  const [isOverwriteTemplate, setIsOverwriteTemplate] = useState(true); // For Overwriting old record with Same Title(Library Title)
  //-------------------------------------------------------//

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  //-----------  For Deadline date Switch is on or not --------------//
  const [is_checked, setis_checked] = useState(false);
  //-----------------------------------------------------------------//

  const [form] = Form.useForm();
  //--------------------------------------------------------------------------------------------------------------------------------------//

  //--------------------------------------------------- Store Declaration ---------------------------------------------------------------//

  const {
    AUTH,
    LEADSSTORE,
    DEALDESKSTORE: {
      deal_data,
      resetActiveScheduleNoteEditDated,
      editActiveScheduleData,
      dealdeskActiveTab,
      calculateHeightOnEdit,
      setActiveScheduleData,
      activeScheduleData,
      setEditActiveScheduleData,
      setLeftSectionActiveTab,
      copiedActiveScheduleData,
      setCopiedActiveScheduleData,
      getDealDetail,
    },
    SSSLEADLIBRARYSTORE: {
      addSssData,
      scheduleAutosaveUpdated,
      scheduleAutoSaveData,
      resetScheduleAutoSaveData,
      addUpdateNoteList,
      getCurrentAutoSaveSchedule,
      deleteSssData,
      fetchListData,
    },
    DEALDESKSCHEDULELIBRARY: {
      setSavescheduleform,
      setScheduleData,
      ScheduleData,
      SelectedDate,
      DateType,
      SetDate,
      SetDateType,
      setCopiedScheduleData,
    },
    TimeTrackerStore,
  } = useStore();

  //--------------------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------- Handle Change Event  ---------------------------------------------------------------//

  const handleFormChange = useCallback(() => {
    form
      .validateFields()
      .then((data) => {
        if (ScheduleData?.length > 0) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      })
      .catch((e) => {
        setDisabled(true);
      });
  }, [form, ScheduleData?.length]);

  //--------------------------------------------------------------------------------------------------------------------------------------//

  //----------------------------------------------- View Deal Library  Drawer ------------------------------------------------------------//

  const openViewDrawer = (data) => {
    setViewDrawer(true);
    TimeTrackerStore.setStopForDesk(true);
  };

  const closeViewDrawer = (data = null) => {
    TimeTrackerStore.setStopForDesk(false);
    setViewDrawer(false);
    if (data) {
      setis_checked(data);
    }
    setDisabled(false);
  };

  //--------------------------------------------------------------------------------------------------------------------------------------//

  //----------------------------------------------- Auto Save Functions ------------------------------------------------------------------//

  const saveObj = () => {
    let obj = {
      is_checked: is_checked,
      date: SelectedDate,
      is_library: isSaveToLibrary,
      description: ScheduleData,
    };
    return obj;
  };

  var autosaveTimeoutData = null;
  const autoSaveData = (data = null) => {
    let allowAutosave = false;
    //------------------START:TO RESTRICT AUTOSAVE IN EDITO MODE----//
    // if (!editActiveScheduleData) {
    allowAutosave = true;
    // }
    //------------------END:TO RESTRICT AUTOSAVE IN EDITO MODE----//
    let timer = localStorage.getItem('scheduleAutoSaveData');
    if (allowAutosave) {
      if (autosaveTimeoutData || timer) {
        clearTimeout(autosaveTimeoutData || timer);
      }
      let descData = ScheduleData;
      let continueAutosave = true;
      if (descData && descData.length <= 0) {
        if (editActiveScheduleData) {
          continueAutosave = false;
        }
        else if (!scheduleAutosaveUpdated) {
          continueAutosave = false;
        }
      }
      if (continueAutosave) {
        autosaveTimeoutData = setTimeout(() => {
          handleSubmit({}, false, 1, data);
        }, 15000);
        localStorage.setItem('scheduleAutoSaveData', autosaveTimeoutData);
      }
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------------------//

  //-------------------------------------------------- Library Title Drawer --------------------------------------------------------------//

  const openAddTitleModal = () => {
    setVisibleTitleModal(true);
    setisAutoSaveCalledBefore(false);
    let timer = localStorage.getItem('scheduleAutoSaveData');
    if (autosaveTimeoutData || timer) {
      setisAutoSaveCalledBefore(true);
      clearAutoSaveTimer();
    }
  };

  const closeAddTitleModal = () => {
    setVisibleTitleModal(false);
    if (isAutoSaveCalledBefore) {
      autoSaveData();
      setisAutoSaveCalledBefore(false);
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------- Handle Submit(On Finish) -----------------------------------------------------------//

  let dealid = deal_data.lead_information && deal_data.lead_information.id;

  const handleSubmit = (data, is_post = false, isAutoSave = 0, newObj = null) => {
    
    data = form.getFieldsValue();
    data.description = ScheduleData;
    //-------------------- Get Last Active Element ID (Auto Save Only) -------------//
    let lastFocusID;
    let lastElement;
    if (isAutoSave) {
      lastFocusID = document.activeElement?.id;
      lastElement = document.activeElement;
      //----------------------------------------------------------------------------//
    } else {
      //--------------------TO CLEAR PREVIOUS TIMEOUT -------------//
      let timer = localStorage.getItem('scheduleAutoSaveData');
      if (timer) {
        clearTimeout(Number(timer));
        localStorage.removeItem('scheduleAutoSaveData');
      }
      setdisabledSave(true);
    }

    //--------------------TO CLEAR PREVIOUS TIMEOUT -------------//

    //------- Post flag added -----//

    if (!isAutoSave) {
      setSaving(true);
    }

    //---------------------------//

    data.autosave = isAutoSave;
    if (newObj && isAutoSave) {
      data.date = moment(newObj.date).format('YYYY-MM-DD');
      data.is_start_date = newObj.is_checked;
      data.description = newObj.description;
    } else {
      data.date = moment(SelectedDate).format('YYYY-MM-DD');
      data.is_start_date = is_checked;
    }

    //------  added blank array if children not found -----------//

    if (data?.description?.length > 0) {
      data.description.map((obj) => {
        if (!obj.children) {
          obj.children = [];
        }
        return obj;
      });
    }
    //------------------------------------------------------------//

    if (editActiveScheduleData && editActiveScheduleData.id) {
      data.id = editActiveScheduleData.id;
    }

    if (isAutoSave || !isSaveToLibrary) {
      data.title = new Date().getTime().toString();
      if (newObj) {
        data.is_library = newObj.is_library;
      } else {
        data.is_library = isSaveToLibrary;
      }
    } else {
      data.is_library = isSaveToLibrary;
    }
    data.type = 2;
    data.isOverwriteTemplate = isOverwriteTemplate;
    addSssData(dealid, data)
      .then((data) => {
        
        if (isAutoSave) {
          if (editActiveScheduleData) {
            addUpdateNoteList(data, true);
          }
          else {
            addUpdateNoteList(data, false);
          }
        }
        else {
          localStorage.removeItem('last_focusedElementSchedule');

          if (visibleTitleModal) {
            setVisibleTitleModal(false);
          }
          setCopiedScheduleData(null);
          if (editActiveScheduleData) {
            if (isOverwriteTemplate) {
              fetchListData(deal_data?.lead_information?.id, true);
            } else {
              addUpdateNoteList(data, true);
            }
            setActiveScheduleData(null);
            setEditActiveScheduleData(null);
          } else {
            if (isOverwriteTemplate) {
              if (activeScheduleData && activeScheduleData?.id === data?.id) {
                setActiveScheduleData(null);
              }
              fetchListData(deal_data?.lead_information?.id, true);
            } else {
              addUpdateNoteList(data, false);
            }
          }
          if (copiedActiveScheduleData) {
            setActiveScheduleData(null);
            setCopiedActiveScheduleData(null);
          }
          if (sendClick) {
            vsmNotify.success({
              message: vsmDealDesk.schedule.sentSuccess,
            });
          } else {
            if (editActiveScheduleData) {
              vsmNotify.success({
                message: vsmDealDesk.schedule.updateSuccess,
              });
            } else {
              vsmNotify.success({
                message: vsmDealDesk.schedule.saveSuccess,
              });
            }
            setLibraryTitle(null);
            setClassificationId(null);
            setLibraryDescriptionInfo(null);
            setLeftSectionActiveTab('2'); //LEFT SECTION TAB SELECTION ON SUCCESS
            setDisabled(true);
            form.resetFields();
            setdisabledSave(false);
            setIsSaveToLibrary(false);
            setScheduleData([]);
            setis_checked(false);
            SetDate(new Date());
          }

          if (
            editActiveScheduleData?.id &&
            (scheduleAutoSaveData || scheduleAutosaveUpdated)
          ) {
            getCurrentAutoSaveSchedule();
          } else {
            resetScheduleAutoSaveData();
          }

          setIsOverwriteTemplate(true);

          // To update last updated date in  headers with header api
          getDealDetail(dealid ? dealid : data?.leads_transactions_id);
        }
      })
      .catch((e) => {
        if (e.errors) {
          let schedule_description_error = 0;
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (e === 'description') {
                  schedule_description_error = 1;
                }
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });

          if (schedule_description_error) {
            vsmNotify.error({
              message: `${t('DealDesk.Schedule_Description')}!`,
            });
          } else {
            form.setFields(e.errors);
          }
        }
      })
      .finally(() => {
        setSaving(false);
        setdisabledSave(false);
        //------------------- Focus to Last Focused Element at end of Input --------------------------------//
        if (isAutoSave && lastFocusID?.includes('schedule_innerContent_')) {
          let elementID = document.getElementById(lastFocusID);
          elementID.focus();
          let len = lastElement.selectionStart;
          if (len) {
            elementID.setSelectionRange(len, len);
          }
        }
        //--------------------------------------------------------------------------------------------------//
        //setPosting(false);
      });
  };

  //--------------------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------- UseEffect for Auto Saved Data ------------------------------------------------------//

  useEffect(() => {
    if (scheduleAutoSaveData && dealdeskActiveTab === '2') {
      setis_checked(scheduleAutoSaveData.is_start_date);
      scheduleAutoSaveData.description &&
        scheduleAutoSaveData.description.forEach((element) => {
          delete element.start_date;
          if (element.children && element.children.length > 0) {
            element.children.forEach((obj1, index) => {
              delete obj1.start_date;
              if (obj1.children && obj1.children.length > 0) {
                obj1.children.forEach((obj2, index) => {
                  delete obj2.start_date;
                });
              }
            });
          }
        });

      setScheduleData(scheduleAutoSaveData.description);
      SetDate(new Date(scheduleAutoSaveData.date));
      SetDateType(false);
      setIsSaveToLibrary(scheduleAutoSaveData.is_library);
      // Character Length Limit from Setting

      let lengthLimit = 170;
      if (AUTH?.user?.bonus_figures) {
        if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
          lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
        }
      }
      //--------------------------------------- Calculate height based on content ------------------------------//

      let schedule_data = scheduleAutoSaveData.description || [];
      calculateHeightOnEdit(scheduleAutoSaveData.description).then(
        debounce(() => {
          if (schedule_data?.length > 0) {
            setDisabled(false);
          }
          schedule_data &&
            schedule_data.forEach((obj, index) => {
              obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

              obj.height = calculateHeight(
                `schedule_innerContent_textarea_${obj.item_key}`,
                INITIAL_HEIGHT,
                obj.detail,
                true
              );

              if (obj.children && obj.children.length > 0) {
                obj.children.forEach((obj1, index) => {
                  obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                  obj1.height = calculateHeight(
                    `schedule_innerContent_textarea_${obj1.item_key}`,
                    INITIAL_HEIGHT,
                    obj1.detail,
                    true
                  );
                  if (obj1.children && obj1.children.length > 0) {
                    obj1.children.forEach((obj2, index) => {
                      obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                      obj2.height = calculateHeight(
                        `schedule_innerContent_textarea_${obj2.item_key}`,
                        INITIAL_HEIGHT,
                        obj2.detail,
                        true
                      );
                    });
                  }
                });
              }
            });
          form.setFieldsValue({
            title: scheduleAutoSaveData?.title,
            classification_id: scheduleAutoSaveData?.classification_id,
            description_text: scheduleAutoSaveData?.description_text,
          });
        }, 200)
      );
      setScheduleData(schedule_data);
      //--------------------------------------------------------------------------------------------------------//
    }
  }, [
    scheduleAutoSaveData,
    AUTH?.user,
    setScheduleData,
    dealdeskActiveTab,
    form,
    SetDate,
    SetDateType,
    calculateHeightOnEdit,
  ]);

  //--------------------------------------------------------------------------------------------------------------------------------------//

  useEffect(() => {
    setSavescheduleform(form);
    return function cleanup() {
      setScheduleData([]);
      clearAutoSaveTimer();
    };
  }, [form, setScheduleData, setSavescheduleform]);

  //------------------------------------------------- UseEffect for Edit Schedule Data ---------------------------------------------------//
  useEffect(() => {
    if (editActiveScheduleData) {
      clearAutoSaveTimer();
      setis_checked(editActiveScheduleData.is_start_date);
      editActiveScheduleData.description &&
        editActiveScheduleData.description.forEach((element) => {
          delete element.start_date;
          if (element.children && element.children.length > 0) {
            element.children.forEach((obj1, index) => {
              delete obj1.start_date;
              if (obj1.children && obj1.children.length > 0) {
                obj1.children.forEach((obj2, index) => {
                  delete obj2.start_date;
                });
              }
            });
          }
        });

      setScheduleData(editActiveScheduleData.description);
      setIsSaveToLibrary(editActiveScheduleData.is_library);
      setLibraryDescriptionInfo(editActiveScheduleData?.description_text);
      setLibraryTitle(editActiveScheduleData.title);
      setClassificationId(editActiveScheduleData.classification_id);
      setCopiedScheduleData(editActiveScheduleData);
      SetDate(new Date(editActiveScheduleData.date));
      SetDateType(false);

      // Character Length Limit from Setting

      let lengthLimit = 170;
      if (AUTH?.user?.bonus_figures) {
        if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
          lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
        }
      }

      //--------------------------------------- Calculate height based on content ------------------------------//

      let schedule_data = editActiveScheduleData.description || [];
      calculateHeightOnEdit(editActiveScheduleData.description).then(
        debounce(() => {
          if (schedule_data?.length > 0) {
            setDisabled(false);
          }
          schedule_data &&
            schedule_data.forEach((obj, index) => {
              obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

              obj.height = calculateHeight(
                `schedule_innerContent_textarea_${obj.item_key}`,
                INITIAL_HEIGHT,
                obj.detail,
                true
              );

              if (obj.children && obj.children.length > 0) {
                obj.children.forEach((obj1, index) => {
                  obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                  obj1.height = calculateHeight(
                    `schedule_innerContent_textarea_${obj1.item_key}`,
                    INITIAL_HEIGHT,
                    obj1.detail,
                    true
                  );
                  if (obj1.children && obj1.children.length > 0) {
                    obj1.children.forEach((obj2, index) => {
                      obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                      obj2.height = calculateHeight(
                        `schedule_innerContent_textarea_${obj2.item_key}`,
                        INITIAL_HEIGHT,
                        obj2.detail,
                        true
                      );
                    });
                  }
                });
              }
            });
          form.setFieldsValue({
            title: editActiveScheduleData?.title,
            classification_id: editActiveScheduleData.classification_id,
            description_text: editActiveScheduleData?.description_text,
          });
        }, 200)
      );
      setScheduleData(schedule_data);
      //--------------------------------------------------------------------------------------------------------//
    }
  }, [
    form,
    AUTH?.user,
    setScheduleData,
    editActiveScheduleData,
    setCopiedScheduleData,
    SetDate,
    SetDateType,
    calculateHeightOnEdit,
  ]);
  //--------------------------------------------------------------------------------------------------------------------------------------//

  //-------------------------------------------------- UseEffect for Copy Data -----------------------------------------------------------//
  useEffect(() => {
    if (copiedActiveScheduleData) {
      setis_checked(copiedActiveScheduleData.is_start_date);
      copiedActiveScheduleData.description &&
        copiedActiveScheduleData.description.forEach((element) => {
          delete element.start_date;
          if (element.children && element.children.length > 0) {
            element.children.forEach((obj1, index) => {
              delete obj1.start_date;
              if (obj1.children && obj1.children.length > 0) {
                obj1.children.forEach((obj2, index) => {
                  delete obj2.start_date;
                });
              }
            });
          }
        });

      setScheduleData(copiedActiveScheduleData.description);
      // setIsSaveToLibrary(copiedActiveScheduleData.is_library);
      setCopiedScheduleData(copiedActiveScheduleData);
      SetDate(new Date(copiedActiveScheduleData.date));
      SetDateType(false);

      // Character Length Limit from Setting

      let lengthLimit = 170;
      if (AUTH?.user?.bonus_figures) {
        if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
          lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
        }
      }

      //--------------------------------------- Calculate height based on content ------------------------------//

      let schedule_data = copiedActiveScheduleData.description || [];
      calculateHeightOnEdit(copiedActiveScheduleData.description).then(
        debounce(() => {
          if (schedule_data?.length > 0) {
            setDisabled(false);
          }
          schedule_data &&
            schedule_data.forEach((obj, index) => {
              obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

              obj.height = calculateHeight(
                `schedule_innerContent_textarea_${obj.item_key}`,
                INITIAL_HEIGHT,
                obj.detail,
                true
              );

              if (obj.children && obj.children.length > 0) {
                obj.children.forEach((obj1, index) => {
                  obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                  obj1.height = calculateHeight(
                    `schedule_innerContent_textarea_${obj1.item_key}`,
                    INITIAL_HEIGHT,
                    obj1.detail,
                    true
                  );
                  if (obj1.children && obj1.children.length > 0) {
                    obj1.children.forEach((obj2, index) => {
                      obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                      obj2.height = calculateHeight(
                        `schedule_innerContent_textarea_${obj2.item_key}`,
                        INITIAL_HEIGHT,
                        obj2.detail,
                        true
                      );
                    });
                  }
                });
              }
            });
          // form.setFieldsValue({
          //   title: copiedActiveScheduleData?.title,
          //   description_text: copiedActiveScheduleData?.description_text,
          // });
        }, 200)
      );
      setScheduleData(schedule_data);
      //--------------------------------------------------------------------------------------------------------//
    }
  }, [
    //form,
    AUTH?.user,
    copiedActiveScheduleData,
    calculateHeightOnEdit,
    setCopiedScheduleData,
    SetDate,
    setScheduleData,
    SetDateType,
  ]);

  //--------------------------------------------------------------------------------------------------------------------------------------//

  //----------------------------------  Clear Auto Save/Clear Form/Clear Timer Functions  ------------------------------------------------//

  const clearAutoSave = () => {
    if (scheduleAutoSaveData || scheduleAutosaveUpdated) {
      resetScheduleAutoSaveData();
      deleteSssData(scheduleAutosaveUpdated?.id || scheduleAutoSaveData.id, {
        is_deal_desk: true,
      }).then(() => {
        form.resetFields();
        setScheduleData([]);
        setDisabled(true);
        setis_checked(false);
        setIsSaveToLibrary(false);
        setLibraryTitle(null);
        setClassificationId(null);
        setLibraryDescriptionInfo(null);
        SetDate(new Date());
      });
    } else {
      form.resetFields();
      setScheduleData([]);
      setDisabled(true);
      setis_checked(false);
      setIsSaveToLibrary(false);
      setLibraryTitle(null);
      setClassificationId(null)
      setLibraryDescriptionInfo(null);
      SetDate(new Date());
    }
    clearAutoSaveTimer();
    localStorage.removeItem('last_focusedElementSchedule');
  };

  const clearAutoSaveTimer = () => {
    let timer = localStorage.getItem('scheduleAutoSaveData');
    if (timer) {
      clearTimeout(Number(timer));
      localStorage.removeItem('scheduleAutoSaveData');
    }
  };

  const clearFormValues = () => {
    form.resetFields();
    localStorage.removeItem('last_focusedElementSchedule');
    setSaving(false);
    setScheduleData([]);
    setIsSaveToLibrary(false);
    setDisabled(true);
    setdisabledSave(false);
    setis_checked(false);
    SetDate(new Date());
    setSendClick(false);
    clearAutoSaveTimer();
    setIsOverwriteTemplate(true);
    getCurrentAutoSaveSchedule();
    setLibraryDescriptionInfo(null);
    //setPosting(false);
  };

  //--------------------------------------------------------------------------------------------------------------------------------------//

  //------------------------------------------------------ Edit Lead Drawer --------------------------------------------------------------//

  const openEditLeadDrawer = () => {
    vsmNotify.error({
      message:
        `${t(('DealDesk.SOLD_Name_Blank'))}. ${t('DealDesk.Value_To_Save')}, ${t('DealDesk.Structure_Schedule')}`,
    });

    LEADSSTORE.setEditValues({ id: deal_data?.lead_information?.id });
    setEditLeadDrawer(true);
    setisAutoSaveCalledBefore(false);
    

    if (autosaveTimeoutData > 0) {
      setisAutoSaveCalledBefore(true);
      clearAutoSaveTimer();
    }
  };

  const closeEditLeadDrawer = () => {
    setEditLeadDrawer(false);
    getDealDetail(deal_data?.lead_information?.id).then((data) => {
      if (data?.data?.lead_information?.opportunity_name) {
        setLibraryTitle(data?.data?.lead_information?.opportunity_name);
        form.setFieldsValue({
          title: data?.data?.lead_information?.opportunity_name,
        });
        setVisibleTitleModal(true);
      }
    });
  };

  //--------------------------------------------------------------------------------------------------------------------------------------//

  const handleUpdate = () => {
    if (isSaveToLibrary) {
      if (deal_data?.lead_information?.opportunity_name) {
        form.setFieldsValue({
          title: deal_data?.lead_information?.opportunity_name,
        });
        setVisibleTitleModal(true);
      } else {
        openEditLeadDrawer();
      }
    } else {
      setSendClick(false);
      handleSubmit();
    }
  };


  return (
    <>
      <div className='RightScheduleList'>
        <Form form={form} className='p-24' onFinish={handleSubmit}>
          <div className='list'>
            <ScheduleComponent
              openViewDrawer={openViewDrawer}
              scheduleName='description'
              form={form}
              autoSaveData={autoSaveData}
              saveObj={saveObj}
              setis_checked={setis_checked}
              INITIAL_HEIGHT={INITIAL_HEIGHT}
              is_checked={is_checked}
              SelectedDate={SelectedDate}
              SetDate={SetDate}
              DateType={DateType}
              forceUpdate={forceUpdate}
              SetDateType={SetDateType}
              setscheduleList={setScheduleData}
              scheduleList={ScheduleData}
              handleFormChange={handleFormChange}
            />
          </div>

          <div className='footer ScheduleComponentFooter'>
            <div>
              <Checkbox
                checked={isSaveToLibrary}
                onChange={(e) => {
                  setIsSaveToLibrary(e.target.checked);
                  if (saveObj) {
                    let newObj = saveObj();
                    newObj.is_library = e.target.checked;
                    autoSaveData(newObj);
                  } else {
                    autoSaveData();
                  }
                }}
              >
                {t('DealDesk.Save_Library')}
              </Checkbox>
            </div>

            <div>
              <AutosaveNotifiy />

              {editActiveScheduleData ? (
                <>
                  <Button
                    type='primary'
                    shape='round'
                    disabled={
                      editActiveScheduleData || copiedActiveScheduleData ? false : true
                    }
                    className='mr-5'
                    onClick={() => {
                      resetActiveScheduleNoteEditDated();
                      clearFormValues();
                    }}
                  >
                    {t('Common_Data.Cancel')}
                  </Button>
                  <Button
                    shape='round'
                    className='mr-5'
                    loading={saving}
                    onClick={() => {
                      LEADSSTORE.getClassificationList();
                      handleUpdate();
                    }}
                    disabled={disabled}
                  >
                    {t('Common_Data.Update')}
                  </Button>
                </>
              ) : (
                <>
                  {(scheduleAutosaveUpdated || scheduleAutosaveUpdated) && !scheduleAutoSaveData?.isEditAutoSaveData ?
                    <Button
                      className='mr-5'
                      shape='round'
                      type='primary'
                      size='medium'
                      disabled={!scheduleAutosaveUpdated && !(ScheduleData?.length > 0)}
                      onClick={() => clearAutoSave()}
                      key='3'
                    >
                      {t('Common_Data.Clear')}
                    </Button>
                    :
                    copiedActiveScheduleData
                      ?
                      <Button
                        className='mr-5'
                        shape='round'
                        type='primary'
                        size='medium'
                        disabled={!(ScheduleData?.length > 0)}
                        onClick={() => {
                          setCopiedActiveScheduleData(null);
                          clearAutoSave()
                        }
                        }
                        key='6'
                      >
                        {t('Common_Data.Clear')}
                      </Button>
                      :
                      null
                  }
                  <Button
                    shape='round'
                    className='mr-5'
                    loading={saving}
                    onClick={() => {
                      if (isSaveToLibrary) {
                        if (deal_data?.lead_information?.opportunity_name && deal_data?.lead_information?.classification_id) {
                          form.setFieldsValue({
                            title: deal_data?.lead_information?.opportunity_name,
                            classification_id: deal_data?.lead_information?.classification_id,
                          });
                          openAddTitleModal();
                        } else {
                          openEditLeadDrawer();
                        }
                      } else {
                        setSendClick(false);
                        handleSubmit();
                      }
                    }}
                    disabled={disabled || disabledSave}
                  >
                    {t('Common_Data.Save')}
                  </Button>
                </>
              )}
            </div>
          </div>
          <AddLibaryTitle
            visible={visibleTitleModal}
            close={closeAddTitleModal}
            libraryTitle={libraryTitle}
            classificationId={classificationId}
            handleChange={handleFormChange}
            saveType={'schedule'}
            handleSubmit={handleSubmit}
            libraryDescriptionInfo={libraryDescriptionInfo}
            setLibraryDescriptionInfo={setLibraryDescriptionInfo}
            setLibraryTitle={setLibraryTitle}
            setClassificationId={setClassificationId}
            parentForm={form}
            setIsOverwriteTemplate={setIsOverwriteTemplate}
            isOverwriteTemplate={isOverwriteTemplate}
          />
        </Form>
      </div>
      <ViewListing visible={viewDrawer} close={closeViewDrawer} />

      <EditLeadComponent
        visible={editLeadDrawer}
        close={closeEditLeadDrawer}
        isFromDealDesk={true}
      />
    </>
  );
});

export default Schedule;
