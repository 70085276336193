import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Menu, Dropdown, Form, Space, Radio, Tooltip, Typography } from 'antd';
import useStore from '../../../store';
import { useHistory } from 'react-router-dom';
import ListComponent from './component/ListComponent';
import EditComponent from './component/EditComponentNew';
import DeleteComponent from './component/DeleteComponent';
import AddComponent from './component/AddComponentNew';
import RecordPerPage from '../../../config/RecordPerPage';
import MapOrgWithLedger from './component/MapOrgWithLedger';
import Unauthorized from '../../Unauthorized';
import ViewComponent from './component/ViewComponent';
import CloneComponent from './component/CloneComponentNew';
import { filterFiltersByPageType, globalStatus } from '../../../utils/GlobalFunction';
import ImportComponent from './component/ImportComponent';

import EnterFullscreen from '../../../component/EnterFullscreen';
import debounce from 'lodash/debounce';
//import ApplyGridFilter from '../../../component/ApplyGridFilter';
import ApplyGridFilterNew from '../../../component/ApplyGridFilterNew';
import MergeOrganisation from './component/MergeOrganisation';
import { vsmNotify } from '../../../config/messages';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import ResetGridOptions from '../../../component/ResetGridOptions';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const Organisations = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  let history = useHistory();
  const {
    PEOPLE,
    ORGANISATION,
    ORGANISATION: {
      per_page,
      setOrganisationValues,
      setEditValues,
      setDeleteValues,
      setPageSize,
      exportData,
      exportOrganisationRolesData,
      exportLoadingIcn,
      isSavedFiltersLoadedOrg,
    },
    // DEALDESKSTORE: { stopInvestmentHourCounter, setStopInvestmentHourCounter },
    AUTH,
    AUTH: { allPageFilters, allPageFiltersLoaded },
    SETTINGS,
    // TimeTrackerStore
  } = useStore();
  const { SearchPage, AllView, sectorSelector, search_term, resultCount, handleRoute } = props;
  const { Title } = Typography;

  const [tabselection, setTabselection] = useState(2);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [importModal, setImportModal] = useState(false);

  //----------------MERGE PEOPLE STATES---------------------------//
  const [selectedRowsCount, setSelectedRowsCount] = useState(false);
  const [mergeVisibleModal, setMergeVisibleModal] = useState(false);
  const [selectedMergeData, setSelectedMergeData] = useState(null);
  const [showCheckbox, setshowCheckbox] = useState(false);
  //----------------MERGE PEOPLE STATES---------------------------//

  const [editOpen, setEditOpen] = useState(false);
  //const [showCheckbox, setshowCheckbox] = useState(false);

  // set id for edit / view drawer
  const setId = (id) => setTransactionId(id);

  // Open form for add new Organisation
  const openAddModal = (para = null, form = null) => {
    setCurrentTab({ para, form });
    setAddModal(true);
  };

  // Close form for close new Organisation
  const closeAddModal = () => setAddModal(false);

  // Open form for edit existing Organisation and set values to form
  const openEditModal = (data, isEditOpen = false) => {
    setEditOpen(isEditOpen);
    setOrganisationValues(null);
    setId(data?.id);
    setEditValues(data);
    // setStopInvestmentHourCounter(true);
    setEditModal(true);
  };

  // Open Role Mapping List
  const openRoleListing = (data) => {
    history.push(`/organisations/all-organisations/role-mapping/${data?.id}`);
  };

  // Open Bank Accounts List
  const openBankAccounts = (data) => {
    history.push(`/organisations/all-organisations/bank-accounts/${data?.id}`);
  };

  // Close form of edit Organisation
  const closeEditModal = () => {
    // ORGANISATION.organisationValues = null;
    // setEditValues(null);
    setEditModal(false);
    // setStopInvestmentHourCounter(false);
    setEditOpen(false);
  };

  // Open confirmation alert before deleting existing Organisation
  const openDeleteModal = (data) => {
    setDeleteValues(data);
    setDeleteModal(true);
  };

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => setDeleteModal(false);

  //Open Modal to Map Organisation with Current Ledger
  const mapOrgWithLedger = () => {
    setMapModal(true);
  };

  //Close Modal to Map Organisation with Current Ledger
  const closeMapModal = () => setMapModal(false);

  const handleViewDetailCall = debounce((data) => {
    ORGANISATION.viewPeopleLoading = true;
    ORGANISATION.getViewOrgDetail(data?.id)
      .then((data) => {
        if (data?.cash_transactions && data?.cash_transactions.length) {
          data?.cash_transactions.forEach((item) => {
            item.status_name = globalStatus(
              'cash_transaction_status',
              'key',
              item.status
            );
          });
        }
        ORGANISATION.viewOrgValues = data;
        PEOPLE.list_related_ORG = data?.related_organisations;
        PEOPLE.list_related_Ppl = data?.related_peoples;
        PEOPLE.list_trade_transaction = data?.trade_transactions;
        PEOPLE.list_cash_transaction = data?.cash_transactions;
        PEOPLE.list_banks = data?.banks;
        ORGANISATION.viewPeopleLoading = false;
      })
      .catch((ORGANISATION.viewPeopleLoading = false));
  }, 500);

  const openViewDrawer = (data) => {
    if (AUTH.checkPermission(16, 'view-details')) {
      ORGANISATION.viewPeopleLoading = true;
      setViewDrawer(true);
      handleViewDetailCall(data);
    }
  };

  const closeViewDrawer = () => {
    // setOrganisationValues([]);
    // ORGANISATION.viewOrgValues = null;
    setViewDrawer(false);
  };

  // Open clone
  const openCloneDrawer = (data) => {
    setOrganisationValues(null);
    setId(data?.id);
    setEditValues(data);
    setCloneDrawer(true);
  };

  //Close Modal to Map Organisation with Current Ledger
  const closeCloneDrawer = () => {
    // ORGANISATION.organisationValues = null;
    // setEditValues(null);
    setCloneDrawer(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key='organisations' onClick={() => exportData()}>
        {t('Common_Data.Organisations')}
      </Menu.Item>
      <Menu.Item key='organisations_roles' onClick={() => exportOrganisationRolesData()}>
        {t('OrganisationManagement.Organisations_Roles')}
      </Menu.Item>
    </Menu>
  );

  const openRoleURL = () => {
    history.push(`/organisations/administration/roles`);
  };

  const optionsRadiolist = [
    { label: `${t('Common_Data.People')}`, value: '1' },
    {
      label: `${t('Common_Data.Organisations')}`, value: '2', disabled: true
    },
  ];

  const optionsRadioOrg = [{
    label: `${t('Common_Data.Organisations')}`, value: '2', disabled: true
  }];

  const onRadiolistchange = (e) => {
    if (e.target.value < 2) {
      history.push(`/contacts/people`);
      AUTH.updateSwitchOrgmenu(true);
      setTabselection(1);
    }
  };
  //Open Modal to import data
  const importData = () => setImportModal(true);
  const closeImportModal = () => {
    setImportModal(false);
    //history.replace(history.location.pathname)
  };

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(16, 'edit')) {
            if (result.key === 'Enter') {
              openEditModal(e.data);
            }
          }
          if (AUTH.checkPermission(16, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteModal(e.data);
            }
          }
        }
      }
    }
  };

  //START:-----------ORGANISATION Merge Functions---------------------------//
  const toggleMergeTicket = () => {
    if (showCheckbox) {
      openMergeModal();
    } else {
      setshowCheckbox(true);
    }
  };

  const openMergeModal = () => {
    let selectedRow = ORGANISATION.agGrid.api.getSelectedRows();
    if (selectedRow.length >= 2) {
      setMergeVisibleModal(true);
      setSelectedMergeData(selectedRow);
    } else {
      vsmNotify.error({
        message: `${t('OrganisationManagement.Please_Select_Records')} 2 ${t('OrganisationManagement.Records')}.`,
      });
    }
  };
  const closeMergeModal = () => {
    setshowCheckbox(false);
    setMergeVisibleModal(false);
    setSelectedMergeData(null);
    if (ORGANISATION.agGrid) {
      ORGANISATION.agGrid.api.deselectAll();
    }
    setSelectedRowsCount(0);
  };
  const selectionChanged = () => {
    let selectedRow = ORGANISATION.agGrid.api.getSelectedRows();
    setSelectedRowsCount(selectedRow.length > 0 ? selectedRow.length : 0);
  };
  const onClearSelectionClick = () => {
    if (ORGANISATION.agGrid) {
      ORGANISATION.agGrid.api.deselectAll();
    }
    setshowCheckbox(false);
  };
  //END:-----------ORGANISATION Merge Functions---------------------------//

  useEffect(() => { }, [isSavedFiltersLoadedOrg, allPageFiltersLoaded, allPageFilters]);

  const pageFilterType = 'organisations'; //FOR PROPS: TOP FILTER DROPDOWN LIST AND RESET OPTIONS

  return (
    <>
      {!AUTH.checkPermission(16, 'list') ? (
        <Unauthorized />
      ) :
        // SearchPage ? (
        //   <>
        //     <ListComponent
        //       openViewDrawer={openViewDrawer}
        //       openEditModal={openEditModal}
        //       openDeleteModal={openDeleteModal}
        //       openRoleListing={openRoleListing}
        //       openBankAccounts={openBankAccounts}
        //       openCloneDrawer={openCloneDrawer}
        //       SearchPage={SearchPage}
        //       //showCheckbox={showCheckbox}
        //       AllView={AllView}
        //     />
        //     {addModal && (
        //       <AddComponent
        //         visible={addModal}
        //         close={closeAddModal}
        //         currentTab={currentTab}
        //       />
        //     )}
        //     <EditComponent
        //       transactionId={transactionId}
        //       setId={setId}
        //       visible={editModal}
        //       editOpen={editOpen}
        //       close={closeEditModal}
        //     />
        //     <CloneComponent
        //       transactionId={transactionId}
        //       setId={setId}
        //       visible={cloneDrawer}
        //       close={closeCloneDrawer}
        //     />

        //     <ViewComponent
        //       visible={viewDrawer}
        //       close={closeViewDrawer}
        //       openEditModal={openEditModal}
        //       handleViewDetailCall={handleViewDetailCall}
        //     />
        //     <DeleteComponent
        //       visible={deleteModal}
        //       close={closeDeleteModal}
        //       lineText={`${t('OrganisationManagement.Remove_Organisation')} ? `}
        //       SearchPage={SearchPage}
        //     />
        //     {mapModal && (
        //       <MapOrgWithLedger
        //         visible={mapModal}
        //         close={closeMapModal}
        //         mapData={mapOrgWithLedger}
        //         openAddModal={openAddModal}
        //       />
        //     )}
        //     <ImportComponent
        //       visible={importModal}
        //       close={closeImportModal}
        //       importData={importData}
        //     />
        //   </>
        // ) : 
        (
          <>
            {!SearchPage ?
              <Radio.Group
                options={
                  AUTH.checkPermission(18, 'list') ? optionsRadiolist : optionsRadioOrg
                }
                onChange={onRadiolistchange}
                value={tabselection}
                optionType='button'
                buttonStyle='solid'
                className='peopleOrgTabs'
              />
              : null}
            <PageHeader
              title={
                !SearchPage ? ``
                  : (<>
                    <div className='d-flex justify-content-start align-items-center notes'>
                      <Title level={3}>
                        {sectorSelector} : <Tooltip title={search_term} placement="topLeft">"{search_term?.length > 25 ? search_term.substring(0, 25) + "..." : search_term}"</Tooltip> {`${t('DealDesk.Results')}`}
                      </Title>
                      <div className='searchView ml-10 '>
                        <span className='sep'>|</span>
                        {resultCount ? resultCount.toLocaleString('en-GB') : resultCount}{' '}
                        {t('Common_Data.Records_found')}
                        <span className='sep'>|</span>
                        <span className='cursorPointer text-decoration-underline mr-10' onClick={handleRoute} >
                          {t('Common_Data.View_all')} {sectorSelector}
                        </span>
                      </div>
                    </div>
                  </>)
              }
              className={SearchPage ? 'SearchResult-PageHeader ' + SETTINGS.fullscreen_class : 'peopleOrg ' + SETTINGS.fullscreen_class}
              extra={[
                <Form form={form} className='innerForm headerButton narrowButtons'>
                  <Space align='center' size={3}>
                    {selectedRowsCount || showCheckbox ? (
                      <Tooltip title={`${t('Common_Data.Click')} ${t('Common_Data.To')} ${t('Common_Data.Clear')}`}>
                        <Button
                          key='1'
                          shape='round'
                          size='medium'
                          onClick={onClearSelectionClick}
                        >
                          <img
                            src={AUTH.GetThemedImage('Refresh_NoSpace')}
                            alt=''
                            className='icon'
                          />
                        </Button>
                      </Tooltip>
                    ) : null}

                    <Tooltip title={showCheckbox ? `${t('Common_Data.Click')} ${t('Common_Data.To')} ${t('Common_Data.Merge')}` : `${t('OrganisationManagement.Start')} ${t('OrganisationManagement.Merging')}`}>
                      <Button
                        key='2'
                        shape='round'
                        size='medium'
                        onClick={toggleMergeTicket}
                      >
                        <img
                          src={AUTH.GetThemedImage('Hyperlink_icn')}
                          alt=''
                          className='icon'
                        />
                      </Button>
                    </Tooltip>

                    {!SearchPage ? <ApplyGridFilterNew
                      type={pageFilterType}
                      selectionValue={ORGANISATION.selectedGridFilter}
                      filterList={filterFiltersByPageType(allPageFilters, pageFilterType)}
                    /> : null}
                    {!SearchPage ? AUTH.checkPermission(17, 'list') && (
                      <Button key='2' shape='round' size='medium' onClick={openRoleURL}>
                        {t('Common_Data.Organisation_Roles')}
                      </Button>
                    ) : null}

                    {!SearchPage ? AUTH.checkPermission(16, 'import') && (
                      <Button key='3' onClick={importData} shape='round' size='medium'>
                        {t('Common_Data.Import')}
                      </Button>
                    ) : null}
                    {!SearchPage ? !AUTH.checkPermission(16, 'export-organisation') ? null : (
                      <Dropdown key='4' overlay={menu}>
                        <Button
                          loading={exportLoadingIcn}
                          key='3'
                          onClick={(e) => e.preventDefault()}
                          shape='round'
                          size='medium'
                        >
                          {t('Common_Data.Export')}
                        </Button>
                      </Dropdown>
                    ) : null}

                    {!SearchPage ? AUTH.checkPermission(16, 'role-mapping-add') && (
                      <Button
                        key='5'
                        onClick={mapOrgWithLedger}
                        shape='round'
                        size='medium'
                      >
                        {t('Common_Data.Add')} {t('Common_Data.Organisation')} {t('Common_Data.Mapping')}
                      </Button>
                    ) : null}

                    {!SearchPage ? AUTH.checkPermission(16, 'add') && (
                      <Button key='6' onClick={openAddModal} shape='round' size='medium'>
                        {t('Common_Data.Add')} {t('Common_Data.Organisation')}
                      </Button>
                    ) : null}
                    <ResetGridOptions key={'7'} pageType={pageFilterType} />

                    <EnterFullscreen key='8' />

                    <RecordPerPage
                      key='9'
                      initialValue={per_page + ' per page'}
                      onChange={setPageSize}
                      shape='round'
                      size='medium'
                      className='narrowRecordPerPage'
                    />
                  </Space >
                </Form >,
              ]}
            >
              <ListComponent
                openViewDrawer={openViewDrawer}
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
                openRoleListing={openRoleListing}
                openBankAccounts={openBankAccounts}
                openCloneDrawer={openCloneDrawer}
                SearchPage={SearchPage}
                onCellKeyDown={onCellKeyDown}
                selectionChanged={selectionChanged}
                showCheckbox={showCheckbox}
                AllView={AllView}
              />
              <AddComponent
                visible={addModal}
                close={closeAddModal}
                currentTab={currentTab}
              />
              <EditComponent
                transactionId={transactionId}
                setId={setId}
                visible={editModal}
                editOpen={editOpen}
                close={closeEditModal}
              />
              <CloneComponent
                transactionId={transactionId}
                setId={setId}
                visible={cloneDrawer}
                close={closeCloneDrawer}
              />
              <ViewComponent
                visible={viewDrawer}
                editModal={editModal}
                openEditModal={openEditModal}
                close={closeViewDrawer}
                handleViewDetailCall={handleViewDetailCall}
              />
              <DeleteComponent
                visible={deleteModal}
                close={closeDeleteModal}
                lineText={`${t('OrganisationManagement.Remove_Organisation')} ? `}
                SearchPage={SearchPage}
              />
              {
                mapModal && (
                  <MapOrgWithLedger
                    visible={mapModal}
                    close={closeMapModal}
                    mapData={mapOrgWithLedger}
                    openAddModal={openAddModal}
                  />
                )
              }
              <ImportComponent
                visible={importModal}
                close={closeImportModal}
                importData={importData}
              />
              <MergeOrganisation
                visible={mergeVisibleModal}
                close={closeMergeModal}
                selectedMergeData={selectedMergeData}
              />
              {/* {
                !stopInvestmentHourCounter && (
                  <TrackTimeComponent pageName={SearchPage ? 'search' : 'organisation'} />
                )
              } */}
              <TimeTracker
                page={"organisation"}
                stop={addModal || editModal || viewDrawer || cloneDrawer}
              />
            </PageHeader >
          </>
        )}
    </>
  );
});

export default Organisations;
