import Axios from 'axios';
import { decorate, observable, action } from 'mobx';

export default class Dashboardstore {
  leads_total_count = 0;
  opportunity_total_count = 0;
  deals_total_count = 0;
  ticket_total_count = 0;

  active_days_leads = 7;
  active_days_opportunity = 7;
  active_days_deals = 7;
  active_days_ticket = 7;

  report_data = null;

  fetchLeadsTotalCount = (days) => {
    this.active_days_leads = days;
    return Axios.get(`dashboard/leadscount/${days}`)
      .then(({ data }) => {
        this.leads_total_count = data && data.data && data.data.count;
        return data;
      })
      .catch((data) => {
        return Promise.reject(data);
      });
  };

  fetchOpportunityTotalCount = (days) => {
    this.active_days_opportunity = days;
    return Axios.get(`dashboard/opportunitiescount/${days}`)
      .then(({ data }) => {
        this.opportunity_total_count = data && data.data && data.data.count;
        return data;
      })
      .catch((data) => {
        return Promise.reject(data);
      });
  };
  fetchDealsTotalCount = (days) => {
    this.active_days_deals = days;
    return Axios.get(`dashboard/dealscount/${days}`)
      .then(({ data }) => {
        this.deals_total_count = data && data.data && data.data.count;
        return data;
      })
      .catch((data) => {
        return Promise.reject(data);
      });
  };
  fetchTicketTotalCount = (days) => {
    this.active_days_ticket = days;
    return Axios.get(`dashboard/ticketscount/${days}`)
      .then(({ data }) => {
        this.ticket_total_count = data && data.data && data.data.count;
        return data;
      })
      .catch((data) => {
        return Promise.reject(data);
      });
  };

  fetchDashboardReport = () => {
    return Axios.get(`dashboard/favorite/report`)
      .then(({ data }) => {
        this.report_data = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
}

decorate(Dashboardstore, {
  leads_total_count: observable,
  opportunity_total_count: observable,
  deals_total_count: observable,
  ticket_total_count: observable,
  active_days_leads: observable,
  active_days_opportunity: observable,
  active_days_deals: observable,
  active_days_ticket: observable,
  report_data: observable,
  fetchLeadsTotalCount: action,
  fetchOpportunityTotalCount: action,
  fetchDealsTotalCount: action,
  fetchTicketTotalCount: action,
  fetchDashboardReport: action,
});
