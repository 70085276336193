import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { vsmCommon } from '../../../config/messages';
import useStore from '../../../store';
import { Button } from 'antd';

import LocalGridConfig from '../../../config/LocalGridConfig';
import { DateComparator } from '../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { t } = useTranslation();
  const { onCellKeyDown, SearchPage } = props;

  const {
    PEOPLE,
    KNOWLEDGE,
    AUTH,
    SEARCHSTORE: { searchCount, setResultCount },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  useEffect(() => {
    if (SearchPage) {
      setResultCount(searchCount?.knowledge_count);
      KNOWLEDGE.getList();
    } else {
      KNOWLEDGE.search_payload = {};
      KNOWLEDGE.getList();
    }
  }, [KNOWLEDGE, KNOWLEDGE.getList, SearchPage, setResultCount, searchCount]);

  const ActionRenderer = (params) => {
    return (
      <div className='action-column'>
        {AUTH.checkPermission(20, 'view-details') && (
          <Button
            type='text'
            title={`${t('Common_Data.View')}`}
            onClick={() => props.openViewDrawer(params.data)}
          >
            <img
              alt={`${t('Common_Data.View')}`}
              className='menuicon'
              src={AUTH.GetThemedImage('View')}
            />
          </Button>
        )}
        {AUTH.checkPermission(20, 'edit') && (
          <Button
            type='text'
            title={`${t('Common_Data.Edit')}`}
            onClick={() => props.openEditDrawer(params.data)}
          >
            <img
              alt={`${t('Common_Data.Edit')}`}
              className='menuicon'
              src={AUTH.GetThemedImage('Edit')}
            />
          </Button>
        )}
        {AUTH.checkPermission(20, 'delete-knowledge') && (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            onClick={() => props.openDeleteDrawer(params.data)}
          >
            <img
              alt={`${t('Common_Data.Edit')}`}
              className='menuicon'
              src={AUTH.GetThemedImage('Waste')}
            />
          </Button>
        )}
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('DealDesk.Knowledge_Title')}`,
        field: 'subject',
        filter: 'agTextColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Knowledge.Group_Name')}`,
        field: 'knowledge_group',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            KNOWLEDGE.getKnowledgeGroupList().then((data) => {
              params.success([...data.data.map((x) => x.knowledge_group)]);
            });
          },
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Credebt_Classification')}`,
        field: 'classification_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            KNOWLEDGE.getClassificationList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('DealDesk.Knowledge_Classification')}`,
        field: 'knowledge_classification',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            params.success([
              ...AUTH.user?.knowledge_classification.map(
                (x) => x.knowledge_classification
              ),
            ]);
          },
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        headerTooltip: `${t('Common_Data.Description')}`,
        field: 'description',
        tooltipValueGetter: (params) => {
          return params?.data?.description.replace(/<(.|\n)*?>/g, '');
        },
        cellRenderer: (params) => {
          var a = document.createElement('div');
          a.innerHTML = params.data.description;
          return a;
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },

      {
        headerName: `${t('Common_Data.Last_Updated_By')}`,
        field: 'updated_by_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            PEOPLE.getUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Last_Updated_At')}`,
        field: 'updated_at',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.updated_at ? AUTH.getLocalTime(params.data.updated_at,AUTH.global_fulldate_format) : '';
        },
        floatingFilter: true,
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',

        maxWidth: 100,
        // lockPosition: true,
        // suppressNavigable: true
        filter: 'agNumberColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',

        maxWidth: 120,
        filter: false,
        sortable: false,
        pinned: 'right',
        // lockPosition: true,
        // suppressNavigable: true
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('knowledge_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['description'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }

    return item;
  });

  return (
    <div
      className={`ag-theme-alpine grid_wrapper ${SearchPage ? "paginationAtTop" : ""}`}
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth :
          SearchPage ? 'calc(100vh - 13rem)' : 'calc(100vh - 11rem)',
      }}
    >
      <AgGridReact
        rowData={KNOWLEDGE.list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={{
          ...LocalGridConfig.defaultColDef,
          width: 120,
            minWidth: 70,
            flex: 1,
          floatingFilter: false,
        }}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
        }}
        onGridReady={KNOWLEDGE.setupGrid}
        gridOptions={{
          ...LocalGridConfig.options,
          onRowDoubleClicked: (event) => {
            AUTH.checkPermission(20, 'view-details') && props.openViewDrawer(event?.data);
          },
          pagination: SearchPage ? true :false,
          rowHeight: 30,
        }}
        onColumnResized={KNOWLEDGE.onGridChanged}
        onColumnMoved={KNOWLEDGE.onGridChanged}
        onColumnPinned={KNOWLEDGE.onGridChanged}
        onFilterChanged={KNOWLEDGE.onFilterChanged}
        onSortChanged={KNOWLEDGE.onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        onCellKeyDown={onCellKeyDown}
      />
    </div>
  );
});

export default ListComponent;
