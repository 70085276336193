import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col } from 'antd';
import InputComponent from '../../../component/InputComponent';
import useStore from '../../../store';
import { vsmNotify, vsmUsers } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const SalesFormComponent = observer(({ handleSubmit, onChange, form, id, visible }) => {
  const { t } = useTranslation();
  const { USERS } = useStore();
  const [salesTragetFieldValue, setSalesTragetFieldValue] = useState();
  const onFinish = (data) => {
    data.user_id = USERS.salesTargetValues;
    USERS.updateSalesTarget(data)
      .then(() => {
        vsmNotify.success({
          message: vsmUsers.userSalesTarget,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally();
  };

  useEffect(() => {
    if (visible) {
      USERS.fetchSalesTargetData(USERS.salesTargetValues).then((data) => {
        setSalesTragetFieldValue(data.sales_target);

        let formData = [];

        // formData.push({
        //   name: data.sales_target.name,
        //   sales_target: data.sales_target.sales_target,
        //   days_target: data.sales_target.days_target,
        // });

        if (data.sales_target.length > 0) {
          data.sales_target?.map((obj) => {
            formData.push(obj);
            return null;
          });
          // form.setFieldsValue({
          // 	details: partnerviewValues.fpfamily,
          // });
        }

        form.setFieldsValue({
          sales_target: formData,
        });

        // form.setFieldsValue({
        //   sales_target: data.sales_target.sales_target
        //     ? data.sales_target.sales_target
        //     : null,
        //   days_target: data.sales_target.days_target && data.sales_target.days_target,
        //   name: data.sales_target.name && data.sales_target.name,
        // });
      });
    }
  }, [USERS, USERS.salesTargetValues, USERS.fetchSalesTargetData, form, visible]);

  return (
    <Form
      form={form}
      className='innerForm'
      labelCol={{ span: 24 }}
      id={id}
      onFinish={onFinish}
    >
      <Form.List name='sales_target' initialValue={[null, null, null]}>
        {(fields) => {
          return (
            <div>
              <Row gutter={30}>
                <Col sm={{ span: 6 }} className='pb-20'>
                  {/* <strong>Name</strong> */}
                  <InputComponent
                    type='labelOnly'
                    label={`${t('OrganisationManagement.name')}`}
                    tooltip={`${t('OrganisationManagement.name')}`}
                  />
                </Col>
                <Col sm={{ span: 7 }}>
                  {/* <strong>Sales Target </strong> */}
                  <InputComponent
                    type='labelOnly'
                    label={`${t('OrganisationManagement.SalesTarget')}`}
                    tooltip={`${t('OrganisationManagement.SalesTarget_ToolTip')}`}
                    required
                  />
                </Col>
                <Col sm={{ span: 11 }}>
                  {/* <strong>Days Target</strong> */}
                  <InputComponent
                    type='labelOnly'
                    label={`${t('OrganisationManagement.DaysTarget')}`}
                    tooltip={`${t('OrganisationManagement.DaysTarget_ToolTip')}`}
                    required
                  />
                </Col>

                {salesTragetFieldValue &&
                  salesTragetFieldValue.map((field, index) => {
                    return (
                      <>
                        <Col sm={{ span: 6 }}>
                          <InputComponent
                            name={[index, 'name']}
                            // placeholder={`${t('OrganisationManagement.days')}`}
                            type='input'
                            // onChange={onChange}
                            initialValue={field.name}
                            // rules={vsmUsers.validation.days}
                            //label={field.name}
                            bordered={false}
                            disabled
                          />
                        </Col>
                        <Col sm={{ span: 7 }}>
                          <InputComponent
                            name={[index, 'sales_target']}
                            placeholder={`${t('OrganisationManagement.Sales')} ${t(
                              'Common_Data.Target'
                            )}`}
                            type='number'
                            defaultValue='0'
                            value={field.sales_target}
                            onChange={onChange}
                            rules={vsmUsers.validation.sales_target}
                          />
                        </Col>
                        <Col sm={{ span: 11 }}>
                          <InputComponent
                            name={[index, 'days_target']}
                            placeholder={`${t('Common_Data.Days')} ${t(
                              'Common_Data.Target'
                            )}`}
                            type='select'
                            defaultValue={field.days_target}
                            // initialValue='Daily'
                            options={{
                              // label: `${t('DealBuilder.Test')}`,
                              values: [
                                { value: '1', text: `${t('Users.Daily')}` },
                                { value: '7', text: `${t('Users.Weekly')}` },
                                {
                                  value: '14',
                                  text: `${t('Users.Bi')}-${t('Users.Weekly')}`,
                                },
                                {
                                  value: '21',
                                  text: `${t('Users.Tri')}-${t('Users.Weekly')}`,
                                },
                                { value: '30', text: `${t('Users.Monthly')}` },
                                {
                                  value: '60',
                                  text: `${t('Users.Bi')}-${t('Users.Monthly')}`,
                                },
                                { value: '90', text: `${t('Users.Quarterly')}` },
                                {
                                  value: '180',
                                  text: `${t('Users.Half')} ${t('Users.Yearly')}`,
                                },
                                { value: '360', text: `${t('Users.Yearly')}` },
                              ],
                              value_key: 'value',
                              text_key: 'text',
                            }}
                            onChange={onChange}
                            rules={vsmUsers.validation.days_target}
                          />
                        </Col>
                      </>
                    );
                  })}
              </Row>
            </div>
          );
        }}
      </Form.List>
    </Form>
  );
});

export default SalesFormComponent;
