import { Col } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../../component/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';

const DateSelectionElement = observer(
  ({ onChange, disabledDate, SetDateRange, formDataList }) => {
    const { REPORTSTORE } = useStore();
    const { t } = useTranslation();
    return (
      <>
        {formDataList.includes('single_date') && (
          <Col span={4}>
            <InputComponent
              label={`${t('Common_Data.Date')}`}
              type='date'
              required
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Date')}`}
              name='single_date'
              onChange={onChange}
              rules={vsmTransactionReport.validation.date}
              tooltip={`${t('Reports.Fetch_Reports')}.`}
            />
          </Col>
        )}
        {formDataList.includes('date_range') && (
          <Col span={8}>
            <InputComponent
              label={`${t('Common_Data.Date')} ${t('Reports.Range')}`}
              type='date_range'
              name='date_range'
              placeholder={[`${t('DealDesk.Start_Date')}`, `${t('DealDesk.End_Date')}`]}
              required
              onChange={onChange}
              rules={vsmTransactionReport.validation.date_range}
              tooltip={`${t('Reports.Date_Range_Tooltip')}.`}
              disabledDate={disabledDate}
              onCalendarChange={(val) => SetDateRange(val)}
            />
          </Col>
        )}

        {(formDataList.includes('period_x_value') ||
          formDataList.includes('period_x_value_type')) && (
            <>
              {formDataList.includes('period_x_value') && (
                <Col span={2}>
                  <InputComponent
                    label={`${t('Common_Data.Last')}`}
                    required
                    type='number'
                    name='period_x_value'
                    min={1}
                    max={12}
                    className='w-100'
                    onChange={onChange}
                    rules={vsmTransactionReport.validation.period_x_value}
                    tooltip={`${t('Reports.Date_Selection')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('period_x_value_type') && (
                <Col span={8}>
                  <InputComponent
                    label='&nbsp;'
                    onChange={onChange}
                    type='radio_button'
                    rules={[{ required: false, message: '' }]}
                    name='period_x_value_type'
                    options={{
                      className: 'd-flex w-100',
                      values: [
                        { value: 'day', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Days') : t('Reports.Day')}` },
                        { value: 'week', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Weeks') : t('Common_Data.Week')}` },
                        { value: 'month', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Months') : t('Common_Data.Month')}` },
                        { value: 'quarter', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Quarters') : t('Common_Data.Quarter')}` },
                        { value: 'year', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Years') : t('Reports.Year')}` },
                      ],
                    }}
                  />
                </Col>
              )}
            </>
          )}

        {formDataList.includes('financial_year') && (
          <Col span={5}>
            <div className='w400'>
              <InputComponent
                label={`${t('Reports.Financial')} ${t('Reports.Year')}`}
                required
                type='select'
                name='financial_year'
                placeholder={`${t('Reports.Financial')} ${t('Reports.Year')}`}
                onChange={onChange}
                options={{
                  values: REPORTSTORE.financial_year_list,
                }}
                tooltip={`${t('Reports.Financial_Year_Tooltip')}.`}
                rules={vsmTransactionReport.validation.financial_year}
              />
            </div>
          </Col>
        )}
      </>
    );
  }
);

export default DateSelectionElement;
