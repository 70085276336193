import React, { useEffect, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import LeftSideComponent from "./LeftSideComponent";
import RightSideComponent from "./RightSideComponent";
import { Row, Col, Spin } from "antd";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import useStore from "../../store";
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import { useTranslation } from "react-i18next";
import TimeTracker from "../../component/TimeTracker";

const DealDesk = observer((props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [stopTimer, setStopTimer] = useState(false);

  const {
    DEALDESKSTORE: {
      deal_data,
      getDealDetail,
      resetDealData,
      setStatusPayloadForShortfall,
    },
    DEALDESKNOTESOUTBOUND: { fetchClassificationList, setAutoSaveIDTempData },
    DEALDESKNOTESEQUENCE: { resetNoteData },
    SSSLEADLIBRARYSTORE: { resetAutosaveData },
    TimeTrackerStore,
  } = useStore();
  const { id } = useParams();

  useEffect(() => {
    fetchClassificationList();
    getDealDetail(id).then((data) => {
      setLoading(false);
    });

    return function cleanup() {
      resetDealData();
      resetNoteData();
      resetAutosaveData();
      setStatusPayloadForShortfall(null);
      setAutoSaveIDTempData(null);
      localStorage.removeItem("autoSavePending");
    };
  }, [
    getDealDetail,
    id,
    resetDealData,
    setStatusPayloadForShortfall,
    fetchClassificationList,
    resetNoteData,
    resetAutosaveData,
    setAutoSaveIDTempData,
  ]);

  useEffect(() => {
    if (TimeTrackerStore.stopForDesk) {
      setStopTimer(true);
    } else {
      setStopTimer(false);
    }
  }, [TimeTrackerStore.stopForDesk]);

  return deal_data && deal_data.lead_information ? (
    <>
      <div className="">
        <Spin size="medium" spinning={loading} tip={`${t("Common_Data.Load")}`}>
          <HeaderComponent />
          <Row gutter={6} className="halfcut__content">
            <Col span={12}>
              <LeftSideComponent />
            </Col>
            <Col span={12}>
              <RightSideComponent />
            </Col>
          </Row>

          {/* {!stopInvestmentHourCounter && (
            <TrackTimeComponent
              pageName={'dealdesk'}
              leadId={deal_data.lead_information.id}
              isDealDesk={true}
            />
          )} */}

          <TimeTracker stop={stopTimer} />
        </Spin>
      </div>
    </>
  ) : (
    <>
      <div className="spinInside">
        <Spin
          size="medium"
          align="centered"
          spinning={loading}
          tip={`${t("Common_Data.Load")}`}
        ></Spin>
      </div>
    </>
  );
});

export default DealDesk;
