import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ListComponent from '../../Tags/component/ListComponent';
import ViewComponent from '../../Tags/component/ViewComponent';
import DeleteComponent from '../../Tags/component/DeleteComponent';
import RestoreComponent from './RestoreComponent';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const Tags = observer((props) => {
  const { WasteManagement } = props;
  const { TagsStore, WASTESTORE } = useStore();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [restoreValues, setRestoreValues] = useState(false);
  const { t } = useTranslation()
  // Open & Close Restore Model function
  const openRestoreModal = (data) => {
    setRestoreValues(data)
    setRestoreModal(true);
  };

  const closeRestoreModal = () => setRestoreModal(false);

  // Open & Close Delete Model function
  const openDeleteDrawer = (data) => {
    TagsStore.setDeleteValues(data)
    setDeleteDrawer(true);
  };

  const closeDeleteDrawer = () => setDeleteDrawer(false);

  // Open & Close View Drawer function
  const openViewDrawer = (data) => {
    TagsStore.setViewValues(data)
    setViewDrawer(true);
  };

  const closeViewDrawer = () => setViewDrawer(false);

  return (
    <>
      <ListComponent
        WasteManagement={WasteManagement}
        openViewDrawer={openViewDrawer}
        openDeleteDrawer={openDeleteDrawer}
        openRestoreModal={openRestoreModal}
      />
      <ViewComponent visible={viewDrawer} close={closeViewDrawer} />
      <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} getWasteCount={WASTESTORE.getWasteCount} lineText={`${t('Waste.Permanently_Remove_Record')}?`} />
      <RestoreComponent visible={restoreModal} close={closeRestoreModal} getWasteCount={WASTESTORE.getWasteCount} store={TagsStore} id={"restoretagform"}
        currentValues={restoreValues} lineText={`${t('Waste.Tag')}`} title={`${t('Common_Data.Restore')} ${t('Waste.Tag')} - #${restoreValues && restoreValues.id}`} />
    </>
  );
});

export default Tags;
