import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Form, Select, Spin, Col, Row } from 'antd';
import useStore from '../../../../store';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const ReminderForElement = observer((props) => {
  const { typeValue } = props;
  const { Option, OptGroup } = Select;
  const [ticketListKey, setTicketListKey] = useState();
  const [leadListKey, setLeadListKey] = useState();

  const { t } = useTranslation();

  const {
    REPORTSTORE: {
      fetchingTicketSearch,
      ticket_list_data,
      searchTicket,
      fetchingLead,
      lead_search_dropdown_data,
      searchLeadSOLDName,
    },
  } = useStore();

  // search string for dropdown
  const handleSearch = useMemo(() => {
    const loadOptions = (val, type) => {
      if (type === 'tickets') {
        if (val && val.length >= 3) {
          setTicketListKey(val);
          searchTicket({ search_text: val });
        }
      } else if (type === 'leads') {
        if (val && val.length >= 3) {
          setLeadListKey(val);
          searchLeadSOLDName({ search_text: val });
        }
      }
    };

    return debounce(loadOptions, 500);
  }, [searchTicket, searchLeadSOLDName]);

  const handleChange = (value, name) => {
    props.onChange();
  };

  return (
    <>
      <Row gutter={24}>
        {typeValue?.includes('tickets') && (
          <Col span={24}>
            <Form.Item
              label={t("Reports.Reminder_For_Ticket")}
              tooltip={t("Reports.Reminder_created_for")}
              name='reminder_ticket_id'
            >
              <Select
                name='reminder_ticket_id'
                // className='personName'
                type='select'
                placeholder={t("Reports.Select_Reminder_For")}
                showSearch
                showArrow
                mode='multiple'
                allowClear={true}
                filterOption={false}
                onChange={handleChange}
                notFoundContent={
                  fetchingTicketSearch ? (
                    <Spin size='small' />
                  ) : ticketListKey ? (
                    `${t('Common_Data.No_Record_Found')}.`
                  ) : null
                }
                onSearch={(val) => {
                  handleSearch(val, 'tickets');
                }}
                onClear={(val) => {
                  handleSearch(val, 'tickets');
                }}
              >
                {ticket_list_data?.length ? (
                  <OptGroup label='Tickets'>
                    {ticket_list_data?.map((item, index) => (
                      <Option value={item.id} name={item.ticket_subject} key={index}>
                        {`${item.ticket_number} | ${item.ticket_subject}`}
                      </Option>
                    ))}
                  </OptGroup>
                ) : null}
              </Select>
            </Form.Item>
          </Col>
        )}
        {typeValue?.includes('leads') && (
          <Col span={24}>
            <Form.Item
              label={t("Reports.Reminder_For_SOLD")}
              tooltip={t("Reports.Reminder_created_for")}
              name='reminder_leads_id'
            >
              <Select
                name='reminder_leads_id'
                // className='personName'
                type='select'
                placeholder={t("Reports.Select_Reminder_For")}
                showSearch
                showArrow
                mode='multiple'
                allowClear={true}
                filterOption={false}
                onChange={handleChange}
                notFoundContent={
                  fetchingLead ? (
                    <Spin size='small' />
                  ) : leadListKey ? (
                    `${t('Common_Data.No_Record_Found')}.`
                  ) : null
                }
                onSearch={(val) => {
                  handleSearch(val, 'leads');
                }}
                onClear={(val) => {
                  handleSearch(val, 'leads');
                }}
              >
                {lead_search_dropdown_data?.length ? (
                  <OptGroup label='SOLD'>
                    {lead_search_dropdown_data?.map((item, index) => (
                      <Option value={item.id} name={item.opportunity_name} key={index}>
                        {`${item.id} | ${item.opportunity_name}`}
                      </Option>
                    ))}
                  </OptGroup>
                ) : null}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
});
export default ReminderForElement;
