import React, { useState, useEffect } from 'react';
import { Drawer, Button, Form } from 'antd';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import moment from 'moment';

import FormComponent from './FormComponent';
import useStore from '../../../store';
import { vsmNotify, vsmTags } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [content, setContent] = useState(null);

  const [form] = Form.useForm();

  const {
    TagsStore: { editValues, editTag },
    AUTH,
  } = useStore();
  const { t } = useTranslation()
  const close = () => {
    props.close();
    setDisabled(true);
    setSaving(false);
    setContent(null);
    form.resetFields();
  };

  const handleSubmit = (data) => {
    data.deadline_date = moment(data.deadline_date).format('YYYY-MM-DD');
    setSaving(true);
    editTag(editValues.id, data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmTags.edit,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (editValues) {
      form.setFieldsValue({
        tag_subject: editValues?.tag_subject,
        tag_number: editValues?.tag_number,
        organisation_id: editValues?.global_organisation_id
          ? editValues?.global_organisation_id
          : null,
        holder: editValues?.holder,
        tag_status: editValues?.tag_status_id,
        tag_content: editValues?.tag_content,
        parent_ticket_id: editValues.parent_ticket_id
          ? editValues.parent_ticket_id
          : null,
        deadline_date: editValues.deadline_date && moment(editValues.deadline_date),
      });
      setContent(editValues?.tag_content);
    }
  }, [editValues, form, AUTH.global_dateformat]);

  return (
    editValues && (
      <>
        <Drawer
          visible={props.visible}
          onClose={close}
          placement='right'
          width={'1050px'}
          title={`${t('Common_Data.Edit')} ${t('Tags.Tag')} ${editValues.id ? ' - #' + editValues.id : ''}`}
          destroyOnClose={true}
          footer={[
            <div className='text-right' key='1'>
              <Button
                key='1'
                form='tageditform'
                className='mr-10'
                loading={saving}
                htmlType='submit'
                type='primary'
                shape='round'
                disabled={isDisabled}
              >
                {t('Common_Data.Update')}
              </Button>
              <Button shape='round' onClick={close} key='2'>
                {t('Common_Data.Cancel')}
              </Button>
            </div>,
          ]}
        >
          <FormComponent
            form={form}
            onChange={handleChange}
            handleSubmit={handleSubmit}
            visible={props.visible}
            id='tageditform'
            content={content}
          />
        </Drawer>
      </>
    )
  );
});

export default EditComponent;
