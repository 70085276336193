import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import useStore from '../../../store';
// import TrackTimeComponent from '../../../component/TrackTimeComponent';
import TimeTracker from '../../../component/TimeTracker';
import moment from 'moment';
import debounce from 'lodash/debounce';

//import { convertNumberToComma } from '../../../utils/GlobalFunction';
import { vsmNotify, vsmCallAudit, vsmLeadsList } from '../../../config/messages';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import StructureTableContent from '../../DealDesk/RightSideComponent/Structure/component/StructureTableContent';
import InputComponent from '../../../component/InputComponent';
import EditComponent from '../../DealDesk/RightSideComponent/Structure/component/EditComponent';

const ViewComponent = observer((props) => {
  const { t } = useTranslation();
  const { openEditDrawer = () => { }, isWaste = false } = props;
  const [form] = Form.useForm();
  const location = useLocation();

  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [failedReasonList, setFailedReasonList] = useState([]);
  const [rejectedReasonList, setRejectedReasonList] = useState([]);
  const [futureReasonList, setFutureReasonList] = useState([]);

  const [editDrawerShortfall, setEditDrawerShortfall] = useState(false);

  const {
    AUTH,
    LEADSSTORE,
    LEADSSTORE: { viewValues },
    LEADDEALS, LEADOPPORTUNITY, LEADACTIVE, PROMOTIONSTORE, DEALDESKSTORE, DEALDESKNOTESEQUENCE, DEALDESKNOTESOUTBOUND, DEALDESKSTRUCTURELIBRARY,
  } = useStore();

  const close = () => {
    // LEADSSTORE.viewValues = null;
    props.close();
    setDisabled(true);
    setSaving(false);
  };

  const handleCallAudit = (data) => {
    let formdata = {
      id: viewValues.id,
      phone: data,
    };
    LEADSSTORE.callAudit(formdata).then(() => {
      vsmNotify.success({
        message: vsmCallAudit.added,
      });
    });
  };

  const handleEmailCallAudit = (data) => {
    let formdata = {
      id: viewValues.id,
      email: data,
    };
    LEADSSTORE.callEmailAudit(formdata).then(() => {
      vsmNotify.success({
        message: vsmCallAudit.emailadded,
      });
    });
  };

  useEffect(() => {
    if (viewValues) {
      setFailedReasonList(AUTH.user?.failed_reasons);
      setRejectedReasonList(AUTH.user?.rejected_reasons);
      setFutureReasonList(AUTH.user?.future_reasons);

      form.setFieldsValue({
        failed_reason: viewValues.failed_reason,
        failed_summary: viewValues.failed_summary,
        summary: viewValues.summary,
        rejected_reason: viewValues.rejected_reason,
        future_reason: viewValues.future_reason,
      })
    }
  }, [viewValues, form, AUTH?.user]);

  const scrollToNote = debounce((id) => {
    DEALDESKSTORE.setLeftSectionActiveTab('5');
    if (document.getElementById(`#n${id}`)) {
      document.getElementById(`#n${id}`).scrollIntoView();
    }
  }, 500);

  const handleSubmit = (data) => {
    LEADSSTORE.updateReasonAndDummary(viewValues.id, data).then(() => {
      close();
      if (location.pathname === '/leads/deals') {
        LEADDEALS.setupGrid(LEADDEALS.agGrid);
      }
      if (location.pathname === '/leads/opportunities') {
        LEADOPPORTUNITY.setupGrid(LEADOPPORTUNITY.agGrid);
      }
      if (location.pathname === '/leads/active') {
        LEADACTIVE.setupGrid(LEADACTIVE.agGrid);
      }
      if (location.pathname === '/promotion') {
        PROMOTIONSTORE.setupGrid(PROMOTIONSTORE.agGrid);
      }
      if (location.pathname === '/leads/deals/kanban') {
        LEADSSTORE.getKanbanStatusList('Deal');
      }
      vsmNotify.success({
        message: vsmLeadsList.successStatusUpdate,
      });



      // For REJECTED Note
      if (data?.summary && (data?.summary !== viewValues?.summary)) {
        let payload = { "notes_flag": null, "notes_description": null }
        payload.notes_flag = 2;
        payload.notes_description = `<rejectionSummary>Rejection Summary<br /><br />${data?.summary}</rejectionSummary>`;

        // API Call for REJECTED Note
        DEALDESKNOTESOUTBOUND.saveOutboundNote(viewValues?.id, payload).then((res) => {
          if (res && res?.data) {
            DEALDESKNOTESEQUENCE.addUpdateNoteList(res?.data, false);
            scrollToNote(res?.data?.id);
          }
        })
      }

      // For FAILED  Note
      if (data?.failed_summary && (data?.failed_summary !== viewValues?.failed_summary)) {
        let payload = { "notes_flag": null, "notes_description": null }
        payload.notes_flag = 2;
        payload.notes_description = `<rejectionSummary>Failed Summary<br /><br />${data?.failed_summary}</rejectionSummary>`

        // API Call for FAILED Note
        DEALDESKNOTESOUTBOUND.saveOutboundNote(viewValues?.id, payload).then((res) => {
          if (res && res?.data) {
            DEALDESKNOTESEQUENCE.addUpdateNoteList(res?.data, false);
            scrollToNote(res?.data?.id);
          }
        })
      }

    })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  }
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);


  // Edit Drawer Shortfall
  // let shortfallValues = {};
  const openEditDrawerShortfall = (data, val) => {
    if (val === "Failed") {

      DEALDESKSTRUCTURELIBRARY.setEditValues({
        ...viewValues?.failed_shortfall,
        shortfall_type: 'failed',
        classification_name: viewValues?.failed_shortfall?.classification_name,
        classification_id: viewValues?.failed_shortfall?.classification_id,
        leads_transactions_id:viewValues?.failed_shortfall?.leads_transactions_id,
        id:viewValues?.failed_shortfall?.id,
        autosave: 0,
        type: 5,
        is_library: true,
      });
    }
    if (val === "Rejected") {
      DEALDESKSTRUCTURELIBRARY.setEditValues({
        ...viewValues?.rejected_shortfall,
        shortfall_type: 'rejected',
        classification_name: viewValues?.rejected_shortfall?.classification_name,
        classification_id: viewValues?.rejected_shortfall?.classification_id,
        leads_transactions_id:viewValues?.rejected_shortfall?.leads_transactions_id,
        id:viewValues?.rejected_shortfall?.id,
        autosave: 0,
        type: 5,
        is_library: true,
      });
    }
    setEditDrawerShortfall(true);
  };
  

  const closeEditDrawerShortfall = () => setEditDrawerShortfall(false);

  return (
    <>
      {viewValues && <Drawer
        visible={props.visible}
        onClose={close}
        placement='right'
        width={'1100px'}
        title={`${t('Common_Data.View')} ${t('Common_Data.Sold')} - #${viewValues?.id}`}
        destroyOnClose={true}
        footer={[
          <>
            {!isWaste && (
              <Link
                to={viewValues?.id ? `/dealdesk/${viewValues?.id}` : '#nogo'}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  className='menuicon'
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '15px',
                    right: '20px',
                    width: '20px',
                  }}
                  alt=''
                  title={`${t('Common_Data.View')} ${t('Tickets.Chat')} ${t(
                    'Tickets.History'
                  )}`}
                  src={AUTH.GetThemedImage('Deal_icn')}
                />
              </Link>
            )}
            {AUTH.checkPermission(11, 'edit') && openEditDrawer && !isWaste && (
              <>
              <img
                className='menuicon'
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '17px',
                  right: '60px',
                  width: '20px',
                }}
                alt=''
                title={`${t('Common_Data.Edit')} ${t('Common_Data.Lead')}`}
                src={AUTH.GetThemedImage('Edit')}
                onClick={() => openEditDrawer(viewValues, true)}
              />
              </>
            )}

            {(
              viewValues?.rejected_reason || viewValues?.failed_reason || viewValues?.future_reason
            ) &&
              <div className='text-right w-100 d-flex justify-content-end'>
                <Button
                  key='5'
                  form='viewleadform'
                  className='mr-10'
                  loading={saving}
                  htmlType='submit'
                  type='primary'
                  shape='round'
                  disabled={isDisabled}
                >
                  {t('Common_Data.Update')}
                </Button>
              </div>
            }
          </>,
        ]}
      >
        <div className='viewPeople' key='1'>
          <Form form={form} layout='vertical' id={'viewleadform'} onFinish={handleSubmit} onChange={handleChange} className='innerForm'>
            <table cellPadding='0' cellSpacing='0' border='0'>
              <tbody>
                <tr>
                  <td width='20%' style={{ border: '0' }}></td>
                  <td width='30%' style={{ border: '0' }}></td>
                  <td width='20%' style={{ border: '0' }}></td>
                  <td width='30%' style={{ border: '0' }}></td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.Sold')} {t('LeadListing.Record')}{' '}
                    {t('Common_Data.Type')}:
                  </th>
                  <td>{viewValues?.lead_record_type}</td>
                  <th>
                    <span
                      className='converbillLink'
                      onClick={() => {
                        window.open(
                          'https://www.convertibill.com/credebtclassifications-popup.html',
                          '_blank',
                          'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                        );
                      }}
                    >
                      {t('Common_Data.Credebt_Classification')}
                    </span>
                    :
                  </th>
                  <td>{viewValues?.classifications?.name}</td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.Sold')} {t('Common_Data.Name')}:
                  </th>
                  <td colSpan='3'>
                    {viewValues.opportunity_name ? viewValues.opportunity_name : ''}
                  </td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.Full')} {t('Common_Data.Name')}:
                  </th>
                  <td colSpan='3'>
                    {(viewValues.people && viewValues?.people.first_name
                      ? viewValues?.people.first_name
                      : '') +
                      ' ' +
                      (viewValues.people && viewValues?.people.last_name
                        ? viewValues?.people.last_name
                        : '')}
                  </td>
                </tr>
                <tr>
                  <th>{t('Common_Data.Organisation')}:</th>
                  <td colSpan='3'>{viewValues?.sold_organisation?.organisation_name}</td>
                </tr>
                <tr>
                  <th>{t('LeadListing.Product')}:</th>
                  <td colSpan='3'>{viewValues?.product_name}</td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.People')} {t('Common_Data.Phone')}:
                  </th>
                  <td colSpan='3'>
                    <ul className='contactListing'>
                      {viewValues.people_phone.map((item, key) => {
                        return (
                          <>
                            <li key={key}>
                              {`${t(`Common_Data.${item.contact_for}`)}`}
                              {`: `}
                              <a
                                href={`tel:${item?.contact_value}`}
                                onClick={() => {
                                  handleCallAudit(item.contact_value);
                                }}
                              >
                                {item?.contact_value}
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>{t('Common_Data.Phone')}:</th>
                  <td colSpan='3'>
                    <ul className='contactListing'>
                      {viewValues.phone.map((item, key) => {
                        return (
                          <>
                            <li key={key}>
                              {`${t(`Common_Data.${item.contact_for}`)}`}
                              {`: `}
                              <a
                                href={`tel:${item?.contact_value}`}
                                onClick={() => {
                                  handleCallAudit(item.contact_value);
                                }}
                              >
                                {item?.contact_value}
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </td>
                </tr>

                <tr>
                  <th>
                    {t('Common_Data.People')} {t('Common_Data.Email')} {t('Common_Data.ID')}
                  </th>
                  <td colSpan='3'>
                    <ul className='emailListing'>
                      {viewValues.people_email.map((item, key) => {
                        return (
                          <>
                            <li key={key}>
                              {`${t(`Common_Data.${item.contact_for}`)}`}
                              {`: `}
                              <a
                                href={`mailto:${item?.contact_value}`}
                                onClick={() => {
                                  handleEmailCallAudit(item.contact_value);
                                }}
                              >
                                {item?.contact_value}
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.Email')} {t('Common_Data.ID')}: :
                  </th>
                  <td colSpan='3'>
                    <ul className='emailListing'>
                      {viewValues.email.map((item, key) => {
                        return (
                          <>
                            <li key={key}>
                              {`${t(`Common_Data.${item.contact_for}`)}`}
                              {`: `}
                              <a
                                href={`mailto:${item?.contact_value}`}
                                onClick={() => {
                                  handleEmailCallAudit(item.contact_value);
                                }}
                              >
                                {item?.contact_value}
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.Sold')} {t('Common_Data.Source')}:
                  </th>
                  <td>{viewValues?.lead_source}</td>
                  <th>{t('Common_Data.SOLD_Stage')}:</th>
                  <td>
                    {viewValues?.lead_status_id?.status_name}{' '}
                    {viewValues?.lead_status_id?.parent_leads?.status_name
                      ? '(' + viewValues?.lead_status_id?.parent_leads?.status_name + ')'
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.Sold')} {t('Common_Data.Source')}{' '}
                    {t('LeadListing.Details')}:
                  </th>
                  <td colSpan={3}>
                    {viewValues?.lead_source_details}
                  </td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.DeadLine')} {t('Common_Data.Date')}:
                  </th>
                  <td>
                    {viewValues.deadline_date &&
                      AUTH.getLocalTime(moment(viewValues.deadline_date), AUTH.global_dateformat)}
                  </td>
                  <th>{t('LeadListing.Time_Spent')}:</th>
                  <td>{viewValues.investment_hours && viewValues.investment_hours}</td>
                </tr>
                <tr>
                  <th>{t('LeadListing.SOLD_Value')}:</th>
                  <td>{viewValues?.financevalue}</td>

                  <th>{t('LeadListing.Turn_Over')}:</th>
                  <td>{viewValues?.turnover}</td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.Sold')} {t('LeadListing.Currency')}:
                  </th>
                  <td colSpan='3'>{viewValues?.lead_currency_id?.currency_code}</td>
                </tr>
                <tr>
                  <th>
                    {t('Common_Data.Sold')} {t('Common_Data.Owner')}:
                  </th>
                  <td>
                    {(viewValues &&
                      viewValues.lead_owner_name &&
                      viewValues.lead_owner_name.first_name
                      ? viewValues.lead_owner_name.first_name
                      : '') +
                      ' ' +
                      (viewValues &&
                        viewValues.lead_owner_name &&
                        viewValues.lead_owner_name.last_name
                        ? viewValues.lead_owner_name.last_name
                        : '')}
                  </td>
                  <th>
                    {t('Common_Data.Sold')} {t('Common_Data.Holder')}:
                  </th>
                  <td>
                    {(viewValues &&
                      viewValues.lead_holder_name &&
                      viewValues.lead_holder_name.first_name
                      ? viewValues.lead_holder_name.first_name
                      : '') +
                      ' ' +
                      (viewValues &&
                        viewValues.lead_holder_name &&
                        viewValues.lead_holder_name.last_name
                        ? viewValues.lead_holder_name.last_name
                        : '')}
                  </td>
                </tr>

                <tr>
                  <th>
                    {t('Common_Data.Created')} {t('LeadListing.By')}
                  </th>
                  <td>{viewValues?.created_by_name}</td>
                  <th>
                    {t('Common_Data.Created')} {t('LeadListing.At')}
                  </th>
                  <td>
                    {viewValues && viewValues?.created_date
                      ? viewValues?.created_time
                        ? `${AUTH.getLocalTime(
                          viewValues?.created_date + ' ' + viewValues?.created_time, AUTH.global_fulldate_format
                        )}`
                        : `${AUTH.getLocalTime(moment(viewValues?.created_date))}`
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th>{t('Common_Data.Last_Updated_By')}</th>
                  <td>{viewValues?.updated_by_name}</td>
                  <th>{t('Common_Data.Last_Updated_At')}</th>
                  <td>
                    {viewValues && viewValues?.updated_date
                      ? viewValues?.updated_time
                        ? `${AUTH.getLocalTime(
                          viewValues?.updated_date + ' ' + viewValues?.updated_time, AUTH.global_fulldate_format
                        )}`
                        : `${AUTH.getLocalTime(moment(viewValues?.updated_date))}`
                      : ''}
                  </td>
                </tr>

                {viewValues?.failed_reason &&
                  <>
                    <tr>
                      <th style={{ verticalAlign: "middle" }}>
                        {t('LeadListing.Failed') + " " + t('LeadListing.Reason')}:
                      </th>
                      <td colSpan={1}>
                        <InputComponent
                          name='failed_reason'
                          required
                          // allowClear
                          showArrow
                          showSearch
                          placeholder={t('LeadListing.Failed') + " " + t('LeadListing.Reason')}
                          onChange={handleChange}
                          type='select'
                          options={{
                            values: failedReasonList ? failedReasonList : [],
                            value_key: 'reason',
                            text_key: 'reason',
                          }}
                          rules={[{ required: true, message: `${t('Common_Data.Please')} ${t('LeadListing.Select')} ${t('Common_Data.Reason')}.` }]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t('LeadListing.Failed') + " " + t('LeadListing.Summary')}:</th>
                      <td colSpan={3}>
                        <InputComponent
                          name='failed_summary'
                          placeholder={t('LeadListing.Failed') + " " + t('LeadListing.Summary')}
                          onChange={handleChange}
                          type='textarea'
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t('LeadListing.Failed') + " " + t('LeadListing.Shortfall')}:</th>
                      <td colSpan='3'>
                        <img className='shortfallEdit' alt=''
                          // title={`${t('Common_Data.Edit')} ${t('Common_Data.Lead')}`}
                          src={AUTH.GetThemedImage('Edit')}
                          onClick={(e) => openEditDrawerShortfall(viewValues, e = "Failed")}
                        />
                        <StructureTableContent isViewLead Structure={viewValues?.failed_shortfall?.description} />
                      </td>
                    </tr>
                  </>
                }

                {viewValues?.rejected_reason &&
                  <>
                    <tr>
                      <th>{t('LeadListing.Rejected') + " " + t('LeadListing.Reason')}:</th>
                      <td colSpan={1}>
                        <InputComponent
                          name='rejected_reason'
                          required
                          // allowClear
                          showArrow
                          showSearch
                          placeholder={t('LeadListing.Rejected') + " " + t('LeadListing.Reason')}
                          onChange={handleChange}
                          type='select'
                          options={{
                            values: rejectedReasonList ? rejectedReasonList : [],
                            value_key: 'reason',
                            text_key: 'reason',
                          }}
                          rules={[{ required: true, message: `${t('Common_Data.Please')} ${t('LeadListing.Select')} ${t('Common_Data.Reason')}.` }]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t('LeadListing.Rejected') + " " + t('LeadListing.Summary')}:</th>
                      <td colSpan={3}>
                        <InputComponent
                          name='summary'
                          placeholder={t('LeadListing.Rejected') + " " + t('LeadListing.Summary')}
                          onChange={handleChange}
                          type='textarea'
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t('LeadListing.Rejected') + " " + t('LeadListing.Shortfall')}:</th>
                      <td colSpan='3'>
                        <img className='shortfallEdit' alt=''
                          // title={`${t('Common_Data.Edit')} ${t('Common_Data.Lead')}`}
                          src={AUTH.GetThemedImage('Edit')}
                          onClick={(e) => openEditDrawerShortfall(viewValues, e = "Rejected")}
                        />
                        <StructureTableContent isViewLead Structure={viewValues?.rejected_shortfall?.description} />
                      </td>
                    </tr>
                  </>
                }

                {viewValues?.future_reason &&
                  <>
                    <tr>
                      <th>{t('LeadListing.Future') + " " + t('LeadListing.Reason')}:</th>
                      <td colSpan={1}>
                        <InputComponent
                          name='future_reason'
                          required
                          // allowClear
                          showArrow
                          showSearch
                          placeholder={t('LeadListing.Future') + " " + t('LeadListing.Reason')}
                          onChange={handleChange}
                          type='select'
                          options={{
                            values: futureReasonList ? futureReasonList : [],
                            value_key: 'reason',
                            text_key: 'reason',
                          }}
                          rules={[{ required: true, message: `${t('Common_Data.Please')} ${t('LeadListing.Select')} ${t('Common_Data.Reason')}.` }]}
                        />
                      </td>
                    </tr>
                  </>
                }
              </tbody>
            </table>
          </Form>
        </div>
        {/* <TrackTimeComponent pageName={'leadlisting'} leadId={viewValues.id} /> */}

        <EditComponent saveType="shortfall" visible={editDrawerShortfall} close={closeEditDrawerShortfall} viewFromLeads={viewValues} />
      </Drawer>}

      <TimeTracker
        page={"leadlisting"}
        id={viewValues?.id}
        stop={!props.visible || props.editDrawer}
      />
    </>
  );
});

export default ViewComponent;
