import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Modal, Col, Row } from 'antd';
import useStore from '../../../../store';
import { vsmNotify } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const GlobalStatusComponent = observer((props) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const {t} = useTranslation()
  const {
    SavedReportStore: { globalStatusTempData, editFilter },
    AUTH,
  } = useStore();

  const handleSubmit = (data) => {
    setSaving(true);
    data.global_toggle = props.toggleswichvalue;

    let tempJsonData = JSON.parse(globalStatusTempData?.generated_on_options);
    tempJsonData.global_toggle = props.toggleswichvalue;
    data.generated_on_options = JSON.stringify(tempJsonData);

    editFilter(globalStatusTempData.id, data, AUTH.user.id)
      .then(() => {
        props.close();
        vsmNotify.success({
          message: `${t('Reports.Report_Updated')}!`,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  return globalStatusTempData ? (
    <Modal
      centered
      title={`${t('Reports.Report')} ${t('Common_Data.Update')}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='toggleSavedFilterReportForm'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='toggleSavedFilterReportForm' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={24}>
              <p>
                {!globalStatusTempData.global_toggle
                  ? `${t('Reports.Set_Global_Report')}?`
                  : `${t('Reports.Remove_Global_Report')}?`}
              </p>
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default GlobalStatusComponent;
