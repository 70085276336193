import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Tooltip } from 'antd';

import { vsmTransactionReport } from '../../../../config/messages';
import InputComponent from '../../../../component/InputComponent';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';
import { hideTotalRecordsCountForReport } from '../../../../utils/GlobalFunction';
// import moment from 'moment';

const PreviewDateSubFilterElement = observer(
  ({
    formDataList,
    onChange,
    disabledDate,
    SetDateRange,
    form,
    handlePreviewReport,
    isPreviewFilter = false,
    previewfilterBtnDisable = true,
  }) => {
    const { REPORTSTORE } = useStore();
    const { t } = useTranslation();
    // const todayDate = moment(new Date());

    const reportTitleMaxLimit = 30;
    let activeTitleLength = REPORTSTORE.previewReportValues?.name ? REPORTSTORE.previewReportValues.name.length : 0;

    const reportFilterTitle = REPORTSTORE.previewReportValues
      ? hideTotalRecordsCountForReport.includes(REPORTSTORE.previewReportValues.report_type)
        ? `${activeTitleLength > reportTitleMaxLimit ? REPORTSTORE.previewReportValues.name.substring(0, reportTitleMaxLimit) : REPORTSTORE.previewReportValues.name} `
        : `${activeTitleLength > reportTitleMaxLimit ? REPORTSTORE.previewReportValues.name.substring(0, reportTitleMaxLimit) + '...' : REPORTSTORE.previewReportValues.name}  ${!['time_spent'].includes(REPORTSTORE.previewReportValues.report_type)
          ? ''
          : ''
        }`
      : 'Preview Data';


    return isPreviewFilter ? (
      <>
        <div className='d-flex' style={{ gap: "20px" }}>
          {formDataList.includes('single_date') && (
            <div>
              <InputComponent
                // label='Date'
                label=''
                type='date'
                name='single_date'
                placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Date')}`}
                onChange={onChange}
                rules={vsmTransactionReport.validation.single_date}
                tooltip={`${t('Reports.Fetch_Reports')}.`}
              />
            </div>
          )}
          {formDataList.includes('date_range') && (
            <div className='w250'>
              <InputComponent
                //label='Date Range'
                label=''
                type='date_range'
                name='date_range'
                placeholder={[`${t('DealDesk.Start_Date')}`, `${t('DealDesk.End_Date')}`]}
                onChange={onChange}
                rules={vsmTransactionReport.validation.date_range}
                tooltip={`${t('Reports.Date_Range_Tooltip')}.`}
                disabledDate={disabledDate}
                onCalendarChange={(val) => SetDateRange(val)}
              // defaultValue={[moment(todayDate.subtract(1, "days")), moment()]}
              />
            </div>
          )}

          {(formDataList.includes('period_x_value') ||
            formDataList.includes('period_x_value_type')) && (
              <div className='w400'>
                <Row gutter={25} className="noMargin-InnerRow">
                  {formDataList.includes('period_x_value') && (
                    <Col span={4}>
                      <InputComponent
                        //label='Last'
                        label=''
                        required
                        type='number'
                        name='period_x_value'
                        min={1}
                        max={12}
                        className='w-100'
                        onChange={onChange}
                        rules={vsmTransactionReport.validation.period_x_value}
                        tooltip={`${t('Reports.Date_Selection')}.`}
                      />
                    </Col>
                  )}
                  {formDataList.includes('period_x_value_type') && (
                    <Col span={formDataList.includes('period_x_value') ? 20 : 24}>
                      <InputComponent
                        //label='&nbsp;'
                        label={''}
                        onChange={onChange}
                        type='radio_button'
                        name='period_x_value_type'
                        options={{
                          className: 'd-flex w-100',
                          values: [
                            { value: 'day', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Days') : t('Reports.Day')}` },
                            { value: 'week', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Weeks') : t('Common_Data.Week')}` },
                            { value: 'month', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Months') : t('Common_Data.Month')}` },
                            { value: 'quarter', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Quarters') : t('Common_Data.Quarter')}` },
                            { value: 'year', text: `${formDataList.includes('period_x_value') ? t('Common_Data.Years') : t('Reports.Year')}` },
                          ],
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            )}

          {formDataList.includes('financial_year') && (
            <div className='w200 mediumSelect'>
              <InputComponent
                noStyle
                //label='Financial Year'
                label=''
                required
                type='select'
                name='financial_year'
                placeholder={`${t('Reports.Fiscal')}`}
                onChange={onChange}
                options={{
                  values: REPORTSTORE.financial_year_list,
                }}
                tooltip={`${t('Reports.Fiscal_Tooltip')}.`}
                rules={vsmTransactionReport.validation.fiscal}
              />
            </div>
          )}

          {handlePreviewReport && isPreviewFilter && (
            <div>
              {/* <Button
              size='medium'
              shape='round'
              onClick={() => {
                handlePreviewReport();
              }}
              type='primary'
              disabled={previewfilterBtnDisable}
            >
              {t('Common_Data.Update')} {t('Common_Data.Result')}
            </Button> */}

              <Tooltip title={REPORTSTORE.previewReportValues?.name}>
                <span className='reportFilterTitle text-center'>{reportFilterTitle}</span>
              </Tooltip>

            </div>
          )}
        </div>
      </>
    ) : (
      <>
        <div className='d-flex' style={{ gap: "20px" }}>
          {formDataList.includes('single_date') && (
            <div>
              <InputComponent
                label='Date'
                type='date'
                name='single_date'
                onChange={onChange}
                rules={vsmTransactionReport.validation.single_date}
                tooltip={`${t('Reports.Fetch_Reports')}.`}
              />
            </div>
          )}
          {formDataList.includes('date_range') && (
            <div className='w250'>
              <InputComponent
                label='Date Range'
                type='date_range'
                placeholder={[`${t('DealDesk.Start_Date')}`, `${t('DealDesk.End_Date')}`]}
                name='date_range'
                onChange={onChange}
                rules={vsmTransactionReport.validation.date_range}
                tooltip={`${t('Reports.Date_Range_Tooltip')}.`}
                disabledDate={disabledDate}
                onCalendarChange={(val) => SetDateRange(val)}
              // defaultValue={[moment(todayDate.subtract(1, "days")), moment()]}
              />
            </div>
          )}

          {(formDataList.includes('period_x_value') ||
            formDataList.includes('period_x_value_type')) && (
              <div>
                <Row gutter={10}>
                  {formDataList.includes('period_x_value') && (
                    <Col span={4}>
                      <InputComponent
                        label='Last'
                        required
                        type='number'
                        name='period_x_value'
                        min={1}
                        max={12}
                        className='w-100'
                        onChange={onChange}
                        rules={vsmTransactionReport.validation.period_x_value}
                        tooltip={`${t('Reports.Date_Selection')}.`}
                      />
                    </Col>
                  )}
                  {formDataList.includes('period_x_value_type') && (
                    <Col span={20}>
                      <InputComponent
                        label='&nbsp;'
                        onChange={onChange}
                        type='radio_button'
                        name='period_x_value_type'
                        options={{
                          className: 'd-flex w-100',
                          values: [
                            { value: 'day', text: 'Days' },
                            { value: 'week', text: 'Weeks' },
                            { value: 'month', text: 'Months' },
                            { value: 'quarter', text: 'Quarters' },
                            { value: 'year', text: 'Years' },
                          ],
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            )}

          {formDataList.includes('financial_year') && (
            <div className='w400'>
              <InputComponent
                label={`${t('Reports.Financial')} ${t('Reports.Year')}`}
                required
                type='select'
                name='financial_year'
                placeholder={`${t('Reports.Financial')} ${t('Reports.Year')}`}
                onChange={onChange}
                options={{
                  values: REPORTSTORE.financial_year_list,
                }}
                tooltip={`${t('Reports.Financial_Year_Tooltip')}.`}
                rules={vsmTransactionReport.validation.financial_year}
              />
            </div>
          )}
        </div>
      </>
    );
  }
);
export default PreviewDateSubFilterElement;
