import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import ListComponent from '../../AuditLogs/component/ListComponent';
import ViewComponent from '../../AuditLogs/component/ViewComponent';
//import Unauthorized from '../../commonpage/Unauthorized';
import useStore from '../../../store';
import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';

const AuditLogElements = observer((props) => {
  const {t} = useTranslation();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [id, setId] = useState(false);
  const { AUTH } = useStore();
  const close = () => {
    props.close();
  };

  const setAuditId = (data) => setId(data);
  // View audit log
  const openViewDrawer = (data) => {
    setAuditId(data);
    setViewDrawer(true);
  };
  const closeViewDrawer = () => setViewDrawer(false);
  return !AUTH.checkPermission(31, 'list') ? null : (
    <Modal
      centered
      width={1200}
      title={`${t('LeadListing.Audit_Log')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      destroyOnClose={true}
      //onOk={close}
      footer={false}
    >
      <ListComponent
        AuditLogModal={props.visible}
        openViewDrawer={openViewDrawer}
        auditLeadID={props.auditLeadID}
      />
      <ViewComponent
        id={id}
        setId={setAuditId}
        visible={viewDrawer}
        close={closeViewDrawer}
      />
      {props.visible && (
        <TrackTimeComponent pageName={'leadlisting'} leadId={props.auditLeadID} />
      )}
    </Modal>
  );
});

export default AuditLogElements;
