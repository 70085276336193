import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PageHeader, Button, Space, Form } from 'antd';
import { useLocation } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';

//import TopSelection from './component/TopSelection';
import ListComponent from './component/ListComponent';
import EditComponent from './component/EditComponentNew';
import DeleteComponent from './component/DeleteComponent';
import CloneComponent from './component/CloneComponentNew';
import ViewComponent from './component/ViewComponent';
import LeadImportComponent from './component/LeadImportComponent';
// import OpportunityImportComponent from './component/OpportunityImportComponent';
import RecordPerPage from '../../config/RecordPerPage';
import useStore from '../../store';
import EnterFullscreen from '../../component/EnterFullscreen';
import Unauthorized from '../Unauthorized';
import TrackTimeComponent from '../../component/TrackTimeComponent';
import { filterFiltersByPageType } from '../../utils/GlobalFunction';
import ResetGridOptions from '../../component/ResetGridOptions';
import ApplyGridFilterNew from '../../component/ApplyGridFilterNew';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../component/TimeTracker';

const LeadListing = observer((props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();
  const { SearchPage } = props;

  const {
    LEADSSTORE,
    AUTH,
    SETTINGS,
    AUTH: { allPageFilters, allPageFiltersLoaded },
  } = useStore();

  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  const [pageTitle, setPageTitle] = useState(`${t('LeadListing.Leads')}`);
  const [kanbanRoute, setKanbanRoute] = useState(null);
  const [importLeadModal, setImportLeadModal] = useState(false);
  // const [importOpportunitiesModal, setImportOpportunitiesModal] = useState(false);

  const [editOpen, setEditOpen] = useState(false);

  const [form] = Form.useForm();
  //Edit drawer
  const openEditDrawer = (data, isEditOpen = false) => {
    setEditOpen(isEditOpen);
    LEADSSTORE.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
    setEditOpen(false);
  };

  //open delet drawer
  const openDeleteDrawer = (data) => {
    LEADSSTORE.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  //Clone drawer
  const openCloneDrawer = (data) => {
    LEADSSTORE.setCloneValues(data);
    setCloneDrawer(true);
  };
  const closeCloneDrawer = () => setCloneDrawer(false);

  //View drawer
  const openViewDrawer = (data) => {
    if (AUTH.checkPermission(10, 'view-details')) {
      LEADSSTORE.setViewValues(data);
      setViewDrawer(true);
    }
  };
  const closeViewDrawer = () => setViewDrawer(false);
  const openKanbanView = () => history.push(kanbanRoute);

  //Open Drawer to import data
  const importLeadData = () => setImportLeadModal(true);
  const closeImportLeadModal = () => {
    setImportLeadModal(false);
  };
  // const importOpportunitiesData = () => setImportOpportunitiesModal(true);
  // const closeImportOpportunitiesModal = () => {
  //   setImportOpportunitiesModal(false);
  // };

  useEffect(() => {
    if (location.pathname !== LEADSSTORE.current_list_path) {
      if (location.pathname.includes('search/UnusedLeads')) {
        LEADSSTORE.current_list_path = '/leads/unused';
      } else {
        LEADSSTORE.current_list_path = location.pathname;
      }
    }
    if (location.pathname === '/leads/deals') {
      setPageTitle(`${t('LeadListing.Deals')}`);
    }
    if (location.pathname === '/leads/unused') {
      setPageTitle(`${t('LeadListing.Unused')}`);
    }
    if (location.pathname === '/leads/opportunities') {
      setPageTitle(`${t('LeadListing.Opportunities')}`);
    }

    setKanbanRoute(location.pathname + '/kanban');
  }, [
    LEADSSTORE,
    LEADSSTORE.current_list_path,
    location,
    allPageFiltersLoaded,
    allPageFilters,
    t,
  ]);

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(10, 'edit')) {
            if (result.key === 'Enter') {
              openEditDrawer(e.data);
            }
          }
          if (AUTH.checkPermission(10, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteDrawer(e.data);
            }
          }
        }
      }
    }
  };

  const pageFilterType = 'unusedleads';

  return (
    <>
      {!AUTH.checkPermission(10, 'list') ? (
        <Unauthorized />
      ) : SearchPage ? (
        <>
          <ListComponent
            openViewDrawer={openViewDrawer}
            openEditDrawer={openEditDrawer}
            openDeleteDrawer={openDeleteDrawer}
            openCloneDrawer={openCloneDrawer}
            SearchPage={SearchPage}
          />
        </>
      ) : (
        <PageHeader
          title={pageTitle}
          className={'page-title ' + SETTINGS.fullscreen_class}
          extra={[
            <Form form={form} className='innerForm headerButton'>
              <Space size={5}>
                <ApplyGridFilterNew
                  type={pageFilterType}
                  selectionValue={LEADSSTORE.selectedGridFilter}
                  filterList={filterFiltersByPageType(allPageFilters, pageFilterType)}
                />
                {AUTH.checkPermission(10, 'change-view') && (
                  <Button
                    className='gridbtn'
                    key='1'
                    shape='round'
                    size='medium'
                    onClick={openKanbanView}
                    icon={
                      <img
                        className='menuicon'
                        alt=''
                        src={AUTH.GetThemedImage('Kanban_View')}
                      />
                    }
                  >
                    {t('LeadListing.Kanban_View')}
                  </Button>
                )}

                {AUTH.checkPermission(10, 'import') && (
                  <Button
                    className='gridbtn'
                    key='3'
                    shape='round'
                    size='medium'
                    onClick={importLeadData}
                  >
                    {t('LeadListing.Import')}
                  </Button>
                )}

                {AUTH.checkPermission(10, 'export') && (
                  <Button
                    className='gridbtn'
                    key='4'
                    shape='round'
                    size='medium'
                    loading={LEADSSTORE.exportLoadingIcn}
                    onClick={() => LEADSSTORE.exportLeadData('unused')}
                  >
                    {t('LeadListing.Export')}
                  </Button>
                )}

                <ResetGridOptions key={'5'} pageType={pageFilterType} />
                <EnterFullscreen key='6' />
                <RecordPerPage
                  key='7'
                  initialValue={LEADSSTORE.per_page + ' per page'}
                  onChange={LEADSSTORE.setPageSize}
                />
              </Space >
            </Form >,
          ]}
        >
          <ListComponent
            openViewDrawer={openViewDrawer}
            openEditDrawer={openEditDrawer}
            openDeleteDrawer={openDeleteDrawer}
            openCloneDrawer={openCloneDrawer}
            SearchPage={SearchPage}
            onCellKeyDown={onCellKeyDown}
          />
          <TrackTimeComponent pageName={'leadlisting'} />
        </PageHeader >
      )}
      <ViewComponent
        visible={viewDrawer}
        openEditDrawer={openEditDrawer}
        close={closeViewDrawer}
      />
      <EditComponent visible={editDrawer} editOpen={editOpen} close={closeEditDrawer} />
      <DeleteComponent
        visible={deleteDrawer}
        close={closeDeleteDrawer}
        lineText={`${t('Common_Data.Remove_Lead')}?`}
      />
      <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />
      <LeadImportComponent visible={importLeadModal} close={closeImportLeadModal} />
      {/* <OpportunityImportComponent
        visible={importOpportunitiesModal}
        close={closeImportOpportunitiesModal}
      /> */}
      <TimeTracker
        page={"leadlisting"}
        stop={viewDrawer || editDrawer || cloneDrawer}
      />
    </>
  );
});

export default LeadListing;
