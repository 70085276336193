import { Modal, Radio } from "antd";
import React from "react";
import { vsmCallAudit, vsmNotify } from "../../../../config/messages";

const CallAuditModal = ({
  selectedValue,
  setIsModalOpen,
  setSelectedValue,
  isModalOpen,
  formData,
  LEADSSTORE,
  lastCalledOn,
}) => {
  const handleOk = () => {
    console.log("formData", formData);
    LEADSSTORE.callAudit(formData).then(() => {
      vsmNotify.success({
        message: vsmCallAudit.added,
      });
    });
    if (formData?.phone) {
      // Open phone dialer with the selected phone number
      window.location.href = `tel:${formData.phone}`;
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title="Call Type"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Radio.Group
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        name="radiogroup"
        defaultValue={lastCalledOn}
        value={selectedValue} // Bind state to Radio.Group
        onChange={(e) => setSelectedValue(e.target.value)}
        options={[
          { value: "work", label: "Work" },
          { value: "outbound", label: "Outbound" },
          { value: "email", label: "Email" },
          { value: "inbound", label: "Inbound" },
        ]}
      />
    </Modal>
  );
};

export default CallAuditModal;
