import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { Card, Dropdown, Tooltip } from 'antd';
import useStore from '../../../store';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import InputComponent from '../../../component/InputComponent';
import { useTranslation } from 'react-i18next';
import { vsmCallAudit, vsmNotify } from '../../../config/messages';
const DragHandle = SortableHandle(() => {
  return <MenuOutlined />;
});

const CardItem = SortableElement(
  ({ id, details, removeItem, disabled, propsData, showRoleDropdown }) => {
    const { AUTH, LEADSSTORE, ORGANISATION } = useStore();
    const { t } = useTranslation();
    const commentContent = () => {
      return (
        <div className='ant-popover-inner-content'>
          <ul className='popover-menu' style={{ width: '100%' }}>
            {AUTH?.user &&
              AUTH?.user?.connection_role.map((u, index) => {
                return (
                  <li
                    key={index}
                    className={`cursorPointer ${details?.connection_role_name &&
                      details?.connection_role_name?.includes(u?.role)
                      ? 'active'
                      : ''
                      }`}
                    onClick={(e) => {
                      propsData?.handleConnnectionChange(u?.role, id, {
                        isPrimary: propsData?.isPrimaryConnection,
                        type: details.type,
                      });
                    }}
                  >
                    {u?.role}
                  </li>
                );
              })}
          </ul>
        </div>
      );
    };

    const selectedRolesInput = () => {
      return (
        <>
          {details?.connection_role_name?.length ? (
            details?.connection_role_name?.map((items, index) => {
              return (
                <span key={index}>
                  {items}
                  {index !== details?.connection_role_name?.length - 1 ? ',' : ''}
                </span>
              );
            })
          ) : (
            <span key={1}>{t('LeadListing.No_Roles_Selected')}</span>
          )}
        </>
      );
    };

    const handleEmailCallAudit = (data) => {
      let formdata = {
        id: id,
        email: data,
      };
      LEADSSTORE.callEmailAudit(formdata).then(() => {
        vsmNotify.success({
          message: vsmCallAudit.emailadded,
        });
      });
    };

    const handleCallAudit = (data) => {
      let formdata = {
        id: id,
        phone: data,
      };
      ORGANISATION.callAudit(formdata).then(() => {
        vsmNotify.success({
          message: vsmCallAudit.added,
        });
      });
    };

    return (
      <li className='connectionLisitngLI' id={`connection_div_${id}`}>
        <Card
          title={
            <>
              <div className='headContent'>
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '83%',
                  }}
                >
                  {propsData?.isPrimaryConnection ? null : (
                    <span className='dragger'>
                      <DragHandle />
                    </span>
                  )}

                  <img
                    alt={details.type === 1 ? `${t('Common_Data.Organisation')}` : `${t('LeadListing.People')}`}
                    title={details.type === 1 ? `${t('Common_Data.Organisation')}` : `${t('LeadListing.People')}`}
                    className={'actionIcons'}
                    src={
                      details.type === 1
                        ? AUTH.GetThemedImage('Connections_icn')
                        : AUTH.GetThemedImage('Users')
                    }
                  />
                  {` `}
                  <span title={details.name}>{details.name}</span>
                </div>

                <div>
                  <img
                    style={{ cursor: 'pointer' }}
                    title={`${t('Common_Data.View')}`}
                    className='actionIcons mr-10'
                    alt={`${t('Common_Data.View')}`}
                    src={AUTH.GetThemedImage('View')}
                    onClick={() => {
                      if (details.type === 1) {
                        propsData.openViewOrgDrawer(details);
                      } else {
                        propsData.openPeopleViewDrawer(details);
                      }
                    }}
                  />
                  {propsData?.isPrimaryConnection ? null : (
                    <img
                      style={{ cursor: 'pointer' }}
                      title={`${t('Common_Data.Delete')}`}
                      className='actionIcons'
                      alt={`${t('Common_Data.Delete')}`}
                      src={AUTH.GetThemedImage('Waste')}
                      onClick={() => {
                        removeItem(details);
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          }
          bordered={true}
        // style={{ width: 300 }}
        >
          <div className='bodyContent'>
            <ul>
              <li>
                <img
                  src={AUTH.GetThemedImage('Mail')}
                  alt=''
                  className='mr-5'
                  style={{ width: '16px' }}
                />
                <span className='textData' title={details?.email}>
                  {(details?.email && details?.email === "N/A" && details?.email === "-") ?
                    <a
                      href={`mailto:${details?.email && details.email}`}
                      onClick={(e) => {
                        handleEmailCallAudit(details.email);
                      }}
                    >
                      {details?.email}
                    </a>
                    : `${t('Common_Data.N_A')}`}
                </span>
              </li>
              <li>
                <img
                  src={AUTH.GetThemedImage('Phone')}
                  alt=''
                  className='mr-5'
                  style={{ width: '16px' }}
                />
                <span className='textData' title={details?.phone}>
                  {details?.phone ?
                    <a
                      href={`tel:${details.phone}`}
                      onClick={(e) => {
                        handleCallAudit(details.phone);
                      }}
                    >
                      {details.phone}
                    </a>
                    : `${t('Common_Data.N_A')}`}
                </span>
              </li>
              <li>
                {showRoleDropdown ? (
                  <>
                    <div className='suffixIcon connectionRoleDropdown'>
                      <Tooltip
                        // placement='leftTop'
                        title={selectedRolesInput()}
                        trigger='hover'
                      >
                        <Dropdown
                          overlayClassName='timeMenu'
                          overlay={commentContent()}
                          trigger='click'
                          placement='bottomRight'
                          arrow
                        >
                          <span className='box'>
                            <InputComponent
                              name='connections'
                              placeholder={selectedRolesInput()}
                              required
                              type='select'
                              mode='multiple'
                              allowClear
                              options={{
                                values: AUTH?.user && AUTH?.user?.connection_role,
                                value_key: 'connection_role_name',
                                text_key: 'connection_role_name',
                              }}
                              disabled={true}
                            />
                            <DownOutlined />
                          </span>
                        </Dropdown>
                      </Tooltip>
                    </div>
                  </>
                ) : null}
              </li>
            </ul>
          </div>
        </Card>
      </li>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, removeItem, propsData, showRoleDropdown }) => {
    return (
      <ul className='connectionLisitng'>
        {items?.map((item, index) => (
          <CardItem
            key={`item-${index}`}
            index={index}
            id={item.id}
            showRoleDropdown={showRoleDropdown}
            details={item}
            removeItem={removeItem}
            propsData={propsData}
            disabled={items?.role_name === 'Primary' ? true : false}
          />
        ))}
      </ul>
    );
  }
);

const ConnectionCard = (props) => {
  const { showRoleDropdown = false } = props;
  const onSortEnd = ({ oldIndex, newIndex }) => {
    props.setItems(arrayMoveImmutable(props.items, oldIndex, newIndex));
    if (props.setDisabled) {
      props.setDisabled(false);
    }
  };
  return (
    <>
      <SortableList
        axis='xy'
        items={props.items}
        onSortEnd={onSortEnd}
        showRoleDropdown={showRoleDropdown}
        useDragHandle={true}
        removeItem={props.removeItem}
        propsData={props}
      />
    </>
  );
};
export default ConnectionCard;
