import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Spin } from 'antd';
import { vsmUsergroup } from '../../../config/messages';
import InputComponent from '../../../component/InputComponent';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(({ handleSubmit, onChange, form, id }) => {
  const { t } = useTranslation()
  const [fetchUserList, setfetchUserList] = useState(true);

  const {
    USERGROUP: { dropdown_user_list, getUsersList, editValues },
  } = useStore();

  const onFinish = (data) => {
    handleSubmit(data);
  };

  return (
    <Form
      form={form}
      id={id}
      labelCol={{ span: 24 }}
      className='innerForm'
      onFinish={onFinish}
    >
      <Row gutter={15}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <InputComponent
            name='group_name'
            label={`${t('Knowledge.Group_Name')}:`}
            required
            placeholder={`${t('Knowledge.Group_Name')}`}
            type='input'
            tooltip={`${t('Common_Data.User_Group_Tooltip')}.`}
            onChange={onChange}
            autoComplete='off'
            rules={vsmUsergroup.validation.group_name}
          />
        </Col>
      </Row>
      <Row gutter={15}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <InputComponent
            name='manager_id'
            label={`${t('Common_Data.Group')} ${t('UserGroups.Manager')}`}
            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Group')} ${t('UserGroups.Manager')}`}
            type='select'
            tooltip={`${t('UserGroups.Group_Manager_Tooltip')}.`}
            //required
            allowClear
            //onClear={onChange}
            onChange={onChange}
            options={{
              values:
                dropdown_user_list ||
                (editValues?.manager && [
                  {
                    id: editValues.manager.id,
                    name: editValues?.manager?.full_name,
                  },
                ]),
              value_key: 'id',
              text_key: 'name',
            }}
            onFocus={() =>
              fetchUserList && getUsersList().then(() => setfetchUserList(false))
            }
            notFoundContent={fetchUserList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
          />
        </Col>
      </Row>
    </Form>
  );
});

export default FormComponent;
