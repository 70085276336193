import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const HourglassConfirmComponent = observer((props) => {
  const {t} = useTranslation()
  const [saving, setSaving] = useState(false);
  const { resetTime } = props;
  const [form] = Form.useForm();
  const { AUTH, PROMOTIONSTORE } = useStore();

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    var data = {
      reminder_type: 'leads',
      reminder_for: props?.hourglassConfirm?.id,
      minutes: resetTime,
      hourglass: true,
      seperate_hourglass: 0,
    };
    setSaving(true);
    close();
    AUTH.ResetData(data)
      .then(() => {
        close();
        PROMOTIONSTORE.setupGrid(PROMOTIONSTORE.agGrid);

        form.resetFields();
        vsmNotify.success({
          message: `${t('Common_Data.Reminder_Updated')}!`,
        });
      })
      .catch((e) => {})
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Reset')} ${t('Common_Data.Lead')} ${t('Common_Data.Reminder')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='hourglassForm'
          htmlType='submit'
          type='primary'
          shape='round'
          loading={saving}
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='hourglassForm' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('Common_Data.Reset_Reminder_Lead')}{' '}
              <strong>#{props?.hourglassConfirm?.id}</strong> {t('Common_Data.With')}{' '}
              <strong>{props?.resetTime}</strong> {t('Common_Data.Minutes')}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  );
});

export default HourglassConfirmComponent;
