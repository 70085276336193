import React from 'react';
import { Card } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const PplDetailComponent = observer((props) => {
  const {t} = useTranslation()
  return (
    <Card size='small' className='mb-20'>
      <h3>
        <b>
          {props.data.first_name +
            ' ' +
            (props.data.last_name ? props.data.last_name : '')}
        </b>
      </h3>
      <div className='ant-table-content ant-table-small ant-table viewPeople'>
        <table>
          <tbody className='ant-table-tbody'>
            <tr>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
            </tr>
            <tr>
              <th>{t('Common_Data.Title')}:</th>
              <td>{props.data.title && props.data.title}</td>
              <th>{t('PeopleManagement.PPS')} {t('Common_Data.Number')}:</th>
              <td>{props.data.pps_number && props.data.pps_number}</td>
            </tr>
            <tr>
              <th>{t('Common_Data.Phone')}:</th>
              <td>
                {props.data.phone &&
                  props.data.phone.map((x) => x.contact_value).join(', ')}
              </td>
              <th>{t('Common_Data.Email')}:</th>
              <td>
                {props.data.email &&
                  props.data.email.map((x) => x.contact_value).join(', ')}
              </td>
            </tr>
            <tr>
              <th>{t('Common_Data.Address')}:</th>
              <td colSpan='3'>
                {props.data.single_address && (
                  <div>
                    {props.data.single_address.address_1}
                    {props.data.single_address.address_2 &&
                      ', ' + props.data.single_address.address_2}
                    {props.data.single_address.address_3 &&
                      ', ' + props.data.single_address.address_3}
                    {props.data.single_address.city &&
                      ', ' + props.data.single_address.city}
                    {props.data.single_address.state_county &&
                      ', ' + props.data.single_address.state_county}
                    {props.data.single_address.countries &&
                      ', ' + props.data.single_address.countries.country_name}
                    {props.data.single_address.postal_code &&
                      ', ' + props.data.single_address.postal_code}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
  );
});

export default PplDetailComponent;
