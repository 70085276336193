import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';

import { AllModules } from '@ag-grid-enterprise/all-modules';

import LocalGridConfig from '../../../../config/LocalGridConfig';

import useStore from '../../../../store';
import { vsmCommon } from '../../../../config/messages';
import { PageHeader } from 'antd';
import { DateComparator, secondsToHmsWithAppend } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const UserInvestmentTicket = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    SavedReportStore,
    SavedReportStore: { investmentHoursDetailDataByuser },
  } = useStore();

  const gridOptions = {
    columnDefs: [
      {
        headerName: 'SR#',
        headerTooltip: 'SR#',
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',
        cellClass: 'cellClass',
        pinned: 'left',
        cellStyle: { 'text-align': 'center' },
        filter: false,
        sortable: false,
        width: 85,
        minWidth: 85,
      },
      {
        headerName: `${t('Reports.Ticket')} ${t('Common_Data.Number')}`,
        field: 'ticket_number',
        cellRenderer: 'agGroupCellRenderer',
        width: 150,
        minWidth: 150,
      },
      {
        headerName: `${t('Common_Data.Subject')}`,
        field: 'subject',
        width: 620,
        minWidth: 620,
      },
      {
        headerName: `${t('Common_Data.Date')}`,
        field: 'datetime',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.datetime
            ? AUTH.getLocalTimeDealDesk(params.data.datetime, AUTH.global_dateformat)
            : '';
        },
        width: 100,
        minWidth: 100,
      },
      {
        headerName: `${t('Common_Data.Time_Spent')}`,
        field: 'time_spent',
        width: 100,
        minWidth: 100,
      },
    ],
  };

  const detailGridOptions = {
    detailGridOptions: {
      columnDefs: [
        {
          headerName: 'SR#',
          headerTooltip: 'SR#',
          cellRenderer: (params) => {
            return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
          },
          field: 'sr',
          cellClass: 'cellClass',
          pinned: 'left',
          cellStyle: { 'text-align': 'center' },
          filter: false,
          sortable: false,
          width: 80,
          minWidth: 80,
        },
        {
          headerName: `${t('Reports.Ticket')} ${t('Common_Data.Number')}`,
          field: 'ticket_number',
          cellRenderer: 'agGroupCellRenderer',
          width: 150,
          minWidth: 150,
        },
        {
          headerName: `${t('Common_Data.Subject')}`,
          field: 'subject',
          width: 550,
          minWidth: 550,
        },
        {
          headerName: `${t('Common_Data.Date')}`,
          field: 'datetime',
          filter: 'agDateColumnFilter',
          filterParams: {
            buttons: ['reset'],
            inRangeInclusive: true,
            comparator: DateComparator,
          },
          cellRenderer: (params) => {
            return params.data.datetime
              ? AUTH.getLocalTimeDealDesk(params.data.datetime, AUTH.global_dateformat)
              : '';
          },
          width: 170,
          minWidth: 170,
        },
        {
          headerName: `${t('Common_Data.Time_Spent')}`,
          field: 'time_spent',
          width: 150,
          minWidth: 150,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
    },
    getDetailRowData: function (params) {
      params.successCallback(params.data.child_tickets);
    },
  };

  let PinnedData = [];
  let soldObj = {
    sr: 'Total',
    id: null,
    sold_name: null,
    datetime: null,
    time_spent: investmentHoursDetailDataByuser
      ? secondsToHmsWithAppend(investmentHoursDetailDataByuser?.by_id?.tickets_total)
      : 0,
  };
  PinnedData.push(soldObj);

  const onCellDoubleClickRoute = (cellName, cellData) => {
    if (['ticket_number'].includes(cellData?.column?.colId) && cellData?.value) {
      window.open(`/ticket/${cellData?.value}`, '_blank');
    }
  };

  return (
    <PageHeader
      title={`${t('Reports.Ticket')} ${t('Reports.Breakdown')} (${investmentHoursDetailDataByuser?.by_id?.tickets &&
        Array.isArray(investmentHoursDetailDataByuser?.by_id?.tickets)
        ? investmentHoursDetailDataByuser?.by_id?.tickets.length.toLocaleString('en-GB')
        : 0
        } records)`}
    >
      <div
        className='ag-theme-alpine grid_wrapper reportPreviewData'
        style={{
          height: `calc(100vh - 15rem)`,
          minHeight: '200px',
        }}
      >
        <AgGridReact
          rowData={
            investmentHoursDetailDataByuser &&
              investmentHoursDetailDataByuser?.by_id?.tickets
              ? investmentHoursDetailDataByuser?.by_id?.tickets
              : []
          }
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: false,
            flex: 1,
          }}
          pinnedBottomRowData={PinnedData}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{}}
          onGridReady={SavedReportStore.setupInvestmentGroup}
          gridOptions={LocalGridConfig.options}
          masterDetail={true}
          suppressRowClickSelection={true}
          detailCellRendererParams={detailGridOptions}
          rowSelection={'multiple'}
          isRowMaster={(dataItem) => {
            return dataItem?.child_tickets ? true : false;
          }}
          onSortChanged={SavedReportStore.drilldownRedrawGrid}
          onCellDoubleClicked={(params) =>
            onCellDoubleClickRoute(params?.colDef?.headerName, params)
          }
        />
      </div>
    </PageHeader>
  );
});

export default UserInvestmentTicket;
