import React from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../component/InputComponent';
import { Form, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
//import useStore from '../../../store';

const ShortcutElement = observer(({ onChange, form, shortcutKeys }) => {
  //const { AUTH } = useStore();
  const { t } = useTranslation()
  return (
    <div className='w-100'>
      <Form.List name='shortcut_keys' initialValue={[null]}>
        {(fields, { add, remove }) => {
          return fields.map((field, index) => {
            return (
              <div className='w-100' key={field.key}>
                <Row gutter={15}>
                  <Col span={11}>
                    <InputComponent
                      name={[field.name, 'key']}
                      key={[field.key, 'key']}
                      placeholder={`${t('Common_Data.Select')} ${t('Settings.Key')}`}
                      type='select'
                      style={{ width: '100%' }}
                      onChange={async () => {
                        await shortcutKeys.set(
                          form.getFieldValue('bonus_settings')['shortcut_keys']
                        );
                      }}
                      options={{
                        values: [
                          { value: 'Enter', text: `${t('Common_Data.Enter')}` },
                          { value: 'Delete', text: `${t('Common_Data.Delete')}` },
                        ],
                        text_key: 'text',
                        value_key: 'value',
                        rejected_keys:
                          shortcutKeys.get &&
                          shortcutKeys.get.map(
                            (x) =>
                              x &&
                              ((shortcutKeys.get[field.name] &&
                                x.key !== shortcutKeys.get[field.name].key) ||
                                !shortcutKeys.get[field.name]) &&
                              x.key
                          ),
                      }}
                      autoComplete='off'
                    />
                  </Col>

                  <Col span={11}>
                    <InputComponent
                      name={[field.name, 'action']}
                      key={[field.key, 'action']}
                      placeholder={`${t('Settings.Action')}`}
                      type='select'
                      style={{ width: '100%' }}
                      onChange={async () => {
                        await shortcutKeys.set(
                          form.getFieldValue('bonus_settings')['shortcut_keys']
                        );
                      }}
                      options={{
                        values: [
                          { value: 'Edit', text: `${t('Common_Data.Edit')}` },
                          { value: 'Delete', text: `${t('Common_Data.Delete')}` },
                        ],
                        rejected_keys:
                          shortcutKeys.get &&
                          shortcutKeys.get.map(
                            (x) =>
                              x &&
                              ((shortcutKeys.get[field.name] &&
                                x.key !== shortcutKeys.get[field.name].key) ||
                                !shortcutKeys.get[field.name]) &&
                              x.action
                          ),
                      }}
                    />
                  </Col>
                </Row>
              </div>
            );
          });
        }}
      </Form.List>
    </div>
  );
});

export default ShortcutElement;
