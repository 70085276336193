import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Input, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { vsmAlgorithm, vsmNotify } from '../../../../config/messages';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const {
    AlgorithmsStore: { DeleteData, deleteValues },
  } = useStore();
  const [saving, setSaving] = useState();

  // Make function call to delete exixting record
  const handleSubmit = (data) => {
    setSaving(true);
    DeleteData(data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmAlgorithm.delete,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // set the form values to delete
  useEffect(() => {
    if (deleteValues) {
      form.setFieldsValue({
        id: deleteValues.id,
      });
    }
  }, [deleteValues, form]);

  const close = () => {
    form.resetFields();
    props.close();
  };

  return deleteValues ? (
    <Modal
      title={`${t('DealBuilder.Delete_Deal')} - #${deleteValues?.id}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      centered
      footer={[
        <Button
          key='1'
          form='deleteformDEALBuilder'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
          shape='round'
          size='medium'
        >
         {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' onClick={close} shape='round' size='medium'>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteformDEALBuilder' onFinish={handleSubmit}>
        <Form.Item name='id' style={{ display: 'none' }}>
          <Input type='hidden' />
        </Form.Item>
        <Row align='middle'>
          <Col span={4} className='DeleteIcon'>
            <WarningFilled />
          </Col>
          <Col span={20} className='DeleteText'>
            {t('DealBuilder.Remove_Calculation')}?
          </Col>
        </Row>
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
