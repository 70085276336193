import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmNotify, vsmUsers } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const [form] = Form.useForm();
  const { USERS } = useStore();
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const {t} = useTranslation()
  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
    setDisabled(true);
  };

  // Make delete button enable / disable
  const handleFieldChange = () => {
    if (form.getFieldsValue().confirm.toLowerCase() === 'confirm') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    vsmNotify.success({
      message: vsmUsers.add
    });
  };

  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    // USERGROUP.DeleteData(USERGROUP.deleteValues.id).then(() => {
    // 	close()
    // 	vsmNotify.success({
    // 		message: vsmUsergroup.delete
    // 	})
    // }).catch(e => {
    // 	if (e.errors) { form.setFields(e.errors) }
    // }).finally(() => {
    // 	setSaving(false)
    // })
  };

  return USERS.deleteValues ? (
    <Modal
      centered
      title={`${t('Common_Data.Delete')} ${t('Common_Data.User')} - #${USERS.deleteValues?.id}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deleteformUsers'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
          disabled={disabled}
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>
      ]}
    >
      <Form
        form={form}
        id='deleteformUsers'
        onFinish={handleSubmit}
        onChange={handleFieldChange}
      >
        {
          <Row align='middle'>
            <Col span={4}>
              <WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} />
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
