import React, { useState } from 'react';
import { Button, Popover, Space, Spin, Tooltip } from 'antd';
import DateTimeConfirmElements from '../../LeadListing/elements/DateTimeConfirmElements';
import HourglassConfirmElements from '../../LeadListing/elements/HourglassConfirmElements';
import ReassignConfirmElements from '../../LeadListing/elements/ReassignConfirmElements';
// import StatusConfirmElements from '../../LeadListing/elements/StatusConfirmElements';
import GoToDealDeskConfirmElements from '../../LeadListing/elements/GoToDealDeskConfirmElements';
import EditComponent from '../../LeadListing/component/EditComponentNew';
import DeleteComponent from '../../LeadListing/component/DeleteComponent';
import CloneComponent from '../../LeadListing/component/CloneComponentNew';
import ViewComponent from '../../LeadListing/component/ViewComponent';
import useStore from '../../../store';
import { useHistory, useLocation } from 'react-router-dom';
import AuditLogElements from '../../LeadListing/elements/AuditLogElements';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../component/DatePickerCostume';
const CardTemplate = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  let location = useLocation();

  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }
  const View_icon = require(`../../../assets/images/${themecolor}/View.svg`).default;
  const Edit_icon = require(`../../../assets/images/${themecolor}/Edit.svg`).default;
  const Waste_icon = require(`../../../assets/images/${themecolor}/Waste.svg`).default;
  const Clone_icon =
    require(`../../../assets/images/${themecolor}/Clone_Record.svg`).default;
  const DropdownBox =
    require(`../../../assets/images/${themecolor}/DropdownBox.svg`).default;
  const Reassign = require(`../../../assets/images/${themecolor}/Reassign.svg`).default;
  const Reminders =
    require(`../../../assets/images/${themecolor}/Reminders_icn.svg`).default;
  const Calendar = require(`../../../assets/images/${themecolor}/Calendar.svg`).default;
  // const StatusChange =
  //   require(`../../../assets/images/${themecolor}/StatusChange.svg`).default;
  const Deal_icon = require(`../../../assets/images/${themecolor}/Deal_icn.svg`).default;
  const Audit_Logs =
    require(`../../../assets/images/${themecolor}/Audit_Logs.svg`).default;

  const { LEADSSTORE, AUTH,
    REMINDERSSTORE: { getReminderDatepickerExtraFooter }, } = useStore();

  const [count, setCount] = useState(0);
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [openHourglassModal, setOpenHourglassModal] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  // const [openleadStatusOptins, setOpenleadStatusOptins] = useState(false);
  const [GotoDealDrawer, setGotoDealDrawer] = useState(false);
  const [leadID, setleadID] = useState();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  // const [leadStatus, setLeadStatus] = useState(null);
  // const [statusUpdateData, setStatusUpdateData] = useState(null);
  const [reAssignUser, setReAssignUser] = useState(null);
  const [reAssingTempData, setReAssingTempData] = useState(null);
  const [openAuditLogModal, setopenAuditLogModal] = useState(false);

  const [extrafooter, setExtrafooter] = useState(null); // To add footer in reminder

  const [date, setDate] = useState();
  const [reminderConfirm, setReminderConfirm] = useState();
  const [hourglassConfirm, setHourglassConfirm] = useState();
  const [resetTime, setresetTime] = useState(null);

  const [editOpen, setEditOpen] = useState(false);

  //Confirm Modal
  const openConfirmDateTimeModal = (dateformat, data) => {
    if (dateformat) {
      setDate(dateformat);
      setReminderConfirm(data);
      setOpenDateTimeModal(true);
    }
  };
  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    setOpenDateTimeModal(false);
  };

  const openConfirmHourglassModal = (data) => {
    setOpenHourglassModal(true);
    setHourglassConfirm(data);
    setCount(count + 1);

    let leadHourglassTime = 0;
    if (data?.lead_status_id?.parent_status_id === 2) {
      //Opportunity
      leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_opportunity_hourglass;
    } else if (data?.lead_status_id?.parent_status_id === 3) {
      //Deals
      leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_deals_hourglass;
    } else if (data?.lead_status_id?.id === 4) {
      //Un-Used Leads
      leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_unused_leads_hourglass;
    } else {
      //Active Leads
      leadHourglassTime = AUTH.user?.dm_settings?.[0].screen_used_leads_hourglass;
    }
    setresetTime(leadHourglassTime);
  };
  const closeHourglassModal = () => {
    setHourglassConfirm(null);
    setOpenHourglassModal(false);
    setresetTime(null);
  };

  const openConfirmReassignModal = (userid, username, leadid) => {
    let tempReAssign = {
      userid: userid,
      username: username,
      leadid: leadid,
    };
    setReAssingTempData(tempReAssign);
    setOpenReassignModal(true);
  };
  const closeReassignModal = () => setOpenReassignModal(false);

  // const openleadStatusModal = (new_id, oldstatus, newstatus, id) => {
  //   let tempDataStatusUpdate = {
  //     new_id: new_id,
  //     oldstatus: oldstatus,
  //     newstatus: newstatus,
  //     id: id,
  //   };
  //   setStatusUpdateData(tempDataStatusUpdate);
  //   setOpenleadStatusOptins(true);
  // };
  // const closeleadStatusOptins = () => {
  //   setStatusUpdateData(null);
  //   setOpenleadStatusOptins(false);
  // };

  //Edit drawer
  const openEditDrawer = (data, isEditOpen = false) => {
    setEditOpen(isEditOpen);
    LEADSSTORE.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setEditDrawer(false);
    setEditOpen(false);
  }

  //open delet drawer
  const openDeleteDrawer = (data) => {
    LEADSSTORE.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  //Clone drawer
  const openCloneDrawer = (data) => {
    LEADSSTORE.setCloneValues(data);
    setCloneDrawer(true);
  };
  const closeCloneDrawer = () => setCloneDrawer(false);

  //View drawer
  const openViewDrawer = (data) => {
    LEADSSTORE.setViewValues(data);
    setViewDrawer(true);
  };
  const closeViewDrawer = () => setViewDrawer(false);

  // let liPercentage =
  //   leadStatus && leadStatus.length > 0 ? ((100 / leadStatus.length) - 1) + '%' : '24%';

  // let ulWidth =
  //   leadStatus && (leadStatus.length === 1 || leadStatus.length === 2)
  //     ? 200 * leadStatus.length + 'px'
  //     : '655px';

  // const LeadStatusOptins = (
  //   <div className='leadStatusOptins'>
  //     <ul
  //       style={{
  //         width: ulWidth,
  //       }}
  //     >
  //       {leadStatus &&
  //         leadStatus.map((item) => {
  //           return (
  //             <li key={item.id} style={{ width: liPercentage }}>
  //               <span className='title'>{item.status_name}</span>
  //               <ul>
  //                 {item.child_leads.map(
  //                   (itemsubleads) =>
  //                     itemsubleads.id !== LEADSSTORE.ignore_unusedlead_id && (
  //                       <li
  //                         key={itemsubleads.id}
  //                         className={
  //                           itemsubleads.id === props.lead_status_id.id
  //                             ? 'active'
  //                             : ''
  //                         }
  //                         onClick={() => {
  //                           if (props.lead_status_id.id !== itemsubleads.id) {
  //                             openleadStatusModal(
  //                               itemsubleads.id,
  //                               props.leadstatusname,
  //                               itemsubleads.status_name,
  //                               props.id
  //                             )
  //                           }
  //                         }
  //                         }
  //                       >
  //                         <Tooltip title={itemsubleads.status_name}>
  //                           {itemsubleads.status_name}
  //                         </Tooltip>
  //                       </li>
  //                     )
  //                 )}
  //               </ul>
  //             </li>
  //           );
  //         })}
  //     </ul>
  //   </div>
  // );

  const userList = (
    <div className='ticketsPopoverIcons username'>
      <ul>
        {reAssignUser &&
          reAssignUser.map((item) => {
            return (
              <li
                key={item.id}
                onClick={() => openConfirmReassignModal(item.id, item.name, props.id)}
              >
                <Tooltip title={item.name}>{item.name}</Tooltip>
              </li>
            );
          })}
      </ul>
    </div>
  );
  const linkToDeal = (leadid) => {
    history.push(`/dealdesk/${leadid.id}`);
  };
  const AuditLogModal = (data) => {
    setopenAuditLogModal(true);
  };
  const closeAuditLogModal = () => setopenAuditLogModal(false);
  // const format = 'HH:mm';

  // const disabledDate = (current) => {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // };

  // const disabledHours = (e) => {
  //   let hours = [19, 20, 21, 22, 23];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  const content = (
    <div style={{ width: '160px' }} className='ticketsPopoverIcons'>
      <Space size={0} wrap className='KanbanIcons'>
        {((location.pathname === '/leads/opportunities/kanban' &&
          AUTH.checkPermission(12, 'change-view')) ||
          (location.pathname === '/leads/deals/kanban' &&
            AUTH.checkPermission(13, 'change-view')) ||
          (location.pathname === '/leads/active/kanban' &&
            AUTH.checkPermission(11, 'change-view'))) && (
            <img
              className='menuicon'
              title={`${t('Common_Data.Dealdesk')}`}
              alt=''
              src={Deal_icon}
              onClick={() => {
                linkToDeal(props);
              }}
            />
          )}
        {((location.pathname === '/leads/opportunities/kanban' &&
          AUTH.checkPermission(12, 'view-details')) ||
          (location.pathname === '/leads/deals/kanban' &&
            AUTH.checkPermission(13, 'view-details')) ||
          (location.pathname === '/leads/active/kanban' &&
            AUTH.checkPermission(11, 'view-details'))) && (
            <img
              className='menuicon'
              title={`${t('DealDesk.View')}`}
              alt=''
              src={View_icon}
              onClick={() => {
                openViewDrawer(props);
              }}
            />
          )}
        {((location.pathname === '/leads/opportunities/kanban' &&
          AUTH.checkPermission(12, 'edit')) ||
          (location.pathname === '/leads/deals/kanban' &&
            AUTH.checkPermission(13, 'edit')) ||
          (location.pathname === '/leads/active/kanban' &&
            AUTH.checkPermission(11, 'edit'))) && (
            <img
              className='menuicon'
              alt=''
              title={`${t('Common_Data.Edit')}`}
              src={Edit_icon}
              onClick={() => {
                openEditDrawer(props);
              }}
            />
          )}
        {((location.pathname === '/leads/opportunities/kanban' &&
          AUTH.checkPermission(12, 'delete')) ||
          (location.pathname === '/leads/deals/kanban' &&
            AUTH.checkPermission(13, 'delete')) ||
          (location.pathname === '/leads/active/kanban' &&
            AUTH.checkPermission(11, 'delete'))) && (
            <img
              className='menuicon'
              alt=''
              title={`${t('Common_Data.Delete')}`}
              src={Waste_icon}
              onClick={() => {
                openDeleteDrawer(props);
              }}
            />
          )}
        {((location.pathname === '/leads/opportunities/kanban' &&
          AUTH.checkPermission(12, 'clone')) ||
          (location.pathname === '/leads/deals/kanban' &&
            AUTH.checkPermission(13, 'clone')) ||
          (location.pathname === '/leads/active/kanban' &&
            AUTH.checkPermission(11, 'clone'))) && (
            <img
              className='menuicon'
              alt=''
              title={`${t('Common_Data.Clone')}`}
              src={Clone_icon}
              onClick={() => {
                openCloneDrawer(props);
              }}
            />
          )}
        {/* Remove Change Status for SOLD
        {((location.pathname === '/leads/opportunities/kanban' &&
          AUTH.checkPermission(12, 'change-status')) ||
          (location.pathname === '/leads/deals/kanban' &&
            AUTH.checkPermission(13, 'change-status')) ||
          (location.pathname === '/leads/active/kanban' &&
            AUTH.checkPermission(11, 'change-status'))) && (
            <Popover
              content={LeadStatusOptins}
              placement='bottom'
              trigger='hover'
              overlayClassName='profileMenu leadStatusFixHeight'
            >
              <img
                className='menuicon'
                alt=''
                title={`${t('LeadListing.Status_Change')}`}
                src={StatusChange}
                onMouseEnter={() => {
                  if (!leadStatus) {
                    LEADSSTORE.getLeadStatusList().then((data) => {
                      setLeadStatus(data.data);
                    });
                  }
                }}
              />
            </Popover>
          )} */}
        {((location.pathname === '/leads/opportunities/kanban' &&
          AUTH.checkPermission(12, 'reassign')) ||
          (location.pathname === '/leads/deals/kanban' &&
            AUTH.checkPermission(13, 'reassign')) ||
          (location.pathname === '/leads/active/kanban' &&
            AUTH.checkPermission(11, 'reassign'))) && (
            <Popover
              content={userList}
              placement='bottomRight'
              trigger='hover'
              overlayClassName='profileMenu'
            >
              <img
                className='menuicon'
                alt=''
                title={`${t('LeadListing.Reassign')}`}
                src={Reassign}
                onMouseEnter={() => {
                  if (!reAssignUser) {
                    LEADSSTORE.getReassignUserList().then((data) => {
                      setReAssignUser(data.data);
                    });
                  }
                }}
              />
            </Popover>
          )}

        {AUTH.checkPermission(31, 'list') && (
          <img
            className='menuicon'
            title={`${t('LeadKanbanView.Audit_Logs')}`}
            alt=''
            src={Audit_Logs}
            onClick={() => {
              AuditLogModal(props);
            }}
          />
        )}
        {((location.pathname === '/leads/opportunities/kanban' &&
          AUTH.checkPermission(12, 'reset-reminder-date-time')) ||
          (location.pathname === '/leads/deals/kanban' &&
            AUTH.checkPermission(13, 'reset-reminder-date-time')) ||
          (location.pathname === '/leads/active/kanban' &&
            AUTH.checkPermission(11, 'reset-reminder-date-time'))) && (

            <span
              className='Calendar cursor'
              onMouseEnter={() => {
                if (props?.reset_count !== null) {
                  getReminderDatepickerExtraFooter(
                    props,
                    AUTH.global_fulldate_format,
                    {
                      type: 'leads',
                    }
                  ).then((data) => {
                    setExtrafooter(data);
                  });
                }
              }}
            >
              <Tooltip
                title={
                  props?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`
                }
              >
                <DatePickerCostume
                  name='Calendar'
                  showTime
                  disabledDate={true}
                  onChange={(e) => openConfirmDateTimeModal(e, props)}
                  // minuteStep={15}
                  // hideDisabledOptions
                  // disabledHours={() => disabledHours()}
                  // format={format}
                  showNow={false}
                  onOk={(e) => openConfirmDateTimeModal(e, props)}
                  renderExtraFooter={() => extrafooter}
                />
                <img
                  className='menuicon'
                  alt=''
                  src={Calendar}
                //onClick={handleCount}
                />
              </Tooltip>
            </span>
          )}
        {((location.pathname === '/leads/opportunities/kanban' &&
          AUTH.checkPermission(12, 'reset-reminder-time')) ||
          (location.pathname === '/leads/deals/kanban' &&
            AUTH.checkPermission(13, 'reset-reminder-time')) ||
          (location.pathname === '/leads/active/kanban' &&
            AUTH.checkPermission(11, 'reset-reminder-time'))) && (
            <>
              <img
                className='menuicon'
                alt=''
                title={`${t('Common_Data.Hour_Glass')}`}
                src={Reminders}
                onClick={() => openConfirmHourglassModal(props)}
              />
              <span>{count}</span>
            </>
          )}
      </Space>
    </div>
  );

  const onLeadSelected = () => {
    setGotoDealDrawer(true);
    setleadID(props.id);
    //alert('Lead');
  };
  const closeGotoDealDrawer = () => setGotoDealDrawer(false);

  return (
    <>
      {props ? (
        <div className={'card-template'}>
          <div className='e-card-header'>
            <div className='e-card-header-caption'>
              <div className='e-card-header-title e-tooltip-text'>
                <span onClick={() => onLeadSelected()}>{t('Common_Data.Lead')}# {props.id}</span>
                <span className='icon'>
                  <Popover
                    content={content}
                    placement='bottomRight'
                    trigger='click'
                    overlayClassName='profileMenu'
                    arrowPointAtCenter
                  >
                    <Button type='text'>
                      <img className='menuicon' alt='' src={DropdownBox} />
                    </Button>
                  </Popover>
                </span>
              </div>
            </div>
          </div>
          <div className='e-card-content e-tooltip-text'>
            <div className='e-text'>
              {props.lead_owner_name && props.lead_owner_name.full_name}
            </div>
          </div>
        </div>
      ) : (
        <Spin />
      )}
      <ViewComponent
        visible={viewDrawer}
        openEditDrawer={openEditDrawer}
        close={closeViewDrawer}
      />
      <EditComponent
        visible={editDrawer}
        editOpen={editOpen}
        close={closeEditDrawer}
      />
      <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
      <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />

      <DateTimeConfirmElements
        visible={openDateTimeModal}
        close={closeDateTimeModal}
        date={date}
        reminderConfirm={reminderConfirm}
      />
      <HourglassConfirmElements
        visible={openHourglassModal}
        close={closeHourglassModal}
        hourglassConfirm={hourglassConfirm}
        resetTime={resetTime}
      />
      <ReassignConfirmElements
        visible={openReassignModal}
        close={closeReassignModal}
        reAssingTempData={reAssingTempData}
      />
      {/* <StatusConfirmElements
        visible={openleadStatusOptins}
        close={closeleadStatusOptins}
        statusUpdateData={statusUpdateData}
      /> */}
      <GoToDealDeskConfirmElements
        visible={GotoDealDrawer}
        close={closeGotoDealDrawer}
        leadID={leadID}
      />
      <AuditLogElements visible={openAuditLogModal} close={closeAuditLogModal} />
    </>
  );
};
export default CardTemplate;
