import React, { useEffect, useState } from 'react';
import { Form, Button, Drawer } from 'antd';
import { observer } from 'mobx-react';
import { vsmNotify, vsmPeopleRoleMapping } from '../../../../config/messages';
import useStore from '../../../../store';
import { default as AddPeopleComponent } from '../../People/component/AddComponent';
import FormComponent from './FormComponent';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { ORGANISATION, ORGMAPPINGSTORE, PEOPLEMAPPINGSTORE, PEOPLE } = useStore();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [rPeopleData, setRPeopleData] = useState(null);
  const [currentTab, setCurrentTab] = useState('People');
  const [fetchRoles, setFetchRoles] = useState(true);
  const [fetchRelatedRoles, setFetchRelatedRoles] = useState(true);
  const [fetchTags, setFetchTags] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [fetchPeople, setFetchPeople] = useState(true);
  const [rejectedkeys, setRejectedKeys] = useState();

  // set the CurrentTab values to add
  useEffect(() => {
    if (props.visible && props.relatedToTab) {
      setCurrentTab(props.relatedToTab);
    }
  }, [props]);

  // Open form for add new People
  const openAddModal = () => {
    setAddModal(true);
  };

  const setPeopleDropDown = (id) => {
    PEOPLE.getAllPeople({ selected_id: [id], mapping_type: 2 });
    form.setFieldsValue({ people_id: id });
    PEOPLE.getPeopleCardValue(id, 'people');
  };

  // Close form for add new People
  const closeAddModal = () => setAddModal(false);

  // Handle submit and call function to save new record
  const handleSubmit = (data) => {
    setSaving(true);
    if (PEOPLE.peopleValues) {
      data.entity_id = PEOPLE.peopleValues.id;
      data.people_id = PEOPLE.peopleValues.id;
    } else {
      data.entity_id = props.parent_id || form.getFieldValue('people_id');
    }

    data.entity_type = 2;
    PEOPLEMAPPINGSTORE.AddData(data)
      .then(() => {
        close();
        /*START:Used To Update Dealdesk connections */
        if (props.ConnectionModule) {
          props.fetchMappingData();
        }
        /*END:Used To Update Dealdesk connections */
        if (props.callList) {
          props.callList();
        }
        if (props.handleViewDetailCall) {
          props.handleViewDetailCall({ id: props.parent_id });
        }
        vsmNotify.success({
          message: vsmPeopleRoleMapping.add,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable & set value of role
  const handleChange = async () => {
    if (currentTab !== form.getFieldValue('related_entity_type')) {
      await form.resetFields([
        'related_people_id',
        'related_role_id',
        'related_entity_id',
      ]);
      PEOPLE.rPeopleValues = null;
      setRPeopleData(null);
      setFetchRelatedRoles(true);
      setCurrentTab(form.getFieldValue('related_entity_type'));
    }
    setRejectedKeys(form.getFieldValue('role_id'));
    validateData();
  };

  // call to validate fields
  const validateData = () => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  // reset form and close add form
  const close = () => {
    form.resetFields();
    setDisabled(true);
    setCurrentTab('People');
    ORGANISATION.rOrganisationValues = null;
    ORGANISATION.dropdown_ROrg_list = null;
    PEOPLE.rPeopleValues = null;
    PEOPLE.peopleCardValue = null;
    PEOPLE.rPeopleCardValue = null;
    setFetchRoles(true);
    setFetchRelatedRoles(true);
    setFetchTags(true);
    ORGMAPPINGSTORE.dropdown_tags_list = null;
    setRejectedKeys();
    props.close();
  };

  useEffect(() => {
    if (props.visible && props.parent_id && !props.waste) {
      PEOPLE.getPeopleCardValue(props.parent_id, 'people');
    }
  }, [props.visible, props.waste, props.parent_id, PEOPLE]);
  return (
    <>
      <Drawer
        destroyOnClose={true}
        centered
        width={'1050px'}
        title={`${t('Common_Data.Add')} ${t('Common_Data.People')} ${t('Common_Data.Mapping')}`}
        visible={props.visible}
        onClose={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <div className='text-right'>
            <Button
              key='1'
              disabled={disabled}
              form='mapaddeditform'
              loading={saving}
              htmlType='submit'
              type='primary'
              shape='round'
              size='mediumn'
              className='mr-10'
            >
              {t('Common_Data.Save')}
            </Button>
            <Button
              key='2'
              className='ml-10'
              htmlType='button'
              shape='round'
              size='mediumn'
              onClick={close}
            >
              {t('Common_Data.Cancel')}
            </Button>
          </div>,
        ]}
      >
        <FormComponent
          form={form}
          handleSubmit={handleSubmit}
          onChange={handleChange}
          currentTab={currentTab}
          fetchRoles={fetchRoles}
          fetchRelatedRoles={fetchRelatedRoles}
          fetchTags={fetchTags}
          fetchPeople={fetchPeople}
          setFetchRoles={setFetchRoles}
          setFetchRelatedRoles={setFetchRelatedRoles}
          setFetchTags={setFetchTags}
          setFetchPeople={setFetchPeople}
          rPeopleData={rPeopleData}
          setRPeopleData={setRPeopleData}
          openAddModal={openAddModal}
          visible={props.visible}
          id={props.parent_id}
          setCurrentTab={setCurrentTab}
          relatedToTab={props.relatedToTab}
          rejectedkeys={rejectedkeys}
          setRejectedKeys={setRejectedKeys}
        />
      </Drawer>
      <AddPeopleComponent
        visible={addModal}
        close={closeAddModal}
        setPeopleDropDown={setPeopleDropDown}
      />
    </>
  );
  // return (
  //   <>
  //     <Drawer
  //       destroyOnClose={true}
  //       width={'75%'}
  //       title={`Map New Role for ${
  //         PEOPLE.peopleValues && PEOPLE.peopleValues.first_name
  //           ? '- ' + PEOPLE.peopleValues.first_name
  //           : ''
  //       } ${
  //         PEOPLE.peopleValues && PEOPLE.peopleValues.last_name
  //           ? '- ' + PEOPLE.peopleValues.last_name
  //           : ''
  //       }`}
  //       visible={props.visible}
  //       onClose={close}
  //       footer={[
  //         <div className='text-center'>
  //           <Button
  //             key='1'
  //             disabled={disabled}
  //             form='mapaddeditform'
  //             loading={saving}
  //             htmlType='submit'
  //             type='primary'
  //           >
  //             Save
  //           </Button>
  //           <Button key='2' className='ml-10' htmlType='button' onClick={close}>
  //             Cancel
  //           </Button>
  //         </div>,
  //       ]}
  //     >
  //       <FormComponent
  //         form={form}
  //         handleSubmit={handleSubmit}
  //         onChange={handleChange}
  //         currentTab={currentTab}
  //         fetchRoles={fetchRoles}
  //         fetchRelatedRoles={fetchRelatedRoles}
  //         fetchTags={fetchTags}
  //         fetchPeople={fetchPeople}
  //         setFetchRoles={setFetchRoles}
  //         setFetchRelatedRoles={setFetchRelatedRoles}
  //         setFetchTags={setFetchTags}
  //         setFetchPeople={setFetchPeople}
  //         rPeopleData={rPeopleData}
  //         setRPeopleData={setRPeopleData}
  //         openAddModal={openAddModal}
  //         visible={props.visible}
  //         id={props.parent_id}
  //         setCurrentTab={setCurrentTab}
  //         relatedToTab={props.relatedToTab}
  //         rejectedkeys={rejectedkeys}
  //         setRejectedKeys={setRejectedKeys}
  //       />
  //     </Drawer>
  //     <AddPeopleComponent
  //       visible={addModal}
  //       close={closeAddModal}
  //       setPeopleDropDown={setPeopleDropDown}
  //     />
  //   </>
  // );
});

export default AddComponent;
