import React from 'react';
import { observer } from 'mobx-react';
import { Drawer, Button } from 'antd';
// import moment from 'moment';
import ScheduleContent from './ScheduleContent';
import { useTranslation } from 'react-i18next';

const ScheduleLibraryView = observer((props) => {
  const { t } = useTranslation();
  const {
    scheduleData,
    selectedLibraryItemNode,
    setSelectedLibraryItemNode,
    isCheckBoxSelection,
    tempcopySchedule,
  } = props;

  const closeScheduleLibraryDrawer = () => props.closeScheduleLibraryDrawer();

  return (
    <>
      <Drawer
        title={`${t('DealDesk.View_Schedule')} - #${scheduleData?.id} - ${scheduleData?.title}`}
        visible={props.visibleScheduleLibraryDrawer}
        onClose={closeScheduleLibraryDrawer}
        placement='right'
        width={'1050px'}
        destroyOnClose={true}
        footer={[
          <div className='text-right'>
            {selectedLibraryItemNode && selectedLibraryItemNode.length > 0 && (
              <Button
                key='1'
                className='mr-10'
                type='primary'
                shape='round'
                onClick={() => tempcopySchedule(scheduleData, true)}
              >
                {t('DealDesk.Copy')}
              </Button>
            )}
          </div>,
        ]}
      >
        <div className='structureRight noStyle'>
          <div className='list fullHeight'>
            <ScheduleContent
              scheduleList={scheduleData?.description}
              editable={false}
              onlyForViewWithNostyle={true}
              isCheckBoxSelection={isCheckBoxSelection}
              selectedLibraryItemNode={selectedLibraryItemNode}
              setSelectedLibraryItemNode={setSelectedLibraryItemNode}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
});
export default ScheduleLibraryView;
