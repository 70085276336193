import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import { ActionRenderer } from './GridComponent';
import { vsmCommon } from '../../../config/messages';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { useTranslation } from 'react-i18next';

function getFileCellRenderer() {
  function FileCellRenderer() {}
  FileCellRenderer.prototype.init = function (params) {
    var tempDiv = document.createElement('div');
    var value = params.value;
    var icon = getFileIcon(params.value);
    tempDiv.innerHTML = icon
      ? `<i className="${icon}" /> <span class='filename '> ${value}</span>`
      : value;

    this.eGui = icon
      ? `<i class="icon ${icon}" /><span class='filename'> ${value}</span>`
      : value;
    //
  };
  FileCellRenderer.prototype.getGui = function () {
    return this.eGui;
  };
  return FileCellRenderer;
}
function getFileIcon(filename) {
  return filename.endsWith('.mp3') || filename.endsWith('.wav')
    ? 'fas fa-file-audio'
    : filename.endsWith('.xls')
    ? 'fas fa-file-excel'
    : filename.endsWith('.txt')
    ? 'fas fa-file'
    : filename.endsWith('.pdf')
    ? 'fas fa-file-pdf'
    : 'fas fa-folder';
}

const EditComponent = observer((props) => {
  const {
    DEALDESKSTORE: { leadFileFolderList },
  } = useStore();
  const {t} = useTranslation()
  const gridOptions = {
    columnDefs: [
      {
        headerName: `${t('FoldersFileModule.Files')}`,
        field: 'entity_name',
        cellClass: 'cellClass cellClassDropDown',
        cellRenderer: 'getFileCellRenderer',
      },
      {
        headerName: `${t('Common_Data.Key')} ${t('Common_Data.Document')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',
        minWidth: 150,
      },
    ],
  };

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper fileFolder fileFolderEdit'
        style={{ height: '500px' }}
      >
        <AgGridReact
          rowData={leadFileFolderList ? leadFileFolderList : []}
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: false,
            width: 120,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
          }}
          components={{ fileCellRenderer: getFileCellRenderer() }}
          //onGridReady={TicketsStore.setupGrid}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: false,
            animateRows: true,
          }}
        />
      </div>
    </>
  );
});
export default EditComponent;
