import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { Checkbox, Col, Divider, Form, Row } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { useTranslation } from 'react-i18next';

const SummaryTagsElement = observer(({ onChange, formDataList, form }) => {
  const { TagsStore } = useStore();
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={12} align='middle' className='mb-5'>
        <Col span={24}>
          <Divider orientation='left' orientationMargin='0' plain>
            {t('Common_Data.Tags')}
          </Divider>
        </Col>
        <Col span={24}>
          <Row gutter={12} align='middle'>
            {formDataList.includes('tag_sum_total') && (
              <Col span={4}>
                <Form.Item className='m-0' name='tag_sum_total' valuePropName='checked'>
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Total')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('tag_created') && (
              <Col span={4}>
                <Form.Item className='m-0' name='tag_created' valuePropName='checked'>
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Created')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('tag_assigned') && (
              <Col span={4}>
                <Form.Item className='m-0' name='tag_assigned' valuePropName='checked'>
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Assigned')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('sum_by_tag_status') && (
              <Col span={4}>
                <Form.Item
                  className='m-0'
                  name='sum_by_tag_status'
                  valuePropName='checked'
                >
                  <Checkbox onChange={onChange}>{t('Reports.Sum_by_Status')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('tag_status') && (
              <Col span={8}>
                <InputComponent
                  type='select'
                  rowclassName='m-0'
                  allowClear
                  showSearch
                  showArrow
                  name='tag_status'
                  placeholder={t('Reports.Tag_Status')}
                  mode='multiple'
                  onChange={onChange}
                  options={{
                    values: TagsStore.statusArr_list,
                    value_key: 'value',
                    text_key: 'text',
                  }}
                  tooltip={t('Reports.Organiation_Type_Tooltip')}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
});

export default SummaryTagsElement;
