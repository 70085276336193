import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import useStore from '../../../../store';
import { vsmCommon } from '../../../../config/messages';
import { Button, Tooltip } from 'antd';
import { DateComparator } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { openEditModal, openDeleteModal, openMapBankAccount, onCellKeyDown } = props;
  const { PEOPLEMAPPINGSTORE, REMINDERSSTORE, AUTH } = useStore();
  const { t } = useTranslation()
  const ActionRenderer = (props) => {
    let themecolor = localStorage.getItem('app_color_theme');
    if (themecolor && themecolor !== 'undefined') {
    } else {
      themecolor = 'gray';
    }
    const editIcon = require(`../../../../assets/images/${themecolor}/Edit.svg`).default;
    const wasteIcon = require(`../../../../assets/images/${themecolor}/Waste.svg`).default;
    const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents;
    return (
      <div className='action-column'>
        {AUTH.checkPermission(18, 'role-mapping-edit') && (
          <Button
            title={`${t('Common_Data.Edit')} ${t('PeopleManagement.Role')} ${t('Common_Data.Mapping')}`}
            type='text'
            onClick={() => {
              openEditModal(props.data);
            }}
          >
            <img alt='' className='menuicon' src={editIcon} />
          </Button>
        )}
        {!AUTH.checkPermission(18, 'role-mapping-delete') ? null : props.data
          .association_count > 0 ? (
          <Tooltip
            placement='topRight'
            color={'red'}
            title={
              `${t('PeopleManagement.Role_Mapping_Tooltip')}.`
            }
          >
            <Button type='text' disabled>
              <img alt='' className='menuicon' src={wasteIcon} />
            </Button>
          </Tooltip>
        ) : (
          <Button
            title={`${t('Common_Data.Delete')} ${t('PeopleManagement.Role')} ${t('Common_Data.Mapping')}`}
            type='text'
            onClick={() => {
              openDeleteModal(props.data);
            }}
          >
            <img alt='' className='menuicon' src={wasteIcon} />
          </Button>
        )}
        {/* {parseInt(id) === props.data.entity_id && (
          <Button
            title='Add Bank Accounts'
            type='text'
            onClick={() => {
              openMapBankAccount(props.data);
            }}
          >
            <BankOutlined />
          </Button>
        )} */}
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Name')}`,
        field: 'entity_name',
        tooltipValueGetter: (params) => params.data && params.data.entity_type === 'People'
          ? params.data.people &&
          params.data.people.first_name +
          (params.data.people.last_name ? ' ' + params.data.people.last_name : '')
          : params.data.organisation && params.data.organisation.organisation_name,
        valueGetter: (params) =>
          params.data && params.data.entity_type === 'People'
            ? params.data.people &&
            params.data.people.first_name +
            (params.data.people.last_name ? ' ' + params.data.people.last_name : '')
            : params.data.organisation && params.data.organisation.organisation_name,

      },
      {
        headerName: `${t('PeopleManagement.Role')}`,
        headerTooltip: `${t('PeopleManagement.Role')}`,
        field: 'role_name',
        tooltipValueGetter: (params) => params.data && params.data.entity_type === 'People'
          ? params.data.people_role && params.data.people_role.role_name
          : params.data.role && params.data.role.role_name,

        valueGetter: (params) =>
          params.data && params.data.entity_type === 'People'
            ? params.data.people_role && params.data.people_role.role_name
            : params.data.role && params.data.role.role_name,
        filter: 'agSetColumnFilter',
        filterParams: { defaultToNothingSelected: true },

      },
      {
        headerName: `${t('PeopleManagement.Related')} ${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('PeopleManagement.Related')} ${t('Common_Data.Organisation')} / ${t('Common_Data.People')} ${t('Common_Data.Name')}`,
        field: 'related_entity_name',
        tooltipValueGetter: (params) => params.data && params.data.related_entity_type === 'People'
          ? params.data.related_people &&
          params.data.related_people.first_name +
          (params.data.related_people.last_name
            ? ' ' + params.data.related_people.last_name
            : '')
          : params.data.related_organisation &&
          params.data.related_organisation.organisation_name,

        valueGetter: (params) =>
          params.data && params.data.related_entity_type === 'People'
            ? params.data.related_people &&
            params.data.related_people.first_name +
            (params.data.related_people.last_name
              ? ' ' + params.data.related_people.last_name
              : '')
            : params.data.related_organisation &&
            params.data.related_organisation.organisation_name,

      },
      {
        headerName: `${t('PeopleManagement.Related')} ${t('PeopleManagement.Role')}`,
        headerTooltip: `${t('PeopleManagement.Related')} ${t('PeopleManagement.Role')}`,
        field: 'related_role_name',
        tooltipValueGetter: (params) => params.data && params.data.related_entity_type === 'People'
          ? params.data.peoplemerge_role && params.data.peoplemerge_role.role_name
          : params.data.merge_role && params.data.merge_role.role_name,

        valueGetter: (params) =>
          params.data && params.data.related_entity_type === 'People'
            ? params.data.peoplemerge_role && params.data.peoplemerge_role.role_name
            : params.data.merge_role && params.data.merge_role.role_name,
        filter: 'agSetColumnFilter',
        filterParams: { defaultToNothingSelected: true },

      },
      {
        headerName: `${t('Common_Data.Tags')}`,
        headerTooltip: `${t('Common_Data.Tags')}`,
        field: 'tag_names',
        tooltipValueGetter: (params) => params.data && params.data.tags_names
          ? params.data.tags_names.map((item) => {
            return item;
          })
          : '',

        valueGetter: (params) =>
          params.data && params.data.tags_names
            ? params.data.tags_names.map((item) => {
              return item;
            })
            : '',

      },
      {
        headerName: `${t('Common_Data.Last_Updated_By')}`,
        headerTooltip: `${t('Common_Data.Last_Updated_By')}`,
        field: 'updated_by',
        tooltipField: 'updated_by',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            REMINDERSSTORE.getUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        headerTooltip: `${t('Common_Data.Last_Updated_On')}`,
        field: 'updated_at',
        tooltipField: 'updated_at',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },

      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        headerTooltip: `#${t('Common_Data.ID')}`,
        field: 'id',
        tooltipField: 'id',
        filter: 'agNumberColumnFilter',

        width: 120,
        // lockPosition: true,
        // suppressNavigable: true
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        headerTooltip: `${t('Common_Data.Actions')}`,
        field: 'actions',
        tooltipField: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',

        width: 120,
        lockPosition: true,
        suppressNavigable: true,
      },
    ],
  };

  return (
    <div className='ag-theme-alpine grid_wrapper'>
      <AgGridReact
        rowData={PEOPLEMAPPINGSTORE.list_data}
        modules={AllModules}
        columnDefs={gridOptions.columnDefs}
        defaultColDef={LocalGridConfig.defaultColDef}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          openEditModal,
          openDeleteModal,
          openMapBankAccount,
        }}
        onGridReady={PEOPLEMAPPINGSTORE.setupGrid}
        gridOptions={{ ...LocalGridConfig.options, pagination: true, rowHeight: 30 }}
        onFilterChanged={PEOPLEMAPPINGSTORE.onFilterChanged}
        onSortChanged={PEOPLEMAPPINGSTORE.onFilterChanged}
        rowSelection={'multiple'}
        suppressRowClickSelection={true}
        onCellKeyDown={onCellKeyDown}
      />
    </div>
  );
});

export default ListComponent;
