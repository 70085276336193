import '../style/index.css';
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from '@syncfusion/ej2-react-kanban';
import { extend } from '@syncfusion/ej2-base';
import CardTemplate from './cardTemplate';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const KanbanView = (props) => {
  const {t} = useTranslation();
  const CardData = extend([], props.datasource, null, true);

  const OnDragStop = (params) => {

    let tempDataStatusUpdate = params && {
      oldstatus: params.element && params.element.dataset.key,
      newstatus: params.data.length && params.data && params.data[0][props.fieldKey],
      id: params.data.length && params.data && params.data[0].id,
    };

    props.onDragStop(tempDataStatusUpdate);
  };

  const columnTemplate = (params) => {
    return (
      <div className='header-template-wrap'>
        <div className={'header-icon e-icons ' + params.keyField}></div>
        <div className='header-text'>{params.headerText}</div>
      </div>
    );
  };

  return (
    <Spin spinning={props.loadkanban}>
      <div className='schedule-control-section'>
        <div className='control-section'>
          <div className='control-wrapper'>
            <KanbanComponent
              id='kanban'
              keyField={props.fieldKey}
              dataSource={CardData}
              cardSettings={{
                headerField: 'Title',
                template: CardTemplate,
                selectionType: 'Multiple',
              }}
              dragStop={OnDragStop}
              dataBound={() => {
                if (props.datasource && props.datasource.length > 0) {
                  props.setLoadkanban(false);
                }
              }}
            >
              <ColumnsDirective>
                {props?.directives || props.fieldKey || CardData ? (
                  props?.directives.map((item, index) => (
                    <ColumnDirective
                      key={index}
                      headerText={item.status_name}
                      keyField={item.id}
                      allowToggle={true}
                      template={columnTemplate}
                    />
                  ))
                ) : (
                  <Spin size='large' tip={`${t('Common_Data.Load')}`}></Spin>
                )}
              </ColumnsDirective>
            </KanbanComponent>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default KanbanView;
