import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
export default class NotesOutboundStore {
  dropdown_tinymce_industry_list = null;
  notifyAutosave = false;
  autoSaveIDTempData = null; //-------------USED TO CALL AUTOSAVE DELETE LEFT SECTION ------------------//
  classificationList = null;
  //EditAutosaveTest
  saveOutboundNoteEdit_v1 = (id, formdata, isautosave = false) => {
    return Axios.post(`deal-desk/v2/create-note-outbound/${id}`, formdata)
      .then(({ data }) => {
        if (isautosave) {
          this.notifyAutosave = true;

          //-------------START:USED TO CALL AUTOSAVE DELETE LEFT SECTION ------------------//
          this.autoSaveIDTempData = data?.data;
          //-------------END:USED TO CALL AUTOSAVE DELETE LEFT SECTION ------------------//
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //set Deal Status Update API
  saveOutboundNote = (id, formdata, isautosave = false) => {
    return Axios.post(`deal-desk/create-note-outbound/${id}`, formdata)
      .then(({ data }) => {
        if (isautosave) {
          this.notifyAutosave = true;

          //-------------START:USED TO CALL AUTOSAVE DELETE LEFT SECTION ------------------//
          this.autoSaveIDTempData = data?.data;
          //-------------END:USED TO CALL AUTOSAVE DELETE LEFT SECTION ------------------//
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!['photo_url'].includes(key)) {
        formData.append(
          key,
          data[key] !== undefined && data[key] !== null ? data[key] : ''
        );
      }
    });
    return formData;
  };

  //set Deal Status Update API
  fetchUsersDropList = () => {
    return Axios.get(`user/dropdown/list`)
      .then(({ data }) => {
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  //set Deal Status Update API
  fetchLockListGroupUsers = () => {
    return Axios.get(`deal-desk/user/user-group/dropdown`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  //set Deal Status Update API
  fetchClassificationList = () => {
    return Axios.post(`industry/list`)
      .then(({ data }) => {
        this.classificationList = data?.data;
        let temp_tinymce_industry_list = [];
        if (data && data.data && data.data.length > 0) {
          data.data.forEach((x) => {
            temp_tinymce_industry_list.push({ text: `${x.name}`, value: `${x.id}` });
          });
        }
        this.dropdown_tinymce_industry_list = temp_tinymce_industry_list;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  setNotifyAutosave = (data) => {
    this.notifyAutosave = data;
  };

  //-------------START:USED TO CALL AUTOSAVE DELETE LEFT SECTION ------------------//
  setAutoSaveIDTempData = (data) => {
    this.autoSaveIDTempData = data;
  };

  removeAutosaveNote = (dealid, autosaveNoteID) => {
    return Axios.post(`deal-desk/delete-note/${dealid}/${autosaveNoteID}`)
      .then(({ data }) => {
        this.autoSaveIDTempData = null;
        return data;
      })
      .catch((data) => {
        return Promise.reject(data);
      });
  };
  //-------------END:USED TO CALL AUTOSAVE DELETE LEFT SECTION ------------------//
}
decorate(NotesOutboundStore, {
  autoSaveIDTempData: observable,
  dropdown_tinymce_industry_list: observable,
  notifyAutosave: observable,
  classificationList: observable,

  saveOutboundNote: action,
  JsonToFormData: action,
  fetchUsersDropList: action,
  fetchLockListGroupUsers: action,
  fetchClassificationList: action,
  setNotifyAutosave: action,

  //-------------START:USED TO CALL AUTOSAVE DELETE LEFT SECTION ------------------//
  setAutoSaveIDTempData: action,
  removeAutosaveNote: action,
  //-------------END:USED TO CALL AUTOSAVE DELETE LEFT SECTION ------------------//
  saveOutboundNoteEdit_v1: action,
});
