import TranslateMessage from "../component/TranslateMessages";

export const AppNavConfig = [
  { id: 16, title: <TranslateMessage msgKey={'Common_Data.Organisations'} />, path: '/organisations' },
  { id: 33, title: <TranslateMessage msgKey={'LeadListing.Notes'} />, path: '/notes' },
  { id: 14, title: <TranslateMessage msgKey={'ManageAllDepartments.Tags'} />, path: '/tags' },
  { id: 15, title: <TranslateMessage msgKey={'Common_Data.Tickets'} />, path: '/tickets' },
  { id: 46, title: <TranslateMessage msgKey={'Common_Data.Reminders'} />, path: '/reminders' },
  { id: 11, title: <TranslateMessage msgKey={'DashBoard.Leads'} />, path: '/leads' },
  { id: 12, title: <TranslateMessage msgKey={'Common_Data.Opportunities'} />, path: '/leads/opportunities' },
  { id: 13, title: <TranslateMessage msgKey={'DashBoard.Deals'} />, path: '/leads/deals' },
  { id: 20, title: <TranslateMessage msgKey={'Common_Data.Knowledge'} />, path: '/knowledge' },
  { id: 5, title: <TranslateMessage msgKey={'Common_Data.Calendar'} />, path: '/calendar' },
  { id: 21, title: <TranslateMessage msgKey={'Reports.Reports'} />, path: '/reports' },
  { id: 22, title: <TranslateMessage msgKey={'Contracts.Contracts_Title'} />, path: '/contracts' },
  { id: 6, title: <TranslateMessage msgKey={'DealDesk.Library'} />, path: '/deals-library' },
  { id: 23, title: <TranslateMessage msgKey={'DealBuilder.Deals_Builder'} />, path: '/deal-builders' },
  { id: 25, title: <TranslateMessage msgKey={'Common_Data.Settings'} />, path: '/settings' },
  { id: 32, title: <TranslateMessage msgKey={'Reports.Waste'} />, path: '/waste' },
];

// export const AppNavConfig = [
// 	{ id: 1, title: "DashBoard", path: "/dashboard" },
// 	{ id: 2, title: "Blank", path: "/blank" },
// 	{ id: 3, title: "Table Example", path: "/table-example" },
// 	{
// 		id: 4, title: "Multiple Level", submenu: [
// 			{ id: 5, title: "First Level 1", path: "/first-level-1" },
// 			{ id: 6, title: "First Level 2", path: "/first-level-2" },
// 			{
// 				id: 7, title: "First Level 3", submenu: [
// 					{ id: 8, title: "Second Level 1", path: "/second-level-1" },
// 					{ id: 9, title: "Second Level 2", path: "/second-level-2" },
// 					{ id: 10, title: "Second Level 3", path: "/second-level-3" },
// 				]
// 			},
// 		]
// 	},
// 	{ id: 11, title: "Reset Password", path: "/reset/1" },

// ]
