import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Checkbox } from 'antd';
import StructureComponent from './StructureComponent';
import useStore from '../../../../../store';
import { vsmNotify, vsmDealDesk, vsmLeadsList } from '../../../../../config/messages';
import moment from 'moment';
import StructureLibrary from './StructureLibrary';
import debounce from 'lodash/debounce';
import {
  calculateHeight,
  trimExtraCharacters,
} from '../../../../../utils/GlobalFunction';
import AddLibaryTitle from './AddLibaryTitle';
import AutosaveNotifiy from '../component/AutosaveNotifiy';
import { default as EditLeadComponent } from '../../../../../page/LeadListing/component/EditComponentNew';
import { useTranslation } from 'react-i18next';

const Listing = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    LEADSSTORE,
    LEADSSTORE: { updateLeadStatus },
    DEALDESKSTORE,
    DEALDESKSTORE: {
      deal_data,
      editActiveStructureData,
      editActiveScreenData,
      editActiveScrutiniseData,
      setActiveStructureData,
      activeStructureData,
      setEditActiveStructureData,
      setLeftSectionActiveTab,
      setEditActiveScreenData,
      setEditActiveScrutiniseData,
      getDealDetail,
      statusPayloadForShortfall,
      setStatusPayloadForShortfall,
    },
    SSSLEADLIBRARYSTORE: {
      addSssData,
      strucureAutosaveData,
      screenAutosaveData,
      scrutiniseAutosaveData,
      setScrutiniseAutosaveData,
      setScreenAutosaveData,
      setStrucureAutosaveData,
      addUpdateNoteList,
      deleteSssData,
      screenAutosaveUpdated,
      structureAutosaveUpdated,
      scrunitiseAutosaveUpdated,
      setScrunitiseAutosaveUpdated,
      setStructureAutosaveUpdated,
      setScreenAutosaveUpdated,
      dealDeskRightSectionActiveTab,
      fetchListData,
    },
    DEALDESKSTRUCTURELIBRARY: {
      Structure,
      Screen,
      Scrunitise,
      CopiedStructureData,
      CopiedScreenData,
      CopiedScrunitiseData,
      SetStructure,
      SetScrunitise,
      SetScreen,
      setSavestructureform,
      setCopiedStructureData,
      setCopiedScreenData,
      setCopiedScrunitiseData,
      calculateHeightOnEdit,
    },
    DEALDESKNOTESEQUENCE,
    DEALDESKNOTESOUTBOUND: {
      saveOutboundNote,
    },
    TimeTrackerStore,
  } = useStore();

  const { saveType } = props;

  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [sendClick, setSendClick] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const [isSaveToLibrary, setIsSaveToLibrary] = useState(false);
  const [visibleTitleModal, setVisibleTitleModal] = useState(false);
  const [libraryTitle, setLibraryTitle] = useState(null);
  const [classificationId, setClassificationId] = useState(null);
  const [libraryDescriptionInfo, setLibraryDescriptionInfo] = useState(null);
  const [editLeadDrawer, setEditLeadDrawer] = useState(false);
  const [isOverwriteTemplate, setIsOverwriteTemplate] = useState(true);

  const [isAutoSaveCalledBefore, setisAutoSaveCalledBefore] = useState(false); //For Stopping Auto Save when Title Drawer is open

  const INITIAL_HEIGHT = '18px';
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const openViewDrawer = (data) => {
    clearAutosaveTimeOut();
    setViewDrawer(true);
    TimeTrackerStore.setStopForDesk(true);
  };
  const closeViewDrawer = () => {
    TimeTrackerStore.setStopForDesk(false);
    setViewDrawer(false)
  };

  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }

  const [form] = Form.useForm();

  const handleFormChange = useCallback(() => {
    if (saveType === 'structure') {
      form
        .validateFields()
        .then((data) => {
          if (Structure.length > 0) {
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        })
        .catch((e) => {
          if (e.errorFields && e.errorFields.length > 0) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        });
    }
  }, [saveType, form, Structure]);

  const handleScrunitiseFormChange = useCallback(() => {
    if (saveType === 'scrutinise') {
      form
        .validateFields()
        .then((data) => {
          if (Scrunitise.length > 0) {
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        })
        .catch((e) => {
          if (e.errorFields && e.errorFields.length > 0) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        });
    }
  }, [saveType, form, Scrunitise]);

  const handleScreenFormChange = useCallback(() => {
    if (saveType === 'screen') {
      form
        .validateFields()
        .then((data) => {
          if (Screen.length > 0) {
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        })
        .catch((e) => {
          if (e.errorFields && e.errorFields.length > 0) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        });
    }
  }, [saveType, form, Screen]);

  const checkSaveType = useCallback(() => {
    if (saveType === 'screen') {
      return Screen;
    }
    if (saveType === 'structure') {
      return Structure;
    }
    if (saveType === 'scrutinise') {
      return Scrunitise;
    }
  }, [Screen, Structure, Scrunitise, saveType]);

  //----------------------------------  Clear Auto Save/Clear Form/Clear Timer Functions  ------------------------------------------------//
  let dealid = deal_data.lead_information && deal_data.lead_information.id;
  var autosaveTimeoutData = null;

  const onChangeCallSubmit = (data = null, checkboxSelection = null) => {
    let allowAutosave = false;
    allowAutosave = true;

    let timer = null;
    if (saveType === 'structure') {
      timer = localStorage.getItem('structureAutosaveTimeOut');
    } else if (saveType === 'screen') {
      timer = localStorage.getItem('screenAutosaveTimeOut');
    } else if (saveType === 'scrutinise') {
      timer = localStorage.getItem('scrunitiseAutosaveTimeOut');
    }
    if (allowAutosave) {
      if (autosaveTimeoutData || timer) {
        clearTimeout(autosaveTimeoutData || Number(timer));
      }

      let descData = checkSaveType();
      let continueAutosave = true;
      if (descData && descData.length <= 0) {
        if (saveType === 'screen') {
          if (editActiveScreenData) {
            continueAutosave = false;
          } else if (!screenAutosaveUpdated) {
            continueAutosave = false;
          }
        }
        if (saveType === 'structure') {
          if (editActiveStructureData) {
            continueAutosave = false;
          } else if (!structureAutosaveUpdated) {
            continueAutosave = false;
          }
        }

        if (saveType === 'scrutinise') {
          if (editActiveScrutiniseData) {
            continueAutosave = false;
          } else if (!scrunitiseAutosaveUpdated) {
            continueAutosave = false;
          }
        }
      }

      if (continueAutosave) {
        autosaveTimeoutData = setTimeout(() => {
          if (data) {
            handleSubmit(
              {},
              false,
              true,
              {
                descriptionData: data,
              },
              checkboxSelection
            );
          } else {
            handleSubmit({}, false, true, {}, checkboxSelection);
          }
        }, 15000);
        if (saveType === 'screen') {
          localStorage.setItem('screenAutosaveTimeOut', autosaveTimeoutData);
        }
        if (saveType === 'structure') {
          localStorage.setItem('structureAutosaveTimeOut', autosaveTimeoutData);
        }
        if (saveType === 'scrutinise') {
          localStorage.setItem('scrunitiseAutosaveTimeOut', autosaveTimeoutData);
        }
      }
    }
  };

  const clearAutosaveTimeOut = useCallback(() => {
    if (saveType === 'screen') {
      let screenTimeoutCall = localStorage.getItem('screenAutosaveTimeOut');
      if (screenTimeoutCall) {
        clearTimeout(Number(screenTimeoutCall));
        localStorage.removeItem('screenAutosaveTimeOut');
      }
    }
    if (saveType === 'structure') {
      let structureTimeoutCall = localStorage.getItem('structureAutosaveTimeOut');
      if (structureTimeoutCall) {
        clearTimeout(Number(structureTimeoutCall));
        localStorage.removeItem('structureAutosaveTimeOut');
      }
    }
    if (saveType === 'scrutinise') {
      let scrunitiseTimeoutCall = localStorage.getItem('scrunitiseAutosaveTimeOut');
      if (scrunitiseTimeoutCall) {
        clearTimeout(Number(scrunitiseTimeoutCall));
        localStorage.removeItem('scrunitiseAutosaveTimeOut');
      }
    }
  }, [saveType]);
  //----------------------------------  Clear Auto Save/Clear Form/Clear Timer Functions  ------------------------------------------------//

  const getSaveIDBasedOnSaveType = useCallback(() => {
    if (saveType === 'screen') {
      if (statusPayloadForShortfall) {
        return 5;
      } else {
        return 3;
      }
    } else if (saveType === 'structure') {
      return 1;
    } else if (saveType === 'scrutinise') {
      return 4;
    }
  }, [saveType, statusPayloadForShortfall]);

  const scrollToNote = debounce((id) => {
    setLeftSectionActiveTab('5');
    if (document.getElementById(`#n${id}`)) {
      document.getElementById(`#n${id}`).scrollIntoView();
    }
  }, 500);

  const handleSubmit = useCallback(
    (
      data,
      is_post = false,
      isAutoSave = false,
      objData = {},
      checkboxSelection = null
    ) => {
      data = form.getFieldsValue();
      let lastFocusID;
      let lastFocusElement;
      if (isAutoSave) {
        lastFocusID = document.activeElement?.id;
        lastFocusElement = document.activeElement;
      } else {
        clearAutosaveTimeOut();

        if (is_post) {
          data.post = true;
          //setPosting(true);
        } else {
          setSaving(true);
        }
      }
      data.autosave = isAutoSave ? 1 : 0;

      //data.is_saved =sendClick ? 0 : 1;

      if (isAutoSave) {
        data.title = new Date().getTime().toString();
        if (checkboxSelection !== null) {
          data.is_library = checkboxSelection;
        } else {
          data.is_library = isSaveToLibrary;
        }
      } else {
        if (isSaveToLibrary) {
          data.is_library = isSaveToLibrary;
        } else {
          data.title = new Date().getTime().toString();
          data.is_library = isSaveToLibrary;
        }
        // data.title = libraryTitle;
        // data.description_text = libraryDescriptionInfo;
      }

      if (saveType === 'screen') {
        if (!Screen) {
          vsmNotify.error({
            message: `${t('Common_Data.Structure')} ${t('DealDesk.Required')}!`,
          });
          return false;
        }
        if (Screen.length > 0) {
          Screen.map((obj) => {
            if (!obj.children) {
              obj.children = [];
            }
            return obj;
          });
        }
      } else if (saveType === 'structure') {
        if (!Structure) {
          vsmNotify.error({
            message: `${t('DealDesk.Screen')} ${t('DealDesk.Required')}!`,
          });
          return false;
        }
        if (Structure.length > 0) {
          Structure.map((obj) => {
            if (!obj.children) {
              obj.children = [];
            }
            return obj;
          });
        }
      } else if (saveType === 'scrutinise') {
        if (!Scrunitise) {
          vsmNotify.error({
            message: `${t('Common_Data.Scrutinise')} ${t('DealDesk.Required')}!`,
          });
          return false;
        }
        if (Scrunitise.length > 0) {
          Scrunitise.map((obj) => {
            if (!obj.children) {
              obj.children = [];
            }
            return obj;
          });
        }
      }
      let datanew = {
        ...data,
        description: checkSaveType(),
        date: moment(new Date()).format('YYYY-MM-DD'),
        shortfall_type: 'other'
      };

      //-------------USED TO SET DESCRIPTION DATA DUE TO FORCEUPDATE ISSUE ON EXCHANGE------------//

      if (objData && isAutoSave && objData?.descriptionData) {
        datanew.description = objData?.descriptionData;
      }
      //-----------USED TO SET DESCRIPTION DATA DUE TO FORCEUPDATE ISSUE ON EXCHANGE------------//

      datanew.type = getSaveIDBasedOnSaveType();

      if (editActiveStructureData && saveType === 'structure') {
        //structure_id = 0;
        datanew.id = editActiveStructureData.id;
      }

      if (editActiveScreenData && saveType === 'screen') {
        // structure_id = 0;
        datanew.id = editActiveScreenData.id;
      }

      /**Adding Id to the payload of shortfall for updating the prev value of the shortfall */
      if (statusPayloadForShortfall && saveType) {
        datanew.id = deal_data.lead_information?.shortfall?.id;
      }

      if (editActiveScrutiniseData && saveType === 'scrutinise') {
        datanew.id = editActiveScrutiniseData.id;
      }

      datanew.isSaveFromLibrary = 0; //TO LOAD EXISTING AUTOSAVE DATA

      datanew.isOverwriteTemplate = isOverwriteTemplate;
      if (statusPayloadForShortfall && saveType === 'screen') {
        if (DEALDESKSTORE?.rejectionSummaryData?.type === "rejected") {
          datanew.shortfall_type = 'rejected'
          datanew.id = deal_data.lead_information?.rejected_shortfall?.id;
        } else if (DEALDESKSTORE?.rejectionSummaryData?.type === "failed") {
          datanew.shortfall_type = 'failed'
          datanew.id = deal_data.lead_information?.failed_shortfall?.id;
        } else {
          datanew.shortfall_type = 'other'
        }
      }

      addSssData(dealid, datanew)
        .then((data) => {
          if (isAutoSave) {
            if (
              editActiveStructureData ||
              editActiveScreenData ||
              editActiveScrutiniseData
            ) {
              addUpdateNoteList(data, true);
            }
          } else {
            if (visibleTitleModal) {
              setVisibleTitleModal(false);
            }

            if (
              editActiveStructureData ||
              editActiveScreenData ||
              editActiveScrutiniseData
            ) {
              vsmNotify.success({
                message:
                  saveType === 'screen'
                    ? vsmDealDesk.screen.updateSuccess
                    : saveType === 'structure'
                      ? vsmDealDesk.structure.updateSuccess
                      : vsmDealDesk.scrutinise.updateSuccess,
              });
            } else {
              if (sendClick) {
                vsmNotify.success({
                  message:
                    saveType === 'screen'
                      ? datanew.type === 5
                        ? vsmDealDesk.shortfall.sentSuccess
                        : vsmDealDesk.screen.sentSuccess
                      : saveType === 'structure'
                        ? vsmDealDesk.structure.sentSuccess
                        : vsmDealDesk.scrutinise.sentSuccess,
                });
              } else {
                vsmNotify.success({
                  message:
                    saveType === 'screen'
                      ? datanew.type === 5
                        ? vsmDealDesk.shortfall.saveSuccess
                        : vsmDealDesk.screen.saveSuccess
                      : saveType === 'structure'
                        ? vsmDealDesk.structure.saveSuccess
                        : vsmDealDesk.scrutinise.saveSuccess,
                });
              }
            }

            if (dealDeskRightSectionActiveTab === 'structure') {
              if (editActiveStructureData && datanew.id) {
                setActiveStructureData(null);
                setEditActiveStructureData(null);
                SetStructure([]);
                setCopiedStructureData(null);
                setStrucureAutosaveData(null);
                /* On update left side list update */
                addUpdateNoteList(datanew, true);
                if (structureAutosaveUpdated.id === datanew.id) {
                  setStructureAutosaveUpdated(null);
                }
              } else if (datanew.id && structureAutosaveUpdated) {
                setStrucureAutosaveData(structureAutosaveUpdated);
              } else {
                SetStructure([]);
                setCopiedStructureData(null);
                if (strucureAutosaveData || structureAutosaveUpdated) {
                  setStrucureAutosaveData(null);
                  setStructureAutosaveUpdated(null);
                }
              }
              setLeftSectionActiveTab('1'); //LEFT SECTION TAB SELECTION ON SUCCESS
            }

            if (dealDeskRightSectionActiveTab === 'screen') {
              if (editActiveScreenData && datanew.id) {
                setEditActiveScreenData(null);
                setScreenAutosaveData(null);
                SetScreen([]);
                setCopiedScreenData(null);
                /* On update left side list update */
                addUpdateNoteList(datanew, true);
                if (screenAutosaveUpdated.id === datanew.id) {
                  setScreenAutosaveUpdated(null);
                }
              } else if (datanew.id && screenAutosaveUpdated) {
                setScreenAutosaveData(screenAutosaveUpdated);
              } else {
                SetScreen([]);
                setCopiedScreenData(null);
                if (screenAutosaveData || screenAutosaveUpdated) {
                  setScreenAutosaveData(null);
                  setScreenAutosaveUpdated(null);
                }
              }
              setLeftSectionActiveTab('6'); //LEFT SECTION TAB SELECTION ON SUCCESS
            }

            if (dealDeskRightSectionActiveTab === 'scrutinise') {
              if (editActiveScrutiniseData && datanew.id) {
                setEditActiveScrutiniseData(null);
                SetScrunitise([]);
                setCopiedScrunitiseData(null);
                setScrutiniseAutosaveData(null);
                /* On update left side list update */
                addUpdateNoteList(datanew, true);
                if (scrunitiseAutosaveUpdated.id === datanew.id) {
                  setScrunitiseAutosaveUpdated(null);
                }
              } else if (datanew.id && scrunitiseAutosaveUpdated) {
                setScrutiniseAutosaveData(scrunitiseAutosaveUpdated);
              } else {
                SetScrunitise([]);
                setCopiedScrunitiseData(null);
                if (scrutiniseAutosaveData || scrunitiseAutosaveUpdated) {
                  setScrutiniseAutosaveData(null);
                  setScrunitiseAutosaveUpdated(null);
                }
              }
              setLeftSectionActiveTab('7'); //LEFT SECTION TAB SELECTION ON SUCCESS
            }

            setDisabled(true);

            form.resetFields();

            //------------------START:TO ADD UPDATE LIST WITHOUT API CALL--------------------//
            if (isOverwriteTemplate) {
              if (activeStructureData && activeStructureData?.id === data?.id) {
                setActiveStructureData(null);
              }
              fetchListData(deal_data?.lead_information?.id, true);
            } else {
              addUpdateNoteList(data, datanew.id ? true : false);
            }
            //------------------END:TO ADD UPDATE LIST WITHOUT API CALL--------------------//

            //----------------START:TO SCROLL TO LATEST ADDED BOX-----//
            if (saveType && document.getElementById(`${saveType}_ScrollToLatest`)) {
              document.getElementById(`${saveType}_ScrollToLatest`).scrollIntoView();
            }
            //----------------END:TO SCROLL TO LATEST ADDED BOX-----//
            setIsOverwriteTemplate(true);

            // ----------------- Shortfall Note added -------------------//
            if (datanew.type === 5 && statusPayloadForShortfall) {
              updateLeadStatus(dealid, statusPayloadForShortfall).then(() => {
                vsmNotify.success({
                  message: vsmLeadsList.successStatusUpdate,
                });
                SetScreen([]);
                setScreenAutosaveData(null);
                setScreenAutosaveUpdated(null);
                setCopiedScreenData(null);
                setStatusPayloadForShortfall(null);
                getDealDetail(dealid);
                AUTH.fetchLeftmenuCount();
              });
            }
            // ----------------------------------------------------------//
          }
        })
        .catch((e) => {
          if (e.errors) {
            let structure_description_error = 0;
            e.errors.forEach((x) => {
              if (x.name.indexOf('.') !== -1) {
                x.name = x.name.split('.');
                x.name.forEach((e, i) => {
                  if (e === 'structure_description') {
                    structure_description_error = 1;
                  }
                  if (!isNaN(parseInt(x.name[i]))) {
                    x.name[i] = parseInt(x.name[i]);
                  }
                });
              }
            });
            form.setFields(e.errors);
            if (structure_description_error) {
              vsmNotify.error({
                message: `${t('Common_Data.Structure')} ${t('Common_Data.Description')} ${t(
                  'DealDesk.Required'
                )}!`,
              });
            }
          }
        })
        .finally(() => {
          if (!isAutoSave) {
            setIsSaveToLibrary(false);
            setLibraryTitle(null);
            setClassificationId(null);
            setLibraryDescriptionInfo(null);
          }
          setSaving(false);

          //-------------------------START: TO FOCUS INPUT IN CASE OF AUTOSAVE---------------------------------//
          if (isAutoSave && lastFocusID?.includes(`${saveType}_innerContent_`)) {
            let elementID = document.getElementById(lastFocusID);
            elementID.focus();
            let len = lastFocusElement?.selectionStart;
            if (len) {
              elementID.setSelectionRange(len, len);
            }
          }
          //-------------------------END: TO FOCUS INPUT IN CASE OF AUTOSAVE---------------------------------//
        });
      if (DEALDESKSTORE.rejectionSummaryData?.summary) {
        if (statusPayloadForShortfall && saveType === "screen" && !isAutoSave) {
          let modifiedRejectionSummaryData = null
          // For REJECTED Note //
          if (DEALDESKSTORE?.rejectionSummaryData?.type === "rejected") {
            modifiedRejectionSummaryData = `<rejectionSummary>${DEALDESKSTORE.rejectionSummaryData?.reason}<br /><br />${DEALDESKSTORE.rejectionSummaryData?.summary}</rejectionSummary>`
          }
          // For FAILED  Note //
          else if (DEALDESKSTORE?.rejectionSummaryData?.type === "failed") {
            modifiedRejectionSummaryData = `<rejectionSummary>${DEALDESKSTORE.rejectionSummaryData?.reason}<br /><br />${DEALDESKSTORE.rejectionSummaryData?.summary}</rejectionSummary>`
          }
          let payload = {
            "notes_flag": 2,
            "notes_description": modifiedRejectionSummaryData,
          }
          saveOutboundNote(dealid, payload).then((res) => {
            if (res && res?.data) {
              DEALDESKNOTESEQUENCE.addUpdateNoteList(res?.data, false);
              scrollToNote(res?.data?.id);
            }
          }
          )
        }
      }
    },
    [
      isOverwriteTemplate,
      getSaveIDBasedOnSaveType,
      dealDeskRightSectionActiveTab,
      Screen,
      SetScreen,
      SetStructure,
      Structure,
      addUpdateNoteList,
      dealid,
      editActiveScreenData,
      editActiveStructureData,
      //fetchStructureNotes,
      form,
      isSaveToLibrary,
      //saveStructureData,
      saveType,
      sendClick,
      setActiveStructureData,
      setCopiedStructureData,
      setCopiedScreenData,
      setEditActiveScreenData,
      setEditActiveStructureData,
      setLeftSectionActiveTab,
      setScreenAutosaveData,
      setStrucureAutosaveData,
      setScreenAutosaveUpdated,
      setStructureAutosaveUpdated,
      strucureAutosaveData,
      screenAutosaveData,
      addSssData,
      visibleTitleModal,
      structureAutosaveUpdated,
      screenAutosaveUpdated,
      clearAutosaveTimeOut,
      fetchListData,
      deal_data,
      activeStructureData,
      t,
      Scrunitise,
      SetScrunitise,
      checkSaveType,
      editActiveScrutiniseData,
      scrunitiseAutosaveUpdated,
      scrutiniseAutosaveData,
      setCopiedScrunitiseData,
      setScrunitiseAutosaveUpdated,
      setScrutiniseAutosaveData,
      setEditActiveScrutiniseData,
      getDealDetail,
      AUTH,
      statusPayloadForShortfall,
      setStatusPayloadForShortfall,
      updateLeadStatus,
      saveOutboundNote,
      DEALDESKSTORE.rejectionSummaryData,
      scrollToNote,
      DEALDESKNOTESEQUENCE,
    ]
  );

  useEffect(() => {
    handleFormChange();
  }, [Structure, handleFormChange]);

  useEffect(() => {
    handleScreenFormChange();
  }, [Screen, handleScreenFormChange]);

  useEffect(() => {
    handleScrunitiseFormChange();
  }, [Scrunitise, handleScrunitiseFormChange]);

  useEffect(() => {
    setSavestructureform(form);
    return function cleanup() {
      SetStructure([]);
      SetScreen([]);
      SetScrunitise([]);
      clearAutosaveTimeOut();
    };
  }, [
    form,
    setSavestructureform,
    SetScreen,
    SetStructure,
    SetScrunitise,
    clearAutosaveTimeOut,
  ]);

  useEffect(() => {
    if (dealDeskRightSectionActiveTab && dealDeskRightSectionActiveTab === 'screen') {
      if (screenAutosaveData) {
        SetScreen(screenAutosaveData.description);
        setIsSaveToLibrary(screenAutosaveData?.is_library);
        form.setFieldsValue({
          title: screenAutosaveData?.title,
          classification_id: screenAutosaveData?.classification_id,
          description_text: screenAutosaveData?.description_text,
        });
      }
      if (editActiveScreenData) {
        clearAutosaveTimeOut();
        SetScreen(editActiveScreenData.description);
        setIsSaveToLibrary(editActiveScreenData?.is_library);
        form.setFieldsValue({
          title: editActiveScreenData?.is_library
            ? editActiveScreenData?.title
            : deal_data?.lead_information?.opportunity_name,
          classification_id: editActiveScreenData?.is_library
            ? editActiveScreenData?.classification_id
            : deal_data?.lead_information?.classification_id,
          description_text: editActiveScreenData?.description_text,
        });
      }

      if (editActiveScreenData || CopiedScreenData || screenAutosaveData || statusPayloadForShortfall) {
        let DataToProcess = null;

        if (CopiedScreenData) {
          DataToProcess = CopiedScreenData;
        } else {
          if (screenAutosaveData) {
            DataToProcess = screenAutosaveData?.description;
          }

          if (editActiveScreenData?.description) {
            DataToProcess = editActiveScreenData?.description;
          }

          // if (statusPayloadForShortfall && deal_data.lead_information?.rejected_shortfall?.description) {
          //   DataToProcess = deal_data.lead_information?.rejected_shortfall?.description;
          // }
          if (statusPayloadForShortfall && statusPayloadForShortfall.statusType) {
            if (statusPayloadForShortfall.statusType === "rejected") {
              DataToProcess = deal_data.lead_information?.rejected_shortfall?.description;
            }
            if (statusPayloadForShortfall.statusType === "failed") {
              DataToProcess = deal_data.lead_information?.failed_shortfall?.description;
            }
          }
        }

        if (DataToProcess) {
          let lengthLimit = 170;
          if (AUTH?.user?.bonus_figures) {
            if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
              lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
            }
          }

          calculateHeightOnEdit(DataToProcess).then(
            debounce(() => {
              DataToProcess &&
                DataToProcess.forEach((obj, index) => {
                  obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

                  obj.height = calculateHeight(
                    `${dealDeskRightSectionActiveTab}_innerContent_textarea_${obj.item_key}`,
                    INITIAL_HEIGHT,
                    obj.detail,
                    true
                  );

                  if (obj.children && obj.children.length > 0) {
                    obj.children.forEach((obj1, index) => {
                      obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                      obj1.height = calculateHeight(
                        `${dealDeskRightSectionActiveTab}_innerContent_textarea_${obj1.item_key}`,
                        INITIAL_HEIGHT,
                        obj1.detail,
                        true
                      );
                      if (obj1.children && obj1.children.length > 0) {
                        obj1.children.forEach((obj2, index) => {
                          obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                          obj2.height = calculateHeight(
                            `${dealDeskRightSectionActiveTab}_innerContent_textarea_${obj2.item_key}`,
                            INITIAL_HEIGHT,
                            obj2.detail,
                            true
                          );
                        });
                      }
                    });
                  }
                });
            }, 200)
          );
          SetScreen(DataToProcess);
        }
      }
    }
    if (dealDeskRightSectionActiveTab && dealDeskRightSectionActiveTab === 'structure') {
      if (strucureAutosaveData) {
        SetStructure(strucureAutosaveData.description);
        setIsSaveToLibrary(strucureAutosaveData?.is_library);
        form.setFieldsValue({
          title: strucureAutosaveData?.title,
          classification_id: strucureAutosaveData?.classification_id,
          description_text: strucureAutosaveData?.description_text,
        });
      }
      if (editActiveStructureData) {
        clearAutosaveTimeOut();
        SetStructure(editActiveStructureData.description);
        setIsSaveToLibrary(editActiveStructureData?.is_library);
        form.setFieldsValue({
          title: editActiveStructureData?.is_library
            ? editActiveStructureData?.title
            : deal_data?.lead_information?.opportunity_name,
          description_text: editActiveStructureData?.description_text,
        });
      }

      if (editActiveStructureData || CopiedStructureData || strucureAutosaveData) {
        let DataToProcess = null;

        if (CopiedStructureData) {
          DataToProcess = CopiedStructureData;
        } else {
          if (strucureAutosaveData) {
            DataToProcess = strucureAutosaveData?.description;
          }
          if (editActiveStructureData?.description) {
            DataToProcess = editActiveStructureData?.description;
          }
        }

        if (DataToProcess) {
          let lengthLimit = 170;
          if (AUTH?.user?.bonus_figures) {
            if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
              lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
            }
          }

          calculateHeightOnEdit(DataToProcess).then(
            debounce(() => {
              DataToProcess &&
                DataToProcess.forEach((obj, index) => {
                  obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

                  obj.height = calculateHeight(
                    `${dealDeskRightSectionActiveTab}_innerContent_textarea_${obj.item_key}`,
                    INITIAL_HEIGHT,
                    obj.detail,
                    true
                  );

                  if (obj.children && obj.children.length > 0) {
                    obj.children.forEach((obj1, index) => {
                      obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                      obj1.height = calculateHeight(
                        `${dealDeskRightSectionActiveTab}_innerContent_textarea_${obj1.item_key}`,
                        INITIAL_HEIGHT,
                        obj1.detail,
                        true
                      );
                      if (obj1.children && obj1.children.length > 0) {
                        obj1.children.forEach((obj2, index) => {
                          obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                          obj2.height = calculateHeight(
                            `${dealDeskRightSectionActiveTab}_innerContent_textarea_${obj2.item_key}`,
                            INITIAL_HEIGHT,
                            obj2.detail,
                            true
                          );
                        });
                      }
                    });
                  }
                });
            }, 200)
          );
          SetStructure(DataToProcess);
        }
      }
    }
    if (dealDeskRightSectionActiveTab && dealDeskRightSectionActiveTab === 'scrutinise') {
      if (scrutiniseAutosaveData) {
        SetScrunitise(scrutiniseAutosaveData.description);
        setIsSaveToLibrary(scrutiniseAutosaveData?.is_library);
        form.setFieldsValue({
          title: scrutiniseAutosaveData?.title,
          classification_id: scrutiniseAutosaveData?.classification_id,
          description_text: scrutiniseAutosaveData?.description_text,
        });
      }
      if (editActiveScrutiniseData) {
        clearAutosaveTimeOut();
        SetScrunitise(editActiveScrutiniseData.description);
        setIsSaveToLibrary(editActiveScrutiniseData?.is_library);
        form.setFieldsValue({
          title: editActiveScrutiniseData?.is_library
            ? editActiveScrutiniseData?.title
            : deal_data?.lead_information?.opportunity_name,
          classification_id: editActiveScrutiniseData?.is_library
            ? editActiveScrutiniseData?.classification_id
            : deal_data?.lead_information?.classification_id,
          description_text: editActiveScrutiniseData?.description_text,
        });
      }

      if (editActiveScrutiniseData || CopiedScrunitiseData || scrutiniseAutosaveData) {
        let DataToProcess = null;

        if (CopiedScrunitiseData) {
          DataToProcess = CopiedScrunitiseData;
        } else {
          if (scrutiniseAutosaveData) {
            DataToProcess = scrutiniseAutosaveData?.description;
          }
          if (editActiveScrutiniseData?.description) {
            DataToProcess = editActiveScrutiniseData?.description;
          }
        }

        if (DataToProcess) {
          let lengthLimit = 170;
          if (AUTH?.user?.bonus_figures) {
            if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
              lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
            }
          }

          calculateHeightOnEdit(DataToProcess).then(
            debounce(() => {
              DataToProcess &&
                DataToProcess.forEach((obj, index) => {
                  obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

                  obj.height = calculateHeight(
                    `${dealDeskRightSectionActiveTab}_innerContent_textarea_${obj.item_key}`,
                    INITIAL_HEIGHT,
                    obj.detail,
                    true
                  );

                  if (obj.children && obj.children.length > 0) {
                    obj.children.forEach((obj1, index) => {
                      obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                      obj1.height = calculateHeight(
                        `${dealDeskRightSectionActiveTab}_innerContent_textarea_${obj1.item_key}`,
                        INITIAL_HEIGHT,
                        obj1.detail,
                        true
                      );
                      if (obj1.children && obj1.children.length > 0) {
                        obj1.children.forEach((obj2, index) => {
                          obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                          obj2.height = calculateHeight(
                            `${dealDeskRightSectionActiveTab}_innerContent_textarea_${obj2.item_key}`,
                            INITIAL_HEIGHT,
                            obj2.detail,
                            true
                          );
                        });
                      }
                    });
                  }
                });
            }, 200)
          );
          SetScrunitise(DataToProcess);
        }
      }
    }
  }, [
    form,
    deal_data,
    CopiedScrunitiseData,
    SetScrunitise,
    editActiveScrutiniseData,
    scrutiniseAutosaveData,
    dealDeskRightSectionActiveTab,
    screenAutosaveData,
    editActiveScreenData,
    CopiedScreenData,
    editActiveStructureData,
    CopiedStructureData,
    strucureAutosaveData,
    AUTH?.user,
    calculateHeightOnEdit,
    SetScreen,
    SetStructure,
    setIsSaveToLibrary,
    clearAutosaveTimeOut,
    statusPayloadForShortfall,
    deal_data.lead_information?.shortfall?.description,
  ]);

  // --------  Shortfall If any edit data exists then perform cancel action  -------- //
  useEffect(() => {
    if (statusPayloadForShortfall && saveType === 'screen' && editActiveScreenData) {
      setCopiedScreenData(null);
      SetScreen([]);
      setEditActiveScreenData(null);
      setSaving(false);
      setDisabled(true);
      setSendClick(false);
      setIsSaveToLibrary(false);
      setLibraryTitle(null);
      setClassificationId(null);
      setVisibleTitleModal(false);
      setLibraryDescriptionInfo(null);
      setIsOverwriteTemplate(true);
    }
    if (statusPayloadForShortfall && saveType === 'screen') {
      SetScreen(deal_data.lead_information?.shortfall?.description)
      if (statusPayloadForShortfall && statusPayloadForShortfall.statusType) {
        if (statusPayloadForShortfall.statusType === "rejected") {
          SetScreen(deal_data.lead_information?.rejected_shortfall?.description);
        }
        if (statusPayloadForShortfall.statusType === "failed") {
          SetScreen(deal_data.lead_information?.failed_shortfall?.description);
        }
      }
    }
  }, [
    statusPayloadForShortfall,
    editActiveScreenData,
    saveType,
    SetScreen,
    setCopiedScreenData,
    setEditActiveScreenData,
    deal_data.lead_information?.shortfall?.description,
    deal_data.lead_information?.rejected_shortfall?.description,
    deal_data.lead_information?.failed_shortfall?.description,
  ]);

  //----------------  Shortfall If any edit data exists then perform cancel action  ------------//

  const clearFormValues = () => {
    form.resetFields();
    if (saveType && saveType === 'screen') {
      setCopiedScreenData(null);
      SetScreen([]);
      setEditActiveScreenData(null);
    } else if (saveType === 'structure') {
      setCopiedStructureData(null);
      SetStructure([]);
      setEditActiveStructureData(null);
    } else if (saveType === 'scrutinise') {
      setCopiedScrunitiseData(null);
      SetScrunitise([]);
      setEditActiveScrutiniseData(null);
    }
    setSaving(false);
    setDisabled(true);
    setSendClick(false);
    setIsSaveToLibrary(false);
    setLibraryTitle(null);
    setClassificationId(null);
    setVisibleTitleModal(false);
    setLibraryDescriptionInfo(null);
    setIsOverwriteTemplate(true);
    // setPosting(false);
  };

  const removeAutosaveData = (id) => {
    deleteSssData(id, { is_deal_desk: true }).then((data) => {
      if (saveType === 'screen') {
        setScreenAutosaveData(null);
        setScreenAutosaveUpdated(null);
      } else if (saveType === 'structure') {
        setStrucureAutosaveData(null);
        setStructureAutosaveUpdated(null);
      } else if (saveType === 'scrutinise') {
        setScrutiniseAutosaveData(null);
        setScrunitiseAutosaveUpdated(null);
      }
      clearFormValues();
    });
  };

  //--------------------------------------------------START: EDIT SOLD NAME LOGIC --------------------------------------------------------------//
  const openEditLeadDrawer = () => {
    TimeTrackerStore.setStopForDesk(true);
    vsmNotify.error({
      message: `${t('DealDesk.Sold_Name_Blank')}. ${t('DealDesk.Value_To_Save')}, ${t(
        'DealDesk.Structure_Schedule'
      )}`,
    });

    LEADSSTORE.setEditValues({ id: deal_data?.lead_information?.id });
    setEditLeadDrawer(true);

    setisAutoSaveCalledBefore(false);
    if (autosaveTimeoutData > 0) {
      setisAutoSaveCalledBefore(true);
      clearAutosaveTimeOut();
    }
  };
  const closeEditLeadDrawer = () => {
    TimeTrackerStore.setStopForDesk(false);
    setEditLeadDrawer(false);
    getDealDetail(deal_data?.lead_information?.id).then((data) => {
      if (data?.data?.lead_information?.opportunity_name) {
        setLibraryTitle(data?.data?.lead_information?.opportunity_name);
        form.setFieldsValue({
          title: data?.data?.lead_information?.opportunity_name,
        });
        setVisibleTitleModal(true);
      }
    });
  };
  //--------------------------------------------------END: EDIT SOLD NAME LOGIC --------------------------------------------------------------//

  //-------------------------------------------------- Library Title Drawer --------------------------------------------------------------//
  const openAddTitleModal = () => {
    setVisibleTitleModal(true);
    setisAutoSaveCalledBefore(false);
    let timer = null;
    if (saveType === 'structure') {
      timer = localStorage.getItem('structureAutosaveTimeOut');
    }
    if (saveType === 'screen') {
      timer = localStorage.getItem('screenAutosaveTimeOut');
    }
    if (saveType === 'scrutinise') {
      timer = localStorage.getItem('scrunitiseAutosaveTimeOut');
    }

    if (timer) {
      setisAutoSaveCalledBefore(true);
      clearAutosaveTimeOut();
    }
  };

  const closeTitleModal = () => {
    setVisibleTitleModal(false);

    if (isAutoSaveCalledBefore) {
      onChangeCallSubmit();
      setisAutoSaveCalledBefore(false);
    }
  };

  const getFormIDBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return 'ScreenForm';
    } else if (saveType === 'structure') {
      return 'StructureForm';
    } else if (saveType === 'scrutinise') {
      return 'ScrutiniseForm';
    }
  };

  const setDataBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return SetScreen;
    } else if (saveType === 'structure') {
      return SetStructure;
    } else if (saveType === 'scrutinise') {
      return SetScrunitise;
    }
  };

  const getFormChangeBasedOnSaveType = () => {
    if (saveType === 'screen') {
      return handleScreenFormChange;
    } else if (saveType === 'structure') {
      return handleFormChange;
    } else if (saveType === 'scrutinise') {
      return handleScrunitiseFormChange;
    }
  };

  //-------------------------------------------------- Library Title Drawer --------------------------------------------------------------//

  return (
    <div>
      <Form id={getFormIDBasedOnSaveType()} form={form} onFinish={handleSubmit}>
        <StructureComponent
          MainStructure={checkSaveType()}
          Structure={checkSaveType()}
          SetStructure={setDataBasedOnSaveType()}
          forceUpdate={forceUpdate}
          editable={true}
          openViewDrawer={openViewDrawer}
          handleFormChange={getFormChangeBasedOnSaveType()}
          saveType={saveType}
          autoSaveData={onChangeCallSubmit}
          INITIAL_HEIGHT={INITIAL_HEIGHT}
        />
        <div className='footer StructureComponentFooter'>
          <div>
            <Checkbox
              checked={
                // statusPayloadForShortfall && saveType === 'screen'
                //   ? true
                //   : isSaveToLibrary
                isSaveToLibrary
              }
              disabled={statusPayloadForShortfall && saveType === 'screen' ? true : false}
              onChange={(e) => {
                onChangeCallSubmit(null, e.target.checked);
                setIsSaveToLibrary(e.target.checked);
              }}
            >
              {t('DealDesk.Save_Library')}
            </Checkbox>
          </div>
          <div>
            <AutosaveNotifiy saveType={saveType} />

            {(dealDeskRightSectionActiveTab === 'structure' && editActiveStructureData) ||
              (dealDeskRightSectionActiveTab === 'screen' && editActiveScreenData) ||
              (dealDeskRightSectionActiveTab === 'scrutinise' &&
                editActiveScrutiniseData) ? (
              <>
                {dealDeskRightSectionActiveTab === 'screen' && editActiveScreenData ? (
                  <>
                    <Button
                      type='primary'
                      shape='round'
                      className='mr-5'
                      //htmlType='submit'
                      onClick={() => {
                        setEditActiveScreenData(null);
                        clearFormValues();
                      }}
                    >
                      {t('Common_Data.Cancel')}
                    </Button>

                    <Button
                      shape='round'
                      size='medium'
                      className='mr-5'
                      //
                      loading={saving}
                      onClick={() => {
                        if (isSaveToLibrary) {
                          if (deal_data?.lead_information?.opportunity_name) {
                            setVisibleTitleModal(true);
                          } else {
                            openEditLeadDrawer();
                          }
                        } else {
                          setSendClick(false);
                          handleSubmit();
                        }
                      }}
                      disabled={disabled}
                    >
                      {t('Common_Data.Update')}
                    </Button>
                  </>
                ) : null}

                {dealDeskRightSectionActiveTab === 'structure' &&
                  editActiveStructureData ? (
                  <>
                    <Button
                      type='primary'
                      shape='round'
                      className='mr-5'
                      //htmlType='submit'
                      onClick={() => {
                        //resetActiveStructureNoteEditDated();
                        setActiveStructureData(null);
                        setEditActiveStructureData(null);
                        clearFormValues();
                      }}
                    >
                      {t('Common_Data.Cancel')}
                    </Button>

                    <Button
                      shape='round'
                      size='medium'
                      className='mr-5'
                      //
                      loading={saving}
                      onClick={() => {
                        if (isSaveToLibrary) {
                          if (deal_data?.lead_information?.opportunity_name) {
                            setVisibleTitleModal(true);
                          } else {
                            openEditLeadDrawer();
                          }
                        } else {
                          setSendClick(false);
                          handleSubmit();
                        }
                      }}
                      disabled={disabled}
                    >
                      {t('Common_Data.Update')}
                    </Button>
                  </>
                ) : null}

                {dealDeskRightSectionActiveTab === 'scrutinise' &&
                  editActiveScrutiniseData ? (
                  <>
                    <Button
                      type='primary'
                      shape='round'
                      className='mr-5'
                      //htmlType='submit'
                      onClick={() => {
                        setEditActiveScrutiniseData(null);
                        clearFormValues();
                      }}
                    >
                      {t('Common_Data.Cancel')}
                    </Button>

                    <Button
                      shape='round'
                      size='medium'
                      className='mr-5'
                      //
                      loading={saving}
                      onClick={() => {
                        if (isSaveToLibrary) {
                          if (deal_data?.lead_information?.opportunity_name) {
                            setVisibleTitleModal(true);
                          } else {
                            openEditLeadDrawer();
                          }
                        } else {
                          setSendClick(false);
                          handleSubmit();
                        }
                      }}
                      disabled={disabled}
                    >
                      {t('Common_Data.Update')}
                    </Button>
                  </>
                ) : null}
              </>
            ) : (
              <>
                {saveType === 'screen' &&
                  screenAutosaveUpdated &&
                  !screenAutosaveUpdated.draft_description ? (
                  <Button
                    type='primary'
                    shape='round'
                    className='mr-5'
                    disabled={false}
                    onClick={() => {
                      if (saveType === 'screen' && screenAutosaveUpdated) {
                        removeAutosaveData(screenAutosaveUpdated.id);
                      }
                    }}
                  >
                    {t('Common_Data.Clear')}
                  </Button>
                ) : saveType === 'screen' && CopiedScreenData ? (
                  <Button
                    type='primary'
                    shape='round'
                    className='mr-5'
                    onClick={() => {
                      clearAutosaveTimeOut();
                      setCopiedScreenData(null);
                      clearFormValues();
                    }}
                  >
                    {t('Common_Data.Clear')}
                  </Button>
                ) : null}

                {saveType === 'scrutinise' &&
                  scrunitiseAutosaveUpdated &&
                  !scrunitiseAutosaveUpdated.draft_description ? (
                  <Button
                    type='primary'
                    shape='round'
                    className='mr-5'
                    disabled={false}
                    onClick={() => {
                      if (saveType === 'scrutinise' && scrunitiseAutosaveUpdated) {
                        removeAutosaveData(scrunitiseAutosaveUpdated.id);
                      }
                    }}
                  >
                    {t('Common_Data.Clear')}
                  </Button>
                ) : saveType === 'scrutinise' && CopiedScrunitiseData ? (
                  <Button
                    type='primary'
                    shape='round'
                    className='mr-5'
                    onClick={() => {
                      clearAutosaveTimeOut();
                      setCopiedScrunitiseData(null);
                      clearFormValues();
                    }}
                  >
                    {t('Common_Data.Clear')}
                  </Button>
                ) : null}

                {saveType === 'structure' &&
                  structureAutosaveUpdated &&
                  !structureAutosaveUpdated.draft_description ? (
                  <Button
                    type='primary'
                    shape='round'
                    className='mr-5'
                    disabled={false}
                    onClick={() => {
                      if (saveType === 'structure' && structureAutosaveUpdated) {
                        removeAutosaveData(structureAutosaveUpdated.id);
                      }
                    }}
                  >
                    {t('Common_Data.Clear')}
                  </Button>
                ) : saveType === 'structure' && CopiedStructureData ? (
                  <Button
                    type='primary'
                    shape='round'
                    className='mr-5'
                    onClick={() => {
                      clearAutosaveTimeOut();
                      setCopiedStructureData(null);
                      clearFormValues();
                    }}
                  >
                    {t('Common_Data.Clear')}
                  </Button>
                ) : null}

                {statusPayloadForShortfall && saveType === 'screen' ? (
                  <Button
                    shape='round'
                    size='medium'
                    className='mr-5'
                    loading={saving}
                    disabled={
                      saveType === 'screen' && Screen && Screen.length > 0 ? false : true
                    }
                    onClick={() => {
                      if (isSaveToLibrary) {
                        if (deal_data?.lead_information?.opportunity_name) {
                          form.setFieldsValue({
                            title: deal_data?.lead_information?.opportunity_name,
                            classification_id: deal_data?.lead_information?.classification_id,
                          });
                          openAddTitleModal();
                        } else {
                          openEditLeadDrawer();
                        }
                      } else {
                        setSendClick(false);
                        handleSubmit();
                      }
                    }}
                  >
                    {t('LeadListing.Change_Status')}
                  </Button>
                ) : (
                  <Button
                    shape='round'
                    size='medium'
                    className='mr-5'
                    //htmlType='submit'
                    loading={saving}
                    onClick={() => {
                      if (isSaveToLibrary) {
                        if (deal_data?.lead_information?.opportunity_name) {
                          form.setFieldsValue({
                            title: deal_data?.lead_information?.opportunity_name,
                            classification_id: deal_data?.lead_information?.classification_id,
                          });
                          openAddTitleModal();
                        } else {
                          openEditLeadDrawer();
                        }
                      } else {
                        setSendClick(false);
                        handleSubmit();
                      }
                    }}
                    disabled={
                      (saveType === 'structure' && Structure && Structure.length > 0) ||
                        (saveType === 'screen' && Screen && Screen.length > 0) ||
                        (saveType === 'scrutinise' && Scrunitise && Scrunitise.length > 0)
                        ? false
                        : disabled
                    }
                  >
                    {t('Common_Data.Save')}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        {/** Pop up for Save in Library */}
        <AddLibaryTitle
          visible={visibleTitleModal}
          close={() => closeTitleModal()}
          libraryTitle={libraryTitle}
          classificationId={classificationId}
          handleChange={handleFormChange}
          handleSubmit={handleSubmit}
          libraryDescriptionInfo={libraryDescriptionInfo}
          setLibraryDescriptionInfo={setLibraryDescriptionInfo}
          setLibraryTitle={setLibraryTitle}
          setClassificationId={setClassificationId}
          parentForm={form}
          saveType={saveType}
          autoSaveData={onChangeCallSubmit}
          setIsOverwriteTemplate={setIsOverwriteTemplate}
          isOverwriteTemplate={isOverwriteTemplate}
        />
      </Form>
      {/** Open when clicked on View Library button */}
        <StructureLibrary
          visible={viewDrawer}
          handleFormChange={handleFormChange}
          close={closeViewDrawer}
          saveType={statusPayloadForShortfall && saveType === 'screen' ? 'shortfall' : saveType}
      />
      {/* Open Edit Lead when there is no SOLD Name */}
      <EditLeadComponent
        visible={editLeadDrawer}
        close={closeEditLeadDrawer}
        isFromDealDesk={true}
      />
    </div>
  );
});

export default Listing;
