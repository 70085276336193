import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';

import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import { ActionRenderer } from './GridComponent';
import { vsmCommon } from '../../../config/messages';
import GridConfig from '../../../config/GridConfig';

import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    SAVEDFILTERSTORE,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  const { openViewDrawer, openDeleteDrawer, openEditDrawer, onSwitchChange } = props;

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Filter')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Filter')} ${t('Common_Data.Name')}`,
        field: 'filter',
        tooltipField: 'value',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Page')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Page')} ${t('Common_Data.Name')}`,
        field: 'page',
        tooltipValueGetter: (params) =>
          params?.data?.page && SAVEDFILTERSTORE.filterPageList[params?.data?.page],
        valueGetter: (params) =>
          params?.data?.page && SAVEDFILTERSTORE.filterPageList[params?.data?.page],
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            params.success([...SAVEDFILTERSTORE.filterPageListValues.map((x) => x.name)]);
          },
        },
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        field: 'created_by_name',
        tooltipField: 'created_by_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            SAVEDFILTERSTORE.getUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.Date')}`,
        field: 'created_at',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        cellRendererFramework: (params) => {
          return (
            <Tooltip title={AUTH.getLocalTime(params?.data?.created_at,AUTH.global_fulldate_format)}>
              {AUTH.getLocalTime(params?.data?.created_at,AUTH.global_fulldate_format)}
            </Tooltip>
          );
        },
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
        minWidth: 200,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        headerTooltip: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: 175,
        filter: false,
        sortable: false,
        pinned: 'right',
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('saved_filter_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper TicketsGrid'
        style={{
          height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 11rem)',
        }}
      >
        <AgGridReact
          rowData={SAVEDFILTERSTORE.list_data}
          modules={AllModules}
          columnDefs={columns}
          defaultColDef={{
            ...GridConfig.defaultColDef,
            floatingFilter: true,
            width: 120,
          }}
          columnTypes={GridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            openViewDrawer,
            openDeleteDrawer,
            openEditDrawer,
            onSwitchChange,
          }}
          onGridReady={SAVEDFILTERSTORE.setupGrid}
          onColumnResized={SAVEDFILTERSTORE.onGridChanged}
          onColumnMoved={SAVEDFILTERSTORE.onGridChanged}
          onColumnPinned={SAVEDFILTERSTORE.onGridChanged}
          gridOptions={{
            ...GridConfig.options,
            rowHeight: 30,
            pagination: true,
            animateRows: true,
          }}
          rowDragManaged={true}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          onFilterChanged={(e) => {
            if (
              e.hasOwnProperty('afterFloatingFilter') &&
              SAVEDFILTERSTORE.selectedGridFilter
            ) {
              SAVEDFILTERSTORE.setSelectedGridFilter(null);
            }
          }}
        />
      </div>
    </>
  );
});

export default ListComponent;
