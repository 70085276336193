import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../../store';
import { vsmCommon } from '../../../../config/messages';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { Tooltip } from 'antd';

import PhoneNumberRender from '../elements/PhoneNumberRender';
import EmailNumberRender from '../elements/EmailNumberRender';
import { useTranslation } from 'react-i18next';

const MergePeopleListing = observer((props) => {
  const {t} = useTranslation()
  const {
    AUTH,
    PEOPLE: { setMasterPeopleToMerge, masterPeopleToMerge },
  } = useStore();

  const gridOptions = {
    columnDefs: [
      {
        checkboxSelection: true,
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Name')}`,
        field: 'people_name',
        tooltipField: 'people_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Title')}`,
        headerTooltip: `${t('Common_Data.Title')}`,
        field: 'title',
        tooltipField: 'title',
        filter: 'agTextColumnFilter',

        valueGetter: (params) =>
          params.data && params.data.title ? params.data.title : '',
      },

      {
        headerName: `${t('Common_Data.People')} ${t('PeopleManagement.Role')}`,
        headerTooltip: `${t('Common_Data.People')} ${t('PeopleManagement.Role')}`,
        field: 'people_role',
        tooltipField: 'people_role',
        filter: 'agTextColumnFilter',
        valueGetter: (params) =>
          params.data &&
          (params.data.people_roles && params.data.people_roles.length > 0
            ? params.data.people_roles.map((x) => x.role_name)
            : null),
      },
      {
        headerName: `${t('Common_Data.Phone')}`,
        headerTooltip: `${t('Common_Data.Phone')}`,
        field: 'phone',
        filter: 'agTextColumnFilter',

        cellClass: 'cellClassDropDown',
        cellRenderer: 'phoneCellRenderer',
      },
      {
        headerName: `${t('Common_Data.Email')}`,
        headerTooltip: `${t('Common_Data.Email')}`,
        field: 'email',
        filter: 'agTextColumnFilter',

        cellClass: 'cellClassDropDown',
        cellRenderer: 'emailCellRenderer',
      },
      {
        headerName: `${t('PeopleManagement.PPS')} ${t('Common_Data.Number')}`,
        headerTooltip: `${t('PeopleManagement.PPS')} ${t('Common_Data.Number')}`,
        field: 'pps_number',
        tooltipField: 'pps_number',
        filter: 'agTextColumnFilter',
        valueGetter: (params) =>
          params.data && params.data.pps_number ? params.data.pps_number : '',
      },
      {
        headerName: `${t('Common_Data.Address')}`,
        headerTooltip: `${t('Common_Data.Address')}`,
        field: 'address',
        tooltipField: 'address',
        filter: 'agTextColumnFilter',

        cellClass: (params) => ['cell_scroll'],
        cellRendererFramework: (data) => <div>{data.data && data.data.address}</div>,
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        field: 'created_by',
        tooltipField: 'created_by',
        filter: 'agSetColumnFilter',
        valueGetter: (params) =>
          params.data && params.data.created_by ? params.data.created_by : '',
      },

      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        headerTooltip: `${t('Common_Data.Last_Updated_On')}`,
        field: 'last_updated',
        cellRendererFramework: (params) => {
          return (
            <Tooltip title={AUTH.getLocalTime(params?.data?.last_updated,AUTH.global_fulldate_format)}>
              {AUTH.getLocalTime(params?.data?.last_updated,AUTH.global_fulldate_format)}
            </Tooltip>
          );
        },
        // cellRenderer: (params) => {
        //   return AUTH.getLocalTime(params?.data?.last_updated);
        // },
        filter: 'agDateColumnFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        headerTooltip: `#${t('Common_Data.ID')}`,
        field: 'id',
        tooltipField: 'id',
        filter: 'agNumberColumnFilter',
        width: 80,
      },
      {
        headerName: `${t('Common_Data.Exchange')} ${t('Common_Data.Ref')} ${t('Common_Data.ID')}`,
        headerTooltip: `${t('Common_Data.Exchange')} ${t('Common_Data.Ref')} ${t('Common_Data.ID')}`,
        field: 'crm_id',
        tooltipField: 'crm_id',
        filter: 'agTextColumnFilter',
        width: 80,
      },
    ],
  };

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper TicketsGrid'
        style={{
          height: '70vh',
        }}
      >
        <AgGridReact
          rowData={props.selectedMergeData}
          modules={AllModules}
          columnDefs={gridOptions.columnDefs}
          defaultColDef={{
            ...LocalGridConfig.defaultColDef,
            floatingFilter: false,
            width: 120,
          }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            phoneCellRenderer: PhoneNumberRender,
            emailCellRenderer: EmailNumberRender,
          }}
          gridOptions={{
            ...LocalGridConfig.options,
            rowHeight: 30,
            pagination: false,
            animateRows: true,
          }}
          rowSelection={'single'}
          suppressRowClickSelection={true}
          onRowSelected={(params) => {
            if (masterPeopleToMerge && params?.data?.id !== masterPeopleToMerge) {
              setMasterPeopleToMerge(params?.data?.id);
            } else if (masterPeopleToMerge && params?.data?.id === masterPeopleToMerge) {
              setMasterPeopleToMerge(null);
            } else {
              setMasterPeopleToMerge(params?.data?.id);
            }
          }}
        />
      </div>
    </>
  );
});
export default MergePeopleListing;
