import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

// import TopSelection from './component/TopSelection';
import ListComponent from './component/ListComponent';
import EditComponent from './component/EditComponentNew';
import DeleteComponent from './component/DeleteComponent';
import CloneComponent from './component/CloneComponentNew';
import ViewComponent from './component/ViewComponent';
import AddTicketsComponent from './component/AddTicketsComponentNew';
import useStore from '../../store';
import { PageHeader, Button, Space, Form, Tooltip, Typography } from 'antd';
import RecordPerPage from '../../config/RecordPerPage';
import EnterFullscreen from '../../component/EnterFullscreen';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';
import Unauthorized from '../Unauthorized';
import ApplyGridFilterNew from '../../component/ApplyGridFilterNew';
import MergeTicket from './component/MergeTicket';
import { vsmNotify } from '../../config/messages';
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import ResetGridOptions from '../../component/ResetGridOptions';
import { filterFiltersByPageType } from '../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../component/TimeTracker';

const Tickets = observer((props) => {
  
  const { t } = useTranslation()
  const [addDrawer, setAddDrawer] = useState(false);
  const [viewDrawer, setViewDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [cloneDrawer, setCloneDrawer] = useState(false);
  const [id, setId] = useState();

  // States added for resolving zindex issue
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  // ----------------------------------------//

  const [selectedRowsCount, setSelectedRowsCount] = useState(false);
  const [mergeVisibleModal, setMergeVisibleModal] = useState(false);
  const [selectedMergeData, setSelectedMergeData] = useState(null);
  const [showCheckbox, setshowCheckbox] = useState(false);

  const { SearchPage, AllView, sectorSelector, search_term, resultCount, handleRoute } = props;
  const { Title } = Typography;
  const {
    TicketsStore,
    AUTH: { allPageFilters, allPageFiltersLoaded },
    AUTH,
    SETTINGS,
    DEALDESKSTORE: { setStopInvestmentHourCounter },
  } = useStore();
  const [form] = Form.useForm();
  //Edit drawer
  const openEditDrawer = (data, isViewOpen = false) => {
    setViewOpen(isViewOpen);
    setStopInvestmentHourCounter(true);
    TicketsStore.setEditValues(data);
    setEditDrawer(true);
  };
  const closeEditDrawer = () => {
    setStopInvestmentHourCounter(false);
    setEditDrawer(false);
    setViewOpen(false);
  };

  //open delet drawer
  const openDeleteDrawer = (data) => {
    TicketsStore.setDeleteValues(data);
    setDeleteDrawer(true);
  };
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  //Clone drawer
  const openCloneDrawer = (data) => {
    TicketsStore.setClonevalues(data);
    setCloneDrawer(true);
  };
  const closeCloneDrawer = () => setCloneDrawer(false);

  //View drawer
  const openViewDrawer = (data, isEditOpen = false) => {
    if (AUTH.checkPermission(15, 'view-details')) {
      setEditOpen(isEditOpen);
      setId(data);
      setStopInvestmentHourCounter(true);
      setViewDrawer(true);
    }
  };
  const closeViewDrawer = () => {
    TicketsStore.viewValues = null;
    setStopInvestmentHourCounter(false);
    setId(null);
    setViewDrawer(false);
    setEditOpen(false);
  };

  //Add drawer
  const openAddDrawer = (data) => {
    setAddDrawer(true);
  };
  const closeAddDrawer = () => {
    setAddDrawer(false);
  };

  //confirmation alert before deleting existing user & edit
  const onCellKeyDown = (e) => {
    if (AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(15, 'edit')) {
            if (result.key === 'Enter') {
              openEditDrawer(e.data);
            }
          }
          if (AUTH.checkPermission(15, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteDrawer(e.data);
            }
          }
        }
      }
    }
  };

  //START:-----------SOLD Merge Functions---------------------------//
  const toggleMergeTicket = () => {
    //setshowCheckbox(!showCheckbox);
    if (showCheckbox) {
      openMergeModal();
    } else {
      setshowCheckbox(true);
    }
  };

  const openMergeModal = () => {
    let selectedRow = TicketsStore.agGrid.api.getSelectedRows();
    if (selectedRow.length >= 2) {
      setMergeVisibleModal(true);
      setSelectedMergeData(selectedRow);
    } else {
      vsmNotify.error({
        message: `${t('Common_Data.Please_Select_Records')} 2 ${t('Common_Data.Record')}.`,
      });
    }
  };
  const closeMergeModal = () => {
    setshowCheckbox(false);
    setMergeVisibleModal(false);
    setSelectedMergeData(null);
  };
  //END:-----------SOLD Merge Functions---------------------------//

  const selectionChanged = () => {
    let selectedRow = TicketsStore.agGrid.api.getSelectedRows();
    setSelectedRowsCount(selectedRow.length > 0 ? selectedRow.length : 0);
  };

  const onClearSelectionClick = () => {
    if (TicketsStore.agGrid) {
      TicketsStore.agGrid.api.deselectAll();
    }
    setshowCheckbox(false);
  };

  useEffect(() => { }, [allPageFiltersLoaded, allPageFilters]);

  const pageFilterType = 'tickets';




  return (
    <>
      {!AUTH.checkPermission(15, 'list') ? (
        <Unauthorized />
      ) :
        //  SearchPage ? (
        //   <>
        //     <ListComponent
        //       openViewDrawer={openViewDrawer}
        //       openEditDrawer={openEditDrawer}
        //       openDeleteDrawer={openDeleteDrawer}
        //       openCloneDrawer={openCloneDrawer}
        //       SearchPage={SearchPage}
        //       AllView={AllView}
        //       onCellKeyDown={onCellKeyDown}
        //       selectionChanged={selectionChanged}
        //       showCheckbox={showCheckbox}
        //     />
        //     <AddTicketsComponent visible={addDrawer} close={closeAddDrawer} />
        //     <ViewComponent
        //       visible={viewDrawer}
        //       id={id}
        //       setId={setId}
        //       close={closeViewDrawer}
        //       closeViewDrawer={closeViewDrawer}
        //       openEditDrawer={openEditDrawer}
        //     />
        //     <EditComponent
        //       visible={editDrawer}
        //       editOpen={editOpen}
        //       close={closeEditDrawer}
        //     />
        //     <DeleteComponent
        //       visible={deleteDrawer}
        //       close={closeDeleteDrawer}
        //       SearchPage={SearchPage}
        //     />
        //     <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />
        //   </>
        // ) :
        (
          <PageHeader
            title={
              !SearchPage ? `${t('Common_Data.Tickets')}`
                : (<>
                  <div className='d-flex justify-content-start align-items-center notes'>
                    <Title level={3}>
                      {sectorSelector} : <Tooltip title={search_term} placement="topLeft">"{search_term?.length > 25 ? search_term.substring(0, 25) + "..." : search_term}"</Tooltip> {`${t('DealDesk.Results')}`}
                    </Title>
                    <div className='searchView ml-10 '>
                      <span className='sep'>|</span>
                      {resultCount ? resultCount.toLocaleString('en-GB') : resultCount}{' '}
                      {t('Common_Data.Records_found')}
                      <span className='sep'>|</span>
                      <span className='cursorPointer text-decoration-underline mr-10' onClick={handleRoute} >
                        {t('Common_Data.View_all')} {sectorSelector}
                      </span>
                    </div>
                  </div>
                </>)
            }
            className={SearchPage ? 'SearchResult-PageHeader ' + SETTINGS.fullscreen_class : '' + SETTINGS.fullscreen_class}
            extra={[
              <Form form={form} className='innerForm headerButton'>
                <Space size={5}>
                  {selectedRowsCount || showCheckbox
                    ? (
                      <Tooltip title={`${t('Common_Data.Clear')} ${t('Common_Data.Merge')}`}>
                        <Button
                          key='9'
                          shape='round'
                          size='medium'
                          onClick={onClearSelectionClick}
                        >
                          <img
                            src={AUTH.GetThemedImage('Refresh_NoSpace')}
                            alt=''
                            className='icon'
                          />
                        </Button>
                      </Tooltip>
                    )
                    : null
                  }

                  <Tooltip title={showCheckbox ? `${t('Common_Data.Merge')} ${t('Common_Data.Records')}` : `${t('Common_Data.Start')} ${t('Common_Data.Merging')}`}>
                    <Button
                      key='10'
                      shape='round'
                      size='medium'
                      onClick={toggleMergeTicket}
                    >
                      <img
                        src={AUTH.GetThemedImage('Hyperlink_icn')}
                        alt=''
                        className='icon'
                      />
                    </Button>
                  </Tooltip>
                  {!SearchPage
                    ? <ApplyGridFilterNew type={pageFilterType} selectionValue={TicketsStore.selectedGridFilter}
                      filterList={filterFiltersByPageType(allPageFilters, pageFilterType)}
                    /> : null}
                  {!SearchPage ? AUTH.checkPermission(15, 'add') && (
                    <Button
                      className='gridbtn'
                      key='2'
                      shape='round'
                      size='medium'
                      onClick={openAddDrawer}
                    >
                      {t('Common_Data.Add')} {t('Common_Data.Ticket')}
                    </Button>
                  ) : null}
                  {!SearchPage ? AUTH.checkPermission(15, 'export') && (
                    <Button
                      className='gridbtn'
                      key='3'
                      shape='round'
                      size='medium'
                      loading={TicketsStore.exportLoading}
                      onClick={TicketsStore.exportData}
                    >
                      {t('Common_Data.Export')}
                    </Button>
                  ) : null}
                  <ResetGridOptions key={'4'} pageType={pageFilterType} />
                  <EnterFullscreen key='5' />
                  <RecordPerPage
                    key='6'
                    initialValue={TicketsStore.per_page + ' per page'}
                    onChange={TicketsStore.setPageSize}
                  />
                </Space >
              </Form >,
            ]}
          >
            <ListComponent
              openViewDrawer={openViewDrawer}
              openEditDrawer={openEditDrawer}
              openDeleteDrawer={openDeleteDrawer}
              openCloneDrawer={openCloneDrawer}
              SearchPage={SearchPage}
              onCellKeyDown={onCellKeyDown}
              onCellDoubleClicked={(e) => {openViewDrawer(e.data);}}
              selectionChanged={selectionChanged}
              showCheckbox={showCheckbox}
              AllView={AllView}
            />
            <AddTicketsComponent visible={addDrawer} close={closeAddDrawer} />
            <ViewComponent
              visible={viewDrawer}
              id={id}
              editOpen={editOpen}
              setId={setId}
              close={closeViewDrawer}
              openEditDrawer={openEditDrawer}
              editDrawer={editDrawer}
            />
            <EditComponent
              visible={editDrawer}
              viewOpen={viewOpen}
              close={closeEditDrawer}
              openViewDrawer={openViewDrawer}
            />
            <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} SearchPage={SearchPage} />
            <CloneComponent visible={cloneDrawer} close={closeCloneDrawer} />
            <MergeTicket
              visible={mergeVisibleModal}
              close={closeMergeModal}
              selectedMergeData={selectedMergeData}
            />
            {/* {!stopInvestmentHourCounter && <TrackTimeComponent pageName={SearchPage ? 'search' : 'tickets'} />} */}
            <TimeTracker
              page={"tickets"}
              stop={addDrawer || viewDrawer || editDrawer || cloneDrawer}
            />
          </PageHeader >
        )}
    </>
  );
});

export default Tickets;
