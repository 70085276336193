import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
export default class StructureStore {
  structure_notes_list = null;
  screen_notes_list = null; //USED FOR LEFT SECTION SCREEN NOTES LIST

  strucureAutosaveData = null; //STRUCTURE AUTOSAVE DATA.
  screenAutosaveData = null; //STRUCTURE AUTOSAVE DATA.

  fetchAutosaveData = (id, type) => {
    return Axios.get(`deal-desk/leads-structure/list/${id}`)
      .then(({ data }) => {
        let currentLoggedUserID = parseInt(localStorage.getItem('user_name'));
        if (type === 'structure') {
          this.strucureAutosaveData = null;

          let structureData = [];
          structureData =
            data &&
            data.data &&
            data.data.length > 0 &&
            data.data.filter((e) => e.type !== 'screen');

          if (structureData && structureData.length > 0) {
            structureData.forEach((item) => {
              if (item.is_saved) {
                if (item.is_saved && currentLoggedUserID === item.created_by) {
                  if (!this.strucureAutosaveData) {
                    this.strucureAutosaveData = item;
                  }
                }
              }
            });
          }
        }

        if (type === 'screen') {
          this.screenAutosaveData = null;

          let screenData = [];
          screenData =
            data &&
            data.data &&
            data.data.length > 0 &&
            data.data.filter((e) => e.type === 'screen');
          if (screenData && screenData.length > 0) {
            screenData.forEach((item) => {
              if (item.is_saved) {
                if (item.is_saved && currentLoggedUserID === item.created_by) {
                  if (!this.screenAutosaveData) {
                    this.screenAutosaveData = item;
                  }
                }
              }
            });
          }
        }

        return data && data.data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };

  fetchStructureNotes = (id, setAutosave = false) => {
    return Axios.get(`deal-desk/leads-structure/list/${id}`)
      .then(({ data }) => {
        let currentLoggedUserID = parseInt(localStorage.getItem('user_name'));

        let structureData = [];
        let screenData = [];

        structureData =
          data &&
          data.data &&
          data.data.length > 0 &&
          data.data.filter((e) => e.type !== 'screen');

        screenData =
          data &&
          data.data &&
          data.data.length > 0 &&
          data.data.filter((e) => e.type === 'screen');

        this.screen_notes_list = screenData;
        this.structure_notes_list = structureData;

        if (setAutosave && structureData) {
          this.strucureAutosaveData = null;
          if (structureData && structureData.length > 0) {
            structureData.forEach((item) => {
              if (item.is_saved) {
                if (item.is_saved && currentLoggedUserID === item.created_by) {
                  if (!this.strucureAutosaveData) {
                    this.strucureAutosaveData = item;
                  }
                }
              }
            });
          }
        }

        if (setAutosave && screenData) {
          this.screenAutosaveData = null;
          if (screenData && screenData.length > 0) {
            screenData.forEach((item) => {
              if (item.is_saved) {
                if (item.is_saved && currentLoggedUserID === item.created_by) {
                  if (!this.screenAutosaveData) {
                    this.screenAutosaveData = item;
                  }
                }
              }
            });
          }
        }

        return data && data.data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };

  removeStructure = (id) => {
    return Axios.post(`deal-desk/remove-leads-structure/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ data }) => {
        return Promise.reject(data);
      });
  };
  setStrucureAutosaveData = (data) => {
    this.strucureAutosaveData = data;
  };
  setScreenAutosaveData = (data) => {
    this.screenAutosaveData = data;
  };
  resetAutosaveData = () => {
    this.screenAutosaveData = null;
    this.strucureAutosaveData = null;
  };
}
decorate(StructureStore, {
  structure_notes_list: observable.shallow,
  screen_notes_list: observable.shallow,
  strucureAutosaveData: observable,
  screenAutosaveData: observable,
  fetchStructureNotes: action,
  removeStructure: action,
  setStrucureAutosaveData: action,
  setScreenAutosaveData: action,
  resetAutosaveData: action,
});
