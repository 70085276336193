import React from 'react';
import { observer } from 'mobx-react';
import { Image } from 'antd';
import moment from 'moment';
import useStore from '../../../../store';
import { vsmNotify, vsmCallAudit } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const ViewElement = observer((props) => {
  const { AUTH, PEOPLE, LEADSSTORE } = useStore();
  const { t } = useTranslation();

  const handleCallAudit = (data) => {
    let formdata = {
      id: props.peopleValues.id,
      phone: data,
    };
    PEOPLE.callAudit(formdata).then(() => {
      //alert(phone);
      vsmNotify.success({
        message: vsmCallAudit.added,
      });
    });
  };
  const handleEmailCallAudit = (data) => {
    let formdata = {
      id: props.peopleValues.id,
      email: data,
    };
    LEADSSTORE.callEmailAudit(formdata).then(() => {
      //alert(phone);
      vsmNotify.success({
        message: vsmCallAudit.emailadded,
      });
    });
  };
  return (
    <>
      <div className='viewPeople'>
        <table cellPadding='0' cellSpacing='0' border='0'>
          <tbody>
            <tr>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
              <td width='25%' style={{ border: '0' }}></td>
            </tr>
            <tr>
              <th>{t('PeopleManagement.Salutation')}</th>
              <th>
                {t('Common_Data.First')} {t('Common_Data.Name')}:
              </th>
              <th>
                {t('Common_Data.Last')} {t('Common_Data.Name')}:
              </th>
              <th>{t('Common_Data.Status')}:</th>
            </tr>
            <tr>
              <td>
                {props.peopleValues && props.peopleValues?.salutation
                  ? props.peopleValues?.salutation
                  : `${t('PeopleManagement.Mr')}.`}
              </td>
              <td>{props.peopleValues && props.peopleValues?.first_name}</td>
              <td>{props.peopleValues && props.peopleValues?.last_name}</td>
              <td>
                {props.peopleValues &&
                  props.peopleValues?.people_status_name?.status_name}
              </td>
            </tr>
            <tr>
              <th>
                {t('PeopleManagement.Former')} {t('Common_Data.Last')}{' '}
                {t('PeopleManagement.Name')}
              </th>
              <th>{t('Common_Data.Pronounced')}:</th>
              <th>
                {t('Common_Data.Date')} {t('Common_Data.Of')}{' '}
                {t('PeopleManagement.Birth')}:
              </th>
              <td rowSpan='2' className='logo'>
                {props.peopleValues?.photo_url ? (
                  <Image
                    width='100%'
                    height={150}
                    src={process.env.React_APP_API_URL + props.peopleValues?.photo_url}
                    alt={`${t('PeopleManagement.People_Image')}`}
                  />
                ) : (
                  <>{t('Common_Data.Logo')}</>
                )}
              </td>
            </tr>
            <tr>
              <td>{props.peopleValues && props.peopleValues?.former_last_name}</td>
              <td>{props.peopleValues && props.peopleValues?.pronounced}</td>
              <td>{props.peopleValues && props.peopleValues?.date_of_birth}</td>
            </tr>
            <tr>
              <th>{t('Common_Data.Title')}:</th>
              <th>{t('Common_Data.Classification')}:</th>
              <th>
                {t('PeopleManagement.PPSN')} {t('PeopleManagement.Document')}:
              </th>
              <th>
                {t('PeopleManagement.PPS')} {t('Common_Data.Number')}:
              </th>
            </tr>
            <tr>
              <td>{props.peopleValues && props.peopleValues?.title}</td>
              <td>
                {props.peopleValues &&
                  props.peopleValues?.classification_id &&
                  props.peopleValues?.classification_id.name}
              </td>
              <td>{props.peopleValues && props.peopleValues?.ppsn_document_type}</td>
              <td>{props.peopleValues && props.peopleValues?.pps_number}</td>
            </tr>

            <tr>
              <th colSpan='3'>{t('LeadListing.Notes')}:</th>
              <th colSpan='1'>
                {t('Common_Data.Exchange')} {t('Common_Data.Ref')} {t('Common_Data.ID')}:
              </th>
            </tr>
            <tr>
              <td colSpan='3'>{props.peopleValues && props.peopleValues?.notes}</td>
              <td colSpan='1'>{props.peopleValues?.crm_id}</td>
            </tr>

            {props.peopleValues?.address && props.peopleValues?.address.length > 0 && (
              <>
                {props.peopleValues?.address.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <th colSpan='2'>
                          {item.address_type
                            ? item.address_type
                            : `${t('Common_Data.Primary')}`}
                          :
                        </th>
                        <th>{t('Common_Data.City')}:</th>
                        <td>{item.city ? item.city : ''}</td>
                      </tr>
                      <tr>
                        <td colSpan='2'>{item.address_1 ? item.address_1 : ''}</td>
                        <th>
                          {t('Common_Data.State')}/{t('PeopleManagement.County')}:
                        </th>
                        <td>{item.state_county ? item.state_county : ''}</td>
                      </tr>
                      <tr>
                        <td colSpan='2'>{item.address_2 ? item.address_2 : ''}</td>
                        <th>{t('Common_Data.Country')}:</th>
                        <td>{item.countries ? item?.countries?.country_name : ''}</td>
                      </tr>
                      <tr>
                        <td colSpan='2'>{item.address_3 ? item.address_3 : ''}</td>
                        <th>{t('Common_Data.Postal_Code')}:</th>
                        <td>{item.postal_code ? item.postal_code : ''}</td>
                      </tr>
                    </>
                  );
                })}
              </>
            )}

            {props.peopleValues?.phone && props.peopleValues?.phone.length > 0 && (
              <tr>
                <th>{t('Common_Data.Phone')}:</th>
                <td colSpan='3'>
                  <ul className='contactListing'>
                    {props.peopleValues?.phone.map((item, index) => {
                      return (
                        <li key={index}>
                          {`${t(`Common_Data.${item.contact_for}`)}`}:
                          <a
                            href={`tel:${item.contact_value}`}
                            onClick={(e) => {
                              handleCallAudit(item.contact_value);
                            }}
                          >
                            {item.contact_value}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
            )}

            {props.peopleValues?.email && props.peopleValues?.email.length > 0 && (
              <tr>
                <th>{t('Common_Data.Email')}:</th>
                <td colSpan='3'>
                  <ul className='emailListing'>
                    {props.peopleValues?.email.map((item, index) => {
                      return (
                        <li key={index}>
                          {`${t(`Common_Data.${item.contact_for}`)}`}:
                          <a
                            href={`mailto:${item.contact_value}`}
                            onClick={(e) => {
                              handleEmailCallAudit(item.contact_value);
                            }}
                          >
                            {item.contact_value}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
            )}
            <tr>
              <th>
                {t('Common_Data.Created')} {t('Common_Data.By')}
              </th>
              <td>{props.peopleValues?.created_by_name}</td>
              <th>
                {t('Common_Data.Created')} {t('Common_Data.At')}
              </th>
              <td>
                {props.peopleValues && props.peopleValues?.created_date
                  ? props.peopleValues?.created_time
                    ? `${AUTH.getLocalTime(
                        props.peopleValues?.created_date +
                          ' ' +
                          props.peopleValues?.created_time,
                          AUTH.global_fulldate_format
                      )}`
                    : `${AUTH.getLocalTime(moment(props.peopleValues?.created_date))}`
                  : ''}
              </td>
            </tr>
            <tr>
              <th>{t('Common_Data.Last_Updated_By')}</th>
              <td>{props.peopleValues?.updated_by_name}</td>
              <th>{t('Common_Data.Last_Updated_At')}</th>
              <td>
                {props.peopleValues && props.peopleValues?.updated_date
                  ? props.peopleValues?.updated_time
                    ? `${AUTH.getLocalTime(
                        props.peopleValues?.updated_date +
                          ' ' +
                          props.peopleValues?.updated_time,
                          AUTH.global_fulldate_format
                      )}`
                    : `${AUTH.getLocalTime(moment(props.peopleValues?.updated_date))}`
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* {AUTH.checkPermission(16, 'role-mapping-list') && (
        <RelatedOrganisationList
          id={props.id}
          currentTab={'people'}
          waste={props.waste}
          handleViewDetailCall={props.handleViewDetailCall}
        />
      )} */}

      {/* {AUTH.checkPermission(18, 'role-mapping-list') && (
        <RelatedPeopleList
          id={props.id}
          currentTab={'people'}
          waste={props.waste}
          handleViewDetailCall={props.handleViewDetailCall}
        />
      )} */}
    </>
  );
});

export default ViewElement;
