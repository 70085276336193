import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { ActionRenderer } from './GridComponent';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import useStore from '../../../../store';
import { vsmCommon } from '../../../../config/messages';
import SwitchComponent from './SwitchComponent';
import { DateComparator } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { t } = useTranslation();
  const {
    gotoEditScreen,
    gotoViewScreen,
    openDeleteModal,
    onSwitchChange,
    onCellKeyDown,
  } = props;
  const {
    AlgorithmsStore,
    AlgorithmsStore: { getList, list_data, setupGrid, onFilterChanged },
    AUTH,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('DealBuilder.Algorithm_Name')}`,
        field: 'algorithm_name',

      },
      {
        headerName: `${t('DealBuilder.Description')}`,
        field: 'algorithm_desc',
        tooltipField: `${t('DealBuilder.Algorithm_Desc')}`,

      },
      {
        headerName: `${t('DealBuilder.Last_Parse_Status')}`,
        field: 'last_parse_result',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
        },

      },
      {
        headerName: `${t('Common_Data.Last_Updated_By')}`,
        field: 'updated_by',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
        },

      },
      {
        headerName: `${t('Common_Data.Last_Updated_On')}`,
        field: 'updated_at',
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.updated_at ? AUTH.getLocalTime(params.data.updated_at,AUTH.global_fulldate_format) : ' ';
        },

        width: 220,

        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('DealBuilder.Status')}`,
        field: 'published',
        cellRendererFramework: function (data) {
          const { onSwitchChange } = data.agGridReact.props;
          return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />;
        },
        sortable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Yes', 'No'],
          defaultToNothingSelected: true,
        },

      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',

      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        sortable: false,
        filter: false,
        pinned: 'right',
      },
    ],
  };

  useEffect(() => {
    getList();
  }, [getList]);

  let columns = [];
  let columnConfig = localStorage.getItem('algorithm_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  if (!AUTH.checkPermission(23, 'change-status')) {
    columns = columns.filter((x) => x.headerName !== 'Status');
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    item.headerTooltip = item.headerName;
    item.tooltipField = item.field;

    return item;
  })

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 11rem)',
      }}
    >
      <AgGridReact
        rowData={list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={LocalGridConfig.defaultColDef}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          gotoEditScreen,
          gotoViewScreen,
          openDeleteModal,
        }}
        onColumnResized={AlgorithmsStore.onGridChanged}
        onColumnMoved={AlgorithmsStore.onGridChanged}
        onColumnPinned={AlgorithmsStore.onGridChanged}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        onGridReady={setupGrid}
        gridOptions={{
          ...LocalGridConfig.options,
          rowHeight: 30,
          onRowDoubleClicked: (event) => { AUTH.checkPermission(23, 'view-details') && gotoViewScreen(event?.data?.id) },
        }}
        onSwitchChange={onSwitchChange}
        onFilterChanged={onFilterChanged}
        onSortChanged={AlgorithmsStore.onGridChanged}
        onCellKeyDown={onCellKeyDown}
      />
    </div>
  );
});

export default ListComponent;
