import React, { useState } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import { vsmOrganisations } from '../../../../config/messages';
import { Form, Col, Row } from 'antd';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const PhoneElement = observer(({ onChange, form }) => {
  const { t } = useTranslation();
  const [SelectTypes, SetSelectedTypes] = useState([{ contact_for: 'Primary' }]);
  const { AUTH } = useStore();

  return (
    <div className='w-100'>
      <Form.List name='phone'>
        {(fields, { add, remove }) => {
          return fields.map((field, index) => {
            let rejected_types = [];
            var accepted_index;
            SelectTypes &&
              SelectTypes.map((x, index) => {
                if (x && x.contact_for === 'Primary') {
                  accepted_index = index;
                  rejected_types.push('Primary');
                }
                return x;
              });
            return (
              <div key={field.key}>
                <Row gutter={15} style={{ flexFlow: 'row' }}>
                  <Col xs={{ span: 11 }}>
                    <InputComponent
                      placeholder='Select'
                      type='select'
                      name={[field.name, 'contact_for']}
                      key={[field.key, 'contact_for']}
                      onChange={() => {
                        onChange();
                        SetSelectedTypes(form.getFieldValue('phone'));
                      }}
                      //initialValue={'Primary'}
                      showSearch='false'
                      options={{
                        values: [
                          { value: 'Primary', text: `${t('Common_Data.Primary')}` },
                          { value: 'Home', text: `${t('Common_Data.Home')}` },
                          { value: 'Office', text: `${t('Common_Data.Office')}` },
                          { value: 'Mobile', text: `${t('Common_Data.Mobile')}` },
                          { value: 'Other', text: `${t('Common_Data.Other')}` }
                        ],
                        rejected_keys: rejected_types,
                        accepted_keys: index === accepted_index && 'Primary'
                      }}
                      autoComplete='off'
                    />
                  </Col>

                  <Col xs={{ span: 10 }}>
                    <InputComponent
                      placeholder={`${t('Common_Data.Phone_Number_Placeholder')}`}
                      name={[field.name, 'contact_value']}
                      key={[field.key, 'contact_value']}
                      onChange={onChange}
                      autoComplete='off'
                      rules={vsmOrganisations.validation.phone}
                    />
                  </Col>

                  <Col xs={{ span: 3 }}>
                    <div className='d-flex'>
                      {fields.length > 1 && (
                        <img
                          src={AUTH.GetThemedImage('remove')}
                          alt=''
                          style={{ marginLeft: '0.5rem' }}
                          onClick={() => {
                            remove(field.name);
                            onChange();
                          }}
                        />
                      )}
                      {index === fields.length - 1 && (
                        <img
                          src={AUTH.GetThemedImage('add')}
                          alt=''
                          style={{ marginLeft: '0.5rem' }}
                          onClick={() => {
                            add();
                            onChange();
                          }}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          });
        }}
      </Form.List>
    </div>
  );
});

export default PhoneElement;
