import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Modal, Row, Col } from 'antd';
import useStore from '../store';
import debounce from 'lodash/debounce';
import InputComponent from './InputComponent';
import { vsmGridFilter, vsmNotify } from '../config/messages';
import { useTranslation } from 'react-i18next';

const SaveGridFilter = observer((props) => {
  const { AUTH } = useStore();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { t } = useTranslation();

  const close = () => {
    props.close();
    form.resetFields();
  };

  const handleSubmit = (data) => {
    let payload = {
      ...props.dataSaveFilter,
      //page: props.dataSaveFilter.type,
      string_value: JSON.stringify(props.dataSaveFilter.value),
      filter: data.filter,
    };

    AUTH.saveGridFilter(payload, props?.isCommonAPICall ? props?.isCommonAPICall : false)
      .then((res) => {
        if (res.id) {
          props.updateNewAddedFilter(res.id);
        }
        close();
        vsmNotify.success({
          message: vsmGridFilter.add,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  const onChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  return (
    <Modal
      centered
      title={`${t('Common_Data.Save_Filter')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          loading={saving}
          htmlType='submit'
          type='primary'
          shape='round'
          form='savefilterform'
          danger
          disabled={disabled}
        >
          {t('Common_Data.Save')}
        </Button>,
        <Button key='2' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id='savefilterform'
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        className='innerForm profileForm'
      >
        <Row gutter={15}>
          <Col span={24}>
            <InputComponent
              required
              name='filter'
              label={t('Common_Data.Filter_Name')}
              placeholder={t('Common_Data.Filter_Name')}
              type='input'
              onChange={onChange}
              tooltip={t('Common_Data.Filter_Name')}
              rules={[
                { required: true, message: t('Messages.vsmSaveFilterName_required') },
                {
                  pattern: /^[A-Za-z0-9-. ]*$/,
                  message:
                    t('Messages.vsmSaveFilterName_pattern'),
                },
              ]}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default SaveGridFilter;
