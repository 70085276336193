import React from 'react';
const StructureTableContent = ({ Structure, isViewLead }) => {
  const PrintStructure = (structure, ItemKey = '') => {
    return (
      structure &&
      structure.map((x, i) => {
        return (
          <>
            <li className='textInCol' key={ItemKey + x.position}>
              {!isViewLead &&
                <span className='listNo'>
                  {ItemKey + x.position}
                  {ItemKey === '' ? '.' : ''} {x.position < 10 && <>&nbsp;</>}
                </span>
              }
              {/* {x.detail} */}
              <span
                className='data'
                dangerouslySetInnerHTML={{
                  __html: x.detail ? x.detail.replace(/(?:\r\n|\r|\n)/g, '<br />') : null,
                }}
              />
              {x.children && x.children.length > 0 && (
                <ol>{PrintStructure(x.children, ItemKey + x.position + '.')}</ol>
              )}
            </li>
          </>
        );
      })
    );
  };

  return (
    <>
      <ol className='structure'>{PrintStructure(Structure)}</ol>
    </>
  );
};

export default StructureTableContent;
