import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmNotify, vsmClassification } from '../../../config/messages';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const {t}= useTranslation();
  const [form] = Form.useForm();
  const { CREDEBTCLASSIFICATION } = useStore();
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };

  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    props.close();
    form.resetFields();
    CREDEBTCLASSIFICATION.DeleteData(CREDEBTCLASSIFICATION.deleteValues.id)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmClassification.delete
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return CREDEBTCLASSIFICATION.deleteValues ? (
    <Modal
      centered
      title={`${t('Credebt_Classification.Delete_Classification')} - ${CREDEBTCLASSIFICATION.deleteValues.name} `}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deleteformCredebtClassification'
          loading={saving}
          htmlType='submit'
          type='primary'
          shape='round'
          danger
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>
      ]}
    >
      <Form form={form} id='deleteformCredebtClassification' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('Credebt_Classification.Remove_Classification')}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default DeleteComponent;
