import React, { useEffect, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { vsmCommon } from '../../../../../config/messages';
import useStore from '../../../../../store';
import {
  Button,
  //  Tooltip
} from 'antd';

import LocalGridConfig from '../../../../../config/LocalGridConfig';
import { DateComparator } from '../../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

import HistoryRender from '../../Structure/elements/HistoryRender';

const ScheduleLibraryList = observer((props) => {
  const { t } = useTranslation();
  const {
    tempcopySchedule,
    viewScheduleLibraryDrawer,
    openEditDrawer,
    openDeleteDrawer,
    libraryData = [],
    DealLibrary,
  } = props;

  const {
    AUTH,
    SSSLEADLIBRARYSTORE,
    SSSLEADLIBRARYSTORE: { libraryGridLoader, setupScheduleLibraryAgGrid },
    LEADSSTORE
  } = useStore();

  const gridRef = useRef();

  const onBtShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, []);

  const hideOverlayFunction = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  useEffect(() => {
    if (libraryGridLoader) {
      onBtShowLoading();
    } else {
      hideOverlayFunction();
    }
    return () => { };
  }, [libraryGridLoader, onBtShowLoading, hideOverlayFunction]);

  const ActionRenderer = (params) => {
    return (
      <div className='action-column'>
        <Button
          type='text'
          title={`${t('DealDesk.View')}`}
          onClick={() => viewScheduleLibraryDrawer(params.data)}
        >
          <img alt={`${t('DealDesk.View')}`} className='menuicon' src={AUTH.GetThemedImage('View')} />
        </Button>

        {DealLibrary ? (
          <Button type='text' title={`${t('Common_Data.Edit')}`} onClick={() => openEditDrawer(params.data)}>
            <img alt={`${t('Common_Data.Edit')}`} className='menuicon' src={AUTH.GetThemedImage('Edit')} />
          </Button>
        ) : null}

        {AUTH.checkPermission(54, 'lead-sss-delete') && DealLibrary ? (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            onClick={() => {
              openDeleteDrawer(params.data);
            }}
          >
            <img alt={`${t('Common_Data.Delete')}`} className='menuicon' src={AUTH.GetThemedImage('Waste')} />
          </Button>
        ) : null}

        {!DealLibrary && (
          <Button type='text' title={`${t('DealDesk.Copy')}`} onClick={() => tempcopySchedule(params.data)}>
            <img alt={`${t('DealDesk.Copy')}`} className='menuicon' src={AUTH.GetThemedImage('Copy_icn')} />
          </Button>
        )}
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },
      {
        headerName: `${t('DealDesk.Updated_On')}`,
        field: 'updated_at',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return AUTH.getLocalTime(params.data.updated_at,AUTH.global_fulldate_format);
        },
      },
      {
        headerName: `${t('DealDesk.Updated_By')}`,
        field: 'updated_by_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADSSTORE.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('DealDesk.Created_On')}`,
        field: 'created_at',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return AUTH.getLocalTime(params.data.created_at,AUTH.global_fulldate_format);
        },
      },
      {
        headerName: `${t('DealDesk.Created_By')}`,
        field: 'created_by_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADSSTORE.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('DealDesk.Schedules')}`,
        field: 'title',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
        cellRendererFramework: (params) => {
          return (
            <>
              {params.data.is_template ? (
                <>
                  <img
                    src={AUTH.GetThemedImage('Template_Icon')}
                    className='menuicon'
                    width={'18px'}
                    height={'18px'}
                    alt=''
                  />{' '}
                </>
              ) : null}
              {params.data.title}
            </>
          );
        },
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Credebt_Classification')}`,
        field: 'classification_name.name',
        tooltipField: 'classification_name.name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            LEADSSTORE.getClassificationList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        field: 'description_text',
        filter: 'agTextColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.History')}`,
        field: 'description',
        // tooltipField:'description',
        filter: 'agTextColumnFilter',        
        // cellRenderer: 'historyRenderer',
        valueGetter: function (params) {
          let data = params?.data?.description;
          let content = data && data.map((item) => {
            return item.children
            ? item.item_key + ":" + (item.detail ? item.detail : " ") + ", " + item.children.map((child1) => (
              child1.children
                ? child1.item_key + ":" + (child1.detail ? child1.detail : " ") + ", " + child1.children.map((child2) => (
                  child2.item_key + ":" + (child2.detail ? child2.detail : " ")
                ))
                : child1.item_key + ":" + (child1.detail ? child1.detail : " ") + ", "
            )
            )
            : item.item_key + ":" + (item.detail ? item.detail : " ") + ", "
          })   
          return  content && content
        },
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',

        maxWidth: 100,
        // lockPosition: true,
        // suppressNavigable: true
        filter: 'agNumberColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },

      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',

        maxWidth: 140,
        filter: false,
        sortable: false,
        pinned: 'right',
        // lockPosition: true,
        // suppressNavigable: true
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('schedule_library_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['description'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }

    return item;
  });
  if(gridRef) {
    SSSLEADLIBRARYSTORE.agGridRef = gridRef;
  }

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: 'calc(100vh - 11rem)',
      }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={libraryData}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={{
          ...LocalGridConfig.defaultColDef,
          floatingFilter: false,
        }}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          historyRenderer: HistoryRender,
        }}
        onGridReady={setupScheduleLibraryAgGrid}
        gridOptions={{
          ...LocalGridConfig.options,
          pagination: false,
          rowHeight: 30,
        }}
        onColumnResized={SSSLEADLIBRARYSTORE.onGridChangedScheulde}
        onColumnMoved={SSSLEADLIBRARYSTORE.onGridChangedScheulde}
        onColumnPinned={SSSLEADLIBRARYSTORE.onGridChangedScheulde}
        onFilterChanged={SSSLEADLIBRARYSTORE.onGridChangedScheulde}
        onSortChanged={SSSLEADLIBRARYSTORE.onGridChangedScheulde}
      />
    </div>
  );
});

export default ScheduleLibraryList;
