import React from 'react';
import { observer } from 'mobx-react';

import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import useStore from '../../../store';
import GridConfig from '../../../config/GridConfig';
import { vsmCommon } from '../../../config/messages';

import moment from 'moment';

import ActionRenderer from '../elements/ActionRenderer';
import ActionHeaderComponent from '../elements/ActionHeaderComponent';
// import StatusRenderer from '../elements/StatusRenderer';
import EmailRenderer from '../elements/EmailRenderer';
import PhoneRenderer from '../elements/PhoneRenderer';
import NoteRenderer from '../elements/NoteRenderer';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const {
    AUTH,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
    BLOCKEDITASSIGNSTORE,
    LEADSSTORE: { leadSourceArray },
  } = useStore();
  const { t } = useTranslation();
  const { openViewDrawer, openEditDrawer } = props;

  const paddingLeft = '10px !important';

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth:37,
        width: 37,
      },

      {
        headerName: `${t('Block_Edit_Assign.Holder')}`,
        field: 'lead_holder_name.full_name',

        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            BLOCKEDITASSIGNSTORE.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Name')}`,
        field: 'people.full_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            BLOCKEDITASSIGNSTORE.getPeopleList().then((data) => {
              params.success([...data.data.map((x) => x.people)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation_name.organisation_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            BLOCKEDITASSIGNSTORE.getMappedOrg().then((data) => {
              params.success([...data.data.map((x) => x.organisation_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        headerTooltip: `${t('Common_Data.SOLD_Name')}`,
        field: 'opportunity_name',
        cellClass: 'cellClass',
        tooltipField: `${t('Common_Data.SOLD_Name')}`,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Block_Edit_Assign.SOLD_Value')}`,
        field: 'finance_value',
        cellClass: 'cellClass',
        cellStyle: {
          'text-align': 'right'
        },
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          return (
            params.data.lead_currency_id?.currency_code + ' ' + params.data?.finance_value
          );
        },
      },
      {
        headerName: `${t('Common_Data.SOLD_Stage')}`,
        field: 'lead_status_id.status_name',
        cellClass: 'cellClass StatusCellPromotion text-left',
        filter: 'agSetColumnFilter',
        // cellRenderer: 'StatusRenderer',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            BLOCKEDITASSIGNSTORE.getAllChildStatusList().then((data) => {
              params.success([...data.map((x) => x && x.status_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Note')}`,
        field: 'Note',
        cellClass: 'cellClass',
        cellRenderer: 'NoteRenderer',
      },
      {
        headerName: `${t('Block_Edit_Assign.Deadline_Date')}`,
        field: 'deadline_date',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? moment(params.data.deadline_date).format(AUTH.global_dateformat)
            : ' ';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Phone')}`,
        headerTooltip: `${t('Common_Data.Phone')}`,
        field: 'phone',
        cellClass: 'cellClass cellClassDropDown',
        filter: 'agTextColumnFilter',
        editable: false,
        cellRenderer: 'PhoneRenderer',
      },
      {
        headerName: `${t('Common_Data.Email')}`,
        headerTooltip: `${t('Common_Data.Email')}`,
        field: 'email',
        cellClass: 'cellClass cellClassDropDown',
        filter: 'agTextColumnFilter',
        editable: false,
        cellRenderer: 'EmailRenderer',
      },

      {
        headerName: `${t('Common_Data.Owner')}`,
        field: 'lead_owner_name.full_name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            BLOCKEDITASSIGNSTORE.getLeadsUsersList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Credebt_Classification')}`,
        field: 'classification.name',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            BLOCKEDITASSIGNSTORE.getClassificationList().then((data) => {
              params.success([...data.data.map((x) => x.name)]);
            });
          },
        },
      },

      {
        headerName: `${t('Block_Edit_Assign.Lead_Source')}`,
        field: 'lead_source',
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            if (leadSourceArray && leadSourceArray.length > 0) {
              params.success([...leadSourceArray.map((x) => x.value)]);
            }
          },
        },
      },
      {
        headerName: `${t('Block_Edit_Assign.Lead_Source_Details')}`,
        field: 'lead_source_details',
        filter: 'agTextColumnFilter',
        cellClass: 'cellClass',
      },

      {
        headerName: `${t('Block_Edit_Assign.Reason')}`,
        headerTooltip: `${t('Block_Edit_Assign.Reason')}`,
        field: 'failed_reason',
        tooltipField: 'failed_reason',
        cellClass: 'cellClass',
        filter: 'agTextColumnFilter',
      },

      {
        headerName: `${t('Block_Edit_Assign.Created_Date')}`,
        headerTooltip: `${t('Block_Edit_Assign.Created_Date')}`,
        field: 'created_at',
        tooltipValueGetter: (params) => {
          return params.data?.created_at
            ? moment(params.data?.created_at).format(AUTH.global_dateformat)
            : ' ';
        },
        cellRenderer: (params) => {
          return params.data?.created_at
            ? moment(params.data?.created_at).format(AUTH.global_dateformat)
            : ' ';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },
      {
        headerName: `${t('Common_Data.Modified')}`,
        headerTooltip: `${t('Common_Data.Modified')}`,
        field: 'updated_at',
        tooltipValueGetter: (params) => {
          return params.data?.updated_at
            ? moment(params.data?.updated_at).format(AUTH.global_dateformat)
            : ' ';
        },
        cellRenderer: (params) => {
          return params.data?.updated_at
            ? moment(params.data?.updated_at).format(AUTH.global_dateformat)
            : ' ';
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          suppressAndOrCondition: true,
        },
      },

      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        cellClass: 'cellClass',
        cellStyle: { textAlign: 'center', padding: '0' },
        width: 90,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },

      {
        headerName: ``,
        field: 'actions',
        cellClass: 'cellClass actionColumn pl-10',
        cellStyle: { paddingLeft },
        type: 'actionColumn',
        filter: false,
        sortable: false,
        pinned: 'right',
        width: 150,
        checkboxSelection:
          AUTH.checkPermission(5, 'delete') || AUTH.checkPermission(5, 'reassign')
            ? true
            : false,
        headerComponentFramework: ActionHeaderComponent,
      },
    ],
  };

  const onCellKeyUp = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          //if (AUTH.checkPermission(13, 'edit')) {
          if (result.key === 'Enter') {
            openEditDrawer(e.data);
          }
          //}
        }
      }
    }
  };

  let columns = [];
  let columnConfig = localStorage.getItem('block_editassign_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['phone', 'email', 'updated_at'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }

    return item;
  });

  return (
    <>
      <div
        className='ag-theme-alpine grid_wrapper'
        style={{
          height: list_fullscreen ? fullscreen_heigthwidth : 'calc(100vh - 11rem)',
        }}
      >
        <AgGridReact
          rowData={BLOCKEDITASSIGNSTORE.list_data}
          modules={AllModules}
          columnDefs={withTooltipCols}
          defaultColDef={{
            ...GridConfig.defaultColDef,
            sortable: true,
            // width: 120,
          }}
          columnTypes={GridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          frameworkComponents={{
            ActionRenderer,
            NoteRenderer,
            PhoneRenderer,
            EmailRenderer,
            // StatusRenderer,
          }}
          onGridReady={BLOCKEDITASSIGNSTORE.setupGrid}
          gridOptions={{
            ...GridConfig.options,
            rowHeight: 30,
            pagination: true,
            paginationPageSize: 100,
            onRowDoubleClicked: (event) => openViewDrawer(event?.data),
          }}
          onColumnResized={BLOCKEDITASSIGNSTORE.onGridChanged}
          onColumnMoved={BLOCKEDITASSIGNSTORE.onGridChanged}
          onColumnPinned={BLOCKEDITASSIGNSTORE.onGridChanged}
          rowSelection='multiple'
          suppressRowClickSelection={true}
          onCellKeyDown={(e) => {
            onCellKeyUp(e);
          }}
          onFilterChanged={(e) => {
            if (
              e.hasOwnProperty('afterFloatingFilter') &&
              BLOCKEDITASSIGNSTORE.selectedGridFilter
            ) {
              BLOCKEDITASSIGNSTORE.setSelectedGridFilter(null);
            }
          }}
        />
      </div>
    </>
  );
});

export default ListComponent;
