import React from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../component/InputComponent';
import { Form, Col, Row } from 'antd';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';
const AutocorrectElement = observer(({ onChange, form }) => {
  const {t} = useTranslation()
  const { AUTH } = useStore();
  return (
    <>
      <div className='w-100'>
        <Row>
          <Form.List name='auto_text_correction' initialValue={[null]}>
            {(fields, { add, remove }) => {
              return fields.map((field, index) => {
                return (
                  <>
                    <Col span={6} key={field.key} className='d-flex quick_notes'>
                      <div className='p-5 w150'>
                        <InputComponent
                          name={[field.name, 'old_text']}
                          key={[field.key, 'old_text']}
                          onChange={onChange}
                          placeholder={`${t('Settings.Old')} ${t('Settings.Text')}`}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div className='p-5 w150'>
                        <InputComponent
                          name={[field.name, 'new_text']}
                          key={[field.key, 'new_text']}
                          onChange={onChange}
                          placeholder={`${t('Settings.New')} ${t('Settings.Text')}`}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div className='d-flex p-5'>
                        {fields.length > 1 && (
                          <img
                            alt=''
                            className={`formIcon mt-5 mr-10`}
                            src={AUTH.GetThemedImage('remove')}
                            onClick={() => {
                              remove(field.name);
                              onChange();
                            }}
                          />
                        )}
                        {index === fields.length - 1 && (
                          <img
                            alt=''
                            className={`formIcon mt-5 mr-10`}
                            src={AUTH.GetThemedImage('add')}
                            onClick={async () => {
                              await add(null);
                              onChange();
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </>
                );
              });
            }}
          </Form.List>
        </Row>
      </div>
      {/* <div className='w-100'>
        <Form.List name='auto_text_correction' initialValue={[null]}>
          {(fields, { add, remove }) => {
            return fields.map((field, index) => {
              return (
                <div className='w-100' key={field.key}>
                  <Row gutter={15}>
                    <Col span={10}>
                      <InputComponent
                        name={[field.name, 'old_text']}
                        key={[field.key, 'old_text']}
                        onChange={onChange}
                        placeholder='Old Text'
                        style={{ width: '100%' }}
                      />
                    </Col>

                    <Col span={9}>
                      <InputComponent
                        name={[field.name, 'new_text']}
                        key={[field.key, 'new_text']}
                        onChange={onChange}
                        placeholder='New Text'
                        style={{ width: '100%' }}
                      />
                    </Col>

                    <Col span={5} className='d-flex'>
                      {fields.length > 1 && (
                        <img
                          alt=''
                          className={`formIcon mt-5 mr-10`}
                          src={AUTH.GetThemedImage('remove')}
                          onClick={() => {
                            remove(field.name);
                            onChange();
                          }}
                        />
                      )}
                      {index === fields.length - 1 && (
                        <img
                          alt=''
                          className={`formIcon mt-5 mr-10`}
                          src={AUTH.GetThemedImage('add')}
                          onClick={async () => {
                            await add(null);
                            onChange();
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              );
            });
          }}
        </Form.List>
      </div>
    */}
    </>
  );
});

export default AutocorrectElement;
