import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, Col, Row, Spin, Drawer, Card } from 'antd';
import { observer } from 'mobx-react';
import { PlusCircleOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { vsmOrganisations, vsmNotify, vsmRoleMapping } from '../../../../config/messages';
import useStore from '../../../../store';
import OrgDetailComponent from '../../RoleMapping/component/OrgDetailComponent';
import InputComponent from '../../../../component/InputComponent';
import RatingElement from '../elements/RatingElement';
import PplDetailComponent from '../../../PeopleManagement/RoleMapping/component/PplDetailComponent';
import { useTranslation } from 'react-i18next';

const MapOrgWithLedger = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(true);
  const [hide, setHide] = useState(false);
  const [ratingHide, setRatingHide] = useState(true);
  const [currentTab, setCurrentTab] = useState(`${t('Common_Data.Organisation')}`);
  const [searchOrgKey, setSearchOrgKey] = useState();
  const [searchROrgKey, setSearchROrgKey] = useState();
  const [fetchRoles, setFetchRoles] = useState(true);
  const [fetchRelatedRole, setFetchRelatedRole] = useState(true);
  const [fetchTags, setFetchTags] = useState(true);
  const [fetchCurrency, setFetchCurrency] = useState(true);
  const [role, setRole] = useState(null);
  const [updateState, setUpdateState] = useState(true);
  const {
    AUTH,
    // AUTH: { user },
    PEOPLE,
    ORGBANKACCOUNTSTORE,
    ORGMAPPINGSTORE,
    ORGMAPPINGSTORE: { getAllTagsList, dropdown_tags_list },
    ORGANISATION: {
      getOrgRoles,
      getRatingToMap,
      getOrgRelatedRoles,
      getOrgCardValue,
      dropdown_RelatedRoles,
      dropdown_ledger_org_list,
      dropdown_ROrg_list,
      dropdown_orgRole_list,
      MapData,
      getOrgCurrentLedgerMap,
      getAllROrg,
      setOrgListNull,
      fetching,
      //organisationValues,
      setOrganisationValues,
      setROrganisationValues,
      rOrganisationValues
    }
  } = useStore();

  // Handle submit and call function to map new record
  const handleSubmit = (data) => {
    setSaving(true);
    data.entity_type = 1;
    data.map_current_ledger = 1;
    data.is_rating = ratingHide ? 0 : 1;
    if (data.rating_table && data.rating_table.length > 0) {
      data.rating_table = data.rating_table.filter(
        (x) => x && x && x.global_exchange_rating_id
      );
      data.rating_table =
        !ratingHide && data.rating_table.length > 0 ? data.rating_table : null;
    }
    MapData(data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmOrganisations.map
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    if (props.visible) {
      setRole(null);
      setOrgListNull(null);
      setOrganisationValues(null);
      setROrganisationValues(null);
      setFetchRoles(true);
      setFetchRelatedRole(true);
      setFetchTags(true);
      setCurrentTab(`${t('Common_Data.Organisation')}`);
      getRatingToMap();
      setRatingHide(true);
    }
  }, [
    props.visible,
    getRatingToMap,
    setOrgListNull,
    setOrganisationValues,
    setROrganisationValues,
    t
  ]);

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = async () => {
    setUpdateState(!updateState);
    if (form.getFieldValue('related_entity_type') === 'Ledger') {
      setHide(true);
    } else {
      setHide(false);
    }
    if (currentTab !== form.getFieldValue('related_entity_type')) {
      await form.resetFields([
        'related_people_id',
        'related_role_id',
        'related_entity_id'
      ]);
      setCurrentTab(form.getFieldValue('related_entity_type'));
      PEOPLE.rPeopleCardValue = null;
      setSearchROrgKey(null);
      setFetchRelatedRole(true);
    }
    if (form.getFieldValue('role_id')) {
      var rating =
        dropdown_orgRole_list &&
        dropdown_orgRole_list.find((x) => x.role_name.toLowerCase() === 'rating agency');
      if (rating && form.getFieldValue('role_id') === rating.id) {
        setRatingHide(false);
      } else {
        setRatingHide(true);
      }
    }
    validateData();
  };

  // call to validate fields
  const validateData = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  // search string for dropdown
  const handleSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchOrgKey(val);
      getOrgCurrentLedgerMap({ search_for: val, related_entity_type: 1 });
    };

    return debounce(loadOptions, 500);
  }, [getOrgCurrentLedgerMap]);

  // search string for dropdown
  const handleROrgSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchROrgKey(val);
      getAllROrg({ search_for: val, related_entity_type: 1 });
    };

    return debounce(loadOptions, 500);
  }, [getAllROrg]);

  // search string for people dropdown
  const handleRPplSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchROrgKey(val);
      PEOPLE.getPeopleSearchList({
        search_for: val,
        related_entity_type: 2
      });
    };

    return debounce(loadOptions, 500);
  }, [PEOPLE]);

  // set related people data in card
  const handlePeopleChange = (data) => {
    if (data) {
      PEOPLE.getPeopleCardValue(data, 'people');
    } else {
      PEOPLE.rPeopleCardValue = null;
    }
  };

  // get organisation data
  const handleOrgChange = (data) => {
    if (data) {
      getOrgCardValue(data, 'org');
    } else {
      setOrganisationValues(null);
    }
  };

  // get related organisation data
  const handleROrgChange = (data) => {
    if (data) {
      getOrgCardValue(data, 'relatedOrg');
    } else {
      setROrganisationValues(null);
    }
  };

  const handleRoleChange = () => {
    if (
      form.getFieldValue('role_id') &&
      form.getFieldValue('related_entity_type') === 'Organisation'
    ) {
      getOrgRelatedRoles({ role_id: form.getFieldValue('role_id') }).then(() =>
        setFetchRelatedRole(false)
      );
      form.resetFields(['related_role_id']);
    }
  };

  const handleChangeRole = () => {
    setRole(form.getFieldValue('role_id'));
    handleRoleChange();
  };

  // reset form and close map form
  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
    setFetchCurrency(true);
    setOrganisationValues(null);
    setROrganisationValues(null);
    ORGMAPPINGSTORE.dropdown_tags_list = null;
    PEOPLE.rPeopleCardValue = null;
  };

  return (
    <Drawer
      title={`${t('Common_Data.Add')} ${t('Common_Data.Organisation')} ${t('Common_Data.Mapping')}`}
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      destroyOnClose={true}
      footer={[
        <div key='1' className='text-right'>
          <Button
            key='1'
            disabled={disabled}
            form='mapform'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
          >
            {t('Common_Data.Save')}
          </Button>
          <Button
            key='2'
            className='ml-10'
            htmlType='button'
            onClick={close}
            shape='round'
          >
            {t('Common_Data.Cancel')}
          </Button>
        </div>
      ]}
    >
      <Form
        form={form}
        id='mapform'
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        onChange={validateData}
        autoComplete='off'
        className='innerForm'
      >
        <Row gutter={24}>
          <Col span={12}>
            <InputComponent
              required
              type='select'
              allowClear
              style={{ width: '77%' }}
              label={`${t('Common_Data.Organisation')}`}
              name='entity_id'
              placeholder={`${t('Common_Data.Organisation')}`}
              onChange={(data) => {
                handleChange(data);
                handleOrgChange(data);
              }}
              options={{
                values: dropdown_ledger_org_list,
                value_key: 'id',
                text_key: 'entity_name',
                rejected_keys: [form.getFieldValue('related_entity_id')]
              }}
              tooltip={`${t('OrganisationManagement.Name_Tooltip')} (${t('OrganisationManagement.Company_Legal_Name')}).`}
              rules={vsmOrganisations.validation.organisation}
              filterOption={false}
              notFoundContent={
                fetching ? (
                  <Spin size='small' />
                ) : searchOrgKey ? (
                  `${t('Common_Data.No_Record_Found')}!`
                ) : null
              }
              onSearch={handleSearch}
              extra={
                AUTH.checkPermission(35, 'add') && (
                  <PlusCircleOutlined
                    className='ml-10'
                    onClick={() => {
                      props.openAddModal('maporg', form);
                    }}
                  />
                )
              }
            />
          </Col>

          <Col span={12}>
            <InputComponent
              required
              type='select'
              allowClear
              showSearch
              label={`${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')}`}
              name='role_id'
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')}`}
              onChange={async () => {
                await handleChangeRole();
                handleChange();
              }}
              options={{
                values: dropdown_orgRole_list,
                value_key: 'id',
                text_key: 'role_name',
                rejected_keys: currentTab !== 'Ledger' && [
                  form.getFieldValue('related_role_id')
                ]
              }}
              tooltip={`${t('OrganisationManagement.Role_Name_Tooltip')}`}
              rules={vsmOrganisations.validation.organisation_role}
              onFocus={() => fetchRoles && getOrgRoles().then(() => setFetchRoles(false))}
              notFoundContent={fetchRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>
          {/* {organisationValues && (
            <Col span={24}>
              <OrgDetailComponent data={organisationValues} />
            </Col>
          )} */}
        </Row>

        <InputComponent
          required
          type='radio_button'
          label={`${t('Common_Data.Relate')} ${t('Common_Data.To')}`}
          name='related_entity_type'
          initialValue={`${t('Common_Data.Organisation')}`}
          onChange={handleChange}
          options={{
            values: [
              { value: 'Organisation', text: `${t('Common_Data.Organisation')}` },
              { value: 'People', text: `${t('Common_Data.People')}` }
            ]
          }}
          tooltip={`${t('OrganisationManagement.Option_To_Select')}: ${t('Common_Data.Organisation')}/${t('Common_Data.People')}/${t('OrganisationManagement.Ledger')} ${t('Common_Data.Name')}.`}
          rules={vsmOrganisations.validation.related_entity_type}
        />
        {currentTab === `${t('OrganisationManagement.Ledger')}` ? null : currentTab === `${t('Common_Data.Organisation')}` ? (
          <Row gutter={24}>
            <Col span={12}>
              <InputComponent
                hidden={hide}
                required
                type='select'
                allowClear
                label={`${t('OrganisationManagement.Related')} ${t('Common_Data.Organisation')}`}
                name='related_entity_id'
                placeholder={`${t('OrganisationManagement.Related')} ${t('Common_Data.Organisation')}`}
                onChange={(data) => {
                  handleChange(data);
                  handleROrgChange(data);
                }}
                options={{
                  values: dropdown_ROrg_list,
                  value_key: 'id',
                  text_key: 'entity_name',
                  rejected_keys: [form.getFieldValue('entity_id')]
                }}
                tooltip={`${t('OrganisationManagement.Related_Organisation_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_organisation}
                filterOption={false}
                notFoundContent={
                  fetching ? (
                    <Spin size='small' />
                  ) : searchROrgKey ? (
                    `${t('Common_Data.No_Record_Found')}!`
                  ) : null
                }
                onSearch={handleROrgSearch}
              />
            </Col>

            <Col span={12}>
              <InputComponent
                hidden={hide}
                disabled={role ? false : true}
                required
                type='select'
                allowClear
                label={`${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                name='related_role_id'
                placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                onChange={handleChange}
                options={{
                  values: dropdown_RelatedRoles,
                  value_key: 'id',
                  text_key: 'role_name',
                  rejected_keys: [form.getFieldValue('role_id')]
                }}
                tooltip={`${t('OrganisationManagement.Related_Role_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_role}
                onFocus={() =>
                  fetchRelatedRole &&
                  getOrgRelatedRoles({
                    role_id: form.getFieldValue('role_id')
                  }).then(() => setFetchRelatedRole(false))
                }
                notFoundContent={
                  fetchRelatedRole ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
              />
            </Col>
            {rOrganisationValues && (
              <Row>
                <Col offset={6} flex={1}>
                  <OrgDetailComponent data={rOrganisationValues} />
                </Col>
              </Row>
            )}
          </Row>
        ) : (
          <Row gutter={24}>
            <Col span={12}>
              <InputComponent
                hidden={hide}
                required
                type='select'
                allowClear
                label={`${t('OrganisationManagement.Related')} ${t('Common_Data.People')}`}
                name='related_entity_id'
                placeholder={`${t('OrganisationManagement.Related')} ${t('Common_Data.People')}`}
                onChange={(data) => {
                  handleChange(data);
                  handlePeopleChange(data);
                }}
                options={{
                  values: PEOPLE.dropdown_RPpl_list,
                  value_key: 'id',
                  text_key: 'entity_name'
                }}
                tooltip={`${t('OrganisationManagement.Related_People_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_people}
                filterOption={false}
                notFoundContent={
                  PEOPLE.fetching ? (
                    <Spin size='small' />
                  ) : searchROrgKey ? (
                    `${t('Common_Data.No_Record_Found')}!`
                  ) : null
                }
                onSearch={handleRPplSearch}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                hidden={hide}
                disabled={role ? false : true}
                required
                type='select'
                allowClear
                label={`${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                name='related_role_id'
                placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Related')} ${t('OrganisationManagement.Role')}`}
                onChange={handleChange}
                options={{
                  values: PEOPLE.dropdown_pplRole_list,
                  value_key: 'id',
                  text_key: 'role_name'
                }}
                tooltip={`${t('OrganisationManagement.People_Role_Relation_Tooltip')}.`}
                rules={vsmOrganisations.validation.related_role}
                onFocus={() =>
                  fetchRelatedRole &&
                  PEOPLE.getPeopleRoles().then(() => setFetchRelatedRole(false))
                }
                notFoundContent={
                  fetchRelatedRole ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
              />
            </Col>
            {PEOPLE.rPeopleCardValue && (
              <Col offset={6} flex={1}>
                <PplDetailComponent data={PEOPLE.rPeopleCardValue} />
              </Col>
            )}
          </Row>
        )}

        <Row gutter={24}>
          <Col span={12}>
            <InputComponent
              mode='tags'
              type='select'
              allowClear
              label={`${t('Common_Data.Tags')}`}
              name='tag_ids'
              placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.More_Tags')}`}
              onChange={handleChange}
              options={{
                values: dropdown_tags_list,
                value_key: 'id',
                text_key: 'tag_name'
              }}
              tooltip={`${t('OrganisationManagement.Tags_Tooltip')}.`}
              rules={vsmOrganisations.validation.tag_name}
              maxTagTextLength={10}
              tokenSeparators={[',']}
              onFocus={() =>
                fetchTags && getAllTagsList().then(() => setFetchTags(false))
              }
              notFoundContent={fetchTags ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              label={`${t('Common_Data.Exchange')} ${t('OrganisationManagement.Reference')} ${t('Common_Data.Id')}`}
              name='exchange_ref_id'
              placeholder={`${t('Common_Data.Exchange')} ${t('OrganisationManagement.Reference')} ${t('Common_Data.Id')}`}
              onChange={handleChange}
              tooltip={`${t('Common_Data.Exchange')} ${t('Common_Data.ID')} ${t('OrganisationManagement.Numerical_Value')} ${t('Common_Data.Organisation')} ${t('OrganisationManagement.Role')} ${t('Common_Data.In')} ${t('Common_Data.Exchange')} 2.0 ${t('OrganisationManagement.Software')}.`}
              rules={vsmOrganisations.validation.exchange_ref_id}
              maxLength={100}
            />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <InputComponent
              label={`${t('OrganisationManagement.Supplier_Insurance')} ${t('Common_Data.No')}.`}
              name='supplier_insurance_no'
              placeholder={`${t('OrganisationManagement.Supplier_Insurance')} ${t('Common_Data.Number')}`}
              onChange={handleChange}
              tooltip={`${t('OrganisationManagement.Supplier_Insurance')} ${t('Common_Data.No')}.`}
              rules={vsmRoleMapping.validation.Insurance}
              maxLength={100}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              label={`${t('OrganisationManagement.Agreed_Early_Payment_Discount')}`}
              name='agreed_early_payment_discount'
              placeholder={`${t('OrganisationManagement.Agreed_Early_Payment_Discount')}`}
              onChange={handleChange}
              tooltip={`${t('OrganisationManagement.Agreed_Early_Payment_Tooltip')}.`}
              rules={vsmRoleMapping.validation.Discount}
              maxLength={100}
            />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <InputComponent
              allowClear
              type='select'
              label={`${t('Common_Data.Currency')}`}
              name='currency_id'
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Organisation')} ${t('Common_Data.Currency')}`}
              onChange={handleChange}
              tooltip={`${t('OrganisationManagement.Currency_Tooltip')}.`}
              onFocus={() =>
                fetchCurrency &&
                ORGBANKACCOUNTSTORE.getCurrencyList().then(() => setFetchCurrency(false))
              }
              notFoundContent={fetchCurrency ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
              options={{
                values: ORGBANKACCOUNTSTORE.dropdown_currency_list,
                value_key: 'id',
                text_key: 'currency_name'
              }}
            />
          </Col>
          <Col span={12}>
            <InputComponent
              label={`${t('OrganisationManagement.CRM')} ${t('Common_Data.ID')}`}
              name='crm_id'
              placeholder={`${t('Common_Data.Enter')} ${t('OrganisationManagement.CRM')} ${t('OrganisationManagement.Refernce')} ${t('Common_Data.ID')}`}
              onChange={handleChange}
              tooltip={`${t('OrganisationManagement.CRM')} ${t('Common_Data.ID')}`}
              rules={vsmRoleMapping.validation.crm}
              maxLength={100}
            />
          </Col>
        </Row>

        {ratingHide ? null : (
          <>
            <Card title={`${t('OrganisationManagement.Rating_Table')}`} className='mb-25'>
              <RatingElement form={form} onChange={validateData} />
            </Card>
            <InputComponent
              required
              allowClear
              type='textarea'
              label={`${t('Common_Data.Description')}`}
              name='additional_description'
              placeholder={`${t('Common_Data.Description')}`}
              onChange={validateData}
              rules={vsmOrganisations.validation.description}
              maxLength={100}
              tooltip={`${t('OrganisationManagement.Description_Tooltip')}.`}
            />
          </>
        )}
      </Form>
    </Drawer>
  );
});
export default MapOrgWithLedger;
