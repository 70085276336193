import React, { useState, useEffect } from 'react';
import { Col } from 'antd';

import { observer } from 'mobx-react';
import useStore from '../../../../store';
import InputComponent from '../../../../component/InputComponent';
import { vsmLeadsList, vsmTransactionReport } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const SoldStageSelectionElement = observer(({ form, handleChange, formDataList }) => {
  const [fetchLeadRecordType, setFetchLeadRecordType] = useState(false);
  const { t } = useTranslation();
  //----------GLOBAL STATE DECLARATION---------------//
  const {
    REPORTSTORE: {
      recordTypeList,
      lead_type_list,
      lead_status_list,
      getStatusList,
      getLeadTypeList,
      previewReportValues,
      getRecordType,
    },
  } = useStore();
  //----------GLOBAL STATE DECLARATION---------------//
  // const [disabled, setDisabled] = useState(true);

  const handleRecordType = (e) => {
    getStatusList(form.getFieldValue('parent_sold_stage'), e).then(() => {
      form.setFieldsValue({
        sold_stage: undefined,
      });
    });
  };

  const handleLeadTypeChange = (e) => {
    getStatusList(e, form.getFieldValue('record_type')).then(() => {
      form.setFieldsValue({
        sold_stage: undefined,
      });
    });
  };

  const handleColdFilterStage = (e) => {
    handleChange();
    if (form?.getFieldValue(`report_type`) === 'summary') {
      getStatusList([], e).then(() => {
        form.setFieldsValue({
          sold_stage: undefined,
        });
      });
      // setDisabled(!disabled);
    } else {
      handleRecordType(e);
    }
  };

  useEffect(() => {
    if (!fetchLeadRecordType) {
      getLeadTypeList(previewReportValues);
      getRecordType();
      setFetchLeadRecordType(true);
    }
  }, [
    fetchLeadRecordType,
    previewReportValues,
    getLeadTypeList,
    getRecordType,
    setFetchLeadRecordType,
  ]);

  return (
    <>
      <Col span={6}>
        {formDataList.includes('record_type') && (
          <InputComponent
            type='select'
            required={form?.getFieldValue(`report_type`) === 'summary' ? true : false}
            rules={form?.getFieldValue(`report_type`) === 'summary' ? vsmLeadsList.validation.lead_record_type : []}
            label={
              form?.getFieldValue(`report_type`) === 'summary'
                ? `${t('Reports.SOLD_Type_to_filter_Stages')}`
                : `${t('Common_Data.Sold')} ${t('Common_Data.Type')}`
            }
            name='record_type'
            placeholder={`${t('Common_Data.Choose')} ${t('Common_Data.Sold')} ${t(
              'Common_Data.Type'
            )}`}
            tooltip={`${t('Common_Data.Sold_Record_Tooltip')}.`}
            onChange={handleColdFilterStage}
            showArrow
            allowClear
            options={{
              values: recordTypeList,
              value_key: 'id',
              text_key: 'record_type_name',
            }}
          />
        )}
      </Col>
      <Col span={9}>
        {formDataList.includes('parent_sold_stage') && (
          <InputComponent
            type='select'
            mode='multiple'
            label={
              form?.getFieldValue(`report_type`) === 'summary'
                ? `${t('Reports.Select_Columns_for_Stage')}`
                : `${t('Common_Data.Sold')} ${t(
                  'Common_Data.Stage'
                )}`
            }
            name='parent_sold_stage'
            placeholder={`${t('Common_Data.Choose')} ${t(
              'Common_Data.Sold')} ${t('Common_Data.Stage')}`}
            tooltip={`${t('Reports.Parent_Sold_Tooltip')}.`}
            rules={vsmTransactionReport.validation.lead_type}
            onChange={(e) => {
              handleChange();
              if (form?.getFieldValue(`report_type`) !== 'summary') {
                handleLeadTypeChange(e);
              }
            }}
            showArrow
            allowClear
            options={{
              values: lead_type_list,
              value_key: 'id',
              text_key: 'status_name',
            }}
            disabled={form?.getFieldValue(`report_type`) !== 'summary' ? false : !(form?.getFieldValue(`record_type`))}
          />
        )}
      </Col>
      <Col span={9}>

        {formDataList.includes('sold_stage') && (
          <InputComponent
            type='select'
            mode={(form?.getFieldValue(`report_type`) === 'count_list') ? '' : 'multiple'}
            placeholder={
              form?.getFieldValue(`report_type`) === 'summary'
                ? `${t('Reports.All_SOLD_Stages')}`
                : `${t('Reports.SOLD_Stage_Title')}`
            }
            showArrow
            allowClear
            label={
              form?.getFieldValue(`report_type`) === 'summary'
                ? `${t('Reports.Select_Columns_for_Stages')}`
                : `${t('Reports.SOLD_Stages')}`
            }
            name='sold_stage'
            tooltip={t('Reports.SOLD_Stage_Tooltip')}
            onChange={handleChange}
            required={(form?.getFieldValue(`report_type`) === 'count_list') ? true : false}
            options={{
              values: lead_status_list,
              value_key: 'id',
              text_key: 'status_name',
            }}
            disabled={form?.getFieldValue(`report_type`) !== 'summary' ? false : !(form?.getFieldValue(`record_type`))}
          />
        )}
      </Col>
    </>
  );
});

export default SoldStageSelectionElement;
