import React from 'react';
import { Button, Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const NoRecordAlert = observer(({ setNoDataAlert, visible }) => {
  const {t} = useTranslation();
  return (
    <Modal
      centered
      title={`${t('Common_Data.No')} ${t('Reports.Data')} ${t('Reports.Filter')}`}
      visible={visible}
      onCancel={() => setNoDataAlert(false)}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          shape='round'
          key='1'
          onClick={() => setNoDataAlert(false)}
          type='primary'
        >
          {t('Reports.OK')}
        </Button>
      ]}
    >
      <Row align='middle'>
        <Col span={4} className='DeleteIcon'>
          <WarningFilled />
        </Col>
        <Col span={20} className='DeleteText'>
          {t('Reports.Repharse_Your_Filter')}.
        </Col>
      </Row>
    </Modal>
  );
});

export default NoRecordAlert;
