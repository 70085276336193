import React, { useState, useEffect, useCallback } from 'react';
import {
  Form,
  Typography,
  Row,
  Col,
  Divider,
  Input,
  Tooltip,
  Button,
  Modal,
  Spin,
  Checkbox,
} from 'antd';
import useStore from '../../store';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { observer } from 'mobx-react';
import '../../scss/page/settings.css';

import { vsmNotify, vsmSettings } from '../../config/messages';
//import SortableComponent from './component/SortableComponent';
import InputComponent from '../../component/InputComponent';
import ShortcutElement from './elements/ShortcutElement';
import AutocorrectElement from './elements/AutocorrectElement';
import ColorElement from './elements/ColorElement';
import QuickNotes from './elements/QuickNotes';
import FailedReasons from './elements/FailedReasons';

import { QuestionCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
import Unauthorized from '../../page/Unauthorized';
import ProductElement from './elements/ProductElement';
import RejectedReasons from './elements/RejectedReasons';
import GenericEmails from './elements/GenericEmails';
import FutureReasons from './elements/FutureReasons';
import ConnectionRoles from './elements/ConnectionRoles';
import KnowledgeClassficationElement from './elements/KnowledgeClassificationElement';
import PersonTelephoneTypeElement from './elements/PersonTelephoneTypeElement';
import PersonEmailTypeElement from './elements/PersonEmailTypeElement';
import PersonAddressTypeElement from './elements/PersonAddressTypeElement';
import OrgTelephoneTypeElement from './elements/OrgTelephoneTypeElement';
import OrgEmailTypeElement from './elements/OrgEmailTypeElement';
import OrgAddressTypeElement from './elements/OrgAddressTypeElement';
//import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';
import { useTranslation } from 'react-i18next';
import { lang_trans_arr } from '../../utils/GlobalFunction';
import i18n from '../../i18n';

const Settings = observer((props) => {
  const [form] = Form.useForm();
  const {
    SETTINGS,
    SETTINGS: { dropdown_timezone_list, getFetchTimezone },
    AUTH: { setTimeZone, setGlobalDateFormate, setGlobalTimeFormate },
    AUTH,
  } = useStore();
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitconfirm, setSubmitconfirm] = useState(false);
  const [formResetvisible, setFormResetvisible] = useState(false);

  //const [textCorrect, setTextCorrect] = useState([{ value: null }]);
  const [bonusPer, setBonusPer] = useState(null);
  const [meetingValue, setMeetingValue] = useState(null);
  const [convertedValue, setConvertedValue] = useState(null);
  const [rpaValue, setRpaValue] = useState(null);
  const [confirmedValue, setConfirmedValue] = useState(null);
  const [tradedValue, setTradedValue] = useState(null);
  const [totalbonusValue, setTotalBonusValue] = useState(0);

  const [meetingDiscount, setMeetingDiscount] = useState(0);
  const [convertedDiscount, setConvertedDiscount] = useState(0);
  const [rpaDiscount, setRpaDiscount] = useState(0);
  const [confirmedDiscount, setConfirmedDiscount] = useState(0);
  const [tradedDiscount, setTradedDiscount] = useState(0);
  const [totalbonusDiscount, setTotalbonusDiscount] = useState(0);
  const [shortcutKeys, setShortcutKeys] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [items, setItems] = useState([
    'Deal',
    'Leads',
    'Opportunity',
    'Meetings',
    'Tickets',
    'Tags',
    'Reminders/Snap Notes',
    'Phone Calls',
  ]);

  const { Title } = Typography;

  const handleSettingForm = (data) => {
    setSaving(true);
    setSubmitconfirm(false);
    if (
      AUTH.user &&
      AUTH.user?.id &&
      AUTH.user?.id === 1 
    ) {
      data.bonus_settings.reminder_priority = items.join();
    }

    data.notification_settings = [];
    if (data?.dm_settings?.tickets_push_notifications) {
      data.notification_settings = [...data.notification_settings, 'TICKET_CREATE', 'TICKET_UPDATE']
    }
    if (data?.dm_settings?.tag_push_notifications) {
      data.notification_settings = [...data.notification_settings, 'TAG_ASSIGNED']
    }
    if (data?.dm_settings?.sold_push_notifications) {
      data.notification_settings = [
        ...data.notification_settings,
        'LEAD_CREATE',
        'LEAD_UPDATE',
        'LEAD_OWNER_UPDATE',
        'LEAD_HOLDER_UPDATE'
      ]
    }
    if (data?.dm_settings?.reminders_push_notifications) {
      data.notification_settings = [...data.notification_settings, 'REMINDER_CREATE', 'REMINDER_UPDATE']
    }


    SETTINGS.SaveSetting(data)
      .then(() => {
        vsmNotify.success({
          message: vsmSettings.add,
        });
        setSaving(false);
        setTimeZone(data.dm_settings.timezone);
        setGlobalDateFormate(data.dm_settings.date_format);
        setGlobalTimeFormate(data.dm_settings.time_format);
        AUTH.setUserOptions(AUTH.token);
      })
      .catch((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            if (x.name.indexOf('.') !== -1) {
              x.name = x.name.split('.');
              x.name.forEach((e, i) => {
                if (!isNaN(parseInt(x.name[i]))) {
                  x.name[i] = parseInt(x.name[i]);
                }
              });
            }
          });
          form.setFields(e.errors);
        }
        setSaving(false);
      })
      .finally(() => setSaving(false));
  };
  const handleFormChange = useCallback(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields.length > 0) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      });
  }, [form]);

  const cancelSubmit = () => {
    setSubmitconfirm(false);
  };
  const onReset = () => {
    setLoading(true);
    setFormResetvisible(false);
    // vsmNotify.success({
    //   message: vsmSettings.cancelResetFormNotify,
    // });
    fetchSettingData();
  };

  const handleSettotal = useCallback(() => {
    var data = form.getFieldsValue().bonus_settings;
    if (data) {
      let bonusvaluetotal =
        parseFloat(data.meetings || 0) +
        parseFloat(data.converted || 0) +
        parseFloat(data.rpa || 0) +
        parseFloat(data.confirmed || 0) +
        parseFloat(data.traded || 0);
      setTotalBonusValue(bonusvaluetotal.toFixed(2));

      setMeetingDiscount(
        parseFloat(
          (parseFloat(data.meetings || 0) * parseFloat(data.team_individual || 0)) / 100
        ).toFixed(2)
      );
      setConvertedDiscount(
        parseFloat(
          (parseFloat(data.converted || 0) * parseFloat(data.team_individual || 0)) / 100
        ).toFixed(2)
      );
      setRpaDiscount(
        parseFloat(
          (parseFloat(data.rpa || 0) * parseFloat(data.team_individual || 0)) / 100
        ).toFixed(2)
      );
      setConfirmedDiscount(
        parseFloat(
          (parseFloat(data.confirmed || 0) * parseFloat(data.team_individual || 0)) / 100
        ).toFixed(2)
      );
      setTradedDiscount(
        parseFloat(
          (parseFloat(data.traded || 0) * parseFloat(data.team_individual || 0)) / 100
        ).toFixed(2)
      );

      let bonusdistotal =
        (parseFloat(data.meetings || 0) * parseFloat(data.team_individual || 0)) / 100 +
        (parseFloat(data.converted || 0) * parseFloat(data.team_individual || 0)) / 100 +
        (parseFloat(data.rpa || 0) * parseFloat(data.team_individual || 0)) / 100 +
        (parseFloat(data.confirmed || 0) * parseFloat(data.team_individual || 0)) / 100 +
        (parseFloat(data.traded || 0) * parseFloat(data.team_individual || 0)) / 100;
      setTotalbonusDiscount(parseFloat(bonusdistotal).toFixed(2));
      handleFormChange();
    }
  }, [form, handleFormChange]);

  const fetchSettingData = useCallback(() => {
    SETTINGS.FetchSetting().then((data) => {
      SETTINGS.getDurationvalues();
      SETTINGS.getReminderHourvalues();
      SETTINGS.getReminderMinutevalues();
      if (data.notification_settings) {
        if (data.notification_settings.includes('TICKET_CREATE')) {
          data.dm_settings.tickets_push_notifications = true;
        }
        if (data.notification_settings.includes('TAG_ASSIGNED')) {
          data.dm_settings.tag_push_notifications = true;
        }
        if (data.notification_settings.includes('LEAD_CREATE')) {
          data.dm_settings.sold_push_notifications = true;
        }
        if (data.notification_settings.includes('REMINDER_CREATE')) {
          data.dm_settings.reminders_push_notifications = true;
        }
      }
      if (data?.country) {
        let selected_timezone = {
          id: data?.country?.id,
          timezone_new:
            '(UTC ' + data?.country?.time_zone + ') ' + data?.country?.country_name,
        };
        setSelectedTimezone(selected_timezone);
      }

      if (data?.dm_settings?.snap_note_send_option) {
        data.dm_settings.snap_note_send_option = moment(
          data.dm_settings.snap_note_send_option
        );
      }

      // Start: To add old color in request
      if (data?.bonus_settings?.notes_category) {
        data.bonus_settings.notes_category = data.bonus_settings.notes_category.map(
          (v) => ({ ...v, old_color_code: v.color_code })
        );
      }

      if (data?.bonus_settings) {
        data.bonus_settings.idle_popup_duration = data.bonus_settings.idle_popup_duration
          ? moment(data.bonus_settings.idle_popup_duration)
          : null;

        data.bonus_settings.shortcut_keys =
          data.bonus_settings.shortcut_keys &&
            data.bonus_settings.shortcut_keys.length > 0
            ? data.bonus_settings.shortcut_keys
            : [null];
        data.bonus_settings.auto_text_correction =
          data.bonus_settings.auto_text_correction &&
            data.bonus_settings.auto_text_correction.length > 0
            ? data.bonus_settings.auto_text_correction
            : [null];
        data.bonus_settings.notes_category =
          data.bonus_settings.notes_category &&
            data.bonus_settings.notes_category.length > 0
            ? data.bonus_settings.notes_category
            : [null];
        data.bonus_settings.products =
          data.bonus_settings.products && data.bonus_settings.products.length > 0
            ? data.bonus_settings.products
            : [null];
      }

      if (AUTH.user && AUTH.user.user_role && AUTH.user.user_role.user_role_id === 1) {
        if (data?.quick_notes) {
          data.quick_notes =
            data.quick_notes && data.quick_notes.length > 0 ? data.quick_notes : [null];
        }
        if (data?.failed_reasons) {
          data.failed_reasons =
            data.failed_reasons && data.failed_reasons.length > 0
              ? data.failed_reasons
              : [null];
        }
        if (data?.rejected_reasons) {
          data.rejected_reasons =
            data.rejected_reasons && data.rejected_reasons.length > 0
              ? data.rejected_reasons
              : [null];
        }
        if (data?.future_reasons) {
          data.future_reasons =
            data.future_reasons && data.future_reasons.length > 0
              ? data.future_reasons
              : [null];
        }
        if (data?.generic_emails) {
          data.generic_emails =
            data.generic_emails && data.generic_emails.length > 0
              ? data.generic_emails
              : [null];
        }
        if (data?.organization_status) {
          data.organization_status =
            data.organization_status && data.organization_status.length > 0
              ? data.organization_status
              : [null];
        }
        if (data?.people_status) {
          data.people_status =
            data.people_status && data.people_status.length > 0
              ? data.people_status
              : [null];
        }
        if (data?.connection_role) {
          data.connection_role =
            data.connection_role && data.connection_role.length > 0
              ? data.connection_role
              : [null];
        }
        if (data?.knowledge_classification) {
          data.knowledge_classification =
            data.knowledge_classification && data.knowledge_classification.length > 0
              ? data.knowledge_classification
              : [null];
        }
      }
      // End: To add old color in request

      form.setFieldsValue(data);

      if (
        AUTH.user &&
        AUTH.user.user_role &&
        AUTH.user.user_role.user_role_id === 1 &&
        data?.bonus_settings
      ) {
        setItems(data?.bonus_settings?.reminder_priority?.split(','));

        setShortcutKeys(
          data.bonus_settings.shortcut_keys && data.bonus_settings.shortcut_keys
        );
        handleSettotal();
      }
      setLoading(false);
    });
  }, [SETTINGS, form, handleSettotal, AUTH]);

  useEffect(() => {
    fetchSettingData();
  }, [fetchSettingData]);

  const handelBonusCal = (targetfield, passedfieldvalue) => {
    if (isNaN(passedfieldvalue)) {
      return false;
    }
    if (isNaN(bonusPer)) {
      return false;
    }

    if (targetfield === 'meetings') {
      setMeetingValue(parseFloat(passedfieldvalue));
      setMeetingDiscount(parseFloat(((passedfieldvalue * bonusPer) / 100).toFixed(2)));
    }
    if (targetfield === 'converted') {
      setConvertedValue(parseFloat(passedfieldvalue));
      setConvertedDiscount(parseFloat(((passedfieldvalue * bonusPer) / 100).toFixed(2)));
    }
    if (targetfield === 'rpa') {
      setRpaValue(parseFloat(passedfieldvalue));
      setRpaDiscount(parseFloat(((passedfieldvalue * bonusPer) / 100).toFixed(2)));
    }
    if (targetfield === 'confirmed') {
      setConfirmedValue(parseFloat(passedfieldvalue));
      setConfirmedDiscount(parseFloat(((passedfieldvalue * bonusPer) / 100).toFixed(2)));
    }
    if (targetfield === 'traded') {
      setTradedValue(parseFloat(passedfieldvalue));
      setTradedDiscount(parseFloat(((passedfieldvalue * bonusPer) / 100).toFixed(2)));
    }

    handleFormChange();
  };
  const handelBonusPerCal = (percevalues) => {
    if (isNaN(percevalues)) {
      return false;
    }
    setBonusPer(parseFloat(percevalues));
    setMeetingDiscount(parseFloat(((meetingValue * percevalues) / 100).toFixed(2)));
    setConvertedDiscount(parseFloat(((convertedValue * percevalues) / 100).toFixed(2)));

    setRpaDiscount(parseFloat(((rpaValue * percevalues) / 100).toFixed(2)));
    setConfirmedDiscount(parseFloat(((confirmedValue * percevalues) / 100).toFixed(2)));
    setTradedDiscount(parseFloat(((tradedValue * percevalues) / 100).toFixed(2)));

    handleFormChange();
  };

  const DragHandle = SortableHandle(() => <span>::</span>);
  const SortableItem = SortableElement(({ value }) => (
    <li tabIndex={0}>
      <DragHandle /> {value}
    </li>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className='sortablelist'>
        {items.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} value={value} />
        ))}
      </ul>
    );
  });
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  // Language Translation Change Event 

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e);
  }

  return (
    <>
      {!AUTH.checkPermission(25, 'change-settings') ? (
        <Unauthorized />
      ) : (
        <>
          <Title className='page-title'>{t('Common_Data.Settings')}</Title>
          <section className='main-content'>
            <Spin size='medium' spinning={loading} tip={`${t('Common_Data.Load')}`}>
              <Form
                form={form}
                id='SettingForm'
                className='innerForm'
                labelCol={{ span: 24 }}
                onFinish={handleSettingForm}
                onChange={handleFormChange}
              >
                <Form.List name='dm_settings'>
                  {(fields) => (
                    <>
                      <Title level={3} className='page-title'>
                        {t('Common_Data.Date')} & {t('Common_Data.Time')}
                      </Title>
                      <Row gutter={15}>
                        <Col span={6}>
                          <InputComponent
                            name={['timezone']}
                            label={`${t('Settings.Timezone')}`}
                            required
                            //placeholder='Select Timezone'
                            type='select'
                            tooltip={`${t('Settings.Timezone_Tooltip')}.`}
                            onFocus={getFetchTimezone}
                            options={{
                              values:
                                dropdown_timezone_list ||
                                (selectedTimezone && [selectedTimezone]),
                              value_key: 'id',
                              text_key: 'timezone_new',
                            }}
                            notFoundContent={
                              dropdown_timezone_list ? (
                                <Spin size='small' />
                              ) : (
                                `${t('Common_Data.No_Record_Found')}.`
                              )
                            }
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.timezone}
                          />
                        </Col>
                        <Col span={6}>
                          <InputComponent
                            name={['language']}
                            label={t('Common_Data.Language')}
                            required
                            type='select'
                            tooltip={t('Common_Data.Language')}
                            options={{
                              values: lang_trans_arr,
                              value_key: 'id',
                              text_key: 'language',
                            }}
                            onChange={(e) => {
                              handleFormChange();
                              handleLanguageChange(e);
                            }}
                            rules={vsmSettings.validation.language}
                          />
                        </Col>
                        <Col span={6}>
                          <InputComponent
                            name={['date_format']}
                            label={`${t('Common_Data.Date')} ${t('Common_Data.Format')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Date')} ${t('Common_Data.Format')}`}
                            type='select'
                            tooltip={`${t('Settings.Date_Format_Tooltip')}.`}
                            options={{
                              values: [
                                {
                                  value: 'YYYY-MM-DD',
                                  text: 'YYYY-MM-DD',
                                },
                                {
                                  value: 'MM-DD-YYYY',
                                  text: 'MM-DD-YYYY',
                                },
                                {
                                  value: 'DD-MM-YYYY',
                                  text: 'DD-MM-YYYY',
                                },
                              ],
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.date_format}
                          />
                        </Col>
                        <Col span={6}>
                          <InputComponent
                            name={['time_format']}
                            label={`${t('Common_Data.Time')} ${t('Common_Data.Format')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Time')} ${t('Common_Data.Format')}`}
                            type='select'
                            tooltip={`${t('Settings.Time_Format_Tooltip')}.`}
                            options={{
                              values: [
                                {
                                  value: 'HH:mm',
                                  text: `24-${t('Settings.Hours')} (HH:mm)`,
                                },
                                {
                                  value: 'hh:mm a',
                                  text: `12-${t('Settings.Hours')} (hh:mm a)`,
                                },
                              ],
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.time_format}
                          />
                        </Col>
                      </Row>

                      <Title level={3} className='page-title'>
                        {t('Settings.Snap')} {t('Common_Data.Note')} {t('Settings.Hourglass')} ({t('Settings.In')} {t('Settings.Minutes')})
                      </Title>
                      <Row gutter={15}>
                        <Col span={4}>
                          <InputComponent
                            name={['organization_hourglass']}
                            label={`${t('Common_Data.Organisation')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Duration_Tooltip')}.`}
                            onChange={handleFormChange}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            rules={vsmSettings.validation.organization_hourglass}
                          />
                        </Col>
                        <Col span={4}>
                          <InputComponent
                            name={['people_hourglass']}
                            label={`${t('Common_Data.People')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.People_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.people_hourglass}
                          />
                        </Col>
                        <Col span={4}>
                          <InputComponent
                            name={['deal_hourglass']}
                            label={`${t('Common_Data.Deal')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Deal_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.deal_hourglass}
                          />
                        </Col>
                        <Col span={4}>
                          <InputComponent
                            name={['ticket_hourglass']}
                            label={`${t('Settings.Ticket')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Ticket_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.ticket_hourglass}
                          />
                        </Col>
                        <Col span={4}>
                          <InputComponent
                            name={['note_hourglass']}
                            label={`${t('Common_Data.Note')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Note_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.note_hourglass}
                          />
                        </Col>
                      </Row>

                      <Title level={3} className='page-title'>
                        {t('Common_Data.Screen')} {t('Settings.Hourglass')} ({t('Settings.In')} {t('Settings.Minutes')})
                      </Title>
                      <Row gutter={15}>
                        <Col span={4}>
                          <InputComponent
                            name={['screen_organization_hourglass']}
                            label={`${t('Common_Data.Organisations')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Organisation_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_organization_hourglass}
                          />
                        </Col>
                        <Col span={4}>
                          <InputComponent
                            name={['screen_people_hourglass']}
                            label={`${t('Common_Data.People')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Screen_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_people_hourglass}
                          />
                        </Col>

                        <Col span={4}>
                          <InputComponent
                            name={['screen_unused_leads_hourglass']}
                            label={`${t('Common_Data.Unused')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Unused_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_unused_leads_hourglass}
                          />
                        </Col>

                        <Col span={4}>
                          <InputComponent
                            name={['screen_used_leads_hourglass']}
                            label={`${t('Settings.Used')} ${t('LeadListing.Leads')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Used_Lead_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_used_leads_hourglass}
                          />
                        </Col>

                        <Col span={4}>
                          <InputComponent
                            name={['screen_opportunity_hourglass']}
                            label={`${t('Common_Data.Opportunities')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Opportunities_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_opportunity_hourglass}
                          />
                        </Col>
                        <Col span={4}>
                          <InputComponent
                            name={['screen_deals_hourglass']}
                            label={`${t('LeadListing.Deals')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Deals_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_deals_hourglass}
                          />
                        </Col>
                      </Row>
                      <Row gutter={15}>
                        <Col span={4}>
                          <InputComponent
                            name={['screen_tags_hourglass']}
                            label={`${t('Common_Data.Tags')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Tags_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_tags_hourglass}
                          />
                        </Col>
                        <Col span={4}>
                          <InputComponent
                            name={['screen_ticket_hourglass']}
                            label={`${t('Common_Data.Tickets')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Tickets_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_ticket_hourglass}
                          />
                        </Col>
                        <Col span={4}>
                          <InputComponent
                            name={['screen_reminder_hourglass']}
                            label={`${t('Common_Data.Reminder')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Reminder_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_reminder_hourglass}
                          />
                        </Col>
                        <Col span={4}>
                          <InputComponent
                            name={['screen_note_hourglass']}
                            label={`${t('LeadListing.Notes')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')}`}
                            type='select'
                            tooltip={`${t('Settings.Notes_Tooltip')}.`}
                            onFocus={SETTINGS.getDurationvalues}
                            options={{
                              values: SETTINGS.dropdown_duration_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.screen_note_hourglass}
                          />
                        </Col>
                      </Row>

                      <Title level={3} className='page-title'>
                        {t('Common_Data.Settings')} {t('Common_Data.For')} {t('Common_Data.Reminders')} {t('Settings.Calling')}
                      </Title>
                      <Row gutter={15}>
                        <Col span={6}>
                          <InputComponent
                            name={['reminder_due_days']}
                            label={`${t('Common_Data.Reminder')} ${t('Common_Data.Days')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Common_Data.Days')}`}
                            type='select'
                            tooltip={`${t('Settings.Organisation_Tooltip')}.`}
                            options={{
                              values: [
                                {
                                  text: `1 Day`,
                                  value: 1,
                                },
                                {
                                  text: `2 Days`,
                                  value: 2,
                                },
                                {
                                  text: `3 Days`,
                                  value: 3,
                                },
                                {
                                  text: `4 Days`,
                                  value: 4,
                                },
                                {
                                  text: `5 Days`,
                                  value: 5,
                                },
                                {
                                  text: `6 Days`,
                                  value: 6,
                                },
                                {
                                  text: `7 Days`,
                                  value: 7,
                                },
                                {
                                  text: `8 Days`,
                                  value: 8,
                                },
                                {
                                  text: `9 Days`,
                                  value: 9,
                                },
                                {
                                  text: `10 Days`,
                                  value: 10,
                                },
                              ],
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.reminder_due_days}
                          />
                        </Col>
                        <Col span={6}>
                          <InputComponent
                            name={['reminder_due_hours']}
                            label={`${t('Common_Data.Reminder')} ${t('Common_Data.Hours')}`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Common_Data.Hours')}`}
                            type='select'
                            tooltip={`${t('Settings.Organisation_Tooltip')}.`}
                            onFocus={SETTINGS.getReminderHourvalues}
                            options={{
                              values: SETTINGS.dropdown_reminder_hour_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.reminder_due_hours}
                          />
                        </Col>

                        <Col span={6}>
                          <InputComponent
                            name={['reminder_due_mins']}
                            label={`${t('Common_Data.Reminder')} ${t('Common_Data.Minute')}s`}
                            required
                            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Common_Data.Minute')}s`}
                            type='select'
                            tooltip={`${t('Settings.Organisation_Tooltip')}.`}
                            onFocus={SETTINGS.getReminderMinutevalues}
                            options={{
                              values: SETTINGS.dropdown_reminder_minute_list,
                            }}
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.reminder_due_mins}
                          />
                        </Col>
                      </Row>

                      <div className='ant-form-item-label'>
                        <label
                          className='ant-form-item-required'
                          title={`${t('Settings.Snap')} ${t('Common_Data.Note')} ${t('Common_Data.Send')} ${t('Settings.Option')}`}
                        >
                          <Title level={3} className='page-title'>
                            {t('Settings.Snap')} {t('Common_Data.Note')} {t('Common_Data.Send')} {t('Settings.Option')}
                            <Tooltip title={`${t('Settings.Send_Option_Tooltip')}.`}>
                              <QuestionCircleOutlined className='ml-5' />
                            </Tooltip>
                          </Title>
                        </label>
                      </div>

                      <Row gutter={15}>
                        <Col span={3}>
                          <InputComponent
                            name={['snap_note_send_option']}
                            placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Time')}`}
                            type='time'
                            // use12Hours
                            // format='h:mm'
                            tooltip=''
                            onChange={handleFormChange}
                            rules={vsmSettings.validation.snap_note_send_option}
                          />
                        </Col>
                      </Row>

                      {/* Notifcation Settings  */}

                      <div className='ant-form-item-label'>
                        <label
                          className='ant-form-item-required'
                          title={t('Settings.Notification_Settings')}
                        >
                          <Title level={3} className='page-title'>
                            {t('Settings.Notification_Settings')}
                            <Tooltip title={`Notification Settings`}>
                              <QuestionCircleOutlined className='ml-5' />
                            </Tooltip>
                          </Title>
                        </label>
                      </div>
                      <Row gutter={15} className='mb-5'>
                        <Col xs={{ span: 3 }}>
                          <strong>{t('Settings.Settings_For')}</strong>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <strong>{t('Settings.Alerts')}</strong>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <strong>{t('Common_Data.Emails')}</strong>
                        </Col>
                      </Row>
                      <Row gutter={15}>
                        <Col xs={{ span: 3 }}>
                          <div className='ant-form-item-label mb-0'>
                            <label className='mb-0' title={t('Settings.Tag_Assignments')}>
                              {t('Settings.Tag_Assignments')}
                            </label>
                          </div>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <Form.Item
                            noStyle
                            name={['tag_push_notifications']}
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={handleFormChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <Form.Item
                            noStyle
                            name={['tag_email_notifications']}
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={handleFormChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={15}>
                        <Col xs={{ span: 3 }}>
                          <div className='ant-form-item-label mb-0'>
                            <label className='mb-0' title={t('Settings.Tickets_Assignments')}>
                              {t('Settings.Tickets_Assignments')}
                            </label>
                          </div>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <Form.Item
                            noStyle
                            name={['tickets_push_notifications']}
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={handleFormChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <Form.Item
                            noStyle
                            name={['tickets_email_notifications']}
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={handleFormChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={15}>
                        <Col xs={{ span: 3 }}>
                          <div className='ant-form-item-label mb-0'>
                            <label className='mb-0' title={t('Settings.SOLD_Assignments')}>
                              {t('Settings.SOLD_Assignments')}
                            </label>
                          </div>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <Form.Item
                            noStyle
                            name={['sold_push_notifications']}
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={handleFormChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <Form.Item
                            noStyle
                            name={['sold_email_notifications']}
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={handleFormChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={15}>
                        <Col xs={{ span: 3 }}>
                          <div className='ant-form-item-label mb-0'>
                            <label className='mb-0' title={t('Common_Data.Reminders')}>
                              {t('Common_Data.Reminders')}
                            </label>
                          </div>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <Form.Item
                            noStyle
                            name={['reminders_push_notifications']}
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={handleFormChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 2 }} className='text-center'>
                          <Form.Item
                            noStyle
                            name={['reminders_email_notifications']}
                            valuePropName='checked'
                          >
                            <Checkbox
                              onChange={handleFormChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form.List>

                {AUTH.user &&
                  AUTH.user?.id &&
                  AUTH.user?.id === 1 && (
                    <>
                      <Divider />
                      <Form.List name='bonus_settings'>
                        {(fields) => (
                          <>
                            <Row gutter={[15, 15]}>
                              <Col md={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 7 }}>
                                <Title level={3} className='page-title'>
                                  {t('Settings.Bonus_Figures')}
                                </Title>
                                <Row gutter={10}>
                                  <Col xs={{ span: 8 }}>
                                    <strong>{t('Settings.Targets')}</strong> <br />
                                    <div className='ant-form-item-label'>
                                      <label className='ant-form-item-required' title={`${t('Settings.Calls')}`}>
                                        {t('Settings.Calls')}
                                        <Tooltip title={`${t('Settings.Calls_Tooltip')}.`}>
                                          <QuestionCircleOutlined className='ml-5' />
                                        </Tooltip>
                                      </label>
                                    </div>
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <strong>{t('Settings.Individual')}</strong> <br />
                                    <Input bordered={false} value='100%' />
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <strong>{t('Settings.Team')} (%)</strong> <br />
                                    {/* <InputComponent
                                      name={['team_individual']}
                                      required
                                      placeholder='Bonus Figures'
                                      type='text'
                                      tooltip=''
                                      onChange={(e) => handelBonusPerCal(e.target.value)}
                                      onBlur={handleSettotal}
                                      rules={vsmSettings.validation.teamIndividual}
                                    /> */}
                                    <InputComponent
                                      name={['team_individual']}
                                      required
                                      placeholder={`${t('Settings.Bonus_Figures')}`}
                                      type='number'
                                      min={0}
                                      max={100}
                                      onChange={(e) => handelBonusPerCal(e)}
                                      onBlur={() => {
                                        setTimeout(() => {
                                          handleSettotal();
                                        }, 1);
                                      }}
                                      rules={vsmSettings.validation.teamIndividual}
                                    />
                                  </Col>
                                  <Col xs={{ span: 24 }}>
                                    <strong>{t('Reports.Results')}</strong>
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <div className='ant-form-item-label'>
                                      <label
                                        className='ant-form-item-required'
                                        title={`${t('Reports.Meetings')}`}
                                      >
                                        {t('Reports.Meetings')}
                                        <Tooltip title={`${t('Settings.Meeting_Tooltip')}.`}>
                                          <QuestionCircleOutlined className='ml-5' />
                                        </Tooltip>
                                      </label>
                                    </div>
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <InputComponent
                                      name={['meetings']}
                                      required
                                      type='text'
                                      tooltip=''
                                      onChange={(e) =>
                                        handelBonusCal(e.target.id, e.target.value)
                                      }
                                      onBlur={handleSettotal}
                                      initialValue={meetingValue}
                                      rules={vsmSettings.validation.meetings}
                                    />
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <Input
                                      bordered={false}
                                      value={meetingDiscount}
                                      className='text-center'
                                      name={['meetingDiscount']}
                                    />
                                  </Col>

                                  <Col xs={{ span: 8 }}>
                                    <div className='ant-form-item-label'>
                                      <label
                                        className='ant-form-item-required'
                                        title={`${t('Settings.Converted')}`}
                                      >
                                        {t('Settings.Converted')}
                                        <Tooltip title={`${t('Settings.Converted_Tooltip')}.`}>
                                          <QuestionCircleOutlined className='ml-5' />
                                        </Tooltip>
                                      </label>
                                    </div>
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <InputComponent
                                      name={['converted']}
                                      required
                                      type='text'
                                      tooltip=''
                                      onChange={(e) =>
                                        handelBonusCal(e.target.id, e.target.value)
                                      }
                                      onBlur={handleSettotal}
                                      rules={vsmSettings.validation.converted}
                                      initialValue={convertedValue}
                                    />
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <Input
                                      bordered={false}
                                      value={convertedDiscount}
                                      className='text-center'
                                    />
                                  </Col>

                                  <Col xs={{ span: 8 }}>
                                    <div className='ant-form-item-label'>
                                      <label className='ant-form-item-required' title={`${t('Common_Data.RPA')}`}>
                                        {t('Common_Data.RPA')}
                                        <Tooltip title={`${t('Settings.RPA_Title')}.`}>
                                          <QuestionCircleOutlined className='ml-5' />
                                        </Tooltip>
                                      </label>
                                    </div>
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <InputComponent
                                      name={['rpa']}
                                      required
                                      type='text'
                                      tooltip=''
                                      onBlur={handleSettotal}
                                      onChange={(e) =>
                                        handelBonusCal(e.target.id, e.target.value)
                                      }
                                      rules={vsmSettings.validation.rpa}
                                      initialValue={rpaValue}
                                    />
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <Input
                                      bordered={false}
                                      value={rpaDiscount}
                                      className='text-center'
                                    />
                                  </Col>

                                  <Col xs={{ span: 8 }}>
                                    <div className='ant-form-item-label'>
                                      <label
                                        className='ant-form-item-required'
                                        title={`${t('Settings.Confirmed')}`}
                                      >
                                        {t('Settings.Confirmed')}
                                        <Tooltip title={`${t('Settings.Confirmed_Tooltip')}.`}>
                                          <QuestionCircleOutlined className='ml-5' />
                                        </Tooltip>
                                      </label>
                                    </div>
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <InputComponent
                                      name={['confirmed']}
                                      required
                                      type='text'
                                      onChange={(e) =>
                                        handelBonusCal(e.target.id, e.target.value)
                                      }
                                      onBlur={handleSettotal}
                                      rules={vsmSettings.validation.confirmed}
                                      initialValue={confirmedValue}
                                    />
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <Input
                                      bordered={false}
                                      value={confirmedDiscount}
                                      className='text-center'
                                    />
                                  </Col>

                                  <Col xs={{ span: 8 }}>
                                    <div className='ant-form-item-label'>
                                      <label
                                        className='ant-form-item-required'
                                        title={`${t('Settings.Traded')}`}
                                      >
                                        {t('Settings.Traded')}
                                        <Tooltip title={`${t('Settings.Traded_Tooltip')}.`}>
                                          <QuestionCircleOutlined className='ml-5' />
                                        </Tooltip>
                                      </label>
                                    </div>
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <InputComponent
                                      name={['traded']}
                                      required
                                      type='text'
                                      onChange={(e) =>
                                        handelBonusCal(e.target.id, e.target.value)
                                      }
                                      onBlur={handleSettotal}
                                      rules={vsmSettings.validation.traded}
                                      initialValue={tradedValue}
                                    />
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <Input
                                      bordered={false}
                                      value={tradedDiscount}
                                      className='text-center'
                                    />
                                  </Col>

                                  <Col xs={{ span: 8 }}>
                                    <Input bordered={false} value='Bonus Value' />
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <Input
                                      bordered={false}
                                      value={totalbonusValue}
                                      className='text-center'
                                    />
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <Input
                                      bordered={false}
                                      value={totalbonusDiscount}
                                      className='text-center'
                                    />
                                  </Col>

                                  <Col xs={{ span: 8 }}>
                                    <Input bordered={false} value='Difference ' />
                                  </Col>
                                  <Col xs={{ span: 8 }}>
                                    <Input
                                      bordered={false}
                                      value='0.00'
                                      className='text-center'
                                    />
                                  </Col>
                                </Row>

                                <Row gutter={[15, 15]} className='mt-20'>
                                  <Col
                                    //flex='300px'
                                    sm={{ span: 24 }}
                                  // className='ml-10'
                                  >
                                    <Title level={3} className='page-title'>
                                      {t('Common_Data.Assign')} {t('Settings.Shortcut')} {t('Settings.Keys')}{' '}
                                      <Tooltip title={`${t('Settings.Shortcut_Tooltip')}.`}>
                                        <QuestionCircleOutlined />
                                      </Tooltip>
                                    </Title>
                                    <ShortcutElement
                                      onChange={handleFormChange}
                                      form={form}
                                      shortcutKeys={{
                                        get: shortcutKeys,
                                        set: setShortcutKeys,
                                      }}
                                    />
                                  </Col>

                                </Row>
                              </Col>
                              <Col
                                md={{ span: 24 }}
                                lg={{ span: 12 }}
                                xl={{ span: 12 }}
                                xxl={{ span: 5 }}
                              >
                                <Title level={3} className='page-title'>
                                  {t('Common_Data.Set_Reminders')} {t('Common_Data.Priority')}&nbsp;
                                  <Tooltip title={`${t('Settings.Set_Reminder_Tooltip')}.`}>
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </Title>
                                <Row gutter={10}>
                                  <Col xs={{ span: 24 }}>
                                    <SortableList items={items} onSortEnd={onSortEnd} />
                                    <Title level={3} className='page-title'>
                                      {t('Common_Data.Week')} {t('Common_Data.Number')}{' '}
                                      <Tooltip title={`${t('Settings.Week_Number_Tooltip')}.`}>
                                        <QuestionCircleOutlined />
                                      </Tooltip>
                                    </Title>
                                    <InputComponent
                                      name={['week_number']}
                                      type='text'
                                      //tooltip=''
                                      //label='Week Number'
                                      required
                                      disabled={true}
                                      onChange={handleFormChange}
                                      rules={vsmSettings.validation.week_number}
                                    />
                                  </Col>

                                  <Col
                                    sm={{ span: 24 }}
                                  // className='ml-10'
                                  >
                                    <div className='ant-form-item-label'>
                                      <label
                                        className='ant-form-item-required'
                                        title={`${t('Common_Data.Idle')} ${t('Common_Data.Time')} ${t('Settings.Duration')}`}
                                      >
                                        <Title level={3} className='page-title'>
                                          {t('Common_Data.Idle')} {t('Common_Data.Time')} {t('Settings.Duration')}
                                          <Tooltip title={`${t('Settings.Time_Duration_Tooltip')}.`}>
                                            <QuestionCircleOutlined className='ml-5' />
                                          </Tooltip>
                                        </Title>
                                      </label>
                                    </div>
                                    <InputComponent
                                      name={['idle_popup_duration']}
                                      placeholder={`${t('Common_Data.Select')} ${t('Settings.Duration')} (${t('Common_Data.Minute')}/${t('Common_Data.Seconds')})`}
                                      type='time'
                                      //use12Hours
                                      showNow={false}
                                      format='m:ss'
                                      tooltip=''
                                      onChange={handleFormChange}
                                      rules={
                                        vsmSettings.validation.investment_hours_idle_duration
                                      }
                                    />
                                    <div className='ant-form-item-label'>
                                      <label
                                        className='ant-form-item-required'
                                        title={`${t('Settings.SS')}&${t('Settings.Max_Length')}`}
                                      >
                                        <Title level={3} className='page-title'>
                                          {t('Settings.SS')}&{t('Settings.Max_Length')}
                                          <Tooltip title={`${t('Settings.SS')}&${t('Settings.Max_Length')}.`}>
                                            <QuestionCircleOutlined className='ml-5' />
                                          </Tooltip>
                                        </Title>
                                      </label>
                                    </div>
                                    <InputComponent
                                      name={['sss_max_character_length']}
                                      placeholder=''
                                      type='text'
                                      tooltip=''
                                      onChange={handleFormChange}
                                      rules={vsmSettings.validation.maxcharacterLength}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col //flex='auto'
                                sm={{ span: 24 }}
                                xl={{ span: 12 }}
                                xxl={{ span: 5 }}
                                className='ml-20'
                              >
                                <Title level={3} className='page-title'>
                                  {t('Common_Data.Products')}{' '}
                                  <Tooltip title={`${t('Settings.Product_Tooltip')}.`}>
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </Title>
                                <ProductElement onChange={handleFormChange} form={form} />
                              </Col>
                              <Col //flex='auto'
                                sm={{ span: 24 }}
                                xl={{ span: 12 }}
                                xxl={{ span: 5 }}
                                className='ml-20'
                              >
                                <Title level={3} className='page-title'>
                                  {t('Common_Data.History')} {t('Settings.Category')}{' '}
                                  <Tooltip title={`${t('Settings.History_Category_Tooltip')}.`}>
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </Title>
                                <ColorElement onChange={handleFormChange} form={form} />
                              </Col>
                            </Row>
                          </>
                        )}
                      </Form.List>
                      <Divider />
                      <Row gutter={[15, 15]}>
                        <Col flex='auto'>
                          <div className='ant-form-item-label'>
                            <label className='ant-form-item-required' title={`${t('Common_Data.Quick')} ${t('LeadListing.Notes')}`}>
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Quick')} {t('LeadListing.Notes')}
                                <Tooltip title={`${t('Settings.Quick_Note_Tooltip')}.`}>
                                  <QuestionCircleOutlined className='ml-5' />
                                </Tooltip>
                              </Title>
                            </label>
                          </div>
                          <QuickNotes onChange={handleFormChange} form={form} />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={[15, 15]}>
                        <Col flex='auto'>
                          <Title level={3} className='page-title mt-10'>
                            {t('Settings.Auto_Text_Correction')}{' '}
                            <Tooltip title={`${t('Settings.Auto_Text_Correction_Tooltip')}.`}>
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Title>
                          <AutocorrectElement onChange={handleFormChange} form={form} />
                        </Col>
                      </Row>

                      <Divider />

                      <Row gutter={[15]}>
                        <Col flex='auto'>
                          <div className='ant-form-item-label'>
                            <label className='ant-form-item-required' title={`${t('Common_Data.Failed')} ${t('Settings.Reasons')}`}>
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Failed')} {t('Settings.Reasons')}
                                <Tooltip title={`${t('Common_Data.Failed')} ${t('Settings.Reasons')}`}>
                                  <QuestionCircleOutlined className='ml-5' />
                                </Tooltip>
                              </Title>
                            </label>
                          </div>
                          <FailedReasons onChange={handleFormChange} form={form} />
                        </Col>
                      </Row>

                      <Divider />

                      <Row gutter={[15]}>
                        <Col flex='auto'>
                          <div className='ant-form-item-label'>
                            <label
                              className='ant-form-item-required'
                              title={`${t('Common_Data.Rejected')} ${t('Settings.Reasons')}`}
                            >
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Rejected')} {t('Settings.Reasons')}
                                <Tooltip title={`${t('Common_Data.Rejected')} ${t('Settings.Reasons')}`}>
                                  <QuestionCircleOutlined className='ml-5' />
                                </Tooltip>
                              </Title>
                            </label>
                          </div>
                          <RejectedReasons onChange={handleFormChange} form={form} />
                        </Col>
                      </Row>

                      <Divider />

                      <Row gutter={[15]}>
                        <Col flex='auto'>
                          <div className='ant-form-item-label'>
                            <label className='ant-form-item-required' title={`${t('Common_Data.Future')} ${t('Settings.Reasons')}`}>
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Future')} {t('Settings.Reasons')}
                                <Tooltip title={`${t('Common_Data.Future')} ${t('Settings.Reasons')}`}>
                                  <QuestionCircleOutlined className='ml-5' />
                                </Tooltip>
                              </Title>
                            </label>
                          </div>
                          <FutureReasons onChange={handleFormChange} form={form} />
                        </Col>
                      </Row>

                      <Divider />

                      <Row gutter={[15]}>
                        <Col flex='auto'>
                          <div className='ant-form-item-label'>
                            <label
                              className='ant-form-item-required'
                              title={`${t('Settings.Generic')} ${t('Common_Data.Emails')}`}
                            >
                              <Title level={3} className='page-title'>
                                {t('Settings.Generic')} {t('Common_Data.Emails')}
                                <Tooltip title={`${t('Settings.Generic')} ${t('Common_Data.Emails')}`}>
                                  <QuestionCircleOutlined className='ml-5' />
                                </Tooltip>
                              </Title>
                            </label>
                          </div>
                          <GenericEmails onChange={handleFormChange} form={form} />
                        </Col>
                      </Row>

                      <Divider />

                      <Row gutter={[15]}>
                        <Col flex='auto'>
                          <div className='ant-form-item-label'>
                            <label
                              className='ant-form-item-required'
                              title={`${t('Common_Data.Connection')} ${t('OrganisationManagement.Roles')}`}
                            >
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Connection')} {t('OrganisationManagement.Roles')}
                                <Tooltip title={`${t('Common_Data.Connection')} ${t('OrganisationManagement.Roles')}`}>
                                  <QuestionCircleOutlined className='ml-5' />
                                </Tooltip>
                              </Title>
                            </label>
                          </div>
                          <ConnectionRoles onChange={handleFormChange} form={form} />
                        </Col>
                      </Row>

                      <Divider />

                      <Row gutter={[15]}>
                        <Col flex='auto'>
                          <div className='ant-form-item-label'>
                            <label
                              className='ant-form-item-required'
                              title={`${t('Common_Data.Knowledge')} ${t('Common_Data.Classification')}`}
                            >
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Knowledge')} {t('Common_Data.Classification')}
                                <Tooltip title={`${t('Common_Data.Knowledge')} ${t('Common_Data.Classification')}`}>
                                  <QuestionCircleOutlined className='ml-5' />
                                </Tooltip>
                              </Title>
                            </label>
                          </div>
                          <KnowledgeClassficationElement
                            onChange={handleFormChange}
                            form={form}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={[15]}>
                        <Col className='gutter-row' span={24}>
                          <div className='ant-form-item-label'>
                            <Title level={3} className='page-title'>
                              {t('Common_Data.People')}
                              <Tooltip title={`${t('Common_Data.People')}`}>
                                <QuestionCircleOutlined className='ml-5' />
                              </Tooltip>
                            </Title>
                          </div>
                        </Col>
                        <Col className='gutter-row' span={24}>
                          <div className='ant-form-item-label'>
                            <label className='ant-form-item-required' title={`${t('Common_Data.Telephone')}`}>
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Telephone')} {t('Common_Data.Types')}
                              </Title>
                            </label>
                          </div>
                          <PersonTelephoneTypeElement
                            onChange={handleFormChange}
                            form={form}
                          />
                        </Col>
                        <Col className='gutter-row' span={24}>
                          <div className='ant-form-item-label'>
                            <label className='ant-form-item-required' title={`${t('Common_Data.Email')} ${t('Common_Data.Address')}`}>
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Email')} {t('Common_Data.Types')}
                              </Title>
                            </label>
                          </div>
                          <PersonEmailTypeElement
                            onChange={handleFormChange}
                            form={form}
                          />
                        </Col>
                        <Col className='gutter-row' span={24}>
                          <div className='ant-form-item-label'>
                            <label className='ant-form-item-required' title={`${t('Common_Data.Address')}`}>
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Address')} {t('Common_Data.Types')}
                              </Title>
                            </label>
                          </div>
                          <PersonAddressTypeElement
                            onChange={handleFormChange}
                            form={form}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={[15]}>
                        <Col className='gutter-row' span={24}>
                          <div className='ant-form-item-label'>
                            <Title level={3} className='page-title'>
                              {t('Common_Data.Organisation')}
                              <Tooltip title={`${t('Common_Data.Organisation')}`}>
                                <QuestionCircleOutlined className='ml-5' />
                              </Tooltip>
                            </Title>
                          </div>
                        </Col>
                        <Col className='gutter-row' span={24}>
                          <div className='ant-form-item-label'>
                            <label className='ant-form-item-required' title={`${t('Common_Data.Telephone')}`}>
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Telephone')} {t('Common_Data.Types')}
                              </Title>
                            </label>
                          </div>
                          <OrgTelephoneTypeElement
                            onChange={handleFormChange}
                            form={form}
                          />
                        </Col>
                        <Col className='gutter-row' span={24}>
                          <div className='ant-form-item-label'>
                            <label className='ant-form-item-required' title={`${t('Common_Data.Email')} ${t('Common_Data.Address')}`}>
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Email')} {t('Common_Data.Types')}
                              </Title>
                            </label>
                          </div>
                          <OrgEmailTypeElement onChange={handleFormChange} form={form} />
                        </Col>
                        <Col className='gutter-row' span={24}>
                          <div className='ant-form-item-label'>
                            <label className='ant-form-item-required' title={`${t('Common_Data.Address')}`}>
                              <Title level={3} className='page-title'>
                                {t('Common_Data.Address')} {t('Common_Data.Types')}
                              </Title>
                            </label>
                          </div>
                          <OrgAddressTypeElement
                            onChange={handleFormChange}
                            form={form}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                <Row gutter={15}>
                  <Col xs={{ span: 24 }} className='text-right pt-15'>
                    <Button
                      type='primary'
                      shape='round'
                      loading={saving}
                      //htmlType='submit'
                      onClick={() => setSubmitconfirm(true)}
                      disabled={disabled}
                    >
                      {t('Common_Data.Save')}
                    </Button>
                    <Button shape='round' onClick={() => setFormResetvisible(true)}>
                      {t('Common_Data.Reset')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </section>

          <Modal
            centered
            title={`${t('Common_Data.Save')} ${t('Common_Data.Settings')}`}
            visible={submitconfirm}
            onCancel={cancelSubmit}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={[
              <Button
                key='1'
                //disabled={disabled}
                form='SettingForm'
                //loading={saving}
                htmlType='submit'
                type='primary'
              >
                {t('Common_Data.Save')}
              </Button>,
              <Button key='2' htmlType='button' onClick={cancelSubmit}>
                {t('Common_Data.Cancel')}
              </Button>,
            ]}
          >
            <p>{t('Settings.Save_All_Settings')}?</p>
          </Modal>

          <Modal
            centered
            title={`${t('Common_Data.Reset')} ${t('Common_Data.Settings')}`}
            visible={formResetvisible}
            onCancel={() => setFormResetvisible(false)}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={[
              <Button key='1' form='SettingForm' type='primary' onClick={() => onReset()}>
                {t('Common_Data.Reset')}
              </Button>,
              <Button
                key='2'
                htmlType='button'
                onClick={() => setFormResetvisible(false)}
              >
                {t('Common_Data.Cancel')}
              </Button>,
            ]}
          >
            <p>{t('Settings.Reset_All_Settings')}?</p>
          </Modal>
          {/* <TrackTimeComponent pageName={'settings'} /> */}
          <TimeTracker />
        </>
      )}
    </>
  );
});

export default Settings;
