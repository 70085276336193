import React from 'react';
import editIcon from '../../../../../../assets/images/gray/Edit.svg';
import wasteIcon from '../../../../../../assets/images/gray/Waste.svg';
import { Button, Tooltip } from 'antd';
import useStore from '../../../../../../store';
import { useTranslation } from 'react-i18next';

export const ActionRenderer = (props) => {
  const {t} = useTranslation()
  const {
    currentTab,
    openEditModal,
    openDeleteModal,
    ConnectionModule,
    updateConnection,
  } = props.agGridReact.props.frameworkComponents;
  const { AUTH } = useStore();
  var permissionId = currentTab === 'people' ? 41 : 35;

  return (
    <div className='action-column'>
      {AUTH.checkPermission(permissionId, 'role_mapping_edit') && (
        <Button
          title={`${t('Common_Data.Edit')} ${t('PeopleManagement.Role')} ${t('Common_Data.Mapping')}`}
          type='text'
          onClick={() => {
            openEditModal(props.data);
          }}
        >
          <img alt='' className='menuicon' src={editIcon} />
        </Button>
      )}
      {!AUTH.checkPermission(permissionId, 'role_mapping_delete') ? null : props.data
        .association_count > 0 ? (
        <Tooltip
          placement='topRight'
          color={'red'}
          title={
            `${t('PeopleManagement.Role_Mapping_Tooltip')}.`
          }
        >
          <Button type='text' disabled>
            <img alt='' className='menuicon' src={wasteIcon} />
          </Button>
        </Tooltip>
      ) : (
        <Button
          title={`${t('Common_Data.Delete')} ${t('PeopleManagement.Role')} ${t('Common_Data.Mapping')}`}
          type='text'
          onClick={() => {
            openDeleteModal(props.data);
          }}
        >
          <img alt='' className='menuicon' src={wasteIcon} />
        </Button>
      )}
      {ConnectionModule && (
        <Button
          title={
            props.data.connection_id ? `${t('Common_Data.Remove')} ${t('PeopleManagement.From')} ${t('PeopleManagement.Connection')}` : 'Add To Connection'
          }
          type='text'
          onClick={() => {
            updateConnection(props.data);
          }}
        >
          <img
            alt=''
            className='menuicon circle'
            src={AUTH.GetThemedImage(props.data.connection_id ? 'remove' : 'add')}
          />
        </Button>
      )}
    </div>
  );
};

// export const gridOptions = {
//   columnDefs: [
//     {
//       headerName: '#',
//       valueGetter: function (params) { return params.node.rowIndex + 1; },
//       tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
//       cellClass: 'cellClass',
//       pinned: 'left',
//       filter: false,
//       sortable: false,
//       width: 60,
//     },
//     {
//       headerName: `#${t('Common_Data.ID')}`,
//       field: 'id',
//       filter: 'agNumberColumnFilter',
//       maxWidth: 100,

//     },
//     {
//       headerName: `${t('Common_Data.People')} ${t('Common_Data.Name')}`,
//       field: 'entity_name',

//     },
//     {
//       headerName: `${t('PeopleManagement.Role')}`,
//       field: 'role_name',

//     },
//     {
//       headerName: `${t('Common_Data.Title')}`,
//       field: 'title',

//     },
//     {
//       headerName: `${t('Common_Data.Mobile')}`,
//       field: 'mobile',
//       valueGetter: (params) =>
//         params.data && params.data.view_phone
//           ? params.data.view_phone.map((x) => x.contact_value).join(', ')
//           : null,

//     },
//     {
//       headerName: `${t('Common_Data.Email')}`,
//       field: 'email',
//       valueGetter: (params) =>
//         params.data && params.data.view_email
//           ? params.data.view_email.map((x) => x.contact_value).join(', ')
//           : null,

//     },
//     {
//       headerName: `${t('PeopleManagement.PPS')} ${t('Common_Data.Number')}`,
//       field: 'pps_number',

//     },
//     {
//       headerName: `${t('Common_Data.Address')}`,
//       field: 'address',
//       valueGetter: (params) =>
//         params.data && params.data.view_addresses
//           ? params.data.view_addresses.address_1 +
//           (params.data.view_addresses.address_2
//             ? ', ' + params.data.view_addresses.address_2
//             : '') +
//           (params.data.view_addresses.address_3
//             ? ', ' + params.data.view_addresses.address_3
//             : '') +
//           (params.data.view_addresses.city
//             ? ', ' + params.data.view_addresses.city
//             : '') +
//           (params.data.view_addresses.postal_code
//             ? ', ' + params.data.view_addresses.postal_code
//             : '')
//           : null,

//     },
//     {
//       headerName: `${t('Common_Data.Actions')}`,
//       field: 'actions',
//       cellClass: 'cellClass actionColumn',
//       type: 'actionColumn',
//       maxWidth: 120,
//       filter: false,
//       sortable: false,
//       pinned: 'right',
//     },
//   ],
// };
