import React from 'react';
import { observer } from 'mobx-react';
import { Drawer } from 'antd';
import useStore from '../../../store';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ViewComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    TagsStore: { viewValues, statusArr_list },
  } = useStore();

  const close = () => {
    props.close();
  };
  const resultStatus =
    statusArr_list &&
    viewValues &&
    statusArr_list.filter((e) => e.value === viewValues.tag_status_id).shift();

  return (
    viewValues && (
      <>
        <Drawer
          visible={props.visible}
          onClose={close}
          destroyOnClose
          placement='right'
          width={'1050px'}
          title={`${t('Common_Data.View')} ${t('Tags.Tag')} ${viewValues.tag_number ? ' - #' + viewValues.tag_number : ''
            }`}
          zIndex={1001}
        >
          <div className='viewPeople' key='1'>
            <table cellPadding='0' cellSpacing='0' border='0'>
              <tbody>
                <tr>
                  <td width='25%' style={{ border: '0' }}></td>
                  <td width='25%' style={{ border: '0' }}></td>
                  <td width='25%' style={{ border: '0' }}></td>
                  <td width='25%' style={{ border: '0' }}></td>
                </tr>
                <tr>
                  <th>{t('Common_Data.Subject')}:</th>
                  <td colSpan='3'>{viewValues && viewValues.tag_subject}</td>
                </tr>
                <tr>
                  <th>{t('Common_Data.Organisation')}:</th>
                  <td colSpan='3'>{viewValues?.organisation?.organisation_name}</td>
                </tr>
                <tr>
                  <th>{t('Tags.Tag')} {t('Common_Data.Number')}:</th>
                  <td>{viewValues?.tag_number}</td>
                  <th>{t('Common_Data.Master')} {t('Tags.Ticket')}:</th>
                  <td>{viewValues?.master_ticket?.ticket_subject}</td>
                </tr>
                <tr>
                  <th>{t('Common_Data.Owner')}:</th>
                  <td>{viewValues?.created_by_name}</td>
                  <th>{t('Common_Data.Holder')}:</th>
                  <td>
                    {viewValues.tag_holder &&
                      viewValues?.tag_holder?.first_name +
                      ' ' +
                      viewValues?.tag_holder?.last_name}
                  </td>
                </tr>
                <tr>
                  <th>{t('Common_Data.Status')}:</th>
                  <td>{resultStatus?.text}</td>
                  <th>{t('Common_Data.DeadLine')} {t('Common_Data.Date')}:</th>
                  <td>
                    {viewValues &&
                      viewValues.deadline_date &&
                      moment(viewValues?.deadline_date).format(AUTH.global_dateformat)}
                  </td>
                </tr>
                <tr>
                  <th colSpan='4'>{t('Common_Data.Description')}:</th>
                </tr>
                <tr>
                  <td
                    colSpan='4'
                    dangerouslySetInnerHTML={{
                      __html: viewValues.tag_content,
                    }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </Drawer>
      </>
    )
  );
});

export default ViewComponent;
