import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmClassification } from '../../../config/messages';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const {
    CREDEBTCLASSIFICATION: { editValues, EditData }
  } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);
    data.id = editValues.id;
    EditData(data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmClassification.edit
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    if (editValues) {
      form.setFieldsValue({
        name: editValues.name
      });
    }
  }, [editValues, form]);

  return (
    editValues && (
      <>
        <Modal
          centered
          title={`${t('Credebt_Classification.Edit_Classification')} - ${editValues.name}`}
          visible={props.visible}
          onCancel={close}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          footer={[
            <Button
              key='1'
              form='editform'
              shape='round'
              htmlType='submit'
              type='primary'
              disabled={isDisabled}
              loading={saving}
            >
              {t('Common_Data.Update')}
            </Button>,
            <Button key='2' form='addform' shape='round' onClick={close}>
             {t('Common_Data.Cancel')}
            </Button>
          ]}
          width={500}
        >
          <FormComponent
            form={form}
            onChange={handleChange}
            handleSubmit={handleSubmit}
            id='editform'
          />
        </Modal>
      </>
    )
  );
});

export default EditComponent;
