import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button, PageHeader, Form, Space } from 'antd';
import useStore from '../../../store';
import RecordPerPage from '../../../config/RecordPerPage';
import ListComponent from './components/ListComponent';
import DeleteComponent from './components/DeleteComponent';
import AddComponent from './components/AddComponent';
import CloneComponent from './components/CloneComponent';
import EditComponent from './components/EditComponent';
import PreviewReportComponent from './components/PreviewReportComponent';
import PreviewDataComponent from './components/PreviewDataComponent';
import Unauthorized from '../../Unauthorized';
import { useHistory, useLocation } from 'react-router-dom';
import EnterFullscreen from '../../../component/EnterFullscreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import GlobalStatusChangeComponent from './elements/GlobalStatusChangeComponent';
import TrackTimeComponent from '../../../component/TrackTimeComponent';
import ConfirmFavReportElement from './elements/ConfirmFavReportElement';
import { useTranslation } from 'react-i18next';

const SavedReports = observer(() => {
  let history = useHistory();
  let location = useLocation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [report_id, setReportID] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [toogleFilterVisible, setToogleFilterVisible] = useState(false);
  const [confirmFavReport, setConfirmFavReport] = useState(false); // Used for Fav Report Confirmation
  const [toggleswichvalue, setToggleswichvalue] = useState(false);
  const [previewReportModal, setPreviewReportModal] = useState(false);
  const [previewDataModal, setPreviewDataModal] = useState(false);
  const [previewFromEditDrawer, setpreviewFromEditDrawer] = useState(false);
  const [previewFromList, setpreviewFromList] = useState(false);
  const [previewFromClone, sepreviewFromClone] = useState(false);

  const { t } = useTranslation()
  const {
    AUTH,
    SavedReportStore,
    SETTINGS,
    SavedReportStore: {
      getList,
      per_page,
      setDeleteValues,
      setPageSize,
      setGlobalStatusTempData,
    },
  } = useStore();

  history.listen((location, action) => {
    if (location.search !== '') {
      var tempSplit = location.search.split('=');
      if (tempSplit[0] && tempSplit[0] === '?preview_report' && tempSplit[1]) {
        openPreviewReportModal(tempSplit[1]);
      }
    }
  });

  // Open form for add new User
  const openAddModal = () => {
    setAddModal(true);
    SavedReportStore.created_by_id = null;
  };

  // Close form for add new User
  const closeAddModal = () => setAddModal(false);

  // Open form for add new User
  const openCloneModal = (id) => {
    setCloneModal(true);
    setReportID(id);
    SavedReportStore.created_by_id = null;
  };

  // Close form for add new User
  const closeCloneModal = () => setCloneModal(false);

  // Open form for edit User
  const openEditModal = (id) => {
    setEditModal(true);
    setReportID(id);
  };

  // Close form for edit User
  const closeEditModal = () => {
    setEditModal(false);
    setReportID(false);
  };

  // Open form for preview Reports Drawer
  const openPreviewReportModal = useCallback(
    (id) => {
      setPreviewReportModal(true);
      setReportID(id);
    },
    [setPreviewReportModal, setReportID]
  );

  // Close form for preview Reports Drawer
  const closePreviewReportModal = (close_all = true) => {
    setPreviewReportModal(false);
    setReportID(false);
    if (close_all) {
      setAddModal(false);
      setEditModal(false);
      setCloneModal(false);
    }
    history.replace(history.location.pathname);
  };

  // Open form for preview Reports Drawer
  const openPreviewDataModal = useCallback(
    (id, isEdit = false, isList = false, isClone = false) => {
      setCloneModal(false);
      setAddModal(false);
      setEditModal(false);
      setpreviewFromList(isList);
      sepreviewFromClone(isClone);
      setpreviewFromEditDrawer(isEdit);
      setPreviewDataModal(true);
      setReportID(id);
    },
    [setPreviewDataModal, setReportID, setAddModal, setEditModal]
  );

  // Close form for preview Reports Drawer
  const closePreviewDataModal = (close_all = true) => {
    setPreviewDataModal(false);
    sepreviewFromClone(false);
    setpreviewFromList(false);
    setpreviewFromEditDrawer(false);
    setReportID(false);
    if (close_all) {
      setAddModal(false);
      setEditModal(false);
      setCloneModal(false);
    }
  };

  // Open confirmation alert before deleting existing Role
  const openDeleteModal = (data) => {
    setDeleteValues(data);
    setDeleteModal(true);
  };

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => setDeleteModal(false);

  const handleReset = async () => {
    await localStorage.removeItem('report_grid');
    SavedReportStore.agGrid.api.setFilterModel(null);
    SavedReportStore.agGrid.api.setSortModel(null);
    SavedReportStore.agGrid.columnApi.resetColumnState();
    SavedReportStore.agGrid.columnApi.autoSizeColumns();
    SavedReportStore.agGrid.api.onFilterChanged(null);
  };

  useEffect(() => {
    SavedReportStore.previewReportValues = null;
    if (location.search !== '') {
      var tempSplit = location.search.split('=');
      if (tempSplit[0] && tempSplit[0] === '?preview_report' && tempSplit[1]) {
        openPreviewReportModal(tempSplit[1]);
      }
    }
  }, [SavedReportStore, location.search, openPreviewReportModal]);

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (AUTH.checkPermission(21, 'edit')) {
            if (result.key === 'Enter') {
              openEditModal(e.data.id);
            }
          }
          if (AUTH.checkPermission(21, 'delete')) {
            if (result.key === 'Delete') {
              openDeleteModal(e.data);
            }
          }
        }
      }
    }
  };

  // Handle on switch data
  const onSwitchChange = (checked, data) => {
    SavedReportStore.agGrid.api.refreshCells({ force: true });

    setGlobalStatusTempData(data);
    setToogleFilterVisible(true);
    setToggleswichvalue(checked);
  };

  // To Close Status Model Popup
  const closeToogleFilter = () => {
    setGlobalStatusTempData(null);
    setToogleFilterVisible(false);
    setToggleswichvalue(null);
  };

  // Handle on switch data
  const onFavReportChange = (data) => {
    setGlobalStatusTempData(data);
    setConfirmFavReport(true);
  };

  // To Close Status Model Popup
  const closeFavReport = () => {
    setGlobalStatusTempData(null);
    setConfirmFavReport(false);
  };

  return (
    <>
      {!AUTH.checkPermission(21, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={`${t('Reports.Reports')}`}
          className={'page-title ' + SETTINGS.fullscreen_class}
          extra={[
            <Form className='innerForm headerButton'>
              <Space align='center' size={5}>
                {AUTH.checkPermission(21, 'show-only-my-reports') && (
                  <Button
                    shape='round'
                    key='1'
                    onClick={() => {
                      if (SavedReportStore.my_records === 'all') {
                        getList({}, AUTH.user.id);
                        SavedReportStore.my_records = AUTH.user.id;
                      } else {
                        getList({}, 'all');
                        SavedReportStore.my_records = 'all';
                      }
                    }}
                  >
                    {SavedReportStore.my_records === AUTH.user.id
                      ? `${t('Reports.Show')} ${t('Reports.All')} ${t('Reports.Reports')}`
                      : `${t('Reports.Show')} ${t('Reports.Only')} ${t('Reports.My')} ${t('Reports.Reports')}`}
                  </Button>
                )}

                {AUTH.checkPermission(21, 'add') && (
                  <Button shape='round' key='2' onClick={openAddModal}>
                    {t('Common_Data.Add')} {t('Reports.Report')}
                  </Button>
                )}
                <Button
                  key='4'
                  title={`${t('Common_Data.Reset')}`}
                  shape='round'
                  size='medium'
                  onClick={handleReset}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <EnterFullscreen key='5' />
                <RecordPerPage
                  key='3'
                  initialValue={per_page + ' per page'}
                  onChange={setPageSize}
                />
              </Space>
            </Form>,
          ]}
        >
          <ListComponent
            openPreviewDataModal={openPreviewDataModal}
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
            onSwitchChange={onSwitchChange}
            onCellKeyDown={onCellKeyDown}
            openCloneModal={openCloneModal}
            onFavReportChange={onFavReportChange}
          />
          {addModal && (
            <AddComponent
              openPreviewDataModal={openPreviewDataModal}
              openPreviewReportModal={openPreviewReportModal}
              visible={addModal}
              close={closeAddModal}
            />
          )}

          {cloneModal && (
            <CloneComponent
              report_id={report_id}
              setReportID={setReportID}
              openPreviewDataModal={openPreviewDataModal}
              openPreviewReportModal={openPreviewReportModal}
              visible={cloneModal}
              close={closeCloneModal}
            />
          )}

          {editModal && (
            <EditComponent
              report_id={report_id}
              setReportID={setReportID}
              openPreviewDataModal={openPreviewDataModal}
              openPreviewReportModal={openPreviewReportModal}
              visible={editModal}
              close={closeEditModal}
            />
          )}

          {previewReportModal && (
            <PreviewReportComponent
              addModal={addModal}
              editModal={editModal}
              cloneModal={cloneModal}
              openPreviewDataModal={openPreviewDataModal}
              report_id={report_id}
              setReportID={setReportID}
              visible={previewReportModal}
              close={closePreviewReportModal}
            />
          )}

          {previewDataModal && (
            <PreviewDataComponent
              addModal={addModal}
              editModal={editModal}
              cloneModal={cloneModal}
              report_id={report_id}
              previewFromEditDrawer={previewFromEditDrawer}
              previewFromList={previewFromList}
              previewFromClone={previewFromClone}
              openPreviewReportModal={openPreviewReportModal}
              setReportID={setReportID}
              visible={previewDataModal}
              close={closePreviewDataModal}
              openCloneModal={openCloneModal}
            />
          )}
          {deleteModal && (
            <DeleteComponent visible={deleteModal} close={closeDeleteModal} />
          )}
          <GlobalStatusChangeComponent
            visible={toogleFilterVisible}
            close={closeToogleFilter}
            toggleswichvalue={toggleswichvalue}
          />

          <ConfirmFavReportElement visible={confirmFavReport} close={closeFavReport} />

          <TrackTimeComponent pageName={'reports'} />
        </PageHeader>
      )}
    </>
  );
});

export default SavedReports;
