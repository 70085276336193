import React from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../component/InputComponent';
import { Form, Col, Row } from 'antd';
import { vsmSettings } from '../../../config/messages';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const PersonTelephoneTypeElement = observer(({ onChange, form }) => {
  const { AUTH } = useStore();
  const {t} =  useTranslation()
  return (
    <div className='w-100'>
      <Row>
        <Form.List
          name='person_telephone_types'
          initialValue={[null]}
          className='d-flex '
        >
          {(fields, { add, remove }) => {
            return fields.map((field, index) => {
              return (
                <>
                  <Col span={8} key={field.key} className='d-flex'>
                    <div className='p-5 w-100'>
                      <InputComponent
                        name={[field.name, 'type']}
                        key={[field.key, 'type']}
                        onChange={onChange}
                        placeholder={`${t('Common_Data.People')} ${t('Common_Data.Telephone')} ${t('Common_Data.Type')}`}
                        style={{ width: '100%' }}
                        rules={
                          vsmSettings.validation.person_telephone_types_with_required
                        }
                      />
                    </div>

                    <div className='d-flex p-5'>
                      {fields.length > 1 && (
                        <img
                          alt=''
                          className={`formIcon mt-5 mr-10`}
                          src={AUTH.GetThemedImage('remove')}
                          onClick={() => {
                            remove(field.name);
                            //onChange();
                          }}
                        />
                      )}
                      {index === fields.length - 1 && (
                        <img
                          alt=''
                          className={`formIcon mt-5 mr-10`}
                          src={AUTH.GetThemedImage('add')}
                          onClick={async () => {
                            await add(null);
                            //onChange();
                          }}
                        />
                      )}
                    </div>
                  </Col>
                </>
              );
            });
          }}
        </Form.List>
      </Row>
    </div>
  );
});

export default PersonTelephoneTypeElement;
