import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Popover, Button, Row, Col, Form, Spin } from 'antd';
import useStore from '../../../store';
import InputComponent from '../../../component/InputComponent';
import { vsmNotify, vsmQuickNoteUpdate } from '../../../config/messages';
import TrackTimeComponent from '../../../component/TrackTimeComponent';
import { useTranslation } from 'react-i18next';

const NoteRenderer = observer((props) => {
  const {t}  = useTranslation()
  const {
    AUTH,
    PROMOTIONSTORE: {
      updateQuickNote,
      setDelayNoteHoverHandler,
      delayNoteHoverHandler,
      getLeadsNotes,
    },
  } = useStore();
  const [form] = Form.useForm();
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [noteData, setNoteData] = useState();
  const [fetchNoteLoader, setFetchNoteLoader] = useState(true);

  const handleHoverChange = (visible) => {
    setHovered(visible);
    setClicked(false);
  };
  const handleClickChange = (visible) => {
    setClicked(visible);
    setHovered(false);
  };
  const onFinish = (values) => {
    let formdata = {
      notes_flag: 1,
      notes_description: values.NoteText,
    };
    let id = props.data.id;
    updateQuickNote(id, formdata)
      .then(() => {
        vsmNotify.success({
          message: vsmQuickNoteUpdate.successNoteUpdate,
        });
        form.resetFields();
        setClicked(false);
      })
      .catch((e) => {
        if (e.errors) {
          //form.setFields(e.errors);
        }
      });
  };

  const content = (
    <div className='NoteText'>
      <Form form={form} name='SendNote' onFinish={onFinish}>
        <Row gutter={0}>
          <Col span={24}>
            <InputComponent name='NoteText' type='textarea' />
          </Col>
          <Col span={24} className='text-right mt-10'>
            <Button size='middle' shape='round' type='primary' htmlType='submit'>
              {t('Common_Data.Save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );

  const tooltipTitle = (
    <div className='NoteText'>
      {fetchNoteLoader ? (
        <Spin size='medium' spinning={fetchNoteLoader} tip={`${t('Common_Data.Load')}`}></Spin>
      ) : noteData ? (
        <>
          <div style={{ fontSize: '1.1rem' }} className='mb-5'>
            {noteData && noteData?.organisation?.organisation_name}{' '}
            <b>
              {noteData && noteData.created_by_name}{' '}
              {` ${noteData && noteData.created_by_name ? ' | ' : ''} ${
                // noteData?.notes_date ? AUTH.getLocalTime(noteData.notes_date, 'YYYY-MM-DD HH:mm:ss') : ''
                noteData?.notes_date ? AUTH.getLocalTime(noteData.notes_date, AUTH.global_fulldate_format) : ''
              }`}
            </b>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: noteData && noteData.notes_description,
            }}
          ></div>
        </>
      ) : (
        <div style={{ fontSize: '1.2rem' }} className='text-center'>
          {t('Common_Data.No')} {t('Common_Data.Note')} {t('Promotion.Saved')}
        </div>
      )}
    </div>
  );

  const handleMouseEnter = (event) => {
    let lead_transaction_id = props.data.id;
    setDelayNoteHoverHandler(
      setTimeout(() => {
        getLeadsNotes(lead_transaction_id)
          .then((data) => {
            setNoteData(data.data[0]);
          })
          .finally(() => setFetchNoteLoader(false));
      }, 500)
    );
  };

  const handleMouseLeave = (event) => {
    clearTimeout(delayNoteHoverHandler);
    setDelayNoteHoverHandler(null);
  };

  return (
    <span className='noteRender'>
      <Popover
        content={tooltipTitle}
        placement='top'
        arrowPointAtCenter
        trigger='hover'
        overlayClassName='profileMenu'
        visible={hovered}
        onVisibleChange={handleHoverChange}
      >
        <Popover
          content={content}
          placement='top'
          arrowPointAtCenter
          trigger='click'
          overlayClassName='profileMenu'
          visible={clicked}
          onVisibleChange={AUTH.checkPermission(2, 'add-note') ? handleClickChange : null}
        >
          <img
            className='menuicon'
            alt=''
            src={AUTH.GetThemedImage('Note')}
            onMouseEnter={(e) => handleMouseEnter(e)}
            onMouseLeave={(e) => handleMouseLeave(e)}
          />
        </Popover>
        {clicked && <TrackTimeComponent pageName={'promotion'} leadId={props.data.id} />}
      </Popover>
    </span>
  );
});
export default NoteRenderer;
