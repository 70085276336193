import { Col } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../../component/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';
import {
  remindersStatus,
  reminderTypeList,
  reminder_priority,
} from '../../../../utils/GlobalFunction';
import NaceCategoryElement from './NaceCategoryElement';
import OrganisationElements from './OrganisationElements';
import ParentOrgRowElements from './ParentOrgRowElements';
import PersonElements from './PersonElements';
import ReminderForElement from './ReminderForElement';

const ReminderReportElements = ({ form, handleChange, formDataList }) => {
  const { t } = useTranslation();
  return (
    <>
      {formDataList.includes('reminder_status') && (
        <Col span={6}>
          <InputComponent
            name='reminder_status'
            label={t("Reports.Reminder_Status")}
            placeholder={`${t("Common_Data.Select")} ${t("Reports.Reminder_Status")}`}
            type='select'
            mode='multiple'
            showArrow
            tooltip={t("Reports.Status_Tooltip")}
            allowClear
            onChange={handleChange}
            options={{
              values: remindersStatus,
            }}
          />
        </Col>
      )}

      {formDataList.includes('reminder_priority') && (
        <Col span={6}>
          <InputComponent
            name='reminder_priority'
            label={t("Reports.Reminder_Priority")}
            mode='multiple'
            allowClear
            showArrow
            type='select'
            showSearch={false}
            placeholder={`${t('PeopleManagement.All')}`}
            tooltip={t("Reports.Reminder_Priority_Tooltip")}
            onChange={handleChange}
            options={{
              values: reminder_priority,
              id: 'value',
              text_key: 'text',
            }}
            rules={vsmTransactionReport.validation.priority}
          />
        </Col>
      )}
      {formDataList.includes('reminder_type') && (
        <>
          <Col span={6}>
            <InputComponent
              name='reminder_type'
              label={t("Reports.Reminder_Type")}
              type='select'
              showSearch={false}
              tooltip={t("Reports.Reminder_Type_Tooltip")}
              placeholder={`${t('PeopleManagement.All')}`}
              allowClear
              showArrow
              onChange={() => {
                handleChange();
                form.resetFields(['reminder_leads_id', 'reminder_ticket_id']);
              }}
              onClear={() => {
                form.resetFields(['reminder_leads_id', 'reminder_ticket_id']);
                handleChange();
              }}
              rules={vsmTransactionReport.validation.reminder_type}
              options={{
                values: reminderTypeList,
              }}
            />
          </Col>

          <Col span={6}>
            <ReminderForElement
              formDataList={formDataList}
              typeValue={form.getFieldValue('reminder_type')}
              onChange={handleChange}
            />
          </Col>
        </>
      )}
      {formDataList.includes(`reminder_subject`) && (
        <Col span={24}>
          <InputComponent
            name={`reminder_subject`}
            label={t("Common_Data.Subject")}
            placeholder={t("Reports.Subject_Placeholder")}
            type='textarea'
            tooltip={t("Common_Data.Subject")}
            onChange={handleChange}
          />
        </Col>
      )}
      <OrganisationElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <PersonElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <ParentOrgRowElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <NaceCategoryElement
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
    </>
  );
};

export default ReminderReportElements;
