import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../../component/InputComponent';
import { vsmDealDesk, vsmLeadsList } from '../../../../../config/messages';
import { Col, Form, Row } from 'antd';
import useStore from '../../../../../store';
import ScheduleComponent from './ScheduleComponent';
import { useTranslation } from 'react-i18next';
import SaveModal from '../../Structure/component/SaveModal';

const FormComponent = observer(
  ({
    handleSubmit,
    onChange,
    forceUpdate,
    form,
    id,
    INITIAL_HEIGHT,
    setis_checked,
    is_checked,
    setScheduleData,
    ScheduleData,
    autoSaveData = () => { },
    saveObj,
    openViewDrawer,
    isSaveModal,
    setIsSaveModal,
    loading,
    isTemplate,
    setIsTemplate, 
  }) => {
    const {
      DEALDESKSCHEDULELIBRARY,
      DEALDESKSCHEDULELIBRARY: {
        setSavescheduleform,
        SelectedDate,
        DateType,
        SetDate,
        SetDateType,
      },
      LEADSSTORE,
      LEADSSTORE: {
        classification_role_list,
      }
    } = useStore();

    const { t } = useTranslation();
    useEffect(() => {
      setSavescheduleform(form);
    }, [form, setSavescheduleform]);

    return (
      <div className='RightScheduleList'>
        <Form
          form={form}
          layout='vertical'
          id={id}
          onFinish={handleSubmit}
          className='innerForm'
        >
          <Row gutter={24}>
            <Col span={12}>
              <InputComponent
                required
                className='note'
                type='textarea'
                autoSize={{ minRows: 1, maxRows: 1 }}
                maxLength={100}
                label={`${t('DealDesk.Schedule_Title')}`}
                name='title'
                placeholder={`${t('DealDesk.Enter_Schedule_Title')}...`}
                onChange={onChange}
                rules={vsmDealDesk.schedule.validation.title}
              />
            </Col>
            <Col span={12}>
              <InputComponent
                name='classification_id'
                placeholder={`${t('Common_Data.Select_Credebt_Classification')}`}
                onFocus={() =>
                  !LEADSSTORE.classification_role_list &&
                  LEADSSTORE.getClassificationList()
                }
                label={<span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/credebtclassifications-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('Common_Data.Credebt_Classification')}
                </span>}
                required
                className='note'
                maxLength={50}
                type='select'
                allowClear
                showArrow
                showSearch
                onChange={onChange}
                options={{
                  values:
                    classification_role_list ||
                    (DEALDESKSCHEDULELIBRARY.editValues &&
                      DEALDESKSCHEDULELIBRARY.editValues.classification_name && [
                        DEALDESKSCHEDULELIBRARY.editValues.classification_name,
                      ]),
                  value_key: 'id',
                  text_key: 'name',
                }}
                notFoundContent={`${t('Common_Data.No_Record_Found')}.`}
                tooltip={`${t('LeadListing.Classification_Group_Tooltip')}.`}
                rules={vsmLeadsList.validation.classification_id}
              />
            </Col>
          </Row>
          <InputComponent
            required
            className='note'
            type='textarea'
            label={`${t('Common_Data.Description')}`}
            name='description_text'
            placeholder={`${t('Common_Data.Description')}`}
            onChange={onChange}
            rules={[{ required: true, message: `${t('DealDesk.Not_Blank')}` }]}
          />

          <div className='list mt-5'>
            <ScheduleComponent
              scheduleName='description'
              form={form}
              isFromLibrary={true}
              INITIAL_HEIGHT={INITIAL_HEIGHT}
              forceUpdate={forceUpdate}
              autoSaveData={autoSaveData}
              setscheduleList={setScheduleData}
              scheduleList={ScheduleData}
              saveObj={saveObj}
              SelectedDate={SelectedDate}
              SetDate={SetDate}
              DateType={DateType}
              setis_checked={setis_checked}
              is_checked={is_checked}
              SetDateType={SetDateType}
              handleFormChange={onChange}
              openViewDrawer={openViewDrawer}
            />
          </div>
          <SaveModal
            id={id}
            visible={isSaveModal}
            handleSubmit={handleSubmit}
            saveType={"schedule"}
            loading={loading}
            isTemplate={isTemplate}
            setIsTemplate={setIsTemplate}
            close={setIsSaveModal}
          />
        </Form>
      </div>
    );
  }
);

export default FormComponent;
