import moment from 'moment';
import { Link } from 'react-router-dom';
import useStore from '../../../store';
import {
  reportDynamicFieldsListColumnConfig,
  DateComparator,
  formatDataBasedOnColumn,
  currencyType,
  secondsToHmsWithAppend,
  numberWithCommas,
} from '../../../utils/GlobalFunction';


const GetDynamicColumns = (element, reportType, mainReportType) => {
  const { AUTH } = useStore();
  let columnObj = {};
  let elementDetails = reportDynamicFieldsListColumnConfig?.[element];
  let type = elementDetails?.filtertype;
  // To get value of lead status in sold list
  const getLeadStatusFilter = (params) => {
    let colData = params && params.value?.split('</sortByLeadStatus>')
    let filterdata = colData && colData[1] ? colData[1] : '(Blanks)';
    return filterdata && filterdata
  }
  const getLeadStatusData = (params) => {
    let colData = params && params.value?.split('</sortByLeadStatus>')
    let filterdata = colData && colData[1] ? colData[1] : '';
    let isDowngraded = (params?.data?.is_downgraded === true) ? true : false;
    let returnData = (isDowngraded === true && filterdata) ? (filterdata + " -") : (filterdata)
    return returnData
  }



  if (type === 'sr') {
    columnObj = {
      headerName: elementDetails?.title,
      headerTooltip: elementDetails?.title,
      cellRenderer: (params) => {
        return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
      },
      field: 'sr',
      cellClass: 'cellClass',
      pinned: 'left',
      cellStyle: { 'text-align': 'center' },
      filter: false,
      sortable: false,
      width: 37,
      minWidth: 37,
    };
  }
  else if (type === 'date') {
    columnObj = {
      headerName: elementDetails?.title,
      headerTooltip: elementDetails?.title,

      field: element,
      cellStyle: { 'text-align': 'center' },
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset'],
        inRangeInclusive: true,
        comparator: DateComparator,
      },
      cellRenderer: (params) => {
        return params?.data && ((params?.data[element] !== undefined || params?.data[element] !== null) && (params?.data?.last_conversation_on !== undefined)
          ? AUTH.getLocalTimeDealDesk(params.data[element], AUTH.global_dateformat)
          : '')
      },
      minWidth: 70,
    };
  }
  else if (type === 'number') {
    if (currencyType.includes(element)) {
      if (element === 'turn_over') {
        columnObj = {
          headerName: elementDetails?.title,
          headerTooltip: elementDetails?.title,
          field: element,
          cellStyle: { 'text-align': 'right' },
          filter: 'agNumberColumnFilter',
          cellRenderer: (params) => {
            // return params?.value && params?.value
            return params?.value ? formatDataBasedOnColumn(element, params?.value) : '';
          },
          comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            if (valueA === valueB) { return 0 };
            if (valueA) {
              valueA = Number(valueA);
            } else {
              valueA = valueA ? valueA : 0;
            }
            if (valueB) {
              valueB = Number(valueB);
            } else {
              valueB = valueB ? valueB : 0;
            }
            return (valueA > valueB) ? 1 : -1;
          },
          minWidth: 70,
        };
      }
      else {
        columnObj = {
          headerName: elementDetails?.title,
          headerTooltip: elementDetails?.title,
          field: element,
          cellStyle: { 'text-align': 'right' },
          filter: 'agNumberColumnFilter',
          cellRenderer: (params) => {
            return typeof params?.value === 'number' ? formatDataBasedOnColumn(element, params?.value) : params.value;
          },
          comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            if (valueA === valueB) { return 0 };
            if (valueA) {
              valueA = Number(valueA);
            } else {
              valueA = valueA ? valueA : 0;
            }
            if (valueB) {
              valueB = Number(valueB);
            } else {
              valueB = valueB ? valueB : 0;
            }
            return (valueA > valueB) ? 1 : -1;
          },
          minWidth: 70,
        };
      }
    }
    else if (['total_comments',
      'total_communication',
      'total_conversation',
      'total_conversation_words',
      'total_inbound_notes',
      'chat',
      'total_outbound_notes',
      'total_words',
      'deals',
      'meetings',
      'meetings_held',
      'qualify_held',
      'traded',
      'total_notes'].includes(element)) {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        field: element,
        cellStyle: { 'text-align': 'center' },
        cellRenderer: (params) => {
          return numberWithCommas(params?.value);
        },
        filter: 'agNumberColumnFilter',
        minWidth: 70,
      };
    }

    else {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        field: element,
        filter: 'agNumberColumnFilter',
        minWidth: 70,
      };
    }


  } else if (type === 'columnfilter') {
    if (element === 'lead_status' || element === 'status') {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        sortable: true,
        field: element,
        cellClass: 'cellClass reportColumn',
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (valueA === valueB) { return 0 };
          if (typeof valueA === 'string') {
            valueA = valueA.toLowerCase();
          }
          else {
            valueA = valueA ? valueA : '';
          }
          if (typeof valueB === 'string') {
            valueB = valueB.toLowerCase();
          }
          else {
            valueB = valueB ? valueB : '';
          }
          if (valueA === valueB) return 0;
          return (valueA > valueB) ? 1 : -1;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          valueFormatter: getLeadStatusFilter,
        },
        valueFormatter: getLeadStatusData,
      };
    }
    else {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        sortable: true,
        field: element,
        cellClass: 'cellClass reportColumn',
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (valueA === valueB) { return 0 };
          if (typeof valueA === 'string') {
            valueA = valueA.toLowerCase();
          }
          else {
            valueA = valueA ? valueA : '';
          }
          if (typeof valueB === 'string') {
            valueB = valueB.toLowerCase();
          }
          else {
            valueB = valueB ? valueB : '';
          }
          if (valueA === valueB) return 0;
          return (valueA > valueB) ? 1 : -1;
        },
        // filter:  element === "lead_status" ? 'agTextColumnFilter' :  'agSetColumnFilter',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          // valueFormatter: ,
        },
        // valueFormatter: ,
      };
    }
  } else {
    if (currencyType.includes(element)) {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        sortable: true,
        field: element,
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          return params?.value ? formatDataBasedOnColumn(element, params?.value) : '';
        },
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (valueA === valueB) { return 0 };
          if (valueA) {
            valueA = Number(valueA);
          }
          else {
            valueA = valueA ? valueA : 0;
          }
          if (valueB) {
            valueB = Number(valueB);
          }
          else {
            valueB = valueB ? valueB : 0;
          }
          return (valueA > valueB) ? 1 : -1;
        },
      };
    } else if (['investment_hours'].includes(element)) {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        field: element,
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        cellRendererFramework: function (params) {
          if (params.data?.sr === 'Total') {
            return params?.data?.investment_hours !== undefined && params?.data?.investment_hours;
          }
          return params?.data?.investment_hours !== undefined ? secondsToHmsWithAppend(params?.data?.investment_hours) : '';
        },
        // cellRenderer: (params) => {
        //   return params?.value ? secondsToHmsWithAppend(params?.value) : '';
        // },
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (valueA === valueB) { return 0 };
          if (valueA) {
            valueA = Number(valueA);
          }
          else {
            valueA = valueA ? valueA : 0;
          }
          if (valueB) {
            valueB = Number(valueB);
          }
          else {
            valueB = valueB ? valueB : 0;
          }
          return (valueA > valueB) ? 1 : -1;
        },
      };
    } else if (['idle', 'idle_day'].includes(element)) {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        field: element,
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          let dateAsString = moment(nodeA?.data?.idle_original).format('DD/MM/YYYY');
          let dateParts = dateAsString.split('/');
          let cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
          );
          let dateAsStringB = moment(nodeB?.data?.idle_original).format('DD/MM/YYYY');
          let datePartsB = dateAsStringB.split('/');
          let cellDateB = new Date(
            Number(datePartsB[2]),
            Number(datePartsB[1]) - 1,
            Number(datePartsB[0])
          );
          if (cellDateB.getTime() === cellDate.getTime()) {
            return 0;
          }

          if (cellDateB < cellDate) {
            return -1;
          }

          if (cellDateB > cellDate) {
            return 1;
          }

        },
      };
    }

    else if (['shortfall', 'rejected_shortfall', 'failed_shortfall'].includes(element)) {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        sortable: true,
        field: element,
        filter: 'agTextColumnFilter',
        // minWidth: 200,
        // width: 200,
        cellRenderer: (params) => {
          let shortfallData = null

          if (['shortfall'].includes(element)) {
            shortfallData = params?.data?.shortfall && JSON.parse(params?.data?.shortfall);
          } else if (['rejected_shortfall'].includes(element)) {
            shortfallData = params?.data?.rejected_shortfall && JSON.parse(params?.data?.rejected_shortfall);
          } else if (['failed_shortfall'].includes(element)) {
            shortfallData = params?.data?.failed_shortfall && JSON.parse(params?.data?.failed_shortfall);
          }


          // let content = shortfall && shortfall.map((item) => { return item.item_key + ": " + item.detail }).toString()          
          let content = shortfallData && shortfallData.map((item) => {
            return (item.children && (item.children?.length > 0))
              ? item.item_key + ":" + (item.detail ? item.detail : " ") + ", " + item.children.map((child1) => (
                child1.children
                  ? child1.item_key + ":" + (child1.detail ? child1.detail : " ") + ", " + child1.children.map((child2) => (
                    child2.item_key + ":" + (child2.detail ? child2.detail : " ")
                  ))
                  : child1.item_key + ":" + (child1.detail ? child1.detail : " ")
              )
              )
              : item.item_key + ":" + (item.detail ? item.detail : " ")
          }).toString()

          return content && content

        },
      };
    }
    // else if (element === "lead_status") {
    //   columnObj = {
    //     headerName: elementDetails?.title,
    //     headerTooltip: elementDetails?.title,
    //     sortable: true,
    //     field: element,
    //     filter: 'agSetColumnFilter',
    //     // width: 200,
    //     // minWidth: 200,
    //     cellRendererFramework: (params) => {
    //       return (params.value + " -")
    //     },
    //   };
    // }
    else if (element === "id" || element === "people_id" || element === "organisation_id") {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        field: element,
        filter: 'agTextColumnFilter',
        sortable: false,
        cellRendererFramework: (params) => {
          if (params?.data) {

            let cellData = params?.data ? params?.data : null
            let linkID = cellData?.id
            let displayID = null
            let linkPagename = ''
            if (cellData.people_id) {
              displayID = cellData.people_id
              linkPagename = 'people'
            } else if (cellData.organisation_id) {
              displayID = cellData.organisation_id
              linkPagename = 'organisation'
            } else if (cellData.ticket_number) {
              displayID = cellData?.id
              linkID = cellData.ticket_number
              linkPagename = 'ticket'
            } else {
              displayID = cellData?.id
              linkPagename = 'dealdesk'
            }
            return (<><Link to={`/${linkPagename}/${linkID}`} nofollow='true' target='_blank'>{displayID}</Link></>)
          }
          else {
            return null
          }
        },
      };
    }

    else {
      columnObj = {
        headerName: elementDetails?.title,
        headerTooltip: elementDetails?.title,
        field: element,
        cellStyle: { 'text-align': 'left' },
        sortable: true,
        filter: 'agTextColumnFilter',
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (valueA === valueB) { return 0 };
          if (typeof valueA === 'string') {
            valueA = valueA.toLowerCase();
          }
          else {
            valueA = valueA ? valueA : '';
          }
          if (typeof valueB === 'string') {
            valueB = valueB.toLowerCase();
          }
          else {
            valueB = valueB ? valueB : '';
          }
          if (valueA === valueB) return 0;
          return (valueA > valueB) ? 1 : -1;
        }
      };
    }
  }
  return columnObj;
};

export default GetDynamicColumns;
