import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Modal, Radio } from 'antd';
import useStore from '../../../store';
import ListTagTicketComponent from './ListTagTicketComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ModalTagTicketComponent = observer((props) => {
  const {
    TicketsStore,
    TagsStore,
    RIGHTSECTTION_TAGSTICKETS: { tagcount, ticketcount },
  } = useStore();
  const {t}  = useTranslation()
  const [currentTab, setCurrentTab] = useState('tags');
  const [optionsRadiolist, setOptionsRadiolist] = useState([
    { label: 'Tags(' + tagcount + ')', value: 'tags' },
    { label: 'Tickets(' + ticketcount + ')', value: 'tickets' },
  ]);

  useEffect(() => {
    setOptionsRadiolist([
      { label: 'Tags(' + tagcount + ')', value: 'tags' },
      { label: 'Tickets(' + ticketcount + ')', value: 'tickets' },
    ]);
  }, [tagcount, ticketcount]);

  const handleReset = () => {
    if (currentTab === 'tags') {
      TagsStore.handleReset();
    } else {
      TicketsStore.handleReset();
    }
  };

  return (
    <>
      <Modal
        title={`${t('Common_Data.Manage')} ${t('Common_Data.Tags')} & ${t('Common_Data.Tickets')}`}
        centered
        style={{ top: 20 }}
        visible={props.visible}
        footer={false}
        onOk={() => props.close()}
        onCancel={() => props.close()}
        width={1000}
        className='SnapNoteModal'
      >
        <div className='d-flex justify-space-between'>
          <Radio.Group
            options={optionsRadiolist}
            onChange={(e) => setCurrentTab(e.target.value)}
            value={currentTab}
            optionType='button'
            buttonStyle='solid'
            className='peopleOrgTabs'
          />
          <Button
            shape='round'
            size='medium'
            title={`${t('Common_Data.Reset')}`}
            onClick={handleReset}
            className='TagTicketReset'
          >
            <FontAwesomeIcon icon={faUndo} />
          </Button>
        </div>
        <ListTagTicketComponent
          id={currentTab}
          currentTab={currentTab}
          openTagViewDrawer={props.openTagViewDrawer}
          openTicketViewDrawer={props.openTicketViewDrawer}
        />
      </Modal>
    </>
  );
});

export default ModalTagTicketComponent;
