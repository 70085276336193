import React, { useState } from "react";
import { observer } from "mobx-react";
import { Popover } from "antd";
import useStore from "../../../store";
// import { vsmNotify, vsmCallAudit } from "../../../config/messages";
import CallAuditModal from "../../DealDesk/HeaderComponent/component/CallAuditModal";

const PhoneNumberRender = observer((props) => {
  const { AUTH, LEADSSTORE } = useStore();
  const [phone] = useState(props.data.phone);
  const [peoplePhone] = useState(props.data.people_phone);

  // State for handle modal opening to initiate the call
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("work");
  const [selectedNumber, setSelectedNumber] = useState("");

  const handleCallAudit = (data) => {
    setIsModalOpen(true);
    setSelectedNumber(data);
    // let formdata = {
    //   id: props.data.id,
    //   phone: data,
    // };
    // LEADSSTORE.callAudit(formdata).then(() => {
    //   //alert(phone);
    //   vsmNotify.success({
    //     message: vsmCallAudit.added,
    //   });
    // });
  };

  const numbers = (
    <div className="contactList">
      <ul>
        {peoplePhone &&
          peoplePhone.length > 0 &&
          peoplePhone.map((detail, index) => {
            return (
              <li
                key={index}
                value={`${detail.contact_for}: ${detail.contact_value}`}
              >
                <a
                  // href={`tel:${detail.contact_value}`}
                  onClick={(e) => {
                    handleCallAudit(detail.contact_value);
                  }}
                >
                  <img
                    src={
                      (detail.contact_for === "Primary" &&
                        AUTH.GetThemedImage("Phone")) ||
                      (detail.contact_for === "Home" &&
                        AUTH.GetThemedImage("Phone")) ||
                      (detail.contact_for === "Office" &&
                        AUTH.GetThemedImage("Phone")) ||
                      (detail.contact_for === "Other" &&
                        AUTH.GetThemedImage("Phone")) ||
                      (detail.contact_for === "Mobile" &&
                        AUTH.GetThemedImage("Mobile")) ||
                      (detail.contact_for === "SMS" &&
                        AUTH.GetThemedImage("SMS"))
                    }
                    alt=""
                    className="menuicon"
                  />{" "}
                  {detail.contact_value}
                </a>
              </li>
            );
          })}
        {phone &&
          phone.length > 0 &&
          phone.map((detail, index) => {
            return (
              <li
                key={index}
                value={`${detail.contact_for}: ${detail.contact_value}`}
              >
                <a
                  // href={`tel:${detail.contact_value}`}
                  onClick={(e) => {
                    handleCallAudit(detail.contact_value);
                  }}
                >
                  <img
                    src={
                      (detail.contact_for === "Primary" &&
                        AUTH.GetThemedImage("Phone")) ||
                      (detail.contact_for === "Home" &&
                        AUTH.GetThemedImage("Phone")) ||
                      (detail.contact_for === "Office" &&
                        AUTH.GetThemedImage("Phone")) ||
                      (detail.contact_for === "Other" &&
                        AUTH.GetThemedImage("Phone")) ||
                      (detail.contact_for === "Mobile" &&
                        AUTH.GetThemedImage("Mobile")) ||
                      (detail.contact_for === "SMS" &&
                        AUTH.GetThemedImage("SMS"))
                    }
                    alt=""
                    className="menuicon"
                  />{" "}
                  {detail.contact_value}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );

  return (
    <div className="action-column TelephoneRenderer">
      <Popover
        content={numbers}
        placement="topLeft"
        trigger="hover"
        overlayClassName="profileMenu"
      >
        {peoplePhone && peoplePhone.length > 0
          ? peoplePhone.map((detail, index) => {
              if (index === 0) {
                return (
                  <a
                    // href={`tel:${detail.contact_value}`}
                    onClick={(e) => {
                      handleCallAudit(detail.contact_value);
                    }}
                  >
                    <img
                      src={
                        (detail.contact_for === "Primary" &&
                          AUTH.GetThemedImage("Phone")) ||
                        (detail.contact_for === "Home" &&
                          AUTH.GetThemedImage("Phone")) ||
                        (detail.contact_for === "Office" &&
                          AUTH.GetThemedImage("Phone")) ||
                        (detail.contact_for === "Other" &&
                          AUTH.GetThemedImage("Phone")) ||
                        (detail.contact_for === "Mobile" &&
                          AUTH.GetThemedImage("Mobile")) ||
                        (detail.contact_for === "SMS" &&
                          AUTH.GetThemedImage("SMS"))
                      }
                      alt=""
                      className="menuicon"
                    />{" "}
                    {detail.contact_value}
                  </a>
                );
              } else {
                return null;
              }
            })
          : phone &&
            phone.length > 0 &&
            phone.map((detail, index) => {
              return (
                <li
                  key={index}
                  value={`${detail.contact_for}: ${detail.contact_value}`}
                >
                  <a
                    href={`tel:${detail.contact_value}`}
                    onClick={(e) => {
                      handleCallAudit(detail.contact_value);
                    }}
                  >
                    <img
                      src={
                        (detail.contact_for === "Primary" &&
                          AUTH.GetThemedImage("Phone")) ||
                        (detail.contact_for === "Home" &&
                          AUTH.GetThemedImage("Phone")) ||
                        (detail.contact_for === "Office" &&
                          AUTH.GetThemedImage("Phone")) ||
                        (detail.contact_for === "Other" &&
                          AUTH.GetThemedImage("Phone")) ||
                        (detail.contact_for === "Mobile" &&
                          AUTH.GetThemedImage("Mobile")) ||
                        (detail.contact_for === "SMS" &&
                          AUTH.GetThemedImage("SMS"))
                      }
                      alt=""
                      className="menuicon"
                    />{" "}
                    {detail.contact_value}
                  </a>
                </li>
              );
            })}
      </Popover>

      <CallAuditModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        LEADSSTORE={LEADSSTORE}
        formData={{
          id: props.data.id,
          phone: selectedNumber,
          callType: selectedValue,
        }}
        // formData={formData}
      />
    </div>
  );
});
export default PhoneNumberRender;
