import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../component/InputComponent';
import RecordPerPage from '../../../config/RecordPerPage';
import useStore from '../../../store';
import { Form, Spin } from 'antd';
import moment from 'moment';
import debounce from 'lodash/debounce';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../../component/EnterFullscreen';
import ApplyGridFilterNew from '../../../component/ApplyGridFilterNew';
import { filterFiltersByPageType } from '../../../utils/GlobalFunction';
import ResetGridOptions from '../../../component/ResetGridOptions';
import { useTranslation } from 'react-i18next';

const TopSelection = observer((props) => {
  const { t } = useTranslation()
  const [fetchOrgList, setFetchOrgList] = useState(true);
  const [fetchTicketstatus, setFetchTicketstatus] = useState(true);
  const [fetchUsersList, setFetchUsersList] = useState(true);
  const [dropdownPriority, setDropdownPriority] = useState(true);

  const {
    PRODUCTIVITYSTORE,
    ORGANISATION,
    TicketsStore,
    TagsStore,
    //AUTH
    AUTH: { allPageFilters, allPageFiltersLoaded },
  } = useStore();
  const [form] = Form.useForm();

  const handleSubmit = (data) => {
    let dateRangeArr = {};
    if (data.deadline_date) {
      dateRangeArr = {
        from_date: moment(data.deadline_date[0]).format('YYYY-MM-DD'),
        to_date: moment(data.deadline_date[1]).format('YYYY-MM-DD'),
      };
      data.deadline_date = dateRangeArr;
    }

    delete data['RecordPerPage'];
    delete data['RecordPerPage'];

    PRODUCTIVITYSTORE.extraFilter = data;
    if (PRODUCTIVITYSTORE.agGrid) {
      PRODUCTIVITYSTORE.setupGrid(PRODUCTIVITYSTORE.agGrid);
    }
  };

  const onChange = debounce(() => {
    form.submit();
  }, 200);

  useEffect(() => { }, [allPageFilters, allPageFiltersLoaded]);

  const pageFilterType = 'productivity';

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        className='innerForm'
        onFinish={handleSubmit}
        id='TopSelection'
      >
        <div className='topSelection'>
          <InputComponent
            name='organisation_id'
            type='select'
            allowClear
            showSearch={true}
            placeholder={`${t('Common_Data.Organisation')}`}
            onChange={onChange}
            onFocus={() =>
              fetchOrgList &&
              ORGANISATION.getMappedOrg().then(() => setFetchOrgList(false))
            }
            options={{
              values: ORGANISATION.dropdown_global_org,
              value_key: 'id',
              text_key: 'organisation_name',
            }}
            notFoundContent={fetchOrgList ? <Spin size='small' /> : null}
          />
          <InputComponent
            name='ticket_status'
            type='select'
            allowClear
            showSearch={true}
            placeholder={`${t('Productivity.Ticket_Status')}`}
            onChange={onChange}
            onFocus={() =>
              fetchTicketstatus &&
              TicketsStore.getTicketstatusList().then(() => setFetchTicketstatus(false))
            }
            options={{
              values: TicketsStore.dropdown_ticketstatus,
              value_key: 'id',
              text_key: 'status_name',
            }}
            notFoundContent={fetchTicketstatus ? <Spin size='small' /> : null}
          />
          <InputComponent
            name='tag_status'
            type='select'
            allowClear
            showSearch={true}
            placeholder={t('Reports.Tag_Status')}
            onChange={onChange}
            options={{
              values: TagsStore.statusArr_list,
              value_key: 'value',
              text_key: 'text',
            }}
          />
          <InputComponent
            name='priority'
            type='select'
            allowClear
            showSearch={true}
            placeholder={`${t('Common_Data.Priority')}`}
            onChange={onChange}
            onFocus={() => {
              dropdownPriority && TicketsStore.getDropdownPriority().then(() => setDropdownPriority(true))
            }}
            options={{
              values: TicketsStore.dropdown_priority,
              value_key: 'id',
              text_key: 'priority',
            }}
            notFoundContent={dropdownPriority ? <Spin size='small' /> : null}
          />
          <InputComponent
            name='holder'
            type='select'
            allowClear
            showSearch={true}
            placeholder={`${t('Common_Data.User')}`}
            onChange={onChange}
            onFocus={() =>
              fetchUsersList &&
              TicketsStore.getUsersList().then(() => setFetchUsersList(false))
            }
            options={{
              values: TicketsStore.dropdown_user_list,
              value_key: 'id',
              text_key: 'name',
            }}
            notFoundContent={fetchUsersList ? <Spin size='small' /> : null}
          />
          <div className='dateRangeSelection'>
            <InputComponent onChange={onChange} name='deadline_date'
              type='date_range'
              placeholder={[`${t('DealDesk.Start_Date')}`, `${t('DealDesk.End_Date')}`]}
            />
          </div>
          {/* <ApplyGridFilter
            type='productivity'
            selectionValue={PRODUCTIVITYSTORE.selectedGridFilter}
          /> */}
          <ApplyGridFilterNew
            type={pageFilterType}
            selectionValue={PRODUCTIVITYSTORE.selectedGridFilter}
            filterList={filterFiltersByPageType(allPageFilters, pageFilterType)}
          />

          <ResetGridOptions key={'1'} pageType={pageFilterType} form={form} />

          <div className='smallBtn'>
            <EnterFullscreen key='2' />
          </div>
          <RecordPerPage
            key='3'
            initialValue={PRODUCTIVITYSTORE.per_page + ' per page'}
            onChange={PRODUCTIVITYSTORE.setPageSize}
            className='RecordPerPage'
          />
        </div >
      </Form >
    </>
  );
});
export default TopSelection;
