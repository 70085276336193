import React, { useState } from 'react';
import { Col, Spin } from 'antd';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const ParentOrgRowElements = observer(({ handleChange, formDataList, form }) => {
  const [fetchMappedOrg, setFetchMappedOrg] = useState(true);
  const [isGlobalCountryList , setIsGlobalCountryList] = useState(true);
  const [fetchStatusOrg, setFetchStatusOrg] = useState(true);
  const { t } = useTranslation();
  //----------GLOBAL STATE DECLARATION---------------//
  const {AUTH,ORGANISATION,ORGSTATUSSTORE,} = useStore();
  //----------GLOBAL STATE DECLARATION---------------//

  return (
    <>
      <Col span={8}>
        {formDataList.includes('parent_organisation') && (
          <InputComponent
            label={`${t('Reports.Parent_Organisation')}`}
            name='parent_organisation'
            placeholder={`${t('PeopleManagement.All')}`}
            type='select'
            mode='multiple'
            showSearch
            allowClear
            showArrow
            options={{
              values: ORGANISATION.dropdown_global_org,
              value_key: 'id',
              text_key: 'organisation_name',
            }}
            tooltip={`${t('Reports.Parent_Organisation_Tooltip')}`}
            onFocus={() =>
              fetchMappedOrg &&
              ORGANISATION.getMappedOrg().then(() => setFetchMappedOrg(false))
            }
            notFoundContent={fetchMappedOrg ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
          />
        )}
      </Col>

      <Col span={8}>
        {formDataList.includes('organisation_registration_country') && (
          <InputComponent
            label={`${t('Reports.Organisation_Reg_Country')}`}
            type='select'
            mode='multiple'
            name='organisation_registration_country'
            placeholder={`${t('PeopleManagement.All')}`}
            showSearch
            allowClear
            showArrow
            tooltip={`${t('Reports.Organisation_Reg_Country')}`}
            onChange={() => {
              handleChange();
            }}
            options={{
              values: AUTH.global_country_list,
              value_key: 'id',
              text_key: 'country_name',
            }}
            onFocus={() =>
              isGlobalCountryList &&
              AUTH.fetchGlobalCountryList().then(() => setIsGlobalCountryList(false))
            }
            notFoundContent={isGlobalCountryList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
          />
        )}
      </Col>
      <Col span={8}>
        {formDataList.includes('organisation_status') && (
          <InputComponent
            label={`${t('Reports.Organisation_Status_Title')}`}
            name='organisation_status'
            placeholder={`${t('Reports.Organisation_Status_Title')}`}
            type='select'
            mode='multiple'
            showSearch
            allowClear
            showArrow
            options={{
              values: ORGSTATUSSTORE.orgStatusList,
              value_key: 'id',
              text_key: 'status_name',
            }}
            onFocus={() =>
              fetchStatusOrg &&
              ORGSTATUSSTORE.getOrgStatus().then(() => setFetchStatusOrg(false))
            }
            notFoundContent={fetchStatusOrg ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            tooltip={`${t('Reports.Organisation_Status')}`}
          />
        )}
      </Col>
    </>
  );
});

export default ParentOrgRowElements;
