import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Popover, Checkbox, Spin } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import useStore from '../../../store';
import { vsmNotify, vsmSnapNote } from '../../../config/messages';
import { useTranslation } from 'react-i18next';
const Notification = observer((props) => {
  const [visible, setVisible] = useState(false);
  const {t}  = useTranslation()
  const { ExpandView } = props;
  const {
    AUTH,
    SPANNOTESTORE: {
      getList,
      list_data,
      readReminder,
      setUnreadBlink,
      is_snapnote_loadMore,
      snapnoteLoading,
    },
  } = useStore();

  useEffect(() => {
    getList();
  }, [getList]);

  const onChange = (checkedValues) => {
    let payload = {
      id: [checkedValues],
    };
    readReminder(payload).then(() => {
      vsmNotify.success({
        message: vsmSnapNote.read,
      });
    });
  };

  const Notification = (
    <Checkbox.Group style={{ width: '100%' }}>
      <ul className='popover-menu'>
        {list_data?.data?.length > 0 ? (
          list_data.data.map((item, index) => (
            <li key={index}>
              <Checkbox value={item.id} onChange={(e) => onChange(e.target.value)}>
                <strong>{item.created_by_name}</strong>
                <i>{item.snapnote?.created_at}</i>
                <div
                  className='resopnsiveImg'
                  dangerouslySetInnerHTML={{
                    __html:
                      item.snapnote.snap_note_texts &&
                      item.snapnote.snap_note_texts.length > 70
                        ? item.snapnote.snap_note_texts.substr(0, 70) + '...'
                        : item.snapnote.snap_note_texts,
                  }}
                ></div>
              </Checkbox>
            </li>
          ))
        ) : (
          <li className='text-center'>{t('Common_Data.No')} {t('SnapNoteModule.Notification')}</li>
        )}
        {is_snapnote_loadMore && (
          <Spin spinning={snapnoteLoading}>
            <li className='loadMore'>
              <span onClick={() => getList(1)}>
                {snapnoteLoading ? `${t('Common_Data.Load')}` : `${t('SnapNoteModule.Load_More')}`}
              </span>
            </li>
          </Spin>
        )}
      </ul>
    </Checkbox.Group>
  );

  return ExpandView ? (
    Notification
  ) : (
    <Popover
      placement='bottomRight'
      content={Notification}
      title=''
      overlayClassName='profileMenu snapNoteNoti'
      trigger='click'
      visible={visible}
      onVisibleChange={(x) => {
        setVisible(x);
        setUnreadBlink(false);
      }}
    >
      {AUTH.checkPermission(48, 'unread-snap-notes') && (
        <DownOutlined className='bar_icon' />
      )}
    </Popover>
  );
});
export default Notification;
