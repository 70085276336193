import React from 'react';
import { Checkbox, Col, Form } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { capitalFirstLetter } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const UserSelectionElement = observer(({ form, handleChange, report_by = '', resetUserSelection }) => {

  const { t } = useTranslation();
  const {
    REPORTSTORE: { report_dropdown_users_list },
  } = useStore();

  return (
    <>
      <Col span={6}>
        <InputComponent
          label=' '
          type='labelOnly'
        />
        <Form.Item label=' ' name='include_inactive_users' valuePropName='checked'>
          <Checkbox
            onChange={
              (e) => {
                resetUserSelection(e);
                handleChange();
              }
            }
          >
            {t('Reports.Include_non_active')} {form?.getFieldValue('report_by') ? form.getFieldValue('report_by') : ''}?
          </Checkbox>
        </Form.Item>
      </Col>
      <Col span={6}>
        <InputComponent
          label={`${form?.getFieldValue('report_by') ? capitalFirstLetter(form.getFieldValue('report_by')) : 'Users'}`}
          type='select'
          mode='multiple'
          name={report_by ? report_by : 'users'}
          placeholder={`${t('Reports.All')} ${form?.getFieldValue('report_by') ? capitalFirstLetter(form.getFieldValue('report_by')) : 'Users'}`}
          allowClear
          showArrow
          onChange={() => {
            handleChange();
          }}
          options={{
            values: form?.getFieldValue('include_inactive_users')
              ? report_dropdown_users_list
              : report_dropdown_users_list &&
              report_dropdown_users_list.length > 0 &&
              report_dropdown_users_list.filter((e) => e.status === 1),
            value_key: 'id',
            text_key: 'name',
          }}
          tooltip={`${t('Reports.Users_Tooltip')}.`}
        />
      </Col>
    </>
  );
});
export default UserSelectionElement;
