import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store';
// import GridConfig from '../../../../config/GridConfig';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { vsmCommon } from '../../../config/messages';

import PhoneNumberRender from '../../OrganisationManagement/AllOrganisations/elements/PhoneNumberRender';
import EmailNumberRender from '../../OrganisationManagement/AllOrganisations/elements/EmailNumberRender';
import { GridLoader } from '../../../utils/GridLoader';
import moment from 'moment';
import { DateComparator } from '../../../utils/GlobalFunction';
import NoteRenderer from '../ReminderSearch/NoteRenderer';

const ListComponent = observer((props) => {
  const { t } = useTranslation();

  const {
    openEditModal,
    openDeleteModal,
    openViewDrawer,
    WasteManagement,
    SearchPage,
    // onCellKeyDown,
    showCheckbox = false,
    selectionChanged,
  } = props;

  const {
    AUTH,
    ORGANISATION,
    SEARCHSTORE,
    SEARCHSTORE: { searchCount, setResultCount },
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  useEffect(() => {
    if (WasteManagement) {
      ORGANISATION.waste = true;
      ORGANISATION.search_payload = {};
    } else if (SearchPage) {
      ORGANISATION.waste = false;
      SEARCHSTORE.resultCount = SEARCHSTORE?.searchCount?.organisation_count;
    } else {
      ORGANISATION.waste = false;
      ORGANISATION.search_payload = {};
    }
  }, [ORGANISATION, SearchPage, SEARCHSTORE, WasteManagement, ORGANISATION.total]);

  useEffect(() => {
    if (SearchPage) {
      setResultCount(searchCount?.organisation_count);
      ORGANISATION.getListSearch(ORGANISATION.search_payload);
    }
  }, [SearchPage, ORGANISATION, setResultCount, searchCount?.organisation_count])

  const ActionRenderer = (props) => {
    const {
      ORGANISATION: { getLeadTransactionID },
    } = useStore();
    let history = useHistory();

    return (
      <div className='action-column'>
        <>
          <Button
            type='text'
            title={props.data.leads_transactions_id > 0 ? `${t('OrganisationManagement.History')}` : `${t('OrganisationManagement.Start')}`}
            onClick={() => {
              if (props.data.leads_transactions_id > 0) {
                history.push(`/organisation/${props.data?.leads_transactions_id}`);
              } else {
                getLeadTransactionID(props.data.id).then((data) => {
                  history.push(`/organisation/${data.leads_transactions_id}`);
                });
              }
            }}
          >
            <img alt='' className='menuicon' src={AUTH.GetThemedImage('Deal_icn')} />
          </Button>


          {AUTH.checkPermission(16, 'edit') ? (
            <Button
              title={`${t('Common_Data.Edit')}`}
              type='text'
              onClick={() => {
                openEditModal(props.data);
              }}
            >
              <img alt='' className='menuicon' src={AUTH.GetThemedImage('Edit')} />
            </Button>
          ) : null}

          <NoteRenderer data={props.data} />
          {AUTH.checkPermission(16, 'delete') &&
            props?.data?.id !== 1 &&
            (AUTH?.user?.user_role?.user_role_id === 1 ||
              AUTH?.user?.id === props?.data?.created_by) ? (
            props?.data?.transactions !== null ? (
              <Tooltip
                placement='topRight'
                color={'red'}
                title={
                  `${t('OrganisationManagement.Associate_Tooltip')} ${t('OrganisationManagement.Transactions')}.`
                }
              >
                <Button type='text' disabled>
                  <img
                    alt=''
                    className='menuicon disabled'
                    src={AUTH.GetThemedImage('Waste')}
                  />
                </Button>
              </Tooltip>
            ) : props?.data?.associated_ledgers === null ? (
              <Button
                title={`${t('Common_Data.Delete')}`}
                type='text'
                onClick={() => {
                  openDeleteModal(props.data);
                }}
              >
                <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
              </Button>
            ) : props.data?.organisation_role_mapping_count > 0 ||
              props.data?.related_organisation_role_mapping_count > 0 ? (
              <Tooltip
                placement='topRight'
                color={'red'}
                title={
                  `${t('OrganisationManagement.Associate_Tooltip')} ${t('OrganisationManagement.Role')}.`
                }
              >
                <Button type='text' disabled>
                  <img
                    alt=''
                    className='menuicon disabled'
                    src={AUTH.GetThemedImage('Waste')}
                  />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                placement='topRight'
                color={'red'}
                title={
                  `${t('OrganisationManagement.Associate_Tooltip')} ${t('OrganisationManagement.Ledger')}.`
                }
              >
                <Button type='text' disabled>
                  <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              </Tooltip>
            )
          ) : (
            <>
              <Tooltip
                placement='topRight'
                color={'red'}
                title={
                  `${t('OrganisationManagement.Associate_Tooltip')} ${t('OrganisationManagement.Role')}.`
                }
              >
                <Button type='text' disabled>
                  <img alt='' className='menuicon' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              </Tooltip>
            </>
          )}
        </>
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        checkboxSelection: showCheckbox,
        valueGetter: function (params) {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },
        tooltipValueGetter: (params) => {
          return !showCheckbox ? params.node.rowIndex + 1 : '';
        },

        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Organisation')} ${t('Common_Data.Name')}`,
        field: 'organisation_name',
        tooltipField: 'organisation_name',
        filter: 'agTextColumnFilter',
      },
      {
        // headerName: `${t('OrganisationManagement.Trade')} ${t('Common_Data.Name')}`,
        headerName: `t/a`,
        headerTooltip: `t/a`,
        field: 'trade_name',
        tooltipField: 'trade_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Connected_Peoples')}`,
        headerTooltip: `${t('Common_Data.Connected_Peoples')}`,
        field: 'connected_peoples',
        tooltipField: 'connected_peoples',
        // filter: 'agSetColumnFilter',
        floatingFilter: false,
      },
      // {
      //   headerName: `${t('Common_Data.Title')}`,
      //   headerTooltip: `${t('Common_Data.Title')}`,
      //   field: 'title',
      //   tooltipField: 'title',
      //   filter: 'agSetColumnFilter',
      //   // valueGetter: (params) =>
      //   //   params.data && params.data.title ? params.data.title : '',
      // },
      {
        headerName: `${t('Common_Data.Phone')}`,
        headerTooltip: `${t('Common_Data.Phone')}`,
        field: 'phone',
        cellClass: 'cellClassDropDown',
        cellRenderer: 'phoneCellRenderer',
        filter: 'agTextColumnFilter',
        filterParams: {
          valueGetter: params => {
            return params.data.phone.length > 0 && params?.data?.phone[0].contact_value
          }
        }
      },
      {
        headerName: `${t('Common_Data.Email')}`,
        headerTooltip: `${t('Common_Data.Email')}`,
        field: 'email',
        filter: 'agTextColumnFilter',
        cellClass: 'cellClassDropDown',
        cellRenderer: 'emailCellRenderer',
        filterParams: {
          valueGetter: params => {
            return params.data.email.length > 0 && params?.data?.email[0].contact_value
          }
        }
      },
      {
        headerName: `${t('Common_Data.Country')}`,
        headerTooltip: `${t('Common_Data.Country')}`,
        field: 'global_countries.country_name',
        tooltipField: 'global_countries.country_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            ORGANISATION.getCountryFilter().then((data) => {
              params.success([...data?.data?.map((x) => x.country_name)]);
            });
          },
        },
      },
      {
        headerName: `${t('Common_Data.Modified')}`,
        headerTooltip: `${t('Common_Data.Modified')}`,
        field: 'updated_at',
        // tooltipField: 'updated_at',
        cellRendererFramework: (params) => {
          return (
            <Tooltip title={moment(params?.data?.updated_at).format(AUTH.global_fulldate_format)}>
              {/* {AUTH.getLocalTime(params?.data?.updated_at)} */}
              {moment(params?.data?.updated_at).format(AUTH.global_fulldate_format)}
            </Tooltip>
          );
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'reset'],
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('Credebt_Classification.Classification')}`,
        headerTooltip: `${t('Credebt_Classification.Classification')}`,
        field: 'global_industries.name',
        tooltipField: 'global_industries.name',
        filter: 'agSetColumnFilter',

        filterParams: {
          defaultToNothingSelected: true,
          buttons: ['apply', 'reset'],
          values: (params) => {
            ORGANISATION.getIndustries().then((data) => {
              params.success([...data?.data?.map((x) => x.name)]);
            });
          },
        },
      },
      {
        headerName: `Type`,
        headerTooltip: `Type`,
        field: 'org_types',
        tooltipField: 'org_types',
        // filter: 'agSetColumnFilter',
        floatingFilter: false,
        // valueGetter: (params) =>
        //   params.data &&
        //   (params.data.org_types && params.data.org_types.length > 0
        //     ? params.data.org_types.map((x) => x.role_name)
        //     : null),
      },










      // {
      //   headerName: `${t('OrganisationManagement.Role')} (${t('OrganisationManagement.Associated')} ${t('Common_Data.Organisation')})`,
      //   headerTooltip: `${t('OrganisationManagement.Role')} (${t('OrganisationManagement.Associated')} ${t('Common_Data.Organisation')})`,
      //   field: 'organisation_role_mapping',
      //   tooltipValueGetter: (params) =>
      //     params.data &&
      //       params.data?.organisation_role_mapping &&
      //       params.data?.organisation_role_mapping.length > 0
      //       ? params.data?.organisation_role_mapping.map((item, i) => {
      //         return item.role_name + '(' + item.organisation_name + ')';
      //       })
      //       : '',
      //   valueGetter: (params) =>
      //     params.data &&
      //       params.data?.organisation_role_mapping &&
      //       params.data?.organisation_role_mapping.length > 0
      //       ? params.data?.organisation_role_mapping.map((item, i) => {
      //         return item.role_name + '(' + item.organisation_name + ')';
      //       })
      //       : '',
      //   filter: false,
      //   sortable: false,
      //   wrapText: false,
      // },

      // {
      //   headerName: `${t('OrganisationManagement.Registration')} ${t('Common_Data.Number')}`,
      //   headerTooltip: `${t('OrganisationManagement.Registration')} ${t('Common_Data.Number')}`,
      //   field: 'registration_number',
      //   tooltipField: 'registration_number',
      //   filter: 'agTextColumnFilter',
      // },

      // {
      //   headerName: `${t('Common_Data.Status')}`,
      //   headerTooltip: `${t('Common_Data.Status')}`,
      //   field: 'status.status_name',
      //   tooltipField: 'status.status_name',
      //   filter: 'agSetColumnFilter',
      //   filterParams: {
      //     defaultToNothingSelected: true,
      //     buttons: ['apply', 'reset'],
      //     values: (params) => {
      //       ORGANISATION.getStatusFilter().then((data) => {
      //         params.success([...data?.data?.map((x) => x.status_name)]);
      //       });
      //     },
      //   },
      // },
      // {
      //   headerName: `${t('OrganisationManagement.Logo')}`,
      //   headerTooltip: `${t('OrganisationManagement.Logo')}`,
      //   field: 'logo_url',
      //   cellRendererFramework: function (data) {
      //     return data?.data && data?.data?.logo_url ? (
      //       <div className='text-center'>
      //         <Image
      //           style={{ height: '46px', width: 'auto' }}
      //           src={process.env.React_APP_API_URL + data?.data?.logo_url}
      //           alt=''
      //         />
      //       </div>
      //     ) : (
      //       ''
      //     );
      //   },

      //   filter: false,
      //   sortable: false,
      // },

      // {
      //   headerName: `#${t('Common_Data.ID')}`,
      //   headerTooltip: `#${t('Common_Data.ID')}`,
      //   field: 'id',
      //   tooltipField: 'id',
      //   filter: 'agNumberColumnFilter',
      //   width: 120,
      // },
      // {
      //   headerName: `${t('OrganisationManagement.Exchange_Ref')} ${t('Common_Data.ID')}`,
      //   headerTooltip: `${t('OrganisationManagement.Exchange_Ref')} ${t('Common_Data.ID')}`,
      //   field: 'crm_id',
      //   tooltipField: 'crm_id',
      //   filter: 'agTextColumnFilter',
      //   width: 80,
      // },
      {
        headerName: `${t('Common_Data.Actions')}`,
        headerTooltip: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        width: 141,
        // minWidth: 120,
        filter: false,
        sortable: false,
        pinned: 'right',
      },
    ],
  };

  // Columns size change, move, pin
  let columns = [];
  let columnConfig = localStorage.getItem('search_org_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data?.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }

  function getContextMenuItems(params) {
    var result = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'];
    if (params.column.colId === 'actions') {
      result = [
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Tab')}`,
          action: function () {
            window.open(`/organisation/${params?.node?.data?.leads_transactions_id}`, '_blank');
          },
        },
        {
          // custom item
          name: `${t('Common_Data.Open_Link_New_Window')}`,
          action: function () {
            window.open(
              `/organisation/${params?.node?.data?.leads_transactions_id}`,
              "New Window'",
              'location=yes,scrollbars=yes,status=yes'
            );
          },
        }, // built in copy item
        ...result,
      ];
    }

    return result;
  }
  GridLoader(ORGANISATION)

  return (
    <div
      className={`ag-theme-alpine grid_wrapper ${SearchPage ? "paginationAtTop" : ""}`}
      style={{
        height:
          WasteManagement === true
            ? 'calc(100vh - 11rem)'
            : SearchPage
              ? 'calc(100vh - 13rem)'
              : list_fullscreen
                ? fullscreen_heigthwidth
                : props.AllView
                  ? '150px'
                  : 'calc(100vh - 15rem)',
        minHeight: props.AllView ? '100px' : '250px',
      }}
    >
      <AgGridReact
        rowData={ORGANISATION.list_data}
        modules={AllModules}
        columnDefs={gridOptions.columnDefs}
        defaultColDef={{
          ...LocalGridConfig.defaultColDef
        }}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          openEditModal,
          openDeleteModal,
          WasteManagement,
          phoneCellRenderer: PhoneNumberRender,
          emailCellRenderer: EmailNumberRender,
        }}
        // onGridReady={setupGrid}
        onGridReady={ORGANISATION.setupGridSearch}
        gridOptions={{
          ...LocalGridConfig.options,
          pagination: true,
          onRowDoubleClicked: (event) => openViewDrawer(event?.data),
          getContextMenuItems: getContextMenuItems,
        }}
        suppressRowClickSelection={true}
        rowSelection='multiple'
        onColumnResized={ORGANISATION.onGridChangedSearch}
        onColumnMoved={ORGANISATION.onGridChangedSearch}
        onColumnPinned={ORGANISATION.onGridChangedSearch}
        onSortChanged={(params) => {
          if (localStorage.getItem('params')) {
            var temp = JSON.parse(localStorage.getItem('params'));
            localStorage.setItem('params', JSON.stringify({ ...temp, OrgSearchStore: { sort: params.api.getSortModel() }, }));
          } else {
            localStorage.setItem('params',
              JSON.stringify({ OrgSearchStore: { sort: params.api.getSortModel() }, })
            );
          }
        }}
        // onCellKeyDown={onCellKeyDown}
        // onFilterChanged={(e) => {
        //   if (
        //     e.hasOwnProperty('afterFloatingFilter') &&
        //     ORGANISATION.selectedGridFilter
        //   ) {
        //     ORGANISATION.setSelectedGridFilter(null);
        //   }
        // }}
        onSelectionChanged={selectionChanged}
      />
    </div>
  );
});

export default ListComponent;
