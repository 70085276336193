import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../store';
import { vsmNotify } from '../../../config/messages';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ConvertticketComponent = observer((props) => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const history = useHistory();
  const {t} = useTranslation()
  const {
    TicketsStore: { convertTicket },
  } = useStore();

  const close = () => {
    props.close();
    setSaving(false);
    form.resetFields();
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    convertTicket(props.convertTicketTempData)
      .then((data) => {
        close();
        vsmNotify.success({
          message: `${t('Tickets.Ticket_Message')}!`,
        });

        if (data?.lead_transaction_id) {
          history.push(`/dealdesk/${data.lead_transaction_id}`);
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
        setSaving(false);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return props.convertTicketTempData ? (
    <Modal
      centered
      title={`#${props.convertTicketTempData.ticket_number} ${t('Tickets.Convert')} ${t('Common_Data.Ticket')} ${t('Tickets.To')} ${t('Common_Data.Lead')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='convertform'
          htmlType='submit'
          type='primary'
          shape='round'
          loading={saving}
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='convertform' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('Tickets.Convert_Ticket')} {t('Common_Data.To')} {t('Common_Data.Sold')}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default ConvertticketComponent;
