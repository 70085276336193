import React, { useState } from 'react';
import { Button, Popover, Tooltip } from 'antd';
import DateTimeConfirmComponent from './DateTimeConfirmComponent';
import HourglassConfirmComponent from './HourglassConfirmComponent';
import ReassignConfirmComponent from './ReassignConfirmComponent';
import useStore from '../../../store';
import { useHistory } from 'react-router';
// import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../component/DatePickerCostume';

export const ActionRenderer = (props) => {
  const { t } = useTranslation()
  const {
    AUTH,
    TagsStore: { getUsersList },
    REMINDERSSTORE: { getReminderDatepickerExtraFooter },
  } = useStore();
  let history = useHistory();

  //const [count, setCount] = useState(0);
  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [openHourglassModal, setOpenHourglassModal] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [date, setDate] = useState();

  const [reAssignUser, setReAssignUser] = useState(null);
  const [reAssingTempData, setReAssingTempData] = useState(null);
  const [reminderConfirm, setReminderConfirm] = useState();
  const [hourglassConfirm, setHourglassConfirm] = useState();
  const [extrafooter, setExtrafooter] = useState(null);

  const {
    //openViewDrawer,
    openDeleteDrawer,
    openRestoreModal,
    //openEditDrawer,
    openEditViewDrawer,
    //openCloneDrawer,
    TagTicketModule,
    WasteManagement,
  } = props.agGridReact.props.frameworkComponents;

  // const handleCount = () => {
  //   setCount(count + 1);
  // };

  //Confirm Modal
  const openConfirmDateTimeModal = (dateformat, data) => {
    if (dateformat) {
      setDate(dateformat);
      setReminderConfirm(data);
      setOpenDateTimeModal(true);
    }
  };
  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    setOpenDateTimeModal(false);
  };

  const openConfirmHourglassModal = (data) => {
    //setCount(count + 1);
    setHourglassConfirm(data);
    setOpenHourglassModal(true);
  };
  const closeHourglassModal = () => setOpenHourglassModal(false);

  const openConfirmReassignModal = (id, username, tagData) => {
    let tempReAssign = {
      userid: id,
      username: username,
      tagno: tagData.tagno,
      tagid: tagData.id,
    };
    setReAssingTempData(tempReAssign);
    setOpenReassignModal(true);
  };
  const closeReassignModal = () => setOpenReassignModal(false);

  const userList = (
    <div className='ticketsPopoverIcons username'>
      <ul>
        {reAssignUser &&
          reAssignUser.map(function (item) {
            return (
              <li
                key={item.id}
                onClick={() => openConfirmReassignModal(item.id, item.name, props.data)}
              >
                {item.name}
              </li>
            );
          })}
      </ul>
    </div>
  );

  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // }

  // const disabledHours = (e) => {
  //   let hours = [19, 20, 21, 22, 23];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  let approvedTagNoteDesc = props.data?.tag_content?.includes('<approved');
  let approvedTag = false;
  if (approvedTagNoteDesc) {
    approvedTag = true;
  }

  return (
    <div className='action-column'>
      {WasteManagement === true ? (
        <>
          {/* {AUTH.checkPermission(52, 'view-details') && (
            <Button
              type='text'
              title='View'
              onClick={() => {
                openViewDrawer(props.data);
              }}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
            </Button>
          )} */}
          {AUTH.checkPermission(52, 'delete') && (
            <Button
              type='text'
              title={`${t('Common_Data.Delete')}`}
              onClick={() => {
                openDeleteDrawer(props.data);
              }}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
            </Button>
          )}
          {AUTH.checkPermission(52, 'restore') && (
            <Button
              type='text'
              title={`${t('Common_Data.Restore')}`}
              onClick={() => {
                openRestoreModal(props.data);
              }}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('Restore')} />
            </Button>
          )}
        </>
      ) : (
        <>
          {/* {AUTH.checkPermission(14, 'view-details') && (
            <Button
              type='text'
              title='View'
              onClick={() => {
                openViewDrawer(props.data);
              }}
            >
              <img className='menuicon' alt='' src={AUTH.GetThemedImage('View')} />
            </Button>
          )} */}

          {!TagTicketModule ? (
            <>
              {/* {props?.data?.leads_transactions_id && ( */}
              <Button
                type='text'
                //title='Deal Desk'
                title={`${t('Common_Data.View')} ${t('Tags.Chat')} ${t('Tags.History')} ${t('Common_Data.Tags')}`}
                disabled={props.data?.leads_transactions_id ? false : true}
              >
                <img
                  className='menuicon'
                  alt=''
                  src={AUTH.GetThemedImage('Deal_icn')}
                  onClick={() => {
                    if (props.data?.leads_notes_id) {
                      history.push(
                        `/dealdesk/${props.data.leads_transactions_id}#n${props.data?.leads_notes_id}`
                      );
                    } else {
                      history.push(`/dealdesk/${props.data.leads_transactions_id}`);
                    }
                  }}
                />
              </Button>
              {/* )} */}

              {AUTH.checkPermission(14, 'edit') && (
                <Button
                  type='text'
                  title={`${t('Common_Data.Edit')}`}
                  onClick={() => {
                    openEditViewDrawer(props.data, props.rowIndex);
                  }}
                >
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
                </Button>
              )}

              {AUTH.checkPermission(14, 'delete') && (
                <Button
                  type='text'
                  title={`${t('Common_Data.Delete')}`}
                  onClick={() => {
                    openDeleteDrawer(props.data);
                  }}
                >
                  <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
                </Button>
              )}

              {/* {AUTH.checkPermission(14, 'clone') && (
                <Button
                  type='text'
                  title='Clone'
                  onClick={() => {
                    openCloneDrawer(props.data);
                  }}
                >
                  <img
                    className='menuicon'
                    alt=''
                    src={AUTH.GetThemedImage('Clone_Record')}
                  />
                </Button>
              )} */}

              {AUTH.checkPermission(14, 'reassign') &&
                (props.data?.tag_status_id &&
                  ![5, 3].includes(props.data?.tag_status_id) &&
                  !approvedTag ? (
                  <Popover
                    content={userList}
                    placement='bottomRight'
                    trigger='hover'
                    overlayClassName='profileMenu'
                  >
                    <Button type='text' title={`${t('Common_Data.Reassign')}`}>
                      <img
                        className='menuicon'
                        alt=''
                        src={AUTH.GetThemedImage('Reassign')}
                        onMouseEnter={() => {
                          if (!reAssignUser) {
                            getUsersList().then((data) => {
                              setReAssignUser(data.data);
                            });
                          }
                        }}
                      />
                    </Button>
                  </Popover>
                ) : (
                  <Button type='text' title={`${t('Common_Data.Reassign')}`} disabled={true}>
                    <img
                      className='menuicon'
                      alt=''
                      src={AUTH.GetThemedImage('Reassign')}
                    />
                  </Button>
                ))}

              {AUTH.checkPermission(14, 'reset-reminder-date-time') && (
                //props?.data?.reset_count !== null &&
                <span
                  style={{ position: 'relative', overflow: 'hidden' }}
                  onMouseEnter={() => {
                    if (props.data?.reset_count !== null) {
                      getReminderDatepickerExtraFooter(
                        props.data,
                        AUTH.global_fulldate_format,
                        {
                          type: 'tags',
                        }
                      ).then((data) => {
                        setExtrafooter(data);
                      });
                    }
                  }}
                >
                  <Tooltip
                    title={
                      props.data?.reset_count !== null ? `${t('Common_Data.Reset_Specific_Reminder')}` : `${t('Common_Data.Set_Specific_Reminder')}`
                    }
                  >
                    <DatePickerCostume
                      name='Calendar'
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 5,
                        opacity: 0,
                      }}
                      showTime
                      disabledDate={true}
                      // disabledHours={() => disabledHours()}
                      // minuteStep={15}
                      // hideDisabledOptions
                      showNow={false}
                      onOk={(e) => openConfirmDateTimeModal(e, props.data)}
                      renderExtraFooter={() => extrafooter}
                      />
                  </Tooltip>
                  <Button type='text' title={`${t('Common_Data.Calendar')}`}>
                    <img
                      className='menuicon'
                      alt={`${t('Common_Data.Calendar')}`}
                      src={AUTH.GetThemedImage('Calendar')}
                      title={`${t('Common_Data.Calendar')}`}
                    // onClick={handleCount}
                    />
                  </Button>
                </span>
              )}

              {AUTH.checkPermission(14, 'reset-reminder-time') && (
                //props?.data?.reset_count !== null &&
                <div>
                  <img
                    style={{ margin: 5 }}
                    className='menuicon'
                    title={`${t('Common_Data.Hour_Glass')}`}
                    alt={`${t('Common_Data.Hour_Glass')}`}
                    src={AUTH.GetThemedImage('Reminders_icn')}
                    onClick={() => openConfirmHourglassModal(props.data)}
                  />
                  <span>{props.data?.reset_count ? props.data?.reset_count : 0}</span>
                </div>
              )}
              {/* </Space> */}
              {/* {(AUTH.checkPermission(14, 'reassign') ||
                AUTH.checkPermission(14, 'reset-reminder-date-time') ||
                AUTH.checkPermission(14, 'reset-reminder-time')) && (
                  <Popover
                    content={content}
                    placement='bottomRight'
                    trigger='hover'
                    overlayClassName='profileMenu'
                  >
                    <Button type='text' title='Clone'>
                      <img
                        className='menuicon'
                        alt=''
                        src={AUTH.GetThemedImage('DropdownBox')}
                      />
                    </Button>
                  </Popover>
                )} */}

              <DateTimeConfirmComponent
                visible={openDateTimeModal}
                close={closeDateTimeModal}
                date={date}
                reminderConfirm={reminderConfirm}
              />
              <HourglassConfirmComponent
                visible={openHourglassModal}
                close={closeHourglassModal}
                hourglassConfirm={hourglassConfirm}
              />
              <ReassignConfirmComponent
                visible={openReassignModal}
                close={closeReassignModal}
                reAssingTempData={reAssingTempData}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
};
