import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Col, Spin } from 'antd';
import InputComponent from '../../../component/InputComponent';
import { vsmReminders } from '../../../config/messages';
import useStore from '../../../store';
import DropDownSelection from './DropDownSelection';
import moment from 'moment';
import { reminderTypeBasedOn } from '../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';
const FormElements = observer(
  ({ handleSubmit, onChange, form, id, priority, isDeal, isAdd = false }) => {
    const {
      AUTH: { user },
      REMINDERSSTORE: { editValues, dropdown_user_list, getUsersList },
      DEALDESKSTORE: { deal_data },
    } = useStore();
    const [fetchUsersList, setFetchUsersList] = useState(true);
    const [typeValue, setTypeValue] = useState();
    const { t } = useTranslation()
    // handle Type Change
    const handleTypeChange = (values) => {
      form.resetFields([
        'userSelect',
        'tag_id',
        'ticket_id',
        'leads_id',
        'contacts_id',
        'organisations_id',
      ]);
      setTypeValue(values);
      onChange();
    };
    const onFinish = (data) => {
      handleSubmit(data);
    };

    useEffect(() => {
      if (editValues) {
        setTypeValue(editValues.reminder_for && editValues.reminder_type);
      }
    }, [editValues]);

    useEffect(() => {
      if (isDeal) {
        if (deal_data?.lead_information?.is_ticket === 0) {
          form.setFieldsValue({
            reminder_type: 'tickets',
            ticket_id: deal_data?.lead_information?.ticket_data?.id,
          });
          setTypeValue('tickets');
        } else if (deal_data?.lead_information?.is_ticket === 1) {
          form.setFieldsValue({
            receiver_by: user?.id,
            reminder_type: 'leads',
            leads_id: deal_data?.lead_information?.id,
          });
          setTypeValue('leads');
        } else if (deal_data?.lead_information?.is_ticket === 2) {
          form.setFieldsValue({
            reminder_type: 'contacts',
            contacts_id: `p${deal_data?.lead_information?.people?.id}`,
          });
          setTypeValue('contacts');
        } else if (deal_data?.lead_information?.is_ticket === 3) {
          form.setFieldsValue({
            reminder_type: 'organisations',
            organisations_id: `o${deal_data?.lead_information?.sold_organisation?.id}`,
          });
          setTypeValue('organisations');
        }
      }
    }, [form, deal_data, isDeal, user]);



    // function disabledDate(current) {
    //   // Can not select days before today and today
    //   return current && current <= moment().subtract(3, 'days').endOf('day');
    // }

    // const disabledHours = (e) => {
    //   let hours = [19, 20, 21, 22, 23];
    //   for (let i = 0; i < 7; i++) {
    //     hours.push(i);
    //   }
    //   return hours;
    // };


    return (
      <Form
        form={form}
        layout='vertical'
        id={id}
        className='innerForm hideErrorMessage'
        onFinish={onFinish}
        onChange={onChange}
      >
        <Row gutter={[15, 6]}>
          <Col span={7}>
            <InputComponent
              name='reminder_type'
              label={`${t('Common_Data.Reminder')} ${t('Common_Data.Type')}`}
              type='select'
              tooltip={`${t('Common_Data.Reminder')} ${t('Common_Data.Related')} ${t('Common_Data.To')}`}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Reminder')} ${t('Common_Data.Type')}`}
              required
              allowClear
              onChange={handleTypeChange}
              rules={vsmReminders.validation.reminder_type}
              disabled={isDeal || !isAdd ? true : false}
              options={{
                values: reminderTypeBasedOn(isAdd),
              }}
            />
          </Col>
          {!(typeValue === null || typeValue === 'notes' || typeValue === undefined || typeValue === 'private') ?
            <Col span={7}>
              <DropDownSelection
                typeValue={typeValue}
                onChange={onChange}
                isAdd={isAdd}
                isDeal={isDeal}
              />
            </Col>
            : null
          }
          <Col span={6}>
            <InputComponent
              name='receiver_by'
              label={`${t('Common_Data.Assign')} ${t('Reminders.To')}`}
              placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Assign')} ${t('Reminders.To')}`}
              type='select'
              tooltip={`${t('Reminders.Reminder_Tooltip')}`}
              required
              allowClear
              onChange={() => {
                onChange();
              }}
              onFocus={() =>
                fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
              }
              disabled={
                typeValue === 'private' ||
                  form.getFieldValue('reminder_type') === 'private'
                  ? true
                  : false
              }
              options={{
                values:
                  dropdown_user_list ||
                  (editValues?.receiver_by_name && [
                    {
                      id: editValues.receiver_by_name.id,
                      name:
                        (editValues.receiver_by_name.first_name
                          ? editValues.receiver_by_name.first_name
                          : '') +
                        ' ' +
                        (editValues.receiver_by_name.last_name
                          ? editValues.receiver_by_name.last_name
                          : ''),
                    },
                  ]) ||
                  (deal_data?.lead_information && [
                    {
                      id: user?.id,
                      name: user?.first_name,
                    },
                  ]),
                value_key: 'id',
                text_key: 'name',
              }}
              notFoundContent={
                fetchUsersList ? (
                  <div className='spinCenter'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              rules={vsmReminders.validation.holder}
            />
          </Col>
        </Row>
        <Row gutter={[15, 6]}>
          <Col span={24} className='d-flex' style={{ gap: '15px' }}>
            <InputComponent
              name='priority'
              label={`${t('Common_Data.Priority')}`}
              type='radio_button'
              tooltip={`${t('Reminders.Priority_Tooltip')}.`}
              required
              onChange={onChange}
              initialValue={'2'}
              rules={vsmReminders.validation.priority}
              options={{
                values: [
                  { value: '2', text: `${t('Reminders.Routine')}` },
                  { value: '1', text: `${t('Reminders.Timed')}` },
                  { value: '5', text: `${t('Reminders.Urgent')}` },
                  { value: '6', text: `${t('Reminders.Critical')}` },
                  { value: '3', text: `${t('Reminders.Normal')}` },
                  { value: '4', text: `${t('Reminders.High')}` },
                ],
              }}
            />

            {(priority === '1' || priority === '5') && (

              <InputComponent
                name='reminder_date'
                label={`${t('Common_Data.Reminder')} ${t('Reminders.Due')} ${t('Common_Data.Date')}`}
                type='date'
                tooltip={`${t('Reminders.Reminder_Due_Date_Tooltip')}.`}
                required
                placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Date')}`}
                disabledDate={true}
                // disabledHours={disabledHours}
                onChange={onChange}
                format={'YYYY-MM-DD HH:mm'}
                showTime={true}
                initialValue={moment()}
                // minuteStep={15}
                rules={vsmReminders.validation.reminder_date}
                // hideDisabledOptions
              />
            )}
          </Col>


          <Col span={24}>
            <InputComponent
              name='subject'
              label={`${t('Common_Data.Subject')}`}
              placeholder={`${t('Common_Data.Subject')}`}
              type='input'
              tooltip={`${t('Reminders.Subject_Tooltip')}`}
              required
              onChange={onChange}
              rules={vsmReminders.validation.subject}
            />
          </Col>

          <Col span={24}>
            <InputComponent
              name='description'
              label={`${t('Common_Data.Description')}`}
              placeholder={`${t('Common_Data.Description')}`}
              type='textarea'
              tooltip={`${t('Common_Data.Reminder')} ${t('Reminders.Short')} ${t('Reminders.Description')}`}
              onChange={onChange}
              rows={2}
              required={!deal_data?.lead_information?.is_ticket}
              rules={
                !deal_data?.lead_information?.is_ticket
                  ? vsmReminders.validation.description
                  : [{ required: false, message: '' }]
              }
            />
          </Col>
          <Col span={24}>
            <InputComponent
              name='full_description'
              label={`${t('Common_Data.Full')} ${t('Reminders.Description')}`}
              placeholder={`${t('Common_Data.Full')} ${t('Reminders.Description')}`}
              type='textarea'
              tooltip={`${t('Common_Data.Reminder')} ${t('Common_Data.Full')} ${t('Reminders.Description')}`}
              onChange={onChange}
              rows={5}
            />
          </Col>
        </Row>
      </Form>
    );
  }
);

export default FormElements;
