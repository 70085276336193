import React from 'react';
import { List } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../../../store';
import copy from 'copy-to-clipboard';
import { vsmNotify } from '../../../../../config/messages';
import { useTranslation } from 'react-i18next';

const KeyNotes = observer((props) => {
  const {t} = useTranslation();
  const { AUTH } = useStore();

  const copyIcon = (note) => (
    <img
      style={{ width: '18px' }}
      src={AUTH.GetThemedImage('Copy_icn')}
      alt={`${t('DealDesk.Copy')}`}
      onClick={(event) => {
        copy(note);
        vsmNotify.success({
          message: `${t('DealDesk.Copied')}!`,
        });
      }}
    />
  );

  return props.key_notes_data && (
    <>
      <List
      className='keyNotesList'
        itemLayout="horizontal"
        dataSource={props && props.key_notes_data}
        renderItem={item => item && (
          <List.Item actions={[copyIcon(item.key_note)]}>
            <List.Item.Meta
              description={<p style={{ marginLeft: 10, fontSize: 13, padding: 0 }}>{item && item.key_note}</p>}
            />
          </List.Item>
        )}
      />
    </>
  );
});

export default KeyNotes;
