import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';

import useStore from '../../../../store';
import StructureTableContent from '../../RightSideComponent/Structure/component/StructureTableContent';
import { dateCompare } from '../../../../utils/GlobalFunction';

const DisplayMsgList = observer(
  ({ openViewDrawer, apiTypeValue, listData, listingType }) => {
    const {
      AUTH,
      DEALDESKSTORE,
      DEALDESKSTORE: {
        //deal_data,
        setActiveStructureData,
      },
    } = useStore();
    const location = useLocation();

    // Scroll to note from tag list
    useEffect(() => {
      if (
        listData &&
        location.hash &&
        (location.hash.substr(0, 2) === '#d' || location.hash.substr(0, 2) === '#b' || location.hash.substr(0, 2) === '#a')
      ) {
        let checkNoteExists = document.getElementById(location.hash);
        if (checkNoteExists) {
          document.getElementById(location.hash).scrollIntoView();
        }
      }
    }, [location.hash, listData]);

    const getCharacterByType = () => {
      if (apiTypeValue === 1) {
        return 'd'
      }
      else if (apiTypeValue === 3) {
        return 'b'
      }
      else if (apiTypeValue === 4) {
        return 'a'
      }
    }

    let filterDataArr =
      listData && listData.filter((x) => x.type === apiTypeValue && ((!x.autosave) || (x.autosave === true && x.draft_description !== null)));
    let hashUrlAlphabet = getCharacterByType();

    return (
      <>
        <div className='DisplayMsgList mb-30'>
          {filterDataArr &&
            filterDataArr.map((item, index) => {
              let item_date_different = true;
              if (item.updated_at && item.created_at) {
                item_date_different = dateCompare(
                  new Date(item.updated_at),
                  new Date(item.created_at)
                );
              }

              return (
                <>
                  <div
                    key={index}
                    id={`${!index
                      ? `${listingType}_ScrollToLatest ${`#${hashUrlAlphabet}${item.id}`}`
                      : `${`#${hashUrlAlphabet}${item.id}`}`
                      }`}
                    className={`msgBox 
                ${DEALDESKSTORE?.activeStructureData?.id === item?.id ? 'active' : ''}
                ${item.type &&
                      ((item.type === 'incoming' ? 'incoming' : '') ||
                        (item.type === 'outgoing' ? 'outgoing' : '') ||
                        (item.type === 'comments' ? 'comments' : ''))
                      }`}
                    onClick={() => setActiveStructureData(item)}
                  >
                    <div className='header'>
                      {/* <div className='details'>
                    <Tooltip title={item.organisation}>
                      {deal_data &&
                        deal_data.lead_information &&
                        deal_data.lead_information.organisation_name &&
                        deal_data.lead_information.organisation_name.organisation_name}
                    </Tooltip>
                  </div> */}
                      <div className='details'>
                        <strong>
                          <Tooltip title={item.updated_by_name}>
                            {item.updated_by_name}
                          </Tooltip>
                        </strong>
                        {` `}
                        <Tooltip title={AUTH.getLocalTime(item.updated_at)}>
                          {AUTH.getLocalTime(item.updated_at)}
                        </Tooltip>
                      </div>

                      {item_date_different ? (
                        <div className='details detailsNoteCreated'>
                          <img
                            style={{ width: 12, height: 12 }}
                            alt=''
                            src={AUTH.GetThemedImage('Date_Time_icn')}
                          />
                          {` `}
                          <Tooltip title={item.created_by_name}>
                            {item.created_by_name}
                          </Tooltip>
                          {` : `}
                          {` ${AUTH.getLocalTimeDealDesk(
                            item.created_at,
                            AUTH.global_dateformat
                          )}`}
                        </div>
                      ) : null}

                      <div className='dealdeskNoteNo'>
                        <a href={`#${hashUrlAlphabet}${item.id}`}>
                          {hashUrlAlphabet}#{filterDataArr.length - index}
                        </a>
                      </div>
                    </div>
                    <div className='mt-10'>
                      <StructureTableContent Structure={item.description} />
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </>
    );
  }
);

export default DisplayMsgList;
