import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
export default class ConnectionsStore {
  connection_data = null;
  displayConnectionList = [];
  otherConnections_data = null;
  selectedconnections = null;

  fetchConnections = (id) => {
    return Axios.get(`deal-desk/connection/list/${id}`)
      .then(({ data }) => {
        if (data && data.hasOwnProperty('data')) {
          let displayList = [];
          if (data && data.data.length) {
            data.data.forEach((item, index) => {
              item.key = index + 1;
              item.index = index;
              item.peopleorgname = item?.entity ? item?.entity?.name : '';
              item.role_name = item.role
                ? item?.role?.role_name
                : item.role_id
                  ? '-'
                  : 'Primary';
              let connectionRole = [];
              if (item?.connection_role_name) {
                connectionRole = item?.connection_role_name ? item?.connection_role_name?.split('#') : []
                if (connectionRole?.length > 0) {
                  connectionRole.map((obj, index) => {
                    let newObj = {
                      id: item.id,
                      connection_role: obj,
                      entity_id: item.entity_id,
                      connection_role_name: item.connection_role_name,
                      entity_type: item?.entity_type,
                      peopleorgname: item?.entity ? item?.entity?.name : '',
                      role_name: item.role_name,
                    };
                    displayList.push(newObj);
                    return null;
                  })
                }
              }
              else {
                displayList.push(item);
              }
            });
          }
          if (data && data?.other_connections && data.other_connections.length) {
            data.other_connections.forEach((item, index) => {
              item.key = index + 1;
              item.index = index;
              item.peopleorgname = item?.entity ? item?.entity?.name : '';
              item.role_name = item.role
                ? item?.role?.role_name
                : item.role_id
                  ? '-'
                  : '-';
                  displayList.push(item);
            });
          }
          this.connection_data = data && data.data;
          this.otherConnections_data = data && data?.other_connections;
          this.displayConnectionList = displayList;
          return data && data.data;
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  updateConnection = (id, formdata) => {
    return Axios.post(`deal-desk/connection/add-remove/${id}`, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };
  updateSequence = (payload, id) => {
    return Axios.post(`deal-desk/connection/update-connections/${id}`, payload)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.resolve({ data: null });
      });
  };
  setConnectionData = (data) => {
    this.connection_data = data;
  };
  setSelectedconnections = (data) => {
    this.selectedconnections = data;
  };
}
decorate(ConnectionsStore, {
  connection_data: observable,
  selectedconnections: observable,
  otherConnections_data: observable,
  displayConnectionList: observable,
  fetchConnections: action,
  updateConnection: action,
  updateSequence: action,
  setConnectionData: action,
  setSelectedconnections: action,
});
