import React, { useEffect, useState } from 'react'; //  useState
import { observer } from 'mobx-react';
import { Modal, Col, Row, Button } from 'antd';
import useStore from '../../../store';
import moment from 'moment';

import { default as TicketDateTimeConfirmComponent } from '../../Tickets/component/DateTimeConfirmComponent';
import { default as TicketHourglassConfirmComponent } from '../../Tickets/component/HourglassConfirmComponent';

import { default as TagsDateTimeConfirmComponent } from '../../Tags/component/DateTimeConfirmComponent';
import { default as TagsHourglassConfirmComponent } from '../../Tags/component/HourglassConfirmComponent';

import { default as LeadDateTimeConfirmComponent } from '../../LeadListing/elements/DateTimeConfirmElements';
import { default as LeadHourglassConfirmComponent } from '../../LeadListing/elements/HourglassConfirmElements';

import { default as PeopleDateTimeConfirmElements } from '../../PeopleManagement/People/elements/DateTimeConfirmElements';
import { default as PeopleHourglassConfirmElements } from '../../PeopleManagement/People/elements/HourglassConfirmElements';

import { default as OrgDateTimeConfirmElements } from '../../OrganisationManagement/AllOrganisations/elements/DateTimeConfirmElements';
import { default as OrgHourglassConfirmElements } from '../../OrganisationManagement/AllOrganisations/elements/HourglassConfirmElements';
import { vsmNotify } from '../../../config/messages';
import { useTranslation } from 'react-i18next';
import DatePickerCostume from '../../../component/DatePickerCostume';

const ReminderNotify = observer((props) => {
  const { t } = useTranslation();
  const [openHourglassModal, setOpenHourglassModal] = useState(false);
  const [hourglassConfirm, setHourglassConfirm] = useState();

  const [openDateTimeModal, setOpenDateTimeModal] = useState(false);
  const [date, setDate] = useState();
  const [reminderConfirm, setReminderConfirm] = useState();

  const {
    AUTH,
    AUTH: {
      reminderNotifyVisible,
      notificationData,
      //setnotificationData,
      //setnotificationVisible,
    },
    REMINDERSSTORE,
    SPANNOTESTORE,
    REMINDERSSTORE: { viewValues },
  } = useStore();

  useEffect(() => {}, [notificationData]);


  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current <= moment().subtract(1, 'days').endOf('day');
  // }

  //reset Notification Data
  const resetNotificationData = () => {
    if (AUTH.reminderNotifyVisible && AUTH.notificationData) {
      AUTH.reminderNotifyVisible = false;
      AUTH.notificationData = null;
    }
  };

  //Confirm Modal
  const openConfirmDateTimeModal = (dateformat) => {
    if (dateformat) {
      setDate(dateformat);
      setReminderConfirm(notificationData);
      setOpenDateTimeModal(true);
    }
  };
  const closeDateTimeModal = () => {
    setDate(null);
    setReminderConfirm(null);
    setOpenDateTimeModal(false);
    //resetNotificationData();
  };

  const openConfirmHourglassModal = (data) => {
    setOpenHourglassModal(true);
    setHourglassConfirm(notificationData);
  };
  const closeHourglassModal = () => {
    setOpenHourglassModal(false);
    // resetNotificationData();
  };

  let priorityFilter = REMINDERSSTORE.priorityForFilter.filter(
    (x) => x.value === viewValues?.priority
  );

  return (
    notificationData && (
      <>
        <Modal
          width={1000}
          title={`${t('Common_Data.Reset')} ${t('Common_Data.Reminder')} - #${viewValues.id}`}
          visible={reminderNotifyVisible}
          className='reminderModal'
          onCancel={() => { resetNotificationData(); AUTH.setnotificationVisible(false) }}
          footer={[
            <>
              <Row>
                <Col span={12} className='text-left'>
                  <Button
                    type='primary'
                    onClick={() => {
                      SPANNOTESTORE.readCommonReminder({
                        reminder_id: [viewValues.id],
                        type: viewValues.type,
                      }).then(() => {
                        resetNotificationData();
                        AUTH.setnotificationVisible(false)
                        vsmNotify.success({
                          message: `${t('Common_Data.Reminder_Updated')}!`,
                        });
                      });
                    }}
                  >
                    {t('Reminders.Mark_Completed')}
                  </Button>
                </Col>
                <Col span={12}>
                  <div className='rightAction'>
                    {notificationData && viewValues?.leads_transactions_id && (
                      <img
                        style={{ margin: 'auto 20px' }}
                        className='menuicon'
                        title={`${t('Block_Edit_Assign.Deal_Desk')}`}
                        alt={`${t('Block_Edit_Assign.Deal_Desk')}`}
                        src={AUTH.GetThemedImage('Deal_icn')}
                        onClick={() => {
                          resetNotificationData();
                          AUTH.setnotificationVisible(false)
                          window.open(`/dealdesk/${viewValues?.leads_transactions_id}`, '_blank');
                        }}
                      />
                    )}
                    <span style={{ position: 'relative', overflow: 'hidden' }}>
                      <DatePickerCostume
                        name='Calendar'
                        title={`${t('Common_Data.Calendar')}`}
                        //className='cursor'
                        disabledDate={true}
                        // disabledHours={disabledHours}
                        // disabledMinutes={getDisabledMinutes}
                        // minuteStep={15}
                        showTime
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          zIndex: 5,
                          opacity: 0,
                        }}
                        value={moment(AUTH.getLocalTimeDealDesk(new Date()))}
                        onChange={(e) => openConfirmDateTimeModal(e)}
                        showNow={false}
                        onOk={(e) => openConfirmDateTimeModal(e)}
                      />
                      <img
                        className='menuicon'
                        alt={`${t('Common_Data.Calendar')}`}
                        src={AUTH.GetThemedImage('Calendar')}
                        title={`${t('Common_Data.Calendar')}`}
                      //onClick={handleCount}
                      />
                    </span>

                    <img
                      style={{ margin: 'auto 20px' }}
                      className='menuicon'
                      title={`${t('Common_Data.Hour_Glass')}`}
                      alt={`${t('Common_Data.Hour_Glass')}`}
                      src={AUTH.GetThemedImage('Reminders_icn')}
                      onClick={() => openConfirmHourglassModal(notificationData)}
                    />

                    <span>
                      {notificationData?.reset_count ? notificationData?.reset_count : 0}
                    </span>
                  </div>
                </Col>
              </Row>
            </>,
          ]}
        >
          <div className='viewPeople'>
            <table
              cellPadding='0'
              cellSpacing='0'
              border='0'
              style={{ margin: '-15px 0 0' }}
            >
              <tr>
                <td width='25%' style={{ border: '0', height: '1px' }}></td>
                <td width='25%' style={{ border: '0', height: '1px' }}></td>
                <td width='25%' style={{ border: '0', height: '1px' }}></td>
                <td width='25%' style={{ border: '0', height: '1px' }}></td>
              </tr>
              <tr>
                <th>{t('Common_Data.Reminder')} {t('Common_Data.Type')} :</th>
                <td>
                  {viewValues?.reminder_type &&
                    REMINDERSSTORE.remindersTypeValue[viewValues?.reminder_type]}
                </td>
                <th>{t('Common_Data.Reminder')} {t('Reminders.For')} :</th>
                <td>
                  {viewValues?.reminder_type === 'contacts'
                    ? viewValues?.reminder_for_info
                    : viewValues?.reminder_for_name}
                </td>
              </tr>

              <tr>
                <th>{t('Common_Data.Priority')} :</th>
                <td>
                  {priorityFilter && priorityFilter.length > 0
                    ? priorityFilter[0].text
                    : ''}
                </td>
                <th>{t('Common_Data.Status')} :</th>
                <td>{viewValues?.status}</td>
              </tr>

              <tr>
                <th>{t('Common_Data.Reminder')} {t('Reminders.Due')} {t('Common_Data.Date')} :</th>
                <td>
                  {viewValues?.due_date_time &&
                    moment(viewValues?.due_date_time).format('YYYY-MM-DD hh:mm A')}
                </td>
                <th>{t('Common_Data.Created')} {t('Common_Data.By')} :</th>
                <td>{viewValues?.created_by_name}</td>
              </tr>

              <tr>
                <th>{t('Common_Data.Subject')} :</th>
                <td colSpan={3}>{viewValues?.subject}</td>
              </tr>

              <tr>
                <th>{t('Common_Data.Description')} :</th>
                <td colSpan={3}>{viewValues?.description}</td>
              </tr>

              <tr>
                <th>{t('Common_Data.Full')} {t('Common_Data.Description')} :</th>
                <td colSpan={3}>{viewValues?.full_description}</td>
              </tr >
            </table >
          </div >
        </Modal >

        {notificationData?.type && notificationData.type === 'tickets' && (
          <>
            <TicketDateTimeConfirmComponent
              visible={openDateTimeModal}
              close={closeDateTimeModal}
              date={date}
              reminderConfirm={reminderConfirm}
              resetNotificationData={resetNotificationData}
            />

            <TicketHourglassConfirmComponent
              visible={openHourglassModal}
              close={closeHourglassModal}
              hourglassConfirm={hourglassConfirm}
              resetNotificationData={resetNotificationData}
            />
          </>
        )}

        {
          notificationData?.type && notificationData.type === 'tags' && (
            <>
              <TagsDateTimeConfirmComponent
                visible={openDateTimeModal}
                close={closeDateTimeModal}
                date={date}
                reminderConfirm={reminderConfirm}
                resetNotificationData={resetNotificationData}
              />

              <TagsHourglassConfirmComponent
                visible={openHourglassModal}
                close={closeHourglassModal}
                hourglassConfirm={hourglassConfirm}
                resetNotificationData={resetNotificationData}
              />
            </>
          )
        }

        {
          notificationData?.type &&
          (notificationData.type === 'leads' || notificationData.type === 'private') && (
            <>
              <LeadDateTimeConfirmComponent
                visible={openDateTimeModal}
                close={closeDateTimeModal}
                date={date}
                reminderConfirm={reminderConfirm}
                resetNotificationData={resetNotificationData}
              />

              <LeadHourglassConfirmComponent
                visible={openHourglassModal}
                close={closeHourglassModal}
                hourglassConfirm={hourglassConfirm}
                resetTime={
                  notificationData.type === 'private'
                    ? AUTH.user?.dm_settings?.[0].screen_reminder_hourglass
                    : notificationData.parent_status_id === 2
                      ? AUTH.user?.dm_settings?.[0].screen_opportunity_hourglass
                      : notificationData.parent_status_id === 3
                        ? AUTH.user?.dm_settings?.[0].screen_deals_hourglass
                        : notificationData.status_id === 4
                          ? AUTH.user?.dm_settings?.[0].screen_unused_leads_hourglass
                          : AUTH.user?.dm_settings?.[0].screen_used_leads_hourglass
                }
                resetNotificationData={resetNotificationData}
              />
            </>
          )
        }

        {
          notificationData?.type && notificationData.type === 'contacts' && (
            <>
              <PeopleDateTimeConfirmElements
                visible={openDateTimeModal}
                close={closeDateTimeModal}
                date={date}
                reminderConfirm={reminderConfirm}
                resetNotificationData={resetNotificationData}
              />

              <PeopleHourglassConfirmElements
                visible={openHourglassModal}
                close={closeHourglassModal}
                hourglassConfirm={hourglassConfirm}
                resetNotificationData={resetNotificationData}
              />
            </>
          )
        }

        {
          notificationData?.type && notificationData.type === 'organisations' && (
            <>
              <OrgDateTimeConfirmElements
                visible={openDateTimeModal}
                close={closeDateTimeModal}
                date={date}
                reminderConfirm={reminderConfirm}
                resetNotificationData={resetNotificationData}
              />

              <OrgHourglassConfirmElements
                visible={openHourglassModal}
                close={closeHourglassModal}
                hourglassConfirm={hourglassConfirm}
                resetNotificationData={resetNotificationData}
              />
            </>
          )
        }
      </>
    )
  );
});

export default ReminderNotify;
