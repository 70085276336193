import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ListComponent from '../../Tickets/component/ListComponent';
import ViewComponent from '../../Tickets/component/ViewComponent';
import DeleteComponent from '../../Tickets/component/DeleteComponent';
import RestoreComponent from './RestoreComponent';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const Leads = observer((props) => {
  const { WasteManagement } = props;
  const { AUTH, TicketsStore, WASTESTORE } = useStore();
  const [viewDrawer, setViewDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [restoreValues, setRestoreValues] = useState(false);
  const [id, setId] = useState();
  const {t} = useTranslation()
  // Open & Close Restore Model function
  const openRestoreModal = (data) => {
    setRestoreValues(data);
    setRestoreModal(true);
  };

  const closeRestoreModal = () => setRestoreModal(false);

  // Open & Close Delete Model function
  const openDeleteDrawer = (data) => {
    TicketsStore.setDeleteValues(data);
    setDeleteDrawer(true);
  };

  const closeDeleteDrawer = () => setDeleteDrawer(false);

  // Open & Close View Drawer function
  //View drawer
  const openViewDrawer = (data) => {
    if (AUTH.checkPermission(15, 'view-details')) {
      setId(data);
      TicketsStore.setViewValues(data);
      TicketsStore.apicalled = false;
      setViewDrawer(true);
    }
  };

  const closeViewDrawer = () => {
    TicketsStore.viewValues = null;
    setId(null);
    setViewDrawer(false);
  };

  return (
    <>
      <ListComponent
        WasteManagement={WasteManagement}
        openViewDrawer={openViewDrawer}
        openDeleteDrawer={openDeleteDrawer}
        openRestoreModal={openRestoreModal}
      />
      <ViewComponent
        id={id}
        setId={setId}
        visible={viewDrawer}
        close={closeViewDrawer}
        isFromWaste={true}
      />
      <DeleteComponent
        visible={deleteDrawer}
        close={closeDeleteDrawer}
        getWasteCount={WASTESTORE.getWasteCount}
        lineText={`${t('Waste.Permanently_Remove_Record')}?`}
      />
      <RestoreComponent
        visible={restoreModal}
        close={closeRestoreModal}
        getWasteCount={WASTESTORE.getWasteCount}
        store={TicketsStore}
        id={'restoreticketform'}
        currentValues={restoreValues}
        lineText={`${t('Common_Data.Ticket')}`}
        title={`${t('Common_Data.Restore')} ${t('Common_Data.Ticket')} - #${restoreValues && restoreValues.id}`}
      />
    </>
  );
});

export default Leads;
