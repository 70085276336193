import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Button } from 'antd';

import LocalGridConfig from '../../../config/LocalGridConfig';
import { vsmCommon } from '../../../config/messages';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const ListComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    TICKERTAPBROADCAST,
    AUTH,
    SETTINGS: { list_fullscreen, fullscreen_heigthwidth },
  } = useStore();

  useEffect(() => {
    TICKERTAPBROADCAST.getList();
  }, [TICKERTAPBROADCAST]);

  const ActionRenderer = (params) => {
    return (
      <div className='action-column'>
        {AUTH.checkPermission(29, 'edit') && (
          <Button
            type='text'
            title={`${t('Common_Data.Edit')}`}
            onClick={() => props.openEditDrawer(params.data)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Edit')} />
          </Button>
        )}
        {AUTH.checkPermission(29, 'delete') && (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            size='small'
            onClick={() => props.openDeleteDrawer(params.data)}
          >
            <img className='menuicon' alt='' src={AUTH.GetThemedImage('Waste')} />
          </Button>
        )}
      </div>
    );
  };

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) { return params.node.rowIndex + 1; },
        tooltipValueGetter: (params) => { return params.node.rowIndex + 1; },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('TickerTapBroadcast.Ticker')} ${t('TickerTapBroadcast.Text')}`,
        field: 'ticker_text',

      },
      {
        headerName: `${t('Common_Data.Description')}`,
        field: 'description',

        tooltipField: 'description',
        //tooltip: (params) => params.description,
      },
      {
        headerName: `${t('TickerTapBroadcast.Ticker')} ${t('Common_Data.URL')}`,
        field: 'ticker_url',

      },
      {
        headerName: `${t('Common_Data.User')} ${t('Common_Data.Groups')}`,
        field: 'user_group_name',

      },
      {
        headerName: `${t('Common_Data.Last_Updated_By')}`,
        field: 'updated_by',

      },
      {
        headerName: `${t('Common_Data.Last')} ${t('TickerTapBroadcast.Updated')} ${t('Common_Data.Date')}`,
        field: 'updated_at',

        cellRenderer: (params) => {
          return params.data.updated_at ? AUTH.getLocalTime(params.data.updated_at,AUTH.global_fulldate_format) : ' ';
        },
      },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',
        filter: 'agNumberColumnFilter',

        maxWidth: 120,
        // lockPosition: true,
        // suppressNavigable: true
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',

        maxWidth: 110,
        filter: false,
        sortable: false,
        pinned: 'right',
        lockPosition: true,
        suppressNavigable: true,
      },
    ],
  };

  let columns = [];
  let columnConfig = localStorage.getItem('ticker_tap_broadcast_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data.forEach((element) => {
      cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
      if (cols) {
        columns.push(cols);
      }
    });
  } else {
    columns = gridOptions.columnDefs;
  }

  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    item.headerTooltip = item.headerName;
    item.tooltipField = item.field;

    return item;
  })

  return (
    <div
      className='ag-theme-alpine grid_wrapper'
      style={{
        height: list_fullscreen ? fullscreen_heigthwidth : '200px',
        minHeight: list_fullscreen ? null : '200px',
      }}
    >
      <AgGridReact
        rowData={TICKERTAPBROADCAST.list_data}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={{
          ...LocalGridConfig.defaultColDef,
          floatingFilter: false,
        }}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
        }}
        onGridReady={TICKERTAPBROADCAST.setupGrid}
        onColumnResized={TICKERTAPBROADCAST.onGridChanged}
        onColumnMoved={TICKERTAPBROADCAST.onGridChanged}
        onColumnPinned={TICKERTAPBROADCAST.onGridChanged}
        onFilterChanged={TICKERTAPBROADCAST.onFilterChanged}
        onSortChanged={TICKERTAPBROADCAST.onGridChanged}
        gridOptions={{
          ...LocalGridConfig.options,
          pagination: false,
          animateRows: true,
          rowHeight: 30,
        }}
        groupUseEntireRow={true}
        rowDragManaged={true}
        groupDefaultExpanded={-1}
        //rowGroupPanelShow={'always'}
        domLayout={LocalGridConfig.domLayout}
        onCellKeyDown={props.onCellKeyDown}
        rowSelection='multiple'
        suppressRowClickSelection={true}
      />
    </div>
  );
});

export default ListComponent;
