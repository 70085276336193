import React, { useState, useCallback, useEffect } from 'react';
import { Drawer, Form, Spin, Button } from 'antd';
import { observer } from 'mobx-react';

import FormComponent from './FormComponent';
import { useHistory } from 'react-router-dom';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages';
import { convertError } from '../../../../utils/GlobalFunction';

const CloneComponent = observer((props) => {
  const [tip, setTip] = useState(null);
  const [isReportLoading, setisReportLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [saveFilterList, setsaveFilterList] = useState([]);
  const [submitSaving, setSubmitSaving] = useState(false);
  const [savePreviewSaving, setsavePreviewSaving] = useState(false);

  /**--------CLONE REPORT INITIALIZATION STATES----------------**/
  const [isInitiated, setInitiation] = useState(false);
  const [formDataList, setFormDataList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  /**--------CLONE REPORT INITIALIZATION STATES----------------**/

  const [countsListReport, setCountsListReport] = useState(false);

  const { t } = useTranslation();
  const {
    AUTH,
    REPORTSTORE,
    ORGANISATION,
    PEOPLE,
    LEADSSTORE,
    REPORTSTORE: { selectedReportFieldsKeys, setSelectedReportFieldsKeys },
    ORGSTATUSSTORE,
  } = useStore();

  const { visible } = props;
  const history = useHistory();
  const defaultFormDataList = ['selection_ui', 'report_type'];
  const [form] = Form.useForm();

  /*------------------------START:HANDLE SUBMIT AND PREVIEW FUNCTIONS-------------------*/
  const handleSubmit = (data, isPreview = false) => {
    data.form_list = formDataList;
    if (data.global_toggle !== undefined) {
      data.global_toggle = !data.global_toggle;
    } else if (data.global_toggle === undefined) {
      data.global_toggle = true;
    }
    data.check_unique_code = true;
    data.grid_columns = selectedReportFieldsKeys;

    if (isPreview) {
      setsavePreviewSaving(true);
    } else {
      setSubmitSaving(true);
    }
    setTip('Saving');

    let users = data.users
    let owners = data.owners
    let holders = data.holders
    let user_groups = data.user_groups
    if (data.report_by === "all") {
      data.users = null;
      data.owners = null;
      data.holders = null;
      data.user_groups = null;
    } else if (data.report_by === "users") {
      data.users = users;
      data.owners = null;
      data.holders = null;
      data.user_groups = null;
    } else if (data.report_by === "owners") {
      data.users = null;
      data.owners = owners;
      data.holders = null;
      data.user_groups = null;
    } else if (data.report_by === "holders") {
      data.users = null;
      data.owners = null;
      data.holders = holders;
      data.user_groups = null;
    } else if (data.report_by === "user_groups") {
      data.users = null;
      data.owners = null;
      data.holders = null;
      data.user_groups = user_groups;
    }

    REPORTSTORE.AddData(data)
      .then((res) => {
        vsmNotify.success({
          message: vsmTransactionReport.save,
        });

        if (res.report) {
          REPORTSTORE.created_by_id = res.report.created_by;
          REPORTSTORE.previewReportValues = {
            ...JSON.parse(res.report.generated_on_options),
            id: res.report.id,
            name: res.report.name,
            code: res.report.code,
            description: res.report.description,
          };
          if (REPORTSTORE.previewReportValues?.global_toggle !== undefined) {
            REPORTSTORE.previewReportValues.global_toggle =
              !REPORTSTORE.previewReportValues.global_toggle;
          } else {
            REPORTSTORE.previewReportValues.global_toggle = false;
          }
        }
        if (isPreview) {
          props.openPreviewDataModal(res.report.id, true);
        } else {
          props.close();
        }
        if (REPORTSTORE.my_records === 'my') {
          REPORTSTORE.getList({}, AUTH.user.id);
        } else {
          REPORTSTORE.getList();
        }
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(convertError(e.errors));
        }
      })
      .finally(() => {
        setSubmitSaving(false);
        setsavePreviewSaving(false);
        setTip(null);
      });
  };

  const openPreviewReport = () => {
    let data;
    REPORTSTORE.setPreviewReportValues({
      ...REPORTSTORE.previewReportValues,
      ...form.getFieldsValue(),
      form_list: formDataList,
    });
    data = REPORTSTORE.formatFormData(REPORTSTORE.previewReportValues, true, true);
    data.check_unique_code = true;
    data.grid_columns = selectedReportFieldsKeys;
    data.action = 'preview-data';
    setTip('Loading');
    REPORTSTORE.PreviewData(data)
      .then((data) => {
        props.openPreviewDataModal(undefined, false, false, true);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(convertError(e.errors));
        }
      })
      .finally(() => {
        setTip(null);
      });
  };

  const savePreviewReport = (data = form.getFieldsValue()) => {
    data.form_list = formDataList;
    data = REPORTSTORE.formatFormData({
      ...REPORTSTORE.previewReportValues,
      ...data,
    });
    data.action = 'save_report';
    data.selectedReportFieldsKeys = selectedReportFieldsKeys;
    handleSubmit(data, true);
  };
  /*------------------------END:HANDLE SUBMIT AND PREVIEW FUNCTIONS-------------------*/

  const checkInterMediateCheckbox = useCallback(() => {
    let commonFields = ['sum_call_clicks', 'sum_email_clicks', 'sum_total_time_spent', 'sum_modified'];
    // --------  Add Fields to check for All Checkbox -------- //
    if (['all', 'sold'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'sold_created',
        'sold_assigned',
        'sold_sum_total',
        'sum_sss_created',
        'sum_results',
        'sum_inbound_leads',
        'sum_outbound_leads',
        'sum_finance_value',
        'sum_by_history_category',
        'sum_by_stages',
      ];
    }

    if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'tag_created',
        'tag_assigned',
        'tag_sum_total',
        'sum_by_tag_status',
      ];
    }

    if (['all', 'tickets'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'ticket_created',
        'ticket_sum_total',
        'ticket_assigned',
        'sum_by_ticket_status',
      ];
    }

    if (['all', 'reminders'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'reminder_created',
        'reminder_assigned',
        'reminder_sum_total',
        'sum_by_reminder_status',
      ];
    }

    if (['all', 'tags'].includes(form.getFieldValue('report_on'))) {
      commonFields = [
        ...commonFields,
        'tag_created',
        'tag_sum_total',
        'tag_assigned',
        'sum_by_tag_status',
      ];
    }

    if (['all', 'organisation'].includes(form.getFieldValue('report_on'))) {
      commonFields = [...commonFields, 'organisation_created'];
    }

    if (['all', 'people'].includes(form.getFieldValue('report_on'))) {
      commonFields = [...commonFields, 'people_created'];
    }
    // --------  Add Fields to check for All Checkbox -------- //
    let count = 0;
    let ispartialChecked = false;
    commonFields.map((obj) => {
      if (form.getFieldValue([obj])) {
        count++;
      }
      return null;
    });
    if (count === commonFields.length) {
      ispartialChecked = false;
      form.setFieldsValue({
        select_all_columns: true,
      });
      setCheckAll(true);
    } else if (count > 0) {
      ispartialChecked = true;
      form.setFieldsValue({
        select_all_columns: false,
      });
      setCheckAll(false);
    } else {
      ispartialChecked = false;
      form.setFieldsValue({
        select_all_columns: false,
      });
      setCheckAll(false);
    }
    setIndeterminate(ispartialChecked);
  }, [form]);

  /*------------------------START:INITIALIZATION FUNCTIONS-------------------*/
  const InitaitePreviewReport = useCallback(async () => {
    await setFormDataList(REPORTSTORE.previewReportValues.form_list);
    var previewReportValues = REPORTSTORE.formatFormData(
      REPORTSTORE.previewReportValues,
      false
    );

    if (previewReportValues['nace_category']) {
      ORGANISATION.getNaceCode();
    }
    if (previewReportValues['people_status']) {
      PEOPLE.getPeopleStatus();
    }

    if (previewReportValues['organisation']) {
      ORGANISATION.getAllROrg({
        selected_id: previewReportValues['organisation'],
        mapping_type: 1,
      });
    }
    if (previewReportValues['organisation_name']) {
      ORGANISATION.getAllROrgNameListForReport({
        selected_id: previewReportValues['organisation_name'],
        mapping_type: 1,
      });
    }
    if (previewReportValues['people']) {
      PEOPLE.getAllPeople({
        selected_id: previewReportValues['people'],
        mapping_type: 2,
      });
    }
    if (previewReportValues['people_name']) {
      PEOPLE.getAllPeopleNameDropdown({
        selected_id: previewReportValues['people_name'],
        mapping_type: 2,
      });
    }
    if (previewReportValues['lead_currency_id'] && !LEADSSTORE.dropdown_currency_list) {
      LEADSSTORE.getCurrencyList();
    }
    if (previewReportValues['organisation_type']) {
      ORGANISATION.getOrgRoles();
    }
    if (previewReportValues['people_type']) {
      PEOPLE.getPeopleRoles();
    }
    if (previewReportValues['classification_id']) {
      REPORTSTORE.getClassificationList();
    }
    if (
      previewReportValues &&
      (previewReportValues['users'] ||
        previewReportValues['owners'] ||
        previewReportValues['holders'])
    ) {
      let formdata = {
        inactive: true,
      };
      REPORTSTORE.getReportUsersList(formdata);
    }

    if (previewReportValues['parent_organisation']) {
      ORGANISATION.getMappedOrg();
    }
    if (previewReportValues['organisation_status']) {
      ORGSTATUSSTORE.getOrgStatus();
    }

    previewReportValues.selection_ui = 'radiobtn';
    await form.setFieldsValue(previewReportValues);
    await checkInterMediateCheckbox();
  }, [REPORTSTORE, LEADSSTORE, ORGANISATION, PEOPLE, form, ORGSTATUSSTORE, checkInterMediateCheckbox]);



  useEffect(() => {
    if (!isInitiated && props.report_id && props.visible) {
      setInitiation(true);
      setisReportLoading(true);
      REPORTSTORE.ReadReport(props.report_id, true)
        .then((data) => {
          InitaitePreviewReport();
          setTip(null);
        })
        .catch(({ response }) => { })
        .finally(() => {
          setisReportLoading(false);
        });
    }
  }, [
    InitaitePreviewReport,
    REPORTSTORE,
    checkInterMediateCheckbox,
    isInitiated,
    props.report_id,
    props.visible,
    history,
  ]);
  /*------------------------END: INITIALIZATION FUNCTIONS-------------------*/

  useEffect(() => {
    REPORTSTORE.previewReportValues = null;
  }, [REPORTSTORE]);

  const close = () => {
    props.close();
    props.setReportID(null);
    REPORTSTORE.previewReportValues = null;
    setInitiation(false);
    setisReportLoading(false);
    setsavePreviewSaving(false);
    setIndeterminate(false);
    setCheckAll(false);
    setTip('Loading');
    setSelectedReportFieldsKeys([]);
  };

  return (
    <Drawer
      visible={visible}
      onClose={close}
      placement='right'
      width={"1100px"}
      title={`${t('Common_Data.Clone')} ${t('Reports.Report')}`}
      destroyOnClose={true}
      footer={[
        <div className='text-right' key='1'>
          {
            <Button
              key='1'
              className='mr-10'
              htmlType='button'
              shape='round'
              disabled={isDisabled}
              onClick={() => {
                openPreviewReport();
              }}
            >
              {t('Reports.Preview')} {t('Reports.Data')}
            </Button>
          }
          {AUTH.checkPermission(21, 'add') && (
            <Button
              key='2'
              form='cloneformReport'
              className='mr-10'
              htmlType='submit'
              shape='round'
              type='primary'
              disabled={isDisabled}
              loading={submitSaving}
            >
              {t('Common_Data.Save')}
            </Button>
          )}
          {AUTH.checkPermission(21, 'add') && (
            <Button
              key='3'
              className='mr-10'
              shape='round'
              disabled={isDisabled}
              onClick={() => {
                savePreviewReport();
              }}
              loading={savePreviewSaving}
            >
              {t('Common_Data.Save')} & {t('Reports.Preview')}
            </Button>
          )}
          <Button shape='round' onClick={close} key='4'>
            {t('Common_Data.Cancel')}
          </Button>
        </div>,
      ]}
    >
      <Spin
        size='large'
        spinning={isReportLoading}
        className='fullHeight w1000'
        tip={tip}
        footer={[
          <div className='text-right' key='1'>
            {
              <Button
                key='1'
                className='mr-10'
                htmlType='button'
                shape='round'
                disabled={isDisabled}
                onClick={() => {
                  openPreviewReport();
                }}
              >
                {t('Reports.Preview')} {t('Reports.Data')}
              </Button>
            }
            {AUTH.checkPermission(21, 'add') && (
              <Button
                key='2'
                form='cloneformReport'
                className='mr-10'
                htmlType='submit'
                shape='round'
                type='primary'
                disabled={isDisabled}
                loading={submitSaving}
              >
                {t('Common_Data.Save')}
              </Button>
            )}
            {AUTH.checkPermission(21, 'add') && (
              <Button
                key='3'
                className='mr-10'
                shape='round'
                disabled={isDisabled}
                onClick={() => {
                  savePreviewReport();
                }}
                loading={savePreviewSaving}
              >
                {t('Common_Data.Save')} & {t('Reports.Preview')}
              </Button>
            )}
            <Button shape='round' onClick={close} key='4'>
              {t('Common_Data.Cancel')}
            </Button>
          </div>,
        ]}
      >
        {isReportLoading ? null : (
          <FormComponent
            defaultFormDataList={defaultFormDataList}
            form={form}
            formDataList={formDataList}
            setFormDataList={setFormDataList}
            handleSubmit={handleSubmit}
            initialValues={{
              ...REPORTSTORE.initialSummaryValues,
              sort_by: 'modified_at',
            }}
            setDisabled={setDisabled}
            saveFilterList={saveFilterList}
            setsaveFilterList={setsaveFilterList}
            id='cloneformReport'
            isClone={true}
            checkAll={checkAll}
            setCheckAll={setCheckAll}
            indeterminate={indeterminate}
            setIndeterminate={setIndeterminate}
            countsListReport={countsListReport}
            setCountsListReport={setCountsListReport}
          />
        )}
      </Spin>
    </Drawer>
  );
});
export default CloneComponent;
