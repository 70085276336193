import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, PageHeader } from 'antd';
import ScheduleLibraryView from './ScheduleLibraryView';
import useStore from '../../../../../store';
import AddComponent from './AddComponent';
import EditComponent from './EditComponent';
import {
  calculateHeight,
  trimExtraCharacters,
} from '../../../../../utils/GlobalFunction';
import debounce from 'lodash/debounce';
import DeleteComponent from './DeleteComponent';
import ScheduleLibraryList from './ScheduleLibraryList';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ResetGridOptions from '../../../../../component/ResetGridOptions';
import TimeTracker from '../../../../../component/TimeTracker';

const ViewListing = observer((props) => {
  const { t } = useTranslation();
  const INITIAL_HEIGHT = '18px';
  const { DealLibrary } = props;
  const [scheduleDataLIB, setScheduleDataLIB] = useState(null);
  const {
    AUTH,
    DEALDESKSTORE: { deal_data },
    // SSSLEADLIBRARYSTORE,
    SSSLEADLIBRARYSTORE: { schedule_library_data, fetchListData, fetchLibraryByType },
    DEALDESKSCHEDULELIBRARY: {
      savescheduleform,
      setScheduleData,
      setCopiedScheduleData,
      SetDate,
      SetDateType,
      setEditValues,
      calculateHeightOnEdit,
      ScheduleData,
    },
  } = useStore();

  const [addDrawer, setAddDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [id, setID] = useState(null);
  const [libData, setlibData] = useState(null);
  const [openScheduleLibraryDrawer, setOpenScheduleLibraryDrawer] = useState(false);
  const [selectedLibraryItemNode, setSelectedLibraryItemNode] = useState([]);
  // const [agGrid, setAgGrid] = useState({});


  //open add drawer
  const openAddDrawer = () => setAddDrawer(true);

  //close add drawer
  const closeAddDrawer = () => setAddDrawer(false);

  //open edit drawer
  const openEditDrawer = (data) => {
    data.description &&
      data.description.forEach((element) => {
        delete element.start_date;
      });
    setEditValues(data);
    setEditDrawer(true);
  };
  //close edit drawer
  const closeEditDrawer = () => setEditDrawer(false);

  //open add drawer
  const openDeleteDrawer = (data) => {
    setDeleteDrawer(true);
    setlibData(data);
    setID(data.id);
  };

  //close add drawer
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  const viewScheduleLibraryDrawer = (data) => {
    setScheduleDataLIB(data);
    setOpenScheduleLibraryDrawer(true);
  };

  const closeScheduleLibraryDrawer = () => {
    setScheduleDataLIB(null);
    setOpenScheduleLibraryDrawer(false);
  };

  const tempcopySchedule = (data, isCheckboxSelection = false) => {
    if (savescheduleform) {
      if (isCheckboxSelection) {
        let newDescriptionTemp = [];

        let parentKey = 1;
        let Position = 1;

        parentKey =
          ScheduleData && ScheduleData.length > 0
            ? parseInt(ScheduleData[ScheduleData.length - 1]['item_key']) + 1
            : 1;
        Position =
          ScheduleData && ScheduleData.length > 0
            ? parseInt(ScheduleData[ScheduleData.length - 1]['position']) + 1
            : 1;

        //-------------------------START: REARRANGE ITEM KEYS FOR SELECTED NODES------------------//
        if (data.description) {
          let parentKeyIterated = 0;

          data.description.forEach((element, index) => {
            let parentSelection = false;

            if (selectedLibraryItemNode && selectedLibraryItemNode.length > 0) {
              if (selectedLibraryItemNode.includes(element.item_key)) {
                element.position = Position;
                if (parentKeyIterated) {
                  element.newItemKey = parentKey + 1;
                  parentKey = element.newItemKey;
                } else {
                  // element.newItemKey = 1;
                  // parentKey = 1;
                  element.newItemKey = parentKey;
                  parentKeyIterated = 1;
                }

                Position = Position + 1;
                parentSelection = true;
                newDescriptionTemp.push(element);
              }

              let childKey = 0;
              let childPosition = 1;
              element.children &&
                element.children.length > 0 &&
                element.children.forEach((element1, indexChild) => {
                  let childSelection = false;

                  if (selectedLibraryItemNode.includes(element1.item_key)) {
                    if (parentSelection) {
                      element1.newItemKey = parentKey + '-' + childKey;
                      element1.position = childPosition;
                      childPosition = childPosition + 1;
                    } else {
                      if (parentKeyIterated) {
                        element1.newItemKey = parentKey + 1;
                      } else {
                        element1.newItemKey = parentKey;
                      }
                      element1.position = Position;

                      Position = Position + 1;
                      parentKey = parentKey + 1;
                    }

                    childKey = childKey + 1;
                    childSelection = true;
                    newDescriptionTemp.push(element1);
                  }

                  let subchildKey = 0;

                  element1.children &&
                    element1.children.length > 0 &&
                    element1.children.forEach((element2, indexSubChild) => {
                      if (selectedLibraryItemNode.includes(element2.item_key)) {
                        if (parentSelection) {
                          if (childSelection) {
                            element2.newItemKey = element1.newItemKey + '-' + subchildKey;
                            element2.position = subchildKey + 1;
                          } else {
                            element2.newItemKey = parentKey + '-' + childKey;
                            element2.position = subchildKey + 1;
                            childKey = childKey + 1;
                          }
                        } else {
                          if (childSelection) {
                            element2.newItemKey =
                              element1.newItemKey + '-' + subchildKey + 1;
                            element2.position = subchildKey + 1;
                          } else {
                            if (parentKeyIterated) {
                              element2.newItemKey = parentKey + 1;
                            } else {
                              element2.newItemKey = parentKey;
                            }
                            element2.position = Position;

                            parentKey = parentKey + 1;
                            Position = Position + 1;
                          }
                        }
                        subchildKey = subchildKey + 1;

                        newDescriptionTemp.push(element2);
                      }
                    });
                });
            }
          });
        }

        let newFormattedArr = [];
        if (newDescriptionTemp && newDescriptionTemp.length > 0) {
          newDescriptionTemp.forEach((x, index) => {
            if (typeof x.newItemKey === 'string') {
              let itemKeyArr = x.newItemKey.split('-');
              if (itemKeyArr.length === 2) {
                if (newFormattedArr && newFormattedArr.length > 0) {
                  newFormattedArr.forEach((e) => {
                    if (parseInt(e.item_key) === parseInt(itemKeyArr[0])) {
                      x.item_key = x.newItemKey;
                      x.children = [];
                      delete x.newItemKey;
                      e.children.push(x);
                    }
                  });
                }
              }
              if (itemKeyArr.length === 3) {
                if (newFormattedArr && newFormattedArr.length > 0) {
                  newFormattedArr.forEach((e) => {
                    if (parseInt(e.item_key) === parseInt(itemKeyArr[0])) {
                      if (e.children && e.children.length) {
                        e.children.forEach((e1) => {
                          if (e1.item_key === itemKeyArr[0] + '-' + itemKeyArr[1]) {
                            x.item_key = x.newItemKey;
                            x.children = [];
                            delete x.newItemKey;
                            e1.children.push(x);
                          }
                        });
                      }
                    }
                  });
                }
              }
            } else {
              x.item_key = x.newItemKey;
              x.children = [];
              delete x.newItemKey;
              newFormattedArr.push(x);
            }
          });
        }


        data.description = ScheduleData.concat(newFormattedArr);
        setSelectedLibraryItemNode([]);
      }

      data.description &&
        data.description.forEach((element) => {
          delete element.start_date;
          if (element.children && element.children.length > 0) {
            element.children.forEach((obj1, index) => {
              delete obj1.start_date;
              if (obj1.children && obj1.children.length > 0) {
                obj1.children.forEach((obj2, index) => {
                  delete obj2.start_date;
                });
              }
            });
          }
        });

      setScheduleData(data.description);
      let lengthLimit = 170;
      if (AUTH?.user?.bonus_figures) {
        if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
          lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
        }
      }

      let schedule_data = data.description || [];
      calculateHeightOnEdit(data.description).then(
        debounce(() => {
          schedule_data &&
            schedule_data.forEach((obj, index) => {
              obj.detail = trimExtraCharacters(lengthLimit, obj.detail);

              obj.height = calculateHeight(
                `schedule_innerContent_textarea_${obj.item_key}`,
                INITIAL_HEIGHT,
                obj.detail,
                true
              );

              if (obj.children && obj.children.length > 0) {
                obj.children.forEach((obj1, index) => {
                  obj1.detail = trimExtraCharacters(lengthLimit, obj1.detail);

                  obj1.height = calculateHeight(
                    `schedule_innerContent_textarea_${obj1.item_key}`,
                    INITIAL_HEIGHT,
                    obj1.detail,
                    true
                  );
                  if (obj1.children && obj1.children.length > 0) {
                    obj1.children.forEach((obj2, index) => {
                      obj2.detail = trimExtraCharacters(lengthLimit, obj2.detail);
                      obj2.height = calculateHeight(
                        `schedule_innerContent_textarea_${obj2.item_key}`,
                        INITIAL_HEIGHT,
                        obj2.detail,
                        true
                      );
                    });
                  }
                });
              }
            });
          // savescheduleform.setFieldsValue({
          //   title: data?.title,
          //   description_text: data?.description_text,
          // });
        }, 200)
      );
      setScheduleData(schedule_data);
      //--------------------------------------------------------------------------------------------------------//

      setCopiedScheduleData(data);
      SetDateType(false);
    }
    if (isCheckboxSelection) {
      setScheduleDataLIB(null);
      closeScheduleLibraryDrawer();
      props.close();
    } else {
      SetDate(new Date(data.date));
      localStorage.removeItem('last_focusedElementSchedule');
      props.close(data.is_start_date);
    }
  };

  useEffect(() => {
    if (
      props.visible
      //&& !schedule_library_data
    ) {
      fetchListData(0, false, { type: 2 }); // Only scheudle library fetch payload in third param
    }
  }, [
    props.visible,
    //schedule_library_data,
    fetchListData,
    deal_data,
  ]);

  // setAgGrid(SSSLEADLIBRARYSTORE.agGridRefSchedule?.current)
  // useEffect(() => {
  // }, [SSSLEADLIBRARYSTORE])


  const refresh = () => {
    fetchLibraryByType("schedule")
  }
  // if(!LEADSSTORE.classification_role_list) {
  //   LEADSSTORE.getClassificationList();
  // }

  return (
    <>
      <Drawer
        title={`${t('DealDesk.Schedule_Library')}`}
        visible={props.visible}
        onClose={() => props.close()}
        placement='right'
        width={'1050px'}
        destroyOnClose={true}
      >
        <ScheduleLibraryList
          viewScheduleLibraryDrawer={viewScheduleLibraryDrawer}
          tempcopySchedule={tempcopySchedule}
          openDeleteDrawer={openDeleteDrawer}
          DealLibrary={false}
          libraryData={schedule_library_data}
        />

        <ScheduleLibraryView
          visibleScheduleLibraryDrawer={openScheduleLibraryDrawer}
          closeScheduleLibraryDrawer={closeScheduleLibraryDrawer}
          scheduleData={scheduleDataLIB}
          isCheckBoxSelection={true}
          tempcopySchedule={tempcopySchedule}
          selectedLibraryItemNode={selectedLibraryItemNode}
          setSelectedLibraryItemNode={setSelectedLibraryItemNode}
          test={'2'}
        />
      </Drawer>
      {DealLibrary && (
        <PageHeader
          title=''
          className={'peopleOrg'}
          extra={[
            <Button shape='round' size='medium' key='1' onClick={openAddDrawer}>
              {t('DealDesk.Add_Schedule')}
            </Button>,
            // <Button
            //   key='2'
            //   title={`${t('Common_Data.Reset')}`}
            //   shape='round'
            //   size='medium'
            //   onClick={handleResetSchedule}
            // >
            //   <FontAwesomeIcon icon={faUndo} />
            // </Button>,
            <ResetGridOptions key={'2'} pageType={"schedule"} agGrid={""} list_grid={"schedule_library_grid"} refresh={refresh} />
          ]}
        >
          <ScheduleLibraryList
            viewScheduleLibraryDrawer={viewScheduleLibraryDrawer}
            openEditDrawer={openEditDrawer}
            openDeleteDrawer={openDeleteDrawer}
            DealLibrary={true}
            libraryData={schedule_library_data}
          />

          {openScheduleLibraryDrawer && (
            <ScheduleLibraryView
              visibleScheduleLibraryDrawer={openScheduleLibraryDrawer}
              closeScheduleLibraryDrawer={closeScheduleLibraryDrawer}
              scheduleData={scheduleDataLIB}
              test={'3'}
            />
          )}

          <AddComponent visible={addDrawer} close={closeAddDrawer} />
          <EditComponent visible={editDrawer} close={closeEditDrawer} />
          <DeleteComponent
            visible={deleteDrawer}
            id={id}
            close={closeDeleteDrawer}
            libData={libData}
          />
        </PageHeader>
      )}
      <TimeTracker
        stop={!props.visible}
        page={"library"} />
    </>
  );
});
export default ViewListing;
