import React, { useState } from 'react';
import { Typography, Form, Input, Button, Popover } from 'antd';
import useStore from '../store';
import { vsmAuth, vsmNotify } from '../config/messages';
import { useHistory } from 'react-router-dom';
import FinalLOGO from '../include/images/dealmaker-logo-250.png';
import Pass_Icon from '../assets/images/gray/Lock_Note.svg';
import InputComponent from '../component/InputComponent';
import { useTranslation } from 'react-i18next';


const ResetPassword = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {
    AUTH: { doForgotPassword },
  } = useStore();
  const {t} = useTranslation()
  const [tooltip, setTooltip] = useState(false);
  let history = useHistory();
  
  // make function call to reset password
  const resetPassword = (data) => {
    setLoading(true);

    doForgotPassword(data)
      .then((res) => {
        if (res) {
          history.push('/dashboard');
          vsmNotify.success({
            message: vsmAuth.reset_success,
          });
        }
      })
      .catch((errors) => {
        form.setFields(errors.errors);
        setLoading(false);
      });
  };

  // // password criteria tool tip
  const passwordTooltip = (
    <div>
      <div>{t('Login.At_Least')} 1 {t('Login.Numeric')} {t('Login.Character')}</div>
      <div>{t('Login.At_Least')} 1 {t('Login.Special')} {t('Login.Character')}</div>
      <div>{t('Login.At_Least')} 1 {t('Login.Uppercase_Letter')}</div>
      <div>{t('Login.At_Least')} 8 {t('Login.Character')}</div>
    </div>
  );

  // handle password tool tip visiblility
  const handleChangePassword = (e) => {
    form
      .validateFields(['password'])
      .then(() => {
        setTooltip(false);
      })
      .catch(() => {
        setTooltip(true);
      });
  };

  return (
    <div className='login__page__wrapper'>
      <img src={FinalLOGO} className='LoginLogo' alt={`${t('Common_Data.Deal')} ${t('Login.Maker')}`} title={`${t('Common_Data.Deal')} ${t('Login.Maker')}`} />
      <div className='login__page__form'>
        <Typography.Title level={2} className='w-100 title'>
          {t('Common_Data.Reset')} {t('Common_Data.Password')}
        </Typography.Title>
        <Form form={form} onFinish={resetPassword}>
          <Form.Item
            name='Key'
            initialValue={props.match.params.id}
            style={{ display: 'none' }}
          >
            <Input type='hidden' tooltip={tooltip} />
          </Form.Item>
          <Popover placement='topRight' content={passwordTooltip} visible={tooltip}>
            <InputComponent
              hasFeedback
              type='password'
              name='password'
              placeholder={`${t('Common_Data.Password')}`}
              prefix={<img src={Pass_Icon} alt={`${t('Common_Data.Email')}`} />}
              onBlur={() => setTooltip(false)}
              onChange={handleChangePassword}
              onFocus={handleChangePassword}
              rules={vsmAuth.validation.password}
            />
          </Popover>
          <InputComponent
            hasFeedback
            type='password'
            name='confirm_password'
            placeholder={`${t('Common_Data.Confirm')} ${t('Common_Data.Password')}`}
            prefix={<img src={Pass_Icon} alt={`${t('Common_Data.Email')}`} />}
            onBlur={() => setTooltip(false)}
            onChange={handleChangePassword}
            onFocus={handleChangePassword}
            rules={vsmAuth.validation.confirmpassword}
          />
          <div className='text-center'>
            <Button
              loading={loading}
              htmlType='submit'
              block
              type='primary'
              className='login'
            >
              {t('Common_Data.Reset')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
