import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const GoToDealDeskConfirmElements = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  let history = useHistory();
  const close = () => {
    props.close();
    form.resetFields();
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    props.close();

    history.push(`/dealdesk/${props.leadID}`);
    form.resetFields();
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.GO')} ${t('Common_Data.To')} ${t('Block_Edit_Assign.Deal_Desk')}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      //onOk={close}
      footer={[
        <Button
          key='1'
          form='deleteformGOTODealDeskConfirm'
          htmlType='submit'
          type='primary'
          shape='round'
          onClick={handleSubmit}
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteformGOTODealDeskConfirm' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('LeadListing.Go_To_Deal_Desk_Page')}{' '}
              {props.leadID}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  );
});

export default GoToDealDeskConfirmElements;
