import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { Checkbox, Col, Divider, Form, Row, Spin } from 'antd';
import SoldStageSelectionElement from './SoldStageSelectionElement';
import InputComponent from '../../../../component/InputComponent';
import { useTranslation } from 'react-i18next';
import { holderModifiedValues } from '../../../../utils/GlobalFunction';

const SummarySOLDElement = observer(
  ({ onChange, formDataList, form, handleStagesSelection, countsListReport }) => {
    const [fetchNotesCategories, setFetchNotesCategories] = useState(true);
    const { t } = useTranslation();
    const { DEALDESKSTORE: { fetchColorList, historyCategoryList }, } = useStore();

    // --------  UseEffect added for History Category API Call ------ //
    useEffect(() => {
      if (form.getFieldValue('history_categories') && fetchNotesCategories) {
        fetchColorList()
          .then(() => { })
          .catch(() => { })
          .finally(() => {
            setFetchNotesCategories(false);
          });
      }
    }, [form, fetchNotesCategories, fetchColorList]);

    // --------  UseEffect added for History Category API Call ------ //

    return (
      <>
        <Row gutter={12} className='mb-5' style={{ alignItems: 'baseline' }}>
          <Col span={24}>
            <Divider orientation='left' orientationMargin='0' plain>
              {t('Common_Data.Sold')}
            </Divider>
          </Col>
          <Col span={24}>
            <Row gutter={12} align='middle'>
              {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('sold_sum_total') && (
                <Col span={4}>
                  <Form.Item name='sold_sum_total' valuePropName='checked'>
                    <Checkbox onChange={onChange}>{t('Reports.Sum_Total')}</Checkbox>
                  </Form.Item>
                </Col>
              )}
              {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('sold_created') &&
                !form?.getFieldValue('display_stages_as_row') &&
                !form?.getFieldValue('sold_by_country') && (
                  <Col span={4}>
                    <Form.Item name='sold_created' valuePropName='checked'>
                      <Checkbox onChange={onChange}>{t('Reports.Sum_Created')}</Checkbox>
                    </Form.Item>
                  </Col>
                )}
              {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('sold_assigned') &&
                !form?.getFieldValue('display_stages_as_row') &&
                !form?.getFieldValue('sold_by_country') && (
                  <Col span={4}>
                    <Form.Item name='sold_assigned' valuePropName='checked'>
                      <Checkbox onChange={onChange}>{t('Reports.Sum_Assigned')}</Checkbox>
                    </Form.Item>
                  </Col>
                )}
              {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('sum_sss_created') &&
                !form?.getFieldValue('display_stages_as_row') &&
                !form?.getFieldValue('sold_by_country') && (
                  <Col span={4}>
                    <Form.Item name='sum_sss_created' valuePropName='checked'>
                      <Checkbox onChange={onChange}>
                        {t('Reports.Sum_SSS_Created')}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}
              {(form?.getFieldValue(`report_type`) !== 'count_list') && form?.getFieldValue('sold_by_country') &&
                !form?.getFieldValue('display_stages_as_row') && (
                  <Col span={4}>
                    <Form.Item name='sum_active' valuePropName='checked'>
                      <Checkbox onChange={onChange}>{t('Reports.Sum_Active')}</Checkbox>
                    </Form.Item>
                  </Col>
                )}
              {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('sum_finance_value') && (
                <Col span={4}>
                  <Form.Item name='sum_finance_value' valuePropName='checked'>
                    <Checkbox onChange={onChange}>
                      {t('Reports.Sum_Finance_Value')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Row gutter={12} align='middle'>
                  {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('sum_results') &&
                    !form?.getFieldValue('display_stages_as_row') &&
                    !form?.getFieldValue('sold_by_country') && (
                      <Col span={4}>
                        <Form.Item name='sum_results' valuePropName='checked'>
                          <Checkbox onChange={onChange}>
                            {t('Reports.Sum_Results')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}
                  {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('sum_inbound_leads') &&
                    !form?.getFieldValue('display_stages_as_row') &&
                    !form?.getFieldValue('sold_by_country') && (
                      <Col span={4}>
                        <Form.Item name='sum_inbound_leads' valuePropName='checked'>
                          <Checkbox onChange={onChange}>
                            {t('Reports.Sum_Inbound_Leads')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}
                  {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('sum_outbound_leads') &&
                    !form?.getFieldValue('display_stages_as_row') &&
                    !form?.getFieldValue('sold_by_country') && (
                      <Col span={4}>
                        <Form.Item name='sum_outbound_leads' valuePropName='checked'>
                          <Checkbox onChange={onChange}>
                            {t('Reports.Sum_Outbound_Leads')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}


                  {((form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('sum_by_history_category')) &&
                    !form?.getFieldValue('display_stages_as_row') &&
                    !form?.getFieldValue('sold_by_country') && (
                      <Col span={4}>
                        <Form.Item name='sum_by_history_category' valuePropName='checked'>
                          <Checkbox onChange={onChange}>
                            {t('Reports.Sum_by_History_Category')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}

                  {((form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('history_categories')) &&
                    !form?.getFieldValue('display_stages_as_row') &&
                    !form?.getFieldValue('sold_by_country') && (
                      <Col span={4}>
                        <InputComponent
                          name='history_categories'
                          placeholder={t('Reports.History_Categories')}
                          type='select'
                          mode='multiple'
                          onChange={onChange}
                          allowClear
                          showArrow
                          showSearch
                          tooltip=''
                          onFocus={() =>
                            fetchNotesCategories &&
                            fetchColorList().then(() => setFetchNotesCategories(false))
                          }
                          notFoundContent={
                            fetchNotesCategories ? (
                              <Spin size='small' />
                            ) : (
                              `${t('Common_Data.No_Record_Found')}.`
                            )
                          }
                          options={{
                            values: historyCategoryList,
                            value_key: 'hex_code',
                            text_key: 'color_name',
                          }}
                        />
                      </Col>
                    )}
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={12} align='middle'>
                  {formDataList.includes('sum_by_stages') &&
                    !form?.getFieldValue('sold_by_country') && (
                      <Col span={4}>
                        <Form.Item name='sum_by_stages' valuePropName='checked'>
                          <Checkbox
                            onChange={(e) => {
                              handleStagesSelection(e);
                              onChange();
                            }}
                          >
                            {t('Reports.Sum_by_Stages')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}
                  {formDataList.includes('count_for_holders_only') &&
                    form?.getFieldValue('sum_by_stages') &&
                    (<Col span={5}>
                      <InputComponent
                        type='radio_button'
                        name='count_for_holders_only'
                        options={{
                          values: holderModifiedValues,
                        }}
                        onChange={onChange}
                      />
                    </Col>)
                  }
                  {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('include_bonus_figures') &&
                    !form?.getFieldValue('display_stages_as_row') &&
                    !form?.getFieldValue('sold_by_country') && (
                      <Col span={6}>
                        <Form.Item name='include_bonus_figures' valuePropName='checked'>
                          <Checkbox onChange={onChange}>
                            {t('Reports.Include_Bonus_Figures')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}
                  {(form?.getFieldValue(`report_type`) !== 'count_list') && formDataList.includes('include_budget') &&
                    !form?.getFieldValue('display_stages_as_row') &&
                    !form?.getFieldValue('sold_by_country') && (
                      <Col span={6}>
                        <Form.Item name='include_budget' valuePropName='checked'>
                          <Checkbox onChange={onChange}>
                            {t('Reports.Include_Budget')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}
                </Row>
                {formDataList.includes('sum_by_stages') &&
                  !form?.getFieldValue('sold_by_country') && (
                    <Row gutter={12}>
                      <SoldStageSelectionElement
                        form={form}
                        handleChange={onChange}
                        formDataList={formDataList}
                        // countsListReport={countsListReport}
                      />
                    </Row>
                  )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
);

export default SummarySOLDElement;
