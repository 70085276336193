import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Spin } from 'antd';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';

import InputComponent from '../../../../component/InputComponent';
import { vsmKnowledge, vsmNotify, vsmDealDesk } from '../../../../config/messages';
import useStore from '../../../../store';
import { useTranslation } from 'react-i18next';

const AddKnowledgeFromNote = observer(({ ...props }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [fetchClassification, setFetchClassification] = useState(true);

  const {
    KNOWLEDGE,
    DEALDESKKEYNOTES,
    AUTH,
    DEALDESKSTORE: { deal_data },
    DEALDESKNOTESEQUENCE: { fetchSequenceNotes },
  } = useStore();

  const { visible, tempKnowledgeNoteData } = props;
  const [form] = Form.useForm();

  const close = () => {
    form.resetFields();
    props.close();
  };

  const onFinish = (data) => {
    setSaving(true);
    let noteKnowledgeData = {
      knowledge_subject: data.knowledge_title,
      industry_id: parseInt(data.classification),
      description: tempKnowledgeNoteData.notes_description,
      note_id: tempKnowledgeNoteData.id,
      knowledge_classification: data.knowledge_classification,
    };

    DEALDESKKEYNOTES.saveNoteKnowledge(noteKnowledgeData)
      .then((data) => {
        close();
        fetchSequenceNotes(deal_data.lead_information && deal_data.lead_information.id);
        vsmNotify.success({
          message: vsmDealDesk.noteKnowledgeSave.saveSuccess,
        });
      })
      .catch(({ response }) => {
        vsmNotify.error({
          message: vsmDealDesk.noteKnowledgeSave.saveError,
        });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const onChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  return (
    <>
      <Modal
        centered
        title={`${t('Common_Data.Add_Knowledge')}`}
        visible={visible}
        onCancel={() => close()}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            form='add_knowledge_form'
            shape='round'
            htmlType='submit'
            type='primary'
            disabled={disabled}
            loading={saving}
          >
            {t('Common_Data.Save')}
          </Button>,
          <Button key='2' form='removeKnowledge' shape='round' onClick={() => close()}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
        width={500}
      >
        <Form
          form={form}
          id={'add_knowledge_form'}
          labelCol={{ span: 24 }}
          className='innerForm'
          onFinish={onFinish}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <InputComponent
                name='knowledge_title'
                label={`${t('DealDesk.Knowledge_Title')}`}
                required
                placeholder={`${t('DealDesk.Knowledge_Title')}`}
                type='input'
                tooltip={`${t('DealDesk.Knowledge_Title')}`}
                onChange={onChange}
                autoComplete='off'
                rules={vsmKnowledge.validation.subject}
              />
            </Col>
          </Row>

          <Row gutter={15}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <InputComponent
                name='classification'
                label={<span
                  className='converbillLink'
                  onClick={() => {
                    window.open(
                      'https://www.convertibill.com/credebtclassifications-popup.html',
                      '_blank',
                      'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                    );
                  }}
                >
                  {t('Common_Data.Credebt_Classification')}
                </span>}
                placeholder={`${t('Common_Data.Select_Credebt_Classification')}`}
                type='select'
                allowSearch
                tooltip={`${t('DealDesk.Industry_Tooltip')}`}
                required
                onChange={onChange}
                options={{
                  values:
                    KNOWLEDGE.classification_list ||
                    (KNOWLEDGE.knowledgeValues &&
                      KNOWLEDGE.knowledgeValues.classification && [
                        {
                          id: KNOWLEDGE.knowledgeValues.classification,
                          name: KNOWLEDGE.knowledgeValues.classification_name,
                        },
                      ]),
                  value_key: 'id',
                  text_key: 'name',
                }}
                onFocus={() =>
                  fetchClassification &&
                  KNOWLEDGE.getClassificationList().then(() =>
                    setFetchClassification(false)
                  )
                }
                notFoundContent={
                  fetchClassification ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
                rules={vsmKnowledge.validation.classification}
              />
            </Col>
          </Row>
          <Row gutter={15}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <InputComponent
                name='knowledge_classification'
                label={`${t('DealDesk.Knowledge_Classification')}`}
                placeholder={`${t('DealDesk.Select_Knowledge_Classification')}`}
                type='select'
                allowSearch
                tooltip={`${t('DealDesk.Knowledge_Classification')}`}
                required
                onChange={onChange}
                options={{
                  values: (AUTH.user && AUTH.user.knowledge_classification) || [],
                  value_key: 'knowledge_classification',
                  text_key: 'knowledge_classification',
                }}
                rules={vsmKnowledge.validation.knowledge_classification}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});

export default AddKnowledgeFromNote;
