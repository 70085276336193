import React from 'react'
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';


const RecordsSelect = (props) => {
    const { t } = useTranslation();
    let initialiseOptions = {
        values: [
            { value: 50, label: `50 ${t('Common_Data.Records')}` },
            { value: 100, label: `100 ${t('Common_Data.Records')}` },
            { value: 200, label: `200 ${t('Common_Data.Records')}` },
            { value: 500, label: `500 ${t('Common_Data.Records')}` },
            { value: 1000, label: `1000 ${t('Common_Data.Records')}` }
        ]
    };
    /* IF report type : tickets then we need to discuss 250 instant of 200 */
    if (props.isReportType && props.isReportType === "tickets") {
        initialiseOptions = {
            values: [
                { value: 50, text: `50 ${t('Common_Data.Records')}` },
                { value: 100, text: `100 ${t('Common_Data.Records')}` },
                { value: 250, text: `250 ${t('Common_Data.Records')}` },
                { value: 500, text: `500 ${t('Common_Data.Records')}` },
                { value: 1000, text: `1000 ${t('Common_Data.Records')}` }
            ]
        };
    }
    return (
        <Select
            style={{ margin: '0', marginRight: '10px',marginTop: '10px', width: "calc(100% - 1rem)" }}
            // dropdownStyle={{width: "80px"}}
            options={initialiseOptions.values}
            {...props}
        />
    )
}

export default RecordsSelect