import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';
const AutosaveNotifiy = observer(() => {
  const {t} = useTranslation()
  const {
    DEALDESKNOTESOUTBOUND: { notifyAutosave, setNotifyAutosave },
  } = useStore();

  useEffect(() => {
    if (notifyAutosave) {
      setTimeout(() => {
        setNotifyAutosave(false);
      }, 4000);
    }
  }, [notifyAutosave, setNotifyAutosave]);

  return (
    <>
      {notifyAutosave ? <span className={'autoSaveNoteNotify'}>{t('DealDesk.Draft_Saved')}</span> : null}
    </>
  );
});

export default AutosaveNotifiy;
