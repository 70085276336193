import { Button, Result } from "antd"
import { useHistory } from "react-router-dom"
import React from 'react'
import { useTranslation } from "react-i18next"

const PageNotFound = (props) => {
	const { t } = useTranslation();
	const history = useHistory()
	return (
		<Result
			status="404"
			title={props.title ? props.title : `${t('Common_Page.Error')}`}
			subTitle={props.subtitle ? props.subtitle : `${t('Common_Page.Not_Exist')}`}
			extra={(props.goback === undefined || props.goback) && (
				<Button type="primary" onClick={() => history.goBack()}>{t('Common_Page.Go_Back')}</Button>
			)}
		/>
	)
}

export default PageNotFound
