import React from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon } from '../../../../config/messages';
import useStore from '../../../../store';

const PreviewDataTableElement = observer((props) => {
  const {
    SavedReportStore: { reportColumnSettings },
  } = useStore();

  if (props.rowData && props.gridOptions) {
    let columns = [];
    let columnConfig = reportColumnSettings;
    if (columnConfig) {
      let data = JSON.parse(columnConfig);
      let cols = props.gridOptions.find((x) => !x.field);
      if (cols) {
        columns.push(cols);
      }
      data &&
        data.forEach((element) => {
          cols = props.gridOptions.find((x) => x.field === element.colId);
          if (cols) {
            columns.push(cols);
          }
        });
    } else {
      columns = props?.gridOptions;
    }

    const onCellDoubleClickRoute = (cellName, cellData) => {
      if (['SOLD ID'].includes(cellName) && cellData?.value) {
        window.open(`/dealdesk/${cellData?.value}`, '_blank');
      }
      if (['ticket_number'].includes(cellData?.column?.colId) && cellData?.value) {
        window.open(`/ticket/${cellData?.value}`, '_blank');
      }
    };

    let pinnedBottomRowData = props.pinnedBottomRowData;

    //--------------START ----Bottom Pinned Row Total column blank Fix--------//
    if (pinnedBottomRowData && pinnedBottomRowData.length > 0) {
      let formatObj = pinnedBottomRowData[0];
      if (!formatObj.hasOwnProperty('sr')) {
        formatObj.sr = 'Total';
        pinnedBottomRowData = [formatObj];
      }
    }

    //--------------START ----Bottom Pinned Row Total column blank Fix--------//

    return (
      <div className='' style={{ height: 'calc(100vh - 17rem)' }}>
        <div className='ag-theme-alpine grid_wrapper'>
          <AgGridReact
            rowData={props.rowData}
            modules={AllModules}
            columnDefs={columns}
            pinnedBottomRowData={pinnedBottomRowData}
            defaultColDef={{ ...LocalGridConfig.defaultColDef }}
            columnTypes={LocalGridConfig.columnTypes}
            overlayNoRowsTemplate={vsmCommon.noRecord}
            onGridReady={props.onGridReady}
            gridOptions={{ ...LocalGridConfig.options, pagination: false }}
            onColumnResized={props.onColumnResized}
            onColumnMoved={props.onColumnMoved}
            onColumnPinned={props.onColumnPinned}
            onSortChanged={props.onSortChanged}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            onFilterChanged={(e) => {
              if (e.api.getDisplayedRowCount()) {
                e.api.hideOverlay();
              } else {
                e.api.showNoRowsOverlay();
              }
            }}
            onCellDoubleClicked={(params) =>
              onCellDoubleClickRoute(params?.colDef?.headerName, params)
            }
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
});

export default PreviewDataTableElement;
