import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Form, Modal } from "antd";

//import EditorFooter from './EditorFooter';
import useStore from "../../../../../store";
import TinyComponent from "../../../../../component/TinyComponent";
import EditorFooter from "./EditorFooter";
import { Tabs } from "antd";
import moment from "moment";
import { vsmNotify, vsmDealDesk } from "../../../../../config/messages";
import debounce from "lodash/debounce";
import { convertTinyMCEDropdown } from "../../../../../utils/GlobalFunction";
import { useTranslation } from "react-i18next";
import DatePickerCostume from "../../../../../component/DatePickerCostume";

const EditorConfig = {
  selector: "textarea#full-featured-non-premium",
  height: "55vh",
};
const modalEditorConfig = {
  selector: "textarea#full-featured-non-premium",
  height: 600,
};

const NoteEditor = observer((props) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const [autoSaveAfterSubmit, setAutoSaveAfterSubmit] = useState(true); // Used To Enable Disable Autosave Functionality
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [noteFlag, setNoteFlag] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false); //Used To Disable submit btn during autosave
  const [editorInitalValue, setEditorInitalValue] = useState(null);

  const [resetContent, setresetContent] = useState(0);

  const [formTagUser, setFormTagUser] = useState([]);
  const [formKnowledge, setFormKnowledge] = useState(0);
  const [formTagGroup, setFormTagGroup] = useState([]);
  const [formLockGroup, setFormLockGroup] = useState([]);
  const [formLockUsers, setFormLockUsers] = useState([]);
  const [formEmailNotify, setFormEmailNotify] = useState(false);
  const [formWhatsappNotify, setFormWhatsappNotify] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modaleditorInitalValue, setModalEditorInitalValue] = useState(null);

  const [changeDateTime, setChangeDateTime] = useState(null); //USED FOR DATED
  const [formNoteDateTime, setFormNoteDateTime] = useState(null); //USED FOR DATED
  const [payloadNoteDate, setPayloadNoteDate] = useState(null); //USED FOR DATED

  const [tagVisible, setTagVisible] = useState(false); //Footer PopoverVisible Functions
  const [lockVisible, setLockVisible] = useState(false); //Footer PopoverVisible Functions
  const [tagVisibleModal, setTagVisibleModal] = useState(false); //Footer PopoverVisible Functions
  const [lockVisibleModal, setLockVisibleModal] = useState(false); //Footer PopoverVisible Functions
  //-------------- Auto Save Timer State--------------------//

  const [timeoutContainer, settimeoutContainer] = useState(null);

  //-------------------------------------------------------//
  const { isNoteFlagExist } = props;
  console.log("These are the props:", props);
  const inputEl = useRef(null);

  const [form] = Form.useForm();

  const {
    AUTH,
    AUTH: {
      getLocalTimeDealDesk,
      user,
      //global_fulldate_format,
      //global_dateformat,
    },
    DEALDESKSTORE,
    DEALDESKSTORE: {
      deal_data,
      activeNoteEditDated,
      resetActiveNoteEditDated,
      resetNoteTabIndex,
      dealdeskActiveNoteTab,
      setDealdeskActiveNoteTab,
      structureSchedulePostData,
      setStructureSchedulePostData,
      setLeftSectionActiveTab,
      // getDealDetail,
    },
    DEALDESKNOTESOUTBOUND: {
      // saveOutboundNote,
      saveOutboundNoteEdit_v1,
      JsonToFormData,
      dropdown_tinymce_industry_list,
      autoSaveIDTempData,
      setAutoSaveIDTempData,
    },
    RIGHTSECTTION_TAGSTICKETS: { fetchTagsTicketData },
    DEALDESKNOTESEQUENCE: {
      //fetchSequenceNotes,
      autoSavedTabID,
      autoSavedNoteData,
      resetAutosaveEditNoteData,
      setAutoSavedTabID,
      addUpdateNoteList,
      setAutoSavedNoteData,
    },
  } = useStore();

  const dateFormat = "YYYY-MM-DD hh:mm a"; // USED FOR DATE SELECTION IN DATEPICKER FORMAT
  // const formdateFormat = 'YYYY-MM-DD hh:mm:ss a'; // USED FOR DISPLAY DATE FORMAT ABOVE EDITOR
  const formdateFormat =
    AUTH.global_timeformat === "hh:mm a"
      ? `${AUTH.global_dateformat + " hh:mm:ss a"}`
      : `${AUTH.global_dateformat + " HH:mm:ss"}`; // USED FOR DISPLAY DATE FORMAT ABOVE EDITOR
  const submitTimeDateFormat = "YYYY-MM-DD HH:mm:ss"; // USED IN FINAL DATE FORMAT ON API CALL NOTE DATE

  useEffect(() => {
    const secondsTimer = setInterval(() => {
      if (inputEl.current) {
        inputEl.current.innerText = AUTH.getLocalTime(
          new Date(),
          formdateFormat
        );
      }
    }, 1000);
    return () => clearInterval(secondsTimer);
  });

  /**UseEffect To Display Autosaved Note Data */
  // let timeWithSecond = AUTH.global_timeformat === "hh:mm a" ? `${AUTH.global_dateformat + " hh:mm:ss a"}` : `${AUTH.global_dateformat + " HH:mm:ss"}`
  useEffect(() => {
    if (!activeNoteEditDated) {
      //!activeNoteEditDated added condition if note is in edit mode on every 3min sync state should note update
      //Until note saved.
      if (autoSavedNoteData) {
        form.resetFields();
        form.setFieldsValue({
          notes_description: autoSavedNoteData.notes_description,
        });

        setEditorInitalValue(
          autoSavedNoteData ? autoSavedNoteData.notes_description : ""
        );
        if (autoSavedNoteData.attachment) {
          setSelectedFiles(autoSavedNoteData.attachment);
        } else {
          setSelectedFiles([]);
        }

        setFormTagUser(
          autoSavedNoteData && autoSavedNoteData.is_tagged
            ? autoSavedNoteData.tag_user_id
            : []
        );
        setFormTagGroup(
          activeNoteEditDated && activeNoteEditDated.is_tagged
            ? activeNoteEditDated.tag_group_id
            : []
        );
        setFormLockUsers(
          autoSavedNoteData &&
            autoSavedNoteData.is_locked &&
            autoSavedNoteData.lock_user_ids
            ? autoSavedNoteData.lock_user_ids
            : []
        );
        autoSavedNoteData?.notes_flag &&
          setNoteFlag(autoSavedNoteData?.notes_flag);
        setFormKnowledge(
          autoSavedNoteData && autoSavedNoteData.is_knowledge ? 1 : 0
        );

        if (autoSavedNoteData && autoSavedNoteData.notes_date) {
          // setChangeDateTime(
          //   // AUTH.getLocalTime(autoSavedNoteData.notes_date, formdateFormat, 0)
          //   moment
          //     .utc(autoSavedNoteData.notes_date)
          //     // .utcOffset(AUTH.selectedCountry.time_zone)
          //     .format(formdateFormat)
          // );
          setChangeDateTime(
            moment(autoSavedNoteData.notes_date).format("YYYY-MM-DD HH:mm:ss")
          );
          setFormNoteDateTime(
            AUTH.getLocalTime(autoSavedNoteData.notes_date, formdateFormat, 0)
          );

          setPayloadNoteDate(
            moment(
              new Date(AUTH.getLocalTime(autoSavedNoteData.notes_date))
            ).format(submitTimeDateFormat)
          );
        }
      } else {
        setEditorInitalValue(null);
        setFormTagUser([]);
        setFormKnowledge(0);
        setFormTagGroup([]);
        setFormLockGroup([]);
        setFormLockUsers([]);
        setNoteFlag();
        setFormEmailNotify(false);
        setFormWhatsappNotify(false);
        setAttachment(false);
        setSelectedFiles([]);
      }
    }
  }, [autoSavedNoteData, form, activeNoteEditDated, formdateFormat, AUTH]);

  useEffect(() => {
    if (activeNoteEditDated) {
      setDisabledBtn(false);
      setAutoSaveAfterSubmit(true); //Restrict Autosave
      form.resetFields();
      form.setFieldsValue({
        notes_description: activeNoteEditDated.notes_description,
      });
      setEditorInitalValue(
        activeNoteEditDated ? activeNoteEditDated.notes_description : ""
      );
      if (activeNoteEditDated.attachment) {
        setSelectedFiles(activeNoteEditDated.attachment);
      } else {
        setSelectedFiles([]);
      }
      setFormTagUser(
        activeNoteEditDated && activeNoteEditDated.is_tagged
          ? activeNoteEditDated.tag_user_id
          : []
      );
      setFormTagGroup(
        activeNoteEditDated && activeNoteEditDated.is_tagged
          ? activeNoteEditDated.tag_group_id
          : []
      );
      setFormLockUsers(
        activeNoteEditDated && activeNoteEditDated.is_locked
          ? activeNoteEditDated.lock_user_ids
          : []
      );
      setFormKnowledge(
        activeNoteEditDated && activeNoteEditDated.is_knowledge ? 1 : 0
      );
      if (activeNoteEditDated && activeNoteEditDated.notes_date) {
        // setChangeDateTime(
        //   AUTH.getLocalTime(activeNoteEditDated.notes_date, formdateFormat)
        // );
        setChangeDateTime(
          moment(activeNoteEditDated.notes_date).format("YYYY-MM-DD HH:mm:ss")
        );
        setFormNoteDateTime(
          AUTH.getLocalTime(activeNoteEditDated.notes_date, formdateFormat)
        );
        setPayloadNoteDate(
          activeNoteEditDated.notes_date
          // moment(new Date(AUTH.getLocalTime(activeNoteEditDated.notes_date))).format(
          //   submitTimeDateFormat
          // )
        );
      }
      //setresetContent(1);
    }
  }, [activeNoteEditDated, form, formdateFormat, AUTH]);

  //-----------START:ON STRUCTURE POST SET NOTE EDITOR DESCRIPTION---------------//
  useEffect(() => {
    if (structureSchedulePostData) {
      // form.resetFields();
      let currentData = form.getFieldValue("notes_description");
      if (!currentData) {
        currentData = "";
      }
      currentData = currentData + ` ${structureSchedulePostData}`;
      form.setFieldsValue({
        notes_description: currentData,
      });
      setEditorInitalValue(currentData ? currentData : "");
      setStructureSchedulePostData(null);
    }
  }, [structureSchedulePostData, form, setStructureSchedulePostData]);

  //-----------START: ON STRUCTURE POST SET NOTE EDITOR DESCRIPTION---------------//

  // function onChangeDateTime(value) {
  //   setChangeDateTime(value?.format(formdateFormat));
  //   setFormNoteDateTime(value?.format(formdateFormat));
  //   dateChangeAutoSave(value?.format(submitTimeDateFormat));
  //   setPayloadNoteDate(value?.format(submitTimeDateFormat));
  // }

  const onChangeDateTime = (value) => {
    if (value && moment(value, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
      setChangeDateTime(moment(value).format("YYYY-MM-DD HH:mm:ss"));
      console.log("Valid Date:-", changeDateTime);
    } else {
      console.warn("Invalid date format:", value);
    }
  };

  // function onOk(value) {
  //   setChangeDateTime(value?.format(formdateFormat));
  //   setFormNoteDateTime(value?.format(formdateFormat));
  //   // dateChangeAutoSave(value?.format(dateFormat));
  //   setPayloadNoteDate(value?.format(submitTimeDateFormat));
  // }
  const onOk = (value) => {
    if (value && moment(value).isValid()) {
      setChangeDateTime(moment(value).format("YYYY-MM-DD HH:mm:ss"));
    } else {
      console.warn("Invalid date selected:", value);
    }
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((data) => {
        if (isNoteFlagExist) {
          props.setDisabled(true);
        } else {
          props.setDisabled(false);
        }
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          props.setDisabled(true);
        }
      });
  };

  window.onbeforeunload = function (event) {
    let autoSavePending = localStorage.getItem("autoSavePending");
    if (autoSavePending) {
      return false;
    }
  };
  window.addEventListener("beforeunload", function (event) {});
  window.addEventListener("unload", function (event) {
    localStorage.removeItem("autoSavePending");
  });

  const close = () => {
    form.setFieldsValue({
      notes_description: form.getFieldValue("notes_description_modal"),
    });
    setEditorInitalValue(form.getFieldValue("notes_description_modal"));
    setOpenModal(false);
    if (activeNoteEditDated) {
    } else {
      setAutoSaveAfterSubmit(true);
    }
  };

  const openExpandModal = () => {
    if (isNoteFlagExist) {
      let note_desc = form.getFieldValue("notes_description");
      setModalEditorInitalValue(note_desc);
      form.setFieldsValue({
        notes_description_modal: note_desc,
      });
      setOpenModal(true);
      setAutoSaveAfterSubmit(false);
    }
  };

  const clearFormValues = () => {
    setAutoSaveAfterSubmit(true);
    form.resetFields();
    setEditorInitalValue(null);
    setFormTagUser([]);
    setFormKnowledge(0);
    setFormTagGroup([]);
    setFormLockGroup([]);
    setFormLockUsers([]);
    setChangeDateTime(null);
    setFormNoteDateTime(null);
    setFormEmailNotify(false);
    setFormWhatsappNotify(false);
    setAttachment(false);
    setSelectedFiles([]);
    setresetContent(1);
    setDealdeskActiveNoteTab(0);
    setAutoSavedTabID(0);
  };
  //-------------------- Handle Footer events ------------------------------------------------//

  const handleLockGroupChange = (item) => {
    //toggleGroupUserArray(item.id, 1);
    let newgrouparr = [];
    if (
      formLockGroup &&
      formLockGroup.length > 0 &&
      formLockGroup.includes(item.id)
    ) {
      newgrouparr = formLockGroup.filter((group) => group !== item.id);
    } else {
      if (formLockGroup && formLockGroup.length > 0) {
        newgrouparr = [...formLockGroup, item.id];
      } else {
        newgrouparr = [item.id];
      }
    }
    setFormLockGroup(newgrouparr);
    forceUpdate();
    let newObj = saveObj();
    newObj.formLockGroup = newgrouparr;
    autoSaveData(newObj);
  };

  const saveObj = () => {
    let obj = {
      formTagUser: formTagUser,
      formKnowledge: formKnowledge,
      formLockGroup: formLockGroup,
      formLockUsers: formLockUsers,
      formTagGroup: formTagGroup,
      selectedFiles: selectedFiles,
      formEmailNotify: formEmailNotify,
      formWhatsappNotify: formWhatsappNotify,
      formNoteType: isNoteFlagExist,
      changeDateTime: changeDateTime,
    };
    return obj;
  };

  const handleLockUserChange = (item) => {
    let newlockuserarr = [];
    if (
      formLockUsers &&
      formLockUsers.length > 0 &&
      formLockUsers.includes(item.id)
    ) {
      newlockuserarr = formLockUsers.filter((x) => x !== item.id);
    } else {
      if (formLockUsers && formLockUsers.length > 0) {
        newlockuserarr = [...formLockUsers, item.id];
      } else {
        newlockuserarr = [item.id];
      }
    }
    setFormLockUsers(newlockuserarr);
    forceUpdate();
    let newObj = saveObj();
    newObj.formLockUsers = newlockuserarr;
    autoSaveData(newObj);
  };

  const handleTagGroupChange = (item) => {
    let newgrouparr = [];
    if (
      formTagGroup &&
      formTagGroup.length > 0 &&
      formTagGroup.includes(item.id)
    ) {
      newgrouparr = formTagGroup.filter((group) => group !== item.id);
    } else {
      if (formTagGroup && formTagGroup.length > 0) {
        newgrouparr = [...formTagGroup, item.id];
      } else {
        newgrouparr = [item.id];
      }
    }
    setFormTagGroup(newgrouparr);
    forceUpdate();
    let newObj = saveObj();
    newObj.formTagGroup = newgrouparr;
    autoSaveData(newObj);
  };

  const handleTagUserChange = (item) => {
    let newTagUserArr = [];
    if (formTagUser && formTagUser.includes(item.id)) {
      newTagUserArr = formTagUser.filter((x) => x !== item.id);
    } else {
      if (formTagUser && formTagUser.length > 0) {
        newTagUserArr = [...formTagUser, item.id];
      } else {
        newTagUserArr = [item.id];
      }
    }
    setFormTagUser(newTagUserArr);
    forceUpdate();
    let newObj = saveObj();
    newObj.formTagUser = newTagUserArr;
    autoSaveData(newObj);
  };

  const dateChangeAutoSave = (date) => {
    let newObj = saveObj();
    newObj.changeDateTime = date;
    autoSaveData(newObj);
  };

  // -----------------------------------------------------------------------------------------//

  //------------------------- Auto Save Functionality ----------------------------------------//
  useEffect(() => {
    if (!autoSaveAfterSubmit) {
      if (timeoutContainer) {
        clearTimeout(timeoutContainer);
      }
    }
  }, [autoSaveAfterSubmit, timeoutContainer]);

  const autoSaveData = (data) => {
    if (activeNoteEditDated ? data === null : true) {
      if (autoSaveAfterSubmit) {
        if (timeoutContainer) {
          clearTimeout(timeoutContainer);
          settimeoutContainer(null);
        }

        localStorage.setItem("autoSavePending", true);
        settimeoutContainer(
          setTimeout(() => {
            handleSubmit(0, true, selectedFiles, data);
          }, 15000)
        );
      } else {
        if (timeoutContainer) {
          clearTimeout(timeoutContainer);
          settimeoutContainer(null);
        }
      }
    }
  };

  // -----------------------------------------------------------------------------------------//

  //-----------------------------START:SCROLL TO NOTE----------------------------------------//
  const scrollToNote = debounce((id) => {
    setLeftSectionActiveTab("5");
    if (document.getElementById(`#n${id}`)) {
      document.getElementById(`#n${id}`).scrollIntoView();
    }
  }, 500);
  //-----------------------------END:SCROLL TO NOTE----------------------------------------//

  /** Modify Tag Subject Function*/
  let tagSubject = null;
  const modifyTagSubject = (data) => {
    let tagSubjectTemp = [];
    data.forEach((x) => {
      x && tagSubjectTemp.push(x);
    });
    tagSubject = tagSubjectTemp.join(" | ");
    return tagSubject;
  };

  /**Modify Tag Subject according to dealDesk type */
  /**Ticket */
  if (deal_data?.lead_information?.is_ticket === 0) {
    let ticketData = deal_data?.lead_information?.ticket_data;
    modifyTagSubject([ticketData.ticket_subject, ticketData.ticket_number]);
  }
  /**Leads */
  if (deal_data?.lead_information?.is_ticket === 1) {
    let leadData = deal_data?.lead_information;
    let soldValue = deal_data?.deal_sum?.deal_sum.replace(/SOLD Value : /g, "");
    modifyTagSubject([
      leadData.opportunity_name,
      leadData.sold_organisation?.organisation_name,
      leadData.product_name,
      soldValue,
      leadData.id,
    ]);
  }
  /**People */
  if (deal_data?.lead_information?.is_ticket === 2) {
    let peopleData = deal_data?.lead_information?.people;
    modifyTagSubject([peopleData.full_name, peopleData.id]);
  }
  /**Org */
  if (deal_data?.lead_information?.is_ticket === 3) {
    let orgData = deal_data?.lead_information?.sold_organisation;
    modifyTagSubject([orgData.organisation_name, orgData.id]);
  }

  // Handle submit and call function to save new record
  const handleSubmit = (
    submittype = 0,
    isAutoSave = false,
    attachments = selectedFiles,
    saveData = null
  ) => {
    if (selectedFiles.length < 1) {
      attachments = 0;
    }
    let data = form.getFieldsValue();

    if (openModal) {
      data.notes_description = data.notes_description_modal;
    }
    if (!data.notes_description) {
      if (!isAutoSave) {
        vsmNotify.error({
          message: vsmDealDesk.Notes.descError,
        });
      }
      return false;
    }

    /**Start:To Restrict Auto save if submit called first */
    if (!isAutoSave) {
      setDisabledBtn(true);
      setAutoSaveAfterSubmit(false);
    } else {
      if (
        !autoSaveAfterSubmit
        //|| DEALDESKSTORE.activeNoteEditDated
      ) {
        return false;
      }
      if (isAutoSave) {
        //setDisabledBtn(true);
      }
    }
    /**End:To Restrict Auto save if submit called first */

    data.is_saved = submittype ? 0 : 1;
    data.is_autosaved = isAutoSave;

    if (!isNoteFlagExist) {
      data.notes_flag = 0; //Outbound
      //return false;
    } else {
      if (isNoteFlagExist === "4") {
        data.notes_flag = 1; //Outbound
      }
      if (isNoteFlagExist === "3") {
        data.notes_flag = 2; // Comment
      }
      if (isNoteFlagExist === "2") {
        data.notes_flag = 3; //Dated
      }
      if (isNoteFlagExist === "1") {
        data.notes_flag = 4; // Inbound
      }
    }

    if (formNoteDateTime) {
    }

    if (isAutoSave && saveData?.changeDateTime) {
      data.notes_date = saveData.changeDateTime;
    } else {
      data.notes_date = changeDateTime
        ? payloadNoteDate
        : isAutoSave
        ? null
        : getLocalTimeDealDesk(new Date(), submitTimeDateFormat);
    }
    if (isAutoSave && saveData) {
      data.is_emailNotify = saveData.formEmailNotify ? 1 : 0;
      data.is_smsNotify = saveData.formWhatsappNotify ? 1 : 0;
    } else {
      data.is_emailNotify = formEmailNotify ? 1 : 0;
      data.is_smsNotify = formWhatsappNotify ? 1 : 0;
    }

    //START: TAG USER FIELD DATA
    if (isAutoSave && saveData) {
      data.tagged_id = saveData.formTagUser;
      data.tagged_group_id = saveData.formTagGroup;

      data.is_tagged =
        (saveData.formTagUser && saveData.formTagUser.length > 0) ||
        (saveData.formTagGroup && saveData.formTagGroup.length > 0)
          ? 1
          : 0;
      // saveData.formTagUser.length > 0 || saveData.formTagGroup.length > 0 ? 1 : 0;
    } else {
      data.tagged_group_id = formTagGroup;
      data.tagged_id = formTagUser;
      data.is_tagged =
        (formTagUser && formTagUser.length > 0) ||
        (formTagGroup && formTagGroup.length > 0)
          ? 1
          : 0;
    }

    //START: KNOWLEDGE-CLASSIFICATION FIELD DATA
    if (isAutoSave && saveData) {
      data.classification_id = saveData.formKnowledge;
      data.is_knowledge = saveData.formKnowledge ? 1 : 0;
    } else {
      data.classification_id = formKnowledge;
      data.is_knowledge = formKnowledge ? 1 : 0;
    }
    //START: TAGS VARIABLS
    if (isAutoSave && saveData) {
      data.is_locked =
        (saveData.formLockGroup && saveData.formLockGroup.length > 0) ||
        (saveData.formLockUsers && saveData.formLockUsers.length > 0)
          ? 1
          : 0;
      if (data.is_locked) {
        data.locked_group_id = saveData.formLockGroup;
        data.locked_user_id = saveData.formLockUsers;
      }
    } else {
      data.is_locked =
        (formLockGroup && formLockGroup.length > 0) ||
        (formLockUsers && formLockUsers.length > 0)
          ? 1
          : 0;
      if (data.is_locked) {
        data.locked_group_id = formLockGroup;
        data.locked_user_id = formLockUsers;
      }
    }

    /**start:if autosave and edit active note  */
    if (activeNoteEditDated) {
      data.note_id = activeNoteEditDated.id;
    } else if (autoSavedNoteData && autoSavedNoteData?.id) {
      data.note_id = autoSavedNoteData.id;
    }
    /**end: if autosave and edit active note  */

    //START: OTHER DATA
    data.tag_subject = tagSubject;
    data.organisation_id = deal_data?.lead_information?.sold_organisation?.id;

    if (!isAutoSave) {
      // ----------------------------------- Note formatting Start -------------------------------------------//

      let spaceRemoved = data.notes_description.replace(
        /\n<p>&nbsp;<\/p>/gi,
        ""
      );

      //------------ Replace blank divs ------//
      // let getContent = spaceRemoved.replace(/<span (.*?)>/, '')
      spaceRemoved = spaceRemoved.replace(/<span>&nbsp;<\/span>/gi, ""); // Comment this line, becuase it's remove text inside span
      spaceRemoved = spaceRemoved.replace(/<span (.*?)>&nbsp;<\/span>/gmu, ""); // Comment this line, becuase it's remove text inside span
      spaceRemoved = spaceRemoved.replace(/<div><\/div>/gi, "");
      //------------ Replace multiple brs with single br ------//
      spaceRemoved = spaceRemoved.replace(/(<br\s*\/?>){2,}/gi, "<br />");

      // spaceRemoved = spaceRemoved.replace(/(&nbsp;){2,}/gi, '');
      // spaceRemoved = spaceRemoved.replace(/(\s)/gi, '&nbsp;');

      //------------ Replace multiple spaces with single space ------//
      spaceRemoved = spaceRemoved.replace(/(&nbsp;\s)/gi, "&nbsp;");

      //------------ Replace more than three spaces with three space ------//

      spaceRemoved = spaceRemoved.replace(
        /(&nbsp;){3,}/gi,
        "&nbsp;&nbsp;&nbsp;"
      );
      // spaceRemoved = spaceRemoved.trim().split(/ +/).join(" ")

      // ----------------------------------- Note formatting End -------------------------------------------//
      data.notes_description = spaceRemoved;
    }
    if (isAutoSave && saveData?.selectedFiles) {
      data.attachments =
        saveData && saveData?.selectedFiles.length > 0
          ? "#" + saveData.selectedFiles.join("#") + "#"
          : "";
    } else {
      data.attachments =
        attachments && attachments.length > 0
          ? "#" + attachments.join("#") + "#"
          : "";
    }

    // return false;
    var formData = JsonToFormData(data);
    //saveOutboundNote(deal_data?.lead_information?.id, formData, isAutoSave)
    saveOutboundNoteEdit_v1(
      deal_data?.lead_information?.id,
      formData,
      isAutoSave
    )
      .then((res) => {
        if (isAutoSave) {
          if (activeNoteEditDated) {
            addUpdateNoteList(res.note, true);
          }
        } else {
          // START:To Update Notes Listing - After NOte Save
          if (!isAutoSave) {
            setDisabledBtn(false);
            clearFormValues();
          }

          if (activeNoteEditDated && data.note_id) {
            resetActiveNoteEditDated();
            resetNoteTabIndex();

            if (autoSaveIDTempData && !autoSavedNoteData) {
              setAutoSavedNoteData(autoSaveIDTempData);
            }
          } else {
            if ((autoSavedNoteData || autoSaveIDTempData) && !isAutoSave) {
              resetAutosaveEditNoteData();
              setAutoSaveIDTempData(null);
            }
          }

          if (!isAutoSave) {
            //-----------------------START:ADD UPDATE NOTE LIST WITHOUT API CALL--------------------------------------//
            if (res && res?.data) {
              addUpdateNoteList(
                res.data,
                activeNoteEditDated || autoSavedNoteData?.isEditAutoSaveData
                  ? true
                  : false
              );
              scrollToNote(res?.data?.id);
            }
            // fetchSequenceNotes(
            //   deal_data.lead_information && deal_data.lead_information.id,
            //   null,
            //   true // Fetch With Old Autosave Notes.
            // );
            //-----------------------END:ADD UPDATE NOTE LIST WITHOUT API CALL--------------------------------------//
          } else if (autoSavedNoteData?.isEditAutoSaveData) {
            addUpdateNoteList(
              res.data,
              autoSavedNoteData?.isEditAutoSaveData ? true : false
            );
          }
          // END:To Update Notes Listing - After NOte Save

          /*Start: On Tagged Note - Tags-List- */
          fetchTagsTicketData(deal_data?.lead_information?.id);
          /*End: On Tagged Note - Tags-List- */

          if (openModal) {
            close();
          }
        }
        localStorage.removeItem("autoSavePending");

        // To update last updated date in  headers with header api
        DEALDESKSTORE?.getDealDetail(deal_data?.lead_information?.id);
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => {
        if (!isAutoSave) {
          setresetContent(0);
        }
      });
  };

  const clearVisiblePopover = () => {
    setTagVisible(false);
    setLockVisible(false);
  };
  console.log(changeDateTime);
  // const disabledHours = (e) => {
  //   let hours = [19, 20, 21, 22, 23];
  //   for (let i = 0; i < 7; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };
  return (
    deal_data && (
      <>
        <Tabs
          activeKey={
            activeNoteEditDated
              ? dealdeskActiveNoteTab
              : autoSavedTabID
              ? autoSavedTabID
              : "0"
          }
          centered
          className="Dealdesk_SubTab hideContent"
          onChange={(e) => {
            setDealdeskActiveNoteTab(e);
            if (autoSaveAfterSubmit) {
              setAutoSavedTabID(e);
              autoSaveData();
            }
          }}
        >
          <TabPane tab={`${t("DealDesk.Inbound")}`} key="1"></TabPane>
          <TabPane tab={`${t("DealDesk.Conversation")}`} key="2"></TabPane>
          <TabPane tab={`${t("DealDesk.Comment")}`} key="3"></TabPane>
          <TabPane tab={`${t("DealDesk.Outbound")}`} key="4"></TabPane>
        </Tabs>
        <div className="dealNoteEditor">
          <Form
            form={form}
            id={"notes_outbound_form"}
            onFinish={handleSubmit}
            onChange={handleChange}
            layout="vertical"
          >
            <div className="NotesDataTitle d-flex">
              <strong className="mr-5">
                {user?.first_name + " " + user?.last_name}
              </strong>{" "}
              <span className="NotesDataTime">
                {/* <DatePickerCostume
                  hideDisabledOptions
                  showNow={false}
                  showTime
                  allowClear={false}
                  onChange={onChangeDateTime}
                  onOk={onOk}
                  // format={'HH:mm'}
                  // use12Hours
                  // minuteStep={15}
                  value={
                    changeDateTime
                      ? moment(changeDateTime)
                      : moment(new Date().setHours(7, 0))
                  }
                /> */}
                <DatePickerCostume
                  value={
                    changeDateTime
                      ? moment(changeDateTime, "YYYY-MM-DD HH:mm:ss")
                      : moment()
                  }
                  onChange={onChangeDateTime}
                  onOk={onOk}
                  showTime
                  allowClear={false}
                />

                {changeDateTime ? changeDateTime : <div ref={inputEl}></div>}
              </span>
            </div>
            <Form.Item name="notes_description">
              <TinyComponent
                htmlName={`notes_description`}
                autoSave={autoSaveAfterSubmit}
                handleSubmit={activeNoteEditDated ? null : handleSubmit}
                form={form}
                autoSaveData={autoSaveData}
                editorvalue={editorInitalValue}
                EditorConfig={EditorConfig}
                resetContent={resetContent}
                selectedFiles={selectedFiles}
                clearVisiblePopover={clearVisiblePopover}
                dropdown_tinymce_industry_list={dropdown_tinymce_industry_list}
                dropdown_tinymce_knowledge_classification_list={convertTinyMCEDropdown(
                  user?.knowledge_classification,
                  "knowledge_classification"
                )}
              />
            </Form.Item>

            <EditorFooter
              openExpandModal={openExpandModal}
              disabled={isNoteFlagExist ? (disabledBtn ? true : false) : true}
              //--------- Change Events------------------//
              onChange={handleChange}
              handleLockGroupChange={handleLockGroupChange}
              handleLockUserChange={handleLockUserChange}
              handleTagGroupChange={handleTagGroupChange}
              handleTagUserChange={handleTagUserChange}
              autoSaveData={autoSaveData}
              saveObj={saveObj}
              //-----------------------------------------//

              handleSubmit={handleSubmit}
              id="notes_outbound_form"
              setFormTagUser={setFormTagUser}
              setFormKnowledge={setFormKnowledge}
              setFormTagGroup={setFormTagGroup}
              setFormLockGroup={setFormLockGroup}
              setFormLockUsers={setFormLockUsers}
              setFormEmailNotify={setFormEmailNotify}
              setFormWhatsappNotify={setFormWhatsappNotify}
              setAttachment={setAttachment}
              selectedTagUser={formTagUser}
              selectedTagGroup={formTagGroup}
              selectedKnowlege={formKnowledge}
              selectedLockGroup={formLockGroup}
              selectedLockUsers={formLockUsers}
              formEmailNotify={formEmailNotify}
              formWhatsappNotify={formWhatsappNotify}
              attachment={attachment}
              noteFlag={noteFlag}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              clearFormValues={clearFormValues}
              tagVisible={tagVisible}
              setTagVisible={setTagVisible}
              lockVisible={lockVisible}
              setLockVisible={setLockVisible}
              isNoteTabID={isNoteFlagExist}
              expandFooter={openModal}
            />

            <Modal
              centered
              //width={1000}
              width={"90%"}
              bodyStyle={{ height: "75vh" }}
              //title=''
              visible={openModal}
              onCancel={close}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
              //footer={<Footer />}
              className="ExpandModal"
            >
              <div className="NotesDataTitle d-flex">
                <strong className="mr-5">
                  {user?.first_name + " " + user?.last_name}
                </strong>{" "}
                <span className="NotesDataTime">
                  <DatePickerCostume
                    hideDisabledOptions
                    showNow={false}
                    showTime
                    // disabledHours={() => disabledHours()}
                    format={dateFormat}
                    onChange={onChangeDateTime}
                    onOk={onOk}
                    minuteStep={10}
                    value={
                      changeDateTime
                        ? moment(changeDateTime)
                        : moment(new Date().setHours(7, 0))
                    }
                  />
                  {changeDateTime ? changeDateTime : <div ref={inputEl}></div>}
                </span>
              </div>
              <Form.Item name="notes_description_modal">
                <TinyComponent
                  htmlName={`notes_description_modal`}
                  handleSubmit={handleSubmit}
                  form={form}
                  autoSave={autoSaveAfterSubmit}
                  editorvalue={modaleditorInitalValue}
                  EditorConfig={modalEditorConfig}
                  resetContent={resetContent}
                  selectedFiles={selectedFiles}
                  clearVisiblePopover={clearVisiblePopover}
                  dropdown_tinymce_industry_list={
                    dropdown_tinymce_industry_list
                  }
                  dropdown_tinymce_knowledge_classification_list={convertTinyMCEDropdown(
                    user?.knowledge_classification,
                    "knowledge_classification"
                  )}
                />
              </Form.Item>
              <EditorFooter
                openModal={openModal}
                close={close}
                disabled={isNoteFlagExist ? (disabledBtn ? true : false) : true}
                onChange={handleChange}
                //--------- Change Events------------------//
                handleLockGroupChange={handleLockGroupChange}
                handleLockUserChange={handleLockUserChange}
                handleTagGroupChange={handleTagGroupChange}
                handleTagUserChange={handleTagUserChange}
                //-----------------------------------------//
                handleSubmit={handleSubmit}
                id="notes_outbound_form"
                setFormTagUser={setFormTagUser}
                setFormKnowledge={setFormKnowledge}
                setFormTagGroup={setFormTagGroup}
                setFormLockGroup={setFormLockGroup}
                setFormLockUsers={setFormLockUsers}
                setFormEmailNotify={setFormEmailNotify}
                setFormWhatsappNotify={setFormWhatsappNotify}
                setAttachment={setAttachment}
                selectedTagUser={formTagUser}
                selectedTagGroup={formTagGroup}
                selectedKnowlege={formKnowledge}
                selectedLockGroup={formLockGroup}
                selectedLockUsers={formLockUsers}
                formEmailNotify={formEmailNotify}
                formWhatsappNotify={formWhatsappNotify}
                attachment={attachment}
                noteFlag={noteFlag}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                clearFormValues={clearFormValues}
                tagVisibleModal={tagVisibleModal}
                setTagVisibleModal={setTagVisibleModal}
                lockVisibleModal={lockVisibleModal}
                setLockVisibleModal={setLockVisibleModal}
                isNoteTabID={isNoteFlagExist}
              />
            </Modal>
          </Form>
        </div>
      </>
    )
  );
});
export default NoteEditor;
