import React, { useState } from 'react';
import { Col, Spin } from 'antd';

import useStore from '../../../../store';

import InputComponent from '../../../../component/InputComponent';
import OrganisationElements from './OrganisationElements';
import PersonElements from './PersonElements';
import { vsmTransactionReport } from '../../../../config/messages';
import NaceCategoryElement from './NaceCategoryElement';
import TimesTrackElement from './TimesTrackElement';
import ParentOrgRowElements from './ParentOrgRowElements';
import { useTranslation } from 'react-i18next';

const TicketReportCriteria = ({ formDataList, form, handleChange }) => {
  const [isMasterTicket, setIsMasterTicket] = useState(true);
  const [isTicketStatus, setIsTicketStatus] = useState(true);
  const [isTicketPriority, setIsTicketPriority] = useState(true);
  const {TicketsStore} = useStore();

  const { t } = useTranslation();

  return (
    <>
      {formDataList.includes('master_ticket_number') && (
        <Col span={6}>
          <InputComponent
            name='master_ticket_number'
            label={`${t('Common_Data.Master')} ${t('DealDesk.Ticket_Number')}`}
            type='select'
            mode='multiple'
            showSearch={true}
            placeholder={`${t('Common_Data.Master')} ${t('DealDesk.Ticket_Number')}`}
            tooltip={`${t('Common_Data.Master')} ${t('DealDesk.Ticket_Number')}`}
            onChange={handleChange}
            allowClear
            showArrow
            onFocus={() =>
              isMasterTicket && TicketsStore.getMasterTicketList().then(() => setIsMasterTicket(false))
            }
            notFoundContent={isMasterTicket ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            options={{
              values: TicketsStore.dropdown_master_ticket,
              value_key: 'id',
              text_key: 'ticket_subject',
            }}
          />
        </Col>
      )}
      {formDataList.includes('leads_tickets_status') && (
        <Col span={6}>
          <InputComponent
            name='leads_tickets_status'
            mode='multiple'
            label={t('Productivity.Ticket_Status')}
            type='select'
            allowClear
            showArrow
            placeholder={t('Productivity.Ticket_Status')}
            showSearch={false}
            tooltip={t('Productivity.Ticket_Status')}
            onChange={handleChange}
            onFocus={() =>
              isTicketStatus && TicketsStore.getTicketstatusList().then(() => setIsTicketStatus(false))
            }
            notFoundContent={isTicketStatus ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            options={{
              values: TicketsStore.dropdown_ticketstatus,
              value_key: 'id',
              text_key: 'status_name',
            }}
            rules={vsmTransactionReport.validation.tag_status_id}
          />
        </Col>
      )}
      {formDataList.includes('ticket_type') && (
        <Col span={6}>
          <InputComponent
            name='ticket_type'
            label={`${t('Reports.Ticket')} ${t('Common_Data.Type')}`}
            type='select'
            mode='multiple'
            allowClear
            showArrow
            showSearch={false}
            placeholder={`${t('PeopleManagement.All')}`}
            tooltip={`${t('Reports.Ticket_Type_Tooltip')}.`}
            onChange={handleChange}
            options={{
              values: TicketsStore.dropdown_ticket_type,
            }}
            rules={vsmTransactionReport.validation.ticket_type}
          />
        </Col>
      )}
      {formDataList.includes('ticket_priority') && (
        <Col span={6}>
          <InputComponent
            name='ticket_priority'
            label={`${t('Reports.Ticket')} ${t('Common_Data.Priority')}`}
            mode='multiple'
            type='select'
            allowClear
            showArrow
            showSearch={false}
            placeholder={`${t('PeopleManagement.All')}`}
            tooltip={`${t('Reports.Priority_Tooltip')}.`}
            onChange={handleChange}
            onFocus={() =>
              isTicketPriority && TicketsStore.getDropdownPriority().then(() => setIsTicketPriority(false))
            }
            notFoundContent={isTicketPriority ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            options={{
              values: TicketsStore.dropdown_priority,
              value_key: 'id',
              text_key: 'priority',
            }}
            rules={vsmTransactionReport.validation.priority}
          />
        </Col>
      )}
      {formDataList.includes(`ticket_subject`) && (
        <Col span={24}>
          <InputComponent
            name={`ticket_subject`}
            label={t('Common_Data.Subject')}
            placeholder={t('Reports.Subject_Placeholder')}
            type='textarea'
            tooltip={t('Common_Data.Subject')}
            onChange={handleChange}
          />
        </Col>
      )}
      <OrganisationElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
      <PersonElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />

      <ParentOrgRowElements
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
       
      <NaceCategoryElement
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />

      <TimesTrackElement
        form={form}
        handleChange={handleChange}
        formDataList={formDataList}
      />
    </>
  );
};

export default TicketReportCriteria;
