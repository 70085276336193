import React, { useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import { WarningFilled } from '@ant-design/icons';
import { vsmNotify, vsmReminders } from '../../../config/messages';
import useStore from '../../../store';
// import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DeleteComponent = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const {
    SEARCHSTORE,
    REMINDERSSTORE,
    REMINDERSSTORE: { deleteValues, deleteReminderData },
    DEALDESKSTORE: { deal_data, setDealData },
  } = useStore();
  // let location = useLocation();

  const close = () => {
    props.close();
    form.resetFields();
    setSaving(false);
  };
  // Make function call to delete existing record
  const handleSubmit = () => {
    setSaving(true);
    deleteReminderData(deleteValues)
      .then(() => {
        form.resetFields();
        close();

        if (!props?.SearchPage) {
          if (REMINDERSSTORE.agGrid) {
            REMINDERSSTORE.setupGrid(REMINDERSSTORE.agGrid);
          }
        } else {
          REMINDERSSTORE.agGrid.api.applyTransaction({ remove: [REMINDERSSTORE.deleteValues] });
          SEARCHSTORE.updateSearchCount({ reminder_count: SEARCHSTORE.searchCount.reminder_count - 1 });
        }

        if (props?.AllSOLDReminder && deal_data) {
          let tempDealdata = deal_data;
          tempDealdata.total_reminder_count = deal_data?.total_reminder_count
            ? deal_data.total_reminder_count - 1
            : 0;
          setDealData(tempDealdata);
        }

        // if (props?.SearchPage) {
        //   if (location.pathname.search('/Reminders')) {
        //     SEARCHSTORE.setSearchCount({ search_for: SEARCHSTORE.searchTerm },'reminder');
        //   }
        // }

        vsmNotify.success({
          message: vsmReminders.delete,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  return (
    <Modal
      centered
      title={`${t('Common_Data.Delete')} ${t('Common_Data.Reminder')} - ${deleteValues?.id}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='deleteformReminders'
          loading={saving}
          htmlType='submit'
          type='primary'
          shape='round'
          danger
        >
          {t('Common_Data.Delete')}
        </Button>,
        <Button key='2' htmlType='button' shape='round' onClick={close}>
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='deleteformReminders' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={4} className='DeleteIcon'>
              <WarningFilled />
            </Col>
            <Col span={20} className='DeleteText'>
              {t('Reminders.Remove_Reminder')}?
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  );
});

export default DeleteComponent;
