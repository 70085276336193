import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Column } from '@ant-design/charts';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const ChartComponent = observer((props) => {
  const { t } = useTranslation();
  const {
    MANAGEMENTALLDEPARTMENTSSTORE: { graphData, fetchGraphData, totalActiveUsers },
  } = useStore();

  let themecolor = localStorage.getItem('app_color_theme');
  if (themecolor && themecolor !== 'undefined') {
  } else {
    themecolor = 'gray';
  }

  let data = graphData && graphData.length > 0 ? graphData : [];
  const config = {
    data,
    width: 500,
    height: 100,
    xField: 'person',
    yField: 'value',
    color: themecolor,
    point: {
      size: 10,
    },
    label: {
      position: 'middle',
      style: {
        fontSize: 16,
        fill: '#FFFFFF',
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        rotate: `${graphData?.length > 8 ? -45 : 0}`,
        position: 'middle',
        offset: '40',
        style: {
          fontSize: 12,
          fill: '#000',
        },
      },
    },

    meta: {
      person: { alias: 'Person' },
      value: { alias: 'Tickets' },
    },
  };

  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData]);

  const getTotalCount = () => {
    let count = 0;
    graphData?.map((obj) => {
      count += Number(obj.value);
      return null;
    });
    return count;
  };

  return (
    <div className='chartBox'>
      <div className='chartTitle'>
        {/* <strong>All Open Tickets</strong> */}
        <span>{t('Common_Data.Total_Open_Tickets')}:</span> {getTotalCount()} | <span>{t('Common_Data.Total')} {t('ManageAllDepartments.Users')}:</span>{' '}
        {totalActiveUsers}
        {/* {graphData && graphData.length} */}
      </div>
      <Column {...config} />
    </div>
  );
});
export default ChartComponent;
