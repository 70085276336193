import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Modal, Col, Row } from 'antd';
import { WarningFilled } from '@ant-design/icons';

const ConfirmDraftNoteEdit = observer(
  ({ visible, setDraftNoteEditConfirmModal, setEditNoteData }) => {
    const [saving, setSaving] = useState(false);

    const [form] = Form.useForm();

    const close = () => {
      setSaving(false);
      setDraftNoteEditConfirmModal(false);
    };

    const handleSubmit = () => {
      setSaving(true);
      setEditNoteData(true);
      close();
    };
    const onCancel = () => {
      setSaving(true);
      setEditNoteData();
      close();
    };

    return (
      <Modal
        centered
        title={`Edit Draft Note`}
        visible={visible}
        destroyOnClose
        onCancel={() => close()}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            key='1'
            onClick={handleSubmit}
            type='primary'
            shape='round'
            loading={saving}
          >
            Load Draft
          </Button>,
          <Button key='2' htmlType='button' shape='round' onClick={onCancel}>
            Load Original
          </Button>,
        ]}
      >
        <Form form={form} id='draftnoteEditConfirmForm' onFinish={handleSubmit}>
          {
            <Row align='middle'>
              <Col span={4} className='DeleteIcon'>
                <WarningFilled />
              </Col>
              <Col span={20} className='DeleteText'>
                There is draft version available for this note text, Do you want to load it?
              </Col>
            </Row>
          }
        </Form>
      </Modal>
    );
  }
);

export default ConfirmDraftNoteEdit;
