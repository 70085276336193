import React, { useState } from 'react';
import { PageHeader, Form, Space, Button } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import { observer } from 'mobx-react';
import useStore from '../../store';

// import TrackTimeComponent from '../../component/TrackTimeComponent';
import Unauthorized from '../Unauthorized';
import ListComponent from './component/ListComponent';
import ViewComponent from './component/ViewComponent';
import EnterFullscreen from '../../component/EnterFullscreen';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../component/TimeTracker';
//import RecordPerPage from '../../config/RecordPerPage';

const Sequencer = observer((props) => {
  const [viewDrawer, setViewDrawer] = useState(false);
  const [sequenceTitle, setSequenceTitle] = useState(null);
  const { t } = useTranslation();
  const {
    SETTINGS,
    AUTH,
    SEQUENCERSTORE,
    SEQUENCERSTORE: { setViewValues, readSequencerDetails },
  } = useStore();

  const [form] = Form.useForm();

  const openViewDrawer = (data) => {
    setViewDrawer(true);
    setSequenceTitle(data?.sequence);
    readSequencerDetails(data.id).then(() => { });
  };
  const closeViewDrawer = () => {
    setViewDrawer(false);
    setViewValues(null);
    setSequenceTitle(null);
  };

  return (
    <>
      {!AUTH.checkPermission(14, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={`${t('Common_Data.Applications')}`}
          className={'page-title ' + SETTINGS.fullscreen_class}
          extra={[
            <Form form={form} className='innerForm headerButton'>
              <Space size={5}>
                <Button
                  key='1'
                  shape='round'
                  size='medium'
                  title={t('Common_Data.Reset')}
                  onClick={SEQUENCERSTORE.handleReset}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <EnterFullscreen key='2' />
                {/* <RecordPerPage
                  key='3'
                  initialValue={SEQUENCERSTORE.per_page + ' per page'}
                  onChange={SEQUENCERSTORE.setPageSize}
                /> */}
              </Space>
            </Form>,
          ]}
        >
          <ListComponent openViewDrawer={openViewDrawer} />
          <ViewComponent
            visible={viewDrawer}
            close={closeViewDrawer}
            sequenceTitle={sequenceTitle}
          />
            {/* <TrackTimeComponent pageName={'sequencer'} /> */}
            <TimeTracker />
        </PageHeader>
      )}
    </>
  );
});

export default Sequencer;
