import React, { useEffect, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { DateComparator } from '../../../utils/GlobalFunction';
import LocalGridConfig from '../../../config/LocalGridConfig';
import { vsmCommon } from '../../../config/messages';
import useStore from '../../../store';
import moment from 'moment';

const KeyNotesSearchListing = observer((props) => {
  const { t } = useTranslation();
  const {
    openEditDrawer,
    openDeleteDrawer,
    /* CommonFieldTitle, */
    /* saveType, */
    // viewScheduleLibraryDrawer,
  } = props;

  const {
    AUTH,
    KeyNotesSearchStore,
    KeyNotesSearchStore: { loader, listData, },
    SEARCHSTORE: { searchCount, setResultCount },
  } = useStore();

  useEffect(() => {
    setResultCount(searchCount?.Key_note_count);
    KeyNotesSearchStore.getList(KeyNotesSearchStore.search_payload);
  }, [KeyNotesSearchStore, setResultCount, searchCount?.Key_note_count]);

  const ActionRenderer = (params) => {
    return (
      <div className='action-column'>

        <Button
          type='text'
          title={`${t('Common_Data.Edit')}`}
          onClick={() => openEditDrawer(params.data)}
        >
          <img
            alt={`${t('Common_Data.Edit')}`}
            className='menuicon'
            src={AUTH.GetThemedImage('Edit')}
          />
        </Button>

        {AUTH.checkPermission(54, 'lead-sss-delete') ? (
          <Button
            type='text'
            title={`${t('Common_Data.Delete')}`}
            onClick={() => openDeleteDrawer(params.data)}
          >
            <img
              alt={`${t('Common_Data.Delete')}`}
              className='menuicon'
              src={AUTH.GetThemedImage('Waste')}
            />
          </Button>
        ) : null}
      </div>
    );
  };

  const gridRef = useRef();

  const onBtShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, []);

  const hideOverlayFunction = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  useEffect(() => {
    if (loader) {
      onBtShowLoading();
    } else {
      hideOverlayFunction();
    }
    return () => { };
  }, [loader, onBtShowLoading, hideOverlayFunction]);

  const gridOptions = {
    columnDefs: [
      {
        headerName: '#',
        valueGetter: function (params) {
          return params.node.rowIndex + 1;
        },
        tooltipValueGetter: (params) => {
          return params.node.rowIndex + 1;
        },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        minWidth: 37,
        width: 37,
      },
      {
        headerName: `${t('Common_Data.Created')}`,
        field: 'created_at',
        cellClass: 'cellClass',
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        filterParams: {
          suppressAndOrCondition: true, buttons: ['apply', 'reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.created_at
            ? moment(params.data.created_at).format(AUTH.global_fulldate_format)
            : '';
        },

      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        field: 'created_by_name',
        filter: 'agSetColumnFilter',
        filterParams: {
          suppressAndOrCondition: true, buttons: ['apply', 'reset'],
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('DealDesk.Key_Notes')}`,
        field: 'key_note',
        filter: 'agTextColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      // {
      //     headerName: `${t('DealDesk.Key')} ${t('Common_Data.Subject')}`,
      //     field: 'key_subject',
      //     filter: 'agTextColumnFilter',
      //     filterParams: {
      //         suppressAndOrCondition: true,
      //     },
      //     floatingFilter: true,
      // },
      {
        headerName: `#${t('Common_Data.ID')}`,
        field: 'id',

        maxWidth: 100,
        // lockPosition: true,
        // suppressNavigable: true
        filter: 'agNumberColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        floatingFilter: true,
      },
      {
        headerName: `${t('Common_Data.Actions')}`,
        field: 'actions',
        cellClass: 'cellClass actionColumn',
        type: 'actionColumn',
        // maxWidth: 120,
        width: 74,
        filter: false,
        sortable: false,
        pinned: 'right',
        // lockPosition: true,
        // suppressNavigable: true
      },
    ],
  };

  // const getGridTypeBasedOnSaveType = () => {
  //   return 'library_grid';
  // };

  // const getGridStoreTypeBasedOnSaveType = () => {
  //   return setupGrid;
  // };

  let columns = [];
  let columnConfig = localStorage.getItem('search_Keynote_grid');
  if (columnConfig) {
    let data = JSON.parse(columnConfig);
    let cols = gridOptions.columnDefs.find((x) => !x.field);
    if (cols) {
      columns.push(cols);
    }
    data &&
      data.forEach((element) => {
        cols = gridOptions.columnDefs.find((x) => x.field === element.colId);
        if (cols) {
          columns.push(cols);
        }
      });
  } else {
    columns = gridOptions.columnDefs;
  }


  // to show tooltip on column header and column values
  var withTooltipCols = columns.map((item) => {
    if (!['description'].includes(item.field)) {
      item.headerTooltip = item.headerName;
      item.tooltipField = item.field;
    }

    return item;
  });

  return (
    <div
      className={`ag-theme-alpine grid_wrapper paginationAtTop`}
      style={{
        height: 'calc(100vh - 13rem)',
      }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={listData}
        modules={AllModules}
        columnDefs={withTooltipCols}
        defaultColDef={{
          ...LocalGridConfig.defaultColDef,
          width: 120,
          minWidth: 70,
          flex: 1,
        }}
        columnTypes={LocalGridConfig.columnTypes}
        overlayNoRowsTemplate={vsmCommon.noRecord}
        frameworkComponents={{
          ActionRenderer,
          //   historyRenderer: HistoryRender,
        }}
        // onGridReady={getGridStoreTypeBasedOnSaveType()}
        onGridReady={KeyNotesSearchStore.setupGridSearch}
        onColumnResized={KeyNotesSearchStore.onGridChangedSearch}
        onColumnMoved={KeyNotesSearchStore.onGridChangedSearch}
        onColumnPinned={KeyNotesSearchStore.onGridChangedSearch}
        onSortChanged={(params) => {
          if (localStorage.getItem('params')) {
            var temp = JSON.parse(localStorage.getItem('params'));
            localStorage.setItem('params', JSON.stringify({ ...temp, KeyNotesSearchStore: { sort: params.api.getSortModel() }, }));
          } else {
            localStorage.setItem('params',
              JSON.stringify({ KeyNotesSearchStore: { sort: params.api.getSortModel() }, })
            );
          }
        }}

        gridOptions={{
          ...LocalGridConfig.options,
          pagination: true,
          rowHeight: 30,
        }}
      />
    </div>
  );
});

export default KeyNotesSearchListing;
