import { Checkbox, Col, Form, Row, Spin } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../../component/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';
import { reportForTypeNew } from '../../../../utils/GlobalFunction';

const ReportForComparisonModeElement = observer(
    ({ onChange, formDataList, form, handleReportForChange, resetFieldsReportFor, enableComparisonTime }) => {
        const { REPORTSTORE } = useStore();
        const { t } = useTranslation();
        const [fetchFinanceYear, setfetchFinanceYear] = useState(true);
        const getDisabledButton = (index) => {
            let formValue = form.getFieldValue('report_for_comparison_mode');
            if (index === 2) {
                if (formValue[2]["enable_radio_button"]) {
                    return false;
                }
                else {
                    return true;
                }
            }
            if (index === 3) {
                if (formValue[3]["enable_radio_button"]) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }


        return (
            <Form.List name='report_for_comparison_mode' >
                {
                    (fields, { add, remove }) => {
                        return fields.map((field, index) => {
                            let formValue = form.getFieldValue('report_for_comparison_mode')[index];

                            return (
                                <>
                                    <Row gutter={12}>
                                        <Col span={14}>
                                            <InputComponent
                                                classNameInput={'inlineRadioBtn'}
                                                label={
                                                    <div className='labelWithCheckbox'>
                                                        {
                                                            <Form.Item
                                                                name={[field.name, 'enable_radio_button']}
                                                                valuePropName='checked' className='ml-5'>
                                                                <Checkbox
                                                                    onChange={(e) => {
                                                                        onChange();
                                                                        enableComparisonTime(e, index)
                                                                    }}
                                                                    disabled={[0, 1].includes(index)}
                                                                >

                                                                </Checkbox>
                                                            </Form.Item>
                                                        }
                                                        &nbsp;&nbsp;&nbsp;
                                                        <span className='title'>
                                                            {t('OrganisationManagement.Range')} #{index + 1} :
                                                        </span>
                                                    </div>
                                                }
                                                disabled={[2, 3].includes(index) ? getDisabledButton(index) : false}
                                                type='radio_button'
                                                name={[field.name, 'report_for']}
                                                key={[field.key, 'report_for']}
                                                options={{
                                                    values: reportForTypeNew,
                                                }}
                                                onChange={() => {
                                                    onChange();
                                                    resetFieldsReportFor(index);
                                                    handleReportForChange(index);
                                                }}
                                            />
                                        </Col>
                                        {formValue['single_date'] !== undefined && (formValue['single_date'] === null || formValue['single_date'] instanceof moment) && (
                                            <Col span={4}>
                                                <InputComponent
                                                    type='date'
                                                    name={[field.name, 'single_date']}
                                                    key={[field.key, 'single_date']}
                                                    placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Date')}`}
                                                    onChange={(e) => {
                                                        onChange();
                                                    }}
                                                    required
                                                    rules={vsmTransactionReport.validation.date}
                                                    tooltip='Select specific date to fetch reports.'
                                                />
                                            </Col>
                                        )}
                                        {formValue['date_range'] !== undefined && (formValue['date_range'] instanceof moment || formValue['date_range'] === null || Array.isArray(formValue['date_range'])) && (
                                            <Col span={8}>
                                                <InputComponent
                                                    type='date_range'
                                                    placeholder={[`${t('DealDesk.Start_Date')}`, `${t('DealDesk.End_Date')}`]}
                                                    name={[field.name, 'date_range']}
                                                    key={[field.key, 'date_range']}
                                                    onChange={(e) => {
                                                        onChange();
                                                    }}
                                                    required
                                                    rules={vsmTransactionReport.validation.date_range}
                                                    tooltip={`${t('Reports.Fetch_Reports')}.`}
                                                // disabledDate={disabledDate}
                                                // onCalendarChange={(val) => SetDateRange(val)}
                                                />
                                            </Col>
                                        )}

                                        {(formValue['period_x_value'] !== undefined ||
                                            formValue['period_x_value_type'] !== undefined) && (
                                                <>
                                                    {formValue['period_x_value'] !== undefined && (
                                                        <Col span={1} style={{ padding: 0 }}>
                                                            <InputComponent
                                                                required
                                                                type='number'
                                                                name={[field.name, 'period_x_value']}
                                                                key={[field.key, 'period_x_value']}
                                                                min={1}
                                                                max={12}
                                                                className='w-100'
                                                                onChange={(e) => {
                                                                    onChange();
                                                                }}
                                                                rules={vsmTransactionReport.validation.period_x_value}
                                                                tooltip={`${t('Reports.Date_Selection')}.`}
                                                            />
                                                        </Col>
                                                    )}
                                                    {formValue['period_x_value_type'] !== undefined && (
                                                        <Col span={8}>
                                                            <InputComponent
                                                                onChange={(e) => {
                                                                    onChange();
                                                                }}
                                                                type='radio_button'
                                                                name={[field.name, 'period_x_value_type']}
                                                                key={[field.key, 'period_x_value_type']}
                                                                options={{
                                                                    className: 'd-flex w-100',
                                                                    values: [
                                                                        { value: 'day', text: `${formValue['period_x_value'] !== undefined ? t('Common_Data.Days') : t('Reports.Day')}` },
                                                                        { value: 'week', text: `${formValue['period_x_value'] !== undefined ? t('Common_Data.Weeks') : t('Common_Data.Week')}` },
                                                                        { value: 'month', text: `${formValue['period_x_value'] !== undefined ? t('Common_Data.Months') : t('Common_Data.Month')}` },
                                                                        { value: 'quarter', text: `${formValue['period_x_value'] !== undefined ? t('Common_Data.Quarters') : t('Common_Data.Quarter')}` },
                                                                        { value: 'year', text: `${formValue['period_x_value'] !== undefined ? t('Common_Data.Years') : t('Reports.Year')}` },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                    )}
                                                </>
                                            )}

                                        {formValue['financial_year'] !== undefined && (
                                            <Col span={5}>
                                                <div className='w400'>
                                                    <InputComponent
                                                        required
                                                        type='select'
                                                        name={[field.name, 'financial_year']}
                                                        key={[field.key, 'financial_year']}
                                                        placeholder={`${t('Reports.Financial')} ${t('Reports.Year')}`}
                                                        onChange={(e) => {
                                                            onChange();
                                                        }}
                                                        onFocus={() =>
                                                            fetchFinanceYear &&
                                                            REPORTSTORE.getFinancialYearList().then(() => setfetchFinanceYear(false))
                                                        }
                                                        notFoundContent={
                                                            fetchFinanceYear ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                                                        }
                                                        options={{
                                                            values: REPORTSTORE.financial_year_list,
                                                        }}
                                                        tooltip={`${t('Reports.Financial_Year_Tooltip')}.`}
                                                        rules={vsmTransactionReport.validation.financial_year}
                                                    />
                                                </div>
                                            </Col>
                                        )}

                                    </Row>
                                </>
                            )
                        })
                    }
                }
            </Form.List >
        );
    }
);

export default ReportForComparisonModeElement;
