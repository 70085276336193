import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
// import NumberFormat from 'react-number-format';
// import { DateComparator } from '../../../../utils/GlobalFunction';
import PreviewDataTableElement from '../elements/PreviewDataTableElement';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { vsmCommon } from '../../../../config/messages';
import {
  DateComparator,
  formatDataBasedOnColumn,
  getSOLDTotalColumns,
  geTicketTotalColumns,
  getDealMakerBonusTotalColumns,
  getTotalColumnSampleWeeklyReport,
  getTotalColumnName,
} from '../../../../utils/GlobalFunction';
import moment from 'moment';
import PerformancePreviewData from './PerformancePreviewData';
import PreviewAuditReportTableElement from '../elements/PreviewAuditReportTableElement';
import { useTranslation } from 'react-i18next';
//import { BgColorsOutlined } from '@ant-design/icons';

const PreviewDataGridComponent = observer((props) => {
  const { t } = useTranslation()
  const {
    SavedReportStore,
    AUTH,
    //  REMINDERSSTORE
  } = useStore();
  let rejectedReason = false;

  if (SavedReportStore?.previewReportValues?.sold_stage?.length > 0) {
    if (SavedReportStore?.previewReportValues?.sold_stage.includes(12)) {
      rejectedReason = true;
    }
  }

  let gridOptions = {
    leads: [
      {
        headerName: `${t('Reports.SR')}#`,
        headerTooltip: `${t('Reports.SR')}#`,
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',
        cellClass: 'cellClass',
        pinned: 'left',
        cellStyle: { 'text-align': 'center' },
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        headerTooltip: `${t('Common_Data.SOLD_Name')}`,
        field: 'sold_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,
        field: 'id',
        cellStyle: { 'text-align': 'center' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Credebt_Classification')}`,
        headerTooltip: `${t('Common_Data.Credebt_Classification')}`,
        field: 'classification_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Type')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Type')}`,
        field: 'lead_record_type',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Parent')} ${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`,
        headerTooltip: `${t('Common_Data.Parent')} ${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`,
        field: 'parent_status_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Stages')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Stages')}`,
        field: 'lead_status',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Rejected')} ${t('Reports.Reason')}`,
        headerTooltip: `${t('Common_Data.Rejected')} ${t('Reports.Reason')}`,
        field: 'rejected_reason',
        tooltipField: 'rejected_reason',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Reports.Product')}/${t('Reports.Service')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Reports.Product')}/${t('Reports.Service')}`,
        field: 'product',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Source')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Source')}`,
        field: 'lead_source',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Reports.Sold')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`,
        headerTooltip: `${t('Reports.Sold')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`,
        field: 'lead_source_details',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Currency')}`,
        headerTooltip: `${t('Common_Data.Currency')}`,

        field: 'currency_code',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Turnover')}`,
        headerTooltip: `${t('Common_Data.Turnover')}`,
        field: 'turn_over',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Financial')} ${t('Common_Data.Value')}`,
        headerTooltip: `${t('Reports.Financial')} ${t('Common_Data.Value')}`,
        field: 'financial_value',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        type: 'rightAligned',
      },
      {
        headerName: `${t('Reports.Rate')}(%)`,
        headerTooltip: `${t('Reports.Rate')}(%)`,
        field: 'rate',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        type: 'rightAligned',
      },
      {
        headerName: `${t('Common_Data.Time_Spent')}`,
        headerTooltip: `${t('Common_Data.Time_Spent')}`,

        field: 'investment_hours',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Deals',
        headerTooltip: 'Deals',

        field: 'deals',
        cellStyle: { 'text-align': 'center' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Meetings',
        headerTooltip: 'Meetings',

        field: 'meetings',
        cellStyle: { 'text-align': 'center' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        headerTooltip: `${t('LeadKanbanView.DeadLine_Date')}`,

        field: 'deadline_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? AUTH.getLocalTimeDealDesk(params.data.deadline_date, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Organisations')}`,
        headerTooltip: `${t('Common_Data.Organisations')}`,

        field: 'organisation_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Organisation Role',
        headerTooltip: 'Organisation Role',
        field: 'organisation_role',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.People')}`,
        headerTooltip: `${t('Common_Data.People')}`,

        field: 'person',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Owner')}`,

        field: 'lead_owner',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,

        field: 'lead_holder',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Conversation')} ${t('Reports.Words')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Conversation')} ${t('Reports.Words')}`,

        field: 'total_conversation_words',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Conversation')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Conversation')}`,

        field: 'total_conversation',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Communication')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Communication')}`,

        field: 'total_communication',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Inbound')} ${t('LeadListing.Notes')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Inbound')} ${t('LeadListing.Notes')}`,

        field: 'total_inbound_notes',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Outbound')} ${t('LeadListing.Notes')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Outbound')} ${t('LeadListing.Notes')}`,

        field: 'total_outbound_notes',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Comments')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Comments')}`,

        field: 'total_comments',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Telephone')}`,
        headerTooltip: `${t('Common_Data.Telephone')}`,
        field: 'lead_phones',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Email')}`,
        headerTooltip: `${t('Common_Data.Email')}`,

        field: 'lead_emails',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Idle')}`,
        headerTooltip: `${t('Reports.Idle')}`,

        field: 'idle',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Reports.Communication')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Reports.Communication')}`,

        field: 'last_communications',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Words')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Words')}`,
        field: 'total_words',
        cellStyle: { 'text-align': 'center' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Note')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Common_Data.Note')}`,
        tooltipField: 'last_note',

        field: 'last_note',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,

        field: 'created_on',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.created_on
            ? AUTH.getLocalTimeDealDesk(params.data.created_on, AUTH.global_dateformat)
            : '';
        },
      },

      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Modified')} ${t('Common_Data.On')}`,

        field: 'modified_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.modified_date
            ? AUTH.getLocalTimeDealDesk(params.data.modified_date, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Sold')} ${t('Reports.Chat')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Common_Data.Sold')} ${t('Reports.Chat')} ${t('Common_Data.On')}`,
        field: 'last_sold_chat_on',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.last_sold_chat_on
            ? AUTH.getLocalTimeDealDesk(
              params.data.last_sold_chat_on,
              AUTH.global_dateformat
            )
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Reports.Conversation')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Reports.Conversation')} ${t('Common_Data.On')}`,

        field: 'last_conversation_on',
        filter: 'agDateColumnFilter',
        cellStyle: { 'text-align': 'center' },

        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.last_conversation_on
            ? AUTH.getLocalTimeDealDesk(
              params.data.last_conversation_on,
              AUTH.global_dateformat
            )
            : '';
        },
      },
    ],
    tags: [
      {
        headerName: `${t('Reports.SR')}#`,
        headerTooltip: `${t('Reports.SR')}#`,

        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',
        cellClass: 'cellClass',
        pinned: 'left',
        cellStyle: { 'text-align': 'center' },
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: `${t('Reports.Tag')} ${t('Common_Data.Number')}`,
        headerTooltip: `${t('Reports.Tag')} ${t('Common_Data.Number')}`,

        field: 'tag_number',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Tag')} ${t('Common_Data.Subject')}`,
        headerTooltip: `${t('Reports.Tag')} ${t('Common_Data.Subject')}`,

        field: 'tag_subject',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Tag')} ${t('Common_Data.Description')}`,
        headerTooltip: `${t('Reports.Tag')} ${t('Common_Data.Description')}`,

        field: 'tag_description',
        cellStyle: { 'text-align': 'left' },
        cellRenderer: (params) => {
          var a = document.createElement('div');
          a.innerHTML = params?.data?.tag_description
            ? params?.data?.tag_description.replace(/<(.|\n)*?>/g, '')
            : '';
          return a;
        },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,

        field: 'sold_id',
        cellStyle: { 'text-align': 'left' },

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Reports.Ticket')} ${t('Common_Data.Number')}`,
        headerTooltip: `${t('Reports.Ticket')} ${t('Common_Data.Number')}`,

        field: 'ticket_number',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        headerTooltip: `${t('Common_Data.SOLD_Name')}`,

        field: 'sold_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Reports.Ticket')} ${t('Common_Data.Subject')}`,
        headerTooltip: `${t('Reports.Ticket')} ${t('Common_Data.Subject')}`,

        field: 'ticket_subject',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Owner')}`,

        field: 'owner',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,

        field: 'holder',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        headerTooltip: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? AUTH.getLocalTimeDealDesk(params.data.deadline_date, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: `${t('Reports.Tag')} ${t('Common_Data.Status')}`,
        headerTooltip: `${t('Reports.Tag')} ${t('Common_Data.Status')}`,

        field: 'tag_status',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        field: 'organisation',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,

        field: 'created_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.created_date
            ? AUTH.getLocalTimeDealDesk(
              params?.data?.created_date,
              AUTH.global_dateformat
            )
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Modified')} ${t('Common_Data.On')}`,
        field: 'modified_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params.data.modified_date
            ? AUTH.getLocalTimeDealDesk(params.data.modified_date, AUTH.global_dateformat)
            : '';
        },
      },
    ],
    tickets: [
      {
        headerName: `${t('Reports.SR')}#`,
        headerTooltip: `${t('Reports.SR')}#`,
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',

        cellStyle: { 'text-align': 'center' },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: `${t('Reports.Ticket')} ${t('Common_Data.Number')}`,
        headerTooltip: `${t('Reports.Ticket')} ${t('Common_Data.Number')}`,
        field: 'ticket_number',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Ticket')} ${t('Common_Data.Subject')}`,
        headerTooltip: `${t('Reports.Ticket')} ${t('Common_Data.Subject')}`,
        field: 'ticket_subject',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        headerTooltip: `${t('Common_Data.Description')}`,
        field: 'ticket_description',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Ticket')} ${t('Common_Data.Type')}`,
        headerTooltip: `${t('Reports.Ticket')} ${t('Common_Data.Type')}`,

        field: 'ticket_type',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Reports.Ticket')} ${t('Common_Data.Status')}`,
        headerTooltip: `${t('Reports.Ticket')} ${t('Common_Data.Status')}`,
        field: 'ticket_status',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Priority')}`,
        headerTooltip: `${t('Common_Data.Priority')}`,
        field: 'priority',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        headerTooltip: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? AUTH.getLocalTimeDealDesk(params.data.deadline_date, AUTH.global_dateformat)
            : '';
        },
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        headerTooltip: `${t('Common_Data.Organisation')}`,
        field: 'organisation_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Owner')}`,
        field: 'owner',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,
        field: 'holder',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Note')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Common_Data.Note')}`,
        field: 'last_note',
        cellStyle: { 'text-align': 'left' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('LeadListing.Notes')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('LeadListing.Notes')}`,
        field: 'total_notes',
        cellStyle: { 'text-align': 'center' },
        filter: 'agTextColumnFilter',
      },
      // {
      //   headerName: 'Master Ticket',
      //   field: 'master_ticket',
      //   filter: 'agTextColumnFilter',
      // },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,

        cellStyle: { 'text-align': 'center' },
        field: 'created_date',
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params?.data?.created_date
            ? AUTH.getLocalTimeDealDesk(
              params?.data?.created_date,
              AUTH.global_dateformat
            )
            : '';
        },
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Modified')} ${t('Common_Data.On')}`,
        field: 'modified_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data?.modified_date
            ? moment(params.data.modified_date).format(AUTH.global_dateformat)
            : '';
        },
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Note')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Common_Data.Note')} ${t('Common_Data.On')}`,
        field: 'last_note_on',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.last_note_on
            ? moment(params.data.last_note_on).format(AUTH.global_dateformat)
            : '';
        },
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
      },
      {
        headerName: `${t('Reports.Idle')} ${t('Reports.Since')} #${t('Common_Data.Days')}`,
        headerTooltip: `${t('Reports.Idle')} ${t('Reports.Since')} #${t('Common_Data.Days')}`,
        field: 'idle_day',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
    ],
    users: [
      {
        headerName: `${t('Reports.SR')}#`,
        headerTooltip: `${t('Reports.SR')}#`,
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',

        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: `${t('Common_Data.Full')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Full')} ${t('Common_Data.Name')}`,
        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Email')} ${t('Common_Data.Id')}`,
        headerTooltip: `${t('Common_Data.Email')} ${t('Common_Data.Id')}`,
        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Common_Data.Time_Spent')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Common_Data.Time_Spent')}`,
        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('LeadListing.Deals')} ${t('Reports.Target')}`,
        headerTooltip: `${t('LeadListing.Deals')} ${t('Reports.Target')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Days')} ${t('Reports.Allocated')}`,
        headerTooltip: `${t('Common_Data.Days')} ${t('Reports.Allocated')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Closed')} ${t('LeadListing.Deals')}`,
        headerTooltip: `${t('Reports.Closed')} ${t('LeadListing.Deals')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Remaining')} ${t('LeadListing.Deals')}`,
        headerTooltip: `${t('Common_Data.Remaining')} ${t('LeadListing.Deals')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Days')} ${t('Reports.Left')}`,
        headerTooltip: `${t('Common_Data.Days')} ${t('Reports.Left')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('LeadListing.Leads')} ${t('Reports.Count')}`,
        headerTooltip: `${t('LeadListing.Leads')} ${t('Reports.Count')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Tags')} ${t('Reports.Count')}`,
        headerTooltip: `${t('Common_Data.Tags')} ${t('Reports.Count')}`,
        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Tickets')} ${t('Reports.Count')}`,
        headerTooltip: `${t('Common_Data.Tickets')} ${t('Reports.Count')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Contacts')} ${t('Reports.Count')}`,
        headerTooltip: `${t('Reports.Contacts')} ${t('Reports.Count')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Reminders')} ${t('Reports.Count')}`,
        headerTooltip: `${t('Common_Data.Reminders')} ${t('Reports.Count')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
    ],
    reminders: [
      {
        headerName: `${t('Reports.SR')}#`,
        headerTooltip: `${t('Reports.SR')}#`,
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',

        cellStyle: { 'text-align': 'center' },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 90,
      },
      {
        headerName: `${t('Common_Data.Reminder')} ${t('Common_Data.Type')}`,
        headerTooltip: `${t('Common_Data.Reminder')} ${t('Common_Data.Type')}`,
        field: 'reminder_type',
        cellClass: 'cellClass',
        cellStyle: { 'text-align': 'left' },
        // cellRenderer: (params) => {
        //   return params?.data?.reminder_type
        //     ? REMINDERSSTORE.remindersTypeValue[params?.data?.reminder_type]
        //     : '';
        // },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Reminder')} ${t('Reports.For')}`,
        headerTooltip: `${t('Common_Data.Reminder')} ${t('Reports.For')}`,

        field: 'reminder_for_name',
        cellStyle: { 'text-align': 'left' },
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Reports.Assigned')} ${t('Reports.To')}`,
        headerTooltip: `${t('Reports.Assigned')} ${t('Reports.To')}`,
        field: 'receiver_id',
        cellStyle: { 'text-align': 'left' },
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },

      {
        headerName: `${t('Common_Data.Priority')}`,
        headerTooltip: `${t('Common_Data.Priority')}`,
        field: 'priority',
        cellStyle: { 'text-align': 'left' },
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Reports.Due')} ${t('Common_Data.Date')}`,
        headerTooltip: `${t('Reports.Due')} ${t('Common_Data.Date')}`,
        field: 'due_date_time',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.due_date_time
            ? AUTH.getLocalTime(params?.data?.due_date_time)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Status')}`,
        headerTooltip: `${t('Common_Data.Status')}`,
        field: 'status',
        cellStyle: { 'text-align': 'left' },
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Subject')}`,
        headerTooltip: `${t('Common_Data.Subject')}`,
        field: 'subject',
        cellStyle: { 'text-align': 'left' },
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Common_Data.Description')}`,
        headerTooltip: `${t('Common_Data.Description')}`,
        field: 'description',
        cellStyle: { 'text-align': 'left' },
        cellClass: 'cellClass',
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,

        field: 'created_on',
        cellStyle: { 'text-align': 'center' },
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.created_on
            ? AUTH.getLocalTimeDealDesk(params?.data?.created_on, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.By')}`,
        field: 'created_by_name',
        cellStyle: { 'text-align': 'left' },
        cellClass: 'cellClass',
        filter: 'agSetColumnFilter',
      },
    ],
    promotion_bonus: [
      {
        headerName: `${t('Common_Data.User')}`,
        headerTooltip: `${t('Common_Data.User')}`,

        field: 'id',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('LeadListing.Leads')}`,
        headerTooltip: `${t('LeadListing.Leads')}`,
        field: 'leads',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Calls')}`,
        headerTooltip: `${t('Reports.Calls')}`,
        field: 'calls',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Modified')}`,
        headerTooltip: `${t('Common_Data.Modified')}`,

        field: 'last_modified_date',
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.last_modified_date
            ? AUTH.getLocalTime(params?.data?.last_modified_date, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Rejected')}`,
        headerTooltip: `${t('Common_Data.Rejected')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Failed')}`,
        headerTooltip: `${t('Common_Data.Failed')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Future')}`,
        headerTooltip: `${t('Common_Data.Future')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Prospect')}`,
        headerTooltip: `${t('Reports.Prospect')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Results')}`,
        headerTooltip: `${t('Reports.Results')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Meetings')}`,
        headerTooltip: `${t('Reports.Meetings')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Converted')}`,
        headerTooltip: `${t('Reports.Converted')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.RPA')}`,
        headerTooltip: `${t('Common_Data.RPA')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Submitted')}`,
        headerTooltip: `${t('Reports.Submitted')}`,
        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Confirmed')}`,
        headerTooltip: `${t('Reports.Confirmed')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Traded')}`,
        headerTooltip: `${t('Reports.Traded')}`,

        field: 'exchange_ref_id',
        filter: 'agTextColumnFilter',
      },
    ],
    deal_maker_bonus: [
      {
        headerName: `${t('Reports.SR')}#`,
        headerTooltip: `${t('Reports.SR')}#`,
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',

        cellStyle: { 'text-align': 'center' },
        cellClass: 'cellClass',
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,
        field: 'id',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Owner')}`,
        field: 'opportunity_owner',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        headerTooltip: `${t('Common_Data.SOLD_Name')}`,
        field: 'opportunity_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        headerTooltip: `${t('Common_Data.Organisation')}`,
        field: 'organisation_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.First')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.First')} ${t('Common_Data.Name')}`,
        field: 'first_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Name')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Common_Data.Name')}`,
        field: 'last_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`,

        field: 'stage',
        cellStyle: { 'text-align': 'left' },

        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Reports.Amount')}`,
        headerTooltip: `${t('Reports.Amount')}`,

        field: 'amount',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Probability')}(%)`,
        headerTooltip: `${t('Reports.Probability')}(%)`,

        field: 'probability',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Screen')} ${t('Common_Data.Counts')}`,
        headerTooltip: `${t('Common_Data.Screen')} ${t('Common_Data.Counts')}`,

        field: 'leads_screen_count',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Structure')} ${t('Common_Data.Counts')}`,
        headerTooltip: `${t('Common_Data.Structure')} ${t('Common_Data.Counts')}`,

        field: 'leads_structure_count',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Schedule')} ${t('Common_Data.Counts')}`,
        headerTooltip: `${t('Common_Data.Schedule')} ${t('Common_Data.Counts')}`,

        field: 'leads_schedule_count',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Deal')} ${t('Reports.Sheet')} ${t('Common_Data.Counts')}`,
        headerTooltip: `${t('Common_Data.Deal')} ${t('Reports.Sheet')} ${t('Common_Data.Counts')}`,

        field: 'leads_issued_rpas_count',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        headerTooltip: `${t('LeadKanbanView.DeadLine_Date')}`,

        field: 'deadline_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? AUTH.getLocalTimeDealDesk(params.data.deadline_date, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Common_Data.Modified')} ${t('Common_Data.Date')}`,

        field: 'last_modified_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.last_modified_date
            ? AUTH.getLocalTimeDealDesk(
              params?.data?.last_modified_date,
              AUTH.global_dateformat
            )
            : '';
        },
      },
    ],
    sample_weekly: [
      {
        headerName: `${t('Reports.SR')}#`,
        headerTooltip: `${t('Reports.SR')}#`,
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',

        cellClass: 'cellClass',
        cellStyle: { 'text-align': 'center' },
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,
        field: 'id',
        cellStyle: { 'text-align': 'center' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Owner')}`,
        field: 'lead_owner',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,
        field: 'lead_holder',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Organisation')}`,
        headerTooltip: `${t('Common_Data.Organisation')}`,
        field: 'organisation_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Person')}`,
        headerTooltip: `${t('Common_Data.Person')}`,
        field: 'person',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Reports.Product')} / ${t('Reports.Service')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Reports.Product')} / ${t('Reports.Service')}`,
        field: 'product_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        headerTooltip: `${t('LeadKanbanView.DeadLine_Date')}`,
        field: 'deadline_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? AUTH.getLocalTimeDealDesk(params.data.deadline_date, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Time_Spent')}`,
        headerTooltip: `${t('Common_Data.Time_Spent')}`,
        field: 'investment_hours',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Type')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Type')}`,

        field: 'lead_record_type',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Parent')} ${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`,
        headerTooltip: `${t('Common_Data.Parent')} ${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`,

        field: 'parent_status_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Stages')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Stages')}`,

        field: 'lead_status',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Reports.Financial')} ${t('Common_Data.Value')}`,
        headerTooltip: `${t('Reports.Financial')} ${t('Common_Data.Value')}`,
        field: 'financial_value',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Rate')}(%)`,
        headerTooltip: `${t('Reports.Rate')}(%)`,
        field: 'rate',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Modified Date',
        headerTooltip: 'Modified Date',
        field: 'modified_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',

        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.modified_date
            ? AUTH.getLocalTimeDealDesk(
              params?.data?.modified_date,
              AUTH.global_dateformat
            )
            : '';
        },
      },
    ],
    related_items: [
      {
        headerName: 'SR#',
        headerTooltip: 'SR#',
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },
        field: 'sr',

        cellClass: 'cellClass',
        cellStyle: { 'text-align': 'center' },
        pinned: 'left',
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: 'Relation Type',
        headerTooltip: 'Relation Type',
        field: 'relation_type',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SOLD Name',
        headerTooltip: 'SOLD Name',
        field: 'sold_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'SOLD ID',
        headerTooltip: 'SOLD ID',
        field: 'sold_id',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Ticket Subject',
        headerTooltip: 'Ticket Subject',

        field: 'ticket_subject',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Ticket Number',
        headerTooltip: 'Ticket Number',

        field: 'ticket_number',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Organisation',
        headerTooltip: 'Organisation',

        field: 'organisation',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'People',
        headerTooltip: 'People',

        field: 'people',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Owner',
        headerTooltip: 'Owner',

        field: 'owner',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Holder',
        headerTooltip: 'Holder',
        field: 'holder',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },

      {
        headerName: 'Telephone',
        headerTooltip: 'Telephone',
        field: 'telephone',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },

      {
        headerName: 'Email',
        headerTooltip: 'Email',

        field: 'email',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Address',
        headerTooltip: 'Address',

        field: 'address',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Registration Country',
        headerTooltip: 'Country',

        field: 'registration_country',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Credebt® Classification',
        headerTooltip: 'Credebt® Classification',
        field: 'classification',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'SOLD Type',
        headerTooltip: 'SOLD Type',
        field: 'sold_type',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Parent SOLD Stage',
        headerTooltip: 'Parent SOLD Stage',
        field: 'parent_sold_stage',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'SOLD Stage',
        headerTooltip: 'SOLD Stage',
        field: 'sold_stage',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },

      {
        headerName: 'SOLD Product/Service',
        headerTooltip: 'SOLD Product/Service',
        field: 'sold_product',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Ticket Type',
        headerTooltip: 'Ticket Type',

        field: 'ticket_type',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Ticket Status',
        headerTooltip: 'Ticket Status',
        field: 'ticket_status',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Organisation Type',
        headerTooltip: 'Organisation Type',
        field: 'organisation_type',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Currency',
        headerTooltip: 'Currency',

        field: 'currency_code',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'SOLD Source',
        headerTooltip: 'SOLD Source',
        field: 'sold_source',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'SOLD Source Details',
        headerTooltip: 'SOLD Source Details',
        field: 'sold_source_details',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Turnover',
        headerTooltip: 'Turnover',
        field: 'turnover',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Financial Value',
        headerTooltip: 'Financial Value',
        field: 'financial_value',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        type: 'rightAligned',
      },
      {
        headerName: 'Rate(%)',
        headerTooltip: 'Rate(%)',
        field: 'rate',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        type: 'rightAligned',
      },
      {
        headerName: 'Time Spent',
        headerTooltip: 'Time Spent',

        field: 'investment_hours',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Deadline Date',
        headerTooltip: 'Deadline Date',

        field: 'deadline_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? AUTH.getLocalTimeDealDesk(params.data.deadline_date, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: 'Ticket Description',
        headerTooltip: 'Ticket Description',
        field: 'ticket_description',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Idle',
        headerTooltip: 'Idle',
        field: 'idle',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Last Communication',
        headerTooltip: 'Last Communication',

        field: 'last_communication',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Created On',
        headerTooltip: 'Created On',

        field: 'created_on',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.created_on
            ? AUTH.getLocalTimeDealDesk(params.data.created_on, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: 'Modified On',
        headerTooltip: 'Modified On',

        field: 'modified_on',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.modified_on
            ? AUTH.getLocalTimeDealDesk(params.data.modified_on, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: 'Last SOLD Chat On',
        headerTooltip: 'Last SOLD Chat On',
        field: 'last_sold_chat_on',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.last_sold_chat_on
            ? AUTH.getLocalTimeDealDesk(
              params.data.last_sold_chat_on,
              AUTH.global_dateformat
            )
            : '';
        },
      },
      {
        headerName: 'Last Conversation On',
        headerTooltip: 'Last Conversation On',

        field: 'last_conversation_on',
        filter: 'agDateColumnFilter',
        cellStyle: { 'text-align': 'center' },

        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.last_conversation_on
            ? AUTH.getLocalTimeDealDesk(
              params.data.last_conversation_on,
              AUTH.global_dateformat
            )
            : '';
        },
      },
    ],
  };

  if (rejectedReason) {
    gridOptions['leads'] = [
      {
        headerName: `${t('Reports.SR')}#`,
        headerTooltip: `${t('Reports.SR')}#`,
        cellRenderer: (params) => {
          return params.node.rowPinned ? 'Total' : params.node.rowIndex + 1;
        },

        cellClass: 'cellClass',
        pinned: 'left',
        field: 'sr',
        cellStyle: { 'text-align': 'center' },
        filter: false,
        sortable: false,
        width: 60,
      },
      {
        headerName: `${t('Common_Data.SOLD_Name')}`,
        headerTooltip: `${t('Common_Data.SOLD_Name')}`,
        field: 'sold_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.ID')}`,
        field: 'id',
        cellStyle: { 'text-align': 'center' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Credebt_Classification')}`,
        headerTooltip: `${t('Common_Data.Credebt_Classification')}`,
        field: 'classification_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Type')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Type')}`,
        field: 'lead_record_type',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Parent')} ${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`,
        headerTooltip: `${t('Common_Data.Parent')} ${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`,
        field: 'parent_status_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Stages')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Stages')}`,
        field: 'lead_status',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Rejected')} ${t('Reports.Reason')}`,
        headerTooltip: `${t('Common_Data.Rejected')} ${t('Reports.Reason')}`,
        field: 'rejected_reason',
        tooltipField: 'rejected_reason',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Rejected')} ${t('Common_Data.By')}`,
        headerTooltip: `${t('Common_Data.Rejected')} ${t('Common_Data.By')}`,
        field: 'rejected_by',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Rejected')} ${t('Common_Data.Date')}`,
        headerTooltip: `${t('Common_Data.Rejected')} ${t('Common_Data.Date')}`,
        field: 'rejected_date',
        filter: 'agDateColumnFilter',
        cellStyle: { 'text-align': 'center' },

        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.rejected_date
            ? AUTH.getLocalTimeDealDesk(
              params?.data?.rejected_date,
              AUTH.global_dateformat
            )
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Note')} ${t('Common_Data.Date')} ${t('Common_Data.By')} ${t('Common_Data.Rejected')} ${t('Common_Data.User')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Common_Data.Note')} ${t('Common_Data.Date')} ${t('Common_Data.By')} ${t('Common_Data.Rejected')} ${t('Common_Data.User')}`,
        field: 'last_note_date_by_rejected_user',
        filter: 'agDateColumnFilter',
        cellStyle: { 'text-align': 'center' },

        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.last_note_date_by_rejected_user
            ? AUTH.getLocalTimeDealDesk(
              params?.data?.last_note_date_by_rejected_user,
              AUTH.global_dateformat
            )
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Note')} ${t('Common_Data.By')} ${t('Common_Data.Rejected')} ${t('Common_Data.User')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Common_Data.Note')} ${t('Common_Data.By')} ${t('Common_Data.Rejected')} ${t('Common_Data.User')}`,
        field: 'last_note_by_rejected_user',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Reports.Product')} / ${t('Reports.Service')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Reports.Product')} / ${t('Reports.Service')}`,
        field: 'product',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Sold')} ${t('Common_Data.Source')}`,
        headerTooltip: `${t('Common_Data.Sold')} ${t('Common_Data.Source')}`,
        field: 'lead_source',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Reports.Sold')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`,
        headerTooltip: `${t('Reports.Sold')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`,
        field: 'lead_source_details',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Currency')}`,
        headerTooltip: `${t('Common_Data.Currency')}`,

        field: 'currency_code',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Turnover')}`,
        headerTooltip: `${t('Common_Data.Turnover')}`,
        field: 'turn_over',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Financial')} ${t('Common_Data.Value')}`,
        headerTooltip: `${t('Reports.Financial')} ${t('Common_Data.Value')}`,
        field: 'financial_value',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        type: 'rightAligned',
      },
      {
        headerName: `${t('Reports.Rate')}(%)`,
        headerTooltip: `${t('Reports.Rate')}(%)`,
        field: 'rate',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        type: 'rightAligned',
      },
      {
        headerName: `${t('Common_Data.Time_Spent')}`,
        headerTooltip: `${t('Common_Data.Time_Spent')}`,

        field: 'investment_hours',
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('LeadKanbanView.DeadLine_Date')}`,
        headerTooltip: `${t('LeadKanbanView.DeadLine_Date')}`,

        field: 'deadline_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        cellRenderer: (params) => {
          return params.data.deadline_date
            ? AUTH.getLocalTimeDealDesk(params.data.deadline_date, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Organisations')}`,
        headerTooltip: `${t('Common_Data.Organisations')}`,

        field: 'organisation_name',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.People')}`,
        headerTooltip: `${t('Common_Data.People')}`,

        field: 'person',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Owner')}`,
        headerTooltip: `${t('Common_Data.Owner')}`,

        field: 'lead_owner',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Holder')}`,
        headerTooltip: `${t('Common_Data.Holder')}`,

        field: 'lead_holder',
        cellStyle: { 'text-align': 'left' },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Conversation')} ${t('Reports.Words')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Conversation')} ${t('Reports.Words')}`,

        field: 'total_conversation_words',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Conversation')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Conversation')}`,

        field: 'total_conversation',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Communication')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Communication')}`,

        field: 'total_communication',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Inbound')} ${t('LeadListing.Notes')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Inbound')} ${t('LeadListing.Notes')}`,

        field: 'total_inbound_notes',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Outbound')} ${t('LeadListing.Notes')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Outbound')} ${t('LeadListing.Notes')}`,

        field: 'total_outbound_notes',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Total')} ${t('Reports.Comments')}`,
        headerTooltip: `${t('Common_Data.Total')} ${t('Reports.Comments')}`,

        field: 'total_comments',
        cellStyle: { 'text-align': 'center' },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Telephone')}`,
        headerTooltip: `${t('Common_Data.Telephone')}`,
        field: 'lead_phones',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Email')}`,
        headerTooltip: `${t('Common_Data.Email')}`,

        field: 'lead_emails',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Reports.Idle')}`,
        headerTooltip: `${t('Reports.Idle')}`,

        field: 'idle',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Reports.Communication')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Reports.Communication')}`,

        field: 'last_communications',
        cellStyle: { 'text-align': 'left' },
        filter: 'agTextColumnFilter',
      },
      {
        headerName: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Created')} ${t('Common_Data.On')}`,

        field: 'created_on',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.created_on
            ? AUTH.getLocalTimeDealDesk(params.data.created_on, AUTH.global_dateformat)
            : '';
        },
      },

      {
        headerName: `${t('Common_Data.Modified')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Modified')} ${t('Common_Data.On')}`,

        field: 'modified_date',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.modified_date
            ? AUTH.getLocalTimeDealDesk(params.data.modified_date, AUTH.global_dateformat)
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Common_Data.Sold')} ${t('Reports.Chat')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Common_Data.Sold')} ${t('Reports.Chat')} ${t('Common_Data.On')}`,
        field: 'last_sold_chat_on',
        cellStyle: { 'text-align': 'center' },
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.last_sold_chat_on
            ? AUTH.getLocalTimeDealDesk(
              params.data.last_sold_chat_on,
              AUTH.global_dateformat
            )
            : '';
        },
      },
      {
        headerName: `${t('Common_Data.Last')} ${t('Reports.Conversation')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Common_Data.Last')} ${t('Reports.Conversation')} ${t('Common_Data.On')}`,

        field: 'last_conversation_on',
        filter: 'agDateColumnFilter',
        cellStyle: { 'text-align': 'center' },

        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.last_conversation_on
            ? AUTH.getLocalTimeDealDesk(
              params.data.last_conversation_on,
              AUTH.global_dateformat
            )
            : '';
        },
      },
    ];
  }
  if (SavedReportStore?.previewReportValues?.waste === true) {
    gridOptions['leads'] = [
      ...gridOptions['leads'],
      {
        headerName: `${t('Reports.Deleted')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Reports.Deleted')} ${t('Common_Data.On')}`,
        field: 'deleted_at',
        filter: 'agDateColumnFilter',
        cellStyle: { 'text-align': 'center' },
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.deleted_at
            ? AUTH.getLocalTimeDealDesk(params.data.deleted_at, AUTH.global_dateformat)
            : '';
        },
      },
    ];
    gridOptions['tags'] = [
      ...gridOptions['tags'],
      {
        headerName: `${t('Reports.Deleted')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Reports.Deleted')} ${t('Common_Data.On')}`,
        field: 'deleted_at',
        filter: 'agDateColumnFilter',
        cellStyle: { 'text-align': 'center' },
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.deleted_at
            ? AUTH.getLocalTimeDealDesk(params.data.deleted_at, AUTH.global_dateformat)
            : '';
        },
      },
    ];
    gridOptions['tickets'] = [
      ...gridOptions['tickets'],
      {
        headerName: `${t('Reports.Deleted')} ${t('Common_Data.On')}`,
        headerTooltip: `${t('Reports.Deleted')} ${t('Common_Data.On')}`,
        field: 'deleted_at',
        filter: 'agDateColumnFilter',
        cellStyle: { 'text-align': 'center' },
        filterParams: {
          buttons: ['reset'],
          inRangeInclusive: true,
          comparator: DateComparator,
        },
        cellRenderer: (params) => {
          return params?.data?.deleted_at
            ? AUTH.getLocalTimeDealDesk(params.data.deleted_at, AUTH.global_dateformat)
            : '';
        },
      },
    ];
  }
  let PinnedData = [];
  if (
    (props.rowData && props?.form?.getFieldValue('report_type') === 'leads') ||
    props?.form?.getFieldValue('report_type') === 'tickets' ||
    props?.form?.getFieldValue('report_type') === 'deal_maker_bonus' ||
    props?.form?.getFieldValue('report_type') === 'sample_weekly'
  ) {
    let soldObj = {};
    let totalColumnList = [];
    let columnOptionArr = null;

    if (props?.form?.getFieldValue('report_type') === 'leads') {
      columnOptionArr = gridOptions['leads'];
      totalColumnList = getSOLDTotalColumns;
    } else if (props?.form?.getFieldValue('report_type') === 'tickets') {
      columnOptionArr = gridOptions['tickets'];
      totalColumnList = geTicketTotalColumns;
    } else if (props?.form?.getFieldValue('report_type') === 'deal_maker_bonus') {
      columnOptionArr = gridOptions['deal_maker_bonus'];
      totalColumnList = getDealMakerBonusTotalColumns;
    } else if (props?.form?.getFieldValue('report_type') === 'sample_weekly') {
      columnOptionArr = gridOptions['sample_weekly'];
      totalColumnList = getTotalColumnSampleWeeklyReport;
    }
    columnOptionArr &&
      columnOptionArr.map((obj) => {
        if (totalColumnList.includes(obj.field)) {
          let total = 0;
          props.rowData &&
            props.rowData.map((x) => {
              total += x[getTotalColumnName[obj.field]];
              return null;
            });
          soldObj[obj.field] = formatDataBasedOnColumn(obj.field, total);
        }

        // if (obj.field === 'sr') {
        //   soldObj[obj.field] = 'Total'
        // }
        return null;
      });
    PinnedData.push(soldObj);
  }

  const report_type = props.form.getFieldValue('report_type')?.replace(/-/g, '_');

  return props.rowData ? (
    <div className='p-reletive'>
      {report_type && report_type === 'performance' ? (
        <PerformancePreviewData rowData={props.rowData} editFilter={props.editFilter} />
      ) : report_type === 'investment_hours' ? (
        <PreviewAuditReportTableElement
          rowData={props.rowData}
          form={props.form}
          editFilter={props.editFilter}
        />
      ) : (
        <PreviewDataTableElement
          rowData={props.rowData}
          modules={AllModules}
          pinnedBottomRowData={
            props.rowData && props.rowData.length > 0 ? PinnedData : []
          }
          gridOptions={gridOptions[report_type]}
          defaultColDef={{ ...LocalGridConfig.defaultColDef, minWidth: 80, flex: 1 }}
          columnTypes={LocalGridConfig.columnTypes}
          overlayNoRowsTemplate={vsmCommon.noRecord}
          form={props.form}
          onGridReady={SavedReportStore.setupGrid}
          onColumnResized={SavedReportStore.onChildnGridChanged}
          onColumnMoved={SavedReportStore.onChildnGridChanged}
          onColumnPinned={SavedReportStore.onChildnGridChanged}
          onSortChanged={SavedReportStore.onChildnGridChanged}
        />
      )}
      {
        props.rowData && props.rowData.length === 1000 && (
          <>
            {AUTH.user?.user_role?.user_role_id === 1 ? (
              <div className='redText noteAtTop text-right'>
                {t('Reports.Maximum_Records_Text_Admin')}
              </div>
            ) : (
              <div className='redText noteAtTop text-right'>
                {t('Reports.Maximum_Records_Text_Normal')}
              </div>
            )}
          </>
        )
      }
    </div >
  ) : null;
});

export default PreviewDataGridComponent;
