import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Card, Table, Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import ViewPeopleComponent from '../../../page/PeopleManagement/People/component/ViewComponent';
import ViewOrgComponent from '../../../page/OrganisationManagement/AllOrganisations/component/ViewComponent';
import { globalStatus } from '../../../utils/GlobalFunction';
import useStore from '../../../store';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
}

const ConnectionsComponent = observer((props) => {
  const {
    AUTH,
    PEOPLE,
    ORGANISATION,
    //DEALDESKSTORE: { deal_data, dealDeskTypeArr },
    CONNECTIONS: { setSelectedconnections },
  } = useStore();
  const { t } = useTranslation()
  const { height } = useWindowDimensions();
  const [viewPeopleDrawer, setViewPeopleDrawer] = useState(false);
  const [viewOrgDrawer, setViewOrgDrawer] = useState(false);

  const selectedRoles = (record, add = false) => {
    let connectionRole = [];
    if (record?.connection_role_name) {
      connectionRole = record?.connection_role_name
        ? record?.connection_role_name?.split('#')
        : [];
    }
    return (
      <ul className='m-0 p-0' style={{ fontSize: '1.2rem', lineHeight: '1.6' }}>
        {add ? (
          <li key='1'>
            <b>{t('Common_Data.Connection')} {t('OrganisationManagement.Roles')}</b>
          </li>
        ) : null}
        {connectionRole?.length ? (
          connectionRole?.map((items, index) => <li key={index}>{items}</li>)
        ) : (
          <li key='1'>{t('ConnectionsModule.Not_Any_Roles_Selected')}</li>
        )}
      </ul>
    );
  };

  const {
    //addConnections,
    sortConnections,
  } = props;

  const SanpNoteTitle = () => {
    return (
      <>
        <img
          src={AUTH.GetThemedImage('Connections_icn')}
          alt={`${t('Common_Data.Connection')}`}
          className='titleIcon'
        />{' '}
        {t('LeadListing.Connections')}
        <span className='TitleValue'>{props.connection_dataCount}</span>
      </>
    );
  };

  const columns = [
    {
      title: 'role_name',
      dataIndex: 'id',
      render: (type, record) => {
        return (
          <span className='cursorPointer'>
            {
              record.connection_role ? record.connection_role : record.role_name
            }
          </span>
        )
      },
    },
    {
      title: 'people_name',
      dataIndex: 'people_name',
      render: (people_name, record) => {
        return (
          <Tooltip
            // placement='leftTop'
            title={
              record?.connection_role_name?.length > 0
                ? selectedRoles(record, true)
                : null
            }
            trigger='hover'
          >
            <span
              className='cursorPointer'
              onClick={() => {
                record && record?.entity_type && record.entity_type === 1
                  ? openOrgDrawer(record)
                  : openPeopleDrawer(record?.entity_id ? record.entity_id : 0);
              }}
            >
              :{' '}
              <u>
                {record.peopleorgname && record.peopleorgname.length > 18
                  ? record.peopleorgname.substring(0, 18) + '...'
                  : record.peopleorgname}
              </u>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const openPeopleDrawer = (data) => {
    PEOPLE.viewPeopleLoading = true;

    PEOPLE.setViewValues(data);
    PEOPLE.getViewPplDetail(data)
      .then((data) => {
        PEOPLE.viewPeopleLoading = false;
      })
      .catch((data) => {
        PEOPLE.viewPeopleLoading = false;
        if (data.status === 404) {
        }
        if (data.status === 403) {
        }
      });
    setViewPeopleDrawer(true);
  };
  const closeViewPeopleDrawer = () => {
    PEOPLE.setViewValues([]);
    setViewPeopleDrawer(false);
  };
  const handleViewDetailCall = debounce((data) => {
    ORGANISATION.viewPeopleLoading = true;
    ORGANISATION.getViewOrgDetail(data?.entity_id)
      .then((data) => {
        if (data) {
          if (data?.cash_transactions && data?.cash_transactions.length) {
            data?.cash_transactions.forEach((item) => {
              item.status_name = globalStatus(
                'cash_transaction_status',
                'key',
                item.status
              );
            });
          }
          ORGANISATION.viewOrgValues = data;
          PEOPLE.list_related_ORG = data?.related_organisations;
          PEOPLE.list_related_Ppl = data?.related_peoples;
          PEOPLE.list_trade_transaction = data?.trade_transactions;
          PEOPLE.list_cash_transaction = data?.cash_transactions;
          PEOPLE.list_banks = data?.banks;
          ORGANISATION.viewPeopleLoading = false;
        }
      })
      .catch((ORGANISATION.viewPeopleLoading = false));
  }, 500);

  const openOrgDrawer = (data) => {
    if (AUTH.checkPermission(16, 'view-details')) {
      ORGANISATION.viewPeopleLoading = true;
      handleViewDetailCall(data);
      setViewOrgDrawer(true);
    }
  };

  const closeViewOrgDrawer = () => {
    PEOPLE.setViewValues([]);
    setViewOrgDrawer(false);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedconnections(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: true,
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  let connectionlist =
    props.connection_data &&
    props.connection_data.slice(0, height && height > 700 ? 5 : 3);

  return (
    <>
      <Card
        size='small'
        title={SanpNoteTitle()}
        className='nopadding'
        extra={
          <PlusCircleOutlined
            className='bar_icon'
            title={`${t('ConnectionsModule.Manage')} ${t('LeadListing.Connections')}`}
            onClick={() => {
              sortConnections();
            }}
          />
        }
      >
        <Table
          rowSelection={{
            type: 'checkbox',
            disabled: true,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={connectionlist}
          showHeader={false}
          size='small'
          className='RigthBar_Table'
          pagination={false}
        />
      </Card>
      <ViewPeopleComponent visible={viewPeopleDrawer} close={closeViewPeopleDrawer} />
      <ViewOrgComponent visible={viewOrgDrawer} close={closeViewOrgDrawer} />
    </>
  );
});

export default ConnectionsComponent;
