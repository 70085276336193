import { Tooltip } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import useStore from "../../../../../store";
import { isDatePassed } from "../../../../../utils/GlobalFunction";

const RightSideTicketDetails = observer((props) => {
  const { t } = useTranslation()
  const { openTicketViewDrawer,// openTicketEditDrawer 
  } = props;
  const {
    AUTH,
    DEALDESKSTORE: {
      deal_data,
    },
    TicketsStore: {
      dropdown_ticket_type,
      // dropdown_priority
    }
  } = useStore();

  const viewSelectedValue = (type) => {
    if (type === "ticket_type") {
      let ticket_type = dropdown_ticket_type.filter(x => x.value === deal_data?.lead_information?.ticket_data?.ticket_type);
      if (ticket_type[0]) {

        return ticket_type[0]["text"];
      }
    }
    // if (type === "ticket_priority") {
    //     let ticket_priority = dropdown_priority.filter(x => x.value === ticketDetails?.priority);
    //     if (ticket_priority[0]) {
    //         return <span className={`${ticket_priority[0]["text"] === "Critical" ? "redText" : ""}`}>{ticket_priority[0]["text"]}</span>;
    //     }
    // }
    return ""
  }
  const ticketDetails = deal_data?.lead_information?.ticket_data ? deal_data?.lead_information?.ticket_data : null;

  // let OwnerIsLoggin = true // deal_data?.lead_information?.lead_owner_name?.id === AUTH?.user?.id
  // let HolderIsLoggin = true // deal_data?.lead_information?.lead_holder_name?.id === AUTH?.user?.id

  var decimalTime = ticketDetails?.hours_required;
  var hours = Math.floor((decimalTime / (60 * 60)));
  decimalTime = decimalTime - (hours * 60 * 60);
  var minutes = Math.floor((decimalTime / 60));

  return (
    <div className="right-side-ticketDetails">
      {ticketDetails ?
        <ul>
          <li>
            <span className='title'>{t('DealDesk.Ticket_Type')}</span>
            <Tooltip
              placement='topLeft'
              title={viewSelectedValue("ticket_type")}
            >
              <span
                onClick={() => {
                  openTicketViewDrawer()
                  // if (OwnerIsLoggin) {
                  //   openTicketEditDrawer(deal_data?.lead_information?.ticket_data, true)
                  // } else if (HolderIsLoggin) {
                  //   openTicketViewDrawer()
                  // }
                }}
              >
                {viewSelectedValue("ticket_type")}
              </span>
            </Tooltip>
          </li>
          <li>
            <span className='title'>{t('DealDesk.Status')}</span>
            <Tooltip
              placement='topLeft'
              title={ticketDetails?.ticket_status ? ticketDetails?.ticket_status.status_name : ""}
            >
              <span
                onClick={() => {
                  openTicketViewDrawer()
                  // if (OwnerIsLoggin) {
                  //   openTicketEditDrawer(deal_data?.lead_information?.ticket_data, true)
                  // } else if (HolderIsLoggin) {
                  //   openTicketViewDrawer()
                  // }
                }}
              >
                {ticketDetails?.ticket_status ? ticketDetails?.ticket_status.status_name : ""}
              </span>
            </Tooltip>
          </li>
          <li>
            <span className='title'>{t('Common_Data.Priority')}</span>
            <Tooltip
              placement='topLeft'
              title={ticketDetails?.ticket_priority
                ? ticketDetails?.ticket_priority.priority
                : ""}
            >
              <span
                onClick={() => {
                  openTicketViewDrawer()
                  // if (OwnerIsLoggin) {
                  //   openTicketEditDrawer(deal_data?.lead_information?.ticket_data, true)
                  // } else if (HolderIsLoggin) {
                  //   openTicketViewDrawer()
                  // }
                }}
              >{ticketDetails?.ticket_priority
                ? ticketDetails?.ticket_priority.priority
                : ""}
                {/* {viewSelectedValue("ticket_priority")} */}
              </span>
            </Tooltip>
          </li>
          <li>
            <span className='title'>{t('DealDesk.Deadline')}</span>
            <Tooltip
              placement='topLeft'
              title={ticketDetails?.deadline_date ? ticketDetails?.deadline_date : ""}
            >
              <span
                onClick={() => {
                  openTicketViewDrawer()
                  // if (OwnerIsLoggin) {
                  //   openTicketEditDrawer(deal_data?.lead_information?.ticket_data, true)
                  // } else if (HolderIsLoggin) {
                  //   openTicketViewDrawer()
                  // }
                }}
                className={`${ticketDetails?.deadline_date ? isDatePassed(new Date(ticketDetails?.deadline_date)) ? "redText" : "" : ""}`}
              >
                {ticketDetails?.deadline_date && AUTH.getLocalTimeDealDesk(ticketDetails?.deadline_date, AUTH.global_fulldate_format)}
              </span>
            </Tooltip>
          </li>
          <li>
            <span className='title'>{t('DealDesk.Hours_Required')}</span>
            <span>{hours}h {minutes}m</span>
          </li>

          <li>
            <span className='title'>{t('DealDesk.Master_Ticket')}</span>
            {ticketDetails?.master_ticket?.master_ticket_number &&

              <Tooltip placement='top' title={ticketDetails?.master_ticket ? ticketDetails?.master_ticket?.master_ticket_number : ""}>
                <span onClick={() => window.open(`/ticket/${ticketDetails?.master_ticket?.master_ticket_number}`, '_blank')}>
                  {ticketDetails?.master_ticket ? ticketDetails?.master_ticket?.master_ticket_number : ""}
                </span>
                <Link to={`/ticket/${ticketDetails?.master_ticket?.master_ticket_number}`} target="_blank">
                  <img
                    className='menuicon'
                    style={{ cursor: 'pointer', display: 'inline-block', width: '16px', margin: '-5px 0 0 5px' }}
                    alt=''
                    src={AUTH.GetThemedImage('Tickets')}
                  />
                </Link>

              </Tooltip>

            }
          </li>


        </ul>
        : null}
    </div >
  )
})

export default RightSideTicketDetails;