import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';


const Unauthorized = (props) => {
  let history = useHistory();
  const {t} =useTranslation()
  return (
    <Result
      style={{ height: props?.height }}
      status='403'
      title='403'
      subTitle={`${t('Common_Page.Not_Allow_Use')}`}
      extra={
        <Button
          type='primary'
          onClick={() => {
            history.goBack();
          }}
        >
          {t('Common_Page.Go_Back')}
        </Button>
      }
    />
  );
};

export default Unauthorized;
