import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import { ageDifferenceInDays, convertTextToID, defaultMyOpenReminders } from '../../utils/GlobalFunction';
import moment from 'moment';
import TranslateMessage from '../../component/TranslateMessages';
import { clear, reset } from '../../utils/ResetClearButton';

export default class RemindersStore {
  list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;

  deleteValues = null;
  editValues = null;
  viewValues = null;
  dropdown_user_list = null;
  search_payload = {};
  gridLoading = false;

  // initialValues = {
  //   phone: [{ id: null, contact_for: 'Primary' }],
  //   email: [{ id: null, contact_for: 'Primary' }]
  // };
  DEFAULT_GRID_FILTER_ID = 99996;
  selectedGridFilter = this.DEFAULT_GRID_FILTER_ID; //Reminder Default Values

  sold_reminderfor_data = null; //-----USED FOR - VIEW ALL REMINDER FROM DEALDESK

  isSavedFiltersLoadedRm = false;

  remindersTypeValueForFilter = [
    { id: 'contacts', name: 'Person' },
    { id: 'organisations', name: 'Organisation' },
    { id: 'leads', name: 'SOLD' },
    { id: 'tickets', name: 'Ticket' },
    { id: 'notes', name: 'Note' },
    { id: 'tags', name: 'Tag' },
    { id: 'private', name: 'Private' },
  ];
  priorityForFilter = [
    { value: '1', text: 'Timed' },
    { value: '2', text: 'Routine' },
    { value: '3', text: 'Normal' },
    { value: '4', text: 'High' },
    { value: '5', text: 'Urgent' },
    { value: '6', text: 'Critical' },
  ];
  statuslistForFilter = [
    // { value: 'Active', text: 'Active' },
    //{ value: 'Approved', text: 'Approved' },
    { value: 'Closed', text: 'Closed' },
    //{ value: 'Document Uploaded', text: 'Document Uploaded' },
    { value: 'Open', text: 'Open' },
    //{ value: 'Pending', text: 'Pending' },
    //{ value: 'Rejected', text: 'Rejected' },
    //{ value: 'New', text: 'New' },
    { value: 'Read', text: 'Read' },
    //{ value: 'Completed', text: 'Completed' },
    //{ value: 'Reset', text: 'Reset' },
  ];

  remindersTypeValue = {
    tags: 'Tag',
    notes: 'Note',
    tickets: 'Tickets',
    leads: 'SOLD',
    contacts: 'Person',
    organisations: 'Organisation',
    private: 'Private',
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let columnConfig = localStorage.getItem('reminder_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    let param = this.getFilter(params);
    if (param && param.request) {
      this.agGrid.api.setFilterModel(param.request.filter);
      this.agGrid.api.setSortModel(param.request.sort);
    }
    var datasource = this.createDatasource(GridConfig.options);
    if (this.isSavedFiltersLoadedRm) {
      api.setServerSideDatasource(datasource);
    } else {
      //--------------TO HOLD GRID SETUP - TILL SAVED FILTER LOADED-------------//
      if (localStorage.getItem('isSavedFiltersFetching')) {
        setTimeout(() => {
          api.setServerSideDatasource(datasource);
        }, 2000);
      }
      //--------------TO HOLD GRID SETUP - TILL SAVED FILTER LOADED-------------//
    }
  };

  setupGridSearch = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('search_reminders_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    if (params && params.request) {
      this.agGrid.api.setFilterModel(params.request.filter);
      this.agGrid.api.setSortModel(params.request.sort);
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'reminder_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  onGridChangedSearch = (params) => {
    localStorage.setItem(
      'search_reminders_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // // reset all the server side filters
  // handleReset = () => {
  //   localStorage.removeItem('reminder_grid');

  //   this.setSelectedGridFilter(this.DEFAULT_GRID_FILTER_ID);
  //   this.agGrid.api.setFilterModel(defaultMyOpenReminders);
  //   this.agGrid.api.setSortModel(null);
  //   this.agGrid.columnApi.resetColumnState();
  //   this.agGrid.api.onFilterChanged(null);
  // };

  // reset all the server side filters
  // Reset : Will reset all filters, sorting, saved columns settings.
  handleReset = () => {
    reset(this.agGrid, "reminder_grid", this.setSelectedGridFilter, this.DEFAULT_GRID_FILTER_ID, defaultMyOpenReminders)
  }

  /** This is called on clear button */
  // Clear : Will clear all applied Filter and sorting, And will reset to default.
  // saveParams = JSON.parse(localStorage.getItem('params'))
  // onLoadFliter = this.saveParams?.RemindersStore?.filter
  applyFilterDefault = () => {
    clear(this.agGrid, defaultMyOpenReminders)
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.ReminderStore) {
        params = { request: temp.ReminderStore };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          ReminderStore: { filter: param.getFilterModel(), sort: param.getSortModel() },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          ReminderStore: { filter: param.getFilterModel(), sort: param.getSortModel() },
        })
      );
    }
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('reminder_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }
        var filter_data = params.request.filterModel;

        if (filter_data['reminder_type']) {
          filter_data['reminder_type'].values = convertTextToID(
            filter_data['reminder_type'],
            this.remindersTypeValueForFilter,
            'name',
            'id'
          );
        }
        if (filter_data['priority_name']) {
          filter_data['priority_name'].values = convertTextToID(
            filter_data['priority_name'],
            this.priorityForFilter,
            'text',
            'value'
          );
        }
        if (filter_data['status']) {
          filter_data['status'].values = convertTextToID(
            filter_data['status'],
            this.statuslistForFilter,
            'text',
            'value'
          );
        }
        // if (filter_data['created_by_name']) {
        //   filter_data['created_by_name'].values = convertTextToID(
        //     filter_data['created_by_name'],
        //     this.dropdown_user_list,
        //     'name',
        //     'id'
        //   );
        // }
        // if (filter_data['holder']) {
        //   filter_data['holder'].values = convertTextToID(
        //     filter_data['holder'],
        //     this.dropdown_user_list,
        //     'name',
        //     'id'
        //   );
        // }

        var payload = {
          waste: this.waste,
          filter_data: params.request.filterModel,
          // filter_data: this.search_payload?.hasOwnProperty('search')
          //   ? {}
          //   : params.request.filterModel,
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };

        //--------USED FOR --- DEALDESK VIEW SOLD ALL REMINDER PAYLOAD----------------//
        if (this.sold_reminderfor_data) {
          payload.sold_reminderfor_data = this.sold_reminderfor_data;
        }
        //--------USED FOR --- DEALDESK VIEW SOLD ALL REMINDER PAYLOAD----------------//

        // let merged = { ...payload, ...this.search_payload };
        let merged = { ...payload };
        this.setFilter(params.api);
        this.getList(merged).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          let columnConfig = localStorage.getItem('reminder_grid');
          if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
              this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
            }
          } else {
            if (this.agGrid && this.agGrid.columnApi && data.total) {
              this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                if (!['actions'].includes(column.colId)) {
                  allColumnIds.push(column.colId);
                }
              });
              this.agGrid.columnApi.autoSizeColumns(allColumnIds);
            }
          }
        });
      },
    };
  };

  getList = (payload = {}) => {
    return Axios.post('reminder/list', payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item) => {
          item.fullname =
            item?.receiver_by?.first_name + ' ' + item?.receiver_by?.last_name;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };

  getListSearch = (payload = {}) => {
    this.gridLoading = true;
    var temp = JSON.parse(localStorage.getItem('params'));
    if (this.agGrid) {
      // var filter = this.agGrid.api.getFilterModel();
      var sort = temp?.ReminderSearchStore?.sort;
    }
    this.list_data = null;

    return Axios.post('reminder/list', payload).then(({ data }) => {
      this.gridLoading = false;
      if (data.data.length) {
        data.data.forEach((item) => {
          item.fullname = item?.receiver_by?.first_name + ' ' + item?.receiver_by?.last_name;
          item.age = item.created_at ? ageDifferenceInDays(new Date(item.created_at)) : 1;
          item.idle = item.updated_at ? ageDifferenceInDays(new Date(item.updated_at)) : 1;
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;

      this.agGrid?.api?.setSortModel(sort ? sort : temp?.ReminderSearchStore?.sort);

      if (this.list_data?.length === 0) {
        this.agGrid?.api.showNoRowsOverlay();
      }
      return data;
    });
  };

  addReminderData = (data) => {
    return Axios.post(`reminder/add`, data)
      .then((data) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      //if (!['photo_url'].includes(key)) {
      formData.append(
        key,
        data[key] !== undefined && data[key] !== null ? data[key] : ''
      );
      //}
    });
    return formData;
  };

  //set edit values
  setEditValues = (data) => {
    return Axios.get(`reminder/read/${data.id}`)
      .then(({ data }) => {
        this.editValues = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        //  this.editValues = null;
        return Promise.reject(data);
      });
  };

  editReminderData = (id, formdata, isSearch) => {
    return Axios.post(`reminder/edit/${id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid && !isSearch) {
          this.setupGrid(this.agGrid);
        }
        if (isSearch) {
          this.getListSearch(this.search_payload);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_user_list = data.data;

      return data;
    });
  };

  setViewValues = (data) => {
    //this.viewValues = data;
    return Axios.get(`reminder/read/${data.id}`)
      .then(({ data }) => {
        this.viewValues = data && data.data;
        return data && data.data;
      })
      .catch(({ response: { data } }) => {
        this.viewValues = null;
        return Promise.reject(data);
      });
  };


    //set reminder values
    setReminderValues = (data) => {
      return Axios.get(`reminder/read/${data.reminder_id}`)
        .then(({ data }) => {
          this.editValues = data && data.data;
          return data && data.data;
        })
        .catch(({ response: { data } }) => {
          //  this.editValues = null;
          return Promise.reject(data);
        });
    };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  deleteReminderData = (formdata) => {
    return Axios.get(`reminder/delete/` + formdata.id)
      .then(({ data }) => {
        // this.setupGrid(this.agGrid);
        // if (this.agGrid) {
        //   this.setupGrid(this.agGrid);
        // }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;
  };
  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));

      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          ReminderStore: { filter: filterdata },
        })
      );

      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  resetSelectedSavedGridFilter = () => {
    this.selectedGridFilter = this.DEFAULT_GRID_FILTER_ID;
  };

  getReminderDatepickerExtraFooter = (data, global_fulldate_format, payload) => {
    return Axios.post(`leadstickets/read-reminder/${data.id}`, payload)
      .then(({ data }) => {
        let ReminderText = '';
        let priority_name = null;
        if (data?.data) {
          if (this.priorityForFilter.length > 0 && data?.data?.priority) {
            let priority = data?.data?.priority;
            priority &&
              this.priorityForFilter.forEach((x) => {
                if (parseInt(x.value) === parseInt(priority) && !priority_name) {
                  priority_name = x.text;
                }
              });
          }
        }

        ReminderText = (
          <div>
            <div><TranslateMessage msgKey={'Common_Data.Priority'} /> : {priority_name}</div>
            <div>
              <TranslateMessage msgKey={'DealDesk.Reminder_Date_Time'} /> :{' '}
              {data.data && data.data?.due_date_time
                ? moment(data.data.due_date_time).format(global_fulldate_format)
                : ''}
            </div>
          </div>
        );

        this.reminderdatepicker_extrafooter = ReminderText;

        return this.reminderdatepicker_extrafooter;
      })
      .catch(() => {
        var errors = [];
        return Promise.reject(errors);
      });
  };

  //--------USED FOR --- DEALDESK VIEW SOLD ALL REMINDER PAYLOAD----------------//
  setSold_reminderfor_data = (data) => {
    this.sold_reminderfor_data = data;
  };

  setIsSavedFiltersLoadedRm = (data) => {
    this.isSavedFiltersLoadedRm = data;
  };

  // /** This is called on clear button */
  //   applyFilterDefault = () => {
  //     // localStorage.removeItem('reminder_grid');
  //     this.setSelectedGridFilter(this.DEFAULT_GRID_FILTER_ID);
  //     // this.agGrid.columnApi.resetColumnState();
  //     if (this.agGrid) {
  //       this.agGrid.api.setFilterModel(defaultMyOpenReminders);
  //       this.agGrid.api.setSortModel(null);
  //       this.agGrid.api.onFilterChanged(null);
  //     }
  //   };
}

decorate(RemindersStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  current_list_path: observable,
  agGrid: observable,
  deleteValues: observable,
  editValues: observable,
  viewValues: observable,
  initialValues: observable,
  waste: observable,
  dropdown_user_list: observable,
  search_payload: observable,
  remindersTypeValue: observable,
  remindersTypeValueForFilter: observable,
  priorityForFilter: observable,
  statuslistForFilter: observable,
  selectedGridFilter: observable,
  DEFAULT_GRID_FILTER_ID: observable,
  sold_reminderfor_data: observable,
  isSavedFiltersLoadedRm: observable,
  gridLoading: observable,

  setPageSize: action,
  onGridChanged: action,
  getList: action,
  setupGrid: action,
  setDeleteValues: action,
  setEditValues: action,
  addReminderData: action,
  JsonToFormData: action,
  deleteReminderData: action,
  //editLeadData: action,
  setViewValues: action,
  getUsersList: action,
  handleReset: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  getReminderDatepickerExtraFooter: action,
  setSold_reminderfor_data: action,
  setIsSavedFiltersLoadedRm: action,
  applyFilterDefault: action,
  getListSearch: action,
  setupGridSearch: action,
  onGridChangedSearch: action,
});
