import React, { useEffect, useState } from 'react';
import InputComponent from '../../../../../component/InputComponent';
import { Form, Spin, Tooltip } from 'antd';

import useStore from '../../../../../store';
import { observer } from 'mobx-react';
//import { vsmNotify, vsmDealDesk } from '../../../../config/messages';
import { UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { ageDifferenceInHours } from '../../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const DropdownComponent = observer((props) => {
  const { t } = useTranslation();
  const {
    AUTH,
    DEALDESKSTORE,
    DEALDESKSTORE: {
      dropdown_users_list,
      // deal_data,
      getUsersList,
      updateLeadOwner,
      updateLeadHolder,
    },
    DEALDESKNOTESEQUENCE: {
      fetchSequenceNotes,
    },
    SSSLEADLIBRARYSTORE: { setSyncData },
  } = useStore();
  const [form] = Form.useForm();
  const [fetchUsersList, setFetchUsersList] = useState(true);

  const callUpdateLeadOwner = () => {
    let payload = {
      lead_owner_id: form.getFieldValue('Owner'),
    };
    updateLeadOwner(DEALDESKSTORE?.deal_data.lead_information.id, payload).then((data) => {
      // vsmNotify.success({
      //   message: vsmDealDesk.successLeadOwnerUpdate,
      // });
    });
  };

  const callUpdateLeadHolder = () => {
    let payload = {
      lead_holder_id: form.getFieldValue('Holder'),
    };
    updateLeadHolder(DEALDESKSTORE?.deal_data.lead_information.id, payload).then((data) => {
      fetchSequenceNotes(DEALDESKSTORE?.deal_data.lead_information.id, null, false, true).then((data) => {
        setSyncData(data?.sss);
      });
    });
  };

  const viewOpenAge = () => {
    let dayObj = ageDifferenceInHours(new Date(DEALDESKSTORE?.deal_data?.lead_information?.ticket_data?.created_at), new Date(AUTH.getLocalTimeDealDesk()));
    if (dayObj.days === 0 && dayObj.hours === 0) {
      return ' 1 hour';
    }

    return (<>{` ${dayObj.days !== 0 ? `${dayObj.days} day${dayObj.days > 1 ? 's' : ''}  ` : ''}  ${dayObj.hours !== 0 ? `${dayObj.hours} hour${dayObj.hours > 1 ? 's' : ''}` : ''}`}</>)
  }

  useEffect(() => {
    if(DEALDESKSTORE?.deal_data?.lead_information){
      form.setFieldsValue({
        Owner:DEALDESKSTORE.deal_data?.lead_information?.lead_owner_id,
        Holder:DEALDESKSTORE.deal_data?.lead_information?.lead_holder_id,
      })
    }
  },[form,DEALDESKSTORE?.deal_data] );


  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        id='updateTicketHeaderForm'
        className='innerForm DropdownComponent prefixIconDropdown'
      >
        <InputComponent
          disabled={DEALDESKSTORE?.deal_data?.lead_information?.is_ticket === 0 ? true : false} //Disable for Ticket Desk
          suffixIcon={<UserOutlined title={`${t('Common_Data.Owner')}`} />}
          name='Owner'
          placeholder={`${t('Common_Data.Owner')}`}
          type='select'
          initialValue={DEALDESKSTORE.deal_data?.lead_information?.lead_owner_id}
          showSearch={false}
          onFocus={() =>
            fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
          }
          notFoundContent={
            fetchUsersList ? (
              <div className='spinInside'>
                <Spin size='small' />
              </div>
            ) : (
              `${t('Common_Data.No_Record_Found')}.`
              //'No Record Found.'
            )
          }
          options={{
            values:
              dropdown_users_list ||
              (DEALDESKSTORE?.deal_data &&
                DEALDESKSTORE?.deal_data.lead_information &&
                DEALDESKSTORE?.deal_data.lead_information.lead_owner_name && [
                  {
                    id: DEALDESKSTORE.deal_data?.lead_information?.lead_owner_name?.id,
                    name: DEALDESKSTORE.deal_data?.lead_information?.lead_owner_name?.full_name,
                  },
                ]),

            value_key: 'id',
            text_key: 'name',
          }}
          onChange={() => callUpdateLeadOwner()}
        />
        <InputComponent
          suffixIcon={<UserSwitchOutlined title={`${t('DealDesk.Holder')}`} />}
          name='Holder'
          placeholder={`${t('DealDesk.Holder')}`}
          type='select'
          initialValue={DEALDESKSTORE.deal_data?.lead_information?.lead_holder_id}
          showSearch={false}
          onFocus={() =>
            fetchUsersList && getUsersList().then(() => setFetchUsersList(false))
          }
          notFoundContent={
            fetchUsersList ? (
              <div className='spinInside'>
                <Spin size='small' />
              </div>
            ) : (
              //'No Record Found.'
              `${t('Common_Data.No_Record_Found')}.`
            )
          }
          options={{
            values:
              dropdown_users_list ||
              (DEALDESKSTORE?.deal_data &&
                DEALDESKSTORE?.deal_data.lead_information &&
                DEALDESKSTORE?.deal_data.lead_information.lead_holder_name && [
                  {
                    id: DEALDESKSTORE.deal_data?.lead_information?.lead_holder_name?.id,
                    name: DEALDESKSTORE.deal_data?.lead_information?.lead_holder_name?.full_name,
                  },
                ]),

            value_key: 'id',
            text_key: 'name',
          }}
          onChange={() => callUpdateLeadHolder()}
          // disabled={DEALDESKSTORE?.deal_data?.lead_information?.lead_owner_name?.id === AUTH?.user?.id ? false : true} //Only enable for Owner
        />
        {!["Tickets Closed", "Escalated"].includes(DEALDESKSTORE?.deal_data?.lead_information?.ticket_data?.ticket_status?.status_name) ?
          <div className="right-side-ticketDetails">
            <ul>
              <li>
                <span style={{ width: 40 }}>
                  {t('DealDesk.Open')} :
                </span>
                <Tooltip placement='topLeft' title={viewOpenAge()}>
                  <span style={{ cursor: "default" }}>{viewOpenAge()}</span>
                </Tooltip>
              </li>
            </ul>
          </div>
          :
          null
        }

      </Form>
    </>
  );
});

export default DropdownComponent;
