import React from 'react';
import {
  //Row, Col,
  Form,
  Input,
  InputNumber,
  Checkbox,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import useStore from '../../../../../store';
import { calculateHeight } from '../../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const StructureContent = ({
  ChangeNode,
  AddNode,
  Structure,
  RemoveNode,
  SetStructure,
  ExchangeNode,
  editable,
  onlyForViewWithNostyle,
  scrollToNode,
  saveType,
  autoSaveData,
  INITIAL_HEIGHT,
  isCheckBoxSelection,
  selectedLibraryItemNode,
  setSelectedLibraryItemNode,
  isFromLibrary,
}) => {
  // Variables -------------------
  const { AUTH } = useStore();
  // Functions -------------------

  const onSortStart = () => {
    let timer = null;
    if (saveType === 'structure') {
      timer = localStorage.getItem('structureAutosaveTimeOut');
    } else if (saveType === 'screen') {
      timer = localStorage.getItem('screenAutosaveTimeOut');
    } else if (saveType === 'scrutinise') {
      timer = localStorage.getItem('scrunitiseAutosaveTimeOut');
    }else if (saveType === 'shortfall') {
      timer = localStorage.getItem('shortfallAutosaveTimeOut');
    }
    if (timer) {
      clearTimeout(Number(timer));
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (Structure && Structure[0]) {
      let ItemKey = Structure[0].item_key;
      ExchangeNode(ItemKey, { oldIndex, newIndex });
    }
    // SetStructure(arrayMove(Structure, oldIndex, newIndex));
  };

  const { t } = useTranslation();

  const getCharacterLength = () => {
    let lengthLimit = 170;
    if (AUTH?.user?.bonus_figures) {
      if (AUTH?.user?.bonus_figures[0]?.sss_max_character_length) {
        lengthLimit = AUTH?.user?.bonus_figures[0]?.sss_max_character_length;
      }
    }
    return lengthLimit;
  };

  // Markup ----------------------

  // set menu icon component
  const DragHandle = SortableHandle(() => (
    // style={{ padding: '32px 10px 5px 0px' }}
    <div className='dragger'>
      <MenuOutlined />
    </div>
  ));

  const SortableItem = SortableElement(({ value: { StructureItem, index } }) => (
    <div
      className={`fields_draggable_wrapper ${isFromLibrary ? 'extraZindex' : ''}`}
      id={`${saveType}_innerContent_${StructureItem.item_key}`}
    >
      <table
        width='100%'
        border={0}
        cellPadding={0}
        cellSpacing={0}
        className='StructureContentTable'
      >
        <tr>
          {isCheckBoxSelection ? (
            <>
              <td width={'24px'}>
                <Checkbox
                  checked={
                    selectedLibraryItemNode &&
                      selectedLibraryItemNode.includes(StructureItem.item_key)
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      if (!selectedLibraryItemNode.includes(StructureItem.item_key)) {
                        setSelectedLibraryItemNode([
                          ...selectedLibraryItemNode,
                          StructureItem.item_key,
                        ]);
                      }
                    } else {
                      let tempSlection = selectedLibraryItemNode.filter(
                        (x) => x !== StructureItem.item_key
                      );
                      setSelectedLibraryItemNode(tempSlection);
                    }
                  }}
                />
              </td>
            </>
          ) : null}
          <td width={'24px'} className='dragger' align='left' valign='middle'>
            {editable && <DragHandle />}
          </td>
          <td width={'50px'} className='number' valign='middle'>
            <Form.Item
              // noStyle
              onBlur={(e) => {
                setTimeout(() => {
                  ChangeNode(StructureItem.item_key, e.target.value, 'position');
                }, 0);
                autoSaveData();
              }}
            >
              <InputNumber
                onChange={(value) => {
                  ChangeNode(StructureItem.item_key, value, 'position');
                  autoSaveData();
                }}
                id={`${saveType}_innerContent_position_${StructureItem.item_key}`}
                disabled={true}
                defaultValue={parseInt(StructureItem.position)}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, '.')}
                parser={(x) => parseFloat(`${x}`.replace(/\./g, '').replace(/#/, '.'))}
              />
            </Form.Item>
          </td>
          <td className='textAreaSection' valign='middle'>
            <Form.Item
              // noStyle

              onBlur={(e) => {
                ChangeNode(StructureItem.item_key, e.target.value, 'detail');
              }}
            >
              {/* <Input
                  disabled={!editable}
                  defaultValue={StructureItem.detail}
                  className='detail'
                /> */}
              {onlyForViewWithNostyle ? (
                // <div className='detail'>{StructureItem.detail}</div>
                <TextArea
                  maxLength={getCharacterLength()}
                  disabled={true}
                  defaultValue={StructureItem.detail}
                  className='detail'
                  style={{ height: StructureItem?.height, margin: '5px' }}
                />
              ) : (
                <TextArea
                  maxLength={getCharacterLength()}
                  disabled={!editable}
                  id={`${saveType}_innerContent_textarea_${StructureItem.item_key}`}
                  defaultValue={StructureItem.detail}
                  className='detail'
                  style={{ height: StructureItem?.height }}
                  onChange={(e) => {
                    //autoSaveData(true);
                    autoSaveData();

                    let HeightStyle = calculateHeight(
                      `${saveType}_innerContent_textarea_${StructureItem.item_key}`,
                      INITIAL_HEIGHT,
                      null,
                      true
                    );
                    ChangeNode(StructureItem.item_key, HeightStyle, 'height');
                    ChangeNode(StructureItem.item_key, e.target.value, 'detail');
                  }}
                  onBlur={() => {
                    localStorage.setItem(
                      `last_focusedElement${saveType}`,
                      StructureItem.item_key
                    );
                  }}
                />
              )}
            </Form.Item>
          </td>
          {editable && (
            <td className='actionIcons text-right' valign='middle'>
              {(StructureItem.item_key + '').split('-').length < 3 && (
                <img
                  src={AUTH.GetThemedImage('add')}
                  alt={`${t('Common_Data.Add')}`}
                  className='icon circle cursorPointer mr-5 ml-5'
                  onClick={() => {
                    AddNode(StructureItem.item_key);
                    scrollToNode(`${saveType}_innerContent_${StructureItem.item_key}`);
                    autoSaveData();
                  }}
                />
              )}
              <img
                src={AUTH.GetThemedImage('remove')}
                alt={`${t('Common_Data.Remove')}`}
                className='mr-5 icon circle cursorPointer'
                onClick={() => {
                  RemoveNode(StructureItem.item_key);
                  autoSaveData();
                }}
              />
            </td>
          )}
        </tr>
        {StructureItem.children ? (
          <tr>
            <td></td>
            <td colSpan={3}>
              <StructureContent
                ChangeNode={ChangeNode}
                AddNode={AddNode}
                Structure={StructureItem.children}
                RemoveNode={RemoveNode}
                SetStructure={SetStructure}
                ExchangeNode={ExchangeNode}
                isFromLibrary={isFromLibrary}
                editable={editable}
                onlyForViewWithNostyle={onlyForViewWithNostyle}
                scrollToNode={scrollToNode}
                saveType={saveType}
                autoSaveData={autoSaveData}
                INITIAL_HEIGHT={INITIAL_HEIGHT}
                isCheckBoxSelection={isCheckBoxSelection}
                selectedLibraryItemNode={selectedLibraryItemNode}
                setSelectedLibraryItemNode={setSelectedLibraryItemNode}
              />
            </td>
          </tr>
        ) : null}
      </table>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div className='fields_draggable_container'>
      {items &&
        items.map((StructureItem, index) => (
          <SortableItem key={index} index={index} value={{ StructureItem, index }} />
        ))}
    </div>
  ));

  return (
    <SortableList
      useDragHandle={true}
      items={Structure}
      onSortEnd={onSortEnd}
      onSortStart={onSortStart}
    />
  );
};

export default StructureContent;
