import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Modal, Col, Row } from 'antd';
import useStore from '../../../../store';
import { vsmNotify, vsmDealDesk } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const NoteDeleteConfirm = observer((props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const {
    DEALDESKSTORE: { msgBoxAPIDealID, removeDealNote },
    DEALDESKNOTESEQUENCE: { fetchSequenceNotes },
  } = useStore();

  const handleSubmit = async () => {
    setSaving(true);
    await removeDealNote()
      .then(async (data) => {
        await fetchSequenceNotes(msgBoxAPIDealID);
        vsmNotify.success({
          message: vsmDealDesk.Notes.successDelete,
        });
        props.close();
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  return props.visible ? (
    <Modal
      centered
      title={`${t('Common_Data.Remove_Note')}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='removenote'
          loading={saving}
          htmlType='submit'
          type='primary'
          danger
        >
          {t('Common_Data.Yes')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='removenote' onFinish={handleSubmit}>
        {
          <Row align='middle'>
            <Col span={24}>
              <p>{t('DealDesk.Remove_Note')}?</p>
            </Col>
          </Row>
        }
      </Form>
    </Modal>
  ) : null;
});

export default NoteDeleteConfirm;
