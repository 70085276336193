import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import { convertTextToID } from '../../utils/GlobalFunction';

export default class SavedFilterStore {
  list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;

  deleteValues = null;
  editValues = null;
  viewValues = null;

  dropdown_user_list = null;

  filterPageListValues = [
    {
      id: 'tags',
      name: 'Tags',
    },
    {
      id: 'productivity',
      name: 'Productivity',
    },
    {
      id: 'alldepartments',
      name: 'Departments',
    },
    {
      id: 'blockeditassign',
      name: 'Block Edit/Assign',
    },
    {
      id: 'activeleads',
      name: 'Active SOLD',
    },
    {
      id: 'unusedleads',
      name: 'Unused SOLD',
    },
    {
      id: 'deals',
      name: 'Deals',
    },
    {
      id: 'people',
      name: 'People',
    },
    {
      id: 'organisations',
      name: 'Organisation',
    },
    {
      id: 'tickets',
      name: 'Tickets',
    },
    {
      id: 'promotion',
      name: 'Promotion',
    },
    {
      id: 'reminder',
      name: 'Reminder',
    },
    {
      id: 'opportunity',
      name: 'Opportunity',
    },
  ];

  filterPageList = {
    tags: 'Tags',
    productivity: 'Productivity',
    alldepartments: 'Departments',
    blockeditassign: 'Block Edit/Assign',
    activeleads: 'Active SOLD',
    unusedleads: 'Unused SOLD',
    deals: 'Deals',
    people: 'People',
    organisations: 'Organisation',
    tickets: 'Tickets',
    promotion: 'Promotion',
    reminder: 'Reminder',
  };

  filterPageRedirectList = {
    tags: '/tags',
    productivity: '/productivity',
    alldepartments: '/management/all-departments',
    blockeditassign: '/management/block-edit-assign',
    activeleads: '/leads/active',
    unusedleads: '/leads/unused',
    deals: '/leads/deals',
    people: '/contacts/people',
    organisations: '/contacts/organisations',
    tickets: '/tickets',
    promotion: '/promotion',
    reminder: '/reminders',
  };

  globalStatusTempData = null;

  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  //set delete values
  setEditValues = (data) => {
    this.editValues = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let columnConfig = localStorage.getItem('saved_filter_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
    let param = this.getFilter(params);
    params.api.setFilterModel(param?.request?.filter);
    params.api.setSortModel(param?.request?.sort);
    var datasource = this.createDatasource(GridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'saved_filter_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // reset all the server side filters
  handleReset = () => {
    localStorage.removeItem('saved_filter_grid');
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.setSortModel(null);
    this.agGrid.api.onFilterChanged(null);
    this.agGrid.columnApi.resetColumnState();
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.SavedFilterStore) {
        params = { request: temp.SavedFilterStore };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          SavedFilterStore: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          SavedFilterStore: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('saved_filter_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }

        let filter_data = params.request.filterModel;

        if (filter_data['created_by_name']) {
          filter_data['created_by_name'].values = convertTextToID(
            filter_data['created_by_name'],
            this.dropdown_user_list,
            'name',
            'id'
          );
        }
        if (filter_data['page']) {
          filter_data['page'].values = convertTextToID(
            filter_data['page'],
            this.filterPageListValues,
            'name',
            'id'
          );
        }

        let payload = {
          filter_data: params.request.filterModel,
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
              (params.request.endRow - params.request.startRow)
          ),
        };

        this.setFilter(params.api);
        this.getList(payload).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          let columnConfig = localStorage.getItem('saved_filter_grid');
          if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
              this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
            }
          } else {
            if (this.agGrid && this.agGrid.columnApi && data.total) {
              this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                if (!['actions'].includes(column.colId)) {
                  allColumnIds.push(column.colId);
                }
              });
              this.agGrid.columnApi.autoSizeColumns(allColumnIds);
            }
          }
        });
      },
    };
  };

  getList = (payload = {}) => {
    return Axios.post(`/page-filter/listnew`, payload).then(({ data }) => {
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };

  setViewValues = (data) => {
    this.viewValues = data;
  };

  deleteFilter = (formdata = null) => {
    return Axios.get(`page-filter/delete/${formdata.id}`)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  editFilter = (id, payload) => {
    return Axios.post(`page-filter/edit/${id}`, payload)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_user_list = data.data;

      return data;
    });
  };

  setGlobalStatusTempData = (data) => {
    this.globalStatusTempData = data;
  };
}

decorate(SavedFilterStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,

  viewValues: observable,
  editValues: observable,
  deleteValues: observable,
  filterPageList: observable,
  filterPageListValues: observable,
  dropdown_user_list: observable,
  globalStatusTempData: observable,

  getList: action,
  createDatasource: action,
  setFilter: action,
  getFilter: action,
  handleReset: action,
  onGridChanged: action,
  setupGrid: action,
  setEditValues: action,
  setDeleteValues: action,
  setViewValues: action,
  deleteFilter: action,
  editFilter: action,
  getUsersList: action,
  setGlobalStatusTempData: action,
});
