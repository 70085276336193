import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Spin, Row, Col } from 'antd';
import InputComponent from '../../../../../component/InputComponent';
import useStore from '../../../../../store';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(({ form, onChange, handleSubmit, tab }) => {
  const { t } = useTranslation()
  const { PEOPLEROLESTORE, AUTH } = useStore();
  const [fetchRelatedRoles, setFetchRelatedRoles] = useState(true);

  const onFinish = (data) => {
    handleSubmit(data);
  };

  useEffect(() => {
    if (PEOPLEROLESTORE.editValues) {
      form.setFieldsValue({
        role_name: PEOPLEROLESTORE.editValues.role_name,
        related_roles: PEOPLEROLESTORE.editValues.associated_role_names
          ? PEOPLEROLESTORE.editValues.associated_role_names.map((x) => parseInt(x.id))
          : undefined
      });
    }
  }, [PEOPLEROLESTORE.editValues, form]);

  return (
    <Form
      form={form}
      id='addeditform'
      labelCol={{ span: 24 }}
      onFinish={onFinish}
      className='innerForm'
    >
      <Row gutter={15}>
        <Col xs={{ span: 24 }}>
          <InputComponent
            required
            label={`${t('PeopleManagement.Role')} ${t('Common_Data.Name')}`}
            name='role_name'
            placeholder={`${t('PeopleManagement.Role')} ${t('Common_Data.Name')}`}
            tooltip={`${t('PeopleManagement.Role_Name_Tooltip')}.`}
            onChange={onChange}
          />
        </Col>
        <Col xs={{ span: 24 }}>
          {((AUTH.user && AUTH.user.user_role.user_role_id === 1) ||
            (tab === 'new' && PEOPLEROLESTORE.list_data.length > 0) ||
            (PEOPLEROLESTORE.list_data.length > 0 &&
              PEOPLEROLESTORE.editValues &&
              PEOPLEROLESTORE.editValues.relatedRoleEnabled)) && (
              <InputComponent
                required
                type='select'
                mode='tags'
                label={`${t('PeopleManagement.Related')} ${t('PeopleManagement.Role')}/s`}
                name='related_roles'
                placeholder={`${t('Common_Data.Choose')} ${t('PeopleManagement.Related')} ${t('OrganisationManagement.Roles')}`}
                showArrow
                options={{
                  values:
                    PEOPLEROLESTORE.relates_roles ||
                    (PEOPLEROLESTORE.editValues &&
                      PEOPLEROLESTORE.editValues.associated_role_names &&
                      PEOPLEROLESTORE.editValues.associated_role_names.length > 0
                      ? PEOPLEROLESTORE.editValues.associated_role_names.map((item) => ({
                        id: parseInt(item.id),
                        role_name: item.role_name
                      }))
                      : []),
                  value_key: 'id',
                  text_key: 'role_name',
                  rejected_keys: PEOPLEROLESTORE.editValues && [
                    PEOPLEROLESTORE.editValues.id
                  ]
                }}
                onFocus={() =>
                  fetchRelatedRoles &&
                  PEOPLEROLESTORE.getRelatedRoles().then(() => setFetchRelatedRoles(false))
                }
                notFoundContent={
                  fetchRelatedRoles ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                }
                tooltip={`${t('PeopleManagement.People_Role_Relation_Tooltip')}.`}
                onChange={onChange}
              />
            )}
        </Col>
      </Row>
    </Form>
  );
});

export default FormComponent;
