import React, { useState } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import { vsmPeople } from '../../../../config/messages';
import useStore from '../../../../store';
import { Form, Row, Col, Upload, message, Divider, Spin } from 'antd';
import moment from 'moment';
import PhoneElement from '../elements/PhoneElement';
import EmailElement from '../elements/EmailElement';
import AddressElement from '../elements/AddressElement';
import { PlusOutlined } from '@ant-design/icons';

const FormComponent = observer(
  ({
    handleSubmit,
    onChange,
    form,
    id,
    fileList,
    updateFileList,
    setAddAddress,
    addAddress,
    setImgchanged
  }) => {
    const { PEOPLE, ORGANISATION } = useStore();
    const [imgDisabled, setImgDisabled] = useState(false);
    const [fetchIndustries, setFetchIndustries] = useState(true);

    const onPreview = async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    };

    const eventProps = {
      onChange: ({ fileList: newFileList }) => {
        updateFileList(newFileList);
      },
      fileList,
      beforeUpload: (file) => {
        let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        let isLt1M = file.size / 1024 / 1024 < 1;

        if (!isJpgOrPng) {
          message.error(`Upload valid image. Only JPG, JPEG or PNG are allowed.`);
          setImgDisabled(true);
          return true;
        } else if (!isLt1M) {
          message.error('File size should not be more than 500px x 500px and 1 MB');
          setImgDisabled(true);
          return true;
        } else {
          setImgchanged(true);
          updateFileList([file]);
          setImgDisabled(false);
          return false;
        }
      }
    };

    // Handle on remove image
    const onRemoveImage = () => {
      setImgchanged(true);
      form.setFields([{ name: 'logo_url', errors: [] }]);
      updateFileList([]);
      setImgDisabled(false);
    };

    const uploadButton = (
      <div>
        <PlusOutlined />
      </div>
    );

    const onFinish = (data) => {
      handleSubmit(data);
    };

    const disabledDate = (current) => {
      return current && current > moment().endOf('day');
    };
    const phoneTitle = (
      <>
        Phone
        <small className='ml-5'>
          (To add extension use ext keyword i.e : +353 x xxx-xxxx, ext. xxxx)
        </small>
      </>
    );
    const photoTitle = (
      <>
        Photo
        <small className='ml-5'>
          (Only JPG or PNG is allowed, Maximum size : 500px x 500px)
        </small>
      </>
    );
    return (
      <Form
        form={form}
        layout='vertical'
        id={id}
        onFinish={onFinish}
        initialValues={PEOPLE.initialValues}
        className='innerForm'
      >
        <Row gutter={15}>
          <Col span={4}>
            <InputComponent
              label='Salutation'
              type='select'
              onChange={onChange}
              // initialValue={'Mr.'}
              options={{
                values: [
                  { value: 'Mr.', text: 'Mr.' },
                  { value: 'Ms.', text: 'Ms.' },
                  { value: 'Mrs.', text: 'Mrs.' },
                  { value: 'Dr.', text: 'Dr.' }
                ]
              }}
              name='salutation'
              tooltip='A gesture or utterance made as a greeting or acknowledgement.'
              rules={vsmPeople.validation.salutation}
              maxLength={100}
            />
          </Col>
          <Col span={10}>
            <InputComponent
              required
              label='First Name'
              placeholder='Enter your First Name'
              name='first_name'
              onChange={onChange}
              tooltip='A personal Name that is given to user/ person at birth or baptism and used before a family name.'
              autoComplete='off'
              rules={vsmPeople.validation.first_name}
              maxLength={100}
            />
          </Col>
          <Col span={10}>
            <InputComponent
              label='Last Name'
              placeholder='Enter your Last Name'
              name='last_name'
              onChange={onChange}
              tooltip='It is the Name of the family to which a person/ user is born.'
              autoComplete='off'
              rules={vsmPeople.validation.last_name}
              maxLength={100}
            />
          </Col>
          <Col span={6}>
            <InputComponent
              label='Title'
              placeholder='Title'
              name='title'
              onChange={onChange}
              tooltip='A title can describe the responsibilities of the position.'
              autoComplete='off'
              rules={vsmPeople.validation.title}
              maxLength={100}
            />
          </Col>
          <Col span={6}>
            <InputComponent
              type='date'
              name='date_of_birth'
              onChange={onChange}
              disabledDate={disabledDate}
              label='Date of Birth'
              placeholder='Select Date of Birth'
              rules={vsmPeople.validation.date_of_birth}
              tooltip='The month, day, and year a person was born.'
            />
          </Col>
          <Col span={6}>
            <InputComponent
              label='Former Last Name'
              placeholder='Enter Former Last Name'
              name='former_last_name'
              onChange={onChange}
              tooltip='It is the name of the family one may have used in the past for any reason.'
              autoComplete='off'
              rules={vsmPeople.validation.former_last_name}
            />
          </Col>
          <Col span={6}>
            <InputComponent
              required
              placeholder='Select Classification'
              label={<span
                className='converbillLink'
                onClick={() => {
                  window.open(
                    'https://www.convertibill.com/credebtclassifications-popup.html',
                    '_blank',
                    'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                  );
                }}
              >
                Credebt® Classification
              </span>}
              type='select'
              onChange={onChange}
              rules={vsmPeople.validation.classification}
              //mode='multiple'
              allowClear
              showSearch
              notFoundContent={
                fetchIndustries ? <Spin size='small' /> : 'No Record Found.'
              }
              options={{
                values:
                  ORGANISATION.industries_list ||
                  (PEOPLE.peopleValues &&
                    PEOPLE.peopleValues.industry_id && [
                      {
                        id: PEOPLE.peopleValues.industry_id.id,
                        name: PEOPLE.peopleValues.industry_id.name
                      }
                    ]),
                value_key: 'id',
                text_key: 'name'
              }}
              onFocus={() => {
                fetchIndustries &&
                  ORGANISATION.getIndustries().then(() => setFetchIndustries(false));
              }}
              name='industry_id'
              tooltip='Classification is a group of productive enterprises or organisations that produce or supply goods, services, or sources of income.'
              autoComplete='off'
            />
          </Col>

          <Col span={12}>
            <Form.Item
              label={phoneTitle}
              className='mb-10'
              tooltip='Phone Type is the type, that would be associated with the Phone(Eg.: Primary, home, work)'
            >
              <div className='w-100'>
                <Row>
                  <Col span={24}>
                    <PhoneElement
                      onChange={onChange}
                      form={form}
                      peopleValues={PEOPLE.peopleValues && PEOPLE.peopleValues}
                    />
                  </Col>
                </Row>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Email'
              className='mb-10'
              tooltip='Email Type is the type, that would be associated with the Email(Eg.: Primary, home, work).'
            >
              <div className='w-100'>
                <Row>
                  <Col span={24}>
                    <EmailElement onChange={onChange} form={form} />
                  </Col>
                </Row>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputComponent
              label='Notes'
              placeholder='Enter Notes'
              name='notes'
              type='textarea'
              onChange={onChange}
              tooltip='The notes are the observations presented to simply describe the field/s.'
              autoComplete='off'
              autoSize={{ minRows: 2, maxRows: 2 }}
              rules={vsmPeople.validation.notes}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              label={photoTitle}
              className='mb-0'
              tooltip='It is image of the person.'
              name='logo_url'
            >
              <Upload
                accept='.png, .jpeg'
                fileList={fileList}
                onRemove={onRemoveImage}
                onPreview={onPreview}
                listType='picture-card'
                multiple={false}
                showUploadList={true}
                {...eventProps}
                disabled={imgDisabled}
                onChange={onChange}
                maxCount={1}
                className='uploadContract'
              >
                {fileList.length >= 1 ? null : uploadButton} +
              </Upload>
            </Form.Item>
          </Col>
          <Col span={8}>
            <InputComponent
              type='select'
              label='PPSN Document'
              placeholder='Select PPSN Document'
              name='ppsn_document_type'
              onChange={onChange}
              allowClear
              options={{
                values: [
                  { value: 1, text: 'Legal Correspondence' },
                  { value: 4, text: 'Notice of Tax Credits' },
                  { value: 2, text: 'P21 (Tax Balancing Statement)' },
                  { value: 5, text: 'Payslip' },
                  { value: 6, text: 'P60' },
                  { value: 7, text: 'P45' },
                  { value: 3, text: 'Tax Assessment' }
                ]
              }}
              tooltip='PPSN Document is the type of the document.'
              autoComplete='off'
              rules={vsmPeople.validation.ppsn_document_type}
            />
          </Col>
          <Col span={8}>
            <InputComponent
              label='PPS Number'
              placeholder='Enter your PPS Number'
              name='pps_number'
              onChange={onChange}
              tooltip='A Personal Public Service (PPS) Number is a unique reference number that helps you access social welfare benefits, public services and information in Ireland. A PPS Number is always 7 numbers followed by either one or two letters. You can use your PPS Number to access public services in Ireland.'
              autoComplete='off'
              rules={vsmPeople.validation.pps_number}
            />
          </Col>
          <Col span={8}>
            <InputComponent
              label='Pronounced'
              placeholder='Enter Pronounced'
              name='pronounced'
              onChange={onChange}
              tooltip='It indicates how the name of the person should be pronounced.'
              autoComplete='off'
              rules={vsmPeople.validation.pronounced}
            />
          </Col>
        </Row>
        <Divider className='mt-10 mb-15' />

        <AddressElement onChange={onChange} form={form} />
      </Form>
    );
  }
);

export default FormComponent;
