import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { PageHeader, Button } from 'antd';
import useStore from '../../store';
import ListComponent from './component/ListComponent';
import { useHistory } from 'react-router-dom';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import EditPermissionComponent from './component/EditPermissionComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../component/EnterFullscreen';
import Unauthorized from '../Unauthorized';
// import TrackTimeComponent from '../../component/TrackTimeComponent';
import TimeTracker from '../../component/TimeTracker';
import { useTranslation } from 'react-i18next';

const UserGroup = observer(() => {

  let history = useHistory();
  const { USERGROUP, AUTH, SETTINGS } = useStore();
  const [addDrawer, setAddDrawer] = useState(false);
  const [editDrawer, setEditDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [editPermissionModal, setEditPermissionModal] = useState(false);
  const { t } = useTranslation()
  // Open form for edit profile User and set values to form
  const openPermissionEditModal = (data) => {
    USERGROUP.setPermissionValues(data);
    setEditPermissionModal(true);
  };

  // Open form for edit User
  const closePermissionEditModal = () => setEditPermissionModal(false);

  //open add drawer
  const openAddDrawer = () => {
    setAddDrawer(true);
  };

  //close add drawer
  const closeAddDrawer = () => setAddDrawer(false);

  //open edit drawer
  const openEditDrawer = (data) => {
    USERGROUP.setEditValues(data);
    setEditDrawer(true);
  };

  //close edit drawer
  const closeEditDrawer = () =>{
    setTimeout(() => setEditDrawer(false), 1000);
  };

  //open delet drawer
  const openDeleteDrawer = (data) => {
    setDeleteDrawer(true);
    USERGROUP.setDeleteValues(data);
  };

  //close delet drawer
  const closeDeleteDrawer = () => setDeleteDrawer(false);

  const openViewDrawer = (data) => {
    history.push(`/people/all-people/view-people/${data.id}`);
  };

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (result.key === 'Enter' && AUTH.checkPermission(26, 'edit')) {
            openEditDrawer(e.data);
          }
          if (result.key === 'Delete' && AUTH.checkPermission(26, 'delete')) {
            openDeleteDrawer(e.data);
          }
        }
      }
    }
  };

  return (
    <>
      {!AUTH.checkPermission(26, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          title={`${t('Common_Data.User')} ${t('Common_Data.Groups')}`}
          className={'page-title ' + SETTINGS.fullscreen_class}
          extra={[
            <Button
              key='1'
              title={`${t('Common_Data.Reset')}`}
              shape='round'
              size='medium'
              onClick={USERGROUP.handleReset}
            >
              <FontAwesomeIcon icon={faUndo} />
            </Button>,
            AUTH.checkPermission(26, 'add') && (
              <Button
                className='gridbtn'
                key='2'
                shape='round'
                size='medium'
                onClick={openAddDrawer}
              >
                {t('Common_Data.Add')} {t('Common_Data.User')} {t('Common_Data.Groups')}
              </Button>
            ),
            <EnterFullscreen key='3' />,
          ]}
        >
          <ListComponent
            openDeleteDrawer={openDeleteDrawer}
            openEditDrawer={openEditDrawer}
            openViewDrawer={openViewDrawer}
            openPermissionEditModal={openPermissionEditModal}
            onCellKeyDown={onCellKeyDown}
          />
          <AddComponent visible={addDrawer} close={closeAddDrawer} />
          <EditComponent visible={editDrawer} close={closeEditDrawer} />
          <DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
          {editPermissionModal && (
            <EditPermissionComponent
              visible={editPermissionModal}
              close={closePermissionEditModal}
            />
          )}
          {/* <TrackTimeComponent pageName={'user_groups'} /> */}
          <TimeTracker />
        </PageHeader>
      )}
    </>
  );
});

export default UserGroup;
