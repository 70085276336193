import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Row, Form } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';
import { MenuOutlined } from '@ant-design/icons';
import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { useTranslation } from 'react-i18next';

const CombinedReportsElement = observer(({ onChange, form }) => {
  const {t} = useTranslation()
  const { SavedReportStore } = useStore();
  const [reports, setReports] = useState();
  const [reportDropdown, setDropdown] = useState();

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(reports), oldIndex, newIndex);
      setReports(newData);
      await form.setFieldsValue({ combined_reports: newData });
      onChange();
    }
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined
      style={{ fontSize: '1.5em', cursor: 'pointer', color: '#999', marginTop: 5 }}
    />
  ));

  const SortableContainer = sortableContainer((props) => (
    <div {...props} className='w-100' />
  ));

  const SortableItem = sortableElement(({ fields, add, remove, field, i }) => {
    return (
      <div style={{ zIndex: 9999 }}>
        <Row gutter={10}>
          <Col>
            <DragHandle />
          </Col>
          <Col flex={1}>
            <InputComponent
              onChange={(data) => {
                onChange(data);
                setReports(form.getFieldValue('combined_reports'));
              }}
              type='select'
              name={[field.name]}
              key={[field.key, 'combined_reports']}
              placeholder={`${t('Common_Data.Select')} ${t('Reports.Report')}`}
              options={{
                values: SavedReportStore.dropdown_list,
                value_key: 'id',
                text_key: 'report_name',
                rejected_keys: reports,
                accepted_key: reports ? [reports[field.name]] : null
              }}
              rules={vsmTransactionReport.validation.combined_reports}
            />
          </Col>
          <Col>
            {fields && fields.length > 1 && (
              <Button
                type='danger'
                onClick={async () => {
                  await remove(field.name);
                  await setReports(form.getFieldValue('combined_reports'));
                  onChange();
                }}
                icon={<MinusOutlined />}
              ></Button>
            )}
            {reportDropdown &&
              i === fields.length - 1 &&
              fields.length < reportDropdown.length &&
              fields.length < 12 && (
                <Button
                  className={fields.length > 1 ? 'ml-10' : null}
                  type='primary'
                  onClick={async () => {
                    await add();
                    await setReports(form.getFieldValue('combined_reports'));
                    onChange();
                  }}
                  icon={<PlusOutlined />}
                ></Button>
              )}
          </Col>
        </Row>
      </div>
    );
  });

  useEffect(() => {
    setReports(form.getFieldValue('combined_reports'));
    setDropdown(SavedReportStore.dropdown_list);
  }, [form, setReports, SavedReportStore.dropdown_list]);

  return (
    <div className='w-100'>
      <SortableContainer useDragHandle onSortEnd={onSortEnd}>
        <Form.List name='combined_reports'>
          {(fields, { add, remove }) => {
            return (
              fields &&
              fields.map((field, index) => {
                return (
                  <SortableItem
                    fields={fields}
                    add={add}
                    i={index}
                    remove={remove}
                    field={field}
                    key={field.key}
                    index={index}
                  />
                );
              })
            );
          }}
        </Form.List>
      </SortableContainer>
    </div>
  );
});

export default CombinedReportsElement;
