import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Col, Form, Row, Spin, Checkbox } from 'antd';
import { vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';
import InputComponent from '../../../../component/InputComponent';
//import DateSelectionElement from '../elements/DateSelectionElement';
import PeriodSelectionElement from '../elements/PeriodSelectionElement';
import CombinedReportsElement from '../elements/CombinedReportsElement';
import moment from 'moment';
import debounce from 'lodash/debounce';
import {
  audit_group_onArr,
  generate_onReports,
  max_value_idle_ticket,
  max_value_investment_hours,
  max_value_turn_over,
  relatedItemCheckboxOptions,
  relatedItemTypeList,
  remindersStatus,
  reminderTypeList,
  reminder_priority,
  reportTypeArray,
  sortByArr,
} from '../../../../utils/GlobalFunction';
import ReminderForElement from '../elements/ReminderForElement';
import DateSelectionElementNew from '../elements/DateSelectionElementNew';
import RelatedItemsForElement from '../elements/RelatedItemsForElement';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(
  ({
    defaultFormDataList,
    form,
    formDataList,
    setFormDataList,
    initialValues = {},
    setDisabled,
    handleSubmit,
    formButtons,
    id,
    saveFilterList,
    setsaveFilterList,
    isClone = false,
  }) => {
    //----------------------------- Store Declaration ---------------------------------------------//

    const {
      SavedReportStore,
      SavedReportStore: {
        getClassificationList,
        classification_role_list,
        getProductList,
        product_list,
        searchLead,
        searchTicket,
        getEditLeads,
      },
      LEADSSTORE,
      AUTH,
      AUTH: { getSavedGridFilter },
      TicketsStore: {
        dropdown_ticket_type,
        dropdown_priority,
        getTicketstatusList,
        dropdown_master_ticket,
        getMasterTicketList,
        dropdown_ticketstatus,
      },
      TagsStore: { statusArr_list },
      ORGANISATION,
      PEOPLE,
    } = useStore();

    //---------------------------------------------------------------------------------------------//

    //----------------------------- States Declaration --------------------------------------------//
    const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false);
    const [GROUPSTATUSLIST, SETGROUPSTATUSLIST] = useState(false);
    const [USERLIST, SETUSERLIST] = useState(false);
    const [USERGROUPLIST, SETUSERGROUPLIST] = useState(false);
    // const [ORGANISATIONS, SETORGANISATION] = useState(false);
    const [ORGLIST, SETORGLIST] = useState(false);
    const [searchOrgKey, setSearchOrgKey] = useState();
    const [searchPplKey, setSearchPplKey] = useState();
    const [searchLeadKey, setSearchLeadKey] = useState();
    const [searchTicketKey, setSearchTicketKey] = useState();
    const [fetchClassification, setFetchClassification] = useState(true);
    const [fetchProductList, setFetchProductList] = useState(true);
    const [fetchLead, setFetchLead] = useState(true);
    const [fetchCurrencyList, setFetchCurrencyList] = useState(true);
    const [fetchTicketStatus, setfetchTicketStatus] = useState(true);
    const [fetchSaveFilter, setfetchSaveFilter] = useState(true);
    const [fetchMasterTicket, setFetchMasterTicket] = useState(true);
    const [DateRange, SetDateRange] = useState([]);
    const { t } = useTranslation()
    //---------------------------------------------------------------------------------------------//

    //----------------------------- Form Change Functions -----------------------------------------//
    const disabledDate = (current) => {
      if (!DateRange || DateRange.length === 0) {
        return false;
      }
      return moment(current).format('Y-M-D') === moment(DateRange[0]).format('Y-M-D');
    };

    const getOrgRelatedData = useCallback(
      (data = {}) => {
        let main_entity = form.getFieldValue('main_entity');
        let related_entity = form.getFieldValue('related_entity');
        let payload = { main_entity, related_entity };
        if (main_entity === 'people') {
          let main_people_role = form.getFieldValue('main_people_role');
          main_people_role =
            main_people_role && main_people_role.length > 0 ? main_people_role : null;
          let main_people = form.getFieldValue('main_people');
          main_people = main_people && main_people.length > 0 ? main_people : null;

          payload = { ...payload, main_people_role, main_people };
        } else {
          let main_organisation_role = form.getFieldValue('main_organisation_role');
          main_organisation_role =
            main_organisation_role && main_organisation_role.length > 0
              ? main_organisation_role
              : null;
          let main_organisation = form.getFieldValue('main_organisation');
          main_organisation =
            main_organisation && main_organisation.length > 0 ? main_organisation : null;
          payload = { ...payload, main_organisation_role, main_organisation };
        }

        if (related_entity === 'people') {
          let related_people_role = form.getFieldValue('related_people_role');
          related_people_role =
            related_people_role && related_people_role.length > 0
              ? related_people_role
              : null;
          payload = { ...payload, related_people_role };
        } else {
          let related_organisation_role = form.getFieldValue('related_organisation_role');
          related_organisation_role =
            related_organisation_role && related_organisation_role.length > 0
              ? related_organisation_role
              : null;
          payload = { ...payload, related_organisation_role };
        }

        payload = { ...payload, ...data };
        SavedReportStore.getDependentList(payload);
      },

      [form, SavedReportStore]
    );

    const getPeopleRole = useCallback(() => {
      if (
        !SavedReportStore.main_people_roles &&
        form.getFieldValue('main_entity') === 'people'
      ) {
        SavedReportStore.getMainRoles(form.getFieldValue('main_entity'));
      }
    }, [SavedReportStore, form]);

    const getFormDataList = useCallback(
      (type) => {
        let FormDataList = defaultFormDataList;

        if (generate_onReports.includes(form.getFieldValue('report_type'))) {
          FormDataList = [...FormDataList, 'generate_on', 'additional_fields'];
        } else {
          FormDataList = [...FormDataList, 'additional_fields'];
        }

        if (type === 'report_type') {
          let filterValue = `save_filter_${form.getFieldValue('report_type')}`;
          form.setFieldsValue({
            deadline_date_leads: null,
            deadline_date_tags: null,
            deadline_date_tickets: null,
            tag_status_name: undefined,
            leads_tickets_status: undefined,
            [filterValue]: undefined,
          });
          setfetchSaveFilter(true);
          SETUSERLIST(false);
          SETUSERGROUPLIST(false);
          SavedReportStore.getStatusList();
        }

        switch (form.getFieldValue('generate_on')) {
          case 'yesterday':
            FormDataList = [...FormDataList];

            break;
          case 'last_week':
            FormDataList = [...FormDataList];

            break;
          case 'this_month':
            FormDataList = [...FormDataList];

            break;
          case 'this_quarter':
            FormDataList = [...FormDataList];

            break;
          case 'this_year':
            FormDataList = [...FormDataList];

            break;
          case 'date_range':
            FormDataList = [...FormDataList, 'date_range'];

            break;
          case 'single':
            FormDataList = [...FormDataList, 'date'];
            if (!form.getFieldValue('date')) {
              form.setFieldsValue({ date: moment(new Date(), 'YYYY-MM-DD') });
            }

            break;
          case 'auto':
            FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type'];

            break;
          case 'financial_year':
            FormDataList = [...FormDataList, 'financial_year'];
            if (!GENERATEONFINANCIAL) {
              SETGENERATEONFINANCIAL(true);
              SavedReportStore.getFinancialYearList();
            }
            break;
          case 'periods':
            FormDataList = [...FormDataList, 'period_selection'];
            switch (form.getFieldValue('period_selection')) {
              case 'custom':
                FormDataList = [...FormDataList, 'period_date_range'];
                break;
              case 'auto':
                FormDataList = [...FormDataList, 'period_x_value', 'period_x_value_type'];
                break;
              default:
            }
            break;
          default:
        }

        SavedReportStore.getAdditionalFields(form.getFieldValue('report_type'));

        switch (form.getFieldValue('report_type')) {
          case 'leads':
            FormDataList = [
              ...FormDataList,
              'parent_sold_stage',
              'filter_id',
              'waste',
              'record_type',
              'classification_id',
              'opportunity_name',
              'product_name',
              'sold_stage',
              'lead_currency_id',
              'lead_source',
              'sold_source_details',
              'turn_over',
              'investment_hours',
              'deadline_date_leads',
              'created_at',
              'modified_at',
              'last_note_on',
              'last_conversation_on',
              'sort_by',
              'lead_owner',
              'lead_holder',
              'phone',
              'email',
              'note',
              'group_by',
              'organisation',
              'people',
            ];

            if (fetchSaveFilter && type) {
              setfetchSaveFilter(false);
              getSavedGridFilter({
                page: 'activeleads',
                is_lead_deal: true,
              }).then((data) => {
                setsaveFilterList(data);
              });
            }
            if (fetchClassification && !classification_role_list) {
              setFetchClassification(false);
              getClassificationList();
            }
            if (!USERLIST) {
              SETUSERLIST(true);
              LEADSSTORE.getReportUsersList();
            }
            if (fetchProductList && !product_list) {
              setFetchProductList(false);
              getProductList();
            }
            break;

          case 'tags':
            FormDataList = [
              ...FormDataList,
              'group_filter_by',
              'organisation',
              'filter_id',
              'waste',
              'tag_subject',
              'ticket_number',
              'deadline_date_tags',
              'sold_id_tags',
              'owner',
              'tagholder',
              'created_at',
              'modified_at',
              'tag_status_name',
            ];
            if (fetchSaveFilter && type) {
              setfetchSaveFilter(false);
              getSavedGridFilter({ page: 'tags' }).then((data) => {
                setsaveFilterList(data);
              });
            }
            if (!ORGLIST) {
              SETORGLIST(true);
              getOrgRelatedData();
              getPeopleRole();
            }
            switch (form.getFieldValue('group_filter_by')) {
              case 3:
                FormDataList = [...FormDataList, 'parent_sold_stage'];
                break;
              default:
            }
            break;

          case 'tickets':
            FormDataList = [
              ...FormDataList,
              'group_filter_by',
              'filter_id',
              'waste',
              'ticket_subject',
              'organisation',
              'deadline_date_tickets',
              'created_at',
              'modified_at',
              'last_note_on',
              'ticket_description',
              'leads_tickets_status',
              'ticket_type',
              'master_ticket_number',
              'lead_owner',
              'ticketholder',
              'idle_ticket',
              'priority_tickets',
            ];
            if (fetchSaveFilter && type) {
              setfetchSaveFilter(false);
              getSavedGridFilter({ page: 'tickets' }).then((data) => {
                setsaveFilterList(data);
              });
            }
            if (fetchMasterTicket) {
              setFetchMasterTicket(false);
              getMasterTicketList();
            }
            if (fetchTicketStatus) {
              setfetchTicketStatus(false);
              getTicketstatusList();
            }
            if (!ORGLIST) {
              SETORGLIST(true);
              getOrgRelatedData();
              getPeopleRole();
            }
            switch (form.getFieldValue('group_filter_by')) {
              case 3:
                FormDataList = [...FormDataList, 'parent_sold_stage'];
                break;
              default:
            }
            break;
          case 'reminders':
            FormDataList = [
              ...FormDataList,
              'priority_name',
              'filter_id',
              'reminder_type',
              'reminder_subject',
              'reminder_description',
              'assign_to',
              'due_date_time',
              'status',
              'created_at',
              'reminder_tag_id',
              'reminder_ticket_id',
              'reminder_leads_id',
              'reminder_contacts_id',
              'reminder_organisations_id',
              //'completed_on',
            ];
            if (fetchSaveFilter && type) {
              setfetchSaveFilter(false);
              getSavedGridFilter({ page: 'reminder' }).then((data) => {
                setsaveFilterList(data);
              });
            }

            break;
          case 'related_items':
            FormDataList = [
              ...FormDataList,
              'related_item_type',
              'created_at',
              'related_item_ticket_id',
              'related_item_leads_id',
              'related_item_contacts_id',
              'related_item_organisations_id',
              'include_related_item_types',
            ];

            break;
          case 'users':
            FormDataList = [
              ...FormDataList,
              'parent_sold_stage',
              'sold_stage',
              'group_filter_by',
            ];

            break;

          case 'promotion-bonus':
            FormDataList = [...FormDataList, 'users_list'];
            if (!USERLIST) {
              SETUSERLIST(true);
              LEADSSTORE.getReportUsersList({});
            }
            break;

          case 'deal_maker_bonus':
            FormDataList = [...FormDataList, 'lead_owner'];
            if (!USERLIST) {
              SETUSERLIST(true);
              LEADSSTORE.getReportUsersList();
            }
            break;

          case 'performance':
            FormDataList = [
              ...FormDataList,
              'users_list',
              'user_group_list',
              'parent_sold_stage',
              'sold_stage',
              'record_type',
              'calls_checkbox',
              'show_result_column',
              'leads_structure_count',
              'leads_schedule_count',
              'leads_screen_count',
              'tag_assigned_to_count',
              'tag_created_count',
              'ticket_created_count',
              'ticket_assigned_to_count',
            ];
            if (!USERLIST) {
              SETUSERLIST(true);
              LEADSSTORE.getReportUsersList({});
            }
            if (!USERGROUPLIST) {
              SETUSERGROUPLIST(true);
              SavedReportStore.fetchLockListGroupUsers();
            }
            if (!GROUPSTATUSLIST) {
              SETGROUPSTATUSLIST(true);
              LEADSSTORE.getLeadStatusList();
            }
            break;

          case 'sample_weekly':
            FormDataList = [...FormDataList];
            break;

          case 'investment_hours':
            FormDataList = [
              ...FormDataList,
              'users_list',
              'user_group_list',
              'lead_id',
              'ticket_id',
              'group_on',
            ];
            if (!USERLIST) {
              SETUSERLIST(true);
              let formdata = {
                inactive: true,
              };
              LEADSSTORE.getReportUsersList(formdata);
            }
            if (!USERGROUPLIST) {
              SETUSERGROUPLIST(true);
              SavedReportStore.fetchLockListGroupUsers();
            }
            if (fetchLead) {
              let obj = {
                lead_ids: form.getFieldValue('lead_id')
                  ? form.getFieldValue('lead_id')
                  : [],
              };
              setSearchLeadKey('');
              getEditLeads(obj);
              setFetchLead(false);
            }

            if (fetchMasterTicket) {
              setFetchMasterTicket(false);
              getMasterTicketList();
            }
            break;
          default:
        }
        return FormDataList;
      },
      [
        defaultFormDataList,
        form,
        fetchClassification,
        fetchSaveFilter,
        getSavedGridFilter,
        fetchProductList,
        product_list,
        classification_role_list,
        getMasterTicketList,
        fetchMasterTicket,
        fetchTicketStatus,
        setsaveFilterList,
        getTicketstatusList,
        getClassificationList,
        getProductList,
        getEditLeads,
        fetchLead,
        getPeopleRole,
        getOrgRelatedData,
        LEADSSTORE,
        GENERATEONFINANCIAL,
        SavedReportStore,
        USERLIST,
        ORGLIST,
        USERGROUPLIST,
        GROUPSTATUSLIST,
      ]
    );

    //---------------------------------------------------------------------------------------------//

    //----------------------------- UseEffects ----------------------------------------------------//

    useEffect(() => {
      let data = getFormDataList('type');

      if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
        setFormDataList(data);
      }
    }, [getFormDataList, setFormDataList, formDataList, form]);

    useEffect(() => {
      SavedReportStore.getLeadTypeList(SavedReportStore.previewReportValues);
      SavedReportStore.getRecordType();
    }, [SavedReportStore, form, SavedReportStore.previewReportValues]);

    useEffect(() => {
      if (
        SavedReportStore.previewReportValues &&
        SavedReportStore.previewReportValues.main_entity
      ) {
        SavedReportStore.getMainRoles(SavedReportStore.previewReportValues.main_entity);
        let main_entity = SavedReportStore.previewReportValues.main_entity;
        let related_entity = SavedReportStore.previewReportValues.related_entity;
        let main_organisation = SavedReportStore.previewReportValues.main_organisation;
        let main_organisation_role =
          SavedReportStore.previewReportValues.main_organisation_role;
        let related_organisation_role =
          SavedReportStore.previewReportValues.related_organisation_role;
        let main_people = SavedReportStore.previewReportValues.main_people;
        let main_people_role = SavedReportStore.previewReportValues.main_people_role;
        let related_people_role =
          SavedReportStore.previewReportValues.related_people_role;
        getOrgRelatedData({
          main_entity,
          related_entity,
          main_organisation,
          main_organisation_role,
          related_organisation_role,
          main_people,
          main_people_role,
          related_people_role,
        });
      } else {
        if (
          !SavedReportStore.previewReportValues ||
          (SavedReportStore.previewReportValues &&
            SavedReportStore.previewReportValues.type !== 5)
        ) {
          // combined_reports
          SavedReportStore.getMainRoles();
          let main_entity = 'organisation';
          let related_entity = 'organisation';
          let main_organisation = [0];
          let main_organisation_role = [0];
          let related_organisation_role = [0];
          let main_people = [0];
          let main_people_role = [0];
          let related_people_role = [0];
          getOrgRelatedData({
            main_entity,
            related_entity,
            main_organisation,
            main_organisation_role,
            related_organisation_role,
            main_people,
            main_people_role,
            related_people_role,
          });
        }
      }
    }, [SavedReportStore, getOrgRelatedData]);

    //---------------------------------------------------------------------------------------------//

    //----------------------------- Change Events -------------------------------------------------//
    const handleChange = async (event, type) => {
      await setFormDataList(getFormDataList(type));

      form
        .validateFields()
        .then((data) => {
          setDisabled(false);
        })
        .catch((e) => {
          if (e.errorFields.length > 0) {
            setDisabled(true);
          }
        });
    };

    // search string for dropdown
    const handleOrgSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchOrgKey(val);
        ORGANISATION.getAllROrg({ search_for: val, mapping_type: 1 });
      };

      return debounce(loadOptions, 500);
    }, [ORGANISATION]);

    const handlePplSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchPplKey(val);
        PEOPLE.getAllPeople({ search_for: val, mapping_type: 2 });
      };

      return debounce(loadOptions, 500);
    }, [PEOPLE]);

    const handleSearchLead = useMemo(() => {
      const loadOptions = (val) => {
        if (val && val?.length >= 3) {
          setSearchLeadKey(val);
          searchLead({ search_text: val });
        }
      };

      return debounce(loadOptions, 500);
    }, [searchLead]);

    const handleSearchTicket = useMemo(() => {
      const loadOptions = (val) => {
        if (val && val?.length >= 3) {
          setSearchTicketKey(val);
          searchTicket({ search_text: val });
        }
      };

      return debounce(loadOptions, 500);
    }, [searchTicket]);

    const onFinish = (data) => {
      data.form_list = formDataList;
      data = SavedReportStore.formatFormData({
        ...SavedReportStore.previewReportValues,
        ...data,
      });

      data.action = 'save_report';
      handleSubmit(data);
    };

    const handleRecordType = (e) => {
      SavedReportStore.getStatusList(form.getFieldValue('parent_sold_stage'), e).then(
        () => {
          form.setFieldsValue({
            sold_stage: undefined,
          });
        }
      );
    };

    const handleLeadTypeChange = (e) => {
      SavedReportStore.getStatusList(e, form.getFieldValue('record_type')).then(() => {
        form.setFieldsValue({
          sold_stage: undefined,
        });
      });
    };

    const handleSliderChange = (val, field) => {
      form.setFieldsValue({
        [field]: val,
      });
    };
    //---------------------------------------------------------------------------------------------//

    return (
      <Form
        form={form}
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        id={id}
        className='innerForm addEditReport'
      >
        {/* Report Type */}

        {formDataList.includes('report_type') && (
          <Row>
            <Col span={24}>
              <InputComponent
                disabled={
                  (SavedReportStore.previewReportValues &&
                    SavedReportStore.previewReportValues.id) ||
                  isClone
                }
                required
                type='radio_button'
                name='report_type'
                onChange={async (e) => {
                  if (form.getFieldValue('generate_on') === 'periods') {
                    await form.setFields([
                      { name: 'generate_on', value: 'all_unarchive_data' },
                    ]);
                  }
                  form.resetFields([
                    'parent_sold_stage',
                    'sold_stage',
                    'record_type',
                    'filter_id',
                    'waste',
                    'global_toggle',
                    'additional_fields',
                    'group_filter_by',
                    'users_list',
                    'generate_on',
                    'lead_owner',
                    'lead_holder',
                    'organisation_type',
                    'group_by',
                    'people',
                    'organisation',
                  ]);
                  handleChange(e, 'report_type');
                }}
                options={{
                  values: reportTypeArray,
                }}
                rules={vsmTransactionReport.validation.report_type}
              />
            </Col>
          </Row>
        )}

        {/* Name/Description  */}

        <Row gutter={24}>
          {formDataList.includes('name') && (
            <Col span={12}>
              <InputComponent
                required
                label={`${t('Common_Data.Name')}`}
                name='name'
                placeholder={`${t('Reports.Report')} ${t('Common_Data.Name')}`}
                onChange={handleChange}
                tooltip={`${t('Reports.Report_Name_Tooltip')}.`}
                rules={vsmTransactionReport.validation.name}
                maxLength={100}
                autoComplete='off'
              />
            </Col>
          )}
          {formDataList.includes('code') && (
            <Col span={12}>
              <InputComponent
                required
                label={`${t('Reports.Code')}`}
                name='code'
                placeholder={`${t('Reports.Report')} ${t('Reports.Code')}`}
                onChange={handleChange}
                tooltip={`${t('Reports.Code_Tooltip')}.`}
                rules={vsmTransactionReport.validation.code}
                maxLength={10}
              />
            </Col>
          )}
          {formDataList.includes('description') && (
            <Col span={12}>
              <InputComponent
                className='default_size_textarea'
                required
                type='textarea'
                rows={4}
                label={`${t('Common_Data.Description')}`}
                name='description'
                placeholder={`${t('Reports.Report')} ${t('Common_Data.Description')}`}
                onChange={handleChange}
                tooltip={`${t('Reports.Description_Tooltip')}.`}
                rules={vsmTransactionReport.validation.description}
                maxLength={1000}
              />
            </Col>
          )}
          {(formDataList.includes(`filter_id`) ||
            formDataList.includes(`global_toggle`)) && (
              <Col span={12}>
                <Row gutter={6}>
                  {formDataList.includes(`filter_id`) && (
                    <Col span={24} style={{ marginTop: '13px' }}>
                      <InputComponent
                        type='select'
                        label={`${t('Reports.Create')} ${t('Reports.Report')} ${t('Common_Data.From')} ${t('Common_Data.Page')} ${t('Reports.Filter')}`}
                        name={`filter_id`}
                        placeholder={`${t('Reports.Create')} ${t('Reports.Report')} ${t('Common_Data.From')} ${t('Common_Data.Page')} ${t('Reports.Filter')}`}
                        tooltip={`${t('Reports.Create_Report_Tooltip')}.`}
                        onChange={(e) => {
                          handleChange();
                        }}
                        showArrow
                        allowClear
                        options={{
                          values: saveFilterList,
                          value_key: 'id',
                          text_key: 'filter',
                        }}
                      />
                    </Col>
                  )}
                  {formDataList.includes(`global_toggle`) && (
                    <>
                      <Col span={12}>
                        <Form.Item name='global_toggle' valuePropName='checked'>
                          <Checkbox>{t('Common_Data.Save')} {t('Reports.As')} {t('Reports.Private')} {t('Reports.Report')} ?</Checkbox>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {formDataList.includes(`waste`) && (
                    <Col span={12}>
                      <Form.Item name='waste' valuePropName='checked'>
                        <Checkbox>{t('Reports.Include')} {t('Reports.Waste')} {t('Common_Data.Records')} ?</Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
        </Row>
        {!form?.getFieldValue(`filter_id`) ? (
          <>
            {formDataList.includes('related_item_type') && (
              <>
                <Row gutter={24}>
                  <Col span={8}>
                    <InputComponent
                      type='radio_button'
                      label='Get Items of'
                      name='related_item_type'
                      tooltip={`Get Items of.`}
                      onChange={() => {
                        form.resetFields([
                          'related_item_ticket_id',
                          'related_item_leads_id',
                          'related_item_contacts_id',
                          'related_item_organisations_id',
                        ]);
                        handleChange();
                      }}
                      options={{
                        values: relatedItemTypeList,
                      }}
                    />
                  </Col>

                  <Col span={16}>
                    <RelatedItemsForElement
                      formDataList={formDataList}
                      typeValue={form.getFieldValue('related_item_type')}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      tooltip='Related to'
                      label='Related to'
                      name='include_related_item_types'
                      rules={vsmTransactionReport.validation.include_related_item_types}
                    >
                      <Checkbox.Group
                        className='relatedItemReport'
                        name='include_related_item_types'
                        options={relatedItemCheckboxOptions}
                        onChange={(e) => handleChange()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            {/* User List */}
            <Row gutter={24}>
              {formDataList.includes('user_group_list') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.User')} ${t('Common_Data.Groups')}`}
                    type='select'
                    mode='multiple'
                    name='user_group_list'
                    placeholder={`${t('Common_Data.Choose')} ${t('Common_Data.User')} ${t('Common_Data.Group')}`}
                    allowClear
                    showArrow
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: SavedReportStore.dropdown_userGroupList,
                      value_key: 'id',
                      text_key: 'group_name',
                    }}
                    tooltip={`${t('Reports.User_Group_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('users_list') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('ManageAllDepartments.Users')}`}
                    type='select'
                    mode='multiple'
                    name='users_list'
                    placeholder={`${t('Reports.All')} ${t('ManageAllDepartments.Users')}`}
                    allowClear
                    showArrow
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: LEADSSTORE.report_dropdown_users_list,
                      value_key: 'id',
                      text_key: 'name',
                    }}
                    tooltip={`${t('Reports.Users_Tooltip')}.`}
                  />
                </Col>
              )}
            </Row>

            {/* Sold Name/Classification/SOLD Type */}

            <Row gutter={24}>
              {formDataList.includes('opportunity_name') && (
                <Col span={12}>
                  <InputComponent
                    name='opportunity_name'
                    label={`${t('Common_Data.SOLD_Name')}`}
                    placeholder={`${t('Common_Data.SOLD_Name')}`}
                    type='text'
                    tooltip={`${t('Reports.Three_Words')}.`}
                    onChange={handleChange}
                    maxLength={100}
                  />
                </Col>
              )}
              {formDataList.includes('classification_id') && (
                <Col span={6}>
                  <InputComponent
                    name='classification_id'
                    placeholder={`${t('Reports.All')}`}
                    label={
                      <span
                        className='converbillLink'
                        onClick={() => {
                          window.open(
                            'https://www.convertibill.com/credebtclassifications-popup.html',
                            '_blank',
                            'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                          );
                        }}
                      >
                        {t('Common_Data.Credebt_Classification')}
                      </span>
                    }
                    type='select'
                    mode='multiple'
                    allowClear
                    showArrow
                    showSearch
                    onChange={handleChange}
                    options={{
                      values: classification_role_list,
                      value_key: 'id',
                      text_key: 'name',
                    }}
                    onFocus={() =>
                      fetchClassification &&
                      getClassificationList().then(() => setFetchClassification(false))
                    }
                    notFoundContent={
                      fetchClassification ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                    tooltip={`${t('Common_Data.Classification_Group_Tooltip')}.`}
                    rules={vsmTransactionReport.validation.classification_id}
                  />
                </Col>
              )}
              {formDataList.includes('record_type') && (
                <Col span={form?.getFieldValue(`report_type`) === 'leads' ? 6 : 12}>
                  <InputComponent
                    type='select'
                    mode='multiple'
                    label={`${t('Common_Data.Sold')} ${t('Common_Data.Type')}`}
                    name='record_type'
                    placeholder={`${t('Common_Data.Choose')} ${t('Common_Data.Sold')} ${t('Common_Data.Type')}`}
                    tooltip={`${t('Common_Data.Sold_Record_Tooltip')}.`}
                    onChange={(e) => {
                      handleChange();
                      if (form?.getFieldValue(`report_type`) !== 'performance') {
                        handleRecordType(e);
                      }
                    }}
                    showArrow
                    allowClear
                    options={{
                      values: SavedReportStore.recordTypeList,
                      value_key: 'id',
                      text_key: 'record_type_name',
                    }}
                  />
                </Col>
              )}

              {/* Lead Type/SOLD Stages */}

              {formDataList.includes('parent_sold_stage') && (
                <Col span={12}>
                  <InputComponent
                    type='select'
                    mode='multiple'
                    label={
                      form?.getFieldValue(`report_type`) === 'performance'
                        ? `${t('Reports.Parent_Sold_Stage_Performance')}.`
                        : `${t('Common_Data.Parent')} ${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`
                    }
                    name='parent_sold_stage'
                    placeholder={`${t('Common_Data.Choose')} ${t('Common_Data.Parent')} ${t('Common_Data.Sold')} ${t('Common_Data.Stage')}`}
                    tooltip={`${t('Reports.Parent_Sold_Tooltip')}.`}
                    rules={vsmTransactionReport.validation.lead_type}
                    onChange={(e) => {
                      handleChange();
                      if (form?.getFieldValue(`report_type`) !== 'performance') {
                        handleLeadTypeChange(e);
                      }
                    }}
                    showArrow
                    allowClear
                    options={{
                      values: SavedReportStore.lead_type_list,
                      value_key: 'id',
                      text_key: 'status_name',
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('sold_stage') &&
                form.getFieldValue('parent_sold_stage') !== 0 && (
                  <Col span={12}>
                    <InputComponent
                      type='select'
                      mode='multiple'
                      placeholder={
                        form?.getFieldValue(`report_type`) === 'performance'
                          ? 'All SOLD Stages'
                          : 'All (Select Parent Stage or SOLD Type to filter)'
                      }
                      showArrow
                      allowClear
                      label={
                        form?.getFieldValue(`report_type`) === 'performance'
                          ? 'Select columns to display (SOLD Stages)'
                          : 'SOLD Stages'
                      }
                      name='sold_stage'
                      tooltip={`${t('Reports.Sold_Stage_Tooltip')}`}
                      onChange={handleChange}
                      options={{
                        values: SavedReportStore.lead_status_list,
                        value_key: 'id',
                        text_key: 'status_name',
                      }}
                    />
                  </Col>
                )}
            </Row>

            <Row gutter={24}>
              {formDataList.includes('organisation_type') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Organisation')}/s`}
                    type='select'
                    mode='multiple'
                    allowClear
                    showArrow
                    name='organisation_type'
                    placeholder={`${t('Reports.All')}`}
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: SavedReportStore.main_organisation,
                      value_key: 'organisation_id',
                      text_key: 'organisation_name',
                    }}
                    tooltip={`${t('Common_Data.People_Role_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('lead_id') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
                    type='select'
                    showSearch={true}
                    allowClear
                    showArrow
                    onSearch={handleSearchLead}
                    mode='multiple'
                    placeholder={`${t('Reports.All')}`}
                    notFoundContent={
                      SavedReportStore.fetching ? (
                        <Spin size='small' />
                      ) : searchLeadKey ? (
                        `${t('Common_Data.No_Record_Found')}.`
                      ) : null
                    }
                    name='lead_id'
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    options={{
                      values: SavedReportStore.lead_list_data,
                      value_key: 'id',
                      text_key: 'search_name',
                    }}
                    tooltip={`${t('Common_Data.People_Role_Tooltip')}.`}
                  />
                </Col>
              )}

              {/* Sold Product/Currency/SOLD Source/SOLD Source Details */}

              {formDataList.includes('product_name') && (
                <Col span={12}>
                  <InputComponent
                    name='product_name'
                    label={`${t('Common_Data.Sold')} ${t('Reports.Product')}/${t('Reports.Service')}`}
                    placeholder={`${t('Reports.All')}`}
                    type='select'
                    mode='multiple'
                    onChange={handleChange}
                    allowClear
                    showArrow
                    showSearch
                    tooltip={`${t('Reports.Choose_To_Product_Created')}.`}
                    rules={vsmTransactionReport.validation.product_name}
                    onFocus={() =>
                      fetchProductList &&
                      getProductList().then(() => setFetchProductList(false))
                    }
                    notFoundContent={
                      fetchProductList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                    options={{
                      values: product_list,
                      value_key: 'product_name',
                      text_key: 'product_name',
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('lead_currency_id') && (
                <Col span={6}>
                  <InputComponent
                    name='lead_currency_id'
                    label={`${t('Common_Data.Currency')}`}
                    allowClear
                    showArrow
                    showSearch
                    mode='multiple'
                    placeholder={`${t('Common_Data.Currency')}`}
                    type='select'
                    tooltip={`${t('Common_Data.Currency_Tooltip')}.`}
                    onChange={handleChange}
                    onFocus={() =>
                      fetchCurrencyList &&
                      LEADSSTORE.getCurrencyList().then(() => setFetchCurrencyList(false))
                    }
                    notFoundContent={
                      fetchCurrencyList ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                    options={{
                      values: LEADSSTORE.dropdown_currency_list,

                      value_key: 'id',
                      text_key: 'currency_code',
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('lead_source') && (
                <Col span={6}>
                  <InputComponent
                    name='lead_source'
                    label={`${t('Common_Data.Sold')} ${t('Common_Data.Source')}`}
                    placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Source')}`}
                    mode='multiple'
                    type='select'
                    onChange={handleChange}
                    allowClear
                    showArrow
                    showSearch
                    tooltip={`${t('Reports.Sold_Source_Tooltip')}.`}
                    options={{
                      values: LEADSSTORE.leadSourceArray,
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('sold_source_details') && (
                <Col span={12}>
                  <InputComponent
                    name='sold_source_details'
                    label={`${t('Common_Data.Sold')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`}
                    placeholder={`${t('Common_Data.Sold')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`}
                    type='textarea'
                    rows={2}
                    onChange={handleChange}
                    tooltip={`Lead Source Details field is a picklist field which records where leads came from.`}
                  />
                </Col>
              )}

              {/* Deadline Date Leads */}
              {formDataList.includes('deadline_date_leads') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('LeadKanbanView.DeadLine_Date')}`}
                    type='date_range'
                    name='deadline_date_leads'
                    onChange={handleChange}
                    tooltip={`${t('Reports.Deadline_Tooltip')}.`}
                    rules={vsmTransactionReport.validation.deadline_date}
                  />
                </Col>
              )}
              {/* Turn Over/Investment Hours Range */}

              {formDataList.includes(`turn_over`) && (
                <Col span={12}>
                  <>
                    <InputComponent type='labelOnly' label={`${t('Common_Data.Turn_Over')}`} />
                    <InputComponent
                      type='range-bar'
                      name='turn_over'
                      tooltipVisible={false}
                      // onLabelChange={() => {

                      // }}
                      defaultValue={form?.getFieldValue('turn_over') || [0, 0]}
                      value={form?.getFieldValue('turn_over') || [0, 0]}
                      valueFormatter={true}
                      slider_width={'55%'}
                      step={1000}
                      max_value={max_value_turn_over}
                      max_label={
                        form?.getFieldValue('turn_over')
                          ? form?.getFieldValue('turn_over')[1] !== 0
                            ? form?.getFieldValue('turn_over')[1]
                            : max_value_turn_over
                          : max_value_turn_over
                      }
                      min_label={
                        form?.getFieldValue('turn_over')
                          ? form?.getFieldValue('turn_over')[0]
                          : 0
                      }
                      min_value={0}
                      onSliderChange={(val) => {
                        handleSliderChange(val, 'turn_over');
                        handleChange();
                      }}
                      disabled={false}
                      post_fix={' m.'}
                    />
                  </>
                </Col>
              )}
              {formDataList.includes(`investment_hours`) && (
                <Col span={12}>
                  <>
                    <InputComponent type='labelOnly' label={`${t('Common_Data.Time_Spent')}`} />
                    <InputComponent
                      type='range-bar'
                      slider_width={'70%'}
                      name='investment_hours'
                      defaultValue={form?.getFieldValue('investment_hours') || [0, 0]}
                      value={form?.getFieldValue('investment_hours') || [0, 0]}
                      valueFormatter={false}
                      tooltipVisible={false}
                      tipFormatter={(value) => {
                        return `${value} h`;
                      }}
                      max_label={
                        form?.getFieldValue('investment_hours')
                          ? form?.getFieldValue('investment_hours')[1] !== 0
                            ? form?.getFieldValue('investment_hours')[1]
                            : max_value_investment_hours
                          : max_value_investment_hours
                      }
                      min_label={
                        form?.getFieldValue('investment_hours')
                          ? form?.getFieldValue('investment_hours')[0]
                          : 0
                      }
                      max_value={max_value_investment_hours}
                      min_value={0}
                      onSliderChange={(val) => {
                        handleChange();
                        handleSliderChange(val, 'investment_hours');
                      }}
                      disabled={false}
                      post_fix={' h.'}
                    />
                  </>
                </Col>
              )}

              {formDataList.includes(`master_ticket_number`) && (
                <Col span={12}>
                  <InputComponent
                    name='master_ticket_number'
                    label={`${t('Common_Data.Master')} ${t('Reports.Ticket')}`}
                    type='select'
                    mode='multiple'
                    showSearch={true}
                    placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Master')} ${t('Reports.Ticket')}`}
                    tooltip={`${t('Reports.Master_Ticket_Tooltip')}.`}
                    onChange={handleChange}
                    allowClear
                    showArrow
                    options={{
                      values: dropdown_master_ticket,
                      value_key: 'id',
                      text_key: 'ticket_subject',
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('leads_tickets_status') && (
                <Col span={12}>
                  <InputComponent
                    name='leads_tickets_status'
                    mode='multiple'
                    label={`${t('Common_Data.Status')}`}
                    type='select'
                    allowClear
                    showArrow
                    placeholder={`${t('Reports.All')}`}
                    showSearch={false}
                    tooltip={`${t('Common_Data.Status_Active_Inactive')}.`}
                    onChange={handleChange}
                    options={{
                      values: dropdown_ticketstatus,
                      value_key: 'id',
                      text_key: 'status_name',
                    }}
                    rules={vsmTransactionReport.validation.tag_status_id}
                  />
                </Col>
              )}
              {formDataList.includes(`tag_subject`) && (
                <Col span={12}>
                  <InputComponent
                    name={`tag_subject`}
                    label={`${t('Common_Data.Subject')}`}
                    placeholder={`${t('Reports.Subject_Placeholder')}`}
                    type='textarea'
                    tooltip={`${t('Common_Data.Subject')}`}
                    onChange={handleChange}
                  />
                </Col>
              )}
              {formDataList.includes(`ticket_subject`) && (
                <Col span={12}>
                  <InputComponent
                    name={`ticket_subject`}
                    label={`${t('Common_Data.Subject')}`}
                    placeholder={`${t('Reports.Subject_Placeholder')}`}
                    type='textarea'
                    tooltip={`${t('Common_Data.Subject')}`}
                    onChange={handleChange}
                  />
                </Col>
              )}
              {formDataList.includes(`reminder_subject`) && (
                <Col span={12}>
                  <InputComponent
                    name={`reminder_subject`}
                    label={`${t('Common_Data.Subject')}`}
                    placeholder={`${t('Reports.Subject_Placeholder')}`}
                    type='textarea'
                    tooltip={`${t('Common_Data.Subject')}`}
                    onChange={handleChange}
                  />
                </Col>
              )}
              {formDataList.includes(`ticket_description`) && (
                <Col span={12}>
                  <InputComponent
                    name={`ticket_description`}
                    label={`${t('Common_Data.Description')}`}
                    placeholder={`${t('Reports.Description_Placeholder')}`}
                    type='textarea'
                    tooltip={`${t('Common_Data.Description')}`}
                    onChange={handleChange}
                  />
                </Col>
              )}
              {formDataList.includes(`reminder_description`) && (
                <Col span={12}>
                  <InputComponent
                    name={`reminder_description`}
                    label={`${t('Common_Data.Description')}`}
                    placeholder={`${t('Reports.Description_Placeholder')}`}
                    type='textarea'
                    tooltip={`${t('Common_Data.Description')}`}
                    onChange={handleChange}
                  />
                </Col>
              )}
              {formDataList.includes(`ticket_number`) && (
                <Col span={12}>
                  <InputComponent
                    name={`ticket_number`}
                    label={`${t('Reports.Ticket')} ${t('Common_Data.Number')}`}
                    placeholder={`${t('Reports.Ticket_Placeholder')}`}
                    type='textarea'
                    tooltip={`${t('Reports.Ticket')} ${t('Common_Data.Number')}`}
                    onChange={handleChange}
                  />
                </Col>
              )}

              {/* Ticket Type/Priority Ticket */}
              {formDataList.includes('ticket_type') && (
                <Col span={12}>
                  <InputComponent
                    name='ticket_type'
                    label={`${t('Reports.Ticket')} ${t('Common_Data.Type')}`}
                    type='select'
                    mode='multiple'
                    allowClear
                    showArrow
                    showSearch={false}
                    placeholder={`${t('Reports.All')}`}
                    tooltip={`${t('Reports.Ticket_Type_Tooltip')}.`}
                    onChange={handleChange}
                    options={{
                      values: dropdown_ticket_type,
                    }}
                    rules={vsmTransactionReport.validation.ticket_type}
                  />
                </Col>
              )}

              {formDataList.includes('priority_tickets') && (
                <Col span={12}>
                  <InputComponent
                    name='priority_tickets'
                    label={`${t('Common_Data.Priority')}`}
                    mode='multiple'
                    type='select'
                    allowClear
                    showArrow
                    showSearch={false}
                    placeholder={`${t('Reports.All')}`}
                    tooltip={`${t('Reports.Priority_Tooltip')}.`}
                    onChange={handleChange}
                    options={{
                      values: dropdown_priority,
                    }}
                    rules={vsmTransactionReport.validation.priority}
                  />
                </Col>
              )}

              {/* Deadline Date Ticket/Tag */}

              {(formDataList.includes(`deadline_date_tickets`) ||
                formDataList.includes(`deadline_date_tags`)) && (
                  <Col span={12}>
                    <InputComponent
                      label={`${t('LeadKanbanView.DeadLine_Date')}`}
                      type='date_range'
                      name={`deadline_date_${form.getFieldValue('report_type')}`}
                      onChange={handleChange}
                      tooltip={`${t('Reports.Deadline_Tooltip')}.`}
                      rules={vsmTransactionReport.validation.deadline_date}
                    />
                  </Col>
                )}

              {formDataList.includes('tag_status_name') && (
                <Col span={12}>
                  <InputComponent
                    name='tag_status_name'
                    mode='multiple'
                    placeholder={`${t('Reports.All')}`}
                    label={`${t('Common_Data.Status')}`}
                    type='select'
                    allowClear
                    showArrow
                    showSearch={false}
                    tooltip={`${t('Common_Data.Status_Active_Inactive')}.`}
                    onChange={handleChange}
                    options={{
                      values: statusArr_list,
                    }}
                    rules={vsmTransactionReport.validation.tag_status_id}
                  />
                </Col>
              )}
              {formDataList.includes(`idle_ticket`) && (
                <>
                  <Col span={12}>
                    <InputComponent type='labelOnly' label={`${t('Reports.Idle')} ${t('Reports.Since')}`} />
                    <InputComponent
                      type='range-bar'
                      name='idle_ticket'
                      tooltipVisible={false}
                      defaultValue={form?.getFieldValue('idle_ticket') || [1, 1]}
                      value={form?.getFieldValue('idle_ticket') || [1, 1]}
                      valueFormatter={true}
                      slider_width={'70%'}
                      max_value={max_value_idle_ticket}
                      max_label={
                        form?.getFieldValue('idle_ticket')
                          ? form?.getFieldValue('idle_ticket')[1] !== 1
                            ? form?.getFieldValue('idle_ticket')[1]
                            : max_value_idle_ticket
                          : max_value_idle_ticket
                      }
                      min_label={
                        form?.getFieldValue('idle_ticket')
                          ? form?.getFieldValue('idle_ticket')[0]
                          : 1
                      }
                      min_value={1}
                      onSliderChange={(val) => {
                        handleSliderChange(val, 'idle_ticket');
                        handleChange();
                      }}
                      disabled={false}
                      post_fix={' days.'}
                    />
                  </Col>
                </>
              )}

              {/* Reminder Type/Priority Reminder */}
              {formDataList.includes('reminder_type') && (
                <>
                  <Col span={24}>
                    <InputComponent
                      name='reminder_type'
                      label={`${t('Common_Data.Reminder')} ${t('Common_Data.Type')}`}
                      type='select'
                      mode='multiple'
                      showSearch={false}
                      tooltip={`${t('Common_Data.Reminder')} ${t('Common_Data.Related')} ${t('Common_Data.To')}`}
                      placeholder={`${t('Reports.All')}`}
                      allowClear
                      showArrow
                      onChange={handleChange}
                      rules={vsmTransactionReport.validation.reminder_type}
                      options={{
                        values: reminderTypeList,
                      }}
                    />
                  </Col>

                  <Col span={24}>
                    <ReminderForElement
                      formDataList={formDataList}
                      typeValue={form.getFieldValue('reminder_type')}
                      onChange={handleChange}
                    />
                  </Col>
                </>
              )}

              {formDataList.includes('assign_to') && (
                <Col span={12}>
                  <InputComponent
                    name='assign_to'
                    label={`${t('Reports.Assigned')} ${t('Reports.To')}`}
                    mode='multiple'
                    placeholder={`${t('Common_Data.Select')} ${t('Reports.Assigned')} ${t('Reports.To')}`}
                    type='select'
                    showArrow
                    tooltip={`${t('Reports.Reminder_Tooltip')}`}
                    allowClear
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: LEADSSTORE.report_dropdown_users_list,
                      value_key: 'id',
                      text_key: 'name',
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('due_date_time') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Reports.Due')} ${t('Common_Data.Date')}`}
                    type='date_range'
                    name='due_date_time'
                    onChange={handleChange}
                    tooltip={`${t('Reports.Deadline_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('status') && (
                <Col span={12}>
                  <InputComponent
                    name='status'
                    label={`${t('Common_Data.Status')}`}
                    placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Status')}`}
                    type='select'
                    mode='multiple'
                    showArrow
                    tooltip={`${t('Reports.Status_Tooltip')}`}
                    allowClear
                    onChange={handleChange}
                    options={{
                      values: remindersStatus,
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('priority_name') && (
                <Col span={12}>
                  <InputComponent
                    name='priority_name'
                    label={`${t('Common_Data.Priority')}`}
                    mode='multiple'
                    allowClear
                    showArrow
                    type='select'
                    showSearch={false}
                    placeholder={`${t('Reports.All')}`}
                    tooltip={`${t('Reports.Priority_Tooltip')}.`}
                    onChange={handleChange}
                    options={{
                      values: reminder_priority,
                      id: 'value',
                      text_key: 'text',
                    }}
                    rules={vsmTransactionReport.validation.priority}
                  />
                </Col>
              )}

              {/* Organization/People */}

              {formDataList.includes(`sold_id_tags`) && (
                <Col span={12}>
                  <InputComponent
                    name={`sold_id_tags`}
                    label={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
                    placeholder={`${t('Reports.Sold_Id_Placeholder')}`}
                    type='textarea'
                    tooltip={`${t('Common_Data.Sold')} ${t('Common_Data.ID')}`}
                    onChange={handleChange}
                  />
                </Col>
              )}

              {formDataList.includes('lead_owner') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Owner')}`}
                    type='select'
                    mode='multiple'
                    name='lead_owner'
                    tooltip={`${t('Reports.Owner_Tooltip')}.`}
                    placeholder={`${t('Reports.All')}`}
                    showSearch
                    allowClear
                    showArrow
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: LEADSSTORE.report_dropdown_users_list,
                      value_key: 'id',
                      text_key: 'name',
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('owner') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Owner')}`}
                    type='select'
                    mode='multiple'
                    name='owner'
                    tooltip={`${t('Reports.Owner_Tooltip')}.`}
                    placeholder={`${t('Reports.All')}`}
                    showSearch
                    allowClear
                    showArrow
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: LEADSSTORE.report_dropdown_users_list,
                      value_key: 'id',
                      text_key: 'name',
                    }}
                  />
                </Col>
              )}

              {formDataList.includes('lead_holder') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Holder')}`}
                    type='select'
                    tooltip={`${t('Reports.Deal_Holder_Tooltip')}.`}
                    mode='multiple'
                    name='lead_holder'
                    placeholder={`${t('Reports.All')}`}
                    showSearch
                    allowClear
                    showArrow
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: LEADSSTORE.report_dropdown_users_list,
                      value_key: 'id',
                      text_key: 'name',
                    }}
                  />
                </Col>
              )}
              {formDataList.includes('tagholder') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Holder')}`}
                    type='select'
                    mode='multiple'
                    name='tagholder'
                    placeholder={`${t('Reports.All')}`}
                    showSearch
                    allowClear
                    showArrow
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: LEADSSTORE.report_dropdown_users_list,
                      value_key: 'id',
                      text_key: 'name',
                    }}
                    tooltip='The Holder may be the Tag Owner, or it can be another user.'
                  />
                </Col>
              )}
              {formDataList.includes('ticketholder') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Holder')}`}
                    type='select'
                    mode='multiple'
                    name='ticketholder'
                    placeholder={`${t('Reports.All')}`}
                    showSearch
                    allowClear
                    showArrow
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: LEADSSTORE.report_dropdown_users_list,
                      value_key: 'id',
                      text_key: 'name',
                    }}
                    tooltip='The Holder may be the Ticket Owner, or it can be another user.'
                  />
                </Col>
              )}
              {formDataList.includes('organisation') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Organisation')}/s`}
                    name='organisation'
                    placeholder={`${t('Reports.All')}`}
                    type='select'
                    mode='multiple'
                    showSearch
                    allowClear
                    showArrow
                    onChange={handleChange}
                    onSearch={handleOrgSearch}
                    notFoundContent={
                      ORGANISATION.fetching ? (
                        <Spin size='small' />
                      ) : searchOrgKey ? (
                        `${t('Common_Data.No_Record_Found')}.`
                      ) : null
                    }
                    options={{
                      values: ORGANISATION.dropdown_ROrg_list,
                      value_key: 'id',
                      text_key: 'entity_name',
                    }}
                    tooltip={`${t('Reports.Organisation_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('people') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.People')}/s`}
                    name='people'
                    placeholder={`${t('Reports.All')}`}
                    type='select'
                    mode='multiple'
                    showSearch
                    allowClear
                    showArrow
                    onSearch={handlePplSearch}
                    onChange={handleChange}
                    notFoundContent={
                      PEOPLE.fetching ? (
                        <Spin size='small' />
                      ) : searchPplKey ? (
                        `${t('Common_Data.No_Record_Found')}.`
                      ) : null
                    }
                    options={{
                      values: PEOPLE.drowpdown_people_list,
                      value_key: 'id',
                      text_key: 'entity_name',
                    }}
                    tooltip={`${t('Reports.People_Tooltip')}.`}
                  />
                </Col>
              )}

              {/* Lead Source/Lead Owner/Lead Holder  */}

              {formDataList.includes('lead_source_details') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Lead')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`}
                    name='lead_source_details'
                    placeholder={`${t('Common_Data.Lead')} ${t('Common_Data.Source')} ${t('Common_Data.Details')}`}
                    onChange={handleChange}
                    maxLength={100}
                    autoComplete='off'
                    tooltip={`${t('Reports.Lead_Source_Details_Tooltip')}.`}
                  />
                </Col>
              )}

              {/* Telephone/Email/Note  */}
              {formDataList.includes('phone') && (
                <Col span={12}>
                  <InputComponent
                    name={'phone'}
                    label={`${t('Common_Data.Telephone')}`}
                    onChange={handleChange}
                    placeholder={`${t('Common_Data.Phone_Number_Placeholder')}`}
                    type='text'
                    tooltip={`${t('Common_Data.Telephone')}`}
                    rules={vsmTransactionReport.validation.contact_value}
                    style={{ width: '100%' }}
                  />
                </Col>
              )}

              {formDataList.includes('email') && (
                <Col span={12}>
                  <InputComponent
                    name={'email'}
                    label={`${t('Common_Data.Email')}`}
                    tooltip={`${t('Common_Data.Email')}`}
                    onChange={handleChange}
                    placeholder={`${t('Common_Data.Enter_Your')} ${t('Common_Data.Email_ID')}`}
                    rules={vsmTransactionReport.validation.contact_for}
                    type='text'
                    style={{ width: '100%' }}
                  />
                </Col>
              )}

              {formDataList.includes('note') && (
                <Col span={24}>
                  <InputComponent
                    name='note'
                    label={`${t('Common_Data.Note')}`}
                    placeholder={`${t('Reports.Note_Placeholder')}`}
                    type='textarea'
                    tooltip={`${t('Common_Data.Note')}`}
                    onChange={handleChange}
                  />
                </Col>
              )}
              {/* Date Ranges (Created/Updated/Last Note On/Last Conversation On) */}

              {formDataList.includes('created_at') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Created')} ${t('Common_Data.On')}`}
                    type='date_range'
                    name='created_at'
                    onChange={handleChange}
                    tooltip={
                      form?.getFieldValue(`report_type`) === 'related_items'
                        ? 'A date when item was created.'
                        : 'A date when lead was created.'
                    }
                  />
                </Col>
              )}
              {formDataList.includes('completed_on') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Reports.Completed')} ${t('Common_Data.On')}`}
                    type='date_range'
                    name='completed_on'
                    onChange={handleChange}
                    tooltip={`${t('Reports.Completed_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('modified_at') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Modified')} ${t('Common_Data.On')}`}
                    type='date_range'
                    name='modified_at'
                    onChange={handleChange}
                    tooltip={`${t('Reports.Modified_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('last_note_on') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Last')} ${t('Common_Data.Note')} ${t('Common_Data.On')}`}
                    type='date_range'
                    name='last_note_on'
                    onChange={handleChange}
                    tooltip={`${t('Reports.Last_Note_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('last_conversation_on') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Last')} ${t('Reports.Conversation')} ${t('Common_Data.On')}`}
                    type='date_range'
                    name='last_conversation_on'
                    onChange={handleChange}
                    tooltip={`${t('Reports.Last_Conversation_Tooltip')}.`}
                  />
                </Col>
              )}
              {formDataList.includes('sort_by') && (
                <Col span={12}>
                  <InputComponent
                    name='sort_by'
                    label={`${t('Common_Data.Sort')} ${t('Common_Data.By')}`}
                    placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Sort')} ${t('Common_Data.By')}`}
                    type='select'
                    onChange={handleChange}
                    allowClear
                    showArrow
                    showSearch
                    //tooltip='A sold source is what turns a prospect into a potential customer.'
                    options={{
                      values: sortByArr,
                      value_key: 'value',
                      text_key: 'text',
                    }}
                  />
                </Col>
              )}

              {formDataList.includes('country') && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Common_Data.Country')}`}
                    type='select'
                    mode='multiple'
                    name='country'
                    placeholder={`${t('Reports.All')}`}
                    showSearch
                    allowClear
                    showArrow
                    tooltip={`${t('Common_Data.State_Nation_Territory_Tooltip')}.`}
                    onChange={() => {
                      handleChange();
                    }}
                    options={{
                      values: AUTH.global_country_list,
                      value_key: 'id',
                      text_key: 'country_name',
                    }}
                  />
                </Col>
              )}
              {/* 
              {formDataList.includes('lead_status_list') && (
                <Col span={12}>
                  <Form.Item
                    label='SOLD Stages'
                    rules={vsmTransactionReport.validation.lead_status_list}
                    tooltip='The deal status field is often a source of contention between Marketing and Sales within an organization. The field is typically used to communicate the status of a record to both the reporting users and the sales rep who is working the sold'
                  >
                    <Form.Item name='lead_status_list' noStyle>
                      <Select
                        className='w100'
                        allowClear
                        // onSearch={(val) => {
                        //   LEADSSTORE.onSOLDSearch(val)
                        // }}
                        optionFilterProp='children'
                        showArrow
                        placeholder='All'
                        showSearch
                        onChange={() => {
                          handleChange();
                        }}
                        mode='multiple'
                      >
                        {LEADSSTORE.dropdown_leadstatus_list &&
                        LEADSSTORE.dropdown_leadstatus_list.length
                          ? LEADSSTORE.dropdown_leadstatus_list.map((parent_status) => (
                              <OptGroup
                                key={parent_status.id}
                                label={parent_status.status_name}
                              >
                                {parent_status.child_leads.map((child_status) => (
                                  <Option value={child_status.id} key={child_status.id}>
                                    {child_status.status_name}
                                  </Option>
                                ))}
                              </OptGroup>
                            ))
                          : LEADSSTORE.editValues &&
                            LEADSSTORE.editValues.lead_status_id && (
                              <Option value={LEADSSTORE.editValues.lead_status_id.id}>
                                {LEADSSTORE.editValues.lead_status_id.status_name}
                              </Option>
                            )}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Col>
              )} */}

              {formDataList.includes(`ticket_id`) && (
                <Col span={12}>
                  <InputComponent
                    label={`${t('Reports.Ticket')} ${t('Common_Data.ID')}`}
                    type='select'
                    showSearch={true}
                    allowClear
                    showArrow
                    onSearch={handleSearchTicket}
                    mode='multiple'
                    placeholder={`${t('Reports.All')}`}
                    notFoundContent={
                      SavedReportStore.fetchingTicketSearch ? (
                        <Spin size='small' />
                      ) : searchTicketKey ? (
                        `${t('Common_Data.No_Record_Found')}.`
                      ) : null
                    }
                    name='ticket_id'
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    options={{
                      values: SavedReportStore.ticket_list_data,
                      value_key: 'id',
                      text_key: 'subjectwithnum',
                    }}
                    tooltip={`${t('Reports.Master_Ticket_Tooltip')}.`}
                  />
                </Col>
              )}

              {formDataList.includes('group_on') && (
                <Col span={12}>
                  <InputComponent
                    type='radio_button'
                    label={`${t('Common_Data.Group')} ${t('Common_Data.On')}`}
                    name='group_on'
                    tooltip={`${t('Reports.Group_Tooltip')}.`}
                    onChange={handleChange}
                    options={{
                      values: audit_group_onArr,
                    }}
                  />
                </Col>
              )}
            </Row>

            <Row>
              {/**START:  To Show Lable Only*/}
              {form?.getFieldValue(`report_type`) === 'performance' && (
                <Col span={24}>
                  <Form.Item
                    label='Include Counts for'
                    tooltip='Include Counts for'
                  ></Form.Item>
                </Col>
              )}
              {/**END:  To Show Lable Only*/}

              {formDataList.includes('calls_checkbox') && (
                <Col span={8}>
                  <Form.Item name='calls_checkbox' valuePropName='checked'>
                    <Checkbox defaultChecked>{t('Reports.Calls')}</Checkbox>
                  </Form.Item >
                </Col >
              )}
              {
                formDataList.includes('show_result_column') && (
                  <Col span={8}>
                    <Form.Item name='show_result_column' valuePropName='checked'>
                      <Checkbox defaultChecked>{t('Reports.Results')}</Checkbox>
                    </Form.Item >
                  </Col >
                )
              }
              {
                formDataList.includes(`leads_screen_count`) && (
                  <>
                    <Col span={8}>
                      <Form.Item name='leads_screen_count' valuePropName='checked'>
                        <Checkbox>Screens</Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )
              }
              {
                formDataList.includes(`leads_structure_count`) && (
                  <>
                    <Col span={8}>
                      <Form.Item name='leads_structure_count' valuePropName='checked'>
                        <Checkbox>Structures</Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )
              }
              {
                formDataList.includes(`leads_schedule_count`) && (
                  <>
                    <Col span={8}>
                      <Form.Item name='leads_schedule_count' valuePropName='checked'>
                        <Checkbox>Schedules</Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )
              }
              {
                formDataList.includes(`tag_created_count`) && (
                  <>
                    <Col span={8}>
                      <Form.Item name='tag_created_count' valuePropName='checked'>
                        <Checkbox>Tags Created</Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )
              }
              {
                formDataList.includes(`tag_assigned_to_count`) && (
                  <>
                    <Col span={8}>
                      <Form.Item name='tag_assigned_to_count' valuePropName='checked'>
                        <Checkbox>Tags Assigned</Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )
              }
              {
                formDataList.includes(`ticket_created_count`) && (
                  <>
                    <Col span={8}>
                      <Form.Item name='ticket_created_count' valuePropName='checked'>
                        <Checkbox>Tickets Created</Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )
              }
              {
                formDataList.includes(`ticket_assigned_to_count`) && (
                  <>
                    <Col span={8}>
                      <Form.Item name='ticket_assigned_to_count' valuePropName='checked'>
                        <Checkbox>Tickets Assigned</Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )
              }
            </Row >

            <Row>
              <Col span={24}>
                {formDataList.includes('generate_on') && (
                  <DateSelectionElementNew
                    SetDateRange={SetDateRange}
                    disabledDate={disabledDate}
                    formDataList={formDataList}
                    onChange={handleChange}
                    form={form}
                  />
                )}
              </Col>
            </Row>

            {/* Date Elements */}

            {
              formDataList.includes('period_selection') && (
                <PeriodSelectionElement
                  formDataList={formDataList}
                  onChange={handleChange}
                />
              )
            }

            {/* Combined Reports */}

            {
              formDataList.includes('combined_reports') && (
                <div className='w600'>
                  <h1>{t('Common_Data.Select')} {t('Reports.Multiple')} {t('Reports.Report')} {t('Reports.Below')}, {t('Common_Data.Up')} {t('Common_Data.To')} 12 {t('Common_Data.Reports')}</h1>
                  <CombinedReportsElement onChange={handleChange} form={form} />
                </div>
              )
            }

            {/* Audit Group */}

            {
              formDataList.includes('audit_group_on') && (
                <InputComponent
                  required
                  label={`${t('Reports.Audit')} ${t('Common_Data.Group')} ${t('Common_Data.On')}`}
                  onChange={handleChange}
                  type='radio_button'
                  name='audit_group_on'
                  options={{
                    values: audit_group_onArr,
                  }}
                  tooltip={`${t('Reports.Audit')} ${t('Common_Data.Group')} ${t('Common_Data.On')}`}
                />
              )
            }
          </>
        ) : null}

        {formButtons}
      </Form >
    );
  }
);

export default FormComponent;
