import { observer } from 'mobx-react';
import React from 'react';
import CardComponent from './component/CardComponent';
// import { Modal } from 'antd';
// import EditComponent from './component/EditComponent';

import useStore from '../../store';
const FoldersFilesModule = observer((props) => {
  const { AUTH } = useStore();
  // const [openModal, setOpenModal] = useState(false);
  // const [editModal, setEditModal] = useState(false);
  // const addFiles = (data) => {
  //   setOpenModal(true);
  // };
  // const editFiles = (data) => {
  //   setEditModal(true);
  // };
  return (
    AUTH.app_color_theme && (
      <>
        <CardComponent />
        {/* <CardComponent addFiles={addFiles} editFiles={editFiles} /> */}
        {/* <Modal
          //title="20px to Top"
          style={{ top: 20 }}
          visible={openModal}
          footer={false}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          width={1000}
          className='SnapNoteModal'
        >
          {'Add New Folders & Files'}
        </Modal>
        <Modal
          title='Manage Folders & Files'
          centered
          visible={editModal}
          footer={false}
          onOk={() => setEditModal(false)}
          onCancel={() => setEditModal(false)}
          width={1000}
          className='SnapNoteModal'
        >
          <EditComponent />
        </Modal> */}
      </>
    )
  );
});

export default FoldersFilesModule;
