import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { PageHeader, Button, Space, Form } from 'antd';
import ListComponent from './component/ListComponent';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import UnpublishComponent from './component/UnpublishComponent';
import { vsmNotify, vsmTransactionFields } from '../../../config/messages';
import RecordPerPage from '../../../config/RecordPerPage';
import Unauthorized from '../../Unauthorized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import EnterFullscreen from '../../../component/EnterFullscreen';
import { useTranslation } from 'react-i18next';
import TimeTracker from '../../../component/TimeTracker';

const TransactionFields = observer(() => {
  const {t} = useTranslation();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [unpublishModal, setUnpublishModal] = useState(false);
  const {
    TransactionFieldStore: {
      per_page,
      getAllRelations,
      setEditValues,
      setDeleteValues,
      setPageSize,
      exportData,
      TogglepublishData,
      setUnpublishValues,
      getAllFieldType,
      getAllFieldAlgorithm,
      agGrid,
      list_data,
      listLoading,
      handleReset
    },
    AUTH,
    SETTINGS
  } = useStore();
  const [form] = Form.useForm();

  // Open form for add new transaction field
  const openAddModal = () => {
    getAllFieldType();
    getAllFieldAlgorithm();
    setAddModal(true);
  };
  // Close form to add new transaction field
  const closeAddModal = () => setAddModal(false);

  // Open form for edit existing transaction field and set values to form
  const openEditModal = (data) => {
    getAllFieldType();
    getAllFieldAlgorithm();
    setEditValues(data);
    setEditModal(true);
  };

  // Close form to edit transaction field
  const closeEditModal = () => setEditModal(false);

  // Open confirmation alert before deleting existing transaction field
  const openDeleteModal = (data) => {
    setDeleteValues(data);
    setDeleteModal(true);
  };

  // Close confirmation alert for deleting record
  const closeDeleteModal = () => setDeleteModal(false);

  // Handle on switch data
  const onSwitchChange = (checked, data) => {
    if (!checked) {
      let formdata = { field_id: data.id };
      getAllRelations(formdata);
      agGrid.api.refreshCells({ force: true });
      setUnpublishValues(data);
      setUnpublishModal(true);
    } else {
      handlePublish(data);
      setUnpublishModal(false);
    }
  };

  // Handle Publish and call function to publish record
  const handlePublish = (data) => {
    let formdata = { id: data.id, status: true };
    TogglepublishData(formdata)
      .then(() => {
        vsmNotify.success({
          message: vsmTransactionFields.publish
        });
      })
      .catch(() => {
        agGrid.api.refreshCells({ force: true });
      });
  };

  // Close unpublish modal
  const closeUnpublishModal = () => {
    setUnpublishModal(false);
  };

  const onCellKeyDown = (e) => {
    if (AUTH.user && AUTH.user.bonus_figures && AUTH.user.bonus_figures.length > 0) {
      if (
        AUTH.user.bonus_figures[0].shortcut_keys &&
        AUTH.user.bonus_figures[0].shortcut_keys.length > 0
      ) {
        const result = AUTH.user.bonus_figures[0].shortcut_keys
          .filter((x) => x.key === e.event.key)
          .shift();
        if (result) {
          if (result.key === 'Enter' && AUTH.checkPermission(24, 'edit')) {
            openEditModal(e.data);
          }
          if (result.key === 'Delete' && AUTH.checkPermission(24, 'delete')) {
            openDeleteModal(e.data);
          }
        }
      }
    }
  };

  return (
    <>
      {!AUTH.checkPermission(24, 'list') ? (
        <Unauthorized />
      ) : (
        <PageHeader
          className={'has_export_spinner page-title ' + SETTINGS.fullscreen_class}
          title={`${t('DealBuilder.Deal_Fields')}`}
          extra={[
            <Form form={form} className='innerForm headerButton'>
              <Space size={5}>
                {AUTH.checkPermission(24, 'add') && (
                  <Button key='1' shape='round' size='medium' onClick={openAddModal}>
                   {t('DealBuilder.Add_Deal_Maker')}
                  </Button>
                )}
                {AUTH.checkPermission(24, 'export') && (
                  <Button
                    key='2'
                    onClick={exportData}
                    shape='round'
                    size='medium'
                    disabled={listLoading || (list_data && list_data.length === 0)}
                  >
                    {t('DealBuilder.Export')}
                  </Button>
                )}
                <Button
                  key='5'
                  title={`${t('Common_Data.Reset')}`}
                  shape='round'
                  size='medium'
                  onClick={handleReset}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <EnterFullscreen key='3' />
                <RecordPerPage
                  key='4'
                  initialValue={per_page + ' per page'}
                  onChange={setPageSize}
                />
              </Space>
            </Form>
          ]}
        >
          <ListComponent
            openEditModal={openEditModal}
            openDeleteModal={openDeleteModal}
            onSwitchChange={onSwitchChange}
            onCellKeyDown={onCellKeyDown}
          />
          <AddComponent visible={addModal} close={closeAddModal} />
          <EditComponent visible={editModal} close={closeEditModal} />
          <DeleteComponent visible={deleteModal} close={closeDeleteModal} />
          <UnpublishComponent visible={unpublishModal} close={closeUnpublishModal} />
          <TimeTracker />
        </PageHeader>
      )}
    </>
  );
});

export default TransactionFields;
