import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Col, Divider, Form, Row } from 'antd';
import InputComponent from '../../../../component/InputComponent';
import { remindersStatus } from '../../../../utils/GlobalFunction';
import { useTranslation } from 'react-i18next';

const SummaryReminderElement = observer(({ onChange, formDataList, form }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={12} align='middle' className='mb-5'>
        <Col span={24}>
          <Divider orientation='left' orientationMargin='0' plain>
            {t('Common_Data.Reminders')}
          </Divider>
        </Col>
        <Col span={24}>
          <Row gutter={12} align='middle'>
            {formDataList.includes('reminder_sum_total') && (
              <Col span={4}>
                <Form.Item
                  className='m-0'
                  name='reminder_sum_total'
                  valuePropName='checked'
                >
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Total')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('reminder_created') && (
              <Col span={4}>
                <Form.Item
                  className='m-0'
                  name='reminder_created'
                  valuePropName='checked'
                >
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Created')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('reminder_assigned') && (
              <Col span={4}>
                <Form.Item
                  className='m-0'
                  name='reminder_assigned'
                  valuePropName='checked'
                >
                  <Checkbox onChange={onChange}>{t('Reports.Sum_Assigned')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('sum_by_reminder_status') && (
              <Col span={4}>
                <Form.Item
                  className='m-0'
                  name='sum_by_reminder_status'
                  valuePropName='checked'
                >
                  <Checkbox onChange={onChange}>{t('Reports.Sum_by_Status')}</Checkbox>
                </Form.Item>
              </Col>
            )}
            {formDataList.includes('reminder_status') && (
              <Col span={8}>
                <InputComponent
                  name='reminder_status'
                  rowclassName='m-0'
                  placeholder={t('Reports.Reminder_Status')}
                  type='select'
                  mode='multiple'
                  showArrow
                  tooltip={t('Reports.Status_Tooltip')}
                  allowClear
                  onChange={onChange}
                  options={{
                    values: remindersStatus,
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
});

export default SummaryReminderElement;
