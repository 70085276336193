import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Form, Row, Col, Spin } from 'antd';
import useStore from '../../../store';
//import moment from 'moment';
import InputComponent from '../../../component/InputComponent';
import { useTranslation } from 'react-i18next';

const ViewComponent = observer((props) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    AUTH,
    AUDITLOG,
    AUDITLOG: { auditValues },
  } = useStore();

  const close = () => {
    props.close();
  };

  // set the form values to edit
  useEffect(() => {
    if (props.visible) {
      if (props.visible) {
        setLoading(true);
        AUDITLOG.auditValues = null;
        AUDITLOG.getRecordDetail(props.id)
          .then((data) => {
            setLoading(false);
          })
          .catch((data) => {
            setLoading(false);
          });
      }
    }
  }, [props, AUDITLOG]);

  // set the form values to edit
  useEffect(() => {
    if (AUDITLOG.auditValues && props.visible) {
      form.setFieldsValue({
        id: AUDITLOG.auditValues.id,
        user_type: AUDITLOG.auditValues.user_type,
        user_id: AUDITLOG.auditValues.user_id,
        event: AUDITLOG.auditValues.event,
        auditable_type: AUDITLOG.auditValues.auditable_type,
        url: AUDITLOG.auditValues.url,
        ip_address: AUDITLOG.auditValues.ip_address,
        user_agent: AUDITLOG.auditValues.user_agent,
        tags: AUDITLOG.auditValues.tags,
        //created_at: moment(AUDITLOG.auditValues.created_at).format('YYYY-MM-DD'),
        created_at: AUTH.getLocalTime(AUDITLOG.auditValues.created_at,AUTH.global_fulldate_format),
        user:
          AUDITLOG.auditValues && AUDITLOG.auditValues.users
            ? AUDITLOG.auditValues.users.first_name +
            ' ' +
            AUDITLOG.auditValues.users.last_name
            : null,
      });
    }
  }, [AUTH, AUDITLOG.auditValues, form, props.visible]);

  return (
    <Drawer
      visible={props.visible}
      onClose={close}
      placement='right'
      width={'1050px'}
      title={`${t('Audit_Logs.View')} ${t('Audit_Logs.Audit_Logs')} -#${AUDITLOG.auditValues?.id}`}
      destroyOnClose={true}
      footer={[]}
    >
      <Spin size='large' spinning={loading} tip={`${t('Common_Data.Load')}`}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          id='addeditformAuditLogs'
          className='innerForm'
        >
          <div className='viewAuditLog'>
            <table cellPadding='0' cellSpacing='0' border='0'>
              <tbody>
                <tr>
                  <td width='25%' style={{ border: '0' }}></td>
                  <td width='25%' style={{ border: '0' }}></td>
                  <td width='25%' style={{ border: '0' }}></td>
                  <td width='25%' style={{ border: '0' }}></td>
                </tr>
                <tr>
                  <th>{t('Common_Data.ID')}</th>
                  <td>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='id'
                    />
                  </td>
                  <th>{t('Audit_Logs.User_Name')}</th>
                  <td>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='user'
                    />
                  </td>
                </tr>
                <tr>
                  <th>{t('Audit_Logs.User_Type')}</th>
                  <td>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='user_type'
                    />
                  </td>
                  <th>{t('Audit_Logs.Event')}</th>
                  <td>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='event'
                    />
                  </td>
                </tr>
                <tr>
                  <th>{t('Audit_Logs.Model')}</th>
                  <td>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='auditable_type'
                    />
                  </td>
                  <th>{t('Audit_Logs.Tag')}</th>
                  <td>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='tags'
                    />
                  </td>
                </tr>
                <tr>
                  <th>{t('Audit_Logs.IP_Address')}</th>
                  <td>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='ip_address'
                    />
                  </td>
                  <th>{t('Audit_Logs.Action_Taken_On')}</th>
                  <td>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='created_at'
                    />
                  </td>
                </tr>
                <tr>
                  <th>{t('Audit_Logs.URL')}</th>
                  <td colSpan='3'>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='url'
                    />
                  </td>
                </tr>
                <tr>
                  <th>{t('Audit_Logs.Browser_Info')}</th>
                  <td colSpan='3'>
                    <InputComponent
                      className='mb-0'
                      bordered={false}
                      disabled
                      name='user_agent'
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Row gutter={0}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
              <div className='LogDetails'>
                <div className='title'>
                  {t('Audit_Logs.What_Changed')} (
                  <span style={{ color: 'rgb(204, 0, 0)' }}>{t('Audit_Logs.Red_is_old_value')}</span> {t('Audit_Logs.And')}{' '}
                  <span style={{ color: 'rgb(25, 134, 0)' }}>{t('Audit_Logs.Green_is_new_value')}</span>)
                </div>
                <div className='content'>
                  <ol>
                    {AUDITLOG.auditValues &&
                      AUDITLOG.auditValues.old_values &&
                      typeof AUDITLOG.auditValues.old_values === 'object' &&
                      Object.keys(AUDITLOG.auditValues.old_values) ? (
                      Object.keys(AUDITLOG.auditValues.old_values).map((item, index) => {
                        if (
                          AUDITLOG.auditValues.new_values &&
                          AUDITLOG.auditValues.old_values[item] ===
                          AUDITLOG.auditValues.new_values[item]
                        ) {
                          return (
                            <>
                              <li key={index}>
                                <div className='green'>
                                  <span className='w25 text-center'>+</span> {item} :
                                  {AUDITLOG.auditValues.new_values[item] === false ||
                                    AUDITLOG.auditValues.new_values[item] === true
                                    ? AUDITLOG.auditValues.new_values[item].toString()
                                    : AUDITLOG.auditValues.new_values[item]}
                                </div>
                              </li>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <li key={index}>
                                <div className='red'>
                                  <span className='w25 text-center'>-</span>
                                  {item} :
                                  {AUDITLOG.auditValues.old_values[item] === false ||
                                    AUDITLOG.auditValues.old_values[item] === true
                                    ? AUDITLOG.auditValues.old_values[item].toString()
                                    : AUDITLOG.auditValues.old_values[item]}
                                </div>
                              </li>
                              <li key={index}>
                                <div className='green'>
                                  <span className='w25 text-center'>+</span>
                                  {item} :
                                  {AUDITLOG.auditValues.new_values &&
                                    (AUDITLOG.auditValues.new_values[item] === false ||
                                      AUDITLOG.auditValues.new_values[item] === true
                                      ? AUDITLOG.auditValues.new_values[item].toString()
                                      : AUDITLOG.auditValues.new_values[item])}
                                </div>
                              </li>
                            </>
                          );
                        }
                      })
                    ) : auditValues?.old_values !== auditValues?.new_values ? (
                      <>
                        <li>
                          <div className='red'>
                            <span className='w25 text-center'>-</span> {t('Audit_Logs.Old_Value')}:
                            {typeof auditValues?.old_values === 'object'
                              ? JSON.stringify(auditValues?.old_values)
                              : auditValues?.old_values
                                ? auditValues?.old_values
                                : ''}
                          </div>
                        </li>
                        <li>
                          <div className='green'>
                            <span className='w25 text-center'>+</span> {t('Audit_Logs.New_Value')} :
                            {typeof auditValues?.new_values === 'object'
                              ? JSON.stringify(auditValues?.new_values)
                              : auditValues?.new_values
                                ? auditValues?.new_values
                                : ''}
                          </div>
                        </li>
                      </>
                    ) : null}
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
});

export default ViewComponent;
