import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../../component/InputComponent';
import { vsmPeople } from '../../../../config/messages';
import useStore from '../../../../store';
import {
  Form,
  Row,
  Col,
  Upload,
  message,
  Divider,
  Spin,
  Button,
  Modal,
  Select,
  Input,
  Tooltip,
} from 'antd';
import moment from 'moment';
import PhoneElement from '../elements/PhoneElement';
import EmailElement from '../elements/EmailElement';
// import AddressElement from '../elements/AddressElement';
import AddressElementNew from '../elements/AddressElementNew';
import { PlusOutlined, PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import FileManager from '../../../FileManager';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const FormComponent = observer(
  ({
    handleSubmit,
    onChange,
    form,
    id,
    fileList,
    updateFileList,
    setAddAddress,
    addAddress,
    setImgchanged,
    openAddOrgModal,
    openEditOrgDrawer,
    isFromOrg = false,
  }) => {
    const { PEOPLE, ORGANISATION, AUTH } = useStore();
    const [imgDisabled, setImgDisabled] = useState(false);
    const [fetchIndustries, setFetchIndustries] = useState(true);
    const [openFileManager, setOpenFileManager] = useState(false);
    const [searchOrgKey, setSearchOrgKey] = useState();
    const [showOrgEditBtn, setShowOrgEditBtn] = useState(false);
    const [fetchStatus, setFetchStatus] = useState(true);
    const [fetchPeopleRole, setFetchPeopleRole] = useState(true);

    const documentList = [
      // {
      //   id: 1,
      //   name: 'document_name_1.pdf',
      // },
    ];
    const { t } = useTranslation()
    const onPreview = async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    };

    const eventProps = {
      onChange: ({ fileList: newFileList }) => {
        updateFileList(newFileList);
      },
      fileList,
      beforeUpload: (file) => {
        let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        let isLt1M = file.size / 1024 / 1024 < 1;

        if (!isJpgOrPng) {
          message.error(`${t('Common_Data.Upload_Valid_Image')}.`);
          setImgDisabled(true);
          return true;
        } else if (!isLt1M) {
          message.error(`${t('Common_Data.File_Size')} 500${t('Common_Data.Px')} x 500${t('Common_Data.Px')} ${t('Common_Data.And')} 1 ${t('Common_Data.Mb')}`);
          setImgDisabled(true);
          return true;
        } else {
          setImgchanged(true);
          updateFileList([file]);
          setImgDisabled(false);
          return false;
        }
      },
    };

    // Handle on remove image
    const onRemoveImage = () => {
      setImgchanged(true);
      form.setFields([{ name: 'logo_url', errors: [] }]);
      updateFileList([]);
      setImgDisabled(false);
    };

    const uploadButton = (
      <div>
        <PlusOutlined />
      </div>
    );

    const onFinish = (data) => {
      handleSubmit(data);
    };

    const disabledDate = (current) => {
      return current && current > moment().endOf('day');
    };
    const phoneTitle = (
      <>
        {t('Common_Data.Telephone')}
        <small className='ml-5'>
          ({t('Common_Data.To_Add_Extension')} : +353 x xxx-xxxx, ext. xxxx)
        </small>
      </>
    );

    const openFileManagerModal = () => {
      setOpenFileManager(false);
    };
    const closeFileManagerModal = () => {
      setOpenFileManager(false);
    };

    // search string for dropdown
    const handleOrgSearch = useMemo(() => {
      const loadOptions = (val) => {
        setSearchOrgKey(val);
        ORGANISATION.getAllROrg({ search_for: val, mapping_type: 1 });
      };

      return debounce(loadOptions, 500);
    }, [ORGANISATION]);

    return (
      <>
        <Form
          form={form}
          layout='vertical'
          id={id}
          onFinish={onFinish}
          initialValues={PEOPLE.initialValues}
          className='innerForm'
        >
          <Row gutter={15}>
            <Col span={18}>
              <Row gutter={15}>
                <Col span={3}>
                  <InputComponent
                    label={`${t('PeopleManagement.Salutation')}`}
                    type='select'
                    onChange={onChange}
                    // initialValue={'Mr.'}
                    options={{
                      values: [
                        { value: 'Mr.', text: `${t('PeopleManagement.Mr')}.` },
                        { value: 'Ms.', text: `${t('PeopleManagement.Ms')}.` },
                        { value: 'Mrs.', text: `${t('PeopleManagement.Mrs')}.` },
                        { value: 'Dr.', text: `${t('PeopleManagement.Dr')}.` },
                      ],
                    }}
                    name='salutation'
                    rules={vsmPeople.validation.salutation}
                    maxLength={100}
                  />
                </Col>
                <Col span={8}>
                  <InputComponent
                    required
                    label={`${t('Common_Data.First')} ${t('Common_Data.Name')}`}
                    placeholder={`${t('Common_Data.First')} ${t('Common_Data.Name')}`}
                    name='first_name'
                    onChange={onChange}
                    autoComplete='off'
                    rules={vsmPeople.validation.first_name}
                    maxLength={100}
                  />
                </Col>
                <Col span={8}>
                  <InputComponent
                    label={`${t('Common_Data.Last')} ${t('Common_Data.Name')}`}
                    placeholder={`${t('Common_Data.Last')} ${t('Common_Data.Name')}`}
                    name='last_name'
                    onChange={onChange}
                    autoComplete='off'
                    rules={vsmPeople.validation.last_name}
                    maxLength={100}
                  />
                </Col>
                <Col span={5}>
                  <InputComponent
                    allowClear
                    showSearch
                    showArrow
                    type='select'
                    label={`${t('Common_Data.Status')}`}
                    name='status_id'
                    placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Status')}`}
                    onChange={onChange}
                    tooltip={`${t('Common_Data.Status_Active_Inactive')}.`}
                    onFocus={() =>
                      fetchStatus &&
                      PEOPLE.getPeopleStatus().then(() => setFetchStatus(false))
                    }
                    notFoundContent={fetchStatus ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
                    options={{
                      values: PEOPLE.peopleStatusList,
                      value_key: 'id',
                      text_key: 'status_name',
                    }}
                  />
                </Col>

                <Col span={8}>
                  <InputComponent
                    label={`${t('Common_Data.Title')}`}
                    placeholder={`${t('PeopleManagement.Job')} ${t('Common_Data.Title')} / ${t('PeopleManagement.Job')} ${t('Common_Data.Description')}`}
                    name='title'
                    onChange={onChange}
                    autoComplete='off'
                    rules={vsmPeople.validation.title}
                    maxLength={100}
                  />
                </Col>
                <Col span={6}>
                  <InputComponent
                    type='date'
                    name='date_of_birth'
                    onChange={onChange}
                    disabledDate={disabledDate}
                    label={`${t('Common_Data.Date')} ${t('Common_Data.Of')} ${t('PeopleManagement.Birth')}`}
                    placeholder={`${t('Common_Data.Date')} ${t('Common_Data.Of')} ${t('PeopleManagement.Birth')}`}
                    rules={vsmPeople.validation.date_of_birth}
                    tooltip={`${t('PeopleManagement.Birth_Tooltip')}.`}
                  />
                </Col>
                <Col span={5}>
                  <InputComponent
                    label={`${t('Common_Data.Pronounced')}`}
                    placeholder={`${t('PeopleManagement.Phonetic_Tooltip')}`}
                    name='pronounced'
                    onChange={onChange}
                    tooltip={`${t('PeopleManagement.Represent_Sounds')}. "${t('PeopleManagement.Phonetically')}" ${t('PeopleManagement.Enter_As')} "${t('PeopleManagement.Fo_Net')}"`}
                    autoComplete='off'
                    // rules={vsmPeople.validation.last_name}
                    maxLength={100}
                  />
                </Col>
                <Col span={5}>
                  <InputComponent
                    allowClear
                    showSearch
                    showArrow
                    type='select'
                    label={t('OrganisationManagement.Role')}
                    name='types'
                    placeholder={`${t('Common_Data.Select')} ${t('OrganisationManagement.Role')}`}
                    onChange={onChange}
                    tooltip={`${t('Common_Data.Select')} ${t('OrganisationManagement.Role')}`}
                    mode='multiple'
                    onFocus={() =>
                      fetchPeopleRole &&
                      PEOPLE.getPeopleRoles().then(() => setFetchPeopleRole(false))
                    }
                    notFoundContent={
                      fetchPeopleRole ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                    options={{
                      values:
                        PEOPLE.dropdown_pplRole_list ||
                        (PEOPLE.peopleValues && PEOPLE.peopleValues?.role_types_list),
                      value_key: 'id',
                      text_key: 'role_name',
                    }}
                  />
                </Col>

                <Col span={8}>
                  <InputComponent
                    label={`${t('PeopleManagement.Tax')} ${t('Common_Data.ID')} ${t('PeopleManagement.Document')}`}
                    tooltip={`${t('PeopleManagement.Tax_Id_Tooltip')}.`}
                    type='labelOnly'
                  />
                  <div className='suffixIcon'>
                    <Form.Item>
                      <Select
                        label={`${t('PeopleManagement.Test')}`}
                        placeholder={`${t('Common_Data.Select')} ${t('PeopleManagement.Document')} ${t('Common_Data.Type')}`}
                        className='selfUpdatingOptions'
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <div>
                              <Form className='innerForm addOption'>
                                <div>
                                  <Form.Item>
                                    <Input maxLength={50} />
                                  </Form.Item>
                                  <Button
                                    type='primary'
                                    size='small'
                                  // onClick={addItem}
                                  >
                                    <PlusOutlined /> {t('Common_Data.Add')} {t('PeopleManagement.Item')}
                                  </Button>
                                </div>
                              </Form>
                            </div>
                          </>
                        )}
                        disabled={true}
                      >
                        <Select.Option value='Document Type 1'>
                          {t('PeopleManagement.Document')} {t('Common_Data.Type')} 1
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <PlusCircleOutlined
                      className='extraFromIcon mr-5 cursorPointer'
                      onClick={openFileManagerModal}
                    />
                    {/* <EditOutlined className='extraFromIcon' /> */}
                  </div>
                </Col>
                <Col span={6}>
                  <InputComponent
                    label={`${t('PeopleManagement.Tax')} ${t('Common_Data.ID')} ${t('Common_Data.Number')}`}
                    placeholder={`${t('PeopleManagement.Tax')} ${t('Common_Data.ID')} ${t('Common_Data.Number')}`}
                    name='tax_id_number'
                    onChange={onChange}
                    tooltip={`${t('PeopleManagement.Tax_Id_Number_Tooltip')}.`}
                    autoComplete='off'
                    // rules={vsmPeople.validation.last_name}
                    maxLength={100}
                    disabled={true}
                  />
                </Col>
                <Col span={10}>
                  <InputComponent
                    label={`${t('Common_Data.Other')} ${t('Common_Data.Last')} ${t('Common_Data.Name')}`}
                    placeholder={`${t('PeopleManagement.Previous')}/${t('Common_Data.Other')} ${t('Common_Data.Last')} ${t('Common_Data.Name')}`}
                    name='other_last_name'
                    onChange={onChange}
                    tooltip={`${t('PeopleManagement.Other_Last_Name_Tooltip')}.`}
                    autoComplete='off'
                    // rules={vsmPeople.validation.last_name}
                    maxLength={100}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Form.Item
                // label={photoTitle}
                label={`${t('PeopleManagement.Photo')}`}
                className='mb-0'
                tooltip={`${t('PeopleManagement.Drag_Drop')} 500${t('Common_Data.Px')} x 500 ${t('Common_Data.Px')} ${t('Common_Data.In')} ${t('PeopleManagement.JPEG_PNG_Format')}`}
                name='logo_url'
              >
                <Upload
                  accept='.png, .jpeg'
                  fileList={fileList}
                  onRemove={onRemoveImage}
                  onPreview={onPreview}
                  listType='picture-card'
                  multiple={false}
                  showUploadList={true}
                  {...eventProps}
                  disabled={imgDisabled}
                  onChange={onChange}
                  maxCount={1}
                  className='uploadContract square'
                >
                  {fileList.length >= 1 ? null : uploadButton} +
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row gutter={15}>
                <Col span={5}>
                  <InputComponent
                    label={`${t('PeopleManagement.Proof')} ${t('Common_Data.Of')} ${t('PeopleManagement.Identity')}`}
                    tooltip={`${t('PeopleManagement.Proof_Tooltip')}.`}
                    type='labelOnly'
                  />
                  <div className='suffixIcon'>
                    <Form.Item>
                      <Select
                        label={`${t('PeopleManagement.Test')}`}
                        placeholder={`${t('Common_Data.Select')} ${t('PeopleManagement.Proof')} ${t('Common_Data.Of')} ${t('PeopleManagement.Identity')}`}
                        className='selfUpdatingOptions'
                        disabled={true}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <div>
                              <Form className='innerForm addOption'>
                                <div>
                                  <Form.Item>
                                    <Input maxLength={50} />
                                  </Form.Item>
                                  <Button
                                    type='primary'
                                    size='small'
                                  // onClick={addItem}
                                  >
                                    <PlusOutlined onClick={openFileManagerModal} />{t('Common_Data.Add')}
                                    {t('PeopleManagement.Item')}
                                  </Button>
                                </div>
                              </Form>
                            </div>
                          </>
                        )}
                      >
                        <Select.Option value='Proof of Identity 1'>
                          {t('PeopleManagement.Proof')} {t('Common_Data.Of')} {t('PeopleManagement.Identity')} 1
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <PlusCircleOutlined
                      className='extraFromIcon mr-5 cursorPointer'
                      onClick={openFileManagerModal}
                    />
                    {/* <EditOutlined className='extraFromIcon' /> */}
                  </div>
                </Col>
                <Col span={5}>
                  <InputComponent
                    label={`${t('PeopleManagement.Proof')} ${t('Common_Data.Of')} ${t('Common_Data.Address')}`}
                    tooltip={`${t('PeopleManagement.Proof_Address_Tooltip')}.`}
                    type='labelOnly'
                  />
                  <div className='suffixIcon'>
                    <Form.Item>
                      <Select
                        label={`${t('PeopleManagement.Test')}`}
                        placeholder={`${t('Common_Data.Select')} ${t('PeopleManagement.Proof')} ${t('Common_Data.Of')} ${t('Common_Data.Address')}`}
                        className='selfUpdatingOptions'
                        disabled={true}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <div>
                              <Form className='innerForm addOption'>
                                <div>
                                  <Form.Item>
                                    <Input maxLength={50} />
                                  </Form.Item>
                                  <Button
                                    type='primary'
                                    size='small'
                                  // onClick={addItem}
                                  >
                                    <PlusOutlined /> {t('Common_Data.Add')} {t('PeopleManagement.Item')}
                                  </Button>
                                </div>
                              </Form>
                            </div>
                          </>
                        )}
                      >
                        <Select.Option value='Proof of Address 1'>
                          {t('PeopleManagement.Proof')} {t('Common_Data.Of')} {t('Common_Data.Address')} 1
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <PlusCircleOutlined
                      className='extraFromIcon mr-5 cursorPointer'
                      onClick={openFileManagerModal}
                    />
                    {/* <EditOutlined className='extraFromIcon' /> */}
                  </div>
                </Col>
                <Col span={4}>
                  <InputComponent
                    label={`${t('Common_Data.Documents')}`}
                    type='labelOnly'
                    tooltip={`${t('PeopleManagement.Document_Tooltip')}.`}
                    className='ml-5'
                  />
                  <div className='suffixIcon' style={{ paddingTop: '8px' }}>
                    {documentList &&
                      documentList.slice(0, 6).map((doc, index) => {
                        return (
                          <Tooltip placement='top' title={doc.name}>
                            <img
                              className='extraFromIcon cursorPointer mr-5'
                              alt=''
                              src={AUTH.GetThemedImage('Preview_Report')}
                              style={{ height: '20px' }}
                              key={index}
                              onClick={openFileManagerModal}
                            />
                          </Tooltip>
                        );
                      })}

                    {/* <PlusCircleOutlined
                      className='extraFromIcon ml-10'
                      onClick={openFileManagerModal}
                    /> */}
                  </div>
                  {/* <Button
                    key='1'
                    form='addfrom'
                    htmlType='submit'
                    // type='primary'
                    outline
                    onClick={openFileManagerModal}
                    className='addDocumentBtn'
                  >
                    Add Document
                  </Button> */}
                </Col>
                {!isFromOrg &&
                  <Col span={5}>
                    <InputComponent
                      label={`${t('Common_Data.Organisation')} ${t('Common_Data.Name')}`}
                      type='labelOnly'
                      tooltip={`${t('PeopleManagement.Organisation_Tooltip')}.`}
                    />
                    <div className='suffixIcon'>
                      <InputComponent
                        className={
                          form.getFieldValue('organisation_name') ||
                            PEOPLE.editValues?.organisation_name ||
                            showOrgEditBtn
                            ? 'addEditInputEle editInputEle'
                            : 'addEditInputEle'
                        }
                        name='organisation_name'
                        placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Organisation')}`}
                        required
                        allowClear
                        type='select'
                        onChange={() => {
                          if (form.getFieldValue('organisation_name')) {
                            setShowOrgEditBtn(true);
                          } else {
                            setShowOrgEditBtn(false);
                          }
                          onChange();
                        }}
                        onSearch={handleOrgSearch}
                        notFoundContent={
                          ORGANISATION.fetching ? (
                            <Spin size='small' />
                          ) : searchOrgKey ? (
                            `${t('Common_Data.No_Record_Found')}.`
                          ) : null
                        }
                        options={{
                          values: ORGANISATION.dropdown_ROrg_list,
                          value_key: 'id',
                          text_key: 'entity_name',
                        }}
                      />

                      {AUTH.checkPermission(16, 'add') ? (
                        <PlusCircleOutlined
                          className='extraFromIcon mr-5'
                          onClick={openAddOrgModal}
                        />
                      ) : null}

                      {AUTH.checkPermission(16, 'edit') ? (
                        form.getFieldValue('organisation_name') ||
                          PEOPLE.editValues?.organisation_name ||
                          showOrgEditBtn ? (
                          <EditOutlined
                            className='extraFromIcon'
                            onClick={() => openEditOrgDrawer()}
                          />
                        ) : null
                      ) : null}
                    </div>
                  </Col>
                }
                <Col span={isFromOrg ? 10 : 5}>
                  <InputComponent
                    required
                    placeholder={`${t('Common_Data.Select')} ${t('Common_Data.Classification')}`}
                    label={<span
                      className='converbillLink'
                      onClick={() => {
                        window.open(
                          'https://www.convertibill.com/credebtclassifications-popup.html',
                          '_blank',
                          'location=yes,height=570,width=650,scrollbars=yes,status=yes'
                        );
                      }}
                    >
                      {t('Common_Data.Credebt_Classification')}
                    </span>}
                    type='select'
                    onChange={onChange}
                    rules={vsmPeople.validation.classification}
                    //mode='multiple'
                    allowClear
                    showSearch
                    notFoundContent={
                      fetchIndustries ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
                    }
                    options={{
                      values:
                        ORGANISATION.industries_list ||
                        (PEOPLE.peopleValues &&
                          PEOPLE.peopleValues.industry_id && [
                            {
                              id: PEOPLE.peopleValues.industry_id.id,
                              name: PEOPLE.peopleValues.industry_id.name,
                            },
                          ]),
                      value_key: 'id',
                      text_key: 'name',
                    }}
                    onFocus={() => {
                      fetchIndustries &&
                        ORGANISATION.getIndustries().then(() =>
                          setFetchIndustries(false)
                        );
                    }}
                    name='industry_id'
                    // tooltip='Classification is a group of productive enterprises or organisations that produce or supply goods, services, or sources of income.'
                    autoComplete='off'
                  />
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Form.Item
                label={phoneTitle}
                className='mb-10'
              // tooltip='Phone Type is the type, that would be associated with the Phone(Eg.: Primary, home, work)'
              >
                <div className='w-100'>
                  <Row>
                    <Col span={24}>
                      <PhoneElement
                        onChange={onChange}
                        form={form}
                        peopleValues={PEOPLE.peopleValues && PEOPLE.peopleValues}
                      />
                    </Col>
                  </Row>
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={`${t('Common_Data.Email')}`}
                className='mb-10'
                tooltip={`${t('PeopleManagement.Email_Type')}(${t('Common_Data.Eg')}.: ${t('PeopleManagement.Primary_Home_Work')}).`}
              >
                <div className='w-100'>
                  <Row>
                    <Col span={24}>
                      <EmailElement onChange={onChange} form={form} />
                    </Col>
                  </Row>
                </div>
              </Form.Item>
            </Col>

            <Divider className='mt-10 mb-15' />

            <Col span={12}>
              <AddressElementNew onChange={onChange} form={form} />
            </Col>
            <Col span={12}>
              <Row gutter={15}>
                <Col span={12} className='address_list'>
                  <div className='addMore'>
                    <PlusCircleOutlined className='mr-5 cursorPointer' />
                  </div>
                  <InputComponent
                    label={`${t('LeadListing.Relationships')}`}
                    tooltip={`${t('Common_Data.Select_Type_Relationship')} (${t('Common_Data.Created_Settings')})`}
                    type='labelOnly'
                  />
                  <Form.Item name={'Relationships'}>
                    <Select
                      label={`${t('PeopleManagement.Test')}`}
                      placeholder={`${t('LeadListing.Relationships')} ${t('Common_Data.Type')}`}
                      className='selfUpdatingOptions'
                      disabled={true}
                    >
                      <Select.Option value='Relationships Type 1'>
                        {t('LeadListing.Relationships')} {t('Common_Data.Type')} 1
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={`${t('PeopleManagement.Related')} ${t('Common_Data.Items')}`}
                    tooltip={`${t('Common_Data.View_Select')} (${t('Common_Data.View_Select_Tooltip')})`}
                    name={'related_items'}
                  >
                    <Select
                      placeholder={`${t('PeopleManagement.Related')} ${t('Common_Data.Items')}`}
                      className='selfUpdatingOptions'
                      disabled={true}
                    >
                      <Select.Option value='Related Items 1'>
                        {t('PeopleManagement.Related')} {t('Common_Data.Items')} 1
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <AddressElement onChange={onChange} form={form} /> */}
        </Form>
        <Modal
          centered
          title={`${t('PeopleManagement.Document')} ${t('PeopleManagement.Management')}`}
          visible={openFileManager}
          onCancel={closeFileManagerModal}
          onOk={closeFileManagerModal}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          width={'90%'}
          zIndex='1350'
          className='FileManager'
          //closeIcon={<img src={AUTH.GetThemedImage('CloseExpand')} alt='close' />}
          destroyOnClose={true}
          footer={[
            <Button key='1' htmlType='submit' type='primary' shape='round' size='medium'>
              {t('Common_Data.Update')}
            </Button>,
            <Button
              key='2'
              htmlType='button'
              shape='round'
              size='medium'
              onClick={closeFileManagerModal}
            >
              {t('Common_Data.Cancel')}
            </Button>,
          ]}
        >
          <FileManager addPeople={true} />
        </Modal>
      </>
    );
  }
);

export default FormComponent;
