import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import useStore from "../store";
import { vsmNotify, vsmDealDesk } from "../config/messages";
import {
  removeBGColorTinyMCE,
  text_patterns_tinyMCE,
  valid_elements_tinyMCE,
} from "../utils/GlobalFunction";
import { useTranslation } from "react-i18next";

const TinyComponent = (props) => {
  const { isKnowledge = false, autoSaveData = () => {} } = props;
  const {
    AUTH,
    DEALDESKKEYNOTES,
    DEALDESKSTORE: { deal_data },
  } = useStore();

  const { t } = useTranslation();

  // const fakeMoveEventForiFrame = () => {
  //   let ev = new MouseEvent("mousemove", {
  //     view: window,
  //     bubbles: true,
  //     cancelable: true,
  //   });
  //   // Send event
  //   let selection = document.querySelector("#notes_outbound_form textarea");
  //   if (selection) {
  //     document.querySelector("#notes_outbound_form textarea").dispatchEvent(ev);
  //   } else {
  //     selection = document.querySelector(".tox-editor-container");
  //     if (selection) {
  //       document
  //         .querySelector(".toxprocess.env-editor-container")
  //         .dispatchEvent(ev);
  //     }
  //   }
  // };

  const fakeMoveEventForiFrame = () => {
    let ev = new MouseEvent("mousemove", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    let selection = document.querySelector("#notes_outbound_form textarea");
    if (selection) {
      selection.dispatchEvent(ev);
    } else {
      selection = document.querySelector(".tox-editor-container");
      if (selection) {
        selection.dispatchEvent(ev);
      }
    }
  };

  const handleEditorChange = (e, editor) => {
    fakeMoveEventForiFrame();

    if (props.setEditorData) {
      props.setEditorData(e);
    }
    if (props.form && props.htmlName) {
      props.form.setFieldsValue({
        [props.htmlName]: e,
      });
      if (props.handleFormChange) {
        props.handleFormChange();
      }
    }
    autoSaveData(null);
  };

  const image_upload_handler = (blobInfo, success, failure, progress) => {
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    const hostUrl = process.env.React_APP_API_URL + "aws/s3/chat/image/upload";
    xhr.open("POST", hostUrl);

    let token = localStorage.getItem("token");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + token);
    xhr.setRequestHeader("fm", "dm");

    xhr.upload.onprogress = function (e) {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = function () {
      var json;
      var jsontemp;
      if (xhr.status === 403) {
        failure("HTTP Error: " + xhr.status, { remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure("HTTP Error: " + xhr.status);
        return;
      }
      //{ "location": "folder/sub-folder/new-location.png" }
      jsontemp = JSON.parse(xhr.responseText);
      json = { location: jsontemp.link };

      if (!json || typeof json.location != "string") {
        failure("Invalid JSON: " + xhr.responseText);
        return;
      }

      success(json.location);
    };

    xhr.onerror = function () {
      failure(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    formData = new FormData();
    formData.append("image", blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
  };

  const handleAddKeyNoteClick = (editor) => {
    let selectedText = editor.selection.getContent({ format: "html" });
    selectedText = selectedText.replace(/<[^>]+>/g, "");
    selectedText = selectedText.replace(/&nbsp;/g, "");
    let node = editor.selection.getContent();

    let itemsArr = selectedText
      ? [
          {
            type: "htmlpanel",
            html: `<b>${t("Editor_Module.Are_Sure_Add_Note")}</b>`,
          },
        ]
      : [
          {
            type: "htmlpanel",
            html: `<b>${t("Editor_Module.Please_select_text")}</b>`,
          },
        ];
    let buttonsArr = selectedText
      ? [
          {
            type: "submit",
            text: `${t("Common_Data.Save")}`,
            primary: true,
            class: "primarySave",
          },
          { type: "cancel", text: `${t("Common_Data.Cancel")}` },
        ]
      : [{ type: "cancel", text: `${t("Common_Data.Cancel")}` }];
    editor.windowManager.open({
      title: `${t("Editor_Module.Add_Key_Note")}`,
      body: {
        type: "panel",
        items: itemsArr,
      },
      buttons: buttonsArr,
      onSubmit: (api) => {
        let data = api.getData();

        if (data.title !== "" && data.title !== "") {
          //-------------------  Adding Key Icons ---------------------------//

          let keyIcons = `<img width="10px" style="margin-left:5px" src=${AUTH.GetThemedImage(
            "Key_Note"
          )} alt=${t(
            "Editor_Module.Add_Key_Note"
          )} class="toolicon keyNote" />${node}<img width="10px" style="margin-left:5px" src=${AUTH.GetThemedImage(
            "Key_Note"
          )} alt=${t(
            "Editor_Module.Add_Key_Note"
          )} class="toolicon keyNote" />`;
          editor.selection.setContent(keyIcons);
          // editor.execCommand('mceReplaceContent', false, keyIcons);

          //----------------------------------------------------------------//

          let keyNoteData = {
            key_subject: data.title,
            key_note: selectedText,
            lead_transcation_id:
              deal_data &&
              deal_data.lead_information &&
              deal_data.lead_information.id
                ? deal_data.lead_information.id
                : 0,
          };
          DEALDESKKEYNOTES.keyNoteSave(keyNoteData)
            .then((data) => {})
            .catch(({ response }) => {
              vsmNotify.error({
                message: vsmDealDesk.KeyNoteSave.saveError,
              });
            });

          api.close();
        } else {
          alert("Field is required.");
        }
      },
    });
  };

  const handleAddEmailLink = (editor) => {
    let selectedText = editor.selection.getContent({ format: "html" });
    selectedText = selectedText.replace(/<[^>]+>/g, "");
    let itemsArr = selectedText
      ? [
          {
            type: "input",
            name: "emailid",
            label: `${t("Editor_Module.Email_Address")}`,
          },
        ]
      : [
          {
            type: "htmlpanel",
            html: `<b>${t("Editor_Module.Please_select_text")}</b>`,
          },
        ];
    let buttonsArr = selectedText
      ? [
          {
            type: "submit",
            text: `${t("Common_Data.Save")}`,
            primary: true,
            class: "primarySave",
          },
          { type: "cancel", text: `${t("Common_Data.Cancel")}` },
        ]
      : [{ type: "cancel", text: `${t("Common_Data.Cancel")}` }];
    editor.windowManager.open({
      title: `${t("Editor_Module.Add_Email_Link")}`,
      body: {
        type: "panel",
        items: itemsArr,
      },
      buttons: buttonsArr,
      onSubmit: (api) => {
        let data = api.getData();
        if (data.emailid !== "" && data.emailid !== "") {
          if (selectedText) {
            editor.insertContent(
              `<a href="mailto:${data.emailid}?body=${selectedText}" target="_blank" class="emailLink" title="Email to ${data.emailid}">${selectedText}</a>`
            );
          }
          api.close();
        } else {
          alert("Field is required.");
        }
      },
    });
  };

  const handleAddSmsLink = (editor) => {
    let selectedText = editor.selection.getContent({ format: "html" });
    selectedText = selectedText.replace(/<[^>]+>/g, "");
    let itemsArr = selectedText
      ? [
          {
            type: "input",
            name: "whatsApp",
            label: `${t("Editor_Module.Whatsapp_Number_Placeholder")}`,
          },
        ]
      : [
          {
            type: "htmlpanel",
            html: `<b>${t("Editor_Module.Please_select_text")}</b>`,
          },
        ];
    let buttonsArr = selectedText
      ? [
          {
            type: "submit",
            text: `${t("Common_Data.Save")}`,
            primary: true,
            class: "primarySave",
          },
          { type: "cancel", text: `${t("Common_Data.Cancel")}` },
        ]
      : [{ type: "cancel", text: `${t("Common_Data.Cancel")}` }];
    editor.windowManager.open({
      title: `${t("Editor_Module.Add_WhatsApp_Link")}`,
      body: {
        type: "panel",
        items: itemsArr,
      },
      buttons: buttonsArr,
      onSubmit: (api) => {
        let data = api.getData();
        if (data.whatsApp !== "" && data.whatsApp !== "") {
          if (selectedText) {
            editor.insertContent(
              `<a href="https://wa.me/${data.whatsApp}?text=${selectedText}" target="_blank" class="smsLink" title="WhatsApp to ${data.whatsApp}">${selectedText}</a>`
            );
          }
          api.close();
        } else {
          alert("Field is required.");
        }
      },
    });
  };

  const handleToUpperCase = (editor) => {
    let selectedText = editor.selection.getContent({ format: "html" });

    selectedText = selectedText.toUpperCase();
    editor.insertContent(selectedText);
    // editor.execCommand('mceUpperCase');
  };

  const handleToLowerCase = (editor) => {
    // editor.execCommand('mceLowerCase');
    let selectedText = editor.selection.getContent({ format: "html" });
    selectedText = selectedText.toLowerCase();
    editor.insertContent(selectedText);
  };

  const handleAppendCrossButton = (editor) => {
    editor.insertContent("&#10013;");
  };

  const handleAppendPipeButton = (editor) => {
    editor.insertContent("&#124;");
  };

  const handleAddKnowledge = (editor) => {
    let selectedText = editor.selection.getContent({ format: "html" });
    let node = editor.selection.getContent();

    let itemsArr = selectedText
      ? [
          {
            type: "input",
            name: "title",
            label: `${t("DealDesk.Knowledge_Title")}`,
          },
          {
            type: "listbox", // component type
            name: "industry_id", // identifier
            label: `<a
                  style='color: #1890ff;
                  text-decoration: none;
                  background-color: transparent;
                  outline: none;
                  cursor: pointer;
                  transition: color 0.3s;
                  -webkit-text-decoration-skip: objects;'
                  href='https://www.convertibill.com/credebtclassifications-popup.html'
                  target='_blank'
                >
                ${t("Common_Data.Credebt_Classification")}
                </a>`,
            disabled: false, // disabled state
            items: props.dropdown_tinymce_industry_list
              ? props.dropdown_tinymce_industry_list
              : [],
          },
          {
            type: "listbox", // component type
            name: "knowledge_classification", // identifier
            label: `${t("DealDesk.Knowledge_Classification")}`,
            disabled: false, // disabled state
            items: props.dropdown_tinymce_knowledge_classification_list
              ? props.dropdown_tinymce_knowledge_classification_list
              : [],
          },
        ]
      : [
          {
            type: "htmlpanel",
            html: `<b>${t("Editor_Module.Please_select_text")}</b>`,
          },
        ];
    let buttonsArr = selectedText
      ? [
          {
            type: "submit",
            text: `${t("Common_Data.Save")}`,
            primary: true,
            class: "primarySave",
          },
          { type: "cancel", text: `${t("Common_Data.Cancel")}` },
        ]
      : [{ type: "cancel", text: `${t("Common_Data.Cancel")}` }];
    editor.windowManager.open({
      title: `${t("Common_Data.Add_Knowledge")}`,
      body: {
        type: "panel",
        items: itemsArr,
      },
      buttons: buttonsArr,

      onSubmit: (api) => {
        let data = api.getData();
        if (
          data.industry_id !== "" &&
          data.title !== "" &&
          data.knowledge_classification !== ""
        ) {
          //-------------------  Adding Knowledge Icons ---------------------------//

          let knowledgeIcons = `<img width="10px" style="margin-left:1px" src=${AUTH.GetThemedImage(
            "Knowledge"
          )} alt="Add Key Knowledge" class="toolicon" />${node}<img width="10px" style="margin-left:1px" src=${AUTH.GetThemedImage(
            "Knowledge"
          )} alt="Add Key Knowledge" class="toolicon" />`;

          editor.selection.setContent(knowledgeIcons);
          // editor.execCommand('mceReplaceContent', false, knowledgeIcons);

          //------------------------------------------------------------------------//
          selectedText = selectedText.replace(/<img[^>]*>/g, "");
          let noteKnowledgeData = {
            knowledge_subject: data.title,
            industry_id: parseInt(data.industry_id),
            knowledge_classification: data.knowledge_classification,
            description: selectedText,
          };
          DEALDESKKEYNOTES.saveNoteKnowledge(noteKnowledgeData)
            .then((data) => {})
            .catch(({ response }) => {
              vsmNotify.error({
                message: vsmDealDesk.noteKnowledgeSave.saveError,
              });
            });
          api.close();
        } else {
          alert("Field is required.");
        }
      },
    });
  };

  const handleColorChange = (editor, colorCode, color) => {
    editor.execCommand("mceApplyTextcolor", "forecolor", `${colorCode}`);
  };

  // const image_verification = (blobInfo, success, failure, progress) => {
  // }

  const defaultConfig = {
    selector: "textarea#full-featured-non-premium",
    plugins:
      "print preview paste importcss searchreplace autolink  save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
    imagetools_cors_hosts: ["picsum.photos"],
    initialValue: "<p>This is the initial content of the editor</p>",
    // menubar: 'file edit view insert format',
    menubar: false,
    statusbar: false,
    // extended_valid_elements: 'img[]',
    toolbar:
      "removeformat | bullist numlist | undo redo | forecolor backcolor | fontsizeselect  bold italic underline strikethrough  | charmap | formatselect | upperCaseButton lowerCaseButton | crossButton pipeButton  emoticons insertdatetime |  newdocument superscript subscript  selectall cut copy paste remove | lineheight | alignleft aligncenter alignright alignjustify indent outdent  ",
    insertdatetime_formats: [
      "%Y-%m-%d",
      "%D",
      "%H:%M:%S",
      "%I:%M:%S %p",
      "%A",
      "%B",
    ],
    textpattern_patterns: text_patterns_tinyMCE,
    toolbar_sticky: true,
    // autosave_ask_before_unload: true,
    // autosave_interval: '30s',
    // autosave_prefix: '{path}{query}-{id}-',
    // autosave_restore_when_empty: false,
    // autosave_retention: '2m',
    image_advtab: true,
    importcss_append: true,
    // valid_elements: valid_elements_tinyMCE,
    image_caption: true,
    quickbars_selection_toolbar:
      "addKeyNote addEmailLink addSmsLink addKnowledge link unlink | addRed addOrange addGreen addBlue addPurple addYellow",
    noneditable_noneditable_class: "mceNonEditable",
    toolbar_mode: "sliding",
    // contextmenu: 'link image imagetools table',
    contextmenu: false,
    skin: "oxide",
    paste_preprocess: function (plugin, args) {
      let newContent = args.content;
      args.content = removeBGColorTinyMCE(newContent); // Function for removing background color based on theme
    },
    paste_block_drop: true,
    content_css: "default",
    content_style:
      "body { font-family:Verdana,Arial,sans-serif; font-size:10pt;line-height:1.3; }",
    height: 600,
    setup: function (editor) {
      if (!isKnowledge) {
        editor.ui.registry.addButton("addKeyNote", {
          text: `<img src=${AUTH.GetThemedImage("Key_Note")} alt=${t(
            "Editor_Module.Add_Key_Note"
          )} class="toolicon" title=${t("Editor_Module.Key_Title")} />`,
          onAction: () => handleAddKeyNoteClick(editor),
        });
        editor.ui.registry.addButton("addEmailLink", {
          text: `<img src=${AUTH.GetThemedImage(
            "Mail"
          )} alt="Add Email Link" class="toolicon" title=${t(
            "Editor_Module.Email_Title"
          )} />`,
          onAction: () => handleAddEmailLink(editor),
        });
        editor.ui.registry.addButton("addSmsLink", {
          text: `<img src=${AUTH.GetThemedImage(
            "SMS"
          )} alt="Add SMS Link" class="toolicon" title=${t(
            "Editor_Module.Message_Title"
          )} />`,
          onAction: () => handleAddSmsLink(editor),
        });
        editor.ui.registry.addButton("addKnowledge", {
          text: `<img src=${AUTH.GetThemedImage(
            "Knowledge"
          )} alt="Add Knowledge" class="toolicon"  title=${t(
            "Editor_Module.Knowledge_Title"
          )} />`,
          onAction: () => handleAddKnowledge(editor),
        });
        editor.ui.registry.addButton("upperCaseButton", {
          text: '<span style="font-size: 18px;">AZ</span>',
          onAction: () => handleToUpperCase(editor),
        });
        editor.ui.registry.addButton("lowerCaseButton", {
          text: '<span style="font-size: 18px;">az</span>',
          onAction: () => handleToLowerCase(editor),
        });
        editor.ui.registry.addButton("addRed", {
          text: `<span title=${t(
            "Editor_Module.Highlight_Red"
          )} style="height:20px;width:20px;display:inline-block;margin-right:2px;background-color:rgb(224, 62, 45)"></span>`,
          onAction: () => handleColorChange(editor, "rgb(224, 62, 45)", "red"),
        });
        editor.ui.registry.addButton("addOrange", {
          text: `<span title=${t(
            "Editor_Module.Highlight_Orange"
          )} style="height:20px;width:20px;display:inline-block;margin-right:2px;background-color:rgb(230, 126, 35)"></span>`,
          onAction: () =>
            handleColorChange(editor, "rgb(230, 126, 35)", "orange"),
        });
        editor.ui.registry.addButton("addGreen", {
          text: `<span title=${t(
            "Editor_Module.Highlight_Green"
          )} style="height:20px;width:20px;display:inline-block;margin-right:2px;background-color:rgb(45, 194, 107)"></span>`,
          onAction: () =>
            handleColorChange(editor, "rgb(45, 194, 107)", "green"),
        });
        editor.ui.registry.addButton("addBlue", {
          text: `<span title=${t(
            "Editor_Module.Highlight_Blue"
          )} style="height:20px;width:20px;display:inline-block;margin-right:2px;background-color:rgb(53, 152, 219)"></span>`,
          onAction: () =>
            handleColorChange(editor, "rgb(53, 152, 219)", "blue"),
        });
        editor.ui.registry.addButton("addPurple", {
          text: `<span title=${t(
            "Editor_Module.Highlight_Purple"
          )} style="height:20px;width:20px;display:inline-block;background-color:rgb(185, 106, 217)"></span>`,
          onAction: () =>
            handleColorChange(editor, "rgb(185, 106, 217)", "purple"),
        });
        editor.ui.registry.addButton("addYellow", {
          text: `<span title=${t(
            "Editor_Module.Highlight_Yellow"
          )} style="height:20px;width:20px;display:inline-block;background-color:rgb(241, 196, 15)"></span>`,
          onAction: () =>
            handleColorChange(editor, "rgb(241, 196, 15)", "yellow"),
        });
        editor.ui.registry.addButton("crossButton", {
          text: '<span title="Cross" style="font-size: 18px;">✝</span>',
          onAction: () => handleAppendCrossButton(editor),
        });
        editor.ui.registry.addButton("pipeButton", {
          text: '<span title="Pipe" style="font-size: 18px;">ӏ</span>',
          onAction: () => handleAppendPipeButton(editor),
        });
      }
      // if(props.editorvalue){
      //   alert("hi")
      //   // editor.execCommand('RemoveFormat');
      //   editor.onload(function(event){
      //     alert("hello")
      //   })

      // }
    },
    init_instance_callback: function (editor) {
      // Shortcuts and useful things go here.
      editor.shortcuts.add("alt+d", "A New Date", function () {
        editor.execCommand("mceInsertDate");
      });
      editor.shortcuts.add("alt+t", "A New Time", function () {
        editor.execCommand("mceInsertTime");
      });
    },
    valid_elements: valid_elements_tinyMCE,
    extended_valid_elements: "span[*]",
    paste_data_images: true, // Allow to paste images
    images_upload_handler: image_upload_handler, // Function for image upload api
    paste_remove_styles_if_webkit: false, // Enable/Disable Styles on Copy Paste
    paste_as_text: false, // Enable/Disable for forcing text to simple text
    remove_redundant_brs: true, //Enable/Disable for removing br end of text
    convert_newlines_to_brs: true, // Enable/Disable for newline to br tags
    // remove_linebreaks:true,
    force_p_newlines: true, // Enable/Disable for on press enter create p tag or br deprecated in 3.5 version
    force_br_newlines: false, // Enable/Disable for on press enter/Shift + enter create  br deprecated in 3.5 version
    forced_root_block: false, // Enable/Disable If you set this option to false it will never produce p tags on enter or automatically it will instead produce br elements and Shift+Enter will produce a p.
    relative_urls: false,
    remove_script_host: false,
    document_base_url:
      window.location
        .origin /* For URLs with the same domain as the page containing the TinyMCE editor. If set to:
                              true — All URLs created in TinyMCE will be converted to a link relative to the document_base_url.
                              false — All URLs will be converted to absolute URLs. */,
  };

  return (
    <Editor
      apiKey={process.env.React_APP_TINY_API_KEY}
      // onChange={handleEditorChange}
      onEditorChange={handleEditorChange}
      init={{ ...defaultConfig, ...props.EditorConfig }}
      initialValue={props.editorvalue}
      key={props.resetContent}
      onPaste={handleEditorChange}
      onFocus={() => {
        if (props?.clearVisiblePopover) {
          props?.clearVisiblePopover();
        }
      }}
    />
  );
};

export default TinyComponent;
