import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Form } from 'antd';
import useStore from '../../../store';
import FormComponent from './FormComponent';
import { vsmNotify, vsmTickertap } from '../../../config/messages';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

const AddComponent = observer((props) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { TICKERTAPBROADCAST, AUTH } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
  };

  const handleSubmit = (data) => {
    setSaving(true);

    TICKERTAPBROADCAST.AddData(data)
      .then(() => {
        close();
        AUTH.getTickerHeaderInfo();
        vsmNotify.success({
          message: vsmTickertap.add,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  return (
    <>
      <Modal
        centered
        title={`${t('Common_Data.Add')} ${t('TickerTapBroadcast.New')} ${t('TickerTapBroadcast.Information')}`}
        visible={props.visible}
        onCancel={close}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button
            form='addform'
            className='mr-10'
            loading={saving}
            htmlType='submit'
            type='primary'
            shape='round'
            size='medium'
            disabled={isDisabled}
          >
            {t('Common_Data.Save')}
          </Button>,
          <Button shape='round' onClick={close}>
            {t('Common_Data.Cancel')}
          </Button>,
        ]}
        width={500}
      >
        <FormComponent
          form={form}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          id='addform'
        />
      </Modal>
    </>
  );
});

export default AddComponent;
