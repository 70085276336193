import React from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

const ConditionalOperator = (props) => {
	const { t } = useTranslation()
	const handleChange = (e) => {
		props.formula.value = e
		props.setFormula(props.main)
	}
	return (
		<Select defaultValue={props.formula.value} value={props.formula.value} className="w100 mb-10 mt-10" placeholder={`${t('DealBuilder.Operator')}`} onChange={handleChange} >
			<Select.Option value="<=">{"<="}</Select.Option>
			<Select.Option value="<">{"<"}</Select.Option>
			<Select.Option value=">">{">"}</Select.Option>
			<Select.Option value=">=">{">="}</Select.Option>
			<Select.Option value="=">{"="}</Select.Option>
			<Select.Option value="!=">{"!="}</Select.Option>
		</Select >
	)
}

export default ConditionalOperator
