import React from 'react';
import { observer } from 'mobx-react';

const HistoryRender = observer((props) => {
  let data = props.value;

  let content = data && data.map((item) => {
    return item.children
    ? item.item_key + ":" + (item.detail ? item.detail : " ") + ", " + item.children.map((child1) => (
      child1.children
        ? child1.item_key + ":" + (child1.detail ? child1.detail : " ") + ", " + child1.children.map((child2) => (
          child2.item_key + ":" + (child2.detail ? child2.detail : " ")
        ))
        : child1.item_key + ":" + (child1.detail ? child1.detail : " ") + ", "
    )
    )
    : item.item_key + ":" + (item.detail ? item.detail : " ") + ", "
  } )
var detail = JSON.stringify(content)
  return (<>
    <div className='action-column' title={detail}>
      <p>{detail.toString()}</p>
    </div>
  </>
  );
});
export default HistoryRender;
