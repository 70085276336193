import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import GridConfig from '../../config/GridConfig';
import { convertTextToID, defaultPromotionFilter } from '../../utils/GlobalFunction';
import { clear, reset } from '../../utils/ResetClearButton';

export default class PromotionStore {
  list_data = [];
  per_page = GridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;

  qucik_note_list = null;
  leads_notes = null;
  extraFilter = null;

  dropdown_users_list = null;
  dropdown_people_list = null;
  dropdown_global_org = null;
  dropdown_leadstatus_list = null;
  child_lead_status_list = null;
  dropdown_leads_users_list = null;
  classification_role_list = null;
  kanban_list_data = [];

  allChildStagesList = null;

  delayNoteHoverHandler = null; // USED TO GET NOTE DETAIL ON MOUSE HOVER FOR 3 SECONDS

  DEFAULT_GRID_FILTER_ID = 99995;
  selectedGridFilter = localStorage.getItem('savedFilters')
    ? JSON.parse(localStorage.getItem('savedFilters'))?.PROMOTIONSTORE
      ? JSON.parse(localStorage.getItem('savedFilters'))?.PROMOTIONSTORE
      : null
    : 99995;

  isSavedFiltersLoadedPromotion = false;  // Used for Twice api call

  setDelayNoteHoverHandler = (data) => {
    this.delayNoteHoverHandler = data;
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = GridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.PROMOTIONSTORE) {
        params = { request: temp.PROMOTIONSTORE };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PROMOTIONSTORE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          PROMOTIONSTORE: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let columnConfig = localStorage.getItem('promotion_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({
        state: JSON.parse(columnConfig),
      });
    }
    let param = this.getFilter(params);
    // let user_name_fullname = localStorage.getItem('user_name_fullname');
    let filter = {
      // 'lead_owner_name.full_name': { values: [paramData], filterType: 'set' },
      ...param?.request?.filter,
    };

    params.api.setFilterModel(filter);
    params.api.setSortModel(param?.request?.sort);

    var datasource = this.createDatasource(GridConfig.options);
    if (this.isSavedFiltersLoadedPromotion) {
      api.setServerSideDatasource(datasource);
    } else {
      if (localStorage.getItem('isSavedFiltersFetching')) {
        setTimeout(() => {
          api.setServerSideDatasource(datasource);
        }, 2000);
      }
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'promotion_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        let columnConfig = localStorage.getItem('promotion_grid');
        if (columnConfig) {
          this.onGridChanged(params);
        }
        var filter_data = params.request.filterModel;

        if (filter_data['lead_owner_name.full_name']) {
          filter_data['lead_owner_name.full_name'].values = convertTextToID(
            filter_data['lead_owner_name.full_name'],
            this.dropdown_leads_users_list,
            'name',
            'id'
          );
        }
        if (filter_data['lead_holder_name.full_name']) {
          filter_data['lead_holder_name.full_name'].values = convertTextToID(
            filter_data['lead_holder_name.full_name'],
            this.dropdown_leads_users_list,
            'name',
            'id'
          );
        }
        if (filter_data['people.full_name']) {
          filter_data['people.full_name'].values = convertTextToID(
            filter_data['people.full_name'],
            this.dropdown_people_list,
            'people',
            'id'
          );
        }
        if (filter_data['organisation_name.organisation_name']) {
          filter_data['organisation_name.organisation_name'].values = convertTextToID(
            filter_data['organisation_name.organisation_name'],
            this.dropdown_global_org,
            'organisation_name',
            'id'
          );
        }
        if (filter_data['lead_status_id.status_name']) {
          filter_data['lead_status_id.status_name'].values = convertTextToID(
            filter_data['lead_status_id.status_name'],
            this.allChildStagesList,
            'status_name',
            'id'
          );
        }
        if (filter_data['classification.name']) {
          filter_data['classification.name'].values = convertTextToID(
            filter_data['classification.name'],
            this.classification_role_list,
            'name',
            'id'
          );
        }

        var payload = {
          waste: this.waste,
          filter_data: params.request.filterModel,
          sort_data: params.request.sortModel,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
            (params.request.endRow - params.request.startRow)
          ),
        };

        let merged = { ...payload, ...this.extraFilter };
        this.setFilter(params.api);
        if (this.isSavedFiltersLoadedPromotion) {
          this.getList(merged).then((data) => {
            if (data.total === 0) {
              this.agGrid.api.showNoRowsOverlay();
            } else {
              this.agGrid.api.hideOverlay();
            }
            params.successCallback(data.data, data.total);
            var allColumnIds = [];
            let columnConfig = localStorage.getItem('promotion_grid');
            if (this.agGrid && this.agGrid.columnApi && columnConfig) {
              if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
                this.agGrid.columnApi.applyColumnState({
                  state: JSON.parse(columnConfig),
                });
              }
            } else {
              if (this.agGrid && this.agGrid.columnApi && data.total) {
                this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                  if (!['actions'].includes(column.colId)) {
                    allColumnIds.push(column.colId);
                  }
                });
                this.agGrid.columnApi.autoSizeColumns(allColumnIds);
              }
            }
          });
        }
      },
    };
  };

  getList = (payload = {}) => {
    return Axios.post(`leadstransactions/list/promotion`, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => {
          if (item.email && item.email.length > 0) {
            item.email.forEach((itememail, indexemail) => {
              item.emailname = itememail.contact_value;
            });
          } else {
            item.emailname = '';
          }
        });
      }
      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      return data;
    });
  };

  getUsersList = () => {
    return Axios.get(`user/dropdown/list`).then(({ data }) => {
      this.dropdown_users_list = data.data;

      return data;
    });
  };

  getLeadsUsersList = () => {
    return Axios.get(`leads/user/dropdown/list`).then(({ data }) => {
      this.dropdown_leads_users_list = data.data;

      return data;
    });
  };

  getPeopleList = () => {
    return Axios.get(`global/people/dropdown/list`).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item, index) => { });
      }
      this.dropdown_people_list = data.data;

      return data;
    });
  };

  // call api to get all parent organisations list
  getMappedOrg = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      this.dropdown_global_org = data.data;
      return data;
    });
  };

  getLeadStatusList = (type) => {
    return Axios.post(`leads-status/status/list`, { lead_record_type: type }).then(
      ({ data }) => {
        this.dropdown_leadstatus_list = data.data;

        return data;
      }
    );
  };
  getChildStatusList = (parentstatus_type) => {
    return Axios.get(`leads-status/status/list`).then(({ data }) => {
      let child_status = [];
      if (data.data && data.data.length > 0) {
        let parentstatusArr = data.data.filter((e) => e.id === parentstatus_type);
        if (parentstatusArr && parentstatusArr.length > 0) {
          child_status = parentstatusArr.shift().child_leads;
        }
      }
      data.data = child_status;
      this.child_lead_status_list = child_status;

      return data;
    });
  };

  getQuickNoteList = () => {
    return Axios.post(`my-settings`).then(({ data }) => {
      this.qucik_note_list = data.data.quick_notes;
      return data;
    });
  };

  //set Deal Status Update API
  updateQuickNote = (id, formdata) => {
    return Axios.post(`deal-desk/create-note-outbound/${id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  getLeadsNotes = (lead_transaction_id) => {
    return Axios.post(`deal-desk/get-leads-notes/${lead_transaction_id}`)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  // reset all the server side filters
  /*
  handleReset = () => {
    localStorage.removeItem('promotion_grid');

    this.extraFilter = null;
    // this.selectedGridFilter = null;
    this.setSelectedGridFilter(this.DEFAULT_GRID_FILTER_ID);
    // let tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
    // localStorage.setItem(
    //   'savedFilters',
    //   JSON.stringify({
    //     ...tempsavedFilters,
    //     PROMOTIONSTORE: this.DEFAULT_GRID_FILTER_ID,
    //   })
    // );

    // let user_name_fullname = localStorage.getItem('user_name_fullname');
    // let filter = {
    //   'lead_owner_name.full_name': { values: [user_name_fullname], filterType: 'set' },
    // };
    this.agGrid.api.setFilterModel(defaultMyLeads);
    this.agGrid.api.setSortModel(null);
    this.agGrid.columnApi.resetColumnState();
    this.agGrid.columnApi.autoSizeColumns();
    this.agGrid.api.onFilterChanged(null);
  }; */

  handleReset = () => {
    this.extraFilter = null;
    reset(this.agGrid, 'promotion_grid', this.setSelectedGridFilter, this.DEFAULT_GRID_FILTER_ID, defaultPromotionFilter)
  }

  /** This is called on clear button */
  applyFilterDefault = () => {
    this.extraFilter = null;
    clear(this.agGrid, defaultPromotionFilter)
  }

  getClassificationList = () => {
    return Axios.post(`industry/list`).then(({ data }) => {
      this.classification_role_list = data.data;

      return data;
    });
  };

  getKanbanList = (payload = {}) => {
    let api_url_path = `/leads-transactions/kanban/list/1`;

    if (this.current_list_path === '/leads/opportunities/kanban') {
      api_url_path = `/leads-transactions/kanban/list/2`;
    } else if (this.current_list_path === '/leads/unused/kanban') {
      api_url_path = `/leads-transactions/kanban/list`;
    } else if (this.current_list_path === '/leads/deals/kanban') {
      api_url_path = `/leads-transactions/kanban/list/3`;
    }

    return Axios.post(api_url_path, payload).then(({ data }) => {
      if (data.data && data.data.data && data.data.data.length) {
        data.data.data.forEach((item, index) => {
          if (item.email && item.email.length > 0) {
            item.email.forEach((itememail, indexemail) => {
              item.emailname = itememail.contact_value;
            });
          } else {
            item.emailname = '';
          }
          item['Title'] = item.id;
          item['Status'] =
            item.lead_status_id &&
            item.lead_status_id.status_name.charAt(0).toUpperCase() +
            item.lead_status_id.status_name.slice(1);
          item.leadstatusname = item.lead_status_id && item.lead_status_id.status_name;
          item.LeadStatusID = item.lead_status_id && item.lead_status_id.id;
        });
      }
      this.kanban_list_data = data && data.data && data.data.data;

      return data.data;
    });
  };

  //set Deal Status Update API
  updateLeadStatus = (id, formdata) => {
    return Axios.post(`leads-status/update-status/${id}`, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        this.getKanbanList();
        return data;
      })
      .catch(({ response: { data } }) => {
        return Promise.reject(data);
      });
  };

  // USED FOR PROMOTION AND UNUSED LEADS FILTER DROPDOWN
  getAllChildStatusList = () => {
    return Axios.get(`leads-status/status/list`).then(({ data }) => {
      let childArr = [];
      if (data.data && data.data.length > 0) {
        data.data.forEach((x) => {
          if (x.child_leads) {
            x.child_leads.forEach((y) => {
              if (y) {
                childArr.push(y);
              }
            });
          }
        });
      }
      this.allChildStagesList = childArr;

      return childArr;
    });
  };

  setSelectedGridFilter = (data) => {
    this.selectedGridFilter = data;

    let tempsavedFilters = JSON.parse(localStorage.getItem('savedFilters'));
    localStorage.setItem(
      'savedFilters',
      JSON.stringify({
        ...tempsavedFilters,
        PROMOTIONSTORE: data,
      })
    );
  };

  /// Used To Update GRID FROM SAVED FILTERS
  updateGridOnSavedFilter = (filterdata) => {
    if (this.agGrid) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          PROMOTIONSTORE: { filter: filterdata },
        })
      );

      this.agGrid.api.setFilterModel(filterdata);
    }
  };

  resetSelectedSavedGridFilter = () => {
    this.selectedGridFilter = this.DEFAULT_GRID_FILTER_ID;
  };

  /** This is called on clear button :: DEFAULT - R */
  // applyFilterDefault = () => {
  //   // localStorage.removeItem('promotion_grid');
  //   this.setSelectedGridFilter(this.DEFAULT_GRID_FILTER_ID);
  //   this.extraFilter = null;
  //   // this.agGrid.columnApi.resetColumnState();
  //   if (this.agGrid) {
  //     this.agGrid.api.setFilterModel(defaultMyLeads);
  //     this.agGrid.api.setSortModel(null);
  //     this.agGrid.api.onFilterChanged(null);
  //   }
  // };

  /** This is called on clear button */
  /*
  applyFilterDefault = () => {
    // localStorage.removeItem('promotion_grid');
    this.setSelectedGridFilter(this.DEFAULT_GRID_FILTER_ID);
    this.extraFilter = null;
    // this.agGrid.columnApi.resetColumnState();
    if (this.agGrid) {
      this.agGrid.api.setFilterModel(defaultMyLeads);
      this.agGrid.api.setSortModel(null);
      this.agGrid.api.onFilterChanged(null);
    }
  }; */

  setIsSavedFiltersLoadedPromotion = (data) => {
    this.isSavedFiltersLoadedPromotion = data;
  };
}

decorate(PromotionStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  qucik_note_list: observable,
  leads_notes: observable,
  dropdown_users_list: observable,
  dropdown_people_list: observable,
  dropdown_global_org: observable,
  dropdown_leadstatus_list: observable,
  child_lead_status_list: observable,
  extraFilter: observable,
  dropdown_leads_users_list: observable,
  classification_role_list: observable,
  kanban_list_data: observable,
  allChildStagesList: observable,
  delayNoteHoverHandler: observable,
  selectedGridFilter: observable,
  DEFAULT_GRID_FILTER_ID: observable,
  isSavedFiltersLoadedPromotion: observable,

  setPageSize: action,
  getList: action,
  setupGrid: action,
  setDeleteValues: action,
  getQuickNoteList: action,
  getLeadsNotes: action,
  updateQuickNote: action,
  getUsersList: action,
  getPeopleList: action,
  getMappedOrg: action,
  onGridChanged: action,
  getLeadStatusList: action,
  getChildStatusList: action,
  getLeadsUsersList: action,
  getClassificationList: action,
  getKanbanList: action,
  updateLeadStatus: action,
  getAllChildStatusList: action,
  setDelayNoteHoverHandler: action,
  setSelectedGridFilter: action,
  updateGridOnSavedFilter: action,
  resetSelectedSavedGridFilter: action,
  applyFilterDefault: action,
  setIsSavedFiltersLoadedPromotion: action,
});
