import React from 'react';
const StructureTableContentPost = ({ Structure }) => {
  const PrintStructure = (structure, isParent = null) => {
    return (
      structure &&
      structure.map((x, i) => {
        return (
          x && (
            <>
              <div key={i}>
                {isParent ? `${isParent}.${x.position}` : `${x.position}.`}
                &nbsp;&nbsp;
                {/* {x.detail ? x.detail.replace(/(?:\r\n|\r|\n)/g, '<br />') : ''} */}
                <span
                  className='data'
                  dangerouslySetInnerHTML={{
                    __html: x.detail
                      ? x.detail.replace(
                          /(?:\r\n|\r|\n)/g,
                          '<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                        )
                      : null,
                  }}
                />
              </div>
              {x.children && x.children.length > 0 && (
                <div>
                  {x.children &&
                    PrintStructure(
                      x.children,
                      isParent ? isParent + '.' + x.position : x.position
                    )}
                </div>
              )}
            </>
          )
        );
      })
    );
  };

  return (
    <>
      <div className='structure'>{PrintStructure(Structure)}</div>
    </>
  );
};

export default StructureTableContentPost;
