import React, { useState } from 'react';
import { Form, Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const ProxyloginComponent = observer((props) => {
  const [form] = Form.useForm();
  const {t} = useTranslation()
  const [saving, setSaving] = useState(false);
  const {
    USERS,
    AUTH: { initiatAppOptions },
  } = useStore();

  const handleSubmit = () => {
    setSaving(true);

    let formdata = {
      id: USERS.proxyValues && USERS.proxyValues.id,
    };
    USERS.doProxyLogin(formdata).then((data) => {
      localStorage.setItem('doProxyLogIn', true)
      initiatAppOptions();
    });
  };

  return USERS.proxyValues ? (
    <Modal
      centered
      title={`${t('Users.Proxy')} ${t('Users.Login')}`}
      visible={props.visible}
      onCancel={props.close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='proxylogin'
          loading={saving}
          htmlType='submit'
          type='primary'
        >
          {t('Users.Continue')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            form.resetFields();
            props.close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form form={form} id='proxylogin' onFinish={handleSubmit}>
        {<p>{t('Users.Switch_User_Account')}?</p>}
      </Form>
    </Modal>
  ) : null;
});

export default ProxyloginComponent;
