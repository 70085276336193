import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
export default class ScheduleLibraryStore {
  schedule_library_data = null;
  CopiedScheduleData = null;
  Schedule = [];
  savescheduleform = null;

  SelectedDate = new Date();
  DateType = false;
  editValues = null;

  setCopyActiveScheduleData = null;

  notifyAutosave = false;

  notifyAutosaveLib = false;

  scheduleAutoSaveDataLIB = null; //Schedule AUTOSAVE DATA

  autoSaveUpdatedData = null;

  ScheduleData = [];

  setEditValues = (data) => {
    this.editValues = data;
  };

  saveScheduleData = (id = 0, formdata) => {
    let APIURL = `deal-desk/leads-sss/add/${id}`;
    return Axios.post(APIURL, formdata)
      .then(({ data }) => {
        if (formdata.autosave && !formdata.is_library) {
          this.notifyAutosave = true;
        }

        if (formdata.autosave && formdata.is_library) {
          this.notifyAutosaveLib = true;
          this.autoSaveUpdatedData = data.data;
        }

        if (!formdata.autosave && formdata.is_library) {
          this.resetScheduleAutoSaveData();
        }

        let appendData = this.schedule_library_data;
        if (appendData && data.data && !formdata.autosave && formdata.is_library) {
          let isAlreadyExists = appendData.findIndex((x) => x.id === data.data.id);
          if (isAlreadyExists === -1) {
            appendData = [...appendData, data.data];
          } else {
            appendData[isAlreadyExists] = data.data;
          }
        }

        this.schedule_library_data = appendData;

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  resetScheduleAutoSaveData = () => {
    this.scheduleAutoSaveDataLIB = null;
    this.autoSaveUpdatedData = null;
  };

  SetSchedule = (data) => {
    this.Schedule = data;
  };
  setCopiedScheduleData = (data) => {
    this.CopiedScheduleData = data ? data : null;
  };
  setSavescheduleform = (data) => {
    this.savescheduleform = data;
  };
  SetDate = (data) => {
    this.SelectedDate = data;
  };
  SetDateType = (data) => {
    this.DateType = data;
  };

  setNotifyAutosave = (data) => {
    this.notifyAutosave = data;
  };

  setNotifyAutosaveLib = (data) => {
    this.notifyAutosaveLib = data;
  };

  setScheduleData = (data) => {
    this.ScheduleData = data;
  };

  calculateHeightOnEdit = (editActiveScheduleData, form) => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  };
}

decorate(ScheduleLibraryStore, {
  schedule_library_data: observable,
  Schedule: observable,
  editValues: observable,
  CopiedScheduleData: observable,
  SelectedDate: observable,
  DateType: observable,
  notifyAutosave: observable,
  notifyAutosaveLib: observable,
  scheduleAutoSaveDataLIB: observable,
  autoSaveUpdatedData: observable,
  ScheduleData: observable.shallow,

  saveScheduleData: action,
  fetchStructureLibrary: action,
  SetSchedule: action,
  setCopiedScheduleData: action,
  setSavestructureform: action,
  SetDate: action,
  setEditValues: action,
  SetDateType: action,
  setNotifyAutosave: action,
  setNotifyAutosaveLib: action,
  setScheduleData: action,
  calculateHeightOnEdit: action,
});
