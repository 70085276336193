import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';
import { globalStatus } from '../../utils/GlobalFunction';
import { reset } from '../../utils/ResetClearButton';

export default class ContractsStore {
  list_data = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  agGridRelOrg = null;
  deleteValues = null;
  contractValues = null;
  viewValues = null;
  editValues = null;
  ContractsValues = null;
  rContractsValues = null;
  dropdown_org_list = null;
  dropdown_people_list = null;
  dropdown_global_currency_list = null;
  fetching = false;

  initialValues = {
    phone: [null],
    mobile: [null],
    email: [null],
    adddress: [],
  };

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  setEditValues = (data) => {
    return Axios.get(`contracts/read/${data.id}`)
      .then(({ data }) => {
        this.editValues = data.data;

        return data;
      })
      .catch(({ response: { data } }) => {
        this.editValues = null;
        return Promise.reject(data);
      });
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  getFilter = (params) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      if (temp.CONTRACTS) {
        params = { request: temp.CONTRACTS };
      }
    }
    return params;
  };

  setFilter = (param) => {
    if (localStorage.getItem('params')) {
      var temp = JSON.parse(localStorage.getItem('params'));
      localStorage.setItem(
        'params',
        JSON.stringify({
          ...temp,
          CONTRACTS: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    } else {
      localStorage.setItem(
        'params',
        JSON.stringify({
          CONTRACTS: {
            filter: param.getFilterModel(),
            sort: param.getSortModel(),
          },
        })
      );
    }
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('contracts_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    this.setFilter(params.api);
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  getList = (payload = {}) => {
    return Axios.post(`contracts/list`, payload).then(({ data }) => {
      if (data.data.length) {
        data.data.forEach((item) => {
          item.contract_status_name = globalStatus(
            'contracts_status',
            'key',
            item.contract_status
          );
        });
      }

      this.list_data = data.data;
      this.total = data.total;
      this.current_page = data.current_page;
      if (this.agGrid && this.agGrid.columnApi) {
        let columnConfig = localStorage.getItem('contracts_grid');
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
          if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
          }
        }
        let param = this.getFilter(this.agGrid);
        if (param && param.request) {
          this.agGrid.api.setFilterModel(param.request.filter);
          this.agGrid.api.setSortModel(this.agGrid.columnApi.getColumnState());
        }
      }
      return data;
    });
  };

  getRecordDetail = (formdata, setVariable = true) => {
    return Axios.get(`contracts/read/${formdata.contractid}`)
      .then(({ data }) => {
        if (setVariable) {
          this.contractValues = data.data;
        }

        return data;
      })
      .catch(({ response: { data } }) => {
        this.contractValues = null;
        return Promise.reject(data);
      });
  };

  setContractValues = (data) => {
    this.contractValues = data;
  };

  // set related organisation values
  setRContractsValues = (data) => {
    this.rContractsValues = data;
  };

  AddData = (data) => {
    return Axios.post(`contracts/add`, data)
      .then(({ data }) => {
        this.getList();

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  EditData = (data, formData) => {
    return Axios.post(`contracts/edit/${data.id}`, formData)
      .then(({ data }) => {
        this.getList();

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  DeleteData = (contract_id) => {
    return Axios.get(`contracts/delete/${contract_id}`)
      .then(({ data }) => {
        this.getList();

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  getOrganisationList = () => {
    return Axios.get(`global/organisations/get`).then(({ data }) => {
      this.dropdown_org_list = data.data;

      return data;
    });
  };
  getPeopleList = () => {
    return Axios.get(`global/people/dropdown/list`).then(({ data }) => {
      this.dropdown_people_list = data.data;

      return data;
    });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!['logo_url', 'contract_file_path'].includes(key)) {
        formData.append(
          key,
          data[key] !== undefined && data[key] !== null ? data[key] : ''
        );
      }
    });
    return formData;
  };

  getGlobalCurrencyList = () => {
    return Axios.get(`globalcurreny/list`).then(({ data }) => {
      this.dropdown_global_currency_list = data.data;

      return data;
    });
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'contracts_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  }

  // reset function
  handleReset = () => {
    reset(this.agGrid, "contracts_grid")
  };
}

decorate(ContractsStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  agGridRelOrg: observable,
  deleteValues: observable,
  contractValues: observable,
  rContractsValues: observable,
  initialValues: observable,
  viewValues: observable,
  dropdown_org_list: observable,
  dropdown_people_list: observable,
  dropdown_global_currency_list: observable,
  editValues: observable,
  setPageSize: action,
  getList: action,
  setupGrid: action,
  setDeleteValues: action,
  getRecordDetail: action,
  AddData: action,
  EditData: action,
  DeleteData: action,
  getOrganisationList: action,
  getPeoplelist: action,
  JsonToFormData: action,
  getGlobalCurrencyList: action,
  setEditValues: action,
  onGridChanged: action,
  onFilterChanged: action,
  handleReset: action,
});
