import React, { useState, useEffect } from 'react';
import { Col } from 'antd';

import { observer } from 'mobx-react';
import useStore from '../../../../store';
import InputComponent from '../../../../component/InputComponent';
// import { vsmTransactionReport } from '../../../../config/messages';
import { useTranslation } from 'react-i18next';

const KPIReportCriteriaElement = observer(({ form, handleChange, formDataList }) => {
  const [fetchLeadRecordType, setFetchLeadRecordType] = useState(false);
  const { t } = useTranslation();
  //----------GLOBAL STATE DECLARATION---------------//
  const {
    REPORTSTORE: {
      recordTypeList,
      getStatusList,
      getLeadTypeList,
      previewReportValues,
      getRecordType,
    },
  } = useStore();
  //----------GLOBAL STATE DECLARATION---------------//

  const handleRecordType = (e) => {
    getStatusList([], e, true).then(() => {});
    form.resetFields(['selected_lead_status']);
  };

  useEffect(() => {
    if (!fetchLeadRecordType) {
      getLeadTypeList(previewReportValues, true);
      getRecordType();
      setFetchLeadRecordType(true);
    }
  }, [
    fetchLeadRecordType,
    previewReportValues,
    getLeadTypeList,
    getRecordType,
    getStatusList,
    setFetchLeadRecordType,
  ]);

  return (
    <>
      {formDataList.includes('record_type') && (
        <Col span={6}>
          <InputComponent
            type='select'
            label={`${t('Common_Data.Sold')} ${t('Common_Data.Type')}`}
            required
            rules={[{ required: true, message: t('Reports.SOLD_Type_Validation') }]}
            name='record_type'
            placeholder={`${t('Common_Data.Choose')} ${t('Common_Data.Sold')} ${t(
              'Common_Data.Type'
            )}`}
            tooltip={`${t('Common_Data.Sold_Record_Tooltip')}.`}
            onChange={(e) => {
              handleChange();
              handleRecordType(e);
            }}
            showArrow
            allowClear
            options={{
              values: recordTypeList,
              value_key: 'id',
              text_key: 'record_type_name',
            }}
          />
        </Col>
      )}
    </>
  );
});

export default KPIReportCriteriaElement;
