import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button, Form } from 'antd';
import useStore from '../../../store';
import { vsmNotify, vsmUsers } from '../../../config/messages';
import debounce from 'lodash/debounce';
import SalesFormComponent from './SalesFormComponent';
import { useTranslation } from 'react-i18next';

const SalesComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    USERS,
    AUTH: { user },
  } = useStore();
  const [isDisabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [visible, setVisible] = useState(true);

  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(true);
    setVisible(false);
  };

  const handleSubmit = (data) => {
    window.date_test = data.date_of_birth;
    setSaving(true);
    var formData = USERS.JsonToFormData(data);
    // if (data.logo_url.fileList[0] && data.logo_url.fileList.length > 0) { formData.append("photo_url", data.logo_url.fileList[0].originFileObj) }
    formData.append('date_of_birth', data.date_of_birth.format('D/MM/Y'));
    USERS.AddData(formData)
      .then(() => {
        USERS.getList({}, user?.user_role?.user_role_id === 1 ? true : false);
        close();
        vsmNotify.success({
          message: vsmUsers.add,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally();
  };

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = debounce(() => {
    form
      .validateFields()
      .then((data) => {
        setDisabled(false);
      })
      .catch((e) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  }, 200);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return USERS.salesTargetValues ? (
    <Modal
      centered
      title={`${t('Users.Set')} ${t('OrganisationManagement.Sales')} ${t(
        'Common_Data.Target'
      )}`}
      visible={props.visible}
      onCancel={close}
      destroyOnClose={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          form='salestargetform'
          htmlType='submit'
          type='primary'
          shape='round'
          size='medium'
          loading={saving}
          disabled={isDisabled}
        >
          {t('Common_Data.Update')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            close();
          }}
          shape='round'
          size='medium'
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
      width={500}
    >
      <SalesFormComponent
        form={form}
        onChange={handleChange}
        handleSubmit={handleSubmit}
        id='salestargetform'
        visible={visible}
      />
    </Modal>
  ) : null;
});

export default SalesComponent;
