import Axios from 'axios';
import { decorate, observable, action } from 'mobx';
import LocalGridConfig from '../../config/LocalGridConfig';

export default class LeadStatusStore {
  list_data = null;
  cardDataUpdate = [];
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  total = 0;
  agGrid = null;
  agGridRelOrg = null;
  deleteValues = null;
  viewValues = null;
  leadValues = null;
  editValues = null;
  rLeadValues = null;
  fetching = false;
  parent_leadstatus_list = null;
  kanbanLead_status_list = null;
  recordTypeList = null;

  extraFilter = "Originator";

  initialValues = {
    status_name: [null],
    parent_status_id: [],
  };

  listLoading = false;

  //set delete values
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  //set delete values
  setEditValues = (data) => {
    return Axios.get(`leadsstatus/read/${data.id}`)
      .then(({ data }) => {
        this.editValues = data.data;

        return data;
      })
      .catch(({ response: { data } }) => {
        this.editValues = null;
        return Promise.reject(data);
      });
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page;
    this.agGrid.api.paginationSetPageSize(parseInt(page));
  };

  setupGrid = (params) => {
    this.agGrid = params;
    let columnConfig = localStorage.getItem('lead_status_grid');
    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
      this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
    }
  };

  // Set column width after resizing colums
  onGridChanged = (params) => {
    localStorage.setItem(
      'lead_status_grid',
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // reset function
  handleReset = () => {
    this.extraFilter = null;
    this.agGrid.api.setFilterModel(null);
    this.agGrid.api.setSortModel(null);
    this.agGrid.api.onFilterChanged(null);
  };

  getList = (payload = {}) => {
    this.setListLoading(true);
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.columnApi.getColumnState();
    }
    if (this.extraFilter) {
      payload.lead_record_type = this.extraFilter;
    }
    this.cardDataUpdate = []
    return Axios.post(`leads-status/status/list`, payload).then(({ data }) => {
      this.list_data = data.data;
      let cardDataUpdate = []
      if (this.list_data.length > 0) {


        this.list_data.map((val) => {

          val.child_leads.map((valChild, index) => {
            let currentElement = valChild;
            if (currentElement.record_type_actual) {
              if (currentElement.record_type_actual.length > 25) {
                currentElement.modified_record_type = currentElement.record_type_actual.substr(0, 25);
                currentElement.modified_record_type += "...";
              }
              else {
                currentElement.modified_record_type = currentElement.record_type_actual
              }
            }
            else {
              currentElement.modified_record_type = ""
            }
            currentElement.parent_status_id = val.id
            currentElement.customIndex = String(val.id).concat(index);
            cardDataUpdate.push(currentElement)

            return null;
          })
          return null;
        });
      }

      this.cardDataUpdate = cardDataUpdate;

      this.total = data.total;
      this.current_page = data.current_page;

      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.columnApi.applyColumnState({ state: sort });
      }
      let columnConfig = localStorage.getItem('lead_status_grid');
      if (this.agGrid && this.agGrid.columnApi && columnConfig) {
        this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) });
      }
      this.setListLoading(false);
      return data;
    });
  };

  getRecordDetail = (formdata, setVariable = true) => {
    return Axios.get(`leadsstatus/read/${formdata.leadid}`)
      .then(({ data }) => {
        if (setVariable) {
          this.leadValues = data.data;
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        this.leadValues = null;
        return Promise.reject(data);
      });
  };

  setLeadValues = (data) => {
    this.leadValues = data;
  };

  AddData = (data) => {
    return Axios.post(`leadsstatus/add`, data)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  EditData = (data) => {
    return Axios.post(`leadsstatus/edit/${data.id}`, data)
      .then(({ data }) => {
        this.getList();

        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  DeleteData = (leadid) => {
    return Axios.get(`leadsstatus/delete/${leadid}`)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (!['logo_url', 'date_of_birth'].includes(key)) {
        data[key] && formData.append(key, data[key]);
      }
    });
    return formData;
  };

  getParentLeadStatus = () => {
    return Axios.get(`leadsstatus/parentstatus`).then(({ data }) => {
      this.parent_leadstatus_list = data.data;

      return data;
    });
  };

  getKanbanLeadStatus = () => {
    return Axios.get(`leadsstatus/parentstatus`).then(({ data }) => {
      this.kanbanLead_status_list = data.data;

      return data;
    });
  };

  saveReorder = (data) => {
    return Axios.post(`leadsstatus/updateorder`, data)
      .then(({ data }) => {
        // this.getKanbanLeadStatus();
        this.kanbanLead_status_list = data.data;
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  getRecordType = (type = {}) => {
    return Axios.get('leads-record-type/list', type).then(({ data }) => {
      this.recordTypeList = data.data;
      return data;
    });
  };

  setListLoading = (data) => {
    this.listLoading = data;
  };
}

decorate(LeadStatusStore, {
  list_data: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  agGridRelOrg: observable,
  deleteValues: observable,
  leadValues: observable,
  editValues: observable,
  rLeadValues: observable,
  initialValues: observable,
  viewValues: observable,
  parent_leadstatus_list: observable,
  kanbanLead_status_list: observable,
  recordTypeList: observable,
  extraFilter: observable,
  listLoading: observable,
  setPageSize: action,
  getList: action,
  getKanbanLeadStatus: action,
  setupGrid: action,
  setDeleteValues: action,
  getRecordDetail: action,
  setLeadValues: action,
  AddData: action,
  EditData: action,
  DeleteData: action,
  JsonToFormData: action,
  getParentLeadStatus: action,
  saveReorder: action,
  setEditValues: action,
  onFilterChanged: action,
  onGridChanged: action,
  handleReset: action,
  getRecordType: action,
  setListLoading: action,
  cardDataUpdate: observable
});
