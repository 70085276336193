import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';

import useStore from '../../../../store';
import InputComponent from '../../../../component/InputComponent';
import { Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';

const OrganisationElements = observer(({ handleChange, form, formDataList }) => {
  const [searchOrgKey, setSearchOrgKey] = useState();
  const [fetchOrgRole, setFetchOrgRole] = useState(true);
  const { t } = useTranslation();
  //----------GLOBAL STATE DECLARATION---------------//
  const { ORGANISATION } = useStore();
  //----------GLOBAL STATE DECLARATION---------------//

  const handleOrgSearch = useMemo(() => {
    const loadOptions = (val) => {
      setSearchOrgKey(val);
      ORGANISATION.getAllROrg({
        search_for: val,
        mapping_type: 1,
        organisation_type: form.getFieldValue('organisation_type'),
      });
    };

    return debounce(loadOptions, 500);
  }, [ORGANISATION, form]);

  return (
    <>
      {formDataList.includes('organisation_type') && (
        <Col span={12}>
          <InputComponent
            type='select'
            allowClear
            showSearch
            showArrow
            label={t('Reports.Organisation_Type')}
            name='organisation_type'
            placeholder={`${t('PeopleManagement.All')}`}
            mode='multiple'
            onChange={handleChange}
            onFocus={() =>
              fetchOrgRole && ORGANISATION.getOrgRoles().then(() => setFetchOrgRole(false))
            }
            notFoundContent={fetchOrgRole ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`}
            options={{
              values: ORGANISATION.dropdown_orgRole_list,
              value_key: 'id',
              text_key: 'role_name',
            }}
            tooltip={t('Reports.Organiation_Type_Tooltip')}
          />
        </Col>
      )}
      {formDataList.includes('organisation') && (
        <Col span={12}>
          <InputComponent
            label={`${t('Common_Data.Organisation')}/s`}
            name='organisation'
            placeholder={`${t('PeopleManagement.All')}`}
            type='select'
            mode='multiple'
            showSearch
            allowClear
            showArrow
            onChange={handleChange}
            onSearch={handleOrgSearch}
            notFoundContent={
              ORGANISATION.fetching ? (
                <Spin size='small' />
              ) : searchOrgKey ? (
                `${t('Common_Data.No_Record_Found')}.`
              ) : null
            }
            options={{
              values: ORGANISATION.dropdown_ROrg_list,
              value_key: 'id',
              text_key: 'entity_name',
            }}
            tooltip={`${t('Reports.Organisation_Tooltip')}.`}
          />
        </Col>
      )}
    </>
  );
});

export default OrganisationElements;
