import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Input, Tooltip, Spin, Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import useStore from '../../../../../store';
import { vsmRoles, vsmNotify } from '../../../../../config/messages';
import InputComponent from '../../../../../component/InputComponent';
import { useTranslation } from 'react-i18next';

const EditComponent = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    ORGANISATION,
    ORGROLESTORE: { EditData, editValues },
  } = useStore();
  const [saving, setSaving] = useState();
  const [disabled, setDisabled] = useState(false);
  const [fetchRole, setFetchRole] = useState(true);

  // make a fuction to call to edit record
  const handleSubmit = (data) => {
    setSaving(true);
    data.id = editValues.id;
    EditData(data)
      .then(() => {
        close();
        vsmNotify.success({
          message: vsmRoles.edit,
        });
      })
      .catch((e) => {
        if (e.errors) {
          form.setFields(e.errors);
        }
      })
      .finally(() => setSaving(false));
  };

  // set the form values to edit
  useEffect(() => {
    setDisabled(true);
    if (props.visible && editValues) {
      let roles = editValues.associated_role_names
        ? editValues.associated_role_names
        : [];
      let role_ids = roles.length > 0 ? roles.map((item) => item.id) : [];
      ORGANISATION.dropdown_orgRole_list = roles;
      form.setFieldsValue({
        role_name: editValues.role_name,
        related_role_ids: role_ids,
      });
    }
  }, [editValues, form, ORGANISATION, props]);

  // check for valid form values then accordingly make save button disable/enable
  const handleChange = () => {
    form
      .validateFields()
      .then((d) => {
        setDisabled(false);
      })
      .catch((d) => {
        setDisabled(true);
      });
  };

  // reset form and close edit form
  const close = () => {
    props.close();
    form.resetFields();
    setDisabled(false);
    setFetchRole(true);
  };

  return editValues ? (
    <Modal
      centered
      title={`${t('Common_Data.Edit')} ${t('OrganisationManagement.Role')} - #${editValues.id} - ${editValues.role_name}`}
      visible={props.visible}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button
          key='1'
          disabled={disabled}
          form='editform'
          loading={saving}
          htmlType='submit'
          type='primary'
        >
          {t('Common_Data.Update')}
        </Button>,
        <Button
          key='2'
          htmlType='button'
          onClick={() => {
            close();
          }}
        >
          {t('Common_Data.Cancel')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id='editform'
        onFinish={handleSubmit}
        onChange={handleChange}
        labelCol={{ span: 24 }}
        className='innerForm'
      >
        <Row gutter={15}>
          <Col xs={{ span: 12 }}>
            <Form.Item label={`${t('OrganisationManagement.Role')} ${t('Common_Data.Name')}`} required>
              <Form.Item
                name='role_name'
                noStyle
                rules={vsmRoles.validation.role_name}
              >
                <Input placeholder={`${t('OrganisationManagement.Role')} ${t('Common_Data.Name')}`} maxLength={50} />
              </Form.Item>
              <Tooltip title={`${t('OrganisationManagement.Role_Name_Tooltip')}.`}>
                <InfoCircleOutlined className='ml-10' />
              </Tooltip>
            </Form.Item>
          </Col>
          <Col xs={{ span: 12 }}>
            <InputComponent
              required
              type='select'
              mode='multiple'
              label={`${t('OrganisationManagement.Related_Roles')} `}
              name='related_role_ids'
              placeholder={`${t('OrganisationManagement.Choose')} ${t('OrganisationManagement.Related_Roles')}`}
              tooltip={`${t('OrganisationManagement.Related_Role_Tooltip')}.`}
              onChange={handleChange}
              rules={vsmRoles.validation.related_role}
              onFocus={() =>
                fetchRole &&
                ORGANISATION.getOrgRoles().then(() => setFetchRole(false))
              }
              notFoundContent={
                fetchRole ? <Spin size='small' /> : `${t('Common_Data.No_Record_Found')}.`
              }
              options={{
                values: ORGANISATION.dropdown_orgRole_list,
                value_key: 'id',
                text_key: 'role_name',
                rejected_keys: [editValues.id],
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  ) : null;
});

export default EditComponent;
