import { observer } from 'mobx-react';
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom';
import { RouterConfig } from '../config/RouterConfig';
import useStore from '../store';
import useInterval from '../utils/useInterval';

const TimeTracker = observer(({ page, id, stop, fromSideBar }) => {
    const [time, setTime] = useState(0); // state to store Time
    const [stopTimer, setStopTimer] = useState(); // To store the stop prop
    const [delay, setDelay] = useState(); // state to stop/start Timer
    const [isProxyLogin, setIsProxyLogin] = useState(JSON.parse(localStorage.getItem("doProxyLogIn")));

    const timeRef = useRef();
    const match = useRouteMatch()

    const { TimeTrackerStore } = useStore(); // Mobx Store

    /** Costume Hook: Time Counter || Timer. "delay" is passed to stop/start the timer */
    useInterval(() => {
        setTime((time) => time + 1);
    }, delay);

    useEffect(() => {
        timeRef.current = time;
    }, [time]);

    useEffect(() => {
        // console.log(TimeTrackerStore.stop)
        if (isProxyLogin) {
            setStopTimer(false);
        } else if (!fromSideBar && TimeTrackerStore.stop) {
            setStopTimer(true)
        } else {
            setStopTimer(stop);
        }
    }, [isProxyLogin, fromSideBar, stop, TimeTrackerStore.stop]);

    useEffect(() => {
        setIsProxyLogin(JSON.parse(localStorage.getItem("doProxyLogIn")))
    }, []);

    /** API CALL to Save Time */
    const callAPI = useCallback(() => {
        let payload = {
            page_name: page || RouterConfig.find(obj => obj.path === match.path).title,
            seconds: timeRef.current,
        };
        if (TimeTrackerStore.leadTransactionId || id) {
            payload = { lead_transaction_id: TimeTrackerStore.leadTransactionId || id, ...payload } //New "id" to be send if successfully submit done in Add and Clone Components
        }
        if (timeRef.current > 0) {
            TimeTrackerStore.saveTimeTracked(payload);
        }

    }, [TimeTrackerStore, page, id, match.path]);



    /** Stop Timer: when go to different component or "I'm Back" popup initialized */
    useEffect(() => {
        if (!stopTimer) {
            if (TimeTrackerStore.isBackPopup) {
                setDelay(false);
                // console.log("Timer Stoped due to 'I'm Back' popup")
            } else {
                if (isProxyLogin) {
                    setDelay(false)
                } else {
                    setTime(0);
                    setDelay(1000); //start timer when back popup clicked
                }
            }
        } else {
            setDelay(false);
        }
    }, [TimeTrackerStore, TimeTrackerStore.isBackPopup, setDelay, stopTimer, isProxyLogin])

    /** When another component is opened; call the api(stop is true) */
    useEffect(() => {
        if (stopTimer) {
            callAPI();
            // console.log("API CALLED")
        }
    }, [stopTimer, callAPI]);

    /**Call API on unmount if stop set true */
    useEffect(() => {
        return () => {
            if (!id) {
                callAPI();
            }
        }
    }, [callAPI, id]);

    /** Restart Timer: restart by setting time state to 0 and calling the api */
    const restartTimer = useCallback(() => {
        callAPI();
        setTime(0);
        // console.log("Restart")
        // console.log("API CALLED")
    }, [callAPI]);

    /** To Restart Timer: when "Yes, I am working" popup is initialized or clicked*/
    useEffect(() => {
        if (!stopTimer) {
            if (TimeTrackerStore.isPopupInitialized) {
                // console.log("Popup Came")
                restartTimer();
                TimeTrackerStore.setIsPopupInitialized(false);
            } else if (TimeTrackerStore.isWorkingPopupClicked) {
                // console.log("I'm Working Clicked")
                restartTimer();
                TimeTrackerStore.setIsWorkingPopupClicked(false);
            }
        }
    }, [stopTimer, restartTimer, TimeTrackerStore, TimeTrackerStore.isPopupInitialized, TimeTrackerStore.isWorkingPopupClicked])

    /** For Testing //To Remove Only */
    useEffect(() => {
        // console.log(time);
    }, [time]);
    useEffect(() => {
        if (stopTimer) {
            // console.log("Stoped");
            // console.log("Gone to Another Component")
        }
    }, [stopTimer])

    return <></>
})

export default TimeTracker