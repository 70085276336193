import React from 'react';
import { observer } from 'mobx-react';
import InputComponent from '../../../component/InputComponent';
import { Form, Col, Row, Popover } from 'antd';
import { GithubPicker } from 'react-color';
import { vsmSettings } from '../../../config/messages';
import useStore from '../../../store';
import { useTranslation } from 'react-i18next';

const ColorElement = observer(({ onChange, form }) => {
  const { AUTH } = useStore();
  const {t} = useTranslation()
  return (
    <div className='w-100'>
      <Form.List name='notes_category' initialValue={[null]}>
        {(fields, { add, remove }) => {
          return fields.map((field, index) => {
            const handleColorChange = async (c, event) => {
              let temp = form.getFieldValue('bonus_settings')['notes_category'];
              temp[field.name].color_code = c;
              form.setFieldsValue({
                bonus_settings: { notes_category: temp },
              });
              onChange();
            };

            const colorPiker = () => {
              return (
                <Form.Item
                  required={true}
                  name={[field.name, 'color_code']}
                  key={[field.key, 'color_code']}
                  rules={vsmSettings.validation.colorpicker_with_required}
                >
                  <GithubPicker
                    triangle={'left'}
                    className='colorBox'
                    onChange={handleColorChange}
                    colors={[
                      '#000000',
                      '#7F7F7F',
                      '#880015',
                      '#ED1C24',
                      '#FC7F27',
                      '#FFF20A',
                      '#22B04C',
                      '#15A2E8',
                      '#3F48CC',
                      '#A349A4',
                      '#FFFFFF',
                      '#C3C3C3',
                      '#B97A57',
                      '#FDAEC9',
                      '#FEC90E',
                      '#EFE4B0',
                      '#B5E61D',
                      '#99D9EA',
                      '#7092BE',
                      '#C8BFE7',
                    ]}
                  />
                </Form.Item>
              );
            };

            return (
              <div className='w-100' key={field.key}>
                <Row gutter={15}>
                  <Col span={13}>
                    <InputComponent
                      name={[field.name, 'color_name']}
                      key={[field.key, 'color_name']}
                      onChange={onChange}
                      placeholder={`${t('Settings.Color')}`}
                      style={{ width: '100%' }}
                      rules={vsmSettings.validation.with_required}
                    />
                  </Col>
                  <Col span={5}>
                    <Popover
                      trigger='click'
                      content={colorPiker}
                      overlayClassName='colorPiker'
                      placement='bottomRight'
                    >
                      <div
                        style={{
                          background: form.getFieldValue('bonus_settings')[
                            'notes_category'
                          ][field.name]
                            ? form.getFieldValue('bonus_settings')['notes_category'][
                                field.name
                              ].color_code &&
                              form.getFieldValue('bonus_settings')['notes_category'][
                                field.name
                              ].color_code.hex
                            : '#FFFFFF',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                        className='colorInput'
                      >
                        {form.getFieldValue('bonus_settings')['notes_category'][
                          field.name
                        ] &&
                        form.getFieldValue('bonus_settings')['notes_category'][field.name]
                          .color_code
                          ? form.getFieldValue('bonus_settings')['notes_category'][
                              field.name
                            ].color_code.hex
                          : '#FFFFFF'}
                      </div>
                    </Popover>
                  </Col>

                  <Col span={4} className='d-flex'>
                    {fields.length > 1 && (
                      <img
                        alt=''
                        className={`formIcon mt-5 mr-10`}
                        src={AUTH.GetThemedImage('remove')}
                        onClick={() => {
                          remove(field.name);
                          onChange();
                        }}
                      />
                    )}
                    {index === fields.length - 1 && fields.length < 8 && (
                      <img
                        alt=''
                        className={`formIcon mt-5 mr-10`}
                        src={AUTH.GetThemedImage('add')}
                        onClick={() => {
                          add({
                            color_name: null,
                            color_code: { hex: '#FFFFFF' },
                          });
                          onChange();
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            );
          });
        }}
      </Form.List>
    </div>
  );
});

export default ColorElement;
